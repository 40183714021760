/* eslint-disable import/no-unresolved */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useContext, useCallback, useEffect, useRef } from 'react'
import {
  View,
  SafeAreaView,
  Linking,
  Dimensions,
  TouchableOpacity,
  LayoutAnimation,
  Platform,
} from 'react-native'
import { useFocusEffect } from '@react-navigation/native'
import { useBackHandler } from '@react-native-community/hooks'
import QRCode from 'react-native-qrcode-svg'
import ContentLoader, { Rect } from 'react-content-loader/native'
import { useLazyQuery, useApolloClient, useMutation } from '@apollo/client'
import moment from 'moment'
import RNHTMLtoPDF from 'react-native-html-to-pdf'
import Share from 'react-native-share'

import { useTheme } from 'react-native-themed-styles'
import PropTypes from 'prop-types'

import { useReactToPrint } from 'react-to-print'

import { Margin, usePDF } from 'react-to-pdf'

import {
  Button,
  Text,
  Icon,
  ErrorModal,
  SimpleList,
  DoubleIcon,
  Header,
  Loader,
  ScrollWrapperWeb,
  RetryBanner,
  SimpleType,
  BottomSheetModal,
  SimpleSelectType,
} from '../../Components'
import { printReceipt } from '../../Utils/Order'
import { useLanguage } from '../../Utils/Language'
import { convertRefund } from '../../Utils/PdfTemplates'
import Merchant from '../../Utils/Merchant'
import { INF, useConfig, useTemplateLanguage } from '../../Utils'
import ContentManager from '../../Utils/ContentManager'
import { GET_REFUND, SYNC_TRANSACTION } from '../Orders/Orders.schema'

import { ConvertRefund, ReactToPDF } from '../../Utils/PdfTemplatesWeb'

import { GET_QR } from '../../Utils/Utils.Schema'

import Theme, { layoutAnimConfig } from '../../Themes/Theme'
import themedStyles, { itemStyles } from './Refund.Styles'
import images, { animations } from '../../Themes/Images'

const { width } = Dimensions.get('screen')

const LANGUAGES = {
  al: 'sq',
  en: 'en-gb',
}

function LoaderContent({ isFirst, isLast }) {
  const loaderStyle = {}
  if (isFirst) {
    loaderStyle.borderTopLeftRadius = 7
    loaderStyle.borderTopRightRadius = 7
  }
  if (isLast) {
    loaderStyle.borderBottomLeftRadius = 7
    loaderStyle.borderBottomRightRadius = 7
  }
  return (
    <ContentLoader
      speed={0.7}
      height={64}
      style={Object.assign({}, { backgroundColor: 'white' }, loaderStyle)}
      backgroundColor="#e2e2e2"
      foregroundColor="#ecebeb">
      <Rect x="17" y="22.5" rx="3" ry="3" width="37" height="25" />
      <Rect x="75" y="27" rx="3" ry="3" width="120" height="16" />
      <Rect x={`${width - 140}`} y="27" rx="3" ry="3" width="100" height="16" />
    </ContentLoader>
  )
}
LoaderContent.propTypes = {
  isFirst: PropTypes.bool,
  isLast: PropTypes.bool,
}

function SimpleLoaderContent({ isFirst, isLast }) {
  const loaderStyle = {}
  if (isFirst) {
    loaderStyle.borderTopLeftRadius = 7
    loaderStyle.borderTopRightRadius = 7
  }
  if (isLast) {
    loaderStyle.borderBottomLeftRadius = 7
    loaderStyle.borderBottomRightRadius = 7
  }
  return (
    <ContentLoader
      speed={0.7}
      height={64}
      style={Object.assign({}, { backgroundColor: 'white' }, loaderStyle)}
      backgroundColor="#e2e2e2"
      foregroundColor="#ecebeb">
      <Rect x="17" y="28" rx="3" ry="3" width="100" height="14" />
      <Rect x={`${width - 140}`} y="28" rx="3" ry="3" width="100" height="14" />
    </ContentLoader>
  )
}
SimpleLoaderContent.propTypes = {
  isFirst: PropTypes.bool,
  isLast: PropTypes.bool,
}

function SingleLoaderContent({ isFirst, isLast }) {
  const loaderStyle = {}
  if (isFirst) {
    loaderStyle.borderTopLeftRadius = 7
    loaderStyle.borderTopRightRadius = 7
  }
  if (isLast) {
    loaderStyle.borderBottomLeftRadius = 7
    loaderStyle.borderBottomRightRadius = 7
  }
  return (
    <ContentLoader
      speed={0.7}
      height={40}
      width={200}
      style={Object.assign({}, loaderStyle)}
      backgroundColor="#e2e2e2"
      foregroundColor="#ecebeb">
      <Rect x="0" y="10" rx="3" ry="3" width="200" height="20" />
    </ContentLoader>
  )
}
SingleLoaderContent.propTypes = {
  isFirst: PropTypes.bool,
  isLast: PropTypes.bool,
}

function QRLoaderContent({ isFirst, isLast }) {
  const loaderStyle = {}
  if (isFirst) {
    loaderStyle.borderTopLeftRadius = 7
    loaderStyle.borderTopRightRadius = 7
  }
  if (isLast) {
    loaderStyle.borderBottomLeftRadius = 7
    loaderStyle.borderBottomRightRadius = 7
  }
  return (
    <ContentLoader
      speed={0.7}
      height={80}
      width={80}
      style={Object.assign({}, loaderStyle)}
      backgroundColor="#e2e2e2"
      foregroundColor="#ecebeb">
      <Rect x="0" y="0" rx="3" ry="3" width="80" height="80" />
    </ContentLoader>
  )
}
QRLoaderContent.propTypes = {
  isFirst: PropTypes.bool,
  isLast: PropTypes.bool,
}

function ItemLoader() {
  return (
    <View>
      <LoaderContent isFirst />
      <SimpleLoaderContent />
      <SimpleLoaderContent />
      <SimpleLoaderContent />
      <SimpleLoaderContent />
      <LoaderContent isLast />
    </View>
  )
}
function Item({
  title,
  description,
  subDescText,
  amount,
  stylee,
  onPress,
  type,
}) {
  const [styles] = useTheme(itemStyles)
  const { colors } = useContext(Theme)

  return (
    <TouchableOpacity
      onPress={onPress}
      style={[
        styles.itemContainer,
        stylee,
      ]}
      disabled={!onPress}>
      <View style={styles.itemSubcontainer}>
        <DoubleIcon
          iconSource={images.cash}
          statusIconSource={images[type]}
          iconSize={30}
          statusSize={10}
          style={styles.iconStyles}
          statusIconStyle={styles.statusIcon}
        />
        <View style={styles.listDescContainer}>
          <Text i18nKey={title} numberOfLines={1} size="footnote" />
          <Text
            i18nKey={description}
            weight="light"
            color={colors.secondaryText}
            numberOfLines={1}
            style={styles.description}
          />
          <Text
            i18nKey={subDescText}
            numberOfLines={1}
            weight="light"
            color={colors.secondaryText}
            style={styles.subdescription}
          />
        </View>
        <View
          style={styles.amountContainer}>
          <Text i18nKey={amount} style={styles.amount} />
          <Icon source={images.arrow_right} size={24} />
        </View>
      </View>
    </TouchableOpacity>
  )
}
Item.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  subDescText: PropTypes.string,
  amount: PropTypes.string,
  stylee: PropTypes.object,
  onPress: PropTypes.func,
  type: PropTypes.string,
}

function FiscItem({ title, value, isFirst, isLast }) {
  const [styles] = useTheme(itemStyles)

  const translate = (key) => {
    return ContentManager.translate(key)
  }

  let style = {}
  if (isFirst) {
    style = Object.assign({
      borderTopLeftRadius: 7,
      borderTopRightRadius: 7,
      borderTopWidth: 0,
    })
  }
  if (isLast) {
    style = Object.assign({
      borderBottomLeftRadius: 7,
      borderBottomRightRadius: 7,
    })
  }

  return (
    <View
      style={[
        styles.fiscItemContainer,
        style,
      ]}>
      <View style={styles.itemStyle}>
        <View style={styles.listDescContainer1}>
          <Text i18nKey={`${translate(title)}: `} />
          <Text i18nKey={value} />
        </View>
      </View>
    </View>
  )
}
FiscItem.propTypes = {
  title: PropTypes.string,
  value: PropTypes.string,
  isFirst: PropTypes.bool,
  isLast: PropTypes.bool,
}

const FAIL_REASONS = [
  {
    label: 'receipt_retry_no_internet',
    id: '1',
    value: 'NOINTERNET',
  },
  {
    label: 'receipt_retry_technical_problem',
    id: '2',
    value: 'BOUNDBOOK',

  },
  {
    label: 'receipt_retry_general_problem',
    id: '3',
    value: 'SERVICE',

  },
  {
    label: 'receipt_retry_boundbook_problem',
    id: '4',
    value: 'TECHNICALERROR',

  },
]

const PAYMENT_METHOD = {
  BANKNOTE: 'payment_banknote_description',
  CARD: 'payment_card_description',
  CHECK: 'payment_check_description',
  SVOUCHER: 'payment_voucher_description',
  COMPANY: 'payment_company_description',
  ORDER: 'payment_order_description',
  ACCOUNT: 'payment_account_description',
  FACTORING: 'payment_factoring_description',
  COMPENSATION: 'payment_compensation_description',
  TRANSFER: 'payment_transfer_description',
  WAIVER: 'payment_waiver_description',
  KIND: 'payment_kind_description',
  OTHER: 'payment_other_description',

}

function Refund({ route, navigation: { navigate, goBack, pop } }) {

  const { switchLocation: { deviceId, locationId } } = useConfig()

  const [styles] = useTheme(themedStyles)
  const convertRefundWebRef = useRef()
  const retryBannerRef = useRef()

  const { colors } = useContext(Theme)
  const context = useContext(Merchant)
  const lang = useLanguage()
  const { openConfigurationSelector, translate: tempTranslate } = useTemplateLanguage()

  const { translate } = ContentManager

  const { id, refundId, receiptNum, type } = route.params

  const [confirm, setConfirm] = useState(false)
  const [time, setTime] = useState('')
  const [amount, setAmount] = useState('')
  const [currency, setCurrency] = useState('Lekë')
  const [receiptNumber, setReceiptNumber] = useState('')
  const [refundNumber, setRefundNumber] = useState('')
  const [email, setEmail] = useState('')
  const [loading1, setLoading] = useState(true)
  const [loadingDownloadWeb, setLoadingDownloadWeb] = useState(false)
  const [loadingPrintWeb, setLoadingPrintWeb] = useState(false)
  const [qrCode, setQrCode] = useState('')
  const [shareLoading, setShareLoading] = useState(false)


  const [refund1, setRefund] = useState({})

  const [cashBuyer, setCashBuyer] = useState('')
  const [customer, setCustomer] = useState({})

  const client = useApolloClient()

  const [items, setItems] = useState([])
  const [selectedReason, setSelectedReason] = useState({})

  const [
    getRefund,
    { data: refundData, refetch, loading, error },
  ] = useLazyQuery(GET_REFUND, {
    fetchPolicy: 'network-only',
  })
  const [syncTransaction, { loading: syncLoading }] = useMutation(SYNC_TRANSACTION)

  const [modal, setModal] = useState({
    isVisible: false,
    data: [],
    title: '',
    placeholder: '',
    selected: undefined,
    select: () => { },
    Item: SimpleType,
  })

  const [errorModal, setErrorModal] = useState({
    isVisible: false,
    icon: images.error,
    title: '',
    desc: '',
    primaryText: '',
    primaryAction: null,
    secondaryText: '',
    secondaryAction: null,
  })

  const openErrorModal = (
    title = 'receipt_printer_error',
    desc = '',
    primaryText = '',
    primaryAction = null,
    secondaryText = '',
    secondaryAction = null,
    icon = images.warningIcon,
  ) => {
    setErrorModal({
      isVisible: true,
      icon,
      title,
      desc,
      primaryText,
      primaryAction,
      secondaryText,
      secondaryAction,
    })
  }

  const closeErrorModal = () => {
    setErrorModal({
      isVisible: false,
      icon: images.delete,
      title: '',
      desc: '',
      primaryText: '',
      primaryAction: null,
      secondaryText: '',
      secondaryAction: null,
    })
  }

  useFocusEffect(
    useCallback(() => {
      getRefund({
        variables: {
          locationId: 'K11715005L',
          locId: locationId,
          cashRegisterId: deviceId,
          transactionId: id,
          refundId: refundId,
        },
      })
      closeErrorModal()
    }, []),
  )

  useEffect(() => {
    if (refundData) {
      const resp = mapResponse(refundData)
      LayoutAnimation.configureNext(layoutAnimConfig)
      setItems(resp)
      setLoading(false)
    }
  }, [refundData])

  const renderItemRightComponent = (rightLabel) => {
    return (
      <View style={styles.rightLabelStyle}>
        <Text style={styles.itemRightLabel} i18nKey={rightLabel} />
      </View>
    )
  }

  const renderIconLeftComponent = () => {
    return (
      <DoubleIcon
        iconSource={images.cash}
        statusIconSource={images.refund}
        iconSize={30}
        statusSize={10}
        style={styles.spacing}
        statusIconStyle={styles.bottomSpacing}
      />
    )
  }

  const renderLeftComponent = (icon, name) => {
    if (icon.uri) {
      return (
        <Icon
          size={70}
          source={icon.uri ? icon : images.icon}
          resizeMode="cover"
        />
      )
    } else {
      return (
        <View
          style={styles.leftComponentStyles(icon)}>
          <Text
            size="h5"
            color={icon}
            align="center"
            i18nKey={name?.slice(0, 2)}
          />
        </View>
      )
    }
  }

  const buttonProps = {
    backgroundColor: {
      true: colors.secondaryAccent,
      false: colors.buttonSecondaryAccent,
    },
    foregroundColor: {
      true: colors.invertedText,
      false: colors.buttonTertiaryAccent,
    },
    text: {
      true: 'receipt_button_confirm',
      false: 'receipt_button_print_receipt',
    },
    iconColor: {
      true: colors.tertiaryIcon,
      false: colors.secondaryIcon,
    },
  }

  const activateConfirm = () => {
    if (confirm) {
      printReceipt(
        refund1,
        cashBuyer,
        '',
        '',
        context?.nipt,
        context?.company,
        context?.location,
        customer,
        tempTranslate
      ).catch(() => {
        openErrorModal(
          'receipt_printer_error',
          'receipt_printer_error_label',
          'receipt_open_printers_button',
          () => {
            closeErrorModal()
            navigate('PrinterSetUp')
          },
          'checkout_button_skip',
          () => {
            closeErrorModal()
          },
          images.error
        )
        setConfirm(false)
      })
    } else {
      openConfigurationSelector(false, () => setConfirm(true))
    }
  }

  const mapResponse = (dataa) => {
    const res = []
    let section = null
    const data = dataa?.getRefund
    if (data) {
      setRefund(data)
      setCashBuyer(`${data?.amountMoney?.amount}`)
      const transactionData = data?.transaction
      setEmail(transactionData?.employee)
      setTime(transactionData?.createdAt)
      setAmount(INF.format(transactionData?.tender?.additionalAmountMoney?.amount))
      setCurrency(transactionData?.tender?.additionalAmountMoney?.currency)
      setReceiptNumber(transactionData?.receiptNumber)
      setRefundNumber(data?.receiptNumber)
      setQrCode(data?.qrCode)
      const shipping = data?.order?.shippingAddress || {}
      const bankAccount = data?.order?.bankAccount || {}
      const req2 = data?.order?.lineItems
      const customer1 = data?.customer
      let customerData = {}
      if (customer1) {
        const {
          issuerNUIS,
          businessName,
          givenName,
          familyName,
          reference,
          address: {
            addressLine1,
            country,
            administrativeDistrictLevel1,
          },
          type: customerType,
        } = customer1
        customerData = {
          address: `${addressLine1}, ${administrativeDistrictLevel1}, ${country}`,
        }
        if (customerType === 'INDIVIDUAL') {
          const { name: idName, value: idValue } = reference || {}
          customerData = { ...customerData, title: givenName + ' ' + familyName, idName, idValue }
        } else {
          customerData = { ...customerData, title: businessName, nipt: issuerNUIS }
        }
      }
      setCustomer(customerData)
      section = {
        id: 1,
        data: [],
      }
      res.push(section)
      res[res.length - 1]?.data.push(
        {
          id: 1,
          leftComponent: renderIconLeftComponent(),
          withIcon: true,
          title: 'refund_label',
          rightLabel: moment(data?.createdAt)
            .locale(LANGUAGES[lang])
            .format('DD/MM/YYYY hh:mm A'),
          rightTextStyle: {
            fontSize: 12,
            textAlign: 'right',
          },
        },
        {
          id: 2,
          leftText: 'receipt_total_method',
          rightText: translate(PAYMENT_METHOD[transactionData?.tender?.type]),
        },
        {
          id: 6,
          rightText: data?.reason,
          leftText: 'receipt_refund_reason',
        },
        {
          id: 3,
          rightText:
            INF.format(data?.amountMoney?.amount) +
            ' ' +
            data?.amountMoney?.currency,
          leftText: 'refund_amount_label',
        },
        {
          id: 5,
          rightText: data?.employee,
          leftText: 'receipt_user',
        },
        {
          id: 5,
          rightText: data?.receiptNumber,
          leftText: 'receipt_invoice_number',
        },
      )
      req2?.map((item) => {
        let subdescription = '',
          modifierLine = '',
          discountLine = ''
        const quantity = item.quantity || 0
        const baseAmount = INF.format(item.basePriceMoney?.amount) || 0
        const currencyBasePrice = item.basePriceMoney?.currency || 'Lekë'
        const variation = item?.variationName
        const modifier = item?.modifiers
        const discounts = item?.appliedDiscounts

        discounts?.map((el, ind) => {
          if (
            el.discountType === 'FIXED_PERCENTAGE' ||
            el.discountType === 'VARIABLE_PERCENTAGE'
          ) {
            if (ind === 0) {
              discountLine = `${el.name} (${el.percentage}%)`
            } else {
              discountLine = `${discountLine}\n${el.name} (${el.percentage}%)`
            }
          } else {
            if (ind === 0) {
              discountLine = `${el?.appliedMoney?.amount} ${el?.appliedMoney?.currency
                } ${translate('receipt_discount_line')}`
            } else {
              discountLine = `${discountLine}\n${el?.appliedMoney?.amount} ${el?.appliedMoney?.currency
                } ${translate('receipt_discount_line')}`
            }
          }
        })
        modifier?.map((element, i) => {
          if (i === 0) {
            modifierLine = `${element.name} (${element.appliedMoney?.amount + ' ' + element.appliedMoney.currency
              })`
          } else {
            modifierLine = `${modifierLine}\n${element.name} (${element.appliedMoney?.amount + ' ' + element.appliedMoney.currency
              })`
          }
        })
        if (variation !== null && modifier?.[0] && discounts?.[0]) {
          subdescription = `${variation}\n${modifierLine}\n${discountLine}`
        } else if (modifier?.[0] && variation !== null) {
          subdescription = `${variation}\n${modifierLine}`
        } else if (modifier?.[0] && discounts?.[0]) {
          subdescription = `${modifierLine}\n${discountLine}`
        } else if (discounts?.[0] && variation !== null) {
          subdescription = `${variation}\n${discountLine}`
        } else if (variation !== null) {
          subdescription = variation
        } else if (modifier?.[0]) {
          subdescription = modifierLine
        } else if (discounts?.[0]) {
          subdescription = discountLine
        } else {
          subdescription = ''
        }
        res[res.length - 1]?.data.push({
          id: item.id,
          rightOrderComponent: renderItemRightComponent(
            INF.format(item.totalMoney?.amount) + ' ' + item.totalMoney.currency,
          ),
          leftComponent: renderLeftComponent(
            item.imageUrl
              ? { uri: item.imageUrl }
              : item.labelColor || colors.purple,
            item.itemName,
          ),
          subDescText: subdescription,
          subDescriptionStyle: {
            fontSize: 12,
            lineHeight: 14,
          },
          withIcon: true,
          title: item.itemName,
          description: quantity + ' x ' + baseAmount + ' ' + currencyBasePrice,
          separator: true,
          titleContainerStyle: { paddingVertical: 5, width: 100 },
        })
      })

      if (Object.keys(customerData).length > 0) {
        section = {
          id: 5,
          sectionTitle: 'customer_checkout_label',
          data: [],
        }
        res.push(section)
        res[res.length - 1]?.data.push(
          {
            id: 1,
            leftText: 'categories_name_label',
            rightText: customerData.title,
          },
          {
            id: 5,
            leftText: 'demo_address_label',
            rightText: customerData.address,
            ratio: '1:2',
            rightTextProps: {
              numberOfLines: 1,
            },
          },
        )
        if (customerData.nipt) {
          res[res.length - 1]?.data.push(
            {
              id: 12,
              leftText: 'certificate_nipt_label',
              rightText: customerData.nipt,
            },
          )
        }
      }
      if (Object?.keys(shipping)?.length > 0) {
        section = {
          id: 6,
          sectionTitle: 'einvoice_details_delivery_tab',
          data: [],
        }
        res.push(section)
        if (shipping?.externalId) {
          res[res.length - 1]?.data.push(
            {
              id: 12,
              leftText: 'payment_delivery_receiver_label',
              rightText: shipping?.externalId,
            },
          )
        }
        res[res.length - 1]?.data.push(
          {
            id: 17,
            leftText: 'einvoice_delivery_address_header',
            rightText: `${shipping?.addressLine1}, ${shipping?.locality}, ${shipping?.country}`,
          },
        )
      }
      if (Object?.keys(bankAccount)?.length > 0) {
        section = {
          id: 7,
          sectionTitle: 'einvoice_payment_bank_account_label',
          data: [],
        }
        res.push(section)
        res[res.length - 1]?.data.push(
          {
            id: 11,
            leftText: 'payment_account_number_label',
            rightText: bankAccount?.holderName,
          },
          {
            id: 12,
            leftText: 'payment_iban_label',
            rightText: bankAccount?.iban,
          },
          {
            id: 13,
            leftText: 'bank_accounts_bank_name',
            rightText: bankAccount?.bankName,
          },
          {
            id: 14,
            leftText: 'bank_accounts_swift_code',
            rightText: bankAccount?.swiftCode,
          },
        )
      }
    }
    return res
  }

  useBackHandler(() => {
    if (type === 'goBack') {
      goBack()
    } else {
      navigate('Transactions')
    }
    return true
  })

  const shareReceipt = async () => {
    setShareLoading(true)
    const { data: qr } = await client.query({
      query: GET_QR,
      variables: {
        text: refund1.qrCode,
      },
    })
    openConfigurationSelector(true, () => {
      RNHTMLtoPDF.convert({
        html: convertRefund(
          refund1,
          cashBuyer,
          context?.nipt,
          context?.company,
          context?.location,
          customer,
          tempTranslate,
          qr
        ),
        fileName: 'fature_korrigjuese',
      })
        .then((res) => {
          Share.open({
            url: `file://${res.filePath}`,
            subject: 'Fatura korrigjuese ' + refundNumber,
            title: 'Fatura korrigjuese',
          })
            .catch(() => { })
          setShareLoading(false)
        })
        .catch(() => {
          setShareLoading(false)
        })
    })
    setShareLoading(false)
  }

  const { toPDF, targetRef } = usePDF({
    method: 'save',
    filename: `${receiptNumber}.pdf`,
    page: { margin: Margin.NONE },
  })
  const handlePrintWeb = useReactToPrint({
    content: () => targetRef.current,
    onBeforePrint: () => setLoadingPrintWeb(true),
    onAfterPrint: () => setLoadingPrintWeb(false),
  })


  const onHandlePrintWeb = () => {
    openConfigurationSelector(false, handlePrintWeb)
  }


  const handleDownload = async () => {
    setLoadingDownloadWeb(true)
    await toPDF()
    setLoadingDownloadWeb(false)
  }


  const onSyncTransaction = () => {
    setModal(prev => ({ ...prev, isVisible: false }))
    syncTransaction({
      variables: {
        locId: locationId,
        cashRegisterId: deviceId,
        id: refundId,
        reason: selectedReason?.value,
      },
    }).then(res => {
      retryBannerRef.current.closeBanner()
    }).catch(e => {
      openErrorModal(
        'certificate_error_label',
        'demo_error_description',
        'printers_button_close',
        () => {
          closeErrorModal()
        },
        '',
        null,
        images.error
      )
    })
  }

  return (
    <SafeAreaView style={styles.container}>
      <Header
        image={images.closeIcon}
        title={receiptNum || refundNumber}
        loading={loading1}
        onPress={() => {
          if (type === 'goBack') {
            goBack()
          } else {
            pop(4)
          }
        }}
      />
      <ScrollWrapperWeb>
        <SimpleList
          DATA={items}
          containerStyle={styles.list}
          onRefresh={() => refetch()}
          refreshing={false}
          separatorStyle={styles.separatorStyle}
          withSectionHeader
          sectionList
          listHeaderComponent={
            <>

              <View style={styles.inlineButtons}>
                <Button
                  title={Platform.OS === 'web' ? 'receipt_button_print_receipt' : buttonProps.text[confirm]}
                  icon={loadingPrintWeb ? null : images.printerActive}
                  iconStyle={{
                    tintColor: buttonProps.iconColor[confirm],
                  }}
                  disabled={loading || loadingPrintWeb || error}
                  style={styles.inlineButton1}
                  backgroundColor={buttonProps.backgroundColor[confirm]}
                  borderWidth={0}
                  borderColor={buttonProps.backgroundColor[confirm]}
                  color={buttonProps.foregroundColor[confirm]}
                  loader={loadingPrintWeb}
                  loaderComponent={<Loader source={animations.vfLoaderThinDark} size={24} />}

                  onPress={Platform.OS === 'web' ? () => onHandlePrintWeb() : () => activateConfirm()}
                />
                {Platform.OS === 'web' ?
                  <Button
                    icon={loadingDownloadWeb ? null : images.download}
                    iconColor={colors.secondaryIcon}
                    color={colors.secondaryAccent}
                    disabled={loadingDownloadWeb}
                    title="receipt_download_as_pdf"
                    titleStyle={{ color: colors.primaryText }}
                    backgroundColor={colors.white}
                    style={styles.inlineButton2}
                    loader={loadingDownloadWeb}
                    loaderComponent={<Loader source={animations.vfLoaderThinDark} size={24} />}
                    variant={loading || shareLoading ? 'disabled' : 'active'}
                    onPress={() => {
                      openConfigurationSelector(true, handleDownload)
                    }}
                  />
                  :
                  <Button
                    icon={shareLoading ? null : images.share}
                    iconColor={colors.secondaryIcon}
                    color={colors.primaryText}
                    title="orders_button_share_receipt"
                    backgroundColor={colors.white}
                    style={styles.inlineButton2}
                    onPress={Platform.OS === 'web' ? handlePrintWeb : () => shareReceipt().catch(() => { })}
                    loader={shareLoading}
                    loaderComponent={<Loader source={animations.vfLoaderThinDark} size={24} />}
                    variant={loading || shareLoading || error ? 'disabled' : 'active'}
                  />
                }
              </View>
              {loading1 ? null : <RetryBanner
                ref={retryBannerRef}
                onRetry={() => setModal(prev => ({ ...prev, isVisible: true }))}
                status={refund1?.status}
                code={refund1?.code}
                message={refund1?.message}
                loading={syncLoading}
              />
              }
            </>
          }
          listFooterComponent={
            <>
              {loading1 ? <ItemLoader /> : null}
              {loading1 ? (
                <>
                  <View style={styles.invValContainer1}>
                    <SingleLoaderContent />
                  </View>
                  <View style={styles.qrCode}>
                    <QRLoaderContent />
                  </View>
                </>
              ) : null}
              {Boolean(qrCode) && (
                <View style={styles.invValContainer}>
                  <Text
                    size="footnote"
                    style={styles.qrTitle}
                    i18nKey="receipt_section_invoice_validation"
                  />
                  <View style={styles.qrCode}>
                    <QRCode
                      value={qrCode}
                      size={80}
                      backgroundColor={colors.background}
                      color={colors.secondaryIcon}
                    />
                  </View>
                  <Button
                    icon={images.link}
                    iconStyle={styles.invoiceButton}
                    iconColor={colors.quartenaryIcon}
                    titleStyle={{ color: colors.primaryText }}
                    title="receipt_button_invoice_validation"
                    backgroundColor={colors.tertiaryBackground}
                    style={styles.fullButton}
                    borderColor={colors.transparent}
                    onPress={() => Linking.openURL(qrCode)}
                  />
                  <View>
                    <FiscItem
                      title="receipt_iic_label"
                      value={refund1?.IIC}
                      isFirst={true}
                    />
                    <FiscItem title="receipt_fic_label" value={refund1?.FIC} />
                    {refund1?.transaction?.location?.businUnitCode ? <FiscItem
                      title="cash_register_business_code"
                      value={refund1?.transaction?.location?.businUnitCode}
                    /> : null}
                    {refund1?.operatorCode ? <FiscItem
                      title="receipt_operator_code_label"
                      value={refund1?.operatorCode}
                    /> : null}
                    {refund1?.softwareCode ? <FiscItem
                      title="receipt_software_code_label"
                      value={refund1?.softwareCode}
                    /> : null}
                    {refund1?.referenceIIC ? <FiscItem
                      title="receipt_reference_iic_label"
                      value={refund1?.referenceIIC}
                    /> : null}
                    {refund1?.referenceFIC ? <FiscItem title="receipt_reference_fic_label"
                      value={refund1?.referenceFIC}
                      isLast={true} /> : null}
                  </View>
                </View>
              )}
              {receiptNumber ? <View style={styles.invValContainer1}>
                {loading1 ? (
                  <SingleLoaderContent />
                ) : (
                  <Text
                    size="footnote"
                    style={styles.qrTitle}
                    i18nKey="receipt_section_primary_invoice"
                  />
                )}
                {loading1 ? null : (
                  <Item
                    title={receiptNumber}
                    description={moment(time)
                      .locale(LANGUAGES[lang])
                      .format('DD/MM/YYYY hh:mm A')}
                    subDescText={email}
                    amount={`+${amount} ${currency}`}
                    type="transaction"
                    stylee={styles.topSpacing}
                    onPress={() => {
                      navigate('Receipt', {
                        id: id,
                        receiptNumber: receiptNumber,
                      })
                    }}
                  />
                )}
              </View> : null}
            </>
          }
        />
        {Platform.OS === 'web' && Object.keys(refund1).length > 0 &&
          <div style={styles.webStyles}>
            <ConvertRefund
              refund={refund1}
              cashMoney={cashBuyer}
              nipt={context?.nipt}
              company={context?.company}
              location={context?.location}
              customer={customer}
              ref={targetRef}
            />
          </div>
        }

      </ScrollWrapperWeb>
      <ErrorModal
        isVisible={errorModal.isVisible}
        icon={errorModal.icon}
        title={errorModal.title}
        description={errorModal.desc}
        primaryText={errorModal.primaryText}
        primaryAction={errorModal.primaryAction}
        secondaryText={errorModal.secondaryText}
        secondaryAction={errorModal.secondaryAction}
        onBackdropPress={() => closeErrorModal()}
        onBackButtonPress={() => closeErrorModal()}
        onClosePress={() => closeErrorModal()}
      />
      <BottomSheetModal
        isVisible={modal.isVisible}
        onClose={() => setModal(prev => ({ ...prev, isVisible: false }))}
      >
        <SimpleSelectType
          subtitle="receipt_retry_subtitle"
          data={FAIL_REASONS}
          Item={modal.Item}
          title="receipt_retry_title"
          placeholder="einvoice_doc_type_search_placeholder"
          selected={selectedReason?.id}
          select={obj => setSelectedReason(obj)}
          closeModal={() => setModal(prev => ({ ...prev, isVisible: false }))}
        />
        <Button
          title="receipt_retry_synchronize_button"
          style={styles.retryButton}
          loader={shareLoading}
          loaderComponent={<Loader source={animations.vfLoaderThinDark} size={24} />}
          variant={Object.keys(selectedReason).length > 0 ? 'active' : 'disabled'}
          onPress={onSyncTransaction}
        />
      </BottomSheetModal>
    </SafeAreaView>
  )
}
Refund.propTypes = {
  route: PropTypes.object,
  navigation: PropTypes.object,
}

export default Refund
