import Config from 'react-native-config'
import { Platform } from 'react-native'

// later we will find react-native-config => for web
// const url = Platform.OS === 'web' ? 'https://abrigvjfarhtrkllih6i3ae75i.appsync-api.eu-central-1.amazonaws.com/graphql' : Config.APPSYNC_API_URL
// prod
const url = Platform.OS === 'web' ? 'https://qfihfigcofaqdjmxhkcw6dicyi.appsync-api.eu-central-1.amazonaws.com/graphql' : Config.APPSYNC_API_URL

const appsyncConfig = {
  UserAgent: 'aws-amplify-cli/0.1.0',
  Version: '1.0',
  IdentityManager: {
    Default: {},
  },
  AppSync: {
    Default: {
      ApiUrl: url,
      Region: 'eu-central-1',
      AuthMode: 'AMAZON_COGNITO_USER_POOLS',
      ClientDatabasePrefix: 'Connect API V2_AMAZON_COGNITO_USER_POOLS',
    },
    'Connect API V2_API_KEY': {
      ApiUrl: url,
      Region: 'eu-central-1',
      AuthMode: 'API_KEY',
      ApiKey: Config.APPSYNC_API_KEY,
      ClientDatabasePrefix: 'Connect API V2_API_KEY',
    },
  },
}

export default appsyncConfig
