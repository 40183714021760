import React, { useContext, useState } from 'react'
import { SafeAreaView, View, TouchableOpacity, FlatList, ScrollView, Linking, KeyboardAvoidingView, Platform } from 'react-native'
import PropTypes from 'prop-types'
import { useTheme } from 'react-native-themed-styles'
import { useMutation } from '@apollo/client'

import {
    Header,
    Text,
    RadioInput,
    Icon,
    TextInput,
    Button,
    ErrorModal,
    Loader,
} from '../../Components'

import { getErrorMessage, useUserDetails } from '../../Utils'

import ContentManager from '../../Utils/ContentManager'

import images, { animations } from '../../Themes/Images'
import Theme from '../../Themes/Theme'

import themedStyles, { itemStyles } from './DeleteAccount.Styles'

import { REQUEST_DELETION } from './DeleteAccount.Schema'


const REASONS = {
    admin: [
        {
            code: 'closeBus',
            label: 'delete_account_reason_closed_bussiness',
        },
        {
            code: 'uselessApp',
            label: 'delete_account_reason_useless_app',
        },
        {
            code: 'other',
            label: 'delete_account_reason_other',
        },
    ],
    seller: [
        {
            code: 'closeBus',
            label: 'delete_account_reason_leave',
        },
        {
            code: 'uselessApp',
            label: 'delete_account_reason_useless_app',
        },
        {
            code: 'other',
            label: 'delete_account_reason_other',
        },
    ],
}

function Item({ label, selected, onPress, isFirst, isLast, code }) {
    const [styles] = useTheme(itemStyles)
    const { colors } = useContext(Theme)
    return (
        <View
            style={[
                { backgroundColor: colors.tertiaryBackground },
                styles['firstItem_' + isFirst],
                styles['lastItem_' + isLast],
            ]}>
            <TouchableOpacity onPress={onPress} style={styles.listInfoContainer}>
                <View style={styles.listDescContainer}>
                    <Text i18nKey={label} numberOfLines={1} />
                </View>
                <View style={styles.listPriceContainer}>
                    <View pointerEvents="none">
                        <RadioInput
                            index={code}
                            selected={selected?.code}
                            onPress={onPress}
                            style={styles.selector}
                            color={colors.secondaryAccent}
                        />
                    </View>
                </View>
            </TouchableOpacity>
        </View>
    )
}
Item.propTypes = {
    label: PropTypes.string,
    selected: PropTypes.string,
    code: PropTypes.string,
    onPress: PropTypes.func,
    isFirst: PropTypes.bool,
    isLast: PropTypes.bool,
}

function DeleteAccount({
    navigation: {
        goBack,
    },
}) {

    const [styles] = useTheme(themedStyles)
    const { colors } = useContext(Theme)
    const { role } = useUserDetails()

    const { translate } = ContentManager

    const [selected, setSelected] = useState('')
    const [note, setNote] = useState('')
    const [modal, setModal] = useState({
        isVisible: false,
        icon: images.warningIcon,
        title: '',
        desc: '',
        descPlaceholders: [],
        primaryText: '',
        primaryAction: null,
        tertiaryText: '',
        tertiaryAction: null,
    })
    const openModal = (
        title = 'opened_orders_error',
        desc = '',
        descPlaceholders = [],
        primaryText = '',
        primaryAction = null,
        tertiaryText = '',
        tertiaryAction = null,
        icon = images.warningIcon,
    ) => {
        setModal({
            isVisible: true,
            icon,
            title,
            desc,
            descPlaceholders,
            primaryText,
            primaryAction,
            tertiaryText,
            tertiaryAction,
        })
    }
    const closeModal = () => {
        setModal({
            isVisible: false,
            icon: images.delete,
            title: '',
            desc: '',
            primaryText: '',
            primaryAction: null,
            tertiaryText: '',
            tertiaryAction: null,
        })
    }

    const [deleteAccount, { loading }] = useMutation(REQUEST_DELETION, {
        onCompleted: () => {
            openModal(
                'delete_account_success_modal_title',
                'delete_account_success_modal_description',
                '',
                'cash_register_okay_button',
                () => closeModal(),
                'logout_button_cancel',
                () => closeModal(),
                images.accepted,
            )
        },
        onError: (err) => {
            openModal(
                'certificate_error_label',
                getErrorMessage(err),
                '',
                'cash_register_okay_button',
                () => closeModal(),
                'logout_button_cancel',
                undefined,
                images.error,
            )
        },
    })

    const onButtonPress = () => {
        openModal(
            'delete_account_modal_title',
            'delete_account_modal_description',
            '',
            'delete_account_modal_delete',
            () => {
                closeModal()
                deleteAccount({
                    variables: {
                        terminationReason: translate(selected?.label),
                        additionalReason: note,
                    },
                })
            },
            'logout_button_cancel',
            () => closeModal(),
            images.delete
        )
    }

    return (
        <>
            <SafeAreaView style={styles.wrapper}>
                <Header
                    title="settings_delete_account_label"
                    image={images.back}
                    onPress={() => goBack()}
                />
                <KeyboardAvoidingView
                    behavior={Platform.select({
                        ios: 'padding',
                        android: 'height',
                    })}
                    style={styles.wrapper}
                >
                    <ScrollView showsVerticalScrollIndicator={false} style={styles.container}>
                        <View style={styles.descriptionWrapper}>
                            <Text weight="bold" i18nKey="delete_account_title" style={styles.bottomSpacing} />
                            <Text i18nKey={`delete_account_description_${role.toLowerCase()}`} />
                            {role === 'Admin' && <><Text i18nKey="delete_account_contact_section" />
                                <View style={styles.contactWrapper}>
                                    <TouchableOpacity onPress={() => Linking.openURL('mailto:nakontaktoni@vodafone.com')}>
                                        <View style={styles.supportLabel}>
                                            <Icon size={20} source={images.mail} color={colors.black} style={styles.icon} />
                                            <Text i18nKey="nakontaktoni@vodafone.com" />
                                        </View>
                                    </TouchableOpacity>
                                    <TouchableOpacity
                                        onPress={() => Linking.openURL('https://api.whatsapp.com/send?phone=355699000149')}
                                    >
                                        <View style={styles.supportLabel}>
                                            <Icon size={18} source={images.whatsapp} color={colors.black} style={styles.icon} />
                                            <Text i18nKey="support_title" />
                                        </View>
                                    </TouchableOpacity>
                                </View></>}

                        </View>
                        <FlatList
                            showsVerticalScrollIndicator={false}
                            keyExtractor={(item, index) => 'row-' + index}
                            getItemLayout={(dataa, index) => ({
                                length: 70,
                                offset: 71 * index,
                                index,
                            })}
                            data={REASONS[role.toLowerCase()]}
                            style={styles.listStyle}
                            ListHeaderComponent={<Text style={styles.listHeader} i18nKey="delete_account_reason_title" />}
                            contentContainerStyle={styles.listContainerStyle}
                            renderItem={({ item, index }) => {
                                let isFirst, isLast
                                if (index === 0) {
                                    isFirst = true
                                }
                                if (index === REASONS[role.toLowerCase()].length - 1) {
                                    isLast = true
                                }
                                return (
                                    <Item
                                        {...item}
                                        selected={selected}
                                        isFirst={isFirst}
                                        isLast={isLast}
                                        onPress={() => setSelected(item)}
                                    />
                                )
                            }}

                            ItemSeparatorComponent={() => <View style={styles.separator} />}
                            refreshing={false}
                        />
                        <View>
                            <Text style={styles.descriptionLabel} i18nKey="delete_account_reason_description" />
                            <TextInput
                                placeholder="delete_account_reason_placeholder"
                                style={styles.input}
                                multiline={true}
                                numberOfLines={4}
                                value={note}
                                onChangeText={setNote}
                            />
                        </View>
                        <Button
                            loader={loading}
                            loaderComponent={<Loader source={animations.vfLoaderWhite} size={24} />}
                            variant={selected ? 'active' : 'disabled'}
                            title="delete_account_button_title"
                            style={styles.button}
                            onPress={onButtonPress}
                        />
                    </ScrollView>
                </KeyboardAvoidingView>

            </SafeAreaView>
            <ErrorModal
                isVisible={modal?.isVisible}
                title={modal?.title}
                icon={modal?.icon}
                description={modal?.desc}
                placeHolders={modal.descPlaceholders}
                primaryText={modal?.primaryText}
                tertiaryText={modal?.tertiaryText}
                primaryAction={modal?.primaryAction}
                tertiaryAction={modal?.tertiaryAction}
                onBackdropPress={() => closeModal()}
                onBackButtonPress={() => closeModal()}
                onClosePress={() => closeModal()}
            />
        </>
    )
}

DeleteAccount.propTypes = {
    navigation: PropTypes.object,
}

export default DeleteAccount
