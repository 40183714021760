import { Platform } from 'react-native'

import { styleSheetFactory } from '../../Themes/Colors'

const styles = styleSheetFactory(colors => ({
  listContainer: {
    position: 'relative',
    flexGrow: 1,
  },
  contentContainer: {
    borderRadius: 7,
    overflow: Platform.OS !== 'web' ? 'hidden' : 'auto',
    paddingHorizontal: 15,
  },
  separatorContainer: {
    height: 1,
    backgroundColor: colors.tertiaryBackground,
  },
  separator: {
    height: 1,
    flex: 1,
    backgroundColor: colors.separator,
  },
  fab: {
    position: 'absolute',
    bottom: 30,
    right: 15,
    height: 50,
    width: 50,
    borderRadius: 25,
    backgroundColor: colors.accent,
    justifyContent: 'center',
    alignItems: 'center',
    shadowColor: colors.black,
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.22,
    shadowRadius: 2.22,
    elevation: 3,
  },
  fabIcon: {
    transform: [{ rotate: '-90deg' }],
  },
  separatorSpacing: { marginLeft: 70 },
}))

export default styles
