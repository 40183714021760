/* eslint-disable no-unused-vars */
import React, { memo, useEffect, useRef, useState } from 'react'
import { Animated, ImageBackground, SafeAreaView, ScrollView, TouchableOpacity, View } from 'react-native'
import PropTypes from 'prop-types'
import { useTheme } from 'react-native-themed-styles'
import { useLazyQuery } from '@apollo/client'

import {
    DiscountLabel,
    Header,
    SwipeList,
    Text,
    TextInput,
    CheckBox,
} from '../../../Components'

import { images, colors } from '../../../Themes'
import ContentManager from '../../../Utils/ContentManager'

import { useConfig } from '../../../Utils'

import themedStyles, { itemStyles } from './FirstCatalogStep.Styles'
import { LIST_ITEMS } from './FirstCatalogStep.Schema'


const labelTypes = {
    items: {
        header: 'upload_items_header',
        note: 'upload_items_note_text',
    },
    categories: {
        header: 'upload_categories_header',
        note: 'upload_categories_note_text',
    },
    sales: {
        header: 'upload_sales_header',
        note: 'upload_sales_note_text',
    },
}
const Item = memo(({
    name,
    description,
    labelColor,
    itemVariationsCount,
    itemVariation,
    discountsCount,
    discount,
    imageUrl,
    onPress,
    checked,
}) => {
    const [styles] = useTheme(itemStyles)
    const anim = useRef(new Animated.Value(1)).current
    let price, currency, pricelabel

    const getItemPrice = (prices) => {
        let result
        if (prices.length === 1) {
            result = `${prices[0]?.amount} ${prices[0].currency}`
        } else {
            result = `${prices.length} ${ContentManager.translate('checkout_variable_price_label')}`
        }
        return result
    }

    if (itemVariationsCount === 1) {
        const priceMoney = itemVariation?.prices || 0
        if (priceMoney.length) {
            // price = INF.format(priceMoney?.amount)
            // currency = priceMoney?.currency
            pricelabel = getItemPrice(priceMoney)
        } else {
            pricelabel = 'checkout_variable_label'
        }
    } else if (itemVariationsCount === 0) {
        pricelabel = 'checkout_variable_label'
    } else {
        pricelabel =
            itemVariationsCount +
            ContentManager.translate('checkout_multiple_variations_label')
    }

    return (
        <Animated.View
            style={[
                styles.container,
                { transform: [{ scale: anim }] },
            ]}>
            <TouchableOpacity
                style={styles.touchable}
                onPress={onPress}
                // onPressIn={() => animate(1.05)}
                // onPressOut={() => animate(1)}
                activeOpacity={0.7}
                disabled={!onPress}>
                {imageUrl ? (
                    <ImageBackground
                        source={{ uri: imageUrl }}
                        style={styles.imageContainer(colors.white)}
                        resizeMode="cover"
                    />
                ) : (
                    <View style={styles.imageContainer(labelColor)}>
                        <Text
                            size={'h5'}
                            color={labelColor}
                            i18nKey={name
                                ?.match(/\b(\w{1})/g)
                                ?.slice(0, 2)
                                ?.join('')
                                ?.toUpperCase()}
                        />
                    </View>
                )}
                <View style={styles.infoContainer}>
                    <View style={styles.descContainer}>
                        <Text i18nKey={name} numberOfLines={1} />
                        <Text
                            i18nKey={description}
                            weight="light"
                            color={colors.secondaryText}
                            size="footnote"
                            numberOfLines={1}
                        />
                    </View>
                    <View style={styles.priceContainer}>
                        {pricelabel ? (
                            <Text i18nKey={pricelabel} />
                        ) : (
                            <Text
                                i18nKey="checkout_price_currency_value"
                                placeHolders={[price, currency]}
                            />
                        )}
                        <CheckBox
                            checked={checked}
                            tintColors={{
                                true: colors.secondaryAccent,
                                false: colors.placeholder,
                            }}
                            onValueChange={onPress}
                        />
                    </View>
                </View>

                <DiscountLabel discountsCount={discountsCount} discount={discount} />
            </TouchableOpacity>
        </Animated.View>
    )
})
Item.defaultProps = {
    labelColor: 'white',
}
Item.propTypes = {
    name: PropTypes.string,
    description: PropTypes.string,
    itemVariationsCount: PropTypes.number,
    itemVariation: PropTypes.shape({
        priceMoney: PropTypes.shape({
            amount: PropTypes.number,
            currency: PropTypes.string,
        }),
        prices: PropTypes.array,
    }),
    discountsCount: PropTypes.number,
    discount: PropTypes.object,
    imageUrl: PropTypes.string,
    onPress: PropTypes.func,
    checked: PropTypes.bool,
    labelColor: PropTypes.string,
}

function FirstCatalogStep({
    goBack,
    next,
    selectedBulk,
    setSelectedBulk,
    keepDuplicates,
    setKeepDuplicates,
    setLocationAddress,
}) {
  const { switchLocation: { deviceId, locationId } } = useConfig()

    const searchRef = useRef(null)
    const [searchText, setSearchText] = useState('')
    const [styles] = useTheme(themedStyles)
    const [items, setItems] = useState([])
    const [loadingList, setLoading] = useState(true)
    const [itemWidth, setItemWidth] = useState(0)
    const [selectedItems, setSelectedItems] = useState([])


    const [listItems, { loading, data, refetch, fetchMore }] = useLazyQuery(
        LIST_ITEMS,
        {
            fetchPolicy: 'network-only',
        }
    )

    const onSearch = (text) => {
        setSearchText(text)
        list(text)
    }

    //@TODO see this
    useEffect(() => {
        list(searchText)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const list = (text) => {
        listItems({
            variables: {
                locId: locationId,
                cashRegisterId: deviceId,
                limit: 20,
                filter: {
                    name: 'name',
                    value: text,
                },
            },
        })
    }

    const onEndReached = () => {
        if (!data?.listItems?.nextToken) {
            return
        }

        fetchMore({
            query: LIST_ITEMS,
            variables: {
                locId: locationId,
                cashRegisterId: deviceId,
                limit: 20,
                nextToken: data.listItems.nextToken,
                filter: {
                    name: 'name',
                    value: searchText,
                },
            },
        })
    }

    useEffect(() => {
        const itemsRes = data?.listItems?.items
        if (itemsRes) {
            setItems([...itemsRes])
            setLoading(false)
        }
    }, [data])


    const renderItem = ({ item, index }) => (
        <Item
            {...item}
            onPress={() => {
                selectedItems.includes(item) ? setSelectedItems((prev) => {
                    return prev.filter(object => object !== item)

                }) : setSelectedItems((prev) => {
                    return [...prev, item]
                })

            }}
            checked={selectedItems.includes(item)}
        />
    )

    return (
        <SafeAreaView>
            <Header
                image={images.close}
                title={labelTypes?.[selectedBulk]?.header}
                titlePlaceHolders={[1]}
                onPress={() => goBack()}
            />
            <ScrollView>
                <View pointerEvents="none" style={styles.inputContainer}>
                    <TextInput
                        ref={searchRef}
                        placeholder={'cart_search_placeholder'}
                        style={styles.searchInput}
                        placeholderTextColor={colors.placeholder}
                        value={searchText}
                        onChangeText={onSearch}
                    />
                </View>
                <SwipeList
                    searchValue={searchText}
                    // searchPlaceholder="Search For Categories..."
                    // onSearch={onSearch}
                    data={items}
                    renderItem={renderItem}
                    // renderHiddenItem={renderHiddenItem}
                    disableRightSwipe
                    disableLeftSwipe
                    // onRowClose={() => setNeedForConfirmation(true)}
                    setItemWidth={setItemWidth}
                    createNewLabel="item_button_create"
                    onEndReached={() => onEndReached()}
                    buttonVariant={'active'}
                    refreshing={loading}
                    onRefresh={() => refetch()}
                // containerStyle={isScannerVisible ? styles.withQR : styles.withoutQR}
                // ListEmptyComponent={!loadingList && <EmptyComponent navigate={navigate} />}
                />
            </ScrollView>
        </SafeAreaView>
    )
}

FirstCatalogStep.propTypes = {
    goBack: PropTypes.func,
    next: PropTypes.func,
    selectedBulk: PropTypes.string,
    setSelectedBulk: PropTypes.func,
    keepDuplicates: PropTypes.bool,
    setKeepDuplicates: PropTypes.func,
    setLocationAddress: PropTypes.func,
}

export default FirstCatalogStep
