import React from 'react'
import { View, TouchableOpacity, TouchableWithoutFeedback } from 'react-native'
import { useTheme } from 'react-native-themed-styles'
import PropTypes from 'prop-types'

import Text from '../Text'

import themedStyles from './SelectableItem.Styles'
import { colors } from '../../Themes'

function SelectableItem({ item: { code, disabled, description, descPlaceHolders , id } = {}, selected, onPress }) {
  const [styles] = useTheme(themedStyles)
  const isSelected = selected?.id === id


  return (
    <TouchableWithoutFeedback>
      <View style={styles.container}>
        <TouchableOpacity
          onPress={onPress}
          style={[
            styles['itemSelected_' + isSelected],
            styles['isDisabled_' + disabled],
          ]}
          disabled={disabled}
          activeOpacity={0.6}
        >
          <Text i18nKey={code} align="left" size="body" color={colors.primaryText} style={styles.itemCode} />
          {Boolean(description) && <>

            <Text
              i18nKey={description}
              placeHolders={descPlaceHolders}
              size="footnote"
              color={colors.inActiveText}
              numberOfLines={2}
              style={styles['itemTextSelected_' + isSelected]}
            />
          </>}
        </TouchableOpacity>
      </View>
    </TouchableWithoutFeedback>
  )
}
SelectableItem.propTypes = {
  item: PropTypes.shape({
    code: PropTypes.string,
    disabled: PropTypes.bool,
    description: PropTypes.string,
    descPlaceHolders: PropTypes.array,
  }),
  selected: PropTypes.object,
  onPress: PropTypes.func,
}

export default SelectableItem
