import React, { useContext } from 'react'
import { View } from 'react-native'
import RNModal from 'react-native-modal'
import PropTypes from 'prop-types'
import { useTheme } from 'react-native-themed-styles'

import themedStyles from './BottomSheetModal.Styles'

import Theme from '../../Themes/Theme'

function BottomSheetModal({
  onClose, style, contentContainerStyle, children, ...rest
}) {
  const [styles] = useTheme(themedStyles)
  const { colors } = useContext(Theme)

  return (
    <RNModal
      backdropColor={colors.primaryTextRGBA(0.7)}
      backdropOpacity={0.6}
      swipeDirection="down"
      backdropTransitionOutTiming={0}
      onBackButtonPress={() => onClose()}
      onBackdropPress={() => onClose()}
      onSwipeComplete={() => onClose()}
      hardwareAccelerated
      propagateSwipe
      hideModalContentWhileAnimating
      style={[styles.container, style]}
      avoidKeyboard
      {...rest}
    >
        <View style={[styles.contentContainerStyle, contentContainerStyle]}>
          {children}
        </View>
    </RNModal>
  )
}

BottomSheetModal.propTypes = {
  style: PropTypes.object,
  onClose: PropTypes.func,
  contentContainerStyle: PropTypes.object,
  children: PropTypes.any,
}

export default BottomSheetModal
