import { styleSheetFactory } from '../../Themes/Colors'

const styles = styleSheetFactory(colors => ({
  scene: {
    flex: 1,
    backgroundColor: colors.background,
  },
  tabContainerStyle: {
    backgroundColor: colors.tertiaryBackground,
    elevation: 0,
    shadowRadius: 0,
    shadowOpacity: 0,
    marginBottom: 0,
  },
  indicatorStyle: {
    backgroundColor: colors.tabAccent,
  },
  fullView: {
    flexGrow: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  tabView: {
    backgroundColor: colors.background,
    paddingTop: 80,
  },
  label: {
    fontFamily: 'Vodafone Rg',
    textTransform: 'none',
  },
  routePlaceholder: {
    flex: 1,
  },
}))

export default styles
