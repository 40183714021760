const al = {
  app_description: 'Për të aktivizuar shërbimin eBiznes, paraqituni në çdo dyqan Vodafone dhe regjistroni kredencialet e biznesit.',
  logout_key: 'Dil',
  logout_message: 'A jeni të sigurt që dëshironi të dilni?',
  logout_button_cancel: 'Anulo',
  support_title: '24/7 Suport',
  app_title: 'Fillo!',
  app_learn_more: 'Mëso më shumë.',
  app_account_title: 'Nuk keni një llogari?',

  //Menu items
  menu_checkout_label: 'Shitje',
  menu_items_label: 'Artikujt',
  menu_invoices_label: 'Faturat',
  menu_transactions_label: 'Transaksionet',
  menu_gov_label: 'Gabime fiskalizimi',
  menu_drawer_label: 'Arka',
  menu_users_label: 'Përdoruesit',
  menu_customers_label: 'Klientët',
  menu_partners_label: 'Partnerët',
  menu_analytics_label: 'Raporte',
  menu_settings_label: 'Konfigurimet',
  menu_reports_label: 'Raportet',
  menu_einvoice_label: 'Fatura elektronike',
  menu_supporting_invoice_label: 'Fatura shoqëruese',
  menu_bulk_upload_label: 'Ngarkim me shumicë',

  //Header titles
  header_settings: 'Konfigurimet',
  header_transactions: 'Transaksionet',
  header_gov_events: 'Gabime fiskalizimi',
  header_issue_refund: 'Kthe Shitje',
  header_printers: 'Printerat',
  header_taxes: 'Taksat',
  header_new_tax: 'Krijo taksë',
  header_new_user: 'Përdorues i ri',
  header_new_supplier: 'Furnizues i ri',
  header_users: 'Përdoruesit',
  header_user_location: 'Zgjidh vendodhje',
  header_items: 'Artikujt',
  header_cash_drawer: 'Arka',
  header_cash_drawer_history: 'Historia e arkës',
  header_cash_drawer_report: 'Raporti i arkës',
  header_current_drawer: 'Arka aktuale',
  header_end_drawer: 'Përfundo arkën',
  header_pay_in_out: 'Depozitim/Terheqje',
  header_cash_sales: 'Shitje me para në dorë',
  header_cash_refunds: 'Kthime me para në dorë',
  header_all_taxes: 'Të gjitha taksat',
  header_new_variation: 'Variacion i ri',
  header_edit_variation: 'Modifikim i variacionit',
  header_new_modifier: 'Përbërës të rinj ekstra',
  header_edit_modifier: 'Modifiko përbërësit',
  header_all_modifiers: 'Të gjithë përbërësit ekstra',
  header_new_discount: 'Ulje e re',
  header_edit_discount: 'Modifikim i uljes',
  header_discounts: 'Uljet',
  header_all_items: 'Të gjithë artikujt',
  header_categories: 'Kategoritë',
  header_new_category: 'Kategori e re',
  header_edit_category: 'Modifikim i kategorisë',
  header_new_item: 'Artikull i ri',
  header_edit_item: 'Modifikim i artikullit',
  header_sign_in: 'Hyr',
  header_new_unit: 'Njësi e re',
  header_update_unit: 'Modifikim i njësisë',
  header_locations: 'Vendet e ushtrimit të aktivitetit',
  header_online_store: 'Marketi Online',
  header_cash_register: 'Pajisjet elektronike të faturimit',
  header_new_register: 'Pajisje e re elektronike',
  header_request_demo: 'Kërkoni një demonstrim',
  header_add_employee: 'Shtoni punonjës',
  header_choose_cash_register: 'Zgjidhni një pajisje',
  header_analytics: 'Raporte',
  header_sound_vibration: 'Tingulli dhe dridhja',
  header_terms_conditions: 'Kushtet e përdorimit',
  header_privacy_policy: 'Deklarata e Privatësisë',
  header_customers: 'Klientët',
  header_new_customer: 'Klient i ri',
  header_new_group: 'Grup i ri',
  header_einvoice: 'Fatura elektronike',
  header_process_einvoice: 'Procesi (%1$s/%2$s)',
  header_buyer_seller_einvoice: 'Blerësi & Shitësi (%1$s/%2$s)',
  header_reference_einvoice: 'Referencat (%1$s/%2$s)',
  header_delivery_einvoice: 'Dërgesa (%1$s/%2$s)',
  header_notes_einvoice: 'Notes (%1$s/%2$s)', // added by erik
  header_preview_einvoice: 'Preview (%1$s/%2$s)',
  header_transport_supportive_invoice: 'Informacione Transporti (%1$s/%2$s)',
  header_issuerCarrier_supportive_invoice: 'Lëshuesi dhe Transportuesi (%1$s/%2$s)',
  header_supporting_invoice: 'Faturat shoqëruese',

  analytics_sales_label: 'Shitje',
  analytics_employees_label: 'Punonjës',
  analytics_calendar_placeholder: 'Kërko sipas datave...',

  //Transaction
  receipt_print_a4: 'Printo A4',
  receipt_print_58mm: 'Printo 58mm',
  orders_try_again: 'Provo përsëri',
  orders_not_found_title: 'Na vjen keq!',
  orders_not_found_description: 'Kërkuam të gjithë listën, por ky transaksion nuk gjendet.',
  orders_no_transactions_label: 'Nuk është bërë asnjë transaksion.',
  orders_no_refunds_label: 'Nuk është kthyer asnjë shitje.',
  orders_label: 'Shitjet',
  refunds_label: 'Kthimet',
  receipt_total_method: 'Mënyra e pagesës',

  choose_color_label: 'Zgjidh një ngjyrë',

  orders_calendar_placeholder: 'Kërko për kupon...',
  orders_options_title: 'Rendit',

  orders_button_go_back: 'Kthehu pas',
  orders_button_share_receipt: 'Shpërnda',
  orders_refund_items_title: 'Zgjidh të gjithë artikujt',
  orders_refund_items_description: 'Çmimi i artikullit përfshin taksat dhe uljet, kur ato aplikohen.',
  orders_refund_button_next: 'Tjetra',
  orders_refund_amount_description: 'Totali i kthyeshëm është %1$s Lekë.',
  orders_refund_amount_label: 'Shuma që do kthehet',
  orders_old_refund_amount_label: 'Shtoni shumën që doni kthehet',

  orders_getting_receipt_label: 'Duke marrë detajet e kuponit...',
  orders_error_label: 'Gabim',
  orders_printing_interrupted_label: 'Procesi i printimit u ndërpre.',
  orders_printing_label: 'Duke printuar...',
  orders_success_label: 'Sukses',

  online_orders_header: 'Porosi Online',
  online_orders_empty_list: 'Nuk u gjet asnjë porosi online',
  online_orders_active_item: 'Aktiv',
  online_orders_completed_item: 'Perfunduar',
  online_orders_new_item: 'E Re',
  online_orders_cancelled_item: 'Anulluar',
  online_orders_pickup_filter: 'Pick up',
  online_orders_curbside_filter: 'Curbside',
  online_orders_shipment_filter: 'Shipment',
  online_orders_delivery_filter: 'Dërgesë',
  online_orders_digital_filter: 'Dixhitale',
  online_orders_managed_filter: 'Dërgesë e menaxhuar',
  online_orders_search_placeholder: 'Kërko për porosi online...',

  refund_continue_to_refund: 'Vazhdo kthimin e shitjes',
  order_continue_to_order: 'Vazhdo me porosinë',


  receipt_printer_error: 'Nuk u lidh me printerin',
  receipt_printer_error_label: 'Lidhuni me një printer ose sigurohuni që të jetë i ndezur',
  receipt_total: 'Totali',
  receipt_subtotal: 'Nëntotali',
  receipt_section_total: 'Totali',
  receipt_tax: 'TVSH (%1$s%)',
  receipt_total_without_tax: 'Totali pa taksë',
  receipt_section_items_service: 'Artikujt dhe shërbimet',
  receipt_section_invoice_validation: 'Verifikimi i faturës',
  receipt_button_invoice_validation: 'Link për verifikim fature.',
  receipt_button_confirm: 'Konfirmo',
  receipt_button_print_receipt: 'Printo',
  receipt_download_as_pdf: 'Shkarko Pdf',
  receipt_cash_payment: 'Pagesë me para në dorë',
  receipt_noncash_payment: 'Pagesë pa para në dorë',
  receipt_cash: 'Para në dorë',
  receipt_user: 'Përdorues',
  receipt_invoice_number: 'Numër fature',
  receipt_invoice_ordinal_number: 'Numri rendor i faturës',
  receipt_label: 'Kupon',
  receipt_label_number: 'Numri i kuponit',
  receipt_button_issue_refund: 'Korrigjo',
  receipt_fic_label: 'NIVF',
  receipt_iic_label: 'NSLF',
  receipt_reference_fic_label: 'NIVF Prim',
  receipt_reference_iic_label: 'NSLF Prim',
  receipt_operator_code_label: 'Kod operatori',
  receipt_software_code_label: 'Kod softueri',
  receipt_business_code_label: 'Njësia e biznesit',
  receipt_section_primary_invoice: 'Fatura fillestare',
  receipt_refund_reason: 'Arsye kthimi',
  receipt_button_creating_refund: 'Duke kthyer shitje...',
  receipt_drawer_warning_title: 'Arka nuk është hapur ende',
  receipt_drawer_warning_description: 'Ju lutem hapni arkën për të vazhduar me kthimin e shitjes.',
  receipt_button_open_drawer: 'Hapni arkën',
  receipt_no_refund_items: 'Asnjë artikull për të kthyer',
  receipt_total_discount: 'Totali i uljeve të faturës',
  receipt_refund_success: 'Artikujt u kthyen me sukses!',
  receipt_open_printers_button: 'Shko tek konfigurimet e printerit',
  receipt_discount_line: 'zbritje',
  receipt_foreign_currency_label: 'Totali në %1$s',
  receipt_currency_rate_label: 'Kursi i këmbimit',
  receipt_retry_subtitle: 'Eventi nuk u sinkronizua sepse:',
  receipt_retry_title: 'Sinkronizo',

  receipt_retry_no_internet: 'Mungesë e lidhjes me internetin',
  receipt_retry_technical_problem: 'Probleme teknike',
  receipt_retry_general_problem: 'Probleme me shërbimin e fiskalizimit',
  receipt_retry_boundbook_problem: 'Probleme me librin e kufirit',
  receipt_retry_synchronize_button: 'Sinkronizo',
  invoice_status_success: 'Sukses',
  invoice_status_failed: 'Dështuar',
  invoice_synchronising_label: 'Duke u sinkronizuar...',

  refund_label: 'Kthim shitje',
  refund_amount_label: 'Shuma e rimbursuar',
  cash_sales_no_data: 'Asnjë shitje me para në dorë',
  cash_refunds_no_data: 'Asnjë kthim me para në dorë',
  cart_refunds_no_data: 'Asnjë kthim me kartë',
  cart_sales_no_data: 'Asnjë shitje me kartë',
  cart_payment_no_data: 'Asnje pagesë me kartë',
  search_employee_event_placeholder: 'Kërko me email punonjësi...',

  orders_refund_reason_return: 'Kthim malli',
  orders_refund_reason_accidental: 'Pagesë aksidentale',
  orders_refund_reason_cancel: 'Porosi e anuluar',
  orders_refund_reason_fraud: 'Pagesë jo e vertetë',
  orders_refund_reason_other: 'Shto arsye tjetër...',
  orders_refund_reason_button: 'Kthe shitje',

  drawer_current: 'Arka aktuale',
  drawer_history: 'Historia e arkës',
  drawer_calendar_placeholder: 'Kërko për arkë...',
  drawer_options_title: 'Rendit',
  drawer_actual_label: 'Gjendja e arkës',
  drawer_difference_label: 'Diferenca',
  drawer_description_label: 'Përshkrimi',
  drawer_current_details: 'Detajet e arkës aktuale',
  drawer_close_button: 'Mbyll arkën',
  drawer_confirm_close: 'Konfirmo mbylljen e arkës',
  drawer_end_button: 'Përfundo arkën',
  drawer_confirm_end: 'Konfirmo përfundimin e arkës',
  drawer_start_button: 'Nis',
  drawer_confirm_start: 'Konfirmo nisjen',
  drawer_retry_button: 'Riprovo',
  drawer_starting_amount_label: 'Para në nisje',
  drawer_start_drawer: 'Nis arkën',
  drawer_pay_inout_button: 'Depozitim/Tërheqje',
  drawer_description_placeholder: 'Përshkrimi i arkës',
  drawer_id_label: 'ID',
  drawer_started_label: 'Filluar',
  drawer_starting_date_label: 'Data e nisjes',
  drawer_started_by_label: 'Nisur nga',
  drawer_ended_label: 'Përfunduar',
  drawer_ended_by_label: 'Përfunduar nga',
  drawer_closed_label: 'Mbyllur',
  drawer_closed_by_label: 'Mbyllur nga',
  drawer_starting_cash_label: 'Gjendje fillestare parash',
  drawer_paid_inout_label: 'Depozituar/Tërhequr',
  drawer_cash_sales_label: 'Shitje me para në dorë',
  drawer_cash_refunds_label: 'Kthime me para në dorë',
  drawer_expected_label: 'Priten në arkë',
  drawer_total_paid_label: 'Total depozitimi/tërheqjeje',
  drawer_paid_out_label: 'Tërhequr',
  drawer_paid_in_label: 'Depozituar',
  drawer_processing_label: 'Duke procesuar...',
  drawer_error_label: 'Gabim %1$s',
  drawer_label_amount: 'Shuma',
  drawer_total_label: 'Totali',
  drawer_no_events_label: 'Asnjë event',
  drawer_button_confirm_payin: 'Konfirmo depozitim',
  drawer_button_confirm_payout: 'Konfirmo tërheqje',
  drawer_button_payout: 'Tërhiq',
  drawer_button_payin: 'Depozito',
  drawer_opened_route: 'Arka të hapura',
  drawer_ended_route: 'Arka të përfunduara',
  drawer_closed_route: 'Arka të mbyllura',
  drawer_no_paid_in: 'Asnjë depozitë',
  drawer_no_paid_out: 'Asnjë tërheqje',
  drawer_no_end_drawer: 'Asnjë arkë e përfunduar',
  drawer_no_open_drawer: 'Asnjë arkë e hapur',
  drawer_end_drawer_label: 'Arkë e përfunduar',
  drawer_open_drawer_label: 'Arkë e hapur',
  drawer_card_sales: 'Shitje me kartë',
  drawer_card_refunds: 'Kthime me kartë',
  drawer_pay_inout_credit_card_reason: 'Pagesa me kartë',
  drawer_card_payment: 'Pagesa me karte',

  drawer_open_success: 'Hapja e arkës u krye me sukses!',
  drawer_end_success: 'Përfundimi i arkës u krye me sukses!',
  drawer_close_success: 'Mbyllja e arkës u krye me sukses!',
  drawer_date_time_label: 'Data & Ora',
  drawer_pay_in_label: 'Depozitë',
  drawer_card_pay_in_label: 'Pagesë me kartë',
  drawer_pay_out_label: 'Tërheqje',
  drawer_pay_in_reason: 'Depozito në arkë',
  drawer_pay_out_reason: 'Tërhiq nga arka',
  drawer_pay_inout_payment_reason: 'Pagesë fature',
  drawer_pay_inout_number: 'Numri i mandatit',
  drawer_payment_button: 'Pagesë',

  drawer_default_description: 'Përshkrimi',
  drawer_default_amount: '0.00',

  drawer_options_day: 'Ditë',
  drawer_options_week: 'Javë',
  drawer_options_month: 'Muaj',
  drawer_not_found: 'Nuk u gjend asnjë arkë',

  drawer_currency_all: 'Lekë',
  drawer_currency_euro: 'Euro',
  drawer_currency_dollar: '$',

  drawer_continue_to_payment: 'Vazhdo me pagesën',
  drawer_bottom_disclaimer: 'Shitjet, rimbursimet dhe pagesat me kartë krediti nuk do të llogariten në shumën totale të arkës së hapur.',


  gov_calendar_placeholder: 'Kërko për ngjarje sipas datës...',
  gov_options_title: 'Rendit',
  gov_select_all_label: 'Zgjidhi të gjitha',
  gov_sync_button_title: 'Sinkronizo eventet',
  gov_tabs_title_drawer: 'Arka',
  gov_tabs_title_pay: 'Depozitë/Tërheqje',
  gov_tabs_title_order: 'Porosi',
  gov_swipe_hidden_message: 'Duke u sinkronizuar...',
  gov_drawer_title: 'Arkë',
  gov_pay_in_title: 'Depozita',
  gov_pay_out_title: 'Tërheqje',
  gov_order_title: 'Porosi',
  gov_processing_description: 'Duke procesuar...',
  gov_hidden_label: 'Sinkronizo',
  gov_technical_fail_reason: 'Problem teknik',
  gov_internet_fail_reason: 'Nuk ka lidhje interneti',
  gov_fisc_fail_reason: 'Probleme me shërbimin e fiskalizimit',
  gov_boundbook_fail_reson: 'Boundbook Problems',
  gov_no_failed_events: 'Asnjë event i pafiskalizuar.',
  gov_synchronize_header: 'Sinkronizo event',
  gov_reason_label: 'Eventi dështoi sepse:',
  gov_select_reason: 'Zgjidh një arsye',
  gov_button_synchronize: 'Sinkronizo',
  gov_button_synchronizing: 'Duke u sinkronizuar...',

  // Checkout
  checkout_screen_title: 'Shitje',
  checkout_title_tab_all: 'Të gjitha',
  checkout_price_currency_value: '%1$s %2$s',
  checkout_charge_price_currency: 'Faturo %1$s %2$s',
  checkout_VAT_percentage: 'TVSH (%1$s%):',
  checkount_recover_order_title: 'Porosi e papërfunduar',
  checkount_recover_order_description:
    'Porosia e fundit nuk është përfunduar.\nDëshiron të vazhdosh me këtë porosi?',
  checkount_recover_order_confirm: 'Po, vazhdo',
  checkount_recover_order_cancel: 'Jo, pastro porosinë',
  checkount_device_not_authorized_title: 'Pajisje e paautorizuar!',
  checkount_device_not_authorized_description: 'Pajisja e përdorur nuk është e regjistruar, që të mund të bëni shitje pajisja duhet të jetë e aktivizuar!',
  checkount_device_not_authorized_confirm: 'Më dërgo tek aktivizimi!',
  checkount_device_employee_authorization_title: 'Nuk jeni të autorizuar!',
  checkount_device_employee_authorization_description: 'Ju nuk jeni të autorizuar për të përdorur këtë pajisje. Ju lutem shtoni veten tuaj tek lista e punonjësve të autorizuar për këtë pajisje!',
  checkount_device_employee_authorization_confirm: 'Më jep akses!',
  checkount_device_not_authorized_cancel: 'Jo, nuk do shes!',
  checkout_new_release_title: 'Version i ri',
  checkout_new_release_description: 'Funksionalitete të reja po ju presin!',
  checkout_update_button: 'Përditësoni e-Biznes',
  checkout_skip_button: 'Më kujto më vonë',

  checkout_title_about_item: 'Rreth artikullit',
  checkout_title_choose_variation: 'Zgjidh variacion',
  checkout_title_add_modifiers: '%1$s (Zgjidh deri në %2$s)',
  checkout_title_add_discount: 'Shto ulje',
  checkout_title_select_discount: 'Zgjidh uljet (%1$s zgjedhur)',
  checkout_title_add_tax: 'Shto taksë',
  checkout_title_applied_tax: 'Taksa e aplikuar',
  checkout_button_add_to_basket: 'Shto në shportë',
  checkout_button_add_price: 'Shto çmim',
  checkout_button_select_variation: 'Zgjidh variacion',
  checkout_button_add_tax: 'Shto taksë',
  checkout_button_remove_item: 'Hiq nga shporta',
  checkout_button_create_item: 'Krijo artikull',
  checkout_no_items_description: 'Nuk gjendet asnjë artikull këtu',
  checkout_variable_item_title: 'Shto një artikull jo inventari',
  checkout_variable_item_description: 'Çmim sipas kërkesës',
  checkout_list_view_title: 'Pamje list',
  checkout_grid_view_title: 'Pamje rrjet',
  checkout_button_save_cart: 'Ruaj shportën',
  checkout_button_clear_cart: 'Boshatis shportën',
  checkout_variable_label: 'Variabël',
  checkout_variable_price_label: ' çmime',
  checkout_multiple_variations_label: ' variacione',
  checkout_noninventory_label: 'Shit një artikull të painventarizuar',
  checkout_payment_label: 'Pagesë %1$s',
  checkount_error_message: 'Diçka shkoi gabim',
  checkout_change_label: 'Kusur',
  checkout_button_pay: 'Paguaj',
  checkout_drawer_error_message: 'Ju lutem hapni arkën për të vazhduar me porosinë.',
  checkout_button_connect: 'Lidhu',
  checkout_button_skip: 'Anulo',
  checkout_print_title: 'Printo kupon',
  checkout_printing_description: 'Duke printuar kuponin',
  checkout_button_start_new: 'Krijo një porosi të re',
  checkout_discount_number: '%1$s Ulje',
  checkout_empty_message: 'Nuk ka asnjë artikull në monedhën %1$s.',
  checkout_change_currency_label: 'Boshatis shportën dhe ndrysho monedhë',
  checkout_change_currency_title: 'Ndrysho monedhë',
  checkout_currency_clear_description: {
    one: 'Ka një artikull në këtë shportë.\nNë mënyrë që të ndryshoni monedhën duhet të boshatisni shportën ekzistuese.',
    other: 'Ka {{count}} artikuj në këtë shportë.\nNë mënyrë që të ndryshoni monedhën duhet të boshatisni shportën ekzistuese.',
  },

  cart_search_placeholder: 'Kërko artikuj...',
  cart_screen_title: {
    zero: 'Shportë',
    one: 'Shportë (1 artikull)',
    other: 'Shportë ({{count}} artikuj)',
  },
  cart_product_price_details: '%1$s X %2$s %3$s',
  cart_subtotal_label: 'Nëntotal:',
  cart_total_discount_label: 'Zbritje totale:',
  cart_total_label: 'TOTALI:',
  cart_euro_total_label: 'Totali në Euro:',

  cart_clear_ttle: 'Boshatis shportën',
  cart_clear_description: {
    zero: 'Nuk ka asnjë artikull në shportë, të gjithë uljet për të do të pastrohen.\nJeni të sigurt që doni ti fshini?',
    one: 'Ka një artikull në këtë shportë.\nJeni të sigurt që doni ta boshatisni?',
    other: 'Ka {{count}} artikuj në këtë shportë.\nJeni të sigurt që doni ta boshatisni?',
  },
  cart_clear_confirm: 'Boshatis shportën',
  cart_clear_cancel: 'Anulo',

  opened_orders_title: {
    zero: 'Porosi të hapura',
    one: 'Porosi të hapura (1 porosi)',
    other: 'Porosi të hapura ({{count}} porosi)',
  },
  opened_orders_section_title: 'Porosi të hapura',
  opened_orders_clear_title: 'Kujdes!',
  opened_orders_clear_desc_0:
    'Keni një shportë të hapur me %1$s artikull në të.\nDoni ta ruani?',
  opened_orders_clear_desc_1:
    'Keni një shportë të hapur me %1$s artikuj në të.\nDoni ta ruani?',
  opened_orders_clear_save_first: 'Po, ruaj shportën aktuale',
  opened_orders_clear_override: 'Jo, mbivendos shportën aktuale',
  opened_orders_clear_cancel: 'Anulo',
  opened_orders_create_new: 'Krijo porosi të re',
  opened_orders_delete_confirm: 'Fshi',
  opened_orders_error: 'Gabim',
  opened_orders_save_success: 'Shporta aktuale u ruajt me sukses!',
  opened_orders_get_success: 'Porosia e zgjedhur u hap me sukses!',
  opened_orders_delete_success: 'Porosia u fshi me sukses!',
  opened_orders_continue: 'Vazhdo me porositë e hapura',
  order_transaction_label: 'Transaksioni i orderit',

  // ORDER DETAILS
  order_details_last_update: 'Rifreskimi i fundit: ',
  order_details_label: 'Detajet e Orderit',
  order_details_date_placed: 'Koha e krijimit',
  order_details_type: 'Tipi',
  order_details_price: 'Shuma',
  order_details_delivery_time: 'Koha e dorezimit',
  order_details_lineItems: 'Artikujt',
  order_details_total_details_label: 'Detajet e totalit',
  order_details_total_details_subtotal: 'Subtotali',
  order_details_total_details_total: 'Totali ',
  order_details_total_details_vat: 'VAT ',
  order_details_total_details_total_discount: 'Totali i zbritjeve',
  order_details_total_details_total_without_tax: 'Total pa taksa',
  order_costumer_label: 'Detajet e klientit',
  order_costumer_NIPT: 'NIPT',
  order_costumer_address: 'Adresa',
  order_costumer_name: 'Emri',
  order_costumer_country: 'Shteti',
  order_costumer_email: 'Email',
  order_costumer_phone: 'Numri telefonik',
  order_shipment_label: 'Adresa e dergimit',
  order_shipment_name: 'Emri',
  order_shipment_phone: 'Numri telefonik',
  order_shipment_address: 'Adresa',
  order_delivery_label: 'Detajet e dorezimit',
  order_delivery_carrier: 'transportuesi',
  order_invoice_label: 'Validimi i fatures',
  order_invoice_link: 'Linku per validimin e fatures',
  order_invoice_iix: 'IIC',
  order_invoice_fix: 'FIC',
  order_invoice_operator_code: 'Kodi operatorit: ',
  order_invoice_software: 'Kodi i softwarit: ',
  order_info_label: 'Informacion mbi porosinë',
  order_activity_label: 'Aktiviteti',
  order_activity_new: 'Porosi e re',
  order_activity_confirmed: 'Porosia u konfirmua',
  order_activity_in_progress: 'Porosia në progres',
  order_activity_proccessed: 'Porosia u procesua',
  order_activity_completed: 'Porosia u përfundua',
  order_activity_cancelled: 'Porosia u anulua',
  order_accept_label: 'Prano porosinë',
  order_in_progress_label: 'Shënoje si porosi aktive',
  order_complete_label: 'Shënoje si porosi e përfunduar',


  save_items_title: 'Ruaj artikujt në...',
  save_items_save_new: 'Ruaj në një porosi të re',
  save_items_existing_section_title: 'Ruaj artikujt në porositë ekzistuese',
  save_items_no_existing_orders: 'Nuk ka asnjë porosi ekzistuese',
  save_items_save_existing: 'Ruaj',
  save_items_saving: 'Duke ruajtur porosinë',
  save_items_retrieving_selected: 'Duke tërhequr porosinë e zgjedhur',
  save_items_processing: 'Duke procesuar',
  save_items_save_success: 'Shporta u ruajt me sukses!',
  save_items_continue: 'Vazhdo me ruajtjen e porosisë',
  save_non_invetory_item: 'Ruani në listën e artikujve',

  payment_price_currency: '%1$s %2$s',
  payment_amount_change: '%1$s %2$s kusur',
  payment_out_of_total: 'nga %1$s %2$s',
  payment_calculating_change: 'Duke llogaritur kusurin',
  payment_save_success: 'Shporta u ruajt me sukses!',

  order_status: 'Statusi porosisë',
  order_choose_status: 'Zgjidhni statusin',
  order_active_status: 'Aktiv',
  order_new_status: 'Sapoardhur',
  order_in_progress_status: 'Në progres',
  order_completed_status: 'Mbaruar',
  order_cancelled_status: 'Anuluar',
  choose_status_label: 'Zgjidhni statusin',

  payment_select_method: 'Zgjidh procesin e faturimit',
  payment_title_type_cash: 'Pagesë me para në dorë',
  payment_title_type_noncash: 'Pa para në dorë',
  payment_title_type_card: 'Pagesë me kartë bankare',
  payment_label_type_export: 'Faturë eksporti \n(Pa para në dorë)',
  payment_title_type_order: 'Pagesë me faturë përmbledhëse',
  payment_title_type_voucher: 'Pagesë me kupon',
  payment_title_type_check: 'Pagesë me çek bankar',
  payment_title_type_other: 'Mënyrë tjetër pagese',
  payment_label_type_cash: 'Para në dorë',
  payment_label_type_noncash: 'Faturë me bankë \n(Individ)',
  payment_label_type_card: 'Kartë',
  payment_label_type_order: 'Faturë',
  payment_label_type_voucher: 'Kupon',
  payment_label_type_einvoice: 'Faturë elektronike \n(Biznes)',
  payment_label_type_warehouse: 'Faturë shoqëruese',
  payment_label_type_reverse_charge: 'Auto faturë',
  payment_label_type_reverse_charge_label: 'Auto faturë/ngarkesë',
  payment_label_type_self_invoice: 'Vetë faturim',
  payment_label_type_other: 'Tjetër',
  payment_success_description: 'Fatura u krye me sukses!',
  payment_cash_helper: 'Shuma maksimale e pagesës për transaksion me para në dorë mes dy tatimpaguesve është 150,000 lekë',
  payment_fiscalizing_description: 'Duke fiskalizuar faturën...',
  payment_receipt_configuration_title: 'Zgjidhni konfigurimet e faturës',
  payment_receipt_size_header: 'Madhësia e faturës',
  payment_receipt_language_header: 'Gjuha e faturës',
  payment_receipt_language_note: 'Fatura do të printohet dhe shpërndahet në këtë gjuhë',
  payment_receipt_input_header: 'Formati i faturës',
  payment_delivery_information_section: 'Detajet e dërgesës',
  payment_delivery_receiver_label: 'Marrësi i dërgesës',
  payment_supplying_period_label: 'Periudha e furnizimit',

  payment_title_type_noncash_einvoice: 'Detajet e pagesës së faturës elektronike',
  payment_iban_label: 'IBAN',
  payment_account_number_label: 'Emri i mbajtësit të llogarisë',
  payment_bic_label: 'Kodi SWIFT',
  payment_description_label: 'Përshkrimi i pagesës',
  payment_proceed_button: 'Vazhdo',
  payment_total_method: 'Mënyra e pagesës',
  payment_supporting_invoice_title: 'Detajet e faturës',
  payment_total_header: 'Detajet e pagesës',
  payment_select_currency: 'Zgjidh monedhë tjetër',
  payment_currency_title: 'Monedha',
  payment_currency_placeholder: 'Kërko për monedhë',
  payment_euro_currency_label: '1 Euro = %1$s Lekë',
  payment_none_currency_label: 'Asnjë monedhë sekondare',
  payment_usd_currency_label: '1 USD = %1$s Lekë',
  payment_pound_currency_label: '1 GBP = %1$s Lekë',
  payment_none_currency_title: 'Asnjë',

  payment_select_vat_label: 'Zgjidh TVSH',
  payment_banknote_description: 'Kartëmonedha dhe monedha',
  payment_card_description: 'Kartë debiti dhe krediti',
  payment_check_description: 'Çek bankar',
  payment_voucher_description: 'Tollon me një qëllim',
  payment_company_description: 'Kartat e kompanisë dhe të ngjashme',
  payment_order_description: 'Fatura ende e papaguar. Do të paguhet me faturë përmbledhëse.',

  payment_account_description: 'Transaksion nga llogaria',
  payment_factoring_description: 'Faktoring',
  payment_compensation_description: 'Kompensim',
  payment_waiver_description: 'Fshirje e borxheve',
  payment_transfer_description: 'Transferim i të drejtave ose borxheve',
  payment_kind_description: 'Klering',
  payment_other_description: 'Të tjera pagesa pa para në dorë',
  payment_tax_free_description: 'Eksporti i shërbimeve',
  payment_exportof_goods_description: 'Eksporti i produkteve',

  payment_self_charge_type_label: 'Lloji',
  payment_self_charge_reason_label: 'Arsyeja e auto faturës',
  payment_self_charge_reason_placeholder: 'Arsyeja',
  payment_self_charge_reason_agreement: 'Marrëveshje e mëparshme mes palëve',
  payment_self_charge_reason_domestic: 'Blerje nga fermerët e zonës',
  payment_self_charge_reason_abroad: 'Blerje nga shërbimet jashtë vendit',
  payment_self_charge_reason_self: 'Vetë konsumi',
  payment_self_charge_reason_other: 'Të tjera',
  payment_self_charge_supplier_label: 'Furnizuesi',
  payment_self_charge_noncash_description: 'Faturë pa para në dorë',
  payment_self_charge_cash_description: 'Faturë me para në dorë',
  payment_self_charge_supplier_button: 'Zgjidh furnizuesin',
  payment_self_charge_action_invoice: 'Auto Faturë',
  payment_self_charge_charge: 'Auto Ngarkesë',
  payment_self_charge_payment_type: 'Lloji i pagesës së faturës',
  payment_exchange_rate_label: 'Kursi i këmbimit',


  einvoice_section_process: 'Procesi',
  einvoice_section_doc_info: 'Informacione mbi dokumentin',
  einvoice_section_delivery_info: 'Periudha e furnizimit',
  einvoice_process_type_label: 'Llojet e procesit',
  einvoice_doc_type_label: 'Llojet e dokumentit',
  einvoice_currency_label: 'Monedha',
  einvoice_payment_deadline_label: 'Afati i pagesës',
  einvoice_start_date_label: 'Data e fillimit',
  einvoice_end_date_label: 'Data e mbarimit',
  einvoice_effective_vat_label: 'Kodi i datës për TVSH-në efektive',
  einvoice_payment_button: 'Pagesa',
  einvoice_buyer_seller_button: 'Blerësi & Shitësi',
  einvoice_process_type_modal_header: 'Lloji i procesit',
  einvoice_process_type_search_placeholder: 'Kërko kodin për llojin e procesit',
  einvoice_doc_type_modal_header: 'Lloji i dokumentit',
  einvoice_doc_type_search_placeholder: 'Kërko kodin për llojin e dokumentit',
  einvoice_effective_vat_modal_header: 'Kodet e datave për TVSH-në efektive',
  einvoice_effective_vat_search_placeholder: 'Kërko kod date',
  einvoice_add_buyer_button: 'Shto blerës',
  einvoice_add_seller_button: 'Shto shitës',
  einvoice_references_button: 'Referencat',
  einvoice_city_postalcode_label: 'Qyteti dhe Kodi postar',
  einvoice_commercial_name_label: 'Emri tregtar',
  einvoice_references_buyer: 'Blerësi',
  einvoice_references_project: 'Projekti',
  einvoice_references_contract: 'Kontrata',
  einvoice_references_order_form: 'Formulari i porosise',
  einvoice_references_sales_order: 'Porosia e shitësit',
  einvoice_references_additional_docs: 'Dokumenti mbështetës',
  einvoice_references_docs_location: 'Vendodhja e dokumentit të jashtëm',
  einvoice_references_docs_description: 'Përshkrim i dokumentit mbështetës',
  einvoice_references_reference_header: 'Referencat',
  einvoice_references_document_header: 'Dokumente shtesë',
  einvoice_references_document_footer: 'Ngarko dokumentin shtesë',
  einvoice_references_buyer_button: 'Blerësi & Shitësi',
  einvoice_references_delivery_button: 'Dërgesa',
  einvoice_delivery_delivery_header: 'Dërgesa',
  einvoice_delivery_address_header: 'Adresa',
  einvoice_delivery_recipient_name: 'Emri i marrësit',
  einvoice_delivery_date: 'Data e dorëzimit',
  einvoice_delivery_street: 'Rruga',
  einvoice_delivery_city: 'Qyteti',
  einvoice_delivery_postal_code: 'Kodi Postar',
  einvoice_delivery_state: 'Shteti',
  einvoice_delivery_reference_button: 'Referencat',
  einvoice_delivery_preview_button: 'Preview',
  einvoice_delivery_notes_button: 'Shenimet',
  einvoice_document_label: 'Skedari',
  einvoice_process_preview_section: 'Informacione mbi procesin & dokumentin',
  einvoice_doc_type_preview: 'Lloji i dokumentit',
  einvoice_file_size_info: 'Madhësia maksimale e ngarkimit është 1 MB.',
  einvoice_preview_process_tab: 'Informacioni mbi procesin dhe dokumentin',
  einvoice_preview_buyerseller_tab: 'Shitësi & Blerësi',
  einvoice_preview_items_tab: 'Artikujt',
  einvoice_preview_references_tab: 'Referencat',
  einvoice_preview_delivery_tab: 'Dërgesa',
  einvoice_preview_payment_tab: 'Detaje per Pagesen',
  einvoice_preview_additionalDet_tab: 'Informacione shtesë',
  einvoice_preview_total_amount: 'Shumat totale neto të artikujve',
  einvoice_preview_total_withvat: 'Shuma totale me TVSH',
  einvoice_preview_total_vat: 'Shuma totale e TVSH-së',
  einvoice_preview_total_withoutvat: 'Shuma totale pa TVSH',
  einvoice_preview_total_paid: 'Shuma totale e mbetur për t\'u paguar',
  einvoice_date_note: 'Shënim: Datat duhet të jenë në të njëjtin muaj',
  einvoice_save_modal_title: 'Ruaj faturën',
  einvoice_save_modal_description: 'Ruaj këtë faturë për më vonë ose fshije dhe krijo një porosi të re.',
  einvoice_save_modal_button: 'Ruaj faturën',
  einvoice_delete_modal_button: 'Fshi faturën',
  einvoice_cancel_modal_button: 'Anulo',
  einvoice_retrieving_selected: 'Duke tërhequr faturën elektronike',
  einvoice_opcode_error_title: 'Kod operatori jo i saktë',
  einvoice_opcode_error_description: 'Vendosni saktë kodin e operatorit në mënyrë që të vazhdoni me procesin.',
  einvoice_opcode_error_button: 'Modifiko kodin e operatorit',
  einvoice_payment_bank_account_label: 'Llogaria bankare',
  einvoice_payment_seller_error_note: 'Diçka shkoi gabim! \n S\'mundëm të gjenim shitësin. Provoni përsëri për të vazhduar.',
  einvoice_payment_buyer_error_note: 'Diçka shkoi gabim! \n S\'mundëm të gjenim blerësin. Provoni përsëri për të vazhduar.',
  einvoice_payment_buyer_seller_error_note: 'Diçka shkoi gabim! \n S\'mundëm të gjenim blerësin dhe shitësin. Provoni përsëri për të vazhduar.',
  einvoice_print_button: 'Printo faturë',

  //bank accounts
  bank_accounts_add_button_label: 'Shto llogari bankare',
  bank_accounts_account_holder_name: 'Emri i mbajtësit të llogarisë',
  bank_accounts_bank_name: 'Emri i bankës',
  bank_accounts_create_page_label: 'Llogari e re bankare',
  bank_accounts_card_info_label: 'Informacione mbi kartën',
  bank_accounts_swift_code: 'Kodi i SWIFT / BIC',
  bank_accounts_card_iban_helper1: 'Formati i IBAN-it per Shqipërinë p.sh ',
  bank_accounts_card_iban_helper2: 'AL47123123411234567890123456.',
  bank_accounts_card_swift_helper1: 'Kodi SWIFT / BIC është i ndryshëm në çdo bankë',
  bank_accounts_card_credit_helper1: 'Ky veprim nuk ndikon në kreditin tuaj.',
  bank_accounts_swift_search: 'Kërko kodin swift',
  bank_accounts_bank_search: 'Kërko emrin e bankës',
  bank_accounts_empty_list_subtitle: 'Nuk u gjet asnjë llogari bankare.',
  bank_accounts_empty_list_title: 'Mirësevini në direktorinë tuaj të llogarive bankare',
  bank_accounts_note_description: 'Menaxhoni llogaritë bankare të biznesit tuaj',

  //supporting invoices
  supporting_invoice_invoice_type_label: 'Lloji i faturës',
  supporting_invoice_transaction_type_label: 'Lloji i transaksionit',
  supporting_invoice_invoice_type_search: 'Llojet e faturës',
  supporting_invoice_transaction_type_search: 'Llojet e transaksioneve',
  supporting_invoice_transport_ownership: 'Pronari i mjetit',
  supporting_invoice_transport_plate: 'Targa',
  supporting_invoice_vehicle_plate_helper_1: 'Formati i targës duhet të jetë ',
  supporting_invoice_vehicle_plate_helper_2: 'p.sh. AA123AA',
  supporting_invoice_transport_starting_point: 'Pika e nisjes',
  supporting_invoice_transport_destination_point: 'Pika e dorëzimit',
  supporting_invoice_transport_starting_address: 'Adresa e nisjes',
  supporting_invoice_transport_destination_date: 'Data e dorëzimit',
  supporting_invoice_transport_button: 'Informacione Transporti',
  supporting_invoice_transport_button_question: 'Jo',
  supporting_invoice_issuerCarrier_title: 'Lëshuesi & Transportuesi',
  supporting_invoice_transport_destination_address: 'Adresa e dorëzimit',
  supporting_invoice_issuerCarrier_header: 'Transportues',
  supporting_invoice_issuerCarrier_id_type: 'Tipi i ID-së',
  supporting_invoice_issuer_error_message: 'Diçka shkoi gabim! \n S\'mundëm të gjenim lëshuesin. Provoni përsëri për të vazhduar.',
  supporting_invoice_issuerCarrier_id_type_seach: 'Tipet e ID-ve',
  supporting_invoice_personal_id_helper_1: 'Formati i ID-së personale duhet të jetë ',
  supporting_invoice_personal_id_helper_2: 'p.sh. L12345678A',
  supporting_invoice_nipt_id_helper_1: 'Formati i NIPT-it duhet të jetë ',
  supporting_invoice_nipt_id_helper_2: 'p.sh. L12345678A',
  supporting_invoice_issuerCarrier_id_personal: 'ID Personale',
  supporting_invoice_flammable_item_question: 'A janë objektet të rrezikshme?',
  supporting_invoice_accompany_goods_questin: 'A është shoqërimi i mallit i domosdoshëm?',
  supporting_invoice_preview_dangerous: 'Të rrezikshme',
  supporting_invoice_preview_accompaniment: 'Të shoqëruara',
  supporting_invoice_item_descr: '%1$s %2$s',
  supporting_invoice_city_search: 'Kërko qytetin',
  supporting_invoice_transport_ownership_search: 'Kërko për pronarin e mjetit',
  supporting_invoice_transport_starting_search: 'Kërko për pikën e nisjes',
  supporting_invoice_transport_destination_search: 'Kërko për pikën e destinacionit',
  supporting_invoice_issuerCarrier_id_type_seach_label: 'Kërko llojin e ID-së',
  supporting_invoice_start_point_warehouse: 'Magazinë',
  supporting_invoice_start_point_exhibition: 'Ekspozitë',
  supporting_invoice_start_point_store: 'Dyqan',
  supporting_invoice_start_point_sale: 'Pika shitje',
  supporting_invoice_start_point_another: 'Magazina e një personi tjetër',
  supporting_invoice_start_point_customs: 'Magazina doganore',
  supporting_invoice_start_point_other: 'Tjetër',
  supporting_invoice_search_placeholder: 'Kërko për faturë shoqëruese...',
  supporting_invoice_list_empty_message: 'Asnjë faturë shoqëruese',
  supportive_invoice_exit_modal_description: 'Fatura juaj nuk do të ruhet nëqoftëse dilni tani. Jeni i sigurt që doni të dilni?',
  supportive_invoice_yes_option: 'PO',
  supportive_invoice_no_option: 'JO',
  supportive_invoice_information_intro: 'Informacion në lidhje me përdorimin për Lloji i Faturës/Lloji Transaksionit.',
  supportive_invoice_information_sales: 'Përdoret për transferimin e mallrave kur ndryshon pronësia, pra shiten. Për sektorin e karburanteve si dhe për sektorë të tjerë të ekonomisë. Përdoret edhe për eksportet, pra shoqërimin e mallrave deri në doganë.',
  supportive_invoice_information_examination: 'Përdoret për sektorin e karburanteve për rastet e verifikimit të pajisjeve dhe lëngut nga institucionet përgjegjëse të fushës (DPM). Për marrjen e mostrave të lëngut për verifikime.',
  supportive_invoice_information_transfer: 'Përdoret për transferimin e malllrave (pa  përcaktim sektori) kur nuk ndryshon  pronësia, p.sh, nga magazine në magazinë,  magazine pikë shitje, dyqan etj, dhe për  rastet kur transferojmë një mall, artikull,  object për riparim, të cilin e transferojmë  përkohësisht tek njësia e biznesit e një  tatimpaguesi tjetër.',
  supportive_invoice_information_door: 'Përdoret për transferimin e mallrave nga  magazine tek njësia e shitjes (automjeti) për  shitjet derë më derë.',

  process_type_p2_description: 'Faturimi periodik i dorëzimit të bazuar në kontratë',
  process_type_p10_description: 'Faturimi korrigjues',
  process_type_p1_description: 'Faturimi i furnizimit të mallrave dhe shërbimeve të porositura në bazë të kontratës',
  process_type_p3_description: 'Dorëzimi i faturimit mbi porosi të paparashikuara',
  process_type_p4_description: 'Pagesa paraprake',
  process_type_p5_description: 'Pagesa në vend',
  process_type_p6_description: 'Pagesa para dorëzimit bazuar në një porosi blerjeje',
  process_type_p7_description: 'Faturat në lidhje me një shënim transporti',
  process_type_p8_description: 'Faturat në lidhje me një shënim transporti dhe dëftese',
  process_type_p9_description: 'Miratimi ose faturimi negativ',
  process_type_p11_description: 'Faturim i pjesshëm dhe përfundimtar',
  process_type_p12_description: 'Vetëfaturim',

  document_type_384_description: 'Faturë korrigjuese',
  document_type_388_description: 'Faturë tatimore',
  document_type_82_description: 'Faturë për shërbimet e matura',
  document_type_325_description: 'Parafatura',
  document_type_326_description: 'Faturë e pjesshme',
  document_type_380_description: 'Faturë tregtare',
  document_type_381_description: 'Miratim',
  document_type_383_description: 'Debit',
  document_type_386_description: 'Faturë pagese paraprake',
  document_type_394_description: 'Faturë Lizingu',

  invoice_type_wtn_description: 'Faturë shoqëruese pa ndryshuar pronësinë',
  invoice_type_sale_description: 'Dokument transporti për shitje të karburanteve',

  transaction_type_sales_description: 'Lloji i rregullt i transaksionit të shitjeve për karburantet',
  transaction_type_examination_description: 'Dokument transporti për shitje të karburanteve',
  transaction_type_transfer_description: 'Lloji i transaksionit të transferimit',
  transaction_type_door_description: 'Mallrat transferohen për llojin e transaksionit të shitjeve derë më derë',

  transport_ownership_type_owner_description: 'Lëshuesi është pronar i automjetit',
  transport_ownership_type_third_party_description: 'Pala e tretë është pronar i automjetit',

  id_type_nuis_description: 'Numri i NIPT',
  id_type_id_description: 'Numri personal i identifikimit',

  date_codes_3_description: 'Data e lëshimit të faturës',
  date_codes_35_description: 'Data e dorëzimit aktual',
  date_codes_432_description: 'Data e pagesës',

  variable_item_tax_missing: 'Taksa nuk mund të aplikohej. Ju lutem, provoni përsëri më vonë. Nëse problemi përsëritet, na kontaktoni.',

  //Setting
  settings_tax_label: 'Taksat',
  settings_printers_label: 'Printerat',
  settings_tax_description: 'Vendos dhe modifiko taksat e tua',
  settings_printers_description: 'Shto dhe lidh printera',
  settings_language_label: 'Gjuhë',
  settings_sound_vibration_label: 'Tingulli dhe dridhja',
  settings_sound_vibration_description: 'Aktivizo/çaktivizo tingullin ose dridhjen',
  settings_language_description: 'Zgjidh gjuhën e dëshiruar',
  settings_changePassword_label: 'Fjalëkalimi',
  settings_changePassword_description: 'Ndrysho fjalëkalimin',
  settings_albanian_label: 'Shqip',
  settings_english_label: 'Anglisht',
  settings_locations_label: 'Vend i ushtrimit të aktivitetit',
  settings_locations_description: 'Shihni vendet e ushtrimit të aktivitetit',
  settings_online_store_label: 'Marketi Online',
  settings_online_store_description: 'Konfiguroni marketet tuaja online',
  settings_cash_register_label: 'Pajisjet elektronike të faturimit',
  settings_cash_register_description: 'Konfiguroni pajisjet tuaja',
  settings_terms_conditions_label: 'Kushtet e përdorimit',
  settings_terms_conditions_description: 'Lexo kushtet e përdorimit',
  settings_certificate_label: 'Çertifikata',
  settings_certificate_description: 'Konfiguro dhe regjistro çertifikatën tënde',
  settings_bank_accounts_label: 'Llogaritë bankare',
  settings_bank_accounts_description: 'Shto dhe menaxho llogaritë e tua bankare',
  settings_upload_files_description: 'Ngarko artikuj, zbritje dhe më shumë',
  settings_upload_files_label: 'Ngarko skedarë',
  settings_privacy_policy_label: 'Deklarata e Privatësise',
  settings_privacy_policy_description: 'Lexo deklaratën e privatësisë',
  settings_delete_account_label: 'Fshi llogarinë',
  settings_delete_account_description: 'Fshi llogarinë dhe të dhënat tuaja',
  password_succes_title: 'Fjalëkalimi u ndryshua me sukses!',
  password_succes_message: 'Fjalëkalimi juaj u ndryshua me sukses. Kyçuni me fjalëkalimin tuaj te ri',
  settings_business_code_label: 'Kodi i njësisë së biznesit',
  settings_business_code_label_description: 'Menaxho kodin e njësisë së biznesit.',
  // Location
  location_use_my_location: 'Përdor vendodhjen time',
  location_permission_request_message: 'Na duhet leja juaj për të përdorur vendodhjen.',
  location_permission_open_settings: 'Open settings',
  location_label_business_name: 'Emri i biznesit',
  location_label_vat_number: 'NIPT',
  location_label_location: 'Vend i ushtrimit të aktivitetit',
  location_label_email: 'Email',
  location_label_phone: 'Numri i telefonit',
  location_section_cash_register: 'Pajisjet elektronike të faturimit',
  location_no_cash_register: 'Nuk ka asnjë pajisje për këtë vendodhje',
  location_no_locations: 'Nuk u gjet asnjë vendodhje',
  location_note_helper: 'Kujdes në ndërrimin e vendodhjes pasi çdo veprim i bërë do pasqyrohet në atë vendodhje',
  location_change_button: 'Ndërro vendodhjen',
  location_search_placeholder: 'Kërko vendodhjen',
  location_info_tab_label: 'Informacion',
  location_info_catalog_title: 'Konfigurimi i Katalogut',
  location_info_catalog_description: 'Zgjidhni nëse dëshironi të përdorni Katalogun e Biznesit për këtë vend ushtrimi të aktivitetit. Nëse vendosni të mos e përdorni, mund të krijoni artikujt tuaj në këtë vend ushtrimi të aktivitetit',
  location_info_catalog_config_global: 'Katalogun e Biznesit',
  location_info_catalog_config_local: 'Katalogun e vendit të ushtrimit të aktivitetit',
  location_confrim_modal_title: 'Konfigurimi i katalogut',
  location_confrim_modal_desc: 'Po ndryshoni nga një katalog lokal në katalogun e biznesit. Artikujt do të jenë përditësuar në katalogun e biznesit version. Vendndodhja e artikujve nuk humbet.',

  location_business_about_location_header: 'Përreth vendndodhjes',
  location_business_note_1: 'SHËNIM: ',
  location_business_note_2: ' Kodi i njësisë së biznesit mund të ndryshohet vetëm kur të keni hequr të gjitha pajisjet që lidhen me këtë vendndodhje.',
  location_business_connected_devices: 'Pajisjet e lidhura',
  location_business_noncash_invoices: 'Fatura pa para në dorë',
  location_business_e_invoices: 'Fatura elektronike',
  location_business_button: 'Ndrysho kodin e njësisë së biznesit',

  edit_business_code_current_header: 'Konfirmoni kodin aktual të njësisë së biznesit që dëshironi të ndryshoni:',
  edit_business_code_new_header: 'Konfirmoni kodin e ri të njësisë së biznesit që dëshironi të shtoni:',
  edit_business_code_current_input: 'Kodi i vjetër i njësisë së biznesit',
  edit_business_code_new_input: 'Kodi i ri i njësisë së biznesit',
  edit_business_tcr_error: 'Kodi i njësisë së biznesit nuk është i saktë. Gjeni kodin aktual të njësisë së biznesit në informacionin e mësipërm.',
  edit_business_new_tcr_error: 'Formati i njësisë së biznesit nuk është i saktë. Formati i saktë është p.sh aa123aa123.',

  edit_business_redirect_devices: 'Më dërgo te pajisjet',
  //added by Erik
  edit_business_code_label: 'Kodi i Njësisë së Biznesit',
  edit_business_code_enter_prompt: 'Vendos kodin e njësisë së biznesit',
  edit_business_code_set: 'Ruaj kodin e njësisë së biznesit',


  // Taxes
  taxes_name_default_true: '%1$s (e parazgjedhur)',
  taxes_name_default_false: '%1$s',
  taxes_error_label: 'Ups!',
  taxes_info: 'Vlera e taksës së aplikuar ndaj një artikulli do të llogaritet automatikisht gjatë shitjes.',
  taxes_label_note: 'SHËNIM',
  taxes_tax_free_label: 'Përjashtuar nga taksa',
  taxes_tax_free_reason_section: 'Arsyeja e përjashtimit nga taksa',
  taxes_note: 'Taksa e parazgjedhur është e aplikuar automatikisht ndaj artikujve të rinj dhe atyre jo inventari.\nTë gjithë taksat e tjera të aktivizuara janë taksa dytësore dhe mund të zgjidhen në momentin e krijimit ose modifikimit të një artikulli.',
  taxes_change_default_button: 'Ndrysho taksën e parazgjedhur',
  taxes_change_default_info: 'Zgjidh taksën e re të parazgjedhur që dëshironi të aplikoni për biznesin tuaj.',
  taxes_change_default_note: 'Kini kujdes, taksa e parazgjedhur do të aplikohet për të gjithë artikujt tuaj të rinj dhe ato jo të inventarit. Kur ndryshoni taksën e parazgjedhur, do të jeni përgjegjës për shitjen e artikujve me këtë taksë të re.\nVini re se mund të zgjidhni vetëm nga taksa aktive, nëse taksa që dëshironi nuk është e listuar këtu, kthehuni dhe aktivizoni atë.',

  // Sound & Vibration
  sound_vibration_label_sound: 'Tingulli',
  sound_vibration_label_vibration: 'Dridhja',
  sound_vibration_info: 'Zgjidhni nëse doni të lajmëroheni me anë të tingullit dhe/ose dridhjes në momentin e skanimit të një barkodi ose kodi QR.',

  taxes_confirm_delete: 'Konfirmo fshirjen e taksës',
  taxes_not_found_label: 'Nuk ka taksë me këtë emër.',
  no_taxes_label: 'NUK KENI ASNJË TAKSË.',
  taxes_info_label: "Vlerat e taksës mund t'i aplikohen artikujve dhe do të llogariten automatikisht në blerje.",
  taxes_search_placeholder: 'Kërko për taksë...',
  taxes_create_button: 'Krijo taksë',
  taxes_deleting_notification: 'Duke fshirë taksën...',
  taxes_deletion_warning: 'Nuk mund të fshihet një taksë që i është aplikuar të paktën një artikulli.',
  taxes_select_label: 'Zgjidhni taksat që aplikohen për biznesin tuaj.',
  taxes_item_pricing_section: 'Vendos çmimet',
  tax_exists_label: 'Kjo taksë ekziston!',
  taxes_item_pricing_description: 'Këtu mund të zgjidhni nëse taksa shtohet mbi çmimin final apo është e përfshirë në çmim.',
  taxes_save_button: 'Ruaj',
  taxes_creating_tax_label: 'Duke krijuar taksë...',
  taxes_inclusion_type1: 'Shto taksën në çmimin e produktit',
  taxes_inclusion_type2: 'Përfshi taksën në çmimin e produktit',
  taxes_note_select: 'Shënim që mund të zgjidhni nëse taksa shtohet mbi çmimin final apo është e përfshirë në çmim.',

  //Printers
  printers_error_description: 'Printeri nuk lidhet',
  printers_receipt_error_description: 'Lidhu me printer për të printuar kuponin',
  printers_check_warning_message: 'Ju lutem kontrolloni printerin.',
  printers_button_create: 'Shto një printer',
  printers_button_search: 'Kërko për printer Bluetooth',
  printers_button_save: 'Ruaj',
  printers_buttoon_confirm_delete: 'Konfirmo fshirjen e printerit',
  printers_button_print_test: 'Testo printerin',
  printers_button_search_again: 'Kërko përsëri',
  printers_button_cancel: 'Anulo',
  printers_button_resume: 'Vazhdo',
  printers_button_discard: 'Mos ruaj',
  printers_button_close: 'Mbyll',
  printers_label_learn_more: '%1$sMëso më shumë',
  printers_label_more_supported: 'Mëso më shumë për printerat e suportuar.',
  printers_label_no_bluetooth: 'Nuk gjeni dot printerin tuaj?',
  printers_label_printer_info: 'Rifreskoni listën dhe nëse printeri i kërkuar nuk gjendet, sigurohuni që printeri është çiftëzuar nëpërmjet Bluetooth.',
  printers_label_no_selected: 'Asnjë printer i zgjedhur',
  printers_label_connect_later: 'Lidh printerin më vonë',
  printers_input_label_name: 'Emri i printerit',
  printers_label_printer: 'Printer',
  printers_label_select_printer: 'Zgjidh printer',
  printers_label_printer_setup: 'Konfiguro printer',
  printers_modal_resume_title: 'Ndryshime të paruajtura',
  printers_modal_resume_description: 'Doni të vazhdoni modifikimin apo ti shbëni këto ndryshime?',
  printers_modal_connection_title: 'Lidhja dështoi',
  printers_modal_connection_description: 'Kjo pajisje ose nuk është printer, ose është e fikur.',
  printers_modal_connecting: 'Duke u lidhur me printerin',
  printers_modal_search_title: 'Nuk gjetëm dot një printer Bluetooth.',
  printers_modal_search_description: 'Sigurohuni që pajisja është në gjendje çiftëzimi për Bluetooth.',
  printers_enabled_section: 'Aktivizuar',
  printers_disabled_section: 'Çaktivizuar',
  printers_instructions_first1: '1. Ndiz printerin',
  printers_instructions_first2: '',
  printers_instructions_second1: '2. Në pajisjen tënde, shko tek ',
  printers_instructions_second2: 'Konfigurimet > Bluetooth.',
  printers_instructions_third1: '3. Ndiz Bluetooth dhe  ',
  printers_instructions_third2: 'zgjidh printerin.',
  printers_instructions_fourth1: '4. Nëse ju kërkohet një PIN, vendosni ',
  printers_instructions_fourth2: '%1$s ose %2$s.',
  printers_instructions_fifth: '5. Rikthehu në këtë faqe.',
  printers_empty_message: 'Asnjë printer i regjistruar.',

  //Certificate
  certificate_header_title: 'Regjistro çertifikatë',
  certificate_button_register: 'Regjistro',
  certificate_button_update: 'Modifiko',
  certificate_update_header: 'Modifiko çertifikatë',
  certificate_or_label: 'ose',
  certificate_choose_label: 'Zgjidh skedar',
  certificate_file_format_info: 'Skedari duhet të jetë .p12 ose .pfx',
  certificate_password_placeholder: 'Fjalëkalim',
  certificate_company_information_section: 'Detaje të kompanisë',
  certificate_enter_information_label: 'Vendos detajet e kompanisë.',
  certificate_nipt_label: 'NIPT',
  certificate_id_label: 'ID personale',
  certificate_email_label: 'Email',
  certificate_phone_label: 'Telefon',
  certificate_upload_label: 'Ngarko çertifikatën',
  certificate_password_label: 'Fjalëkalimi i çertifikatës',
  certificate_button_next: 'Tjetra',
  certificate_confirmation_label: 'Konfirmim',
  certificate_confirmation_code_description: 'Ju kemi dërguar një mesazh me një kod 5-shifror',
  certificate_enter_code_label: 'Vendos kodin',
  certificate_resend_code_label: 'Ridërgo kodin',
  certificate_button_finish: 'Përfundo',
  certificate_congratulations_label: 'Urime',
  certificate_success_description: 'Çertifikata juaj u regjistrua me sukses.',
  certificate_button_done: 'Ruaj',
  certificate_error_label: 'Ups!',
  certificate_error_description: 'Diçka shkoi gabim. Provo përsëri për të regjistruar çertifikatën.',
  certificate_error2_description: 'Diçka shkoi gabim. Provo përsëri për të modifikuar çertifikatën.',
  certificate_button_try_again: 'Provo përsëri',
  certificate_organisation_label: 'Emri i organizatës',
  certificate_start_date_label: 'Data e fillimit',
  certificate_expiration_date_label: 'Data e skandencës',
  certificate_issuer_label: 'Lëshuesi',
  certificate_subject_label: 'Subjekti',
  certificate_renew_description: 'Ju mund ta rinovoni çertifikatën tuaj në çdo kohë.',
  certificate_upload_description: 'Ngarko çertifikatën për të filluar shitjet.',
  certificate_empty_description: 'Nuk është ngarkuar asnjë çertifikatë.',
  certificate_info_section: 'Informacion mbi çertifikatën',
  certificate_not_registered_title: 'Çertifikata nuk është regjistruar',
  certificate_not_valid_title: 'Çertifikata nuk është e vlefshme',
  certificate_go_button: 'Shko tek çertifikata',
  certificate_note_description: 'Për më shumë informacion mbi hapat kliko këtu.',
  certificate_upload_error: 'Ndodhi një gabim gjatë ngarkimit të këtij skedari.',
  certificate_incorrect_format_description: 'Skedari që po përpiqeni të ngarkoni nuk është ne formatin e duhur.',
  certificate_password_helper: 'Ky fjalëkalim ju është dërguar në emailin e çertifikatës.',

  // CANCEL ORDER
  cancel_order_title: 'Anulo porosinë',
  cancel_order_no_items_stock: 'Nuk ka artikuj në gjëndje',
  cancel_order_accidental: 'Porosi aksidentale',
  cancel_order_reason_label: 'Arsyeja për anulim',
  cancel_order_button_label: 'Anulo porosinë',

  //Users
  users_active_tag: 'Aktivë',
  users_inactive_tag: 'Joaktivë',
  users_pending_tag: 'Pezull',
  users_activate_button: 'Aktivizo',
  users_deactivate_button: 'Çaktivizo',
  users_delete_button: 'Fshi',
  users_confirm_activate: 'Konfirmo aktivizimin e përdoruesit',
  users_confirm_deactivate: 'Konfirmo çaktivizimin e përdoruesit',
  users_confirm_delete: 'Konfirmo fshirjen e përdoruesit',
  users_no_data: 'Nuk gjendet asnjë përdorues.',
  users_all_filter: 'Të gjithë',
  users_active_filter: 'Aktivë',
  users_inactive_filter: 'Joaktivë',
  users_pending_filter: 'Në pritje',
  users_search_placeholder: 'Kërko përdorues...',
  users_filter_title: 'Filtër',
  users_button_create: 'Krijo përdorues',
  users_choose_location_label: 'Zgjidh vendodhje',
  users_personal_information_section: 'Detaje personale',
  users_professional_information_section: 'Detaje profesionale',
  users_first_name_label: 'Emri',
  users_last_name_label: 'Mbiemri',
  users_email_label: 'Email',
  users_phone_label: 'Telefoni',
  users_role_label: 'Roli',
  users_seller_label: 'Shitës',
  users_location_label: 'Vend i ushtrimit të aktivitetit',
  users_assigned_to_label: 'Caktuar',
  users_status_label: 'Status',
  users_save_button: 'Ruaj',
  users_saving_button: 'Duke ruajtur...',
  users_existing_user_error: 'Ju keni një përdorues me këtë email',
  users_choose_avatar_title: 'Zgjidh avatar',
  users_choose_color_title: ' ose ngjyrë',
  users_add_cash_register: 'Shtoni pajisje',
  users_operatorcode_format_message: 'Formati i saktë i kodit të operatorit është p.sh. aa123aa123',
  users_email_usage_message: 'Kjo adresë emaili do të përdoret për marrjen e kredencialeve si dhe do të printohet në faturë kur të kryhet një shitje.',
  users_no_cash_register: 'Këtij përdoruesi nuk i është caktuar asnjë pajisje elektronike faturimi.',
  users_create_success: 'Përdoruesi u krijua me sukses!',
  users_update_success: 'Përdoruesi u përditësua me sukses!',
  users_web_access_label: 'Pajisja me akses në Web',
  users_web_access_placeholder: 'Kërko pajisjen me akses në Web',
  users_api_access_label: 'Pajisja me akses në API',
  users_api_access_placeholder: 'Kërko pajisjen me akses në API',
  users_resend_credentials_button: 'Ridërgo Kredencialet',
  users_resend_credentials_modal_title: 'Kredencialet u dërguan!',
  users_resend_credentials_modal_description: 'Kredencialet u dërguan në emailin e përdoruesit. Njoftoni përdoruesin të kontrollojë emailin e tij.',
  users_resend_credentials_description: 'Kredencialet do të dërgohen në emailin e përdoruesit.',

  users_profile_tab: 'Profili',
  users_analytics_tab: 'Raporte',
  users_acivity_tab: 'Aktiviteti',
  users_events_tab: 'Ngjarjet',

  users_sales_tag: 'Shitje',
  users_refund_tag: 'Kthime',
  OperatorCodeSelectLabel: 'ose zgjidhni në listën e mëposhtme.',
  OperatorCodeEnterPrompt: 'Vendos kodin e operatorit',
  OperatorCodeSet: 'Ruaj kodin e operatorit',
  CodeFormatIncorrect: 'Formati është i pasakt. Ju lutem provoni përsëri.',

  // Item
  modifiers_count: '%1$s përbërës ekstra',
  midifiers_search_placeholder: 'Kërko për përbërës ekstra...',
  modifiers_button_create: 'Krijo përbërës ekstra',
  modifiers_delete_error: 'Gabim në fshirje përbërës ekstra',
  modifiers_general_error: 'Gabim',
  modifiers_edit_header: 'Modifiko përbërësit',
  modifier_name_placeholder: 'Përbërës ekstra',
  modifier_set_name_placeholder: 'Emër përbërës ekstra',
  modifiers_button_new: 'Shto të ri',
  modifier_button_add: 'Shto përbërës ekstra',
  modifier_no_selected_message: 'Asnjë përbërës ekstra i zgjedhur',
  modifiers_warning_message: 'Duhet të shtoni të paktën një përbërës ekstra.',
  modifiers_attention_name_required: 'Emri është i detyrueshëm!',
  modifiers_update_success: 'Përbërësi ekstra u ruajt me sukses!',
  modifiers_add_success: 'Përbërësi ekstra u shtua me sukses!',
  modifiers_delete_success: 'Përbërësi ekstra u fshi me sukses!',

  categories_search_plaheholder: 'Kërko për kategori...',
  categories_button_create: 'Krijo kategori',
  categories_delete_error: 'Gabim gjatë fshirjes së kategorisë',
  categories_name_label: 'Emri',
  categories_name_placeholder: 'Emër kategorie',
  categories_attention_name_required: 'Emri është i detyrueshëm!',
  categories_update_success: 'Kategoria u ruajt me sukses!',
  categories_add_success: 'Kategoria u shtua me sukses!',
  categories_delete_success: 'Kategoria u fshi me sukses!',

  discount_amount: '%1$s %2$s',
  discount_percentage: '%1$s%',
  discount_search_placeholder: 'Kërko për ulje...',
  discount_button_create: 'Krijo ulje',
  discount_button_save: 'Ruaj',
  discount_delete_error: 'Gabim gjatë fshirjes së uljes',
  discount_edit_header: 'Modifiko uljen',
  discount_name_placeholder: 'Emër uljeje',
  discount_value_label: 'Vlera',
  discount_select_label: 'Zgjidh uljen',
  discount_button_create_new: 'Krijo ulje të re',
  discount_button_apply: 'Apliko',
  discount_button_add: 'Shto ulje',
  discount_no_selected_message: 'Asnjë ulje e zgjedhur',
  discount_error_message: 'Lejohen vetëm vlerat numerike më të mëdha se 0.',
  discount_attention_name_required: 'Emri është i detyrueshëm!',
  discount_update_success: 'Ulja u ruajt me sukses!',
  discount_add_success: 'Ulja ekstra u shtua me sukses!',
  discount_delete_success: 'Ulja ekstra u fshi me sukses!',
  discount_multiple_prices_label: {
    one: 'Ulje me 1 çmim',
    other: 'Ulje me {{count}} çmime',
  },

  unit_screen_name: 'Njësi',
  unit_abbreviation_label: 'Shkurtim',
  unit_search_placeholder: 'Kërko për njësi...',
  unit_precision_section: 'Precizioni',
  unit_button_create_custom: 'Krijo njësi të personalizuar',
  unit_new_cubic: 'Cubic inch (cu in)',
  unit_new_gram: 'Gram (g)',
  unit_button_create: 'Krijo njësi',
  unit_button_save: 'Ruaj',
  unit_name_abreviation: '%1$s (%2$s)',
  unit_general_error: 'Gabim',
  unit_attention_name_required: 'Emri është i detyrueshëm!',
  unit_attention_abreviation_required: 'Shkurtimi është i detyrueshëm!',
  unit_add_success: 'Njësia u shtua me sukses!',
  unit_update_success: 'Njësia u modifikua me sukses!',
  unit_delete_success: 'Njësia u fshi me sukses!',

  item_label_all: 'Të gjithë artikujt',
  item_label_categories: 'Kategoritë',
  item_label_modifiers: 'Përbërës ekstra',
  item_label_discounts: 'Uljet',
  item_label_units: 'Njësitë',
  item_button_create: 'Krijo artikull',
  item_delete_error: 'Gabim gjatë fshirjes së artikullit',
  item_button_save: 'Ruaj artikullin',
  item_info_tab: 'Detaje artikulli',
  item_in_stock_label: ' në magazinë',
  item_name_label: 'Emri artikullit',
  item_name_placeholder: 'Vendos emër artikulli',
  item_category_label: 'Kategori',
  item_choose_category_label: 'Zgjidh kategori',
  item_barcode_label: 'Barkod',
  item_price_inventory_section: 'Çmim',
  item_unit_type_label: 'Tip njësie',
  item_choose_unit_label: 'Njësia',
  item_price_label: 'Çmimi',
  item_sku_label: 'SKU',
  item_sku_placeholder: 'Asnjë',
  item_stock_label: 'Gjendje magazine',
  item_input_stock_label: ' në magazinë',
  item_variations_section: 'Variacione',
  item_vat_label: 'TVSH',
  item_choose_tax_label: 'Apliko taksat',
  item_description_placeholder: 'Përshkrim artikulli',
  item_button_add_variation: 'Shto variacion',
  item_attention_name_required: 'Emri është i detyrueshëm!',
  item_update_success: 'Artikulli u modifikua me sukses!',
  item_add_success: 'Artikulli u shtua me sukses!',
  item_delete_success: 'Artikulli u fshi me sukses!',
  item_prices_label: {
    other: '{{count}} çmime',
  },
  item_tax_type1_title: 'Përjashtim i llojit 1',
  item_tax_type2_title: 'Përjashtim i llojit 2',
  item_tax_type3_title: 'Shitje pa TVSH',
  item_tax_type4_title: 'Skema e Marzhit',
  item_tax_type1_description: 'Përjashtim në bazë të Nenit 51 të ligjit për TVSH-në.',
  item_tax_type2_description: 'Përjashtim në bazë të Neneve 53 dhe 54 të Ligjit për TVSH-në.',
  item_tax_type3_description: 'Shitjet pa TVSH që përjashtohen bazuar në ligjin e TVSH-së, përvec neneve 51, 53 dhe 54 të ligjit të TVSH-së, dhe nuk është skemë e marzhit dhe as e eksporti I mallrave.',
  item_tax_type4_description: 'Skema e TVSH-së së agjentëve të udhetimit, skema e TVSH-së për mallrat e dorës së dytë, skemës së TVSH-së së veprave të artit, artikujt, etj.',
  tax_exempt: 'Përjashto taksën',

  variation_delete_success: 'Variacioni u fshi me sukses!',
  item_dublicated_non_inventory_label: 'Emëri i artikullit ekziston!',
  item_dublicated_non_inventory_desc: 'Ekziston tashmë një artikull me këtë emër në katalogun tuaj. Ndryshoni emrin ose çaktivizoni opsionin e ruajtjes si një artikull inventar.',


  //Cash Register
  cash_register_note: 'Shënim',
  cash_register_note_description: 'Pajisja juaj duhet të konfigurohet dhe të lidhet me një kasë. Në këtë mënyrë, përdoruesi mund të hyjë në sistem dhe të vazhdojë aktivitetin e tij. Zgjidhni një pajisje për më shumë informacion.',
  cash_register_pending_label: 'Pezull',
  cash_register_active_label: 'Aktivë',
  cash_register_inactive_label: 'Joaktivë',
  cash_register_name_label: 'Emri i pajisjes',
  cash_register_deny_button: 'Moho aksesin',
  cash_register_new_note: 'Një ID TCR-je duhet të gjenerohet në mënyrë që pajisja elektronike e faturimit të regjistrohet dhe të funksionojë. Pasi të gjeneroni ID-në dhe pajisja të krijohet, veprimi nuk mund të kthehet më mbrapsht. ',
  cash_register_tcr_button: 'Gjeneroni një ID TCR-je',
  cash_register_tcr_label: 'TCR ID',
  cash_register_about_label: 'Detaje mbi pajisjen',
  cash_register_device_name: 'Emri i pajisjes',
  cash_register_device_type: 'Lloji i pajisjes',
  cash_register_device_model: 'Modeli i pajisjes',
  cash_register_operating_system: 'Sistemi i operimit',
  cash_register_device_id: 'ID-ja e pajisjes',
  cash_register_yes_button: 'Po',
  cash_register_modal_description: 'Nëse e fshini këtë pajisje, përdoruesit nuk do të kenë mundësi ta aksesojnë dhe ID-ja e TCR së saj do fshihet. Ky veprim nuk mund të kthehet mbrapsht. Jeni të sigurt që dëshironi ta fshini?',
  cash_register_modal_title: 'Duke fshirë pajisjen elektronike',
  cash_register_search_placeholder: 'Kërkoni pajisjen elektronike...',
  cash_register_employees_section: 'Punonjësit',
  cash_register_deactivate_button: 'Çaktivizoni pajisjen elektronike',
  cash_register_activate_button: 'Aktivizoni pajisjen elektronike',
  cash_register_delete_button: 'Fshijeni pajisjen elektronike',
  cash_register_remove_button: 'Hiqeni',
  cash_register_deleting_title: 'Pajisja elektronike po fshihet',
  cash_register_deactivate_message: 'Nëse çaktivizoni këtë pajisje, përdoruesit nuk do të kenë mundësi të hynë më në të ose të kryjnë shitje nëpërmjet saj. Jeni të sigurt që dëshironi ta çaktivizoni?',
  cash_register_activate_message: 'Nëse aktivizoni këtë pajisje, përdoruesit do të kenë mundësi të hynë dhe të vazhdojnë aktivitetin normal në të.',
  cash_register_add_employee: 'Shtoni punonjës',
  cash_register_empty: 'Asnjë pajisje elektronike faturimi',
  cash_register_tablet_label: 'Tablet',
  cash_register_mobile_label: 'Celular',
  cash_register_add_employee_message: 'Mbaj në këtë listë vetëm punonjësit që dëshiron të kenë akses në këtë pajisje.',
  cash_register_add_employee_list: 'Zgjidh punonjësit që dëshiron të kenë akses në këtë pajisje.',
  cash_register_deny_success: 'Aksesi i pajisjes u mohua me sukses!',
  cash_register_deny_error: 'Something wrong happened',
  cash_register_delete_success: 'Pajisja elektronike e faturimit u fshi me sukses!',
  cash_register_deactivate_success: 'Pajisja elektronike e faturimit u çaktivizua me sukses!',
  cash_register_deactivate_error: 'Couldn\'t deactivate cash register',
  cash_register_tcr_success: 'TCR ID u gjenerua me sukses!',
  cash_register_delete_error: 'Fshirja e punonjësit në këtë pajisje elektronike dështoi! Mund të provoni përsëri më vonë.',
  cash_register_add_error_0: 'Shtimi i %1$s punonjësve në këtë pajisje elektronike dështoi! Mund të provoni përsëri më vonë.',
  cash_register_add_error_1: 'Shtimi i %1$s punonjësi në këtë pajisje elektronike dështoi. Mund të provoni përsëri më vonë.',
  cash_register_okay_button: 'Në rregull',
  cash_register_business_code: 'Kodi i njësisë së biznesit',
  cash_register_business_code1: '*Ky informacion mund të merret në portalin',
  cash_register_business_code2: ' Self Care të Platformës Qëndore të Faturave ',
  cash_register_business_code3: 'në menunë ',
  cash_register_business_code4: 'Fiskalizim > Operatorët ',
  cash_register_business_code4_1: 'Fiskalizim > Vendet e Ushtrimit të Veprimtarisë së Biznesit ',
  cash_register_business_code5: ' (p.sh. aa123aa123)',
  cash_register_access_label: 'Aksesimi i pajisjes',
  cash_register_web_access_label: 'Akses nga web',
  cash_register_api_access_label: 'Akses nga api',
  cash_register_user_tab: 'Pajisjet',
  cash_register_current_device: 'Pajisja aktuale',
  cash_register_end_date: 'Data e skadimit të TCR',
  cash_register_cash_invoice_nr: 'Numri Faturave me para në dorë ',
  cash_register_noncash_invoice_nr: 'Numri Faturave pa para në dorë',
  cash_register_incorrect_tcr_label: 'Id-ja e TCR nuk është e saktë. Gjeni Id-në e duhur TCR më lart',
  cash_register_remove_tcr_header: 'Fshi Id-në e TCR',
  cash_register_remove_tcr_actual: 'Id-ja e TCR aktuale (pajisja elektronike)',
  cash_register_remove_tcr_users: 'Përdoruesit e lidhur',
  cash_register_remove_confirm_subheader: 'Konfirmo Id-në TCR që dëshironi të fshini:',
  cash_register_remove_confirm_delete: 'Je i sigurt që do ta fshish?',
  cash_register_remove_tcr_descr: 'Nëse hiqni ID-në TCR (pajisje elektronike), përdoruesit nuk do të mund të hyjnë më në këtë arkë dhe do të dalin nga llogaria. Transaksionet e mëparshme do të mbeten. Kini parasysh që ky veprim nuk mund të zhbëhet.',
  cash_register_remove_tcr_button: 'Fshi ID-në e TCR (Pajisja Elektronike)',
  cash_register_manage_busin_code: 'Menaxho kodin e njësisë së biznesit',
  cash_register_businCode_label: ' Kodi i Njësisë së biznesit',

  // Request demo
  demo_request_button: 'Kërkoni një demonstrim',
  demo_welcome_label: 'Mirëseerdhët',
  demo_welcome_description: 'Ky demonstrim do ju mësojë si të:',
  demo_explanation_1: 'Krijoni një porosi për blerësin tuaj.',
  demo_explanation_2: 'Listoni të gjithë produktet që shisni.',
  demo_explanation_3: 'Hapni, përfundoni dhe mbyllni arkën çdo ditë.',
  demo_explanation_4: 'Gjurmoni të gjitha shitjet dhe rimbursimet.',
  demo_explanation_5: 'Shtoni dhe kontrolloni punën e ekipit tuaj.',
  demo_explanation_6: 'Fiskalizoni një event dhe njoftoheni nëse dështon (dhe përse).',
  demo_explanation_7: 'Vendosni TVSH, ulje etj për produktet dhe porositë.',
  demo_explanation_8: 'Regjistroni dhe modifikoni çertifikatën e biznesit tuaj online.',
  demo_get_started_button: 'Filloni',
  demo_account_information_label: 'Informacione mbi llogarinë tuaj',
  demo_business_information: 'Informacione mbi biznesin',
  demo_business_information_label: 'Informacione mbi biznesin tuaj',
  demo_city_label: 'Qyteti',
  demo_company_name: 'Emri i kompanisë',
  demo_send_button: 'Dërgo',
  demo_address_label: 'Adresa',
  demo_pin_verification_title: 'Verifikimi i PIN-it',
  demo_pin_verification_description: 'Kemi dërguar PIN-in me 5 shifra në numrin %1$s',
  demo_success_message: 'Kërkesa juaj është dërguar me sukses.',
  demo_success_description: 'Brenda 24 orësh do ju dërgojmë një email me kredenciale dhe mund të aksesoni llogarinë tuaj në versionin demonstrativ.',
  demo_back_login_button: 'Kthehuni në hyrje të llogarisë',
  demo_error_description: 'Diçka nuk ka shkoi mirë. Provoni përsëri më vonë.',

  //Customers
  customers_label: 'Klientët',
  suppliers_label: 'Furnizuesit',
  customers_all_label: 'Të gjithë klientët',
  suppliers_all_label: 'Të gjithë furnizuesit',
  customers_groups_label: 'Grupet',
  customers_street_label: 'Rruga',
  customers_state_label: 'Shteti',
  customers_state_placeholder: 'Kërko për shtetin...',
  customers_other_information_label: 'Detaje të tjera',
  customers_birthdate_label: 'Ditëlindja',
  customers_group_label: 'Grupi',
  customer_consent_label: 'Preferenca të klientit',
  supplier_consent_label: 'Preferenca të furnizuesit',
  customer_email_receipt_label: 'Dërgo fatura me email.',
  customer_push_notifications_label: 'Push notification on mobile',
  customer_notifiy_new_label: 'Notifications for new things.',
  customer_customer_type_label: 'Tipi i klientit',
  customer_supplier_type_label: 'Tipi i furnizuesit',
  customer_new_button_label: 'Krijo klient të ri',
  suppliers_new_button_label: 'Krijo furnizues të ri',
  customer_search_placeholder: 'Kërko sipas NIPT, emrit ose numrit të telefonit...',
  customer_business_label: 'Biznes',
  customer_individ_label: 'Individ',
  customer_individual_label: 'Individ',
  customer_empty_list_title: 'Mirësevini në direktorinë tuaj të klientëve',
  customer_empty_suppliers_title: 'Mirësevini në direktorinë tuaj të furnizuesve',
  customer_empty_list_subtitle: 'Asnjë klient i regjistruar',
  customer_empty_suppliers_subtitle: 'Asnjë furnizues i regjistruar',
  customer_search_group_placeholder: 'Kërko grup...',
  customer_new_group_button: 'Krijo grup',
  customer_group_name_label: 'Emri i grupit',
  customer_group_type_label: 'Tipi i grupit',
  customer_new_group_description: 'Vendosni emra përshkrues për grupet, p.sh: NVM, Koorporata, etj.',
  customer_empty_groups_title: 'Mirësevini në grupet e klientëve tuaj',
  customer_empty_groups_subtitle: 'Asnjë grup i krijuar',
  customer_add_group_button: 'Shto grupe',
  customer_searching_data: 'Duke kërkuar për të dhëna...',
  customer_select_customer_address: 'Zgjidh adresë',
  customer_nipt_error: 'Ky NIPT nuk ekziston. Provo përsëri.',
  customer_checkout_label: 'Klienti',
  customer_passport_number_label: 'Numri i pasaportës',
  customer_vat_number_label: 'Numri i TVSH-së',
  customer_tax_number_label: 'Numri i tatimit',
  customer_soc_number_label: 'Numri i Sigurimeve Shoqërore (SSN)',
  customer_id_label: 'ID e klientit',
  supplier_id_label: 'ID e furnizuesit',
  customer_id_search_placeholder: 'Kërko për tipin e ID...',
  customer_business_group_label: 'Grup biznesi',
  customer_group_for_label: 'Grup për',
  customer_birdthday_helper_1: 'Formati i ditëlindjes duhet të jetë ',
  customer_birdthday_helper_2: 'e.g DD/MM/YYYY',
  customer_clear_address_label: 'Pastro adresën',

  //E-Invoice
  einvoice_search_placeholder: 'Kërko për faturë elektronike...',
  einvoice_create_button: 'Krijo faturë elektronike',
  einvoice_buyer_filter: 'Blerës',
  einvoice_seller_filter: 'Shitës',
  einvoice_delivered_filter: 'Dërguar',
  einvoice_in_progress_filter: 'Në progres',
  einvoice_accepted_filter: 'Pranuar',
  einvoice_refused_filter: 'Refuzuar',
  einvoice_registered_filter: 'Dërguar',
  einvoice_refunds_filter: 'Kthimet',
  einvoice_accept_button: 'Prano',
  einvoice_refuse_button: 'Refuzo',
  einvoice_list_empty_message: 'Asnjë faturë elektronike',
  einvoice_currency_all: '%1$s Lekë',
  DELIVERED: 'Dërguar',
  REFUSED: 'Refuzuar',
  ACCEPTED: 'Pranuar',
  BUYER: 'Blerës',
  SELLER: 'Shitës',
  REGISTERED: 'Dërguar',
  IN_PROGRESS: 'Në progres',
  CORRECTEDINVOICE: 'Faturë kthimi',
  einvoice_search_button: 'Kërko',
  einvoice_cancel_button: 'Anulo',
  einvoice_party_type_label: 'Pala lëshuese',
  einvoice_date_label: 'Data',
  einvoice_amount_label: 'Totali',
  einvoice_receipt_label: 'Fatura',
  einvoice_deadline_label: 'Afati i pagesës',
  einvoice_status_label: 'Statusi',
  einvoice_EIC_label: 'ID e faturës',
  einvoice_share_button: 'Shpërnda',
  einvoice_preview_button: 'Shfaq PDF',
  einvoice_confirm_button: 'Konfirmo',
  einvoice_confirm_accept_button: 'Konfirmo pranimin',
  einvoice_confirm_refuse_button: 'Konfirmo refuzimin',
  einvoice_my_invoices_tab: 'Faturat e mia',
  einvoice_all_invoices_tab: 'Të gjitha faturat',
  einvoice_buyertin_label: 'NIPT i blerësit',
  einvoice_sellertin_label: 'NIPT i shitësit',
  einvoice_details_info_tab: 'Informacione bazë',
  einvoice_details_buyerseller_tab: 'Blerësi & Shitësi',
  einvoice_details_process_tab: 'Procesi',
  einvoice_details_items_tab: 'Artikujt',
  einvoice_details_references_tab: 'Referencat',
  einvoice_details_refunds_tab: 'Faturat e kthimit',
  einvoice_details_delivery_tab: 'Dërgesa',
  einvoice_payment_details_tab: 'Detaje për Pagesen & Totali',
  einvoice_details_refund_button: 'Rimburso',
  einvoice_details_nipt_label: 'NIPT',
  einvoice_details_name_label: 'Emri',
  einvoice_details_address_label: 'Adresa',
  einvoice_details_city_label: 'Qyteti',
  einvoice_details_state_label: 'Shteti',
  taxable_amount_label: 'Vlera e tatueshme',
  einvoice_discount_amount_receipt_label: 'Vlera e uljes',
  einvoice_discount_base_receipt_label: 'Baza e uljes',
  einvoice_discount_percentage_receipt_label: '% e uljes',
  einvoice_discount_receipt_header: 'Ulje në nivel fature',
  einvoice_addition_receipt_header: 'Shtesë në nivel fature',
  tax_sum_label: 'Shumë e TVSH-së',

  einvoice_notes_label: 'Shënime',
  einvoice_note_button: '+ Shto shënime',
  einvoice_note_character_limit: 'Maksimum 250 karaktere.',






  //OnBoarding
  onboarding_setup_title: 'Le të fillojmë!',
  onboarding_setup_button: 'Vazhdojmë!',
  onboarding_logout_message_description: 'Jeni i sigurt që doni të dilni pa perfunduar?',
  onboarding_certificate_title: 'Ngarkoni çertifikatën e biznesit',
  onboarding_device_title: 'Konfiguroni pajisjen elektronike të faturimit',
  onboarding_operator_code_title: 'Vendosni kodin e operatorit',
  onboarding_operator_code_label: 'Vendosni kodin e operatorit',
  onboarding_back_button: 'Kthehu',
  onboarding_sales_declaration_title: 'Deklarimi i shitjeve bëhet automatikisht & në kohë reale!',
  onboarding_internet_title: 'Lidhja e pajisjes me internetin mundësuar nga Vodafone.',
  onboarding_reports_title: 'Raporte ditore dhe mujore të shitjeve.',
  onboarding_congatulations_title: 'Urime!',
  onboarding_account_ready: 'Llogaria juaj është gati',
  onboarding_motto: 'Së bashku ne mundemi!',
  onboarding_user_detail_label: 'Detaje mbi përdoruesin',
  onboarding_terms_condition_1: 'Pranoj ',
  onboarding_terms_condition_2: 'Kushtet e Përdorimit ',
  onboarding_terms_condition_3: 'të eBiznes',
  onboarding_permission_1: 'Pranoj ',
  onboarding_permission_2: 'Politikat e Privatësisë ',
  onboarding_permission_3: 'të eBiznes',
  onboarding_terms_privacy_label: 'Politikat e Kushteve\n dhe Privatësisë të eBiznes',
  onboarding_terms_privacy_header: 'Politikat e Përdorimit dhe Privatësisë',
  onboarding_terms_privacy_link: 'https://api-web-vfal.vodafone.com/file/eBiznes/Privatesise_eBiznes_AL.html',
  onboarding_permission_link: 'https://api-web-vfal.vodafone.com/file/eBiznes/Licence_Software_Agreement.html',
  onboarding_terms_condition_description: 'Për të vazhduar të përdorni shërbimin eBiznes, ju duhet të pranoni Termat & Kushtet dhe Politikat e Privatësisë. \nPër të lexuar detajet përkatëse, klikoni mbi linkun përbri.',
  onboarding_cert_warning_header: 'Çertifikata është duke skaduar',
  onboarding_cert_warning_title: 'Çertifikata juaj elektronike skadon së shpejti!',
  onboarding_cert_warning_desc_1: 'Çertifikata juaj elektronike skadon së shpejti. Për të siguruar fiskalizim te suksesshëm të faturave në vijim, duhet të rinovoni çertifikatën tuaj përmes një aplikimi të ri në e-Albania',
  onboarding_cert_warning_desc_2: 'Pasi të keni marrë çertifikaten e re në email:',
  onboarding_cert_warning_desc_3: '- Hyr në eBiznes\n- Shko në menunë Konfigurimet/Çertifikata\n- Kliko Modifiko Çertifikatë dhe Ngarko skedarin e çertifikatës së re',
  onboarding_cert_warning_skip: 'Hiqe për tani',

  //Reports
  reports_article_summary_title: 'Pëmbledhje \n e artikujve',
  reports_business_turnover_title: 'Rendimenti \n i biznesit',
  reports_analytical_sales_title: 'Analizë \n shitjesh',
  reports_locations_button_title: 'Zgjidhni njësinë e biznesit',
  reports_today_date_title: 'Sot',
  reports_week_date_title: 'Këtë javë',
  reports_month_date_title: 'Këtë muaj',
  reports_custom_date_title: 'E personalizuar',
  reports_report_type_title: 'Zgjidhni llojin e raportit',
  reports_business_location_title: {
    zero: 'Zgjidhni vendodhjen e biznesit',
    one: 'Zgjidhni vendodhjen e biznesit ({{count}} e zgjedhur)',
    other: 'Zgjidhni vendodhjen e biznesit ({{count}} te zgjedhura)',
  },
  reports_business_location_screen_counter: {
    one: '{{count}} vendodhje biznesi e zgjedhur',
    other: '{{count}} vendodhje biznesi te zgjedhura',
  },
  reports_date_title: 'Zgjidhni datën',
  reports_employees_filter_title: 'Filtroni punonjësit',
  reports_employees_select_title: {
    zero: 'Zgjidhni punonjësit',
    one: 'Zgjidhni punonjësit ({{count}} i zgjedhur)',
    other: 'Zgjidhni punonjësit ({{count}} të zgjedhur)',
  },
  reports_employees_select_screen_counter: {
    one: '{{count}} punonjës i zgjedhur',
    other: '{{count}} punonjës të zgjedhur',
  },
  reports_download_button: 'Shkarko',
  reports_generate_button: 'Gjenero raport',

  report_clear_filter_warning_title: 'Gjenero raport të ri',
  report_clear_filter_warning_description: 'Do humbisni raportin aktual dhe do krijoni një raport të ri',
  report_clear_filter_warning_yes: 'Po, krijo një raport të ri',
  reports_generated_section: 'Raportet e gjeneruara',
  reports_date_note: 'Datat e zgjedhura duhet të jenë brënda të njëjtit muaj',
  reports_filer_clear: 'Hiq filtrat',

  upload_type_note_description: 'Mund të importoni artikuj nga një skedar CSV nga pajisja juaj ose të importoni nga katalogu i përgjithshëm',
  upload_type_config_upload: 'Ngarko skedarin CSV',
  upload_type_config_import: 'Importo nga katalogu i përgjithshëm',
  upload_type_config_label: 'Importo nga',

  upload_items_header: 'Importo Artikujt (%1$s/2)',
  upload_categories_header: 'Importo Kategoritë (%1$s/2)',
  upload_sales_header: 'Importo Zbritjet (%1$s/2)',
  upload_items_note_text: 'Importoni artikuj nga skedari i Excel-it dhe sigurohuni të jenë në formatin e duhur.\n\nNëqoftëse nuk e keni formatin e duhur, shkarkojeni këtu dhe plotësojeni me artikujt tuaj.',
  upload_categories_note_text: 'Importoni kategoritë nga skedari i Excel-it dhe sigurohuni të jenë në formatin e duhur.\n\nNëqoftëse nuk e keni formatin e duhur, shkarkojeni këtu dhe plotësojeni me kategoritë tuaja.',
  upload_sales_note_text: 'Importoni zbritjet nga skedari i Excel-it dhe sigurohuni të jenë në formatin e duhur.\n\nNëqoftëse nuk e keni formatin e duhur, shkarkojeni këtu dhe plotësojeni me zbritjet tuaja.',
  upload_items_upload_file: 'Ngarko Skedarin',
  upload_items_upload_file_note: 'Skedari duhet të jetë .xlsx',
  upload_items_file_details: 'Detaje të skedarit',
  upload_items_uploading: 'Duke u ngarkuar...',
  upload_items_incorrect_format_label: 'Format i gabuar i skedarit!',
  upload_items_incorrect_format_message: ' Ka disa probleme që duhet t\'i rregulloni në skedarin tuaj.',
  upload_items_incorrect_format_message1: 'Provoni të korrigjoni skedarin tuaj dhe ngarkojeni përsëri',
  upload_items_empty_list: 'Aktualisht nuk ka asnjë artikull në listën tuaj të artikujve.',
  upload_items_empty_header: 'Importo Artikujt',
  upload_items_type: 'Zgjidh llojin e ngarkimit',
  upload_items_item_option: 'Artikuj',
  upload_items_categories_option: 'Kategori',
  upload_items_sales_option: 'Ulje',
  upload_items_continue_button_option: 'Vazhdo ngarkimin',
  upload_items_keep_duplicate_radio: 'Ruaji të gjithë artikujt',
  upload_items_replace_duplicate_radio: 'Zëvendëso artikujt ekzistues',
  upload_items_duplicate_desription: 'Një ose më shumë artikuj ekzistojnë tashmë në këtë vendndodhje. Dëshironi të zëvendësoni artikujt ekzistues me versione të reja apo t\'i mbani të dyja?',
  upload_items_replace_title: 'Opsionet e ngarkimit',
  upload_items_success_title: 'Importi u krye me sukses!',
  upload_items_cancel: 'Anulo importin',

  privacy_policy_link: 'https://api-web-vfal.vodafone.com/file/eBiznes/Privatesise_eBiznes_AL.html',

  delete_account_title: 'Fshi llogarinë time të eBiznes',
  delete_account_description_admin: 'Nëse kërkoni fshirjen e llogarisë, do të humbisni të dhënat e biznesit tuaj, historikun e transaksioneve, bashkë me të dhënat e punonjësve dhe partnerëve që keni regjistruar dhe nuk mund të logoheni më në aplikacion.\n\nFshirja e llogarisë, nuk ju heq detyrimin kontraktual të pagesave për këtë shërbim. Kjo kërkesë duhet të ndiqet me shkrim përmes agjentit tuaj të dedikuar ose në një nga dyqanet e Vodafone.\n\nPas marrjes së kësaj kërkese, do të udhëzoheni me një email për hapat e radhës.\n',
  delete_account_description_seller: 'Nëse kërkoni fshirjen e llogarisë, do të humbisni të historikun e transaksioneve dhe nuk mund të logoheni më në aplikacion.\n\nKjo kërkesë do t\'i shkojë llogarisë admin të eBiznes për subjektin tuaj.',
  delete_account_contact_section: 'Nëse hasni probleme që mund t\'ju ndihmojmë më tej, na kontaktoni në:',
  delete_account_reason_title: 'Na tregoni pse po e fshini llogarinë tuaj',
  delete_account_reason_description: 'Na tregoni më tepër',
  delete_account_reason_placeholder: 'Shkruaj këtu...',
  delete_account_button_title: 'Kërko fshirjen e llogarisë',
  delete_account_reason_closed_bussiness: 'Mbyllje e biznesit',
  delete_account_reason_useless_app: 'Nuk e gjej të dobishëm aplikacionin',
  delete_account_reason_other: 'Tjetër',
  delete_account_reason_leave: 'Largim nga puna',
  delete_account_modal_title: 'Kërko fshirjen',
  delete_account_modal_description: 'Jeni i sigurt që dëshironi të kërkoni fshirjen e llogarisë tuaj? \nPasi ta bëni këtë, do të njoftoheni në emailin tuaj për hapat e ardhshëm.',
  delete_account_modal_delete: 'Po, kërko fshirjen',
  delete_account_success_modal_title: 'Kërkesa e fshirjes u dërguar me sukses!',
  delete_account_success_modal_description: 'Kontrolloni emailin per hapat e ardhshëm.',

  product_catalog_title: 'Katalogu i Produkteve',
  product_catalog_catalog: 'Katalogu',
  product_catalog_general_business: 'Biznesit të përgjithshëm',
  product_catalog_general_note: 'Çdo artikull, kategori, zbritje dhe modifikues që shtoni në katalogun e përgjithshëm do të shfaqet në të gjitha vendet që e përdorin këtë si katalogun e tyre.',
  product_catalog_location_note: 'Çdo artikull, kategori, zbritje dhe modifikues që shtoni në këtë katalog do të shfaqet vetëm në këtë vend.',

  old_invoices_button: 'Faturat e vjetra',
  gov_invoices_header: 'Kërko faturën',
  gov_invoices_subheader: 'Kërko për fatura të mëparshme:',
  gov_invoices_invoice_date: 'Data e faturës',
  gov_invoices_invoice_total: 'Totali i faturës',
  gov_invoices_taxpayer_name: 'Emri i tatimpaguesit',
  gov_invoices_taxpayer_nipt: 'NIPT i tatimpaguesit',
  gov_invoices_not_found: 'Nuk u gjet asnjë faturë',


  //added by erik
  change_location_select_prompt: 'Zgjidh vendndodhjen',
  change_location_note: 'Shenim',
  change_location_note_text: 'Kini kujdes kur ndryshoni vendndodhjen sepse çdo operacion dhe ngjarje që bëni do të reflektojë në atë vendndodhje.',
  change_location_button_label: 'Tjetra',
  change_location_changing_text: 'Duke ndryshuar drejt ',


  change_devices_select_prompt: 'Zgjidh pajisjen elektronike',
  change_devices_note: 'Shenim',
  change_devices_note_text: 'Kini kujdes kur zgjidhni pajisjen elektronike sepse çdo operacion do të regjistrohet në atë pajisje.',
  change_devices_button_label: 'Ndrysho',
  change_devices_search_placeholder: 'Kerko pajisjen elektronike',



  /**
   * Backend error messages
   */
  GeneralError: 'Diçka shkoi keq. Ju lutem provoni përsëri më vonë.',
  DiscountGeneralError: 'Diçka shkoi keq. Zbritja nuk u aplikua për artikullin. Ju lutem provoni përsëri më vonë.',
  DiscountNameDuplicate: 'Këtë emër ulje e keni përdorur më parë. Ju lutem vendosni një emër të ri.',
  DiscountIsUsedByItems: 'Kjo ulje është aplikuar në të paktën një artikull, kështu që ju nuk mund ta fshini atë.',
  TaxGeneralError: 'Diçka shkoi keq. Veprimi mbi taksën nuk u krye. Ju lutem provoni përsëri më vonë.',
  TaxNameDuplicate: 'Këtë emër TVSH-je e keni përdorur më parë. Ju lutem vendosni një të ri.',
  TaxIsUsedByItems: 'Kjo taksë është aplikuar në të paktën një artikull, kështu që ju nuk mund ta fshini atë.',
  ModifierListGeneralError: 'Diçka shkoi keq. Veprimi mbi përbërësit ekstra nuk u krye. Ju lutem provoni përsëri më vonë.',
  ModifierListNameDuplicate: 'Këtë emër përbërësi ekstra e keni përdorur më parë. Ju lutem vendosni një emër të ri.',
  ModifierListIsUsedByItems: 'Ky përbërës ekstra nuk mund të fshihet pasi është aplikuar në të paktën një artikull.',
  MeasurementUnitGeneralError: 'Diçka shkoi keq. Veprimi mbi njësinë matëse nuk u krye. Ju lutem provoni përsëri më vonë.',
  MeasurementUnitNameDuplicate: 'Këtë emër për njësinë matëse e keni përdorur më parë. Ju lutem vendosni një të ri.',
  MeasurementUnitIsUsedByItems: 'Kjo njësi matëse nuk mund të fshihet pasi është aplikuar në të paktën një artikull.',
  CategoryGeneralError: 'Diçka shkoi keq. Veprimi mbi kategorinë nuk u krye. Ju lutem provoni përsëri më vonë.',
  CategoryNameDuplicate: 'Këtë emër kategorie e keni përdorur më parë. Ju lutem vendosni një emër të ri.',
  CategoryIsUsedByItems: 'Ju nuk mund të fshini një kategori që ka të paktën një artikull në të. Ju lutem hiqni të gjithë artikujt dhe më pas mund ta fshini kategorinë.',
  ItemGeneralError: 'Diçka shkoi keq. Veprimi mbi artikullin nuk u krye. Ju lutem provoni përsëri më vonë.',
  ItemNameDuplicate: 'Këtë emër artikulli e keni përdorur më parë. Ju lutem vendosni një të ri.',
  ItemBarcodeDuplicate: 'Ky barkod i përket një artikulli ekzistues. Ju lutem vendosni një barkod të ri për këtë artikull.',
  OrderGeneralError: 'Diçka shkoi keq. Veprimi mbi porosinë nuk u krye. Ju lutem provoni përsëri më vonë.',
  OrderInvalidMoneyAmount: 'Shuma monetare që keni vendosur nuk është e saktë. Ju lutem vendosni një shumë tjetër dhe provoni përsëri.',
  OrderNoOpenDrawer: 'Na vjen keq por nuk po arrijmë të gjejmë porosinë që kërkoni. Ju lutem provoni përsëri.',
  CashDrawerShiftGeneralError: 'Diçka nuk shkoi mirë. Veprimi mbi arkën nuk u krye. Ju lutem provoni përsëri.',
  CashDrawerShiftUnexpectedState: 'Kjo arkë është mbyllur tashmë, kështu që statusi i saj nuk mund të ndryshojë.',
  CashDrawerShiftAmountError: 'Shuma që keni vendosur nuk është e saktë. Ju lutem vendosni një shumë tjetër dhe provoni përsëri.',
  CashDrawerShiftUnauthorized: 'Ju nuk jeni të autorizuar për të kryer këtë veprim!',
  CashDrawerShiftEventGeneralError: 'Diçka nuk shkoi mirë. Veprimi mbi arkën nuk u krye. Ju lutem provoni përsëri.',
  CashDrawerShiftEventAmountError: 'Shuma që keni vendosur nuk është e saktë. Ju lutem vendosni një shumë tjetër dhe provoni përsëri.',
  TransactionGeneralError: 'Diçka nuk shkoi mirë. Veprimi mbi transaksionin nuk u krye. Ju lutem provoni përsëri.',
  RefundGeneralError: 'Diçka nuk shkoi mirë. Veprimi mbi rimbursimin nuk u krye. Ju lutem provoni përsëri.',
  RefundInvalidMoneyAmount: 'Shuma për rimbursim që keni vendosur nuk është e saktë. Ju lutem vendosni një shumë tjetër dhe provoni përsëri.',
  RefundNoOpenDrawer: 'Nuk po arrijmë të gjejmë porosinë që kërkoni të rimbursoni. Ju lutem provoni përsëri.',
  RefundIdempotentParameterMismatchException: 'Kërkesa juaj për të kryer këtë veprim është ekzekutuar tashmë. Nëse e ekzekutoni përsëri, do keni duplikim.',
  EmployeeUserAlreadyExist: 'Ky email është përdorur për një punonjës tjetër. Ju lutem vendosni një të ri.',
  EmployeeGeneralError: 'Diçka nuk shkoi mirë. Veprimi mbi punonjësit nuk u krye. Ju lutem provoni përsëri.',
  CashRegisterGeneralError: 'Diçka nuk shkoi mirë. Veprimi mbi pajisjen elektronike nuk u krye. Ju lutem provoni përsëri.',
  CashRegisterNameDuplicate: 'Këtë emër pajisjeje e keni përdorur më parë. Ju lutem vendosni një të ri.',
  CashRegisterTCRExist: 'Këtë TCR ID e keni përdorur më parë. Ju lutem gjeneroni një të re.',
  CashRegisterTCRGeneralError: 'Diçka shkoi gabim. Provoni përsëri të gjeneroni një TCR ID.',
  CashRegisterNotActive: 'Kjo pajisje elektronike nuk është aktive.',
  CashRegisterUnknown: 'Kjo pajisje nuk është e regjistruar.',
  CashRegisterNoAccessEmployee: 'Kësaj pajisjeje elektronike nuk i është caktuar asnjë përdorues (punonjës).',
  CertificateNotExist: 'Ju nuk keni një çertifikatë biznesi. Ngarko çertifikatën e biznesit për të vazhduar.',
  CertificateNotValid: 'Çertifikata e biznesit nuk është e vlefshme. Modifiko çertifikatën e biznesit për të vazhduar.',
  CustomerGeneralError: 'Diçka nuk shkoi mirë. Veprimi mbi klientin nuk u krye. Ju lutem provoni përsëri.',
  CustomerGroupGeneralError: 'Diçka nuk shkoi mirë. Veprimi mbi grupin nuk u krye. Ju lutem provoni përsëri.',
  CustomerGroupNameDuplicate: 'Këtë emër grupi e keni përdorur më parë. Ju lutem vendosni një të ri.',
  CustomerGroupIsUsedByCustomers: 'Ju nuk mund të fshini një grup që ka të paktën një klient në të. Hiqni të gjithë klientët dhe më pas mund ta fshini grupin.',
  CustomerIssuerNUISDuplicate: 'Një klient biznesi me këtë NIPT dhe vendndodhje është ruajtur më parë. Ju lutem vendosni një NIPT dhe një vendndodhje tjetër.',
  SupplierIssuerNUISDuplicate: 'Një furnizues biznesi me këtë NIPT dhe vendndodhje është ruajtur më parë. Ju lutem vendosni një NIPT dhe një vendndodhje tjetër.',
  OrderInvalidStatus: 'Statusi i vendosur nuk është valid.',

  MappingTemplate: 'Madhësia e imazhit që po ngarkoni është shumë e madhe.',

  DifferentNipts: 'Diçka nuk shkoi mirë gjatë ngarkimit të çertifikatës me këtë NIPT. Ju lutemi kontrolloni çertifikatën tuaj dhe provoni përsëri.',
  IncorrectPhoneNumberFormat: 'Numri nuk është i saktë! Ju lutem sigurohuni të keni vendosur numrin e telefonit të saktë në formatin 06XXXXXXXX.',
  PinLimitReached: 'Keni arritur limitin e numrit PIN që mund të vendosni. Provoni përsëri më vonë.',
  EmptyCertificate: 'Diçka nuk shkoi mirë gjatë ngarkimit të çertifikatës.Ju lutemi kontrolloni çertifikatën tuaj dhe provoni përsëri.',
  InvalidPinException: 'PIN-i që keni vendosur nuk është i saktë. Ju lutem provoni përsëri.',
  TaxVerifyTokenInvalid: 'PIN-i që keni vendosur nuk është i saktë. Ju lutem provoni përsëri.',
  InvalidNiptOrPersonalId: 'NIPT-i që keni vendosur nuk është i saktë. Ju lutem provoni përsëri.',
  WrongPassword: 'Fjalëkalimi i çertifikatës se biznesit që keni vendosur nuk është i saktë. Ju lutem provoni përsëri.',
  CouldNotSave: 'Diçka nuk shkoi mirë gjatë ngarkimit të çertifikatës. Ju lutem provoni përsëri.',
  InvalidCertificate: 'Diçka nuk shkoi mirë gjatë ngarkimit të çertifikatës. Ju lutem provoni përsëri.',
  InvalidPhoneNumber: 'Numri nuk është i saktë! Ju lutem sigurohuni të keni vendosur numrin e telefonit të saktë në formatin 06XXXXXXXX.',
  ChangeEinvoiceStatusRefusedError: 'Diçka nuk shkoi mirë gjatë procesit të refuzimit të faturës elektronike. Refuzimi i faturës nuk u kry. Ju lutem provoni përsëri.',
  ChangeEinvoiceStatusAcceptedError: 'Diçka nuk shkoi mirë gjatë procesit të pranimit të faturës elektronike. Pranimi i faturës nuk u kry. Ju lutem provoni përsëri.',
  EmployeeOperatorCodeInvalid: 'Kodi i operatorit nuk është i saktë. Ju lutemi, sigurohuni që keni vendosur saktë kodin e operatorit.',
  TaxpayerDoesNotExist: 'Diçka nuk shkoi mirë. Ju lutemi, sigurohuni që keni vendosur saktë kodin e operatorit.',

  FailedToRecreatePdf: 'Diçka nuk shkoi mirë. Ne po punojmë për të rregulluar problemin. Ju lutemi provoni përsëri më vonë.',
  FiscalTaxError: 'Diçka nuk shkoi mirë. Ne po punojmë për të rregulluar problemin. Ju lutemi provoni përsëri më vonë.',
  IncorrectInvoiceIdNumber: 'Diçka nuk shkoi mirë. Ne po punojmë për të rregulluar problemin. Ju lutemi provoni përsëri më vonë.',
  InvoiceIssueDateTimeReferenceInvalid: 'Diçka nuk shkoi mirë. Ne po punojmë për të rregulluar problemin. Ju lutemi provoni përsëri më vonë.',
  NegativeValuesOnlyInSpecialCases: 'Diçka nuk shkoi mirë. Ne po punojmë për të rregulluar problemin. Ju lutemi provoni përsëri më vonë.',
  SoftCodeMustExistToRegisterFirstTcr: 'Diçka nuk shkoi mirë. Ne po punojmë për të rregulluar problemin. Ju lutemi provoni përsëri më vonë.',
  MaintainerCodeMustExistToRegisterFirstTcr: 'Diçka nuk shkoi mirë. Ne po punojmë për të rregulluar problemin. Ju lutemi provoni përsëri më vonë.',
  TcrTypeMustExistToRegisterFirstTcr: 'Diçka nuk shkoi mirë. Ne po punojmë për të rregulluar problemin. Ju lutemi provoni përsëri më vonë.',
  InvalidPaymentMethod: 'Diçka nuk shkoi mirë. Ne po punojmë për të rregulluar problemin. Ju lutemi provoni përsëri më vonë.',
  InvalidTVSHValue: 'Diçka nuk shkoi mirë. Ne po punojmë për të rregulluar problemin. Ju lutemi provoni përsëri më vonë.',
  AddrFiledMustExistWhenSellerIsForeign: 'Diçka nuk shkoi mirë. Ne po punojmë për të rregulluar problemin. Ju lutemi provoni përsëri më vonë.',
  AllBuyerFieldsMustExistInSelfChargeInvoice: 'Diçka nuk shkoi mirë. Ne po punojmë për të rregulluar problemin. Ju lutemi provoni përsëri më vonë.',
  InvalidIssueDateTimeInvoiceFiled: 'Diçka nuk shkoi mirë. Ne po punojmë për të rregulluar problemin. Ju lutemi provoni përsëri më vonë.',
  InvalidAttributesForSupplyDate: 'Diçka nuk shkoi mirë. Ne po punojmë për të rregulluar problemin. Ju lutemi provoni përsëri më vonë.',
  TotalCostAndMethodPaymentDontCorrespond: 'Diçka nuk shkoi mirë. Ne po punojmë për të rregulluar problemin. Ju lutemi provoni përsëri më vonë.',
  EinvoiceMustBeOfTypeNonCash: 'Diçka nuk shkoi mirë. Ne po punojmë për të rregulluar problemin. Ju lutemi provoni përsëri më vonë.',
  RevokedIicMustNoteBeTheSameAsActualIic: 'Diçka nuk shkoi mirë. Ne po punojmë për të rregulluar problemin. Ju lutemi provoni përsëri më vonë.',
  ThirdPartyDeclarationForVehOwner: 'Diçka nuk shkoi mirë. Ne po punojmë për të rregulluar problemin. Ju lutemi provoni përsëri më vonë.',
  CashDepositInvalidChangeDateTime: 'Diçka nuk shkoi mirë. Ne po punojmë për të rregulluar problemin. Ju lutemi provoni përsëri më vonë.',
  WtnInvalidIssueDateTime: 'Diçka nuk shkoi mirë. Ne po punojmë për të rregulluar problemin. Ju lutemi provoni përsëri më vonë.',
  UnsupportedEinvoice: 'Na vjen keq, ndodhi një gabim gjatë krijimit të faturës elektronike. Fatura elektronike nuk u krijua. Ju lutemi provoni përsëri më vonë.',
  XmlSchemaValidationError: 'Diçka nuk shkoi mirë. Ne po punojmë për të rregulluar problemin. Ju lutemi provoni përsëri më vonë.',
  UnauthorizedException: 'Diçka nuk shkoi mirë. Ne po punojmë për të rregulluar problemin. Ju lutemi provoni përsëri më vonë.',

  BankAccountIbanDuplicate: 'Një llogari bankare me këtë IBAN ekziston tashmë! Ju lutemi provoni një të re.',
  BankAccountGeneralError: 'Diçka shkoi keq. Veprimi në llogarinë bankare nuk është kryer. Ju lutemi provoni përsëri më vonë.',
  RealVatDifferentThanClaimed: 'TVSH-ja është e ndryshme nga vlera e pretenduar për biznesin tuaj.',

  BankAccountNotValidSwiftCode: 'Kodi Swift që vendosët nuk është i saktë. Ju lutemi kontrolloni dhe provoni përsëri.',
  RefundCustomerMissing: 'Mungon blerësi për këtë faturë. Ju lutem sigurohuni të keni përfshirë nje blerës për veprimin qe po kryeni.',
  OrderCustomerMissing: 'Mungon blerësi për këtë faturë. Ju lutem sigurohuni të keni përfshirë nje blerës për veprimin qe po kryeni.',
  OrderExportTaxLineItem: 'Taksa e produkteve nuk përputhet me procesin e faturimit. Kontrolloni taksën e produkteve dhe provoni përsëri.',
  ReportsGeneralError: 'Diçka nuk shkoi mirë. Ne po punojmë për të rregulluar problemin. Ju lutemi provoni përsëri më vonë.',

  EInvoiceCustomerError: 'Nuk mund të bëni faturë elektronike kundrejt një blerësi me NIPT të huaj.',
  SupplierNUISMismatchPattern: 'NIPT i lëshuesit nuk përputhet me formatin e duhur.',
  SupplierEmailMismatchPattern: 'Email-i nuk përputhet me formatin e duhur.',
  SupplierArgumentInvalid: 'Argumentet e dhënë janë të pavlefshme.',
  CustomerNUISMismatchPattern: 'NIPT i lëshuesit nuk përputhet me formatin e duhur.',
  CustomerEmailMismatchPattern: 'Email-i nuk përputhet me formatin e duhur.',
  CustomerArgumentInvalid: 'Argumentet e dhënë janë të pavlefshme.',

  EmployeeLocationMismatchError: 'Po përpiqeni ta lidhni këtë punonjës me një pajisje nga një vendndodhje tjetër. Ju nuk mund të vazhdoni me këtë veprim. Provoni të lidhni punonjësin me një pajisje nga e njëjta vendndodhje.',
  CashRegisterLocationMismatchError: 'Punonjësi që po përpiqeni të shtoni ka një vendndodhje të ndryshme nga vendndodhja e pajisjes. Ju nuk mund të vazhdoni me këtë veprim. Sigurohuni që punonjësi dhe pajisja të kenë të njëjtën vendndodhje.',
  EntitiesLocationMismatchError: 'Ju nuk mund të vazhdoni me këtë veprim. Përdoruesi dhe pajisja e përdorur nuk janë në të njëjtin vendndodhje. Ata duhet të regjistrohen në të njëjtën vendndodhje për të vazhduar me këtë veprim.',

  CashRegisterTCRNotDeleted: 'Ju keni ende pajisje të lidhura me këtë kod të njësisë së biznesit. Hiqini të gjitha pajisjet dhe provojeni përsëri. Klikoni më poshtë për të parë të gjitha pajisjet e lidhura.',
  UsedBusinessUnitCodeError: 'Nuk mund të përdorni asnjë nga kodet tuaja të vjetra të njësis së biznesit. Sigurohuni që të shtoni kodin e ri të njësisë së biznesit.',

  CashRegisterNotFound: 'Arka me këtë ID nuk u gjet.',
  CashRegisterIncorrectTCR: 'TCR ID-ja nuk është e saktë. Gjeni TCR ID-në e duhur më lart.',
  CashRegisterTCRDeleted: 'Kjo TCR ID tashmë është fshirë.',
  CashRegisterTCRDeleteError: 'TCR ID-ja e administratorit nuk mund të fshihet pa përditësuar kodin e njësisë së biznesit.',
  CashRegisterBusinessUnitIdOrIssuerNUISNotFound: 'Nuk mund ta gjejmë këtë kod të njësisë së biznesit. Ju lutemi kontrolloni përsëri.',

}

export default al
