import { styleSheetFactory } from '../../Themes/Colors'

const styles = styleSheetFactory(colors => ({
  contentContainer: {
    padding: 15,
    marginTop: 15,
  },
  sectionHeader: {
    marginTop: 20,
    marginBottom: 10,
  },
  footer: {
    marginTop: 20,
    // marginHorizontal: 15,
  },
  button: {
    height: 50,
    margin: 15,
    marginBottom: 30,
  },
}))

const itemStyles = styleSheetFactory(colors => ({
  container: {
    height: 60,
    backgroundColor: colors.tertiaryBackground,
    justifyContent: 'center',
    overflow: 'hidden',
  },
  firstItem_true: {
    borderTopLeftRadius: 7,
    borderTopRightRadius: 7,
  },
  lastItem_true: {
    borderBottomLeftRadius: 7,
    borderBottomRightRadius: 7,
  },
  touchable: {
    flexGrow: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  textContainer: {
    marginRight: 15,
    flexGrow: 1,
    flexShrink: 1,
    flexDirection: 'row',
  },
  name: {
    marginLeft: 15,
  },
  radioInput: {
    flexShrink: 0,
    marginRight: 16,
  },
}))

const separatorStyles = styleSheetFactory(colors => ({
  container: {
    backgroundColor: colors.separator,
    height: 1,
  },
}))

export {
  itemStyles,
  separatorStyles,
}

export default styles
