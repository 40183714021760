import { styleSheetFactory } from '../../Themes/Colors'

const styles = styleSheetFactory(colors => ({
  container: {
    flexGrow: 1,
    marginHorizontal: 15,
    borderRadius: 7,
    overflow: 'hidden',
    flex: 1,
  },
  contentContainer: {
    backgroundColor: colors.background,
    paddingTop: 15,
    flexGrow: 1,
    paddingBottom: 20,
  },
  separatorStyle: {
    height: 1,
    flex: 1,
    backgroundColor: colors.lightGrey,
  },
  rowBack: {
    backgroundColor: colors.accent,
    flex: 1,
    // justifyContent: 'center',
    alignItems: 'flex-end',
  },
  confirmDelete: {
    flex: 1,
  },
  itemIcon: {
    width: 20,
    height: 20,
    resizeMode: 'contain',
  },
  hiddenContent: {
    width: 100,
    height: 70,
    justifyContent: 'center',
    alignItems: 'center',
  },
  hiddenOption: {
    width: 100,
    height: 70,
    justifyContent: 'center',
    alignItems: 'center',
  },
  delete: {
    backgroundColor: colors.primaryIcon,
  },
  plusIcon: {
    tintColor: colors.white,
    height: 19,
    width: 19,
  },
  button: {
    marginBottom: 30,
    height: 50,
  },
  buttonTitle: {
    fontSize: 18,
  },
  hiddenElement: {
    position: 'absolute',
  },
  divider: {
    height: 50,
    alignSelf: 'center',
    width: 1,
    backgroundColor: 'rgba(255, 255, 255, 0.4)',
    position: 'absolute',
  },
  flatHiddenOption: {
    width: 100,
    height: 70,
    justifyContent: 'center',
    alignItems: 'center',
  },
  flatDelete: {
    backgroundColor: colors.primaryIcon,
  },
  rightArrow: {
    marginLeft: 10,
    width: 24,
    height: 24,
    resizeMode: 'contain',
  },
  sectionTitle: { marginBottom: 15, marginTop: 20 },
  itemWrapper: { flex: 1 },
  hiddenItem: {
    flex: 1,
    backgroundColor: colors.secondaryAccent,
    justifyContent: 'center',
  },
  iconText: {
    width: 100,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  hiddenTitle: { marginLeft: 8 },
  confirmHidden: { flex: 1, backgroundColor: colors.secondaryAccent },
  confirmHiddenContainer: { flex: 1, flexDirection: 'row' },
  hiddenContainerSpace: {
    flex: 3,
  },
  confirmationText: { color: colors.white, flex: 1, textAlign: 'center' },
  flatItemContainer: { flexGrow: 1 },
  flatConfirmItem: { flex: 1, backgroundColor: colors.primaryIcon },
  hiddenImage: {
    flex: 1,
  },
}))

export default styles
