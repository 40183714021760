/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useContext, useRef, memo } from 'react'
import {
  View,
  TouchableOpacity,
  Dimensions,
  ImageBackground,
  LayoutAnimation,
  Animated,
  Vibration,
} from 'react-native'
import { useLazyQuery, useMutation } from '@apollo/client'
import PropTypes from 'prop-types'
import { useTheme } from 'react-native-themed-styles'
import Sound from 'react-native-sound'
import { useSelector } from 'react-redux'

import {
  QRScannerOrder,
  TextInput,
  Icon,
  SwipeList,
  Text,
  Modal,
  Loader,
  Header,
  DiscountLabel,
  ModalContent,
  TutorialNote,
  Option,
} from '../../Components'
import ContentManager from '../../Utils/ContentManager'
import { getErrorMessage } from '../../Utils'

import { useConfig } from '../../Utils/Config'
import { getProductCatalog } from '../ProductCatalog/ProductCatalog.Selectors'

import { LIST_ITEMS, DELETE_ITEM, GET_ITEM } from './ItemsList.schema'

import Theme, { layoutAnimConfig } from '../../Themes/Theme'
import { images, animations } from '../../Themes'
import themedStyles, {
  itemStyles,
  hiddenItemStyles,
} from './ItemsList.Styles'

const Item = memo(({
  name,
  description,
  labelColor,
  itemVariationsCount,
  itemVariation,
  discountsCount,
  discount,
  imageUrl,
  onPress,
}) => {
  const [styles] = useTheme(itemStyles)
  const { colors } = useContext(Theme)
  const anim = useRef(new Animated.Value(1)).current
  const animate = value => {
    Animated.spring(anim, {
      toValue: value,
      useNativeDriver: true,
    }).start()
  }
  let price, currency, pricelabel

  const getItemPrice = (prices) => {
    let result
    if (prices.length === 1) {
      result = `${prices[0].amount} ${prices[0].currency}`
    } else {
      result = `${prices.length} ${ContentManager.translate('checkout_variable_price_label')}`
    }
    return result
  }

  if (itemVariationsCount === 1) {
    const priceMoney = itemVariation?.prices || 0
    if (priceMoney.length) {
      // price = INF.format(priceMoney?.amount)
      // currency = priceMoney?.currency
      pricelabel = getItemPrice(priceMoney)
    } else {
      pricelabel = 'checkout_variable_label'
    }
  } else if (itemVariationsCount === 0) {
    pricelabel = 'checkout_variable_label'
  } else {
    pricelabel =
      itemVariationsCount +
      ContentManager.translate('checkout_multiple_variations_label')
  }

  return (
    <Animated.View
      style={[
        styles.container,
        { transform: [{ scale: anim }] },
      ]}>
      <TouchableOpacity
        style={styles.touchable}
        onPress={onPress}
        onPressIn={() => animate(1.05)}
        onPressOut={() => animate(1)}
        activeOpacity={0.7}
        disabled={!onPress}>
        {imageUrl ? (
          <ImageBackground
            source={{ uri: imageUrl }}
            style={styles.imageContainer(colors.white)}
            resizeMode="cover"
          />
        ) : (
          <View style={styles.imageContainer(labelColor)}>
            <Text
              size={'h5'}
              color={labelColor}
              i18nKey={name
                ?.match(/\b(\w{1})/g)
                ?.slice(0, 2)
                ?.join('')
                ?.toUpperCase()}
            />
          </View>
        )}
        <View style={styles.infoContainer}>
          <View style={styles.descContainer}>
            <Text i18nKey={name} numberOfLines={1} />
            <Text
              i18nKey={description}
              weight="light"
              color={colors.secondaryText}
              size="footnote"
              numberOfLines={1}
            />
          </View>
          <View style={styles.priceContainer}>
            {pricelabel ? (
              <Text i18nKey={pricelabel} />
            ) : (
              <Text
                i18nKey="checkout_price_currency_value"
                placeHolders={[price, currency]}
              />
            )}
          </View>
        </View>

        <DiscountLabel discountsCount={discountsCount} discount={discount} />
      </TouchableOpacity>
    </Animated.View>
  )
})
Item.defaultProps = {
  labelColor: 'white',
}
Item.propTypes = {
  name: PropTypes.string,
  description: PropTypes.string,
  itemVariationsCount: PropTypes.number,
  itemVariation: PropTypes.shape({
    priceMoney: PropTypes.shape({
      amount: PropTypes.number,
      currency: PropTypes.string,
    }),
    prices: PropTypes.array,
  }),
  discountsCount: PropTypes.number,
  discount: PropTypes.object,
  imageUrl: PropTypes.string,
  onPress: PropTypes.func,
}
function HiddenItem({
  needsConfirmation,
  onPress,
  onConfirm,
  onCancel,
  loading,
}) {
  const [styles] = useTheme(hiddenItemStyles)
  const { colors } = useContext(Theme)

  return (
    <View
      style={styles.wrapper}>
      {loading ? (
        <View style={styles.loader}>
          <Loader
            size={32}
            color={colors.white}
            source={animations.vfLoaderWhite}
          />
        </View>
      ) : needsConfirmation ? (
        <TouchableOpacity style={styles.option} onPress={onPress}>
          <Icon source={images.delete} color={colors.white} size={24} />
        </TouchableOpacity>
      ) : (
        <View style={styles.optionFull}>
          <TouchableOpacity style={styles.confirm} onPress={onConfirm}>
            <Icon source={images.delete} color={colors.white} size={24} />
            <Text
              i18nKey="opened_orders_delete_confirm"
              color={colors.white}
              style={styles.confirmText}
            />
          </TouchableOpacity>
          <TouchableOpacity style={styles.cancel} onPress={onCancel}>
            <Icon source={images.closeIcon} color={colors.white} size={20} />
          </TouchableOpacity>
        </View>
      )}
    </View>
  )
}
HiddenItem.propTypes = {
  needsConfirmation: PropTypes.bool,
  onPress: PropTypes.func,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  loading: PropTypes.bool,
}

function EmptyComponent({ navigate }) {
  const { colors } = useContext(Theme)
  const [styles] = useTheme(themedStyles)

  return (
    <View style={styles.emptyComponent}>
      <Icon source={images.emptyItemList} size={180} style={styles.bottomSpacing} />
      <Text i18nKey="upload_items_empty_list" align="center" />
      <TouchableOpacity activeOpacity={1} onPress={() => { navigate('UploadType') }}>
        <View style={styles.wrapper}>
          <Icon source={images.attachFile} color={colors.secondaryAccent} size={25} style={styles.rightSpacing} />
          <Text i18nKey="upload_items_empty_header" color={colors.secondaryAccent} />
        </View>
      </TouchableOpacity>
    </View>
  )

}
EmptyComponent.propTypes = {
  navigate: PropTypes.func,
}

function ItemsList({
  navigation: {
    navigate,
    addListener,
    goBack,
  },
  route: {

  },

}) {

  const { switchLocation: { deviceId, locationId:locId } } = useConfig()

  const [styles] = useTheme(themedStyles)
  const { colors } = useContext(Theme)
  const {
    soundEnabled,
    vibrationEnabled,
  } = useConfig()

  const {locationId, businessName, address } = useSelector(getProductCatalog)


  const [needsConfirmation, setNeedForConfirmation] = useState(true)
  const [items, setItems] = useState([])
  const [itemWidth, setItemWidth] = useState(0)
  const [limit] = useState(30)
  const [searchText, setSearchText] = useState('')
  const [loadingList, setLoading] = useState(true)

  const searchRef = useRef(null)
  const [isScannerVisible, setScannerVisible] = useState(false)
  const [isScannerError, setScannerError] = useState(false)
  const lastScanned = useRef(null)
  const timeout = useRef(null)

  useEffect(() => {
    const unsubscribe = addListener('beforeRemove', () => {
      clearTimeout(timeout.current)
    })
    return unsubscribe
  }, [])

  const [listItems, { loading, data, refetch, fetchMore }] = useLazyQuery(
    LIST_ITEMS,
    {
      fetchPolicy: 'network-only',
    }
  )

  const [scanItem, { data: scanResult }] = useLazyQuery(
    GET_ITEM,
    { fetchPolicy: 'network-only' }
  )

  const list = (text) => {
    listItems({
      variables: {
        locId: locId,
        cashRegisterId: deviceId,
        limit: limit,
        locationId: locationId ? locationId : null,
        filter: {
          name: 'name',
          value: text,
        },
      },
    })
  }

  useEffect(() => {
    list(searchText)
  }, [])

  const scan = (barcode) => {
    scanItem({
      variables: {
        locId: locId,
        cashRegisterId: deviceId,
        searchField: 'barcode',
        searchValue: barcode,
        locationId: locationId ? locationId : null,

      },
    })
  }

  const toggleScanner = () => {
    if (isScannerVisible) {
      lastScanned.current = null
      list(searchText)
    }
    LayoutAnimation.configureNext(layoutAnimConfig)
    setScannerVisible((value) => !value)
  }

  const onBarcodeRead = (event) => {
    const barcode = event.data
    if (barcode !== lastScanned.current) {
      if (soundEnabled) {
        const whoosh = new Sound('scan.mp3', Sound.MAIN_BUNDLE, (error) => {
          if (error) {
            return
          }
          whoosh?.play(() => {
            whoosh.release()
          })
        })
      }
      if (vibrationEnabled) {
        Vibration.vibrate([0, 200])
      }
      lastScanned.current = barcode
      clearTimeout(timeout.current)
      timeout.current = setTimeout(() => {
        lastScanned.current = null
      }, 1000)
      scan(barcode)
    }
  }

  const [isErrorActive, setErrorActive] = useState(false)
  const [deleteItem, { loading: deleting, error: deleteError }] = useMutation(
    DELETE_ITEM,
  )

  const onSearch = (text) => {
    setSearchText(text)
    list(text)
  }

  useEffect(() => {
    const itemsRes = data?.listItems?.items
    if (itemsRes) {
      setItems([...itemsRes])
      setLoading(false)
    }
  }, [data])

  useEffect(() => {
    const { item: scannedItem, listItemVariations = {} } =
      scanResult?.getItem || {}
    if (scannedItem) {
      if (scannedItem?.itemVariationsCount === 1) {
        scannedItem.itemVariation = listItemVariations.itemVariations?.[0]
      }
      setItems([{ ...scannedItem }])
      setLoading(false)
    }
  }, [scanResult])

  const editItem = (item) => {
    if (item) {
      navigate('NewItem', { item, onSave: () => refetch() })
    } else {
      navigate('NewItem', { onSave: () => refetch() })
    }
  }

  const renderItem = ({ item, index }) => (
    <Item
      {...item}
      onPress={() => editItem(item)}
    />
  )

  const openRow = (rowMap, id) => {
    setNeedForConfirmation(false)
    rowMap[id].manuallySwipeRow(-itemWidth)
  }

  const closeRow = (rowMap, id) => {
    rowMap[id].closeRow()
    setNeedForConfirmation(true)
  }

  const deleteRow = (rowMap, id) => {
    deleteItem({
      variables: {
        locId: locId,
        cashRegisterId: deviceId,
        id: id,
        locationId: locationId ? locationId : null,
      },
    })
      .then(() => {
        // displayToast('item_delete_success')
        closeRow(rowMap, id)
        refetch()
      })
      .catch(() => {
        setErrorActive(true)
        closeRow(rowMap, id)
      })
  }

  const renderHiddenItem = ({ item, index }, rowMap) => (
    <HiddenItem
      needsConfirmation={needsConfirmation}
      onPress={() => openRow(rowMap, item.id)}
      onConfirm={() => deleteRow(rowMap, item.id)}
      onCancel={() => closeRow(rowMap, item.id)}
      loading={deleting}
    />
  )

  const onEndReached = () => {
    if (!data?.listItems?.nextToken) {
      return
    }

    fetchMore({
      variables: {
        locId: locId,
        cashRegisterId: deviceId,
        limit: limit,
        locationId: locationId ? locationId : null,
        nextToken: data.listItems.nextToken,
        filter: {
          name: 'name',
          value: searchText,
        },
      },
    })
  }

  return (
    <>
      <Header
        title="header_all_items"
        image={images.back}
        onPress={goBack}
        customBusinessName={businessName}
        customAddress={address}
      />
      <QRScannerOrder
        error={isScannerError}
        barcode
        retry={() => setScannerError(false)}
        onBarcodeRead={onBarcodeRead}
        visible={isScannerVisible}
        containerStyle={isScannerVisible ? styles.qrScanner : {}}
      />
      <SwipeList
        searchValue={searchText}
        // searchPlaceholder="Search For Categories..."
        // onSearch={onSearch}
        data={items}
        renderItem={renderItem}
        renderHiddenItem={renderHiddenItem}
        disableRightSwipe
        rightOpenValue={-100}
        onRowClose={() => setNeedForConfirmation(true)}
        setItemWidth={setItemWidth}
        createNewLabel="item_button_create"
        onCreatePress={() => editItem(undefined)}
        onEndReached={() => onEndReached()}
        buttonVariant={'active'}
        refreshing={loading}
        onRefresh={() => refetch()}
        containerStyle={isScannerVisible ? styles.withQR : styles.withoutQR}
        ListHeaderComponent={<TutorialNote url="https://www.youtube.com/watch?v=wygo8XhiC60" />}
        ListEmptyComponent={!loadingList && <EmptyComponent navigate={navigate} />}
      />
      <View style={styles.topMenuContainer}>
        <View style={styles.searchWrapper}>
          <TouchableOpacity
            onPress={() => {
              searchRef.current?.focus()
              if (isScannerVisible) {
                toggleScanner()
              }
            }}
            style={styles.searchContainer}
            activeOpacity={0.5}>
            <Icon
              source={images.search}
              size={16}
              style={styles.searchIcon}
              color={colors.secondaryIcon}
            />
            <View pointerEvents="none" style={styles.inputContainer}>
              <TextInput
                ref={searchRef}
                placeholder={'cart_search_placeholder'}
                style={styles.searchInput}
                placeholderTextColor={colors.placeholder}
                value={searchText}
                onChangeText={onSearch}
              />
            </View>
          </TouchableOpacity>
          {searchText ? (
            <View style={styles.clearSearchContainer}>
              <TouchableOpacity
                onPress={() => onSearch('')}
                style={styles.clearSearchTouchable}
                activeOpacity={0.5}>
                <Icon
                  source={images.closeIcon}
                  size={20}
                  style={styles.searchIcon}
                  color={colors.secondaryIcon}
                />
              </TouchableOpacity>
            </View>
          ) : <Option
            icon={isScannerVisible ? images.closeIcon : images.scan}
            iconColor={isScannerVisible ? colors.accent : colors.secondaryIcon}
            onPress={() => {
              toggleScanner()
              if (searchRef.current?.isFocused()) {
                searchRef.current?.blur()
              }
            }}
            style={styles.leftSpacing}
          />}
        </View>
        <Option
          icon={images.attachFile}
          onPress={() => {
            navigate('UploadType')
          }}
          style={styles.leftSpacing}
        />
      </View>
      <Modal
        isVisible={isErrorActive}
        animationIn="fadeIn"
        animationOut="fadeOut">
        <ModalContent onClose={() => setErrorActive(false)}>
          <Icon source={images.delete} style={styles.errorIcon} />
          <Text
            i18nKey="orders_error_label"
            color={colors.accent}
            size="h5"
            align="center"
            weight="bold"
            style={styles.errorTitle}
          />
          <Text
            i18nKey={getErrorMessage(deleteError)}
            align="center"
          />
        </ModalContent>
      </Modal>
    </>
  )
}

ItemsList.propTypes = {
  navigation: PropTypes.object,
  route: PropTypes.object,
}

export default ItemsList
