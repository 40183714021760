/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useState, useContext, useEffect } from 'react'
import {
  View,
  Image,
  TouchableOpacity,
  FlatList,
  Animated,
  ImageBackground,
  Platform,
  useWindowDimensions,
} from 'react-native'
import { launchCamera, launchImageLibrary } from 'react-native-image-picker'
import PropTypes from 'prop-types'
import { useTheme } from 'react-native-themed-styles'
import ShadowView from 'react-native-androw'
import {
  PERMISSIONS,
  RESULTS,
  check,
  request,
  openSettings,
} from 'react-native-permissions'

import Text from '../Text'
import Modal from '../Modal'
import Icon from '../Icon'

import ModalContent from '../ModalContent'

import Theme from '../../Themes/Theme'
import themedstyles, {
  colorStyles,
  imageStyles,
} from './ImageColorPicker.Styles'
import images from '../../Themes/Images'

const imagePickerOptions = {
  mediaType: 'photo',
  includeBase64: true,
  maxWidth: 1280,
  maxHeight: 1280,
}
const defaultColors = [
  '#9F52FF',
  '#FCD716',
  '#FFA726',
  '#6CCDFE',
  '#EF5350',
  '#66BB6A',
  '#424242',
  '#F15C9A',
  '#FFFFFF',
]

function SelectColor(props) {
  const [styles] = useTheme(colorStyles)
  const { colors } = useContext(Theme)
  const { isVisible, close, colors: colorOptions, selectColor } = props

  const renderItem = ({ item }) => (
    <ShadowView style={styles.shadow}>
      <TouchableOpacity
        onPress={() => {
          close()
          selectColor(item)
        }}
        style={styles.colorContainer(item)}
      />
    </ShadowView>
  )
  const keyExtractor = (item, index) => index + item

  return (
    <Modal
      isVisible={isVisible}
      onBackButtonPress={close}
      onBackdropPress={close}
      animationIn="fadeIn"
      animationOut="fadeOut"
      useNativeDriver
      hideModalContentWhileAnimating
      backdropTransitionInTiming={0}
      backdropTransitionOutTiming={0}>
      <ModalContent onClose={close}>
        <Text
          i18nKey="choose_color_label"
          color={colors.secondaryText}
          size="h5"
          align="center"
          weight="bold"
          style={styles.modalTitle}
        />
        <FlatList
          data={colorOptions}
          keyExtractor={keyExtractor}
          renderItem={renderItem}
          numColumns={3}
          style={styles.container}
          contentContainerStyle={styles.contentContainer}
        />
      </ModalContent>
    </Modal>
  )
}

SelectColor.defaultProps = {
  colors: defaultColors,
}
SelectColor.propTypes = {
  isVisible: PropTypes.bool,
  close: PropTypes.func,
  selectColor: PropTypes.func,
}

function SelectImage(props) {
  const [styles] = useTheme(imageStyles)
  const { colors } = useContext(Theme)
  const { isVisible, close, selectImage } = props
  const getImage = (image) => {
    const imageAssets = image?.assets?.[0]
    const uri = imageAssets?.uri
    if (uri) {
      close()
      selectImage({ ...imageAssets })
    }
  }

  return (
    <Modal
      isVisible={isVisible}
      onBackButtonPress={close}
      onBackdropPress={close}
      animationIn="fadeIn"
      animationOut="fadeOut"
      useNativeDriver
      hideModalContentWhileAnimating
      backdropTransitionInTiming={0}
      backdropTransitionOutTiming={0}>
      <ModalContent onClose={close}>
        <Text
          i18nKey="Add image from"
          color={colors.secondaryText}
          size="h5"
          align="center"
          weight="bold"
          style={styles.modalTitle}
        />
        <View style={styles.optionsContainer}>
          <ShadowView style={styles.shadow}>
            <TouchableOpacity
              onPress={() => {
                Platform.OS === 'android' && close()
                check(
                  Platform.select({
                    ios: PERMISSIONS.IOS.CAMERA,
                    android: PERMISSIONS.ANDROID.CAMERA,
                  }),
                )
                  .then((result) => {
                    switch (result) {
                      case RESULTS.DENIED:
                        request(
                          Platform.select({
                            ios: PERMISSIONS.IOS.CAMERA,
                            android: PERMISSIONS.ANDROID.CAMERA,
                          }),
                        ).then((res) => {
                          if (res === RESULTS.GRANTED) {
                            launchCamera(imagePickerOptions, getImage)
                          }
                        })
                        break
                      case RESULTS.LIMITED:
                        launchImageLibrary(imagePickerOptions, getImage)
                        break
                      case RESULTS.GRANTED:
                        launchCamera(imagePickerOptions, getImage)
                        break
                      case RESULTS.BLOCKED:
                        openSettings()
                        break
                    }
                  })
                  .catch(() => { })
              }}
              style={styles.option}>
              <Icon source={images.camera} />
              <Text i18nKey="Camera" align="center" />
            </TouchableOpacity>
          </ShadowView>
          <ShadowView style={styles.shadow}>
            <TouchableOpacity
              onPress={() => {
                Platform.OS === 'android' && close()
                check(
                  Platform.select({
                    ios: PERMISSIONS.IOS.PHOTO_LIBRARY,
                    android: PERMISSIONS.ANDROID.READ_EXTERNAL_STORAGE,
                  }),
                )
                  .then((result) => {
                    switch (result) {
                      case RESULTS.DENIED:
                        request(
                          Platform.select({
                            ios: PERMISSIONS.IOS.PHOTO_LIBRARY,
                            android: PERMISSIONS.ANDROID.READ_EXTERNAL_STORAGE,
                          }),
                        ).then((res) => {
                          if (res === RESULTS.GRANTED) {
                            launchImageLibrary(imagePickerOptions, getImage)
                          }
                        })
                        break
                      case RESULTS.LIMITED:
                        launchImageLibrary(imagePickerOptions, getImage)
                        break
                      case RESULTS.GRANTED:
                        launchImageLibrary(imagePickerOptions, getImage)
                        break
                      case RESULTS.BLOCKED:
                        openSettings()
                        break
                    }
                  })
                  .catch(() => { })
              }}
              style={styles.option}>
              <Icon source={images.gallery} />
              <Text i18nKey="Gallery" align="center" />
            </TouchableOpacity>
          </ShadowView>
        </View>
      </ModalContent>
    </Modal>
  )
}

SelectImage.propTypes = {
  isVisible: PropTypes.bool,
  close: PropTypes.func,
  selectImage: PropTypes.func,
}

//Note: Used to pick image web
function readFileWeb(file) {
  return new Promise((resolve) => {
    // eslint-disable-next-line no-undef
    const reader = new FileReader()
    reader.addEventListener('load', () => resolve(reader.result), false)
    reader.readAsDataURL(file)
  })
}

function ImageColorPicker(props) {
  const [styles] = useTheme(themedstyles)
  const {
    selectedColor,
    setSelectedColor,
    selectedImage,
    setSelectedImage,
    style,
  } = props

  const { width: windowWidth } = useWindowDimensions()
  const [isSelectColorVisible, setSelectColorVisibility] = useState(false)
  const [isSelectImageVisible, setSelectImageVisibility] = useState(false)
  const [hasImage, setImagePresence] = useState(Boolean(selectedImage?.uri))
  const anim = useRef(new Animated.Value(0)).current
  // const closeAnim = useRef(new Animated.Value(0)).current
  if (Platform.OS === 'web'){
    anim.interpolate = function({inputRange, outputRange}){
      if (!hasImage){
        return outputRange[0]
      }
      return outputRange[1]
    }
  }

  const width = windowWidth >= 800 ? windowWidth - 280 : windowWidth

  const animate = (animatedValue, value) => {
    Animated.spring(animatedValue, {
      toValue: value,
      useNativeDriver: true,
    }).start()
  }

  useEffect(() => {
    if (selectedColor) {
      animate(anim, 0)
      setImagePresence(false)
    }
  }, [selectedColor])

  useEffect(() => {
    if (selectedImage?.uri) {
      animate(anim, 1)
      setImagePresence(true)
    }
  }, [selectedImage])

  // const toggleCloseButton = () => {
  //   const value = closeAnim._value === 0 ? 1 : 0
  //   animate(closeAnim, value)
  // }

  const uri = selectedImage?.uri

  const onFileChange = async (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0]
      const imageDataUrl = await readFileWeb(file)
      setSelectedImage({ uri: imageDataUrl, base64:imageDataUrl.replace(`data:${file?.type};base64,`, '')})
    }
  }
  return (
    <>
      <ImageBackground
        source={hasImage ? { uri: uri } : undefined}
        resizeMode="cover"
        blurRadius={6}
        style={[styles.container(selectedColor), style]}>
        {hasImage && (
          <Image
            source={uri ? { uri: uri } : undefined}
            resizeMode="contain"
            style={styles.image}
          />
        )}
        <View pointerEvents="box-none" style={styles.imagePickerContainer}>
          {Platform.OS === 'web' ?
          <>
            <input
            hidden
            id="contained-image-file"
            type="file"
            accept="image/x-png,image/gif,image/jpeg"
            onChange={onFileChange}
          />
          <label htmlFor="contained-image-file" >
          <TouchableOpacity
          style={[
            styles.imagePicker,
            {
              borderRadius: anim.interpolate({
                inputRange: [0, 1],
                outputRange: [7, 50],
              }),
              borderTopRightRadius: anim.interpolate({
                inputRange: [0, 1],
                outputRange: [0, 50],
              }),
              borderBottomRightRadius: anim.interpolate({
                inputRange: [0, 1],
                outputRange: [0, 50],
              }),
              transform: [
                {
                  translateX: anim.interpolate({
                    inputRange: [0, 1],
                    outputRange: [25, -width / 4],
                  }),
                },
                {
                  scale: anim.interpolate({
                    inputRange: [0, 1],
                    outputRange: [1, 0.7],
                  }),
                },
              ],
            },
          ]}
          >

          <Animated.Image
            source={images.gallery}
            style={[
              styles.galleryIcon,
              {
                transform: [
                  {
                    translateX: anim.interpolate({
                      inputRange: [0, 1],
                      outputRange: [-12.5, 0],
                    }),
                  },
                  {
                    scale: anim.interpolate({
                      inputRange: [0, 1],
                      outputRange: [0.8, 1],
                    }),
                  },
                ],
              },
            ]}
          />
        </TouchableOpacity>
            </label>
          </>
          :

          <TouchableOpacity
          style={[
            styles.imagePicker,
            {
              borderRadius: anim.interpolate({
                inputRange: [0, 1],
                outputRange: [7, 50],
              }),
              borderTopRightRadius: anim.interpolate({
                inputRange: [0, 1],
                outputRange: [0, 50],
              }),
              borderBottomRightRadius: anim.interpolate({
                inputRange: [0, 1],
                outputRange: [0, 50],
              }),
              transform: [
                {
                  translateX: anim.interpolate({
                    inputRange: [0, 1],
                    outputRange: [25, -width / 4],
                  }),
                },
                {
                  scale: anim.interpolate({
                    inputRange: [0, 1],
                    outputRange: [1, 0.7],
                  }),
                },
              ],
            },
          ]}
          onPress={() => setSelectImageVisibility(true)}
          >

          <Animated.Image
            source={images.gallery}
            style={[
              styles.galleryIcon,
              {
                transform: [
                  {
                    translateX: anim.interpolate({
                      inputRange: [0, 1],
                      outputRange: [-12.5, 0],
                    }),
                  },
                  {
                    scale: anim.interpolate({
                      inputRange: [0, 1],
                      outputRange: [0.8, 1],
                    }),
                  },
                ],
              },
            ]}
          />
        </TouchableOpacity>
          }

        </View>
        <Animated.View
          style={[
            styles.seperatorContainer,
            {
              transform: [
                {
                  scaleY: anim.interpolate({
                    inputRange: [0, 1],
                    outputRange: [1, 0],
                  }),
                },
              ],
            },
          ]}>
          <View style={styles.seperator} />
        </Animated.View>
        <View pointerEvents="box-none" style={styles.colorPickerContainer}>
          <TouchableOpacity
            style={[
              styles.colorPicker,
              {
                borderRadius: anim.interpolate({
                  inputRange: [0, 1],
                  outputRange: [7, 50],
                }),
                borderTopLeftRadius: anim.interpolate({
                  inputRange: [0, 1],
                  outputRange: [0, 50],
                }),
                borderBottomLeftRadius: anim.interpolate({
                  inputRange: [0, 1],
                  outputRange: [0, 50],
                }),
                transform: [
                  {
                    translateX: anim.interpolate({
                      inputRange: [0, 1],
                      outputRange: [-25, width / 4],
                    }),
                  },
                  {
                    scale: anim.interpolate({
                      inputRange: [0, 1],
                      outputRange: [1, 0.7],
                    }),
                  },
                ],
              },
            ]}
            onPress={() => setSelectColorVisibility(true)}>
            <Animated.Image
              source={images.palette}
              style={[
                styles.colorIcon,
                {
                  transform: [
                    {
                      translateX: anim.interpolate({
                        inputRange: [0, 1],
                        outputRange: [12.5, 0],
                      }),
                    },
                    {
                      scale: anim.interpolate({
                        inputRange: [0, 1],
                        outputRange: [0.8, 1],
                      }),
                    },
                  ],
                },
              ]}
            />
          </TouchableOpacity>
        </View>
      </ImageBackground>
      <SelectImage
        isVisible={isSelectImageVisible}
        close={() => setSelectImageVisibility(false)}
        selectImage={(image) => setSelectedImage?.(image)}
      />
      <SelectColor
        isVisible={isSelectColorVisible}
        close={() => setSelectColorVisibility(false)}
        selectColor={(color) => setSelectedColor?.(color)}
      />
    </>
  )
}

ImageColorPicker.defaultProps = {
  setSelectedColor: () => { },
  setSelectedImage: () => { },
}

ImageColorPicker.propTypes = {
  setSelectedColor: PropTypes.func,
  selectedImage: PropTypes.object,
  setSelectedImage: PropTypes.func,
  style: PropTypes.object,
}

export { SelectColor, SelectImage }
export default ImageColorPicker
