import { styleSheetFactory } from '../../Themes/Colors'

const styles = styleSheetFactory(colors => ({
  container: {
    flex: 1,
  },
  webView: {
    marginHorizontal: 8,
    flexGrow: 1,
  },
  lottieWrapper: {
    height: '100%',
    paddingHorizontal: 16,
    paddingVertical: 32,
    marginBottom: 32,
    justifyContent: 'center',
    alignItems: 'center',
  },
  lottie: {
    width: 150,
    height: 150,
    marginLeft: 0,
    alignSelf: 'center',
  },
}))

export default styles
