
const STATE_LIST = [
    {
        'code': 'ALB',
        'description': 'Albania',
        'type': 'ALB',
    },
    {
        'code': 'ABW',
        'description': 'Aruba',
        'type': 'ABW',
    },
    {
        'code': 'AFG',
        'description': 'Afghanistan',
        'type': 'AFG',
    },
    {
        'code': 'AGO',
        'description': 'Angola',
        'type': 'AGO',
    },
    {
        'code': 'AIA',
        'description': 'Anguilla',
        'type': 'AIA',
    },
    {
        'code': 'ALA',
        'description': 'Aland Islands',
        'type': 'ALA',
    },
    {
        'code': 'AND',
        'description': 'Andorra',
        'type': 'AND',
    },
    {
        'code': 'ARE',
        'description': 'United Arab Emirates',
        'type': 'ARE',
    },
    {
        'code': 'ARG',
        'description': 'Argentina',
        'type': 'ARG',
    },
    {
        'code': 'ARM',
        'description': 'Armenia',
        'type': 'ARM',
    },
    {
        'code': 'ASM',
        'description': 'American Samoa',
        'type': 'ASM',
    },
    {
        'code': 'ATA',
        'description': 'Antarctica',
        'type': 'ATA',
    },
    {
        'code': 'ATF',
        'description': 'French Southern Territories',
        'type': 'ATF',
    },
    {
        'code': 'ATG',
        'description': 'Antigua and Barbuda',
        'type': 'ATG',
    },
    {
        'code': 'AUS',
        'description': 'Australia',
        'type': 'AUS',
    },
    {
        'code': 'AUT',
        'description': 'Austria',
        'type': 'AUT',
    },
    {
        'code': 'AZE',
        'description': 'Azerbaijan',
        'type': 'AZE',
    },
    {
        'code': 'BDI',
        'description': 'Burundi',
        'type': 'BDI',
    },
    {
        'code': 'BEL',
        'description': 'Belgium',
        'type': 'BEL',
    },
    {
        'code': 'BEN',
        'description': 'Benin',
        'type': 'BEN',
    },
    {
        'code': 'BES',
        'description': 'Bonaire, Sint Eustatius and Saba',
        'type': 'BES',
    },
    {
        'code': 'BFA',
        'description': 'Burkina Faso',
        'type': 'BFA',
    },
    {
        'code': 'BGD',
        'description': 'Bangladesh',
        'type': 'BGD',
    },
    {
        'code': 'BGR',
        'description': 'Bulgaria',
        'type': 'BGR',
    },
    {
        'code': 'BHR',
        'description': 'Bahrain',
        'type': 'BHR',
    },
    {
        'code': 'BHS',
        'description': 'Bahamas',
        'type': 'BHS',
    },
    {
        'code': 'BIH',
        'description': 'Bosnia and Herzegovina',
        'type': 'BIH',
    },
    {
        'code': 'BLM',
        'description': 'Saint Barthelemy',
        'type': 'BLM',
    },
    {
        'code': 'BLR',
        'description': 'Belarus',
        'type': 'BLR',
    },
    {
        'code': 'BLZ',
        'description': 'Belize',
        'type': 'BLZ',
    },
    {
        'code': 'BMU',
        'description': 'Bermuda',
        'type': 'BMU',
    },
    {
        'code': 'BOL',
        'description': 'Bolivia (Plurinational State of)',
        'type': 'BOL',
    },
    {
        'code': 'BRA',
        'description': 'Brazil',
        'type': 'BRA',
    },
    {
        'code': 'BRB',
        'description': 'Barbados',
        'type': 'BRB',
    },
    {
        'code': 'BRN',
        'description': 'Brunei Darussalam',
        'type': 'BRN',
    },
    {
        'code': 'BTN',
        'description': 'Bhutan',
        'type': 'BTN',
    },
    {
        'code': 'BVT',
        'description': 'Bouvet Island',
        'type': 'BVT',
    },
    {
        'code': 'BWA',
        'description': 'Botswana',
        'type': 'BWA',
    },
    {
        'code': 'CAF',
        'description': 'Central African Republic',
        'type': 'CAF',
    },
    {
        'code': 'CAN',
        'description': 'Canada',
        'type': 'CAN',
    },
    {
        'code': 'CCK',
        'description': 'Cocos (Keeling) Islands',
        'type': 'CCK',
    },
    {
        'code': 'CHE',
        'description': 'Switzerland',
        'type': 'CHE',
    },
    {
        'code': 'CHL',
        'description': 'Chile',
        'type': 'CHL',
    },
    {
        'code': 'CHN',
        'description': 'China',
        'type': 'CHN',
    },
    {
        'code': 'CIV',
        'description': "Cote d'Ivoire",
        'type': 'CIV',
    },
    {
        'code': 'CMR',
        'description': 'Cameroon',
        'type': 'CMR',
    },
    {
        'code': 'COD',
        'description': 'Congo, Democratic Republic of the',
        'type': 'COD',
    },
    {
        'code': 'COG',
        'description': 'Congo',
        'type': 'COG',
    },
    {
        'code': 'COK',
        'description': 'Cook Islands',
        'type': 'COK',
    },
    {
        'code': 'COL',
        'description': 'Colombia',
        'type': 'COL',
    },
    {
        'code': 'COM',
        'description': 'Comoros',
        'type': 'COM',
    },
    {
        'code': 'CPV',
        'description': 'Cabo Verde',
        'type': 'CPV',
    },
    {
        'code': 'CRI',
        'description': 'Costa Rica',
        'type': 'CRI',
    },
    {
        'code': 'CUB',
        'description': 'Cuba',
        'type': 'CUB',
    },
    {
        'code': 'CUW',
        'description': 'Curacao',
        'type': 'CUW',
    },
    {
        'code': 'CXR',
        'description': 'Christmas Island',
        'type': 'CXR',
    },
    {
        'code': 'CYM',
        'description': 'Cayman Islands',
        'type': 'CYM',
    },
    {
        'code': 'CYP',
        'description': 'Cyprus',
        'type': 'CYP',
    },
    {
        'code': 'CZE',
        'description': 'Czechia',
        'type': 'CZE',
    },
    {
        'code': 'DEU',
        'description': 'Germany',
        'type': 'DEU',
    },
    {
        'code': 'DJI',
        'description': 'Djibouti',
        'type': 'DJI',
    },
    {
        'code': 'DMA',
        'description': 'Dominica',
        'type': 'DMA',
    },
    {
        'code': 'DNK',
        'description': 'Denmark',
        'type': 'DNK',
    },
    {
        'code': 'DOM',
        'description': 'Dominican Republic',
        'type': 'DOM',
    },
    {
        'code': 'DZA',
        'description': 'Algeria',
        'type': 'DZA',
    },
    {
        'code': 'ECU',
        'description': 'Ecuador',
        'type': 'ECU',
    },
    {
        'code': 'EGY',
        'description': 'Egypt',
        'type': 'EGY',
    },
    {
        'code': 'ERI',
        'description': 'Eritrea',
        'type': 'ERI',
    },
    {
        'code': 'ESH',
        'description': 'Western Sahara',
        'type': 'ESH',
    },
    {
        'code': 'ESP',
        'description': 'Spain',
        'type': 'ESP',
    },
    {
        'code': 'EST',
        'description': 'Estonia',
        'type': 'EST',
    },
    {
        'code': 'ETH',
        'description': 'Ethiopia',
        'type': 'ETH',
    },
    {
        'code': 'FIN',
        'description': 'Finland',
        'type': 'FIN',
    },
    {
        'code': 'FJI',
        'description': 'Fiji',
        'type': 'FJI',
    },
    {
        'code': 'FLK',
        'description': 'Falkland Islands (Malvinas)',
        'type': 'FLK',
    },
    {
        'code': 'FRA',
        'description': 'France',
        'type': 'FRA',
    },
    {
        'code': 'FRO',
        'description': 'Faroe Islands',
        'type': 'FRO',
    },
    {
        'code': 'FSM',
        'description': 'Micronesia (Federated States of)',
        'type': 'FSM',
    },
    {
        'code': 'GAB',
        'description': 'Gabon',
        'type': 'GAB',
    },
    {
        'code': 'GBR',
        'description': 'United Kingdom of Great Britain and Northern Ireland',
        'type': 'GBR',
    },
    {
        'code': 'GEO',
        'description': 'Georgia',
        'type': 'GEO',
    },
    {
        'code': 'GGY',
        'description': 'Guernsey',
        'type': 'GGY',
    },
    {
        'code': 'GHA',
        'description': 'Ghana',
        'type': 'GHA',
    },
    {
        'code': 'GIB',
        'description': 'Gibraltar',
        'type': 'GIB',
    },
    {
        'code': 'GIN',
        'description': 'Guinea',
        'type': 'GIN',
    },
    {
        'code': 'GLP',
        'description': 'Guadeloupe',
        'type': 'GLP',
    },
    {
        'code': 'GMB',
        'description': 'Gambia',
        'type': 'GMB',
    },
    {
        'code': 'GNB',
        'description': 'Guinea-Bissau',
        'type': 'GNB',
    },
    {
        'code': 'GNQ',
        'description': 'Equatorial Guinea',
        'type': 'GNQ',
    },
    {
        'code': 'GRC',
        'description': 'Greece',
        'type': 'GRC',
    },
    {
        'code': 'GRD',
        'description': 'Grenada',
        'type': 'GRD',
    },
    {
        'code': 'GRL',
        'description': 'Greenland',
        'type': 'GRL',
    },
    {
        'code': 'GTM',
        'description': 'Guatemala',
        'type': 'GTM',
    },
    {
        'code': 'GUF',
        'description': 'French Guiana',
        'type': 'GUF',
    },
    {
        'code': 'GUM',
        'description': 'Guam',
        'type': 'GUM',
    },
    {
        'code': 'GUY',
        'description': 'Guyana',
        'type': 'GUY',
    },
    {
        'code': 'HKG',
        'description': 'Hong Kong',
        'type': 'HKG',
    },
    {
        'code': 'HMD',
        'description': 'Heard Island and McDonald Islands',
        'type': 'HMD',
    },
    {
        'code': 'HND',
        'description': 'Honduras',
        'type': 'HND',
    },
    {
        'code': 'HRV',
        'description': 'Croatia',
        'type': 'HRV',
    },
    {
        'code': 'HTI',
        'description': 'Haiti',
        'type': 'HTI',
    },
    {
        'code': 'HUN',
        'description': 'Hungary',
        'type': 'HUN',
    },
    {
        'code': 'IDN',
        'description': 'Indonesia',
        'type': 'IDN',
    },
    {
        'code': 'IMN',
        'description': 'Isle of Man',
        'type': 'IMN',
    },
    {
        'code': 'IND',
        'description': 'India',
        'type': 'IND',
    },
    {
        'code': 'IOT',
        'description': 'British Indian Ocean Territory',
        'type': 'IOT',
    },
    {
        'code': 'IRL',
        'description': 'Ireland',
        'type': 'IRL',
    },
    {
        'code': 'IRN',
        'description': 'Iran (Islamic Republic of)',
        'type': 'IRN',
    },
    {
        'code': 'IRQ',
        'description': 'Iraq',
        'type': 'IRQ',
    },
    {
        'code': 'ISL',
        'description': 'Iceland',
        'type': 'ISL',
    },
    {
        'code': 'ISR',
        'description': 'Israel',
        'type': 'ISR',
    },
    {
        'code': 'ITA',
        'description': 'Italy',
        'type': 'ITA',
    },
    {
        'code': 'JAM',
        'description': 'Jamaica',
        'type': 'JAM',
    },
    {
        'code': 'JEY',
        'description': 'Jersey',
        'type': 'JEY',
    },
    {
        'code': 'JOR',
        'description': 'Jordan',
        'type': 'JOR',
    },
    {
        'code': 'JPN',
        'description': 'Japan',
        'type': 'JPN',
    },
    {
        'code': 'KAZ',
        'description': 'Kazakhstan',
        'type': 'KAZ',
    },
    {
        'code': 'KEN',
        'description': 'Kenya',
        'type': 'KEN',
    },
    {
        'code': 'KGZ',
        'description': 'Kyrgyzstan',
        'type': 'KGZ',
    },
    {
        'code': 'KHM',
        'description': 'Cambodia',
        'type': 'KHM',
    },
    {
        'code': 'KIR',
        'description': 'Kiribati',
        'type': 'KIR',
    },
    {
        'code': 'KNA',
        'description': 'Saint Kitts and Nevis',
        'type': 'KNA',
    },
    {
        'code': 'KOR',
        'description': 'Korea, Republic of',
        'type': 'KOR',
    },
    {
        'code': 'KWT',
        'description': 'Kuwait',
        'type': 'KWT',
    },
    {
        'code': 'LAO',
        'description': "Lao People's Democratic Republic",
        'type': 'LAO',
    },
    {
        'code': 'LBN',
        'description': 'Lebanon',
        'type': 'LBN',
    },
    {
        'code': 'LBR',
        'description': 'Liberia',
        'type': 'LBR',
    },
    {
        'code': 'LBY',
        'description': 'Libya',
        'type': 'LBY',
    },
    {
        'code': 'LCA',
        'description': 'Saint Lucia',
        'type': 'LCA',
    },
    {
        'code': 'LIE',
        'description': 'Liechtenstein',
        'type': 'LIE',
    },
    {
        'code': 'LKA',
        'description': 'Sri Lanka',
        'type': 'LKA',
    },
    {
        'code': 'LSO',
        'description': 'Lesotho',
        'type': 'LSO',
    },
    {
        'code': 'LTU',
        'description': 'Lithuania',
        'type': 'LTU',
    },
    {
        'code': 'LUX',
        'description': 'Luxembourg',
        'type': 'LUX',
    },
    {
        'code': 'LVA',
        'description': 'Latvia',
        'type': 'LVA',
    },
    {
        'code': 'MAC',
        'description': 'Macao',
        'type': 'MAC',
    },
    {
        'code': 'MAF',
        'description': 'Saint Martin (French part)',
        'type': 'MAF',
    },
    {
        'code': 'MAR',
        'description': 'Morocco',
        'type': 'MAR',
    },
    {
        'code': 'MCO',
        'description': 'Monaco',
        'type': 'MCO',
    },
    {
        'code': 'MDA',
        'description': 'Moldova, Republic of',
        'type': 'MDA',
    },
    {
        'code': 'MDG',
        'description': 'Madagascar',
        'type': 'MDG',
    },
    {
        'code': 'MDV',
        'description': 'Maldives',
        'type': 'MDV',
    },
    {
        'code': 'MEX',
        'description': 'Mexico',
        'type': 'MEX',
    },
    {
        'code': 'MHL',
        'description': 'Marshall Islands',
        'type': 'MHL',
    },
    {
        'code': 'MKD',
        'description': 'North Macedonia',
        'type': 'MKD',
    },
    {
        'code': 'MLI',
        'description': 'Mali',
        'type': 'MLI',
    },
    {
        'code': 'MLT',
        'description': 'Malta',
        'type': 'MLT',
    },
    {
        'code': 'MMR',
        'description': 'Myanmar',
        'type': 'MMR',
    },
    {
        'code': 'MNE',
        'description': 'Montenegro',
        'type': 'MNE',
    },
    {
        'code': 'MNG',
        'description': 'Mongolia',
        'type': 'MNG',
    },
    {
        'code': 'MNP',
        'description': 'Northern Mariana Islands',
        'type': 'MNP',
    },
    {
        'code': 'MOZ',
        'description': 'Mozambique',
        'type': 'MOZ',
    },
    {
        'code': 'MRT',
        'description': 'Mauritania',
        'type': 'MRT',
    },
    {
        'code': 'MSR',
        'description': 'Montserrat',
        'type': 'MSR',
    },
    {
        'code': 'MTQ',
        'description': 'Martinique',
        'type': 'MTQ',
    },
    {
        'code': 'MUS',
        'description': 'Mauritius',
        'type': 'MUS',
    },
    {
        'code': 'MWI',
        'description': 'Malawi',
        'type': 'MWI',
    },
    {
        'code': 'MYS',
        'description': 'Malaysia',
        'type': 'MYS',
    },
    {
        'code': 'MYT',
        'description': 'Mayotte',
        'type': 'MYT',
    },
    {
        'code': 'NAM',
        'description': 'Namibia',
        'type': 'NAM',
    },
    {
        'code': 'NCL',
        'description': 'New Caledonia',
        'type': 'NCL',
    },
    {
        'code': 'NER',
        'description': 'Niger',
        'type': 'NER',
    },
    {
        'code': 'NFK',
        'description': 'Norfolk Island',
        'type': 'NFK',
    },
    {
        'code': 'NGA',
        'description': 'Nigeria',
        'type': 'NGA',
    },
    {
        'code': 'NIC',
        'description': 'Nicaragua',
        'type': 'NIC',
    },
    {
        'code': 'NIU',
        'description': 'Niue',
        'type': 'NIU',
    },
    {
        'code': 'NLD',
        'description': 'Netherlands',
        'type': 'NLD',
    },
    {
        'code': 'NOR',
        'description': 'Norway',
        'type': 'NOR',
    },
    {
        'code': 'NPL',
        'description': 'Nepal',
        'type': 'NPL',
    },
    {
        'code': 'NRU',
        'description': 'Nauru',
        'type': 'NRU',
    },
    {
        'code': 'NZL',
        'description': 'New Zealand',
        'type': 'NZL',
    },
    {
        'code': 'OMN',
        'description': 'Oman',
        'type': 'OMN',
    },
    {
        'code': 'PAK',
        'description': 'Pakistan',
        'type': 'PAK',
    },
    {
        'code': 'PAN',
        'description': 'Panama',
        'type': 'PAN',
    },
    {
        'code': 'PCN',
        'description': 'Pitcairn',
        'type': 'PCN',
    },
    {
        'code': 'PER',
        'description': 'Peru',
        'type': 'PER',
    },
    {
        'code': 'PHL',
        'description': 'Philippines',
        'type': 'PHL',
    },
    {
        'code': 'PLW',
        'description': 'Palau',
        'type': 'PLW',
    },
    {
        'code': 'PNG',
        'description': 'Papua New Guinea',
        'type': 'PNG',
    },
    {
        'code': 'POL',
        'description': 'Poland',
        'type': 'POL',
    },
    {
        'code': 'PRI',
        'description': 'Puerto Rico',
        'type': 'PRI',
    },
    {
        'code': 'PRK',
        'description': "Korea (Democratic People's Republic of)",
        'type': 'PRK',
    },
    {
        'code': 'PRT',
        'description': 'Portugal',
        'type': 'PRT',
    },
    {
        'code': 'PRY',
        'description': 'Paraguay',
        'type': 'PRY',
    },
    {
        'code': 'PSE',
        'description': 'Palestine, State of',
        'type': 'PSE',
    },
    {
        'code': 'PYF',
        'description': 'French Polynesia',
        'type': 'PYF',
    },
    {
        'code': 'QAT',
        'description': 'Qatar',
        'type': 'QAT',
    },
    {
        'code': 'REU',
        'description': 'Reunion',
        'type': 'REU',
    },
    {
        'code': 'ROU',
        'description': 'Romania',
        'type': 'ROU',
    },
    {
        'code': 'RUS',
        'description': 'Russian Federation',
        'type': 'RUS',
    },
    {
        'code': 'RWA',
        'description': 'Rwanda',
        'type': 'RWA',
    },
    {
        'code': 'SAU',
        'description': 'Saudi Arabia',
        'type': 'SAU',
    },
    {
        'code': 'SDN',
        'description': 'Sudan',
        'type': 'SDN',
    },
    {
        'code': 'SEN',
        'description': 'Senegal',
        'type': 'SEN',
    },
    {
        'code': 'SGP',
        'description': 'Singapore',
        'type': 'SGP',
    },
    {
        'code': 'SGS',
        'description': 'South Georgia and the South Sandwich Islands',
        'type': 'SGS',
    },
    {
        'code': 'SHN',
        'description': 'Saint Helena, Ascension and Tristan da Cunha',
        'type': 'SHN',
    },
    {
        'code': 'SJM',
        'description': 'Svalbard and Jan Mayen',
        'type': 'SJM',
    },
    {
        'code': 'SLB',
        'description': 'Solomon Islands',
        'type': 'SLB',
    },
    {
        'code': 'SLE',
        'description': 'Sierra Leone',
        'type': 'SLE',
    },
    {
        'code': 'SLV',
        'description': 'El Salvador',
        'type': 'SLV',
    },
    {
        'code': 'SMR',
        'description': 'San Marino',
        'type': 'SMR',
    },
    {
        'code': 'SOM',
        'description': 'Somalia',
        'type': 'SOM',
    },
    {
        'code': 'SPM',
        'description': 'Saint Pierre and Miquelon',
        'type': 'SPM',
    },
    {
        'code': 'SRB',
        'description': 'Serbia',
        'type': 'SRB',
    },
    {
        'code': 'SSD',
        'description': 'South Sudan',
        'type': 'SSD',
    },
    {
        'code': 'STP',
        'description': 'Sao Tome and Principe',
        'type': 'STP',
    },
    {
        'code': 'SUR',
        'description': 'Suriname',
        'type': 'SUR',
    },
    {
        'code': 'SVK',
        'description': 'Slovakia',
        'type': 'SVK',
    },
    {
        'code': 'SVN',
        'description': 'Slovenia',
        'type': 'SVN',
    },
    {
        'code': 'SWE',
        'description': 'Sweden',
        'type': 'SWE',
    },
    {
        'code': 'SWZ',
        'description': 'Eswatini',
        'type': 'SWZ',
    },
    {
        'code': 'SXM',
        'description': 'Sint Maarten (Dutch part)',
        'type': 'SXM',
    },
    {
        'code': 'SYC',
        'description': 'Seychelles',
        'type': 'SYC',
    },
    {
        'code': 'SYR',
        'description': 'Syrian Arab Republic',
        'type': 'SYR',
    },
    {
        'code': 'TCA',
        'description': 'Turks and Caicos Islands',
        'type': 'TCA',
    },
    {
        'code': 'TCD',
        'description': 'Chad',
        'type': 'TCD',
    },
    {
        'code': 'TGO',
        'description': 'Togo',
        'type': 'TGO',
    },
    {
        'code': 'THA',
        'description': 'Thailand',
        'type': 'THA',
    },
    {
        'code': 'TJK',
        'description': 'Tajikistan',
        'type': 'TJK',
    },
    {
        'code': 'TKL',
        'description': 'Tokelau',
        'type': 'TKL',
    },
    {
        'code': 'TKM',
        'description': 'Turkmenistan',
        'type': 'TKM',
    },
    {
        'code': 'TLS',
        'description': 'Timor-Leste',
        'type': 'TLS',
    },
    {
        'code': 'TON',
        'description': 'Tonga',
        'type': 'TON',
    },
    {
        'code': 'TTO',
        'description': 'Trinidad and Tobago',
        'type': 'TTO',
    },
    {
        'code': 'TUN',
        'description': 'Tunisia',
        'type': 'TUN',
    },
    {
        'code': 'TUR',
        'description': 'Turkey',
        'type': 'TUR',
    },
    {
        'code': 'TUV',
        'description': 'Tuvalu',
        'type': 'TUV',
    },
    {
        'code': 'TWN',
        'description': 'Taiwan, Province of China',
        'type': 'TWN',
    },
    {
        'code': 'TZA',
        'description': 'Tanzania, United Republic of',
        'type': 'TZA',
    },
    {
        'code': 'UGA',
        'description': 'Uganda',
        'type': 'UGA',
    },
    {
        'code': 'UKR',
        'description': 'Ukraine',
        'type': 'UKR',
    },
    {
        'code': 'UMI',
        'description': 'United States Minor Outlying Islands',
        'type': 'UMI',
    },
    {
        'code': 'RKS',
        'description': 'Kosovo',
        'type': 'RKS',
    },
    {
        'code': 'URY',
        'description': 'Uruguay',
        'type': 'URY',
    },
    {
        'code': 'USA',
        'description': 'United States of America',
        'type': 'USA',
    },
    {
        'code': 'UZB',
        'description': 'Uzbekistan',
        'type': 'UZB',
    },
    {
        'code': 'VAT',
        'description': 'Holy See',
        'type': 'VAT',
    },
    {
        'code': 'VCT',
        'description': 'Saint Vincent and the Grenadines',
        'type': 'VCT',
    },
    {
        'code': 'VEN',
        'description': 'Venezuela (Bolivarian Republic of)',
        'type': 'VEN',
    },
    {
        'code': 'VGB',
        'description': 'Virgin Islands (British)',
        'type': 'VGB',
    },
    {
        'code': 'VIR',
        'description': 'Virgin Islands (U.S.)',
        'type': 'VIR',
    },
    {
        'code': 'VNM',
        'description': 'Viet Nam',
        'type': 'VNM',
    },
    {
        'code': 'VUT',
        'description': 'Vanuatu',
        'type': 'VUT',
    },
    {
        'code': 'WLF',
        'description': 'Wallis and Futuna',
        'type': 'WLF',
    },
    {
        'code': 'WSM',
        'description': 'Samoa',
        'type': 'WSM',
    },
    {
        'code': 'YEM',
        'description': 'Yemen',
        'type': 'YEM',
    },
    {
        'code': 'ZAF',
        'description': 'South Africa',
        'type': 'ZAF',
    },
    {
        'code': 'ZMB',
        'description': 'Zambia',
        'type': 'ZMB',
    },
    {
        'code': 'ZWE',
        'description': 'Zimbabwe',
        'type': 'ZWE',
    },
]

const CITIES = [
    'Tirana',
    'Durrësi',
    'Shkodra',
    'Elbasani',
    'Vlora',
    'Korça',
    'Fieri',
    'Berati',
    'Lushnja',
    'Pogradeci',
    'Kavaja',
    'Laçi',
    'Lezha',
    'Kukësi',
    'Gjirokastra',
    'Patosi',
    'Kruja',
    'Kuçova',
    'Saranda',
    'Peshkopia',
    'Burreli',
    'Cërriku',
    'Çorovoda',
    'Shijaku',
    'Librazhdi',
    'Tepelena',
    'Gramshi',
    'Poliçani',
    'Bulqiza',
    'Përmeti',
    'Fushë-Kruja',
    'Kamëz',
    'Rrësheni',
    'Ballshi',
    'Mamurrasi',
    'Bajram Curri',
    'Erseka',
    'Peqini',
    'Divjaka',
    'Selenica',
    'Bilishti',
    'Roskoveci',
    'Këlcyra',
    'Puka',
    'Memaliaj',
    'Rrogozhina',
    'Ura Vajgurore',
    'Himara',
    'Delvina',
    'Vora',
    'Kopliku',
    'Maliqi',
    'Përrenjasi',
    'Shtërmeni',
    'Kruma',
    'Libohova',
    'Orikumi',
    'Fushë-Arrëza',
    'Shëngjini',
    'Rubiku',
    'Miloti',
    'Leskoviku',
    'Konispoli',
    'Krasta',
    'Kërraba',
]

export {
    STATE_LIST,
    CITIES,
}

