
import Color from 'color'

import { styleSheetFactory } from '../../Themes/Colors'


const styles = styleSheetFactory(colors => ({
    container: {
        flex: 1,
        backgroundColor: colors.background,
    },
    noteWrapper: {
        marginVertical: 20,
        marginHorizontal: 15,
        padding: 15,
        backgroundColor: colors.white,
        borderRadius: 7,
    },
    separator: {
        height: 7,
        // flex: 1,
        backgroundColor: colors.background,
    },
    infoWrapper: {
        flexDirection: 'row',
        marginBottom: 5,
    },
    icon: {
        marginRight: 5,
    },
    listStyle: {
        flex: 1,
    },
    listContainerStyle: {
        flexGrow: 1,
        paddingHorizontal: 15,
        // paddingTop: 10,
        paddingBottom: 30,
    },
    button: {
        marginHorizontal: 15,
        marginBottom: 15,
    },

}))

const separatorStyles = styleSheetFactory(colors => ({
    container: {
        height: 1,
        backgroundColor: colors.separator,
    },
}))

const itemStyles = styleSheetFactory(colors => ({
    container: {
        backgroundColor: colors.tertiaryBackground,
        height: 70,
        // marginVertical: 3,
        // borderRadius: 7,
        overflow: 'hidden',
    },
    firstItem_true: {
        borderTopLeftRadius: 7,
        borderTopRightRadius: 7,
        overflow: 'hidden',
    },
    lastItem_true: {
        borderBottomLeftRadius: 7,
        borderBottomRightRadius: 7,
        overflow: 'hidden',
    },
    touchable: {
        flexGrow: 1,
        justifyContent: 'space-between',
        alignItems: 'center',
        flexDirection: 'row',
    },
    logo: {
        width: 70,
        height: 70,
        backgroundColor: Color(colors.primaryText)
            .fade(0.65)
            .toString(),
        justifyContent: 'center',
        alignItems: 'center',
    },
    detailsContainer: {
        flexGrow: 1,
        flexShrink: 1,
        paddingHorizontal: 15,
    },
    rightElement: {
        flexShrink: 0,
        marginRight: 15,
    },
    selector: {
        marginRight: 15,
    },
}))

export { separatorStyles, itemStyles }
export default styles
