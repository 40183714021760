import React from 'react'
import { View } from 'react-native'
import { useTheme } from 'react-native-themed-styles'
import PropTypes from 'prop-types'

import Icon from '../Icon'

import themedStyles from './AuthWrapper.Styles'

import images from '../../Themes/Images'

function AuthWrapper(props) {
  const {
    children,
    costumizedContainer,
    withLogo,
  } = props
  const [styles] = useTheme(themedStyles)

  return (
    <View style={[styles.container, costumizedContainer]}>
    { withLogo && <View style={styles.iconWrapper}>
        <Icon source={images.vfLogo} size={48} />
      </View>}
      {children}
    </View>
  )
}
AuthWrapper.defaultProps = {
  withLogo: true,

}
AuthWrapper.propTypes = {
  children: PropTypes.any,
  costumizedContainer: PropTypes.object,
  withLogo: PropTypes.bool,

}

export default AuthWrapper
