/* eslint-disable react-native/no-inline-styles */
/* eslint-disable react/prop-types */
import React, { forwardRef, useEffect, useRef, useState } from 'react'
import moment from 'moment'
import parse, { attributesToProps, domToReact } from 'html-react-parser'
import ReactToPDF from 'react-to-pdf'
import QRCode from 'react-qr-code'

import { INF } from './Helpers'
import { useTemplateLanguage } from './TemplatesLanguage'
import useUnits from './Units'

const getPageMargins = () => {
    const marginTop = '20px'
    const marginRight = '20px'
    const marginBottom = '20px'
    const marginLeft = '20px'
    return `@page { margin: ${marginTop} ${marginRight} ${marginBottom} ${marginLeft} !important; }`
}

const PAYMENT_METHOD = {
    BANKNOTE: 'payment_banknote_description',
    CARD: 'payment_card_description',
    CHECK: 'payment_check_description',
    SVOUCHER: 'payment_voucher_description',
    COMPANY: 'payment_company_description',
    ORDER: 'payment_order_description',
    ACCOUNT: 'payment_account_description',
    FACTORING: 'payment_factoring_description',
    COMPENSATION: 'payment_compensation_description',
    TRANSFER: 'payment_transfer_description',
    WAIVER: 'payment_waiver_description',
    KIND: 'payment_kind_description',
    OTHER: 'payment_other_description',

}

const parseOptionItem = {
    replace: ({ attribs, children }) => {
        if (!attribs) {
            return
        }

        if (attribs.id === 'td') {
            const props = attributesToProps(attribs)

            return <td {...props} >{domToReact(children, parseOptionItem)}</td>
        }
        if (attribs.id === 'div') {
            const props = attributesToProps(attribs)
            return <div {...props} >{domToReact(children, parseOptionItem)}</div>
        }
        if (attribs.id === 'table') {
            const props = attributesToProps(attribs)
            return <table {...props} >{domToReact(children, parseOptionItem)}</table>
        }
    },
}


const ConvertReceiptWeb = forwardRef((props, ref) => {
    const {
        transaction,
        cashBuyer,
        change,
        nipt,
        company,
        location,
        customer,
    } = props

    const {
        order,
        createdAt,
        receiptNumber,
        employee,
        qrCode,
        FIC,
        IIC,
        invoiceNumber,
        invoiceType,
        softwareCode,
        operatorCode,
        tender,
        employeeDetails,
    } = transaction


    const { translate } = useTemplateLanguage()
    const totalMoney = tender?.additionalAmountMoney || tender?.amountMoney
    const businUnitCode = transaction?.location?.businUnitCode
    const shippingAdress = order?.shippingAddress || {}
    const bankAccount = order?.bankAccount || {}
    const orderSubType = order?.orderSubType?.split('_')?.[0] || null
    const currency = tender?.cashDetails?.currency || {}
    const supplyDateOrPeriod = order?.supplyDateOrPeriod || {}
    const printItems = () => {
        const l = (order?.lineItems || []).length
        let r = ''
        for (let i = 0; i < l; i++) {
            const item = order?.lineItems[i]
            const price = `${INF.format(item.totalMoney?.amount || 0)} ${item.totalMoney?.currency}`
            r += `
            <table style="table-layout:fixed; width:100%; border-bottom: 1px solid #ccc; padding:7px 10px; border-collapse:separate;">
      <tr>
          <td style="text-align:left; padding:0px 0px;font-size: 16px;">
          ${item.itemName}
          </td>
          <td  style="text-align:right; padding:0px 0px; font-size: 16px;">
          ${price}
          </td>
      </tr>
      <tr>
          <td  style="text-align:left; padding:0px 0px;" colspan="2">
              <div style="font-size: 12px; padding:1px 0;">${item.quantity || 0} ${translate(item?.measurementUnitId)} X ${INF.format(item.basePriceMoney?.amount || 0,)} ${item.basePriceMoney?.currency}</div>`
            for (let j = 0; j < (item?.modifiers?.length || 0); j++) {
                const modifier = item?.modifiers[j]
                r += `<div  style="font-size: 12px;padding:1px 0;" >${modifier.name} (${INF.format(modifier.appliedMoney?.amount || 0)} ${modifier.appliedMoney?.currency})</div>`
            }

            for (let j = 0; j < (item?.appliedDiscounts?.length || 0); j++) {
                const discount = item?.appliedDiscounts[j]
                if (discount.percentage) {
                    r += ` <div   style="font-size: 12px;padding:1px 0;" >${discount.name} (${discount.percentage || 0}%)</div>`
                } else {
                    r += ` <div  style="font-size: 12px;padding:1px 0;" >${discount.name} (${INF.format(discount.appliedMoney?.amount || 0)} ${discount.appliedMoney?.currency})</div>`
                }
            }
            r += `</td>  
            </tr> 
            </table>
            `
        }
        return r
    }

    const printTaxes = () => {
        const l = (order?.orderGroupedTaxes || []).length
        let r = ''
        for (let i = 0; i < l; i++) {
            const t = order?.orderGroupedTaxes?.[i] || {}
            const p = `${INF.format(t.VATAmt?.amount || 0)} ${t.VATAmt?.currency}`
            r += ` 
      <td style="text-align:left;font-size: 13px; padding:3px 0">
      <b>${translate('receipt_tax')}(${t.VATRate}%) :</b> ${p}
      </td>
    `
        }
        return r
    }

    const printCustomer = () => {
        let r = ''
        if (Object.keys(customer).length > 0) {
            r += `
            <div style="font-size: 16px;font-weight: 700;">
            ${orderSubType === 'SELF' ? translate('SELLER') : translate('BUYER')}:
            </div>
            <div style="font-size: 13px">
            ${customer?.title}
            </div>
            <div style="font-size: 13px">
            ${customer?.address}
            </div>
       `
            if (customer?.nipt) {
                r += `<div  style="font-size: 13px"> ${translate('einvoice_buyertin_label')}: ${customer?.nipt}</div>`
            }
        }
        return r
    }

    const printOtherCurrency = () => {
        const addAmount = tender?.additionalAmountMoney || tender?.amountMoney
        const totAmount = tender?.amountMoney
        let r = ''
        if (addAmount?.currency !== totAmount?.currency) {
            r += `
            <tr>
                    <td style="text-align:left;font-size: 14px; padding:3px 0">
                    ${translate('receipt_currency_rate_label')}:
                    </td>
                    <td style="text-align:right;font-size: 14px; padding:3px 0">
                     ${currency?.exRate}
                    </td>
                </tr>
            <tr>
                    <td style="text-align:left;font-size: 14px; padding:3px 0">
                    ${translate('receipt_foreign_currency_label')}  ${totAmount?.currency}:
                    </td>
                    <td style="text-align:right;font-size: 14px; padding:3px 0">
                    ${INF.format(totAmount?.amount)} ${totAmount?.currency}
                    </td>
                </tr>`
        }
        return r
    }

    const printShippingAdress = () => {
        let r = ''
        if (Object.keys(shippingAdress).length > 0) {
            r = `
                <table style="table-layout:fixed; width:100%; padding:7px 10px;border-top: 1px dashed #ccc;">
                <tr>
                <td "textAlign: 'left'; padding: '0px 0'; fontSize: '20px';">
                         ${translate('e_invoice_remittances_label')}:
                        </td>
                    </tr>
                    <tr>
                    <td style="text-align:left;font-size: 12px; padding:0px 0">
                    <b> ${translate('e_invoice_recipient_name_label')}:</b> ${shippingAdress?.externalId}
                    </td>
                </tr>
                    <tr>
                        <td style="text-align:left;font-size: 12px; padding:0px 0">
                        <b> ${translate('supporting_invoice_address_label')}:</b> ${shippingAdress?.addressLine1}, ${shippingAdress?.locality}, ${shippingAdress?.country}
                        </td>
                    </tr>
                </table>`
        }

        return r

    }

    const printBanckAccount = () => {
        let r = ''
        if (Object.keys(bankAccount).length > 0) {
            r = `
            <table style="table-layout:fixed; width:100%; padding:7px 10px;border-top: 1px dashed #ccc;">
                <tr>
                   <td "textAlign: 'left'; padding: '0px 0'; fontSize: '20px';">
                     ${translate('receipt_payment_info_label')}:
                    </td>
                </tr>
                <tr>
                    <td style="text-align:left;font-size: 12px; padding:0px 0">
                     <b> ${translate('receipt_payment_name_label')}:</b> ${bankAccount?.bankName}
                    </td>
                </tr>
                <tr>
                <td style="text-align:left;font-size: 12px; padding:0px 0">
                 <b> ${translate('receipt_payment_iban_label')}:</b> ${bankAccount?.iban}
                </td>
            </tr>
            <tr>
            <td style="text-align:left;font-size: 12px; padding:0px 0">
             <b> ${translate('receipt_payment_swift_label')}:</b> ${bankAccount?.swiftCode}
            </td>
        </tr>
            </table>`
        }

        return r

    }

    const renderFooter = () => {
        let r = ''
        if (Object.keys(customer).length > 0) {
            r = ` <table style="width:100%; padding:10px 10px;border-top:1px dashed black;">
            <tr>
                <td>
                <div style="text-align:center;font-size: 14px; padding:0px">
                    ${translate('e_invoice_buyer_label')}
                </div>
                <div style="text-align:center;font-size: 12px; padding:0px">
                     ${translate('e_invoice_signature_field_label')}
                </div>
                <div style="text-align:center;padding:10px 0; border-bottom: 1px solid black;">
                </div>
            </td>
            <td>
                <div style="text-align:center;font-size: 14px; padding:0px">
                ${translate('e_invoice_carrier_label')}
                </div>
                <div style="text-align:center;font-size: 12px; padding:0px">
            ${translate('e_invoice_signature_field_label')}
    
                </div>
                <div style="text-align:center;padding:10px 0; border-bottom: 1px solid black;">
                </div>
            </td>
            <td>
                <div style="text-align:center;font-size: 14px; padding:0px">
                ${translate('e_invoice_seller_label')}
                </div>
                <div style="text-align:center;font-size: 12px; padding:0px">
                 ${translate('e_invoice_signature_field_label')}
    
                </div>
                <div style="text-align:center;padding:10px 0; border-bottom: 1px solid black;">
                </div>
            </td>
            </tr>
        </table>`
        }

        return r
    }

    const PAYMENT_TYPE = {
        NONCASH: {
            null: {
                title: 'receipt_invoice_type',
                description: translate('receipt_label_type_noncash'),
            },
            EXPORT: {
                title: 'receipt_invoice_type',
                description: translate('receipt_label_type_export'),
            },
            SELF: {
                title: 'receipt_invoice_type',
                description: translate('receipt_label_type_reverse_charge'),
            },
        },
        CASH: {
            null: {
                title: 'receipt_payment_type_cash',
                description: `${INF.format(cashBuyer)} ${totalMoney?.currency}`,
            },
            SELF: {
                title: 'receipt_invoice_type',
                description: translate('receipt_label_type_reverse_charge'),
            },
        },
    }

    return (
        <>
            <style>
                {getPageMargins()},
            </style>
            <div style={{ width: '210mm', borderRadius: '8px' }} ref={ref}>
                <table style={{ tableLayout: 'fixed', width: '100%', padding: '16px 10px 16px 10px', borderCollapse: 'separate' }}>
                    <tr>
                        <td>
                            <div style={{ fontWeight: 700, fontSize: '16px' }} >
                                {company}
                            </div>

                            <div style={{ fontSize: '13px' }}>
                                {location}
                            </div>

                            <div style={{ fontSize: '13px' }} >
                                {translate('receipt_nipt_label')}: {nipt}
                            </div>
                            {businUnitCode &&
                                <div style={{ fontSize: '13px' }} >
                                    {translate('e_invoice_bussines_unit_code_label')}: {businUnitCode}
                                </div>
                            }
                            <div style={{ fontSize: '13px' }}>
                                {receiptNumber}
                            </div>
                        </td>
                        <tr>
                            <td>
                                {parse(printCustomer(), parseOptionItem)}
                            </td>
                        </tr>
                    </tr>

                </table>
                <table style={{ tableLayout: 'fixed', width: ' 100%', borderTop: '1px dashed #ccc' }}>
                    <tr>
                        <td style={{
                            textAlign: 'center',
                            padding: '16px 10px',
                            fontSize: 18,
                            fontWeight: 700,
                        }}>
                            {translate('invoice_header')}
                        </td>
                    </tr>
                </table>
                <table style={{ tableLayout: 'fixed', width: ' 100%', backgroundColor: '#eee' }}>
                    <tr>
                        <td style={{ textAlign: 'left', padding: '5px 10px', fontSize: '16px' }}>
                            {translate('receipt_preview_items')}
                        </td>
                        <td style={{ textAlign: 'right', padding: '5px 10px', fontSize: '16px' }}>
                            {translate('receipt_item_price_label')}
                        </td>
                    </tr>
                </table>
                {/* item */}
                {parse(printItems(), parseOptionItem)}
                {/* <!-- Totals --> */}
                <table style={{ tableLayout: 'fixed', width: ' 100%', padding: '7px 10px', borderCollapse: 'separate' }}>
                    <tr>
                        <td style={{ textAlign: 'left', padding: '3px 0', fontSize: '13px' }}>
                            {translate('receipt_subtotal')}:
                        </td>
                        <td style={{ textAlign: 'right', padding: '3px 0', fontSize: '13px' }}>
                            {INF.format(
                                order.totalMoney?.amount +
                                order.totalDiscountMoney?.amount -
                                order?.totalTaxMoney?.amount || 0,
                            )} {order.totalMoney?.currency}
                        </td>
                    </tr>
                    <tr>
                        <td style={{ textAlign: 'left', padding: '3px 0', fontSize: '13px', color: '#e60000' }}>
                            {translate('receipt_discount_line')}:
                        </td>
                        <td style={{ textAlign: 'right', padding: '3px 0', fontSize: '13px', color: 'e60000' }}>
                            {INF.format(order.totalDiscountMoney?.amount || 0)}  {order.totalDiscountMoney?.currency}
                        </td>
                    </tr>
                    <tr>
                        <td style={{ textAlign: 'left', padding: '3px 0', fontSize: '20px', fontWeight: 'bold' }} >
                            {translate('receipt_total')}:
                        </td>
                        <td style={{ textAlign: 'right', padding: '3px 0', fontSize: '20px', fontWeight: 'bold' }}>
                            {INF.format(totalMoney?.amount)} {totalMoney?.currency}
                        </td>
                    </tr>
                    {parse(printOtherCurrency(), parseOptionItem)}
                </table>
                <table style={{
                    width: '100%',
                    padding: '7px 10px',
                    borderTop: '1px dashed #ccc',
                    borderCollapse: 'separate',
                }}>
                    <tr>
                        {parse(printTaxes(), parseOptionItem)}
                    </tr>
                </table>
                <table style={{ tableLayout: 'fixed', width: ' 100%', padding: '7px 10px', borderCollapse: 'separate', borderTop: '1px dashed #ccc' }} >
                    <tr>
                        <td style={{ textAlign: 'left', padding: '3px 0', fontSize: '14px' }} >
                            <b> {translate(PAYMENT_TYPE?.[invoiceType]?.[orderSubType]?.title)}: </b> {PAYMENT_TYPE?.[invoiceType]?.[orderSubType]?.description}
                        </td>
                        <td style={{ padding: '3px 0', fontSize: '14px' }}>
                            <b> {translate('receipt_change')}:</b> {INF.format(change)} {totalMoney?.currency}
                        </td>
                    </tr>
                    <tr>
                        <td style={{ textAlign: 'left', padding: '3px 0', fontSize: '14px' }} >
                            <b>{translate('receipt_total_method')} :</b> {translate(PAYMENT_METHOD[tender?.type])}
                        </td>
                        <td style={{ padding: '3px 0', fontSize: '14px' }} >
                            {/* <b> {translate('SELLER')}:</b> {employee?.split('@')?.[0]} */}
                            <b> {translate('SELLER')}:</b> {employeeDetails ? employeeDetails?.firstName + ' ' + employeeDetails?.lastName : employee?.split('@')?.[0]}
                        </td>
                    </tr>
                    <tr>
                        <td style={{ textAlign: 'left', padding: '3px 0', fontSize: '14px' }}>
                            <b> {translate('receipt_invoice_number')}:</b> {invoiceNumber}
                        </td>
                        <td style={{ padding: '3px 0', fontSize: '14px' }}>
                            <b> {translate('receipt_created_at')}:</b> {moment(createdAt).format('DD-MM-YY HH:mm')}
                        </td>
                    </tr>
                    <tr>
                        <td style={{ textAlign: 'left', padding: '3px 0', fontSize: '14px', wordBreak: 'break-all' }}>
                            <b> {translate('receipt_iic_label')}:</b> {IIC}

                        </td>
                        <td style={{ textAlign: 'left', padding: '3px 0', fontSize: '14px' }}>
                            <b>{translate('receipt_operator_code_label')}:</b> {operatorCode}
                        </td>
                    </tr>
                    <tr>
                        <td style={{ padding: '3px 0', fontSize: '14px', wordBreak: 'break-all' }} >
                            <b> {translate('receipt_fic_label')}:</b> {FIC}

                        </td>
                        <td style={{ padding: '3px 0', fontSize: '14px' }}>
                            <b>{translate('receipt_software_code_label')}: </b>{softwareCode}
                        </td>
                    </tr>
                    {Object.keys(supplyDateOrPeriod).length > 0 &&
                        <tr>
                            <td style={{ padding: '3px 0', fontSize: '14px' }}>
                                <b> {translate('payment_supplying_period_label')}:</b> {`${moment(supplyDateOrPeriod?.startDate).format('DD/MM/YYYY')} - ${moment(supplyDateOrPeriod?.endDate).format('DD/MM/YYYY')}`}
                            </td>
                        </tr>
                    }
                </table>
                {parse(printBanckAccount(), parseOptionItem)}
                {parse(printShippingAdress(), parseOptionItem)}

                {parse(renderFooter(), parseOptionItem)}

                <table style={{ tableLayout: 'fixed', width: ' 100%', padding: '7px 10px', borderCollapse: 'separate' }} >
                    <tr>
                        <td style={{ textAlign: 'center' }} >
                            <QRCode value={qrCode} size={200} />
                            {/* <img src={`http://chart.apis.google.com/chart?cht=qr&chs=200x200&chl=${qrCodeWithSlashes}&chld=H|0`} width="200px" height="200px" /> */}
                        </td>
                    </tr>
                </table>

                <table style={{ tableLayout: 'fixed', width: ' 100%', padding: '7px 10px', borderCollapse: 'separate' }} >
                    <tr>
                        <td style={{ textAlign: 'center', fontSize: '13px', fontWeight: 700 }}>
                            {translate('receipt_generated_by')} Vodafone eBiznes
                        </td>
                    </tr>
                </table>
            </div>
        </>
    )
})
const ConvertOldInvoiceWeb = forwardRef((props, ref) => {
    const {
        transaction,
    } = props

    const { translate } = useTemplateLanguage()
    return (
        <>
            <style>
                {getPageMargins()},
            </style>
            <div style={{ width: '210mm', borderRadius: '8px' }} ref={ref}>

                <table style={{ tableLayout: 'fixed', width: '100%', borderTop: '1px dashed #ccc' }}>
                    <tbody>
                        <tr>
                            <td style={{
                                textAlign: 'center',
                                padding: '16px 10px',
                                fontSize: 18,
                                fontWeight: 700,
                            }}>
                                {translate('invoice_header')}
                            </td>
                        </tr>
                    </tbody>
                </table>
                <table style={{ tableLayout: 'fixed', width: '100%', padding: '7px 10px', borderCollapse: 'separate', borderTop: '1px dashed #ccc' }} >
                    <tbody>
                        <tr>
                            <td style={{ textAlign: 'left', padding: '3px 0', fontSize: '14px' }} >
                                <b> {translate('receipt_nsfl_label')}: </b> {transaction?.IICRef}
                            </td>
                            <td style={{ padding: '3px 0', fontSize: '14px' }}>
                                <b> {translate('receipt_invoice_number')}:</b> {transaction?.InvoiceNumber}
                            </td>
                        </tr>
                        <tr>
                            <td style={{ textAlign: 'left', padding: '3px 0', fontSize: '14px' }} >
                                <b>{translate('receipt_created_at')} :</b> {moment(transaction?.InvoiceDate).format('MM/DD/YYYY')}
                            </td>
                        </tr>
                    </tbody>
                </table>
                <table style={{ tableLayout: 'fixed', width: '100%', padding: '7px 10px', borderCollapse: 'separate', borderTop: '1px dashed #ccc' }} >
                    <tbody>
                        <tr>
                            <td>
                                <div style={{ fontSize: '16px', fontWeight: '700' }}>
                                    {translate('BUYER')}:
                                </div>
                                <div style={{ fontSize: '13px' }}>
                                    {transaction?.NUIS}
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <table style={{ tableLayout: 'fixed', width: '100%', padding: '7px 10px', borderCollapse: 'separate', borderTop: '1px dashed #ccc' }} >
                    <tbody>
                        <tr>
                            <td>
                                <div style={{ fontSize: '16px', fontWeight: '700' }}>
                                    {translate('SELLER')}:
                                </div>
                                <div style={{ fontSize: '13px' }}>
                                    {transaction?.TargetTaxpayerName}
                                </div>
                                <div style={{ fontSize: '13px' }}>
                                    {`${translate('receipt_nipt_label')}: ${transaction?.TargetTaxpayerNUIS}`}
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <table style={{ tableLayout: 'fixed', width: ' 100%', padding: '7px 10px', borderCollapse: 'separate' }} >
                    <tbody>
                        <tr>
                            <td style={{ textAlign: 'center', fontSize: '13px', fontWeight: '700' }}>
                                {translate('receipt_generated_by')} Vodafone eBiznes
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </>
    )
})
const ConvertOldInvoicetWeb58mm = forwardRef((props, ref) => {
    const {
        transaction,

    } = props

    const { translate } = useTemplateLanguage()
    return (
        <div style={{ width: '58mm', borderRadius: '8px' }} ref={ref}>
            <table style={{ tableLayout: 'fixed', width: ' 100%', borderTop: '1px dashed #ccc' }}>
                <tr>
                    <td style={{
                        textAlign: 'center',
                        padding: '16px 10px',
                        fontSize: 18,
                        fontWeight: 700,
                    }}>
                        {translate('invoice_header')}
                    </td>
                </tr>
            </table>
            <table style={{ tableLayout: 'fixed', width: ' 100%', padding: '7px 10px', borderTop: '1px dashed #ccc' }} >
                <tr>
                    <td style={{ textAlign: 'left', padding: '0 0', fontSize: '14px' }} >
                        <b> {translate('receipt_nsfl_label')}: </b> {transaction?.IICRef}

                    </td>
                </tr>
                <tr>
                    <td style={{ padding: '0 0', fontSize: '14px' }}>
                        <b>{translate('receipt_invoice_number')}:</b> {transaction?.InvoiceNumber}
                    </td>
                </tr>
                <tr>
                    <td style={{ textAlign: 'left', padding: '0 0', fontSize: '14px' }} >
                        <b>{translate('receipt_created_at')} :</b> {moment(transaction?.InvoiceDate).format('MM/DD/YYYY')}

                    </td>
                </tr>

            </table>
            <table style={{ tableLayout: 'fixed', width: '100%', padding: '7px 10px', borderCollapse: 'separate', borderTop: '1px dashed #ccc' }} >
                <tbody>
                    <tr>
                        <td>
                            <div style={{ fontSize: '16px', fontWeight: '700' }}>
                                {translate('BUYER')}:
                            </div>
                            <div style={{ fontSize: '13px' }}>
                                {transaction?.NUIS}
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
            <table style={{ tableLayout: 'fixed', width: '100%', padding: '7px 10px', borderCollapse: 'separate', borderTop: '1px dashed #ccc' }} >
                <tbody>
                    <tr>
                        <td>
                            <div style={{ fontSize: '16px', fontWeight: '700' }}>
                                {translate('SELLER')}:
                            </div>
                            <div style={{ fontSize: '13px' }}>
                                {transaction?.TargetTaxpayerName}
                            </div>
                            <div style={{ fontSize: '13px' }}>
                                {`${translate('receipt_nipt_label')}: ${transaction?.TargetTaxpayerNUIS}`}
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
            <table style={{ tableLayout: 'fixed', width: ' 100%', padding: '7px 10px', borderCollapse: 'separate' }} >
                <tr>
                    <td style={{ textAlign: 'center', fontSize: '13px', fontWeight: 700 }}>
                        {translate('receipt_generated_by')} Vodafone eBiznes
                    </td>
                </tr>
            </table>
        </div>
    )
})

const ConvertReceiptWeb58mm = forwardRef((props, ref) => {
    const {
        transaction,
        cashBuyer,
        change,
        nipt,
        company,
        location,
        customer,
    } = props

    const {
        order,
        createdAt,
        receiptNumber,
        employee,
        qrCode,
        FIC,
        IIC,
        invoiceNumber,
        invoiceType,
        softwareCode,
        operatorCode,
        tender,
        employeeDetails,
    } = transaction
    const { translate } = useTemplateLanguage()
    const totalMoney = tender?.additionalAmountMoney || tender?.amountMoney
    const businUnitCode = transaction?.location?.businUnitCode
    const shippingAdress = order?.shippingAddress || {}
    const bankAccount = order?.bankAccount || {}
    const orderSubType = order?.orderSubType?.split('_')?.[0] || null
    const currency = tender?.cashDetails?.currency || {}
    const supplyDateOrPeriod = order?.supplyDateOrPeriod || {}

    const printItems = () => {
        const l = (order?.lineItems || []).length
        let r = ''
        for (let i = 0; i < l; i++) {
            const item = order?.lineItems[i]
            const price = `${INF.format(item.totalMoney?.amount || 0)} ${item.totalMoney?.currency}`
            r += `
            <table style="table-layout:fixed; width:100%; border-bottom: 1px solid #ccc; padding:7px 10px; border-collapse:separate;">
      <tr>
          <td style="text-align:left; padding:0px 0px;font-size: 16px;word-break: break-all;">
          ${item.itemName}
          </td>
          <td  style="text-align:right; padding:0px 0px; font-size: 16px;">
          ${price}
          </td>
      </tr>
      <tr>
          <td  style="text-align:left; padding:0px 0px;" colspan="2">
              <div style="font-size: 12px; padding:1px 0;">${item.quantity || 0} ${translate(item?.measurementUnitId)} X ${INF.format(item.basePriceMoney?.amount || 0,)} ${item.basePriceMoney?.currency}</div>`
            for (let j = 0; j < (item?.modifiers?.length || 0); j++) {
                const modifier = item?.modifiers[j]
                r += `<div  style="font-size: 12px;padding:1px 0;" >${modifier.name} (${INF.format(modifier.appliedMoney?.amount || 0)} ${modifier.appliedMoney?.currency})</div>`
            }

            for (let j = 0; j < (item?.appliedDiscounts?.length || 0); j++) {
                const discount = item?.appliedDiscounts[j]
                if (discount.percentage) {
                    r += ` <div   style="font-size: 12px;padding:1px 0;" >${discount.name} (${discount.percentage || 0}%)</div>`
                } else {
                    r += ` <div  style="font-size: 12px;padding:1px 0;" >${discount.name} (${INF.format(discount.appliedMoney?.amount || 0)} ${discount.appliedMoney?.currency})</div>`
                }
            }
            r += `</td>  
            </tr> 
            </table>
            `
        }
        return r
    }

    const printTaxes = () => {
        const l = (order?.orderGroupedTaxes || []).length
        let r = ''
        for (let i = 0; i < l; i++) {
            const t = order?.orderGroupedTaxes?.[i] || {}
            const p = `${INF.format(t.VATAmt?.amount || 0)} ${t.VATAmt?.currency}`
            r += ` 
      <td style="text-align:left;font-size: 13px; padding:3px 0">
      <b>${translate('receipt_tax')} (${t.VATRate}%) :</b> ${p}
      </td>
    `
        }
        return r
    }

    const printCustomer = () => {
        let r = ''
        if (Object.keys(customer).length > 0) {
            r += `
            <div style="font-size: 16px;font-weight: 700;">
            ${translate('BUYER')}:
            </div>
            <div style="font-size: 13px">
            ${customer?.title}
            </div>
            <div style="font-size: 13px">
            ${customer?.address}
            </div>
       `
            if (customer?.nipt) {
                r += `<div  style="font-size: 13px">${translate('einvoice_buyertin_label')}: ${customer?.nipt}</div>`
            }
        }
        return r
    }

    const printOtherCurrency = () => {
        const addAmount = tender?.additionalAmountMoney || tender?.amountMoney
        const totAmount = tender?.amountMoney
        let r = ''

        if (addAmount?.currency !== totAmount?.currency) {
            r += `
            <tr>
                    <td style="text-align:left;font-size: 14px; padding:3px 0">
                    ${translate('receipt_currency_rate_label')}:
                    </td>
                    <td style="text-align:right;font-size: 14px; padding:3px 0">
                     ${currency?.exRate}
                    </td>
                </tr>
            <tr>
            <tr>
                    <td style="text-align:left;font-size: 14px; padding:3px 0">
                    ${translate('receipt_foreign_currency_label')}  ${totAmount?.currency} :
                    </td>
                    <td style="text-align:right;font-size: 14px; padding:3px 0">
                    ${INF.format(totAmount?.amount)} ${totAmount?.currency}
                    </td>
            </tr>`
        }
        return r
    }

    const printShippingAdress = () => {
        let r = ''
        if (Object.keys(shippingAdress).length > 0) {

            r = `
                <table style="table-layout:fixed; width:100%; padding:7px 10px;border-top: 1px dashed #ccc;">
                <tr>
                <td "textAlign: 'left'; padding: '0px 0'; fontSize: '20px';">
                         ${translate('e_invoice_remittances_label')}:
                        </td>
                    </tr>
                    <tr>
                    <td style="text-align:left;font-size: 12px; padding:0px 0">
                    <b> ${translate('e_invoice_recipient_name_label')}:</b> ${shippingAdress?.externalId}
                    </td>
                </tr>
                    <tr>
                        <td style="text-align:left;font-size: 12px; padding:0px 0">
                        <b> ${translate('supporting_invoice_address_label')}:</b> ${shippingAdress?.addressLine1}, ${shippingAdress?.locality}, ${shippingAdress?.country}
                        </td>
                    </tr>
                </table>`
        }

        return r

    }

    const printBanckAccount = () => {
        let r = ''
        if (Object.keys(bankAccount).length > 0) {
            r = `
            <table style="table-layout:fixed; width:100%; padding:7px 10px;border-top: 1px dashed #ccc;">
                <tr>
                   <td "textAlign: 'left'; padding: '0px 0'; fontSize: '20px';">
                     ${translate('receipt_payment_info_label')}:
                    </td>
                </tr>
                <tr>
                    <td style="text-align:left;font-size: 12px; padding:0px 0">
                     <b> ${translate('receipt_payment_name_label')}:</b> ${bankAccount?.bankName}
                    </td>
                </tr>
                <tr>
                <td style="text-align:left;font-size: 12px; padding:0px 0">
                 <b> ${translate('receipt_payment_iban_label')}:</b> ${bankAccount?.iban}
                </td>
            </tr>
            <tr>
            <td style="text-align:left;font-size: 12px; padding:0px 0">
             <b> ${translate('receipt_payment_swift_label')}:</b> ${bankAccount?.swiftCode}
            </td>
            </tr>
            </table>`
        }

        return r

    }
    const renderFooter = () => {
        let r = ''
        if (Object.keys(customer).length > 0) {
            r = `
       
            <table style="width:100%; padding:10px 10px;border-top:1px dashed black;">
                <tr>
                    <td style="text-align:center;font-size: 12px; padding:0px">
                    ${translate('e_invoice_buyer_label')}
                    </td>
                </tr>
                <tr>
                    <td style="text-align:center;font-size: 10px; padding:0px">
                     ${translate('e_invoice_signature_field_label')}
                    </td>
                </tr>
                <tr>
                    <td style="text-align:center;padding:10px 0; border-bottom: 1px solid black;">
                    </td>
                </tr>
            </table>
            <table style="width:100%; padding:10px 10px;">
                <tr>
                    <td style="text-align:center;font-size: 12px; padding:0px">
                    ${translate('e_invoice_carrier_label')}
                    </td>
                </tr>
                <tr>
                    <td style="text-align:center;font-size: 10px; padding:0px">
                     ${translate('e_invoice_signature_field_label')}
                    </td>
                </tr>
                <tr>
                    <td style="text-align:center;padding:10px 0; border-bottom: 1px solid black;">
                    </td>
                </tr>
            </table>
            <table style="width:100%; padding:10px 10px;">
                <tr>
                    <td style="text-align:center;font-size: 12px; padding:0px">
                    ${translate('e_invoice_seller_label')}
                    </td>
                </tr>
                <tr>
                    <td style="text-align:center;font-size: 10px; padding:0px">
                     ${translate('e_invoice_signature_field_label')}
                    </td>
                </tr>
                <tr>
                    <td style="text-align:center;padding:10px 0; border-bottom: 1px solid black;">
                    </td>
                </tr>
            </table>`
        }

        return r
    }

    const PAYMENT_TYPE = {
        NONCASH: {
            null: {
                title: 'receipt_invoice_type',
                description: translate('receipt_label_type_noncash'),
            },
            EXPORT: {
                title: 'receipt_invoice_type',
                description: translate('receipt_label_type_export'),
            },
            SELF: {
                title: 'receipt_invoice_type',
                description: translate('receipt_label_type_reverse_charge'),
            },
        },
        CASH: {
            null: {
                title: 'receipt_payment_type_cash',
                description: `${INF.format(cashBuyer)} ${totalMoney?.currency}`,
            },
            SELF: {
                title: 'receipt_invoice_type',
                description: translate('receipt_label_type_reverse_charge'),
            },
        },
    }

    return (
        <div style={{ width: '58mm', borderRadius: '8px' }} ref={ref}>
            <table style={{ tableLayout: 'fixed', width: '100%', padding: '16px 10px 16px 10px', borderCollapse: 'separate' }}>
                <tr>
                    <td>
                        <div style={{ fontWeight: 700, fontSize: '16px' }} >
                            {company}
                        </div>

                        <div style={{ fontSize: '13px' }}>
                            {location}
                        </div>

                        <div style={{ fontSize: '13px' }} >
                            {translate('receipt_nipt_label')}: {nipt}
                        </div>

                        <div style={{ fontSize: '13px' }}>
                            {receiptNumber}
                        </div>
                    </td>
                </tr>
            </table>
            <table style={{ tableLayout: 'fixed', width: ' 100%', borderTop: '1px dashed #ccc' }}>
                <tr>
                    <td style={{
                        textAlign: 'center',
                        padding: '16px 10px',
                        fontSize: 18,
                        fontWeight: 700,
                    }}>
                        {translate('invoice_header')}
                    </td>
                </tr>
            </table>
            <table style={{ tableLayout: 'fixed', width: ' 100%', backgroundColor: '#eee' }}>
                <tr>
                    <td style={{ textAlign: 'left', padding: '5px 10px', fontSize: '16px' }}>
                        {translate('receipt_preview_items')}
                    </td>
                    <td style={{ textAlign: 'right', padding: '5px 10px', fontSize: '16px' }}>
                        {translate('receipt_item_price_label')}
                    </td>
                </tr>
            </table>
            {/* item */}
            {parse(printItems(), parseOptionItem)}
            {/* <!-- Totals --> */}
            <table style={{ tableLayout: 'fixed', width: ' 100%', padding: '7px 10px', borderCollapse: 'separate' }}>
                <tr>
                    <td style={{ textAlign: 'left', padding: '3px 0', fontSize: '13px' }}>
                        {translate('receipt_subtotal')}:
                    </td>
                    <td style={{ textAlign: 'right', padding: '3px 0', fontSize: '13px' }}>
                        {INF.format(
                            order.totalMoney?.amount +
                            order.totalDiscountMoney?.amount -
                            order?.totalTaxMoney?.amount || 0,
                        )} {order.totalMoney?.currency}
                    </td>
                </tr>
                <tr>
                    <td style={{ textAlign: 'left', padding: '3px 0', fontSize: '13px', color: '#e60000' }}>
                        {translate('receipt_discount_line')}:
                    </td>
                    <td style={{ textAlign: 'right', padding: '3px 0', fontSize: '13px', color: 'e60000' }}>
                        {INF.format(order.totalDiscountMoney?.amount || 0)}
                        {order.totalDiscountMoney?.currency}
                    </td>
                </tr>
                <tr>
                    <td style={{ textAlign: 'left', padding: '3px 0', fontSize: '16px', fontWeight: 'bold' }} >
                        {translate('receipt_total')}:
                    </td>
                    <td style={{ textAlign: 'right', padding: '3px 0', fontSize: '16px', fontWeight: 'bold' }}>
                        {INF.format(totalMoney?.amount)} {totalMoney?.currency}
                    </td>
                </tr>
                {parse(printOtherCurrency(), parseOptionItem)}
            </table>
            <table style={{
                width: '100%',
                padding: '7px 10px',
                borderTop: '1px dashed #ccc',
                borderCollapse: 'separate',
            }}>
                <tr>
                    {parse(printTaxes(), parseOptionItem)}
                </tr>
            </table>
            <table style={{ tableLayout: 'fixed', width: ' 100%', padding: '7px 10px', borderTop: '1px dashed #ccc' }} >

                <tr>
                    <td style={{ textAlign: 'left', padding: '0 0', fontSize: '14px' }} >
                        <b> {translate(PAYMENT_TYPE?.[invoiceType]?.[orderSubType]?.title)}: </b> {PAYMENT_TYPE?.[invoiceType]?.[orderSubType]?.description}

                    </td>
                </tr>
                <tr>
                    <td style={{ padding: '0 0', fontSize: '14px' }}>
                        <b>{translate('receipt_change')}:</b> {INF.format(change)} {totalMoney?.currency}
                    </td>
                </tr>
                <tr>
                    <td style={{ textAlign: 'left', padding: '0 0', fontSize: '14px' }} >
                        <b>{translate('receipt_total_method')} :</b> {translate(PAYMENT_METHOD[tender?.type])}

                    </td>
                </tr>
                <tr>
                    <td style={{ padding: '0 0', fontSize: '14px' }} >
                        {/* <b> {translate('SELLER')}:</b> {employee?.split('@')?.[0]} */}
                        <b> {translate('SELLER')}:</b> {employeeDetails ? employeeDetails?.firstName + ' ' + employeeDetails?.lastName : employee?.split('@')?.[0]}
                    </td>
                </tr>
                <tr>
                    <td style={{ textAlign: 'left', padding: '0 0', fontSize: '14px' }}>
                        <b>{translate('receipt_invoice_number')}:</b> {invoiceNumber}
                    </td>
                </tr>
                <tr>
                    <td style={{ padding: '0 0', fontSize: '14px' }}>
                        <b> {translate('receipt_created_at')}:</b> {moment(createdAt).format('DD-MM-YY HH:mm')}
                    </td>
                </tr>
                <tr>
                    <td style={{
                        padding: '3px 0',
                        fontSize: '14px',
                        wordBreak: ' break-all',
                    }}>
                        <b> {translate('receipt_iic_label')}:</b> {IIC}

                    </td>
                </tr>
                <tr>
                    <td style={{
                        padding: '0 0',
                        fontSize: '14px',
                        wordBreak: ' break-all',

                    }} >
                        <b> {translate('receipt_fic_label')}:</b> {FIC}

                    </td>
                </tr>

                {businUnitCode &&
                    <tr>
                        <td style={{ padding: '0 0', fontSize: '14px' }}>
                            <b>{translate('e_invoice_bussines_unit_code_label')} </b>: {businUnitCode}
                        </td>
                    </tr>
                }
                <tr>
                    <td style={{ textAlign: 'left', padding: '0 0', fontSize: '14px' }}>
                        <b>{translate('receipt_operator_code_label')}:</b> {operatorCode}
                    </td>
                </tr>
                <tr>
                    <td style={{ padding: '0 0', fontSize: '14px' }}>
                        <b> {translate('receipt_software_code_label')}: </b>{softwareCode}
                    </td>
                </tr>
                {Object.keys(supplyDateOrPeriod).length > 0 &&
                    <tr>
                        <td style={{ padding: '3px 0', fontSize: '14px' }}>
                            <b> {translate('payment_supplying_period_label')}:</b> {`${moment(supplyDateOrPeriod?.startDate).format('DD/MM/YYYY')} - ${moment(supplyDateOrPeriod?.endDate).format('DD/MM/YYYY')}`}
                        </td>
                    </tr>
                }
                <tr>
                    <td>
                        {parse(printCustomer(), parseOptionItem)}
                    </td>
                </tr>
            </table>
            {parse(printBanckAccount(), parseOptionItem)}
            {parse(printShippingAdress(), parseOptionItem)}
            {parse(renderFooter(), parseOptionItem)}

            <table style={{ tableLayout: 'fixed', width: ' 100%', padding: '7px 10px', borderCollapse: 'separate' }} >
                <tr>
                    <td style={{ textAlign: 'center' }} >
                        <QRCode value={qrCode} size={200} />

                        {/* <img src={`http://chart.apis.google.com/chart?cht=qr&chs=200x200&chl=${qrCodeWithSlashes}&chld=H|0`} width="200px" height="200px" /> */}
                    </td>
                </tr>
            </table>

            <table style={{ tableLayout: 'fixed', width: ' 100%', padding: '7px 10px', borderCollapse: 'separate' }} >
                <tr>
                    <td style={{ textAlign: 'center', fontSize: '13px', fontWeight: 700 }}>
                        {translate('receipt_generated_by')} Vodafone eBiznes
                    </td>
                </tr>
            </table>
        </div>
    )
})

const ConvertRecipt = forwardRef((props, ref) => {
    const { receiptFormat } = useTemplateLanguage()

    const dynamicContetn = {
        '80mm': ConvertReceiptWeb58mm,
        'A4': ConvertReceiptWeb,
    }

    const Content = dynamicContetn[receiptFormat]
    if (!Content) { return <> </> }

    return <Content {...props} ref={ref} />
})
const ConvertOldInvoice = forwardRef((props, ref) => {
    const { receiptFormat } = useTemplateLanguage()

    const dynamicContetn = {
        '80mm': ConvertOldInvoicetWeb58mm,
        'A4': ConvertOldInvoiceWeb,
    }

    const Content = dynamicContetn[receiptFormat]
    if (!Content) { return <> </> }

    return <Content {...props} ref={ref} />
})
const ConvertRefundWeb = forwardRef((props, ref) => {
    const {
        refund,
        cashMoney,
        nipt,
        company,
        location,
        customer,
    } = props
    const {
        order,
        createdAt,
        reason,
        receiptNumber,
        employee,
        qrCode,
        FIC,
        IIC,
        invoiceNumber,
        referenceIIC,
        referenceFIC,
        softwareCode,
        operatorCode,
        transaction,
        employeeDetails,
    } = refund

    const { translate } = useTemplateLanguage()
    const businUnitCode = transaction?.location?.businUnitCode
    const shippingAdress = order?.shippingAddress || {}
    const bankAccount = order?.bankAccount || {}
    const currency = transaction?.tender?.cashDetails?.currency || {}
    const supplyDateOrPeriod = transaction?.order?.supplyDateOrPeriod || {}
    const orderSubType = transaction?.order?.orderSubType?.split('_')?.[0] || null
    const PAYMENT_TYPE = {
        NONCASH: {
            null: {
                title: 'receipt_invoice_type',
                description: translate('receipt_label_type_noncash'),
            },
            EXPORT: {
                title: 'receipt_invoice_type',
                description: translate('receipt_label_type_export'),
            },
            SELF: {
                title: 'receipt_invoice_type',
                description: translate('receipt_label_type_reverse_charge'),
            },
        },
        CASH: {
            null: {
                title: 'receipt_payment_type_cash',
                description: `${INF.format(cashMoney)} ${order?.totalMoney?.currency}`,
            },
            SELF: {
                title: 'receipt_invoice_type',
                description: translate('receipt_label_type_reverse_charge'),
            },
        },
    }


    const printItems = () => {
        const l = (order?.lineItems || []).length
        let r = ''
        for (let i = 0; i < l; i++) {
            const item = order?.lineItems[i]
            const price = `${INF.format(item.totalMoney?.amount || 0)} ${item.totalMoney?.currency} `
            r += `
            <table style="table-layout:fixed; width:100%; border-bottom: 1px solid #ccc; padding:7px 10px; border-collapse:separate;">
      <tr>
          <td style="text-align:left; padding:0px 0px;font-size: 16px;">
          ${item.itemName}
          </td>
          <td  style="text-align:right; padding:0px 0px; font-size: 16px;">
          ${price}
          </td>
      </tr>
      <tr>
          <td  style="text-align:left; padding:0px 0px;" colspan="2">
              <div style="font-size: 12px; padding:1px 0;">${item.quantity || 0} ${translate(item?.measurementUnitId)} X ${INF.format(item.basePriceMoney?.amount || 0,)} ${item.basePriceMoney?.currency}</div>`
            for (let j = 0; j < (item?.modifiers?.length || 0); j++) {
                const modifier = item?.modifiers[j]
                // r += `<div class="product1"> ${modifier.name} (${INF.format(modifier.appliedMoney?.amount || 0)}
                //         ${modifier.appliedMoney?.currency})</div>`
                r += `<div  style="font-size: 12px;padding:1px 0;" >${modifier.name} (${INF.format(modifier.appliedMoney?.amount || 0)} ${modifier.appliedMoney?.currency})</div>`
            }

            for (let j = 0; j < (item?.appliedDiscounts?.length || 0); j++) {
                const
                    discount = item?.appliedDiscounts[j]
                if (discount.percentage) {
                    r += ` <div  style="font-size: 12px;padding:1px 0;" >${discount.name} (${discount.percentage || 0}%)</div>`
                } else {
                    r += ` <div  style="font-size: 12px;padding:1px 0;" >${discount.name} (${INF.format(discount.appliedMoney?.amount || 0)} ${discount.appliedMoney?.currency})</div>`
                }
            }
            r += `</td>  
            </tr> 
            </table>
            `
        }
        return r
    }

    const printTaxes = () => {
        const l = (order?.orderGroupedTaxes || []).length
        let r = ''
        for (let i = 0; i < l; i++) {
            const t = order?.orderGroupedTaxes?.[i] || {}
            const p = `${INF.format(t.VATAmt?.amount || 0)} ${t.VATAmt?.currency} `
            r += ` 
            <td style="text-align:left;font-size: 13px; padding:3px 0">
            <b>${translate('receipt_tax')} (${t.VATRate}%) :</b> ${p}
            </td>
          `
        }
        return r
    }

    const printCustomer = () => {
        let r = ''
        if (Object.keys(customer).length > 0) {
            r += `
            <div style="font-size: 16px;font-weight: 700;">
            ${translate('BUYER')}:
            </div>
            <div style="font-size: 13px">
            ${customer?.title}
            </div>
            <div style="font-size: 13px">
            ${customer?.address}
            </div>
       `
            if (customer?.nipt) {
                r += `<div  style="font-size: 13px"> ${translate('einvoice_buyertin_label')}: ${customer?.nipt}</div>`
            }
        }
        return r
    }

    const printOtherCurrency = () => {
        const addAmount = transaction?.tender?.additionalAmountMoney || transaction?.tender?.amountMoney
        const totAmount = transaction?.tender?.amountMoney
        let r = ''

        if (addAmount?.currency !== totAmount?.currency) {
            r += `
            <tr>
                    <td style="text-align:left;font-size: 14px; padding:3px 0">
                    ${translate('receipt_currency_rate_label')}:
                    </td>
                    <td style="text-align:right;font-size: 14px; padding:3px 0">
                     ${currency?.exRate}
                    </td>
                </tr>
            <tr>
            <tr>
                    <td style="text-align:left;font-size: 14px; padding:3px 0">
                    ${translate('receipt_foreign_currency_label')}  ${totAmount?.currency} :
                    </td>
                    <td style="text-align:right;font-size: 14px; padding:3px 0">
                    ${INF.format(totAmount?.amount)} ${totAmount?.currency}
                    </td>
            </tr>`
        }
        return r
    }

    const printShippingAdress = () => {
        let r = ''
        if (Object.keys(shippingAdress).length > 0) {
            r = `
                <table style="table-layout:fixed; width:100%; padding:7px 10px;border-top: 1px dashed #ccc;">
                <tr>
                <td "textAlign: 'left'; padding: '0px 0'; fontSize: '20px';">
                         ${translate('e_invoice_remittances_label')}:
                        </td>
                    </tr>
                    <tr>
                    <td style="text-align:left;font-size: 12px; padding:0px 0">
                    <b> ${translate('e_invoice_recipient_name_label')}:</b> ${shippingAdress?.externalId}
                    </td>
                </tr>
                    <tr>
                        <td style="text-align:left;font-size: 12px; padding:0px 0">
                        <b> ${translate('supporting_invoice_address_label')}:</b> ${shippingAdress?.addressLine1}, ${shippingAdress?.locality}, ${shippingAdress?.country}
                        </td>
                    </tr>
                </table>`
        }

        return r

    }

    const printBanckAccount = () => {
        let r = ''
        if (Object.keys(bankAccount).length > 0) {
            r = `
            <table style="table-layout:fixed; width:100%; padding:7px 10px;border-top: 1px dashed #ccc;">
                <tr>
                   <td "textAlign: 'left'; padding: '0px 0'; fontSize: '20px';">
                     ${translate('receipt_payment_info_label')}:
                    </td>
                </tr>
                <tr>
                    <td style="text-align:left;font-size: 12px; padding:0px 0">
                     <b> ${translate('receipt_payment_name_label')}:</b> ${bankAccount?.bankName}
                    </td>
                </tr>
                <tr>
                <td style="text-align:left;font-size: 12px; padding:0px 0">
                 <b> ${translate('receipt_payment_iban_label')}:</b> ${bankAccount?.iban}
                </td>
            </tr>
            <tr>
            <td style="text-align:left;font-size: 12px; padding:0px 0">
             <b> ${translate('receipt_payment_swift_label')}:</b> ${bankAccount?.swiftCode}
            </td>
        </tr>
            </table>`
        }

        return r

    }
    const renderFooter = () => {
        let r = ''
        if (Object.keys(customer).length > 0) {
            r = ` <table style="width:100%; padding:10px 10px;border-top:1px dashed black;">
            <tr>
                <td>
                <div style="text-align:center;font-size: 14px; padding:0px">
                    ${translate('e_invoice_buyer_label')}
                </div>
                <div style="text-align:center;font-size: 12px; padding:0px">
                     ${translate('e_invoice_signature_field_label')}
                </div>
                <div style="text-align:center;padding:10px 0; border-bottom: 1px solid black;">
                </div>
            </td>
            <td>
                <div style="text-align:center;font-size: 14px; padding:0px">
                ${translate('e_invoice_carrier_label')}
                </div>
                <div style="text-align:center;font-size: 12px; padding:0px">
            ${translate('e_invoice_signature_field_label')}
    
                </div>
                <div style="text-align:center;padding:10px 0; border-bottom: 1px solid black;">
                </div>
            </td>
            <td>
                <div style="text-align:center;font-size: 14px; padding:0px">
                ${translate('e_invoice_seller_label')}
                </div>
                <div style="text-align:center;font-size: 12px; padding:0px">
                 ${translate('e_invoice_signature_field_label')}
    
                </div>
                <div style="text-align:center;padding:10px 0; border-bottom: 1px solid black;">
                </div>
            </td>
            </tr>
        </table>`
        }

        return r
    }

    return (
        <>
            <style>{getPageMargins()}</style>
            <div style={{ width: '210mm', borderRadius: '8px' }} ref={ref}>
                <table style={{ tableLayout: 'fixed', width: '100%', padding: '16px 10px 16px 10px', borderCollapse: 'separate' }}>
                    <tr>
                        <td>
                            <div style={{ fontWeight: 700, fontSize: '16px' }} >
                                {company}
                            </div>

                            <div style={{ fontSize: '13px' }}>
                                {location}
                            </div>

                            <div style={{ fontSize: '13px' }} >
                                {translate('receipt_nipt_label')}: {nipt}
                            </div>
                            {businUnitCode && <div style={{ fontSize: '13px' }} >
                                {translate('e_invoice_bussines_unit_code_label')}: {businUnitCode}
                            </div>
                            }

                            <div style={{ fontSize: '13px' }}>
                                {receiptNumber}
                            </div>
                        </td>
                        <tr>
                            <td>
                                {parse(printCustomer(), parseOptionItem)}
                            </td>
                        </tr>
                    </tr>

                </table>
                <table style={{ tableLayout: 'fixed', width: ' 100%', borderTop: '1px dashed #ccc' }}>
                    <tr>
                        <td style={{
                            textAlign: 'center',
                            padding: '16px 10px',
                            fontSize: 18,
                            fontWeight: 700,
                        }}>
                            {translate('invoice_header')}
                        </td>
                    </tr>
                </table>
                <table style={{ tableLayout: 'fixed', width: ' 100%', backgroundColor: '#eee' }}>
                    <tr>
                        <td style={{ textAlign: 'left', padding: '5px 10px', fontSize: '16px' }}>
                            {translate('receipt_preview_items')}
                        </td>
                        <td style={{ textAlign: 'right', padding: '5px 10px', fontSize: '16px' }}>
                            {translate('receipt_item_price_label')}
                        </td>
                    </tr>
                </table>
                {parse(printItems(), parseOptionItem)}
                <table style={{ tableLayout: 'fixed', width: ' 100%', padding: '7px 10px', borderCollapse: 'separate' }}>
                    <tr>
                        <td style={{ textAlign: 'left', padding: '3px 0', fontSize: '13px' }}>
                            {translate('receipt_subtotal')}:
                        </td>
                        <td style={{ textAlign: 'right', padding: '3px 0', fontSize: '13px' }}>
                            {INF.format(
                                order.totalMoney?.amount +
                                order.totalDiscountMoney?.amount -
                                order?.totalTaxMoney?.amount || 0,
                            )} {order.totalMoney?.currency}
                        </td>
                    </tr>
                    <tr>
                        <td style={{ textAlign: 'left', padding: '3px 0', fontSize: '13px', color: '#e60000' }}>
                            {translate('receipt_discount_line')}:
                        </td>
                        <td style={{ textAlign: 'right', padding: '3px 0', fontSize: '13px', color: 'e60000' }}>
                            {INF.format(order.totalDiscountMoney?.amount || 0)}
                            {order.totalDiscountMoney?.currency}
                        </td>
                    </tr>
                    <tr>
                        <td style={{ textAlign: 'left', padding: '3px 0', fontSize: '20px', fontWeight: 'bold' }} >
                            {translate('receipt_total')}:
                        </td>
                        <td style={{ textAlign: 'right', padding: '3px 0', fontSize: '20px', fontWeight: 'bold' }}>
                            {INF.format(order.totalMoney?.amount)} {order.totalMoney?.currency}
                        </td>
                    </tr>
                    {parse(printOtherCurrency(), parseOptionItem)}
                </table>
                <table style={{
                    width: '100%',
                    padding: '7px 10px',
                    borderTop: '1px dashed #ccc',
                    borderCollapse: 'separate',
                }}>
                    <tr>
                        {parse(printTaxes(), parseOptionItem)}
                    </tr>
                </table>
                <table style={{ tableLayout: 'fixed', width: ' 100%', padding: '7px 10px', borderCollapse: 'separate', borderTop: '1px dashed #ccc' }} >
                    <tr>
                        <td style={{ textAlign: 'left', padding: '3px 0', fontSize: '14px' }} >
                            <b>{translate(PAYMENT_TYPE?.[transaction?.invoiceType]?.[orderSubType]?.title)}:</b> {PAYMENT_TYPE?.[transaction?.invoiceType]?.[orderSubType]?.description}
                        </td>
                        <td style={{ padding: '3px 0', fontSize: '14px' }}>
                            <b> {translate('receipt_refund_reason')}:</b> {reason}
                        </td>
                    </tr>


                    <tr>
                        <td style={{ textAlign: 'left', padding: '3px 0', fontSize: '14px' }}>
                            <b> {translate('receipt_invoice_number')}:</b> {invoiceNumber}
                        </td>
                        <td style={{ padding: '3px 0', fontSize: '14px' }}>
                            <b> {translate('receipt_created_at')}:</b> {moment(createdAt).format('DD-MM-YY HH:mm')}
                        </td>
                    </tr>
                    <tr>
                        <td style={{ textAlign: 'left', padding: '3px 0', fontSize: '14px', wordBreak: 'break-all' }}>
                            <b>{translate('receipt_iic_label')}:</b> {IIC}
                        </td>
                        <td style={{ textAlign: 'left', padding: '3px 0', fontSize: '14px' }}>
                            <b> {translate('receipt_operator_code_label')}:</b> {operatorCode}
                        </td>
                    </tr>
                    <tr>
                        <td style={{ padding: '3px 0', fontSize: '14px', wordBreak: 'break-all' }} >
                            <b> {translate('receipt_fic_label')}:</b> {FIC}

                        </td>
                        <td style={{ padding: '3px 0', fontSize: '14px' }}>
                            <b> {translate('receipt_software_code_label')}: </b>{softwareCode}
                        </td>
                    </tr>
                    <tr>
                        <td style={{ padding: '3px 0', fontSize: '14px' }}>
                            {/* <b>{translate('SELLER')}:</b> {employee?.split('@')?.[0]} */}
                            <b>{translate('SELLER')}:</b> {employeeDetails ? employeeDetails?.firstName + ' ' + employeeDetails?.lastName : employee?.split('@')?.[0]}
                        </td>
                        <td style={{ padding: '3px 0', fontSize: '14px' }}>
                            <b>{translate('receipt_total_method')}:</b> {translate(PAYMENT_METHOD[transaction?.tender?.type])}
                        </td>
                    </tr>
                </table>
                <table style={{ tableLayout: 'fixed', width: ' 100%', padding: '7px 10px', borderCollapse: 'separate', borderTop: '1px dashed #ccc' }} >
                    <tr>
                        <td style={{ textAlign: 'left', padding: '3px 0', fontSize: '20px', fontWeight: 'bold' }} >
                            {translate('receipt_section_primary_invoice')}:
                        </td>
                    </tr>
                    <tr>
                        <td style={{ textAlign: 'left', padding: '3px 0', fontSize: '14px' }} >
                            <b>  {translate('receipt_section_primary_iic_label')}:</b> {referenceIIC}


                        </td>
                    </tr>
                    <tr>
                        <td style={{ padding: '3px 0', fontSize: '14px' }}>
                            <b> {translate('receipt_section_primary_fic_label')}: </b> {referenceFIC}

                        </td>
                    </tr>
                    <tr>
                        <td style={{ padding: '3px 0', fontSize: '14px' }}>
                            <b> {translate('receipt_created_at')}:</b> {moment(transaction?.createdAt).format('DD-MM-YY HH:mm')}
                        </td>
                    </tr>
                    {Object.keys(supplyDateOrPeriod).length > 0 &&
                        <tr>
                            <td style={{ padding: '3px 0', fontSize: '14px' }}>
                                <b> {translate('payment_supplying_period_label')}:</b> {`${moment(supplyDateOrPeriod?.startDate).format('DD/MM/YYYY')} - ${moment(supplyDateOrPeriod?.endDate).format('DD/MM/YYYY')}`}
                            </td>
                        </tr>
                    }
                </table>
                {parse(printBanckAccount(), parseOptionItem)}
                {parse(printShippingAdress(), parseOptionItem)}
                {parse(renderFooter(), parseOptionItem)}

                <table style={{ tableLayout: 'fixed', width: ' 100%', padding: '7px 10px', borderCollapse: 'separate' }} >
                    <tr>
                        <td style={{ textAlign: 'center' }} >
                            <QRCode value={qrCode || ''} size={200} />
                        </td>
                    </tr>
                </table>
                <table style={{ tableLayout: 'fixed', width: ' 100%', padding: '7px 10px', borderCollapse: 'separate' }} >
                    <tr>
                        <td style={{ textAlign: 'center', fontSize: '13px', fontWeight: 700 }}>
                            {translate('receipt_generated_by')} Vodafone eBiznes
                        </td>
                    </tr>
                </table>
            </div>
        </>

    )
})
const ConvertRefundWeb58mm = forwardRef((props, ref) => {
    const {
        refund,
        cashMoney,
        nipt,
        company,
        location,
        customer,
    } = props
    const {
        order,
        createdAt,
        reason,
        receiptNumber,
        employee,
        qrCode,
        FIC,
        IIC,
        invoiceNumber,
        referenceIIC,
        referenceFIC,
        softwareCode,
        operatorCode,
        transaction,
        employeeDetails,
    } = refund
    const { translate } = useTemplateLanguage()
    const businUnitCode = transaction?.location?.businUnitCode
    const shippingAdress = order?.shippingAddress || {}
    const bankAccount = order?.bankAccount || {}
    const currency = transaction?.tender?.cashDetails?.currency || {}
    const supplyDateOrPeriod = transaction?.order?.supplyDateOrPeriod || {}
    const orderSubType = transaction?.order?.orderSubType?.split('_')?.[0] || null


    const PAYMENT_TYPE = {
        NONCASH: {
            null: {
                title: 'receipt_invoice_type',
                description: translate('receipt_label_type_noncash'),
            },
            EXPORT: {
                title: 'receipt_invoice_type',
                description: translate('receipt_label_type_export'),
            },
            SELF: {
                title: 'receipt_invoice_type',
                description: translate('receipt_label_type_reverse_charge'),
            },
        },
        CASH: {
            null: {
                title: 'receipt_payment_type_cash',
                description: `${INF.format(cashMoney)} ${order?.totalMoney?.currency}`,
            },
            SELF: {
                title: 'receipt_invoice_type',
                description: translate('receipt_label_type_reverse_charge'),
            },
        },
    }

    const printItems = () => {
        const l = (order?.lineItems || []).length
        let r = ''
        for (let i = 0; i < l; i++) {
            const item = order?.lineItems[i]
            const price = `${INF.format(item.totalMoney?.amount || 0)} ${item.totalMoney?.currency} `
            r += `
            <table style="table-layout:fixed; width:100%; border-bottom: 1px solid #ccc; padding:7px 10px; border-collapse:separate;">
      <tr>
          <td style="text-align:left; padding:0px 0px;font-size: 16px; word-break: break-all;">
          ${item.itemName}
          </td>
          <td  style="text-align:right; padding:0px 0px; font-size: 16px;">
          ${price}
          </td>
      </tr>
      <tr>
          <td  style="text-align:left; padding:0px 0px;" colspan="2">
              <div style="font-size: 12px; padding:1px 0;">${item.quantity || 0} X ${INF.format(item.basePriceMoney?.amount || 0,)} ${item.basePriceMoney?.currency}</div>`
            for (let j = 0; j < (item?.modifiers?.length || 0); j++) {
                const modifier = item?.modifiers[j]
                // r += `<div class="product1"> ${modifier.name} (${INF.format(modifier.appliedMoney?.amount || 0)}
                //         ${modifier.appliedMoney?.currency})</div>`
                r += `<div  style="font-size: 12px;padding:1px 0;" >${modifier.name} (${INF.format(modifier.appliedMoney?.amount || 0)} ${modifier.appliedMoney?.currency})</div>`
            }

            for (let j = 0; j < (item?.appliedDiscounts?.length || 0); j++) {
                const
                    discount = item?.appliedDiscounts[j]
                if (discount.percentage) {
                    r += ` <div  style="font-size: 12px;padding:1px 0;" >${discount.name} (${discount.percentage || 0}%)</div>`
                } else {
                    r += ` <div  style="font-size: 12px;padding:1px 0;" >${discount.name} (${INF.format(discount.appliedMoney?.amount || 0)} ${discount.appliedMoney?.currency})</div>`
                }
            }
            r += `</td>  
            </tr> 
            </table>
            `
        }
        return r
    }

    const printTaxes = () => {
        const l = (order?.orderGroupedTaxes || []).length
        let r = ''
        for (let i = 0; i < l; i++) {
            const t = order?.orderGroupedTaxes?.[i] || {}
            const p = `${INF.format(t.VATAmt?.amount || 0)} ${t.VATAmt?.currency} `
            r += ` 
            <td style="text-align:left;font-size: 13px; padding:3px 0">
            <b>${translate('receipt_tax')} (${t.VATRate}%) :</b> ${p}
            </td>
          `
        }
        return r
    }

    const printCustomer = () => {
        let r = ''
        if (Object.keys(customer).length > 0) {
            r += `
            <div style="font-size: 16px;font-weight: 700;">
            ${translate('BUYER')}:
            </div>
            <div style="font-size: 13px">
            ${customer?.title}
            </div>
            <div style="font-size: 13px">
            ${customer?.address}
            </div>
       `
            if (customer?.nipt) {
                r += `<div  style="font-size: 13px"> ${translate('einvoice_buyertin_label')}: ${customer?.nipt}</div>`
            }
        }
        return r
    }

    const printOtherCurrency = () => {
        const addAmount = transaction?.tender?.additionalAmountMoney || transaction?.tender?.amountMoney
        const totAmount = transaction?.tender?.amountMoney
        let r = ''

        if (addAmount?.currency !== totAmount?.currency) {
            r += `
            <tr>
                    <td style="text-align:left;font-size: 14px; padding:3px 0">
                    ${translate('receipt_currency_rate_label')}:
                    </td>
                    <td style="text-align:right;font-size: 14px; padding:3px 0">
                     ${currency?.exRate}
                    </td>
                </tr>
            <tr>
            <tr>
                    <td style="text-align:left;font-size: 14px; padding:3px 0">
                    ${translate('receipt_foreign_currency_label')}  ${totAmount?.currency} :
                    </td>
                    <td style="text-align:right;font-size: 14px; padding:3px 0">
                    ${INF.format(totAmount?.amount)} ${totAmount?.currency}
                    </td>
            </tr>`
        }
        return r
    }

    const printShippingAdress = () => {
        let r = ''
        if (Object.keys(shippingAdress).length > 0) {
            r = `
                <table style="table-layout:fixed; width:100%; padding:7px 10px;border-top: 1px dashed #ccc;">
                <tr>
                <td "textAlign: 'left'; padding: '0px 0'; fontSize: '20px';">
                         ${translate('e_invoice_remittances_label')}:
                        </td>
                    </tr>
                    <tr>
                    <td style="text-align:left;font-size: 12px; padding:0px 0">
                    <b> ${translate('e_invoice_recipient_name_label')}:</b> ${shippingAdress?.externalId}
                    </td>
                </tr>
                    <tr>
                        <td style="text-align:left;font-size: 12px; padding:0px 0">
                        <b> ${translate('supporting_invoice_address_label')}:</b> ${shippingAdress?.addressLine1}, ${shippingAdress?.locality}, ${shippingAdress?.country}
                        </td>
                    </tr>
                </table>`
        }

        return r

    }

    const printBanckAccount = () => {
        let r = ''
        if (Object.keys(bankAccount).length > 0) {
            r = `
            <table style="table-layout:fixed; width:100%; padding:7px 10px;border-top: 1px dashed #ccc;">
                <tr>
                   <td "textAlign: 'left'; padding: '0px 0'; fontSize: '20px';">
                     ${translate('receipt_payment_info_label')}:
                    </td>
                </tr>
                <tr>
                    <td style="text-align:left;font-size: 12px; padding:0px 0">
                     <b> ${translate('receipt_payment_name_label')}:</b> ${bankAccount?.bankName}
                    </td>
                </tr>
                <tr>
                <td style="text-align:left;font-size: 12px; padding:0px 0">
                 <b> ${translate('receipt_payment_iban_label')}:</b> ${bankAccount?.iban}
                </td>
            </tr>
            <tr>
            <td style="text-align:left;font-size: 12px; padding:0px 0">
             <b> ${translate('receipt_payment_swift_label')}:</b> ${bankAccount?.swiftCode}
            </td>
        </tr>
            </table>`
        }

        return r
    }

    const renderFooter = () => {
        let r = ''
        if (Object.keys(customer).length > 0) {
            r = `
       
            <table style="width:100%; padding:10px 10px;border-top:1px dashed black;">
                <tr>
                    <td style="text-align:center;font-size: 12px; padding:0px">
                    ${translate('e_invoice_buyer_label')}
                    </td>
                </tr>
                <tr>
                    <td style="text-align:center;font-size: 10px; padding:0px">
                     ${translate('e_invoice_signature_field_label')}
                    </td>
                </tr>
                <tr>
                    <td style="text-align:center;padding:10px 0; border-bottom: 1px solid black;">
                    </td>
                </tr>
            </table>
            <table style="width:100%; padding:10px 10px;">
                <tr>
                    <td style="text-align:center;font-size: 12px; padding:0px">
                    ${translate('e_invoice_carrier_label')}
                    </td>
                </tr>
                <tr>
                    <td style="text-align:center;font-size: 10px; padding:0px">
                     ${translate('e_invoice_signature_field_label')}
                    </td>
                </tr>
                <tr>
                    <td style="text-align:center;padding:10px 0; border-bottom: 1px solid black;">
                    </td>
                </tr>
            </table>
            <table style="width:100%; padding:10px 10px;">
                <tr>
                    <td style="text-align:center;font-size: 12px; padding:0px">
                    ${translate('e_invoice_seller_label')}
                    </td>
                </tr>
                <tr>
                    <td style="text-align:center;font-size: 10px; padding:0px">
                     ${translate('e_invoice_signature_field_label')}
                    </td>
                </tr>
                <tr>
                    <td style="text-align:center;padding:10px 0; border-bottom: 1px solid black;">
                    </td>
                </tr>
            </table>`
        }

        return r
    }

    return (
        <>
            <style>{getPageMargins()}</style>
            <div style={{ width: '58mm', borderRadius: '8px' }} ref={ref}>
                <table style={{ tableLayout: 'fixed', width: '100%', padding: '16px 10px 16px 10px', borderCollapse: 'separate' }}>
                    <tr>
                        <td>
                            <div style={{ fontWeight: 700, fontSize: '16px' }} >
                                {company}
                            </div>

                            <div style={{ fontSize: '13px' }}>
                                {location}
                            </div>

                            <div style={{ fontSize: '13px' }} >
                                {translate('receipt_nipt_label')}:  {nipt}
                            </div>

                            <div style={{ fontSize: '13px' }}>
                                {receiptNumber}
                            </div>
                        </td>
                    </tr>
                </table>
                <table style={{ tableLayout: 'fixed', width: ' 100%', borderTop: '1px dashed #ccc' }}>
                    <tr>
                        <td style={{
                            textAlign: 'center',
                            padding: '16px 10px',
                            fontSize: 18,
                            fontWeight: 700,
                        }}>
                            {translate('invoice_header')}
                        </td>
                    </tr>
                </table>
                <table style={{ tableLayout: 'fixed', width: ' 100%', backgroundColor: '#eee' }}>
                    <tr>
                        <td style={{ textAlign: 'left', padding: '5px 10px', fontSize: '16px' }}>
                            {translate('receipt_preview_items')}
                        </td>
                        <td style={{ textAlign: 'right', padding: '5px 10px', fontSize: '16px' }}>
                            {translate('receipt_item_price_label')}
                        </td>
                    </tr>
                </table>
                {parse(printItems(), parseOptionItem)}
                <table style={{ tableLayout: 'fixed', width: ' 100%', padding: '7px 10px', borderCollapse: 'separate' }}>
                    <tr>
                        <td style={{ textAlign: 'left', padding: '3px 0', fontSize: '13px' }}>
                            {translate('receipt_subtotal')}:
                        </td>
                        <td style={{ textAlign: 'right', padding: '3px 0', fontSize: '13px' }}>
                            {INF.format(
                                order.totalMoney?.amount +
                                order.totalDiscountMoney?.amount -
                                order?.totalTaxMoney?.amount || 0,
                            )} {order.totalMoney?.currency}
                        </td>
                    </tr>
                    <tr>
                        <td style={{ textAlign: 'left', padding: '3px 0', fontSize: '13px', color: '#e60000' }}>
                            {translate('receipt_discount_line')}:
                        </td>
                        <td style={{ textAlign: 'right', padding: '3px 0', fontSize: '13px', color: 'e60000' }}>
                            {INF.format(order.totalDiscountMoney?.amount || 0)}
                            {order.totalDiscountMoney?.currency}
                        </td>
                    </tr>
                    <tr>
                        <td style={{ textAlign: 'left', padding: '3px 0', fontSize: '16px', fontWeight: 'bold' }} >
                            {translate('receipt_total')}:
                        </td>
                        <td style={{ textAlign: 'right', padding: '3px 0', fontSize: '16px', fontWeight: 'bold' }}>
                            {INF.format(order.totalMoney?.amount)} {order.totalMoney?.currency}
                        </td>
                    </tr>
                    {parse(printOtherCurrency(), parseOptionItem)}

                </table>
                <table style={{
                    width: '100%',
                    padding: '7px 10px',
                    borderTop: '1px dashed #ccc',
                    borderCollapse: 'separate',
                }}>
                    <tr>
                        {parse(printTaxes(), parseOptionItem)}
                    </tr>
                </table>
                <table style={{ tableLayout: 'fixed', width: ' 100%', padding: '7px 10px', borderCollapse: 'separate', borderTop: '1px dashed #ccc' }} >
                    <tr>
                        <td style={{ textAlign: 'left', padding: '0 0', fontSize: '14px' }} >
                            <b>{translate(PAYMENT_TYPE?.[transaction?.invoiceType]?.[orderSubType]?.title)}:</b> {PAYMENT_TYPE?.[transaction?.invoiceType]?.[orderSubType]?.description}

                        </td>
                    </tr>
                    <tr>
                        <td style={{ padding: '0 0', fontSize: '14px' }}>
                            <b> {translate('receipt_refund_reason')}:</b> {reason}
                        </td>
                    </tr>


                    <tr>
                        <td style={{ textAlign: 'left', padding: '0 0', fontSize: '14px' }}>
                            <b> {translate('receipt_invoice_number')}:</b> {invoiceNumber}
                        </td>
                    </tr>
                    <tr>
                        <td style={{ padding: '0 0', fontSize: '14px' }}>
                            <b>{translate('receipt_created_at')}:</b> {moment(createdAt).format('DD-MM-YY HH:mm')}
                        </td>
                    </tr>
                    <tr>
                        <td style={{
                            padding: '3px 0',
                            fontSize: '14px',
                            wordBreak: 'break-all',
                        }}>
                            <b> {translate('receipt_iic_label')}:</b> {IIC}

                        </td>
                    </tr>
                    <tr>
                        <td style={{
                            padding: '0 0',
                            fontSize: '14px',
                            wordBreak: 'break-all',
                        }} >
                            <b>{translate('receipt_fic_label')}:</b> {FIC}

                        </td>
                    </tr>

                    {businUnitCode &&
                        <tr>
                            <td style={{ textAlign: 'left', padding: '0 0', fontSize: '14px' }}>
                                <b>{translate('e_invoice_bussines_unit_code_label')} </b>: {businUnitCode}
                            </td>
                        </tr>
                    }
                    <tr>
                        <td style={{ textAlign: 'left', padding: '0 0', fontSize: '14px' }}>
                            <b>{translate('receipt_operator_code_label')}:</b> {operatorCode}
                        </td>
                    </tr>
                    <tr>
                        <td style={{ padding: '0 0', fontSize: '14px' }}>
                            <b> {translate('receipt_software_code_label')}: </b>{softwareCode}
                        </td>
                    </tr>
                    <tr>
                        <td style={{ padding: '0 0', fontSize: '14px' }}>
                            {/* <b>{translate('SELLER')}:</b> {employee?.split('@')?.[0]} */}
                            <b>{translate('SELLER')}:</b> {employeeDetails ? employeeDetails?.firstName + ' ' + employeeDetails?.lastName : employee?.split('@')?.[0]}
                        </td>
                    </tr>
                    <tr>
                        <td style={{ padding: '0 0', fontSize: '14px' }}>
                            <b>{translate('receipt_total_method')}:</b> {translate(PAYMENT_METHOD[transaction?.tender?.type])}
                        </td>
                    </tr>


                    <tr>
                        <td>
                            {parse(printCustomer(), parseOptionItem)}
                        </td>
                    </tr>
                </table>
                <table style={{ tableLayout: 'fixed', width: ' 100%', padding: '7px 10px', borderCollapse: 'separate', borderTop: '1px dashed #ccc' }} >
                    <tr>
                        <td style={{ textAlign: 'left', padding: '3px 0', fontSize: '20px', fontWeight: 'bold' }} >
                            {translate('receipt_section_primary_invoice')}:
                        </td>
                    </tr>
                    <tr>
                        <td style={{
                            padding: '3px 0',
                            fontSize: '14px',
                            width: '100%',
                            wordBreak: 'break-all',

                        }}>
                            <b> {translate('receipt_section_primary_iic_label')}:</b> {referenceIIC}


                        </td>
                    </tr>
                    <tr>
                        <td style={{
                            padding: '3px 0',
                            fontSize: '14px',
                            wordBreak: 'break-all',

                        }}>
                            <b> {translate('receipt_section_primary_fic_label')}: </b> {referenceFIC}

                        </td>
                    </tr>
                    <tr>
                        <td style={{ padding: '3px 0', fontSize: '14px' }}>
                            <b> {translate('receipt_created_at')}:</b> {moment(transaction?.createdAt).format('DD-MM-YY HH:mm')}
                        </td>
                    </tr>
                    {Object.keys(supplyDateOrPeriod).length > 0 &&
                        <tr>
                            <td style={{ padding: '3px 0', fontSize: '14px' }}>
                                <b> {translate('payment_supplying_period_label')}:</b> {`${moment(supplyDateOrPeriod?.startDate).format('DD/MM/YYYY')} - ${moment(supplyDateOrPeriod?.endDate).format('DD/MM/YYYY')}`}
                            </td>
                        </tr>
                    }
                </table>
                {parse(printBanckAccount(), parseOptionItem)}
                {parse(printShippingAdress(), parseOptionItem)}
                {parse(renderFooter(), parseOptionItem)}

                <table style={{ tableLayout: 'fixed', width: ' 100%', padding: '7px 10px', borderCollapse: 'separate' }} >
                    <tr>
                        <td style={{ textAlign: 'center' }} >
                            <QRCode value={qrCode || ''} size={200} />
                            {/* <img src={`http://chart.apis.google.com/chart?cht=qr&chs=200x200&chl=${qrCodeWithSlashes}&chld=H|0`} width="100px" height="100px" /> */}
                        </td>
                    </tr>
                </table>
                <table style={{ tableLayout: 'fixed', width: ' 100%', padding: '7px 10px', borderCollapse: 'separate' }} >
                    <tr>
                        <td style={{ textAlign: 'center', fontSize: '13px', fontWeight: 700 }}>
                            {translate('receipt_generated_by')} Vodafone eBiznes
                        </td>
                    </tr>
                </table>
            </div>
        </>

    )
})


const ConvertRefund = forwardRef((props, ref) => {
    const { receiptFormat } = useTemplateLanguage()

    const dynamicContetn = {
        '80mm': ConvertRefundWeb58mm,
        'A4': ConvertRefundWeb,
    }

    const Content = dynamicContetn[receiptFormat]
    if (!Content) { return <> </> }

    return <Content {...props} ref={ref} />
})

const ConvertSupportingInvoiceWeb = forwardRef((props, ref) => {
    const {
        invoice,
        nipt,
        company,
        location,
    } = props
    const {
        basicInfo,
        transportInfo,
        issuer,
        carrier,
        items,
    } = invoice

    const { translate } = useTemplateLanguage()

    const printItems = () => {
        const l = (items || []).length
        let r = ''
        for (let i = 0; i < l; i++) {
            const item = items[i]
            const price = `${item.quantity || 0} ${item.unit} `
            r += `
            <table style="table-layout:fixed; width:100%; border-bottom: 1px solid #ccc; padding:7px 10px; border-collapse:separate;">
      <tr>
          <td style="text-align:left; padding:0px 0px;font-size: 16px;">
          ${item.name}
          </td>
          <td  style="text-align:right; padding:0px 0px; font-size: 16px;">
          ${price}
          </td>
      </tr>
            </table>
            `
        }
        return r
    }

    const renderStartingPoint = () => {
        let r = ''
        r = ` <div style="font-size: 16px;font-weight: bold;padding-bottom:5px;">${translate('supporting_invoice_transport_starting_point')}</div>
        <div style="font-size: 14px; padding:0px 0"><b>${translate('supporting_invoice_transport_starting_address')}</b>: ${transportInfo.startingAddress}</div>
        <div style="font-size: 14px; padding:0px 0"><b>${translate('supporting_invoice_city')}</b>: ${transportInfo.startingCity}</div>
        <div style="font-size: 14px; padding:0px 0"><b>${translate('supporting_invoice_transport_starting_point')}</b>: ${transportInfo.startingPoint}</div>
        <div style="font-size: 14px; padding:0px 0"><b>${translate('supporting_invoice_starting_date')}</b>: ${transportInfo.startingDate} </div>`
        return r
    }

    const renderArrivalPoint = () => {
        let r = ''
        r = ` <div style="font-size: 16px;font-weight: bold; padding-bottom:5px;">${translate('supporting_invoice_transport_destination_point')}</div>
        <div style="font-size: 14px; padding:0px 0"><b>${translate('supporting_invoice_transport_destination_address')}</b>: ${transportInfo.destinationAddress}</div>
        <div style="font-size: 14px; padding:0px 0"><b>${translate('supporting_invoice_city')}</b>: ${transportInfo.destinationCity}</div>
        <div style="font-size: 14px; padding:0px 0"><b>${translate('supporting_invoice_transport_destination_point')}</b>: ${transportInfo.destinationPoint}</div>
        <div style="font-size: 14px; padding:0px 0"><b>${translate('supporting_invoice_transport_destination_date')}</b>: ${transportInfo.destinationDate} </div>`
        return r
    }

    const renderIssuer = () => {
        let r = ''
        r = ` <div style="font-size: 16px;font-weight: bold;padding-bottom:5px;">${translate('supporting_invoice_issuer_label')}</div>
        <div style="font-size: 14px; padding:0px 0"><b>${translate('receipt_nipt_label')}</b>: ${transportInfo.destinationAddress}</b>: ${issuer.nuis}</div>
        <div style="font-size: 14px; padding:0px 0"><b>${translate('supporting_invoice_name_label')}</b>: ${issuer.name}</div>
        <div style="font-size: 14px; padding:0px 0"><b>${translate('supporting_invoice_address_label')}</b>: ${issuer.address}</div>
        <div style="font-size: 14px; padding:0px 0"><b>${translate('supporting_invoice_city')} </b>: ${issuer.city} </div>`
        return r
    }
    const renderCarrier = () => {
        let r = ''
        r = ` <div style="font-size: 16px;font-weight: bold;padding-bottom:5px;">${translate('supporting_invoice_carrier_label')}</div>
        <div style="font-size: 14px; padding:0px 0"><b>${translate('receipt_nipt_label')}</b>: ${carrier.nuis}</div>
        <div style="font-size: 14px; padding:0px 0"><b>${translate('supporting_invoice_name_label')}</b>: ${issuer.name}</div>
        <div style="font-size: 14px; padding:0px 0"><b>${translate('supporting_invoice_address_label')}</b>: ${carrier.address} </div>
        <div style="font-size: 14px; padding:0px 0"><b>${translate('supporting_invoice_city')}</b>: ${carrier.city}</div>`
        return r
    }

    return (
        <>
            <style>
                {getPageMargins()}
            </style>
            <div style={{ width: '210mm', borderRadius: '8px' }} ref={ref}>
                <table style={{ tableLayout: 'fixed', width: '100%', padding: '10px 10px', borderCollapse: 'separate' }}>
                    <tr>
                        <td>
                            <div style={{ fontWeight: 700, fontSize: '16px' }} >
                                {company}
                            </div>

                            <div style={{ fontSize: '13px' }}>
                                {location}
                            </div>

                            <div style={{ fontSize: '13px' }} >
                                {translate('receipt_nipt_label')}: {nipt}
                            </div>

                            <div style={{ fontSize: '13px' }}>
                                {translate('e_invoice_bussines_unit_code_label')}: {basicInfo.businUnitCode}
                            </div>
                            <div style={{ fontSize: '13px' }} >
                                {basicInfo?.receipt}
                            </div>
                        </td>
                    </tr>

                </table>
                <table style={{ tableLayout: 'fixed', width: ' 100%', borderTop: '1px dashed #ccc' }}>
                    <tr>
                        <td style={{
                            textAlign: 'center',
                            padding: '16px 10px',
                            fontSize: 18,
                            fontWeight: 700,
                        }}>
                            {translate('supporting_invoice_label_type')}
                        </td>
                    </tr>
                </table>
                <table style={{ width: ' 100%', backgroundColor: ' #eee', padding: '0 10px' }}>
                    <tr>
                        <td style={{ textAlign: 'left', padding: '5px 0', fontSize: '16px' }} >
                            {translate('supporting_invoice_list_items_label')}
                        </td>
                        <td style={{ textAlign: 'right', padding: '5px 0', fontSize: '16px' }} >
                            {translate('supporting_invoice_amount_label')}
                        </td>
                    </tr>
                </table>
                {parse(printItems(), parseOptionItem)}

                <table style={{ tableLayout: 'fixed', width: ' 100%', padding: '7px 10px', borderCollapse: 'separate', borderTop: '1px dashed #ccc' }} >
                    <tr>
                        <td style={{
                            fontSize: 16,
                            fontWeight: 'bold',
                            paddingBottom: 5,
                        }}>
                            {translate('supporting_invoice_receipt_info')}
                        </td>
                    </tr>
                    <tr>
                        <td style={{ textAlign: 'left', padding: '0 0', fontSize: '14px' }} >
                            <b> {translate('supporting_invoice_fwtnic')}: </b> {basicInfo.fwtnic}
                        </td>
                        <td style={{ padding: '0 0', fontSize: '14px' }}>
                            <b>{translate('supporting_invoice_seller')}:</b> {basicInfo?.employeeDetails ? basicInfo?.employeeDetails?.firstName + ' ' + basicInfo?.employeeDetails?.lastName : basicInfo?.employeeEmail?.split('@')?.[0]}

                        </td>

                    </tr>
                    <tr>

                        <td style={{ padding: '0 0', fontSize: '14px' }}>
                            <b> {translate('receipt_operator_code_label')}:</b> {basicInfo.operatorCode}
                        </td>
                        <td style={{ padding: '0 0', fontSize: '14px' }}>
                            <b> {translate('receipt_created_at')}:</b> {basicInfo.date}
                        </td>

                    </tr>
                    <tr>
                        <td style={{ textAlign: 'left', padding: '0 0', fontSize: '14px' }} >
                            <b>{translate('receipt_software_code_label')}:</b> {basicInfo.softCode}
                        </td>
                        <td style={{ textAlign: 'left', padding: '0 0', fontSize: '14px' }}>
                            <b>{translate('supporting_invoice_type')}:</b> {basicInfo.invoiceType}
                        </td>
                    </tr>
                    <tr>
                        <td style={{ padding: '0 0', fontSize: '14px' }} >
                            <b> {translate('supporting_invoice_number')}:</b> {basicInfo.receiptNr}
                        </td>
                        <td style={{ textAlign: 'left', padding: '0 0', fontSize: '14px' }}>
                            <b>  {translate('supporting_invoice_transaction_type_label')}:</b> {basicInfo.transactionType}
                        </td>
                    </tr>
                </table>
                <table style={{ tableLayout: 'fixed', width: ' 100%', padding: '7px 10px', borderTop: '1px dashed #ccc' }}>
                    <tr>
                        <td style={{
                            fontSize: 16,
                            fontWeight: 'bold',
                            paddingBottom: 5,
                        }}>
                            {translate('supporting_invoice_transport_info_label')}
                        </td>
                    </tr>
                    <tr>
                        <td style={{ textAlign: 'left', padding: '3px 0', fontSize: '14px' }} >
                            <b> {translate('supporting_invoice_transport_owner_label')}: </b>{transportInfo.transportOwnership}
                        </td>
                    </tr>
                    <tr>
                        <td style={{ textAlign: 'left', padding: '3px 0', fontSize: '14px' }} >
                            <b>{translate('supporting_invoice_transport_plate')}:</b> {transportInfo.plates}
                        </td>
                    </tr>
                </table>
                <table style={{ tableLayout: 'fixed', width: ' 100%', padding: '7px 10px', borderCollapse: 'separate', borderTop: '1px dashed #ccc' }} >
                    <tr>
                        <td>
                            {parse(renderStartingPoint(), parseOptionItem)}
                        </td>
                        <td>
                            {parse(renderArrivalPoint(), parseOptionItem)}
                        </td>
                    </tr>
                </table>
                <table style={{ tableLayout: 'fixed', width: '100%', padding: '7px 10px' }}>
                    <tr>
                        <td style={{
                            fontSize: 16,
                            fontWeight: 'bold',
                            paddingBottom: 5,
                        }}>
                            {translate('receipt_preview_items')}
                        </td>
                    </tr>
                    <tr>
                        <td style={{ textAlign: 'left', padding: '0 0', fontSize: '14px' }} >
                            {translate('supporting_invoice_preview_dangerous')}: {transportInfo.isItemDangerous ? `${translate('supporting_invoice_yes_question')}` : `${translate('supporting_invoice_no_question')}`}
                        </td>
                        <td style={{ padding: '0 0', fontSize: '14px' }} >
                            {translate('supporting_invoice_preview_accompaniment')}: {transportInfo.isEscort ? `${translate('supporting_invoice_yes_question')}` : `${translate('supporting_invoice_no_question')}`}
                        </td>
                    </tr>
                </table>

                <table style={{ tableLayout: 'fixed', width: ' 100%', padding: '7px 10px', borderCollapse: 'separate', borderTop: '1px dashed #ccc' }} >
                    <tr>
                        <td>
                            {parse(renderIssuer(), parseOptionItem)}

                        </td>
                        <td>
                            {parse(renderCarrier(), parseOptionItem)}
                        </td>
                    </tr>
                </table>
                <table style={{ tableLayout: 'fixed', width: ' 100%', padding: '7px 10px', borderCollapse: 'separate' }} >
                    <tr>
                        <td style={{ textAlign: 'center' }} >
                            <QRCode value={basicInfo?.qrCode || ''} size={200} />

                            {/* <img src={`http://chart.apis.google.com/chart?cht=qr&chs=200x200&chl=${qrCodeWithSlashes}&chld=H|0`} width="200px" height="200px" /> */}
                        </td>
                    </tr>
                </table>
                <table style={{ tableLayout: 'fixed', width: ' 100%', padding: '7px 10px', borderCollapse: 'separate' }} >
                    <tr>
                        <td style={{ textAlign: 'center', fontSize: '13px', fontWeight: 700 }}>
                            {translate('receipt_generated_by')} Vodafone eBiznes
                        </td>
                    </tr>
                </table>
            </div>
        </>
    )
})


const ConvertSupportingInvoiceWeb58m = forwardRef((props, ref) => {
    const {
        invoice,
        nipt,
        company,
        location,
    } = props
    const {
        basicInfo,
        transportInfo,
    } = invoice
    const { translate } = useTemplateLanguage()

    const renderReceiptInfo = () => {
        let r = ''
        r = `  <div style="font-size: 13px; padding:0px 0"><b>${translate('supporting_invoice_fwtnic')}</b>: ${basicInfo.fwtnic}</div>
        <div style="font-size: 13px; padding:0px 0"><b>${translate('supporting_invoice_seller')}</b>: ${basicInfo?.employeeDetails ? basicInfo?.employeeDetails?.firstName + ' ' + basicInfo?.employeeDetails?.lastName : basicInfo?.employeeEmail?.split('@')?.[0]}</div>
        <div style="font-size: 13px; padding:0px 0"><b>${translate('e_invoice_bussines_unit_code_label')}</b>: ${basicInfo.businUnitCode}</div>
        <div style="font-size: 13px; padding:0px 0"><b>${translate('receipt_operator_code_label')}</b>: ${basicInfo.operatorCode}</div>
        <div style="font-size: 13px; padding:0px 0"><b>${translate('receipt_software_code_label')}</b>: ${basicInfo.softCode}</div>
        <div style="font-size: 13px; padding:0px 0"><b>${translate('receipt_created_at')}</b>: ${basicInfo.date}</div>
        <div style="font-size: 13px; padding:0px 0"><b>${translate('supporting_invoice_number')}</b>: ${basicInfo.receiptNr}</div>
        <div style="font-size: 13px; padding:0px 0"><b>${translate('supporting_invoice_type')}</b>: ${basicInfo.invoiceType}</div>
        <div style="font-size: 13px; padding:0px 0"><b><b>${translate('supporting_invoice_transaction_type_label')}</b>: ${basicInfo.transactionType}</div>`
        return r
    }

    return (
        <>
            <style>
                {getPageMargins()}
            </style>
            <div style={{ width: '58mm', borderRadius: '8px' }} ref={ref}>
                <table style={{ tableLayout: 'fixed', width: '100%', padding: '10px 10px', borderCollapse: 'separate' }}>
                    <tr>
                        <td>
                            <div style={{ fontWeight: 700, fontSize: '16px' }} >
                                {company}
                            </div>

                            <div style={{ fontSize: '13px' }}>
                                {location}
                            </div>

                            <div style={{ fontSize: '13px' }} >
                                {translate('receipt_nipt_label')}: {nipt}
                            </div>

                            <div style={{ fontSize: '13px' }}>
                                {basicInfo?.receipt}
                            </div>
                        </td>
                    </tr>

                </table>


                <table style={{ tableLayout: 'fixed', width: ' 100%', padding: '7px 10px', borderCollapse: 'separate', borderTop: '1px dashed #ccc' }} >
                    <tr>
                        <td style={{
                            fontSize: 16,
                            fontWeight: 'bold',
                            paddingBottom: 5,
                        }}> {translate('supporting_invoice_receipt_info')}</td>
                    </tr>
                    <tr>
                        <td>
                            {parse(renderReceiptInfo(), parseOptionItem)}
                        </td>
                    </tr>
                </table>
                <table style={{ tableLayout: 'fixed', width: ' 100%', padding: '7px 10px', borderTop: '1px dashed #ccc' }}>
                    <tr>
                        <td style={{
                            fontSize: 16,
                            fontWeight: 'bold',
                            paddingBottom: 5,
                        }}>
                            {translate('supporting_invoice_transport_info_label')}
                        </td>
                    </tr>
                    <tr>
                        <td style={{ textAlign: 'left', padding: '3px 0', fontSize: '14px' }} >
                            <b>{translate('supporting_invoice_transport_owner_label')}: </b>  <br />{transportInfo.transportOwnership}
                        </td>
                    </tr>
                    <tr>
                        <td style={{ textAlign: 'left', padding: '3px 0', fontSize: '14px' }} >
                            <b>{translate('supporting_invoice_transport_plate')}:</b> {transportInfo.plates}
                        </td>
                    </tr>
                </table>
                <table style={{ tableLayout: 'fixed', width: ' 100%', padding: '7px 10px', borderCollapse: 'separate' }} >
                    <tr>
                        <td style={{ textAlign: 'center' }} >
                            <QRCode value={basicInfo?.qrCode || ''} size={200} />

                            {/* <img src={`http://chart.apis.google.com/chart?cht=qr&chs=200x200&chl=${qrCodeWithSlashes}&chld=H|0`} width="200px" height="200px" /> */}
                        </td>
                    </tr>
                </table>
                <table style={{ tableLayout: 'fixed', width: ' 100%', padding: '7px 10px', borderCollapse: 'separate' }} >
                    <tr>
                        <td style={{ textAlign: 'center', fontSize: '13px', fontWeight: 700 }}>
                            {translate('receipt_generated_by')} Vodafone eBiznes
                        </td>
                    </tr>
                </table>
            </div>
        </>
    )
})

const ConvertSupportingInvoice = forwardRef((props, ref) => {
    const { receiptFormat } = useTemplateLanguage()
    const dynamicContetn = {
        '80mm': ConvertSupportingInvoiceWeb58m,
        'A4': ConvertSupportingInvoiceWeb,
    }

    const Content = dynamicContetn[receiptFormat]
    if (!Content) { return <> </> }

    return <Content {...props} ref={ref} />
})


const processTypes = [
    {
        code: 'P1',
        description: 'process_type_p1_description',
        type: 'P1_CONTRACT',
    },
    {
        code: 'P2',
        description: 'process_type_p2_description',
        type: 'P2_PERIODIC_INVOICE',
    },
    {
        code: 'P3',
        description: 'process_type_p3_description',
        type: 'P3_UNFORESEEN_ORDER',
    },
    {
        code: 'P4',
        description: 'process_type_p4_description',
        type: 'P4_ADVANCE_PAYMENT',
    },
    {
        code: 'P5',
        description: 'process_type_p5_description',
        type: 'P5_SPOT_PAYMENT',
    },
    {
        code: 'P6',
        description: 'process_type_p6_description',
        type: 'P6_PAYMENT_BEFORE_PURCHASE',
    },
    {
        code: 'P7',
        description: 'process_type_p7_description',
        type: 'P7_DISPATCH_NOTE',
    },
    {
        code: 'P8',
        description: 'process_type_p8_description',
        type: 'P8_DISPATCH_NOTE_RECEIPT',
    },
    {
        code: 'P9',
        description: 'process_type_p9_description',
        type: 'P9_APPROVAL_OR_NEGATIVE',
    },
    {
        code: 'P10',
        description: 'process_type_p10_description',
        type: 'P10_CORRECTIVE_INVOICE',
        disabled: true,
    },
    {
        code: 'P11',
        description: 'process_type_p11_description',
        type: 'P11_PARTIAL_INVOICING',
    },
    {
        code: 'P12',
        description: 'process_type_p12_description',
        type: 'P12_SELF_INVOICE',
    },
]

const documentTypes = [
    {
        code: '388',
        description: 'document_type_388_description',
        type: 'TAXINVOICE',
    },
    {
        code: '82',
        description: 'document_type_82_description',
        type: 'METERED_SERVICE',
    },
    {
        code: '325',
        description: 'document_type_325_description',
        type: 'PROFORMA_INVOICE',
    },
    {
        code: '326',
        description: 'document_type_326_description',
        type: 'PARTIAL_INVOICE',
    },
    {
        code: '380',
        description: 'document_type_380_description',
        type: 'COMMERCIAL_INVOICE',
    },
    {
        code: '381',
        description: 'document_type_381_description',
        type: 'CREDIT_NOTE',
    },
    {
        code: '383',
        description: 'document_type_383_description',
        type: 'DEBIT_NOTE',
    },
    {
        code: '384',
        description: 'document_type_384_description',
        type: 'CORRECTEDINVOICE',
        disabled: true,
    },
    {
        code: '386',
        description: 'document_type_386_description',
        type: 'PREPAYMENT_INVOICE',
    },
    {
        code: '394',
        description: 'document_type_394_description',
        type: 'LEASE_INVOICE',
    },
]

const getProcessDescr = (processCode) => {
    const description = processTypes.find(item => item.type === processCode)
    return description?.description
}

const getDocumentDescr = (documentCode) => {
    const description = documentTypes.find(item => item.type === documentCode)
    return description?.description
}

const getDocumentDescrCode = (documentCode) => {
    const description = documentTypes.find(item => item.type === documentCode)
    return description?.code
}

const getUnitName = (listUnits, unitCode) => {
    const unit = listUnits.find(item => item.id === unitCode)
    return unit?.name
}

const ConvertEInvoiceWeb58mm = forwardRef((props, ref) => {
    const {
        Einvoice: { basicInfo, invoiceLine, additionalInfo, total, process, note },
        buyer,
        seller,
        listUnits,
    } = props
    const { translate } = useTemplateLanguage()
    const [items, setItems] = useState([])
    const mapItems = (unmappedData) => {
        const response = []
        unmappedData.map((item) => {
            const itemDetails = item?.item
            const taxCategory = itemDetails?.taxCategory
            const totalTaxMoney = item?.totalTaxMoney?.amount || 0
            const totalWithVatMoney = item?.totalMoney?.amount || 0
            let description = ''
            const allowance = item?.allowances
            allowance.map((el, ind) => {
                const amount = el?.amount
                if (ind === 0) {
                    description = `${el.chargeReason} (${amount?.amount} ${amount?.currency})`
                } else {
                    description = `${description}\n${el.chargeReason} (${amount?.amount} ${amount?.currency})`
                }
            })
            response.push({
                id: item?.id,
                name: itemDetails?.name,
                description: description,
                totalMoney: item?.lineExtension,
                quantity: item?.quantity,
                taxCategory: `${taxCategory?.id}-${taxCategory?.taxSchema} ${taxCategory?.percent} %`,
                tax: `${taxCategory?.percent}%`,
                unitCode: itemDetails?.baseQuantity?.unitCode,
                totalTaxMoney: totalTaxMoney,
                totalWithVatMoney: totalWithVatMoney,
                unitPriceWithVat: itemDetails?.price,
            })
        })
        return response
    }

    const notes = additionalInfo?.note?.filter(item => item.includes('note='))


    useEffect(() => {
        if (Array.isArray(invoiceLine)) {
            setItems(mapItems(invoiceLine))
        }
    }, [invoiceLine])

    const renderHeader = () => {
        const r = `
        <table style="table-layout:fixed;width:100%; padding:0 10px;">

        <tr>
            <td style="padding:0px;font-size: 14px">
                <b>${translate('e_invoice_number')}:</b> ${basicInfo?.id}
            </td>
        </tr>
        <tr>
            <td style="padding:0px;font-size: 14px">
                <b>${translate('e_invoice_date')}:</b> ${moment(note?.IssueDateTime).format('DD/MM/YYYY')}
            </td>
        </tr>
    </table>
    <table style="table-layout:fixed;padding:5px 10px; width: 100%;">
    <tr>
    <td>
        <div style=" font-size: 13px;">
            <b>${translate('e_invoice_seller_label')}:</b> ${seller?.registrationName}
        </div>
        <div style="font-size: 13px">
            <b>${translate('receipt_nipt_label')}:</b> ${seller?.issuerNUIS}
        </div>
        <div style="font-size: 13px">
            <b>${translate('e_invoice_address_label')}:</b> ${seller?.address.streetName}
        </div>
        <div style="font-size: 13px">
            <b>${translate('e_invoice_tel_label')}:</b> ${seller?.contact.telephone}
        </div>
    </td>
</tr>
<tr>
    <td style="padding-top: 10px;">
        <div style=" font-size: 13px;">
            <b>${translate('e_invoice_buyer_label')}:</b> ${buyer?.registrationName}
        </div>
        <div style="font-size: 13px">
            <b>${translate('receipt_nipt_label')}:</b> ${buyer?.issuerNUIS}
        </div>
        <div style="font-size: 13px">
            <b>${translate('e_invoice_address_label')}:</b> ${buyer?.address.streetName}
        </div>
        <div style="font-size: 13px">
           <b>${translate('e_invoice_tel_label')}:</b> ${buyer?.contact.telephone}
        </div>
    </td>
</tr>
</table>
    `

        return r
    }

    const renderItemsHeader = () => {
        const r = `<table style="width:100%;padding:5px 10px; border-bottom: 1px solid black; border-top: 1px dashed#ccc;">
        <td style="padding:3px 0px;">
            <div style="font-size: 14px; font-weight: 700;"> ${translate('e_invoice_item_label')}</div>
                <div style="font-size: 12px;">${translate('e_invoice_quantity_label')} (${translate('e_invoice_unit_label')}) *  ${translate('e_invoice_price_vat_label')}</div>
        </td>
        <td style="padding:3px 0px;text-align: right;">
            <div style="font-size: 14px; font-weight: 700;"> ${translate('e_invoice_total_label')}</div>
        </td>
    </table>`
        return r
    }


    const renderItem = (item) => {
        const r = `
        <table  style="width:100%; padding:0px 10px; border-bottom: 1px dashed black;">
        <tr>
            <td style="padding:2px 0px;">
                <div style="font-size: 13px;word-break: break-all;"> ${item?.name}</div>
                <div style="font-size: 12px;"> ${item?.quantity}  ${getUnitName(listUnits, item?.unitCode) || item?.unitCode} x  ${INF.format(item?.unitPriceWithVat?.amount || 0)} </div>
                <div style="font-size: 12px;">  ${translate('receipt_tax')} ( ${INF.format(item?.totalTaxMoney || 0)})</div>
            </td>
            <td style="padding:2px 0px;text-align: right;">
                <div style="font-size: 14px;">    ${INF.format((item?.totalWithVatMoney || 0))}  </div>
            </td>
        </tr>
    </table>`
        return r
    }

    const renderTotal = () => {
        const r = `
    <table style="width:100%; padding:0px 10px;">
        <tr>
            <td style="text-align:left;font-size: 12px; padding:0px">
            ${translate('e_invoice_total_discount_label')}:
            </td>
            <td style="text-align:right;font-size: 12px; padding:0px">
                ${INF.format((total?.allowance || 0))}
            </td>
        </tr>
        <tr>
            <td style="text-align:left;font-size: 12px; padding:0px">
            ${translate('e_invoice_total_amount_no_tax')}:
            </td>
            <td style="text-align:right;font-size: 12px; padding:0px">
            ${INF.format((total?.taxExclusive || 0))}
            </td>
        </tr>
        <tr>
            <td style="text-align:left;font-size: 12px; padding:0px">
            ${translate('e_invoice_total_tax_amount')}:
            </td>
            <td style="text-align:right;font-size: 12px; padding:0px">
            ${INF.format((total?.taxAmount || 0))}
            </td>
        </tr>
        <tr>
            <td style="text-align:left;font-size: 12px; padding:0px">
            ${translate('e_invoice_total_amount_with_tax')}:
            </td>
            <td style="text-align:right;font-size: 12px; padding:0px">
            ${INF.format((total?.taxInclusive || 0))}
            </td>
        </tr>
    </table>`
        return r
    }

    const renderNotes = () => {
        const r = `   <table style="padding:10px 10px;width: 100%;">
        <tr>
            <td>
            <div style="font-size:13px">
           <b>${translate('e_invoice_payment_type_label')}</b>: ${translate('e_invoice_payment_type_value')} 
            </div>
                <div style=" font-size: 12px;">
                    <b> ${translate('e_invoice_bussines_unit_code_label')}:</b> ${note?.BusinessUnitCode}
                </div>
                <div style=" font-size: 12px;">
                    <b>${translate('e_invoice_bussines_soft_code_label')}:</b> ${note?.SoftwareCode}
                </div>
                <div style=" font-size: 12px;">
                    <b>${translate('e_invoice_bussines_operator_code_label')}:</b> ${note?.OperatorCode}
                </div>
                <div style="font-size: 12px; word-break: break-all;">
                    <b>${translate('receipt_iic_label')}:</b> ${note?.IIC}
                </div>
                <div style=" font-size: 12px;">
                    <b>${translate('receipt_fic_label')}:</b> ${note?.FIC}
                </div>
                <div style=" font-size: 12px;">
                    <b>${translate('e_invoice_eic_label')}:</b> ${note?.EIC}
                </div>
            </td>
        </tr>
    </table>`
        return r
    }

    const renderReceiptInfo = () => {
        const r = `
        <table style="padding:5px 10px; width: 100%;">
        <tr>
            <td style="text-align:left;font-size: 14px; font-weight: 700;padding:0px">
            ${translate('e_invoice_info_label')}
            </td>
        </tr>
    </table>
    <table style="padding:0px 10px 0px 10px; width: 100%;table-layout:fixed;">
        <tr>
            <td>
                <div style=" font-size: 12px;">
                 ${translate('e_invoice_payment_bic_label')}  ${getDocumentDescrCode(process?.invoiceDocumentCode)} / ${translate('e_invoice_bill_type')}:  ${translate(getDocumentDescr(process?.invoiceDocumentCode))}
                </div>
                <div style=" font-size: 12px;">
                ${translate('e_invoice_proccess_type')}:  ${translate(getProcessDescr(process?.invoiceProcessCode))}
                </div>
            </td>
        </tr>
    </table>`
        return r
    }


    const renderRealNotes = () => {
        const r = `   <table style="padding:10px 10px;width: 100%;">
        <tr>
            <td style="font-size:13px; word-break: break-word;" >
                 <b>${translate('e_invoice_notes_label')}</b>: ${notes[0]?.slice(5) || '-'}
            </td>
        </tr>
    </table>`
        return r
    }

    const renderScanForMore = () => {
        const r = `   <table style="padding:10px 10px;width: 100%;">
           <tr>
            <td style="font-size:13px; " >
                 <b>${translate('e_invoice_scan_qr')}</b>
            </td>
        </tr>
    </table>`
        return r

    }

    const renderFooter = () => {
        const r = `
       
        <table style="width:100%; padding:10px 10px;border-top:1px dashed black;">
            <tr>
                <td style="text-align:center;font-size: 12px; padding:0px">
                ${translate('e_invoice_buyer_label')}
                </td>
            </tr>
            <tr>
                <td style="text-align:center;font-size: 10px; padding:0px">
                 ${translate('e_invoice_signature_field_label')}
                </td>
            </tr>
            <tr>
                <td style="text-align:center;padding:10px 0; border-bottom: 1px solid black;">
                </td>
            </tr>
        </table>
        <table style="width:100%; padding:10px 10px;">
            <tr>
                <td style="text-align:center;font-size: 12px; padding:0px">
                ${translate('e_invoice_carrier_label')}
                </td>
            </tr>
            <tr>
                <td style="text-align:center;font-size: 10px; padding:0px">
                 ${translate('e_invoice_signature_field_label')}
                </td>
            </tr>
            <tr>
                <td style="text-align:center;padding:10px 0; border-bottom: 1px solid black;">
                </td>
            </tr>
        </table>
        <table style="width:100%; padding:10px 10px;">
            <tr>
                <td style="text-align:center;font-size: 12px; padding:0px">
                ${translate('e_invoice_seller_label')}
                </td>
            </tr>
            <tr>
                <td style="text-align:center;font-size: 10px; padding:0px">
                 ${translate('e_invoice_signature_field_label')}
                </td>
            </tr>
            <tr>
                <td style="text-align:center;padding:10px 0; border-bottom: 1px solid black;">
                </td>
            </tr>
        </table>`
        return r
    }

    const renderShipment = () => {
        let r = ''
        if (!additionalInfo?.name && !additionalInfo.actualDeliveryDate && !additionalInfo?.streetName) {
            return r
        } else {
            r = ` <table style="padding:5px 10px;width:100%;table-layout:fixed">
            <tr>
                <td style="text-align:left;font-size:14px;font-weight:700;padding:0px">
                ${translate('e_invoice_remittances_label')}
                </td>
            </tr>
        </table>
        <table style="padding:0px 10px 10px 10px;width:100%;table-layout:fixed">
            <tr>
                <td>
                    <div style="font-size:12px">
                        <b> ${translate('e_invoice_recipient_name_label')}:</b> ${additionalInfo?.name}
                    </div>`

            if (additionalInfo.actualDeliveryDate) {
                r += ` <div style="font-size:12px">
            <b>${translate('e_invoice_date_of_shipment_label')}:</b> ${additionalInfo.actualDeliveryDate ? moment(additionalInfo.actualDeliveryDate)?.format('DD/MM/YYYY') : ''}
        </div>`
            }
            if (additionalInfo?.streetName) {
                r += `   <div style="font-size:12px">
            <b>${translate('e_invoice_address_label')}:</b>${additionalInfo?.streetName}
        </div>`
            }
            r += `  <div style="font-size:12px">
        <b>${translate('e_invoice_country_label')}:</b> ${additionalInfo?.countryCode}
    </div>
</td>
</tr>
</table>`
            return r
        }
    }


    return (
        <>
            <style>
                {getPageMargins()}
            </style>
            <div style={{ width: '58mm' }} ref={ref} >
                <table style={{ tableLayout: 'fixed', width: ' 100%', padding: '7px 10px', borderCollapse: 'separate' }} >
                    <tr>
                        <td style={{ padding: '10px 0', fontSize: '18px', fontWeight: 800 }}>
                            {translate('e_invoice_title_label')}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <QRCode value={note?.QrCode || ''} size={200} />
                        </td>
                    </tr>
                </table>
                {parse(renderHeader(), parseOptionItem)}
                {parse(renderItemsHeader(), parseOptionItem)}
                {items.length > 0 &&
                    items.map((item) => {
                        return parse(renderItem(item), parseOptionItem)
                    })
                }
                <table style={{ width: '100%', padding: '10px 10px' }} >
                    <tr>
                        <td style={{ textAlign: 'left', fontSize: 16, fontWeight: 'bold', padding: 0 }}>
                            {translate('receipt_total')}
                        </td>
                        <td style={{ textAlign: 'right', fontSize: 16, fontWeight: 'bold', padding: 0 }} >
                            {INF.format((basicInfo?.amount || 0))} {basicInfo?.amountCurrency}
                        </td>
                    </tr>
                </table>
                {parse(renderTotal(), parseOptionItem)}
                {parse(renderNotes(), parseOptionItem)}
                {parse(renderReceiptInfo(), parseOptionItem)}
                {parse(renderRealNotes(), parseOptionItem)}
                {notes?.length > 0 && parse(renderScanForMore(), parseOptionItem)}
                {parse(renderShipment(), parseOptionItem)}
                {parse(renderFooter(), parseOptionItem)}
                <table style={{ tableLayout: 'fixed', width: ' 100%', padding: '7px 10px', borderCollapse: 'separate' }} >
                    <tr>
                        <td style={{ textAlign: 'center', fontSize: '13px', fontWeight: 700 }}>
                            {translate('receipt_generated_by')} Vodafone eBiznes
                        </td>
                    </tr>
                </table>
            </div>
        </>
    )

})

const ConvertEInvoiceWebA4 = forwardRef((props, ref) => {
    const {
        Einvoice: { basicInfo, invoiceLine, additionalInfo, total, process, note },
        buyer,
        seller,
        listUnits,
    } = props
    const { translate } = useTemplateLanguage()
    const [items, setItems] = useState([])
    const mapItems = (unmappedData) => {
        const response = []
        unmappedData.map((item) => {
            const itemDetails = item?.item
            const taxCategory = itemDetails?.taxCategory
            const totalTaxMoney = item?.totalTaxMoney?.amount || 0
            const totalWithVatMoney = item?.totalMoney?.amount || 0

            let description = ''
            const allowance = item?.allowances
            allowance.map((el, ind) => {
                const amount = el?.amount
                if (ind === 0) {
                    description = `${el.chargeReason} (${amount?.amount} ${amount?.currency})`
                } else {
                    description = `${description}\n${el.chargeReason} (${amount?.amount} ${amount?.currency})`
                }
            })
            response.push({
                id: item?.id,
                name: itemDetails?.name,
                description: description,
                totalMoney: item?.lineExtension,
                quantity: item?.quantity,
                taxCategory: `${taxCategory?.id}-${taxCategory?.taxSchema} ${taxCategory?.percent} %`,
                tax: `${taxCategory?.percent}%`,
                unitCode: itemDetails?.baseQuantity?.unitCode,
                totalWithVatMoney: totalWithVatMoney,
                totalTaxMoney: totalTaxMoney,
                unitPriceWithVat: itemDetails?.price,
            })
        })
        return response
    }

    const notes = additionalInfo?.note?.filter(item => item.includes('note='))
    const noteLength = notes?.length
    const testDivRef = useRef(null) // ref for the div used to measure the DPI
    const [emptyElement, setEmptyElement] = useState(0) //height of the empty element

    const getSpaceLeft = () => {
        const children = ref?.current?.children
        let size = 0
        const screenDpi = testDivRef?.current?.offsetHeight
        for (let i = 0; i < children?.length; i++) {
            const child = children[i]
            const height = child?.offsetHeight
            const isLastElement = i === children?.length - 1
            const isSecondToLast = i === children?.length - 2
            const isThirdToLast = i === children?.length - 3
            // const isFirstElement = i === 0
            if (!isSecondToLast && !isLastElement && !isThirdToLast) {
                size = size + height
            }
        }
        const a4SizeInPx = ((297 * screenDpi) / 25.4) //  pageMargins Excluded
        const nrOfPages = Math.ceil(size / a4SizeInPx)
        const totalSizeInPx = nrOfPages * a4SizeInPx
        const spaceLeftFromNewPage = totalSizeInPx - size
        return Math.floor(spaceLeftFromNewPage)
    }

    useEffect(() => {
        setEmptyElement(getSpaceLeft())
    }, [])

    useEffect(() => {
        if (Array.isArray(invoiceLine)) {
            setItems(mapItems(invoiceLine))
        }
    }, [invoiceLine])

    const renderHeader = () => {
        const r = `
        <table style="table-layout:fixed;width:100%; padding:0 10px;">

        <tr>
            <td style="padding:0px;font-size: 14px">
                <b>${translate('e_invoice_number')}</b> ${basicInfo?.id}
            </td>
        </tr>
        <tr>
            <td style="padding:0px;font-size: 14px">
                <b>${translate('e_invoice_date')}:</b> ${moment(note?.IssueDateTime).format('DD/MM/YYYY')}
            </td>
        </tr>
    </table>
    <table style="table-layout:fixed;padding:5px 10px; width: 100%;">
    <tr>
    <td>
        <div style=" font-size: 13px;">
            <b>${translate('e_invoice_seller_label')}:</b> ${seller?.registrationName}
        </div>
        <div style="font-size: 13px">
            <b>${translate('receipt_nipt_label')}:</b> ${seller?.issuerNUIS}
        </div>
        <div style="font-size: 13px">
            <b>${translate('e_invoice_address_label')}:</b> ${seller?.address.streetName}
        </div>
        <div style="font-size: 13px">
            <b>${translate('e_invoice_tel_label')}:</b> ${seller?.contact.telephone}
        </div>
    </td>
    <td style="padding-top: 10px;">
    <div style=" font-size: 13px;">
        <b>${translate('e_invoice_buyer_label')}:</b> ${buyer?.registrationName}
    </div>
    <div style="font-size: 13px">
        <b>${translate('receipt_nipt_label')}:</b> ${buyer?.issuerNUIS}
    </div>
    <div style="font-size: 13px">
        <b>${translate('e_invoice_address_label')}:</b> ${buyer?.address.streetName}
    </div>
    <div style="font-size: 13px">
       <b>${translate('e_invoice_tel_label')}:</b> ${buyer?.contact.telephone}
    </div>
</td>
</tr>
</table>
    `

        return r
    }

    const renderItemsHeader = () => {
        const r = ` <table style="width:100%;padding:5px 10px; border-bottom: 1px solid black; border-top: 1px dashed#ccc; table-layout:fixed;">
        <tr>
        <td style="padding:3px 0px;font-size: 14px; font-weight: 700;vertical-align: top;">
        ${translate('e_invoice_item_label')}
        </td>
        <!-- <td style="padding:3px 0px; font-size: 14px; font-weight: 700;vertical-align: top;">
        ${translate('e_invoice_barcode_label')}
        </td> -->
        <td style="padding:3px 0px;font-size: 14px; font-weight: 700;vertical-align: top;">
        ${translate('e_invoice_unit_label')}
        </td>
        <td style="padding:3px 0px;font-size: 14px; font-weight: 700;vertical-align: top;">
        ${translate('e_invoice_quantity_label')}
        </td>
        <td style="padding:3px 0px;font-size: 14px; font-weight: 700;vertical-align: top;">
        ${translate('e_invoice_price_label')}
        </td>
        <td style="padding:3px 0px;font-size: 14px; font-weight: 700;vertical-align: top;">
        ${translate('e_invoice_price_without_vat_label')}
        </td>
        <!-- <td style="padding:3px 0px; font-size: 14px; font-weight: 700;vertical-align: top;">
        ${translate('e_invoice_no_tax_label')}
        </td> -->
        <td style="padding:3px 0px;font-size: 14px; font-weight: 700;vertical-align: top;">
        ${translate('e_invoice_tax_label')}
        </td>
        <!-- <td style="padding:3px 0px;font-size: 14px; font-weight: 700;vertical-align: top;">
        ${translate('e_invoice_price_vat_label')}
        </td> -->
        <td style="padding:3px 0px;font-size: 14px; font-weight: 700;vertical-align: top;">
        ${translate('e_invoice_total_label')}
        </td>
    </tr>
    </table>`
        return r
    }

    const renderItem = (item) => {
        const r = `
        <table style="table-layout:fixed; width:100%; padding:0px 10px; border-bottom: 1px dashed black;">
            <tr>
                <td style="padding:2px 0px;font-size: 12px;">
                    ${item?.name}
                </td>
                <td style="padding:2px 0px;font-size: 12px;">
                ${getUnitName(listUnits, item?.unitCode) || item?.unitCode}
                </td>
                <td style="padding:2px 0px;font-size: 12px">
                ${item?.quantity}
                </td>
           
            <td style="padding:2px 0px;font-size: 12px;">
            ${INF.format((item?.totalMoney?.amount || 0) / item?.quantity)} 
            </td>
            <td style="padding:2px 0px;font-size: 12px;">
            ${INF.format((item?.totalMoney?.amount || 0))} 
            </td>
                <td style="padding:2px 0px; font-size: 12px;">
                ${INF.format(item?.totalTaxMoney || 0)}
                </td>
                <!-- <td style="padding:2px 0px; font-size: 12px;">
                ${INF.format(item?.unitPriceWithVat?.amount || 0)} 
                </td> -->
                <td style="padding:2px 0px;font-size: 12px;">
                ${INF.format((item?.totalWithVatMoney || 0))} 
                </td>
            </tr>
        </table>`
        return r
    }

    const renderTotal = () => {
        const r = `
    <table style="width:100%; padding:0px 10px;">
        <tr>
            <td style="text-align:left;font-size: 12px; padding:0px">
            ${translate('e_invoice_total_discount_label')}
            </td>
            <td style="text-align:right;font-size: 12px; padding:0px">
                ${INF.format((total?.allowance || 0))}
            </td>
        </tr>
        <tr>
            <td style="text-align:left;font-size: 12px; padding:0px">
            ${translate('e_invoice_total_amount_no_tax')}:
            </td>
            <td style="text-align:right;font-size: 12px; padding:0px">
            ${INF.format((total?.taxExclusive || 0))}
            </td>
        </tr>
        <tr>
            <td style="text-align:left;font-size: 12px; padding:0px">
            ${translate('e_invoice_total_tax_amount')}:
            </td>
            </td>
            <td style="text-align:right;font-size: 12px; padding:0px">
            ${INF.format((total?.taxAmount || 0))}
            </td>
        </tr>
        <tr>
            <td style="text-align:left;font-size: 12px; padding:0px">
            ${translate('e_invoice_total_amount_with_tax')}:
            </td>
            <td style="text-align:right;font-size: 12px; padding:0px">
            ${INF.format((total?.taxInclusive || 0))}
            </td>
        </tr>
    </table>`
        return r
    }

    const renderNotes = () => {
        const r = `   <table style="padding:10px 10px;width: 100%;">
        <tr>
            <td>
                <div style=" font-size: 12px;">
                    <b> ${translate('e_invoice_bussines_unit_code_label')}:</b> ${note?.BusinessUnitCode}
                </div>
                <div style=" font-size: 12px;">
                    <b>${translate('e_invoice_bussines_soft_code_label')}:</b> ${note?.SoftwareCode}
                </div>
                <div style=" font-size: 12px;">
                    <b>${translate('e_invoice_bussines_operator_code_label')}:</b> ${note?.OperatorCode}
                </div>
                </td>
                <td>
                <div style="font-size: 12px; word-break: break-all;">
                    <b>${translate('receipt_iic_label')}:</b> ${note?.IIC}
                </div>
                <div style=" font-size: 12px;">
                    <b>${translate('receipt_fic_label')}:</b> ${note?.FIC}
                </div>
                <div style=" font-size: 12px;">
                    <b>${translate('e_invoice_eic_label')}:</b> ${note?.EIC}
                </div>
            </td>
        </tr>
    </table>`
        return r
    }

    const renderReceiptInfo = () => {
        const r = `
        <table style="padding:5px 10px; width: 100%;">
        <tr>
            <td style="text-align:left;font-size: 14px; font-weight: 700;padding:0px">
            ${translate('e_invoice_info_label')}
            </td>
        </tr>
    </table>
    <table style="padding:0px 10px 0px 10px; width: 100%;table-layout:fixed;">
        <tr>
            <td>
            <div style="font-size:13px">
            <b>${translate('e_invoice_payment_type_label')}</b>: ${translate('e_invoice_payment_type_value')} 
            </div>
                <div style=" font-size: 12px;">
                ${translate('e_invoice_payment_bic_label')}  ${getDocumentDescrCode(process?.invoiceDocumentCode)} / ${translate('e_invoice_bill_type')}:  ${translate(getDocumentDescr(process?.invoiceDocumentCode))}
                </div>
                <div style=" font-size: 12px;">
                ${translate('e_invoice_proccess_type')}:  ${translate(getProcessDescr(process?.invoiceProcessCode))}
                </div>
            </td>
        </tr>
    </table>`
        return r
    }

    const renderNoteLabel = () => {
        const r = `   <table style="padding:10px 10px ${noteLength < 1 ? '10px' : '0px'} 10px;width: 100%;">
        <tr>
            <td style="font-size:14px; word-break: break-all;" >
                 <b>${translate('e_invoice_notes_label')}${noteLength < 1 ? ': -' : ''}</b>
            </td>
        </tr>
    </table>`
        return r
    }

    const renderSingleNote = (text, isLast) => {
        const r = `   <table style="padding:0px 10px ${isLast ? '10px' : '0px'} 10px;width: 100%;">
        <tr>
            <td style="font-size:12px; word-break: break-word;" >
                ${text.slice(5)}
            </td>
        </tr>
    </table>`
        return r
    }

    const renderFooter = () => {
        const r = ` <table style="width:100%; padding:10px 10px;border-top:1px dashed black;">
        <tr>
            <td>
            <div style="text-align:center;font-size: 14px; padding:0px">
                ${translate('e_invoice_buyer_label')}
            </div>
            <div style="text-align:center;font-size: 12px; padding:0px">
                 ${translate('e_invoice_signature_field_label')}
            </div>
            <div style="text-align:center;padding:10px 0; border-bottom: 1px solid black;">
            </div>
        </td>
        <td>
            <div style="text-align:center;font-size: 14px; padding:0px">
            ${translate('e_invoice_carrier_label')}
            </div>
            <div style="text-align:center;font-size: 12px; padding:0px">
        ${translate('e_invoice_signature_field_label')}

            </div>
            <div style="text-align:center;padding:10px 0; border-bottom: 1px solid black;">
            </div>
        </td>
        <td>
            <div style="text-align:center;font-size: 14px; padding:0px">
            ${translate('e_invoice_seller_label')}
            </div>
            <div style="text-align:center;font-size: 12px; padding:0px">
             ${translate('e_invoice_signature_field_label')}

            </div>
            <div style="text-align:center;padding:10px 0; border-bottom: 1px solid black;">
            </div>
        </td>
        </tr>
    </table>`
        return r
    }
    const renderShipment = () => {
        let r = ''
        if (!additionalInfo?.name && !additionalInfo.actualDeliveryDate && !additionalInfo?.streetName) {
            return r
        } else {
            r = ` <table style="padding:5px 10px;width:100%;table-layout:fixed">
            <tr>
                <td style="text-align:left;font-size:14px;font-weight:700;padding:0px">
                ${translate('e_invoice_remittances_label')}
                </td>
            </tr>
        </table>
        <table style="padding:0px 10px 10px 10px;width:100%;table-layout:fixed">
            <tr>
                <td>
                    <div style="font-size:12px">
                        <b> ${translate('e_invoice_recipient_name_label')}:</b> ${additionalInfo?.name}
                    </div>`

            if (additionalInfo.actualDeliveryDate) {
                r += ` <div style="font-size:12px">
            <b>${translate('e_invoice_date_of_shipment_label')}:</b> ${additionalInfo.actualDeliveryDate ? moment(additionalInfo.actualDeliveryDate)?.format('DD/MM/YYYY') : ''}
        </div>`
            }
            if (additionalInfo?.streetName) {
                r += `   <div style="font-size:12px">
            <b>${translate('e_invoice_address_label')}:</b>${additionalInfo?.streetName}
        </div>`
            }
            r += `  <div style="font-size:12px">
        <b>${translate('e_invoice_country_label')}:</b> ${additionalInfo?.countryCode}
    </div>
</td>
</tr>
</table>`
            return r
        }
    }
    return (
        <>
            <style>
                {getPageMargins()}
            </style>
            <div id="testdiv" ref={testDivRef} style={{ height: '1in', left: '-100%', position: 'absolute', top: '-100%', width: '1in' }} />
            <div style={{ width: '210mm' }} ref={ref} >
                <table style={{ tableLayout: 'fixed', width: ' 100%', padding: '7px 10px', borderCollapse: 'separate' }} >
                    <tr>
                        <td style={{ padding: '10px 0', fontSize: '18px', fontWeight: 800 }}>
                            {translate('e_invoice_title_label')}
                        </td>
                    </tr>
                </table>
                {parse(renderHeader(), parseOptionItem)}
                {parse(renderItemsHeader(), parseOptionItem)}
                {items.length > 0 &&
                    items.map((item) => {
                        return parse(renderItem(item), parseOptionItem)
                    })
                }
                <table style={{ width: '100%', padding: '10px 10px' }} >
                    <tr>
                        <td style={{ textAlign: 'left', fontSize: 16, fontWeight: 'bold', padding: 0 }}>
                            {translate('receipt_total')}
                        </td>
                        <td style={{ textAlign: 'right', fontSize: 16, fontWeight: 'bold', padding: 0 }} >
                            {INF.format((basicInfo?.amount || 0))} {basicInfo?.amountCurrency}
                        </td>
                    </tr>
                </table>
                {parse(renderTotal(), parseOptionItem)}
                {parse(renderNotes(), parseOptionItem)}
                {parse(renderReceiptInfo(), parseOptionItem)}
                {parse(renderNoteLabel(), parseOptionItem)}
                {noteLength > 0 && notes?.map((item, index) => {
                    const isLast = index === noteLength - 1
                    return parse(renderSingleNote(item, isLast), parseOptionItem)

                })}
                {parse(renderShipment(), parseOptionItem)}
                {parse(renderFooter(), parseOptionItem)}
                {emptyElement < 210 && <div style={{ height: `${emptyElement}px`, width: '100%' }} />}
                <table style={{ tableLayout: 'fixed', width: ' 100%', padding: '7px 10px', borderCollapse: 'separate' }} >
                    <tr>
                        <td style={{ textAlign: 'center' }} >
                            <QRCode value={note?.QrCode || ''} size={200} />
                        </td>
                    </tr>
                </table>
                <table style={{ tableLayout: 'fixed', width: ' 100%', padding: '7px 10px', borderCollapse: 'separate' }} >
                    <tr>
                        <td style={{ textAlign: 'center', fontSize: '13px', fontWeight: 700 }}>
                            {translate('receipt_generated_by')} Vodafone eBiznes
                        </td>
                    </tr>
                </table>

            </div>
        </>
    )

})

const ConvertEInvoice = forwardRef((props, ref) => {
    const { receiptFormat } = useTemplateLanguage()
    const listUnits = useUnits()

    const dynamicContetn = {
        '80mm': ConvertEInvoiceWeb58mm,
        'A4': ConvertEInvoiceWebA4,
    }

    const Content = dynamicContetn[receiptFormat]
    if (!Content) { return <> </> }

    return <Content listUnits={listUnits} {...props} ref={ref} />
})

const ConvertPayinPayoutFormats = forwardRef((props, ref) => {
    const {
        eventData,
        company,
        location,
        nipt,
        receiptFormat,
    } = props

    const { translate } = useTemplateLanguage()

    const formats = {
        'A4': '210mm',
        '80mm': '58mm',
    }

    const formatWidth = formats?.[receiptFormat] || '210mm'

    const replaceSpecialChars = (str) => str?.replace(/[ëçÇË]/g, m => chars[m])


    const amount = eventData?.eventMoney
    var chars = { 'ë': 'e', 'ç': 'c', 'Ç': 'C', 'Ë': 'E' }
    const currency = amount?.currency.replace(/[ëçÇË]/g, m => chars[m])
    const amountMoney = eventData?.eventType === 'PAY_OUT' ? `-${amount?.amount}` : amount?.amount
    const price = `${amountMoney} ${currency}`

    const renderItem = () => {
        const r = `
        <table style="table-layout:fixed; width:100%; padding:0px 10px; border-bottom: 1px dashed black;">
            <tr>
                <td style="padding:2px 0px;font-size: 12px;">
                    ${replaceSpecialChars(eventData?.description)}
                </td>
                <td style="padding:2px 0px;font-size: 12px;text-align:right;">
                ${amountMoney}
                </td>  
            </tr>         
        </table>`
        return r
    }

    const renderInfo = () => {
        const r = `
        <table style="table-layout:fixed; width:100%; padding:0px 10px; border-top: 1px dashed black;">
                <tr>
                    <td style="padding:2px 0px;font-size: 12px;">
                        ${translate('pay_in_out_number')}: ${eventData?.statementNumber}
                    </td>
                </tr> 
                <tr>
                    <td style="padding:2px 0px;font-size: 12px;">
                        ${translate('receipt_refund_reason')}: ${replaceSpecialChars(eventData?.reason)}
                    </td>
                </tr> 
                <tr>
                    <td style="padding:2px 0px;font-size: 12px;">
                        ${translate('receipt_seller_label')}: ${eventData?.employee?.firstName || ' '}
                    </td>
                </tr> 
                <tr>
                    <td style="padding:2px 0px;font-size: 12px;">
                        ${translate('receipt_date_time_label')}: ${moment(eventData?.createdAt).format('DD-MM-YY HH:mm')}
                    </td>
                </tr> 
                <tr>
                    <td style="padding:2px 0px;font-size: 12px;">
                        ${translate('receipt_operator_code')}: ${eventData?.employee?.operatorCode}
                    </td>
                </tr> 
                <tr>
                    <td style="padding:2px 0px;font-size: 12px;">
                        ${translate('receipt_software_code_label')}: ${eventData?.softCode}
                    </td>
                </tr> 
                <tr>
                    <td style="padding:2px 0px;font-size: 12px;">
                        ${translate('pay_in_out_buscode_label')}: ${eventData?.businUnitCode}
                    </td>
                </tr> 
                <tr>
                    <td style="padding:2px 0px;font-size: 12px;">
                        ${translate('pay_in_out_id')}: ${eventData?.FCDC || ' '}
                    </td>
                </tr> 
        </table>`
        return r
    }

    return (
        <>
            <style>
                {getPageMargins()},
            </style>
            <div style={{ width: formatWidth, borderRadius: '8px' }} ref={ref}>
                <table style={{ tableLayout: 'fixed', width: '100%', padding: '16px 10px 16px 10px', borderCollapse: 'separate' }}>
                    <tr>
                        <td>
                            <div style={{ fontWeight: 700, fontSize: '16px' }} >
                                {company}
                            </div>

                            <div style={{ fontSize: '13px' }}>
                                {location}
                            </div>

                            <div style={{ fontSize: '13px' }} >
                                {translate('receipt_nipt_label')}: {nipt}
                            </div>

                        </td>
                    </tr>

                </table>
                <table style={{ tableLayout: 'fixed', width: ' 100%', borderTop: '1px dashed #ccc' }}>
                    <tr>
                        <td style={{
                            textAlign: 'center',
                            padding: '16px 10px',
                            fontSize: 18,
                            fontWeight: 700,
                        }}>
                            {translate('pay_in_out_title')}
                        </td>
                    </tr>
                </table>
                <table style={{ tableLayout: 'fixed', width: ' 100%', backgroundColor: '#eee' }}>
                    <tr>
                        <td style={{ textAlign: 'left', padding: '5px 10px', fontSize: '16px' }}>
                            {translate('pay_in_out_description')}
                        </td>
                        <td style={{ textAlign: 'right', padding: '5px 10px', fontSize: '16px' }}>
                            {translate('pay_in_out_sum')}
                        </td>
                    </tr>
                </table>
                {/* item */}
                {parse(renderItem(), parseOptionItem)}
                {/* <!-- Totals --> */}
                <table style={{ tableLayout: 'fixed', width: ' 100%', padding: '7px 10px', borderCollapse: 'separate' }}>
                    <tr>
                        <td style={{ textAlign: 'left', padding: '3px 0', fontSize: '20px', fontWeight: 'bold' }} >
                            {translate('receipt_total_label')}:
                        </td>
                        <td style={{ textAlign: 'right', padding: '3px 0', fontSize: '20px', fontWeight: 'bold' }}>
                            {price}
                        </td>
                    </tr>
                </table>
                {parse(renderInfo(), parseOptionItem)}

                <table style={{ tableLayout: 'fixed', width: ' 100%', padding: '7px 10px', borderCollapse: 'separate' }} >
                    <tr>
                        <td style={{ textAlign: 'center', fontSize: '13px', fontWeight: 700 }}>
                            {translate('receipt_generated_by')} Vodafone eBiznes
                        </td>
                    </tr>
                </table>
            </div>
        </>
    )
})


const ConvertPayinPayout = forwardRef((props, ref) => {
    const { receiptFormat } = useTemplateLanguage()
    return <ConvertPayinPayoutFormats {...props} ref={ref} receiptFormat={receiptFormat} />
})

export {
    ConvertRecipt,
    ConvertRefund,
    ConvertSupportingInvoice,
    ConvertEInvoice,
    ConvertPayinPayout,
    ConvertOldInvoice,
    ReactToPDF,
}
