/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import {
  View,
  Dimensions,
  LayoutAnimation,
} from 'react-native'
import ContentLoader, { Rect } from 'react-content-loader/native'
import { useQuery, useLazyQuery } from '@apollo/client'
import { useTheme } from 'react-native-themed-styles'
import PropTypes from 'prop-types'

import {
  SimpleList,
} from '../../../../Components'

import { GET_BUYER_SELLER, GET_TAX_PAYER } from '../../EInvoiceDetails.Schema'
import themedStyles from '../../EInvoiceDetails.Styles'

import { layoutAnimConfig } from '../../../../Themes/Theme'

const { width } = Dimensions.get('screen')

function LoaderContent({ isFirst, isLast, style }) {
  const [styles] = useTheme(themedStyles)
  const loaderStyle = {}
  if (isFirst) {
    loaderStyle.borderTopLeftRadius = 7
    loaderStyle.borderTopRightRadius = 7
  }
  if (isLast) {
    loaderStyle.borderBottomLeftRadius = 7
    loaderStyle.borderBottomRightRadius = 7
    loaderStyle.borderBottomWidth = 0
  }
  return (
    <ContentLoader
      speed={0.7}
      height={70}
      style={Object.assign({}, styles.loaderContainer, loaderStyle, style)}
      backgroundColor="#e2e2e2"
      foregroundColor="#ecebeb">
      <Rect x="20" y="20" rx="3" ry="3" width="90" height="20" />
      <Rect x={`${width - 150}`} y="18" rx="3" ry="3" width="100" height="24" />
    </ContentLoader>
  )
}
LoaderContent.propTypes = {
  isFirst: PropTypes.bool,
  isLast: PropTypes.bool,
  style: PropTypes.object,
}

function SingleLoaderContent({ isFirst, isLast, style }) {
  const loaderStyle = {}
  if (isFirst) {
    loaderStyle.borderTopLeftRadius = 7
    loaderStyle.borderTopRightRadius = 7
  }
  if (isLast) {
    loaderStyle.borderBottomLeftRadius = 7
    loaderStyle.borderBottomRightRadius = 7
  }
  return (
    <ContentLoader
      speed={0.7}
      height={40}
      width={200}
      style={Object.assign({}, loaderStyle, style)}
      backgroundColor="#e2e2e2"
      foregroundColor="#ecebeb">
      <Rect x="0" y="10" rx="3" ry="3" width="120" height="20" />
    </ContentLoader>
  )
}
SingleLoaderContent.propTypes = {
  isFirst: PropTypes.bool,
  isLast: PropTypes.bool,
  style: PropTypes.object,
}

function ItemLoader() {
  return (
    <>
      <View>
        <SingleLoaderContent />
        <LoaderContent isFirst />
        <LoaderContent />
        <LoaderContent />
        <LoaderContent />
        <LoaderContent isLast />
      </View>
      <View>
        <SingleLoaderContent />
        <LoaderContent isFirst />
        <LoaderContent />
        <LoaderContent />
        <LoaderContent />
        <LoaderContent isLast />
      </View>
    </>
  )
}

function BuyerSeller({ id }) {
  const [styles] = useTheme(themedStyles)

  const [items, setItems] = useState([])

  const { data, refetch, loading } = useQuery(GET_BUYER_SELLER, { fetchPolicy: 'network-only', variables: { eic: id } })
  const [getBuyer, { data: buyerData, loading: buyerLoading }] = useLazyQuery(GET_TAX_PAYER, { fetchPolicy: 'network-only' })
  const [getSeller, { data: sellerData, loading: sellerLoading }] = useLazyQuery(GET_TAX_PAYER, { fetchPolicy: 'network-only' })

  const mapData = (buyer, seller) => {
    const response = []
    let section = null
    section = {
      id: 3,
      sectionTitle: 'einvoice_seller_filter',
      data: [],
    }
    const buyerAddress = buyer?.address
    const sellerAddress = seller?.address
    response.push(section)
    response[response.length - 1]?.data.push(
      {
        id: 1,
        leftText: 'einvoice_details_nipt_label',
        rightText: seller?.issuerNUIS,
      },
      {
        id: 2,
        leftText: 'einvoice_details_name_label',
        rightText: seller?.businessName,
      },
      {
        id: 3,
        leftText: 'einvoice_details_address_label',
        rightText: sellerAddress?.addressLine1,
      },
      {
        id: 4,
        leftText: 'einvoice_details_city_label',
        rightText: sellerAddress?.administrativeDistrictLevel1,
      },
      {
        id: 5,
        leftText: 'einvoice_details_state_label',
        rightText: sellerAddress?.country,
      },
    )
    section = {
      id: 4,
      sectionTitle: 'einvoice_buyer_filter',
      data: [],
    }
    response.push(section)
    response[response.length - 1]?.data.push(
      {
        id: 1,
        leftText: 'einvoice_details_nipt_label',
        rightText: buyer?.issuerNUIS,
      },
      {
        id: 2,
        leftText: 'einvoice_details_name_label',
        rightText: buyer?.businessName,
      },
      {
        id: 3,
        leftText: 'einvoice_details_address_label',
        rightText: buyerAddress?.addressLine1,
      },
      {
        id: 4,
        leftText: 'einvoice_details_city_label',
        rightText: buyerAddress?.administrativeDistrictLevel1,
      },
      {
        id: 5,
        leftText: 'einvoice_details_state_label',
        rightText: buyerAddress?.country,
      },
    )
    return response
  }

  useEffect(() => {
    const invoiceData = data?.getEinvoices?.eInvoices?.[0]?.attributes
    if (invoiceData) {
      getBuyer({
        variables: {
          name: 'businessTin',
          value: invoiceData?.BuyerTin,
        },
      })
      getSeller({
        variables: {
          name: 'businessTin',
          value: invoiceData?.SellerTin,
        },
      })
    }
  }, [data])

  useEffect(() => {
    const buyer = buyerData?.getBusinessGov?.customers?.[0]
    const seller = sellerData?.getBusinessGov?.customers?.[0]
    if (buyer && seller) {
      const newData = mapData(buyer, seller)
      LayoutAnimation.configureNext(layoutAnimConfig)
      setItems(newData)
    }
  }, [buyerData, sellerData])

  return (
    <SimpleList
      DATA={items}
      containerStyle={styles.listStyles}
      separatorStyle={styles.separatorStyle}
      withSectionHeader
      sectionList
      initialNumToRender={20}
      refreshing={false}
      onRefresh={refetch}
      sectionHeaderStyle={styles.sectionHeader}
      listFooterComponent={(loading || (buyerLoading && sellerLoading)) && <ItemLoader />}
    />
  )
}
BuyerSeller.propTypes = {
  id: PropTypes.string,
}

export default BuyerSeller
