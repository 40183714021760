import React from 'react'
import PropTypes from 'prop-types'
import { ScrollView } from 'react-native'

function ScrollWrapperWeb(props) {
  const { children, ...otherProps } = props
  return (
    <ScrollView showsVerticalScrollIndicator={false} {...otherProps}>
      {children}
    </ScrollView>
  )
}
ScrollWrapperWeb.propTypes = {
  children: PropTypes.node,
}

export default ScrollWrapperWeb
