import React, { useContext } from 'react'
import { View } from 'react-native'
import PropTypes from 'prop-types'

import { useTheme } from 'react-native-themed-styles'

import Text from '../Text'

import Theme from '../../Themes/Theme'

import themedStyles from './SimpleListElement.Styles'

function SimpleListElement(props) {
  const [styles] = useTheme(themedStyles)
  const { colors } = useContext(Theme)
  const {
    leftText,
    leftTextProps,
    leftComponent,
    leftTextStyle,
    leftDesc,
    leftDescStyle,
    leftExtraInfo,
    leftExtraInfoStyle,
    rightText,
    rightTextProps,
    rightComponent,
    rightTextStyle,
    rightDesc,
    rightDescStyle,
    rightExtraInfo,
    rightExtraInfoStyle,
    style,
    ratio,
  } = props

  const leftStyle = {}, rightStyle = {}
  var res = ratio?.split(':')
  leftStyle.flex = Number(res?.[0] || 1)
  rightStyle.flex = Number(res?.[1] || 1)

  return (
    <View style={[styles.container, style]}>
      <View style={styles.subContainer}>
        {(leftText && (
          <View style={[styles.textContainer, leftStyle]}>
            <Text
              size="footnote"
              weight="light"
              align="left"
              style={[styles.text, leftTextStyle]}
              i18nKey={leftText}
              {...leftTextProps}
            />
            {leftDesc && (
              <Text
                size="footnote"
                weight="light"
                align="left"
                color={colors.secondaryText}
                style={[styles.desc, leftDescStyle]}
                i18nKey={leftDesc} />
            )}
            {leftExtraInfo && (
              <Text
                size="footnote"
                weight="light"
                align="left"
                style={[styles.extraInfo, leftExtraInfoStyle]}
                i18nKey={leftExtraInfo} />
            )}
          </View>
        )) ||
          leftComponent || null}
        {(rightText && (
          <View style={[styles.rightTextContainer, rightStyle]}>
            <Text
              size="footnote"
              align="right"
              style={[styles.text, rightTextStyle]}
              i18nKey={rightText}
              {...rightTextProps}
            />
            {rightDesc && (
              <Text
                size="footnote"
                weight="light"
                align="right" color={colors.secondaryText}
                style={rightDescStyle}
                i18nKey={rightDesc}
              />
            )}
            {rightExtraInfo && (
              <Text
                size="footnote"
                weight="light"
                align="right"
                color={colors.secondaryText}
                style={[styles.extraInfo, rightExtraInfoStyle]}
                i18nKey={rightExtraInfo} />
            )}
          </View>
        )) ||
          rightComponent || null}
      </View>
    </View>
  )
}

SimpleListElement.propTypes = {
  leftText: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  leftTextStyle: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  leftComponent: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  leftDesc: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  leftDescStyle: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  rightText: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  rightTextStyle: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  rightComponent: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  rightDesc: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  rightDescStyle: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  style: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  rightContainerStyle: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  ratio: PropTypes.string,
  leftContainerStyle: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  leftTextProps: PropTypes.object,
  leftExtraInfo: PropTypes.string,
  leftExtraInfoStyle: PropTypes.object,
  rightTextProps: PropTypes.object,
  rightExtraInfo: PropTypes.string,
  rightExtraInfoStyle: PropTypes.object,
}

export default SimpleListElement
