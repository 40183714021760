/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState, useEffect } from 'react'
import { Platform, KeyboardAvoidingView, View, SafeAreaView, ScrollView } from 'react-native'
import DocumentPicker from 'react-native-document-picker'
import moment from 'moment'
import PropTypes from 'prop-types'
import RNFS from 'react-native-fs'
import { useTheme } from 'react-native-themed-styles'
import { useDispatch, useSelector } from 'react-redux'
import { useQuery, useMutation } from '@apollo/client'

import InfoInput from '../RegisterCertificate/InfoInput'

import { onBoardingUpdateStepKey } from '../OnBoarding/OnBoarding.Actions'
import { useUserDetails, useLocationDetails } from '../../Utils/AuthDetails'
import { getOnBoardingCertificate } from '../OnBoarding/OnBoarding.Selectors'
import { GET_MERCHANT, SEND_PIN,GENERATE_AND_SEND_PIN } from '../RegisterCertificate/RegisterCertificate.schema'

import {
    Button,
    Header,
    ErrorModal,
    Section,
    ListLoader,
} from '../../Components'

import { getErrorMessage } from '../../Utils'

import Theme from '../../Themes/Theme'

import themedStyles from './OnBoardingSecondStep.Styles'

import images from '../../Themes/Images'


function OnBoardingSecondStep({ next, selectedPage, skip, logout }) {
    const { phoneNumber, email, id } = useUserDetails()
    const { NIPT } = useLocationDetails()
    const [certificate, setCertificate] = useState({
        items: [],
        isLoading: true,
    })


    const [sendPin, { loading: sendPinLoading }] = useMutation(GENERATE_AND_SEND_PIN)

    useEffect(() => {
        updateCertificate('attached', false)
        updateCertificate('fileName', '')
        updateCertificate('base64', '')
        updateCertificate('password', '')
    }, [])

    const DOCUMENT_IMPORT_TYPE = Platform.select({
        ios: ['public.data'],
        android: [DocumentPicker.types.allFiles],
    })

    const [modal, setModal] = useState({
        isVisible: false,
        icon: images.warningIcon,
        title: '',
        desc: '',
        descPlaceholders: [],
        primaryText: '',
        primaryAction: null,
        tertiaryText: '',
        tertiaryAction: null,
    })
    const openModal = (
        title = 'opened_orders_error',
        desc = '',
        descPlaceholders = [],
        primaryText = '',
        primaryAction = null,
        tertiaryText = '',
        tertiaryAction = null,
        icon = images.warningIcon,
    ) => {
        setModal({
            isVisible: true,
            icon,
            title,
            desc,
            descPlaceholders,
            primaryText,
            primaryAction,
            tertiaryText,
            tertiaryAction,
        })
    }
    const closeModal = () => {
        setModal({
            isVisible: false,
            icon: images.delete,
            title: '',
            desc: '',
            primaryText: '',
            primaryAction: null,
            tertiaryText: '',
            tertiaryAction: null,
        })
    }

    const [disabled, setDisabled] = useState(false)
    const [niptError] = useState(false)
    const storedCertificate = useSelector(getOnBoardingCertificate)
    const [styles] = useTheme(themedStyles)
    const { colors } = useContext(Theme)

    const dispatchAction = useDispatch()

    const { loading } = useQuery(GET_MERCHANT, {
        fetchPolicy: 'network-only',
        variables: {
            id: id,
        },
        onCompleted: (newData) => {
            const certData = newData?.getMerchant?.certificate
            if (certData) {
                updateCertificate('hasCertificate', true)
                const subject = certData?.CertData?.subject
                const issuer = certData?.CertData?.issuer
                setCertificate({
                    items: [
                        {
                            title: 'certificate_info_section',
                        },
                        {
                            label: 'certificate_organisation_label',
                            value: subject?.O,
                        },
                        {
                            label: 'certificate_email_label',
                            value: certData?.contactEmail,
                        },
                        {
                            label: 'certificate_nipt_label',
                            value: subject?.SN,
                        },
                        {
                            label: 'certificate_id_label',
                            value: certData?.personalId,
                        },
                        {
                            label: 'certificate_phone_label',
                            value: `${certData?.phoneNumber}`,
                        },
                        {
                            label: 'certificate_start_date_label',
                            value: moment(new Date(certData?.CertData?.notBefore).toISOString()).format(
                                'DD/MM/YYYY hh:mm A',
                            ),
                        },
                        {
                            label: 'certificate_expiration_date_label',
                            value: moment(new Date(certData?.CertData?.notAfter).toISOString()).format(
                                'DD/MM/YYYY hh:mm A',
                            ),
                        },
                        {
                            label: 'location_label_location',
                            value: `${subject?.L}, ${subject?.C}`,
                        },
                        {
                            label: 'certificate_issuer_label',
                            value: issuer?.CN,
                        },
                        {
                            label: 'certificate_subject_label',
                            value: subject?.CN,
                        },
                    ],
                    isLoading: false,
                })
                setDisabled(false)
            }
        },
        skip: !id || selectedPage !== 2,
    })

    const updateCertificate = (key, value) => {
        dispatchAction(onBoardingUpdateStepKey('certificateFirst', key, value))
    }

    useEffect(() => {
        if (
            storedCertificate.nipt === '' ||
            storedCertificate.email === '' ||
            storedCertificate.phone === '' ||
            storedCertificate.base64 === '' ||
            storedCertificate.fileName === '' ||
            storedCertificate.password === '' ||
            storedCertificate.attached === false
        ) {
            setDisabled(true)
        } else {
            setDisabled(false)
        }
    }, [
        storedCertificate.nipt,
        storedCertificate.email,
        storedCertificate.phone,
        storedCertificate.base64,
        storedCertificate.password,
        storedCertificate.fileName,
        storedCertificate.attached,
    ])

    useEffect(() => {
        if (NIPT) {
            updateCertificate('nipt', NIPT)
            updateCertificate('email', email)
            updateCertificate('phone', phoneNumber.slice(4))
        }
    }, [NIPT, email, phoneNumber])

    const attachfile = async () => {
        try {
            const res = await DocumentPicker.pick({
                type: DOCUMENT_IMPORT_TYPE,
            })
            if (res[0]?.type !== 'application/x-pkcs12' && res[0]?.type !== 'application/octet-stream') {
                openModal(
                    'certificate_error_label',
                    'certificate_incorrect_format_description',
                )
            } else {
                RNFS.readFile(decodeURI(res[0]?.uri), 'base64')
                    .then((dataa) => {
                        // setBase64File(dataa)
                        // setFileName(res.name)
                        updateCertificate('base64', dataa)
                        updateCertificate('fileName', res[0]?.name)
                        updateCertificate('attached', true)

                    })
                    .catch(() => openModal(
                        'certificate_error_label',
                        'certificate_upload_error',
                    ))
            }
        } catch (err) {
            if (DocumentPicker.isCancel(err)) {
            } else {
                throw err
            }
        }
    }

    // function to atach files only on web NOTE:Is not used in react-native
    const attachFileWeb = (acceptedFiles) => {
        if (acceptedFiles.length > 0) {
            // eslint-disable-next-line no-undef
            const reader = new FileReader()
            reader.readAsDataURL(acceptedFiles[0])
            reader.onload = function () {
                updateCertificate('base64', reader.result.replace('data:application/x-pkcs12;base64,', ''))
                updateCertificate('fileName', acceptedFiles[0].name)
                updateCertificate('attached', true)
            }
            reader.onerror = function (error) {
                openModal(
                    'certificate_error_label',
                    images.error,
                    'certificate_upload_error',
                )
            }
        } else {
            openModal(
                'certificate_error_label',
                images.error,
                'certificate_incorrect_format_description',
            )
        }
    }

    const sendCode = () => {
        if (storedCertificate.hasCertificate) {
            skip()
        } else {
            sendPin({
                variables: {
                     id: 'static',
                },
            })
                .then(() => {
                    dispatchAction(onBoardingUpdateStepKey('certificateSecond', 'code', ''))
                    next()
                })
                .catch((e) => {
                    openModal(
                        'certificate_error_label',
                        getErrorMessage(e),
                        [],
                        'certificate_button_try_again',
                        () => {
                            closeModal()
                        },
                        '',
                        undefined
                    )
                })
        }

    }

    return (
        <>
            <SafeAreaView style={styles.wrapper}>
                <Header
                    title="certificate_upload_label"
                    image={images.close}
                    onPress={() => {
                        openModal(
                            'logout_key',
                            'onboarding_logout_message_description',
                            [],
                            'logout_key',
                            () => {
                                logout()
                            },
                            'logout_button_cancel',
                            () => {
                                closeModal()
                            },
                        )
                    }}
                />
                <KeyboardAvoidingView
                    behavior={Platform.select({
                        ios: 'padding',
                        android: undefined,
                    })}
                    style={styles.container}
                >
                    {loading ?
                        <ScrollView
                            keyboardShouldPersistTaps="always"
                            showsVerticalScrollIndicator={false}
                            keyboardDismissMode="on-drag"
                            contentContainerStyle={styles.loaderWrapper}
                        >
                            <ListLoader
                                loading={certificate.isLoading}
                                isInitial={certificate.items.length === 0}
                            />
                        </ScrollView>
                        : storedCertificate.hasCertificate ?
                            <ScrollView showsVerticalScrollIndicator={false}>
                                <Section style={styles.buttonContainer} data={certificate.items} />
                            </ScrollView>
                            :
                            <InfoInput
                                userPhone={storedCertificate.phone}
                                setPhone={(val) => updateCertificate('phone', val)}
                                nipt={storedCertificate.nipt}
                                setNipt={() => { }}
                                niptError={niptError}
                                userEmail={storedCertificate.email}
                                setEmail={() => { }}
                                attachfile={attachfile}
                                attached={storedCertificate.attached}
                                fileName={storedCertificate.fileName}
                                setAttached={(val) => updateCertificate('attached', val)}
                                setFileName={(val) => updateCertificate('fileName', val)}
                                pass={storedCertificate.password}
                                setPass={(val) => updateCertificate('password', val)}
                                attachFileWeb={attachFileWeb}
                            />
                    }
                    <View style={styles.buttonContainer}>
                        <Button
                            title="orders_refund_button_next"
                            variant={disabled || sendPinLoading ? 'disabled' : 'active'}
                            loader={sendPinLoading}
                            iconColor={colors.accent}
                            style={styles.rightButton}
                            onPress={() => sendCode()}
                        />
                    </View>
                </KeyboardAvoidingView>
                <ErrorModal
                    isVisible={modal?.isVisible}
                    title={modal?.title}
                    description={modal?.desc}
                    placeHolders={modal.descPlaceholders}
                    primaryText={modal?.primaryText}
                    tertiaryText={modal?.tertiaryText}
                    primaryAction={modal?.primaryAction}
                    tertiaryAction={modal?.tertiaryAction}
                    onBackdropPress={() => closeModal()}
                    onBackButtonPress={() => closeModal()}
                    onClosePress={() => closeModal()}
                />
            </SafeAreaView>

        </>
    )
}
OnBoardingSecondStep.propTypes = {
    next: PropTypes.func,
    selectedPage: PropTypes.number,
    skip: PropTypes.func,
    logout: PropTypes.func,
}

export default OnBoardingSecondStep
