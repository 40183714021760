import React, { useContext } from 'react'
import { View, TouchableHighlight } from 'react-native'
import PropTypes from 'prop-types'

import { useTheme } from 'react-native-themed-styles'

import Text from '../Text'
import Icon from '../Icon'

import Theme from '../../Themes/Theme'

import themedStyles from './ListElement.styles'

import images from '../../Themes/Images'

const Color = require('color')

function ListElement(props) {
  const [styles] = useTheme(themedStyles)
  const { colors } = useContext(Theme)
  const {
    leftElement,
    image,
    title,
    description,
    color = colors.midGrey,
    rightLabel,
    rightComponent,
    rightOrderComponent,
    leftComponent,
    subDescWithIcon,
    subDescWithIconText,
    subDescText,
    onPress,
    style,
    listItemStyle = {},
    descriptionStyle,
    subDescriptionStyle,
    disabled,
    imageContainerStyle,
    titleStyle,
    titleContainerStyle,
    rightTextStyle,
    id,
    numOfLines,
    subtitles,
    iconSize,
  } = props


  let textBgColor = colors.white,
    imageBgColor = colors.transparent

  let textColor = color

  if (!image && !leftComponent) {
    if (!color || color === '#000000') {
      textColor = Color(colors.midGrey)
    } else {
      textColor = Color(color)
    }

    textBgColor = textColor.fade(0.65)
    imageBgColor = textBgColor
  }

  const capitalize = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1)
  }

  const initials = capitalize((title?.match(/\b(\w\w)/)?.slice(0, 3)[0] ?? '- -'))

  return (
    <TouchableHighlight
      style={[styles.container, listItemStyle.itemContainer, style]}
      onPress={() => onPress({ id: id })}
      // onLongPress={() => onLongPress()}
      // delayLongPress={100}
      underlayColor={colors.lightGrey}
      disabled={disabled}
    >
      <>
        {leftElement !== false &&
          // eslint-disable-next-line react-native/no-inline-styles
          <View style={[styles.imageContainer, { backgroundColor: imageBgColor, alignSelf: 'flex-start', minWidth: 68, height: 70 }, imageContainerStyle]}>
            {image ? (
              <Icon source={image} size={iconSize} style={listItemStyle.leftImageStyle} />
            ) : leftComponent ?
                <>
                  {leftComponent}
                </>
                : (
                  <Text numberOfLines={2} style={styles.initials} color={color} i18nKey={initials} />
                )}
            {subtitles.map((subtitle) => (
              <Text key={subtitle.Id} size="body" style={styles.subtitleText} i18nKey={subtitle.description} />
            ))}
          </View>
        }
        <View style={[styles.titleContainer, titleContainerStyle]}>
          <Text style={[styles.title, titleStyle]} i18nKey={title} numberOfLines={1} />
          {Boolean(description) && (
            <Text
              style={[styles.description, descriptionStyle]}
              weight="light"
              size={'footnote'}
              i18nKey={description}
              numberOfLines={numOfLines || 1}
            />
          )}
          {Boolean(subDescText) && (subDescWithIcon === true ? (<View style={styles.rowView}>
            <Icon
              size={15}
              source={images.sync}
              color={colors.secondaryAccent}
              style={styles.rightSpacing}
            />
            <Text
              style={styles.subdescription}
              i18nKey={subDescWithIconText}
              color={colors.secondaryAccent}
            />
          </View>) : (
              <Text
                style={[styles.subDescription, subDescriptionStyle]}
                weight="light"
                size={'footnote'}
                i18nKey={subDescText}
                numberOfLines={numOfLines || undefined} />
            ))}
        </View>
        {rightLabel ? (
          <View style={styles.rightLabelContainer}>
            <Text style={[styles.rightText, rightTextStyle]} i18nKey={rightLabel} />
          </View>
        ) : rightOrderComponent ?
            <>
              {rightOrderComponent}
            </>
            : rightComponent ? (
              <View style={styles.rightLabelContainer}>
                {rightComponent}
              </View>
            ) : null}
      </>
    </TouchableHighlight>
  )
}

ListElement.defaultProps = {
  subtitles: [],
  iconSize: 25,
}

ListElement.propTypes = {
  leftElement: PropTypes.bool,
  image: PropTypes.any,
  title: PropTypes.string,
  description: PropTypes.string,
  color: PropTypes.any,
  rightLabel: PropTypes.string,
  rightComponent: PropTypes.node,
  rightOrderComponent: PropTypes.node,
  leftComponent: PropTypes.string,
  subDescWithIcon: PropTypes.string,
  subDescWithIconText: PropTypes.string,
  subDescText: PropTypes.string,
  onPress: PropTypes.func,
  style: PropTypes.object,
  listItemStyle: PropTypes.object,
  descriptionStyle: PropTypes.object,
  subDescriptionStyle: PropTypes.object,
  disabled: PropTypes.bool,
  imageContainerStyle: PropTypes.object,
  titleStyle: PropTypes.object,
  titleContainerStyle: PropTypes.object,
  rightTextStyle: PropTypes.object,
  id: PropTypes.string,
  numOfLines: PropTypes.number,
  subtitles: PropTypes.array,
  iconSize: PropTypes.number,
}

const shouldNotRender = (prevProps, nextProps) => {
  if (nextProps.id === prevProps.id && nextProps.title === prevProps.title) {
    return true
  }

  return false
}


export default React.memo(ListElement, shouldNotRender)
// export default ListElement
