import { styleSheetFactory } from '../../Themes/Colors'

const styles = styleSheetFactory(colors => ({
  container: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  fromContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  separator: {
    width: 1,
    height: 20,
    backgroundColor: colors.separator,
    marginHorizontal: 8,
  },
  toContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  flagContainer: {
    marginRight: 5,
    borderRadius: 3,
    overflow: 'hidden',
    backgroundColor: 'pink',
  },
  flag: {
    width: 32.4,
    height: 24,
  },
}))

export default styles
