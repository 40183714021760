/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useState,
  useContext,
  useEffect,
} from 'react'
import {
  SafeAreaView,
  View,
  TouchableOpacity,
  Dimensions,
  Platform,
} from 'react-native'
import { useMutation, useQuery } from '@apollo/client'
import PropTypes from 'prop-types'
import { useTheme } from 'react-native-themed-styles'
import ContentLoader, { Rect } from 'react-content-loader/native'
import { v1 as uuidv1 } from 'uuid'
import { useSelector } from 'react-redux'

import {
  InputRow,
  SwipeList,
  Loader,
  Text,
  Button,
  Icon,
  Modal,
  Header,
  ModalContent,
} from '../../Components'

import { getProductCatalog } from '../ProductCatalog/ProductCatalog.Selectors'

import { getErrorMessage, useConfig } from '../../Utils'

import themedStyles, {
  hiddenItemStyles,
  separatorStyles,
} from './NewModifier.Styles'
import {
  CREATE_MODIFIER,
  EDIT_MODIFIER,
  GET_MODIFIER,
  DELETE_MODIFIER,
} from './NewModifier.schema'

import Theme from '../../Themes/Theme'
import images, { animations } from '../../Themes/Images'

const { width } = Dimensions.get('screen')
const multiline = Platform.OS === 'android'

function Modifier({
  name,
  label,
  value,
  onNameChange,
  onPriceChange,
  isFirst,
  isLast,
}) {
  const [styles] = useTheme(themedStyles)

  const onChangePrice = text => {
    if (text.match(/^\d*(\.\d{0,2}){0,1}$/)) {
      onPriceChange(text)
    }
  }

  return (
    <View
      style={[
        styles.inputContainer,
        styles['firstItem_' + isFirst],
        styles['lastItem_' + isLast],
      ]}>
      <InputRow
        leftInput
        leftValue={name}
        leftPlaceholder="modifier_name_placeholder"
        inputType="numeric"
        inputLabel={label}
        value={value}
        placeholder="0.00"
        onChangeText={onChangePrice}
        onChangeLeftText={onNameChange}
        style={styles.input}
        multiline={multiline}
      />
    </View>
  )
}
Modifier.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  onNameChange: PropTypes.func,
  onPriceChange: PropTypes.func,
  isFirst: PropTypes.bool,
  isLast: PropTypes.bool,
}

function ModifierPlaceholder({ isFirst, isLast }) {
  const [styles] = useTheme(themedStyles)

  return (
    <View
      style={[
        styles.inputContainer,
        styles['firstItem_' + isFirst],
        styles['lastItem_' + isLast],
      ]}>
      <ContentLoader
        speed={2}
        width={width - 30}
        height={60}
        backgroundColor="#f3f3f3"
        foregroundColor="#fff">
        <Rect
          x="15"
          y="21"
          rx="6"
          ry="6"
          width={`${(width - 30) * (2 / 5)}`}
          height="18"
        />
        <Rect
          x={`${width - 141}`}
          y="21"
          rx="6"
          ry="6"
          width="60"
          height="18"
        />
        <Rect x={`${width - 75}`} y="21" rx="3" ry="3" width="30" height="18" />
      </ContentLoader>
    </View>
  )
}
ModifierPlaceholder.propTypes = {
  isFirst: PropTypes.bool,
  isLast: PropTypes.bool,
}

function HiddenItem({
  needsConfirmation,
  onPress,
  onConfirm,
  onCancel,
  loading,
  isFirst,
  isLast,
}) {
  const [styles] = useTheme(hiddenItemStyles)
  const { colors } = useContext(Theme)

  return (
    <View
      style={[
        styles.wrapper,
        styles['firstItem_' + isFirst],
        styles['lastItem_' + isLast],
      ]}>
      {loading ? (
        <View style={styles.loader}>
          <Loader size={32} source={animations.vfLoaderWhite} />
        </View>
      ) : needsConfirmation ? (
        <TouchableOpacity style={styles.option} onPress={onPress}>
          <Icon source={images.delete} color={colors.white} size={24} />
        </TouchableOpacity>
      ) : (
        <View style={styles.optionFull}>
          <TouchableOpacity style={styles.confirm} onPress={onConfirm}>
            <Icon source={images.delete} color={colors.white} size={24} />
            <Text
              i18nKey="opened_orders_delete_confirm"
              color={colors.white}
              style={styles.confirmText}
            />
          </TouchableOpacity>
          <TouchableOpacity style={styles.cancel} onPress={onCancel}>
            <Icon source={images.closeIcon} color={colors.white} size={20} />
          </TouchableOpacity>
        </View>
      )}
    </View>
  )
}
HiddenItem.propTypes = {
  needsConfirmation: PropTypes.bool,
  onPress: PropTypes.func,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  loading: PropTypes.bool,
  isFirst: PropTypes.bool,
  isLast: PropTypes.bool,
}

function Separator() {
  const [styles] = useTheme(separatorStyles)
  return <View style={styles.container} />
}

function NewModifier({
  route: { params: { modifier: modifierObj, onSave = () => { } } = {} } = {},
  navigation: { addListener, goBack, setOptions, dispatch },
}) {

  const { switchLocation: { deviceId, locationId: locId } } = useConfig()

  const [styles] = useTheme(themedStyles)
  const { colors } = useContext(Theme)
  const [isDisabled, setDisabled] = useState(false)

  const [modifier, setModifier] = useState(modifierObj || {})
  const [modifiers, setModifiers] = useState(modifierObj?.modifiers || [])
  const [needsConfirmation, setNeedForConfirmation] = useState(true)
  const [itemWidth, setItemWidth] = useState(0)

  const { locationId, businessName, address } = useSelector(getProductCatalog)

  const [modal, setModal] = useState({
    isVisible: false,
    icon: images.delete,
    title: '',
    desc: '',
  })
  const openModal = (
    desc = '',
    title = 'modifiers_general_error',
    icon = images.delete,
  ) => {
    setModal({
      isVisible: true,
      icon: icon,
      title: title,
      desc: desc,
    })
  }
  const closeModal = () => {
    setModal({
      isVisible: false,
      icon: images.delete,
      title: 'modifiers_general_error',
      desc: '',
    })
  }

  const [addModifier, { loading: creating }] = useMutation(CREATE_MODIFIER)
  const [updateModifier, { loading: updating }] = useMutation(EDIT_MODIFIER)
  const [deleteModifier, { loading: deleting }] = useMutation(DELETE_MODIFIER)

  const { loading } = useQuery(GET_MODIFIER, {
    variables: {
      locId: locId,
      cashRegisterId: deviceId,
      id: modifier.id,
      locationId: locationId ? locationId : null,
    },
    onCompleted: (newData) => {
      const {
        modifierList = {},
        listModifiers: { modifiers: modifierItems = [] } = {},
      } = newData.getModifierList || {}
      setModifier({ ...modifierList })
      setModifiers(
        modifierItems.map((element) => {
          return { ...element }
        }),
      )
    },
  })

  const save = () => {
    if (!modifier.name) {
      // displayToast('modifiers_attention_name_required', images.attention)
      return
    }
    if (modifierObj) {
      updateModifier({
        variables: {
          locId: locId,
          cashRegisterId: deviceId,
          id: modifier.id,
          locationId: locationId ? locationId : null,
          name: modifier.name,
          modifiers: modifiers.map((element) => {
            if (element?.id?.startsWith('new')) {
              delete element.id
            }
            element.priceMoney = {
              amount: parseFloat(element.priceMoney.amount),
              currency: element.priceMoney.currency,
            }
            return element
          }),
        },
      })
        .then((data) => {
          // displayToast('modifiers_update_success')
          const { updateModifierList: updatedModifierList } = data.data
          onSave({ ...updatedModifierList })
          goBack()
        })
        .catch((e) => {
          openModal(
            getErrorMessage(e),
            'modifiers_general_error',
            images.warningIcon,
          )
        })
    } else {
      delete modifier.id
      addModifier({
        variables: {
          locId: locId,
          cashRegisterId: deviceId,
          name: modifier.name,
          locationId: locationId ? locationId : null,
          modifiers: modifiers.map((element) => {
            delete element.id
            element.priceMoney = {
              amount: parseFloat(element.priceMoney.amount),
              currency: element.priceMoney.currency,
            }
            return element
          }),
        },
      })
        .then((data) => {
          const { updateModifierList: updatedModifierList } = data.data
          onSave({ ...updatedModifierList })
          goBack()
        })
        .catch((e) => {
          openModal(
            getErrorMessage(e),
            'modifiers_general_error',
            images.warningIcon,
          )
          return
        })
    }
  }

  const changeModifier = (key, value, index) => {
    setModifiers((prev) => {
      if (key === 'price') {
        prev[index].priceMoney = {
          amount: value,
          currency: 'Lekë',
        }
      } else {
        prev[index][key] = value
      }
      return [...prev]
    })
  }

  const addNewModifier = () => {
    setModifiers((prev) =>
      [
        {
          id: 'new' + uuidv1(),
          modifierListId: modifier.id,
          name: '',
          priceMoney: {
            amount: '',
            currency: 'Lekë',
          },
        },
      ].concat(prev),
    )
  }

  const renderItem = ({ item, index }) => {
    return (
      <View
        style={[
          styles.inputContainer,
          styles['firstItem_' + (index === 0)],
          styles['lastItem_' + (index === modifiers.length - 1)],
        ]}>
        <InputRow
          leftInput
          leftValue={item.name}
          leftPlaceholder="modifier_name_placeholder"
          inputType="numeric"
          inputLabel={item.priceMoney.currency}
          value={item.priceMoney.amount + ''}
          placeholder="0.00"
          onChangeText={(value) => changeModifier('price', value, index)}
          onChangeLeftText={(value) => changeModifier('name', value, index)}
          style={styles.input}
          multiline={multiline}
        />
      </View>
    )
  }

  const openRow = (rowMap, id) => {
    setNeedForConfirmation(false)
    rowMap[id].manuallySwipeRow(-itemWidth)
  }

  const closeRow = (rowMap, id) => {
    rowMap[id].closeRow()
    setNeedForConfirmation(true)
  }

  const removeModifier = (id) => {
    setModifiers((prev) => prev?.filter((element) => element.id !== id))
  }

  const deleteRow = (rowMap, item) => {
    if (item.modifierListId) {
      deleteModifier({
        variables: {
          locId: locId,
          cashRegisterId: deviceId,
          id: item.id,
          locationId: locationId ? locationId : null,
          modifierListId: item.modifierListId,
        },
      })
        .then(() => {
          // displayToast('modifiers_delete_success')
          closeRow(rowMap, item.id)
          removeModifier(item.id)
        })
        .catch((e) => {
          openModal(getErrorMessage(e))
          closeRow(rowMap, item.id)
        })
    } else {
      closeRow(rowMap, item.id)
      removeModifier(item.id)
    }
  }

  const renderHiddenItem = ({ item, index }, rowMap) => (
    <HiddenItem
      needsConfirmation={needsConfirmation}
      onPress={() => openRow(rowMap, item.id)}
      onConfirm={() => deleteRow(rowMap, item)}
      onCancel={() => closeRow(rowMap, item.id)}
      loading={deleting}
      isFirst={index === 0}
      isLast={index === modifiers.length - 1}
    />
  )

  const checkDisabled = () => {
    if (!modifier.name) {
      // setTip('Name is required!')
      return true
    }
    if (modifiers.length === 0) {
      // setTip('You must add at least one modifier!')
      return true
    }
    for (const i in modifiers) {
      const element = modifiers[i]
      if (!element.priceMoney?.amount || !element.name) {
        // setTip('Te gjithe modifiers duhet te kene emer dhe cmim!')
        return true
      }
      if (!((element.priceMoney?.amount || '') + '')?.match(/^\d*(\.\d{0,2}){0,1}$/)) {
        // setTip('Formati i cmimit nuk eshte i sakte, shembull: 1234 ose 1234.12')
        return true
      }
    }
    if (creating || updating) {
      // setTip()
      return true
    }
    // setTip()
    return false
  }

  useEffect(() => {
    setDisabled(checkDisabled())
  }, [modifier, modifiers])

  return (
    <>
      <SafeAreaView style={styles.container}>
        <Header
          title={modifierObj ? 'header_edit_modifier' : 'header_new_modifier'}
          image={images.closeIcon}
          onPress={goBack}
          customBusinessName={businessName}
          customAddress={address}
        />
        <InputRow
          label="categories_name_label"
          placeholder="modifier_set_name_placeholder"
          defaultValue={modifier.name}
          value={modifier.name}
          onChangeText={(value) => setModifier(prev => { return { ...prev, name: value } })}
          style={styles.nameContainer}
        />

        <View style={styles.sectionTitleContainer}>
          <Text weight="light" i18nKey="item_label_modifiers" />
          <Button
            icon={images.plusIcon}
            title="modifiers_button_new"
            onPress={addNewModifier}
            variant="secondary"
            style={styles.addNew}
          />
        </View>

        <SwipeList
          data={modifiers}
          renderItem={renderItem}
          renderHiddenItem={renderHiddenItem}
          disableRightSwipe
          rightOpenValue={-100}
          onRowClose={() => setNeedForConfirmation(true)}
          setItemWidth={setItemWidth}
          ItemSeparatorComponent={Separator}
          ListHeaderComponent={
            loading && (
              <>
                <ModifierPlaceholder isFirst />
                <ModifierPlaceholder />
                <ModifierPlaceholder />
                <ModifierPlaceholder isLast />
              </>
            )
          }
          ListFooterComponent={
            <Text
              i18nKey="modifiers_warning_message"
              weight="light"
              align="center"
              style={styles.footer}
            />
          }
        />

        {/* {Boolean(tip) && <View style={styles.tip}>
          <Icon source={images.attention} size={24} />
          <Text i18nKey={tip} style={styles.tipText} />
        </View>}
        <Attention
          text={tip}
        /> */}
        <Button
          title={'discount_button_save'}
          variant={(isDisabled || updating) ? 'disabled' : 'active'}
          onPress={save}
          style={styles.save}
          loader={updating || creating}
          loaderComponent={
            <Loader size={32} source={animations.vfLoaderWhite} />
          }
        />
        <Modal
          isVisible={modal.isVisible}
          onBackButtonPress={() => closeModal()}
          onBackdropPress={() => closeModal()}
          animationIn="fadeIn"
          animationOut="fadeOut">
          <ModalContent onClose={() => closeModal()}>
            <Icon source={modal.icon} style={styles.modalIcon} size={40} />
            <Text
              i18nKey={modal.title}
              color={colors.primaryText}
              size="h5"
              align="center"
              weight="bold"
              style={styles.modalTitle}
            />
            <Text i18nKey={modal.desc} align="center" />
          </ModalContent>
        </Modal>
      </SafeAreaView>
    </>
  )
}

NewModifier.propTypes = {
  route: PropTypes.object,
  navigation: PropTypes.object,
}

export default NewModifier
