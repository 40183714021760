/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import {
  View,
  Dimensions,
  LayoutAnimation,
  Linking,
} from 'react-native'
import ContentLoader, { Rect } from 'react-content-loader/native'
import moment from 'moment'
import { useTheme } from 'react-native-themed-styles'
import PropTypes from 'prop-types'
import QRCode from 'react-native-qrcode-svg'

import {
  Text,
  SimpleList,
  Icon,
  Button,
  Section,
  ScrollWrapperWeb,
} from '../../../Components'
import { INF } from '../../../Utils/Helpers'

import themedStyles from '../RefundDetails.Styles'
import images from '../../../Themes/Images'
import { colors } from '../../../Themes'

import { layoutAnimConfig } from '../../../Themes/Theme'

const { width } = Dimensions.get('screen')

const statusIcons = {
  REGISTERED: {
    image: images.waiting,
    color: colors.secondaryAccent,
  },
}

function LoaderContent({ isFirst, isLast, style }) {
  const [styles] = useTheme(themedStyles)
  const loaderStyle = {}
  if (isFirst) {
    loaderStyle.borderTopLeftRadius = 7
    loaderStyle.borderTopRightRadius = 7
  }
  if (isLast) {
    loaderStyle.borderBottomLeftRadius = 7
    loaderStyle.borderBottomRightRadius = 7
    loaderStyle.borderBottomWidth = 0
  }
  return (
    <ContentLoader
      speed={0.7}
      height={70}
      style={Object.assign({}, styles.loaderContainer, loaderStyle, style)}
      backgroundColor="#e2e2e2"
      foregroundColor="#ecebeb">
      <Rect x="20" y="20" rx="3" ry="3" width="90" height="20" />
      <Rect x={`${width - 150}`} y="18" rx="3" ry="3" width="100" height="24" />
    </ContentLoader>
  )
}
LoaderContent.propTypes = {
  isFirst: PropTypes.bool,
  isLast: PropTypes.bool,
  style: PropTypes.object,
}

function ItemLoader() {
  return (
    <View>
      <LoaderContent isFirst />
      <LoaderContent />
      <LoaderContent />
      <LoaderContent />
      <LoaderContent />
      <LoaderContent />
      <LoaderContent />
      <LoaderContent />
      <LoaderContent isLast />
    </View>
  )
}

function BasicInfo({ eInvoiceData, loading, note, total, taxData, allowances }) {
  const [styles] = useTheme(themedStyles)
  const [items, setItems] = useState([])
  const [taxItems, setTaxItems] = useState([])
  const [allowanceItems, setAllowanceItems] = useState([])

  const renderRightComponent = (invoiceStatus) => {
    return (
      <View
        style={styles.listRightComponent}>
        <Icon
          source={statusIcons?.[invoiceStatus]?.image}
          color={statusIcons?.[invoiceStatus]?.color}
          size={24}
          style={styles.statusIcon}
        />
        <Text
          size="footnote"
          style={styles.itemRightLabel}
          i18nKey={invoiceStatus}
        />
      </View>
    )
  }

  const mapData = (unmappedData) => {
    const response = []
    response.push(
      {
        id: 1,
        leftText: 'einvoice_party_type_label',
        rightText: 'users_seller_label',
      },
      {
        id: 2,
        leftText: 'einvoice_date_label',
        rightText: moment(unmappedData?.startDate).format('DD/MM/YYYY hh:mm A'),
      },
      {
        id: 11,
        leftText: 'receipt_user',
        rightText: unmappedData?.employeeEmail,
      },
      {
        id: 10,
        leftText: 'einvoice_amount_label',
        rightText: `${INF.format(unmappedData?.amount)} ${unmappedData?.amountCurrency}`,
      },
      {
        id: 3,
        leftText: 'einvoice_receipt_label',
        rightText: unmappedData?.id,
      },
      {
        id: 4,
        leftText: 'einvoice_deadline_label',
        rightText: moment(unmappedData?.deadLine).format('DD/MM/YYYY'),
      },
      {
        id: 5,
        leftText: 'einvoice_status_label',
        rightComponent: renderRightComponent(unmappedData?.status),
      },
      {
        id: 6,
        leftText: 'einvoice_buyertin_label',
        rightText: unmappedData?.buyerNUIS,
      },
      {
        id: 7,
        leftText: 'einvoice_sellertin_label',
        rightText: unmappedData?.sellerNUIS,
      },
      {
        id: 8,
        leftText: 'einvoice_EIC_label',
        rightText: unmappedData?.eic,
      },
    )
    return response
  }

  const mapTaxes = (unmappedData) => {
    const mappedData = []
    unmappedData?.taxSubtotal.map((item) => {
      const taxCategory = item?.taxCategory
      const taxableAmount = item?.taxableAmount
      const taxAmount = item?.taxAmount
      mappedData.push({
        title: `${taxCategory?.id}-${taxCategory?.taxSchema} ${taxCategory?.percent}%`,
      }, {
        label: 'taxable_amount_label',
        value: `${INF.format(taxableAmount?.amount)} ${taxableAmount?.currency}`,
      }, {
        label: 'tax_sum_label',
        value: `${INF.format(taxAmount?.amount)} ${taxAmount?.currency}`,
      })
    })
    return mappedData
  }
  const mapAllowances = (unmappedData) => {
    const mappedData = []
    unmappedData?.allowances.map((item) => {
      const itemAmount = item?.amount
      const taxCategory = item?.taxCategory
      mappedData.push({
        title: item?.chargeReason,
      }, {
        label: `${taxCategory?.id}-${taxCategory?.taxSchema}`,
        value: `${taxCategory?.percent} %`,
      }, {
        label: 'einvoice_discount_amount_receipt_label',
        value: `${INF.format(itemAmount?.amount)} ${itemAmount?.currency}`,
      }, {
        label: 'einvoice_discount_base_receipt_label',
        value: item?.baseAmount,
      }, {
        label: 'einvoice_discount_percentage_receipt_label',
        value: item?.percent ? `${item?.percent} %` : '',
      })
    })
    return mappedData
  }
  useEffect(() => {
    if (taxData) {
      setTaxItems(mapTaxes(taxData))
    }
  }, [taxData])

  useEffect(() => {
    if (allowances) {
      setAllowanceItems(mapAllowances(allowances))
    }
  }, [allowances])

  useEffect(() => {
    if (eInvoiceData) {
      LayoutAnimation.configureNext(layoutAnimConfig)
      setItems(mapData(eInvoiceData))
    }
  }, [eInvoiceData])

  const sectionData = [
    {
      label: 'receipt_iic_label',
      value: note?.IIC,
    },
    {
      label: 'receipt_fic_label',
      value: note?.FIC,
    },
    {
      label: 'cash_register_business_code',
      value: note?.BusinessUnitCode,
    },
    {
      label: 'receipt_software_code_label',
      value: note?.SoftwareCode,
    },
    {
      label: 'receipt_operator_code_label',
      value: note?.OperatorCode,
    },
  ]
  const totalsectionData = [
    {
      title: 'receipt_total',
    },
    {
      label: 'einvoice_preview_total_amount',
      value: `${INF.format(total?.lineExtension)} ${total?.lineExtensionCurrency}`,
    },
    {
      label: 'receipt_total_discount',
      value: `${INF.format(total?.allowance)} ${total?.allowanceCurrency}`,
    },
    {
      label: 'einvoice_preview_total_withoutvat',
      value: `${INF.format(total?.taxExclusive)} ${total?.taxExclusiveCurrency}`,
    },
    {
      label: 'einvoice_preview_total_vat',
      value: `${INF.format(total?.taxAmount)} ${total?.taxAmountCurrency}`,
    },
    {
      label: 'einvoice_preview_total_withvat',
      value: `${INF.format(total?.taxInclusive)} ${total?.taxInclusiveCurrency}`,
    },
    {
      label: 'einvoice_preview_total_paid',
      value: `${INF.format(total?.payable)} ${total?.payableCurrency}`,
    },
  ]

  return (
    <ScrollWrapperWeb>
      <SimpleList
        DATA={items}
        containerStyle={styles.list}
        separatorStyle={styles.separatorStyle}
        initialNumToRender={20}
        refreshing={false}
        listFooterComponent={
          loading ? <ItemLoader /> :
            <>
              {allowanceItems.length !== 0 ? <Text i18nKey="einvoice_discount_receipt_header" weight="bold" style={styles.sectionStyle} /> : null}
              <Section data={allowanceItems} />
              <Text i18nKey="einvoice_addition_receipt_header" weight="bold" style={styles.sectionStyle} />
              <Section data={taxItems} />
              <Section data={totalsectionData} style={styles.sectionStyle} />
              <View style={styles.invValContainer}>
                <Text
                  size="footnote"
                  i18nKey="receipt_section_invoice_validation"
                />

                <View style={styles.qrCode}>
                  {note?.QrCode && <QRCode
                    value={note?.QrCode}
                    size={90}
                    backgroundColor={colors.background}
                    color={colors.secondaryIcon}
                  />}
                </View>
                <Button
                  icon={images.link}
                  iconStyle={styles.invoiceButton}
                  iconColor={colors.quartenaryIcon}
                  titleStyle={{ color: colors.primaryText }}
                  title="receipt_button_invoice_validation"
                  backgroundColor={colors.tertiaryBackground}
                  style={styles.fullButton}
                  borderColor={colors.transparent}
                  onPress={() => Linking.openURL(note?.QrCode)}
                />
                <View>
                  <Section data={sectionData} />
                </View>
              </View>
            </>
        }
      />
    </ScrollWrapperWeb>
  )
}
BasicInfo.propTypes = {
  eInvoiceData: PropTypes.object,
  loading: PropTypes.bool,
  note: PropTypes.object,
  total: PropTypes.object,
  taxData: PropTypes.object,
  allowances: PropTypes.object,
}

export default BasicInfo
