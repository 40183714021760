/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback, useRef, memo, useContext } from 'react'
import {
    SafeAreaView,
    TouchableOpacity,
    View,
    useWindowDimensions,
    Platform,
} from 'react-native'
import { useTheme } from 'react-native-themed-styles'
import PropTypes from 'prop-types'
import { useFocusEffect } from '@react-navigation/native'
import { SwipeRow } from 'react-native-swipe-list-view'
import { useLazyQuery, useApolloClient } from '@apollo/client'
import moment from 'moment'
import RNHTMLtoPDF from 'react-native-html-to-pdf'
import Share from 'react-native-share'
import LottieView from 'lottie-react-native'

import {
    Header,
    PeriodCalendar,
    SwipeList,
    ModalContent,
    Modal,
    Icon,
    Text,
    ListLoader,
    Loader,
    ErrorModal,
    Button,
} from '../../Components'
import { getErrorMessage, INF, convertSupportingInvoice, printWtnReceipt, useTemplateLanguage, useConfig } from '../../Utils'
import { useLanguage } from '../../Utils/Language'
import Merchant from '../../Utils/Merchant'

import { GET_QR } from '../../Utils/Utils.Schema'

import { GET_SUPPORTIVE_INVOICE, LIST_SUPPORTIVE_INVOICES } from './SupportingInvoice.Schema'

import themedStyles, { itemStyles, myHiddenItemStyles } from './SupportingInvoice.Styles'
import { images, colors, animations } from '../../Themes'

const statusIcons = {
    IN_PROGRESS: {
        image: images.inProgressInvoice,
    },
    REGISTERED: {
        image: images.regSupportive,
    },
}

const LANGUAGES = {
    al: 'sq',
    en: 'en-gb',
}

const PRINTING_STEPS = {
    data: 'data',
    printing: 'printing',
    success: 'success',
}

function Data() {
    const [styles] = useTheme(themedStyles)
    const anim = useRef()

    return (
        <View style={styles.retrievingScreen}>
            <LottieView
                ref={(ref) => {
                    anim.current = ref
                }}
                source={animations.print}
                autoPlay
                loop={false}
                resizeMode="cover"
                style={styles.lottieView}
            />
            <Text i18nKey="orders_getting_receipt_label" size="h3" />
        </View>
    )
}

function Printing({ anim }) {
    const [styles] = useTheme(themedStyles)

    return (
        <>
            <View style={styles.retrievingScreen}>
                <LottieView
                    ref={(ref) => {
                        anim.current = ref
                    }}
                    source={animations.print}
                    autoPlay
                    loop={false}
                    resizeMode="cover"
                    style={styles.lottieView}
                />
                <Text i18nKey="orders_printing_label" size="h3" />
            </View>
        </>
    )
}
Printing.propTypes = {
    anim: PropTypes.string,
}

function Success({ setModalVisible, setPrintingStatus }) {
    const [styles] = useTheme(themedStyles)
    return (
        <View style={styles.successScreen}>
            <View
                style={styles.retrievingScreen}>
                <LottieView
                    source={animations.success}
                    autoPlay
                    loop={false}
                    resizeMode="cover"
                    style={styles.lottieSuccess}
                />
                <Text i18nKey="orders_success_label" size="h3" align="center" />
            </View>
            <Button
                title="orders_button_go_back"
                backgroundColor={colors.accent}
                borderColor={colors.transparent}
                style={styles.goBackButton}
                onPress={() => {
                    setModalVisible(false)
                    setPrintingStatus(PRINTING_STEPS.data)
                }}
            />
        </View>
    )
}
Success.propTypes = {
    setModalVisible: PropTypes.func,
    setPrintingStatus: PropTypes.func,
}

const PrintingSteps = {
    data: Data,
    printing: Printing,
    success: Success,
}

function FullModal({
    setModalVisible,
    navigate,
    opened,
    setOpened,
    printingStatus,
    setPrintingStatus,
    anim,
}) {
    const PrintingStep = PrintingSteps[printingStatus]

    return (
        <><PrintingStep
            setModalVisible={setModalVisible}
            setPrintingStatus={setPrintingStatus}
            anim={anim}
        />
            <ErrorModal
                isVisible={opened}
                icon={images.error}
                title="orders_error_label"
                description="orders_printing_interrupted_label"
                primaryText="receipt_open_printers_button"
                primaryAction={() => {
                    setOpened(false)
                    setModalVisible(false)
                    navigate('PrinterSetUp')
                }}
                secondaryText="checkout_button_skip"
                secondaryAction={() => {
                    setOpened(false)
                    setModalVisible(false)
                    setPrintingStatus(PRINTING_STEPS.data)
                }}
                onBackdropPress={() => {
                    setOpened(false)
                    setModalVisible(false)
                    setPrintingStatus(PRINTING_STEPS.data)
                }}
                onClosePress={() => {
                    setOpened(false)
                    setModalVisible(false)
                    setPrintingStatus(PRINTING_STEPS.data)
                }}
            />
        </>
    )
}
FullModal.propTypes = {
    setModalVisible: PropTypes.func,
    navigate: PropTypes.func,
    opened: PropTypes.bool,
    setOpened: PropTypes.func,
    printingStatus: PropTypes.string,
    setPrintingStatus: PropTypes.func,
    anim: PropTypes.string,
}

function SectionItem({ sectionTitle }) {
    const [styles] = useTheme(themedStyles)

    return (
        <Text
            i18nKey={sectionTitle}
            size="footnote"
            color={colors.secondaryText}
            style={styles.sectionTitle}
        />
    )
}
SectionItem.propTypes = {
    sectionTitle: PropTypes.string,
}

function Invoice({
    type,
    createdAt,
    title,
    amount,
    employeeEmail,
    onPress,
    isFirst,
    isLast,
}) {
    const [styles] = useTheme(itemStyles)

    return <View style={[
        styles.container,
        styles['firstItem_' + isFirst],
        styles['lastItem_' + isLast],
    ]}>
        <TouchableOpacity
            onPress={onPress}
            style={styles.touchable}
        >
            <View style={styles.textContainer}>
                <Icon source={statusIcons?.[type]?.image} color={statusIcons?.[type]?.color} />
                <View style={styles.leftData}>
                    <Text i18nKey={title} numberOfLines={1} />
                    {createdAt ? <Text i18nKey={createdAt} weight="light" size="footnote" numberOfLines={1} /> : null}
                    <Text i18nKey={employeeEmail} weight="light" size="footnote" numberOfLines={1} />
                </View>
            </View>
            <View style={styles.rightComponent}>
                <View style={styles.rightData}>
                    <Text i18nKey="einvoice_currency_all" placeHolders={[amount]} />
                </View>
                <Icon
                    source={images.chevronRight}
                    size={26}
                    color={colors.accent}
                    style={styles.rightIcon}
                />
            </View>

        </TouchableOpacity>
    </View>
}
Invoice.propTypes = {
    type: PropTypes.string,
    partyType: PropTypes.string,
    createdAt: PropTypes.string,
    title: PropTypes.string,
    amount: PropTypes.string,
    employeeEmail: PropTypes.string,
    onPress: PropTypes.func,
    isFirst: PropTypes.bool,
    isLast: PropTypes.bool,
}

// function HiddenItem({
//     needsConfirmation,
//     onPress, onConfirm,
//     onCancel, loading,
//     isFirst,
//     isLast,
// }) {
//     const [styles] = useTheme(myHiddenItemStyles)
//     return (
//         <View style={[styles.wrapper, styles['firstItem_' + isFirst], styles['lastItem_' + isLast]]}>
//             {loading
//                 ? <View style={styles.loader}>
//                     <Loader size={32} color={colors.white} source={animations.vfLoaderWhite} />
//                 </View>
//                 : needsConfirmation
//                     ? <TouchableOpacity
//                         style={styles.option}
//                         onPress={onPress}
//                     >
//                         <Icon source={images.delete} color={colors.white} size={24} />
//                     </TouchableOpacity>
//                     : <View style={styles.optionFull}>
//                         <TouchableOpacity
//                             style={styles.confirm}
//                             onPress={onConfirm}
//                         >
//                             <Icon source={images.delete} color={colors.white} size={24} />
//                             <Text i18nKey="opened_orders_delete_confirm" color={colors.white} style={styles.confirmText} />
//                         </TouchableOpacity>
//                         <TouchableOpacity
//                             style={styles.cancel}
//                             onPress={onCancel}
//                         >
//                             <Icon source={images.closeIcon} color={colors.white} size={20} />
//                         </TouchableOpacity>
//                     </View>
//             }
//         </View>
//     )
// }
// HiddenItem.propTypes = {
//     needsConfirmation: PropTypes.bool,
//     onPress: PropTypes.func,
//     onConfirm: PropTypes.func,
//     onCancel: PropTypes.func,
//     loading: PropTypes.bool,
//     isFirst: PropTypes.bool,
//     isLast: PropTypes.bool,
// }

const HiddenItem = memo(({ isFirst, isLast, onPrintPress, onSharePress, shareLoading }) => {
    const [styles] = useTheme(myHiddenItemStyles)

    return (
        <View
            style={[
                styles.hiddenItemStyle,
                styles[`firstHiddenItem_${isFirst}`],
                styles[`lastHiddenItem_${isLast}`],
            ]}>
            <TouchableOpacity
                style={styles.hiddenSubcontainer}
                onPress={onPrintPress}>
                <Icon source={images.printerActive} color={colors.white} size={24} />
            </TouchableOpacity>
            <View style={styles.divider} />
            <TouchableOpacity
                style={styles.hiddenSubcontainer}
                onPress={onSharePress}
                disabled={shareLoading}
            >
                {shareLoading ? <Loader source={animations.vfLoaderWhite} size={24} /> : <Icon source={images.share} color={colors.white} size={24} />}
            </TouchableOpacity>
        </View>
    )
})
HiddenItem.propTypes = {
    isFirst: PropTypes.bool,
    isLast: PropTypes.bool,
    onPrintPress: PropTypes.func,
    onSharePress: PropTypes.func,
    shareLoading: PropTypes.bool,
}

function EmptyItem() {
    return null
}

const listItems = {
    section: SectionItem,
    IN_PROGRESS: Invoice,
    REGISTERED: Invoice,
}

const hiddenItems = {
    section: EmptyItem,
    IN_PROGRESS: HiddenItem,
    REGISTERED: HiddenItem,
}

function SupportingInvoice({ navigation: { navigate, goBack } }) {

    const { switchLocation: { deviceId, locationId } } = useConfig()

    const [styles] = useTheme(themedStyles)
    const lang = useLanguage()
    const context = useContext(Merchant)
    const { openConfigurationSelector, translate } = useTemplateLanguage()

    const [printingStatus, setPrintingStatus] = useState(PRINTING_STEPS.data)
    const { width, height } = useWindowDimensions()
    const [selectedDates, setSelectedDates] = useState({})
    const [allInvoicesDate, setAllInvoicesDates] = useState({})
    const [shareLoading, setShareLoading] = useState(false)
    const [action, setAction] = useState()
    const [isModalVisible, setModalVisible] = useState(false)
    const [opened, setOpened] = useState(false)
    const client = useApolloClient()

    const anim = useRef()
    const sectionDate = useRef('-')

    const onSearchPress = () => {
        setAllInvoicesDates(selectedDates)
    }

    const [needsConfirmation, setNeedForConfirmation] = useState(true)
    const [eInvoices, setEinvoices] = useState({
        invoices: [],
        loading: true,
    })
    // eslint-disable-next-line no-unused-vars
    const [refetching, setRefetching] = useState(false)

    const [selected, setSelected] = useState()

    const types = [
        {
            title: 'einvoice_registered_filter',
            type: 'REGISTERED',
            name: 'status',
            list: () => filteredList('', ''),
        },
        {
            title: 'einvoice_refunds_filter',
            type: 'UblRefund',
            name: 'type',
            list: () => filteredList('', ''),
        },
    ]

    const [listSupportingInvoices, { data, refetch, fetchMore }] = useLazyQuery(LIST_SUPPORTIVE_INVOICES, { fetchPolicy: 'network-only' })
    const [getInvoice, { data: invoiceDetails }] = useLazyQuery(GET_SUPPORTIVE_INVOICE, { fetchPolicy: 'network-only' })

    const list = () => {
        listSupportingInvoices({
            fetchPolicy: 'network-only',
            variables: {
                locId: locationId,
                cashRegisterId: deviceId,
                limit: 30,
                filter: {
                    name: 'status',
                    value: 'REGISTERED',
                },
            },
        })
    }

    const filteredList = (key, val) => {
        setSelected(val)
        listSupportingInvoices({
            variables: {
                locId: locationId,
                cashRegisterId: deviceId,
                limit: 20,
                filter: {
                    name: 'status',
                    value: 'REGISTERED',
                },
            },
        })
    }

    const [isErrorActive, setErrorActive] = useState({
        isVisible: false,
        message: '',
    })

    useEffect(() => {
        setSelected('REGISTERED')
    }, [])

    useEffect(() => {
        const invoices = data?.listWarehouseNotes?.warehouseNotes
        if (Array.isArray(invoices)) {
            const newArray = mapData(invoices)
            setEinvoices({
                invoices: newArray,
                loading: false,
            })
        }
    }, [data])

    const refetchData = async () => {
        if (refetch) {
            setRefetching(true)
            await refetch()
        }
    }

    useFocusEffect(
        useCallback(() => {
            refetchData()
        }, [])
    )

    useEffect(() => {
        if (selected) {
            const index = types.findIndex((el) => el.type === selected)
            setRefetching(false)
            setEinvoices({
                invoices: [],
                loading: true,
            })
            types[index].list()
        }
    }, [selected])

    useEffect(() => {
        setEinvoices({
            invoices: [],
            loading: true,
        })
    }, [width, height])

    const mapData = (unmappedData) => {
        const response = []
        let itemTime
        let isFirst = false,
            isLast = false
        sectionDate.current = null
        unmappedData?.map((item, index) => {
            isFirst = false
            isLast = false
            itemTime = item?.issueDateTime
            const date = moment(itemTime).locale(LANGUAGES[lang]).format('MMM DD, YYYY')
            if (date !== sectionDate.current) {
                if (response?.[response.length - 1]) {
                    response[response.length - 1].isLast = true
                }
                sectionDate.current = date
                response.push({
                    id: date,
                    sectionTitle: date,
                    type: 'section',
                })
            }

            if (response?.[response.length - 1]?.type === 'section') {
                isFirst = true
                isLast = false
            }

            response.push({
                id: item?.id,
                type: item?.status,
                createdAt: moment(item?.issueDateTime).locale(LANGUAGES[lang]).format('hh:mm A'),
                title: item?.wtnNum ? item?.wtnNum : item?.id,
                amount: INF.format(item?.valueOfGoods),
                employeeEmail: item?.employeeEmail,
                isFirst: isFirst,
                isLast: isLast,
            })
        })
        if (response?.[response.length - 1]) {
            response[response.length - 1].isLast = true
        }
        return response
    }

    const onSharePress = (id) => {
        openConfigurationSelector(true, () => {
            setAction('sharing')
            setShareLoading(true)
            getInvoice({
                variables: {
                    id,
                    locId: locationId,
                    cashRegisterId: deviceId,
                },
            })
        })
    }

    const onPrintPress = (id) => {
        openConfigurationSelector(false, () => {
            setAction('printing')
            getInvoice({
                variables: {
                    id,
                    locId: locationId,
                    cashRegisterId: deviceId,
                },
            })
            setModalVisible(true)
        })
    }
    const createPdf = async (myInvoiceMappedData, note, supportingInvoiceData) => {
        setShareLoading(true)
        const { data: qr } = await client.query({
            query: GET_QR,
            variables: {
                text: myInvoiceMappedData?.basicInfo?.qrCode,
            },
        })
        RNHTMLtoPDF.convert({
            html: convertSupportingInvoice(
                { ...myInvoiceMappedData, note },
                context?.nipt,
                context?.company,
                context?.location,
                translate,
                qr
            ),
            fileName: 'fature',
        })
            .then((res) => {
                Share.open({
                    url: `file://${res.filePath}`,
                    subject: 'Fatura ' + supportingInvoiceData?.wtnNum,
                    title: 'Fatura',
                })
                    .catch(() => { })
                setShareLoading(false)
            })
            .catch(() => {
                setShareLoading(false)
            })

    }

    useEffect(() => {
        if (invoiceDetails) {
            const supportingInvoiceData = invoiceDetails?.getWarehouseNote
            let note = {}
            supportingInvoiceData?.note?.map((item) => {
                const itemValue = item.substring(item.indexOf('=') + 1)
                const itemKey = item.substring(0, item.indexOf('='))
                const noteitem = { [itemKey]: itemValue }
                note = { ...note, ...noteitem }
            })
            const myInvoiceMappedData = {
                basicInfo: {
                    date: moment(supportingInvoiceData?.issueDateTime).format('DD/MM/YYYY hh:mm A'),
                    receiptNr: supportingInvoiceData?.wtnOrdNum,
                    receipt: supportingInvoiceData?.wtnNum,
                    issuerNIPT: supportingInvoiceData?.issuer?.nuis,
                    invoiceType: supportingInvoiceData?.type,
                    transactionType: supportingInvoiceData?.transaction,
                    startDate: moment(supportingInvoiceData?.startDateTime).format('DD/MM/YYYY hh:mm A'),
                    amount: INF.format(supportingInvoiceData?.valueOfGoods),
                    qrCode: supportingInvoiceData?.qrCode,
                    operatorCode: supportingInvoiceData?.operatorCode,
                    NIVF: supportingInvoiceData?.fwtnic,
                    NSLF: supportingInvoiceData?.wtnic,
                    fwtnic: supportingInvoiceData?.fwtnic,
                    softCode: supportingInvoiceData?.softCode,
                    businUnitCode: supportingInvoiceData?.businUnitCode,
                    employeeEmail: supportingInvoiceData?.employeeEmail,
                },
                transportInfo: {
                    transportOwnership: supportingInvoiceData?.vehOwnership,
                    plates: supportingInvoiceData?.vehPlates,
                    startingAddress: supportingInvoiceData?.startAddr,
                    startingCity: supportingInvoiceData?.startCity,
                    startingPoint: supportingInvoiceData?.startPoint,
                    startingDate: moment(supportingInvoiceData?.startDateTime).format('DD/MM/YYYY hh:mm A'),
                    destinationAddress: supportingInvoiceData?.destinAddr,
                    destinationCity: supportingInvoiceData?.destinCity,
                    destinationPoint: supportingInvoiceData?.destinPoint,
                    destinationDate: moment(supportingInvoiceData?.destinDateTime).format('DD/MM/YYYY hh:mm A'),
                    isItemDangerous: supportingInvoiceData?.isGoodsFlammable,
                    isEscort: supportingInvoiceData?.isEscortRequired,
                },
                issuer: {
                    nuis: supportingInvoiceData?.issuer?.nuis,
                    name: supportingInvoiceData?.issuer?.name,
                    address: supportingInvoiceData?.issuer?.address,
                    city: supportingInvoiceData?.issuer?.town,
                },
                carrier: {
                    idType: supportingInvoiceData?.carrier?.idType,
                    nuis: supportingInvoiceData?.carrier?.idNum,
                    name: supportingInvoiceData?.carrier?.name,
                    address: supportingInvoiceData?.carrier?.address,
                    city: supportingInvoiceData?.carrier?.town,
                },
                items: supportingInvoiceData?.items,
            }
            if (action === 'sharing') {
                createPdf(myInvoiceMappedData, note, supportingInvoiceData).catch(() => { })
            } else if (action === 'printing') {
                setPrintingStatus(PRINTING_STEPS.printing)
                if (Platform.OS === 'web') {
                } else {
                    printWtnReceipt({
                        ...supportingInvoiceData,
                        NIPT: context?.nipt,
                        name: context?.company,
                        address: context?.location,
                        translate,
                    })
                        .then(() => {
                            setPrintingStatus(PRINTING_STEPS.success)
                        })
                        .catch(() => {
                            setOpened(true)
                        })
                }
            }
        }
    }, [invoiceDetails])

    const renderItem = (rowData, rowMap) => {
        const { item, index } = rowData
        const ListItem = listItems[item?.type]
        const ListHiddenItem = hiddenItems[item?.type]
        return (
            <SwipeRow
                key={'row-' + index}
                disableRightSwipe
                disableLeftSwipe={item?.type === 'section' || Platform.OS === 'web'}
                rightOpenValue={-161}
                closeOnRowPress
                recalculateHiddenLayout={false}
                useNativeDriver
            >
                {Platform.OS === 'web' ? <></>
                    : <ListHiddenItem
                        key={'row-' + index}
                        onPrintPress={() => onPrintPress(item.id)}
                        onSharePress={() => onSharePress(item.id)}
                        shareLoading={shareLoading}
                        needsConfirmation={needsConfirmation}
                        isFirst={item.isFirst}
                        isLast={item.isLast}
                    />}
                <ListItem
                    {...item}
                    key={'row-' + index}
                    onPress={() => navigate('SupportingInvoiceDetails', {
                        id: item?.id,
                        title: item?.title,
                    })}
                    sectionTitle={item.sectionTitle}
                />
            </SwipeRow>
        )
    }

    const onEndReached = () => {
        if (!data?.listWarehouseNotes?.nextToken) {
            return
        }
        setRefetching(false)
        setEinvoices((prev) => ({
            ...prev,
            loading: true,
        }))
        fetchMore({
            variables: {
                locId: locationId,
                cashRegisterId: deviceId,
                limit: 30,
                filter: {
                    name: 'status',
                    value: 'REGISTERED',
                },
                nextToken: data.listWarehouseNotes.nextToken,
            },
        })
    }

    const onRefresh = () => {
        setRefetching(true)
        refetch()
    }

    useEffect(() => {
        setEinvoices((prev) => ({
            ...prev,
            loading: true,
        }))
        if (allInvoicesDate) {
            if (Object.keys(allInvoicesDate).length > 0) {
                listSupportingInvoices({
                    variables: {
                        locId: locationId,
                        cashRegisterId: deviceId,
                        filter: {
                            name: 'status',
                            value: 'REGISTERED',
                        },
                        endTime: allInvoicesDate?.endingDay,
                        beginTime: allInvoicesDate?.startingDay,
                    },
                })
            } else {
                list()
            }
        }
    }, [allInvoicesDate])

    return (
        <>
            <SafeAreaView style={styles.container}>
                <Header title="header_supporting_invoice" image={images.back} onPress={() => goBack()} />
                <SwipeList
                    style={Platform.OS === 'web' ? styles.eInvoiceListWeb : styles.eInvoiceList}
                    data={eInvoices.invoices}
                    renderItem={renderItem}
                    disableRightSwipe
                    onRowClose={() => setNeedForConfirmation(true)}
                    onEndReached={onEndReached}
                    // ListHeaderComponent={<TagList
                    //   selected={selected}
                    //   onPress={setSelected}
                    //   options={types}
                    //   style={styles.headerStyles}
                    //   contentContainerStyle={styles.tagListContainer}
                    // />}
                    onEndReachedThreshold={0.2}
                    ListFooterComponent={eInvoices.loading && <ListLoader loading={eInvoices.loading} />}
                    ListEmptyComponent={eInvoices.loading ? null : <Text weight="light" i18nKey="supporting_invoice_list_empty_message" style={styles.emptyComponent} />}
                    refreshing={false}
                    onRefresh={onRefresh}
                />
                <View style={styles.searchContainer}>
                    <PeriodCalendar
                        placeholder="supporting_invoice_search_placeholder"
                        onSearch={onSearchPress}
                        onIntervalChange={setSelectedDates}
                        onSearchClean={() => setAllInvoicesDates({})}
                    />
                </View>
            </SafeAreaView>
            <Modal
                isVisible={isModalVisible}
                onBackButtonPress={() => setModalVisible(false)}
                animationIn="fadeIn"
                animationOut="fadeOut"
                useNativeDriver
                style={styles.modalStyle}>
                <View style={styles.modalContent}>
                    <FullModal
                        setModalVisible={setModalVisible}
                        opened={opened}
                        setOpened={setOpened}
                        navigate={navigate}
                        printingStatus={printingStatus}
                        setPrintingStatus={setPrintingStatus}
                        anim={anim}
                    />
                </View>
            </Modal>
            <Modal
                isVisible={isErrorActive.isVisible}
                onBackButtonPress={() => setErrorActive({
                    isVisible: false,
                    message: '',
                })}
                onBackdropPress={() => setErrorActive({
                    isVisible: false,
                    message: '',
                })}
                animationIn="fadeIn"
                animationOut="fadeOut"
            >
                <ModalContent onClose={() => setErrorActive({
                    isVisible: false,
                    message: '',
                })}>
                    <Icon source={images.delete} style={styles.errorIcon} />
                    <Text i18nKey="orders_error_label" color={colors.accent} size="h5" align="center" weight="bold" style={styles.errorTitle} />
                    <Text i18nKey={getErrorMessage(isErrorActive.message)} align="center" />
                </ModalContent>
            </Modal>
        </>
    )
}
SupportingInvoice.propTypes = {
    navigation: PropTypes.object,
    selectedDates: PropTypes.object,
}

export default SupportingInvoice

