import { styleSheetFactory } from '../../../../Themes/Colors'

const styles = styleSheetFactory(colors => ({
  container: {
    flex: 1,
    backgroundColor: colors.background,
    position: 'relative',
  },
  listStyle: {
    flexShrink: 1,
    paddingHorizontal: 15,
  },
  rightComponentStyle: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  itemRightLabel: {
    marginRight: 5,
  },
  searchContainer: {
    flexDirection: 'row',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    margin: 15,
    marginBottom: 0,
  },
  inputContainer: {
    flexDirection: 'row',
    flexGrow: 1,
    backgroundColor: colors.white,
    borderRadius: 7,
  },
  searchIconContainer: {
    padding: 10,
    justifyContent: 'center',
    alignItems: 'center',
    width: 50,
    height: 50,
  },
  searchInput: {
    flexGrow: 1,
    fontSize: 16,
    fontFamily: 'Vodafone Lt',
    color: colors.primaryText,
  },
  clearContainer: {
    margin: 6.5,
    justifyContent: 'center',
    alignItems: 'center',
    width: 35,
    height: 35,
    borderRadius: 20,
    backgroundColor: colors.background,
  },
  listContainerStyle: {
    paddingBottom: 30,
  },
  fab: {
    position: 'absolute',
    bottom: 30,
    right: 15,
    height: 50,
    width: 50,
    borderRadius: 25,
    backgroundColor: colors.accent,
    justifyContent: 'center',
    alignItems: 'center',
    shadowColor: colors.black,
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.22,
    shadowRadius: 2.22,
    elevation: 3,
  },
  fabIcon: {
    transform: [{ rotate: '-90deg' }],
  },
  emptyComponent: {
    marginTop: 10,
  },
}))

const itemStyles = styleSheetFactory(colors => ({
  itemStyle: {
    backgroundColor: colors.tertiaryBackground,
    minHeight: 60,
    justifyContent: 'center',
    paddingVertical: 5,
    borderTopWidth: 1,
    borderTopColor: colors.separator,
  },
  listDescContainer: {
    flexGrow: 1,
    flexShrink: 1,
    marginLeft: 10,
    paddingRight: 15,
    justifyContent: 'center',
    marginRight: 15,
  },
  firstItem_true: {
    borderTopLeftRadius: 7,
    borderTopRightRadius: 7,
    borderTopWidth: 0,
  },
  lastItem_true: {
    borderBottomLeftRadius: 7,
    borderBottomRightRadius: 7,
  },
  itemSubContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    minHeight: 60,
    paddingRight: 10,
  },
  rightContainer: {
    marginRight: 15,
    alignItems: 'flex-end',
    flexShrink: 0,
  },
  sectionTitle: {
    marginBottom: 10,
    marginTop: 15,
  },
}))

export { itemStyles }

export default styles
