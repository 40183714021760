import React from 'react'
import { SafeAreaView, ScrollView, View, Text as RNText } from 'react-native'
import { useTheme } from 'react-native-themed-styles'
import PropTypes from 'prop-types'

import { Button, InputWithTags, Text } from '../../Components'

import ContentManager from '../../Utils/ContentManager'


import themedStyles from './Location.Styles'
import { colors } from '../../Themes'


function BusinessUnitInfo({ location, goBack, navigate, loading }) {
    const { translate } = ContentManager

    const [styles] = useTheme(themedStyles)
    return (
        <SafeAreaView style={styles.businInfoContainer}>
            <ScrollView showsVerticalScrollIndicator={false} >
                <Text i18nKey={'location_business_about_location_header'} style={styles.busInfoHeader} />
                <View style={styles.deviceInfoContainer}>
                    <View style={styles.deviceInfoRow}>
                        <Text style={styles.textColumn()} i18nKey={'location_label_business_name'} color={colors.midGrey} />
                        <Text style={styles.textColumn(true)} i18nKey={location?.businessName} />
                    </View>
                    <View style={styles.deviceInfoRow}>
                        <Text style={styles.textColumn()} i18nKey={'location_label_vat_number'} color={colors.midGrey} />
                        <Text style={styles.textColumn(true)} i18nKey={location?.NIPT} />
                    </View>
                    <View style={styles.deviceInfoRow}>
                        <Text style={styles.textColumn()} i18nKey={'location_label_location'} color={colors.midGrey} />
                        <Text style={styles.textColumn(true)} i18nKey={location?.address?.addressLine1} />
                    </View>
                </View>
                <View style={styles.invoiceInfoContainer}>

                    <View style={styles.deviceInfoRow}>
                        <Text style={styles.textColumn()} i18nKey={'location_business_noncash_invoices'} color={colors.midGrey} />
                        <Text style={styles.textColumn(true)} i18nKey={location?.totalNoncashInvoices || location?.totalNoncashInvoices === 0 ? location?.totalNoncashInvoices + '' : ''} />
                    </View>
                    <View style={styles.deviceInfoRow}>
                        <Text style={styles.textColumn()} i18nKey={'location_business_e_invoices'} color={colors.midGrey} />
                        <Text style={styles.textColumn(true)} i18nKey={location?.totalEInvoices || location?.totalEInvoices === 0 ? location?.totalEInvoices + '' : 0} />
                    </View>
                </View>
                <Text i18nKey="settings_business_code_label" weight="bold" size="h5" />
                <InputWithTags
                    label="settings_business_code_label"
                    value={location.businUnitCode}
                    inputContainerStyle={styles.inputContainer}
                    maxLength={50}
                    editable={false}
                    inputWrapperStyle={styles.inputWrapper}
                />
                <RNText style={styles.infoText}>
                    <RNText style={styles.boldText}>{translate('location_business_note_1')}</RNText>
                    <RNText>{translate('location_business_note_2')}</RNText>
                </RNText>
                <Button variant={loading ? 'disabled' : 'secondary'} title={'location_business_button'} style={styles.editBusinessButton} onPress={() => navigate('EditBusinessUnitCode', { location })} />
            </ScrollView>
        </SafeAreaView>
    )
}

BusinessUnitInfo.propTypes = {
    location: PropTypes.object,
    setLocation: PropTypes.func,
    goBack: PropTypes.func,
    isVirtual: PropTypes.bool,
    loading: PropTypes.bool,
    refetch: PropTypes.func,
    navigate: PropTypes.func,

}

export default BusinessUnitInfo
