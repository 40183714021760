import { gql } from '@apollo/client'

export const LIST_GROUPS = gql`
  query listCustomerGroups($limit: Int, $nextToken: String, $filter: Filter, $type: CustomerType) {
    listCustomerGroups(listCustomerGroupsInput: {
      limit: $limit,
      nextToken: $nextToken,
      filter: $filter,
      type: $type
    }) {
        customerGroups {
        id
        name,
        labelColor,
        type
      },
      nextToken
    },
  }
`
export const LIST_SUPPLIER_GROUPS = gql`
  query listSupplierGroups($limit: Int, $nextToken: String, $filter: Filter, $type: SupplierType) {
    listSupplierGroups(listSupplierGroupsInput: {
      limit: $limit,
      nextToken: $nextToken,
      filter: $filter,
      type: $type
    }) {
        supplierGroups {
        id
        name,
        labelColor,
        type
      },
      nextToken
    },
  }
`

export const DELETE_GROUP = gql`
  mutation deleteCustomerGroup($id: String!) {
    deleteCustomerGroup(id: $id){
      id
    }
  }
`
export const DELETE_SUPPLIER_GROUP = gql`
  mutation deleteSupplierGroup($id: String!) {
    deleteSupplierGroup(id: $id){
      id
    }
  }
`

export const EDIT_CUSTOMER = gql`
  mutation updateCustomer(
    $id: String!
    $customerGroupsToEnable: [String],
    $customerGroupsToDelete: [String]
  ){
    updateCustomer(customerUpdateInput:{
      id: $id,
      customerGroupsToEnable: $customerGroupsToEnable
      customerGroupsToDelete: $customerGroupsToDelete
    }){
      id
    }
  }
`

export const EDIT_SUPPLIER = gql`
  mutation updateSupplier(
    $id: String!
    $supplierGroupsToEnable: [String],
    $supplierGroupsToDelete: [String]
  ){
    updateSupplier(supplierUpdateInput:{
      id: $id,
      supplierGroupsToEnable: $supplierGroupsToEnable
      supplierGroupsToDelete: $supplierGroupsToDelete
    }){
      id
    }
  }
`
