import { styleSheetFactory } from '../../Themes/Colors'

const styles = styleSheetFactory(colors => ({
    container: {
        backgroundColor: colors.tertiaryBackground,
        borderRadius: 7,
        marginLeft: 15,
        height: 50,
        width: 55,
        justifyContent: 'center',
        alignItems: 'center',
    },
}))

export default styles
