import React, { useContext, useState } from 'react'
import {
    View,
    TouchableOpacity,
    FlatList,
    TouchableWithoutFeedback,
    Platform,
} from 'react-native'
import { useTheme } from 'react-native-themed-styles'
import PropTypes from 'prop-types'

import Text from '../Text'
import Icon from '../Icon'
import Button from '../Button'

import { useTemplateLanguage } from '../../Utils/TemplatesLanguage'

import Theme from '../../Themes/Theme'

import images from '../../Themes/Images'

import themedStyles from './ConfigurationSelectType.Styles'

const LANGUAGES = {
    al: 'al',
    en: 'en',
}

const isApp = Platform.OS !== 'web'

const SUPPORTED_LANGS = [
    {
        id: 'al',
        label: 'settings_albanian_label',
        disabled: false,
    },
    {
        id: 'en',
        label: 'settings_english_label',
        disabled: false,
    },
]

const SUPPORTED_FORMATS = {
    app: {
        true: [
            {
                id: '58mm',
                label: '58mm',
                disabled: true,
            },
            {
                id: '80mm',
                label: '80mm',
                disabled: true,
            },
            {
                id: 'A4',
                label: 'A4',
                disabled: false,
            },
        ],
        false: [
            {
                id: '58mm',
                label: '58mm',
                disabled: false,
            },
            {
                id: '80mm',
                label: '80mm',
                disabled: true,
            },
            {
                id: 'A4',
                label: 'A4',
                disabled: true,
            },
        ],
    },
    web: {
        true: [
            {
                id: '58mm',
                label: '58mm',
                disabled: true,
            },
            {
                id: '80mm',
                label: '80mm',
                disabled: true,
            },
            {
                id: 'A4',
                label: 'A4',
                disabled: false,
            },
        ],
        false: [
            {
                id: '58mm',
                label: '58mm',
                disabled: true,
            },
            {
                id: '80mm',
                label: '80mm',
                disabled: false,
            },
            {
                id: 'A4',
                label: 'A4',
                disabled: false,
            },
        ],
    },
}

function ConfigurationSelectType({
    languages,
    closeModal,
    closeModalWithHide,
    selectedFormat,
    selectFormat,
    selectedLang,
    selectLang,
    title,
    Item,
    isSharing,
}) {
    const [styles] = useTheme(themedStyles)
    const { colors } = useContext(Theme)
    const { setLanguage } = useTemplateLanguage()
    const [format, setFormat] = useState(selectedFormat)
    const [lang, setLang] = useState(selectedLang)

    const [formats] = useState(SUPPORTED_FORMATS[isApp ? 'app' : 'web'][isSharing])

    const keyExtractor = (_, index) => 'item_' + index

    const renderLangItem = ({ item }) => (
        <Item
            id={item.id}
            item={item}
            selected={lang.id}
            onPress={() => setLang(item)}
        />
    )

    return (
        <View style={styles.wrapper}>
            <View style={styles.header}>
                <Text
                    i18nKey={title}
                    weight="bold"
                    size="h4"
                    color={colors.accent}
                />
                <TouchableOpacity onPress={closeModal}>
                    <Icon source={images.close} color={colors.secondaryIcon} size={24} />
                </TouchableOpacity>
            </View>

            <TouchableWithoutFeedback>
                <FlatList
                    data={languages}
                    onStartShouldSetResponder={() => true}
                    decelerationRate={0.8}
                    keyExtractor={keyExtractor}
                    renderItem={renderLangItem}
                    style={styles.languageListStyle}
                    contentContainerStyle={styles.languageListContainerStyle}
                    ListHeaderComponent={<>
                        <Text i18nKey="payment_receipt_size_header" />
                        <View style={styles.formatListStyle}>
                            {formats.map((item, index) => (
                                <Item
                                    id={item?.id}
                                    item={item}
                                    index={index}
                                    length={formats?.length}
                                    selected={format}
                                    onPress={() => setFormat(item?.id)}
                                    isHorizontal={true}
                                />
                            ))}
                        </View>
                        <Text i18nKey="payment_receipt_language_header" />
                        <Text i18nKey="payment_receipt_language_note" size="footnote" />
                    </>}
                />
            </TouchableWithoutFeedback>
            <Button title="einvoice_confirm_button"
                style={styles.button}
                variant={'active'}
                onPress={() => {
                    const language = LANGUAGES?.[lang.id]
                    setLanguage(language)
                    selectFormat(format)
                    selectLang(lang)
                    closeModalWithHide()
                }}
            />
        </View>
    )
}
ConfigurationSelectType.defaultProps = {
    languages: SUPPORTED_LANGS,
    // formats: SUPPORTED_FORMATS[isApp ? 'app' : 'web'][isSharing],
}
ConfigurationSelectType.propTypes = {
    data: PropTypes.array,
    select: PropTypes.func,
    selected: PropTypes.object,
    closeModal: PropTypes.func,
    closeModalWithHide: PropTypes.func,
    title: PropTypes.string,
    Item: PropTypes.node,
    selectedFormat: PropTypes.string,
    selectFormat: PropTypes.func,
    selectedLang: PropTypes.string,
    selectLang: PropTypes.func,
    formats: PropTypes.array,
    languages: PropTypes.array,
    onSavePress: PropTypes.func,
    isSharing: PropTypes.bool,
}

export default ConfigurationSelectType
