import { gql } from '@apollo/client'

const GET_LOCATION = gql`
  query getLocation($id: String!){
    getLocation(id: $id){
      id
      NIPT
      phoneNumber
      businessName
      businUnitCode
      totalNoncashInvoices
      totalEInvoices
      businessEmail
      coordinates {
        latitude
        longitude
      }
      logoUrl
      catalogType
    }
  }
`

const LIST_CASH_REGISTERS = gql`
  query listCashRegisters($nextToken: String, $filter: Filter, $limit: Int, $locId: String, $cashRegisterId: String){
    listCashRegisters(
      nextToken: $nextToken, 
      filter: $filter, 
      limit: $limit
      requestedLocationAndCashRegister: {
        locationId: $locId,
        cashRegisterId: $cashRegisterId
      }
      ){
      cashRegisters {
        id,
        name,
        deviceName,
        status
      }
      nextToken
    }
  }
`

const UPDATE_LOCATION = gql`
  mutation updateLocation($catalogType: CatalogType, $id: String!,$businessEmail: String, $businessName: String, $phoneNumber: String,$businUnitCode:String, $locId: String, $cashRegisterId: String){
    updateLocation(
      updateLocationInput:{
        catalogType: $catalogType
        id: $id,
        businessName: $businessName,
        businessEmail: $businessEmail
        phoneNumber: $phoneNumber
        businUnitCode:$businUnitCode
        }
        requestedLocationAndCashRegister: {
          locationId: $locId,
          cashRegisterId: $cashRegisterId
        }
        ){
         id
    }
  }
`

const BUSIN_UNITS_LIST = gql`
query getBusinessUnits {
  getBusinessUnits {
    businessUnits {
      attributes {
        BUCode
        BUName
        BUStreetName
        BUValidFrom
      }
    }
  }
}
`

export {
  GET_LOCATION,
  LIST_CASH_REGISTERS,
  UPDATE_LOCATION,
  BUSIN_UNITS_LIST,
}
