import { styleSheetFactory } from '../../Themes/Colors'

const styles = styleSheetFactory(colors => ({
  container: {
    flex: 1,
  },
  iconHeader: {
    alignItems: 'center',
  },
  loaderContainer: {
    backgroundColor: colors.white,
    width: 110,
    height: 110,
    overflow: 'hidden',
    borderRadius: 7,
    justifyContent: 'center',
    alignItems: 'center',
  },

  //Tabs
  tabView: {
    backgroundColor: colors.background,
    paddingTop: 0,
  },
  scene: {
    flex: 1,
    backgroundColor: colors.background,
    paddingTop: 20,
  },
  iconContainer: {
    padding: 10,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: colors.backgroundRGBA(0.5),
    borderRadius: 20,
    marginRight: 10,
    width: 30,
    height: 30,
  },
  icon: {
    width: 15,
    height: 15,
    margin: 15,
    resizeMode: 'contain',
    tintColor: colors.secondaryIcon,
  },
  input: {
    flexGrow: 1,
    fontSize: 16,
    fontFamily: 'Vodafone Lt',
    color: colors.primaryText,
  },
  button: {
    marginTop: 5,
    marginBottom: 15,
    marginHorizontal: 15,
    height: 50,
  },
  buttonTitle: {
    fontSize: 18,
  },
  plusIcon: {
    tintColor: colors.white,
    height: 22,
    width: 22,
  },
  searchContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  verticalSpacing: { marginVertical: 5 },
  sectionTitle: { marginBottom: 10, marginTop: 15 },
  errorContainer: {
    backgroundColor: colors.background,
    marginTop: 15,
    borderRadius: 7,
  },
  errorSubContainer: {
    padding: 15,
    justifyContent: 'center',
    alignItems: 'center',
  },
}))

const transactionStyles = styleSheetFactory(colors => ({
  container: {
    flex: 1,
    backgroundColor: colors.background,
  },
  searchContainer: {
    flexDirection: 'row',
    marginTop: 15,
    minHeight: 50,
    justifyContent: 'center',
    alignItems: 'center',
  },
  listContainer: {
    marginTop: 0,
  },
  listRightComponent: {
    flexDirection: 'row',
    alignItems: 'center',
    marginRight: 10,
  },
  listRightComponentText: {
    alignItems: 'flex-end',
    marginRight: 10,
  },
  default: {
    flex: 1,
    backgroundColor: colors.tertiaryBackground,
    overflow: 'hidden',
  },
  list: {
    flexDirection: 'row',
    alignItems: 'center',
    flexGrow: 1,
  },
  listDescContainer: {
    flexGrow: 1,
    flexShrink: 1,
    marginLeft: 10,
    justifyContent: 'center',
  },
  divider: {
    height: 50,
    alignSelf: 'center',
    width: 1,
    backgroundColor: 'rgba(255, 255, 255, 0.4)',
    position: 'absolute',
  },
  listStyle: {
    flex: 1,
    marginHorizontal: 15,
  },
  separatorStyle: {
    height: 1,
    flex: 1,
    backgroundColor: colors.lightGrey,
  },
  fab: {
    position: 'absolute',
    bottom: 30,
    right: 15,
    height: 50,
    width: 50,
    borderRadius: 25,
    backgroundColor: colors.tertiaryAccent,
    justifyContent: 'center',
    alignItems: 'center',
    shadowColor: colors.black,
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.22,
    shadowRadius: 2.22,
    elevation: 3,
  },
  fabIcon: {
    transform: [{ rotate: '-90deg' }],
  },
  firstItem_true: {
    borderTopLeftRadius: 7,
    borderTopRightRadius: 7,
    borderTopWidth: 0,
  },
  lastItem_true: {
    borderBottomLeftRadius: 7,
    borderBottomRightRadius: 7,
  },
  itemStyle: {
    backgroundColor: colors.tertiaryBackground,
    height: 65,
    justifyContent: 'center',
    borderTopWidth: 1,
    borderTopColor: colors.separator,
  },
  contentContainer: { paddingBottom: 30, backgroundColor: colors.background },
  centeredItemView: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  leftSpacing: {
    marginLeft: 10,
  },
  bottomSpacing: {
    marginBottom: 3,
  },
  descriptionStyles: type => ({
    fontSize: type ? 12 : 14,
    lineHeight: type ? 14 : 16,
  }),
  rightComponent: {
    alignItems: 'center',
    justifyContent: 'center',
    padding: 15,
    flexDirection: 'row',
  },
  rightSpacing: {
    marginRight: 5,
  },
}))

const refundStyles = styleSheetFactory(colors => ({
  container: {
    flex: 1,
    backgroundColor: colors.background,
  },
  searchContainer: {
    flexDirection: 'row',
    marginTop: 15,
    minHeight: 50,
    justifyContent: 'center',
    alignItems: 'center',
  },
  listContainer: {
    marginTop: 0,
  },
  listRightComponent: {
    flexDirection: 'row',
    alignItems: 'center',
    marginRight: 10,
  },
  listRightComponentText: {
    alignItems: 'flex-end',
    marginRight: 10,
  },
  default: {
    flex: 1,
    backgroundColor: colors.tertiaryBackground,
    overflow: 'hidden',
  },
  list: {
    flexDirection: 'row',
    alignItems: 'center',
    flexGrow: 1,
  },
  listDescContainer: {
    flexGrow: 1,
    flexShrink: 1,
    marginLeft: 10,
    justifyContent: 'center',
  },
  divider: {
    height: 50,
    alignSelf: 'center',
    width: 1,
    backgroundColor: 'rgba(255, 255, 255, 0.4)',
    position: 'absolute',
  },
  listStyle: {
    flex: 1,
    marginHorizontal: 15,
  },
  separatorStyle: {
    height: 1,
    flex: 1,
    backgroundColor: colors.lightGrey,
  },
  fab: {
    position: 'absolute',
    bottom: 30,
    right: 15,
    height: 50,
    width: 50,
    borderRadius: 25,
    backgroundColor: colors.tertiaryAccent,
    justifyContent: 'center',
    alignItems: 'center',
    shadowColor: colors.black,
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.22,
    shadowRadius: 2.22,
    elevation: 3,
  },
  fabIcon: {
    transform: [{ rotate: '-90deg' }],
  },
  firstItem_true: {
    borderTopLeftRadius: 7,
    borderTopRightRadius: 7,
    borderTopWidth: 0,
  },
  lastItem_true: {
    borderBottomLeftRadius: 7,
    borderBottomRightRadius: 7,
  },
  itemStyle: {
    backgroundColor: colors.tertiaryBackground,
    height: 65,
    justifyContent: 'center',
    borderTopWidth: 1,
    borderTopColor: colors.separator,
  },
  contentContainer: { paddingBottom: 30, backgroundColor: colors.background },
  centeredItemView: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  leftSpacing: {
    marginLeft: 10,
  },
  bottomSpacing: {
    marginBottom: 3,
  },
  descriptionStyles: type => ({
    fontSize: type ? 12 : 14,
    lineHeight: type ? 14 : 16,
  }),
  rightComponent: {
    alignItems: 'center',
    justifyContent: 'center',
    padding: 15,
    flexDirection: 'row',
  },
  rightSpacing: {
    marginRight: 5,
  },
}))

const routeStyles = styleSheetFactory(colors => ({
  emptyComponent: {
    alignSelf: 'center',
    flexGrow: 1,
    alignItems: 'center',
    marginTop: 30,
  },

}))


export { routeStyles, transactionStyles, refundStyles }

export default styles
