import { styleSheetFactory } from '../../Themes/Colors'

const styles = styleSheetFactory(colors => ({
    container: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        height: 60,
        alignItems: 'center',
        borderBottomWidth: 1,
        borderBottomColor: colors.separator,
    },
    containertrue: {
        borderBottomWidth: 0,
    },
    containerTitle: {
        color: colors.secondaryText,
        fontSize: 15,
        paddingLeft: 20,
    },
    containerInfo: {
        color: colors.primaryText,
        paddingRight: 20,
        fontSize: 15,
    },
}))

export default styles
