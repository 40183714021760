/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback, useRef, useContext } from 'react'
import {
  SafeAreaView,
  View,
  ScrollView,
  Text as RNText,
} from 'react-native'
import { v1 as uuidv1 } from 'uuid'
import moment from 'moment'
import { useFocusEffect } from '@react-navigation/native'
import { useLazyQuery, useMutation } from '@apollo/client'
import { useTheme } from 'react-native-themed-styles'
import PropTypes from 'prop-types'

import { useLanguage } from '../../Utils/Language'

import {
  SimpleList,
  Loader,
  Button,
  Text,
  Icon,
  InputRow,
  Header,
  TutorialNote,
  ScrollWrapperWeb,
  RetryBanner,
  SimpleType,
  BottomSheetModal,
  SimpleSelectType,
  ErrorModal,
} from '../../Components'

import {
  CREATE_CASH_DRAWER_SHIFT,
  GET_CASH_DRAWER,
  GET_CURRENT_DRAWER,
  SYNC_CASH_DRAWER_SHIFTS,
} from '../CashDrawer/CashDrawer.schema'
import { getErrorMessage, INF } from '../../Utils/Helpers'

import { useConfig } from '../../Utils'

import Theme from '../../Themes/Theme'
import images from '../../Themes/Images'
import themedStyles from './CurrentDrawer.Styles'


const LANGUAGES = {
  al: 'sq',
  en: 'en-gb',
}
const NEW_BUTTON_TITLE = {
  true: 'drawer_confirm_start',
  false: 'drawer_start_button',
}

// Regex
const FCDCidFormat = /^[0-9A-Za-z-]{36}$/
const setValueFormat = /^\d*(\.\d{0,2})?$/
const submitValueFormat = /^\d+(\.\d{0,2})?$/


const FAIL_REASONS = [
  {
    label: 'receipt_retry_no_internet',
    id: '1',
    value: 'NOINTERNET',
  },
  {
    label: 'receipt_retry_technical_problem',
    id: '2',
    value: 'BOUNDBOOK',

  },
  {
    label: 'receipt_retry_general_problem',
    id: '3',
    value: 'SERVICE',

  },
  {
    label: 'receipt_retry_boundbook_problem',
    id: '4',
    value: 'TECHNICALERROR',

  },
]

function SartNewDrawer({ startdDrawer, openModal }) {

  const { switchLocation: { deviceId, locationId } } = useConfig()

  const [styles] = useTheme(themedStyles)

  const [value, setValue] = useState('')


  const [confirm, setConfirm] = useState(false)
  const [disabled, setDisabled] = useState(true)

  const [createShift, { loading }] = useMutation(CREATE_CASH_DRAWER_SHIFT)

  const replaceAt = (string, index, replacement) => {
    return string.substr(0, index) + replacement + string.substr(index + replacement.length)
  }

  const changeValue = (text) => {
    let newString = text
    const indexOfComa = text.indexOf(',')
    if (indexOfComa >= 0) {
      newString = replaceAt(text, indexOfComa, '.')
    }
    if (setValueFormat.test(newString)) {
      setValue(newString)
      if (text === '') {
        setDisabled(true)
      } else {
        setDisabled(false)
      }
    }
  }

  const startDrawer = () => {
    if (submitValueFormat.test(value)) {
      if (confirm) {
        createShift({
          variables: {
            locationId: locationId,
            cashRegisterId: deviceId,
            description: '',
            amount: value,
            currency: 'Lekë',
          },
        }).then(data => {
          if (data) {
            startdDrawer()
          }
        }).catch(err => {
          openModal(
            'certificate_error_label',
            getErrorMessage(err),
            [],
            '',
            undefined,
            '',
            undefined,
          )
        })
      } else {
        setConfirm(true)
      }
    }
  }

  if (loading) {
    return (
      <SafeAreaView style={styles.container}>
        <Loader view="full" size={124} />
      </SafeAreaView>
    )
  }

  return (
    <ScrollView contentContainerStyle={styles.scrollContainer}>
      <Text size="footnote" style={styles.sectionText} i18nKey="drawer_start_drawer" />
      <InputRow
        label="drawer_starting_amount_label"
        inputType="numeric"
        inputLabel="Lekë"
        value={value}
        placeholder="0.00"
        onChangeText={changeValue}
        style={styles.inputRow}
      />
      <Button
        title={NEW_BUTTON_TITLE[confirm]}
        titleStyle={styles.buttonTitle}
        style={styles.button}
        variant={disabled ? 'disabled' : confirm ? 'active' : 'preActive'}
        onPress={startDrawer}
        disabled={disabled}
        isActive
      />
      <TutorialNote url="https://www.youtube.com/watch?v=eszPokwAhP0" />
    </ScrollView>
  )
}
SartNewDrawer.propTypes = {
  startdDrawer: PropTypes.func,
}

function CurrentDrawer({
  route: {
    params: {
      id: routeId,
      cashRegisterId,
      nextAction,
    } = {},
  },
  navigation: {
    goBack,
    navigate,
  },
}) {

  const { switchLocation: { deviceId, locationId } } = useConfig()

  const [styles] = useTheme(themedStyles)
  const { colors } = useContext(Theme)
  const lang = useLanguage()
  const retryBannerRef = useRef()

  const [selectedReason, setSelectedReason] = useState({})
  const [drawerData, setDrawerData] = useState({
    isLoading: true,
    started: false,
    id: routeId,
    currentId: null,
    cashRegisterId,
    isCurrent: !routeId,
    data: [],
  })
  const [status, setStatus] = useState('')

  const [errorModal, setErrorModal] = useState({
    isVisible: false,
    icon: images.warningIcon,
    title: '',
    desc: '',
    descPlaceholders: [],
    primaryText: '',
    primaryAction: null,
    secondaryText: '',
    secondaryAction: null,
  })
  const openModal = (
    title = 'opened_orders_error',
    desc = '',
    descPlaceholders = [],
    primaryText = '',
    primaryAction = null,
    secondaryText = '',
    secondaryAction = null,
    icon = images.warningIcon,
  ) => {
    setErrorModal({
      isVisible: true,
      icon,
      title,
      desc,
      descPlaceholders,
      primaryText,
      primaryAction,
      secondaryText,
      secondaryAction,
    })
  }
  const closeModal = () => {
    setErrorModal({
      isVisible: false,
      icon: images.delete,
      title: '',
      desc: '',
      primaryText: '',
      primaryAction: null,
      secondaryText: '',
      secondaryAction: null,
    })
  }


  const [modal, setModal] = useState({
    isVisible: false,
    Item: SimpleType,
  })
  const [
    syncCurrentDrawer,
    { loading: syncLoading },
  ] = useMutation(SYNC_CASH_DRAWER_SHIFTS)

  const [getCurrentDrawer, { data: currentDrawerData, refetch: refetchCurrentDrawer }] = useLazyQuery(GET_CURRENT_DRAWER, {
    variables: {
      locationId: locationId,
      cashRegisterId: deviceId,
    },
    fetchPolicy: 'network-only',
  })

  const [getCashDrawerDetails, { error, data, refetch: refetchCashDrawer }] = useLazyQuery(GET_CASH_DRAWER, { fetchPolicy: 'network-only' })

  function getDrawerData() {
    setDrawerData(prev => ({ ...prev, isLoading: true }))
    getCurrentDrawer()
    if (!drawerData.isCurrent) {
      getCashDrawerDetails({
        variables: {
          locationId: 'J11111111A',
          shiftId: drawerData.id,
          cashRegisterId: drawerData.cashRegisterId,
        },
      })
    }
  }

  const refetch = () => {
    return new Promise((resolve) => {
      resolve(refetchCurrentDrawer())
      if (!drawerData.isCurrent) {
        resolve(refetchCashDrawer())
      }
    })
  }

  useFocusEffect(useCallback(() => {
    getDrawerData()
    return
  }, []))

  const renderRightComponent = (rightLabel) => <View style={styles.rightComponentStyle}>
    <Text size="footnote" style={styles.itemRightLabel} i18nKey={rightLabel} align="center" />
    <Icon source={images.chevronRight} size={20} color={colors.primaryIcon} />
  </View>

  const renderLeftComponent = (leftLabel) => {
    return (
      <View style={styles.leftComponentWrapper}>
        {/* <RNText style={styles.leftText}>{translate(leftLabel)}</RNText> */}
        <Text i18nKey={leftLabel} weight="light" size="footnote" />
        <RNText style={styles.listElementStar}>{'*'}</RNText>
      </View>
    )
  }

  const mapResponse = (res, id) => {
    const cashRegisterID = res.cashRegister?.id
    const totalPayIO = res?.cashPaidInMoney?.amount - res?.cashPaidOutMoney?.amount
    const itemStatus = res?.status
    let idRow
    if (!res?.FCDC) {
      idRow = {
        id: uuidv1(),
        leftText: 'drawer_id_label',
        rightComponent: renderIdStatus(
          images.clock,
          'drawer_processing_label',
          colors.secondaryAccent,
        ),
      }
    } else if (FCDCidFormat.test(res?.FCDC)) {
      idRow = {
        id: uuidv1(),
        leftText: 'drawer_id_label',
        rightText: res.FCDC,
        ratio: '1:4',
      }
    } else {
      idRow = {
        id: uuidv1(),
        leftText: 'drawer_id_label',
        rightComponent: renderIdStatus(
          images.warning,
          'drawer_error_label',
          colors.error,
          [res?.FCDC]
        ),
      }
    }
    const result = [
      idRow,
      {
        id: uuidv1(),
        leftText: 'drawer_starting_date_label',
        rightText: moment(res?.openedAt).locale(LANGUAGES[lang]).format('DD/MM/YYYY hh:mm A'),
        ratio: '1:2',
      },
      {
        id: uuidv1(),
        leftText: 'drawer_started_by_label',
        rightText: res?.openingEmployee?.email,
        ratio: '1:2',
      },
      {
        id: uuidv1(),
        leftText: 'drawer_starting_cash_label',
        rightText: INF.format(res?.openedCashMoney?.amount || 0) + ' Lekë',
      },
      {
        id: uuidv1(),
        leftText: 'drawer_paid_inout_label',
        rightComponent: renderRightComponent(INF.format(Number(totalPayIO.toFixed(2))) + ' Lekë'),
        onItemPress: () => navigate('PayInOut', {
          shiftId: drawerData.id || id,
          eventType: 'PAID_IN',
          isCurrent: drawerData.isCurrent,
          cashRegisterId: cashRegisterID || drawerData.cashRegisterId,
        }),
        touchableItems: true,
      },
      {
        id: uuidv1(),
        leftText: 'drawer_cash_sales_label',
        rightComponent: renderRightComponent(INF.format(res?.cashPaymentMoney?.amount || 0) + ' Lekë'),
        onItemPress: () => navigate('TodayDrawer', {
          title: 'drawer_cash_sales_label',
          eventType: 'CASH_TENDER_PAYMENT',
          navigateItem: 'Receipt',
          isRefund: false,
          listIsBlank: 'cash_sales_no_data',
          shiftId: drawerData.id || id,
          cashRegisterId: cashRegisterID || drawerData.cashRegisterId,
        }),
        touchableItems: true,
      },
      {
        id: uuidv1(),
        leftText: 'drawer_cash_refunds_label',
        rightComponent: renderRightComponent(INF.format(res?.cashRefundsMoney?.amount || 0) + ' Lekë'),
        onItemPress: () => navigate('TodayDrawer', {
          title: 'drawer_cash_refunds_label',
          eventType: 'CASH_TENDER_REFUND',
          navigateItem: 'Refund',
          isRefund: true,
          listIsBlank: 'cash_refunds_no_data',
          shiftId: drawerData.id || id,
          cashRegisterId: cashRegisterID || drawerData.cashRegisterId,
        }),
        touchableItems: true,
      },
      {
        id: uuidv1(),
        leftComponent: renderLeftComponent('drawer_card_sales'),
        rightComponent: renderRightComponent(INF.format(res?.cardPaymentsMoney?.amount || 0) + ' Lekë'),
        onItemPress: () => navigate('TodayDrawer', {
          title: 'drawer_card_sales',
          shiftId: drawerData.id || id,
          eventType: 'CARD_TENDER_PAYMENT',
          navigateItem: 'Receipt',
          isRefund: false,
          listIsBlank: 'cart_sales_no_data',
          cashRegisterId: cashRegisterID || drawerData.cashRegisterId,
        }),
        touchableItems: true,
      },
      {
        id: uuidv1(),
        leftComponent: renderLeftComponent('drawer_card_refunds'),
        rightComponent: renderRightComponent(INF.format(res?.cardRefundsMoney?.amount || 0) + ' Lekë'),
        onItemPress: () => navigate('TodayDrawer', {
          title: 'drawer_card_refunds',
          shiftId: drawerData.id || id,
          eventType: 'CARD_TENDER_REFUND',
          navigateItem: 'Refund',
          isRefund: true,
          listIsBlank: 'cart_refunds_no_data',
          cashRegisterId: cashRegisterID || drawerData.cashRegisterId,
        }),
        touchableItems: true,
      },
      {
        id: uuidv1(),
        leftComponent: renderLeftComponent('drawer_card_payment'),
        rightComponent: renderRightComponent(INF.format(res?.cardPaidInMoney?.amount || 0) + ' Lekë'),
        onItemPress: () => {
          navigate('TodayDrawer', {
            title: 'drawer_card_payment',
            eventType: 'CARD_PAID_IN',
            listIsBlank: 'cart_sales_no_data',
            navigateItem: 'PayInOutDetails',
            shiftId: drawerData.id || id,
            cashRegisterId: cashRegisterID || drawerData.cashRegisterId,
          })

        },
        touchableItems: true,
      },
      {
        id: uuidv1(),
        leftText: 'drawer_expected_label',
        rightText: INF.format(res?.expectedCashMoney?.amount || 0) + ' Lekë',
      },
    ]

    if (itemStatus) {
      setStatus(itemStatus)
    }

    setDrawerData(prev => {
      const newData = {
        ...prev,
        data: result,
        isLoading: false,
        started: true,
        currentId: id,
      }
      if (cashRegisterID) {
        newData.cashRegisterId = cashRegisterID
      }
      if (res?.FCDC) {
        newData.FCDC = res.FCDC
      }
      if (res?.failMessage) {
        newData.failMessage = res.failMessage
      }
      return newData
    })
  }

  useEffect(() => {
    if (currentDrawerData) {
      const id = currentDrawerData.currentCashDrawerShift?.id
      if (!routeId) {
        if (id) {
          mapResponse(currentDrawerData.currentCashDrawerShift, id)
        }
        else {
          setDrawerData(prev => ({
            ...prev,
            currentId: id,
            data: [],
            isLoading: false,
            started: false,
          }))
        }
      } else {
        setDrawerData(prev => ({
          ...prev,
          isCurrent: id === prev.id,
          currentId: id,
        }))
      }
    }
  }, [currentDrawerData])

  useEffect(() => {
    if (data) {
      if (data.retrieveCashDrawerShift?.id) {
        mapResponse(data.retrieveCashDrawerShift)
      } else {
        setDrawerData({
          data: [],
          isLoading: false,
          started: false,
        })
      }
    }
  }, [data])

  useEffect(() => {
    if (drawerData.started) {
      refetch()
    }
  }, [status])

  const onSyncCurrentDrawer = () => {
    setModal(prev => ({ ...prev, isVisible: false }))
    const drawerId = drawerData?.isCurrent ? drawerData?.currentId : drawerData?.id

    const syncObject = {
      locId: locationId,
      cashRegisterId: deviceId,
      shiftSyncObjects: [
        {
          id: drawerId,
          reason: selectedReason?.value,
        },
      ],
    }

    syncCurrentDrawer({
      variables: syncObject,
    }).then(res => {
      retryBannerRef.current.closeBanner()
      refetch().then(() => {
        retryBannerRef.current.changeStatus()
      }).catch()
    }).catch(e => { })
  }



  const renderIdStatus = (imgSource, text, color, placeholders = []) => <View style={styles.idStatus}>
    <Icon source={imgSource} size={20} color={color} style={styles.icon} />
    <Text color={color} i18nKey={text} placeHolders={placeholders} />
  </View>


  const renderListHeaderComponent = () => {
    return (
      <>
        <View style={styles.inlineButtons}>
          <Button
            title="drawer_end_button"
            backgroundColor={colors.buttonSecondaryAccent}
            color={colors.primaryText}
            style={styles.inlineButton}
            titleProps={{ align: 'center' }}
            onPress={() =>
              navigate('EndDrawer', {
                currentDrawerId: drawerData.id || drawerData.currentId,
                n: drawerData.isCurrent ? 1 : 2,
                cashRegisterId: drawerData.cashRegisterId,
              })
            }
            disabled={!drawerData.id && !drawerData.currentId}
          />
          {drawerData.isCurrent && <>
            <Button
              title="drawer_pay_inout_button"
              style={styles.inlineButton1}
              backgroundColor={colors.buttonSecondaryAccent}
              color={colors.primaryText}
              titleProps={{ align: 'center' }}
              onPress={() => navigate('PayInOut', {
                isCurrent: drawerData.isCurrent,
                eventType: 'PAID_IN',
                shiftId: drawerData.id || drawerData.currentId,
                cashRegisterId: drawerData?.cashRegisterId,
              })}
            />

            <Button
              title="drawer_payment_button"
              color={colors.primaryText}
              backgroundColor={colors.buttonSecondaryAccent}
              style={styles.inlineButton2}
              titleProps={{ align: 'center' }}
              onPress={() => navigate('PayInOut', {
                isCurrent: drawerData.isCurrent,
                eventType: 'PAID_IN',
                shiftId: drawerData.id || drawerData.currentId,
                cashRegisterId: drawerData?.cashRegisterId,
                isPayment: true,
              })}
            />
          </>}
        </View>
        <TutorialNote url="https://www.youtube.com/watch?v=WljY_9ct21Y" />
        <RetryBanner
          ref={retryBannerRef}
          onRetry={() => setModal(prev => ({ ...prev, isVisible: true }))}
          loading={syncLoading}
          status={status}
          code={drawerData?.FCDC}
          message={drawerData?.failMessage}
        />
        <Text size="footnote" style={styles.sectionText} i18nKey="drawer_current_details" />
      </>
    )
  }

  const renderListFooterComponent = (title) => {
    return (
      <View style={styles.listFooterWrapper}>
        {/*
        <RNText style={styles.listFooterText}>{translate(title)}</RNText> */}
        <RNText style={styles.listFooterStar}>{'*'}</RNText>
        <Text i18nKey={title} weight="regular" size="footnote" />
      </View>
    )
  }

  if (drawerData.isLoading) {
    return (
      <View style={styles.container}>
        <Loader view="full" size={124} />
      </View>
    )
  }

  if (error) {
    return (
      <View style={styles.containerWithCenteredContent}>
        <Text color={colors.error} i18nKey={error.message} />
        <Button
          title="drawer_retry_button"
          titleStyle={styles.buttonTitle}
          style={styles.button}
          backgroundColor={colors.buttonSecondaryAccent}
          borderWidth={0}
          borderColor={colors.buttonSecondaryAccent}
          color={colors.accent}
          onPress={() => getDrawerData()}
          isActive
        />
      </View>
    )
  }

  return (
    <SafeAreaView style={styles.container}>
      <Header
        title="header_current_drawer"
        image={images.closeIcon}
        onPress={() => goBack()}
      />
      <ScrollWrapperWeb>
        {(drawerData.started && <>
          <SimpleList
            DATA={drawerData.data}
            containerStyle={styles.list}
            onRefresh={getDrawerData}
            refreshing={drawerData.isLoading}
            listHeaderComponent={renderListHeaderComponent()}
            listFooterComponent={renderListFooterComponent('drawer_bottom_disclaimer')}
          />
          {Boolean(nextAction) && (
            <Button title={nextAction} onPress={goBack} variant="secondary" style={styles.nextAction} />
          )}
        </>) || (
            <SartNewDrawer startdDrawer={getDrawerData} openModal={openModal} closeModal={closeModal} />
          )}
      </ScrollWrapperWeb>
      <BottomSheetModal
        isVisible={modal.isVisible}
        onClose={() => setModal(prev => ({ ...prev, isVisible: false }))}
      >
        <SimpleSelectType
          subtitle="receipt_retry_subtitle"
          data={FAIL_REASONS}
          Item={modal.Item}
          title="receipt_retry_title"
          placeholder="einvoice_doc_type_search_placeholder"
          selected={selectedReason?.id}
          select={obj => setSelectedReason(obj)}
          closeModal={() => setModal(prev => ({ ...prev, isVisible: false }))}
        />
        <Button
          title="receipt_retry_synchronize_button"
          style={styles.retryButton}
          variant={Object.keys(selectedReason).length > 0 ? 'active' : 'disabled'}
          onPress={onSyncCurrentDrawer}
        />
      </BottomSheetModal>
      <ErrorModal
        isVisible={errorModal?.isVisible}
        title={errorModal?.title}
        description={errorModal?.desc}
        placeHolders={errorModal.descPlaceholders}
        primaryText={errorModal?.primaryText}
        secondaryText={errorModal?.secondaryText}
        primaryAction={errorModal?.primaryAction}
        secondaryAction={errorModal?.secondaryAction}
        onBackdropPress={() => closeModal()}
        onBackButtonPress={() => closeModal()}
        onClosePress={() => closeModal()}
      />
    </SafeAreaView>
  )
}

CurrentDrawer.propTypes = {
  route: PropTypes.object,
  navigation: PropTypes.object,
}

export default CurrentDrawer
