import { gql } from '@apollo/client'

export const GET_INVOICE = gql`
  query getEinvoices(
    $eic: String
    ) {
    getEinvoices(getEinvoicesInput: {
      eic: $eic,
    }) {
      eInvoices {
        attributes {
          EIC
          DocNumber
          Amount
          Status
          PartyType
          RecDateTime
          DueDateTime
          SellerTin
          BuyerTin
        },
      },
    },
  }
`

export const GET_BUYER_SELLER = gql`
  query getEinvoices(
    $eic: String
    ) {
    getEinvoices(getEinvoicesInput: {
      eic: $eic,
    }) {
      eInvoices {
        attributes {
          SellerTin
          BuyerTin
        },
      },
    },
  }
`

export const GET_TAX_PAYER = gql`
  query getBusinessGov($name: String!, $value: String!) {
    getBusinessGov(searchBy: {
      name: $name,
      value: $value
    }) {
      customers {
        id
        issuerNUIS
        businessName
        address {
          addressLine1
          country
          administrativeDistrictLevel1
          postalCode
        }
      },
    },
  }
`

export const GET_PDF = gql`
  query getEinvoices(
    $eic: String
    ) {
    getEinvoices(getEinvoicesInput: {
      eic: $eic,
    }) {
      eInvoices {
        Pdf
      },
    },
  }
`

export const CHANGE_STATUS = gql`
  mutation changeEinvoiceStatus(
      $eics: [String!]!,
      $status: EinvoiceStatus
  ){
    changeEinvoiceStatus(changeEinvoiceStatusInput:{
      eics: $eics,
      status: $status
    }){
      statusCode
    }
  }
`

export const GET_MY_INVOICE = gql`
  query getMyEinvoice($id: String!, $locId: String, $cashRegisterId: String){
    getMyEinvoice(
      id: $id
      requestedLocationAndCashRegister: {
        locationId: $locId,
        cashRegisterId: $cashRegisterId
      }
      ){
      myEinvoice {
        ublInvoice {
          note
          dueDate
          issueDate
          id
          status
          invoiceDocumentCode
          invoiceProcessCode
          buyerReference
          employeeEmail
          orderReference {
            id
            salesOrderId
          }
          contractDocumentReference {
            id
          }
          additionalDocumentReferences {
            id
            description
            uri
            binObj {
              filename
              contents
              mime
            }
          }
          projectReference {
            id
          }
          delivery {
            deliveryPartyName {
              name
            }
            actualDeliveryDate
            deliveryLocation {
              address {
                streetName
                cityName
                postalCode
                countryCode
              }
            }
            deliveryPartyName {
              name
            }
          }
          docCurrencyCode
          taxCurrencyCode
          invoicePeriod {
            startDate
            endDate
            descriptionCode
          }
          supplierParty {
            issuerNUIS
            name
            address {
              streetName
              cityCode
              countryCode
            }
            taxSchema
            registrationName
            contact {
              name
              telephone
              electronicMail
            }
          }
          customerParty {
            issuerNUIS
            name
            address {
              streetName
              cityCode
              countryCode
            }
            taxSchema
            registrationName
            contact {
              name
              telephone
              electronicMail
            }
          }
          paymentMeans {
            card {
              primaryAccountId
            }
            payeeFinantialAccount
            instructionNote
            code
          }
          taxTotal {
            taxAmount {
              amount
              currency
            }
            taxSubtotal {
              taxableAmount {
                amount
                currency
              }
              taxAmount {
                amount
                currency
              }
              taxCategory {
                id
                percent
                taxSchema
              }
            }
          }
          legalMonetaryTotal {
            lineExtension {
              amount
              currency
            }
            taxExclusive {
              amount
              currency
            }
            taxInclusive {
              amount
              currency
            }
            payable {
              amount
              currency
            }
            charge {
              amount
              currency
            }
            allowance {
              amount
              currency
            }
          }
          allowances {
            chargeIndicator
            chargeReason
            amount {
              amount
              currency
            }
            taxCategory {
              id
              percent
              taxSchema
            }
            baseAmount {
              amount
              currency
            }
            percent
          }
          invoiceLine {
            id
            quantity
            lineExtension {
              amount
              currency
            }
            allowances {
              chargeIndicator
              chargeReason
              amount {
                amount
                currency
              }
              taxCategory {
                id
                percent
                taxSchema
              }
              baseAmount {
                amount
                currency
              }
              percent
            }
            item {
              name
              sellerIdentification
              description
              price {
                amount
                currency
              }
              taxCategory {
                id
                percent
                taxSchema
              }
              baseQuantity {
                quantity
                unitCode
              }
            }
            totalMoney {
              amount
              currency
            }
            totalTaxMoney {
              amount
              currency
            }
          }
        }
      }
    }
  }
`
export const LIST_MY_INVOICES = gql`
  query listMyEinvoices(
    $filter: Filter
    $nextToken: String
    $limit: Int
    $locId: String
    $cashRegisterId: String
    ) {
      listMyEinvoices(listEinvoicesInput: {
      filter: $filter
      nextToken: $nextToken
      limit: $limit
    }
    requestedLocationAndCashRegister: {
      locationId: $locId,
      cashRegisterId: $cashRegisterId
    }
    ) {
      nextToken
      myEinvoices {
        ublInvoice {
          id
          issueDate
          status
          invoiceDocumentCode
          invoicePeriod {
            startDate
          }
          note
          legalMonetaryTotal {
            lineExtension {
              amount
              currency
            }
            payable {
              amount
              currency
            }
          }
        },
      },
    },
  }
`

export const CONVERT_UBL_TO_ORDER = gql`
query ublToOrder($ubl: UblInvoiceInput!,  $locId: String, $cashRegisterId: String) {
  ublToOrder(
    ubl: $ubl
    requestedLocationAndCashRegister: {
      locationId: $locId,
      cashRegisterId: $cashRegisterId
    }
    ){
    totalMoney{
      amount
      currency
    }
    totalDiscountMoney{
      amount
      currency
    }
    totalTaxMoney{
      amount
      currency
    }
    appliedDiscounts{
      id
      name
      discountType
      percentage
      appliedMoney{
        amount
        currency
      }
    }
    orderGroupedTaxes {
      VATRate
      NumOfItems
      PriceBefVat {
        amount
        currency
      }
      VATAmt {
        amount
        currency
      }
    }
    lineItems{
      itemName
      quantity
      modifiers{
        name
        appliedMoney{
          amount
          currency
        }
      }
      appliedDiscounts{
        name
        appliedMoney{
          amount
          currency
        }
        percentage
      }
      totalMoney{
        amount
        currency
      }
      basePriceMoney{
        amount
        currency
      }
    }
  }
}
`
