import React, { useContext, useRef } from 'react'
import { View, Animated, Easing, TouchableOpacity, KeyboardAvoidingView, Platform } from 'react-native'
import { SwipeListView } from 'react-native-swipe-list-view'
import PropTypes from 'prop-types'

import { useTheme } from 'react-native-themed-styles'

import Icon from '../Icon'
import TextInput from '../TextInput'
import Button from '../Button'

import themedStyles from './SwipeList.Styles'
import images from '../../Themes/Images'
import Theme from '../../Themes/Theme'

const AnimatedTouchable = Animated.createAnimatedComponent(TouchableOpacity)
const ITEM_HEIGHT = 70

function SwipeList(props) {
  const [styles] = useTheme(themedStyles)
  const { colors } = useContext(Theme)
  const {
    searchValue,
    searchPlaceholder,
    onSearch,
    createNewLabel,
    onCreatePress,
    useFlatList,
    useSectionList,
    setItemWidth,
    buttonVariant,
    containerStyle,
    contentContainerStyle,
    ...rest
  } = props

  const listRef = useRef(null)
  const fabSize = useRef(new Animated.Value(0)).current
  const animate = value => {
    Animated.timing(fabSize, {
      toValue: value,
      duration: 370,
      useNativeDriver: true,
      easing: Easing.inOut(Easing.cubic),
    }).start()
  }
  const scrollToTop = () => {
    animate(0)
    if (useSectionList) {
      listRef.current.scrollToLocation({
        animated: true,
        itemIndex: 0,
        sectionIndex: 0,
      })
    } else {
      listRef.current.scrollToOffset({
        offset: 0,
      })
    }
  }
  const measureView = (event) => {
    setItemWidth(event.nativeEvent.layout.width)
  }
  const keyExtractor = item => item?.id
  const getItemLayout = (_, index) => ({
    length: ITEM_HEIGHT,
    offset: (ITEM_HEIGHT + 1) * index,
    index,
  })
  const onMomentumScrollEnd = (event) => {
    if (event.nativeEvent.contentOffset.y > 800) {
      animate(1)
    } else {
      animate(0)
    }
  }
  const onLayout = (event) => measureView(event)
  const listViewRef = ref => { listRef.current = ref }

  return (
    <KeyboardAvoidingView
      style={styles.keyboardAvoidingView}
      behavior={Platform.select({
        ios: 'padding',
        android: undefined,
      })}
    >
      <View style={[styles.container, containerStyle]}>
        {Boolean(onSearch) && (
          <View style={styles.searchContainer}>
            <Icon source={images.search} style={styles.icon} />
            <TextInput
              placeholder={searchPlaceholder}
              placeholderTextColor={colors.placeholder}
              style={styles.input}
              value={searchValue}
              onChangeText={onSearch}
            />
            {Boolean(searchValue) &&
              <TouchableOpacity onPress={() => onSearch('')} style={styles.iconContainer}>
                <Icon source={images.close} style={styles.icon} />
              </TouchableOpacity>
            }
          </View>
        )}
        {Boolean(onCreatePress) && (
          <Button
            icon={images.plus}
            iconStyle={styles.plusIcon}
            title={createNewLabel}
            titleStyle={styles.buttonTitle}
            style={styles.button}
            onPress={() => onCreatePress()}
            variant={buttonVariant || 'active'}
          />
        )}
        <SwipeListView
          listViewRef={listViewRef}
          onLayout={onLayout}
          onMomentumScrollEnd={onMomentumScrollEnd}
          contentContainerStyle={[styles.contentContainer, contentContainerStyle]}
          keyExtractor={keyExtractor}
          getItemLayout={getItemLayout}
          showsVerticalScrollIndicator={false}
          recalculateHiddenLayout={false}
          closeOnRowPress
          closeOnRowOpen
          closeOnRowBeginSwipe
          closeOnScroll
          useSectionList={useSectionList}
          useNativeDriver
          useAnimatedList
          {...rest}
        />
        <AnimatedTouchable
          onPress={scrollToTop}
          activeOpacity={0.5}
          style={[
            styles.fab,
            {
              opacity: fabSize,
              transform: [{ scale: fabSize }],
            },
          ]}>
          <Icon
            source={images.arrow_right}
            size={20}
            color={colors.tertiaryIcon}
            style={styles.fabIcon}
          />
        </AnimatedTouchable>
      </View>
    </KeyboardAvoidingView>
  )
}

SwipeList.defaultProps = {
  renderHidenItem: () => null,
  useFlatList: true,
  useSectionList: false,
  setItemWidth: () => { },
}

SwipeList.propTypes = {
  searchValue: PropTypes.string,
  searchPlaceholder: PropTypes.string,
  onSearch: PropTypes.func,
  renderItem: PropTypes.func,
  renderHidenItem: PropTypes.func,
  useFlatList: PropTypes.bool,
  useSectionList: PropTypes.bool,
  setItemWidth: PropTypes.func,
  createNewLabel: PropTypes.string,
  onCreatePress: PropTypes.func,
  buttonVariant: PropTypes.string,
  contentContainerStyle: PropTypes.object,
  containerStyle: PropTypes.object,
}

export default SwipeList
