import { createContext } from 'react'
import { LayoutAnimation } from 'react-native'

const Theme = createContext({})

const layoutAnimConfig = {
  duration: 300,
  create:
  {
    type: LayoutAnimation.Types.easeInEaseOut,
    property: LayoutAnimation.Properties.scaleXY,
  },
  update:
  {
    type: LayoutAnimation.Types.easeInEaseOut,
  },
}

const opacityConfig = {
  duration: 300,
  create:
  {
    type: LayoutAnimation.Types.easeInEaseOut,
    property: LayoutAnimation.Properties.opacity,
  },
  update:
  {
    type: LayoutAnimation.Types.easeInEaseOut,
  },
}

export default Theme
export { layoutAnimConfig, opacityConfig }
