import React from 'react'
import { ScrollView } from 'react-native'
import { useTheme } from 'react-native-themed-styles'
import moment from 'moment'
import PropTypes from 'prop-types'


import { Section } from '../../../Components'

import themedStyles from '../RefundDetails.Styles'

const dateFormat = 'DD/MM/YYYY'
const processTypes = [
    {
        code: 'P1',
        description: 'process_type_p1_description',
        type: 'P1_CONTRACT',
    },
    {
        code: 'P2',
        description: 'process_type_p2_description',
        type: 'P2_PERIODIC_INVOICE',
    },
    {
        code: 'P3',
        description: 'process_type_p3_description',
        type: 'P3_UNFORESEEN_ORDER',
    },
    {
        code: 'P4',
        description: 'process_type_p4_description',
        type: 'P4_ADVANCE_PAYMENT',
    },
    {
        code: 'P5',
        description: 'process_type_p5_description',
        type: 'P5_SPOT_PAYMENT',
    },
    {
        code: 'P6',
        description: 'process_type_p6_description',
        type: 'P6_PAYMENT_BEFORE_PURCHASE',
    },
    {
        code: 'P7',
        description: 'process_type_p7_description',
        type: 'P7_DISPATCH_NOTE',
    },
    {
        code: 'P8',
        description: 'process_type_p8_description',
        type: 'P8_DISPATCH_NOTE_RECEIPT',
    },
    {
        code: 'P9',
        description: 'process_type_p9_description',
        type: 'P9_APPROVAL_OR_NEGATIVE',
    },
    {
        code: 'P10',
        description: 'process_type_p10_description',
        type: 'P10_CORRECTIVE_INVOICE',
        disabled: true,
    },
    {
        code: 'P11',
        description: 'process_type_p11_description',
        type: 'P11_PARTIAL_INVOICING',
    },
    {
        code: 'P12',
        description: 'process_type_p12_description',
        type: 'P12_SELF_INVOICE',
    },
]

const documentTypes = [
    {
        code: '388',
        description: 'document_type_388_description',
        type: 'TAXINVOICE',
    },
    {
        code: '82',
        description: 'document_type_82_description',
        type: 'METERED_SERVICE',
    },
    {
        code: '325',
        description: 'document_type_325_description',
        type: 'PROFORMA_INVOICE',
    },
    {
        code: '326',
        description: 'document_type_326_description',
        type: 'PARTIAL_INVOICE',
    },
    {
        code: '380',
        description: 'document_type_380_description',
        type: 'COMMERCIAL_INVOICE',
    },
    {
        code: '381',
        description: 'document_type_381_description',
        type: 'CREDIT_NOTE',
    },
    {
        code: '383',
        description: 'document_type_383_description',
        type: 'DEBIT_NOTE',
    },
    {
        code: '384',
        description: 'document_type_384_description',
        type: 'CORRECTEDINVOICE',
        disabled: true,
    },
    {
        code: '386',
        description: 'document_type_386_description',
        type: 'PREPAYMENT_INVOICE',
    },
    {
        code: '394',
        description: 'document_type_394_description',
        type: 'LEASE_INVOICE',
    },
]

const getProcessDescr = (processCode) => {
    const description = processTypes.find(item => item.type === processCode)
    return description?.description
}

const getDocumentDescr = (documentCode) => {
    const description = documentTypes.find(item => item.type === documentCode)
    return description?.description
}

function Process({ eInvoiceData }) {
    const [styles] = useTheme(themedStyles)

    const sectionData = [
        {
            title: 'einvoice_process_preview_section',
        },
        {
            label: 'einvoice_section_process',
            value: getProcessDescr(eInvoiceData?.invoiceProcessCode),
        },
        {
            label: 'einvoice_doc_type_preview',
            value: getDocumentDescr(eInvoiceData?.invoiceDocumentCode),
        },
        {
            label: 'einvoice_currency_label',
            value: eInvoiceData?.docCurrencyCode,
        },
        {
            label: 'einvoice_payment_deadline_label',
            value: eInvoiceData?.dueDate ? moment(eInvoiceData?.dueDate).format(dateFormat) : '-',
        },
        {
            label: 'einvoice_start_date_label',
            value: eInvoiceData?.startDate ? moment(eInvoiceData?.startDate).format(dateFormat) : '-',
        },
        {
            label: 'einvoice_end_date_label',
            value: eInvoiceData?.endDate ? moment(eInvoiceData?.endDate).format(dateFormat) : '-',
        },
        {
            label: 'einvoice_effective_vat_label',
            value: eInvoiceData?.taxCurrencyCode || '-',
        },
    ]

    return (
        <ScrollView
            style={styles.container}
            contentContainerStyle={styles.content}
            showsVerticalScrollIndicator={false}
        >
            <Section data={sectionData} />
        </ScrollView>
    )
}

Process.propTypes = {
    eInvoiceData: PropTypes.object,
}

export default Process
