import { gql } from '@apollo/client'

export const LIST_TRANSACTIONS = gql`
query listTransactions(
  $locationId: String!, 
  $nextToken: String, 
  $limit: Int,
  $customerId: String, 
  $cashRegisterId: String
  ){
    listTransactions(
        locationId: $locationId, 
        nextToken: $nextToken, 
        limit: $limit, 
        customerId: $customerId,
        requestedLocationAndCashRegister: {
            locationId: $locationId,
            cashRegisterId: $cashRegisterId
          }
        ){
        nextToken
        transactions{
            id
            receiptNumber
            createdAt
            tender{
                amountMoney{
                    amount
                    currency
                }
            }

        }
    }
}
`

export const LIST_REFUNDS = gql`
query listRefunds(
  $locationId: String!, 
  $nextToken: String, 
  $limit: Int,
  $customerId: String,
  $locId: String,
  $cashRegisterId: String
  ){
    listRefunds(
        locationId: $locationId, 
        nextToken: $nextToken, 
        limit: $limit, 
        customerId: $customerId,
        requestedLocationAndCashRegister: {
            locationId: $locId,
            cashRegisterId: $cashRegisterId
          }
        ){
        nextToken
        refunds{
            id
            receiptNumber
            createdAt
            transactionId
            amountMoney{
                amount
                currency
            }
        }
    }
}
`
