import { styleSheetFactory } from '../../Themes/Colors'

const styles = styleSheetFactory(colors => ({
  inputContainer: {
    flexDirection: 'row',
    marginVertical: 5,
    minHeight: 60,
    alignItems: 'center',
    backgroundColor: colors.tertiaryBackground,
    borderRadius: 7,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: colors.tertiaryBackground,
    paddingHorizontal: 17,
    justifyContent: 'space-between',
  },
  label: {
    flexShrink: 0,
    marginRight: 0,
    paddingVertical: 15,
  },
  searchInput: {
    flex: 1,
    marginLeft: 7.5,
    // height: 60,
    textAlign: 'right',
    includeFontPadding: false,
    textAlignVertical: 'center',
    color: colors.primaryText,
    fontSize: 16,
    padding: 0,
    fontFamily: 'Vodafone Lt',
    paddingRight: 0,
    flexGrow: 1,
  },
  input: {
    flexGrow: 1,
    flexShrink: 1,
    marginLeft: 5,
    height: 60,
    textAlign: 'right',
    includeFontPadding: false,
    textAlignVertical: 'center',
    color: colors.primaryText,
    fontSize: 16,
    fontFamily: 'Vodafone Lt',
    paddingRight: 2,
  },
  leftInput: {
    textAlign: 'left',
    marginLeft: 0,
  },
  withInputLabel_false: {
    // paddingRight: 15,
  },
  withInputLabel_true: {
    paddingRight: 2,
  },
  inputLabel: {
    // paddingBottom: 4,
  },
  floatingContainer: {
    height: 60,
    backgroundColor: colors.white,
    marginVertical: 5,
    borderRadius: 7,
    paddingHorizontal: 17,
    paddingVertical: 12,
  },
  floatingInput: {
    fontSize: 16,
    // height: 60,
    fontFamily: 'Vodafone Rg',
    padding: 0,
    paddingBottom: 3.5,
    flexGrow: 1,
  },
  category: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    flexShrink: 1,
  },
  floatingLabel: { marginTop: -5 },
  inputView: { flex: 1, flexDirection: 'row' },
  linkingLabel: { marginRight: 5 },
}))

export default styles
