import React from 'react'
import { View, TouchableOpacity } from 'react-native'
import { useTheme } from 'react-native-themed-styles'
import PropTypes from 'prop-types'

import { Text } from '../../../../../Components'

import themedStyles from './City.Styles'

function City({ item, selected, onPress }) {
  const [styles] = useTheme(themedStyles)
  const isSelected = selected === item

  return (
    <View style={styles.container}>
      <TouchableOpacity
        onPress={onPress}
        style={styles['itemSelected_' + isSelected]}
        activeOpacity={0.6}
      >
        <Text
          i18nKey={item}
          size="footnote"
          style={styles['itemTextSelected_' + isSelected]}
        />
      </TouchableOpacity>
    </View>
  )
}
City.propTypes = {
  item: PropTypes.string,
  selected: PropTypes.object,
  onPress: PropTypes.func,
}

export default City
