import { styleSheetFactory } from '../../../../Themes/Colors'

const styles = styleSheetFactory(colors => ({
  fillRemaining: {
    // flexGrow: 1,
  },
  sectionText: {
    marginTop: 20,
    marginBottom: 5,
    marginHorizontal: 15,
  },
  exchangeRate: {
    justifyContent: 'flex-start',
    marginBottom: 24,
    marginHorizontal: 15,
  },
  tagsContentContainer: {
    paddingHorizontal: 10,
  },
  cashPayment: {
    marginBottom: 16,
    marginHorizontal: 15,
  },
  email: {
    marginHorizontal: 15,
    marginBottom: 16,
  },
  buttons: {
    flexDirection: 'row',
    margin: 15,
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  leftButton: {
    marginRight: 15,
  },
  rightButton: {
    flex: 2,
    height: 50,
  },
  customerContainer: {
    marginHorizontal: 15,
  },
  customerHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  customerData: {
    padding: 15,
    backgroundColor: colors.white,
    borderRadius: 7,
    marginVertical: 10,
  },
  indicatorContainer: {
    width: '100%',
    height: 20,
    marginTop: 10,
    alignContent: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    flexShrink: 0,
  },
  indicator_false: {
    width: 10,
    height: 10,
    backgroundColor: colors.disabled,
    borderRadius: 10,
    marginHorizontal: 7.5,
  },
  indicator_true: {
    width: 18,
    height: 10,
    backgroundColor: colors.accent,
    borderRadius: 10,
    marginHorizontal: 7.5,
  },
  configWrapper: {
    flexDirection: 'row',
    paddingHorizontal: 15,
    paddingTop: 15,
    justifyContent: 'space-between',
  },
  leftConfigInput: {
    flex: 1,
    marginRight: 7,
  },
  rightConfigInput: {
    flex: 1,
  },
  totalAndChangeWrapper: {
    marginHorizontal: 15,
    marginTop: 15,
  },
  inputWrapper: (isEinvoice) => {
    return {
      marginHorizontal: 15,
      marginBottom: isEinvoice ? 0 : 16,
      marginTop: 0,
    }
  },
}))

export default styles
