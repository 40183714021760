const amplifyConfig = {
  Auth: {
    identityPoolId: 'eu-central-1:8eb8950d-1415-4952-9af9-246d32e52019',
    region: 'eu-central-1',
    identityPoolRegion: 'eu-central-1',
    // DEV
    // userPoolId: 'eu-central-1_War7GuG5q',
    // userPoolWebClientId: '5nfjot7um38u507b4l2rv4q143',
    // // PROD
    userPoolId: 'eu-central-1_0PX5B73Ox',
    userPoolWebClientId: '4om2esuqkecapnsfu1v1q4nhek',
  },
  Analytics: {
    disabled: true,
  },
}

export default amplifyConfig
