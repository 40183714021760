import { styleSheetFactory } from '../../Themes/Colors'

const styles = styleSheetFactory(colors => ({
  ButtonRoot: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 8,
    borderRadius: 7,
    fontSize: 18,
    height: 50,
  },
  preActive: {
    borderWidth: 0,
    backgroundColor: colors.tertiaryBackground,
    color: colors.accent,
  },
  active: {
    borderWidth: 0,
    backgroundColor: colors.accent,
    color: colors.white,
  },
  secondary: {
    borderWidth: 0,
    backgroundColor: colors.secondaryAccent,
    color: colors.white,
  },
  default: {
    borderWidth: 0,
    backgroundColor: colors.midGrey,
    color: colors.white,
    textAlign: 'center',
  },
  disabled: {
    borderWidth: 0,
    backgroundColor: colors.disabled,
    color: colors.white,
  },
  outlineActive: {
    borderWidth: 1,
    borderColor: colors.accent,
    backgroundColor: colors.transparent,
    color: colors.accent,
  },
  link: {
    paddingHorizontal: 0,
    borderWidth: 0,
    backgroundColor: colors.tertiaryBackground,
    color: colors.primaryText,
  },
  text: {
    borderWidth: 0,
    backgroundColor: colors.tertiaryBackground,
    color: colors.primaryText,
  },
  outlineSecondary: {
    borderWidth: 1,
    borderColor: colors.turquoise,
    backgroundColor: colors.tertiaryBackground,
    color: colors.turquoise,
  },
  outline: {
    borderWidth: 1,
    borderColor: colors.secondaryText,
    backgroundColor: colors.tertiaryBackground,
    color: colors.primaryText,
  },
  icon: {
    marginRight: 5,
    // tintColor: colors.white,
  },
}))

export default styles
