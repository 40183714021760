import { gql } from '@apollo/client'

export const CREATE_MODIFIER = gql`
  mutation createModifierList($name: String!, $modifiers: [ModifierInput],$locationId:String, $locId: String, $cashRegisterId: String){
    createModifierList(modifierListInput:{
      name: $name
      modifiers: $modifiers
    }
    locationId:$locationId 
    requestedLocationAndCashRegister: {
      locationId: $locId,
      cashRegisterId: $cashRegisterId
    }
  ){
      id
    }
  }
`

export const EDIT_MODIFIER = gql`
  mutation updateModifierList($id: String!, $name: String, $modifiers: [ModifierUpdateInput],$locationId:String, $locId: String, $cashRegisterId: String){
    updateModifierList(modifierListUpdateInput:{
      id: $id
      name: $name
      modifiers: $modifiers
    }
    locationId:$locationId
    requestedLocationAndCashRegister: {
      locationId: $locId,
      cashRegisterId: $cashRegisterId
    }
    ){
      id
      name
    }
  }
`

export const GET_MODIFIER = gql`
  query getModifierList($id: String!,$locationId:String, $locId: String, $cashRegisterId: String){
    getModifierList(getModifierListInput:{
      id: $id
    }
    locationId:$locationId
    requestedLocationAndCashRegister: {
      locationId: $locId,
      cashRegisterId: $cashRegisterId
    }
    ){
      modifierList{
        id
        name
      }
      listModifiers{
        modifiers{
          id
          modifierListId
          name
          priceMoney {
            amount
            currency
          }
        }
        nextToken
      }
    }
  }
`

export const DELETE_MODIFIER = gql`
  mutation deleteModifier($id: String!, $modifierListId: String!,$locationId:String, $locId: String, $cashRegisterId: String){
    deleteModifier(
      id: $id,
      modifierListId: $modifierListId
      locationId:$locationId
      requestedLocationAndCashRegister: {
        locationId: $locId,
        cashRegisterId: $cashRegisterId
      }
    ){
      id
      name
    }
  }
`
