import { styleSheetFactory } from '../../Themes/Colors'

const styles = styleSheetFactory(colors => ({
  wrapper: {
    position: 'relative',
  },
  input: {
    height: 58,
  },
  inputContainer: (isFocused = false) => {
    return {
      backgroundColor: 'white',
      borderRadius: 7,
      borderTopLeftRadius: 7,
      borderTopRightRadius: 7,
      borderWidth: 1,
      borderColor: isFocused ? colors.secondaryAccent : colors.separator,
    }
  },
  label: {
    fontSize: 18,
    fontFamily: 'Vodafone Lt',
  },
  title: {
    fontSize: 16,
    fontFamily: 'Vodafone Rg',
  },
  prefix: {
    marginRight: -7,
  },
  prefixIos: {
    marginRight: -7,
    marginBottom: 0,
  },
  affix: {
    fontSize: 16,
    fontFamily: 'Vodafone Rg',
    marginBottom: 6,
  },
  inputStyle: (editable) => ({
    fontFamily: 'Vodafone Rg',
    // marginRight: editable ? 50 : 0,
    marginRight: 50,
  }),
  selectContact: {
    position: 'absolute',
    right: 2,
    top: 7,
    bottom: 5,
    width: 60,
    borderRadius: 7,
    justifyContent: 'center',
    alignItems: 'center',
  },
  inputCloseIcon: {
    position: 'absolute',
    right: 14,
    top: 14,
    width: 32,
    height: 32,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 32 / 2,
    backgroundColor: colors.lightGray,
  },
}))

export default styles
