import React from 'react'
import { ScrollView } from 'react-native'
import { useTheme } from 'react-native-themed-styles'
import PropTypes from 'prop-types'

import { Section } from '../../../Components'

import themedStyles from '../SupportiveInvoiceDetails.Styles'

const idTypes = {
    NUIS: 'NIPT',
    ID: 'supporting_invoice_issuerCarrier_id_personal',
}

function IssuerCarrier({ issuer, carrier, loading }) {
    const [styles] = useTheme(themedStyles)

    const issuerData = [
        {
            title: 'certificate_issuer_label',
        },
        {
            label: 'einvoice_details_nipt_label',
            value: issuer?.nuis,
        },
        {
            label: 'einvoice_details_name_label',
            value: issuer?.name,
        },
        {
            label: 'einvoice_details_address_label',
            value: issuer?.address,
        },
        {
            label: 'einvoice_delivery_city',
            value: issuer?.city,
        },
    ]

    const carrierData = [
        {
            title: 'supporting_invoice_issuerCarrier_header',
        },
        {
            label: idTypes[carrier?.idType],
            value: carrier?.nuis,
        },
        {
            label: 'einvoice_details_name_label',
            value: carrier?.name,
        },
        {
            label: 'einvoice_details_address_label',
            value: carrier?.address,
        },
        {
            label: 'einvoice_delivery_city',
            value: carrier?.city,
        },
    ]

    return (
        <ScrollView
        style={styles.container}
        contentContainerStyle={styles.content}
        showsVerticalScrollIndicator={false}
    >
        <Section data={issuerData} />
        <Section data={carrierData} />

    </ScrollView>
    )
}

IssuerCarrier.propTypes = {
    issuer: PropTypes.object,
    carrier: PropTypes.object,
    loading: PropTypes.bool,
}

export default IssuerCarrier
