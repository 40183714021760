import { gql } from '@apollo/client'

export const LIST_TRANSACTIONS_HISTORY = gql`
    query transactionHistory(
      $locationId: String!, 
      $nextToken: String, 
      $limit: Int,
      $employeeId: String 
      $cashRegisterId: String
      ){
      transactionsHistory(
            locationId: $locationId, 
            nextToken: $nextToken, 
            limit: $limit, 
            employeeId: $employeeId
            requestedLocationAndCashRegister: {
                locationId: $locationId,
                cashRegisterId: $cashRegisterId
              }
            ){
            nextToken
            items{
                type
                transaction{
                    id
                    receiptNumber
                    employee
                    createdAt
                    tender{
                        createdAt
                        amountMoney {
                          amount
                          currency
                        }
                        cashDetails{
                            buyerTenderedMoney{
                            amount
                            currency
                            }
                            changeBackMoney{
                                amount
                                currency
                            }
                      }
                    }
                }
                refund{
                    id
                    transactionId
                    reason
                    createdAt
                    employee
                    receiptNumber
                    amountMoney {
                        amount
                        currency
                    }
                    transaction{
                        receiptNumber
                    }
                }
            }
        }
    }
`

export const LIST_TRANSACTIONS = gql`
    query listTransactions(
      $locationId: String!, 
      $nextToken: String, 
      $limit: Int,
      $employeeId: String,
      $cashRegisterId: String
      ){
        listTransactions(
            locationId: $locationId, 
            nextToken: $nextToken, 
            limit: $limit, 
            employeeId: $employeeId,
            requestedLocationAndCashRegister: {
                locationId: $locationId,
                cashRegisterId: $cashRegisterId
              }
            ){
            nextToken
            transactions{
                id
                receiptNumber
                createdAt
                tender{
                    amountMoney{
                        amount
                        currency
                    }
                }

            }
        }
    }
`

export const LIST_REFUNDS = gql`
    query listRefunds(
      $locationId: String!, 
      $nextToken: String, 
      $limit: Int,
      $employeeId: String,
      $locId: String,
      $cashRegisterId: String
      ){
        listRefunds(
            locationId: $locationId, 
            nextToken: $nextToken, 
            limit: $limit, 
            employeeId: $employeeId,
            requestedLocationAndCashRegister: {
                locationId: $locId,
                cashRegisterId: $cashRegisterId
              }
            ){
            nextToken
            refunds{
                id
                receiptNumber
                createdAt
                transactionId
                amountMoney{
                    amount
                    currency
                }
            }
        }
    }
`

export const LIST_CASH_DRAWER_SHIFTS_EVENTS = gql`
    query listEvents($locationId: String!, $cursor: String, $shiftId: String!, $limit: Int, $eventType: EventType, $employeeId: String, $cashRegisterId: String){
        listCashDrawerShiftEvents(locationId: $locationId, cursor: $cursor, shiftId: $shiftId, limit: $limit, eventType: $eventType, employeeId: $employeeId,cashRegisterId:$cashRegisterId){
            cursor
            events{
                id
                FCDC
                createdAt
                description
                externalId
                eventType
                eventMoney{
                    amount
                    currency
                }
            }
        }
   }
`

export const LIST_CASH_DRAWER_SHIFTS = gql`
query list_CashDrawerShifts($locationId: String!, $beginTime: String, $state: CashDrawerShiftState, $endTime: String, $cursor: String, $limit: Int,  $employeeId: String, $locId: String, $cashRegisterId: String){
  listCashDrawerShifts(
    locationId: $locationId,
    beginTime: $beginTime,
    state: $state,
    endTime: $endTime,
    cursor: $cursor, 
    limit: $limit, 
    employeeId: $employeeId
    requestedLocationAndCashRegister: {
        locationId: $locId,
        cashRegisterId: $cashRegisterId
      }
    ){
    cursor
    items{
      id
      state
      openedAt
      FCDC
      endedAt
      cashRegisterId
    }
  }
}
`
