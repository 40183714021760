import React, { useContext, useState } from 'react'
import {
    LayoutAnimation,
    ScrollView,
    View,
    TouchableOpacity,
} from 'react-native'
import { useTheme } from 'react-native-themed-styles'
import PropTypes from 'prop-types'
import moment from 'moment'
import { useMutation } from '@apollo/client'
import { useDispatch, useSelector } from 'react-redux'

import {
    Text,
    Button,
    Icon,
    InputWithTags,
    InputWithIcon,
    DatePickerModal,
} from '../../../../Components'

import Step from '../Step'

import { updateStepKey, updateCalculatedUBL, updatePaymentAttribute } from '../../Payment.Actions'
import { getDelivery, getEinvoiceCalculated, getInvoiceId } from '../../Payment.Selectors'
import { SAVE_EINVOICE } from '../../Payment.Schema'

import Theme, { opacityConfig } from '../../../../Themes/Theme'
import images from '../../../../Themes/Images'

import { useConfig } from '../../../../Utils'

import themedStyles, { inputStyles } from './FifthStep.Styles'

function TouchableInput({
    onPress,
    label,
    value,
    setValue,
    icon,
    iconSize,
    ...rest
}) {
    const [styles] = useTheme(inputStyles)

    return (
        <TouchableOpacity
            style={styles.touchableInput}
            onPress={onPress}
        >
            <InputWithIcon
                label={label}
                value={value}
                onChangeText={setValue}
                icon={icon}
                iconSize={iconSize}
                labelOffset={{
                    x0: 0,
                    y0: -7,
                    x1: 0,
                    y1: 2,
                }}
                {...rest}
            />
        </TouchableOpacity>
    )
}
TouchableInput.defaultProps = {
    iconSize: 24,
}
TouchableInput.propTypes = {
    icon: PropTypes.any,
    onPress: PropTypes.func,
    label: PropTypes.string,
    value: PropTypes.string,
    setValue: PropTypes.func,
    // icon: ImagePropTypes,
    iconSize: PropTypes.number,
}

function FifthStep({
    goBack,
    stepperLength,
    index,
    addListener,
    dispatch,
}) {

    const { switchLocation: { deviceId, locationId } } = useConfig()

    const [styles] = useTheme(themedStyles)
    const { colors } = useContext(Theme)

    const [saveEinvoice, { loading }] = useMutation(SAVE_EINVOICE)

    const dispatchAction = useDispatch()
    const updateDelivery = (key, value) => {
        dispatchAction(updateStepKey('delivery', key, value))
    }
    const setPaymentStatus = (value) => dispatchAction(updatePaymentAttribute('paymentStatus', value))

    const deliveryData = useSelector(getDelivery)
    const calculatedUBL = useSelector(getEinvoiceCalculated)
    const einvoiceId = useSelector(getInvoiceId)

    const [date, setDate] = useState({
        date: deliveryData.actualDeliveryDate,
        tempDate: '',
        isShown: false,
    })

    const onButtonPress = (step) => {
        LayoutAnimation.configureNext(opacityConfig)
        setPaymentStatus(step)
    }
    const next = () => {
        if (einvoiceId) {
            const newUbl = {
                ...calculatedUBL,
                delivery: {
                    actualDeliveryDate: deliveryData?.actualDeliveryDate
                        ? moment(deliveryData.actualDeliveryDate).format('YYYY-MM-DD') : null,
                    deliveryLocation: {
                        address: {
                            streetName: deliveryData?.streetName || null,
                            cityName: deliveryData?.cityName || null,
                            postalCode: deliveryData?.postalZone || null,
                            countryCode: deliveryData?.countyCode,
                        },
                    },
                    deliveryPartyName: {
                        name: deliveryData?.name || null,
                    },
                },
            }
            dispatchAction(updateCalculatedUBL(newUbl))
            saveEinvoice({
                variables: {
                    locId: locationId,
                    cashRegisterId: deviceId,
                    eInvoiceInput: {
                        ublInvoice: newUbl,
                    },
                },
            })
                .then(() => {
                    setPaymentStatus('notes')
                })
        }
    }

    return (
        <Step
            title="header_delivery_einvoice"
            goBack={goBack}
            stepperLength={stepperLength}
            index={index}
            addListener={addListener}
            dispatch={dispatch}
        >
            <ScrollView
                contentContainerStyle={styles.fillRemaining}
                showsVerticalScrollIndicator={false}
            >
                <View>
                    <Text i18nKey="einvoice_delivery_delivery_header" weight="bold" style={styles.sectionText} />
                    <InputWithTags
                        label="einvoice_delivery_recipient_name"
                        value={deliveryData?.name}
                        onChangeText={text => updateDelivery('name', text)}
                        autoCompleteType="off"
                        inputWrapperStyle={styles.input}
                    />
                    <TouchableInput
                        label="einvoice_delivery_date"
                        icon={images.calendarBlack}
                        autoCorrect={false}
                        autoCapitalize="none"
                        editable={false}
                        value={date?.date ? moment(date?.date).format('DD/MM/YYYY') : ''}
                        onPress={() => setDate(prev => ({
                            ...prev,
                            isShown: true,
                            tempDate: date?.date,
                        }))}
                    />
                </View>
                <View>
                    <Text i18nKey="einvoice_delivery_address_header" weight="bold" style={styles.sectionText} />
                    <InputWithTags
                        label="einvoice_delivery_street"
                        value={deliveryData?.streetName}
                        onChangeText={text => updateDelivery('streetName', text)}
                        autoCompleteType="off"
                        inputWrapperStyle={styles.input}
                    />
                    <InputWithTags
                        label="einvoice_delivery_city"
                        value={deliveryData?.cityName}
                        onChangeText={text => updateDelivery('cityName', text)}
                        autoCompleteType="off"
                        inputWrapperStyle={styles.input}
                    />
                    <InputWithTags
                        label="einvoice_delivery_postal_code"
                        value={deliveryData?.postalZone}
                        onChangeText={text => updateDelivery('postalZone', text)}
                        autoCompleteType="off"
                        inputWrapperStyle={styles.input}
                    />
                    <InputWithTags
                        label="einvoice_delivery_state"
                        value={deliveryData?.countyCode}
                        onChangeText={text => updateDelivery('countyCode', text)}
                        autoCompleteType="off"
                        inputWrapperStyle={styles.input}
                        disabled
                    />
                </View>
            </ScrollView>
            <View style={styles.buttons}>
                <Button
                    title="einvoice_delivery_reference_button"
                    variant={'preActive'}
                    icon={images.arrowLeft}
                    iconColor={colors.accent}
                    style={styles.rightButton}
                    onPress={() => onButtonPress('references')}
                />
                <Button
                    title="einvoice_delivery_notes_button"
                    variant={loading ? 'disabled' : 'active'}
                    rightComponent={!loading && <Icon
                        size={20}
                        source={images.arrowRight}
                        color={colors.white}
                        style={styles.leftSpacing}
                    />}
                    onPress={next}
                    loader={loading}
                    style={styles.leftButton}
                />
            </View>
            <DatePickerModal
                onClose={() => setDate(prev => ({
                    ...prev,
                    isShown: false,
                }))}
                isVisible={date.isShown}
                onDateChange={(pickedDate) => setDate(prev => ({
                    ...prev,
                    tempDate: pickedDate,
                }))}
                preselectedDate={date?.tempDate === '' ? moment().format('YYYY-MM-DD') : moment(date?.tempDate).format('YYYY-MM-DD')}
                onButtonPress={() => {
                    updateDelivery('actualDeliveryDate', date?.tempDate)
                    setDate(prev => ({
                        ...prev,
                        date: prev.tempDate,
                        isShown: false,
                    }))
                }
                }
            />
        </Step>
    )
}

FifthStep.propTypes = {
    goBack: PropTypes.func,
    stepperLength: PropTypes.number,
    index: PropTypes.number,
    addListener: PropTypes.func,
    dispatch: PropTypes.func,
}

export default FifthStep

