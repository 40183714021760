/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { SafeAreaView, View, LayoutAnimation, TouchableOpacity, Dimensions } from 'react-native'
import { useLazyQuery } from '@apollo/client'
import { useTheme } from 'react-native-themed-styles'
import ContentLoader, { Rect } from 'react-content-loader/native'
import PropTypes from 'prop-types'
import { SwipeListView } from 'react-native-swipe-list-view'

import { useConfig, useUserDetails } from '../../../../Utils'
import { LIST_CASH_REGISTERS } from '../../../CashRegister/CashRegister.Schema'
import { Text } from '../../../../Components'
import { layoutAnimConfig } from '../../../../Themes/Theme'
import { colors } from '../../../../Themes'

import themedStyles, { itemStyles } from './CashRegisters.Styles'

const { width } = Dimensions.get('screen')
function CashPlaceholder({ isFirst, isLast }) {
  const [styles] = useTheme(itemStyles)

  return (
    <TouchableOpacity
      style={[
        styles.itemContainer,
        styles['firstItem_' + isFirst],
        styles['lastItem_' + isLast],
      ]}
      disabled>
      <ContentLoader
        speed={2}
        width={width - 30}
        height={60}
        backgroundColor="#f3f3f3"
        foregroundColor="#fff">
        <Rect
          x="15"
          y="10"
          rx="6"
          ry="6"
          width={`${(width - 30) * (3 / 5)}`}
          height="18"
        />
        <Rect x="15" y="32" rx="6" ry="6" width="60" height="18" />
        {/* <Rect x={`${width - 63}`} y="21" rx="3" ry="3" width="18" height="18" /> */}
      </ContentLoader>
    </TouchableOpacity>
  )
}
CashPlaceholder.propTypes = {
  isFirst: PropTypes.bool,
  isLast: PropTypes.bool,
}

function Item({ item, isFirst, isLast, isCurrent }) {
  const [styles] = useTheme(itemStyles)

  return (
    <View
      style={[
        styles.listItem,
        styles['firstItem_' + isFirst],
        styles['lastItem_' + isLast],
      ]}>
      <View>
        <Text i18nKey={item?.name} />
        <Text i18nKey={item?.deviceName} size="footnote" />
        {isCurrent && <Text i18nKey={'cash_register_current_device'} size="footnote" color={colors.secondaryAccent} />}
      </View>
    </View>
  )
}
Item.propTypes = {
  item: PropTypes.any,
  isFirst: PropTypes.bool,
  isLast: PropTypes.bool,
  isCurrent: PropTypes.bool,
}

function CashRegisters({
  id,
}) {
  const { switchLocation: { deviceId, locationId } } = useConfig()

  const { id: userId } = useUserDetails()
  const [styles] = useTheme(themedStyles)
  const [devices, setDevices] = useState([])
  const [loading1, setLoading] = useState(true)
  const [currentDevice, setCurrentDevice] = useState({})

  const [
    listCashRegisters,
    { loading, data },
  ] = useLazyQuery(LIST_CASH_REGISTERS, {
    fetchPolicy: 'network-only',
  })

  useEffect(() => {
    if (id) {
      listCashRegisters({
        variables: {
          locId: locationId,
          cashRegisterId: deviceId,
          limit: 20,
          filter: {
            name: 'employee',
            value: id,
          },
        },
      })
    } else {
      setLoading(false)
    }
  }, [id])

  useEffect(() => {
    const cashRegisters = (data?.listCashRegisters?.cashRegisters || [])?.filter(el => Boolean(el))
    if (cashRegisters) {
      LayoutAnimation.configureNext(layoutAnimConfig)
      setDevices(cashRegisters)
      const device = cashRegisters?.find(el => el?.id === deviceId)
      setCurrentDevice(device)
      setLoading(false)
    }
  }, [data])

  const renderItem = ({ item, index }) => {
    return (
      <Item
        id={item?.id}
        item={item}
        selected={item?.status}
        onPress={() => { }}
        isFirst={index === 0}
        isLast={index === devices?.length - 1}
        isCurrent={(currentDevice?.id === item?.id) && id === userId}
      />
    )
  }

  return (
    <SafeAreaView style={styles.container}>
      <SwipeListView
        data={devices}
        renderItem={renderItem}
        disableRightSwipe
        rightOpenValue={-100}
        contentContainerStyle={styles.contentContainerStyle}
        showsVerticalScrollIndicator={false}
        style={styles.listStyle}
        // onRowClose={() => setNeedForConfirmation(true)}
        ListEmptyComponent={
          loading1 ? null : <Text
            i18nKey="users_no_cash_register"
            style={styles.topSpacing}
            align="center"
          />
        }
        ListHeaderComponent={
          devices?.length > 0 ? (
            <Text
              i18nKey="location_section_cash_register"
              align="left"
              style={styles.header}
            />
          ) : null
        }
        ListFooterComponent={
          loading && (
            <View style={styles.topSpacing}>
              <CashPlaceholder isFirst />
              <CashPlaceholder isLast />
            </View>
          )
        }
        ItemSeparatorComponent={() => <View style={styles.separator} />}
        refreshing={false}
      // onEndReachedThreshold={0.5}
      // onEndReached={onEndReached}
      />
    </SafeAreaView>
  )
}
CashRegisters.propTypes = {
  id: PropTypes.string,
  firstName: PropTypes.string,
  setFirstName: PropTypes.func,
  lastName: PropTypes.string,
  setLastName: PropTypes.func,
  selected: PropTypes.string,
  setSelected: PropTypes.func,
  navigation: PropTypes.object,
  goBack: PropTypes.func,
}

export default CashRegisters
