const getCurrency = state => (state.checkoutData.currency || {})
const getOrder = state => (state.checkoutData || {})
const getTotal = state => (state.checkoutData.order?.totalMoney?.amount || 0)
const getCheckout = state => (state.checkoutData || {})

export {
  getCurrency,
  getOrder,
  getCheckout,
  getTotal,
}
