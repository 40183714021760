import { styleSheetFactory } from '../../../../Themes/Colors'

const styles = styleSheetFactory(colors => ({
  scrollContainer: {
    flex: 1,
  },
  container: {
    flexGrow: 1,
    paddingHorizontal: 15,
    paddingBottom: 5,
  },
  contentContainer: {
    padding: 15,
    paddingBottom: 64,
  },
  sectionTitle: {
    marginTop: 20,
    marginBottom: 10,
  },
  sectionTitleContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 20,
    marginBottom: 10,
  },
  addNew: {
    height: 36,
    paddingHorizontal: 16,
  },
  scanner: {
    marginTop: 10,
    marginHorizontal: 0,
  },
  priceItem: {
    height: 60,
    backgroundColor: colors.tertiaryBackground,
    justifyContent: 'center',
    overflow: 'hidden',
  },
  firstItem_true: {
    borderTopLeftRadius: 7,
    borderTopRightRadius: 7,
  },
  lastItem_true: {
    borderBottomLeftRadius: 7,
    borderBottomRightRadius: 7,
  },
  header: { marginTop: 10 },
  verticalSpacing: { marginVertical: 15 },
  smallVerticalSpacing: { marginVertical: 5 },
  descriptionInput: {
    minHeight: 60,
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
}))

const variationStyles = styleSheetFactory(colors => ({
  container: {
    height: 60,
    backgroundColor: colors.tertiaryBackground,
    justifyContent: 'center',
    overflow: 'hidden',
  },
  firstItem_true: {
    borderTopLeftRadius: 7,
    borderTopRightRadius: 7,
  },
  lastItem_true: {
    borderBottomLeftRadius: 7,
    borderBottomRightRadius: 7,
  },
  touchable: {
    flexGrow: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  title: {
    marginLeft: 15,
    flexGrow: 1,
    flexShrink: 1,
  },
  desc: {
    marginHorizontal: 15,
    flexShrink: 0,
  },
}))

const hiddenItemStyles = styleSheetFactory(colors => ({
  wrapper: {
    backgroundColor: colors.accent,
    flex: 1,
    justifyContent: 'center',
    alignItems: 'flex-end',
  },
  firstItem_true: {
    borderTopLeftRadius: 7.5,
    borderTopRightRadius: 7.5,
  },
  lastItem_true: {
    borderBottomLeftRadius: 7.5,
    borderBottomRightRadius: 7.5,
  },
  option: {
    width: 100,
    height: 60,
    justifyContent: 'center',
    alignItems: 'center',
  },
  optionFull: {
    flexDirection: 'row',
  },
  confirm: {
    flexGrow: 1,
    height: 60,
    flexDirection: 'row',
    paddingLeft: 15,
    alignItems: 'center',
  },
  confirmText: {
    marginLeft: 15,
  },
  cancel: {
    width: 40,
    height: 40,
    flexShrink: 0,
    marginVertical: 10,
    marginHorizontal: 15,
    borderRadius: 20,
    backgroundColor: colors.whiteRGBA(0.55),
    justifyContent: 'center',
    alignItems: 'center',
  },
  loader: {
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
}))


const separatorStyles = styleSheetFactory(colors => ({
  container: {
    backgroundColor: colors.separator,
    height: 1,
  },
}))

export {
  variationStyles,
  hiddenItemStyles,
  separatorStyles,
}
export default styles

