import { styleSheetFactory } from '../../Themes/Colors'

const styles = styleSheetFactory((colors) => ({
    container: {
        backgroundColor: colors.background,
        flexGrow: 1,
        flex:1,
    },
    contentWrapper: {
        paddingHorizontal: 15,
    },
    textContainer: {
        flexGrow: 1,
        flexDirection: 'column',
        justifyContent: 'center',
        marginRight: 15,
    },
    rightTextContainer: {
        flexDirection: 'column',
        alignItems: 'flex-end',
        marginLeft: 7.5,
        flexShrink: 1,
    },
    inputContainer: {
        minHeight: 60,
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
    },
    itemSpacing: {
        marginVertical: 5,
    },
    saveButton: { height: 50, marginTop: 10, marginBottom: 30 },
    touchableContainer: {
        backgroundColor: colors.tertiaryBackground,
        overflow: 'hidden',
        borderRadius: 7,
        marginBottom: 10,
        minHeight: 50,
        justifyContent: 'center',
    },
    rightContainer: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    addCashRegister: { marginBottom: 10, marginTop: 5 },
    separator: {
        height: 1,
        backgroundColor: colors.separator,
    },
    contentContainer: {
        backgroundColor: colors.background,
        paddingBottom: 0,
        paddingHorizontal: 0,
        flexGrow: 1,
    },
    operatorCodeHelper: {
        padding: 7,
        marginTop: 5,
        marginBottom: 10,
        textAlign: 'left',
        fontFamily: 'Vodafone Rg',
        fontSize: 14,
        color: colors.primaryText,
    },
    operatorCodeHelperItalic: {
        fontStyle: 'italic',
    },
    buttonContainer: {
        marginTop: 15,
        marginHorizontal: 15,
    },
    loadingWrapper: {
        // height: 250,
    },
    loaderContainer: {
        backgroundColor: colors.white,
        borderBottomColor: colors.separator,
        borderBottomWidth: 1,
        height: 60,
    },
    scrollView: { flex: 1 },
}))

export default styles
