/* eslint-disable react-hooks/exhaustive-deps */
import React, {useRef, useEffect} from 'react'
import {Animated, TouchableOpacity, Easing} from 'react-native'
import {useTheme} from 'react-native-themed-styles'
import PropTypes from 'prop-types'

import themedStyles from './Switch.Styles'

function Switch({
  value,
  isLoading,
  containerStyle,
  disabled,
  thumbStyle,
  onPress,
}) {
  const [styles] = useTheme(themedStyles)

  const anim = useRef(new Animated.Value(0)).current
  const scaleVal = useRef(new Animated.Value(0)).current

  const loop = () => {
    Animated.loop(
      Animated.sequence([
        Animated.timing(scaleVal, {
          toValue: 0.8,
          isInteraction: false,
          easing: Easing.inOut(Easing.linear),
          useNativeDriver: true,
        }),
        Animated.timing(scaleVal, {
          toValue: 1,
          isInteraction: false,
          easing: Easing.inOut(Easing.linear),
          useNativeDriver: true,
        }),
      ]),
    ).start()
  }

  const scale = () => {
    Animated.timing(scaleVal, {
      toValue: 1,
      isInteraction: false,
      useNativeDriver: true,
    }).start()
  }

  const animate = () => {
    const toValue = value ? 1 : 0
    Animated.spring(anim, {
      toValue,
      useNativeDriver: true,
    }).start()
  }

  useEffect(() => {
    animate()
  }, [value])

  useEffect(() => {
    if (isLoading) {
      loop()
    } else {
      scaleVal.stopAnimation()
      scale()
    }
  }, [isLoading])

  return (
    <TouchableOpacity
      activeOpacity={0.5}
      onPress={onPress}
      disabled={disabled}
      style={styles.touchable}>
      <Animated.View
        style={[
          styles.container,
          styles['containerEnabled_' + value],
          containerStyle,
        ]}>
        <Animated.View
          style={[
            styles.thumb,
            {
              transform: [
                {
                  translateX: anim.interpolate({
                    inputRange: [0, 1],
                    outputRange: [2, 16],
                  }),
                },
                {
                  scale: scaleVal,
                },
              ],
            },
            thumbStyle,
          ]}
        />
      </Animated.View>
    </TouchableOpacity>
  )
}

Switch.propTypes = {
  value: PropTypes.bool,
  containerStyle: PropTypes.object,
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  thumbStyle: PropTypes.object,
  onPress: PropTypes.func,
}

export default Switch
