import React, { useContext } from 'react'
import { SafeAreaView, View, Image, Linking } from 'react-native'
import PropTypes from 'prop-types'
import { useTheme } from 'react-native-themed-styles'

import { Text, Button } from '../../Components'

import Theme from '../../Themes/Theme'

import themedStyles from './InitialScreen.Styles'

import images from '../../Themes/Images'

function InitialScreen({ navigation }) {
  const [styles] = useTheme(themedStyles)
  const { colors } = useContext(Theme)

  return (
    <SafeAreaView style={styles.container}>
      <View style={styles.header}>
        <Image
          source={images.splash}
          style={styles.splashImage}
        />
      </View>
      <Text i18nKey="app_title" weight="bold" align="center" style={styles.bottomSelfSpacing} />
      <Text i18nKey="app_description" align="center" style={styles.horizontalSelfSpacing} />
      <Button
        title="app_learn_more"
        borderColor={colors.transparent}
        backgroundColor={colors.transparent}
        titleStyle={{ color: colors.accent }}
        onPress={() => Linking.openURL('https://www.vodafone.al/biznesi/produkte-dhe-zgjidhje-biznesi/zgjidhje-biznesi/e-biznes-vodafone-biznes/')}
      />
      <View style={styles.footer}>
        <Button
          title="header_sign_in"
          borderColor={colors.transparent}
          backgroundColor={colors.accent}
          style={styles.button}
          onPress={() => navigation.navigate('Authenticator')}
        />
        {/* <View style={styles.orContainer}>
          <View style={styles.separator} />
          <Text
            style={styles.orText}
            i18nKey="certificate_or_label"
            size="footnote"
            weight="light"
          />
        </View> */}

        {/* <Button
          title="demo_request_button"
          borderColor={colors.transparent}
          backgroundColor={colors.transparent}
          titleStyle={{ color: colors.secondaryAccent }}
          style={styles.demoButton}
          onPress={() => navigation.navigate('WelcomeDemo')}
        /> */}
      </View>
    </SafeAreaView>
  )
}
InitialScreen.propTypes = {
  navigation: PropTypes.object,
}

export default InitialScreen
