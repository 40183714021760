import React, { useState } from 'react'
import { SafeAreaView, View, TextInput, Image, TouchableOpacity, ActivityIndicator } from 'react-native'

import PropTypes from 'prop-types'

import ItemsList from '../ItemsList'
import Button from '../Button'
import Text from '../Text'
import Options from '../Options'


import Styles from './ListContainer.Styles'
import Colors from '../../Themes/Colors'
import Images from '../../Themes/Images'
import { colors } from '../../Themes'


function ListContainer(props) {

  const {
    onSearch,
    items,
    loading,
    onDelete,
    onCreate,
    onPress,
    onRefresh,
    onCreateButtonPress,
    createButtonLabel,
    showSearch,
    showScanner,
    showCreateButton,
    searchPlaceholder,
    useDivider,
    listItemStyle,
    swipeRight,
    onEndReached,
    menu,
    onScanPress,
    searchBarcode,
    removeFilter,
    sortOptions,
    noItemsLabel,
    buttonBackground,
    listContainerStyle,
  } = props

  const deleteItem = (item) => {
    onDelete(item)
  }

  const [searchValue, setSearchValue] = useState('')

  const onSearchText = (text) => {
    setSearchValue(text)
    if ((text.length > 2 || text.length === 0) && !!onSearch) {
      onSearch(text)
    }
  }

  const onSearchClean = () => {
    setSearchValue('')
    onSearch('')
  }

  return (
    <>
      <SafeAreaView style={Styles.container}>
        {showSearch &&
          <View style={Styles.searchContainer}>
            <View style={Styles.inputContainer}>
              <View style={Styles.searchIconContainer}>
                <Image source={Images.search} style={Styles.icon} />
              </View>
              <TextInput
                placeholder={searchPlaceholder}
                placeholderTextColor={Colors.placeholder}
                style={Styles.searchInput}
                value={searchValue}
                onChangeText={(text) => { onSearchText(text) }}
              />
              {searchValue !== '' &&
                <TouchableOpacity onPress={onSearchClean} style={Styles.searchIconContainer}>
                  <Image source={Images.close} style={Styles.icon} />
                </TouchableOpacity>
              }
            </View>
            {showScanner &&
              <TouchableOpacity onPress={onScanPress} style={Styles.scanContainer}>
                <Image source={Images.scan} style={Styles.scanIcon} />
              </TouchableOpacity>
            }

            {sortOptions && <Options items={sortOptions} title="orders_options_title" />}

          </View>
        }

        {searchBarcode !== '' && searchBarcode &&
          <View style={Styles.filterContainer}>
            <Text style={{}} i18nKey={searchBarcode} />
            <TouchableOpacity onPress={removeFilter}>
              <Image source={Images.close} style={Styles.filterRemove} />
            </TouchableOpacity>
          </View>
        }

        <View style={[Styles.listContainer, listContainerStyle]}>
          {showCreateButton &&
            <Button
              icon={Images.plus}
              iconStyle={Styles.plusIcon}
              title={createButtonLabel}
              titleStyle={Styles.buttonTitle}
              style={Styles.button}
              backgroundColor={buttonBackground || Colors.accent}
              borderWidth={0}
              borderColor={Colors.transparent}
              color={Colors.white}
              onPress={onCreateButtonPress}
              isActive
            />
          }


          {items.length === 0 && !loading && noItemsLabel && <Text color={colors.secondaryText} align="center" i18nKey={noItemsLabel} />}

          {/* {loading || items.length > 0 ? */}
          <ItemsList
            menu={menu}
            items={items}
            listItemStyle={listItemStyle}
            useDivider={useDivider}
            loading={loading}
            onDelete={item => deleteItem(item)}
            onCreate={onCreate}
            onRefresh={onRefresh}
            swipeLeft={swipeRight}
            swipeRight={false}
            onEndReached={onEndReached}
            onPress={(item) => onPress(item)}
          />

          {items.length !== 0 && loading && <ActivityIndicator />}
        </View>
      </SafeAreaView>
    </>
  )
}

ListContainer.defaultProps = {
  showSearch: true,
  showCreateButton: true,
  showScanner: false,
  searchPlaceholder: 'Search for items...',
  createButtonLabel: 'Create Item',
}

ListContainer.propTypes = {
  menu: PropTypes.bool,
  onSearch: PropTypes.func,
  items: PropTypes.array,
  loading: PropTypes.bool,
  onDelete: PropTypes.func,
  onCreate: PropTypes.func,
  onPress: PropTypes.func,
  onRefresh: PropTypes.func,
  onCreateButtonPress: PropTypes.func,
  createButtonLabel: PropTypes.string,
  showSearch: PropTypes.bool,
  showScanner: PropTypes.bool,
  showCreateButton: PropTypes.bool,
  searchPlaceholder: PropTypes.string,
  useDivider: PropTypes.bool,
  listItemStyle: PropTypes.object,
  swipeRight: PropTypes.bool,
  onEndReached: PropTypes.func,
  onScanPress: PropTypes.func,
  searchBarcode: PropTypes.string,
  removeFilter: PropTypes.func,
  sortOptions: PropTypes.array,
  noItemsLabel: PropTypes.string,
  buttonBackground: PropTypes.string,
  listContainerStyle: PropTypes.object,
}

export default ListContainer
