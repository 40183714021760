import {
  ON_BOARDING_RESET_STATE,
  ON_BOARDING_UPDATE_STEP,
  ON_BOARDING_UPDATE_STEP_KEY,
} from './OnBoarding.Actions'

const OnBoardingInitialState = {
  intro: {
    completed: false,
  },
  certificateFirst: {
    nipt: '',
    email: '',
    phone: '',
    base64: '',
    password: '',
    attached: false,
    hasCertificate: false,
  },
  certificateSecond: {
    code: '',
  },
  cashRegister: {
    name: '',
    location: '',
    businessUnitCode: '',
    deviceData: {
      TCRCode: '',
      deviceId: '',
      deviceName: '',
      id: '',
      locationId: '',
      model: '',
      name: '',
      os: '',
      requestUserEmail: '',
      isTablet: false,
    },
    tcr: '',
    employees: [],
  },
}

function onBoardingData(
  state = OnBoardingInitialState,
  action,
) {
  const { type, step, key, value } = action || {}
  switch (type) {
    case ON_BOARDING_UPDATE_STEP:
      return {
        ...state,
        [step]: value,
      }

    case ON_BOARDING_UPDATE_STEP_KEY:
      return {
        ...state,
        [step]: {
          ...(state[step] || {}),
          [key]: value,
        },
      }

    case ON_BOARDING_RESET_STATE:
      return {
        ...OnBoardingInitialState,
      }

    default:
      return state
  }
}

export default onBoardingData
