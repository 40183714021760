/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useContext, useEffect } from 'react'
import { Platform, KeyboardAvoidingView, View } from 'react-native'
import DocumentPicker from 'react-native-document-picker'
import PropTypes from 'prop-types'
import RNFS from 'react-native-fs'
import { useTheme } from 'react-native-themed-styles'

import { useMutation } from '@apollo/client'

import { useUserDetails, useLocationDetails } from '../../Utils/AuthDetails'

import {
  Modal,
  Icon,
  Text,
  Button,
  Header,
  ModalContent,
} from '../../Components'

import { getErrorMessage } from '../../Utils'

import {
  CREATE_CERTICIFATE,
  UPDATE_CERTICIFATE,
  SEND_PIN,
  GENERATE_AND_SEND_PIN,
} from './RegisterCertificate.schema'

import Theme from '../../Themes/Theme'

import themedStyles from './RegisterCertificate.Styles'

import images from '../../Themes/Images'

import Information from './InfoInput'
import Confirmation from './Confirmation'

const DOCUMENT_IMPORT_TYPE = Platform.select({
  ios: ['public.data'],
  android: [DocumentPicker.types.allFiles],
})

const REGISTER_STEPS = {
  info: 'info',
  confirm: 'confirm',
}

const CertificateSteps = {
  info: Information,
  confirm: Confirmation,
}

function RegisterCertificate({
  navigation,
  route: {
    params: { onSave = () => { }, data },
  },
}) {
  const [styles] = useTheme(themedStyles)
  const { colors } = useContext(Theme)
  const { phoneNumber, email } = useUserDetails()
  const { NIPT } = useLocationDetails()

  const [certificateStatus, setCertificateStatus] = useState(
    REGISTER_STEPS.info,
  )
  const CertificateStep = CertificateSteps[certificateStatus]

  const [modalContent, setModalContent] = useState({
    isVisible: false,
    title: '',
    icon: '',
    desc: '',
    primaryText: '',
    primaryAction: null,
  })

  const displayModal = (
    title = '',
    icon = '',
    desc = '',
    primaryText = '',
    primaryAction = null,
  ) => {
    setModalContent({
      isVisible: true,
      title: title,
      icon: icon,
      desc: desc,
      primaryText: primaryText,
      primaryAction: primaryAction,
    })
  }

  const dismissModal = () => {
    setModalContent({
      isVisible: false,
      title: '',
      icon: '',
      desc: '',
      primaryText: '',
      primaryAction: null,
    })
  }

  const [nipt, setNipt] = useState(NIPT)
  const [personalId] = useState(NIPT)
  const [userEmail, setEmail] = useState(email)
  const [userPhone, setPhone] = useState(phoneNumber.slice(4))
  const [pass, setPass] = useState('')
  const [show, setShow] = useState(true)
  const [fileName, setFileName] = useState('')
  const [attached, setAttached] = useState(false)
  const [base64File, setBase64File] = useState('')
  const [disabled, setDisabled] = useState(false)
  const [niptError, setNError] = useState(false)
  const [value, setValue] = useState('')

  const passImage = {
    true: images.pass,
    false: images.hiddenPass,
  }

  useEffect(() => {
    if (fileName) {
      setAttached(true)
    }
  }, [fileName])

  const attachfile = async () => {
    try {
      const res = await DocumentPicker.pick({
        type: DOCUMENT_IMPORT_TYPE,
      })
      if (res[0]?.type !== 'application/x-pkcs12' && res[0]?.type !== 'application/octet-stream') {
        displayModal(
          'certificate_error_label',
          images.error,
          'certificate_incorrect_format_description',
        )
      } else {
        RNFS.readFile(decodeURI(res[0]?.uri), 'base64')
          .then((dataa) => {
            setBase64File(dataa)
            setFileName(res[0]?.name)
          })
          .catch(() => displayModal(
            'certificate_error_label',
            images.error,
            'certificate_upload_error',
          ))
      }
    } catch (err) {
      if (DocumentPicker.isCancel(err)) {
      } else {
        throw err
      }
    }
  }

  // function to atach files only on web NOTE:Is not used in react-native
  const attachFileWeb = (acceptedFiles) => {
    if (acceptedFiles.length > 0) {
      // eslint-disable-next-line no-undef
      const reader = new FileReader()
      reader.readAsDataURL(acceptedFiles[0])
      reader.onload = function () {
        setBase64File(reader.result.replace('data:application/x-pkcs12;base64,', ''))
        setFileName(acceptedFiles[0].name)

      }
      reader.onerror = function (error) {
        displayModal(
          'certificate_error_label',
          images.error,
          'certificate_upload_error',
        )
      }
    } else {
      displayModal(
        'certificate_error_label',
        images.error,
        'certificate_incorrect_format_description',
      )
    }

  }

  // const attachfile = async () => {
  //   try {
  //     const res = await DocumentPicker.pick({
  //       type: [DocumentPicker.types.allFiles],
  //     })
  //     setFileName(res.name)
  //     const fs = RNFetchBlob.fs
  //     fs.readFile(res.uri, 'base64').then((dataa) => {
  //       setBase64File(dataa)
  //     })
  //   } catch (err) {
  //     if (DocumentPicker.isCancel(err)) {
  //       // User cancelled the picker, exit any dialogs or menus and move on
  //     } else {
  //       throw err
  //     }
  //   }
  // }

  const checkInputs = () => {
    if (
      nipt === '' ||
      personalId === '' ||
      userEmail === '' ||
      userPhone === '' ||
      fileName === '' ||
      pass === ''
    ) {
      setDisabled(true)
    } else {
      const regex = /^[A-Z]\d{8}[A-Z]$/
      const niptRegex = regex.test(nipt)
      const idRegex = regex.test(personalId)

      if (niptRegex && idRegex) {
        setNError(false)
        setDisabled(false)
      } else {
        if (!niptRegex) {
          setNError(true)
        } else {
          setNError(false)
        }
        setDisabled(true)
      }
    }
  }

  const checkInput = () => {
    if (value.length < 5) {
      setDisabled(true)
    } else {
      setDisabled(false)
    }
  }

  useEffect(() => {
    checkInputs()
  }, [nipt, personalId, userEmail, userPhone, fileName, pass])

  useEffect(() => {
    checkInput()
  }, [value])

  const [sendPin, { }] = useMutation(GENERATE_AND_SEND_PIN)

  const sendCode = () => {
    sendPin({
      variables: {
        id: 'static',
      },
    })
      .then(() => {
        setCertificateStatus(REGISTER_STEPS.confirm)
        setValue('')
      })
      .catch((er) => {
        displayModal(
          'certificate_error_label',
          images.error,
          getErrorMessage(er),
        )
      })
  }

  const [registerCertificate, { }] = useMutation(CREATE_CERTICIFATE)

  const [updateCertificate, { }] = useMutation(UPDATE_CERTICIFATE)

  const newCertificate = () => {
    if (data) {
      updateCertificate({
        variables: {
          confirmationPIN: value,
          issuerNUIS: nipt.toUpperCase(),
          personalId: personalId.toUpperCase(),
          phoneNumber: `355${userPhone}`,
          contactEmail: userEmail,
          certificate: base64File,
          certPassword: pass,
        },
      })
        .then((res) => {
          navigation.goBack()
          onSave()
        })
        .catch((e) => {
          displayModal(
            'certificate_error_label',
            images.error,
            getErrorMessage(e),
            'certificate_button_try_again',
            () => {
              dismissModal()
              setCertificateStatus(REGISTER_STEPS.info)
              setDisabled(false)
            },
          )
        })
    } else {
      registerCertificate({
        variables: {
          confirmationPIN: value,
          issuerNUIS: nipt.toUpperCase(),
          personalId: personalId.toUpperCase(),
          phoneNumber: `355${userPhone}`,
          contactEmail: userEmail,
          certificate: base64File,
          certPassword: pass,
        },
      })
        .then(() => {
          navigation.goBack()
          onSave()
        })
        .catch((e) => {
          displayModal(
            'certificate_error_label',
            images.error,
            getErrorMessage(e),
            'certificate_button_try_again',
            () => {
              dismissModal()
              setCertificateStatus(REGISTER_STEPS.info)
              setDisabled(false)
            },
          )
        })
    }
  }

  const onBackPress = () => {
    if (certificateStatus === 'info') {
      navigation.goBack()
      setDisabled(false)
    } else {
      setCertificateStatus(REGISTER_STEPS.info)
    }
  }

  const onButtonPress = () => {
    if (certificateStatus === 'info') {
      sendCode()
    } else {
      setDisabled(true)
      newCertificate()
    }
  }

  const buttonTitle = {
    info: data ? 'certificate_button_update' : 'certificate_button_register',
    confirm: 'certificate_button_finish',
  }

  return (
    <>
      <Header
        title={data ? 'certificate_update_header' : 'certificate_header_title'}
        image={images.back}
        onPress={onBackPress}
      />
      <KeyboardAvoidingView
        behavior={Platform.select({
          ios: 'padding',
          android: undefined,
        })}
        style={styles.container}>
        <CertificateStep
          data={data}
          userPhone={userPhone}
          setPhone={setPhone}
          sendCode={sendCode}
          nipt={nipt}
          setNipt={setNipt}
          niptError={niptError}
          personalId={personalId}
          userEmail={userEmail}
          setEmail={setEmail}
          attachfile={attachfile}
          attached={attached}
          fileName={fileName}
          setAttached={setAttached}
          setFileName={setFileName}
          pass={pass}
          setPass={setPass}
          show={show}
          passImage={passImage}
          setShow={setShow}
          disabled={disabled}
          certificateStatus={certificateStatus}
          setCertificateStatus={setCertificateStatus}
          resend={sendCode}
          value={value}
          setValue={setValue}
          attachFileWeb={attachFileWeb}
        />
        <View style={styles.selfSpacing}>
          <Button
            title={buttonTitle[certificateStatus]}
            disabled={disabled}
            backgroundColor={disabled ? colors.disabled : colors.accent}
            borderColor={colors.transparent}
            onPress={onButtonPress}
          />
        </View>
      </KeyboardAvoidingView>
      <Modal
        isVisible={modalContent.isVisible}
        onBackButtonPress={() => dismissModal()}
        onBackdropPress={() => dismissModal()}
        animationIn="fadeIn"
        animationOut="fadeOut"
        useNativeDriver>
        <ModalContent onClose={() => dismissModal()}>
          <Icon source={modalContent.icon} size={95} />
          <Text
            i18nKey={modalContent.title}
            align="center"
            size="h4"
            weight="bold"
            style={styles.modalTitle}
          />
          <Text
            i18nKey={modalContent.desc}
            align="center"
            style={styles.modalDesc}
          />
          {modalContent.primaryAction && <Button
            title={modalContent.primaryText}
            backgroundColor={colors.accent}
            borderColor={colors.transparent}
            style={styles.modalPrimaryButton}
            onPress={() => modalContent.primaryAction()}
          />}
        </ModalContent>
      </Modal>
    </>
  )
}
RegisterCertificate.propTypes = {
  navigation: PropTypes.object,
  route: PropTypes.object,
}

export default RegisterCertificate
