import { gql } from '@apollo/client'

export const REQUEST_DELETION = gql`
  mutation requestTermination($terminationReason: String!,$additionalReason: String){
    requestTermination(terminationInput:{
        terminationReason: $terminationReason
        additionalReason: $additionalReason
    }){
     id
    }
  }
`
