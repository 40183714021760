import { styleSheetFactory } from '../../Themes/Colors'

const styles = styleSheetFactory(colors => ({
  container: {
    flex: 1,
    backgroundColor: colors.background,
  },
  scrollContainer: {
    paddingHorizontal: 15,
  },
  newContainer: {
    padding: 15,
  },
  containerWithCenteredContent: {
    flex: 1,
    backgroundColor: colors.background,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 15,
  },
  sectionText: {
    marginBottom: 15,
  },
  inlineButtons: {
    flexDirection: 'row',
    alignItems: 'center',
    marginVertical: 15,
    // justifyContent: 'space-between',
  },
  button: {
    marginVertical: 20,
    height: 50,
  },
  inlineButton: {
    flex: 1,
    minHeight: 60,
    marginRight: 5,
    padding: 5,
  },
  inlineButton1: {
    flex: 1,
    minHeight: 60,
    marginRight: 5,
    marginLeft: 5,
    padding: 5,
  },
  inlineButton2: {
    flex: 1,
    minHeight: 60,
    marginLeft: 5,
    padding: 5,
  },
  buttonTitle: {
    fontSize: 18,
  },
  idStatus: {
    flex: 1,
    marginHorizontal: 15,
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  icon: {
    marginRight: 15,
  },
  rightComponentStyle: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  itemRightLabel: {
    marginRight: 5,
  },
  nextAction: {
    margin: 15,
    marginBottom: 30,
  },
  retryButton: {
    margin: 15,
    marginBottom: 25,
  },
  listFooterWrapper: {
    flexDirection: 'row',
    marginTop: 15,
  },
  listFooterStar: {
    color: 'red',
    marginRight: 5,
  },
  listFooterText: {
    fontSize: 14,
    lineHeight: 22,
  },
  leftComponentWrapper: {
    flexGrow: 1,
    flexShrink: 0,
    flexDirection: 'row',
  },
  leftText: {
    color: '#333333',
    fontSize: 14,
    fontFamily: 'Vodafone Rg',
  },
  listElementStar: {
    color: 'red',
    marginLeft: 5,
  },
  list: { flex: 1 },
}))

export default styles
