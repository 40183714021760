import React, { useState } from 'react'
import { View, TouchableOpacity, LayoutAnimation } from 'react-native'
import { useTheme } from 'react-native-themed-styles'
import PropTypes from 'prop-types'

import {
    Text,
    TouchableInput,
    Switch,
    Type,
    BottomSheetSelector,
} from '../../../Components'

import ContentManager from '../../../Utils/ContentManager'

import { layoutAnimConfig } from '../../../Themes/Theme'
import { images, colors } from '../../../Themes'

import style from './TaxFree.Styles'

const REASONS = [
    {
        code: 'item_tax_type1_title',
        description: 'item_tax_type1_description',
        type: 'TYPE_1',
    },
    {
        code: 'item_tax_type2_title',
        description: 'item_tax_type2_description',
        type: 'TYPE_2',
    },
    {
        code: 'item_tax_type3_title',
        description: 'item_tax_type3_description',
        type: 'TAX_FREE',
    },
    {
        code: 'item_tax_type4_title',
        description: 'item_tax_type4_description',
        type: 'MARGIN_SCHEME',
    },
]

function TaxFree({
    isTaxFree,
    setTaxFree,
    reason,
    setReason,
    disabled,
    showRadio = true,
}) {
    const [styles] = useTheme(style)
    const { translate } = ContentManager

    const [modal, setModal] = useState({
        isVisible: false,
        data: [],
        title: '',
        selected: reason,
        select: () => { },
        Item: Type,
        onModalHide: () => { },
    })

    const openReasonSelector = () => {
        setModal({
            isVisible: true,
            data: REASONS,
            title: 'payment_self_charge_reason_placeholder',
            selected: reason,
            select: setReason,
            searchKey: 'code',
            Item: Type,
        })
    }

    const closeModal = () => {
        setModal(prev => ({ ...prev, isVisible: false }))
    }

    return (
        <View>
            <View style={styles.accessItem}>
                {/* <TouchableOpacity
                    disabled={disabled}
                    onPress={() => {
                        LayoutAnimation.configureNext(layoutAnimConfig)
                        setTaxFree()
                        setReason('')
                    }}
                    style={styles.touchable} activeOpacity={0.5}>
                    <View style={styles.accessTextContainer}>
                        <Text i18nKey="taxes_tax_free_label" weight="bold" numberOfLines={2} style={styles.name} />
                    </View>
                    {showRadio && <View pointerEvents="none" style={styles.radioInput}>
                        <Switch
                            value={isTaxFree}
                            color={colors.secondaryAccent}
                        />
                    </View>}
                </TouchableOpacity> */}
            </View>
            {isTaxFree && <View>
                <Text i18nKey="taxes_tax_free_reason_section" weight="bold" style={styles.sectionTitle} />
                <TouchableInput
                    onPress={() => openReasonSelector()}
                    label="gov_select_reason"
                    value={translate(reason?.code || '')}
                    icon={images.filledDownArrow}
                    iconSize={16}
                    autoCorrect={false}
                    autoCapitalize="none"
                    editable={false}
                    disabled={disabled}
                />
            </View>}
            <BottomSheetSelector
                {...modal}
                searchValue=""
                closeModal={closeModal}
                withSearch={false}
            />
        </View>
    )
}

TaxFree.propTypes = {
    isTaxFree: PropTypes.bool,
    setTaxFree: PropTypes.func,
    reason: PropTypes.object,
    setReason: PropTypes.func,
    disabled: PropTypes.bool,
}

export default TaxFree
