const al = {

  //Receipt app
  invoice_header: 'FATURË TATIMORE',


  //Recipt
  receipt_tax: 'TVSH',
  BUYER: 'Blerës',
  SELLER: 'Shitës',
  einvoice_buyertin_label: 'NIPT i blerësit',
  receipt_foreign_currency_label: 'Totali në ',
  receipt_nipt_label: 'NIPT',
  receipt_preview_items: 'Artikujt',
  receipt_item_price_label: 'Çmimi',
  receipt_total: 'Totali për t\'u paguar',
  receipt_subtotal: 'Nëntotali',
  receipt_discount_line: 'Zbritja',
  receipt_change: 'Kusuri',
  receipt_total_method: 'Mënyra e pagesës',
  receipt_invoice_number: 'Numër fature',
  receipt_created_at: 'Data & Ora',
  payment_supplying_period_label: 'Periudha e furnizimit',
  receipt_fic_label: 'NIVF',
  receipt_iic_label: 'NSLF',
  receipt_operator_code_label: 'Kodi i Operatorit',
  receipt_software_code_label: 'Kodi i Softuerit',
  receipt_generated_by: 'Gjeneruar nga',
  receipt_payment_type_cash: 'Para në dore',
  receipt_payment_type_non_cash: 'Pagesë pa para në dorë',
  receipt_label_type_reverse_charge: 'Auto faturë',
  receipt_label_type_noncash: 'Faturë me bankë \n(Individ)',
  receipt_label_type_export: 'Faturë eksporti',
  receipt_type: 'Faturë tatimore',
  receipt_refund_reason: 'Arsyeja',
  receipt_section_primary_invoice: 'Fatura fillestare',
  receipt_section_primary_iic_label: 'NSLF fillestare',
  receipt_section_primary_fic_label: 'NIVF fillestare',
  receipt_payment_info_label: 'Informacioni i Pagesës',
  receipt_payment_name_label: 'Emri i llogarisë',
  receipt_payment_iban_label: 'IBAN',
  receipt_payment_swift_label: 'SWIFT',
  receipt_payment_description_label: 'Përshkrimi i Pagesës',
  receipt_currency_rate_label: 'Kursi i këmbimit',
  receipt_invoice_type: 'Proçesi i faturimit',
  //supporting invoices
  supporting_invoice_name_label: 'Emri',
  supporting_invoice_address_label: 'Adresa',
  supporting_invoice_list_items_label: 'Lista e Artikujve të Faturës',
  supporting_invoice_amount_label: 'Sasia',
  supporting_invoice_receipt_info: 'Informacione të faturës',
  supporting_invoice_issuer_label: 'Lëshuesi',
  supporting_invoice_carrier_label: 'Transportuesi',
  supporting_invoice_label_type: 'Faturë shoqëruese',
  supporting_invoice_fwtnic: 'FWTNIC',
  supporting_invoice_seller: 'Shitësi',
  supporting_invoice_type: 'Tipi i faturës',
  supporting_invoice_number: 'Numër fature',
  supporting_invoice_transaction_type_label: 'Tipi i transaksionit',
  supporting_invoice_transport_info_label: 'Informacioni i transportit',
  supporting_invoice_transport_owner_label: 'Pronësia e mjetit të transportit',
  supporting_invoice_transport_plate: 'Targa',
  supporting_invoice_preview_dangerous: 'E rrezikshme',
  supporting_invoice_preview_accompaniment: 'Shoqërimi',
  supporting_invoice_no_question: 'Jo',
  supporting_invoice_yes_question: 'Po',
  supporting_invoice_city: 'Qyteti',
  supporting_invoice_starting_date: 'Data e nisjes',
  supporting_invoice_transport_starting_point: 'Pika e nisjes',
  supporting_invoice_transport_destination_point: 'Pika e dorëzimit',
  supporting_invoice_transport_starting_address: 'Adresa e nisjes',
  supporting_invoice_transport_destination_date: 'Data e dorëzimit',
  supporting_invoice_transport_destination_address: 'Adresa e dorëzimit',
  //E-invoice

  e_invoice_number: 'Numër fature',
  e_invoice_date: 'Data',
  e_invoice_seller_label: 'Shitësi',
  e_invoice_address_label: 'Adresa',
  e_invoice_tel_label: 'Tel',
  e_invoice_buyer_label: 'Blerësi',
  e_invoice_item_label: 'Artikull',
  e_invoice_barcode_label: 'Barkod',
  e_invoice_unit_label: 'Njësi',
  e_invoice_quantity_label: 'Sasi',
  e_invoice_price_label: 'Cmimi për njësi pa TVSH',
  e_invoice_price_without_vat_label: 'Vlefta pa TVSH',
  e_invoice_price_vat_label: 'Cmimi për njësi me TVSH',
  e_invoice_no_tax_label: 'Pa TVSH',
  e_invoice_tax_label: 'Vlefta e TVSH',
  e_invoice_total_label: 'Vlefta me TVSH',
  e_invoice_total_discount_label: 'Totali i uljeve në faturë',
  e_invoice_total_amount_no_tax: 'Shuma totale pa TVSH',
  e_invoice_total_tax_amount: 'Shuma totale e TVSH-së',
  e_invoice_total_amount_with_tax: 'Shuma totale me TVSH',
  e_invoice_bussines_unit_code_label: 'Njësia e biznesit',
  e_invoice_bussines_soft_code_label: 'Pajisja elektronike',
  e_invoice_bussines_operator_code_label: 'Kodi i operatorit',
  e_invoice_eic_label: 'EIC',
  e_invoice_info_label: 'Informacione të faturës',
  e_invoice_payment_type_label: 'Mënyra e pagesës',
  e_invoice_payment_type_value: 'Pa para në dorë',

  e_invoice_payment_bic_label: 'Kodi',
  e_invoice_proccess_type: 'Lloji i procesit',
  e_invoice_bill_type: 'Lloji i faturës',
  e_invoice_signature_field_label: '( emër, mbiemër, firmë )',
  e_invoice_carrier_label: 'Transportuesi',
  e_invoice_remittances_label: 'Dergësa',
  e_invoice_recipient_name_label: 'Marrësi',
  e_invoice_date_of_shipment_label: 'Data e dergesës',
  e_invoice_country_label: 'Shteti',
  e_invoice_title_label: 'Faturë Elektronike',
  e_invoice_title_label_1: 'Faturë',
  e_invoice_title_label_2: 'Elektronikë',
  e_invoice_notes_label: 'Shënime',
  e_invoice_scan_qr: 'Skano kodin QR për më shumë detaje',
  payment_delivery_receiver_label: 'Marrësi i dërgesës',

  process_type_p2_description: 'Faturimi periodik i dorëzimit të bazuar në kontratë',
  process_type_p10_description: 'Faturimi korrigjues',
  process_type_p1_description: 'Faturimi i furnizimit të mallrave dhe shërbimeve të porositura në bazë të kontratës',
  process_type_p3_description: 'Dorëzimi i faturimit mbi porosi të paparashikuara',
  process_type_p4_description: 'Pagesa paraprake',
  process_type_p5_description: 'Pagesa në vend',
  process_type_p6_description: 'Pagesa para dorëzimit bazuar në një porosi blerjeje',
  process_type_p7_description: 'Faturat në lidhje me një shënim transporti',
  process_type_p8_description: 'Faturat në lidhje me një shënim transporti dhe dëftese',
  process_type_p9_description: 'Miratimi ose faturimi negativ',
  process_type_p11_description: 'Faturim i pjesshëm dhe përfundimtar',
  process_type_p12_description: 'Vetëfaturim',

  document_type_384_description: 'Faturë korrigjuese',
  document_type_388_description: 'Faturë tatimore',
  document_type_82_description: 'Faturë për shërbimet e matura',
  document_type_325_description: 'Parafatura',
  document_type_326_description: 'Faturë e pjesshme',
  document_type_380_description: 'Faturë tregtare',
  document_type_381_description: 'Miratim',
  document_type_383_description: 'Debit',
  document_type_386_description: 'Faturë pagese paraprake',
  document_type_394_description: 'Faturë Lizingu',

  payment_account_description: 'Transaksion nga llogaria',
  payment_factoring_description: 'Faktoring',
  payment_compensation_description: 'Kompensim',
  payment_waiver_description: 'Fshirje e borxheve',
  payment_transfer_description: 'Transferim i të drejtave ose borxheve',
  payment_banknote_description: 'Kartëmonedha dhe monedha',
  payment_kind_description: 'Klering',
  payment_other_description: 'Të tjera pagesa pa para në dorë',
  payment_tax_free_description: 'Eksporti i shërbimeve',
  payment_exportof_goods_description: 'Eksporti i produkteve',
  payment_card_description: 'Kartë debiti dhe krediti',
  payment_check_description: 'Çek bankar',
  payment_voucher_description: 'Tollon me një qëllim',
  payment_company_description: 'Kartat e kompanisë dhe të ngjashme',
  payment_order_description: 'Fatura ende e papaguar. Do të paguhet me faturë përmbledhëse.',

  //Transaction print from app
  receipt_header: 'Fature tatimore',
  receipt_subtotal_label: 'Nentotal',
  receipt_total_discount_label: 'Totali i uljeve ne fature',
  receipt_total_label: 'TOTAL',
  receipt_foreign_total_label: 'Total',
  receipt_cash_money_label: 'Para ne dore',
  receipt_nsfl_label: 'NSFL',
  receipt_nivf_label: 'NIVF',
  receipt_nsfl_primary_label: 'NSFL Prim',
  receipt_nivf_primary_label: 'NIVF Prim',
  receipt_operator_code: 'Kod operatori',
  receipt_software_code: 'Kod softueri',
  receipt_seller_label: 'Shites',
  receipt_buyer_label: 'Bleres',
  receipt_number_label: 'Nr. fature',
  receipt_date_time_label: 'Data & Ora',
  receipt_buyer_address_label: 'Adresa e bleresit',
  receipt_buyer_nipt_label: 'NIPT i bleresit',
  receipt_thank_you_label: 'Faleminderit!',
  wtn_receipt_info: 'Informacione\n te fatures',
  wtn_fwtnic_label: 'FWTNIC',
  wtn_invoice_type: 'Lloji i fatures',
  wtn_transaction_type: 'Lloji i transaksionit',
  wtn_information_label_1: 'Informacione',
  wtn_information_label_2: 'transporti',
  wtn_owner_veh: 'Pronari i mjetit',
  wtn_plate_label: 'Targa',

  customer_vat_number_label: 'Numri i TVSH-së',
  customer_tax_number_label: 'Numri i tatimit',
  customer_soc_number_label: 'Numri i Sigurimeve Shoqërore (NSS)',
  customer_passport_number_label: 'Numri i pasaportës',

  //UNITS
  KGM: 'Kilogram',
  GRM: 'Gram',
  CMT: 'Centimetër',
  MTR: 'Metër',
  LTR: 'Litër',
  XPP: 'Copë',
  HUR: 'Orë',
  E49: 'Ditë',
  MTK: 'Metër katror',
  A19: 'Metër kub',
  M4: 'Vlerë monetare',
  KMT: 'Kilometër',
  WM: 'Muaj pune',
  MWH: 'MWh',

  //pay in out
  pay_in_out_title: 'MANDAT ARKETIMI',
  pay_in_out_description: 'Pershkrimi',
  pay_in_out_sum: 'Shuma',
  pay_in_out_id: 'ID',
  pay_in_out_buscode_label: 'Njesia e biznesit',
  pay_in_out_number: 'Numri i mandatit',
}

export default al
