import { styleSheetFactory } from '../../Themes/Colors'

const styles = styleSheetFactory((colors) => ({
    container: {
        flexGrow: 1,
        flex: 1,
        backgroundColor: colors.background,
    },
    contentContainer: {
        padding: 15,
        paddingBottom: 64,
    },
    inputContainer: {
        minHeight: 60,
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
    },
    scrollContainer: {
        paddingBottom: 20,
        marginHorizontal: 15,
        marginTop: 30,
    },
    subHeader: {
        marginTop: 15,
        marginBottom: 5,
    },
    deviceInfoContainer: {
        backgroundColor: colors.white,
        padding: 15,
        borderRadius: 7,
    },
    deviceInfoRow: {

        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingVertical: 3,
    },
    separator: {
        backgroundColor: colors.separator,
        height: 1,
        width: '100%',
    },

}))

export default styles
