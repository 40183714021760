import React, { useState } from 'react'
import { SafeAreaView, View } from 'react-native'
import { useTheme } from 'react-native-themed-styles'
import PropTypes from 'prop-types'

import {
    Text,
    Header,
    ButtonGroup,
} from '../../Components'

import themedStyles from './NewCustomer.Styles'
import images from '../../Themes/Images'

import IndividForm from './IndividForm'
import BusinessForm from './BusinessForm'

const Form = {
    individ: IndividForm,
    business: BusinessForm,
}

function NewCustomer({
    navigation: {
        navigate,
        goBack,
    }, route: {
        params: {
            isSupplier,
        },
    } = {} }) {
    const [styles] = useTheme(themedStyles)
    const [selected, setSelected] = useState('business')

    const FormType = Form[selected]


    const types = [
        {
            title: 'customer_business_label',
            type: 'business',
        },
        {
            title: 'customer_individual_label',
            type: 'individ',
        },
    ]

    return (
        <SafeAreaView style={styles.container}>
            <Header title={isSupplier ? 'header_new_supplier' : 'header_new_customer'} image={images.chevronLeft} onPress={() => goBack()} />
            <View style={styles.typeContainer}>
                <Text i18nKey={isSupplier ? 'customer_supplier_type_label' : 'customer_customer_type_label'} align="left" />
                <ButtonGroup
                    selected={selected}
                    onPress={(type) => setSelected(type)}
                    options={types}
                    style={styles.typesGroup}
                />
            </View>
            <FormType navigate={navigate} goBack={goBack} isSupplier={isSupplier} />
        </SafeAreaView>
    )
}

NewCustomer.propTypes = {
    navigation: PropTypes.object,
    route: PropTypes.object,
}

export default NewCustomer
