import React, { createContext, useState, useEffect } from 'react'
import { useQuery } from '@apollo/client'
import PropTypes from 'prop-types'

import { LIST_UNITS } from '../Containers/Units/Units.schema'

const UnitsContext = createContext()

function UnitsProvider({ children }) {
  const { data } = useQuery(LIST_UNITS, { variables: { limit: 30 }, fetchPolicy: 'network-only' })

  const [measurementUnits, setMeasurementUnits] = useState([])
  useEffect(() => {
    const uni = data?.listMeasurementUnits?.measurementUnits
    if (uni) {
      setMeasurementUnits([...uni])
    }
  }, [data])

  return (
    <UnitsContext.Provider value={measurementUnits}>
      {children}
    </UnitsContext.Provider>
  )
}
UnitsProvider.propTypes = {
  children: PropTypes.any,
}

function useUnits() {
  const context = React.useContext(UnitsContext)
  if (context === undefined) {
    throw new Error('useUnits must be used within a UnitsProvider')
  }
  return context
}

export { UnitsProvider }
export default useUnits
