import { gql } from '@apollo/client'

const LIST_ALL_DISCOUNTS = gql`
  query listDiscounts($nextToken: String, $limit: Int, $locId: String, $cashRegisterId: String) {
    listDiscounts(listDiscountsInput: {
      nextToken: $nextToken
      limit: $limit
    }
    requestedLocationAndCashRegister: {
      locationId: $locId,
      cashRegisterId: $cashRegisterId
    }
    ) {
      discounts{
        id
        name
        labelColor
        percentage
        discountType
        prices {
          amount
          currency
        }
      }
      nextToken
    }
  }
`

export default LIST_ALL_DISCOUNTS
