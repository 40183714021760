import { gql } from '@apollo/client'

export const GET_CATEGORIES = gql`
  query listCategories($limit: Int, $nextToken: String, $filter: Filter,$locationId:String, $locId: String, $cashRegisterId: String) {
    listCategories(listCategoriesInput: {
      limit: $limit,
      nextToken: $nextToken,
      filter: $filter,
    }
    locationId:$locationId,
    requestedLocationAndCashRegister: {
      locationId: $locId,
      cashRegisterId: $cashRegisterId
    }
    ) {
      categories {
        id
        name
        labelColor
      },
      nextToken
    },
  }
`


export const DELETE_CATEGORY = gql`
  mutation deleteCategory ($id: String!,$locationId:String, $locId: String, $cashRegisterId: String) {
    deleteCategory(
      id: $id
      locationId:$locationId
      requestedLocationAndCashRegister: {
        locationId: $locId,
        cashRegisterId: $cashRegisterId
      }
      ){
      id
    }
  }
`
