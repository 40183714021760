import { styleSheetFactory } from '../../Themes/Colors'

const styles = styleSheetFactory(colors => ({
  container: {
    width: 18,
    height: 18,
    borderWidth: 2,
    borderStyle: 'solid',
    borderColor: colors.placeholder,
    borderRadius: 9,
    justifyContent: 'center',
    alignItems: 'center',
  },
  selection: {
    width: 18,
    height: 18,
    borderWidth: 4,
    borderColor: colors.transparent,
    borderStyle: 'solid',
    borderRadius: 9,
  },
}))

export default styles
