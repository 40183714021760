import Image from '../Utils/ImageResolve'

const images = {
  back: Image.resolveAssetSource(require('./../Images/Icons/arrow-left.png')),
  close: Image.resolveAssetSource(require('./../Images/Icons/close.png')),
  pass: Image.resolveAssetSource(require('./../Images/Icons/pass.png')),
  hiddenPass: Image.resolveAssetSource(require('./../Images/Icons/hiddenPass.png')),
  attachFile: Image.resolveAssetSource(require('./../Images/Icons/attachFile.png')),
  uploadedSuccess: Image.resolveAssetSource(require('./../Images/Icons/uploadedSuccess.png')),
  success: Image.resolveAssetSource(require('./../Images/Icons/success.png')),
  error: Image.resolveAssetSource(require('./../Images/Icons/error.png')),
  arrow_right: Image.resolveAssetSource(require('./../Images/Icons/arrow-right.png')),
  customers: Image.resolveAssetSource(require('./../Images/vf-icons/customers.png')),
  partner: Image.resolveAssetSource(require('./../Images/vf-icons/partner.png')),
  suppliers: Image.resolveAssetSource(require('./../Images/vf-icons/suppliers.png')),
  customersPadding: Image.resolveAssetSource(require('./../Images/vf-icons/customersPadding.png')),
  list: Image.resolveAssetSource(require('./../Images/vf-icons/items.png')),
  menu: Image.resolveAssetSource(require('./../Images/vf-icons/menu.png')),
  checkout: Image.resolveAssetSource(require('./../Images/vf-icons/checkout.png')),
  settings: Image.resolveAssetSource(require('./../Images/vf-icons/settings.png')),
  transactions: Image.resolveAssetSource(require('./../Images/vf-icons/transactions.png')),
  user: Image.resolveAssetSource(require('./../Images/vf-icons/users.png')),
  cashDrawer: Image.resolveAssetSource(require('./../Images/vf-icons/cash-drawer.png')),
  scan: Image.resolveAssetSource(require('./../Images/Icons/scan.png')),
  search: Image.resolveAssetSource(require('./../Images/Icons/search.png')),
  plus: Image.resolveAssetSource(require('./../Images/Icons/plus.png')),
  arrow_left: Image.resolveAssetSource(require('./../Images/Icons/arrow-left.png')),
  bin_light: Image.resolveAssetSource(require('./../Images/Icons/bin_light.png')),
  logout: Image.resolveAssetSource(require('./../Images/Icons/logout.png')),
  arrowLeft: Image.resolveAssetSource(require('./../Images/iconsVF/arrowLeft.png')),
  arrowRight: Image.resolveAssetSource(require('./../Images/iconsVF/arrowRight.png')),
  filledDownArrow: Image.resolveAssetSource(require('./../Images/iconsVF/filledDownArrow.png')),

  'cash-drawer': Image.resolveAssetSource(require('./../Images/vf-icons/current-drawer.png')),
  calendar: Image.resolveAssetSource(require('./../Images/vf-icons/calendar.png')),
  defaultIcon: Image.resolveAssetSource(require('./../Images/Icons/defaultIcon.png')),
  dotsMenuV: Image.resolveAssetSource(require('./../Images/vf-icons/triple-dots.png')),
  clock: Image.resolveAssetSource(require('./../Images/Icons/clock.png')),
  warning: Image.resolveAssetSource(require('./../Images/Icons/warning.png')),
  whatsapp: Image.resolveAssetSource(require('./../Images/iconsVF/whatsapp.png')),
  addCustomer: Image.resolveAssetSource(require('./../Images/iconsVF/addCustomer.png')),
  privacyPolicy: Image.resolveAssetSource(require('./../Images/Icons/privacyPolicy.png')),

  // Orders and receipt (transactions)
  retry: Image.resolveAssetSource(require('./../Images/Icons/retry.png')),
  not_found: Image.resolveAssetSource(require('./../Images/vf-icons/not-found.png')),
  transaction: Image.resolveAssetSource(require('./../Images/Icons/transaction.png')),
  refund: Image.resolveAssetSource(require('./../Images/Icons/refund.png')),
  arrow_up: Image.resolveAssetSource(require('./../Images/Icons/arrow-up.png')),
  link: Image.resolveAssetSource(require('./../Images/Icons/link.png')),
  sync: Image.resolveAssetSource(require('./../Images/Icons/sync.png')),
  cash: Image.resolveAssetSource(require('./../Images/Icons/cash.png')),
  qrcode: Image.resolveAssetSource(require('./../Images/Icons/qrcode.png')),
  share: Image.resolveAssetSource(require('./../Images/iconsVF/share.png')),
  calendarBlack: Image.resolveAssetSource(require('./../Images/iconsVF/calendar.png')),
  invoice: Image.resolveAssetSource(require('./../Images/iconsVF/transaction.png')),

  // list item images
  list_item: Image.resolveAssetSource(require('./../Images/Icons/list-black.png')),
  categories: Image.resolveAssetSource(require('./../Images/Icons/categories.png')),
  modifiers: Image.resolveAssetSource(require('./../Images/Icons/modifiers.png')),
  discounts: Image.resolveAssetSource(require('./../Images/Icons/discounts.png')),
  units: Image.resolveAssetSource(require('./../Images/Icons/units.png')),
  icon_close_white: Image.resolveAssetSource(require('./../Images/Icons/icon-close-white.png')),
  info: Image.resolveAssetSource(require('./../Images/Icons/info.png')),

  // Checkout
  cardView: Image.resolveAssetSource(require('./../Images/iconsVF/cardView.png')),
  gridView: Image.resolveAssetSource(require('./../Images/iconsVF/gridView.png')),
  listView: Image.resolveAssetSource(require('./../Images/iconsVF/listView.png')),
  bookmark: Image.resolveAssetSource(require('./../Images/vf-icons/bookmark.png')),

  calculator: Image.resolveAssetSource(require('./../Images/Images/calculator.png')),
  delete: Image.resolveAssetSource(require('./../Images/iconsVF/delete.png')),
  plusIcon: Image.resolveAssetSource(require('./../Images/iconsVF/plus.png')),
  minusIcon: Image.resolveAssetSource(require('./../Images/iconsVF/minus.png')),
  closeIcon: Image.resolveAssetSource(require('./../Images/iconsVF/close.png')),
  chevronLeft: Image.resolveAssetSource(require('./../Images/iconsVF/chevronLeft.png')),
  chevronRight: Image.resolveAssetSource(require('./../Images/iconsVF/chevronRight.png')),
  tick: Image.resolveAssetSource(require('./../Images/iconsVF/tick.png')),
  warningIcon: Image.resolveAssetSource(require('./../Images/iconsVF/warning.png')),

  palette: Image.resolveAssetSource(require('./../Images/Icons/color-palette.png')),
  gallery: Image.resolveAssetSource(require('./../Images/iconsVF/gallery.png')),
  camera: Image.resolveAssetSource(require('./../Images/iconsVF/camera.png')),

  printerActive: Image.resolveAssetSource(require('./../Images/iconsVF/printerActive.png')),
  printerInactive: Image.resolveAssetSource(require('./../Images/iconsVF/printerInactive.png')),
  connectionActive: Image.resolveAssetSource(require('./../Images/iconsVF/connectionActive.png')),
  connectionInactive: Image.resolveAssetSource(require('./../Images/iconsVF/connectionInactive.png')),
  notificationActive: Image.resolveAssetSource(require('./../Images/iconsVF/notificationActive.png')),
  notificationInactive: Image.resolveAssetSource(require('./../Images/iconsVF/notificationInactive.png')),
  customersMenu: Image.resolveAssetSource(require('./../Images/iconsVF/users.png')),

  newUpdate: Image.resolveAssetSource(require('./../Images/Icons/newUpdate.png')),

  // Flags
  flagAL: Image.resolveAssetSource(require('./../Images/Images/flagAL.png')),
  flagEU: Image.resolveAssetSource(require('./../Images/Images/flagEU.png')),

  // Checkout Custom Icons
  receipt: Image.resolveAssetSource(require('./../Images/iconsVF/receipt.png')),
  angryFace: Image.resolveAssetSource(require('./../Images/iconsVF/angryFace.png')),

  //Employee
  maleAvatar: Image.resolveAssetSource(require('./../Images/Images/maleAvatar.png')),
  femaleAvatar: Image.resolveAssetSource(require('./../Images/Images/femaleAvatar.png')),
  inactiveStatus: Image.resolveAssetSource(require('./../Images/Icons/inactiveStatus.png')),

  //Settings
  tax: Image.resolveAssetSource(require('./../Images/Icons/taxes.png')),
  translate: Image.resolveAssetSource(require('./../Images/Icons/translate.png')),
  password: Image.resolveAssetSource(require('./../Images/Icons/password.png')),
  location: Image.resolveAssetSource(require('./../Images/Icons/location.png')),
  deny: Image.resolveAssetSource(require('../Images/Icons/deny.png')),
  chart: Image.resolveAssetSource(require('../Images/Icons/chart.png')),
  certificate: Image.resolveAssetSource(require('../Images/Icons/certificate.png')),

  //GOV Events
  payIn: Image.resolveAssetSource(require('./../Images/Icons/payIn.png')),
  payOut: Image.resolveAssetSource(require('./../Images/Icons/payOut.png')),
  cash_drawer: Image.resolveAssetSource(require('./../Images/Icons/cashDrawer.png')),
  govEvents: Image.resolveAssetSource(require('./../Images/Icons/govEvents.png')),

  // Locations
  locationMarker: Image.resolveAssetSource(require('./../Images/iconsVF/locationMarker.png')),

  analiticsIcon: Image.resolveAssetSource(require('../Images/Icons/graph.png')),
  analiticsPage: Image.resolveAssetSource(require('./../Images/Images/analiticsPage.png')),

  tickOutline: Image.resolveAssetSource(require('./../Images/iconsVF/tickOutline.png')),
  attention: Image.resolveAssetSource(require('./../Images/iconsVF/attention.png')),
  infoYellow: Image.resolveAssetSource(require('./../Images/iconsVF/info.png')),
  exclamationMark: Image.resolveAssetSource(require('./../Images/iconsVF/exclamationMark.png')),
  soundAndVibration: Image.resolveAssetSource(require('./../Images/iconsVF/soundAndVibration.png')),
  doc: Image.resolveAssetSource(require('./../Images/iconsVF/doc.png')),

  //Payment methods
  cashPayment: Image.resolveAssetSource(require('./../Images/iconsVF/cash.png')),
  checkPayment: Image.resolveAssetSource(require('./../Images/iconsVF/check.png')),
  creditCardPayment: Image.resolveAssetSource(require('./../Images/iconsVF/creditCard.png')),
  voucherPayment: Image.resolveAssetSource(require('./../Images/iconsVF/voucher.png')),
  orderPayment: Image.resolveAssetSource(require('./../Images/iconsVF/order.png')),
  otherPayment: Image.resolveAssetSource(require('./../Images/iconsVF/other.png')),

  contacts: Image.resolveAssetSource(require('./../Images/iconsVF/contacts.png')),
  closeS: Image.resolveAssetSource(require('./../Images/iconsVF/closeS.png')),

  //E-Invoice
  accepted: Image.resolveAssetSource(require('../Images/Icons/accepted.png')),
  refused: Image.resolveAssetSource(require('../Images/Icons/refused.png')),
  waiting: Image.resolveAssetSource(require('../Images/Icons/waiting.png')),
  inProgressInvoice: Image.resolveAssetSource(require('../Images/iconsVF/inProgressInvoice.png')),
  download: Image.resolveAssetSource(require('../Images/Icons/download.png')),
  previewDoc: Image.resolveAssetSource(require('../Images/Icons/previewDoc.png')),
  eInvoice: Image.resolveAssetSource(require('../Images/Icons/eInvoice.png')),
  refundedInvoice: Image.resolveAssetSource(require('../Images/iconsVF/refundedInvoice.png')),

  //Supportive invoice
  supportiveInvoice: Image.resolveAssetSource(require('../Images/iconsVF/supportiveInvoice.png')),
  regSupportive: Image.resolveAssetSource(require('../Images/iconsVF/regSupportive.png')),

  //Invoices
  cashInvoice: Image.resolveAssetSource(require('../Images/iconsVF/transactions.png')),
  nonCashInvoice: Image.resolveAssetSource(require('../Images/iconsVF/eInvoice.png')),
  wtnInvoice: Image.resolveAssetSource(require('../Images/iconsVF/warehouseNT.png')),

  //OnBoard
  operatorCode: Image.resolveAssetSource(require('../Images/iconsVF/operator_code.png')),
  electronicCashDevice: Image.resolveAssetSource(require('../Images/iconsVF/electronic_cash_device.png')),
  personLaying: Image.resolveAssetSource(require('../Images/iconsVF/person_laying.png')),
  padLock: Image.resolveAssetSource(require('../Images/iconsVF/padLock.png')),
  fourG: Image.resolveAssetSource(require('../Images/iconsVF/4g+.png')),
  salesDeclaration: Image.resolveAssetSource(require('../Images/iconsVF/data.png')),
  reports: Image.resolveAssetSource(require('../Images/iconsVF/reports.png')),
  report: Image.resolveAssetSource(require('../Images/iconsVF/report.png')),

  // BRAND
  logo: Image.resolveAssetSource(require('./../Images/Images/logo.png')),
  vfLogo: Image.resolveAssetSource(require('./../Images/iconsVF/logo.png')),
  splash: Image.resolveAssetSource(require('./../Images/Images/splash.png')),

  //fileUpload
  fileUpload: Image.resolveAssetSource(require('./../Images/Images/fileUpload.png')),
  emptyItemList: Image.resolveAssetSource(require('./../Images/Images/emptyItemList.png')),

  //Delete Account
  mail: Image.resolveAssetSource(require('./../Images/Icons/mail.png')),
}

const animations = {
  vfLoaderWhite: require('../Images/Animated/loaderWhite.json'),
  vfLoaderRed: require('../Images/Animated/loaderRed.json'),
  vfLoaderThinDark: require('../Images/Animated/loaderThinDark.json'),
  print: require('../Images/Animated/print.json'),
  success: require('../Images/Animated/success.json'),
  celebrate: require('../Images/Animated/celebrate.json'),
}

export { animations }
export default images
