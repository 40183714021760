import { gql } from '@apollo/client'

export const GET_ORDER = gql`
    query getOrder($id: String!, $locationId: String, $cashRegisterId: String ){
        getOrder(
          id: $id,
          locationId: $locationId,
          requestedLocationAndCashRegister: {
            locationId: $locationId,
            cashRegisterId: $cashRegisterId
          }
          ){
          id
          invoiceNumber
          orderSubType
          orderType
          receiptNumber
          status
          createdAt
          locationId
          fulfillmentType
          updatedAt
          shippingAddress {
      addressLine1
      addressLine2
      addressLine3
      administrativeDistrictLevel1
      administrativeDistrictLevel2
      administrativeDistrictLevel3
      country
      externalId
      firstName
      lastName
      locality
      organization
      postalCode
      sublocality
      sublocality2
      sublocality3
    }
    activities {
      action
      createdAt
      email
    }
    appliedDiscounts {
      discountType
      id
      appliedMoney {
        amount
        currency
      }
      name
      percentage
    }
    bankAccount {
      updatedAt
      accountNumberSuffix
      accountType
      bankName
      city
      country
      createdAt
      creaditable
      currency
      debitMandateReferenceId
      debitable
      fingerprint
      holderName
      iban
      id
      locationId
      primaryBankIdentificationNumber
      referenceId
      secondaryBankIdentificationNumber
      status
      swiftCode
      version
    }
    createdAt
    currency {
      currencyCode
      exRate
      isBuyer
    }
    fulfillmentType
    id
    invoiceNumber
    lineItems {
      appliedDiscounts {
        appliedMoney {
          amount
          currency
        }
        discountType
        id
        name
        percentage
      }
      appliedTax {
        id
        name
        percentage
      }
      barcode
      basePriceMoney {
        amount
        currency
      }
      id
      imageUrl
      itemId
      itemName
      labelColor
      measurementUnitId
      measurementUnitName
      modifiers {
        appliedMoney {
          currency
          amount
        }
        id
        modifierListId
        modifierListName
        name
      }
      quantity
      refundableQuantity
      taxInclusionType
      totalDiscountMoney {
        amount
        currency
      }
      totalMoney {
        amount
        currency
      }
      totalTaxMoney {
        amount
        currency
      }
      variationId
      variationName
    }
    locationId
    orderGroupedTaxes {
      NumOfItems
      PriceBefVat {
        amount
        currency
      }
      VATAmt {
        amount
        currency
      }
      VATRate
    }
    orderSubType
    orderType
    receiptNumber
    status
    supplyDateOrPeriod {
      endDate
      startDate
    }
    totalDiscountMoney {
      amount
      currency
    }
    totalMoney {
      amount
      currency
    }
    totalTaxMoney {
      amount
      currency
    }
    updatedAt
    reason
    transaction {
      FIC
      IIC
      code
      softwareCode
      qrCode
      id
      receiptNumber
      operatorCode
      status
      }
        }
    }
`

export const UPDATE_ORDER = gql`
mutation updateOrder($orderUpdateInput: OrderUpdateInput!, $locationId: String, $cashRegisterId: String){
  updateOrder(
    orderUpdateInput: $orderUpdateInput,
    requestedLocationAndCashRegister: {
      locationId: $locationId,
      cashRegisterId: $cashRegisterId
    }
    ){
    id
    status
  }
}
`
export const PAY_ORDER = gql`
  mutation payOrder($locationId: String, $payOrderRequest:  PayOrderRequest!, $cashRegisterId: String){
    payOrder(
      locationId: $locationId,
      payOrderRequest: $payOrderRequest,
      requestedLocationAndCashRegister: {
        locationId: $locationId,
        cashRegisterId: $cashRegisterId
      }
      ){
      id
      status
    }
  }
`
