import { styleSheetFactory } from '../../../Themes/Colors'

const styles = styleSheetFactory((colors) => ({
    accessItem: {
        justifyContent: 'center',
        overflow: 'hidden',
        borderBottomLeftRadius: 7,
        borderBottomRightRadius: 7,
        paddingBottom: 15,
    },
    touchable: {
        flexGrow: 1,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    radioInput: {
        flexShrink: 0,
    },
    accessTextContainer: {
        flexGrow: 1,
        flexShrink: 1,
        flexDirection: 'row',
    },
    accessWrapper: {
        marginVertical: 15,
    },
    accessLabel: {
        marginBottom: 10,
    },
    sectionTitle: {
        marginBottom: 5,
    },
}))

export default styles
