import React, { useEffect, useState } from 'react'


import PropTypes from 'prop-types'

import { useNavigation } from '@react-navigation/native'

import { useUserDetails } from '../../Utils/AuthDetails'

import SelectableItem from '../SelectableItem/SelectableItem'

import LIST_LOCATIONS from '../../Containers/Locations/Locations.Schema'

import { LIST_CASH_REGISTERS } from '../../Containers/CashRegister/CashRegister.Schema'
import BottomSheetWithButton from '../BottomSheetWithButton'
import { useConfig } from '../../Utils'

function SwitchLocation({
  modal,
  setModal,
  modalPage,
  setmodalPage,
}) {

  const { switchLocation: { address, deviceId, locationId: id, businessName } } = useConfig()
  const { navigate } = useNavigation()
  const { role } = useUserDetails()
  const [searchValue, setSearchValue] = useState('')
  const [currentLocation, setcurrentLocation] = useState({})
  const [currentDevice, setCurrentDevice] = useState({})
  const [newLocation, setNewLocation] = useState({})
  const isFirstPage = modalPage === 1

  useEffect(() => {
    if (id) {
      setcurrentLocation({ id: id, description: address, code: businessName })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])


  useEffect(() => {
    if (deviceId) {
      setCurrentDevice({ id: deviceId })
    }
  }, [deviceId])


  useEffect(() => {
    if (Object.keys(currentDevice).length === 0) {
      setCurrentDevice({ id: deviceId })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modal?.isVisible])

  useEffect(() => {
    if (Object.keys(currentLocation).length === 0) {
      setcurrentLocation({ id: id, description: address, code: businessName })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modal?.isVisible])

  const mapLocations = (locationList) => {
    const unmappedItems = locationList?.listLocations?.locations
    const mappedItems = []

    if (unmappedItems) {
      unmappedItems.forEach((item) => {
        mappedItems.push({
          id: item?.id,
          code: item?.businessName,
          description: item?.address?.addressLine1,
        })
      })
    }
    return mappedItems
  }
  const mapDevices = (devicesList) => {
    const unmappedItems = devicesList?.listCashRegisters?.cashRegisters
    const mappedItems = []

    if (unmappedItems) {
      unmappedItems.forEach((item) => {
        mappedItems.push({
          id: item?.id,
          code: item?.name,
          description: item?.deviceName,
        })
      })
    }
    return mappedItems
  }

  const closeModal = () => {
    setModal(prev => ({ ...prev, isVisible: false }))
    setmodalPage(1)
  }

  const handleSwitchButton = (newDev) => {
    navigate('LocationChangeLoader', { location: newLocation, device: newDev })
    closeModal()

  }

  const openDevicesModal = (newLoc) => {
    setmodalPage(2)
    setModal({
      isVisible: true,
      title: 'change_devices_select_prompt',
      placeholder: 'change_devices_search_placeholder',
      buttonLabel: 'change_devices_button_label',
      searchKey: 'code',
      Item: SelectableItem,
      noteText: 'change_devices_note_text',
      withVariables: { locId: id, cashRegisterId: deviceId, filter: { name: 'location', value: newLoc.id } },

    })
  }

  return (
    <>
      {role === 'Admin' && <BottomSheetWithButton
        {...modal}
        closeModal={closeModal}
        mapItems={isFirstPage ? mapLocations : mapDevices}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        query={isFirstPage ? LIST_LOCATIONS : LIST_CASH_REGISTERS}
        selected={isFirstPage ? currentLocation : currentDevice}
        queryVariables={isFirstPage ? { id: '1d' } : { filter: { name: 'location', value: newLocation.id } }}
        onButtonPress={isFirstPage ? openDevicesModal : handleSwitchButton}
        select={isFirstPage ? (obj) => setNewLocation(obj) : () => { }}

      />}
    </>
  )
}

SwitchLocation.propTypes = {
  modal: PropTypes.object,
  setModal: PropTypes.func,
  modalPage: PropTypes.number,
  setmodalPage: PropTypes.func,
}


export default SwitchLocation
