import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

import { Platform, Text as RNText, View } from 'react-native'


import { useTheme } from 'react-native-themed-styles'



import { CommonActions } from '@react-navigation/native'

import { Loader, Modal, Text } from '../../Components'

import { useConfig } from '../../Utils'

import themeStyles from './LocationChangeLoader.styles'



const WithWrapper = ({ children }) => {
  const [styles] = useTheme(themeStyles)

  if (Platform.OS === 'web') {
    return (
      <Modal
        isVisible={true}
        backdropOpacity={1}
        backdropColor={'white'}

      >
        <View style={styles.container} >
          {children}
        </View>
      </Modal>)
  }
  return (
    <View style={styles.container} >
      {children}
    </View>
  )

}
WithWrapper.propTypes = {
  children: PropTypes.node,
}
function LocationChangeLoader({
  route,
  navigation: { dispatch },
}) {
  const [styles] = useTheme(themeStyles)
  const { setSwitchLocationConfig } = useConfig()

  useEffect(() => {
    setTimeout(() => {
      setSwitchLocationConfig({
        locationId: route?.params?.location?.id,
        deviceId: route?.params?.device?.id,
        address: route?.params?.location?.description,
        businessName: route?.params?.location?.code,
      })
      route.params.setActiveRoute('Checkout')
      dispatch(CommonActions.reset({
        index: 0,
        routes: [{ name: 'Checkout' }],
      }))

    }, 2000)

  }, [])


  return (
    <WithWrapper>
      <Loader size={200} />
      <Text style={styles.text} i18nKey={'change_location_changing_text'} size="body" />
      <RNText>
        <Text style={styles.text} i18nKey={route.params.location.description} size="body" />
      </RNText>
    </WithWrapper >
  )
}

LocationChangeLoader.propTypes = {
  route: PropTypes.object,
  navigation: PropTypes.object,
}

export default LocationChangeLoader
