import React, { useContext, useEffect, useState } from 'react'
import {
    SafeAreaView,
    View,
    ScrollView,
    Text as RNText,
    TouchableWithoutFeedback,
    TouchableOpacity,
} from 'react-native'
import PropTypes from 'prop-types'
import { useTheme } from 'react-native-themed-styles'
import { WebView } from 'react-native-webview'
import LottieView from 'lottie-react-native'
import { useMutation } from '@apollo/client'

import {
    Text,
    Header,
    Icon,
    Button,
    ErrorModal,
    CheckBox,
    BottomSheetModal,
} from '../../Components'

import { UPDATE_USER_POLICY } from '../Users/Users.schema'

import ContentManager from '../../Utils/ContentManager'
import { useUserDetails, getErrorMessage } from '../../Utils'

import Theme from '../../Themes/Theme'

import images, { animations } from '../../Themes/Images'
import themedStyles from './OnBoardingSeventhStep.Styles'

function OnBoardingSeventhStep({
    next,
    logout,
    policyCheck,
    refreshToken,
}) {
    const [styles] = useTheme(themedStyles)
    const { colors } = useContext(Theme)
    const { translate } = ContentManager
    const { id, privacyPolicy, termsCondition } = useUserDetails()
    const [loading, setLoading] = useState(false)
    const [webViewModal, setWebViewModal] = useState({
        isVisible: false,
        url: '',
        title: '',
    })

    const [conditions, setConditions] = useState({
        privacyPolicy: false,
        termsCondition: false,
    })

    useEffect(() => {
        if (privacyPolicy === 'true') {
            setConditions((prev) => ({ ...prev, privacyPolicy: true }))
        }
        if (termsCondition === 'true') {
            setConditions((prev) => ({ ...prev, termsCondition: true }))
        }
    }, [privacyPolicy, termsCondition])

    const [modal, setModal] = useState({
        isVisible: false,
        icon: images.warningIcon,
        title: '',
        desc: '',
        descPlaceholders: [],
        primaryText: '',
        primaryAction: null,
        tertiaryText: '',
        tertiaryAction: null,
    })
    const openModal = (
        title = 'opened_orders_error',
        desc = '',
        descPlaceholders = [],
        primaryText = '',
        primaryAction = null,
        tertiaryText = '',
        tertiaryAction = null,
        icon = images.warningIcon,
    ) => {
        setModal({
            isVisible: true,
            icon,
            title,
            desc,
            descPlaceholders,
            primaryText,
            primaryAction,
            tertiaryText,
            tertiaryAction,
        })
    }
    const closeModal = () => {
        setModal({
            isVisible: false,
            icon: images.delete,
            title: '',
            desc: '',
            primaryText: '',
            primaryAction: null,
            tertiaryText: '',
            tertiaryAction: null,
        })
    }

    const [
        updateUser,
        { loading: updateLoading },
    ] = useMutation(UPDATE_USER_POLICY)

    const onButtonPress = () => {
        setLoading(true)
        updateUser({
            variables: {
                id: id,
                privacyPolicy: conditions.privacyPolicy,
                termsCondition: conditions.termsCondition,
            },
        }).catch((er) => {
            setLoading(false)
            openModal(
                'certificate_error_label',
                getErrorMessage(er),
                [],
                'certificate_button_try_again',
                () => {
                    closeModal()
                },
                '',
                undefined
            )
        }).then((e) => {
            policyCheck ? refreshToken() : next()
        })
    }

    return (
        <>
            <SafeAreaView style={styles.container}>
                <Header
                    title={'onboarding_terms_privacy_header'}
                    image={images.close}
                    onPress={() => {
                        openModal(
                            'logout_key',
                            'onboarding_logout_message_description',
                            [],
                            'logout_key',
                            () => {
                                logout()
                            },
                            'logout_button_cancel',
                            () => {
                                closeModal()
                            },
                        )
                    }}
                />
                <ScrollView style={styles.scrollView}>
                    <View style={styles.contentWrapper}>
                        <View style={styles.upperContentWrapper}>
                            <Icon source={images.padLock} size={100} style={styles.icon} />
                            <Text i18nKey="onboarding_terms_privacy_label" size="h3" weight="bold" align="center" />
                        </View>
                        <View style={styles.horizontalSpacing}>
                            <Text align="left" i18nKey="onboarding_terms_condition_description" />
                        </View>
                    </View>
                </ScrollView>
                <View style={styles.buttonContainer}>
                    <View style={styles.lowerContentWrapper}>
                        <View style={styles.checkBoxWrapper}>
                            <CheckBox
                                tintColors={styles.checkBoxTintColors}
                                checked={conditions.termsCondition}
                                onValueChange={() => setConditions((prev) => ({ ...prev, termsCondition: !conditions.termsCondition }))}
                                style={styles.checkBox}
                                color={colors.secondaryAccent}
                            />
                            <RNText
                                onPress={() => { setWebViewModal({ isVisible: true, url: translate('onboarding_permission_link'), title: 'onboarding_terms_condition_2' }) }}
                                style={styles.privacyText}
                                allowFontScaling={false}
                            >
                                <RNText>{translate('onboarding_terms_condition_1')}</RNText>
                                <RNText style={{ color: colors.secondaryAccent }}>{translate('onboarding_terms_condition_2')}</RNText>
                                <RNText>{translate('onboarding_terms_condition_3')}</RNText>
                            </RNText>
                        </View>
                        <View style={styles.checkBoxWrapper}>
                            <CheckBox
                                tintColors={styles.checkBoxTintColors}
                                checked={conditions.privacyPolicy}
                                onValueChange={() => setConditions((prev) => ({ ...prev, privacyPolicy: !conditions.privacyPolicy }))}
                                style={styles.checkBox}
                                color={colors.secondaryAccent}
                            />
                            <RNText
                                onPress={() => { setWebViewModal({ isVisible: true, url: translate('onboarding_terms_privacy_link'), title: 'onboarding_permission_2' }) }}
                                style={styles.privacyText}
                                allowFontScaling={false}
                            >
                                <RNText>{translate('onboarding_permission_1')}</RNText>
                                <RNText style={{ color: colors.secondaryAccent }}>{translate('onboarding_permission_2')}</RNText>
                            </RNText>
                        </View>
                    </View>
                    <Button
                        title="onboarding_setup_button"
                        variant={(conditions.privacyPolicy && conditions.termsCondition) && !updateLoading && !loading ? 'active' : 'disabled'}
                        loader={updateLoading}
                        iconColor={colors.accent}
                        style={styles.rightButton(policyCheck)}
                        onPress={() => {
                            onButtonPress()
                        }}
                    />
                </View>
            </SafeAreaView>
            <ErrorModal
                isVisible={modal?.isVisible}
                title={modal?.title}
                description={modal?.desc}
                placeHolders={modal.descPlaceholders}
                primaryText={modal?.primaryText}
                tertiaryText={modal?.tertiaryText}
                primaryAction={modal?.primaryAction}
                tertiaryAction={modal?.tertiaryAction}
                onBackdropPress={() => closeModal()}
                onBackButtonPress={() => closeModal()}
                onClosePress={() => closeModal()}
            />
            <BottomSheetModal
                isVisible={webViewModal.isVisible}
                onClose={() => setWebViewModal({ isVisible: false })}
                contentContainerStyle={styles.modalContainer}
            >
                <View style={styles.webViewWrapper}>
                    <View style={styles.webViewHeader}>
                        <Text i18nKey={webViewModal.title} size="h5" weight="bold" color={colors.accent} />
                        <TouchableOpacity onPress={() => setWebViewModal({ isVisible: false })}>
                            <Icon source={images.close} color={colors.secondaryIcon} size={24} />
                        </TouchableOpacity>
                    </View>
                    <TouchableWithoutFeedback>
                        <WebView
                            originWhitelist={['*']}
                            source={{ uri: webViewModal.url }}
                            style={styles.webView}
                            startInLoadingState={true}
                            renderLoading={() => (
                                <View style={styles.lottieWrapper}>
                                    <LottieView
                                        style={styles.lottie}
                                        autoPlay
                                        loop={true}
                                        source={animations.vfLoaderRed}
                                    />
                                </View>
                            )}
                            androidHardwareAccelerationDisabled={true}
                        />
                    </TouchableWithoutFeedback>

                </View>
            </BottomSheetModal>
        </>
    )
}

OnBoardingSeventhStep.propTypes = {
    next: PropTypes.func,
    logout: PropTypes.func,
    policyCheck: PropTypes.bool,
    refreshToken: PropTypes.func,
}

OnBoardingSeventhStep.defaultProps = {
    policyCheck: false,
}


export default OnBoardingSeventhStep
