import { gql } from '@apollo/client'

//example on how to pass parameters
export const CREATE_CATEGORY = gql`
  mutation createCategory($name: String!, $labelColor: String, $locationId:String,  $locId: String, $cashRegisterId: String){
    createCategory(categoryInput:{
      name: $name
      labelColor: $labelColor
    }
    locationId:$locationId
    requestedLocationAndCashRegister: {
      locationId: $locId,
      cashRegisterId: $cashRegisterId
    }
    ){
      id
      name
      labelColor
    }
  }
`

export const EDIT_CATEGORY = gql`
  mutation updateCategory($id: String!, $name: String, $labelColor: String,$locationId:String,  $locId: String, $cashRegisterId: String){
    updateCategory(categoryUpdateInput:{
      id: $id,
      name: $name
      labelColor: $labelColor
    }
    locationId:$locationId
    requestedLocationAndCashRegister: {
      locationId: $locId,
      cashRegisterId: $cashRegisterId
    }
    ){
      id
      name
      labelColor
    }
  }
`

export const GET_CATEGORY = gql`
  query getCategory($id: String!,$locationId:String, $locId: String, $cashRegisterId: String) {
    getCategory(getCategoryInput: {
      id: $id,
    }
    locationId:$locationId
    requestedLocationAndCashRegister: {
      locationId: $locId,
      cashRegisterId: $cashRegisterId
    }
    ) {
      category {
        id
        name
        labelColor
      },
      listItems {
        items {
          id
          name
          labelColor
        }
      }
    },
  }
`
