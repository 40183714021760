import { Dimensions } from 'react-native'
import Color from 'color'

import { styleSheetFactory } from '../../Themes/Colors'

const { width } = Dimensions.get('screen')
const styles = styleSheetFactory(colors => ({
  qrScanner: {
    marginTop: 80,
  },
  withQR: {
    marginTop: 15,
  },
  withoutQR: {
    marginTop: 80,
  },
  topMenuContainer: {
    flexDirection: 'row',
    position: 'absolute',
    top: 50,
    left: 0,
    right: 0,
    margin: 15,
    marginBottom: 0,
  },
  searchWrapper: {
    flexGrow: 1,
    flexDirection: 'row',
    borderRadius: 7,
    overflow: 'hidden',
    backgroundColor: colors.tertiaryBackground,
  },
  searchContainer: {
    flexGrow: 1,
    flexDirection: 'row',
    backgroundColor: colors.tertiaryBackground,
    borderRadius: 7,
    overflow: 'hidden',
    alignItems: 'center',
  },
  searchIcon: {
    marginHorizontal: 10,
  },
  inputContainer: {
    flexGrow: 1,
  },
  searchInput: {
    flexGrow: 1,
    paddingLeft: 0,
    fontSize: 16,
    fontFamily: 'Vodafone Rg',
    color: colors.primaryText,
  },
  clearSearchContainer: {
    height: 50,
    aspectRatio: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  clearSearchTouchable: {
    backgroundColor: colors.background,
    height: 30,
    aspectRatio: 1,
    borderRadius: 15,
    justifyContent: 'center',
    alignItems: 'center',
  },
  scan: {
    height: 50,
    width: 50,
    backgroundColor: 'white',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: 15,
    borderRadius: 7,
  },
  modalContainer: {
    backgroundColor: colors.white,
    alignSelf: 'center',
    paddingBottom: 30,
    borderRadius: 6,
    minWidth: width - 80,
    maxWidth: 400,
  },
  closeContainer: {
    backgroundColor: colors.disabled,
    position: 'absolute',
    top: 0,
    right: 0,
    borderTopRightRadius: 7,
    borderBottomLeftRadius: 7,
    width: 75,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 10,
  },
  modalContent: {
    paddingHorizontal: 40,
    marginTop: 45,
  },
  errorIcon: {
    alignSelf: 'center',
    marginBottom: 5,
  },
  errorTitle: {
    marginVertical: 8,
  },
  emptyComponent: {
    marginTop: 60,
    alignItems: 'center',
  },
  leftSpacing: { marginLeft: 15 },
  bottomSpacing: { marginBottom: 20 },
  wrapper: { flexDirection: 'row', alignItems: 'flex-end', paddingTop: 5 },
  rightSpacing: { marginRight: 10 },
}))

const itemStyles = styleSheetFactory(colors => ({
  container: {
    height: 70,
    backgroundColor: colors.tertiaryBackground,
    justifyContent: 'center',
    overflow: 'hidden',
    marginVertical: 3,
    borderRadius: 7,
  },
  touchable: {
    flexGrow: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    position: 'relative',
  },
  imageContainer: labelColor => {
    return {
      width: 70,
      height: '100%',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: Color(labelColor).fade(0.65).toString(),
    }
  },
  infoContainer: {
    flexGrow: 1,
    flexShrink: 1,
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
    padding: 15,
    paddingLeft: 5,
  },
  descContainer: {
    flexGrow: 1,
    flexShrink: 1,
  },
  priceContainer: {
    flexDirection: 'column',
    alignItems: 'baseline',
    flexShrink: 0,
    marginLeft: 10,
  },
}))


const hiddenItemStyles = styleSheetFactory(colors => ({
  wrapper: {
    backgroundColor: colors.accent,
    height: 70,
    justifyContent: 'center',
    alignItems: 'flex-end',
    marginVertical: 3,
    borderRadius: 7.5,
  },
  option: {
    width: 100,
    height: 70,
    justifyContent: 'center',
    alignItems: 'center',
  },
  optionFull: {
    flexDirection: 'row',
  },
  confirm: {
    flexGrow: 1,
    height: 70,
    flexDirection: 'row',
    paddingLeft: 15,
    alignItems: 'center',
  },
  confirmText: {
    marginLeft: 15,
  },
  cancel: {
    width: 40,
    height: 40,
    flexShrink: 0,
    marginVertical: 15,
    marginHorizontal: 15,
    borderRadius: 20,
    backgroundColor: colors.whiteRGBA(0.55),
    justifyContent: 'center',
    alignItems: 'center',
  },
  loader: {
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
}))


const separatorStyles = styleSheetFactory(colors => ({
  container: {
    backgroundColor: colors.tertiaryBackground,
    height: 1,
  },
  separator: {
    backgroundColor: colors.separator,
    height: 1,
    marginLeft: 70,
  },
}))

export {
  itemStyles,
  hiddenItemStyles,
  separatorStyles,
}

export default styles


