import { Dimensions } from 'react-native'

import { styleSheetFactory } from '../../Themes/Colors'

const { width } = Dimensions.get('screen')
const styles = styleSheetFactory(colors => ({
  container: {
    borderWidth: 1,
    borderColor: colors.separator,
    backgroundColor: colors.tertiaryBackground,
    height: 55,
    width: width - 30,
    borderRadius: 7,
    margin: 15,
  },
  containerActive_true: {
    borderColor: colors.secondaryAccent,
  },
  touchable: {
    height: '100%',
    width: '100%',
    marginVertical: 8,
    marginHorizontal: 16,
  },
  label: {
    position: 'absolute',
  },
  inputText: {
    fontFamily: 'Vodafone Rg',
  },
}))

export default styles
