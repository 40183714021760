
import { styleSheetFactory } from '../../Themes/Colors'

const style = styleSheetFactory((colors) => ({
    container: {
        marginHorizontal: 15,
    },
    wrapper: {
        flex: 1,
    },
    separator: {
        height: 1,
        backgroundColor: colors.separator,
    },
    lottieWrapper: {
        height: '100%',
        paddingHorizontal: 16,
        paddingVertical: 32,
        marginBottom: 32,
        justifyContent: 'center',
        alignItems: 'center',
    },
    searchContainer: {
        marginTop: 80,
    },
    lottie: {
        width: 150,
        height: 150,
        marginLeft: 0,
        alignSelf: 'center',
    },
    webViewWrapper: {
        width: '100%',
        height: '100%',
        alignSelf: 'center',
        padding: 15,
        borderTopLeftRadius: 10,
        borderTopRightRadius: 10,
        overflow: 'hidden',
    },
    webView: {
        flex: 1,
        backgroundColor: colors.white,
    },
    modalContainer: {
        justifyContent: 'center',
        height: '80%',
        backgroundColor: colors.background,
    },
    webViewHeader: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        padding: 10,
        // marginBottom:5,
    },
    supportLabel: {
        flexDirection: 'row',
        alignItems: 'center',
        marginVertical: 6,
    },
    contactWrapper: {
        marginTop: 5,
    },
    icon: {
        marginRight: 8,
        marginLeft: 5,
    },
    descriptionWrapper: {
        backgroundColor: colors.white,
        borderRadius: 7,
        paddingVertical: 15,
        paddingHorizontal: 10,
        marginBottom: 20,
        marginTop: 15,
    },
    listHeader: {
        marginBottom: 5,
    },
    input: {
        backgroundColor: colors.white,
        marginBottom: 15,
        textAlignVertical: 'top',
        borderRadius: 7,
        padding: 10,
        minHeight: 100,
        color: colors.primaryText,
    },
    descriptionLabel: {
        marginBottom: 5,
        marginTop: 15,
    },
    button: {
        marginTop: 15,
        marginBottom: 40,
    },
    text: {
        fontFamily: 'Vodafone Rg',
        color: colors.primaryText,
        fontSize: 16,
        lineHeight: 22,
    },
    bottomSpacing: { marginBottom: 5 },
}))

const itemStyles = styleSheetFactory((colors) => ({
    listInfoContainer: {
        flexGrow: 1,
        padding: 15,
        justifyContent: 'space-between',
        alignItems: 'center',
        flexDirection: 'row',
        height: 70,
    },
    firstItem_true: {
        borderTopLeftRadius: 7,
        borderTopRightRadius: 7,
    },
    lastItem_true: {
        borderBottomLeftRadius: 7,
        borderBottomRightRadius: 7,
    },
    listPriceContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        flexShrink: 0,
    },
}))

export { itemStyles }
export default style
