import React, { useContext } from 'react'
import { View, ScrollView } from 'react-native'
import PropTypes from 'prop-types'
import { useTheme } from 'react-native-themed-styles'

import {
  Text,
  InputPIN,
  Icon,
} from '../../Components'

import Theme from '../../Themes/Theme'

import images from '../../Themes/Images'

import themedStyles from './RegisterCertificate.Styles'

function Confirmation({
  value,
  setValue,
  resend,
}) {
  const [styles] = useTheme(themedStyles)
  const { colors } = useContext(Theme)

  return (
    <ScrollView
      keyboardShouldPersistTaps="always"
      showsVerticalScrollIndicator={false}
      keyboardDismissMode="on-drag"
      style={styles.scrollView}>
      <View style={styles.bottomSpacing}>
        <Text
          i18nKey="certificate_confirmation_label"
          align="left"
          size="h5"
        />
        <Text
          i18nKey="certificate_confirmation_code_description"
          align="left"
        />
        <View style={styles.codeConfirm}>
        <Icon source={images.padLock} size={80} />
          <Text
            i18nKey="certificate_enter_code_label"
            size="footnote"
            weight="light"
            align="center"
          />
          <InputPIN value={value} setValue={setValue} cellCount={5} />
          <Text
            i18nKey="certificate_resend_code_label"
            size="footnote"
            weight="light"
            color={colors.secondaryAccent}
            align="center"
            style={styles.underlinedText}
            onPress={resend}
          />
        </View>
      </View>
    </ScrollView>
  )
}
Confirmation.propTypes = {
  data: PropTypes.bool,
  newCertificate: PropTypes.func,
  value: PropTypes.string,
  setValue: PropTypes.func,
  onPress: PropTypes.func,
  resend: PropTypes.func,
  disabled: PropTypes.bool,
}

export default Confirmation
