import { styleSheetFactory } from '../../Themes/Colors'

const styles = styleSheetFactory(colors => ({
  modalContent: width => ({
    backgroundColor: colors.white,
    borderRadius: 6,
    alignSelf: 'center',
    width,
  }),
  contentContainer: {
    marginTop: 5,
    marginBottom: 30,
    marginHorizontal: 15,
    justifyContent: 'center',
    alignItems: 'center',
  },
  closeContainer: {
    backgroundColor: colors.background,
    borderTopRightRadius: 7,
    borderBottomLeftRadius: 7,
    width: 75,
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'flex-end',
    padding: 10,
  },
  icon: {
    tintColor: colors.gray,
  },
}))

export default styles

