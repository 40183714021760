import I18n from 'i18n-js'

class ContentManager {
    constructor() {
      ContentManager.dynamicContent = {}
    }

    configureI18nTranslations = (translation = {}) => {
        I18n.translations = {...I18n.translations, ...translation}
    }

    setupContentTranslationObject = (dynamicContentValues = {}) => {
      if (typeof dynamicContentValues !== 'object' && dynamicContentValues === null) {
        throw new Error(
          `Invalid value passed, expected an plain object but received ${JSON.stringify(
            dynamicContentValues
          )}`
        )
      }

      ContentManager.dynamicContent = dynamicContentValues
    }

    translate = (key, placeholdersValues = [], translationOptions = {}) => {
      const placeHolderRegEx = /%[1-9][0-9]*\$s/g
      let currentReplacementIndex = 0

      let value = ''
      if (ContentManager.dynamicContent[key]){
        value = ContentManager.dynamicContent[key]
      } else {
        value = I18n.t(key, { defaultValue: key, ...translationOptions })
      }

      const valueWithPlaceHolders = value.replace(placeHolderRegEx, () => {
        const replacement = placeholdersValues[currentReplacementIndex] || ''
        currentReplacementIndex++
        return replacement
      })

      return valueWithPlaceHolders
    }
  }

  export default new ContentManager()
