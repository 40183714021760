import React, { useRef, useState, useEffect } from 'react'
import { View, ScrollView, Platform } from 'react-native'
import LottieView from 'lottie-react-native'
import { useTheme } from 'react-native-themed-styles'
import RNHTMLtoPDF from 'react-native-html-to-pdf'
import Share from 'react-native-share'
import ShadowView from 'react-native-androw'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { useReactToPrint } from 'react-to-print'
import { useApolloClient } from '@apollo/client'

import { Margin, usePDF } from 'react-to-pdf'

import { getTotal } from '../../../Checkout/Checkout.Selectors'
import { Button, Header, Loader, Text } from '../../../../Components'
import { useLocationDetails } from '../../../../Utils/AuthDetails'
import { convertReceipt } from '../../../../Utils/PdfTemplates'
import { animations, colors, images } from '../../../../Themes'

import { TotalAndChange } from '../Components'
import { getLastTransaction, getPaymentCashMoney, getPaymentChangeMoney, getPaymentCustomer } from '../../Payment.Selectors'
import { updatePaymentAttribute } from '../../Payment.Actions'
import { PAYMENT_STEPS } from '../../Payment.Config'
import { resetOrder } from '../../../Checkout/Checkout.Actions'

import { useTemplateLanguage } from '../../../../Utils/TemplatesLanguage'
// eslint-disable-next-line import/no-unresolved
import { ConvertRecipt, ReactToPDF } from '../../../../Utils/PdfTemplatesWeb'
import { GET_QR } from '../../../../Utils/Utils.Schema'

import themedStyles from './Success.Styles'

function Success({ goBackCustom, otherCurrency, addListener }) {
  const [styles] = useTheme(themedStyles)
  const { translate } = useTemplateLanguage()
  const { NIPT, name, address } = useLocationDetails()
  // const convertReceiptWebRef = useRef()

  const dispatchAction = useDispatch()

  const customer = useSelector(getPaymentCustomer)
  const cash = useSelector(getPaymentCashMoney)
  const total = useSelector(getTotal)
  const change = useSelector(getPaymentChangeMoney)
  const lastTransaction = useSelector(getLastTransaction)

  const [loading, setLoading] = useState(false)
  const [loadingDownloadWeb, setLoadingDownloadWeb] = useState(false)
  const [loadingPrintWeb, setLoadingPrintWeb] = useState(false)
  const clearOrder = () => dispatchAction(resetOrder())
  const setPaymentStatus = (value) => dispatchAction(updatePaymentAttribute('paymentStatus', value))


  useEffect(() => {
    const unsubscribe = addListener('beforeRemove', e => {
      unsubscribe()
      if (Platform.OS === 'web') {
        clearOrder()
      }
      else {
        e.preventDefault()
        backToCheckout()
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const client = useApolloClient()

  const mapCustomer = (customerData) => {
    let customerDataFormatted = {}
    if (customerData?.id) {
      const {
        id: customerId,
        issuerNUIS,
        emailAddress,
        businessName,
        givenName,
        familyName,
        reference,
        address: {
          addressLine1,
          country,
          administrativeDistrictLevel1,
        },
        type,
      } = customerData
      customerDataFormatted = {
        customerId,
        emailAddress,
        address: `${addressLine1}, ${administrativeDistrictLevel1}, ${country}`,
      }
      if (type === 'INDIVIDUAL') {
        const { name: idName, value: idValue } = reference || {}
        customerDataFormatted = { ...customerDataFormatted, title: givenName + ' ' + familyName, idName, idValue }
      } else {
        customerDataFormatted = { ...customerDataFormatted, title: businessName, nipt: issuerNUIS }
      }
    }
    return customerDataFormatted
  }

  const createPdf = async () => {
    const { data: qr } = await client.query({
      query: GET_QR,
      variables: {
        text: lastTransaction.qrCode,
      },
    })

    const newCustomer = mapCustomer(customer)
    RNHTMLtoPDF.convert({
      html: convertReceipt(
        lastTransaction,
        cash,
        change,
        NIPT,
        name,
        address,
        newCustomer,
        translate,
        qr
      ),
      fileName: 'fature',
    })
      .then((res) => {
        setLoading(false)
        Share.open({
          url: `file://${res.filePath}`,
          subject: 'Fatura ' + lastTransaction?.receiptNumber,
          title: 'Fatura',
        })
          .catch(() => { })
      })
      .catch(() => setLoading(false))
  }

  const backToCheckout = () => {
    clearOrder()
    goBackCustom()
    setPaymentStatus(PAYMENT_STEPS.init)
  }

  const { toPDF, targetRef } = usePDF({
    method: 'save',
    filename: `${lastTransaction?.receiptNumber}.pdf`,
    page: { margin: Margin.NONE },
  })

  const handlePrintWeb = useReactToPrint({
    content: () => targetRef.current,
    onBeforePrint: () => setLoadingPrintWeb(true),
    onAfterPrint: () => setLoadingPrintWeb(false),
  })
  const handleDownload = async () => {
    setLoadingDownloadWeb(true)
    await toPDF()
    setLoadingDownloadWeb(false)
  }

  return (
    <>
      <Header
        title="checkout_payment_label"
        image={images.closeIcon}
        onPress={backToCheckout}
      />
      <ScrollView contentContainerStyle={styles.fillRemaining}>
        <View style={styles.detailsContainer}>
          <ShadowView style={styles.shadow}>
            <View style={styles.imageContainer}>
              <LottieView
                source={animations.celebrate}
                autoPlay
                loop={true}
                resizeMode="contain"
                style={styles.printAnimation}
              />
              <Text i18nKey="payment_success_description" style={styles.lottieText} size="h3" weight="bold" align="center" />
              <View style={styles.details}>
                <TotalAndChange
                  total={parseFloat(total || 0)}
                  cash={parseFloat(cash || 0)}
                  secondaryCurrency={otherCurrency?.type}
                  rate={otherCurrency?.rate}
                />
              </View>
            </View>
          </ShadowView>
        </View>
        <View style={styles.inlineButtons}>
          <Button
            title={Platform.OS === 'web' ? 'receipt_button_print_receipt' : 'orders_button_share_receipt'}
            icon={images.printerActive}
            iconStyle={{
              tintColor: colors.secondaryIcon,
            }}
            backgroundColor={colors.buttonSecondaryAccent}
            borderColor={colors.buttonSecondaryAccent}
            color={colors.secondaryIcon}
            variant={loading || loadingPrintWeb ? 'disabled' : 'secondary'}
            loader={loading || loadingPrintWeb}
            loaderComponent={<Loader source={animations.vfLoaderThinDark} size={24} />}
            style={styles.shareButton}
            onPress={() => {
              if (Platform.OS === 'web') {
                handlePrintWeb()
              } else {
                createPdf().catch(e => { })
              }
            }}
          />
          {Platform.OS === 'web' &&

            <Button
              title="receipt_download_as_pdf"
              icon={images.download}
              iconStyle={{
                tintColor: colors.secondaryIcon,
              }}
              backgroundColor={colors.buttonSecondaryAccent}
              borderColor={colors.buttonSecondaryAccent}
              color={colors.secondaryIcon}
              variant={loading || loadingDownloadWeb ? 'disabled' : 'secondary'}
              style={styles.shareButton}
              loader={loadingDownloadWeb}
              loaderComponent={<Loader source={animations.vfLoaderThinDark} size={24} />}
              onPress={handleDownload}
            />
          }
          <Button
            title="checkout_button_start_new"
            variant="active"
            style={styles.startNewButton}
            onPress={backToCheckout}
          />
        </View>
        {Platform.OS === 'web' &&
          <div style={styles.webStyles}>
            <ConvertRecipt
              transaction={lastTransaction}
              cashBuyer={cash}
              change={change}
              nipt={NIPT}
              company={name}
              location={address}
              customer={mapCustomer(customer)}
              ref={targetRef}
            />
          </div>
        }
      </ScrollView>
    </>
  )
}
Success.propTypes = {
  goBackCustom: PropTypes.func,
  otherCurrency: PropTypes.object,
  addListener: PropTypes.func,
}

export default Success
