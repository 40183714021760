import { styleSheetFactory } from '../../Themes/Colors'

const styles = styleSheetFactory(colors => ({
  noteIcon: {
    marginRight: 5,
  },
  touchableTextNote: {
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: colors.white,
    marginBottom: 10,
    paddingHorizontal: 10,
    paddingVertical: 15,
    borderRadius: 7,
  },
  description: {
    flexShrink: 1,
  },
}))

export default styles
