/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useContext, useEffect } from 'react'
import { View, TouchableOpacity, LayoutAnimation } from 'react-native'
import PropTypes from 'prop-types'
import { useTheme } from 'react-native-themed-styles'
import { useDispatch, useSelector } from 'react-redux'

import { getCurrency, getTotal } from '../../../../Checkout/Checkout.Selectors'

import {
  Text,
  Icon,
  InputWithTags,
  BottomSheetSelector,
  TouchableInput,
  Type,
  Button,
  Section,
} from '../../../../../Components'

import Delivery from '../Delivery'

import ContentManager from '../../../../../Utils/ContentManager'

import { getPaymentCashMoney, getPaymentCustomer, getPaymentDelivery, getPaymentMethod, getShouldHaveDelivery } from '../../../Payment.Selectors'
import { updatePaymentAttribute } from '../../../Payment.Actions'

import Theme, { opacityConfig } from '../../../../../Themes/Theme'
import { images } from '../../../../../Themes'

import themedStyles from './CashPaymentType.Styles'

const PAYMENT_METHODS_LABELS = {
  'CASH': 'payment_title_type_cash',
  'NONCASH': 'payment_title_type_card',
  'ORDER': 'payment_title_type_order',
  'EINVOICE': 'payment_title_type_voucher',
  'WAREHOUSE_NOTE': 'payment_label_type_warehouse',
  'REVERSE_CHARGE': 'payment_label_type_reverse_charge',
}

const ID_TYPES = {
  'NUIS': 'certificate_nipt_label',
  'ID': 'certificate_id_label',
  'PASS': 'customer_passport_number_label',
  'VAT': 'customer_vat_number_label',
  'TAX': 'customer_tax_number_label',
  'SOC': 'customer_soc_number_label',
}

function CashPaymentType({
  invoicingMethod,
  options,
  navigate,
  paymentMethods,
  currency,
}) {
  const [styles] = useTheme(themedStyles)
  const primaryCurrency = useSelector(getCurrency)
  const { colors } = useContext(Theme)
  const { translate } = ContentManager
  const [cashLimitError, setCashLimitError] = useState(false)

  const paymentMethod = useSelector(getPaymentMethod)
  const customer = useSelector(getPaymentCustomer)
  const cash = useSelector(getPaymentCashMoney)
  const total = useSelector(getTotal)
  const delivery = useSelector(getPaymentDelivery)
  const shouldHaveDelivery = useSelector(getShouldHaveDelivery)

  const dispatchAction = useDispatch()

  const setCustomer = (value) => dispatchAction(updatePaymentAttribute('customer', value))
  const setCash = (value) => dispatchAction(updatePaymentAttribute('cash', value))
  const setPaymentMethod = (value) => dispatchAction(updatePaymentAttribute('paymentMethod', value))
  const setDelivery = (value) => dispatchAction(updatePaymentAttribute('delivery', value))
  const setShouldHaveDelivery = (value) => dispatchAction(updatePaymentAttribute('shouldHaveDelivery', value))

  const mapCustomer = (customerData) => {
    const response = []
    const customerAddress = customerData?.address
    const NIPT = customerData?.issuerNUIS
    if (customerData?.type === 'BUSINESS') {
      response.push(
        {
          label: 'einvoice_details_nipt_label',
          value: NIPT,
        },
        {
          label: 'einvoice_commercial_name_label',
          value: customerData?.businessName,
        },
        {
          label: 'einvoice_details_address_label',
          value: customerAddress?.addressLine1,
        },
        {
          label: 'einvoice_details_city_label',
          value: customerAddress?.administrativeDistrictLevel1,
        },
        {
          label: 'einvoice_details_state_label',
          value: customerAddress?.country,
        },
        {
          label: 'users_email_label',
          value: customerData?.emailAddress,
        },
        {
          label: 'users_phone_label',
          value: customerData?.phoneNumber,
        }

      )
    } else if (customerData?.reference?.name) {
      response.push(
        {
          label: `${ID_TYPES[customerData?.reference?.name]}`,
          value: customerData?.reference?.value,
        },
        {
          label: 'users_first_name_label',
          value: `${customerData?.givenName} ${customerData?.familyName}`,
        },
        {
          label: 'einvoice_details_address_label',
          value: customerAddress?.addressLine1,
        },
        {
          label: 'einvoice_details_city_label',
          value: customerAddress?.administrativeDistrictLevel1,
        },
        {
          label: 'einvoice_details_state_label',
          value: customerAddress?.country,
        },
        {
          label: 'users_email_label',
          value: customerData?.emailAddress,
        },
        {
          label: 'users_phone_label',
          value: customerData?.phoneNumber,
        }

      )
    }
    else {
      response.push(
        {
          label: 'users_first_name_label',
          value: `${customerData?.givenName} ${customerData?.familyName}`,
        },
        {
          label: 'einvoice_details_address_label',
          value: customerAddress?.addressLine1,
        },
        {
          label: 'einvoice_details_city_label',
          value: customerAddress?.administrativeDistrictLevel1,
        },
        {
          label: 'einvoice_details_state_label',
          value: customerAddress?.country,
        },
        {
          label: 'users_email_label',
          value: customerData?.emailAddress,
        },
        {
          label: 'users_phone_label',
          value: customerData?.phoneNumber,
        }

      )

    }
    return response
  }

  const [customerItems, setCustomerItems] = useState(mapCustomer(customer) || [])

  const setCustomerData = (customerData) => {
    setCustomer(customerData)
    setCustomerItems(mapCustomer(customerData))
  }

  const replaceAt = (string, index, replacement) => {
    return string.substr(0, index) + replacement + string.substr(index + replacement.length)
  }

  const changeCash = (text) => {
    let newString = text + ''
    const indexOfComa = newString.indexOf(',')
    if (/^\d{0,12}(.\d{0,2})?$/.test(text)) {
      if (indexOfComa >= 0) {
        newString = replaceAt(text, indexOfComa, '.')
      }
      setCash(`${newString}`)
    }
  }

  const [searchValue, setSearchValue] = useState('')
  const [modal, setModal] = useState({
    isVisible: false,
    data: [],
    title: '',
    placeholder: '',
    selected: undefined,
    select: () => { },
    Item: Type,
  })

  const closeModal = () => {
    setModal(prev => ({ ...prev, isVisible: false }))
    setSearchValue('')
  }

  const openPaymentMethodSelector = () => {
    setModal({
      isVisible: true,
      data: paymentMethods,
      title: 'payment_total_method',
      placeholder: 'payment_total_method',
      selected: paymentMethod,
      select: setPaymentMethod,
      searchKey: 'code',
      Item: Type,
    })
  }

  useEffect(() => {
    if (primaryCurrency.currencyCode === 'ALL' && total > 150000) {
      setCashLimitError(true)
    } else {
      setCashLimitError(false)
    }
  }, [total])

  return (
    <View>
      {cashLimitError ? <Text i18nKey="payment_cash_helper" align="left" weight="light" style={styles.cashHelper} size="footnote" /> : null}
      <View style={styles.topContent}>
        <Text i18nKey="payment_total_method" weight="bold" style={styles.sectionTitle} />
        <TouchableInput
          onPress={() => openPaymentMethodSelector()}
          label="einvoice_payment_button"
          value={`${paymentMethod.code} - ${translate(paymentMethod.description)}`}
          icon={images.filledDownArrow}
          iconSize={16}
          autoCorrect={false}
          autoCapitalize="none"
          editable={false}
          style={styles.bottomSpacing}
        />

      </View>
      <Text style={styles.sectionText} weight="bold" i18nKey={PAYMENT_METHODS_LABELS[invoicingMethod]} />
      <InputWithTags
        label={PAYMENT_METHODS_LABELS[invoicingMethod]}
        suffix={currency.type}
        value={cash}
        onChangeText={(amount) => changeCash(amount)}
        suggestionType="number"
        suggestions={options}
        keyboardType="numeric"
        autoCompleteType="off"
        inputWrapperStyle={styles.cashPayment}
        tagsContentContainerStyle={styles.tagsContentContainer}
      />
      <View style={styles.content}>
        <>
          <View style={styles.customerHeader}>
            <Text i18nKey="customer_checkout_label" weight="bold" />
            {!customer?.id ? null :
              <TouchableOpacity style={styles.removeBuyer} onPress={() => {
                LayoutAnimation.configureNext(opacityConfig)
                setCustomer({})
                setCustomerItems([])
                setDelivery({})
              }}>
                <Icon source={images.closeIcon} color={colors.secondaryIcon} size={18} />
              </TouchableOpacity>}
          </View>
        </>
        {(!customer?.id) ? <Button
          title="einvoice_add_buyer_button"
          icon={images.addCustomer}
          iconColor={colors.accent}
          iconStyle={styles.rightSpacing}
          variant="preActive"
          style={styles.addCustomer}
          onPress={() => navigate('AllCustomers', { selectable: true, preselectedCustomer: customer, updateSelection: setCustomerData })}
        /> : <Section data={customerItems} />}
      </View>
      <Delivery
        delivery={delivery}
        setDelivery={setDelivery}
        customer={customer}
        shouldHaveDelivery={shouldHaveDelivery}
        setShouldHaveDelivery={setShouldHaveDelivery}
      />
      <BottomSheetSelector
        {...modal}
        closeModal={closeModal}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
      />
    </View>
  )
}
CashPaymentType.propTypes = {
  invoicingMethod: PropTypes.string,
  options: PropTypes.array,
  navigate: PropTypes.func,
  paymentMethods: PropTypes.array,
  currency: PropTypes.object,
}

export default CashPaymentType
