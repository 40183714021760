/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useCallback, useContext } from 'react'
import {
    LayoutAnimation,
    View,
} from 'react-native'
import { useTheme } from 'react-native-themed-styles'
import PropTypes from 'prop-types'
import { useQuery } from '@apollo/client'
import moment from 'moment'
import { useFocusEffect } from '@react-navigation/native'

import {
    Text,
    SimpleList,
    Icon,
    DoubleIcon,
} from '../../../../Components'

import images from '../../../../Themes/Images'
import { INF } from '../../../../Utils/Helpers'
import { useLanguage } from '../../../../Utils/Language'

import { LIST_MY_INVOICES } from '../../EInvoiceDetails.Schema'
import Theme, { layoutAnimConfig } from '../../../../Themes/Theme'
import themedStyles from '../MyEInvoiceDetails.Styles'
import { useConfig } from '../../../../Utils'

const LANGUAGES = {
    al: 'sq',
    en: 'en-gb',
}

function Refunds({ id, navigate }) {

  const { switchLocation: { deviceId, locationId } } = useConfig()

    const [styles] = useTheme(themedStyles)
    const { colors } = useContext(Theme)
    const lang = useLanguage()

    const [items, setItems] = useState([])

    const { data: refunds, refetch, fetchMore, called } = useQuery(
        LIST_MY_INVOICES, {
        fetchPolicy: 'network-only',
        variables: {
            locId: locationId,
            cashRegisterId: deviceId,
            limit: 30,
            filter: {
                name: 'baseUblId',
                value: id,
            },
        },
    })

    useEffect(() => {
        if (refunds) {
            LayoutAnimation.configureNext(layoutAnimConfig)
            setItems(mapResponse(refunds?.listMyEinvoices?.myEinvoices))
        }
    }, [refunds])

    useFocusEffect(
        useCallback(() => {
            if (called) {
                refetch()
            }
        }, [called]),
    )

    const mapNote = (prevNote) => {
        let note = {}
        prevNote?.map((element) => {
            const itemValue = element.substring(element.indexOf('=') + 1)
            const itemKey = element.substring(0, element.indexOf('='))
            const noteitem = { [itemKey]: itemValue }
            note = { ...note, ...noteitem }
        })
        return note
    }

    const renderRefundRightComponent = (rightLabel) => {
        return (
            <View
                style={styles.rightComponent}>
                <View style={styles.listRightComponent}>
                    <Text
                        size="footnote"
                        style={styles.itemRightLabel}
                        i18nKey={rightLabel}
                    />
                </View>
                <Icon
                    source={images.chevronRight}
                    size={24}
                    color={colors.primaryIcon}
                />
            </View>
        )
    }

    const renderRefundLeftComponent = (icon, statusIcon) => {
        return (
            <DoubleIcon
                statusIconSource={statusIcon}
                iconSource={icon}
                statusSize={10}
                iconSize={30}
                style={styles.leftSpacing}
                statusIconStyle={styles.bottomSpacing}
                iconStyle={{ tintColor: colors.secondaryIcon }}
                positionStatus="bottomRight"
            />
        )
    }

    const mapResponse = (data) => {
        const response = []
        let section = null
        data?.map((element, index) => {
            section = {
                id: 1,
                data: [],
            }
            response.push(section)
            const item = element.ublInvoice
            const note = mapNote(item.note)
            response[response.length - 1]?.data.push({
                id: note?.id,
                withIcon: true,
                touchableItems: true,
                leftComponent: renderRefundLeftComponent(images.cash, images.refund),
                title: item.id,
                description: moment(item?.issueDate)
                    .locale(LANGUAGES[lang])
                    .format('DD/MM/YYYY hh:mm A'),
                rightOrderComponent: renderRefundRightComponent(
                    INF.format(item?.legalMonetaryTotal?.payable?.amount) + ' ' + item?.legalMonetaryTotal?.payable?.currency,
                ),
                style: {
                    marginBottom: 7,
                },
                imageContainerStyle: {
                    minWidth: 50,
                },
                titleContainerStyle: {
                    paddingLeft: 10,
                },
                onItemPress: () =>
                    navigate('RefundDetails', {
                        id: note?.id,
                        title: item?.id,
                    }),
            })
        })
        return response
    }

    const onEndReached = () => {
        if (!refunds?.listMyEinvoices?.nextToken) {
          return
        }
        fetchMore({
          variables: {
            locId: locationId,
            cashRegisterId: deviceId,
            limit: 10,
            filter: {
                name: 'baseUblId',
                value: id,
            },
            nextToken: refunds.listMyEinvoices.nextToken,
          },
        })
      }

    return (
        <SimpleList
            DATA={items}
            containerStyle={styles.listContainerStyle}
            withSectionHeader
            sectionList
            initialNumToRender={20}
            refreshing={false}
            onRefresh={refetch}
            onEndReached={onEndReached}
            listEmptyComponent={<Text i18nKey="orders_no_refunds_label" align="center" style={styles.emptyComponent} />}
        />
    )
}
Refunds.propTypes = {
    id: PropTypes.string,
    navigate: PropTypes.func,
}

export default Refunds
