import React, { useContext, useEffect, useRef, useState } from 'react'
import { TextInput, View } from 'react-native'
import { useTheme } from 'react-native-themed-styles'
import PropTypes from 'prop-types'

import Text from '../Text'
import Icon from '../Icon'

import Theme from '../../Themes/Theme'
import themedStyles from './Note.Styles'
import { images } from '../../Themes'
import Colors from '../../Themes/Colors'

function Note({
  title,
  desc,
  withIcon,
  withInput,
  titleWeight,
  titleColor,
  inputValue,
  onChange,
  numberOfInputLines,
  containerStyle,
  inputStyle,
  withTitle = true,
  size,
  color,
  weight,
  onBlur,
}) {
  const [styles] = useTheme(themedStyles)
  const { colors } = useContext(Theme)
  const [height, setHeight] = useState(0)

  useEffect(() => {
    if (inputValue?.trim() === '') {
      setHeight(0)
    }
  }, [inputValue])


  return (
    <View style={[styles.container, containerStyle]}>
      <View style={styles.note}>
        {withIcon && <Icon source={images.info} size={20} style={styles.icon} />}
        {withTitle && <Text i18nKey={title || 'taxes_label_note'} weight={titleWeight} color={titleColor} />}
      </View>
      {!withInput && <Text i18nKey={desc} size={size} weight={weight || 'light'} color={color || colors.secondaryText} />}
      {withInput && <TextInput
        style={[styles.textInputStyle, inputStyle, height && ({ height: Math.max(35, height) })]}
        value={inputValue}
        onChangeText={(text) => onChange(text)}
        numberOfLines={numberOfInputLines}
        onBlur={inputValue?.trim() === '' ? onBlur : () => { }}
        multiline
        onContentSizeChange={(event) =>
          setHeight(event.nativeEvent.contentSize.height)
        }
        maxLength={250}
      />}
    </View>
  )
}

Note.propTypes = {
  title: PropTypes.string,
  desc: PropTypes.string,
  withIcon: PropTypes.bool,
  withInput: PropTypes.bool,
  titleWeight: PropTypes.string,
  titleColor: PropTypes.string,
  withTitle: PropTypes.bool,
  size: PropTypes.string,
  color: PropTypes.string,
  weight: PropTypes.string,
  inputValue: PropTypes.string,
  onChange: PropTypes.func,
  numberOfInputLines: PropTypes.number,
  containerStyle: PropTypes.object,
  inputStyle: PropTypes.object,
}
Note.defaultProps = {
  withIcon: true,
  withInput: false,
  titleColor: Colors.primaryText,
  titleWeight: 'bold',
  withTitle: true,
  size: 'footnote',

}

export default Note
