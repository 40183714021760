import React, {
    useContext,
    useRef,
} from 'react'
import {
    Animated,
    View,
    TouchableOpacity,
    Easing,
    ImageBackground,
} from 'react-native'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { useTheme } from 'react-native-themed-styles'
import Color from 'color'

import {
    Icon,
    Text,
    Loader,
    DiscountLabel,
} from '../../Components'
import {
    useConfig,
    ITEM_TYPES,
    INF,
} from '../../Utils'

import ContentManager from '../../Utils/ContentManager'

import {
    getCurrency,
} from './Checkout.Selectors'

import Theme from '../../Themes/Theme'
import images from '../../Themes/Images'
import {
    cardItemStyles,
    gridItemStyles,
    listItemStyles,
    optionStyles,
    headerStatusIconStyles,
    listStyles,
} from './Checkout.Styles'

const AnimatedTouchable = Animated.createAnimatedComponent(TouchableOpacity)

const TAX_INCLUSION_TYPES = ['INCLUSIVE', 'ADDITIVE']

const ITEM_STYLES = {
    card: cardItemStyles,
    grid: gridItemStyles,
    list: listItemStyles,
}

function Item({
    id,
    index,
    barcode,
    name,
    description,
    labelColor,
    tax,
    taxInclusionType,
    itemVariationsCount,
    modifierListsCount,
    discountsCount,
    itemVariation,
    discount,
    measurementUnitId,
    measurementUnit,
    taxExemptionType,
    imageUrl,
    addToBasket,
    configureProduct,
    addProductPrice,
    numOfLines,
    numCols,
    icon,
    refetchItems,
}) {
    const { itemType } = useConfig()
    const [styles] = useTheme(ITEM_STYLES[itemType])
    const { colors } = useContext(Theme)
    const otherCurrency = useSelector(getCurrency)

    const anim = useRef(new Animated.Value(1)).current
    const animate = (value) => {
        Animated.spring(anim, {
            toValue: value,
            useNativeDriver: true,
        }).start()
    }

    let price, currency, pricelabel, onPress, discCount = 0

    function filterPrice(prices, curr) {
        return prices?.find(pr => pr.currency === curr) || null
    }

    if (discountsCount === 1 && !filterPrice(discount?.prices, otherCurrency.currencyCode)) {
        discCount = 0
    } else {
        discCount = discountsCount
    }

    if (index === 0) {
        pricelabel = 'checkout_variable_label'
        onPress = (pressLocation) =>
            addProductPrice(pressLocation, {}, refetchItems, labelColor, imageUrl)
    } else {
        if (itemVariationsCount === 1) {
            if (modifierListsCount > 0 || discCount > 1) {
                onPress = (pressLocation) =>
                    configureProduct(
                        pressLocation,
                        id,
                        name,
                        labelColor,
                        imageUrl,
                    )
            } else {
                onPress = (pressLocation) => {
                    addToBasket(pressLocation, labelColor, imageUrl, {
                        itemName: name,
                        quantity: 1,
                        itemId: id,
                        variationName: itemVariation?.name,
                        variationId: itemVariation?.id,
                        basePriceMoney: filterPrice(itemVariation?.prices, otherCurrency.currencyCode),
                        appliedTax: tax,
                        taxInclusionType,
                        appliedDiscounts:
                            discountsCount === 1
                                ? [
                                    {
                                        id: discount.id,
                                        name: discount.name,
                                        discountType: discount.discountType,
                                        percentage: discount.percentage,
                                        appliedMoney: discount?.appliedMoney || filterPrice(discount?.prices, otherCurrency.currencyCode),
                                    },
                                ]
                                : [],
                        imageUrl,
                        labelColor,
                        barcode,
                        measurementUnitId,
                        measurementUnitName: measurementUnit?.name,
                        taxExemptionType,
                    })
                }
            }
            const priceMoney = filterPrice(itemVariation?.prices, otherCurrency.currencyCode)
            if (priceMoney?.amount) {
                price = INF.format(priceMoney?.amount)
                currency = otherCurrency?.currencyCode
            } else {
                onPress = (pressLocation) =>
                    addProductPrice(
                        pressLocation,
                        {
                            name: name,
                            quantity: 1,
                            id: id,
                            variationName: itemVariation?.name,
                            variationId: itemVariation?.id,
                            basePriceMoney: filterPrice(itemVariation?.prices, otherCurrency.currencyCode),
                            appliedTax: tax,
                            taxInclusionType,
                            appliedDiscounts:
                                discCount === 1
                                    ? [
                                        {
                                            id: discount?.id,
                                            name: discount?.name,
                                            discountType: discount?.discountType,
                                            percentage: discount?.percentage,
                                            appliedMoney: filterPrice(discount?.prices, otherCurrency?.currencyCode) || discount.amountMoney,
                                        },
                                    ]
                                    : [],
                            imageUrl,
                            labelColor,
                            barcode,
                            measurementUnitId,
                            measurementUnitName: measurementUnit?.name,
                            taxExemptionType,
                        },
                        () => { },
                        labelColor,
                        imageUrl,
                    )
                pricelabel = 'checkout_variable_label'
            }
        } else if (itemVariationsCount === 0) {
            pricelabel = 'checkout_variable_label'
            if (modifierListsCount > 0 || discCount > 1) {
                onPress = (pressLocation) =>
                    configureProduct(
                        pressLocation,
                        id,
                        name,
                        labelColor,
                        imageUrl,
                    )
            } else {
                onPress = (pressLocation) =>
                    addProductPrice(
                        pressLocation,
                        {
                            name: name,
                            quantity: 1,
                            id: id,
                            variationName: itemVariation?.name,
                            variationId: itemVariation?.id,
                            basePriceMoney: filterPrice(itemVariation?.prices, otherCurrency.currencyCode),
                            appliedTax: tax,
                            taxInclusionType,
                            appliedDiscounts:
                                discCount === 1
                                    ? [
                                        {
                                            id: discount?.id,
                                            name: discount?.name,
                                            discountType: discount?.discountType,
                                            percentage: discount?.percentage,
                                            appliedMoney: filterPrice(discount?.prices, otherCurrency?.currencyCode) || discount.amountMoney,
                                        },
                                    ]
                                    : [],
                            imageUrl,
                            labelColor,
                            barcode,
                            measurementUnitId,
                            measurementUnitName: measurementUnit?.name,
                            taxExemptionType,
                        },
                        () => { },
                        labelColor,
                        imageUrl,
                    )
            }
        } else {
            onPress = (pressLocation) =>
                configureProduct(
                    pressLocation,
                    id,
                    name,
                    labelColor,
                    imageUrl,
                )
            pricelabel = itemVariationsCount + ContentManager.translate('checkout_variable_price_label')
        }
    }

    return (
        <Animated.View
            style={[
                styles.container(numCols),
                { transform: [{ scale: anim }] },
            ]}>
            <TouchableOpacity
                style={styles.touchable}
                onPressIn={() => animate(1.05)}
                onPressOut={() => animate(1)}
                onPress={({ nativeEvent }) =>
                    onPress({
                        pageX: nativeEvent.pageX,
                        pageY: nativeEvent.pageY,
                    })
                }
                activeOpacity={0.7}
                onLongPress={({ nativeEvent }) => {
                    if (index === 0) {
                        onPress({
                            pageX: nativeEvent.pageX,
                            pageY: nativeEvent.pageY,
                        })
                    } else {
                        configureProduct(
                            {
                                pageX: nativeEvent.pageX,
                                pageY: nativeEvent.pageY,
                            },
                            id,
                            name,
                            labelColor,
                            imageUrl,
                        )
                    }
                }}
                disabled={!onPress}>
                {imageUrl ? (
                    <ImageBackground
                        source={{ uri: imageUrl, cache: 'force-cache' }}
                        style={styles.imageContainer}
                        resizeMode="cover"
                    />
                ) : (
                    <View
                        style={[
                            styles.imageContainer,
                            {
                                backgroundColor: Color(labelColor || colors.transparent)
                                    .fade(0.65)
                                    .toString(),
                            },
                        ]}>
                        {icon ? (
                            <Icon
                                source={icon}
                                style={styles.image}
                                color={colors.secondaryIcon}
                            />
                        ) : (
                            <Text
                                size={itemType === ITEM_TYPES.list ? 'h5' : 'h2'}
                                color={labelColor}
                                i18nKey={name
                                    ?.match(/\b(\w{1})/g)
                                    ?.slice(0, 2)
                                    ?.join('')
                                    ?.toUpperCase()}
                            />
                        )}
                    </View>
                )}
                <View style={styles.infoContainer}>
                    <View style={styles.descContainer}>
                        <Text i18nKey={name} numberOfLines={numOfLines} style={styles.descContainer} />
                        {Boolean(description) && (
                            <Text
                                i18nKey={description}
                                weight="light"
                                color={colors.secondaryText}
                                size="footnote"
                                numberOfLines={numOfLines}
                            />
                        )}
                    </View>
                    <View style={styles.priceContainer}>
                        {pricelabel ? (
                            <Text i18nKey={pricelabel} />
                        ) : (
                            <Text
                                i18nKey="checkout_price_currency_value"
                                placeHolders={[`${price}`, currency]}
                            />
                        )}
                    </View>
                </View>
                <DiscountLabel discountsCount={discCount} discount={discount} />
            </TouchableOpacity>
        </Animated.View>
    )
}

Item.defaultProps = {
    discount: {},
}
Item.propTypes = {
    id: PropTypes.string,
    index: PropTypes.number,
    barcode: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
    labelColor: PropTypes.string,
    measurementUnitId: PropTypes.string,
    measurementUnit: PropTypes.shape({
        id: PropTypes.string,
        precision: PropTypes.number,
        abbreviation: PropTypes.string,
        name: PropTypes.string,
    }),
    tax: PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        percentage: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        inclusionType: PropTypes.string,
    }),
    discountsCount: PropTypes.number,
    discount: PropTypes.object,
    taxInclusionType: PropTypes.oneOf(TAX_INCLUSION_TYPES),
    itemVariationsCount: PropTypes.number,
    modifierListsCount: PropTypes.number,
    itemVariation: PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        priceMoney: PropTypes.shape({
            amount: PropTypes.number,
            currency: PropTypes.string,
        }),
        prices: PropTypes.array,
    }),
    imageUrl: PropTypes.string,
    addToBasket: PropTypes.func,
    configureProduct: PropTypes.func,
    addProductPrice: PropTypes.func,
    numOfLines: PropTypes.number,
    numCols: PropTypes.number,
    icon: PropTypes.object,
    goBack: PropTypes.func,
    refetchItems: PropTypes.func,
    taxExemptionType: PropTypes.string,
}

function CurrencyOption(props) {
    const [styles] = useTheme(optionStyles)
    const { colors } = useContext(Theme)

    const {
        title,
        onPress,
        disabled,
        style,
    } = props

    return (
        <TouchableOpacity
            onPress={onPress}
            style={[styles.container, style]}
            disabled={disabled}>
            <Text i18nKey={title} size="body" color={colors.primaryText} />
            <Icon source={images.arrow_up} style={{ transform: [{ rotate: '180deg' }] }} size={12} color={colors.accent} />
        </TouchableOpacity>
    )
}
CurrencyOption.defaultProps = {
    onPress: () => { },
    disabled: false,
}
CurrencyOption.propTypes = {
    onPress: PropTypes.func,
    style: PropTypes.object,
    title: PropTypes.string,
    disabled: PropTypes.bool,
}

function HeaderStatusIcon(props) {
    const [styles] = useTheme(headerStatusIconStyles)
    const { colors } = useContext(Theme)

    const {
        isActive,
        withBadge,
        badgeCount,
        activeIcon,
        inactiveIcon,
        activeColor = colors.secondaryIcon,
        inactiveColor = colors.secondaryIcon,
        onPress,
        style,
    } = props

    let icon = activeIcon,
        color = activeColor,
        badgeContent = null

    if (isActive) {
        icon = activeIcon
        color = activeColor
    } else {
        icon = inactiveIcon
        color = inactiveColor
    }

    if (withBadge) {
        const count = badgeCount > 99 ? '99+' : badgeCount
        badgeContent = (
            <View style={styles.statusContainer}>
                {Boolean(count) && <Text i18nKey={count} color={colors.white} style={styles.status} />}
            </View>
        )
    }

    return (
        <TouchableOpacity
            onPress={onPress}
            disabled={!onPress}
            style={[styles.container, style]}>
            <Icon source={icon} size={28} color={color} />
            {badgeContent}
        </TouchableOpacity>
    )
}
HeaderStatusIcon.defaultProps = {
    isActive: true,
    badgeCount: 0,
    activeIcon: images.defaultIcon,
    inactiveIcon: images.defaultIcon,
}
HeaderStatusIcon.propTypes = {
    isActive: PropTypes.bool,
    withBadge: PropTypes.bool,
    badgeCount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    onPress: PropTypes.func,
    style: PropTypes.object,
}

function RenderList({
    numCols,
    addToBasket,
    configureProduct,
    addProductPrice,
    loading,
    items,
    refetch,
    fetchMore,
    containerStyle,
    contentStyle,
    ...rest
}) {
    const [styles] = useTheme(listStyles)
    const { colors } = useContext(Theme)

    const listRef = useRef(null)
    const fabSize = useRef(new Animated.Value(0)).current
    const animate = (value) => {
        Animated.timing(fabSize, {
            toValue: value,
            duration: 370,
            useNativeDriver: true,
            easing: Easing.inOut(Easing.cubic),
        }).start()
    }
    const scrollToTop = () => {
        animate(0)
        listRef.current.scrollToOffset({
            offset: 0,
        })
    }
    const numOfLines = numCols > 1 ? 2 : 1

    const keyExtractor = (item, index) => `${item.id}-${index}`
    const renderItem = ({ item, index }) => {
        return (<Item
            key={'item-' + index}
            {...item}
            index={index}
            addToBasket={addToBasket}
            configureProduct={configureProduct}
            addProductPrice={addProductPrice}
            numOfLines={numOfLines}
            numCols={numCols}
            refetchItems={refetch}
        />)
    }

    return (
        <View style={styles.listContainer}>
            <Animated.FlatList
                key={'list' + numCols}
                ref={(ref) => (listRef.current = ref)}
                onMomentumScrollEnd={(event) => {
                    if (event.nativeEvent.contentOffset.y > 800) {
                        animate(1)
                    } else {
                        animate(0)
                    }
                }}
                showsVerticalScrollIndicator={false}
                style={containerStyle}
                contentContainerStyle={[styles.contentContainer, contentStyle]}
                keyExtractor={keyExtractor}
                data={items}
                numColumns={numCols}
                renderItem={renderItem}
                refreshing={false}
                onRefresh={refetch}
                onEndReached={fetchMore}
                ListFooterComponent={
                    <View style={styles.footer}>
                        {loading ? <Loader size={32} /> : null}
                    </View>
                }
                {...rest}
            />
            <AnimatedTouchable
                onPress={scrollToTop}
                activeOpacity={0.5}
                style={[
                    styles.fab,
                    {
                        opacity: fabSize,
                        transform: [{ scale: fabSize }],
                    },
                ]}>
                <Icon
                    source={images.arrow_right}
                    size={20}
                    color={colors.tertiaryIcon}
                    style={styles.fabIcon}
                />
            </AnimatedTouchable>
        </View>
    )
}
RenderList.propTypes = {
    id: PropTypes.string,
    numCols: PropTypes.number,
    addToBasket: PropTypes.func,
    configureProduct: PropTypes.func,
    addProductPrice: PropTypes.func,
    loading: PropTypes.bool,
    items: PropTypes.array,
    refetch: PropTypes.func,
    fetchMore: PropTypes.func,
    containerStyle: PropTypes.object,
    contentStyle: PropTypes.object,
}

export {
    Item,
    HeaderStatusIcon,
    RenderList,
    CurrencyOption,
}
