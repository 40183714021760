import { styleSheetFactory } from '../../../../../Themes/Colors'

const styles = styleSheetFactory(colors => ({
    containerStyle: {
        paddingHorizontal: 15,
        paddingBottom: 30,
    },
    inputContainer: {
        minHeight: 60,
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
    },
    itemSpacing: {
        marginVertical: 5,
    },
    header: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    stateSelector: { marginBottom: 5, marginTop: 5 },
    citySelector: { marginBottom: 0, marginVertical: 5 },
}))

const itemStyles = styleSheetFactory(colors => ({
    container: {
        paddingVertical: 7.5,
    },
    itemSelected_false: {
        flexDirection: 'row',
        marginHorizontal: 15,
        borderRadius: 10,
        paddingVertical: 10,
        paddingHorizontal: 15,
        backgroundColor: colors.white,
        alignItems: 'center',
        height: 60,
    },
    itemSelected_true: {
        flexDirection: 'row',
        marginHorizontal: 15,
        borderRadius: 10,
        paddingVertical: 10,
        paddingHorizontal: 15,
        backgroundColor: colors.white,
        alignItems: 'center',
        height: 60,
        borderWidth: 1,
        borderColor: colors.secondaryAccent,
    },
    itemTextSelected_false: {
        flexGrow: 1,
        flexShrink: 1,
    },
    itemTextSelected_true: {
        flexGrow: 1,
        flexShrink: 1,
    },
    separator: {
        width: 1,
        backgroundColor: colors.disabled,
        marginHorizontal: 15,
        height: 40,
    },
    isDisabled_true: {
        backgroundColor: colors.secondaryBackround,
    },
    itemCode: {
        minWidth: 30,
        maxWidth: '40%',
    },
}))

export { itemStyles }
export default styles
