import { Platform } from 'react-native'

import { styleSheetFactory } from '../../Themes/Colors'

const isWeb = Platform.OS === 'web'

const styles = styleSheetFactory(colors => ({
    container: {
        flex: 1,
    },
    itemsInfo: {
        fontSize: 12,
        alignSelf: 'center',
        color: colors.secondaryText,
        marginTop: 10,
        marginBottom: 30,
    },
    listStyle: {
        marginHorizontal: 15,
    },
    fullIssueButton: {
        height: 50,
        marginBottom: 15,
    },
    titleStyle: {
        color: colors.buttonSecondaryAccent,
        fontSize: 16,
    },
    amountContainer: {
        marginTop: 5,
    },
    inputRow: {
        marginTop: 10,
    },
    listInfoContainer: {
        paddingRight: 15,
        alignItems: 'center',
        flexDirection: 'row',
        backgroundColor: colors.tertiaryBackground,
        overflow: 'hidden',
        minHeight: 70,
    },
    listDescContainer: {
        flexGrow: 1,
        flexShrink: 1,
        paddingLeft: 15,
        flexDirection: 'column',
    },
    listPriceContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        flexShrink: 0,
        backgroundColor: colors.tertiaryBackground,
    },
    listFirst: {
        borderTopLeftRadius: 7,
        borderTopRightRadius: 7,
    },
    listLast: {
        borderBottomLeftRadius: 7,
        borderBottomRightRadius: 7,
    },
    separator: {
        height: 1,
        backgroundColor: colors.separator,
    },
    separatorContainer: {
        backgroundColor: colors.tertiaryBackground,
        height: 1,
    },
    footerComponent: {
        marginHorizontal: 15,
    },
    quantityContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        marginRight: 10,
        justifyContent: 'space-between',
    },
    quantityButton: {
        width: 30,
        height: 30,
        borderRadius: 7,
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: colors.separator,
        justifyContent: 'center',
        alignItems: 'center',
    },
    quantityInput: {
        width: 75,
        height: 30,
        fontSize: 22,
        paddingVertical: 2,
        color: colors.primaryText,
        alignSelf: 'center',
        alignItems: 'center',
        textAlign: 'center',
    },
    topSpacing: {
        marginTop: 20,
    },
    checkAllContainer: {
        flexDirection: 'row',
        marginVertical: 20,
        justifyContent: 'space-between',
        marginHorizontal: 15,
    },
    checkAll: {
        marginRight: isWeb ? 18 : 15,
    },
}))

export default styles
