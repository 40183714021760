import { gql } from '@apollo/client'

//example on how to pass parameters
export const CREATE_DISCOUNT = gql`
  mutation createDiscount(
    $prices: [MoneyInput],
    $discountType: DiscountType, 
    $labelColor: String, 
    $modifyTaxBasis: String, 
    $name: String!, 
    $percentage: Float, 
    $pinRequired: Boolean, 
    $locationId:String,
    $locId: String, 
    $cashRegisterId: String
  ) {
    createDiscount(discountInput:{
      prices: $prices
      discountType: $discountType
      labelColor: $labelColor
      modifyTaxBasis: $modifyTaxBasis
      name: $name
      percentage: $percentage
      pinRequired: $pinRequired
    }
    locationId:$locationId
    requestedLocationAndCashRegister: {
      locationId: $locId,
      cashRegisterId: $cashRegisterId
    }
    ){
      id
      name
      discountType
      percentage
    }
  }
`


export const EDIT_DISCOUNT = gql`
  mutation updateDiscount(
    $id: String!, 
    $prices: [MoneyInput],
    $discountType: DiscountType, 
    $labelColor: String, 
    $modifyTaxBasis: String, 
    $name: String, 
    $percentage: Float, 
    $pinRequired: Boolean
    $locationId:String
    $locId: String, 
    $cashRegisterId: String
  ){
    updateDiscount(discountUpdateInput:{
      id: $id,
      prices: $prices
      discountType: $discountType
      labelColor: $labelColor
      modifyTaxBasis: $modifyTaxBasis
      name: $name
      percentage: $percentage
      pinRequired: $pinRequired
    }
    locationId:$locationId
    requestedLocationAndCashRegister: {
      locationId: $locId,
      cashRegisterId: $cashRegisterId
    }
    )
    {
      id
      name
      discountType
      percentage
    }
  }
`

export const GET_DISCOUNT = gql`
  query getDiscount($id: String!, $locationId:String , $locId: String, $cashRegisterId: String){
    getDiscount(
    id: $id
    locationId:$locationId
    requestedLocationAndCashRegister: {
      locationId: $locId,
      cashRegisterId: $cashRegisterId
    }
    ){
      id
        name
        prices {
          amount
          currency
        }
        discountType
        labelColor
        modifyTaxBasis
        percentage
    }
  }
`
