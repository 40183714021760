import { CommonActions } from '@react-navigation/native'

const filterOutCart = (state) => {
  const routes = state.routes?.filter((route) => route.name !== 'Cart')
  return CommonActions.reset({
    ...state,
    routes,
    index: routes.length - 1,
  })
}

export default filterOutCart
