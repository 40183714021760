/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useContext, useRef, useEffect } from 'react'
import {
  View,
  SafeAreaView,
  FlatList,
  TouchableOpacity,
  TextInput,
  RefreshControl,
  Platform,
} from 'react-native'
import PropTypes from 'prop-types'
import { useMutation, useQuery } from '@apollo/client'
import { v1 as uuidv1 } from 'uuid'
import _ from 'lodash'
import { useTheme } from 'react-native-themed-styles'

import { ScrollView } from 'react-native-gesture-handler'

import { getErrorMessage, useConfig } from '../../Utils/index'

import {
  Button,
  RadioInput,
  SimpleListElement,
  Text,
  Loader,
  Modal,
  Icon,
  Header,
  ModalContent,
  InputWithTags,
} from '../../Components'
import { CREATE_REFUND, GET_TRANSACTION } from '../Orders/Orders.schema'

import calculatePrice, { clearRefundExtraDetails } from '../../Utils/Order'

import ContentManager from '../../Utils/ContentManager'

import useTaxDetails from '../../Utils/Tax'

import Theme from '../../Themes/Theme'

import themedStyles from './IssueRefund.Styles'

import images, { animations } from '../../Themes/Images'

function ReasonInput({ item, index, selected, onPress, onChangeText, value }) {
  const [styles] = useTheme(themedStyles)
  const { colors } = useContext(Theme)

  let propStyle = {}
  if (item.isFirst) {
    propStyle = styles.listFirst
  }
  if (item.isLast) {
    propStyle = styles.listLast
  }
  const style = Object.assign({}, styles.default, styles.list, propStyle)

  const inputRef = useRef()

  if (index === selected) {
    inputRef.current?.focus()
  }

  return (
    <View style={[{ backgroundColor: colors.tertiaryBackground }, style]}>
      <TouchableOpacity style={style} onPress={onPress}>
        <View style={styles.listInfoContainer}>
          <TextInput
            ref={(ref) => {
              inputRef.current = ref
            }}
            placeholder={item.placeholder}
            keyboardType="default"
            placeholderTextColor={colors.placeholder}
            style={styles.input}
            onChangeText={onChangeText}
            value={value}
            autoCorrect={false}
            maxLength={25}
          />
          <View style={styles.listPriceContainer}>
            <View pointerEvents="none">
              <RadioInput
                index={index}
                selected={selected}
                onPress={onPress}
                style={styles.selector}
                color={colors.secondaryAccent}
              />
            </View>
          </View>
        </View>
      </TouchableOpacity>
    </View>
  )
}
ReasonInput.propTypes = {
  item: PropTypes.object,
  index: PropTypes.number,
  selected: PropTypes.number,
  onPress: PropTypes.func,
  onChangeText: PropTypes.func,
  value: PropTypes.string,
}

function Item(props) {
  const [styles] = useTheme(themedStyles)
  const { colors } = useContext(Theme)

  const { item, index, selected, onPress } = props

  if (item.inputItem) {
    return <ReasonInput {...props} />
  }

  let propStyle = {}
  if (item.isFirst) {
    propStyle = styles.listFirst
  }
  if (item.isLast) {
    propStyle = styles.listLast
  }
  const style = Object.assign({}, styles.default, styles.list, propStyle)

  return (
    <View style={[{ backgroundColor: colors.tertiaryBackground }, style]}>
      <TouchableOpacity style={style} onPress={onPress}>
        <View style={styles.listInfoContainer}>
          <View style={styles.listDescContainer}>
            <Text i18nKey={item.title} numberOfLines={1} size="footnote" />
          </View>
          <View style={styles.listPriceContainer}>
            <View pointerEvents="none">
              <RadioInput
                index={index}
                selected={selected}
                onPress={onPress}
                style={styles.selector}
                color={colors.secondaryAccent}
              />
            </View>
          </View>
        </View>
      </TouchableOpacity>
    </View>
  )
}
Item.propTypes = {
  item: PropTypes.object,
  index: PropTypes.number,
  selected: PropTypes.number,
  onPress: PropTypes.func,
}

function IssueRefund(props) {
  const {
    route: {
      params: {
        refundItems,
        transactionId,
        type,
        customerId,
        bankId,
        shippingAddress,
        oldInvoice = {},
      },
    },
    navigation: {
      navigate,
      goBack,
    },
  } = props

  const { switchLocation: { deviceId, locationId } } = useConfig()


  const [styles] = useTheme(themedStyles)
  const { colors } = useContext(Theme)
  const { taxDetails: { tax: { percentage, id: taxId, name: taxName } } } = useTaxDetails()

  //skip query if oldInvoice

  const { loading: transactionLoading, data: transaction } = useQuery(GET_TRANSACTION, {
    fetchPolicy: 'network-only',
    skip: (Object.keys(oldInvoice).length > 0),
    variables: {
      locationId: 'K11715005L',
      locId: locationId,
      cashRegisterId: deviceId,
      transactionId,
    },
  })



  const [selected, setSelected] = useState(-1)
  const [name, setName] = useState('')
  const [inputValue, setValue] = useState('')
  const [amount, setAmount] = useState('')

  const numberRegex = /^[0-9]*$/

  const isNumber = (num) => {
    if (Object.keys(oldInvoice).length > 0) {
      return num ? numberRegex.test(num) : false
    }
    else {
      return true
    }

  }

  const [order, setOrder] = useState({
    lineItems: [],
    totalMoney: {
      amount: 0,
      currency: 'Lekë',
    },
    totalTaxMoney: {
      amount: 0,
      currency: 'Lekë',
    },
    totalDiscountMoney: {
      amount: 0,
      currency: 'Lekë',
    },
  })

  const translate = (value) => {
    return ContentManager.translate(value)
  }

  const [modal, setModal] = useState({
    isVisible: false,
    icon: images.warningIcon,
    title: '',
    desc: '',
    descPlaceholders: [],
    primaryText: '',
    primaryAction: null,
    secondaryText: '',
    secondaryAction: null,
  })

  const openModal = (
    title = 'opened_orders_error',
    desc = '',
    descPlaceholders = [],
    primaryText = '',
    primaryAction = null,
    secondaryText = '',
    secondaryAction = null,
    icon = images.warningIcon,
  ) => {
    setModal({
      isVisible: true,
      icon,
      title,
      desc,
      descPlaceholders,
      primaryText,
      primaryAction,
      secondaryText,
      secondaryAction,
    })
  }
  const closeModal = () => {
    setModal({
      isVisible: false,
      icon: images.delete,
      title: '',
      desc: '',
      primaryText: '',
      primaryAction: null,
      secondaryText: '',
      secondaryAction: null,
    })
  }

  const items = [
    {
      id: 1,
      title: 'orders_refund_reason_return',
      subtitle: translate('orders_refund_reason_return'),
      isFirst: true,
    },
    {
      id: 2,
      title: 'orders_refund_reason_accidental',
      subtitle: translate('orders_refund_reason_accidental'),
    },
    {
      id: 3,
      title: 'orders_refund_reason_cancel',
      subtitle: translate('orders_refund_reason_cancel'),
    },
    {
      id: 4,
      title: 'orders_refund_reason_fraud',
      subtitle: translate('orders_refund_reason_fraud'),
    },
    {
      id: 5,
      inputItem: true,
      placeholder: translate('orders_refund_reason_other'),
      subtitle: inputValue,
      isLast: true,
    },
  ]

  const [createRefund, { loading, data, error }] = useMutation(CREATE_REFUND)

  const createRefundd = () => {
    if (Object.keys(oldInvoice).length > 0) {
      const totalMoney = amount
      const totalTaxMoney = totalMoney - totalMoney / (1 + percentage / 100)
      const groupTaxes = [
        {
          'VATRate': percentage,
          'NumOfItems': 1,
          'PriceBefVat': {
            'amount': totalMoney - totalTaxMoney,
            'currency': 'ALL',
          },
          'VATAmt': {
            'amount': totalTaxMoney,
            'currency': 'ALL',
          },
        },
      ]

      const lineItems = [
        {
          'id': `${oldInvoice.IICRef}@${oldInvoice.InvoiceDate}`,
          'itemName': 'artikull',
          'quantity': 1,
          'itemId': null,
          'variationName': null,
          'variationId': null,
          'basePriceMoney': {
            'amount': amount,
            'currency': 'ALL',
          },
          'modifiers': [

          ],
          'appliedTax': {
            'id': taxId,
            'name': taxName,
            'percentage': percentage,
          },
          'appliedDiscounts': [

          ],
          'totalTaxMoney': {
            'amount': totalTaxMoney,
            'currency': 'ALL',
          },
          'totalDiscountMoney': {
            'amount': 0,
            'currency': 'ALL',
          },
          'totalMoney': {
            'amount': amount,
            'currency': 'ALL',
          },
          'imageUrl': null,
          'labelColor': '#EF5350',
          'taxInclusionType': 'INCLUSIVE',
          'taxExemptionType': null,
        },
      ]
      createRefund({
        variables: {
          locationId: oldInvoice?.NUIS,
          locId: locationId,
          cashRegisterId: deviceId,
          transactionId: 'INVOICE_FROM_GOV_BOOKS',
          idempotencyKey: uuidv1(),
          reason: name,
          type: 'BANKNOTE',
          bankAccount: null,
          shippingAddress: null,
          lineItems: lineItems,
          totalMoneyAmount: totalMoney,
          totalMoneyCurrency: 'ALL',
          taxMoneyCurrency: 'ALL',
          discountMoneyCurrency: 'ALL',
          taxMoneyAmount: totalTaxMoney,
          discountMoneyAmount: 0,
          orderGroupedTaxes: groupTaxes,
          appliedDiscounts: [],
        },
      })
    }
    else {
      const cleanedOrder = clearRefundExtraDetails({
        lineItems: refundItems,
      })
      createRefund({
        variables: {
          locationId: 'K11715005L',
          locId: locationId,
          cashRegisterId: deviceId,
          transactionId: transactionId,
          idempotencyKey: uuidv1(),
          reason: name,
          type: type,
          customerId: customerId,
          bankAccount: bankId ? { id: bankId } : null,
          shippingAddress: _.isEmpty(shippingAddress) ? null : shippingAddress,
          lineItems: cleanedOrder?.lineItems,
          totalMoneyAmount: order?.totalMoney?.amount,
          totalMoneyCurrency: order?.totalMoney?.currency,
          taxMoneyCurrency: order?.totalTaxMoney?.currency,
          discountMoneyCurrency: order?.totalDiscountMoney?.currency,
          taxMoneyAmount: order?.totalTaxMoney?.amount,
          discountMoneyAmount: order?.totalDiscountMoney?.amount,
          orderGroupedTaxes: order?.orderGroupedTaxes,
          appliedDiscounts: order?.appliedDiscounts,
        },
      })
    }
  }

  useEffect(() => {
    if (transaction) {
      const transactionOrder = transaction?.getTransaction?.order
      const orderWithPrice = calculatePrice({ ...transactionOrder, lineItems: refundItems }, false)
      const cleanOrder = clearRefundExtraDetails(orderWithPrice)
      setOrder({ ...cleanOrder })
    }
  }, [transaction])

  useEffect(() => {
    if (data) {
      // displayToast('receipt_refund_success')
      const itemData = data.createRefund
      navigate('Refund', {
        id: itemData.transactionId,
        refundId: itemData.id,
        type: 'goToList',
      })
    }
  }, [data])

  const handleError = (e) => {
    if (e) {
      const errorMessage = getErrorMessage(e)
      if (errorMessage === 'RefundNoOpenDrawer') {
        openModal(
          'receipt_drawer_warning_title',
          'receipt_drawer_warning_description',
          [],
          'receipt_button_open_drawer',
          () => {
            navigate('CurrentDrawer', { nextAction: 'refund_continue_to_refund' })
            closeModal()
          },
          'orders_button_go_back',
          () => closeModal(),
        )
      } else {
        openModal(
          'opened_orders_error',
          errorMessage,
          [],
          'orders_button_go_back',
          () => closeModal(),
          '',
          undefined,
        )
      }
    }
  }

  useEffect(() => {
    handleError(error)
  }, [error])


  return (
    <SafeAreaView style={styles.container}>
      <Header
        title="header_issue_refund"
        image={images.back}
        onPress={() => goBack()}
      />
      <ScrollView>
        {Object.keys(oldInvoice).length > 0
          ? <View style={styles.inputWrapper}>
            <Text i18nKey={'orders_old_refund_amount_label'} style={styles.subHeader} />
            <InputWithTags
              label="drawer_label_amount"
              value={amount}
              suffix="ALL"
              onChangeText={setAmount}
              autoCorrect={false}
              autoCapitalize="none"
              inputContainerStyle={styles.inputContainer}
              inputWrapperStyle={[styles.itemSpacing, Platform.OS === 'web' && { marginHorizontal: 15 }]}
              labelOffset={{
                x0: 0,
                y0: -7,
                x1: 0,
                y1: 2,
              }}
            />
          </View>
          :
          <SimpleListElement
            leftText="receipt_cash"
            rightText={`${order?.totalMoney?.amount} ${order?.totalMoney?.currency}`}
            style={[styles.description, Platform.OS === 'web' && { marginHorizontal: 30 }]}
          />
        }
        <Text
          i18nKey="receipt_refund_reason"
          align="left"
          style={styles.reasonSection}
        />
        <FlatList
          showsVerticalScrollIndicator={false}
          keyExtractor={(item, index) => 'row-' + index}
          getItemLayout={(__, index) => ({
            length: 60,
            offset: 61 * index,
            index,
          })}
          data={items}
          style={styles.listStyle}
          contentContainerStyle={styles.listContainerStyle}
          renderItem={({ item, index }) => (
            <Item
              key={'row-' + index}
              index={index}
              item={item}
              selected={selected}
              value={inputValue}
              onChangeText={(text) => {
                setValue(text)
                setName(text)
              }}
              onPress={() => {
                setSelected(index)
                setName(item.subtitle)
              }}
            />
          )}
          ItemSeparatorComponent={() => <View style={styles.separator} />}
          ListFooterComponent={
            <Button
              disabled={name && !loading ? false : true}
              variant={!name || loading || transactionLoading || !isNumber(amount) ? 'disabled' : 'active'}
              title="orders_refund_reason_button"
              style={styles.fullIssueButton}
              onPress={() => createRefundd()}
              loader={loading}
              loaderComponent={
                <View
                  style={styles.loaderView}>
                  <Loader size={32} source={animations.vfLoaderWhite} />
                  <Text
                    i18nKey="receipt_button_creating_refund"
                    color={colors.white}
                    style={styles.leftSpacing}
                  />
                </View>
              }
            />
          }
          refreshControl={
            <RefreshControl refreshing={false} onRefresh={() => { }} />
          }
        />
      </ScrollView>
      <Modal
        isVisible={modal.isVisible}
        onBackButtonPress={() => closeModal()}
        onBackdropPress={() => closeModal()}
        animationIn="fadeIn"
        animationOut="fadeOut">
        <ModalContent
          onClose={() => closeModal()}
          contentContainerStyle={styles.modalContent}>
          <Icon source={modal.icon} size={40} />
          <Text
            i18nKey={modal.title}
            align="center"
            size="h4"
            weight="bold"
            style={styles.modalTitle}
          />
          <Text
            i18nKey={modal.desc}
            numberOfLines={10}
            placeHolders={modal.descPlaceholders}
            align="center"
          />
          {modal.primaryAction && (
            <Button
              title={modal.primaryText}
              variant="active"
              style={styles.modalPrimaryButton}
              onPress={() => modal.primaryAction?.()}
            />
          )}
          {modal.secondaryAction && (
            <Button
              title={modal.secondaryText}
              variant="outline"
              style={styles.modalSecondaryButton}
              onPress={() => modal.secondaryAction?.()}
            />
          )}
        </ModalContent>
      </Modal>
    </SafeAreaView>
  )
}
IssueRefund.propTypes = {
  navigation: PropTypes.object,
  route: PropTypes.object,
}

export default IssueRefund
