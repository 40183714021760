import { styleSheetFactory } from '../../Themes/Colors'

const styles = styleSheetFactory(colors => ({
    container: {
        position: 'relative',
        left: 0,
        top: 0,
    },
    mainIcon: {
        position: 'relative',
        top: 0,
        left: 0,
    },
    statusIcon: {
        position: 'absolute',
    },
    topRight: {
        top: 0,
        right: 0,
    },
    topLeft: {
        top: 0,
        left: 0,
    },
    bottomRight: {
        bottom: 0,
        right: 0,
    },
    bottomLeft: {
        bottom: 0,
        left: 0,
    },
}))

export default styles
