/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useEffect } from 'react'
import { View, LayoutAnimation, Platform } from 'react-native'
import PagerView from 'react-native-pager-view'
import { useTheme } from 'react-native-themed-styles'
import PropTypes from 'prop-types'

import OnBoardingFirstStep from '../OnBoardingFirstStep'
import OnBoardingSecondStep from '../OnBoardingSecondStep'
import OnBoardingThirdStep from '../OnBoardingThirdStep'
import OnBoardingFourthStep from '../OnBoardingFourthStep'
import OnBoardingFifthStep from '../OnBoardingFifthStep'
import OnBoardingSixthStep from '../OnBoardingSixthStep'
import OnBoardingSeventhStep from '../OnBoardingSeventhStep'

import { PagerViewWeb } from '../../Components'

import CertWarning from './CertWarning'

import themedStyles from './OnBoarding.Styles'
import { layoutAnimConfig } from '../../Themes/Theme'

const Pagerview = {
    true: PagerViewWeb,
    false: PagerView,
}

const isWeb = Platform.OS === 'web'
function OnBoarding({ logout, refreshToken, onBoard, policyCheck, shouldShowWarning, setShouldShowWarning }) {
    const viewpagerRef = useRef(1)
    const [styles] = useTheme(themedStyles)
    const [selectedPage, setSelectedPage] = useState(policyCheck ? 6 : 1)
    const PagerViewBoth = Pagerview[isWeb]

    useEffect(() => {
        if (onBoard) {
            if (onBoard === 1) {
                setSelectedPage(4)
            } else if (onBoard === 2) {
                setSelectedPage(5)
            } else if (onBoard === 0) {
                setSelectedPage(1)
            } else if (onBoard === 3) {
                setSelectedPage(6)
            }
        } else if (policyCheck) {
            setSelectedPage(6)
        }

    }, [onBoard])

    const skip = () => {
        if (isWeb) {
            return setSelectedPage((page) => page + 2)
        } else {
            LayoutAnimation.configureNext(layoutAnimConfig)
            setSelectedPage((prev) => {
                viewpagerRef.current.setPage(prev + 2)
                return prev + 2
            })
        }
    }

    const next = () => {
        if (isWeb) {
            return setSelectedPage((page) => page + 1)
        } else {
            LayoutAnimation.configureNext(layoutAnimConfig)
            setSelectedPage((prev) => {
                viewpagerRef.current.setPage(prev + 1)
                return prev + 1
            })
        }
    }

    const previous = () => {
        if (isWeb) {
            return setSelectedPage((page) => page - 1)
        } else {
            LayoutAnimation.configureNext(layoutAnimConfig)
            setSelectedPage((prev) => {
                viewpagerRef.current.setPage(prev - 1)
                return prev - 1
            })
        }
    }

    const SETUP_PAGES = [1, 2, 3, 4, 5, 6, 7, 8]
    return (
        <View style={styles.container}>
            {shouldShowWarning ? <CertWarning setShouldShowWarning={setShouldShowWarning} /> : <>
                <PagerViewBoth
                    requestAnimationFrame={() => viewpagerRef.current?.setPage(selectedPage)}
                    ref={(viewpager) => {
                        viewpagerRef.current = viewpager
                    }}
                    style={styles.viewPager}
                    initialPage={selectedPage}
                    onPageSelected={(e) => {
                        setSelectedPage(e.nativeEvent.position)
                    }}
                    scrollEnabled={false}
                >
                    <View key="0" />
                    <View collapsable={false} key="1">
                        <OnBoardingFirstStep next={next} selectedPage={selectedPage} logout={logout} />
                    </View>
                    <View collapsable={false} key="2">
                        <OnBoardingSecondStep next={next} skip={skip} selectedPage={selectedPage} logout={logout} />
                    </View>
                    <View collapsable={false} key="3">
                        <OnBoardingThirdStep next={next} previous={previous} selectedPage={selectedPage} logout={logout} />
                    </View>
                    <View collapsable={false} key="4">
                        <OnBoardingFourthStep next={next} selectedPage={selectedPage} logout={logout} />
                    </View>
                    <View collapsable={false} key="5">
                        <OnBoardingFifthStep next={next} selectedPage={selectedPage} logout={logout} />
                    </View>
                    <View collapsable={false} key="6">
                        <OnBoardingSeventhStep selectedPage={selectedPage} logout={logout} refreshToken={refreshToken} next={next} policyCheck={policyCheck} />
                    </View>
                    <View collapsable={false} key="7">
                        <OnBoardingSixthStep next={next} selectedPage={selectedPage} logout={logout} refreshToken={refreshToken} />
                    </View>
                </PagerViewBoth>
                <View style={policyCheck ? styles.hidden : styles.indicatorContainer}>
                    {SETUP_PAGES.map((item, index) => (
                        <View
                            key={'indicator' + index}
                            style={index === 0 ? styles.hidden : styles['indicator_' + (selectedPage === index)]}
                        />
                    ))}
                </View>
            </>}
        </View>
    )
}

OnBoarding.propTypes = {
    logout: PropTypes.func,
    refreshToken: PropTypes.func,
    onBoard: PropTypes.number,
    policyCheck: PropTypes.bool,
    shouldShowWarning: PropTypes.bool,
    setShouldShowWarning: PropTypes.func,

}

OnBoarding.defaultProps = {
    policyCheck: false,
    shouldShowWarning: false,
    setShouldShowWarning: () => { },
}

export default OnBoarding
