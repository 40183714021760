/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useContext, useEffect, useRef } from 'react'
import {
  View,
  SafeAreaView,
  TouchableOpacity,
  LayoutAnimation,
  useWindowDimensions,
  Animated,
  Easing,
} from 'react-native'
import PropTypes from 'prop-types'
import { useTheme } from 'react-native-themed-styles'
import { useMutation, useLazyQuery } from '@apollo/client'
import moment from 'moment'
import 'moment/locale/sq'
import 'moment/locale/en-gb'
import { useDispatch, useSelector } from 'react-redux'

import {
  Text,
  Button,
  RadioInput,
  Modal,
  Header,
  LoaderModal,
  ModalContent,
  Icon,
  ListLoader,
} from '../../Components'

import calculatePrice, {
  clearExtraDetails,
  clearOrderUpdate,
  useCountOrders,
} from '../../Utils/Order'
import { getErrorMessage, useConfig } from '../../Utils'
import { INF } from '../../Utils/Helpers'

import { useLocationDetails } from '../../Utils/AuthDetails'
import { getOrder, getCurrency } from '../Checkout/Checkout.Selectors'
import { resetOrder } from '../Checkout/Checkout.Actions'

import Theme, { layoutAnimConfig } from '../../Themes/Theme'
import { images } from '../../Themes'
import themedStyles, { itemStyles } from './SaveItems.Styles'

import {
  LIST_SAVED_ORDERS,
  SAVE_ORDER,
  UPDATE_ORDER,
  RETRIEVE_SAVED_ORDER,
} from './SaveItems.Schema'

const POINTER_EVENTS = {
  0: 'none',
  1: 'auto',
}

const LANGUAGES = {
  al: 'sq',
  en: 'en-gb',
}

function Item(props) {
  const [styles] = useTheme(itemStyles)
  const { colors } = useContext(Theme)

  const {
    id,
    totalMoney,
    savedAt,
    selected,
    onPress,
    isFirst,
    isLast,
  } = props

  return (
    <View
      style={[
        styles.wrapper,
        styles['firstItem_' + isFirst],
        styles['lastItem_' + isLast],
      ]}>
      <TouchableOpacity style={styles.container} onPress={onPress}>
        <View style={styles.infoContainer}>
          <View style={styles.leftContainer}>
            <Text i18nKey={id} numberOfLines={1} />
            <Text
              i18nKey={moment(savedAt).fromNow()}
              weight="light"
              color={colors.secondaryText}
              size="footnote"
              numberOfLines={1}
            />
          </View>
          <View style={styles.rightContainer}>
            <View style={styles.priceContainer}>
              <Text
                i18nKey="checkout_price_currency_value"
                placeHolders={[
                  INF.format(totalMoney?.amount || 0),
                  totalMoney?.currency,
                ]}
                numberOfLines={1}
              />
            </View>
            <View pointerEvents="none">
              <RadioInput
                index={id}
                selected={selected}
                onPress={onPress}
                color={colors.secondaryAccent}
              />
            </View>
          </View>
        </View>
      </TouchableOpacity>
    </View>
  )
}
Item.propTypes = {
  id: PropTypes.string,
  totalMoney: PropTypes.shape({
    amount: PropTypes.number,
    currency: PropTypes.string,
  }),
  exchangeRate: PropTypes.string,
  savedAt: PropTypes.string,
  selected: PropTypes.string,
  onPress: PropTypes.func,
  isFirst: PropTypes.bool,
  isLast: PropTypes.bool,
}

function SaveItems({ navigation: { addListener, navigate, goBack } }) {

  const { switchLocation: { deviceId, locationId: id } } = useConfig()

  const [styles] = useTheme(themedStyles)
  const { colors } = useContext(Theme)
  const { width, height } = useWindowDimensions()
  const { euRate } = useLocationDetails()
  const countSavedOrders = useCountOrders()
  const { order: orderObj } = useSelector(getOrder)

  const currency = useSelector(getCurrency)

  const dispatchAction = useDispatch()

  const clearOrder = () => dispatchAction(resetOrder())

  moment.locale(LANGUAGES.al)
  const [selected, setSelected] = useState(-1)

  const listRef = useRef()
  const [pointerEvents, setPointerEvents] = useState('auto')
  const fabSize = useRef(new Animated.Value(0)).current
  const animate = value => {
    setPointerEvents(POINTER_EVENTS[value])
    Animated.spring(fabSize, {
      toValue: value,
      useNativeDriver: true,
      easing: Easing.inOut(Easing.cubic),
    }).start()
  }
  const scrollToTop = () => {
    animate(0)
    listRef.current?.scrollToOffset({
      offset: 0,
    })
  }

  const onMomentumScrollEnd = (event) => {
    if (event.nativeEvent.contentOffset.y > 800) {
      animate(1)
    } else {
      animate(0)
    }
  }

  const [loading, setLoading] = useState(true)
  const [savedOrders, setSavedOrders] = useState([])
  const cursor = useRef()
  const [listSavedOrders, { data }] = useLazyQuery(
    LIST_SAVED_ORDERS,
  )

  const filterOrders = (orders) => orders?.filter(item => item?.totalMoney?.currency === currency?.currencyCode)

  const refetch = () => {
    setLoading(true)
    setSavedOrders([])
    cursor.current = null
    listSavedOrders({
      variables: {
        locationId: id,
        cashRegisterId: deviceId,
      },
    })
  }
  useEffect(() => {
    refetch()
  }, [width, height])
  useEffect(() => {
    const listOrders = data?.listOrders
    if (listOrders) {
      LayoutAnimation.configureNext(layoutAnimConfig)
      setSavedOrders((prev) => prev.concat(filterOrders(listOrders?.orders) || []))
      cursor.current = listOrders?.nextToken
      setLoading(false)
    }
  }, [data])
  const onEndReached = () => {
    if (cursor.current) {
      setLoading(true)
      listSavedOrders({
        variables: {
          locationId: id,
          cashRegisterId: deviceId,
          nextToken: cursor.current,
        },
      })
    }
  }

  const [saveOrder, { loading: saving, data: result }] = useMutation(SAVE_ORDER)
  const [
    retrieveOrder,
    { loading: retrieving, error, data: retrievedOrder },
  ] = useLazyQuery(RETRIEVE_SAVED_ORDER)
  const [updateOrder, { loading: updating, data: updateResult }] = useMutation(
    UPDATE_ORDER,
  )

  const [modal, setModal] = useState({
    isVisible: false,
    icon: images.warningIcon,
    title: '',
    desc: '',
    primaryText: '',
    primaryAction: null,
    secondaryText: '',
    secondaryAction: null,
  })
  const openModal = (
    title = 'opened_orders_error',
    desc = '',
    primaryText = '',
    primaryAction = null,
    secondaryText = '',
    secondaryAction = null,
    icon = images.warningIcon,
  ) => {
    setModal({
      isVisible: true,
      icon: icon,
      title: title,
      desc: desc,
      primaryText: primaryText,
      primaryAction: primaryAction,
      secondaryText: secondaryText,
      secondaryAction: secondaryAction,
    })
  }
  const closeModal = () => {
    setModal({
      isVisible: false,
      icon: images.delete,
      title: '',
      desc: '',
      primaryText: '',
      primaryAction: null,
      secondaryText: '',
      secondaryAction: null,
    })
  }

  const handleError = (e) => {
    if (e) {
      const errorMessage = getErrorMessage(e)
      if (errorMessage === 'OrderNoOpenDrawer') {
        openModal(
          'receipt_drawer_warning_title',
          'checkout_drawer_error_message',
          'receipt_button_open_drawer',
          () => {
            navigate('CurrentDrawer', { nextAction: 'save_items_continue' })
            closeModal()
          },
          'orders_button_go_back',
          () => closeModal(),
        )
      } else {
        openModal(
          'opened_orders_error',
          errorMessage,
          'orders_button_go_back',
          () => closeModal(),
          '',
          undefined,
        )
      }
    }
  }

  useEffect(() => {
    handleError(error)
  }, [error])

  useEffect(() => {
    const unsubscribe = addListener('beforeRemove', () => {
      countSavedOrders()
    })
    return unsubscribe
  }, [])

  const saveNewOrder = () => {
    const order = { ...orderObj }
    saveOrder({
      variables: {
        locationId: id,
        cashRegisterId: deviceId,
        orderInput: {
          status: 'SAVE',
          locationId: id,
          ...clearExtraDetails(order),
        },
      },
    }).catch((e) => handleError(e))
  }

  const addToExisting = () => {
    retrieveOrder({
      variables: {
        id: selected,
        locationId: id,
        cashRegisterId: deviceId,
      },
    })
  }

  useEffect(() => {
    if (retrievedOrder) {
      const newOrder = clearOrderUpdate({ ...retrievedOrder?.getOrder })
      newOrder.lineItems = newOrder.lineItems.concat(orderObj.lineItems)
      newOrder.appliedDiscounts = newOrder.appliedDiscounts.concat(
        orderObj.appliedDiscounts,
      )
      const calculatedOrder = clearOrderUpdate(calculatePrice(newOrder))
      updateOrder({
        variables: {
          locationId: id,
          cashRegisterId: deviceId,
          orderInput: {
            status: 'SAVE',
            ...calculatedOrder,
          },
        },
      }).catch((e) => handleError(e))
    }
  }, [retrievedOrder])

  useEffect(() => {
    if (result?.createOrder?.id) {
      clearOrder()
      goBack()
    }
  }, [result])

  useEffect(() => {
    if (updateResult?.updateOrder?.id) {
      clearOrder()
      goBack()
    }
  }, [updateResult])

  return (
    <SafeAreaView style={styles.container}>
      <Header
        title="save_items_title"
        image={images.closeIcon}
        onPress={goBack}
      />
      <Button
        backgroundColor={colors.white}
        title="save_items_save_new"
        color={colors.accent}
        borderColor={colors.transparent}
        style={styles.chargeButton}
        onPress={saveNewOrder}
      />
      <Text
        i18nKey="save_items_existing_section_title"
        style={styles.sectionHeader}
      />
      <Animated.FlatList
        ref={(ref) => (listRef.current = ref)}
        showsVerticalScrollIndicator={false}
        onMomentumScrollEnd={onMomentumScrollEnd}
        keyExtractor={(item) => item.id}
        getItemLayout={(_, index) => ({
          length: 70,
          offset: 71 * index,
          index,
        })}
        data={savedOrders}
        style={styles.listStyle}
        contentContainerStyle={styles.listContainerStyle}
        renderItem={({ item, index }) => (
          <Item
            key={item.id}
            id={item.id}
            totalMoney={item.totalMoney}
            exchangeRate={euRate}
            savedAt={item.createdAt}
            selected={selected}
            onPress={() => setSelected(item.id)}
            isFirst={index === 0}
            isLast={index === savedOrders.length - 1}
          />
        )}
        ItemSeparatorComponent={() => <View style={styles.separator} />}
        ListFooterComponent={<ListLoader loading={loading} isInitial={savedOrders.length === 0} />}
        ListEmptyComponent={!loading && <Text i18nKey="save_items_no_existing_orders" />}
        refreshing={false}
        onRefresh={() => refetch()}
        onEndReached={() => onEndReached()}
      />
      <Animated.View
        pointerEvents={pointerEvents}
        style={[
          styles.fab,
          {
            opacity: fabSize,
            transform: [{ scale: fabSize }],
          },
        ]}>
        <TouchableOpacity
          onPress={scrollToTop}
          activeOpacity={0.5}
          style={styles.fabTouchable}>
          <Icon source={images.arrow_up} size={20} style={styles.fabIcon} />
        </TouchableOpacity>
      </Animated.View>
      <Button
        title="save_items_save_existing"
        style={styles.chargeButton}
        variant={selected === -1 ? 'disabled' : 'active'}
        onPress={addToExisting}
      />

      <LoaderModal loading={saving} message="save_items_saving" />
      <LoaderModal loading={updating} message="save_items_saving" />
      <LoaderModal
        loading={retrieving}
        message="save_items_retrieving_selected"
      />
      <Modal
        isVisible={modal.isVisible}
        onBackButtonPress={() => closeModal()}
        onBackdropPress={() => closeModal()}
        animationIn="fadeIn"
        animationOut="fadeOut">
        <ModalContent onClose={() => closeModal()} contentContainerStyle={styles.modalContent}>
          <Icon source={modal.icon} size={40} />
          <Text
            i18nKey={modal.title}
            align="center"
            size="h4"
            weight="bold"
            style={styles.modalTitle}
          />
          <Text i18nKey={modal.desc} align="center" />
          <Button
            title={modal.primaryText}
            variant="active"
            style={styles.modalPrimaryButton}
            onPress={() => modal.primaryAction?.()}
          />
          {modal.secondaryAction && (
            <Button
              title={modal.secondaryText}
              variant="text"
              style={styles.modalSecondaryButton}
              onPress={() => modal.secondaryAction?.()}
            />
          )}
        </ModalContent>
      </Modal>
    </SafeAreaView>
  )
}

SaveItems.propTypes = {
  route: PropTypes.object,
  navigation: PropTypes.object,
}

export default SaveItems
