/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useMemo, useState, useRef, useCallback } from 'react'
import { View, SafeAreaView, TouchableOpacity, Platform } from 'react-native'
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs'
import { useTheme } from 'react-native-themed-styles'
import { useLazyQuery } from '@apollo/client'
import PropTypes from 'prop-types'
import moment from 'moment'
import { SwipeRow } from 'react-native-swipe-list-view'
import { useFocusEffect } from '@react-navigation/native'

import {
    Tabs,
    Text,
    Header,
    PeriodCalendar,
    TagList,
    ItemStatus,
    Icon,
    SwipeList,
    Loader,
} from '../../Components'
import ContentManager from '../../Utils/ContentManager'
import { INF, useConfig } from '../../Utils'
import { useLanguage } from '../../Utils/Language'

import Theme from '../../Themes/Theme'

import { animations, images } from '../../Themes'

import { LIST_ONLINE_ORDERS } from './OnlineOrders.Schema'

import themedStyles, { routeStyles, itemStyles, hiddenItemStyles } from './OnlineOrders.Styles'

const Tab = createMaterialTopTabNavigator()

const LANGUAGES = {
    al: 'sq',
    en: 'en-gb',
}

const types = [
    {
        title: 'users_all_filter',
        type: '',
    },
    {
        title: 'online_orders_pickup_filter',
        type: 'PICKUP',
    },
    {
        title: 'online_orders_shipment_filter',
        type: 'SHIPMENT',
    },
    {
        title: 'online_orders_curbside_filter',
        type: 'CURBSIDE',
    },
    {
        title: 'online_orders_delivery_filter',
        type: 'DELIVERY',
    },
]
function EmptyItem() {
    return null
}

const listItems = {
    section: SectionItem,
    order: Item,
}
const hiddenItems = {
    section: EmptyItem,
    order: HiddenItem,
}

function SectionItem({ sectionTitle }) {
    const { colors } = useContext(Theme)
    const [styles] = useTheme(itemStyles)
    return (
        <Text
            i18nKey={sectionTitle}
            size="footnote"
            color={colors.secondaryText}
            style={styles.section}
        />
    )
}
SectionItem.propTypes = {
    sectionTitle: PropTypes.string,
}

function Item({
    isFirst,
    isLast,
    onPress,
    fulfillmentType,
    id,
    amount,
    type,
    status,
    code,
}) {
    const [styles] = useTheme(itemStyles)
    const { colors } = useContext(Theme)

    const ItemStatuses = {

        ACTIVE: <ItemStatus
            color={colors.green}
            title="online_orders_active_item"
        />,
        NEW: <ItemStatus
            color={colors.secondaryAccent}
            title="online_orders_new_item"
        />,
        COMPLETED: <ItemStatus
            color={colors.green}
            title="online_orders_completed_item"
        />,
        CANCELLED: <ItemStatus
            color={colors.accent}
            title="online_orders_cancelled_item"
            code={code} />,
        IN_PROGRESS: <ItemStatus
            color={colors.secondaryAccent}
            title="order_in_progress_status"
        />,
        null: null,
        undefined: null,
    }

    return (
        <View
            style={[
                styles.itemStyle,
                styles[`firstItem_${isFirst}`],
                styles[`lastItem_${isLast}`],
            ]}>
            <TouchableOpacity onPress={onPress} disabled={!onPress}>
                <View style={styles.container}>
                    <Icon source={images.checkout} size={25} color={colors.black} style={styles.icon} />
                    <View style={styles.listDescContainer}>
                        <Text i18nKey={id} numberOfLines={1} size="footnote" />
                        <View style={styles.itemMidSection}>
                            <Text
                                i18nKey={fulfillmentType}
                                weight="light"
                                color={colors.secondaryText}
                                numberOfLines={1}
                                style={styles.description(type)}
                            />
                        </View>
                    </View>
                    <View style={styles.rightSectionItem}>
                        <View style={styles.rightSectionItemContainer}>
                            {ItemStatuses[status]}
                            <Text i18nKey={amount} style={styles.itemText} />
                        </View>
                        <View style={styles.amountContainer}>
                            <Icon source={images.arrow_right} size={20} />
                        </View>
                    </View>
                </View>
            </TouchableOpacity>
        </View>
    )
}
Item.propTypes = {
    isFirst: PropTypes.bool,
    isLast: PropTypes.bool,
    onPress: PropTypes.func,
    title: PropTypes.string,
    description: PropTypes.string,
    subDescText: PropTypes.string,
    amount: PropTypes.string,
    type: PropTypes.string,
    status: PropTypes.string,
    code: PropTypes.string,
    id: PropTypes.string,
    fulfillmentType: PropTypes.string,
}

function HiddenItem({ isFirst, isLast, onPrintPress, onSharePress, shareLoading }) {
    const [styles] = useTheme(hiddenItemStyles)
    const { colors } = useContext(Theme)

    return (
        <View
            style={[
                styles.hiddenItemStyle,
                styles[`firstHiddenItem_${isFirst}`],
                styles[`lastHiddenItem_${isLast}`],
            ]}>
            <TouchableOpacity
                style={styles.hiddenSubcontainer}
                onPress={onPrintPress}>
                <Icon source={images.printerActive} color={colors.white} size={24} />
            </TouchableOpacity>
            {Platform.OS !== 'web' && <><View style={styles.divider} />
                <TouchableOpacity
                    style={styles.hiddenSubcontainer}
                    onPress={onSharePress}
                    disabled={shareLoading}
                >
                    {shareLoading ? <Loader source={animations.vfLoaderWhite} size={24} /> : <Icon source={images.share} color={colors.white} size={24} />}
                </TouchableOpacity></>}
        </View>
    )
}
HiddenItem.propTypes = {
    isFirst: PropTypes.bool,
    isLast: PropTypes.bool,
    onPrintPress: PropTypes.func,
    onSharePress: PropTypes.func,
    shareLoading: PropTypes.bool,
}

function Route({
    title,
    status,
    searchedDates,
    value,
    navigate,
}) {
    // const { id } = useLocationDetails()
  const { switchLocation: { deviceId, locationId } } = useConfig()

    const lang = useLanguage()
    const [orders, setOrders] = useState([])
    const [selectedType, setSelectedType] = useState('')
    const sectionDate = useRef('-')
    const [loading, setLoading] = useState(true)
    const [fetchmoreLoading, setFetchmoreLoading] = useState(true)

    const mapOrders = async (dataa) => {
        const response = []
        let itemTime, itemStatus
        let isFirst = false,
            isLast = false
        sectionDate.current = null
        dataa?.map((item, index) => {
            isFirst = false
            isLast = false
            itemTime = item.createdAt
            const date = moment(itemTime)
                .locale(LANGUAGES[lang])
                .format('MMM DD, YYYY')
            if (date !== sectionDate.current) {
                if (response?.[response.length - 1]) {
                    response[response.length - 1].isLast = true
                }
                sectionDate.current = date
                response.push({
                    id: date,
                    sectionTitle: date,
                    type: 'section',
                })
            }

            if (response?.[response.length - 1]?.type === 'section') {
                isFirst = true
                isLast = false
            }

            const element = item

            itemStatus = element?.status
            const amount = INF.format(element?.totalMoney?.amount)
            const fulfillmentType = element?.fulfillmentType
            const currency = element?.totalMoney?.currency
            const total = `+${amount} ${currency}`
            const onItemPress = () => { navigate('OrderDetails', { id: element.id }) }

            response.push({
                id: element.id,
                type: 'order',
                fulfillmentType: fulfillmentType,
                amount: total,
                isFirst: isFirst,
                status: itemStatus,
                isLast: isLast,
                onPress: onItemPress,
            })
        })
        if (response?.[response.length - 1]) {
            response[response.length - 1].isLast = true
        }
        return response
    }

    const [listOrders, { data, fetchMore, refetch }] = useLazyQuery(LIST_ONLINE_ORDERS, {
        fetchPolicy: 'network-only',
        refetchWritePolicy: 'merge',
    })

    const onEndReached = () => {
        if (data?.listOrders?.nextToken === null || loading) {
            return
        }
        const filter = selectedType ?
            {
                name: 'fulfillmentType',
                value: selectedType,
            } : {

            }

        setFetchmoreLoading(true)
        fetchMore({
            variables: {
                limit: 30,
                nextToken: data?.listOrders?.nextToken,
                locationId: locationId,
                cashRegisterId: deviceId,
                status: status ? status : null,
                beginTime: searchedDates?.startingDay ? moment(searchedDates?.startingDay).utc().startOf('day').add(1, 'days') : null,
                endTime: searchedDates?.endingDay ? moment(searchedDates?.endingDay).utc().endOf('day').add(1, 'days') : searchedDates?.startingDay ? moment(searchedDates?.startingDay).utc().endOf('day').add(1, 'days') : null,
                ...filter,
            },
        })
    }


    useFocusEffect(
        useCallback(() => {
            setLoading(true)
            if (orders.length > 0) {
                setOrders([])
            }
            const filter = selectedType ?
                {
                    name: 'fulfillmentType',
                    value: selectedType,
                } : {

                }

            listOrders({
                variables: {
                    locationId: locationId,
                    cashRegisterId: deviceId,
                    status: status ? status : null,
                    ...filter,
                    limit: 30,
                    beginTime: searchedDates?.startingDay ? moment(searchedDates?.startingDay).utc().startOf('day').add(1, 'days') : null,
                    endTime: searchedDates?.endingDay ? moment(searchedDates?.endingDay).utc().endOf('day').add(1, 'days') : searchedDates?.startingDay ? moment(searchedDates?.startingDay).utc().endOf('day').add(1, 'days') : null,
                },
            })
        }, [selectedType, searchedDates])
    )

    // useEffect(() => {
    //     setLoading(true)
    //     if (orders.length > 0) {
    //         setOrders([])
    //     }
    //     const filter = selectedType ?
    //         {
    //             name: 'fulfillmentType',
    //             value: selectedType,
    //         } : {

    //         }

    //     listOrders({
    //         variables: {
    //             locationId: '26stsmctZOEc2VEqCQICZMz8J2Z',
    //             status: status ? status : null,
    //             ...filter,
    //             limit: 10,
    //             beginTime: searchedDates?.startingDay ? moment(searchedDates?.startingDay).add(1, 'days') : null,
    //             endTime: searchedDates?.endingDay ? moment(searchedDates?.endingDay).add(2, 'days') : null,
    //         },
    //     })

    // }, [selectedType, searchedDates])

    useEffect(() => {
        if (data) {

            mapOrders(data?.listOrders?.orders).then((res) => {
                setLoading(false)
                setFetchmoreLoading(false)
                setOrders(res)

            })
        }

    }, [data])

    const refreshData = async () => {
        if (refetch) {
            await refetch()
        }
    }

    const [styles] = useTheme(routeStyles)

    const renderSwipeList = () => {
        return (<SwipeList
            data={orders}
            renderItem={(rowData, rowMap) => {
                const { item, index } = rowData
                const ListItem = listItems[item.type]
                const ListHiddenItem = hiddenItems[item.type]
                return (
                    <SwipeRow
                        key={'row-' + index}
                        disableRightSwipe
                        disableLeftSwipe={item.type === 'section' || Platform.OS === 'web' ? true : false}
                        rightOpenValue={Platform.OS === 'web' ? -81 : -161}
                        closeOnRowPress
                        recalculateHiddenLayout={false}
                        useNativeDriver={true}

                    >
                        <ListHiddenItem
                            {...item}
                            key={'row-' + index}
                        />
                        <ListItem
                            {...item}
                            sectionTitle={item?.sectionTitle}
                            key={'row-' + index}
                        />
                    </SwipeRow>

                )
            }}
            ListFooterComponent={loading || fetchmoreLoading ? <Loader size={32} /> : null}
            disableRightSwipe
            refreshing={false}
            onRefresh={() => refreshData()}
            onEndReached={onEndReached}
            // onEndReachedThreshold={1.2}
            ListEmptyComponent={loading ? null : <View style={styles.emptyComponent}>
                <Text weight="light" i18nKey="online_orders_empty_list" />
            </View>}
        />)
    }

    return (
        <>
            <TagList
                selected={selectedType}
                onPress={(type) => {
                    setSelectedType(type)
                }}
                options={types}
                style={styles.typesStyle}
            />
            {loading ? <Loader size={32} /> : renderSwipeList()}
        </>
    )
}
Route.propTypes = {
    title: PropTypes.string,
    selectedType: PropTypes.object,
    status: PropTypes.string,
    setSelectedType: PropTypes.func,
    searchedDates: PropTypes.object,
    value: PropTypes.string,
    navigate: PropTypes.func,
}

function SearchRoute({
    value,
    navigate,
}) {



    // const { id } = useLocationDetails()
  const { switchLocation: { deviceId, locationId } } = useConfig()

    const lang = useLanguage()
    const [orders, setOrders] = useState([])
    const sectionDate = useRef('-')
    const [loading, setLoading] = useState(false)

    const mapOrders = (dataa) => {
        const response = []
        let itemTime, status
        let isFirst = false,
            isLast = false
        sectionDate.current = null
        dataa?.map((item, index) => {
            isFirst = false
            isLast = false
            itemTime = item.createdAt
            const date = moment(itemTime)
                .locale(LANGUAGES[lang])
                .format('MMM DD, YYYY')
            if (date !== sectionDate.current) {
                if (response?.[response.length - 1]) {
                    response[response.length - 1].isLast = true
                }
                sectionDate.current = date
                response.push({
                    id: date,
                    sectionTitle: date,
                    type: 'section',
                })
            }

            if (response?.[response.length - 1]?.type === 'section') {
                isFirst = true
                isLast = false
            }

            const element = item

            status = element?.status
            const amount = INF.format(element?.totalMoney?.amount)
            const fulfillmentType = element?.fulfillmentType
            const currency = element?.totalMoney?.currency
            const total = `+${amount} ${currency}`
            const onItemPress = () => { navigate('OrderDetails', { id: element.id }) }

            response.push({
                id: element.id,
                type: 'order',
                fulfillmentType: fulfillmentType,
                amount: total,
                isFirst: isFirst,
                status: status,
                isLast: isLast,
                onPress: onItemPress,
            })
        })
        if (response?.[response.length - 1]) {
            response[response.length - 1].isLast = true
        }
        return response
    }

    const onEndReached = () => {
        if (data?.listOrders?.nextToken === null) {
            return
        }
        setLoading(true)
        fetchMore({
            variables: {
                limit: 30,
                nextToken: data?.listOrders?.nextToken,
                locationId: locationId,
                cashRegisterId: deviceId,
                name: 'orderNumber',
                value: value,
            },
        })
    }

    const [listOrders, { data, fetchMore, refetch }] = useLazyQuery(LIST_ONLINE_ORDERS, {
        fetchPolicy: 'network-only',
    })

    useEffect(() => {
        setLoading(true)

        if (orders.length > 0) {
            setOrders([])
        }

        const filter = {
            name: 'orderNumber',
            value,
        }

        listOrders({
            variables: {
                locationId: locationId,
                cashRegisterId: deviceId,
                ...filter,
                limit: 30,
            },
        })

    }, [value])

    useEffect(() => {
        if (data) {
            setOrders(mapOrders(data?.listOrders?.orders))
            setLoading(false)
        }
    }, [data])

    const refreshData = async () => {
        if (refetch) {
            await refetch()
        }
    }

    const [styles] = useTheme(routeStyles)

    return (
        <>
            <SwipeList
                data={orders}
                containerStyle={styles.searchContainer}
                renderItem={(rowData, rowMap) => {
                    const { item, index } = rowData
                    const ListItem = listItems[item.type]
                    const ListHiddenItem = hiddenItems[item.type]

                    return (
                        <SwipeRow
                            key={'row-' + index}
                            disableLeftSwipe={item.type === 'section' || Platform.OS === 'web' ? true : false}
                            rightOpenValue={Platform.OS === 'web' ? -81 : -161}
                            closeOnRowPress
                            disableRightSwipe
                            recalculateHiddenLayout={false}
                            useNativeDriver={true}
                        >
                            <ListHiddenItem
                                {...item}
                                key={'row-' + index}
                            />
                            <ListItem
                                {...item}
                                sectionTitle={item?.sectionTitle}
                                key={'row-' + index}
                            />
                        </SwipeRow>

                    )
                }}
                ListFooterComponent={loading ? <Loader size={32} /> : null}
                refreshing={false}
                onRefresh={() => refreshData()}
                onEndReached={onEndReached}
                onEndReachedThreshold={0.5}
                ListEmptyComponent={loading ? null : <View style={styles.emptyComponent}>
                    <Text weight="light" i18nKey="online_orders_empty_list" />
                </View>}
            />
        </>
    )
}
SearchRoute.propTypes = {
    navigate: PropTypes.func,
    value: PropTypes.string,
}

function OnlineOrders({ navigation: { navigate, openDrawer } }) {
    const [styles] = useTheme(themedStyles)
    const { translate } = ContentManager

    const [selectedDates, setSelectedDates] = useState({})
    const [searchedDates, setSearchedDates] = useState({})

    const [value, setValue] = useState('')

    const statusTabs = [
        {
            title: translate('checkout_title_tab_all'),
            status: '',
        },
        {
            title: translate('online_orders_active_item'),
            status: 'ACTIVE',
        },
        {
            title: translate('online_orders_completed_item'),
            status: 'COMPLETED',
        },
        {
            title: translate('order_in_progress_status'),
            status: 'IN_PROGRESS',
        },
        {
            title: translate('online_orders_new_item'),
            status: 'NEW',
        },
        {
            title: translate('online_orders_cancelled_item'),
            status: 'CANCELLED',
        },
    ]

    const renderTabs = useMemo(() => {
        if (value) {
            return (
                <SearchRoute value={value} navigate={navigate} />
            )
        }
        return (
            <Tabs
                initialRouteName={'AllOrders'}
                routeContainerStyle={styles.scene}
                style={styles.tabView}
                tabContainerStyle={styles.tabContainer}
                screenOptions={
                    {
                        swipeEnabled: false,
                        lazy: true,
                        tabBarScrollEnabled: true,
                        tabBarItemStyle: Platform.OS === 'web'
                            ? {
                                width: 'auto',
                                minWidth: 300,
                            } : {
                                width: 'auto',
                                minWidth: 100,
                            },
                    }
                }
            >
                {statusTabs.map(({ title, status }, index) => (
                    <Tab.Screen key={index} name={title} >
                        {() => (<Route title={title} status={status} searchedDates={searchedDates} navigate={navigate} />)}
                    </Tab.Screen>
                )
                )}
            </Tabs>
        )
    }, [value, searchedDates])

    const onSearchPress = () => {
        setSearchedDates(selectedDates)
    }
    const handleIntervalChange = (dates) => {
        setSelectedDates(dates)
    }

    return (
        <SafeAreaView style={styles.screenWrapper}>
            <Header title="online_orders_header" image={images.menu} onPress={() => openDrawer()} />
            {renderTabs}
            <View style={styles.searchContainer}>
                <PeriodCalendar
                    placeholder="online_orders_search_placeholder"
                    onSearch={onSearchPress}
                    withInput={true}
                    value={value}
                    onChangeText={setValue}
                    onIntervalChange={handleIntervalChange}
                    onSearchClean={() => {
                        setSelectedDates({})
                        setSearchedDates({})
                        setValue('')
                    }}
                    style={styles.calendar}

                />
            </View>

        </SafeAreaView>
    )
}
OnlineOrders.propTypes = {
    navigation: PropTypes.object,
}

export default OnlineOrders
