import { images } from '../../Themes'

import CashPaymentType from './PaymentSteps/Components/CashPaymentType'
import EInvoiceType from './PaymentSteps/Components/EInvoiceType'
import SupportiveInvoicePayment from './PaymentSteps/Components/SupportiveInvoicePaymentType'
import ExportInvoiceType from './PaymentSteps/Components/ExportInvoiceType'
import ReverseChargeType from './PaymentSteps/Components/ReverseChargeType'
import NonCashPaymentType from './PaymentSteps/Components/NonCashPaymentType'
import {
  CashPaymentFooter,
  EInvoiceFooter,
  NonCashPaymentFooter,
  ExportInvoiceFooter,
  ReverseChargeFooter,
  SupportiveInvoiceFooter,
} from './PaymentSteps/Components/PaymentFooters'

const INVOICING_METHODS = [
  {
    method: 'CASH',
    icon: images.cashPayment,
    title: 'payment_label_type_cash',
    min: 0,
    max: Infinity,
  },
  {
    method: 'NONCASH',
    icon: images.doc,
    title: 'payment_label_type_noncash',
    min: 0,
    max: Infinity,
  },
  {
    method: 'EXPORT_OF_GOODS',
    icon: images.doc,
    title: 'payment_label_type_export',
    min: 0,
    max: Infinity,
  },
  {
    method: 'EINVOICE',
    icon: images.eInvoice,
    title: 'payment_label_type_einvoice',
    min: 0,
    max: Infinity,
  },
  {
    method: 'WAREHOUSE_NOTE',
    icon: images.supportiveInvoice,
    title: 'payment_label_type_warehouse',
    min: 0,
    max: Infinity,
  },
  {
    method: 'REVERSE_CHARGE',
    icon: images.doc,
    title: 'payment_label_type_reverse_charge_label',
    min: 0,
    max: Infinity,
  },
]

const PAYMENT_METHODS_LABELS = {
  'CASH': 'payment_title_type_cash',
  'NONCASH': 'payment_title_type_card',
  'ORDER': 'payment_title_type_order',
  'EINVOICE': 'payment_title_type_voucher',
  'WAREHOUSE_NOTE': 'payment_label_type_warehouse',
  'REVERSE_CHARGE': 'payment_label_type_reverse_charge',
}

const AMOUNT_ALL_VALUES = [5000, 2000, 1000, 500, 200, 100, 1]
const AMOUNT_EUR_VALUES = [500, 200, 100, 50, 20, 10, 5, 2, 1, 0.50]
const AMOUNT_USD_VALUES = [100, 50, 20, 10, 5, 1, 0.50]
const AMOUNT_GBP_VALUES = [50, 20, 10, 5, 1, 0.50]

const NUM_OF_SUGGESTIONS = 2

const PAYMENT_STEPS = {
  init: 'init',
  paying: 'paying',
  printing: 'printing',
  success: 'success',
}

const EINVOICE_PAYMENT_STEPS = {
  init: 'init',
  process: 'process',
  buyerSeller: 'buyerSeller',
  references: 'references',
  delivery: 'delivery',
  notes: 'notes',
  preview: 'preview',
}

const SUPPORTIVE_EINVOICE_PAYMENT_STEPS = {
  init: 'init',
  transportInfo: 'transportInfo',
  issuerCarrier: 'issuerCarrier',
  preview: 'preview',
}

const stepsIndex = {
  'CASH': {},
  'NONCASH': {},
  'EXPORT_OF_GOODS': {},
  'REVERSE_CHARGE': {},
  'SELF_INVOICE': {},
  'EINVOICE': {
    init: {
      index: 1,
      showStepper: true,
    },
    process: {
      index: 2,
      showStepper: true,
    },
    buyerSeller: {
      index: 3,
      showStepper: true,
    },
    references: {
      index: 4,
      showStepper: true,
    },
    delivery: {
      index: 5,
      showStepper: true,
    },
    notes: {
      index: 6,
      showStepper: true,
    },
    preview: {
      index: 7,
      showStepper: true,
    },
  },
  'WAREHOUSE_NOTE': {
    init: {
      index: 1,
      showStepper: true,
    },
    transportInfo: {
      index: 2,
      showStepper: true,
    },
    issuerCarrier: {
      index: 3,
      showStepper: true,
    },
    preview: {
      index: 4,
      showStepper: true,
    },
    print: {
      index: 5,
    },
    success: {
      index: 6,
    },
  },
  'OTHER': {},
}

const stepsArrayIndex = (currentStep) => {
  const values = Object.values(stepsIndex[currentStep])
  const filter = (_, idx) => values[idx].showStepper
  return Object.keys(stepsIndex[currentStep])?.filter(filter)
}

const STEPS = {
  'CASH': PAYMENT_STEPS,
  'NONCASH': PAYMENT_STEPS,
  'EXPORT_OF_GOODS': PAYMENT_STEPS,
  'REVERSE_CHARGE': PAYMENT_STEPS,
  'WAREHOUSE_NOTE': SUPPORTIVE_EINVOICE_PAYMENT_STEPS,
  'EINVOICE': EINVOICE_PAYMENT_STEPS,
}

const INPUT_CONTENT = {
  'CASH': CashPaymentType,
  'NONCASH': NonCashPaymentType,
  'EXPORT_OF_GOODS': ExportInvoiceType,
  'EINVOICE': EInvoiceType,
  'WAREHOUSE_NOTE': SupportiveInvoicePayment,
  'REVERSE_CHARGE': ReverseChargeType,
}

const FOOTER_CONTENT = {
  'CASH': CashPaymentFooter,
  'NONCASH': NonCashPaymentFooter,
  'EXPORT_OF_GOODS': ExportInvoiceFooter,
  'EINVOICE': EInvoiceFooter,
  'WAREHOUSE_NOTE': SupportiveInvoiceFooter,
  'REVERSE_CHARGE': ReverseChargeFooter,
}

const PAYMENT_METHODS = {
  'CASH': [
    {
      code: 'BANKNOTE',
      description: 'payment_banknote_description',
      type: 'BANKNOTE',
    },
    {
      code: 'CARD',
      description: 'payment_card_description',
      type: 'CARD',
    },
    {
      code: 'CHECK',
      description: 'payment_check_description',
      type: 'CHECK',
      disabled: true,
    },
    {
      code: 'SVOUCHER',
      description: 'payment_voucher_description',
      type: 'SVOUCHER',
      disabled: true,
    },
    {
      code: 'COMPANY',
      description: 'payment_company_description',
      type: 'COMPANY',
      disabled: true,
    },
    {
      code: 'ORDER',
      description: 'payment_order_description',
      type: 'ORDER',
      disabled: true,
    },
  ],
  'NONCASH': [
    {
      code: 'ACCOUNT',
      description: 'payment_account_description',
      type: 'ACCOUNT',
    },
    {
      code: 'FACTORING',
      description: 'payment_factoring_description',
      type: 'FACTORING',
    },
    {
      code: 'COMPENSATION',
      description: 'payment_compensation_description',
      type: 'COMPENSATION',
    },
    {
      code: 'TRANSFER',
      description: 'payment_transfer_description',
      type: 'TRANSFER',
    },
    {
      code: 'WAIVER',
      description: 'payment_waiver_description',
      type: 'WAIVER',
    },
    {
      code: 'KIND',
      description: 'payment_kind_description',
      type: 'KIND',
    },
    {
      code: 'OTHER',
      description: 'payment_other_description',
      type: 'OTHER',
    },
  ],
  'EXPORT_OF_GOODS': [
    {
      code: 'ACCOUNT',
      description: 'payment_account_description',
      type: 'ACCOUNT',
    },
    {
      code: 'FACTORING',
      description: 'payment_factoring_description',
      type: 'FACTORING',
    },
    {
      code: 'COMPENSATION',
      description: 'payment_compensation_description',
      type: 'COMPENSATION',
    },
    {
      code: 'TRANSFER',
      description: 'payment_transfer_description',
      type: 'TRANSFER',
    },
    {
      code: 'WAIVER',
      description: 'payment_waiver_description',
      type: 'WAIVER',
    },
    {
      code: 'KIND',
      description: 'payment_kind_description',
      type: 'KIND',
    },
    {
      code: 'OTHER',
      description: 'payment_other_description',
      type: 'OTHER',
    },
  ],
  'EINVOICE': [],
  'WAREHOUSE_NOTE': [],
  'REVERSE_CHARGE': {
    'CASH': [
      {
        code: 'BANKNOTE',
        description: 'payment_banknote_description',
        type: 'BANKNOTE',
      },
      {
        code: 'CARD',
        description: 'payment_card_description',
        type: 'CARD',
      },
      {
        code: 'CHECK',
        description: 'payment_check_description',
        type: 'CHECK',
        disabled: true,
      },
      {
        code: 'SVOUCHER',
        description: 'payment_voucher_description',
        type: 'SVOUCHER',
        disabled: true,
      },
      {
        code: 'COMPANY',
        description: 'payment_company_description',
        type: 'COMPANY',
        disabled: true,
      },
      {
        code: 'ORDER',
        description: 'payment_order_description',
        type: 'ORDER',
        disabled: true,
      },
    ],
    'NONCASH': [
      {
        code: 'ACCOUNT',
        description: 'payment_account_description',
        type: 'ACCOUNT',
      },
      {
        code: 'FACTORING',
        description: 'payment_factoring_description',
        type: 'FACTORING',
      },
      {
        code: 'COMPENSATION',
        description: 'payment_compensation_description',
        type: 'COMPENSATION',
      },
      {
        code: 'TRANSFER',
        description: 'payment_transfer_description',
        type: 'TRANSFER',
      },
      {
        code: 'WAIVER',
        description: 'payment_waiver_description',
        type: 'WAIVER',
      },
      {
        code: 'KIND',
        description: 'payment_kind_description',
        type: 'KIND',
      },
      {
        code: 'OTHER',
        description: 'payment_other_description',
        type: 'OTHER',
      },
    ],
  },
}

const DEFAULT_PAYMENT_METHOD = {
  'CASH': {
    code: 'BANKNOTE',
    description: 'payment_banknote_description',
    type: 'BANKNOTE',
  },
  'NONCASH': {},
  'EXPORT_OF_GOODS': {},
  'EINVOICE': {},
  'WAREHOUSE_NOTE': {},
  'REVERSE_CHARGE': {},
}

const EXPORT_VAT = [
  {
    code: 'EXPORT OF SERVICES',
    description: 'payment_tax_free_description',
    type: 'EXPORT_OF_SERVICES',
  },
  {
    code: 'EXPORT OF GOODS',
    description: 'payment_exportof_goods_description',
    type: 'EXPORT_OF_GOODS',
  },
]

export {
  INVOICING_METHODS,
  PAYMENT_METHODS_LABELS,
  AMOUNT_ALL_VALUES,
  AMOUNT_EUR_VALUES,
  AMOUNT_USD_VALUES,
  AMOUNT_GBP_VALUES,
  NUM_OF_SUGGESTIONS,
  PAYMENT_STEPS,
  EINVOICE_PAYMENT_STEPS,
  PAYMENT_METHODS,
  DEFAULT_PAYMENT_METHOD,
  EXPORT_VAT,
  stepsArrayIndex,
  STEPS,
  INPUT_CONTENT,
  FOOTER_CONTENT,
  SUPPORTIVE_EINVOICE_PAYMENT_STEPS,
  stepsIndex,
}
