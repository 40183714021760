import {styleSheetFactory} from '../../Themes/Colors'

const styles = styleSheetFactory(colors => ({
  container: {
    flex: 1,
    backgroundColor: colors.background,
  },
  primaryIcon: {
    marginLeft: 12,
  },
  listIcon: {
    width: 30,
    height: 30,
    tintColor: colors.secondaryIcon,
  },
  listItem: {
    marginVertical: 12,
    padding: 5,
    borderRadius: 7,
    overflow: 'hidden',
  },
}))

export default styles
