import { styleSheetFactory } from '../../../../../Themes/Colors'

const styles = styleSheetFactory(colors => ({
  content: {
    flexGrow: 1,
    paddingHorizontal: 15,
    paddingBottom: 24,
  },
  addCustomer: {
    height: 50,
    marginVertical: 5,
  },
  sectionTitle: {
    marginBottom: 5,
  },
  customerHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  removeCustomer: {
    borderRadius: 18,
    padding: 5,
    backgroundColor: colors.separatorBody,
    alignSelf: 'flex-end',
    alignItems: 'flex-end',
  },
  bankAccount: {
    marginBottom: 15,
  },
  removeBuyer: {
    borderRadius: 18,
    padding: 5,
    backgroundColor: colors.separatorBody,
  },
  bankHeading: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 10,
  },
  bankDetailsWrapper: {
    backgroundColor: colors.white,
    padding: 10,
    borderRadius: 7,
    alignItems: 'flex-start',
    marginBottom: 15,
  },
  horizontalSpacing: { marginHorizontal: 0 },
  rightSpacing: { marginRight: 10 },
}))

export default styles
