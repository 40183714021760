/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef, useContext } from 'react'
import { SafeAreaView, View, TouchableOpacity, Animated, Easing } from 'react-native'
import PropTypes from 'prop-types'
import moment from 'moment'
import { useLazyQuery } from '@apollo/client'
import { useTheme } from 'react-native-themed-styles'

import {
    Loader,
    Text,
    Icon,
    Header,
} from '../../Components'
import { useLanguage } from '../../Utils/Language'
import { INF } from '../../Utils/Helpers'

import { LIST_CASH_DRAWER_SHIFTS_EVENTS } from './TodaySales.schema'

import Theme from '../../Themes/Theme'
import images from '../../Themes/Images'
import themedStyles, { itemStyles } from './TodaySales.Styles'


const AnimatedTouchable = Animated.createAnimatedComponent(TouchableOpacity)

const LANGUAGES = {
    al: 'sq',
    en: 'en-gb',
}

function Item({
    item,
    onPress,
    style,
}) {
    const [styles] = useTheme(itemStyles)
    const { colors } = useContext(Theme)
    const lang = useLanguage()

    return (
        <View style={[styles.itemStyle, style]}>
            <TouchableOpacity onPress={onPress} disabled={!onPress}>
                <View style={styles.contentWrapper}>
                    <View style={styles.listDescContainer}>
                        <Text i18nKey={item.description} numberOfLines={1} size="footnote" />
                        <Text
                            i18nKey={item?.employee?.email}
                            weight="light"
                            size="footnote"
                            color={colors.secondaryText}
                            numberOfLines={1}
                        />
                        <Text
                            i18nKey={item?.FCDC || '...'}
                            numberOfLines={1}
                            size="footnote"
                            weight="light"
                            color={colors.secondaryText}
                        />
                    </View>
                    <View style={styles.rightComponent}>
                        <Text i18nKey={moment(item?.createdAt).locale(LANGUAGES[lang]).format('hh:mm A')} weight="light" size="footnote" />
                        <Text i18nKey={`+${INF.format(item?.eventMoney?.amount)} ${item?.eventMoney?.currency}`} />
                    </View>
                </View>
            </TouchableOpacity>
        </View >
    )
}
Item.propTypes = {
    item: PropTypes.object,
    onPress: PropTypes.func,
    style: PropTypes.object,
}

function TodaySales({
    route: {
        params: {
            shiftId,
            cashRegisterId,
        },
    },
    navigation: {
        goBack,
        navigate,
    },
}) {
    const [styles] = useTheme(themedStyles)
    const { colors } = useContext(Theme)
    const lang = useLanguage()

    const [items, setItems] = useState([])
    // const [value, setValue] = useState('')
    const [loading, setLoading] = useState(true)
    const listRef = useRef(null)

    const fabSize = useRef(new Animated.Value(0)).current

    const [list, { loading: listLoading, data: listData, refetch, fetchMore }] = useLazyQuery(
        LIST_CASH_DRAWER_SHIFTS_EVENTS,
        {
            fetchPolicy: 'cache-and-network',
        },
    )

    const listShifts = () => {
        list({
            variables: {
                locationId: '123123',
                shiftId: shiftId,
                limit: 20,
                eventType: 'CASH_TENDER_PAYMENT',
                cashRegisterId: cashRegisterId,
            },
        })
    }

    useEffect(() => {
        listShifts()
    }, [])

    useEffect(() => {
        if (listData) {
            setItems(listData?.listCashDrawerShiftEvents?.events)
            setLoading(false)
        }
    }, [listData])

    const onEndReached = () => {
        if (listData?.listCashDrawerShiftEvents?.cursor === null) {
            return
        }
        fetchMore({
            query: LIST_CASH_DRAWER_SHIFTS_EVENTS,
            variables: {
                locationId: '123123',
                cursor: listData?.listCashDrawerShiftEvents?.cursor,
                limit: 20,
                shiftId: shiftId,
                eventType: 'CASH_TENDER_PAYMENT',
                cashRegisterId: cashRegisterId,
            },
        })
    }

    const animate = val => {
        Animated.timing(fabSize, {
            toValue: val,
            duration: 370,
            useNativeDriver: true,
            easing: Easing.inOut(Easing.cubic),
        }).start()
    }

    const scrollToTop = () => {
        animate(0)
        listRef.current.scrollToOffset({
            offset: 0,
        })
    }


    return (
        <SafeAreaView style={styles.container} >
            <Header
                title="header_cash_sales"
                image={images.back}
                onPress={() => goBack()}
            />
            <Animated.FlatList
                showsVerticalScrollIndicator={false}
                keyExtractor={(item, index) => 'row-' + index}
                ref={(ref) => (listRef.current = ref)}
                onMomentumScrollEnd={(event) => {
                    if (event.nativeEvent.contentOffset.y > 800) {
                        animate(1)
                    } else {
                        animate(0)
                    }
                }}
                data={items}
                style={styles.listStyle}
                contentContainerStyle={styles.listContainerStyle}
                renderItem={({ item, index }) => {
                    const style = {}
                    if (index === 0) {
                        style.borderTopLeftRadius = 7
                        style.borderTopRightRadius = 7
                    }
                    if (index === items.length - 1) {
                        style.borderBottomLeftRadius = 7
                        style.borderBottomRightRadius = 7
                    }
                    return <Item
                        key={'row-' + index}
                        item={item}
                        style={style}
                        onPress={() => navigate('Receipt', { id: item.externalId, receiptNumber: item.description })}
                    />
                }}
                ListFooterComponent={listLoading && <Loader size={25} />}
                ListEmptyComponent={listLoading || loading ? null : <Text i18nKey="cash_sales_no_data" style={styles.topSpacing} />}
                ListHeaderComponent={Boolean(items.length) && <Text
                    i18nKey={moment(items?.[0]?.createdAt).locale(LANGUAGES[lang]).format('MMM DD, YYYY')}
                    size="footnote"
                    style={styles.bottomSpacing}
                />}
                ItemSeparatorComponent={() => <View style={styles.separator} />}
                refreshing={false}
                onRefresh={() => refetch()}
                onEndReached={onEndReached}
            />

            <AnimatedTouchable
                onPress={scrollToTop}
                activeOpacity={0.5}
                style={[
                    styles.fab,
                    {
                        opacity: fabSize,
                        transform: [{ scale: fabSize }],
                    },
                ]}>
                <Icon
                    source={images.arrow_right}
                    size={20}
                    color={colors.tertiaryIcon}
                    style={styles.fabIcon}
                />
            </AnimatedTouchable>

            {/* <View style={styles.searchContainer}>
                <View style={styles.inputContainer}>
                    <View style={styles.searchIconContainer}>
                        <Icon source={images.search} size={18} color={colors.secondaryIcon} />
                    </View>
                    <TextInput
                        placeholder="search_employee_event_placeholder"
                        placeholderTextColor={colors.placeholder}
                        style={styles.searchInput}
                        value={value}
                        onChangeText={setValue}
                    />
                    {value !== '' &&
                        <TouchableOpacity onPress={() => setValue('')} style={styles.clearContainer}>
                            <Icon source={images.close} color={colors.secondaryIcon} size={15} />
                        </TouchableOpacity>
                    }
                </View>
            </View> */}
        </SafeAreaView>
    )
}
TodaySales.propTypes = {
    route: PropTypes.object,
    navigation: PropTypes.object,
}

export default TodaySales
