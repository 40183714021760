
import React, { useContext, useMemo, useLayoutEffect } from 'react'
import {
  LayoutAnimation,
  View,
  TouchableOpacity,
} from 'react-native'

import { useTheme } from 'react-native-themed-styles'
import { useMutation } from '@apollo/client'
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'

import { Button, Icon, Tabs as TabsComponent } from '../../../../Components'
import ContentManager from '../../../../Utils/ContentManager'
import { getErrorMessage, useConfig, useUserDetails } from '../../../../Utils'
import Theme, { opacityConfig } from '../../../../Themes/Theme'
import images from '../../../../Themes/Images'

import { getWTNSaveInput } from '../../Helpers'
import { REGISTER_SUPPORTING_INVOICE } from '../../Payment.Schema'
import { dismissPaymentError, displayPaymentError, supportInvoiceUpdateStep, updatePaymentAttribute } from '../../Payment.Actions'
import { getSupportInvoice } from '../../Payment.Selectors'

import Step from '../Step'

import themedStyles, { optionStyles } from './FourthStep.Styles'
import { BasicInfo, TransportInfo, Items, IssuerCarrier } from './Tabs'

const { translate } = ContentManager
const Tab = createMaterialTopTabNavigator()

function Option(props) {
  const [styles] = useTheme(optionStyles)
  const { colors } = useContext(Theme)

  const {
    icon,
    iconColor = colors.secondaryIcon,
    onPress,
    disabled,
    style,
  } = props


  return (
    <TouchableOpacity
      onPress={onPress}
      style={[styles.container, styles['containerDisabled_' + disabled], style]}
      disabled={disabled}>
      <Icon source={icon} size={18} color={iconColor} />
    </TouchableOpacity>
  )
}
Option.defaultProps = {
  onPress: () => { },
  icon: images.defaultIcon,
  disabled: false,
}
Option.propTypes = {
  icon: PropTypes.any,
  onPress: PropTypes.func,
  style: PropTypes.object,
  count: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
}

function FourthStep({
  goBack,
  stepperLength,
  index,
  addListener,
  dispatch,
  navigate,
}) {
  const { switchLocation: { deviceId, locationId } } = useConfig()

  const [styles] = useTheme(themedStyles)
  const { colors } = useContext(Theme)
  const { id } = useUserDetails()

  const dispatchAction = useDispatch()
  const [registerWtn, { loading }] = useMutation(REGISTER_SUPPORTING_INVOICE)

  const setPaymentStatus = (value) => dispatchAction(updatePaymentAttribute('paymentStatus', value))

  const dismissError = () => dispatchAction(dismissPaymentError())

  const displayError = (
    title = '',
    desc = '',
    primaryText = '',
    primaryAction = null,
    secondaryText = '',
    secondaryAction = null,
    isDismissable = true,
  ) => {
    dispatchAction(displayPaymentError({
      isVisible: true,
      title: title,
      desc: desc,
      primaryText: primaryText,
      primaryAction: primaryAction,
      secondaryText: secondaryText,
      secondaryAction: secondaryAction,
      isDismissable: isDismissable,
    }))
  }

  const allInvoice = useSelector(getSupportInvoice)

  const onPrevPress = () => {
    LayoutAnimation.configureNext(opacityConfig)
    setPaymentStatus('issuerCarrier')
  }

  useLayoutEffect(() => {
    LayoutAnimation.configureNext(opacityConfig)
  }, [])

  const noteMapper = (unMappedNote) => {
    let note = {}
    unMappedNote?.map((item) => {
      const itemValue = item.substring(item.indexOf('=') + 1)
      const itemKey = item.substring(0, item.indexOf('='))
      const noteitem = { [itemKey]: itemValue }
      note = { ...note, ...noteitem }
    })
    return note.id
  }

  const registerWareHouse = () => {
    registerWtn({
      variables: {
        locId: locationId,
        cashRegisterId: deviceId,
        warehouseNoteInput: getWTNSaveInput(allInvoice),
      },
    })
      .then((res) => {
        const note = res?.data?.registerWarehouseNote?.note
        const registerId = noteMapper(note)
        dispatchAction(supportInvoiceUpdateStep('registerId', registerId))
        setPaymentStatus('print')
      })
      .catch((error) => {
        const errorMessage = getErrorMessage(error)
        if (errorMessage === 'TaxpayerDoesNotExist') {
          displayError(
            'einvoice_opcode_error_title',
            'einvoice_opcode_error_description',
            'einvoice_opcode_error_button',
            () => {
              navigate('UserDetails', { id })
              dismissError()
            },
            'einvoice_cancel_modal_button',
            () => dismissError(),
            false,
          )
        } else {
          displayError(
            'checkount_error_message',
            errorMessage,
            'einvoice_cancel_modal_button',
            () => dismissError(),
            '',
            undefined,
            false,
          )
        }
      })
  }

  const Tabs = useMemo(() => (
    <TabsComponent
      style={styles.tabView}
      screenOptions={{
        tabBarScrollEnabled: true,
      }}
    >
      <Tab.Screen key={'BasicInfo'} name={translate('einvoice_details_info_tab')} component={BasicInfo} />
      <Tab.Screen key={'Items'} name={translate('einvoice_preview_items_tab')} component={Items} />
      <Tab.Screen key={'IssuerCarrier'} name={translate('supporting_invoice_issuerCarrier_title')} component={IssuerCarrier} />
      <Tab.Screen key={'TransportInfo'} name={translate('supporting_invoice_transport_button')} component={TransportInfo} />
    </TabsComponent>
  // eslint-disable-next-line react-hooks/exhaustive-deps
  ), [])

  return (
    <Step
      title="header_preview_einvoice"
      goBack={goBack}
      stepperLength={stepperLength}
      index={index}
      addListener={addListener}
      dispatch={dispatch}
    >
      {Tabs}
      <View style={styles.buttons}>
        <Option
          icon={images.arrowLeft}
          style={styles.leftButton}
          iconColor={loading ? colors.white : colors.secondaryIcon}
          disabled={loading}
          onPress={() => onPrevPress()}
        />
        <Button
          title="certificate_button_finish"
          variant={loading ? 'disabled' : 'active'}
          rightComponent={!loading && <Icon
            size={20}
            source={images.arrowRight}
            color={colors.white}
            style={styles.leftSpacing}
          />}
          onPress={() => registerWareHouse()}
          loader={loading}
          style={styles.mainButton}
        />
      </View>
    </Step>
  )
}

FourthStep.propTypes = {
  goBack: PropTypes.func,
  stepperLength: PropTypes.number,
  index: PropTypes.number,
  addListener: PropTypes.func,
  dispatch: PropTypes.func,
  navigate: PropTypes.func,
}

export default FourthStep

