import { styleSheetFactory } from '../../Themes/Colors'

const styles = styleSheetFactory(colors => ({
  container: {
    position: 'absolute',
    width: 50,
    height: 50,
    borderRadius: 25,
    overflow: 'hidden',
    backgroundColor: colors.tertiaryBackground,
  },
  content: {
    width: 50,
    height: 50,
    justifyContent: 'center',
    alignItems: 'center',
  },
}))


export default styles
