import { Platform } from 'react-native'

import { styleSheetFactory } from '../../Themes/Colors'

const styles = styleSheetFactory(colors => ({
  container: (color = colors.background) => {
    return {
      backgroundColor: color,
      height: 150,
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'relative',
      overflow: 'hidden',
    }
  },
  image: {
    height: '100%',
    width: '100%',
    position: 'absolute',
  },
  imagePickerContainer: {
    flex: 1,
    paddingVertical: 20,
    alignItems: 'flex-end',
    overflow: 'hidden',
  },
  imagePicker: {
    height: 93,
    width: 93,
    borderRadius: 7,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: colors.tertiaryBackground,
  },
  galleryIcon: {
    flex: 1,
    margin: 25,
    width: '100%',
    resizeMode: 'contain',
    tintColor: colors.midGrey,
  },
  seperatorContainer: {
    height: 93,
    width: 2,
    backgroundColor: colors.tertiaryBackground,
    justifyContent: 'center',
    zIndex: 2,
  },
  seperator: {
    height: 78,
    width: 2,
    backgroundColor: colors.lightGrey,
  },
  colorPickerContainer: {
    flex: 1,
    paddingVertical: 20,
    flexDirection: 'row',
    overflow: 'hidden',
  },
  colorPicker: {
    height: 93,
    width: 93,
    borderRadius: 7,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: colors.tertiaryBackground,
  },
  colorIcon: {
    flex: 1,
    width: '100%',
    margin: 25,
    resizeMode: 'contain',
  },
}))

const colorStyles = styleSheetFactory(colors => ({
  modalTitle: {
    marginBottom: 8,
  },
  container: {
    width: '100%',
  },
  contentContainer: {
    padding: 15,
    paddingHorizontal: 40,
    justifyContent: 'center',
  },
  shadow: {
    ...Platform.OS !== 'web' && {
      shadowColor: colors.black,
      shadowOffset: {
        width: 0,
        height: 0,
      },
      shadowOpacity: 0.2,
      shadowRadius: 6,
    },
    width: '33.33%',
    aspectRatio: 1,
  },
  colorContainer: color => {
    return {
      flexGrow: 1,
      borderRadius: 6,
      margin: 8,
      backgroundColor: color,
    }
  },
}))

const imageStyles = styleSheetFactory(colors => ({
  modalContainer: {
    backgroundColor: colors.white,
    alignSelf: 'center',
    paddingBottom: 30,
    borderRadius: 6,
  },
  closeContainer: {
    backgroundColor: colors.background,
    position: 'absolute',
    top: 0,
    right: 0,
    borderTopRightRadius: 7,
    borderBottomLeftRadius: 7,
    width: 75,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 10,
  },
  modalContent: {
    paddingHorizontal: 40,
    marginTop: 45,
  },
  modalTitle: {
    marginTop: 8,
    marginBottom: 16,
  },
  optionsContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  option: {
    alignItems: 'center',
    padding: 25,
    margin: 8,
    borderRadius: 7,
    backgroundColor: colors.tertiaryBackground,
  },
  shadow: {
    shadowColor: colors.black,
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.2,
    shadowRadius: 6,
  },
}))

export {
  colorStyles,
  imageStyles,
}
export default styles

