import React, { useState, useContext } from 'react'
import { View, TouchableOpacity, Dimensions } from 'react-native'
import ContentLoader, { Rect } from 'react-content-loader/native'
import PropTypes from 'prop-types'
import { useTheme } from 'react-native-themed-styles'

import {
  Icon,
  SwipeList,
  Text,
  Loader,
} from '../../../../Components'



import Theme from '../../../../Themes/Theme'
import images from '../../../../Themes/Images'
import ContentManager from '../../../../Utils/ContentManager'

import themedStyles, { discountStyles, hiddenItemStyles, separatorStyles } from './Discounts.Styles'

const { width } = Dimensions.get('screen')
const types = {
  FIXED_AMOUNT: 'FIXED_AMOUNT',
  VARIABLE_AMOUNT: 'VARIABLE_AMOUNT',
  FIXED_PERCENTAGE: 'FIXED_PERCENTAGE',
  VARIABLE_PERCENTAGE: 'VARIABLE_PERCENTAGE',
  VARIABLE: 'VARIABLE',
  FIXED: 'FIXED',
}

function Discount({ name, discountType, percentage, prices, onPress, isFirst, isLast }) {
  const [styles] = useTheme(discountStyles)

  const getItemPrice = (discPrices) => {
    let result
    if (discPrices?.length === 1) {
      result = `${discPrices?.[0]?.amount} ${discPrices?.[0]?.currency}`
    } else {
      result = `${discPrices?.length} ${ContentManager.translate('checkout_variable_price_label')}`
    }
    return result
  }

  return <View style={[styles.container, styles['firstItem_' + isFirst], styles['lastItem_' + isLast]]}>
    <TouchableOpacity onPress={onPress} style={styles.touchable}>
      <Text i18nKey={name} numberOfLines={2} style={styles.title} />
      {discountType?.match(types.VARIABLE)
        ? <Icon source={images.chevronRight} style={styles.rightIcon} />
        : discountType === types.FIXED_AMOUNT
          ? <Text i18nKey={getItemPrice(prices)} style={styles.desc} />
          : <Text i18nKey="discount_percentage" placeHolders={[percentage]} style={styles.desc} />
      }
    </TouchableOpacity>
  </View>
}

Discount.propTypes = {
  name: PropTypes.string,
  amountMoney: PropTypes.shape({
    amount: PropTypes.number,
    currency: PropTypes.string,
  }),
  prices: PropTypes.array,
  discountType: PropTypes.string,
  percentage: PropTypes.string,
  onPress: PropTypes.func,
  isFirst: PropTypes.bool,
  isLast: PropTypes.bool,
}

function DiscountPlaceholder({ isFirst, isLast }) {
  const [styles] = useTheme(themedStyles)

  return (
    <TouchableOpacity style={[styles.itemContainer, styles['fistItem_' + isFirst], styles['lastItem_' + isLast]]} disabled>
      <ContentLoader
        speed={2}
        width={width - 30}
        height={60}
        backgroundColor="#f3f3f3"
        foregroundColor="#fff"
      >
        <Rect x="15" y="21" rx="6" ry="6" width={`${(width - 30) * (3 / 5)}`} height="18" />
        <Rect x={`${width - 128}`} y="21" rx="6" ry="6" width="50" height="18" />
        <Rect x={`${width - 63}`} y="21" rx="3" ry="3" width="18" height="18" />
      </ContentLoader>
    </TouchableOpacity>
  )
}
DiscountPlaceholder.propTypes = {
  isFirst: PropTypes.bool,
  isLast: PropTypes.bool,
}

function HiddenItem({ needsConfirmation, onPress, onConfirm, onCancel, loading, isFirst, isLast }) {
  const [styles] = useTheme(hiddenItemStyles)
  const { colors } = useContext(Theme)

  return (
    <View style={[styles.wrapper, styles['firstItem_' + isFirst], styles['lastItem_' + isLast]]}>
      {loading
        ? <View style={styles.loader}>
          <Loader size={30} color={colors.white} />
        </View>
        : needsConfirmation
          ? <TouchableOpacity
            style={styles.option}
            onPress={onPress}
          >
            <Icon source={images.delete} color={colors.white} size={24} />
          </TouchableOpacity>
          : <View style={styles.optionFull}>
            <TouchableOpacity
              style={styles.confirm}
              onPress={onConfirm}
            >
              <Icon source={images.delete} color={colors.white} size={24} />
              <Text i18nKey="opened_orders_delete_confirm" color={colors.white} style={styles.confirmText} />
            </TouchableOpacity>
            <TouchableOpacity
              style={styles.cancel}
              onPress={onCancel}
            >
              <Icon source={images.closeIcon} color={colors.white} size={20} />
            </TouchableOpacity>
          </View>
      }
    </View>
  )
}
HiddenItem.propTypes = {
  needsConfirmation: PropTypes.bool,
  onPress: PropTypes.func,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  loading: PropTypes.bool,
  isFirst: PropTypes.bool,
  isLast: PropTypes.bool,
}

function Separator() {
  const [styles] = useTheme(separatorStyles)
  return <View style={styles.container} />
}


function Discounts({
  selectedDiscounts,
  updateSelection,
  navigate,
}) {
  const [styles] = useTheme(themedStyles)

  const [needsConfirmation, setNeedForConfirmation] = useState(true)
  const [discounts, setDiscounts] = useState([...selectedDiscounts])
  const [itemWidth, setItemWidth] = useState(0)
  const [searchText, setSearchText] = useState('')



  const onSearch = (text) => {
    setSearchText(text)
    setDiscounts(selectedDiscounts?.filter(element => element.name?.toLowerCase().match(text.toLowerCase())))
  }

  const updateDiscounts = updated => {
    updateSelection(updated)
    setDiscounts(updated?.filter(element => element.name?.toLowerCase().match(searchText.toLowerCase())))
  }

  const addDiscounts = () => {
    navigate('Discounts', { selectable: true, preselectedDiscounts: selectedDiscounts, updateSelection: updateDiscounts })
  }

  const changeDiscount = discount => {
    const i = selectedDiscounts.findIndex(element => element.id === discount.id)
    if (i !== -1) {
      const updated = selectedDiscounts
      updated[i] = discount
      updateDiscounts([...updated])
    }
  }

  const editDiscount = item => {
    if (item) {
      navigate('NewDiscount', { discountItem: item, onSave: changeDiscount })
    }
  }

  const renderItem = ({ item, index }) => (
    <Discount
      {...item}
      onPress={() => editDiscount(item)}
      isFirst={index === 0}
      isLast={index === discounts?.length - 1}
    />
  )

  const openRow = (rowMap, id) => {
    setNeedForConfirmation(false)
    rowMap[id].manuallySwipeRow(-itemWidth)
  }

  const closeRow = (rowMap, id) => {
    rowMap[id].closeRow()
    setNeedForConfirmation(true)
  }

  const deleteRow = (rowMap, id) => {
    updateDiscounts(discounts?.filter(element => element.id !== id))
    closeRow(rowMap, id)
  }

  const renderHiddenItem = ({ item, index }, rowMap) => (
    <HiddenItem
      needsConfirmation={needsConfirmation}
      onPress={() => openRow(rowMap, item.id)}
      onConfirm={() => deleteRow(rowMap, item.id)}
      onCancel={() => closeRow(rowMap, item.id)}
      isFirst={index === 0}
      isLast={index === discounts?.length - 1}
    />
  )

  return (
    <SwipeList
      searchValue={searchText}
      searchPlaceholder="discount_search_placeholder"
      onSearch={onSearch}
      data={discounts}
      renderItem={renderItem}
      renderHiddenItem={renderHiddenItem}
      disableRightSwipe
      rightOpenValue={-100}
      onRowClose={() => setNeedForConfirmation(true)}
      setItemWidth={setItemWidth}
      createNewLabel="discount_button_add"
      onCreatePress={addDiscounts}
      buttonVariant={'secondary'}
      ItemSeparatorComponent={Separator}
      ListEmptyComponent={<Text i18nKey="discount_no_selected_message" align="center" />}
      containerStyle={styles.container}
    />
  )
}

Discounts.defaultProps = {
  selectedDiscounts: [],
  updateSelection: () => { },
}

Discounts.propTypes = {
  selectedDiscounts: PropTypes.arrayOf(PropTypes.object),
  updateSelection: PropTypes.func,
  navigate: PropTypes.func,
  locationId: PropTypes.string,
}

export default Discounts
