const ON_BOARDING_UPDATE_STEP = 'ON_BOARDING_UPDATE_STEP'
const ON_BOARDING_UPDATE_STEP_KEY = 'ON_BOARDING_UPDATE_STEP_KEY'
const ON_BOARDING_RESET_STATE = 'ON_BOARDING_RESET_STATE'

const onBoardingUpdateStep = (step, value) => ({
    type: ON_BOARDING_UPDATE_STEP,
    step,
    value,
  })

  const onBoardingUpdateStepKey = (step, key, value) => ({
    type: ON_BOARDING_UPDATE_STEP_KEY,
    step,
    key,
    value,
  })

  const onBoardingResetState = () => ({
    type: ON_BOARDING_RESET_STATE,
  })

export {
    ON_BOARDING_UPDATE_STEP,
    ON_BOARDING_UPDATE_STEP_KEY,
    ON_BOARDING_RESET_STATE,
    onBoardingUpdateStep,
    onBoardingUpdateStepKey,
    onBoardingResetState,
}
