/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useMemo, useLayoutEffect } from 'react'
import {
  LayoutAnimation,
  View,
  TouchableOpacity,
} from 'react-native'
import { useTheme } from 'react-native-themed-styles'
import { useMutation } from '@apollo/client'
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'

import { Button, Icon, Tabs as TabsComponent } from '../../../../Components'
import ContentManager from '../../../../Utils/ContentManager'
import { getErrorMessage, useConfig, useUserDetails } from '../../../../Utils'

import Theme, { opacityConfig } from '../../../../Themes/Theme'
import images from '../../../../Themes/Images'

import Step from '../Step'
import { REGISTER_EINVOICE } from '../../Payment.Schema'
import { getEinvoiceCalculated } from '../../Payment.Selectors'
import {
  resetState,
  resetItems,
  updatePaymentAttribute,
  dismissPaymentError,
  displayPaymentError,
  resetEinvoiceState,
} from '../../Payment.Actions'
import { filterOutCart } from '../../Helpers'
import { resetOrder } from '../../../Checkout/Checkout.Actions'
import { PAYMENT_STEPS } from '../../Payment.Config'

import { ProcessDoc, BuyerSeller, Items, AdditionalData } from './Tabs'

import themedStyles, { optionStyles } from './SixthStep.Styles'

const Tab = createMaterialTopTabNavigator()

function Option(props) {
  const [styles] = useTheme(optionStyles)
  const { colors } = useContext(Theme)

  const {
    icon,
    iconColor = colors.secondaryIcon,
    onPress,
    disabled,
    style,
  } = props

  return (
    <TouchableOpacity
      onPress={onPress}
      style={[styles.container, styles['containerDisabled_' + disabled], style]}
      disabled={disabled}>
      <Icon source={icon} size={18} color={iconColor} />
    </TouchableOpacity>
  )
}
Option.defaultProps = {
  onPress: () => { },
  icon: images.defaultIcon,
  disabled: false,
}
Option.propTypes = {
  onPress: PropTypes.func,
  icon: PropTypes.object,
  style: PropTypes.object,
  count: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  disabled: PropTypes.bool,
}

function SixthStep({
  goBack,
  goBackCustom,
  stepperLength,
  index,
  addListener,
  dispatch,
  removeListener,
  navigate,
}) {

  const { switchLocation: { deviceId, locationId } } = useConfig()

  const [styles] = useTheme(themedStyles)
  const { colors } = useContext(Theme)
  const { id } = useUserDetails()

  const { translate } = ContentManager
  const dispatchAction = useDispatch()

  const clearOrder = () => dispatchAction(resetOrder())
  const setPaymentStatus = (value) => dispatchAction(updatePaymentAttribute('paymentStatus', value))
  const dismissError = () => dispatchAction(dismissPaymentError())
  const displayError = (
    title = '',
    desc = '',
    primaryText = '',
    primaryAction = null,
    secondaryText = '',
    secondaryAction = null,
    isDismissable = true,
  ) => {
    dispatchAction(displayPaymentError({
      isVisible: true,
      title: title,
      desc: desc,
      primaryText: primaryText,
      primaryAction: primaryAction,
      secondaryText: secondaryText,
      secondaryAction: secondaryAction,
      isDismissable: isDismissable,
    }))
  }
  const [registerInvoice, { loading }] = useMutation(REGISTER_EINVOICE)

  const calculatedUBL = useSelector(getEinvoiceCalculated)
  const onButtonPress = (step) => {
    LayoutAnimation.configureNext(opacityConfig)
    setPaymentStatus(step)
  }

  useLayoutEffect(() => {
    LayoutAnimation.configureNext(opacityConfig)
  }, [])

  const registerEinvoice = () => {
    registerInvoice({
      variables: {
        locId: locationId,
        cashRegisterId: deviceId,
        eInvoiceInput: {
          ublInvoice: calculatedUBL,
        },
      },
    })
      .then(() => {
        removeListener('beforeRemove')
        dispatchAction(resetState())
        dispatchAction(resetEinvoiceState())
        dispatchAction(resetItems())
        clearOrder()
        dispatch(filterOutCart)
        setPaymentStatus(PAYMENT_STEPS.init)
        goBackCustom()
      })
      .catch((error) => {
        const errorMessage = getErrorMessage(error)
        if (errorMessage === 'TaxpayerDoesNotExist') {
          displayError(
            'einvoice_opcode_error_title',
            'einvoice_opcode_error_description',
            'einvoice_opcode_error_button',
            () => {
              navigate('UserDetails', { id })
              dismissError()
            },
            'einvoice_cancel_modal_button',
            () => dismissError(),
            false,
          )
        } else if (errorMessage === 'GovError') {
          displayError(
            'checkount_error_message',
            error?.message,
            'einvoice_cancel_modal_button',
            () => {
              dismissError()
              removeListener('beforeRemove')
              goBackCustom()
              dispatchAction(resetState())
              dispatchAction(resetEinvoiceState())
              // dispatchAction(resetItems())
              // clearOrder()
              dispatch(filterOutCart)
              setPaymentStatus(PAYMENT_STEPS.init)
            },
            '',
            undefined,
            false,
          )
        } else {
          displayError(
            'checkount_error_message',
            errorMessage,
            'einvoice_cancel_modal_button',
            () => {
              dismissError()
              removeListener('beforeRemove')
              goBackCustom()
              dispatchAction(resetState())
              dispatchAction(resetEinvoiceState())
              // dispatchAction(resetItems())
              // clearOrder()
              dispatch(filterOutCart)
              setPaymentStatus(PAYMENT_STEPS.init)
            },
            '',
            undefined,
            false,
          )
        }
      })
  }

  const Tabs = useMemo(() => (
    <TabsComponent
      style={styles.tabView}
      screenOptions={{
        tabBarScrollEnabled: true,
      }}
    >
      <Tab.Screen key={'ProcessDoc'} name={translate('einvoice_preview_process_tab')} component={ProcessDoc} />
      <Tab.Screen key={'Items'} name={translate('einvoice_preview_items_tab')} component={Items} />
      <Tab.Screen key={'BuyerSeller'} name={translate('einvoice_preview_buyerseller_tab')} component={BuyerSeller} />
      <Tab.Screen key={'AdditionalData'} name={translate('einvoice_preview_additionalDet_tab')} component={AdditionalData} />
    </TabsComponent>
  ), [])

  return (
    <Step
      title="header_preview_einvoice"
      goBack={goBack}
      stepperLength={stepperLength}
      index={index}
      addListener={addListener}
      dispatch={dispatch}
    >
      {Tabs}
      <View style={styles.buttons}>
        <Option
          icon={images.arrowLeft}
          style={styles.leftButton}
          onPress={() => onButtonPress('notes')}
          disabled={loading}
          iconColor={loading ? colors.white : colors.secondaryIcon}
        />
        {/* <Option
          icon={images.bookmark}
          style={styles.leftButton}
          onPress={() => {
            removeListener('beforeRemove')
            dispatchAction(resetState())
            dispatchAction(resetItems())
            clearOrder()
            dispatch(filterOutCart)
            goBackCustom()
          }}
        /> */}
        <Button
          title="certificate_button_finish"
          variant={loading ? 'disabled' : 'active'}
          rightComponent={!loading && <Icon
            size={20}
            source={images.arrowRight}
            color={colors.white}
            style={styles.leftSpacing}
          />}
          onPress={registerEinvoice}
          loader={loading}
          style={styles.mainButton}
        />
      </View>
    </Step>
  )
}

SixthStep.propTypes = {
  goBack: PropTypes.func,
  goBackCustom: PropTypes.func,
  stepperLength: PropTypes.number,
  index: PropTypes.number,
  addListener: PropTypes.func,
  dispatch: PropTypes.func,
  removeListener: PropTypes.func,
  navigate: PropTypes.func,
}

export default SixthStep

