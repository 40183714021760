import { styleSheetFactory } from '../../Themes/Colors'

const styles = styleSheetFactory(colors => ({
  container: {
    margin: 0,
    width: '100%',
    height: '100%',
    justifyContent: 'flex-end',
  },
  contentContainerStyle: {
    backgroundColor: colors.background,
    width: '100%',
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
  },
  // container: {
  //   margin: 0,
  //   justifyContent: 'flex-end' ,
  // },
  // contentContainerStyle: {
  //   backgroundColor: colors.background,
  //   width: '100%',
  //   borderTopLeftRadius: 10,
  //   borderTopRightRadius: 10,
  // },
}))

export default styles


