import React from 'react'
import { ScrollView } from 'react-native'
import { useTheme } from 'react-native-themed-styles'
import PropTypes from 'prop-types'

import { Section } from '../../../Components'

import themedStyles from '../SupportiveInvoiceDetails.Styles'

function TransportInfo({ supportiveInvoiceData }) {
    const [styles] = useTheme(themedStyles)

    const referenceData = [
        {
            title: 'supporting_invoice_transport_button',
        },
        {
            label: 'supporting_invoice_transport_ownership',
            value: supportiveInvoiceData?.transportOwnership,
        },
        {
            label: 'supporting_invoice_transport_plate',
            value: supportiveInvoiceData?.plates,
        },
        {
            title: 'supporting_invoice_transport_starting_point',
        },
        {
            label: 'supporting_invoice_transport_starting_address',
            value: supportiveInvoiceData?.startingAddress,
        },
        {
            label: 'demo_city_label',
            value: supportiveInvoiceData?.startingCity,
        },
        {
            label: 'supporting_invoice_transport_starting_point',
            value: supportiveInvoiceData?.startingPoint,
        },
        {
            label: 'drawer_starting_date_label',
            value: supportiveInvoiceData?.startingDate,
        },
        {
            title: 'supporting_invoice_transport_destination_point',
        },
        {
            label: 'supporting_invoice_transport_destination_address',
            value: supportiveInvoiceData?.destinationAddress,
        },
        {
            label: 'demo_city_label',
            value: supportiveInvoiceData?.destinationCity,
        },
        {
            label: 'supporting_invoice_transport_destination_point',
            value: supportiveInvoiceData?.destinationPoint,
        },
        {
            label: 'supporting_invoice_transport_destination_date',
            value: supportiveInvoiceData?.destinationDate,
        },
        {
            title: 'einvoice_preview_items_tab',
        },
        {
            label: 'supporting_invoice_preview_dangerous',
            value: supportiveInvoiceData?.isItemDangerous ? 'cash_register_yes_button' : 'supporting_invoice_transport_button_question',
        },
        {
            label: 'supporting_invoice_preview_accompaniment',
            value: supportiveInvoiceData?.isEscort ? 'cash_register_yes_button' : 'supporting_invoice_transport_button_question',
        },

    ]

    return (
            <ScrollView
                style={styles.container}
                contentContainerStyle={styles.content}
                showsVerticalScrollIndicator={false}
            >
                <Section data={referenceData} />

            </ScrollView>
    )
}
TransportInfo.propTypes = {
    supportiveInvoiceData: PropTypes.object,
}

export default TransportInfo
