/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { SafeAreaView } from 'react-native'
import { useTheme } from 'react-native-themed-styles'
import PropTypes from 'prop-types'

import { useLazyQuery } from '@apollo/client'
import moment from 'moment'

import { useLocationDetails } from '../../Utils/AuthDetails'
import { Text, Button, Header, SimpleList, ListLoader, TutorialNote, ScrollWrapperWeb } from '../../Components'
import { GET_MERCHANT } from '../RegisterCertificate/RegisterCertificate.schema'

import themedStyles from './Certificate.Styles'

import images from '../../Themes/Images'

function Certificate({ navigation: { goBack, navigate } }) {
  const [styles] = useTheme(themedStyles)
  const { merchantId } = useLocationDetails()

  const [certificate, setCertificate] = useState({
    items: [],
    isLoading: true,
  })

  const [getCertificate, { refetch, data }] = useLazyQuery(
    GET_MERCHANT,
    {
      fetchPolicy: 'network-only',
    },
  )

  const getCertificateData = () => {
    if (merchantId) {
      getCertificate({
        variables: {
          id: merchantId,
        },
      })
    }
  }

  useEffect(() => {
    getCertificateData()
  }, [merchantId])

  useEffect(() => {
    if (data) {
      const certData = data?.getMerchant?.certificate
      if (certData) {
        const subject = certData?.CertData?.subject
        const issuer = certData?.CertData?.issuer
        setCertificate({
          items: [
            {
              id: 1,
              leftText: 'certificate_organisation_label',
              rightText: subject?.O,
            },
            {
              id: 2,
              leftText: 'certificate_email_label',
              rightText: certData?.contactEmail,
            },
            {
              id: 3,
              leftText: 'certificate_nipt_label',
              rightText: subject?.SN,
            },
            {
              id: 4,
              leftText: 'certificate_id_label',
              rightText: certData?.personalId,
            },
            {
              id: 5,
              leftText: 'certificate_phone_label',
              rightText: `${certData?.phoneNumber}`,
            },
            {
              id: 6,
              leftText: 'certificate_start_date_label',
              rightText: moment(new Date(certData?.CertData?.notBefore).toISOString()).format(
                'DD/MM/YYYY hh:mm A',
              ),
            },
            {
              id: 7,
              leftText: 'certificate_expiration_date_label',
              rightText: moment(new Date(certData?.CertData?.notAfter).toISOString()).format(
                'DD/MM/YYYY hh:mm A',
              ),
            },
            {
              id: 8,
              leftText: 'location_label_location',
              rightText: `${subject?.L}, ${subject?.C}`,
            },
            {
              id: 9,
              leftText: 'certificate_issuer_label',
              rightText: issuer?.CN,
            },
            {
              id: 10,
              leftText: 'certificate_subject_label',
              rightText: subject?.CN,
            },
          ],
          isLoading: false,
        })
      } else {
        setCertificate({
          items: [],
          isLoading: false,
        })
      }
    }
  }, [data])

  return (
    <SafeAreaView style={styles.container}>
      <Header
        title="settings_certificate_label"
        image={images.back}
        onPress={() => goBack()}
      />
      {certificate.isLoading ? (
        <ListLoader
          loading={certificate.isLoading}
          isInitial={certificate.items.length === 0}
        />
      ) : (
        <>
          <Button
            title={
              certificate.items.length
                ? 'certificate_update_header'
                : 'certificate_upload_label'
            }
            variant="active"
            style={styles.registerButton}
            onPress={() =>
              navigate('RegisterCertificate', {
                onSave: getCertificateData,
                data: Boolean(certificate.items.length),
              })
            }
          />
          {certificate.items.length ? null : <TutorialNote
            url="https://www.youtube.com/watch?v=OLNq43qtLOA"
            style={styles.horizontalSpacing}
          />
          }
          <Text
            i18nKey={
              certificate.items.length
                ? 'certificate_renew_description'
                : 'certificate_upload_description'
            }
            align="center"
          />
          <ScrollWrapperWeb>
            <SimpleList
              DATA={certificate.items}
              containerStyle={styles.listStyles}
              onRefresh={() => refetch()}
              refreshing={false}
              listEmptyComponent={
                <Text
                  i18nKey="certificate_empty_description"
                  align="center"
                  size="footnote"
                  weight="light"
                  style={styles.emptyComponent}
                />
              }
              listHeaderComponent={
                certificate.items.length ? (
                  <Text
                    i18nKey="certificate_info_section"
                    align="left"
                    style={styles.headerComponent}
                  />
                ) : null
              }
            />
          </ScrollWrapperWeb>
        </>
      )}
    </SafeAreaView>
  )
}
Certificate.propTypes = {
  navigation: PropTypes.object,
}

export default Certificate
