/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useContext, useEffect } from 'react'
import { Platform } from 'react-native'
import PropTypes from 'prop-types'
import { useTheme } from 'react-native-themed-styles'
import {
    Calendar as CalendarSelector,
} from 'react-native-calendars'

import Modal from '../Modal'
import ModalContent from '../ModalContent'
import Button from '../Button'
import Icon from '../Icon'

import Theme from '../../Themes/Theme'
import images from '../../Themes/Images'
import themedStyles from './DatePickerModal.Styles'

function DatePickerModal({
    onClose,
    isVisible,
    onDateChange,
    onButtonPress,
    placeholder,
    preselectedDate = {},
}) {
    const [styles] = useTheme(themedStyles)
    const { colors } = useContext(Theme)

    const calendarTheme = {
        selectedDayBackgroundColor: colors.calendarAccent,
        todayTextColor: colors.calendarAccent,
        textDayHeaderFontFamily: 'Vodafone Rg',
        textDayFontFamily: 'Vodafone Rg',
        textMonthFontFamily: 'Vodafone Rg',
        'stylesheet.calendar.main': {
            container: {
                paddingLeft: 0,
                paddingRight: 0,
                // backgroundColor: 'black',
                backgroundColor: colors.tertiaryBackground,
                borderBottomLeftRadius: 7,
                borderBottomRightRadius: 7,
                width: 320,

            },
            monthView: {
                paddingVertical: 3,
            },
            week: {
                marginTop: 0,
                marginBottom: 0,
                flexDirection: 'row',
                justifyContent: 'space-evenly',
            },
        },
        'stylesheet.calendar.header': {
            header: {
                flexDirection: 'row',
                height: 40,
                // width: 300,
                justifyContent: 'space-between',
                paddingLeft: 15,
                paddingRight: 15,
                marginTop: 0,
                alignItems: 'center',
                backgroundColor: colors.tertiaryBackground,
            },
            monthText: {
                fontSize: 18,
                ...Platform.select({
                    ios: {
                        fontFamily: 'Vodafone Rg',
                        fontWeight: 'bold',
                    },
                    android: {
                        fontFamily: 'Vodafone Rg Bold',
                    },
                }),
                color: colors.primaryText,
                margin: 0,
            },
            arrow: {
                padding: 0,
            },
            arrowImage: {
                tintColor: colors.primaryText,
            },
            disabledArrowImage: {
                tintColor: colors.placeholder,
            },
            week: {
                backgroundColor: colors.tertiaryBackground,
                height: 40,
                marginTop: 0,
                flexDirection: 'row',
                justifyContent: 'space-evenly',
                alignItems: 'center',
            },
            dayHeader: {
                marginTop: 0,
                marginBottom: 0,
                textAlign: 'center',
                fontSize: 12,
                fontFamily: 'Vodafone Rg',
                color: colors.primaryText,
                flex: 1,
            },
        },
        'stylesheet.day.basic': {
            base: {
                minWidth: 42,
                height: 42,
                borderRadius: 21,
                margin: 0,
                justifyContent: 'center',
                alignItems: 'center',
            },
            text: {
                marginTop: 4,
                fontSize: 16,
                lineHeight: 20,
                ...Platform.select({
                    ios: {
                        fontFamily: 'Vodafone Rg',
                        fontWeight: 'bold',
                    },
                    android: {
                        fontFamily: 'Vodafone Rg Bold',
                    },
                }),
                color: colors.primaryText,
            },
            selected: {
                backgroundColor: colors.calendarAccentRGBA(),
            },
            today: {
                borderWidth: 1,
                borderStyle: 'solid',
                borderColor: colors.calendarAccent,
            },
            todayText: {
                color: colors.calendarAccent,
            },
            selectedText: {
                color: colors.calendarText,
            },
            disabledText: {
                color: colors.disabled,
                fontFamily: 'Vodafone Rg',
            },
        },
    }

    const [markedDates, setMarkedDates] = useState({})
    useEffect(() => {
        if (isVisible) {
            handleDayPress(preselectedDate)
        }
    }, [preselectedDate, isVisible])

    const options = {
        selected: placeholder,
        arrows: {
            left: images.arrow_left,
            right: images.arrow_right,
        },
        scale: {
            true: 0,
            false: 1,
        },
        touchable: {
            true: 'auto',
            false: 'none',
        },
    }

    const handleDayPress = day => {
        onDateChange(day)
        setMarkedDates({ [day]: { selected: true } })
    }

    return (
        <Modal
            isVisible={isVisible}
            onBackButtonPress={onClose}
            onBackdropPress={onClose}
            animationIn="fadeIn"
            animationOut="fadeOut"
        >
            <ModalContent onClose={onClose}>
                {/* <DatePicker
                    date={date}
                    onDateChange={onDateChange}
                    mode="date"
                    locale={locale || LANGUAGES[lang]}
                    style={styles.topSpacing}
                    {...rest}
                />  */}
                <CalendarSelector
                    // displayLoadingIndicator={true}
                    onDayPress={day => handleDayPress(day.dateString)}
                    // Formatting values: http://arshaw.com/xdate/#Formatting
                    monthFormat={'MMM yyyy'}
                    markedDates={markedDates}
                    enableSwipeMonths={true}
                    // firstDay=1 week starts from Monday.
                    //Note that dayNames and dayNamesShort should still start from Sunday.
                    firstDay={1}
                    renderArrow={direction => (
                        <Icon
                            source={options.arrows[direction]}
                            size={12}
                            color={colors.primaryText}
                        />
                    )}
                    theme={calendarTheme}
                />
                <Button
                    title="receipt_button_confirm"
                    variant="active"
                    style={styles.dateButton}
                    onPress={onButtonPress}
                />
            </ModalContent>
        </Modal>
    )
}

DatePickerModal.propTypes = {
    onClose: PropTypes.func,
    isVisible: PropTypes.bool,
    onDateChange: PropTypes.func,
    date: PropTypes.string,
    onButtonPress: PropTypes.func,
    placeholder: PropTypes.string,
    preselectedDate: PropTypes.any,
}

export default DatePickerModal
