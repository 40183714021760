import React from 'react'
import { ScrollView } from 'react-native'
import { useTheme } from 'react-native-themed-styles'
import { useSelector } from 'react-redux'

import { getSupportCarrier, getSupportIssuer } from '../../../../Payment.Selectors'

import { Section } from '../../../../../../Components'

import themedStyles from './IssuerCarrier.Styles'

function IssuerCarrier() {
    const [styles] = useTheme(themedStyles)
    const issuerData = useSelector(getSupportIssuer)
    const carrierData = useSelector(getSupportCarrier)

    const issuerSection = [
        {
            title: 'certificate_issuer_label',
        },
        {
            label: 'einvoice_details_nipt_label',
            value: issuerData?.nuis,
        },
        {
            label: 'einvoice_details_name_label',
            value: issuerData?.name,
        },
        {
            label: 'einvoice_delivery_address_header',
            value: issuerData?.address,
        },
        {
            label: 'einvoice_delivery_city',
            value: issuerData?.town,
        },
    ]

    const carrierSection = [
        {
            title: 'supporting_invoice_issuerCarrier_header',
        },
        {
            label: 'einvoice_details_nipt_label',
            value: carrierData?.idNum,
        },
        {
            label: 'einvoice_details_name_label',
            value: carrierData?.name,
        },
        {
            label: 'einvoice_delivery_address_header',
            value: carrierData?.address,
        },
        {
            label: 'einvoice_delivery_city',
            value: carrierData?.town,
        },
    ]

    return (
        <ScrollView
            style={styles.container}
            contentContainerStyle={styles.content}
            showsVerticalScrollIndicator={false}
        >
            <Section data={issuerSection} />
            <Section data={carrierSection} />
        </ScrollView>
    )
}

export default IssuerCarrier
