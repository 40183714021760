import { styleSheetFactory } from '../../Themes/Colors'

const styles = styleSheetFactory(colors => ({
    container: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        height: 100,
        backgroundColor: colors.secondaryAccent,
        borderRadius: 6,
        paddingHorizontal: 10,
        marginBottom: 16,
        position: 'relative',
    },
    subContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        flex: 1,
    },
    descriptionContainer: {
        marginLeft: 10,
        flex: 1,
        paddingVertical: 15,
    },
    retryButton: {
        maxWidth: 100,
        height: 40,
        flex: 1,
    },
    syncIcon: {
        marginRight: 8,
    },
    closeIconTouch: {
        position: 'absolute',
        top: 4,
        right: 4,
    },

}))

export default styles
