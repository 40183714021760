import React from 'react'
import { SafeAreaView } from 'react-native'
import { useTheme } from 'react-native-themed-styles'

import {
  Text,
  Icon,
  ModalContent,
} from '../../Components'

import { images } from '../../Themes'
import themedStyles from './UnsafeDevice.Styles'

function UnsafeDevice() {
  const [styles] = useTheme(themedStyles)

  return (
    <SafeAreaView style={styles.container}>
      <ModalContent style={styles.contentContainer}>
        <Icon source={images.error} size={100} />
        <Text
          i18nKey="Oops!"
          align="center"
          size="h4"
          weight="bold"
          style={styles.title}
        />
        <Text i18nKey="Sorry, you cannot use eBiznes on rooted devices" align="center" />
      </ModalContent>
    </SafeAreaView>
  )
}

export default UnsafeDevice
