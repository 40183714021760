/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import {
  SafeAreaView,
  View,
  TouchableOpacity,
  Dimensions,
  LayoutAnimation,
  Text as RNText,
  Linking,
} from 'react-native'
import { useTheme } from 'react-native-themed-styles'
import PropTypes from 'prop-types'
import ContentLoader, { Rect } from 'react-content-loader/native'
import { SwipeRow } from 'react-native-swipe-list-view'
import Color from 'color'
import { useLazyQuery, useMutation } from '@apollo/client'
import moment from 'moment'

import { getErrorMessage, getErrorType } from '../../Utils'
import ContentManager from '../../Utils/ContentManager'
import {
  Header,
  InputWithTags,
  Text,
  Button,
  Icon,
  Loader,
  ErrorModal,
  Switch,
  TouchableInput,
} from '../../Components'

import SwipeList from '../Users/SwipeList'
import {
  GENERATE_TCR_CODE,
  GET_CASH_REGISTER,
  DELETE_CASH_REGISTER,
  UPDATE_CASH_REGISTER,
} from '../CashRegister/CashRegister.Schema'

import { GET_LOCATION, BUSIN_UNITS_LIST } from '../Location/Location.Schema'
import BottomSheetCodeInput from '../../Components/BottomSheetCodeInput/BottomSheetCodeInput'

import { layoutAnimConfig } from '../../Themes/Theme'
import themedStyles, {
  photoItemStyles,
  itemStyles,
  hiddenStyles,
} from './NewRegister.Styles'

import images from '../../Themes/Images'
import { colors, animations } from '../../Themes'

const employeeStatus = {
  Pending: 'gray',
  Active: 'green',
  Inactive: 'red',
}
const avatars = ['maleAvatar', 'femaleAvatar']
const { width } = Dimensions.get('screen')

function ItemPhoto(props) {
  const [styles] = useTheme(photoItemStyles)

  const { color, title, status } = props

  const pic = avatars.find((el) => el === color)

  return (
    <View>
      {pic ? (
        <View style={styles.avatarContainer}>
          <Icon source={images[pic]} size={70} resizeMode="cover" />
          <View
            style={[
              styles.status,
              {
                backgroundColor: colors[status],
              },
            ]}
          />
        </View>
      ) : (
        <View
          style={[
            {
              backgroundColor: Color(colors[color] || colors.transparent)
                .fade(0.7)
                .toString(),
            },
            styles.colorContainer,
          ]}>
          <Text
            size="h5"
            color={colors[color]}
            align="center"
            i18nKey={title
              ?.match(/\b(\w{1})/g)
              ?.slice(0, 2)
              ?.join('')
              ?.toUpperCase()}
          />
          <View
            style={[
              styles.status,
              {
                backgroundColor: colors[status],
              },
            ]}
          />
        </View>
      )}
    </View>
  )
}
ItemPhoto.propTypes = {
  color: PropTypes.string,
  role: PropTypes.string,
  title: PropTypes.string,
  status: PropTypes.string,
}

function Employee(props) {
  const [styles] = useTheme(itemStyles)
  const { item, isFirst, isLast, stylee } = props

  let propStyle = {},
    propStylee = {}
  if (isFirst) {
    propStyle = styles.listFirst
  }
  if (isLast) {
    propStylee = styles.listLast
  }
  const style = Object.assign(
    {},
    styles.default,
    styles.list,
    propStyle,
    propStylee,
  )

  return (
    <View style={[{ backgroundColor: colors.tertiaryBackground }, stylee, style]}>
      <View style={styles.subContainer}>
        <ItemPhoto
          color={item.color}
          role={item.role}
          title={`${item.firstName} ${item.lastName}`}
          status={employeeStatus[item.userStatus]}
        />
        <View style={styles.textContainer}>
          <View style={styles.firstRow}>
            <Text
              i18nKey={`${item.firstName} ${item.lastName}`}
              numberOfLines={1}
            />
            <Text i18nKey={` (${item.role})`} numberOfLines={1} />
          </View>
          <Text
            i18nKey={item.email}
            weight="light"
            color={colors.secondaryText}
            size="footnote"
            numberOfLines={1}
          />
        </View>
      </View>
    </View>
  )
}
Employee.propTypes = {
  item: PropTypes.object,
  isFirst: PropTypes.bool,
  isLast: PropTypes.bool,
  stylee: PropTypes.object,
}

function HiddenItem(props) {
  const [styles] = useTheme(hiddenStyles)
  const {
    needsConfirmation,
    isFirst,
    isLast,
    onPress,
    onConfirm,
    onCancel,
    style,
    loading,
  } = props

  let propStyle = {},
    propStylee = {}
  if (isFirst) {
    propStyle = styles.listFirst
  }
  if (isLast) {
    propStylee = styles.listLast
  }
  const stylee = Object.assign(
    {},
    styles.default,
    styles.list,
    propStyle,
    propStylee,
  )

  return (
    <View style={[styles.hiddenContainer, style, stylee]}>
      {loading
        ? <View style={styles.loader}>
          <Loader size={32} color={colors.white} source={animations.vfLoaderWhite} />
        </View>
        : needsConfirmation ? (
          <View style={styles.leftContainer}>
            <TouchableOpacity style={styles.hiddenItem} onPress={onPress}>
              <Text i18nKey="cash_register_remove_button" color="white" />
            </TouchableOpacity>
          </View>
        ) : (
          <View style={styles.confirmContainer}>
            <TouchableOpacity style={styles.confirmStyles} onPress={onConfirm}>
              <Icon source={images.bin_light} color={colors.white} size={24} />
              <Text
                i18nKey="receipt_button_confirm"
                color={colors.white}
                style={styles.confirmText}
              />
            </TouchableOpacity>
            <TouchableOpacity style={styles.cancelContainer} onPress={onCancel}>
              <Icon source={images.closeIcon} color={colors.white} size={20} />
            </TouchableOpacity>
          </View>
        )}
    </View>
  )
}
HiddenItem.propTypes = {
  needsConfirmation: PropTypes.bool,
  isFirst: PropTypes.bool,
  isLast: PropTypes.bool,
  onPress: PropTypes.func,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  style: PropTypes.object,
  loading: PropTypes.bool,
}

function Item(props) {
  const [styles] = useTheme(themedStyles)
  const {
    title,
    value,
    isFirst,
    isLast,
    itemStyle,
    containerStyle,
    loading,
  } = props

  const style = {}
  if (isFirst) {
    style.borderTopLeftRadius = 7
    style.borderTopRightRadius = 7
  }
  if (isLast) {
    style.borderBottomLeftRadius = 7
    style.borderBottomRightRadius = 7
  }

  return (
    <View
      style={[
        {
          backgroundColor: colors.tertiaryBackground,
        },
        style,
        containerStyle,
      ]}>
      <View style={[styles.itemStyle, itemStyle]}>
        <View style={styles.listDescContainer}>
          <Text i18nKey={title} weight="light" style={styles.rightSpacing} />
          {loading ? (
            <ContentLoader
              speed={0.7}
              height={25}
              width={100}
              style={Object.assign({ borderRadius: 7 })}
              backgroundColor="#e2e2e2"
              foregroundColor="#ecebeb">
              <Rect x="0" y="5" rx="3" ry="3" width={100} height="15" />
            </ContentLoader>
          ) : (
            <Text i18nKey={value} numberOfLines={1} style={styles.itemText} />
          )}
        </View>
      </View>
    </View>
  )
}
Item.propTypes = {
  title: PropTypes.string,
  value: PropTypes.string,
  isFirst: PropTypes.bool,
  isLast: PropTypes.bool,
  itemStyle: PropTypes.object,
  containerStyle: PropTypes.object,
  loading: PropTypes.bool,
}

function NewRegister(props) {
  const [styles] = useTheme(themedStyles)
  const {
    navigation: { goBack, navigate },
    route: {
      params: { cashId },
    },
  } = props

  const [value, setValue] = useState('')
  const [disabled, setDisabled] = useState(true)
  const [location, setLocation] = useState('')
  const [tcrCode, setTcrCode] = useState('')
  const [businessCode, setBusinessCode] = useState('')
  const [needsConfirmation, setNeedForConfirmation] = useState(true)
  const [rightOpenValueFull, setRightOpenValueFull] = useState(-width + 30)
  const [webAccess, setWebAccess] = useState(false)
  const [apiAccess, setApiAccess] = useState(false)
  const [expDate] = useState('')

  const [businessCodeModal, setBusinessCodeModal] = useState(false)
  const [businessInputValue, setBusinessInputValue] = useState(businessCode)
  const [businCodesData, setBusinCodesData] = useState([])
  const [selectedBusinCode, setselectedBusinCode] = useState({ code: businessCode })
  const isAnItemSelected = businCodesData.filter((item) => item.code === businessCode).length > 0


  useEffect(() => {
    if (isAnItemSelected) {
      setselectedBusinCode({ code: businessCode })
    }
  }, [businessCodeModal])

  useEffect(() => {
    if (!isAnItemSelected) {
      setBusinessInputValue(businessCode)
    }
  }, [businessCodeModal])



  const handleTextChange = (text) => {
    setBusinessInputValue(text)
    setselectedBusinCode('')
  }


  const handleCodeInputModalClose = () => {
    setBusinessCodeModal(false)
  }
  const mapBusinCodes = (businCodesList) => {
    const unmapedBusinCodes = businCodesList?.getBusinessUnits?.businessUnits
    const mappedBusinCodes = []

    if (unmapedBusinCodes) {
      unmapedBusinCodes.forEach((item) => {
        mappedBusinCodes.push({
          id: item?.attributes?.BUName,
          code: item?.attributes?.BUCode,
          description: `${item?.attributes?.BUStreetName}`,
        })
      })
    }
    return mappedBusinCodes
  }

  const [modal, setModal] = useState({
    isVisible: false,
    icon: images.warningIcon,
    title: '',
    desc: '',
    descPlaceholders: [],
    primaryText: '',
    primaryAction: null,
    secondaryText: '',
    secondaryAction: null,
  })
  const openModal = (
    title = 'opened_orders_error',
    desc = '',
    descPlaceholders = [],
    primaryText = '',
    primaryAction = null,
    secondaryText = '',
    secondaryAction = null,
    icon = images.warningIcon,
  ) => {
    setModal({
      isVisible: true,
      icon,
      title,
      desc,
      descPlaceholders,
      primaryText,
      primaryAction,
      secondaryText,
      secondaryAction,
    })
  }
  const closeModal = () => {
    setModal({
      isVisible: false,
      icon: images.delete,
      title: '',
      desc: '',
      primaryText: '',
      primaryAction: null,
      secondaryText: '',
      secondaryAction: null,
    })
  }

  const [deviceData, setDeviceData] = useState({
    TCRCode: '',
    deviceId: '',
    deviceName: '',
    id: '',
    locationId: '',
    model: '',
    name: '',
    os: '',
    requestUserEmail: '',
    isTablet: false,
  })

  const [employees, setEmployees] = useState([])

  const measureView = (event) => {
    setRightOpenValueFull(-event.nativeEvent.layout.width)
  }

  const openRow = (rowMap, id) => {
    setNeedForConfirmation(false)
    rowMap[id].manuallySwipeRow(rightOpenValueFull)
  }

  const closeRow = (rowMap, id) => {
    rowMap[id].closeRow()
    setNeedForConfirmation(true)
  }

  const resetNeedForConfirmation = () => {
    setNeedForConfirmation(true)
  }

  const expRegex = /^([0-2][0-9]|(3)[0-1])(\/)(((0)[0-9])|((1)[0-2]))(\/)\d{4}$/i

  const [
    deleteDevice,
    { loading: deleting },
  ] = useMutation(DELETE_CASH_REGISTER)

  const [
    updateDevice,
    { loading: updating },
  ] = useMutation(UPDATE_CASH_REGISTER)

  const [getLocation, {
    data: details,
    loading: locationLoading,
  }] = useLazyQuery(GET_LOCATION)

  const [generateCode, { loading, data }] = useMutation(GENERATE_TCR_CODE)

  const [
    getDevice,
    { loading: devLoading, data: devData, refetch },
  ] = useLazyQuery(GET_CASH_REGISTER, {
    fetchPolicy: 'network-only',
    variables: {
      searchBy: {
        name: 'id',
        value: cashId,
      },
    },
  })

  useEffect(() => {
    getDevice()
  }, [])

  useEffect(() => {
    const deviceInfo = devData?.getCashRegister
    if (deviceInfo) {
      LayoutAnimation.configureNext(layoutAnimConfig)
      setValue(deviceInfo?.name)
      getLocation({ variables: { id: deviceInfo?.locationId } })
      setDeviceData(deviceInfo)
      setBusinessCode(deviceInfo?.businUnitCode || '')
      setEmployees(deviceInfo?.employees)
      setWebAccess(devData?.enableWebAccess)
      setApiAccess(devData?.enableApiAccess)
    }
  }, [devData])

  const generateTcr = () => {
    generateCode({
      variables: {
        cashRegisterId: cashId,
        businUnitCode: businessCode,
      },
    })
      .then((response) => {
        const tcr = response?.data?.registerTCR?.TCRCode
        LayoutAnimation.configureNext(layoutAnimConfig)
        setTcrCode(tcr)
        setDeviceData((previousState) => {
          return { ...previousState, TCRCode: tcr }
        })
        updateDevice({
          variables: {
            id: cashId,
            name: value,
            status: 'ACTIVE',
          },
        })
          .then(() => refetch())
        // .catch(() => {
        //   openModal(
        //     'certificate_error_label',
        //     'CashRegisterGeneralError',
        //     [],
        //     '',
        //     undefined,
        //     '',
        //     undefined,
        //   )
        // })
      })
      .catch((er) => {
        const errorMessage = getErrorType(er)
        openModal(
          'certificate_error_label',
          `${errorMessage}`,
          [],
          'orders_try_again',
          () => {
            closeModal()
            generateTcr()
          },
          '',
          undefined,
        )
      })
  }

  const onSave = () => {
    updateDevice({
      variables: {
        id: cashId,
        name: value,
        status: 'ACTIVE',
        enableWebAccess: webAccess,
        enableApiAccess: apiAccess,
        validTo: expDate ? moment(expDate, 'DD/MM/YYYY').format('YYYY-MM-DD') : null,

      },
    })
      .then(() => goBack())
      .catch((err) => {
        openModal(
          'certificate_error_label',
          getErrorMessage(err),
          [],
          '',
          undefined,
          '',
          undefined,
        )
      })
  }

  const onDeny = () => {
    deleteDevice({
      variables: {
        cashRegisterId: cashId,
      },
    })
      .then(() => {
        // displayToast('cash_register_delete_success')
        goBack()
      })
      .catch(() => {
        goBack()
        // displayToast('cash_register_delete_error', images.warning)
      })
  }

  useEffect(() => {
    if (tcrCode && value && (expRegex.test(expDate) || !expDate)) {
      setDisabled(false)
    } else {
      setDisabled(true)
    }
  }, [tcrCode, value, expDate])

  useEffect(() => {
    const locationData = details?.getLocation
    if (locationData) {
      setLocation(locationData?.businessName)
    }
  }, [details])

  const addEmployees = (emp) => {
    const preEmployees = {}
    employees.map((item) => {
      preEmployees[item.id] = true
    })
    const employeesToAdd = emp
      ?.filter((employee) => !preEmployees[employee.id])
      .map((em) => em.id)
    updateDevice({
      variables: {
        id: cashId,
        employeeToEnable: employeesToAdd,
      },
    })
      .then(() => {
        setEmployees(emp)
      })
      .catch(() => {
        openModal(
          'certificate_error_label',
          employeesToAdd?.length === 1
            ? 'cash_register_add_error_1'
            : 'cash_register_add_error_0',
          [`${employeesToAdd?.length}`],
          '',
          undefined,
          'cash_register_okay_button',
          () => closeModal(),
        )
      })
  }

  const regex = /^[a-z]{2}\d{3}[a-z]{2}\d{3}$/

  const translate = (key) => {
    return ContentManager.translate(key)
  }

  return (
    <SafeAreaView style={styles.container}>
      <Header
        title="header_new_register"
        image={images.back}
        onPress={() => goBack()}
      />
      <SwipeList
        onLayout={(event) => measureView(event)}
        ItemSeparatorComponent={() => <View style={styles.separator} />}
        style={styles.topSpacing}
        contentContainerStyle={styles.bottomSelfSpacing}
        data={employees}
        onRowClose={resetNeedForConfirmation}
        renderItem={(rowData, rowMap) => {
          const { item, index } = rowData
          let isFirst = false,
            isLast = false
          if (index === 0) {
            isFirst = true
          }
          if (index === employees?.length - 1) {
            isLast = true
          }
          return (
            <SwipeRow
              key={`item-${index}`}
              disableRightSwipe
              rightOpenValue={-100}
              closeOnRowPress
              restSpeedThreshold={0.004}
              useNativeDriver={true}>
              <HiddenItem
                key={item?.id}
                needsConfirmation={needsConfirmation}
                isFirst={isFirst}
                isLast={isLast}
                onPress={() => openRow(rowMap, 'row-' + index)}
                loading={updating}
                onConfirm={() => {
                  const newEmployees = employees?.filter(
                    (employee) => employee.id !== item?.id,
                  )
                  updateDevice({
                    variables: {
                      id: cashId,
                      employeeToDelete: [item?.id],
                    },
                  })
                    .then(() => {
                      closeRow(rowMap, 'row-' + index)
                      setEmployees(newEmployees)
                    })
                    .catch(() => {
                      closeRow(rowMap, 'row-' + index)
                      openModal(
                        'certificate_error_label',
                        'cash_register_delete_error',
                        [],
                        '',
                        undefined,
                        'cash_register_okay_button',
                        () => closeModal(),
                      )
                    })
                }}
                onCancel={() => closeRow(rowMap, 'row-' + index)}
              />
              <Employee
                key={item?.id}
                item={item}
                isFirst={isFirst}
                isLast={isLast}
              />
            </SwipeRow>
          )
        }}
        ListHeaderComponent={
          <>
            <InputWithTags
              label="cash_register_name_label"
              value={value}
              onChangeText={setValue}
              autoCorrect={false}
              inputContainerStyle={styles.inputContainer}
              labelOffset={{
                x0: 0,
                y0: -7,
                x1: 0,
                y1: 2,
              }}
              maxLength={20}
            />
            <Item
              title="users_location_label"
              value={location}
              loading={locationLoading || devLoading}
              isFirst
              isLast
              containerStyle={styles.itemSpacing}
            />
            {/* <InputWithTags
              label="cash_register_business_code"
              value={businessCode}
              onChangeText={setBusinessCode}
              autoCorrect={false}
              autoCapitalize="none"
              inputContainerStyle={styles.inputContainer}
              labelOffset={{
                x0: 0,
                y0: -7,
                x1: 0,
                y1: 2,
              }}
              maxLength={20}
            /> */}
            <TouchableInput
              onPress={() => setBusinessCodeModal(true)}
              label="edit_business_code_new_input"
              value={businessCode}
              icon={images.filledDownArrow}
              iconSize={16}
              autoCorrect={false}
              autoCapitalize="none"
              editable={false}
            />
            <TouchableOpacity onPress={() => Linking.openURL('https://efiskalizimi-app.tatime.gov.al/self-care')}>
              <RNText
                style={styles.textsWrapper}
                allowFontScaling={false}
              >
                <RNText>{translate('cash_register_business_code1')}</RNText>
                <RNText style={{ color: colors.secondaryAccent }}>{translate('cash_register_business_code2')}</RNText>
                <RNText>{translate('cash_register_business_code3')}</RNText>
                <RNText style={styles.italicText}>{translate('cash_register_business_code4_1')}</RNText>
                <RNText>{translate('cash_register_business_code5')}</RNText>
              </RNText>
            </TouchableOpacity>
            <Button
                  title="cash_register_manage_busin_code"
                  variant="outline"
                  onPress={() => navigate('Locations', { isVirtual: false, isBusinessUnitList: true })}
                  style={styles.bottomSpacing}
                />

            <Text i18nKey="cash_register_about_label" align="left" />
            <View style={styles.verticalSpacing}>
              <Item
                title="cash_register_device_name"
                value={deviceData?.deviceName}
                loading={devLoading}
                isFirst={true}
                itemStyle={styles.aboutItem}
              />
              <Item
                title="cash_register_device_type"
                value={
                  deviceData?.isTablet
                    ? 'cash_register_tablet_label'
                    : 'cash_register_mobile_label'
                }
                loading={devLoading}
                itemStyle={styles.aboutItem}
              />
              <Item
                title="cash_register_device_model"
                value={deviceData?.model}
                loading={devLoading}
                itemStyle={styles.aboutItem}
              />
              <Item
                title="cash_register_operating_system"
                value={deviceData?.os}
                loading={devLoading}
                itemStyle={styles.aboutItem}
              />
              <Item
                title="cash_register_device_id"
                value={deviceData?.deviceId}
                loading={devLoading}
                isLast={true}
                itemStyle={styles.aboutItem}
              />
            </View>

            <Item
              title="receipt_user"
              value={deviceData?.requestUserEmail}
              loading={devLoading}
              isFirst
              isLast
            />

            <>
              {!tcrCode ? (
                <Button
                  title="cash_register_tcr_button"
                  backgroundColor={
                    (loading || !value || !regex.test(businessCode)) ? colors.disabled : colors.secondaryAccent
                  }
                  disabled={loading || !value || !regex.test(businessCode)}
                  onPress={generateTcr}
                  style={styles.topSpacing}
                />
              ) :
                <Item
                  title="cash_register_tcr_label"
                  value={deviceData?.TCRCode}
                  loading={loading}
                  isFirst
                  isLast
                  containerStyle={styles.itemSpacing}
                />}
              {/* <Text i18nKey="cash_register_end_date" />
              <InputWithTags
                label="DD/MM/YYYY"
                error={!expRegex.test(expDate) && Boolean(expDate)}
                value={expDate}
                onChangeText={setExpDate}
                autoCorrect={false}
                autoCapitalize="none"
                inputContainerStyle={styles.inputContainer}
                inputWrapperStyle={styles.itemSpacing}
                labelOffset={{
                  x0: 0,
                  y0: -7,
                  x1: 0,
                  y1: 2,
                }}
              /> */}
              <View style={styles.noteView}>
                <View style={styles.noteHeader}>
                  <Icon source={images.info} size={20} />
                  <Text
                    i18nKey="cash_register_note"
                    weight="bold"
                    style={styles.noteTitle}
                  />
                </View>
                <Text i18nKey="cash_register_new_note" />
              </View>

              {Boolean(tcrCode) && (
                <>
                  <View style={styles.accessWrapper}>
                    <Text i18nKey="cash_register_access_label" style={styles.accessLabel} />
                    <View style={styles.firstAccessItem}>
                      <TouchableOpacity onPress={() => setWebAccess(!webAccess)} style={styles.touchable} activeOpacity={0.5}>
                        <View style={styles.textContainer}>
                          <Text i18nKey="cash_register_web_access_label" numberOfLines={2} style={styles.name} />
                        </View>
                        <View pointerEvents="none" style={styles.radioInput}>
                          {devLoading ? (
                            <ContentLoader
                              speed={0.7}
                              height={25}
                              width={100}
                              style={Object.assign({ borderRadius: 15 })}
                              backgroundColor="#e2e2e2"
                              foregroundColor="#ecebeb">
                              <Rect x="65" y="1" rx="10" ry="10" width={35} height="20" />
                            </ContentLoader>
                          ) : (
                            <Switch
                              value={webAccess}
                              onPress={() => setWebAccess(!webAccess)}
                              color={colors.secondaryAccent}
                            />
                          )}

                        </View>
                      </TouchableOpacity>
                    </View>
                    <View style={styles.secondAccessItem}>
                      <TouchableOpacity onPress={() => setApiAccess(!apiAccess)} style={styles.touchable} activeOpacity={0.5}>
                        <View style={styles.textContainer}>
                          <Text i18nKey="cash_register_api_access_label" numberOfLines={2} style={styles.name} />
                        </View>
                        <View pointerEvents="none" style={styles.radioInput}>
                          {devLoading ? (
                            <ContentLoader
                              speed={0.7}
                              height={25}
                              width={100}
                              style={Object.assign({ borderRadius: 15 })}
                              backgroundColor="#e2e2e2"
                              foregroundColor="#ecebeb">
                              <Rect x="65" y="1" rx="10" ry="10" width={35} height="20" />
                            </ContentLoader>
                          ) : (
                            <Switch
                              value={apiAccess}
                              onPress={() => setApiAccess(!apiAccess)}
                              color={colors.secondaryAccent}
                            />
                          )}
                        </View>
                      </TouchableOpacity>
                    </View>
                  </View>
                  <Button
                    title="cash_register_add_employee"
                    icon={images.plus}
                    backgroundColor={colors.secondaryAccent}
                    onPress={() =>
                      navigate('SelectEmployee', {
                        onSave: addEmployees,
                        preSelectedEmployees: employees,
                      })
                    }
                  />
                  <Text
                    weight="light"
                    size="footnote"
                    align="center"
                    i18nKey="cash_register_add_employee_message"
                    style={styles.spacing}
                  />
                </>
              )}
            </>
          </>
        }
        ListFooterComponent={
          <>
            <Button
              title="taxes_save_button"
              disabled={disabled}
              variant={disabled ? 'disabled' : 'active'}
              onPress={() => onSave()}
              loader={updating}
              loaderComponent={
                <Loader size={32} source={animations.vfLoaderWhite} />
              }
              style={styles.verticalSpacing}
            />
            {data ? null : (
              <Button
                title="cash_register_deny_button"
                backgroundColor={colors.white}
                titleStyle={{ color: colors.accent }}
                onPress={() => onDeny()}
                loader={deleting}
                loaderComponent={
                  <Loader size={32} source={animations.vfLoaderRed} />
                }
              />
            )}
          </>
        }
        refreshing={false}
      />
      <ErrorModal
        isVisible={modal?.isVisible}
        title={modal?.title}
        description={modal?.desc}
        placeHolders={modal.descPlaceholders}
        primaryText={modal?.primaryText}
        secondaryText={modal?.secondaryText}
        primaryAction={modal?.primaryAction}
        secondaryAction={modal?.secondaryAction}
        onBackdropPress={() => closeModal()}
        onBackButtonPress={() => closeModal()}
        onClosePress={() => closeModal()}
      />
      <BottomSheetCodeInput
        inputValue={businessInputValue}
        isVisible={businessCodeModal}
        data={businCodesData}
        title={'edit_business_code_label'}
        inputPrompt={'edit_business_code_enter_prompt'}
        inputLabel={'edit_business_code_label'}
        buttonLabel={'edit_business_code_set'}
        onSave={setBusinessCode}
        setData={setBusinCodesData}
        query={BUSIN_UNITS_LIST}
        note
        mapItems={mapBusinCodes}
        onClose={handleCodeInputModalClose}
        onChangeText={handleTextChange}
        selected={selectedBusinCode}
        select={(obj) => {
          setselectedBusinCode(obj)
        }}
        editable={true}
      />
    </SafeAreaView>
  )
}

NewRegister.propTypes = {
  navigation: PropTypes.object,
  route: PropTypes.object,
}

export default NewRegister
