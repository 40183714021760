import React from 'react'
import { Platform } from 'react-native'
import { selectContactPhone } from 'react-native-select-contact'
import {
  check,
  request,
  openSettings,
  PERMISSIONS,
  RESULTS,
} from 'react-native-permissions'
import PropTypes from 'prop-types'

import InputWithIcon from '../InputWithIcon'

import { images } from '../../Themes'

const NAD = /[^\d]/gi

function InputPhone({
  onChangeText,
  error,
  ...props
}) {

  const selectContact = () => {
    selectContactPhone()
      .then((selection) => {
        if (selection?.selectedPhone?.number) {
          const number = selection.selectedPhone.number
          formatPhoneNumber(number)
        }
      })
      .catch(() => { })
  }

  const formatPhoneNumber = (value) => {
    value = value.replace(NAD, '')
    if (value.startsWith('06')) {
      value = value.slice(1)
    } else if (value.startsWith('3556')) {
      value = value.slice(3)
    } else if (value.startsWith('003556')) {
      value = value.slice(5)
    } else if (value.startsWith('35506')) {
      value = value.slice(4)
    } else if (value.startsWith('0035506')) {
      value = value.slice(6)
    }
    onChangeText(value)
  }

  const listContacts = () => {
    const permission = Platform.select({
      ios: PERMISSIONS.IOS.CONTACTS,
      android: PERMISSIONS.ANDROID.READ_CONTACTS,
    })
    check(permission)
      .then((result) => {
        switch (result) {
          case RESULTS.UNAVAILABLE:
            break
          case RESULTS.DENIED:
            request(permission)
              .then((res) => {
                if (res === RESULTS.GRANTED) {
                  selectContact()
                }
              })
              .catch(() => { })
            break
          case RESULTS.GRANTED:
            selectContact()
            break
          case RESULTS.BLOCKED:
            openSettings().catch(() => null)
            break
        }
      })
      .catch(() => null)
  }

  return (
    <InputWithIcon
      {...props}
      error={error}
      onChangeText={onChangeText}
      icon={Platform.OS === 'web' ? null : images.contacts}
      onEndEditing={({ nativeEvent: { text } }) => { formatPhoneNumber(text) }}
      onIconPress={listContacts}
    />
  )
}

InputPhone.propTypes = {
  onChangeText: PropTypes.func,
  error: PropTypes.bool,
}

export default InputPhone
