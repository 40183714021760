/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useRef, useState, useEffect } from 'react'
import { View, Animated, Easing, TouchableOpacity } from 'react-native'
import PropTypes from 'prop-types'

import { useTheme } from 'react-native-themed-styles'

import { useLazyQuery } from '@apollo/client'

import moment from 'moment'

import {
  Text,
  Icon,
  Loader,
} from '../../../../Components'


import images from '../../../../Themes/Images'
import Theme from '../../../../Themes/Theme'

import ContentManager from '../../../../Utils/ContentManager'

import { LIST_CASH_DRAWER_SHIFTS } from '../../UserDetails.Schema'

import { useLanguage } from '../../../../Utils/Language'

import { useConfig } from '../../../../Utils'

import themedStyles from './DrawerList.Styles'


const AnimatedTouchable = Animated.createAnimatedComponent(TouchableOpacity)

const eventTypeDesc = {
  title: {
    END: 'drawer_end_drawer_label',
    OPEN: 'drawer_open_drawer_label',
  },
  time: {
    OPEN: 'openedAt',
    END: 'endedAt',
  },
  noItems: {
    END: 'drawer_no_end_drawer',
    OPEN: 'drawer_no_open_drawer',
  },
}

const LANGUAGES = {
  al: 'sq',
  en: 'en-gb',
}

function SectionItem({ sectionTitle }) {
  const [styles] = useTheme(themedStyles)
  const { colors } = useContext(Theme)

  return (
    <Text
      i18nKey={sectionTitle}
      size="footnote"
      color={colors.secondaryText}
      style={styles.sectionTitle}
    />
  )
}
SectionItem.propTypes = {
  sectionTitle: PropTypes.string,
}

function Item({
  item,
  state,
  style,
  onPress,
}) {
  const [styles] = useTheme(themedStyles)
  const { colors } = useContext(Theme)
  const lang = useLanguage()

  const translate = (key) => {
    return ContentManager.translate(key)
  }

  return (
    <View style={[styles.itemStyle, style, styles[`firstItem_${item.isFirst}`], styles[`lastItem_${item.isLast}`]]}>
      <TouchableOpacity onPress={onPress} disabled={!onPress}>
        <View style={styles.itemContainer}>
          <View style={styles.listDescContainer}>
            <Text
              i18nKey={`${translate(eventTypeDesc.title[state])}`}
              numberOfLines={1} size="footnote"
            />
            <Text
              i18nKey={item?.FCDC || '...'}
              numberOfLines={1}
              weight="light"
              color={colors.secondaryText}
              size="footnote"
            />
          </View>
          <Text i18nKey={moment(item[eventTypeDesc.time[state]]).locale(LANGUAGES[lang]).format('hh:mm A')} weight="light" size="footnote" />
          <Icon source={images.chevronRight} size={24} color={colors.accent} />
        </View>
      </TouchableOpacity>
    </View >
  )
}
Item.propTypes = {
  item: PropTypes.object,
  onPress: PropTypes.func,
  style: PropTypes.object,
  state: PropTypes.string,
}

const listItems = {
  section: SectionItem,
  element: Item,
}

function DrawerList({
  id,
  state,
  navigation: {
    navigate,
  },
  ...rest
}) {
  const { switchLocation: { deviceId, locationId } } = useConfig()

  const [styles] = useTheme(themedStyles)
  const { colors } = useContext(Theme)
  const lang = useLanguage()

  const [items, setItems] = useState([])
  const [endLoading, setEndLoading] = useState(false)
  const [loading1, setLoading] = useState(true)
  const listRef = useRef(null)
  const sectionDate = useRef('-')

  const fabSize = useRef(new Animated.Value(0)).current
  const animate = val => {
    Animated.timing(fabSize, {
      toValue: val,
      duration: 370,
      useNativeDriver: true,
      easing: Easing.inOut(Easing.cubic),
    }).start()
  }

  const scrollToTop = () => {
    animate(0)
    listRef.current.scrollToOffset({
      offset: 0,
    })
  }

  const [list, { loading: listLoading, data: listData, refetch, fetchMore }] = useLazyQuery(
    LIST_CASH_DRAWER_SHIFTS,
    {
      fetchPolicy: 'network-only',
    },
  )

  const listShifts = () => {
    list({
      variables: {
        locationId: '123123',
        locId: locationId,
        cashRegisterId: deviceId,
        limit: 30,
        state: state,
        employeeId: id,
      },
    })
  }

  useEffect(() => {
    if (state) {
      sectionDate.current = null
      setLoading(true)
      setItems([])
      listShifts()
    }
  }, [state])

  const mapData = (dataa) => {
    const response = []
    let isFirst = false, isLast = false
    dataa?.map((item, index) => {
      isFirst = false
      isLast = false
      const date = moment(item.createdAt).locale(LANGUAGES[lang]).format('MMM DD, YYYY')
      if (date !== sectionDate.current) {
        if (response?.[response.length - 1]) {
          response[response.length - 1].isLast = true
        }
        sectionDate.current = date
        response.push({
          id: date,
          sectionTitle: date,
          type: 'section',
        })
      }

      if (response?.[response.length - 1]?.type === 'section') {
        isFirst = true
        isLast = false
      }

      response.push({
        id: item.id,
        cashRegisterId: item?.cashRegisterId,
        type: 'element',
        FCDC: item.FCDC,
        endedAt: item.endedAt,
        openedAt: item.openedAt,
        description: item.description,
        isFirst: isFirst,
        isLast: isLast,
      })
    })
    if (response?.[response.length - 1]) {
      response[response.length - 1].isLast = true
    }
    return response
  }

  useEffect(() => {
    if (listData) {
      const response = mapData(listData?.listCashDrawerShifts?.items)
      setItems(response)
      setLoading(false)
      setEndLoading(false)
    }
  }, [listData])

  const onEndReached = () => {
    if (listData?.listCashDrawerShifts?.cursor === null) {
      return
    }
    setEndLoading(true)
    fetchMore({
      variables: {
        locationId: '123123',
        locId: locationId,
        cashRegisterId: deviceId,
        limit: 30,
        state: state,
        employeeId: id,
        cursor: listData?.listCashDrawerShifts?.cursor,
      },
    })
  }

  const onItemPress = item => {

    if (state === 'END') {
      navigate('CashDrawerReport', {
        id: item?.id,
        cashRegisterId: item?.cashRegisterId,
      })
    } else {
      navigate('CurrentDrawer', {
        id: item?.id,
        cashRegisterId: item?.cashRegisterId,
      })
    }
  }

  return (
    <>
      <Animated.FlatList
        showsVerticalScrollIndicator={false}
        keyExtractor={(item, index) => 'row-' + index}
        ref={(ref) => (listRef.current = ref)}
        onMomentumScrollEnd={(event) => {
          if (event.nativeEvent.contentOffset.y > 800) {
            animate(1)
          } else {
            animate(0)
          }
        }}
        data={items}
        style={styles.listStyle}
        contentContainerStyle={styles.listContainerStyle}
        renderItem={({ item, index }) => {
          const ListItem = listItems[item.type]
          return <ListItem
            sectionTitle={item.sectionTitle}
            key={'row-' + index}
            item={item}
            state={state}
            onPress={() => onItemPress(item)}
          />
        }}
        ListFooterComponent={listLoading || endLoading ? <Loader size={32} /> : null}
        ListEmptyComponent={listLoading || loading1 ? null : <Text i18nKey={eventTypeDesc.noItems[state]} style={styles.emptyComponent} />}
        refreshing={false}
        onRefresh={() => refetch()}
        onEndReached={onEndReached}
        {...rest}
      />
      <AnimatedTouchable
        onPress={scrollToTop}
        activeOpacity={0.5}
        style={[
          styles.fab,
          {
            opacity: fabSize,
            transform: [{ scale: fabSize }],
          },
        ]}>
        <Icon
          source={images.arrow_right}
          size={20}
          color={colors.tertiaryIcon}
          style={styles.fabIcon}
        />
      </AnimatedTouchable>
    </>
  )
}
DrawerList.propTypes = {
  id: PropTypes.string,
  state: PropTypes.string,
  navigation: PropTypes.objectOf({
    navigate: PropTypes.func,
  }),
}

export default DrawerList
