const getInitialData = state => (state.eInvoiceData.init || {})
const getProcessData = state => (state.eInvoiceData.process || {})
const getBuyerData = state => (state.eInvoiceData.buyer || {})
const getSellerData = state => (state.eInvoiceData.seller || {})
const getReferences = state => (state.eInvoiceData.references || {})
const getDelivery = state => (state.eInvoiceData.delivery || {})
const getLineItems = state => (state.eInvoiceLineItems || {})
const getEinvoice = state => (state.eInvoiceData || {})
const getEinvoiceCalculated = state => (state.eInvoiceCalculated || {})
const getInvoiceId = state => (state.eInvoiceData.invoiceId)
const getNotes = state => (state.eInvoiceData.note)

const getSupportInitData = state => (state.supportInvoiceData.initial || {})
const getSupportTransport = state => (state.supportInvoiceData.transportInfo || {})
const getSupportIssuer = state => (state.supportInvoiceData.issuer || {})
const getSupportCarrier = state => (state.supportInvoiceData.carrier || {})
const getSupportTotal = state => (state.supportInvoiceData.total || {})
const getSupportId = state => (state.supportInvoiceData.invoiceId)
const getSupportRegisteredId = state => (state.supportInvoiceData.registerId)
const getSupportInvoice = state => (state.supportInvoiceData || {})
const getSupportItems = state => (state.supportInvoiceData.items || {})

const getPaymentEmpotencyKey = state => (state.paymentData.idempotencyKey || '')
const getPaymentStatus = state => (state.paymentData.paymentStatus || '')
const getPaymentCashMoney = state => (state.paymentData.cash || '')
const getPaymentChangeMoney = state => (state.paymentData.change || '')
const getPaymentCustomer = state => (state.paymentData.customer || {})
const getPaymentBankAccount = state => (state.paymentData.bankAccount || {})
const getPaymentDelivery = state => (state.paymentData.delivery || {})
const getPaymentDates = state => (state.paymentData.dates || {})
const getPaymentOrderType = state => (state.paymentData.orderType || {})
const getPaymentOrderSubtype = state => (state.paymentData.orderSubType || {})
const getPaymentId = state => (state.paymentData.paymentId || '')
const getPaymentMethod = state => (state.paymentData.paymentMethod || {})
const getLastTransaction = state => (state.paymentData.lastTransaction || {})
const getPaymentError = state => (state.paymentData.error || {})
const getShouldHaveDelivery = state => (state.paymentData.shouldHaveDelivery || false)
const getShouldHaveBillingPeriod = state => (state.paymentData.shouldHaveBillingPeriod || false)
const getIsReverseCharge = state => (state.paymentData.isReverseCharge || {})

export {
  getInitialData,
  getProcessData,
  getBuyerData,
  getSellerData,
  getReferences,
  getDelivery,
  getLineItems,
  getEinvoice,
  getEinvoiceCalculated,
  getInvoiceId,
  getNotes,

  getSupportInitData,
  getSupportTransport,
  getSupportIssuer,
  getSupportCarrier,
  getSupportId,
  getSupportTotal,
  getSupportInvoice,
  getSupportItems,
  getSupportRegisteredId,

  getPaymentEmpotencyKey,
  getPaymentStatus,
  getPaymentCashMoney,
  getPaymentChangeMoney,
  getPaymentCustomer,
  getPaymentBankAccount,
  getPaymentDelivery,
  getPaymentDates,
  getPaymentOrderType,
  getPaymentOrderSubtype,
  getPaymentId,
  getPaymentMethod,
  getLastTransaction,
  getPaymentError,
  getShouldHaveDelivery,
  getShouldHaveBillingPeriod,
  getIsReverseCharge,
}
