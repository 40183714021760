import { styleSheetFactory } from '../../Themes/Colors'

const styles = styleSheetFactory(colors => ({
  container: {
    flex: 1,
    backgroundColor: colors.background,
  },
  sectionText: {
    marginTop: 15,
  },
  inputRow: {
    marginBottom: 15,
  },
  description: {
    borderRadius: 7,
    marginBottom: 15,
  },
  inlineButtons: {
    flexDirection: 'row',
  },
  inlineButton: {
    flex: 1,
    marginHorizontal: 0,
  },
  inlineButtonRight: {
    marginLeft: 15,
  },
  headerButton: {
    marginTop: 15,
    marginHorizontal: 0,
  },
  errorText: {
    marginHorizontal: 15,
    paddingTop: 10,
  },
  noErrorText: {
    display: 'none',
  },
  inputError: {
    borderColor: colors.tomato,
  },
  button: {
    marginVertical: 15,
    height: 50,
  },
  buttonTitle: {
    fontSize: 18,
  },
  inputWithCurrency: {
    paddingRight: 0,
  },
  currency: {
    paddingRight: 15,
  },
  sectionSeparator: {
    height: 15,
  },
  smallLoader: {
    marginHorizontal: 15,
  },
  rightComponentStyle: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  itemRightLabel: {
    marginRight: 5,
  },
  leftComponentWrapper: {
    flexGrow: 1,
    flexShrink: 0,
    flexDirection: 'row',
  },
  listElementStar: {
    color: 'red',
    marginLeft: 5,
  },
  list: { flex: 1 },
}))

export default styles
