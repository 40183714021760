import React, {
    useState,
    useRef,
    useLayoutEffect,
} from 'react'
import {
    Animated,
    View,
    Easing,
    ImageBackground,
} from 'react-native'
import PropTypes from 'prop-types'

import { useTheme } from 'react-native-themed-styles'
import Color from 'color'

import Text from '../Text'

import themedStyles from './AnimateToBasket.Styles'

function AnimateToBasket({ imageUrl, color, name, fromX, fromY, toX, toY, onAnimComplete }) {
    const [styles] = useTheme(themedStyles)

    const [isAnimating, setAnimating] = useState(true)
    const anim = useRef(new Animated.Value(0)).current
    const x = toX - fromX
    const y = toY - fromY

    useLayoutEffect(() => {
        Animated.timing(anim, {
            toValue: 1,
            duration: 370,
            useNativeDriver: true,
            easing: Easing.inOut(Easing.cubic),
        }).start(function onComplete() {
            setAnimating(false)
            onAnimComplete?.()
        })
        return
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    if (isAnimating) {
        return (
            <Animated.View
                pointerEvents="none"
                style={[
                    styles.container,
                    {
                        left: fromX,
                        top: fromY,
                        opacity: anim.interpolate({
                            inputRange: [0, 0.03, 0.98, 1],
                            outputRange: [0, 1, 1, 0],
                        }),
                        transform: [
                            {
                                scale: anim.interpolate({
                                    inputRange: [0, 0.1, 1],
                                    outputRange: [3, 1, 1],
                                }),
                            },
                            {
                                translateX: anim.interpolate({
                                    inputRange: [0, 1],
                                    outputRange: [0, x],
                                }),
                            },
                            {
                                translateY: anim.interpolate({
                                    inputRange: [0, 0.6, 0.92, 1],
                                    outputRange: [0, 0.06 * y, 0.4 * y, y],
                                }),
                            },
                        ],
                    },
                ]}>
                {!imageUrl ? (
                    <View
                        style={[
                            styles.content,
                            { backgroundColor: Color(color).fade(0.65).toString() },
                        ]}>
                        <Text
                            i18nKey={name
                                ?.match(/\b(\w{1})/g)
                                ?.slice(0, 2)
                                ?.join('')
                                ?.toUpperCase()}
                            color={color}
                        />
                    </View>
                ) : (
                    <ImageBackground
                        source={{ uri: imageUrl, cache: 'force-cache' }}
                        style={[
                            styles.content,
                            { backgroundColor: Color(color).fade(0.65).toString() },
                        ]}
                        resizeMode="cover"
                    />
                )}
            </Animated.View>
        )
    }
    return null
}
AnimateToBasket.defaultProps = {
    color: 'transparent',
}
AnimateToBasket.propTypes = {
    imageUrl: PropTypes.string,
    name: PropTypes.string,
    fromX: PropTypes.number,
    fromY: PropTypes.number,
    toX: PropTypes.number,
    toY: PropTypes.number,
    onAnimComplete: PropTypes.func,
}

export default AnimateToBasket
