import 'react-native-gesture-handler'
import { AppRegistry } from 'react-native'
import 'react-native-get-random-values'

import { name as appName } from './app.json'

import App from './Containers/App'

AppRegistry.registerComponent(appName, () => App)
AppRegistry.runApplication(appName, {
  rootTag: document.getElementById('root'),
})
