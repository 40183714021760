/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react'
import {
    SafeAreaView,
    View,
    TouchableOpacity,
    ScrollView,
    TouchableWithoutFeedback,
} from 'react-native'
import PropTypes from 'prop-types'
import { useTheme } from 'react-native-themed-styles'
import ShadowView from 'react-native-androw'
import LottieView from 'lottie-react-native'
import { useMutation, useApolloClient } from '@apollo/client'
import { useSelector } from 'react-redux'

import {
    Header,
    Button,
    Icon,
    Text,
    Section,
    Modal,
    ModalContent,
} from '../../../Components'

import { useLanguage } from '../../../Utils/Language'
import { getErrorMessage } from '../../../Utils'

import { images, colors, animations } from '../../../Themes'
import Theme from '../../../Themes/Theme'

import { getProductCatalog } from '../../ProductCatalog/ProductCatalog.Selectors'

import { UPLOAD_BULK, CHECK_UPLOAD_STATUS, UPDATE_STATUS } from './SecondStep.Schema'

import themedStyles, { optionStyles } from './SecondStep.Styles'

function Option(props) {
    const [styles] = useTheme(optionStyles)
    const { colors: optionColors } = useContext(Theme)

    const {
        icon,
        iconColor = optionColors.secondaryIcon,
        onPress,
        disabled,
        style,
    } = props

    return (
        <TouchableOpacity
            onPress={onPress}
            style={[styles.container, styles['containerDisabled_' + disabled], style]}
            disabled={disabled}>
            <Icon source={icon} size={18} color={iconColor} />
        </TouchableOpacity>
    )
}
Option.defaultProps = {
    onPress: () => { },
    icon: images.defaultIcon,
    disabled: false,
}
Option.propTypes = {
    onPress: PropTypes.func,
    style: PropTypes.object,
    count: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    disabled: PropTypes.bool,
    icon: PropTypes.any,
}

function SecondStep({ goBack, pop, selectedBulk, keepDuplicates, previous, locationAddress, currentStep }) {
    const [styles] = useTheme(themedStyles)
    const [isSuccess, setIsSuccess] = useState(false)
    const [uploadDetails, setUploadDetails] = useState([])
    const client = useApolloClient()
    const lang = useLanguage()

    const { locationId, businessName, address } = useSelector(getProductCatalog)

    const [modalContent, setModalContent] = useState({
        isVisible: false,
        title: '',
        icon: '',
        desc: '',
        primaryText: '',
        primaryAction: null,
        secondaryText: '',
        secondaryAction: null,
        hasRadioInput: false,
    })

    const displayModal = (
        title = '',
        icon = '',
        desc = '',
        primaryText = '',
        primaryAction = null,
        secondaryText = '',
        secondaryAction = null,
        hasRadioInput = false,

    ) => {
        setModalContent({
            isVisible: true,
            title: title,
            icon: icon,
            desc: desc,
            primaryText: primaryText,
            primaryAction: primaryAction,
            secondaryText: secondaryText,
            secondaryAction: secondaryAction,
            hasRadioInput: hasRadioInput,

        })
    }

    const dismissModal = () => {
        setModalContent({
            isVisible: false,
            title: '',
            icon: '',
            desc: '',
            primaryText: '',
            primaryAction: null,
            secondaryText: '',
            secondaryAction: null,
            hasRadioInput: false,

        })
    }

    const mapDetails = (object) => {
        const r = []
        // eslint-disable-next-line no-unused-vars
        for (const [key, value] of Object.entries(object)) {
            r.push({ title: `${value?.label}: ${value?.number}` })
        }
        return r
    }

    const statusObject = {
        PENDING: true,
        INPROGESS: true,
        NOTCOMPLETED: false,
        COMPLETED: false,
        CANCELED: false,

    }
    const checkUploadStatusFunction = async (iteration) => {
        if (iteration < 300) {
            try {
                const { data: uploadStatus } = await client.query({
                    query: CHECK_UPLOAD_STATUS,
                    variables: {
                        locationAddress: locationAddress?.locationAddress,
                        uploadTransactionId: locationAddress?.uploadTransactionId,
                        uploadType: selectedBulk,
                        languageCode: lang,
                        locationId: locationId ? locationId : null,

                    },
                })
                if (statusObject[uploadStatus?.queryBulkUploadStatus?.status]) {
                    iteration = iteration + 1
                    setTimeout(() => { checkUploadStatusFunction(iteration) }, 3000)
                } else {
                    setUploadDetails(mapDetails(uploadStatus?.queryBulkUploadStatus?.details))
                    setIsSuccess(true)
                }
            }
            catch (e) {
                displayModal(
                    'certificate_error_label',
                    images.error,
                    getErrorMessage(e),
                    'logout_button_cancel',
                    () => { dismissModal() },
                )
            }
        }

    }

    const [uploadBulk, { }] = useMutation(UPLOAD_BULK, {
        onCompleted() {
            checkUploadStatusFunction(0)
        },
    })

    const [updateStatus, { }] = useMutation(UPDATE_STATUS)

    useEffect(() => {
        if (Object.keys(locationAddress).length > 0 || currentStep === 2) {
            uploadBulk({
                variables: {
                    forceUpload: keepDuplicates,
                    locationAddress: locationAddress?.locationAddress,
                    uploadTransactionId: locationAddress?.uploadTransactionId,
                    uploadType: selectedBulk,
                    languageCode: lang,
                    locationId: locationId ? locationId : null,
                },
            }).catch(e => {
                displayModal(
                    'certificate_error_label',
                    images.error,
                    getErrorMessage(e),
                    'logout_button_cancel',
                    () => { dismissModal(); previous() },
                )
            })
        }
    }, [currentStep])

    return (
        <>
            <SafeAreaView style={styles.container}>
                <Header
                    image={images.close}
                    title={`upload_${selectedBulk}_header`}
                    titlePlaceHolders={[2]}
                    customBusinessName={businessName}
                    customAddress={address}
                    onPress={() => {
                        updateStatus(
                            {
                                variables: {
                                    newStatus: 'CANCELED',
                                    locationAddress: locationAddress?.locationAddress,
                                    transactionId: locationAddress?.uploadTransactionId,
                                    uploadType: selectedBulk,
                                    languageCode: lang,
                                    locationId: locationId ? locationId : null,

                                },
                            }
                        ).catch(e => { })
                        goBack()
                    }}
                />
                <ScrollView style={styles.container} contentContainerStyle={styles.contentContainer}>
                    {isSuccess ?
                        <ShadowView style={styles.shadow}>
                            <View style={styles.successContainer}>
                                <LottieView
                                    source={animations.celebrate}
                                    autoPlay
                                    loop={true}
                                    resizeMode="contain"
                                    style={styles.printAnimation}
                                />
                                <Text i18nKey="upload_items_success_title" size="h3" weight="bold" align="center" />
                                <Section data={uploadDetails} />
                            </View>
                        </ShadowView>
                        :
                        <>
                            <View style={styles.uploadingContainer}>
                                <Icon source={images.fileUpload} size={300} resizeMode="contain" />
                                <Text i18nKey="upload_items_uploading" align="center" />

                            </View>

                        </>}
                </ScrollView>
                {!isSuccess ? <TouchableWithoutFeedback onPress={() => {
                    updateStatus(
                        {
                            variables: {
                                newStatus: 'CANCELED',
                                locationAddress: locationAddress?.locationAddress,
                                transactionId: locationAddress?.uploadTransactionId,
                                uploadType: selectedBulk,
                                languageCode: lang,
                                locationId: locationId ? locationId : null,

                            },
                        }
                    ).catch(e => { })
                    goBack()
                }}>
                    <Text i18nKey="upload_items_cancel" align="center" color={colors.secondaryAccent} style={styles.topSpacing} />
                </TouchableWithoutFeedback> : null}
                <View style={styles.buttons}>
                    <Button
                        title="certificate_button_finish"
                        variant={isSuccess ? 'active' : 'disabled'}
                        onPress={() => { pop(2) }}
                        // loader={loading}
                        style={styles.mainButton}
                    />
                </View>
            </SafeAreaView>
            <Modal
                isVisible={modalContent.isVisible}
                onBackButtonPress={() => dismissModal()}
                onBackdropPress={() => dismissModal()}
                animationIn="fadeIn"
                animationOut="fadeOut">
                <ModalContent
                    onClose={() => dismissModal()}
                    contentContainerStyle={styles.modalContent}>
                    {modalContent.icon ? <Icon source={modalContent.icon} size={40} /> : null}
                    <Text
                        i18nKey={modalContent.title}
                        align="center"
                        size="h4"
                        weight="bold"
                    />
                    <Text
                        i18nKey={modalContent.desc}
                        align="left"
                    />
                    {modalContent.primaryAction && (
                        <Button
                            title={modalContent.primaryText}
                            variant="active"
                            onPress={() => modalContent.primaryAction?.()}
                        />
                    )}
                    {modalContent.secondaryAction && (
                        <Button
                            title={modalContent.secondaryText}
                            variant="link"
                            onPress={() => modalContent.secondaryAction?.()}
                        />
                    )}
                </ModalContent>
            </Modal>
        </>
    )
}

SecondStep.propTypes = {
    goBack: PropTypes.func,
    selectedBulk: PropTypes.string,
    keepDuplicates: PropTypes.bool,
    locationAddress: PropTypes.object,
    currentStep: PropTypes.any,
    locationId: PropTypes.string,
    previous: PropTypes.func,
}

export default SecondStep
