import { styleSheetFactory } from '../../Themes/Colors'

const styles = styleSheetFactory(colors => ({
  containerFull_true: {
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  containerFull_false: {
    height: 70,
    justifyContent: 'center',
    alignItems: 'center',
  },
}))

export default styles
