import { styleSheetFactory } from '../../../../Themes/Colors'

const styles = styleSheetFactory(colors => ({
  container: {
    flex: 1,
    backgroundColor: colors.background,
  },
  fillRemaining: {
    flexGrow: 1,
    paddingBottom: 20,
  },
  buttons: {
    flexDirection: 'row',
    marginBottom: 15,
    marginTop: 10,
    marginHorizontal: 15,
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  leftButton: {
    flex: 1,
    height: 50,
  },
  rightButton: {
    flex: 1,
    height: 50,
    marginRight: 15,
  },
  topContent: {
    paddingHorizontal: 15,
  },
  sectionTitle: {
    marginBottom: 10,
  },
  questionButtonsContainer: {
    flexDirection: 'row',
    flexGrow: 1,
    justifyContent: 'center',
    marginBottom: 20,
  },
  questionButton: {
    paddingHorizontal: 75,
    marginHorizontal: 10,
  },
  sectionNote: {
    marginTop: 5,
    marginBottom: 16,
  },
  currency: {
    marginBottom: 16,
  },
  rightButtonIcon: {
    marginLeft: 10,
  },
  endDateInput: {
    marginBottom: 0,
  },
  buttonTitle: {
    flexShrink: 1,
  },
  vehiclePlateHelper: {
    marginBottom: 20,
    marginTop: 5,
    textAlign: 'left',
    fontFamily: 'Vodafone Rg',
    fontSize: 14,
    color: colors.secondaryText,
  },
  vehiclePlateHelperItalic: {
    fontStyle: 'italic',
  },
}))

export default styles
