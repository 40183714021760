import React from 'react'
import { View } from 'react-native'
import PropTypes from 'prop-types'
import { useTheme } from 'react-native-themed-styles'

import Text from '../Text'

import ContentManager from '../../Utils/ContentManager'

import themedStyles from './Timeline.Styles'

function Timeline({
  items,
  direction,
  titleStyle,
  activeTitleStyle,
  activeDescStyle,
  titleProps,
  descProps,
  style,
  iconContainerStyle,
  isContentLoading,
}) {
  const [styles] = useTheme(themedStyles)

  const { length: len } = items
  const { translate } = ContentManager

  const customStyles = {
    title: {
      active: {},
      current: activeTitleStyle,
      inactive: {},
    },
    desc: {
      active: {},
      current: activeDescStyle,
      inactive: {},
    },
  }

  if (items === []) {
    return (
      <View />
    )
  }

  return (
    <View style={[styles['wraper_' + direction], style]}>
      {items.map((item, i) => (
        <View style={styles['container_' + direction]} key={i}>
          <View style={[styles.iconsContainer, iconContainerStyle]}>
            <View
              style={
                i !== len - 1
                  ? [
                    styles['line_' + direction],
                    styles['line_' + item.type],
                  ]
                  : []
              }
            />
            <View style={[styles.outer, styles['outer_' + item.type]]}>
              <View style={[styles.inner, styles['inner_' + item.type]]} />
            </View>
          </View>
          <View style={styles['text_container_' + direction]}>
            <Text
              size="h5"
              style={[
                styles.title,
                styles['title_' + direction + '_' + item.type],
                titleStyle,
                customStyles.title[item.type],
              ]}
              isLoading={isContentLoading}
              i18nKey={item.title}
              {...titleProps}
            />
            {item.desc && (
              <Text
                weight="light"
                style={[
                  styles.desc,
                  styles['desc_' + direction],
                  styles['desc_' + direction + '_' + item.type],
                  // descStyle.customStyles.desc[item.type],
                ]}
                isLoading={isContentLoading}
                i18nKey={item.desc}
                {...descProps}
              />
            )}
            {item.time && (
              <Text
                weight="light"
                style={[
                  styles.desc,
                  styles['desc_' + direction],
                  styles['desc_' + direction + '_' + item.type],
                  // descStyle.customStyles.desc[item.type],
                ]}
                isLoading={isContentLoading}
                i18nKey={item.time}
                {...descProps}
              />
            )}
            {item.reason && (
              <Text
                weight="light"
                style={[
                  styles.desc,
                  styles['desc_' + direction],
                  styles['desc_' + direction + '_' + item.type],
                  // descStyle.customStyles.desc[item.type],
                ]}
                isLoading={isContentLoading}
                i18nKey={translate('payment_self_charge_reason_placeholder') + ': ' + translate(item?.reason)}
                {...descProps}
              />
            )}
          </View>
        </View>
      ))}
    </View>
  )
}

Timeline.defaultProps = {
  direction: 'vertical',
}

Timeline.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.oneOf(['active', 'current', 'inactive']),
      title: PropTypes.string,
      desc: PropTypes.string,
    })
  ),
  direction: PropTypes.oneOf(['vertical', 'horizontal']),
  titleStyle: PropTypes.object,
  activeTitleStyle: PropTypes.object,
  descStyle: PropTypes.object,
  activeDescStyle: PropTypes.object,
  style: PropTypes.object,
  titleProps: PropTypes.object,
  descProps: PropTypes.object,
  iconContainerStyle: PropTypes.object,
  isContentLoading: PropTypes.bool,
}

export default Timeline
