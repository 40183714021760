import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { SafeAreaView, ScrollView, View } from 'react-native'
import { useTheme } from 'react-native-themed-styles'
import AsyncStorage from '@react-native-async-storage/async-storage'
import moment from 'moment'


import {
    Header,
    Icon,
    Button,
    ErrorModal,
    Text,
} from '../../Components'

import images from '../../Themes/Images'

import themedStyles from './OnBoarding.Styles'

function CertWarning({ setShouldShowWarning }) {

    const [styles] = useTheme(themedStyles)

    const [modal, setModal] = useState({
        isVisible: false,
        icon: images.warningIcon,
        title: '',
        desc: '',
        descPlaceholders: [],
        primaryText: '',
        primaryAction: null,
        tertiaryText: '',
        tertiaryAction: null,
    })
    const closeModal = () => {
        setModal({
            isVisible: false,
            icon: images.delete,
            title: '',
            desc: '',
            primaryText: '',
            primaryAction: null,
            tertiaryText: '',
            tertiaryAction: null,
        })
    }

    return (
        <>
            <SafeAreaView style={styles.container}>
                <Header
                    title={'onboarding_cert_warning_header'}
                    image={images.close}
                    onPress={() => {
                        setShouldShowWarning(false)
                        AsyncStorage.setItem('LAST_CERT_WARNING', JSON.stringify({ date: moment() }))
                    }}
                />
                <ScrollView style={styles.scrollView}>
                    <View style={styles.certWrapper}>
                        <View style={styles.upperContentWrapper}>
                            <Icon source={images.warning} size={60} style={styles.icon} />
                            <Text i18nKey="onboarding_cert_warning_title" size="h3" weight="bold" align="center" style={styles.certTitle} />
                        </View>
                        <View style={styles.horizontalSpacing}>
                            <View style={styles.certDescription}>
                                <Text align="left" i18nKey="onboarding_cert_warning_desc_1" />
                            </View>
                            <Text align="left" i18nKey="onboarding_cert_warning_desc_2" />
                            <View style={styles.certList}>
                                <Text align="left" i18nKey="onboarding_cert_warning_desc_3" />
                            </View>
                        </View>
                    </View>
                </ScrollView>
                <View style={styles.rightButton}>
                    <Button title="onboarding_cert_warning_skip" variant="preActive" onPress={() => {
                        setShouldShowWarning(false)
                        AsyncStorage.setItem('LAST_CERT_WARNING', JSON.stringify({ date: moment() }))
                    }} />
                </View>
            </SafeAreaView>
            <ErrorModal
                isVisible={modal?.isVisible}
                title={modal?.title}
                description={modal?.desc}
                placeHolders={modal.descPlaceholders}
                primaryText={modal?.primaryText}
                tertiaryText={modal?.tertiaryText}
                primaryAction={modal?.primaryAction}
                tertiaryAction={modal?.tertiaryAction}
                onBackdropPress={() => closeModal()}
                onBackButtonPress={() => closeModal()}
                onClosePress={() => closeModal()}
            />
        </>
    )
}

CertWarning.propTypes = {
    setShouldShowWarning: PropTypes.func,

}

export default CertWarning

