/* eslint-disable no-shadow */
import React, { createContext, useState, useContext, useEffect } from 'react'
import { Vibration } from 'react-native'
import AsyncStorage from '@react-native-async-storage/async-storage'
import Sound from 'react-native-sound'
import PropTypes from 'prop-types'

import { useLocationDetails, useUserDetails } from './AuthDetails'

const Config = createContext()
const CONFIG_KEY = 'config'
const ITEM_TYPES = {
  list: 'list',
  grid: 'grid',
  card: 'card',
}
const ITEM_TYPES_ARRAY = Object.values(ITEM_TYPES)
const DEFAULT_CONFIG = {
  isReadingConfig: true,
  itemType: 'grid',
  soundEnabled: false,
  vibrationEnabled: false,
  switchLocation: {
    hasTokenValueSet: false,
    locationId: '',
    address: '',
    deviceId: '',
    businessName: '',
  },
}

function ConfigProvider({ children }) {
  const { deviceId } = useUserDetails()
  const { id, address, name: businessName } = useLocationDetails()
  const [config, setConfig] = useState(DEFAULT_CONFIG)

  const getConfig = () => {
    AsyncStorage.getItem(CONFIG_KEY)
      .then((value = JSON.stringify(DEFAULT_CONFIG)) => setConfig(prev => ({
        ...prev,
        ...JSON.parse(value),
        isReadingConfig: false,
      })))
      .catch(() => setConfig(prev => ({
        ...prev,
        isReadingConfig: false,
      })))
  }

  const setItemType = (type) => {
    if (Object.values(ITEM_TYPES).includes(type)) {
      setConfig(prev => {
        const nextConfig = {
          ...prev,
          itemType: type,
        }
        AsyncStorage.setItem(CONFIG_KEY, JSON.stringify(nextConfig)).catch(() => { })
        return nextConfig
      })
    }
  }

  const toggleSound = () => {
    setConfig(prev => {
      const nextSoundState = !prev?.soundEnabled
      const nextConfig = {
        ...prev,
        soundEnabled: nextSoundState,
      }
      if (nextSoundState) {
        const whoosh = new Sound('scan.mp3', Sound.MAIN_BUNDLE, (error) => {
          if (error) {
            return
          }
          whoosh.setVolume(1)
          whoosh.play((success) => {
            whoosh.release()
          })
        })
      }
      AsyncStorage.setItem(CONFIG_KEY, JSON.stringify(nextConfig)).catch(() => { })
      return nextConfig
    })
  }

  const toggleVibration = () => {
    setConfig(prev => {
      const nextVibrationState = !prev?.vibrationEnabled
      const nextConfig = {
        ...prev,
        vibrationEnabled: nextVibrationState,
      }
      if (nextVibrationState) {
        Vibration.vibrate([0, 200])
      }
      AsyncStorage.setItem(CONFIG_KEY, JSON.stringify(nextConfig)).catch(() => { })
      return nextConfig
    })
  }

  const setSwitchLocationConfig = ({ locationId, deviceId, address, businessName, tokenSet = true }) => {
    setConfig(prev => {
      const nextConfig = {
        ...prev,
        switchLocation: {
          locationId,
          deviceId,
          address,
          businessName,
          hasTokenValueSet: tokenSet,
        },
      }
      AsyncStorage.setItem(CONFIG_KEY, JSON.stringify(nextConfig)).catch(() => { })
      return nextConfig
    })

  }

  useEffect(() => {
    if (!config.isReadingConfig) {
      if (id && !config.switchLocation.hasTokenValueSet) {
        setSwitchLocationConfig({
          locationId: id,
          deviceId: deviceId,
          address: address,
          businessName: businessName,

        })
      }
    }


  }, [id, deviceId, businessName, config.isReadingConfig])

  useEffect(() => {
    getConfig()
  }, [])



  return (
    <Config.Provider value={{
      ...config,
      setItemType,
      toggleSound,
      toggleVibration,
      setSwitchLocationConfig,
    }}>
      {children}
    </Config.Provider>
  )
}
ConfigProvider.propTypes = {
  children: PropTypes.any,
}

function useConfig() {
  const context = useContext(Config)
  if (context === undefined) {
    throw new Error('useConfig must be used within a ConfigProvider')
  }
  return context
}

export { useConfig, ITEM_TYPES, ITEM_TYPES_ARRAY }
export default ConfigProvider
