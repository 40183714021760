import React from 'react'
import { View, TouchableOpacity, LayoutAnimation } from 'react-native'
import { useTheme } from 'react-native-themed-styles'
import moment from 'moment-timezone'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'

import {
    Text,
    TouchableInput,
    Switch,
} from '../../../../../Components'

import { layoutAnimConfig } from '../../../../../Themes/Theme'
import { images, colors } from '../../../../../Themes'

import { getPaymentDates, getShouldHaveBillingPeriod } from '../../../Payment.Selectors'
import { showPaymentDatesModal, updatePaymentAttribute } from '../../../Payment.Actions'

import billingPeriodStyles from './BilingPeriod.Styles'

function BillingPeriod({
    style,
    dateStyle,
}) {
    const [styles] = useTheme(billingPeriodStyles)
    const dispatchAction = useDispatch()

    const shouldHaveBillingPeriod = useSelector(getShouldHaveBillingPeriod)
    const dates = useSelector(getPaymentDates)

    const setShouldHaveBillingPeriod = (value) => dispatchAction(updatePaymentAttribute('shouldHaveBillingPeriod', value))
    const setDates = (value) => dispatchAction(updatePaymentAttribute('dates', value))
    const showDatesModal = (value) => dispatchAction(showPaymentDatesModal(value))

    return (
        <View>
            <View style={[styles.AccessItem, style]}>
                <TouchableOpacity
                    onPress={() => {
                        LayoutAnimation.configureNext(layoutAnimConfig)
                        setShouldHaveBillingPeriod(!shouldHaveBillingPeriod)
                        setDates({
                            startDate: '',
                            endDate: '',
                            shown: false,
                            willUpdate: '',
                            tempDate: '',
                        })
                    }}
                    style={styles.touchable} activeOpacity={0.5}>
                    <View style={styles.accessTextContainer}>
                        <Text i18nKey="payment_supplying_period_label" weight="bold" numberOfLines={2} style={styles.name} />
                    </View>
                    <View pointerEvents="none" style={styles.radioInput}>

                        <Switch
                            value={shouldHaveBillingPeriod}
                            color={colors.secondaryAccent}
                        />
                    </View>
                </TouchableOpacity>
            </View>
            {shouldHaveBillingPeriod && <View style={[styles.buttonWrapper, dateStyle]}>
                <TouchableInput
                    onPress={() => showDatesModal('startDate')}
                    value={dates?.startDate ? moment(dates?.startDate).format('DD/MM/YYYY') : ''}
                    label="einvoice_start_date_label"
                    icon={images.calendarBlack}
                    autoCorrect={false}
                    autoCapitalize="none"
                    editable={false}
                    style={styles.leftButton}
                />
                <TouchableInput
                    onPress={() => showDatesModal('endDate')}
                    value={dates?.endDate ? moment(dates?.endDate).format('DD/MM/YYYY') : ''}
                    disabled={!dates.startDate}
                    label="einvoice_end_date_label"
                    icon={images.calendarBlack}
                    autoCorrect={false}
                    autoCapitalize="none"
                    editable={false}
                    style={styles.rightButton}
                />
            </View>}
        </View >
    )
}

BillingPeriod.propTypes = {
    style: PropTypes.object,
    dateStyle: PropTypes.object,
}

export default BillingPeriod
