import {styleSheetFactory} from '../../Themes/Colors'

const styles = styleSheetFactory((colors) => ({
  wrapper: {
    width: '100%',
    height: '100%',
  },
  container: {
    position: 'absolute',
    top: 0,
    left: 0,
  },
  visible_false: {
    backgroundColor: colors.transparent,
    width: 0,
    height: 0,
  },
  visible_true: {
    backgroundColor: colors.background,
    bottom: 0,
    right: 0,
  },
}))

export default styles
