/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useState,
  useContext,
  useEffect,
  useCallback,
  useRef,
} from 'react'
import {
  SafeAreaView,
  View,
  TouchableOpacity,
  TextInput,
  useWindowDimensions,
} from 'react-native'
import PropTypes from 'prop-types'
import { useFocusEffect } from '@react-navigation/native'
import { SwipeRow } from 'react-native-swipe-list-view'
import Color from 'color'
import { useLazyQuery, useMutation } from '@apollo/client'

import { useTheme } from 'react-native-themed-styles'

import ContentManager from '../../Utils/ContentManager'

import {
  Icon,
  Text,
  Button,
  Header,
  Loader,
  ButtonGroup,
} from '../../Components'

import SwipeList from './SwipeList'

import Theme from '../../Themes/Theme'
import themedStyles, {
  photoItemStyles,
  itemStyles,
  hiddenStyles,
} from './Users.Styles'

import images from '../../Themes/Images'

import { LIST_EMPLOYEES, CHANGE_STATUS, DELETE_EMPLOYEE } from './Users.schema'

const employeeStatus = {
  Pending: 'gray',
  Active: 'green',
  Inactive: 'red',
}

const avatars = ['maleAvatar', 'femaleAvatar']

function ItemPhoto(props) {
  const [styles] = useTheme(photoItemStyles)
  const { colors } = useContext(Theme)
  const { color, role, title, status } = props

  const pic = avatars.find((el) => el === color)

  return (
    <View>
      {pic ? (
        role !== 'Admin' ? (
          <View style={styles.avatarContainer}>
            <Icon
              source={images[pic]}
              size={70}
              resizeMode="cover"
            />
            <View style={[{ backgroundColor: colors[status] }, styles.status]} />
          </View>
        ) : (
          <Icon source={images[pic]} resizeMode="cover" size={70} />
        )
      ) : role !== 'Admin' ? (
        <View
          style={[
            {
              backgroundColor: Color(colors[color] || colors.transparent)
                .fade(0.7)
                .toString(),
            },
            styles.colorContainer,
          ]}>
          <Text
            size="h5"
            color={colors[color]}
            align="center"
            i18nKey={title
              ?.match(/\b(\w{1})/g)
              ?.slice(0, 2)
              ?.join('')
              ?.toUpperCase()}
          />
          <View style={[{ backgroundColor: colors[status] }, styles.status]} />
        </View>
      ) : (
        <View
          style={[
            {
              backgroundColor: Color(colors[color] || colors.transparent)
                .fade(0.7)
                .toString(),
            },
            styles.colorContainer,
          ]}>
          <Text
            size="h5"
            color={colors[color]}
            align="center"
            i18nKey={title
              ?.match(/\b(\w{1})/g)
              ?.slice(0, 2)
              ?.join('')
              ?.toUpperCase()}
          />
        </View>
      )}
    </View>
  )
}
ItemPhoto.propTypes = {
  role: PropTypes.string,
  title: PropTypes.string,
  status: PropTypes.string,
}

function Item(props) {
  const [styles] = useTheme(itemStyles)
  const { colors } = useContext(Theme)

  const { item, isFirst, isLast, onPress } = props

  let propStyle = {}, propStyle1 = {}
  if (isFirst) {
    propStyle = styles.listFirst
  }
  if (isLast) {
    propStyle1 = styles.listLast
  }
  const style = Object.assign({}, styles.default, styles.list, propStyle, propStyle1)

  return (
    <View style={[{ backgroundColor: colors.tertiaryBackground }, style]}>
      <TouchableOpacity style={[style]} onPress={onPress}>
        <View style={styles.subContainer}>
          <ItemPhoto
            color={item.color}
            role={item.role}
            title={`${item.firstName} ${item.lastName}`}
            status={employeeStatus[item.userStatus]}
          />
          <View style={styles.textContainer}>
            <View style={styles.rowStyle}>
              <Text
                i18nKey={`${item.firstName} ${item.lastName}`}
                numberOfLines={1}
              />
              <Text i18nKey={` (${item.role})`} numberOfLines={1} />
            </View>
            <Text
              i18nKey={item.email}
              weight="light"
              color={colors.secondaryText}
              size="footnote"
              numberOfLines={1}
            />
          </View>
          <View style={styles.arrowContainer}>
            <Icon
              source={images.chevronRight}
              size={24}
              color={colors.accent}
            />
          </View>
        </View>
      </TouchableOpacity>
    </View>
  )
}
Item.propTypes = {
  item: PropTypes.object,
  isFirst: PropTypes.bool,
  isLast: PropTypes.bool,
  onPress: PropTypes.func,
}

function HiddenItem(props) {
  const [styles] = useTheme(hiddenStyles)
  const { colors } = useContext(Theme)

  const {
    needsConfirmation,
    activate,
    onActivatePress,
    onDeletePress,
    onActivateConfirm,
    onDeleteConfirm,
    onCancel,
    isFirst,
    isLast,
  } = props

  let propStyle = {},
    propStylee = {}
  if (isFirst) {
    propStyle = styles.listFirst
  }
  if (isLast) {
    propStylee = styles.listLast
  }
  const stylee = Object.assign(
    {},
    styles.default,
    styles.list,
    propStyle,
    propStylee,
  )

  return (
    <View style={[styles.hiddenContainer, stylee]}>
      {needsConfirmation ? (
        <View style={styles.rowStyle}>
          <TouchableOpacity
            style={[
              {
                backgroundColor: colors.green,
              },
              styles.hiddenItem,
            ]}
            onPress={onActivatePress}>
            <Text i18nKey="users_activate_button" color="white" />
          </TouchableOpacity>
          <TouchableOpacity
            style={[
              {
                backgroundColor: colors.red,
              },
              styles.hiddenItem,
            ]}
            onPress={onDeletePress}>
            <Text i18nKey="users_delete_button" color="white" />
          </TouchableOpacity>
        </View>
      ) : (
        <View
          style={styles.activateStyles(activate)}>
          {activate ? (
            <TouchableOpacity
              style={styles.confirmStyles}
              onPress={onActivateConfirm}>
              <Icon source={images.tick} color={colors.white} size={24} />
              <Text
                i18nKey="users_confirm_activate"
                color={colors.white}
                style={styles.LeftSpacing}
              />
            </TouchableOpacity>
          ) : (
            <TouchableOpacity
              style={styles.confirmStyles}
              onPress={onDeleteConfirm}>
              <Icon source={images.delete} color={colors.white} size={24} />
              <Text
                i18nKey="users_confirm_delete"
                color={colors.white}
                style={styles.LeftSpacing}
              />
            </TouchableOpacity>
          )}
          <TouchableOpacity style={styles.cancelContainer} onPress={onCancel}>
            <Icon source={images.closeIcon} color={colors.white} size={20} />
          </TouchableOpacity>
        </View>
      )}
    </View>
  )
}
HiddenItem.propTypes = {
  needsConfirmation: PropTypes.bool,
  onActivatePress: PropTypes.func,
  onDeletePress: PropTypes.func,
  onCancel: PropTypes.func,
  onActivateConfirm: PropTypes.func,
  onDeleteConfirm: PropTypes.func,
  activate: PropTypes.bool,
  style: PropTypes.func,
  isFirst: PropTypes.bool,
  isLast: PropTypes.bool,
}

function HiddenSingleItem(props) {
  const [styles] = useTheme(hiddenStyles)
  const { colors } = useContext(Theme)

  const {
    needsConfirmation,
    onPress,
    onConfirm,
    onCancel,
    isFirst,
    isLast,
    status,
  } = props

  let propStyle = {},
    propStylee = {}
  if (isFirst) {
    propStyle = styles.listFirst
  }
  if (isLast) {
    propStylee = styles.listLast
  }
  const stylee = Object.assign(
    {},
    styles.default,
    styles.list,
    propStyle,
    propStylee,
  )

  return (
    <View style={[styles.hiddenContainer, stylee]}>
      {needsConfirmation ? (
        <View style={styles.rowStyle}>
          <TouchableOpacity
            style={[
              {
                backgroundColor:
                  status === 'Active' ? colors.pending : colors.accent,
              },
              styles.hiddenItem,
            ]}
            onPress={onPress}>
            <Text
              i18nKey={
                status === 'Active'
                  ? 'users_deactivate_button'
                  : 'users_delete_button'
              }
              color="white"
            />
          </TouchableOpacity>
        </View>
      ) : (
        <View
          style={styles.statusView(status)}>
          <TouchableOpacity style={styles.confirmStyles} onPress={onConfirm}>
            <Icon source={images.tick} color={colors.white} size={24} />
            <Text
              i18nKey={
                status === 'Active'
                  ? 'users_confirm_deactivate'
                  : 'users_confirm_delete'
              }
              color={colors.white}
              style={styles.leftSpacing}
            />
          </TouchableOpacity>
          <TouchableOpacity style={styles.cancelContainer} onPress={onCancel}>
            <Icon source={images.closeIcon} color={colors.white} size={20} />
          </TouchableOpacity>
        </View>
      )}
    </View>
  )
}
HiddenSingleItem.propTypes = {
  needsConfirmation: PropTypes.bool,
  onPress: PropTypes.func,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  isFirst: PropTypes.bool,
  isLast: PropTypes.bool,
  status: PropTypes.string,
}

function ErrorItem() {
  const [styles] = useTheme(themedStyles)

  return (
    <View
      style={styles.errorItemWrapper}>
      <View
        style={styles.errorItemTextWrapper}>
        <>
          <Text
            i18nKey="users_no_data"
            align="center"
            size="body"
            weight="light"
          />
        </>
      </View>
    </View>
  )
}
ErrorItem.propTypes = {}

function Users({ navigation }) {
  const [styles] = useTheme(themedStyles)
  const { colors } = useContext(Theme)
  const { width, height } = useWindowDimensions()

  // Options
  const sortOptions = [
    {
      title: 'users_all_filter',
      type: 'all',
      list: () => list(),
    },
    {
      title: 'users_active_filter',
      type: 'active',
      list: () => filteredList('Active'),
    },
    {
      title: 'users_inactive_filter',
      type: 'inactive',
      list: () => filteredList('Inactive'),
    },
    {
      title: 'users_pending_filter',
      type: 'pending',
      list: () => filteredList('Pending'),
    },
  ]

  const [rightOpenValueFull, setRightOpenValueFull] = useState(-width + 30)
  const [needsConfirmation, setNeedForConfirmation] = useState(true)
  const [activate, setActivate] = useState(false)
  const [value, setValue] = useState('')
  const [refresh1, setrefresh1] = useState(false)
  const queryRef = useRef('')
  const [items, setItems] = useState([])
  const [loading1, setLoading1] = useState(true)
  const [refetching, setRefetching] = useState(false)
  const [limit] = useState(20)
  const [chosenStatus, setChosenStatus] = useState('')
  const [selected, setSelected] = useState('all')

  useEffect(() => {
    setLoading1(true)
    setItems([])
  }, [width, height])

  const measureView = (event) => {
    setRightOpenValueFull(-event.nativeEvent.layout.width)
  }
  const openRow = (rowMap, id) => {
    setActivate(false)
    setNeedForConfirmation(false)
    rowMap[id].manuallySwipeRow(rightOpenValueFull)
  }
  const closeRow = (rowMap, id) => {
    rowMap[id].closeRow()
    setNeedForConfirmation(true)
  }

  const openActivateRow = (rowMap, id) => {
    setNeedForConfirmation(false)
    rowMap[id].manuallySwipeRow(rightOpenValueFull)
    setActivate(true)
  }
  const resetNeedForConfirmation = () => {
    setNeedForConfirmation(true)
  }

  const [changeStatus, { data: statusData }] = useMutation(CHANGE_STATUS)

  const [deleteUser, { data: delData }] = useMutation(DELETE_EMPLOYEE)

  const searchList = () => {
    listEmployees({
      variables: {
        limit: limit,
        name: 'name',
        value: value,
      },
    })
  }

  const filteredList = (val) => {
    setChosenStatus(val)
    queryRef.current = 'filter'
    listEmployees({
      variables: {
        limit: limit,
        name: 'userStatus',
        value: val,
      },
    })
  }

  const changeEmployeeStatus = (id, disable) => {
    changeStatus({
      variables: {
        id: id,
        disable: disable,
      },
    })
  }

  const deleteEmployee = (id) => {
    deleteUser({
      variables: {
        id: id,
      },
    })
  }

  const [listEmployees, { data: listData, refetch }] = useLazyQuery(
    LIST_EMPLOYEES,
    {
      fetchPolicy: 'no-cache',
    },
  )

  const list = () => {
    listEmployees({
      variables: {
        limit: limit,
      },
    })
  }

  useEffect(() => {
    if (value !== '') {
      queryRef.current = 'listWithSearch'
      setLoading1(true)
      setRefetching(false)
      setItems([])
      searchList()
    } else {
      setLoading1(true)
      setItems([])
      queryRef.current = 'list'
      setRefetching(false)
      list()
    }
  }, [value])

  useEffect(() => {
    if (listData) {
      const employees = listData?.listEmployee?.empleyees
      setrefresh1(false)
      setLoading1(false)
      if (queryRef.current === 'list') {
        if (refetching) {
          setItems([])
        }
        setItems((prev) => {
          return prev.concat(employees)
        })
      } else {
        setItems((prev) => {
          return prev.concat(employees)
        })
      }
    }
  }, [listData])

  useEffect(() => {
    if (statusData) {
      setLoading1(true)
      setItems([])
      list()
    }
  }, [statusData])

  useEffect(() => {
    if (delData) {
      setLoading1(true)
      setItems([])
      list()
    }
  }, [delData])

  const onEndReached = () => {
    if (
      listData?.listEmployee?.paginationToken === null ||
      (listData?.listEmployee?.paginationToken === undefined && items.length)
    ) {
      return
    }
    setRefetching(false)
    if (queryRef.current === 'list') {
      setLoading1(true)
      setrefresh1(true)
      listEmployees({
        variables: {
          limit: limit,
          paginationToken: listData?.listEmployee?.paginationToken,
        },
      })
    } else if (queryRef.current === 'listWithSearch') {
      listEmployees({
        variables: {
          limit: limit,
          name: 'name',
          value: value,
          paginationToken: listData?.listEmployee?.paginationToken,
        },
      })
    } else {
      listEmployees({
        variables: {
          limit: limit,
          name: 'userStatus',
          value: chosenStatus,
          paginationToken: listData?.listEmployee?.paginationToken,
        },
      })
    }
  }

  const checkValue = (text) => {
    setValue(text)
  }

  const onSearchClean = () => {
    setValue('')
    setSelected('all')
  }

  const onRefresh = () => {
    setRefetching(true)
    refetch()
  }

  useFocusEffect(
    useCallback(() => {
      setChosenStatus('')
      setValue('')
      setSelected('all')
      setLoading1(true)
      setItems([])
      list()
    }, []),
  )

  useEffect(() => {
    if (selected) {
      const index = sortOptions.findIndex((el) => el.type === selected)
      setLoading1(true)
      setItems([])
      sortOptions[index].list()
    }
  }, [selected])

  const handleSingleHiddenItem = (status, id) => {
    if (status === 'Active') {
      changeEmployeeStatus(id, true)
    } else {
      deleteEmployee(id)
    }
  }

  return (
    <SafeAreaView style={styles.container}>
      <Header
        title="header_users"
        image={images.menu}
        onPress={() => navigation.openDrawer()}
      />
      <SwipeList
        style={styles.listStyle}
        onLayout={(event) => measureView(event)}
        ItemSeparatorComponent={() => <View style={styles.listSeparatorStyle} />}
        data={items}
        onRowClose={resetNeedForConfirmation}
        ListHeaderComponent={
          <>
            <Button
              title="users_button_create"
              icon={images.plusIcon}
              iconColor={colors.tertiaryIcon}
              iconStyle={styles.headerLeftButtonIcon}
              backgroundColor={colors.accent}
              borderColor={colors.transparent}
              style={styles.headerLeftButton}
              onPress={() => navigation.navigate('NewUser', {})}
            />
            <ButtonGroup
              selected={selected}
              onPress={(type) => setSelected(type)}
              options={sortOptions}
              style={styles.verticalSpacing}
            />
          </>
        }
        ListEmptyComponent={loading1 ? <Loader size={32} /> : <ErrorItem />}
        ListFooterComponent={
          refresh1 ? (
            <>
              <Loader size={32} />
            </>
          ) : null
        }
        renderItem={(rowData, rowMap) => {
          const { item, index } = rowData
          let isFirst = false,
            isLast = false
          if (index === 0) {
            isFirst = true
          }
          if (index === items.length - 1) {
            isLast = true
          }
          return (
            <SwipeRow
              key={`item-${index}`}
              disableRightSwipe
              disableLeftSwipe={item?.role === 'Admin' ? true : false}
              rightOpenValue={item?.userStatus === 'Inactive' ? -200 : -100}
              closeOnRowPress
              restSpeedThreshold={0.004}
              useNativeDriver={true}>
              {item?.userStatus === 'Inactive' ? (
                <HiddenItem
                  key={item?.id}
                  needsConfirmation={needsConfirmation}
                  activate={activate}
                  isFirst={isFirst}
                  isLast={isLast}
                  onActivatePress={() =>
                    openActivateRow(rowMap, 'row-' + index)
                  }
                  onDeletePress={() => openRow(rowMap, 'row-' + index)}
                  onDeleteConfirm={() => {
                    deleteEmployee(item?.id)
                    closeRow(rowMap, 'row-' + index)
                    setLoading1(true)
                  }}
                  onActivateConfirm={() => {
                    changeEmployeeStatus(item?.id, false)
                    closeRow(rowMap, 'row-' + index)
                    setLoading1(true)
                  }}
                  onCancel={() => closeRow(rowMap, 'row-' + index)}
                />
              ) : (
                <HiddenSingleItem
                  key={item?.id}
                  needsConfirmation={needsConfirmation}
                  isFirst={isFirst}
                  isLast={isLast}
                  status={item?.userStatus}
                  onPress={() => openRow(rowMap, 'row-' + index)}
                  onConfirm={() => {
                    handleSingleHiddenItem(item?.userStatus, item?.id)
                    closeRow(rowMap, 'row-' + index)
                    setLoading1(true)
                  }}
                  onCancel={() => closeRow(rowMap, 'row-' + index)}
                />
              )}
              <Item
                key={item?.id}
                item={item}
                isFirst={isFirst}
                isLast={isLast}
                onPress={() =>
                  navigation.navigate('UserDetails', { id: item?.id })
                }
              />
            </SwipeRow>
          )
        }}
        onEndReachedThreshold={0.5}
        onEndReached={onEndReached}
        onRefresh={onRefresh}
        refreshing={false}
      />
      <View style={styles.searchContainer}>
        <View style={styles.inputContainer}>
          <View style={styles.searchIconContainer}>
            <Icon
              source={images.search}
              size={18}
              color={colors.secondaryIcon}
            />
          </View>
          <TextInput
            placeholder={ContentManager.translate('users_search_placeholder')}
            placeholderTextColor={colors.placeholder}
            style={styles.searchInput}
            value={value}
            onChangeText={(text) => checkValue(text)}
            autoCorrect={false}
            allowFontScaling={false}
          />
          {value !== '' && (
            <TouchableOpacity
              onPress={onSearchClean}
              style={styles.clearContainer}>
              <Icon
                source={images.close}
                color={colors.secondaryIcon}
                size={15}
              />
            </TouchableOpacity>
          )}
        </View>
      </View>
    </SafeAreaView>
  )
}
Users.propTypes = {
  navigation: PropTypes.object,
}

export default Users
