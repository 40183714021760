import React, { useRef } from 'react'
import { ScrollView, View } from 'react-native'
import { useTheme } from 'react-native-themed-styles'
import { useSelector } from 'react-redux'
import moment from 'moment'

import { getDelivery, getReferences, getInitialData, getNotes } from '../../../../Payment.Selectors'

import { Note, Section } from '../../../../../../Components'

import Expandable from '../../../../../../Components/Expandable/Expandable'

import Colors from '../../../../../../Themes/Colors'

import themedStyles from './AdditionalData.Styles'

const dateFormat = 'DD/MM/YYYY'

function AdditionalData() {
  const [styles] = useTheme(themedStyles)
  const deliveryData = useSelector(getDelivery)
  const scrollRef = useRef()

  const notes = useSelector(getNotes)

  const deliverySection = [
    {
      title: 'einvoice_delivery_delivery_header',
    },
    {
      label: 'einvoice_delivery_recipient_name',
      value: deliveryData.name,
    },
    {
      label: 'einvoice_delivery_date',
      value: deliveryData.actualDeliveryDate ? moment(deliveryData.actualDeliveryDate)?.format(dateFormat) : '',
    },
    {
      label: 'einvoice_delivery_street',
      value: deliveryData.streetName,
    },
    {
      label: 'einvoice_delivery_city',
      value: deliveryData.cityName,
    },
    {
      label: 'einvoice_delivery_postal_code',
      value: deliveryData.postalZone,
    },
    {
      label: 'einvoice_delivery_state',
      value: deliveryData.countyCode,
    },
  ]
  const referenceData = useSelector(getReferences)

  const referenceSection = [
    {
      title: 'einvoice_references_reference_header',
    },
    {
      label: 'einvoice_references_buyer',
      value: referenceData.buyerReference,
    },
    {
      label: 'einvoice_references_project',
      value: referenceData.projectReference,
    },
    {
      label: 'einvoice_references_contract',
      value: referenceData.contractReference,
    },
    {
      label: 'einvoice_references_order_form',
      value: referenceData.orderReference,
    },
    {
      label: 'einvoice_references_sales_order',
      value: referenceData.salesOrderId,
    },
    {
      title: 'einvoice_references_document_header',
    },
    {
      label: 'einvoice_references_additional_docs',
      value: referenceData.additionalDocReference,
    },
    {
      label: 'einvoice_references_docs_location',
      value: referenceData.uri,
    },
    {
      label: 'einvoice_references_docs_description',
      value: referenceData.description,
    },
    {
      label: 'einvoice_document_label',
      value: referenceData.fileName,
    },
  ]

  const initData = useSelector(getInitialData)

  const detailsData = [
    {
      title: 'payment_total_header',
    },
    {
      label: 'payment_total_method',
      value: 'Non-cash',
    },
    {
      label: 'payment_iban_label',
      value: initData?.primaryAccountId,
    },
    {
      label: 'payment_account_number_label',
      value: initData?.payeeFinantialAccount,
    },
    {
      label: 'payment_bic_label',
      value: initData?.code,
    },
    {
      label: 'payment_description_label',
      value: initData?.instructionNote,
    },
  ]

  /**
   * @todo Set the right currency!
   * @see Section (Check the comment on Section's Item Component!!)
   */

  return (
    <ScrollView
      style={styles.container}
      contentContainerStyle={styles.content}
      showsVerticalScrollIndicator={false}
      ref={scrollRef}
    >
      <Section data={deliverySection} />
      <Section data={referenceSection} />
      <Section data={detailsData} />

      <View style={styles.noteSectionContainer} >
        <Expandable scrollRef={scrollRef} containerStyle={styles.expandableContainer} title={'einvoice_notes_label'} >
          <View style={styles.separator} />
          {notes?.map(note => {
            return (
              <>
                <Note
                  key={note?.text}
                  size="body"
                  containerStyle={styles.noteContainer}
                  desc={note?.text}
                  withIcon={false}
                  withTitle={false}
                  color={Colors.primaryText}
                  weight={'regular'}
                />
                <View style={styles.separator} />
              </>
            )
          })}



        </Expandable>
      </View>
    </ScrollView>
  )
}

export default AdditionalData
