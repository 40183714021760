import { styleSheetFactory } from '../../Themes/Colors'

const styles = styleSheetFactory(colors => ({
    topSpacing: {
        marginTop: 15,
    },
    dateButton: {
        height: 50,
        width: '100%',
        marginHorizontal: 15,
        marginTop: 15,
    },
}))

export default styles


