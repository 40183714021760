import {styleSheetFactory} from '../../Themes/Colors'

const styles = styleSheetFactory((colors) => ({
  container: {
    flex: 1,
    position: 'relative',
    backgroundColor: colors.background,
  },
  header: {
    height: 56,
    alignItems: 'center',
    flexDirection: 'row',
    backgroundColor: colors.background,
  },
  headerIcon: {
    marginHorizontal: 20,
  },
  chargeButton: {
    height: 50,
    margin: 15,
  },
  listStyle: {
    marginHorizontal: 15,
    flexShrink: 1,
  },
  listContainerStyle: {
    borderRadius: 7,
  },
  separator: {
    height: 1,
    backgroundColor: colors.separator,
  },
}))

const itemStyles = styleSheetFactory((colors) => ({
  itemContainer: {
    backgroundColor: colors.tertiaryBackground,
    flexDirection: 'row',
    alignItems: 'center',
    overflow: 'hidden',
    height: 60,
    flex: 1,
  },
  fistItem_true: {
    borderTopLeftRadius: 7,
    borderTopRightRadius: 7,
  },
  lastItem_true: {
    borderBottomLeftRadius: 7,
    borderBottomRightRadius: 7,
  },
  itemTitle: {
    flexGrow: 1,
    flexShrink: 1,
    margin: 15,
  },
  checkBoxTintColors: {
    true: colors.secondaryAccent,
    false: colors.placeholder,
  },
  checkBox: {
    // flexGrow: 1,
    flexShrink: 0,
    margin: 15,
    marginRight: 6,
    marginLeft: 10,
  },
  subContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    flexShrink: 1,
  },
  textContainer: {
    flexGrow: 1,
    flexShrink: 1,
    marginLeft: 15,
  },
  firstRow: {flexDirection: 'row'},
}))

export {itemStyles}
export default styles
