/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useContext } from 'react'
import { View, TouchableOpacity, Dimensions } from 'react-native'
import { useLazyQuery, useMutation } from '@apollo/client'
import ContentLoader, { Rect } from 'react-content-loader/native'
import PropTypes from 'prop-types'
import { useTheme } from 'react-native-themed-styles'
import { useSelector } from 'react-redux'

import {
  RadioInput,
  Icon,
  SwipeList,
  Text,
  Modal,
  Loader,
  Header,
  ModalContent,
} from '../../Components'
import { getProductCatalog } from '../ProductCatalog/ProductCatalog.Selectors'

import { getErrorMessage } from '../../Utils'

import { LIST_UNITS, DELETE_UNIT } from './Units.schema'

import Theme from '../../Themes/Theme'
import { images, animations } from '../../Themes'
import themedStyles, {
  itemStyles,
  hiddenItemStyles,
  separatorStyles,
} from './Units.Styles'

const { width } = Dimensions.get('screen')
const precissionDisplay = {
  0: '1',
  1: '.0',
  2: '.00',
  3: '.000',
  4: '.0000',
  5: '.00000',
}

function Unit({ name, precision, abbreviation, onPress, isFirst, isLast }) {
  const [styles] = useTheme(itemStyles)

  return (
    <View
      style={[
        styles.container,
        styles['firstItem_' + isFirst],
        styles['lastItem_' + isLast],
      ]}>
      <TouchableOpacity onPress={onPress} style={styles.touchable}>
        <Text
          i18nKey="unit_name_abreviation"
          placeHolders={[name, abbreviation]}
          numberOfLines={2}
          style={styles.title}
        />
        <Text
          i18nKey={precissionDisplay[precision]}
          numberOfLines={1}
          style={styles.desc}
        />
      </TouchableOpacity>
    </View>
  )
}

Unit.propTypes = {
  name: PropTypes.string,
  precision: PropTypes.string,
  abbreviation: PropTypes.string,
  onPress: PropTypes.func,
  isFirst: PropTypes.bool,
  isLast: PropTypes.bool,
}

function SelectableUnit({
  id,
  name,
  precision,
  abbreviation,
  selected,
  onPress,
  isFirst,
  isLast,
}) {
  const [styles] = useTheme(itemStyles)
  const { colors } = useContext(Theme)
  return (
    <View
      style={[
        styles.container,
        styles['firstItem_' + isFirst],
        styles['lastItem_' + isLast],
      ]}>
      <TouchableOpacity onPress={onPress} style={styles.touchable}>
        <View style={styles.textContainer}>
          <Text
            i18nKey="unit_name_abreviation"
            placeHolders={[name, abbreviation]}
            numberOfLines={1}
          />
          <Text i18nKey={precissionDisplay[precision]} numberOfLines={1} />
        </View>
        <View pointerEvents="none" style={styles.radioInput}>
          <RadioInput
            index={id}
            selected={selected}
            onPress={onPress}
            color={colors.secondaryAccent}
          />
        </View>
      </TouchableOpacity>
    </View>
  )
}
SelectableUnit.defaultProps = {
  labelColor: 'white',
}
SelectableUnit.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  precision: PropTypes.string,
  abbreviation: PropTypes.string,
  onPress: PropTypes.func,
  selected: PropTypes.string,
  isFirst: PropTypes.bool,
  isLast: PropTypes.bool,
}
function UnitPlaceholder({ isFirst, isLast }) {
  const [styles] = useTheme(themedStyles)

  return (
    <TouchableOpacity
      style={[
        styles.itemContainer,
        styles['fistItem_' + isFirst],
        styles['lastItem_' + isLast],
      ]}
      disabled>
      <ContentLoader
        speed={2}
        width={width - 30}
        height={60}
        backgroundColor="#f3f3f3"
        foregroundColor="#fff">
        <Rect
          x="15"
          y="21"
          rx="6"
          ry="6"
          width={`${(width - 30) * (3 / 5)}`}
          height="18"
        />
        <Rect
          x={`${width - 128}`}
          y="21"
          rx="6"
          ry="6"
          width="50"
          height="18"
        />
        <Rect x={`${width - 63}`} y="21" rx="3" ry="3" width="18" height="18" />
      </ContentLoader>
    </TouchableOpacity>
  )
}
UnitPlaceholder.propTypes = {
  isFirst: PropTypes.bool,
  isLast: PropTypes.bool,
}
function HiddenItem({
  needsConfirmation,
  onPress,
  onConfirm,
  onCancel,
  loading,
  isFirst,
  isLast,
}) {
  const [styles] = useTheme(hiddenItemStyles)
  const { colors } = useContext(Theme)

  return (
    <View
      style={[
        styles.wrapper,
        styles['firstItem_' + isFirst],
        styles['lastItem_' + isLast],
      ]}>
      {loading ? (
        <View style={styles.loader}>
          <Loader
            size={32}
            color={colors.white}
            source={animations.vfLoaderWhite}
          />
        </View>
      ) : needsConfirmation ? (
        <TouchableOpacity style={styles.option} onPress={onPress}>
          <Icon source={images.delete} color={colors.white} size={24} />
        </TouchableOpacity>
      ) : (
        <View style={styles.optionFull}>
          <TouchableOpacity style={styles.confirm} onPress={onConfirm}>
            <Icon source={images.delete} color={colors.white} size={24} />
            <Text
              i18nKey="opened_orders_delete_confirm"
              color={colors.white}
              style={styles.confirmText}
            />
          </TouchableOpacity>
          <TouchableOpacity style={styles.cancel} onPress={onCancel}>
            <Icon source={images.closeIcon} color={colors.white} size={20} />
          </TouchableOpacity>
        </View>
      )}
    </View>
  )
}
HiddenItem.propTypes = {
  needsConfirmation: PropTypes.bool,
  onPress: PropTypes.func,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  loading: PropTypes.bool,
  isFirst: PropTypes.bool,
  isLast: PropTypes.bool,
}

function Separator() {
  const [styles] = useTheme(separatorStyles)
  return <View style={styles.container} />
}

function Units({
  route: {
    params: {
      selectable = false,
      preselectedUnit = {},
      updateSelection = () => { },
    } = {},
  } = {},
  navigation: { navigate, goBack },
}) {
  const [styles] = useTheme(themedStyles)
  const { colors } = useContext(Theme)

  const [needsConfirmation, setNeedForConfirmation] = useState(true)
  const [units, setUnits] = useState([])
  const [itemWidth, setItemWidth] = useState(0)
  const [limit] = useState(25)
  const [searchText, setSearchText] = useState('')
  const [selectedUnit, setSelectedUnit] = useState({ ...preselectedUnit })

  const { locationId, businessName, address } = useSelector(getProductCatalog)


  const [listUnits, { loading, data, refetch, fetchMore }] = useLazyQuery(
    LIST_UNITS,
    { fetchPolicy: 'network-only' }
  )

  const list = (value) => {
    listUnits({
      variables: {
        limit: limit,
        locationId: locationId ? locationId : null,
        filter: {
          name: 'name',
          value: value,
        },
      },
    })
  }

  useEffect(() => {
    list(searchText)
  }, [])

  const [isErrorActive, setErrorActive] = useState(false)
  const [deleteUnit, { loading: deleting, error: deleteError }] = useMutation(
    DELETE_UNIT,
  )

  const onSearch = (text) => {
    setSearchText(text)
    list(text)
  }

  const onEndReached = () => {
    if (!data?.listMeasurementUnits?.nextToken) {
      return
    }

    fetchMore({
      query: LIST_UNITS,
      variables: {
        limit: limit,
        locationId: locationId ? locationId : null,
        nextToken: data.listMeasurementUnits.nextToken,
        filter: {
          name: 'name',
          value: searchText,
        },
      },
    })
  }

  useEffect(() => {
    const uni = data?.listMeasurementUnits?.measurementUnits
    if (uni) {
      setUnits([...uni])
    }
  }, [data])

  const selectUnit = (item) => {
    setSelectedUnit(item)
    updateSelection({ ...item })
    goBack()
  }

  const renderSelectableItem = ({ item, index }) => (
    <SelectableUnit
      {...item}
      selected={selectedUnit?.id}
      onPress={() => selectUnit(item)}
      isFirst={index === 0}
      isLast={index === units.length - 1}
    />
  )

  const editUnit = (item) => {
    if (item) {
      navigate('NewUnit', { unit: item, onSave: () => refetch() })
    } else {
      navigate('NewUnit', { onSave: () => refetch() })
    }
  }

  const renderNonSelectableItem = ({ item, index }) => (
    <Unit
      {...item}
      onPress={() => editUnit(item)}
      isFirst={index === 0}
      isLast={index === units.length - 1}
    />
  )

  const renderItem = selectable
    ? renderSelectableItem
    : renderNonSelectableItem

  const openRow = (rowMap, id) => {
    setNeedForConfirmation(false)
    rowMap[id].manuallySwipeRow(-itemWidth)
  }

  const closeRow = (rowMap, id) => {
    rowMap[id].closeRow()
    setNeedForConfirmation(true)
  }

  const deleteRow = (rowMap, id) => {
    deleteUnit({
      variables: {
        id: id,
        locationId: locationId ? locationId : null,
      },
    })
      .then(() => {
        // displayToast('unit_delete_success')
        closeRow(rowMap, id)
        refetch()
      })
      .catch(() => {
        setErrorActive(true)
        closeRow(rowMap, id)
      })
  }

  const renderHiddenItem = ({ item, index }, rowMap) => (
    <HiddenItem
      needsConfirmation={needsConfirmation}
      onPress={() => openRow(rowMap, item.id)}
      onConfirm={() => deleteRow(rowMap, item.id)}
      onCancel={() => closeRow(rowMap, item.id)}
      loading={deleting}
      isFirst={index === 0}
      isLast={index === units.length - 1}
    />
  )

  return (
    <>
      <Header
        image={images.arrow_left}
        title="unit_screen_name"
        onPress={goBack}
        customBusinessName={businessName}
        customAddress={address}
      />
      <SwipeList
        searchValue={searchText}
        searchPlaceholder="unit_search_placeholder"
        onSearch={onSearch}
        data={units}
        renderItem={renderItem}
        renderHiddenItem={!selectable ? renderHiddenItem : undefined}
        disableLeftSwipe={selectable}
        disableRightSwipe
        rightOpenValue={-100}
        onRowClose={() => setNeedForConfirmation(true)}
        setItemWidth={setItemWidth}
        onEndReached={() => onEndReached()}
        ItemSeparatorComponent={Separator}
        refreshing={loading}
        onRefresh={() => refetch()}
      />
      <Modal
        isVisible={isErrorActive}
        onBackButtonPress={() => setErrorActive(false)}
        onBackdropPress={() => setErrorActive(false)}
        animationIn="fadeIn"
        animationOut="fadeOut">
        <ModalContent onClose={() => setErrorActive(false)}>
          <Icon source={images.delete} style={styles.errorIcon} />
          <Text
            i18nKey="ERROR"
            color={colors.accent}
            size="h5"
            align="center"
            weight="bold"
            style={styles.errorTitle}
          />
          <Text
            i18nKey={getErrorMessage(deleteError)}
            align="center"
          />
        </ModalContent>
      </Modal>
    </>
  )
}

Units.propTypes = {
  route: PropTypes.object,
  navigation: PropTypes.object,
}

export default Units
