import { gql } from '@apollo/client'

export const CREATE_BANK_ACCOUNT = gql`
mutation createBankAccount($bankName: String!, $holderName: String!, $country: String!, $city: String!, $iban: String!, $swiftCode: String!, $currency: String!) {
    createBankAccount(
      createBankAccountInput: {
        bankName: $bankName,
        holderName: $holderName,
        country: $country,
        iban: $iban,
        city: $city,
        swiftCode: $swiftCode,
        currency: $currency,
      }
  ) {
        id
      
  },
}
`

export const UPDATE_BANK_ACCOUNT = gql`
mutation updateBankAccount($id: String!, $bankName: String!, $holderName: String!, $country: String!, $city: String!, $iban: String!, $swiftCode: String!, $currency: String!) {
    updateBankAccount(
      updateBankAccountInput: {
        id: $id,
        bankName: $bankName,
        holderName: $holderName,
        country: $country,
        iban: $iban,
        city: $city,
        swiftCode: $swiftCode,
        currency: $currency,

      }
  ) {
        id
      
  },
}
`

export const GET_BANK_ACCOUNT = gql`
query getBankAccount($id: String!) {
  getBankAccount(id: $id){
        city,
        country,
        iban,
        swiftCode,
        holderName,
        bankName,
        currency,
  }
}
`
