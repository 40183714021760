import React, { useState, useRef } from 'react'
import { View, LayoutAnimation, Platform } from 'react-native'
import PropTypes from 'prop-types'
import { useTheme } from 'react-native-themed-styles'
import PagerView from 'react-native-pager-view'

import themedStyles from './UploadItems.Styles'
import { layoutAnimConfig } from '../../Themes/Theme'

import FirstUploadStep from './FirstStep'
import FirstCatalogStep from './FirstCatalogStep'
import SecondStep from './SecondStep'

const isWeb = Platform.OS === 'web'
function UploadItems({
    navigation: { goBack, pop },
    route: {
        params: {
            selectedType = 'items',
            catalogUpload = true,
        } = {},
    } = {} }) {

    const FirstStepMatrix = {
        true: FirstUploadStep,
        false: FirstCatalogStep,
    }
    const FirstStep = FirstStepMatrix[catalogUpload]


    const [styles] = useTheme(themedStyles)
    const viewpagerRef = useRef(0)
    const [selectedBulk, setSelectedBulk] = useState(selectedType)
    const [currentStep, setCurrentStep] = useState(1)
    const SETUP_PAGES = [1, 2, 3]
    const [keepDuplicates, setKeepDuplicates] = useState(true)
    const [locationAddress, setLocationAddress] = useState({})

    const next = () => {
        if (isWeb) {
            return setCurrentStep((page) => page + 1)
        } else {
            LayoutAnimation.configureNext(layoutAnimConfig)
            setCurrentStep((prev) => {
                viewpagerRef.current.setPage(prev + 1)
                return prev + 1
            })
        }

    }

    const previous = () => {
        if (isWeb) {
            return setCurrentStep((page) => page - 1)
        } else {
            LayoutAnimation.configureNext(layoutAnimConfig)
            setCurrentStep((prev) => {
                viewpagerRef.current.setPage(prev - 1)
                return prev - 1
            })
        }
    }
    return (
        <View style={styles.container}>
            <PagerView
                requestAnimationFrame={() => viewpagerRef.current?.setPage(currentStep)}
                ref={(viewpager) => {
                    viewpagerRef.current = viewpager
                }}
                style={styles.viewPager}
                initialPage={currentStep}
                onPageSelected={(e) => setCurrentStep(e.nativeEvent.position)}
                scrollEnabled={false}
            >
                <View key="0" />
                <View collapsable={false} key="1">
                    <FirstStep
                        goBack={goBack}
                        next={next}
                        selectedBulk={selectedBulk}
                        setSelectedBulk={setSelectedBulk}
                        keepDuplicates={keepDuplicates}
                        setKeepDuplicates={setKeepDuplicates}
                        setLocationAddress={setLocationAddress}
                    />
                </View>
                <View collapsable={false} key="2">
                    <SecondStep
                        goBack={goBack}
                        pop={pop}
                        previous={previous}
                        selectedBulk={selectedBulk}
                        keepDuplicates={keepDuplicates}
                        locationAddress={locationAddress}
                        currentStep={currentStep}

                    />
                </View>
            </PagerView>
            <View style={styles.indicatorContainer}>
                {SETUP_PAGES.map((item, index) => (
                    <View
                        key={'indicator' + index}
                        style={index === 0 ? styles.firstIndicator : styles['indicator_' + (currentStep === index)]}
                    />
                ))}
            </View>
        </View>
    )
}

UploadItems.propTypes = {
    navigation: PropTypes.object,
    route: PropTypes.object,
}



export default UploadItems
