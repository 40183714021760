import { BluetoothEscposPrinter } from 'react-native-bluetooth-escpos-printer'
import moment from 'moment'

const printPayInOut = async ({
    eventData,
    company,
    location,
    nipt,
    translate,
}) => {
    const printerTranslate = (val) => {
        return translate(val, true)
    }

    const amount = eventData?.eventMoney

    var chars = { 'ë': 'e', 'ç': 'c', 'Ç': 'C', 'Ë': 'E' }
    const currency = amount?.currency.replace(/[ëçÇË]/g, m => chars[m])
    const amountMoney = eventData?.eventType === 'PAY_OUT' ? `-${amount?.amount}` : amount?.amount
    const price = `${amountMoney} ${currency}`

    try {
        await BluetoothEscposPrinter.printerInit()
        await BluetoothEscposPrinter.printerAlign(
            BluetoothEscposPrinter.ALIGN.CENTER,
        )
        await BluetoothEscposPrinter.setBlob(0)
        await BluetoothEscposPrinter.printText(`${company}\n`, {
            fonttype: 1,
            widthtimes: 1,
            heigthtimes: 1,
        })
        await BluetoothEscposPrinter.printerAlign(
            BluetoothEscposPrinter.ALIGN.CENTER,
        )
        await BluetoothEscposPrinter.printText(`${location}\n`, {})
        await BluetoothEscposPrinter.printerAlign(
            BluetoothEscposPrinter.ALIGN.CENTER,
        )
        await BluetoothEscposPrinter.printText(`${printerTranslate('receipt_nipt_label')}: ${nipt}\n\n`, {})
        await BluetoothEscposPrinter.printerAlign(
            BluetoothEscposPrinter.ALIGN.CENTER,
        )
        await BluetoothEscposPrinter.printText(`${printerTranslate('pay_in_out_title')}\n`, {
            fonttype: 2,
            widthtimes: 1,
            heigthtimes: 1,
        })
        await BluetoothEscposPrinter.printText(
            '--------------------------------\r\n',
            {},
        )
        await BluetoothEscposPrinter.printColumn(
            [21, 11],
            [BluetoothEscposPrinter.ALIGN.LEFT, BluetoothEscposPrinter.ALIGN.RIGHT],
            [`${printerTranslate('pay_in_out_description')}`, `${printerTranslate('pay_in_out_sum')}`],
            {},
        )
        await BluetoothEscposPrinter.printText(
            '\r\n',
            {},
        )
        await BluetoothEscposPrinter.printColumn(
            [17, 15],
            [BluetoothEscposPrinter.ALIGN.LEFT, BluetoothEscposPrinter.ALIGN.RIGHT],
            [`${eventData?.description?.replace(/[ëçÇË]/g, m => chars[m])}`, `${amountMoney}`],
            {},
        )
        await BluetoothEscposPrinter.printText(
            '--------------------------------\r\n',
            {},
        )
        const c = price.length + 2
        await BluetoothEscposPrinter.printColumn(
            [32 - c, c],
            [BluetoothEscposPrinter.ALIGN.LEFT, BluetoothEscposPrinter.ALIGN.RIGHT],
            [`${printerTranslate('receipt_total_label')}`, price],
            {},
        )

        await BluetoothEscposPrinter.printText(
            '--------------------------------\r\n',
            {},
        )
        await BluetoothEscposPrinter.printerAlign(
            BluetoothEscposPrinter.ALIGN.LEFT,
        )
        await BluetoothEscposPrinter.printText(`${printerTranslate('pay_in_out_number')}: ${eventData?.statementNumber || ' '}\r\n`, {
            codepage: 0,
            widthtimes: 0,
            heigthtimes: 0,
            fonttype: 5,
        })
        await BluetoothEscposPrinter.printText(`${printerTranslate('receipt_refund_reason')}: ${eventData?.reason?.replace(/[ëçÇË]/g, m => chars[m])}\r\n`, {
            codepage: 0,
            widthtimes: 0,
            heigthtimes: 0,
            fonttype: 5,
        })
        await BluetoothEscposPrinter.printText(`${printerTranslate('receipt_seller_label')}: ${eventData?.employee?.firstName || ' '}\r\n`, {
            codepage: 0,
            widthtimes: 0,
            heigthtimes: 0,
            fonttype: 5,
        })
        await BluetoothEscposPrinter.printText(
            moment(eventData?.createdAt).format(`[${printerTranslate('receipt_date_time_label')}: ]DD-MM-YY HH:mm`) + '\r\n',
            {
                codepage: 0,
                widthtimes: 0,
                heigthtimes: 0,
                fonttype: 7,
            },
        )
        await BluetoothEscposPrinter.printText(
            `${printerTranslate('receipt_operator_code')}: ${eventData?.employee?.operatorCode}\r\n`,
            {
                codepage: 0,
                widthtimes: 0,
                heigthtimes: 0,
                fonttype: 5,
            },
        )
        await BluetoothEscposPrinter.printText(
            `${printerTranslate('receipt_software_code_label')}: ${eventData?.softCode}\r\n`,
            {
                codepage: 0,
                widthtimes: 0,
                heigthtimes: 0,
                fonttype: 5,
            },
        )
        await BluetoothEscposPrinter.printText(
            `${printerTranslate('pay_in_out_buscode_label')}: ${eventData?.businUnitCode}\r\n`,
            {
                codepage: 0,
                widthtimes: 0,
                heigthtimes: 0,
                fonttype: 5,
            },
        )
        await BluetoothEscposPrinter.printText(`${printerTranslate('pay_in_out_id')}: ${eventData?.FCDC || ' '}\n\n`, {
            codepage: 0,
            widthtimes: 0,
            heigthtimes: 0,
            fonttype: 5,
        })
        await BluetoothEscposPrinter.printerAlign(
            BluetoothEscposPrinter.ALIGN.CENTER,
        )

        await BluetoothEscposPrinter.setBlob(1)
        await BluetoothEscposPrinter.printText(`${printerTranslate('receipt_generated_by')} Vodafone eBiznes\n\n\n`, {
            codepage: 0,
            widthtimes: 0,
            heigthtimes: 0,
            fonttype: 5,
        })

        await BluetoothEscposPrinter.printText('\n\n\n', {})
        return
    } catch (e) {
        throw e
    }
}

export default printPayInOut
