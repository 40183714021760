import { styleSheetFactory } from '../../Themes/Colors'

const styles = styleSheetFactory(colors => ({
  input: {
    height: 58,
  },
  inputContainer: (isFocused = false, error = false) => {
    return {
      backgroundColor: 'white',
      borderRadius: 7,
      borderTopLeftRadius: 7,
      borderTopRightRadius: 7,
      borderWidth: 1,
      borderColor: isFocused ? colors.secondaryAccent : error ? colors.accent : colors.separator,
    }
  },
  label: {
    fontSize: 18,
    fontFamily: 'Vodafone Lt',
  },
  title: {
    fontSize: 16,
    fontFamily: 'Vodafone Rg',
  },
  prefix: {
    marginRight: 0,
  },
  prefixIos: {
    marginRight: 0,
    marginBottom: 0,
  },
  affix: {
    fontSize: 16,
    fontFamily: 'Vodafone Rg',
    marginBottom: 6,
  },
  inputStyle: {
    fontFamily: 'Vodafone Rg',
  },
}))

const suggestionStyles = styleSheetFactory(colors => ({
  containerSelected_false: {
    marginHorizontal: 5,
    borderWidth: 1,
    borderColor: colors.disabled,
    borderRadius: 7,
    paddingHorizontal: 8,
    paddingVertical: 4,
    marginBottom: 16,
    minWidth: 60,
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
  },
  containerSelected_true: {
    marginHorizontal: 5,
    backgroundColor: colors.secondaryAccent,
    borderWidth: 0,
    borderColor: colors.transparent,
    borderRadius: 7,
    paddingHorizontal: 9,
    paddingVertical: 5,
    marginBottom: 16,
    minWidth: 60,
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
  },
  textSelected_false: {
    color: colors.secondaryText,
  },
  textSelected_true: {
    color: colors.white,
  },
}))

export {
  suggestionStyles,
}
export default styles
