import { styleSheetFactory } from '../../../Themes/Colors'

const styles = styleSheetFactory(colors => ({
  container: {
    flexGrow: 1,
    backgroundColor: colors.background,
  },
  list: {
    flex: 1,
    marginTop: 15,
  },
  inlineButton1: {
    flex: 1,
    height: 50,
    marginRight: 7.5,
    backgroundColor: colors.white,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 7,
    flexDirection: 'row',
  },
  inlineButton2: {
    flex: 1,
    height: 50,
    marginLeft: 7.5,
    backgroundColor: colors.white,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 7,
    flexDirection: 'row',
  },
  buttonIcon: {
    marginRight: 10,
  },
  inlineButtons: {
    flexDirection: 'row',
    marginVertical: 10,
    alignItems: 'center',
    paddingHorizontal: 15,
  },
  inlineButtonsFooter: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 15,
    marginVertical: 15,
  },
  loaderContainer: {
    backgroundColor: colors.white,
    borderBottomColor: colors.separator,
    borderBottomWidth: 1,
    height: 60,
  },
  listRightComponent: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  statusIcon: {
    marginRight: 5,
  },
  separatorStyle: {
    height: 1,
    flex: 1,
    backgroundColor: colors.lightGrey,
  },

  tabView: {
    backgroundColor: colors.background,
    marginTop: 15,
    paddingTop: 0,
  },
  refundButton:{
    height: 50,
    marginHorizontal: 15,
    marginTop:10,
    marginBottom:20,
  },

}))

export default styles
