import React, { useState, useContext } from 'react'
import {
  View,
  Image,
  TouchableOpacity,
  RefreshControl,
} from 'react-native'
import { SwipeListView, SwipeRow } from 'react-native-swipe-list-view'
import PropTypes from 'prop-types'
import { useTheme } from 'react-native-themed-styles'

import ListElement from '../ListElement'
import Text from '../Text'
import ItemsListLoader from '../ItemListLoader'
import Icon from '../Icon'

import Theme from '../../Themes/Theme'
import themedStyles from './ItemsList.styles'
import Images from '../../Themes/Images'

function ItemsList(props) {
  const [styles] = useTheme(themedStyles)
  const { colors } = useContext(Theme)

  const ITEM_HEIGHT = 70
  const {
    items = [],
    onDelete,
    listFooterComponent,
    listHeaderComponent,
    swipeRight = false,
    useDivider = true,
    onRefresh,
    loading,
    onEndReached,
    onPress,
    hiddenStyle,
    confirmStyle,
    containerStyle,
    useSectionList,
    confirmActionProps,
  } = props

  let { swipeLeft = true } = props

  const [rightOpenValueFull, setRightOpenValueFull] = useState(0)
  const [confirmDeleteButton, setConfirmDeleteButton] = useState(false)

  const separatorComponent = () => {
    return <View style={styles.separatorStyle} />
  }

  const renderItem = (rowData, rowMap) => {
    const style = {}
    if (rowData.index === 0) {
      style.borderTopLeftRadius = 7
      style.borderTopRightRadius = 7
    }
    if (rowData.index === items.length - 1) {
      style.borderBottomLeftRadius = 7
      style.borderBottomRightRadius = 7
    }
    return (
      <ListElement
        key={rowData.item.id}
        id={rowData.item.id}
        useDivider={useDivider}
        title={rowData.item.title || rowData.item.name}
        description={rowData.item.description}
        color={rowData.item.color}
        image={rowData.item.image}
        iconSize={60}
        rightLabel={rowData.item.rightLabel}
        rightComponent={(rowData.item.rightComponent) ? rowData.item.rightComponent : rightArrow}
        onPress={rowData.item.onPress ? rowData.item.onPress : () => onPress(rowData.item)}
        listItemStyle={props.listItemStyle}
        leftElement={rowData.item.leftElement}
        style={style}
        menu={props.menu}
      />
    )
  }

  const openConfirmButton = (rowData, rowMap) => {
    setConfirmDeleteButton(true)
    rowMap[rowData.item.id].manuallySwipeRow(rightOpenValueFull)
  }
  const measureView = (event) => {
    setRightOpenValueFull(-event.nativeEvent.layout.width)
  }
  const resetDeleteButton = () => {
    setConfirmDeleteButton(false)
  }
  const onDeleteElement = (rowData, rowMap) => {
    onDelete(rowData.item, rowData.index)
    revertDelete(rowData, rowMap)
  }

  const revertDelete = (rowData, rowMap) => {
    rowMap[rowData.item.id].manuallySwipeRow(0)
    setConfirmDeleteButton(false)
    swipeLeft = false
  }

  const rightArrow = (
    <Image
      source={Images.arrow_right}
      style={styles.rightArrow} />
  )

  const renderSectionHeader = sectionTitle => {
    if (sectionTitle) {
      return (
        <Text size="footnote" style={styles.sectionTitle} i18nKey={sectionTitle} />
      )
    }
    return null
  }

  if (loading && !useSectionList && items.length === 0) {
    return <ItemsListLoader />
  }


  if (items.length === 0) {
    return null
  }

  if (useSectionList) {
    return (
      <SwipeListView
        onLayout={(event) => measureView(event)}
        useSectionList={true}
        useAnimateList={true}
        useNativeDriver
        contentContainerStyle={[styles.contentContainer, containerStyle]}
        ItemSeparatorComponent={useDivider && separatorComponent}

        keyExtractor={(item, index) => item.id}
        getItemLayout={(data, index) => ({
          length: ITEM_HEIGHT,
          offset: ITEM_HEIGHT * index,
          index,
        })}
        sections={items}
        closeOnRowBeginSwipe={true}
        ListFooterComponent={listFooterComponent}
        ListHeaderComponent={listHeaderComponent}
        style={styles.container}
        showsVerticalScrollIndicator={false}
        renderSectionHeader={({ section: { sectionTitle } }) =>
          renderSectionHeader(sectionTitle)
        }
        renderItem={(rowData, rowMap) => {

          const data = rowData.item
          const itemStyle = {}
          if (rowData.index === 0) {
            itemStyle.borderTopLeftRadius = 7
            itemStyle.borderTopRightRadius = 7
          }
          if (rowData.index === rowData?.section?.data?.length - 1) {
            itemStyle.borderBottomLeftRadius = 7
            itemStyle.borderBottomRightRadius = 7
          }

          if (data.renderItem) {
            return data.renderItem
          }

          return <SwipeRow
            disableLeftSwipe={data.disableLeftSwipe}
            disableRightSwipe={!data.disableLeftSwipe}
            rightOpenValue={-data?.hidden?.length * 100}
            recalculateHiddenLayout={false}
            useNativeDriver={true}
            disableHiddenLayoutCalculation={true}
          >
            <View style={styles.itemWrapper} >
              {!confirmDeleteButton ? <View style={[styles.hiddenItem, itemStyle, hiddenStyle]}>
                {data?.hidden?.map((el, index) => (
                  <>
                    {index > 0 && <View style={[styles.divider, { right: index * 100 }]} />}
                    <TouchableOpacity
                      onPress={() => {
                        if (el.onPressStep === 0) {
                          el.onPress()
                        } else if (el.onPressStep === 1) {
                          // confirmAction(rowData, rowMap, el)
                        } else {
                          openConfirmButton(rowData, rowMap, el)
                        }
                      }}
                      style={[
                        styles.hiddenElement,
                        el.style,
                        {
                          right: index * 100,

                        },
                      ]}>
                      <View style={styles.iconText}>
                        <Icon source={el.icon} size={25} color={colors.tertiaryIcon} />
                        {el.withTitle && <Text i18nKey={el.hiddenText} color="white" style={styles.hiddenTitle} />}
                      </View>
                    </TouchableOpacity>
                  </>
                ))}
              </View> : <View style={[styles.confirmHidden, itemStyle, confirmStyle]}>
                <TouchableOpacity
                  style={styles.confirmHiddenContainer}
                  onPress={confirmActionProps.onPress}
                  disabled={!confirmActionProps.onPress}
                >
                  <View style={[
                    styles.hiddenContent,
                    styles.confirmDelete,
                  ]}>
                    <Icon source={confirmActionProps.icon} size={25} color={colors.tertiaryIcon} />
                  </View>
                  <View style={[
                    styles.hiddenContent,
                    styles.hiddenContainerSpace,
                  ]}>
                    <Text i18nKey={confirmActionProps.message} style={styles.confirmationText} />
                  </View>
                  {confirmActionProps.cancellable && <View style={[
                    styles.hiddenContent,
                    // eslint-disable-next-line react-native/no-inline-styles
                    {
                      borderRadius: 30,
                      backgroundColor: 'rgba(255, 255, 255, 0.4)',
                      width: ITEM_HEIGHT - 30,
                      height: ITEM_HEIGHT - 30,
                      alignSelf: 'center',
                      marginHorizontal: 15,
                    },
                  ]}>
                    <TouchableOpacity
                      onPress={() => revertDelete(rowData, rowMap)}>
                      <Icon source={Images.icon_close_white} size={20} />
                    </TouchableOpacity>
                  </View>}
                </TouchableOpacity>
              </View>
              }
            </View>
            <ListElement
              key={data.id}
              id={data.id}
              title={data.title}
              description={data.description}
              numOfLines={1}
              color={data.color}
              image={data.image}
              rightLabel={data.rightLabel}
              leftComponent={data.leftComponent}
              rightComponent={data.rightComponent}
              checkboxComponent={data.checkboxComponent}
              subDescWithIcon={data.subDescWithIcon}
              subDescWithIconText={data.subDescWithIconText}
              subDescriptionStyle={data.subDescriptionStyle}
              subDescText={data.subDescText}
              descriptionStyle={data.descriptionStyle}
              titleStyle={data.titleStyle}
              onPress={data.onPress || onPress}
              onLongPress={data.onLongPress}
              listItemStyle={props.listItemStyle}
              rightTextStyle={data.rightTextStyle}
              leftElement={data.leftElement}
              style={itemStyle}
            />
          </SwipeRow>
        }}

        onEndReachedThreshold={0.4}
        onEndReached={onEndReached}


        refreshControl={
          onRefresh && <RefreshControl
            refreshing={props.loading}
            onRefresh={onRefresh}
          />
        }
        recalculateHiddenLayout={true}
        directionalDistanceChangeThreshold={0}
        disableRightSwipe={!swipeRight}
        disableLeftSwipe={!swipeLeft}
        onRowClose={resetDeleteButton}
      />
    )
  }

  return (
    <SwipeListView
      onLayout={(event) => measureView(event)}
      useFlatList={true}
      contentContainerStyle={styles.contentContainer}
      ItemSeparatorComponent={useDivider && separatorComponent}
      keyExtractor={(item, index) => item.id}
      getItemLayout={(data, index) => ({
        length: ITEM_HEIGHT,
        offset: ITEM_HEIGHT * index,
        index,
      })}
      data={items}
      showsVerticalScrollIndicator={false}
      ListFooterComponent={listFooterComponent}
      renderItem={renderItem}
      onEndReachedThreshold={0.4}
      onEndReached={onEndReached}
      renderHiddenItem={(rowData, rowMap) => {
        const hiddennStyle = {}
        if (rowData.index === 0) {
          hiddennStyle.borderTopLeftRadius = 8
          hiddennStyle.borderTopRightRadius = 8
        }
        if (rowData.index === items.length - 1) {
          hiddennStyle.borderBottomLeftRadius = 8
          hiddennStyle.borderBottomRightRadius = 8
        }
        return swipeLeft && (
          <View style={styles.flatItemContainer}>
            {!confirmDeleteButton ? <View style={[styles.rowBack, hiddennStyle]}>
                <TouchableOpacity
                  onPress={() => openConfirmButton(rowData, rowMap)}
                  style={[
                    styles.hiddenOption,
                    styles.delete,
                    rowData.item.style,
                    hiddennStyle,
                  ]}>
                  <Image source={Images.bin_light} style={styles.itemIcon} />
                </TouchableOpacity>
              </View> : <View style={styles.flatConfirmItem}>
                <TouchableOpacity
                  style={styles.confirmHiddenContainer}
                  onPress={() => onDeleteElement(rowData, rowMap)}>
                  <View style={[
                    styles.hiddenOption,
                    styles.delete,
                    styles.hiddenImage,
                    hiddennStyle,
                  ]}>
                    <Image source={Images.bin_light} style={styles.itemIcon} />
                  </View>
                  <View style={[
                    styles.hiddenOption,
                    styles.delete,
                    hiddenStyle,
                    styles.hiddenContainerSpace,
                  ]}>
                    <Text i18nKey="Confirm Delete" style={styles.confirmationText} />
                  </View>
                  <View style={[
                    styles.hiddenOption,
                    // eslint-disable-next-line react-native/no-inline-styles
                    {
                      borderRadius: 3000,
                      backgroundColor: 'rgba(255, 255, 255, 0.4)',
                      width: ITEM_HEIGHT - 30,
                      height: ITEM_HEIGHT - 30,
                      alignSelf: 'center',
                      marginHorizontal: 15,
                    },
                  ]}>
                    <TouchableOpacity
                      onPress={() => revertDelete(rowData, rowMap)}>
                      <Image source={Images.icon_close_white} style={styles.itemIcon} />
                    </TouchableOpacity>
                  </View>
                </TouchableOpacity>
              </View>
            }
          </View>
        )
      }
      }
      refreshControl={
        onRefresh && <RefreshControl
          refreshing={props.loading}
          onRefresh={onRefresh}
        />
      }
      leftOpenValue={0}
      rightOpenValue={-100}
      recalculateHiddenLayout={true}
      // directionalDistanceChangeThreshold={0}
      onScroll={(rowKey, rowMap) => {
        //(rowKey, rowMap)
      }}
      disableRightSwipe={!swipeRight}
      disableLeftSwipe={!swipeLeft}
      onRowClose={resetDeleteButton}
    />
  )
}

ItemsList.defaultPropTypes = {
  menu: false,
}

ItemsList.propTypes = {
  items: PropTypes.array,
  children: PropTypes.any,
  menu: PropTypes.bool,
  listItemStyle: PropTypes.object,
  onDelete: PropTypes.func,
  listFooterComponent: PropTypes.node,
  listHeaderComponent: PropTypes.node,
  swipeLeft: PropTypes.bool,
  swipeRight: PropTypes.bool,
  useDivider: PropTypes.bool,
  onRefresh: PropTypes.func,
  loading: PropTypes.bool,
  onEndReached: PropTypes.func,
  onPress: PropTypes.func,
  rightOpenValue: PropTypes.number,
  hiddenStyle: PropTypes.object,
  confirmStyle: PropTypes.object,
  containerStyle: PropTypes.object,
  useSectionList: PropTypes.bool,
  confirmActionProps: PropTypes.object,
}

export default ItemsList
