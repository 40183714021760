/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState, useMemo } from 'react'
import RNCheckBox from '@react-native-community/checkbox'
import { Animated, Easing, View, Platform } from 'react-native'
import PropTypes from 'prop-types'

import { useTheme } from 'react-native-themed-styles'

import Theme from '../../Themes/Theme'

import themedStyles from './CheckBox.Styles'

function CheckBox(props) {

  const [styles] = useTheme(themedStyles)
  const { colors } = useContext(Theme)

  const {
    checked,
    onValueChange,
    tintColors,
    isDisabled,
    disabledTintColors,
    style,
  } = props

  const [anim] = useState(new Animated.Value(0))

  if (checked) {
    Animated.timing(anim, {
      toValue: 1,
      duration: 300,
      useNativeDriver: true,
      easing: Easing.linear,
    }).start()
  } else {
    Animated.timing(anim, {
      toValue: 0,
      duration: 300,
      useNativeDriver: true,
      easing: Easing.linear,
    }).start()
  }

  const checkColors = useMemo(
    () => Object.assign({}, styles['defaultTintColorsDisabled_' + Boolean(isDisabled)], isDisabled ? disabledTintColors : tintColors),
    [isDisabled, tintColors, disabledTintColors]
  )

  if (Platform.OS === 'web') {
    return <input type="checkbox"
      checked={checked}
      disabled={isDisabled}
      onChange={() => onValueChange(!checked)}
      // eslint-disable-next-line react-native/no-inline-styles
      style={{ ...style, width: 20, height: 20 }}
    />
  }

  return (
    <View style={[styles.container, style]}>
      <Animated.View style={[
        styles.background,
        styles['selected' + checked],
        { transform: [{ scale: anim }] },
      ]} />
      <RNCheckBox
        value={checked}
        disabled={isDisabled}
        tintColors={checkColors}
        onValueChange={() => onValueChange(!checked)}
        boxType="square"
        tintColor={checkColors.false}
        onCheckColor={colors.white}
        onFillColor={checkColors.true}
        onTintColor={checkColors.true}
        onAnimationType="bounce"
        offAnimationType="bounce"
        style={Platform.select({
          ios: {
            width: 18,
            height: 18,
            margin: 8,
          },
        })}
      />
    </View>
  )
}

CheckBox.defaultProps = {
  checked: false,
  isDisabled: false,
}
CheckBox.propTypes = {
  checked: PropTypes.bool,
  onValueChange: PropTypes.func,
  isDisabled: PropTypes.bool,
  style: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
}

export default CheckBox
