import { gql } from '@apollo/client'

export const LIST_ONLINE_ORDERS = gql`
  query listOrder($locationId: String!, $nextToken: String, $limit: Int, $beginTime:String, $endTime:String,$name:String,$value:String,$status:OrderStatusInput, $cashRegisterId: String){
    listOrders(
      listOrdersInput:{
      locationId: $locationId
      status: $status
      nextToken: $nextToken
      limit: $limit
      beginTime:$beginTime
      endTime:$endTime
      filter:{
          name:$name
          value:$value
      }
    }
    requestedLocationAndCashRegister: {
      locationId: $locationId,
      cashRegisterId: $cashRegisterId
    }
    ){
      orders{
        id
        receiptNumber
        fulfillmentType
        totalMoney{
          amount
          currency
        }
        createdAt
        status
      }
      nextToken
    }
  }
`
