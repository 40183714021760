import React from 'react'
import { ScrollView } from 'react-native'
import { useTheme } from 'react-native-themed-styles'
import PropTypes from 'prop-types'

import { Section } from '../../../Components'

import themedStyles from '../RefundDetails.Styles'

function PaymentDetails({ eInvoiceData }) {
  const [styles] = useTheme(themedStyles)
  const detailsData = [
    {
      title: 'payment_total_header',
    },
    {
      label: 'payment_total_method',
      value: 'Non-cash',
    },
    {
      label: 'payment_iban_label',
      value: eInvoiceData?.primaryAccountId,
    },
    {
      label: 'payment_account_number_label',
      value: eInvoiceData?.payeeFinantialAccount,
    },
    {
      label: 'payment_bic_label',
      value: eInvoiceData?.code,
    },
    {
      label: 'payment_description_label',
      value: eInvoiceData?.instructionNote,
    },
  ]

  /**
   * @todo Set the right currency!
   * @see Section (Check the comment on Section's Item Component!!)
   */
  return (
    <ScrollView
      style={styles.container}
      contentContainerStyle={styles.content}
      showsVerticalScrollIndicator={false}
    >
      <Section data={detailsData} />
    </ScrollView>
  )
}

PaymentDetails.propTypes = {
    eInvoiceData: PropTypes.object,
  }

export default PaymentDetails
