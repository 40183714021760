import { styleSheetFactory } from '../../../../../../Themes/Colors'

const styles = styleSheetFactory(colors => ({
  container: {
    flex: 1,
  },
  content: {
    position: 'relative',
    flexGrow: 1,
    paddingHorizontal: 16,
    paddingVertical: 24,
  },
  separatorContainer: {
    height: 1,
    backgroundColor: colors.tertiaryBackground,
    paddingLeft: 70,
  },
  separator: {
    height: 1,
    backgroundColor: colors.separator,
  },
  fab: {
    position: 'absolute',
    bottom: 225,
    right: 15,
    height: 50,
    width: 50,
    borderRadius: 25,
    backgroundColor: colors.tertiaryAccent,
    justifyContent: 'center',
    alignItems: 'center',
    shadowColor: colors.black,
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.22,
    shadowRadius: 2.22,
    elevation: 3,
  },
  fabIcon: {
    transform: [{ rotate: '-90deg' }],
  },
}))

const itemStyles = styleSheetFactory(colors => ({
  itemContainer: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    backgroundColor: colors.tertiaryBackground,
    minHeight: 70,
    overflow: 'hidden',
  },
  listImageContainer: {
    width: 70,
    height: 70,
    flexShrink: 0,
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'flex-start',
  },
  listInfoContainer: {
    flexGrow: 1,
    flexShrink: 1,
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    flexDirection: 'row',
    paddingRight: 15,
  },
  listPriceContainer: {
    flexShrink: 0,
    marginTop: 5,
  },
  listFirst: {
    borderTopLeftRadius: 7,
    borderTopRightRadius: 7,
  },
  listLast: {
    borderBottomLeftRadius: 7,
    borderBottomRightRadius: 7,
  },
  listDescContainer: {
    flexGrow: 1,
    flexShrink: 1,
    paddingLeft: 10,
    marginVertical: 5,
  },
}))

export { itemStyles }
export default styles
