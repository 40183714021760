import { gql } from '@apollo/client'

//example on how to pass parameters
export const CREATE_GROUP = gql`
  mutation createCustomerGroup($name: String!, $labelColor: String, $type: CustomerType!){
    createCustomerGroup(customerGroupInput:{
      name: $name
      labelColor: $labelColor
      type: $type
    }){
      id,
      name,
      labelColor
    }
  }
`
export const CREATE_SUPPLIER_GROUP = gql`
  mutation createSupplierGroup($name: String!, $labelColor: String, $type: SupplierType!){
    createSupplierGroup(supplierGroupInput:{
      name: $name
      labelColor: $labelColor
      type: $type
    }){
      id,
      name,
      labelColor
    }
  }
`

export const EDIT_GROUP = gql`
  mutation updateCustomerGroup($id: String!, $name: String, $labelColor: String){
    updateCustomerGroup(customerGroupUpdateInput:{
      id: $id,
      name: $name
      labelColor: $labelColor
    }){
      id
      name
      labelColor
    }
  }
`

export const EDIT_SUPPLIER_GROUP = gql`
  mutation updateSupplierGroup($id: String!, $name: String, $labelColor: String){
    updateSupplierGroup(supplierGroupUpdateInput:{
      id: $id,
      name: $name
      labelColor: $labelColor
    }){
      id
      name
      labelColor
    }
  }
`

export const GET_GROUP = gql`
  query getCustomerGroup($id: String!, $filter: Filter, $limit: Int, $nextToken: String) {
    getCustomerGroup(getCustomerGroupInput:{ 
      id: $id, 
      listCustomersInput: {
        filter: $filter,
        limit: $limit,
        nextToken: $nextToken
      }}) {
        customerGroup{
        id,
        name,
        labelColor
       }
       listCustomers {
         customers {
          id
          givenName
          familyName
          businessName
          phoneNumber
         }
         nextToken
       }
    }
  }
`

export const GET_SUPPLIER_GROUP = gql`
  query getSupplierGroup($id: String!, $filter: Filter, $limit: Int, $nextToken: String) {
    getSupplierGroup(getSupplierGroupInput:{ 
      id: $id, 
      listSuppliersInput: {
        filter: $filter,
        limit: $limit,
        nextToken: $nextToken
      }}) {
        supplierGroup {
          id,
          name,
          labelColor
        }
       listSuppliers {
         suppliers {
            id
            givenName
            familyName
            businessName
            phoneNumber
         }
         nextToken
       }
    }
  }
`

export const EDIT_CUSTOMER = gql`
  mutation updateCustomer(
    $id: String!
    $customerGroupsToDelete: [String]
  ){
    updateCustomer(customerUpdateInput:{
      id: $id,
      customerGroupsToDelete: $customerGroupsToDelete
    }){
      id
    }
  }
`

export const EDIT_SUPPLIER = gql`
  mutation updateSupplier(
    $id: String!
    $supplierGroupsToDelete: [String]
  ){
    updateSupplier(supplierUpdateInput:{
      id: $id,
      supplierGroupsToDelete: $supplierGroupsToDelete
    }){
      id
    }
  }
`
