import { gql } from '@apollo/client'

export const LIST_TRANSACTIONS_HISTORY = gql`
  query transactionHistory(
    $locationId: String!
    $nextToken: String
    $limit: Int
    $beginTime: String
    $endTime: String
    $name: String
    $value: String
    $sortOrder: SortOrder
    $cashRegisterId: String
  ) {
    transactionsHistory(
      locationId: $locationId
      nextToken: $nextToken
      limit: $limit
      sortOrder: $sortOrder
      beginTime: $beginTime
      endTime: $endTime
      filter: {
        name: $name, 
        value: $value
      }
      requestedLocationAndCashRegister: {
        locationId: $locationId,
        cashRegisterId: $cashRegisterId
      }
    ) {
      nextToken
      items {
        type
        transaction {
          id
          receiptNumber
          employee
          createdAt
          status
          code
          tender {
            createdAt
            amountMoney {
              amount
              currency
            }
            additionalAmountMoney {
              amount
              currency
            }
            cashDetails {
              buyerTenderedMoney {
                amount
                currency
              }
              changeBackMoney {
                amount
                currency
              }
            }
          }
        }
        refund {
          id
          transactionId
          reason
          status
          code
          createdAt
          employee
          receiptNumber
          amountMoney {
            amount
            currency
          }
          transaction {
            status
            receiptNumber
          }
        }
      }
    }
  }
`

export const GET_TRANSACTION = gql`
  query getTransaction($locationId: String!, $transactionId: String!, $locId: String, $cashRegisterId: String) {
    getTransaction(
      locationId: $locationId,
       transactionId: $transactionId
       requestedLocationAndCashRegister: {
        locationId: $locId,
        cashRegisterId: $cashRegisterId
      }
       ) {
      id
      qrCode
      invoiceType
      FIC
      IIC
      softwareCode
      location {
        businUnitCode
      }
      operatorCode
      customer {
        id
        givenName
        familyName
        businessName
        issuerNUIS
        type
        address {
          addressLine1
          country
          administrativeDistrictLevel1
        }
        reference {
          name
          value
        }
      }
      order {
        bankAccount {
          id
          swiftCode
          bankName
          iban
          holderName
        }
        shippingAddress {
          externalId
          addressLine1
          locality
          country
        }
        orderSubType
        totalMoney {
          amount
          currency
        }
        totalDiscountMoney {
          amount
          currency
        }
        totalTaxMoney {
          amount
          currency
        }
        orderGroupedTaxes {
          VATRate
          NumOfItems
          PriceBefVat {
            amount
            currency
          }
          VATAmt {
            amount
            currency
          }
        }
        appliedDiscounts {
          id
          name
          discountType
          percentage
          appliedMoney {
            currency
            amount
          }
        }
        lineItems {
          id
          itemName
          itemId
          quantity
          refundableQuantity
          variationName
          variationId
          imageUrl
          labelColor
          taxInclusionType
          modifiers {
            id
            name
            modifierListId
            modifierListName
            appliedMoney {
              amount
              currency
            }
          }
          appliedDiscounts {
            id
            name
            discountType
            percentage
            appliedMoney {
              currency
              amount
            }
          }
          basePriceMoney {
            amount
            currency
          }
          totalMoney {
            amount
            currency
          }
          appliedTax {
            id
            name
            percentage
          }
          totalTaxMoney {
            amount
            currency
          }
          measurementUnitName
          measurementUnitId
          taxExemptionType
        }
        supplyDateOrPeriod {
          endDate
          startDate
        }
      }
      refunds {
        id
        receiptNumber
        createdAt
        amountMoney {
          amount
          currency
        }
      }
      tender {
        amountMoney {
          amount
          currency
        }
        cashDetails {
          buyerTenderedMoney {
            amount
            currency
          }
          changeBackMoney {
            amount
            currency
          }
          currency {
            currencyCode
            exRate
          }
        }
        additionalAmountMoney {
          amount
          currency
        }
        type
      }
      employee
      employeeDetails {
      firstName
      email
      id
      lastName
    }
      createdAt
      receiptNumber
      invoiceNumber
      expectedMoney {
        amount
        currency
      }
      qrCode
      status
      code
      message
    }
  }
`

export const GET_REFUND = gql`
  query getRefund(
    $locationId: String!
    $transactionId: String!
    $refundId: String!
    $locId: String
    $cashRegisterId: String
  ) {
    getRefund(
      locationId: $locationId
      transactionId: $transactionId
      refundId: $refundId
      requestedLocationAndCashRegister: {
        locationId: $locId,
        cashRegisterId: $cashRegisterId
      }
    ) {
      id
      createdAt
      reason
      transactionId
      receiptNumber
      qrCode
      FIC
      IIC
      invoiceNumber
      referenceIIC
      referenceFIC
      softwareCode
      operatorCode
      amountMoney {
        amount
        currency
      }
      employee
      employeeDetails {
      email
      firstName
      id
      lastName
    }
      customer {
        givenName
        familyName
        reference {
          name
          value
        }
        businessName
        issuerNUIS
        type
        address {
          addressLine1
          country
          administrativeDistrictLevel1
        }
      }
      order {
        bankAccount {
          id
          swiftCode
          bankName
          iban
          holderName
        }
        shippingAddress {
          externalId
          addressLine1
          locality
          country
        }
        orderGroupedTaxes {
          VATRate
          NumOfItems
          PriceBefVat {
            amount
            currency
          }
          VATAmt {
            amount
            currency
          }
        }
        lineItems {
          id
          itemName
          itemId
          quantity
          variationName
          variationId
          imageUrl
          labelColor
          modifiers {
            id
            name
            modifierListId
            modifierListName
            appliedMoney {
              amount
              currency
            }
          }
          appliedDiscounts {
            id
            name
            discountType
            percentage
            appliedMoney {
              currency
              amount
            }
          }
          basePriceMoney {
            amount
            currency
          }
          totalMoney {
            amount
            currency
          }
          appliedTax {
            id
            name
            percentage
          }
        }
        totalMoney {
          amount
          currency
        }
        totalDiscountMoney {
          amount
          currency
        }
        totalTaxMoney {
          amount
          currency
        }
      }
      transaction {
        id
        invoiceType
        location {
          businUnitCode
        }
        receiptNumber
        employee
        order {
          supplyDateOrPeriod {
            endDate
            startDate
          }
           lineItems {
             measurementUnitId
             measurementUnitName
           }
           orderSubType
        }
        tender {
          amountMoney {
            amount
            currency
          }
          additionalAmountMoney {
            amount
            currency
          }
          cashDetails {
            buyerTenderedMoney {
              amount
              currency
            }
          currency {
            currencyCode
            exRate
          }
          }
          type
        }
        createdAt
      }
        status
        code
        message
    }
  }
`

export const CREATE_REFUND = gql`
  mutation createRefund(
    $locationId: String!
    $transactionId: String!
    $idempotencyKey: String!
    $reason: String!
    $lineItems: [OrderLineItemUpdateInput]!
    $totalMoneyAmount: Float
    $totalMoneyCurrency: String
    $taxMoneyAmount: Float
    $taxMoneyCurrency: String
    $discountMoneyAmount: Float
    $discountMoneyCurrency: String,
    $orderGroupedTaxes: [OrderGroupedTaxInput]!
    $type: PayOrderRequestType!
    $customerId: String
    $bankAccount: BankAccountInput
    $shippingAddress: AddressInput,
    $appliedDiscounts:[OrderLineItemDiscountInput]
    $locId: String,
    $cashRegisterId: String
  ) {
    createRefund(
      locationId: $locationId
      transactionId: $transactionId
      createRefundRequest: {
        idempotencyKey: $idempotencyKey
        reason: $reason
        type: $type
        customerId: $customerId
        order: {
          lineItems: $lineItems
          totalMoney: {amount: $totalMoneyAmount, currency: $totalMoneyCurrency}
          totalTaxMoney: {amount: $taxMoneyAmount, currency: $taxMoneyCurrency}
          totalDiscountMoney: {
            amount: $discountMoneyAmount
            currency: $discountMoneyCurrency
          }
          orderGroupedTaxes: $orderGroupedTaxes
          bankAccount: $bankAccount
          shippingAddress: $shippingAddress
          appliedDiscounts: $appliedDiscounts
        }
      }
      requestedLocationAndCashRegister: {
        locationId: $locId,
        cashRegisterId: $cashRegisterId
      }
    ) {
      transactionId
      id
    }
  }
`
export const SYNC_TRANSACTION = gql`
mutation syncTransactions( $id: String!, $reason: RetryReason!, $locId: String, $cashRegisterId: String ) {
  syncTransactions(
    transactionSyncObjects:{
      id:$id,
      reason:$reason
    }
    requestedLocationAndCashRegister: {
      locationId: $locId,
      cashRegisterId: $cashRegisterId
    }
  ){
    items {
      type
     }
   }
}
`
