/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useContext, useRef, useEffect } from 'react'
import {
    View,
    SafeAreaView,
    FlatList,
    TouchableOpacity,
    TextInput,
} from 'react-native'
import { useMutation, useQuery } from '@apollo/client'
import PropTypes from 'prop-types'
import { useTheme } from 'react-native-themed-styles'

import {
    Button,
    RadioInput,
    Text,
    Loader,
    Modal,
    Icon,
    Header,
    ModalContent,
} from '../../Components'

import ContentManager from '../../Utils/ContentManager'

import { useConfig } from '../../Utils'

import { UPDATE_ORDER, GET_ORDER } from './CancelOrder.Schema'

import Theme from '../../Themes/Theme'

import themedStyles from './CancelOrder.Styles'

import images, { animations } from '../../Themes/Images'

function ReasonInput({ item, index, selected, onPress, onChangeText, value }) {
    const [styles] = useTheme(themedStyles)
    const { colors } = useContext(Theme)



    let style = {}
    let propStyle = {}
    if (item.isFirst) {
        propStyle = styles.listFirst
    }
    if (item.isLast) {
        propStyle = styles.listLast
    }
    style = Object.assign({}, styles.default, styles.list, propStyle)

    const inputRef = useRef()

    if (index === selected) {
        inputRef.current?.focus()
    }

    return (
        <View style={[{ backgroundColor: colors.tertiaryBackground }, style]}>
            <TouchableOpacity style={style} onPress={onPress}>
                <View style={styles.listInfoContainer}>
                    <TextInput
                        ref={(ref) => {
                            inputRef.current = ref
                        }}
                        placeholder={item.placeholder}
                        keyboardType="default"
                        placeholderTextColor={colors.placeholder}
                        style={styles.input}
                        onChangeText={onChangeText}
                        value={value}
                        autoCorrect={false}
                        maxLength={25}
                    />
                    <View style={styles.listPriceContainer}>
                        <View pointerEvents="none">
                            <RadioInput
                                index={index}
                                selected={selected}
                                onPress={onPress}
                                style={styles.selector}
                                color={colors.secondaryAccent}
                            />
                        </View>
                    </View>
                </View>
            </TouchableOpacity>
        </View>
    )
}
ReasonInput.propTypes = {
    item: PropTypes.bool,
    index: PropTypes.number,
    selected: PropTypes.bool,
    onPress: PropTypes.func,
    onChangeText: PropTypes.func,
    value: PropTypes.any,
}

function Item(props) {
    const [styles] = useTheme(themedStyles)
    const { colors } = useContext(Theme)

    const { item, index, selected, onPress } = props

    if (item.inputItem) {
        return <ReasonInput {...props} />
    }

    let style = {}
    let propStyle = {}
    if (item.isFirst) {
        propStyle = styles.listFirst
    }
    if (item.isLast) {
        propStyle = styles.listLast
    }
    style = Object.assign({}, styles.default, styles.list, propStyle)

    return (
        <View style={[{ backgroundColor: colors.tertiaryBackground }, style]}>
            <TouchableOpacity style={style} onPress={onPress}>
                <View style={styles.listInfoContainer}>
                    <View style={styles.listDescContainer}>
                        <Text i18nKey={item.title} numberOfLines={1} size="footnote" />
                    </View>
                    <View style={styles.listPriceContainer}>
                        <View pointerEvents="none">
                            <RadioInput
                                index={index}
                                selected={selected}
                                onPress={onPress}
                                style={styles.selector}
                                color={colors.secondaryAccent}
                            />
                        </View>
                    </View>
                </View>
            </TouchableOpacity>
        </View>
    )
}

Item.propTypes = {
    item: PropTypes.bool,
    index: PropTypes.number,
    selected: PropTypes.bool,
    onPress: PropTypes.func,
}

function CancelOrder(props) {
    const {
        route: {
            params: {
                id,
            },
        },
        navigation: {
            goBack,
            pop,
        },
    } = props

    const { switchLocation: { deviceId, locationId } } = useConfig()


    const [styles] = useTheme(themedStyles)
    const { colors } = useContext(Theme)

    const [selected, setSelected] = useState(-1)
    const [inputValue, setValue] = useState('')
    const [loading, setLoading] = useState(false)
    const [didChange, setDidChange] = useState(false)
    const reason = useRef('')

    const { data, refetch } = useQuery(GET_ORDER, {
        variables: {
            id: id,
            locationId: locationId,
            cashRegisterId: deviceId,
        },
    })

    const [updateOrder] = useMutation(UPDATE_ORDER)



    const translate = (value) => {
        return ContentManager.translate(value)
    }

    const [modal, setModal] = useState({
        isVisible: false,
        icon: images.warningIcon,
        title: '',
        desc: '',
        descPlaceholders: [],
        primaryText: '',
        primaryAction: null,
        secondaryText: '',
        secondaryAction: null,
    })


    const closeModal = () => {
        setModal({
            isVisible: false,
            icon: images.delete,
            title: '',
            desc: '',
            primaryText: '',
            primaryAction: null,
            secondaryText: '',
            secondaryAction: null,
        })
    }

    const items = [
        {
            id: 1,
            title: 'orders_refund_reason_return',
            subtitle: 'orders_refund_reason_return',
            isFirst: true,
        },
        {
            id: 2,
            title: 'orders_refund_reason_accidental',
            subtitle: 'orders_refund_reason_accidental',
        },
        {
            id: 3,
            title: 'orders_refund_reason_cancel',
            subtitle: 'orders_refund_reason_cancel',
        },
        {
            id: 4,
            title: 'orders_refund_reason_fraud',
            subtitle: 'orders_refund_reason_fraud',
        },
        {
            id: 5,
            inputItem: true,
            placeholder: translate('orders_refund_reason_other'),
            subtitle: inputValue,
            isLast: true,
        },
    ]

    const handlePress = (index, item) => {
        if (inputValue !== '') {
            setSelected(index)
            reason.current = ''
            setValue('')
        }
        setSelected(index)
        reason.current = item.subtitle
    }

    const handleUpdate = async () => {
        setLoading(true)
        setDidChange(true)
        try {
            await updateOrder({ variables: { orderUpdateInput: { ...data?.getOrder, reason: reason.current, status: 'CANCELLED' }, locationId: locationId,
            cashRegisterId: deviceId } })
            refetch()
        } catch (error) {
        }
    }

    useEffect(() => {
        if (didChange) {
            if (data?.getOrder?.status === 'CANCELLED') {
                pop(1)
            }
        }
    }, [data])

    return (
        <SafeAreaView style={styles.container}>
            <Header
                title="cancel_order_title"
                image={images.close}
                onPress={() => goBack()}
            />
            <Text
                i18nKey="cancel_order_reason_label"
                align="left"
                style={styles.textStyle}
                weight="bold"
                size="h5"
            />
            <FlatList
                showsVerticalScrollIndicator={false}
                keyExtractor={(item, index) => 'row-' + index}
                getItemLayout={(i, index) => ({
                    length: 60,
                    offset: 61 * index,
                    index,
                })}
                data={items}
                style={styles.listStyle}
                contentContainerStyle={styles.listContainerStyle}
                renderItem={({ item, index }) => (
                    <Item
                        key={'row-' + index}
                        index={index}
                        item={item}
                        selected={selected}
                        value={inputValue}
                        onChangeText={(text) => {
                            reason.current = text
                            setValue(text)
                        }}
                        onPress={() => {
                            handlePress(index, item)

                        }}
                    />
                )}
                ItemSeparatorComponent={() => <View style={styles.separator} />}
                ListFooterComponent={
                    <Button
                        disabled={reason.current === ''}
                        variant={reason.current === '' ? 'disabled' : 'active'}
                        title="cancel_order_title"
                        onPress={handleUpdate}
                        style={styles.fullIssueButton}
                        loader={loading}
                        loaderComponent={
                            <View
                                style={styles.loaderView}>
                                <Loader size={32} source={animations.vfLoaderWhite} />
                                <Text
                                    i18nKey="drawer_processing_label"
                                    color={colors.white}
                                    style={styles.leftMargin}
                                />
                            </View>
                        }
                    />
                }
            />
            <Modal
                isVisible={modal.isVisible}
                onBackButtonPress={() => closeModal()}
                onBackdropPress={() => closeModal()}
                animationIn="fadeIn"
                animationOut="fadeOut">
                <ModalContent
                    onClose={() => closeModal()}
                    contentContainerStyle={styles.modalContent}>
                    <Icon source={modal.icon} size={40} />
                    <Text
                        i18nKey={modal.title}
                        align="center"
                        size="h4"
                        weight="bold"
                        style={styles.modalTitle}
                    />
                    <Text
                        i18nKey={modal.desc}
                        numberOfLines={10}
                        placeHolders={modal.descPlaceholders}
                        align="center"
                    />
                    {modal.primaryAction && (
                        <Button
                            title={modal.primaryText}
                            variant="active"
                            style={styles.modalPrimaryButton}
                            onPress={() => modal.primaryAction?.()}
                        />
                    )}
                    {modal.secondaryAction && (
                        <Button
                            title={modal.secondaryText}
                            variant="outline"
                            style={styles.modalSecondaryButton}
                            onPress={() => modal.secondaryAction?.()}
                        />
                    )}
                </ModalContent>
            </Modal>
        </SafeAreaView>
    )
}
CancelOrder.propTypes = {
    navigation: PropTypes.object,
    route: PropTypes.object,
}

export default CancelOrder
