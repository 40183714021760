import React, { useContext } from 'react'
import { TouchableOpacity, FlatList, View } from 'react-native'
import PropTypes from 'prop-types'
import { useTheme } from 'react-native-themed-styles'

import Text from '../Text'
import Icon from '../Icon'
import { useUserNames } from '../../Utils'

import themedStyles, { suggestionStyles } from './UsernameSuggestions.Styles'
import Theme from '../../Themes/Theme'
import { images } from '../../Themes'

function Suggestion({
  title,
  select,
  remove,
  isSelected,
}) {
  const [styles] = useTheme(suggestionStyles)

  return (
    <View style={styles['containerSelected_' + isSelected]}>
    <TouchableOpacity onPress={() => select(title)} style={styles['touchableSelected_' + isSelected]}>
      <Text i18nKey={title} style={styles['textSelected_' + isSelected]} />
    </TouchableOpacity>
    <View style={styles['dividerSelected_' + isSelected]}/>
    <TouchableOpacity onPress={() => remove(title)} style={styles['touchableSelected_' + isSelected]}>
      <Icon source={images.closeS} size={18} style={styles['iconSelected_' + isSelected]} />
    </TouchableOpacity>
    </View>
  )
}
Suggestion.propTypes = {
  title: PropTypes.string,
  select: PropTypes.func,
  remove: PropTypes.func,
  isSelected: PropTypes.bool,
}

function UsernameSuggestions(props) {
  const [styles] = useTheme(themedStyles)
  const { colors } = useContext(Theme)
  const {
    latestUsernames,
    deleteUsername,
  } = useUserNames()

  const {
    value,
    select,
  } = props

  const keyExtractor = (_, idx) => 'suggestion_' + idx
  const renderSuggestion = ({ item }) => (
    <Suggestion
      title={item}
      select={select}
      remove={deleteUsername}
      accent={colors.secondaryAccent}
      isSelected={value === item}
    />
  )

  return (
    <FlatList
      horizontal
      inverted
      data={latestUsernames}
      keyExtractor={keyExtractor}
      renderItem={renderSuggestion}
      showsHorizontalScrollIndicator={false}
      style={styles.container}
      contentContainerStyle={styles.contentContainer}
    />
  )
}

UsernameSuggestions.defaultProps = {
  select: () => {},
}

UsernameSuggestions.propTypes = {
  value: PropTypes.string,
  select: PropTypes.func,
}

export default UsernameSuggestions
