import {StyleSheet} from 'react-native'

import colors from '../../Themes/Colors'

export default StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.background,
  },
  itemContainer: {
    marginHorizontal: 15,
  },
  scrollContainer: {
    flex: 1,
  },
  sectionTitle: {
    margin: 15,
    marginLeft: 0,
    marginBottom: 10,
    color: colors.black,
  },
  imagesColorContainer: {
    alignSelf: 'center',
    marginTop: 25,
    backgroundColor: colors.white,
    borderRadius: 7,
    width: 132,
    height: 93,
    padding: 7.5,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  image: {
    width: 58.5,
    height: 78,
    justifyContent: 'center',
    alignItems: 'center',
  },
  galleryIcon: {
    width: 35,
    height: 35,
    resizeMode: 'contain',
    tintColor: colors.midGrey,
    borderRightWidth: 1,
    borderRightColor: 'red',
  },
  seperator: {
    height: 78,
    width: 1.5,
    backgroundColor: colors.white,
  },
  color: {
    width: 58.5,
    height: 39,
    justifyContent: 'center',
    alignItems: 'center',
  },
  colorIcon: {
    width: 35,
    height: 35,
    resizeMode: 'contain',
  },
  scanIcon: {
    width: 25,
    height: 20,
    resizeMode: 'contain',
    tintColor: colors.turquoise,
  },
  button: {
    backgroundColor: colors.accent,
    marginTop: 0,
    height: 50,
  },
  primaryButton: {
    marginTop: 15,
  },
  plusIcon: {
    tintColor: colors.accent,
  },
  arrowIcon: {
    marginLeft: 8,
    width: 12,
    height: 12,
    resizeMode: 'contain',
    tintColor: colors.turquoise,
  },
  noBorder: {
    marginTop: 21.5,
    marginBottom: 3,
    borderWidth: 0,
    borderBottomWidth: 0,
  },
  skuText: {
    paddingBottom: 6.5,
  },
  tabContainerStyle: {
    backgroundColor: colors.white,
    elevation: 0,
    shadowRadius: 0,
    shadowOpacity: 0,
    marginBottom: 0,
    height: 50,
  },
  indicatorStyle: {
    backgroundColor: colors.turquoise,
  },
  fullView: {
    flexGrow: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  tabView: {
    marginTop: 20,
  },
  inputRow: {
    paddingHorizontal: 0,
  },
  inputWithoutSpace: {
    marginVertical: 0,
    marginBottom: 1,
    borderRadius: 0,
  },
  roundedCorners: {
    borderRadius: 7,
  },
  lastInputBlock: {
    borderBottomLeftRadius: 7,
    borderBottomRightRadius: 7,
  },
  fistInputBlock: {
    borderTopLeftRadius: 7,
    borderTopRightRadius: 7,
  },

  wrapper: {
    backgroundColor: colors.accent,
    flex: 1,
    height: 60,
    justifyContent: 'center',
    alignItems: 'flex-end',
  },
  firstItem_true: {
    borderTopLeftRadius: 7.5,
    borderTopRightRadius: 7.5,
  },
  lastItem_true: {
    borderBottomLeftRadius: 7.5,
    borderBottomRightRadius: 7.5,
  },
  option: {
    width: 100,
    height: 60,
    justifyContent: 'center',
    alignItems: 'center',
  },
  optionFull: {
    flexDirection: 'row',
  },
  confirm: {
    flexGrow: 1,
    height: 60,
    flexDirection: 'row',
    paddingLeft: 15,
    alignItems: 'center',
  },
  confirmText: {
    marginLeft: 15,
  },
  cancel: {
    width: 40,
    height: 40,
    flexShrink: 0,
    marginVertical: 10,
    marginHorizontal: 15,
    borderRadius: 20,
    backgroundColor: colors.whiteRGBA(0.55),
    justifyContent: 'center',
    alignItems: 'center',
  },
  loader: {
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },

  sectionTitleContainer: {
    flexDirection:'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 20,
    marginBottom: 10,
  },
  nameContainer: {
    margin: 15,
  },
  addNew: {
    height: 36,
    paddingHorizontal: 16,
  },
  inputContainer: {
    height: 60,
    backgroundColor: colors.tertiaryBackground,
  },
  input: {
    marginVertical: 0,
  },
  listContentStyle: { paddingBottom: 5, paddingHorizontal: 0 },
})
