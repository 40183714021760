import React, { useRef, useState } from 'react'
import { Platform, SafeAreaView, ScrollView, View } from 'react-native'
import { useTheme } from 'react-native-themed-styles'
import PropTypes from 'prop-types'
import { useReactToPrint } from 'react-to-print'
import RNHTMLtoPDF from 'react-native-html-to-pdf'
import Share from 'react-native-share'

import moment from 'moment'

import { Margin, usePDF } from 'react-to-pdf'

import { Button, ErrorModal, Loader, Text, Header, SimpleType } from '../../Components'
import { printOldReceipt } from '../../Utils/Order'
import { ConvertOldInvoice, ReactToPDF } from '../../Utils/PdfTemplatesWeb'

import { convertOldInvoice, useTemplateLanguage } from '../../Utils'

import themedStyles from './GovInvoiceDetails.Styles'

import images, { animations } from '../../Themes/Images'
import { colors } from '../../Themes'






function Item({ title, value, isFirst, isLast }) {
    const [styles] = useTheme(themedStyles)

    let style = {}
    if (isFirst) {
        style = Object.assign({
            borderTopLeftRadius: 7,
            borderTopRightRadius: 7,
            borderTopWidth: 0,
        })
    }
    if (isLast) {
        style = Object.assign({
            borderBottomLeftRadius: 7,
            borderBottomRightRadius: 7,
        })
    }

    return (
        <View
            style={[
                styles.itemStyles,
                style,
            ]}>
            <View style={styles.itemStyle}>
                <View style={styles.listDescContainer}>
                    <Text i18nKey={title} />
                    <Text i18nKey={`: ${value}`} />
                </View>
            </View>
        </View>
    )
}
Item.propTypes = {
    title: PropTypes.string,
    value: PropTypes.string,
    isFirst: PropTypes.bool,
    isLast: PropTypes.bool,
}

function GovInvoiceDetails(
    { navigation: {
        goBack,
        navigate,
    },
        route: {
            params: {
                invoice,
            },
        } }) {
    const [styles] = useTheme(themedStyles)
    const { openConfigurationSelector, translate } = useTemplateLanguage()


    const [confirm, setConfirm] = useState(false)
    const [loadingPrintWeb, setLoadingPrintWeb] = useState(false)
    const [loadingDownloadWeb, setLoadingDownloadWeb] = useState(false)

    const [shareLoading, setShareLoading] = useState(false)

    const [errorModal, setErrorModal] = useState({
        isVisible: false,
        icon: images.error,
        title: '',
        desc: '',
        primaryText: '',
        primaryAction: null,
        secondaryText: '',
        secondaryAction: null,
    })

    const openErrorModal = (
        title = 'receipt_printer_error',
        desc = '',
        primaryText = '',
        primaryAction = null,
        secondaryText = '',
        secondaryAction = null,
        icon = images.warningIcon,
    ) => {
        setErrorModal({
            isVisible: true,
            icon,
            title,
            desc,
            primaryText,
            primaryAction,
            secondaryText,
            secondaryAction,
        })
    }

    const closeErrorModal = () => {
        setErrorModal({
            isVisible: false,
            icon: images.delete,
            title: '',
            desc: '',
            primaryText: '',
            primaryAction: null,
            secondaryText: '',
            secondaryAction: null,
        })
    }

    // const [errorModal, setErrorModal] = useState({
    //     isVisible: false,
    //     icon: images.error,
    //     title: '',
    //     desc: '',
    //     primaryText: '',
    //     primaryAction: null,
    //     secondaryText: '',
    //     secondaryAction: null,
    // })

    // const openErrorModal = (
    //     title = 'receipt_printer_error',
    //     desc = '',
    //     primaryText = '',
    //     primaryAction = null,
    //     secondaryText = '',
    //     secondaryAction = null,
    //     icon = images.warningIcon,
    // ) => {
    //     setErrorModal({
    //         isVisible: true,
    //         icon,
    //         title,
    //         desc,
    //         primaryText,
    //         primaryAction,
    //         secondaryText,
    //         secondaryAction,
    //     })
    // }

    // const closeErrorModal = () => {
    //     setErrorModal({
    //         isVisible: false,
    //         icon: images.delete,
    //         title: '',
    //         desc: '',
    //         primaryText: '',
    //         primaryAction: null,
    //         secondaryText: '',
    //         secondaryAction: null,
    //     })
    // }

    const buttonProps = {
        backgroundColor: {
            true: colors.secondaryAccent,
            false: colors.buttonSecondaryAccent,
        },
        foregroundColor: {
            true: colors.invertedText,
            false: colors.buttonTertiaryAccent,
        },
        text: {
            true: 'receipt_button_confirm',
            false: 'receipt_button_print_receipt',
        },
        iconColor: {
            true: colors.tertiaryIcon,
            false: colors.secondaryIcon,
        },
    }

    const handlePrintWeb = useReactToPrint({
        content: () => targetRef.current,
        onBeforePrint: () => setLoadingPrintWeb(true),
        onAfterPrint: () => setLoadingPrintWeb(false),
    })

    const onHandlePrintWeb = () => {
        openConfigurationSelector(false, handlePrintWeb)
    }

    const { toPDF, targetRef } = usePDF({
        method: 'save',
        filename: `${invoice?.InvoiceNumber}.pdf`,
        page: { margin: Margin.NONE },
    })

    const handleDownload = async () => {
        setLoadingDownloadWeb(true)
        await toPDF()
        setLoadingDownloadWeb(false)
    }

    const shareReceipt = async () => {
        setShareLoading(true)
        // const { data: qr } = await client.query({
        //     query: GET_QR,
        //     variables: {
        //         text: transaction1.qrCode,
        //     },
        // })

        openConfigurationSelector(true, () => {
            RNHTMLtoPDF.convert({
                html: convertOldInvoice(
                    invoice,
                    translate,
                ),
                fileName: 'fature',
            })
                .then((res) => {
                    Share.open({
                        url: `file://${res.filePath}`,
                        subject: 'Fatura ' + invoice?.InvoiceNumber,
                        title: 'Fatura',
                    })
                        .catch(() => { })
                    setShareLoading(false)
                })
                .catch(() => {
                    setShareLoading(false)
                })
        })
        setShareLoading(false)
    }

    const activateConfirm = () => {
        if (confirm) {
            printOldReceipt(
                invoice,
                translate
            ).then(() => {
                setConfirm(false)
            }).catch(() => {
                openErrorModal(
                    'receipt_printer_error',
                    'receipt_printer_error_label',
                    'receipt_open_printers_button',
                    () => {
                        closeErrorModal()
                        navigate('PrinterSetUp')
                    },
                    'checkout_button_skip',
                    () => {
                        closeErrorModal()
                    },
                    images.error
                )
                setConfirm(false)
            })
        } else {
            openConfigurationSelector(false, () => setConfirm(true))
        }
    }

    return (
        <SafeAreaView style={styles.container}>
            <Header title={invoice?.InvoiceNumber} onPress={goBack} />
            <ScrollView style={styles.wrapper}>
                <View style={styles.inlineButtons}>
                    <Button
                        title="receipt_button_issue_refund"
                        titleStyle={styles.buttonTitle}
                        backgroundColor={colors.buttonSecondaryAccent}
                        color={colors.buttonTertiaryAccent}
                        borderColor={colors.transparent}
                        icon={images.refundedInvoice}
                        iconColor={colors.secondaryIcon}
                        iconStyle={{ transform: [{ rotate: '90deg' }] }}
                        // disabled={loading || error}
                        style={styles.fullIssueButton}
                        onPress={() =>
                            navigate('IssueRefund', {
                                oldInvoice: invoice,
                            })
                        }
                    />
                    <Button
                        title={Platform.OS === 'web' ? 'receipt_button_print_receipt' : buttonProps.text[confirm]}
                        icon={loadingPrintWeb ? null : images.printerActive}
                        iconStyle={{
                            tintColor: buttonProps.iconColor[confirm],
                        }}
                        style={styles.inlineButton1}
                        disabled={loadingPrintWeb}
                        backgroundColor={buttonProps.backgroundColor[confirm]}
                        borderWidth={0}
                        borderColor={buttonProps.backgroundColor[confirm]}
                        color={buttonProps.foregroundColor[confirm]}
                        loader={loadingPrintWeb}
                        loaderComponent={<Loader source={animations.vfLoaderThinDark} size={24} />}
                        onPress={Platform.OS === 'web' ? () => onHandlePrintWeb() : () => activateConfirm()}
                    />
                    {Platform.OS === 'web' ?
                        <Button
                            title="receipt_download_as_pdf"
                            icon={loadingDownloadWeb ? null : images.download}
                            iconColor={colors.secondaryIcon}
                            style={styles.inlineButton1}
                            disabled={loadingDownloadWeb}
                            backgroundColor={buttonProps.backgroundColor[confirm]}
                            borderWidth={0}
                            borderColor={buttonProps.backgroundColor[confirm]}
                            titleStyle={{ color: colors.primaryText }}
                            loader={loadingDownloadWeb}
                            loaderComponent={<Loader source={animations.vfLoaderThinDark} size={24} />}
                            onPress={() => {
                                openConfigurationSelector(true, handleDownload)
                            }}
                        />
                        :
                        <Button
                            icon={shareLoading ? null : images.share}
                            iconColor={colors.secondaryIcon}
                            color={colors.primaryText}
                            title="orders_button_share_receipt"
                            backgroundColor={colors.white}
                            style={styles.inlineButton2}
                            onPress={() => shareReceipt().catch(e => { })}
                            loader={shareLoading}
                            loaderComponent={<Loader source={animations.vfLoaderThinDark} size={24} />}
                            variant={shareLoading ? 'disabled' : 'active'}
                        />
                    }

                </View>
                <View style={styles.infoWrapperView}>
                    <Item
                        title="receipt_iic_label"
                        value={invoice.IICRef}
                        isFirst={true}
                    />
                    <Item title="gov_invoices_invoice_date" value={moment(invoice.InvoiceDate).format('MM/DD/YYYY')} />
                    <Item
                        title="receipt_invoice_number"
                        value={invoice.InvoiceNumber}
                        isLast={true}
                    />
                </View>
                <View>
                    <Item
                        title="id_type_nuis_description"
                        value={invoice.NUIS}
                        isFirst={true}

                    />
                    <Item
                        title="gov_invoices_taxpayer_name"
                        value={invoice.TargetTaxpayerName}
                    // isLast={true}
                    />
                    <Item
                        title="gov_invoices_taxpayer_nipt"
                        value={invoice.TargetTaxpayerNUIS}
                        isLast={true}
                    />
                </View>
                {Platform.OS === 'web' &&
                    <div style={styles.webStyles}>
                        <ConvertOldInvoice
                            transaction={invoice}
                            // cashBuyer={cashBuyer}
                            // change={change}
                            // nipt={context?.nipt}
                            // company={context?.company}
                            // location={context?.location}
                            // customer={customer}
                            ref={targetRef}
                        />
                    </div>
                }
            </ScrollView>
            <ErrorModal
                isVisible={errorModal.isVisible}
                icon={errorModal.icon}
                title={errorModal.title}
                description={errorModal.desc}
                primaryText={errorModal.primaryText}
                primaryAction={errorModal.primaryAction}
                secondaryText={errorModal.secondaryText}
                secondaryAction={errorModal.secondaryAction}
                onBackdropPress={() => closeErrorModal()}
                onBackButtonPress={() => closeErrorModal()}
                onClosePress={() => closeErrorModal()}
            />
        </SafeAreaView>
    )
}

GovInvoiceDetails.propTypes = {
    navigation: PropTypes.object,
    route: PropTypes.object,
}

export default GovInvoiceDetails
