/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState, useEffect } from 'react'
import { SafeAreaView, View } from 'react-native'
import { useTheme } from 'react-native-themed-styles'
import { useDispatch, useSelector } from 'react-redux'
import { useMutation } from '@apollo/client'
import PropTypes from 'prop-types'

import { onBoardingUpdateStepKey } from '../OnBoarding/OnBoarding.Actions'
import {
    Header,
    Button,
    Icon,
    ErrorModal,
} from '../../Components'

import { getErrorMessage } from '../../Utils'

import Confirmation from '../RegisterCertificate/Confirmation'
import { getOnBoardingCertificateSecondStep, getOnBoardingCertificate } from '../OnBoarding/OnBoarding.Selectors'

import { CREATE_CERTICIFATE, UPDATE_CERTICIFATE, SEND_PIN, GENERATE_AND_SEND_PIN } from '../RegisterCertificate/RegisterCertificate.schema'

import Theme from '../../Themes/Theme'
import images from '../../Themes/Images'
import themedStyles from './OnBoardingThirdStep.Styles'

function OnBoardingThirdStep({ next, previous, logout }) {
    const { colors } = useContext(Theme)
    const [styles] = useTheme(themedStyles)
    const storedCode = useSelector(getOnBoardingCertificateSecondStep)
    const userCredentials = useSelector(getOnBoardingCertificate)
    const [disabled, setDisabled] = useState(true)
    const dispatchAction = useDispatch()
    const [sendPin, { }] = useMutation(GENERATE_AND_SEND_PIN)

    const updateCertificate = (key, value) => {
        dispatchAction(onBoardingUpdateStepKey('certificateSecond', key, value))
    }

    const sendCode = () => {
        sendPin({
            variables: {
                 id: 'static',
            },
        })
            .then(() => {
                updateCertificate('code', '')
            })
            .catch((e) => {
                openModal(
                    'certificate_error_label',
                    getErrorMessage(e),
                    [],
                    'certificate_button_try_again',
                    () => {
                        closeModal()
                    },
                    '',
                    undefined
                )
            })
    }

    const checkInput = () => {
        if (storedCode?.code.length < 5) {
            setDisabled(true)
        } else {
            setDisabled(false)
        }
    }

    const [registerCertificate, { loading: registerLoading }] = useMutation(CREATE_CERTICIFATE)
    const [updateNewCertificate, { loading: updateLoading }] = useMutation(UPDATE_CERTICIFATE)

    const [modal, setModal] = useState({
        isVisible: false,
        icon: images.warningIcon,
        title: '',
        desc: '',
        descPlaceholders: [],
        primaryText: '',
        primaryAction: null,
        tertiaryText: '',
        tertiaryAction: null,
    })
    const openModal = (
        title = 'opened_orders_error',
        desc = '',
        descPlaceholders = [],
        primaryText = '',
        primaryAction = null,
        tertiaryText = '',
        tertiaryAction = null,
        icon = images.warningIcon,
    ) => {
        setModal({
            isVisible: true,
            icon,
            title,
            desc,
            descPlaceholders,
            primaryText,
            primaryAction,
            tertiaryText,
            tertiaryAction,
        })
    }
    const closeModal = () => {
        setModal({
            isVisible: false,
            icon: images.delete,
            title: '',
            desc: '',
            primaryText: '',
            primaryAction: null,
            tertiaryText: '',
            tertiaryAction: null,
        })
    }

    const newCertificate = () => {
        if (userCredentials.hasCertificate) {
            updateNewCertificate({
                variables: {
                    confirmationPIN: storedCode.code,
                    issuerNUIS: userCredentials.nipt.toUpperCase(),
                    personalId: userCredentials.nipt.toUpperCase(),
                    phoneNumber: `355${userCredentials.phone}`,
                    contactEmail: userCredentials.email,
                    certificate: userCredentials.base64,
                    certPassword: userCredentials.password,
                },
            })
                .then(() => {
                    next()
                })
                .catch((e) => {
                    openModal(
                        'certificate_error_label',
                        getErrorMessage(e),
                        [],
                        'certificate_button_try_again',
                        () => {
                            closeModal()
                        },
                        '',
                        undefined
                    )
                })
        }
        else {
            registerCertificate({
                variables: {
                    confirmationPIN: storedCode.code,
                    issuerNUIS: userCredentials.nipt.toUpperCase(),
                    personalId: userCredentials.nipt.toUpperCase(),
                    phoneNumber: `355${userCredentials.phone}`,
                    contactEmail: userCredentials.email,
                    certificate: userCredentials.base64,
                    certPassword: userCredentials.password,
                },
            })
                .then(() => {
                    next()
                })
                .catch((e) => {
                    openModal(
                        'certificate_error_label',
                        getErrorMessage(e),
                        [],
                        'certificate_button_try_again',
                        () => {
                            closeModal()
                        },
                        '',
                        undefined,
                    )
                })

        }
    }

    useEffect(() => {
        checkInput()
    }, [storedCode.code])

    return (
        <>
            <SafeAreaView style={styles.container}>
                <Header
                    title="certificate_upload_label"
                    image={images.close}
                    onPress={() => {
                        openModal(
                            'logout_key',
                            'onboarding_logout_message_description',
                            [],
                            'logout_key',
                            () => {
                                logout()
                            },
                            'logout_button_cancel',
                            () => {
                                closeModal()
                            },
                        )
                    }}
                />
                <Confirmation resend={() => sendCode()} value={storedCode?.code} setValue={(val) => updateCertificate('code', val)} />
                <View style={styles.buttonContainer}>
                    <Button
                        title="onboarding_back_button"
                        variant={registerLoading || updateLoading ? 'disabled' : 'preActive'}
                        iconColor={registerLoading || updateLoading ? colors.white : colors.accent}
                        style={styles.rightButton}
                        onPress={previous}
                        icon={images.arrowLeft}
                    />
                    <Button
                        loader={registerLoading || updateLoading}
                        title="orders_refund_button_next"
                        variant={disabled || registerLoading || updateLoading ? 'disabled' : 'active'}
                        iconColor={colors.accent}
                        style={styles.leftButton}
                        onPress={() => {
                            newCertificate()
                        }}
                        rightComponent={(registerLoading || updateLoading) ? null : <Icon
                            size={20}
                            source={images.arrowRight}
                            color={colors.white}
                            style={styles.rightButtonIcon}
                        />}
                    />
                </View>
            </SafeAreaView>
            <ErrorModal
                isVisible={modal?.isVisible}
                title={modal?.title}
                description={modal?.desc}
                placeHolders={modal.descPlaceholders}
                primaryText={modal?.primaryText}
                tertiaryText={modal?.tertiaryText}
                primaryAction={modal?.primaryAction}
                tertiaryAction={modal?.tertiaryAction}
                onBackdropPress={() => closeModal()}
                onBackButtonPress={() => closeModal()}
                onClosePress={() => closeModal()}
            />
        </>
    )
}

OnBoardingThirdStep.propTypes = {
    previous: PropTypes.func,
    next: PropTypes.func,
    logout: PropTypes.func,
}

export default OnBoardingThirdStep
