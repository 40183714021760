/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { View, ScrollView, LayoutAnimation, Platform } from 'react-native'
import { CommonActions } from '@react-navigation/native'
import PropTypes from 'prop-types'
import { useTheme } from 'react-native-themed-styles'
import { useMutation, useLazyQuery } from '@apollo/client'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'

import { Header, TouchableInput, InputRow } from '../../../../Components'
import { getErrorMessage, useUserDetails, useLocationDetails, useTemplateLanguage, useConfig } from '../../../../Utils'
import { clearExtraDetails, useCountOrders } from '../../../../Utils/Order'
import SecondaryCurrency from '../Components/SecondaryCurrency'

import {
  getEinvoice,
  getEinvoiceCalculated,
  getInvoiceId,
  getInitialData,
  getSupportInvoice,
  getSupportId,
  getPaymentCashMoney,
} from '../../Payment.Selectors'
import {
  newInvoice,
  storeItems,
  updateCalculatedUBL,
  supportInvoiceUpdateStep,
  supportInvoiceNewInvoice,
  updateStepKey,
  dismissPaymentError,
  displayPaymentError,
  updatePaymentAttribute,
} from '../../Payment.Actions'

import { opacityConfig } from '../../../../Themes/Theme'
import { images } from '../../../../Themes'

import {
  SAVE_ORDER,
  CONVERT_ORDER_TO_UBL,
  SAVE_EINVOICE,
  CALCULATE_UBL,
  CONVERT_ORDER_TO_WTN,
  SAVE_SUPPORTING_INVOICE,
} from '../../Payment.Schema'
import {
  AMOUNT_ALL_VALUES,
  AMOUNT_EUR_VALUES,
  AMOUNT_USD_VALUES,
  AMOUNT_GBP_VALUES,
  NUM_OF_SUGGESTIONS,
  PAYMENT_STEPS,
  EINVOICE_PAYMENT_STEPS,
  INPUT_CONTENT,
  FOOTER_CONTENT,
  SUPPORTIVE_EINVOICE_PAYMENT_STEPS,
  PAYMENT_METHODS,
  DEFAULT_PAYMENT_METHOD,
} from '../../Payment.Config'
import { resetOrder } from '../../../Checkout/Checkout.Actions'

import { getWTNSaveInput } from '../../Helpers'
import { getCurrency, getOrder, getTotal } from '../../../Checkout/Checkout.Selectors'
import { PaymentMethods, TotalAndChange } from '../Components'

import ContentManager from '../../../../Utils/ContentManager'

import themedStyles from './Init.Styles'

// if dynamic it gives a problem in web, maybe we may take a look later into that (anyway they dont change...)
const AMOUNT_VALUES = Platform.OS === 'web'
  ? {
    ALL: [5000, 2000, 1000, 500, 200, 100, 1],
    EUR: [500, 200, 100, 50, 20, 10, 5, 2, 1],
    USD: [100, 50, 20, 10, 5, 1],
    GBP: [50, 20, 10, 5, 1],
  }
  : {
    ALL: AMOUNT_ALL_VALUES,
    EUR: AMOUNT_EUR_VALUES,
    USD: AMOUNT_USD_VALUES,
    GBP: AMOUNT_GBP_VALUES,
  }

function Init({
  navigate,
  goBackCustom,
  dispatch,
  invoicingMethods,
  invoicingMethod,
  setInvoicingMethod,
  stepperLength,
  index,
  otherCurrency,
  setOtherCurrency,
  paymentMethods,
  setPaymentMethods,
}) {

  const { switchLocation: { deviceId, locationId } } = useConfig()

  const [styles] = useTheme(themedStyles)
  const countSavedOrders = useCountOrders()
  const { euRate, usdRate, poundRate } = useLocationDetails()
  const { openConfigurationSelector, receiptFormat, receiptLanguage } = useTemplateLanguage()
  const { id } = useUserDetails()
  const { translate } = ContentManager

  const [options, setOptions] = useState([])
  const [currencies] = useState([
    {
      code: 'Lek (ALL)',
      type: 'Lek',
      description: 'payment_none_currency_label',
      currencyCode: 'ALL',
      rate: 1,
    },
    {
      code: 'Euro (€)',
      type: 'Euro',
      description: 'payment_euro_currency_label',
      descPlaceHolders: [euRate],
      currencyCode: 'EUR',
      rate: euRate,
      disabled: !euRate,
    },
    {
      code: 'USD ($)',
      type: 'USD',
      description: 'payment_usd_currency_label',
      descPlaceHolders: [usdRate],
      currencyCode: 'USD',
      rate: usdRate,
      disabled: !usdRate,
    },
    {
      code: 'Pound (£)',
      type: 'GBP',
      description: 'payment_pound_currency_label',
      descPlaceHolders: [poundRate],
      currencyCode: 'GBP',
      rate: poundRate,
      disabled: !poundRate,
    },
  ])

  const currency = useSelector(getCurrency)
  const { order } = useSelector(getOrder)
  const total = useSelector(getTotal)
  const cash = useSelector(getPaymentCashMoney)

  const einvoiceData = useSelector(getEinvoice)
  const calculatedUBL = useSelector(getEinvoiceCalculated)
  const invoiceID = useSelector(getInvoiceId)
  const initData = useSelector(getInitialData)
  const wtnInvoiceId = useSelector(getSupportId)

  const allInvoice = useSelector(getSupportInvoice)

  const dispatchAction = useDispatch()

  const clearOrder = () => dispatchAction(resetOrder())
  const dismissError = () => dispatchAction(dismissPaymentError())

  const displayError = (
    title = '',
    desc = '',
    primaryText = '',
    primaryAction = null,
    secondaryText = '',
    secondaryAction = null,
    isDismissable = true,
  ) => {
    dispatchAction(displayPaymentError({
      isVisible: true,
      title: title,
      desc: desc,
      primaryText: primaryText,
      primaryAction: primaryAction,
      secondaryText: secondaryText,
      secondaryAction: secondaryAction,
      isDismissable: isDismissable,
    }))
  }
  const setPaymentStatus = (value) => dispatchAction(updatePaymentAttribute('paymentStatus', value))
  const setPaymentMethod = (value) => dispatchAction(updatePaymentAttribute('paymentMethod', value))
  const setOrderType = (value) => dispatchAction(updatePaymentAttribute('orderType', value))

  const updateStep = (step, value) => dispatchAction(supportInvoiceUpdateStep(step, value))
  const storeLineItems = (items) => dispatchAction(storeItems(items))
  const updateProcess = (key, value) => dispatchAction(updateStepKey('process', key, value))

  const InputContent = INPUT_CONTENT[invoicingMethod]
  const FooterContent = FOOTER_CONTENT[invoicingMethod]

  const [saveOrder, { loading, data }] = useMutation(SAVE_ORDER)
  const [saveEinvoice, { loading: saveLoading }] = useMutation(SAVE_EINVOICE)
  const [convertToUbl, { data: ublData }] = useLazyQuery(CONVERT_ORDER_TO_UBL, { fetchPolicy: 'network-only' })
  const [convertToWtn, { data: wtnData, loading: wtnLoading }] = useLazyQuery(CONVERT_ORDER_TO_WTN, { fetchPolicy: 'network-only' })
  const [calcUbl, { }] = useMutation(CALCULATE_UBL)
  const [saveSupportInv, { loading: supportInvLoading }] = useMutation(SAVE_SUPPORTING_INVOICE)
  const [ublLoading, setLoading] = useState(false)
  const [supportiveDisabled, setDisabled] = useState(wtnLoading)

  const mapAdditionalDoc = (docData) => {
    const initial = {
      id: docData.additionalDocReference,
      description: docData.description,
    }
    const additionalDocumentReferences = []
    if (docData.uri && docData.contents) {
      additionalDocumentReferences.push(
        {
          ...initial,
          uri: docData.uri,
        },
        {
          ...initial,
          binObj: {
            filename: docData.fileName,
            contents: docData.contents,
            mime: docData.mime,
          },
        }
      )
    } else if (docData.uri) {
      additionalDocumentReferences.push({
        ...initial,
        uri: docData.uri,
      })
    } else if (docData.contents) {
      additionalDocumentReferences.push({
        ...initial,
        binObj: {
          filename: docData.fileName,
          contents: docData.contents,
          mime: docData.mime,
        },
      })
    } else {
      additionalDocumentReferences.push(initial)
    }
    return additionalDocumentReferences
  }

  const einvoicemapper = (einvoice) => {
    const UBL = {
      dueDate: moment(einvoice.process?.dueDate, 'YYYY-MM-DD') || '',
      invoiceDocumentCode: einvoice.process?.invoiceDocument?.type,
      invoiceProcessCode: einvoice.process?.invoiceProcess?.type,
      docCurrencyCode: einvoice.process?.currency,
      taxCurrencyCode: 'ALL',
      invoicePeriod: {
        startDate: moment(einvoice.process?.startDate, 'YYYY-MM-DD') || '',
        endDate: moment(einvoice.process?.endDate, 'YYYY-MM-DD') || '',
        descriptionCode: einvoice.process?.effectiveVat?.code,
      },
      supplierParty: {
        issuerNUIS: einvoice?.seller?.issuerNUIS,
        name: einvoice.seller?.name,
        address: {
          streetName: einvoice.seller?.address?.streetName,
          countryCode: einvoice.seller?.address?.countryCode,
          postalCode: einvoice.seller?.address?.postalCode,
          cityCode: einvoice.seller?.address?.cityCode,
          // cityCode: data.seller.address.cityCode,
        },
        taxSchema: einvoice.seller?.taxSchema,
        registrationName: einvoice.seller?.registrationName,
        contact: einvoice.seller?.contact,
      },
      customerParty: {
        issuerNUIS: einvoice.buyer?.issuerNUIS,
        name: einvoice.buyer?.name,
        address: {
          streetName: einvoice.buyer?.address?.streetName,
          countryCode: einvoice.buyer?.address?.countryCode,
          postalCode: einvoice.buyer?.address?.postalCode,
          cityCode: einvoice.buyer?.address?.cityCode,
        },
        taxSchema: einvoice.buyer?.taxSchema,
        registrationName: einvoice.buyer?.registrationName,
        contact: einvoice.buyer?.contact,
      },
      paymentMeans: {
        code: einvoice.init.code,
        instructionNote: einvoice.init.instructionNote,
        card: {
          primaryAccountId: einvoice.init.primaryAccountId,
          networkId: einvoice.init.networkId,
        },
        payeeFinantialAccount: einvoice.init.payeeFinantialAccount,
      },
      delivery: {
        actualDeliveryDate: einvoice?.delivery.actualDeliveryDate,
        deliveryLocation: {
          address: {
            streetName: einvoice?.delivery.streetName,
            cityName: einvoice?.delivery.cityName,
            countryCode: einvoice?.delivery.countyCode,
            postalCode: einvoice?.delivery.postalZone,
          },
        },
        deliveryPartyName: {
          name: einvoice?.delivery.name,
        },
      },
      orderReference: {
        id: einvoice?.references.orderReference,
        salesOrderId: einvoice?.references.salesOrderId,
      },
      projectReference: {
        id: einvoice?.references.projectReference,
      },
      contractDocumentReference: {
        id: einvoice?.references.contractReference,
      },
      additionalDocumentReferences: mapAdditionalDoc(einvoice?.references),
    }
    return UBL
  }

  const convertPaymentMeans = (paymentEinvoice) => {
    const {
      code,
      instructionNote,
      primaryAccountId,
      networkId,
      payeeFinantialAccount,
    } = paymentEinvoice

    const newPaymentEinvoice = {
      paymentMeans: {
        code,
        instructionNote,
        card: {
          primaryAccountId,
          networkId,
        },
        payeeFinantialAccount,
      },
    }
    return newPaymentEinvoice
  }

  const noteMapper = (unMappedNote) => {
    let note = {}
    unMappedNote?.map((item) => {
      const itemValue = item.substring(item.indexOf('=') + 1)
      const itemKey = item.substring(0, item.indexOf('='))
      const noteitem = { [itemKey]: itemValue }
      note = { ...note, ...noteitem }
    })
    return note.id
  }

  const placeholders = {
    'EINVOICE': {
      titlePlaceHolders: [`${index}/${stepperLength}`],
    },
    'WAREHOUSE_NOTE': {
      titlePlaceHolders: [`${index}/${stepperLength}`],
    },
  }

  const changeAmounts = {
    'CASH': parseFloat(cash || 0),
    'NONCASH': 0,
    'ORDER': 0,
    'EINVOICE': 0,
    'WAREHOUSE_NOTE': 0,
    'REVERSE_CHARGE': parseFloat(cash || 0),
    'SELF_INVOICE': parseFloat(cash || 0),
    'OTHER': 0,
  }

  const saveNewOrder = () => {
    const newObj = { ...order }
    saveOrder({
      variables: {
        locationId: locationId,
        cashRegisterId: deviceId,
        orderInput: {
          status: 'SAVE',
          locationId,
          ...clearExtraDetails(newObj),
        },
      },
    })
      .catch((error) => {
        const errorMessage = getErrorMessage(error)
        if (errorMessage === 'OrderNoOpenDrawer') {
          displayError(
            'receipt_drawer_warning_title',
            'checkout_drawer_error_message',
            'receipt_button_open_drawer',
            () => {
              navigate('CurrentDrawer', { nextAction: 'drawer_continue_to_payment' })
              dismissError()
            },
            'orders_button_go_back',
            () => dismissError(),
            false,
          )
        } else if (errorMessage === 'TaxpayerDoesNotExist') {
          displayError(
            'einvoice_opcode_error_title',
            'einvoice_opcode_error_description',
            'einvoice_opcode_error_button',
            () => {
              navigate('UserDetails', { id })
              dismissError()
            },
            'einvoice_cancel_modal_button',
            () => dismissError(),
            false,
          )
        } else {
          displayError(
            'checkount_error_message',
            errorMessage,
            'orders_button_go_back',
            () => dismissError(),
            '',
            undefined,
            false,
          )
        }
      })
  }

  useEffect(() => {
    if (data?.createOrder?.id) {
      countSavedOrders()
      clearOrder()
      dispatch((state) => {
        const routes = state.routes?.filter((route) => route.name !== 'Cart')
        return CommonActions.reset({
          ...state,
          routes,
          index: routes.length - 1,
        })
      })
      goBackCustom()
    }
  }, [data])

  useEffect(() => {
    if (otherCurrency) {
      updateProcess('currency', otherCurrency?.currencyCode)
    }
  }, [otherCurrency])

  useEffect(() => {
    if (invoicingMethod) {
      if (invoicingMethod === 'CASH') {
        setOrderType({
          code: 'CASH',
          description: 'payment_self_charge_cash_description',
          type: 'CASH',
        })
        setPaymentMethods(PAYMENT_METHODS.CASH)
        setPaymentMethod(DEFAULT_PAYMENT_METHOD.CASH)
      } else if (invoicingMethod === 'NONCASH') {
        setOrderType({
          code: 'NONCASH',
          description: 'payment_self_charge_noncash_description',
          type: 'NONCASH',
        })
        setPaymentMethods(PAYMENT_METHODS.NONCASH)
      } else if (invoicingMethod === 'EINVOICE') {
        setOrderType({
          code: 'NONCASH',
          description: 'payment_self_charge_noncash_description',
          type: 'NONCASH',
        })
        setLoading(true)
        const newObj = { ...order }
        convertToUbl({
          variables: {
            locId: locationId,
            cashRegisterId: deviceId,
            order: {
              status: 'SAVE',
              ...clearExtraDetails(newObj),
            },
          },
        })
      } else if (invoicingMethod === 'WAREHOUSE_NOTE') {
        setOrderType({
          code: 'NONCASH',
          description: 'payment_self_charge_noncash_description',
          type: 'NONCASH',
        })
        setLoading(true)
        const newObj = { ...order }
        convertToWtn({
          variables: {
            order: {
              status: 'SAVE',
              ...clearExtraDetails(newObj),
            },
          },
        })
      } else if (invoicingMethod === 'EXPORT_OF_GOODS') {
        setOrderType({
          code: 'NONCASH',
          description: 'payment_self_charge_noncash_description',
          type: 'NONCASH',
        })
        setPaymentMethods(PAYMENT_METHODS.EXPORT_OF_GOODS)
      } else if (invoicingMethod === 'REVERSE_CHARGE') {
        setPaymentMethods(PAYMENT_METHODS.REVERSE_CHARGE)
      }
    }
  }, [invoicingMethod])

  useEffect(() => {
    if (ublData?.orderToUbl) {
      const otherData = ublData?.orderToUbl
      const mappedEinvoiceData = einvoicemapper(einvoiceData)
      const ubl = {
        ...mappedEinvoiceData,
        ...otherData,
      }
      storeLineItems(otherData)
      calcUbl({
        variables: {
          ublInput: {
            ublInvoice: {
              ...ubl,
            },
          },
        },
      })
        .then((res) => {
          dispatchAction(updateCalculatedUBL(res.data.calculateUbl.ublInvoice))
          setLoading(false)
        })
        .catch(() => { })
    }
  }, [ublData])

  useEffect(() => {
    const supportiveData = wtnData?.orderToWarehouseTransferNote
    if (supportiveData) {
      updateStep('total', supportiveData?.valueOfGoods)
      updateStep('items', supportiveData?.items)
    }
  }, [wtnData])

  let rate = otherCurrency?.rate
  if (currency.currencyCode === otherCurrency.currencyCode) {
    rate = 1
  }

  const disabled = (isNaN(parseFloat(cash)) || (parseFloat(cash) < parseFloat(total / rate)?.toFixed(2)) || !invoicingMethod) && invoicingMethod !== 'EINVOICE'

  const getBanknoteCounts = (amount = 0) => {
    const banknoteCounts = []
    AMOUNT_VALUES?.[otherCurrency?.currencyCode]?.forEach(value => {
      banknoteCounts.unshift({
        amount: value,
        count: parseInt(amount / value, 10),
      })
      amount %= value
    })
    return banknoteCounts
  }

  const banknotesShift = (banknoteCounts = []) => {
    const length = banknoteCounts.length
    let found = false
    for (let i = 0; i < length; i++) {
      const banknote = banknoteCounts[i]
      if (!found) {
        if (banknote.count !== 0 && i !== length - 1) {
          banknoteCounts[i].count = 0
          banknoteCounts[i + 1].count += 1
          found = true
        }
      } else {
        if (i !== length - 1 && (banknote?.amount * banknote.count) >= banknoteCounts[i + 1]?.amount) {
          banknoteCounts[i].count = 0
          banknoteCounts[i + 1].count += 1
        }
      }
    }
    return banknoteCounts
  }

  const reducer = (acc, curr) => acc + curr?.amount * curr.count
  const getBanknotesSum = (banknoteCounts = []) => {
    return banknoteCounts.reduce(reducer, 0)
  }

  const nextSuggestion = amount => {
    const banknoteCounts = getBanknoteCounts(amount)
    const nextBanknoteCounts = banknotesShift(banknoteCounts)
    return getBanknotesSum(nextBanknoteCounts)
  }

  const getOptions = (amount) => {
    const res = [amount.toFixed(2)]
    for (let i = 0; i < NUM_OF_SUGGESTIONS; i++) {
      const suggestion = nextSuggestion(res[i])
      if (suggestion === res[i]) {
        break
      }
      res.push(suggestion.toFixed(2))
    }
    setOptions(res)
  }

  useEffect(() => {
    getOptions(parseFloat((total / rate) || 0))
  }, [otherCurrency, rate])

  const onCashPay = () => setPaymentStatus(PAYMENT_STEPS.paying)

  const onSupportiveProceed = () => {
    saveSupportInv({
      variables: {
        locId: locationId,
        cashRegisterId: deviceId,
        warehouseNoteInput: getWTNSaveInput(allInvoice),
      },
    })
      .then((res) => {
        if (!wtnInvoiceId) {
          const newId = noteMapper(res?.data?.saveWarehouseNote?.note)
          dispatchAction(supportInvoiceNewInvoice(newId))
        }
        setPaymentStatus(SUPPORTIVE_EINVOICE_PAYMENT_STEPS.transportInfo)
      })
      .catch(e => {
      })
  }

  const onNonCashProceed = () => {
    const paymentMeans = convertPaymentMeans(initData)
    const newUbl = {
      ...calculatedUBL,
      ...paymentMeans,
      // note: [`CurrencyExchangeRate=${otherCurrency.rate}`].concat(calculatedUBL.note || []),
    }

    dispatchAction(updateCalculatedUBL(newUbl))
    if (invoiceID) {
      dispatchAction(updateCalculatedUBL({
        ...newUbl,
        note: [`id=${invoiceID}`, `CurrencyExchangeRate=${otherCurrency.rate}`],
      }))
      saveEinvoice({
        variables: {
          locId: locationId,
          cashRegisterId: deviceId,
          eInvoiceInput: {
            ublInvoice: {
              ...newUbl,
              note: [`id=${invoiceID}`, `CurrencyExchangeRate=${otherCurrency.rate}`],
            },
          },
        },
      })
        .then(() => {
          LayoutAnimation.configureNext(opacityConfig)
          setPaymentStatus(EINVOICE_PAYMENT_STEPS.process)
        })
    } else {
      saveEinvoice({
        variables: {
          locId: locationId,
          cashRegisterId: deviceId,
          eInvoiceInput: {
            ublInvoice: newUbl,
          },
        },
      })
        .then((res) => {
          const response = res?.data?.saveEinvoice?.ublInvoice
          const invId = noteMapper(response.note)
          if (invId) {
            dispatchAction(updateCalculatedUBL({
              ...newUbl,
              note: [`id=${invId}`, `CurrencyExchangeRate=${otherCurrency.rate}`],
            }))
            dispatchAction(newInvoice(invId))
            LayoutAnimation.configureNext(opacityConfig)
            setPaymentStatus(EINVOICE_PAYMENT_STEPS.process)
          }
        })
    }
  }

  const replaceAt = (string, i, replacement) => {
    return string.substr(0, i) + replacement + string.substr(i + replacement.length)
  }

  const changeExRate = (text) => {
    let newString = text
    const indexOfComa = text.indexOf(',')
    if (indexOfComa >= 0) {
      newString = replaceAt(text, indexOfComa, '.')
    }
    if (/^\d{0,3}(\.\d{0,2})?$/.test(newString)) {
      setOtherCurrency((prev) => {
        return {
          ...prev,
          rate: newString,
        }
      })
    }
  }

  const onCashSavePress = () => saveNewOrder()

  const onNonCashSavePress = () => {
    clearOrder()
    goBackCustom()
  }

  return (
    <>
      <Header
        title="checkout_payment_label"
        titlePlaceHolders={placeholders?.[invoicingMethod]?.titlePlaceHolders || []}
        image={images.closeIcon}
        onPress={goBackCustom}
      />
      <ScrollView
        contentContainerStyle={styles.fillRemaining}
        showsVerticalScrollIndicator={false}
      >
        <PaymentMethods
          invoicingMethods={invoicingMethods}
          invoicingMethod={invoicingMethod}
          setInvoicingMethod={setInvoicingMethod}
          setOtherCurrency={setOtherCurrency}
        />
        <View style={styles.totalAndChangeWrapper}>
          <TotalAndChange
            total={parseFloat(total || 0)}
            cash={changeAmounts[invoicingMethod]}
            secondaryCurrency={otherCurrency?.type}
            rate={otherCurrency?.rate}
          />
        </View>
        <View style={styles.configWrapper}>
          {invoicingMethod === 'WAREHOUSE_NOTE' || (otherCurrency.type === 'Lek' && invoicingMethod === 'EINVOICE') ? null :
            <SecondaryCurrency
              currencies={currencies}
              currency={otherCurrency}
              setCurrency={setOtherCurrency}
            />
          }
          {invoicingMethod === 'EINVOICE' ? null : <TouchableInput
            style={styles.rightConfigInput}
            onPress={() => openConfigurationSelector()}
            label="payment_receipt_input_header"
            value={`${receiptFormat} - ${translate(receiptLanguage.label)}`}
            icon={images.filledDownArrow}
            iconSize={16}
            autoCorrect={false}
            autoCapitalize="none"
            editable={false}
          />}
        </View>
        {(invoicingMethod === 'WAREHOUSE_NOTE'
          || otherCurrency.type === 'Lek'
          || (otherCurrency.type === 'Lek' && invoicingMethod === 'EINVOICE')) ? null :
          <InputRow
            label="payment_exchange_rate_label"
            keyboardType="numeric"
            autoCapitalize="none"
            value={otherCurrency?.rate}
            onChangeText={changeExRate}
            maxLength={50}
            style={styles.inputWrapper(invoicingMethod === 'EINVOICE')}
          />
        }
        <InputContent
          invoicingMethod={invoicingMethod}
          euRate={euRate}
          options={options}
          setDisabled={setDisabled}
          navigate={navigate}
          currencies={currencies}
          currency={otherCurrency}
          setCurrency={setOtherCurrency}
          paymentMethods={paymentMethods}
        />
      </ScrollView>
      <View>
        <FooterContent
          onCashPay={onCashPay}
          onNonCashProceed={onNonCashProceed}
          onCashSavePress={onCashSavePress}
          onNonCashSavePress={onNonCashSavePress}
          onSupportiveProceed={onSupportiveProceed}
          loading={loading}
          disabled={disabled}
          ublLoading={ublLoading}
          saveLoading={saveLoading}
          supportiveDisabled={supportiveDisabled}
          supportInvLoading={supportInvLoading}
        />
      </View>
    </>
  )
}
Init.propTypes = {
  invoicingMethods: PropTypes.array,
  invoicingMethod: PropTypes.string,
  setInvoicingMethod: PropTypes.func,
  navigate: PropTypes.func,
  goBackCustom: PropTypes.func,
  dispatch: PropTypes.func,
  stepperLength: PropTypes.number,
  index: PropTypes.number,
  otherCurrency: PropTypes.object,
  setOtherCurrency: PropTypes.func,
  paymentMethods: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  setPaymentMethods: PropTypes.func,
}

export default Init
