import {styleSheetFactory} from '../../Themes/Colors'

const styles = styleSheetFactory((colors) => ({
  loadingContainer: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    backgroundColor: colors.blackRGBA(0.8),
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000,
    elevation: 4,
  },
  message: {
    color: colors.white,
  },
}))

export default styles
