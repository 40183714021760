import { styleSheetFactory } from '../../../../../Themes/Colors'

const styles = styleSheetFactory(colors => ({
  container: {
    paddingVertical: 7.5,
  },
  itemSelected_false: {
    flexDirection: 'row',
    marginHorizontal: 15,
    borderRadius: 10,
    paddingVertical: 10,
    paddingHorizontal: 15,
    backgroundColor: colors.white,
    alignItems: 'center',
    height: 60,
  },
  itemSelected_true: {
    flexDirection: 'row',
    marginHorizontal: 15,
    borderRadius: 10,
    paddingVertical: 10,
    paddingHorizontal: 15,
    backgroundColor: colors.white,
    alignItems: 'center',
    height: 60,
    borderWidth: 1,
    borderColor: colors.secondaryAccent,
  },
  itemTextSelected_false: {
    flexGrow: 1,
    flexShrink: 1,
  },
  itemTextSelected_true: {
    flexGrow: 1,
    flexShrink: 1,
  },
}))

export default styles
