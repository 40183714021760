/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react'
import { View } from 'react-native'
import PropTypes from 'prop-types'
import { useTheme } from 'react-native-themed-styles'


import Text from '../Text'
import Button from '../Button'
import Icon from '../Icon'
import Loader from '../Loader'

import ContentManager from '../../Utils/ContentManager'

import style from './RetryBanner.styles'
import { animations, colors, images } from '../../Themes'


const RetryBanner = forwardRef(({ containerStyle, onRetry, status, code, message, loading }, ref) => {
    const [styles] = useTheme(style)
    const { translate } = ContentManager
    const [isVisible, setIsVisible] = useState(false)
    const [statusChange, setStatusChange] = useState(0)

    useEffect(() => {
        if (status === 'REJECTED' && code) {
            // LayoutAnimation.configureNext(layoutAnimConfig)
            setIsVisible(true)
        } else {
            setIsVisible(false)
        }
    }, [statusChange])

    //Function to call from parent (When the mutation return success)
    useImperativeHandle(
        ref,
        () => ({
            closeBanner() {
                setIsVisible(false)
            },
            changeStatus() {
                setStatusChange(prev => prev + 1)
            },
        }),
    )

    if (!isVisible) { return null }
    return (
        <View style={[styles.container, containerStyle]}>
            <View style={styles.subContainer}>
                {!loading && <Icon source={images.attention} color={colors.white} />}
                <View style={styles.descriptionContainer}>
                    <Text weight="regular" size="h5" color={colors.white} i18nKey={loading ? 'invoice_synchronising_label' : `${translate('invoice_status_failed')} (${code})`} />
                    {!loading && <Text weight="light" size="body" color={colors.white} i18nKey={message} numberOfLines={3} />}
                </View>
            </View>
            <Button
                disabled={loading}
                title="Retry"
                iconColor={colors.secondaryAccent}
                icon={images.sync}
                backgroundColor={colors.white}
                color={colors.secondaryAccent}
                style={styles.retryButton}
                iconStyle={styles.syncIcon}
                onPress={onRetry}
                loader={loading}
                loaderComponent={<Loader source={animations.vfLoaderThinDark} size={24} />}
            />
            {/* <TouchableOpacity style={styles.closeIconTouch} onPress={onClose} >
                <Icon source={images.close} color={colors.white} size={18} />
            </TouchableOpacity> */}
        </View>
    )
})

RetryBanner.defaultProps = {
    onRetry: () => { },
    status: '',
    loading: false,
    message: '',


}
RetryBanner.propTypes = {
    containerStyle: PropTypes.object,
    onRetry: PropTypes.func,
    status: PropTypes.string,
    loading: PropTypes.bool,
    code: PropTypes.string,
    message: PropTypes.string,

}

export default RetryBanner

