import { styleSheetFactory } from '../../Themes/Colors'

const styles = styleSheetFactory(colors => ({
  container: {
    top: -7,
    marginHorizontal: -20,
  },
  contentContainer: {
    paddingHorizontal: 15,
  },
}))

const suggestionStyles = styleSheetFactory(colors => ({
  containerSelected_false: {
    marginHorizontal: 5,
    borderWidth: 1,
    borderColor: colors.disabled,
    borderRadius: 7,
    marginBottom: 16,
    minWidth: 60,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
  },
  containerSelected_true: {
    marginHorizontal: 5,
    borderWidth: 1,
    borderColor: colors.secondaryAccent,
    borderRadius: 7,
    marginBottom: 16,
    minWidth: 60,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
  },
  touchableSelected_false: {
    paddingHorizontal: 8,
    paddingVertical: 4,
  },
  touchableSelected_true: {
    paddingHorizontal: 8,
    paddingVertical: 4,
  },
  textSelected_false: {
    color: colors.secondaryText,
  },
  textSelected_true: {
    color: colors.secondaryAccent,
  },
  iconSelected_false: {
    tintColor: colors.secondaryText,
  },
  iconSelected_true: {
    tintColor: colors.secondaryAccent,
  },
  dividerSelected_false: {
    width: 1,
    height: 24,
    backgroundColor: colors.disabled,
  },
  dividerSelected_true: {
    width: 1,
    height: 24,
    backgroundColor: colors.secondaryAccent,
  },
}))

export {
  suggestionStyles,
}
export default styles
