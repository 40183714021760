import { styleSheetFactory } from '../../../../Themes/Colors'

const styles = styleSheetFactory(() => ({
  container: {
    flexGrow: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
}))

export default styles
