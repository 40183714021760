import { gql } from '@apollo/client'


export const CREATE_CUSTOMER = gql`
  mutation createCustomer(
    $emailAddress: String
    $phoneNumber: String
    $givenName: String
    $familyName: String
    $businessName: String
    $issuerNUIS: String
    $addressLine1: String
    $country: String
    $administrativeDistrictLevel1: String
    $birthday: String
    $emailUnsubscribed: Boolean
    $customerGroupsToEnable: [String]
    $type: CustomerType!
    $reference: ReferenceInput
    ){
    createCustomer(customerInput:{
      emailAddress: $emailAddress,
      phoneNumber: $phoneNumber,
      givenName: $givenName,
      familyName: $familyName,
      businessName: $businessName,
      issuerNUIS: $issuerNUIS,
      address: {
        addressLine1: $addressLine1,
        country: $country,
        administrativeDistrictLevel1: $administrativeDistrictLevel1,
      }
      birthday: $birthday,
      preferences: {
        emailUnsubscribed: $emailUnsubscribed
      },
      customerGroupsToEnable: $customerGroupsToEnable
      type: $type
      reference: $reference
    }){
      id
    }
  }
`

export const CREATE_SUPPLIER = gql`
  mutation createSupplier(
    $emailAddress: String
    $phoneNumber: String
    $givenName: String
    $familyName: String
    $businessName: String
    $issuerNUIS: String
    $addressLine1: String
    $country: String
    $administrativeDistrictLevel1: String
    $birthday: String
    $emailUnsubscribed: Boolean
    $supplierGroupsToEnable: [String]
    $type: SupplierType!
    $reference: ReferenceInput
    ){
    createSupplier(supplierInput:{
      emailAddress: $emailAddress,
      phoneNumber: $phoneNumber,
      givenName: $givenName,
      familyName: $familyName,
      businessName: $businessName,
      issuerNUIS: $issuerNUIS,
      address: {
        addressLine1: $addressLine1,
        country: $country,
        administrativeDistrictLevel1: $administrativeDistrictLevel1,
      }
      birthday: $birthday,
      preferences: {
        emailUnsubscribed: $emailUnsubscribed
      },
      supplierGroupsToEnable: $supplierGroupsToEnable
      type: $type
      reference: $reference
    }){
      id
    }
  }
`

export const EDIT_CUSTOMER = gql`
  mutation updateCustomer(
    $id: String!
    $emailAddress: String,
    $phoneNumber: String,
    $givenName: String,
    $familyName: String,
    $businessName: String,
    $issuerNUIS: String,
    $addressLine1: String,
    $country: String,
    $administrativeDistrictLevel1: String,
    $birthday: String,
    $emailUnsubscribed: Boolean,
    $customerGroupsToEnable: [String],
    $customerGroupsToDelete: [String]
    $reference: ReferenceInput
  ){
    updateCustomer(customerUpdateInput:{
      id: $id,
      emailAddress: $emailAddress,
      phoneNumber: $phoneNumber,
      givenName: $givenName,
      familyName: $familyName,
      businessName: $businessName,
      issuerNUIS: $issuerNUIS,
      address: {
        addressLine1: $addressLine1,
        country: $country,
        administrativeDistrictLevel1: $administrativeDistrictLevel1,
      }
      birthday: $birthday,
      preferences: {
        emailUnsubscribed: $emailUnsubscribed
      },
      customerGroupsToEnable: $customerGroupsToEnable
      customerGroupsToDelete: $customerGroupsToDelete
      reference: $reference
    }){
      id
    }
  }
`
export const EDIT_SUPPLIER = gql`
  mutation updateSupplier(
    $id: String!
    $emailAddress: String,
    $phoneNumber: String,
    $givenName: String,
    $familyName: String,
    $businessName: String,
    $issuerNUIS: String,
    $addressLine1: String,
    $country: String,
    $administrativeDistrictLevel1: String,
    $birthday: String,
    $emailUnsubscribed: Boolean,
    $supplierGroupsToEnable: [String],
    $supplierGroupsToDelete: [String]
    $reference: ReferenceInput
  ){
    updateSupplier(supplierUpdateInput:{
      id: $id,
      emailAddress: $emailAddress,
      phoneNumber: $phoneNumber,
      givenName: $givenName,
      familyName: $familyName,
      businessName: $businessName,
      issuerNUIS: $issuerNUIS,
      address: {
        addressLine1: $addressLine1,
        country: $country,
        administrativeDistrictLevel1: $administrativeDistrictLevel1,
      }
      birthday: $birthday,
      preferences: {
        emailUnsubscribed: $emailUnsubscribed
      },
      supplierGroupsToEnable: $supplierGroupsToEnable
      supplierGroupsToDelete: $supplierGroupsToDelete
      reference: $reference
    }){
      id
    }
  }
`

export const REMOVE_CUSTOMER_GROUP = gql`
  mutation updateCustomer(
    $id: String!
    $customerGroupsToDelete: [String]
  ){
    updateCustomer(customerUpdateInput:{
      id: $id,
      customerGroupsToDelete: $customerGroupsToDelete
    }){
      id
    }
  }
`

export const REMOVE_SUPPLIER_GROUP = gql`
  mutation updateSupplier(
    $id: String!
    $supplierGroupsToDelete: [String]
  ){
    updateSupplier(supplierUpdateInput:{
      id: $id,
      supplierGroupsToDelete: $supplierGroupsToDelete
    }){
      id
    }
  }
`

export const GET_INDIVID_CUSTOMER = gql`
  query getCustomer($id: String!, $limit: Int, $nextToken: String) {
    getCustomer(getCustomerInput: {
      id: $id,
      listCustomerGroupsInput: {
        limit: $limit,
        nextToken: $nextToken,
      }
    }) {
      customer {
        givenName
        familyName
        emailAddress
        address {
          addressLine1
          country
          administrativeDistrictLevel1
        }
        phoneNumber
        birthday
        preferences {
          emailUnsubscribed
        }
        reference {
          name
          value
        }
      },
      listCustomerGroups {
        customerGroups {
          id,
          name,
          labelColor,
          type
        }
        nextToken
      }
    },
  }
`

export const GET_INDIVID_SUPPLIER = gql`
  query getSupplier($id: String!, $limit: Int, $nextToken: String) {
    getSupplier(getSupplierInput: {
      id: $id,
      listSupplierGroupsInput: {
        limit: $limit,
        nextToken: $nextToken,
      }
    }) {
      supplier {
        givenName
        familyName
        emailAddress
        address {
          addressLine1
          country
          administrativeDistrictLevel1
        }
        phoneNumber
        birthday
        preferences {
          emailUnsubscribed
        }
        reference {
          name
          value
        }
      },
      listSupplierGroups {
        supplierGroups {
          id,
          name,
          labelColor,
          type
        }
        nextToken
      }
    },
  }
`

export const GET_BUSINESS_CUSTOMER = gql`
  query getCustomer($id: String!, $limit: Int, $nextToken: String) {
    getCustomer(getCustomerInput: {
      id: $id,
      listCustomerGroupsInput: {
        limit: $limit,
        nextToken: $nextToken,
      }
    }) {
      customer {
        issuerNUIS
        businessName
        emailAddress
        address {
          addressLine1
          country
          administrativeDistrictLevel1
        }
        phoneNumber
        preferences {
          emailUnsubscribed
        }
      },
      listCustomerGroups {
        customerGroups {
          id,
          name,
          labelColor,
          type
        }
        nextToken
      }
    },
  }
`

export const GET_BUSINESS_SUPPPLIER = gql`
  query getSupplier($id: String!, $limit: Int, $nextToken: String) {
    getSupplier(getSupplierInput: {
      id: $id,
      listSupplierGroupsInput: {
        limit: $limit,
        nextToken: $nextToken,
      }
    }) {
      supplier {
        issuerNUIS
        businessName
        emailAddress
        address {
          addressLine1
          country
          administrativeDistrictLevel1
        }
        phoneNumber
        preferences {
          emailUnsubscribed
        }
      },
      listSupplierGroups {
        supplierGroups {
          id,
          name,
          labelColor,
          type
        }
        nextToken
      }
    },
  }
`

export const GET_BUSINESS_DETAILS = gql`
  query getBusinessGov($name: String!, $value: String!) {
    getBusinessGov(searchBy: {
      name: $name,
      value: $value
    }) {
      customers {
        id
        issuerNUIS
        businessName
        address {
          addressLine1
          country
          administrativeDistrictLevel1
        }
      },
    },
  }
`
