import { styleSheetFactory } from '../../../../../Themes/Colors'

const styles = styleSheetFactory(colors => ({
  sectionText: {
    marginTop: 20,
    marginBottom: 5,
    marginHorizontal: 15,
  },
  sectionText2: {
    marginBottom: 5,
    marginHorizontal: 15,
  },
  exchangeRate: {
    justifyContent: 'flex-start',
    marginBottom: 24,
    marginHorizontal: 15,
  },
  cashPayment: {
    marginBottom: 16,
    marginHorizontal: 15,
  },
  tagsContentContainer: {
    paddingHorizontal: 10,
  },
  customerHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  customerContainer: {
    marginHorizontal: 15,
  },
  customerData: {
    padding: 15,
    backgroundColor: colors.white,
    borderRadius: 7,
    marginVertical: 10,
  },
  email: {
    marginHorizontal: 15,
    marginBottom: 16,
  },
  topContent: {
    paddingHorizontal: 15,
  },
  sectionTitle: {
    marginBottom: 5,
  },
  content: {
    flexGrow: 1,
    paddingHorizontal: 16,
    paddingVertical: 15,
  },
  addCustomer: {
    height: 50,
    marginVertical: 5,
  },
  customerHeader2: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 15,
    marginBottom: 10,
  },
  removeBuyer: {
    borderRadius: 18,
    padding: 5,
    backgroundColor: colors.separatorBody,
    alignSelf: 'flex-end',
    alignItems: 'flex-end',
  },
  cashHelper: {
    color: colors.accent,
    paddingHorizontal: 15,
    marginBottom: 10,
  },
  bottomSpacing: { marginBottom: 0 },
  rightSpacing: { marginRight: 10 },
}))

export default styles
