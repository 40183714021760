import { styleSheetFactory } from '../../../../../Themes/Colors'

const styles = styleSheetFactory(colors => ({
  sectionTitle: {
    marginHorizontal: 15,
    marginTop: 15,
    marginBottom: 5,
  },
  container: {
    maxHeight: 90,
    marginHorizontal: 10,
    flexDirection: 'row',
  },
  contentContainer: {
    flex: 1,
  }
}))

export default styles
