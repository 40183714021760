import { styleSheetFactory } from '../../../../Themes/Colors'

const styles = styleSheetFactory(colors => ({
  fillRemaining: {
    flexGrow: 1,
    paddingBottom: 20,
  },
  buttons: {
    flexDirection: 'row',
    marginBottom: 15,
    marginTop: 10,
    marginHorizontal: 15,
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  issuerSection: {
    marginHorizontal: 15,
    marginBottom: 20,
  },
  leftButton: {
    flex: 1,
    height: 50,
  },
  rightButton: {
    flex: 1,
    height: 50,
    marginRight: 15,
  },
  topContent: {
    paddingHorizontal: 15,
  },
  sectionTitle: {
    marginBottom: 10,
  },
  sectionNote: {
    marginTop: 5,
    marginBottom: 16,
  },
  currency: {
    marginBottom: 16,
  },
  rightButtonIcon: {
    marginLeft: 10,
  },
  endDateInput: {
    marginBottom: 0,
  },
  singleLoader: {
    backgroundColor: colors.white,
    borderBottomColor: colors.separator,
    borderBottomWidth: 1,
    height: 60,
  },
  loaderContainer: (isLast = false) => ({
    backgroundColor: colors.white,
    borderBottomColor: colors.separator,
    borderBottomWidth: isLast ? 0 : 1,
    height: 60,
  }),
  loaderWrapper: {
    borderRadius: 7,
    overflow: 'hidden',
    marginHorizontal: 15,
  },
  buttonTitle: {
    flexShrink: 1,
  },
  personalIdHelper: {
    marginTop: 5,
    marginBottom: 20,
    textAlign: 'left',
    fontFamily: 'Vodafone Rg',
    fontSize: 14,
    color: colors.secondaryText,
  },
  personalIdHelperItalic: {
    fontStyle: 'italic',
  },
  errorContainer: {
    backgroundColor: colors.white,
    alignItems: 'center',
    borderRadius: 10,
    paddingVertical: 15,
    marginHorizontal: 15,
  },
  icon: {
    marginBottom: 10,
  },
  errorRetry: {
    color: colors.red,
  },
}))

export default styles
