import React, { useContext } from 'react'
import { View, TouchableOpacity } from 'react-native'

import PropTypes from 'prop-types'

import { useTheme } from 'react-native-themed-styles'

import Text from '../Text'

import Theme from '../../Themes/Theme'

import themedstyles from './ButtonGroup.Styles'

function ButtonGroup(props) {

  const [styles] = useTheme(themedstyles)
  const { colors } = useContext(Theme)
  const { onPress, selected, options, style, buttonStyle } = props

  return (
    <View style={[styles.container, style]} >
      {options.map((element, index) => {
        const active = element.type === selected
        const disabled = element.disabled === true
        return (
          <TouchableOpacity
            id={index}
            key={index}
            onPress={() => onPress(element.type)}
            style={[styles.button(active), styles[`button_${disabled}`], buttonStyle(active)]}
            disabled={disabled}
          >
            <Text
              i18nKey={element.title}
              color={active || disabled ? colors.white : colors.primaryText}
              size="footnote"
              weight="light"
              align="center"
              style={styles.label}
            />
          </TouchableOpacity>
        )
      })}
    </View>
  )
}

ButtonGroup.defaultProps = {
  buttonStyle: () => {},
}

ButtonGroup.propTypes = {
  setSelected: PropTypes.func,
  selected: PropTypes.string,
  options: PropTypes.array,
  style: PropTypes.object,
  onPress: PropTypes.func,
  buttonStyle: PropTypes.func,
}

export default ButtonGroup

