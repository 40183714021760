import { StyleSheet } from 'react-native'
import Color from 'color'

import { styleSheetFactory } from '../../Themes/Colors'

const styles = styleSheetFactory(colors => ({
  detailsContainer: {
    flexGrow: 1,
    // marginHorizontal: 15,
    // marginTop: 15,
    justifyContent: 'space-between',
  },
  inputWrapper: {
    marginVertical: 5,
  },
  inputContainer: {
    minHeight: 60,
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  container: {
    flex: 1,
  },
  sectionTitle: {
    marginTop: 15,
    marginBottom: 5,
  },
  sectionDescription: {
    marginBottom: 5,
  },
  listStyle: {
    flex: 1,
  },
  listContainerStyle: {
    flexGrow: 1,
    paddingHorizontal: 15,
    // paddingTop: 10,
    paddingBottom: 30,
  },
  fab: {
    position: 'absolute',
    bottom: 30,
    right: 15,
    height: 50,
    width: 50,
    borderRadius: 25,
    backgroundColor: colors.background,
    shadowColor: colors.black,
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.22,
    shadowRadius: 2.22,
    elevation: 3,
  },
  fabTouchable: {
    height: 50,
    width: 50,
    borderRadius: 25,
    backgroundColor: colors.tertiaryAccent,
    justifyContent: 'center',
    alignItems: 'center',
  },
  fabIcon: {
    tintColor: colors.white,
  },
  tabView: {
    backgroundColor: colors.background,
    paddingTop: 10,
  },
  button: {
    marginHorizontal: 15,
    marginBottom: 15,
  },
  virtualButton: {
    marginBottom: 15,
  },
  buttonWrap: {
    flex: 1,
    flexGrow: 1,
    justifyContent: 'flex-end',
  },
  deviceInfoContainer: {
    backgroundColor: colors.white,
    padding: 15,
    borderRadius: 7,
  },
  invoiceInfoContainer: {
    backgroundColor: colors.white,
    padding: 15,
    borderRadius: 7,
    marginVertical: 15,
  },
  deviceInfoRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingVertical: 3,
  },
  textColumn: (alignRight = false) => {
    return {
      flex: 1,
      textAlign: alignRight ? 'right' : 'left',
    }
  },
  busInfoHeader: {
    marginBottom: 5,
  },
  editBusinessButton: {
    marginTop: 10,
  },
  infoText: {
    marginBottom: 15,
    marginTop: 5,
    textAlign: 'left',
    fontFamily: 'Vodafone Rg',
    fontSize: 16,
    color: colors.secondaryText,
  },
  boldText: {
    fontWeight: 'bold',
    color: colors.black,
  },
  businInfoContainer: {
    flex: 1,
    marginHorizontal: 15,
    marginTop: 15,
    backgroundColor: colors.background,
  },
}))

const itemStyles = styleSheetFactory(colors => ({
  container: {
    backgroundColor: colors.tertiaryBackground,
    height: 70,
    // marginVertical: 3,
    // borderRadius: 7,
    overflow: 'hidden',
  },
  firstItem_true: {
    borderTopLeftRadius: 7,
    borderTopRightRadius: 7,
    overflow: 'hidden',
  },
  lastItem_true: {
    borderBottomLeftRadius: 7,
    borderBottomRightRadius: 7,
    overflow: 'hidden',
  },
  touchable: {
    flexGrow: 1,
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
  },
  logo: {
    width: 70,
    height: 70,
    backgroundColor: Color(colors.primaryText)
      .fade(0.65)
      .toString(),
    justifyContent: 'center',
    alignItems: 'center',
  },
  detailsContainer: {
    flexGrow: 1,
    flexShrink: 1,
    paddingHorizontal: 15,
  },
  rightElement: {
    flexShrink: 0,
    marginRight: 15,
  },
  selector: {
    marginRight: 15,
  },
}))

const locationStyles = styleSheetFactory(colors => ({
  mylocationContainer: {
    flexGrow: 1,
    minHeight: 232,
    marginVertical: 5,
    backgroundColor: colors.tertiaryBackground,

    borderRadius: 7,
  },
  label: {
    marginTop: 15,
    marginHorizontal: 15,
    paddingBottom: 15,
    justifyContent: 'center',
    alignItems: 'center',
    borderBottomWidth: 1,
    borderBottomColor: colors.separator,
  },
  mapContainer: width => {
    return {
      position: 'relative',
      width: width - 60,
      height: 200,
      marginTop: 15,
      marginHorizontal: 15,
      marginBottom: 15,
      backgroundColor: colors.tertiaryBackground,
      overflow: 'hidden',
    }
  },
  map: {
    ...StyleSheet.absoluteFillObject,
  },
  currentLocationButton: {
    padding: 15,
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    maxHeight: 50,
  },
  currentLocationText: {
    paddingBottom: 3,
  },
  confirmButton: {
    margin: 15,
    marginBottom: 30,
  },
}))

const separatorStyles = styleSheetFactory(colors => ({
  container: {
    height: 1,
    backgroundColor: colors.separator,
  },
}))

export { itemStyles, locationStyles, separatorStyles }
export default styles
