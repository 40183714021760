/* eslint-disable react-hooks/exhaustive-deps */
import React, { memo, useState, useContext, useEffect, useRef } from 'react'
import { SafeAreaView, View, TouchableOpacity, Animated, Easing, ImageBackground } from 'react-native'
import { useTheme } from 'react-native-themed-styles'
import { useLazyQuery } from '@apollo/client'
import PropTypes from 'prop-types'

import {
  Header,
  Icon,
  Text,
  RadioInput,
  ListLoader,
  CheckBox,
  Button,
} from '../../Components'

import LIST_LOCATIONS from './Locations.Schema'
import Theme from '../../Themes/Theme'
import themedStyles, { itemStyles } from './Locations.Styles'
import images from '../../Themes/Images'

const AnimatedTouchable = Animated.createAnimatedComponent(TouchableOpacity)

const Item = memo(function Item({
  businessName,
  businUnitCode,
  isBusinessUnitList,
  address: {
    addressLine1,
  },
  logoUrl,
  onPress,
}) {
  const [styles] = useTheme(itemStyles)
  const { colors } = useContext(Theme)
  const scale = useRef(new Animated.Value(1)).current

  const animate = value => {
    Animated.spring(scale, {
      toValue: value,
      useNativeDriver: true,
    }).start()
  }

  return (
    <Animated.View style={[styles.container, { transform: [{ scale }] }]}>
      <TouchableOpacity
        style={styles.touchable}
        onPress={onPress}
        onPressIn={() => animate(1.05)}
        onPressOut={() => animate(1)}
        activeOpacity={0.7}
      >
        <ImageBackground source={logoUrl ? { url: logoUrl } : undefined} style={styles.logo}>
          {/* {!logoUrl && ( */}
          <Text i18nKey={businessName?.match(/\b(\w{1})/g)?.slice(0, 2)?.join('')?.toUpperCase()} size="h5" />
          {/* )} */}
        </ImageBackground>
        <View style={styles.detailsContainer}>
          <Text i18nKey={businessName} numberOfLines={1} />
          {isBusinessUnitList ? <Text
            i18nKey={businUnitCode}
            weight="light"
            color={colors.secondaryText}
            size="footnote"
          /> : Boolean(addressLine1) && <Text
            i18nKey={addressLine1}
            weight="light"
            color={colors.secondaryText}
            size="footnote"
          />}
        </View>
        <Icon source={images.arrow_right} style={styles.rightElement} />
      </TouchableOpacity>
    </Animated.View>
  )
})
Item.propTypes = {
  businessName: PropTypes.string,
  address: PropTypes.object,
  logoUrl: PropTypes.string,
  onPress: PropTypes.func,
}

function SelectableItem({
  id,
  businessName,
  address: {
    addressLine1,
  },
  logoUrl,
  selected,
  onPress,
  checked = false,
  multipleSelection = false,
}) {
  const [styles] = useTheme(itemStyles)
  const { colors } = useContext(Theme)
  const scale = useRef(new Animated.Value(1)).current

  const animate = value => {
    Animated.spring(scale, {
      toValue: value,
      useNativeDriver: true,
    }).start()
  }

  return (
    <Animated.View style={[styles.container, { transform: [{ scale }] }]}>
      <TouchableOpacity
        style={styles.touchable}
        onPress={onPress}
        onPressIn={() => animate(1.05)}
        onPressOut={() => animate(1)}
        activeOpacity={0.7}
      >
        <ImageBackground source={logoUrl ? { url: logoUrl } : undefined} style={styles.logo}>
          {/* {!logoUrl && ( */}
          <Text i18nKey={businessName?.match(/\b(\w{1})/g)?.slice(0, 2)?.join('')?.toUpperCase()} size="h5" />
          {/* )} */}
        </ImageBackground>
        <View style={styles.detailsContainer}>
          <Text i18nKey={businessName} numberOfLines={1} />
          {Boolean(addressLine1) && <Text
            i18nKey={addressLine1}
            weight="light"
            color={colors.secondaryText}
            size="footnote"
          />}
        </View>
        <View style={styles.rightElement} pointerEvents="none">
          {multipleSelection ?
            <CheckBox
              tintColors={styles.checkBoxTintColors}
              checked={checked}
              onValueChange={onPress}
              style={styles.checkBox}
              color={colors.secondaryAccent}
            /> :
            <RadioInput
              index={id}
              selected={selected}
              onPress={onPress}
              color={colors.secondaryAccent}
            />}
        </View>
      </TouchableOpacity>
    </Animated.View>
  )
}
SelectableItem.propTypes = {
  id: PropTypes.string,
  businessName: PropTypes.string,
  address: PropTypes.object,
  logoUrl: PropTypes.string,
  selected: PropTypes.string,
  onPress: PropTypes.func,
  checked: PropTypes.bool,
  multipleSelection: PropTypes.bool,
}

function Locations({
  route: {
    params: {
      selectable = false,
      multipleSelection = false,
      isBusinessUnitList = false,
      id,
      isVirtual,
      changeLocation = () => { },
      onSave = () => { },
      preselectedLocations = {},
    } = {},
  } = {},
  navigation: { goBack, navigate },
}) {
  const [styles] = useTheme(themedStyles)
  const { colors } = useContext(Theme)
  const [selected, setSelected] = useState(id)
  const [items, setItems] = useState([])
  const [loading, setLoading] = useState(true)
  const [selectedLocations, setSelectedLocations] = useState({})

  const listRef = useRef(null)
  const fabSize = useRef(new Animated.Value(0)).current

  const cursor = useRef()
  const [listLocations, { data, loading: listLoading, fetchMore }] = useLazyQuery(
    LIST_LOCATIONS,
    { fetchPolicy: 'network-only' }
  )
  const list = () => {
    if (isVirtual) {
      return listLocations({
        variables: {
          filter: {
            name: 'businessUnitCode',
            value: null,
          },
        },
      })
    }
    listLocations({
      variables: {},
    })
  }

  const refetch = () => {
    setLoading(true)
    setItems([])
    cursor.current = null
    list()
  }

  const selectLocation = (itemId, location) => {
    setSelectedLocations((prev) => {
      if (prev[itemId]) {
        delete prev[itemId]

      } else {
        prev[itemId] = location
      }

      return { ...prev }
    })
  }

  useEffect(() => {
    // const iteeems = preselectedLocations
    setSelectedLocations(JSON.parse(JSON.stringify(preselectedLocations)))
  }, [])

  useEffect(() => {
    list()
  }, [])

  useEffect(() => {
    const locations = data?.listLocations
    if (locations) {
      setItems(locations?.locations)
      cursor.current = locations?.nextToken
      setLoading(false)
    }
  }, [data])


  const onEndReached = () => {
    if (cursor.current) {
      setLoading(true)
      fetchMore({
        variables: {
          nextToken: cursor.current,
        },
      })
    }
  }

  const animate = val => {
    Animated.spring(fabSize, {
      toValue: val,
      useNativeDriver: true,
      easing: Easing.inOut(Easing.cubic),
    }).start()
  }

  const scrollToTop = () => {
    animate(0)
    listRef.current.scrollToOffset({
      offset: 0,
    })
  }

  const keyExtractor = item => item.id
  const getItemLayout = (_, index) => ({
    length: 76,
    offset: 76 * index,
    index,
  })
  const renderNonSelectableItem = ({ item }) => (
    <Item
      key={item.id}
      {...item}
      isBusinessUnitList={isBusinessUnitList}
      onPress={() => navigate('Location', { ...item, isVirtual: isVirtual, refetch: refetch, isBusinessUnitList })}
    />
  )
  const renderSelectableItem = ({ item }) => (
    multipleSelection ?
      <SelectableItem
        key={item.id}
        {...item}
        checked={Boolean(selectedLocations[item?.id])}
        onPress={() => {
          selectLocation(item?.id, item)
        }}
        multipleSelection
      />
      : <SelectableItem
        key={item.id}
        {...item}
        selected={selected}
        onPress={() => {
          setSelected(item.id)
          changeLocation(item)
          goBack()
        }}
      />
  )

  const renderItem = selectable ? renderSelectableItem : renderNonSelectableItem

  return (
    <SafeAreaView style={styles.container}>
      <Header
        title={isBusinessUnitList ? 'settings_business_code_label' : isVirtual ? 'header_online_store' : 'header_locations'}
        image={images.back}
        onPress={() => goBack()}
      />
      {multipleSelection && <View style={styles.countWrapper}>
        <Text size="h5" i18nKey="reports_business_location_screen_counter" translateOption={{ count: Object.keys(selectedLocations).length }} />
      </View>}
      { }
      <Animated.FlatList
        showsVerticalScrollIndicator={false}
        keyExtractor={keyExtractor}
        getItemLayout={getItemLayout}
        ref={(ref) => (listRef.current = ref)}
        onMomentumScrollEnd={(event) => {
          if (event.nativeEvent.contentOffset.y > 800) {
            animate(1)
          } else {
            animate(0)
          }
        }}
        data={items}
        style={styles.listStyle}
        contentContainerStyle={styles.listContainerStyle}
        renderItem={renderItem}
        ListFooterComponent={<ListLoader loading={loading} isInitial={items.length === 0} />}
        ListEmptyComponent={!loading && <Text i18nKey="location_no_locations" />}
        onEndReached={onEndReached}
        onRefresh={refetch}
        refreshing={false}
      />
      <AnimatedTouchable
        onPress={scrollToTop}
        activeOpacity={0.5}
        style={[
          styles.fab,
          {
            opacity: fabSize,
            transform: [{ scale: fabSize }],
          },
        ]}>
        <Icon
          source={images.arrow_right}
          size={20}
          color={colors.tertiaryIcon}
          style={styles.fabIcon}
        />
      </AnimatedTouchable>
      {multipleSelection && <Button
        title="discount_button_apply"
        variant={listLoading ? 'disabled' : 'active'}
        style={styles.chargeButton}
        onPress={() => { onSave(selectedLocations); goBack() }}
      />}
    </SafeAreaView>
  )
}

Locations.propTypes = {
  route: PropTypes.object,
  navigation: PropTypes.object,
}


export default Locations
