import { styleSheetFactory } from '../../Themes/Colors'

const styles = styleSheetFactory((colors) => ({
  container: {
    flex: 1,
  },
  addPhotoContainer: {
    backgroundColor: colors.tertiaryBackground,
    width: 110,
    alignItems: 'center',
    borderRadius: 7,
    marginTop: 10,
  },
  loaderComponent: {
    backgroundColor: colors.white,
    width: 110,
    height: 110,
    overflow: 'hidden',
    borderRadius: 7,
    justifyContent: 'center',
  },
  imageBackground: {
    width: 110,
    height: 80,
    overflow: 'hidden',
    borderTopRightRadius: 7,
    borderTopLeftRadius: 7,
    justifyContent: 'center',
  },
  imgBackgroundContainer: {
    alignItems: 'center',
  },
  gallery: {
    marginVertical: 5,
  },
  scrollview: {
    flexGrow: 1,
  },
  addPhotoWrapper: {
    alignItems: 'center',
    marginBottom: 10,
  },
  verticalSpacing: { marginVertical: 30 },
}))

const userFormStyles = styleSheetFactory((colors) => ({
  container: {
    backgroundColor: colors.tertiaryBackground,
    overflow: 'hidden',
    borderRadius: 7,
    marginBottom: 10,
  },
  subContainer: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    padding: 15,
  },
  textContainer: {
    flexGrow: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    marginRight: 15,
  },
  rightTextContainer: {
    flexDirection: 'column',
    alignItems: 'flex-end',
    marginLeft: 7.5,
    flexShrink: 1,
  },
  inputContainer: {
    minHeight: 60,
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  itemSpacing: {
    marginVertical: 5,
  },
  saveButton: {
    height: 50,
    marginTop: 10,
    marginBottom: 30,
  },
  credentialButton: {
    height: 50,
    marginTop: 10,
    marginBottom: 5,
  },
  touchableContainer: {
    backgroundColor: colors.tertiaryBackground,
    overflow: 'hidden',
    borderRadius: 7,
    marginBottom: 10,
    minHeight: 50,
    justifyContent: 'center',
  },
  rightContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  addCashRegister: { marginBottom: 10, marginTop: 5 },
  separator: {
    height: 1,
    backgroundColor: colors.separator,
  },
  contentContainer: {
    backgroundColor: colors.background,
    paddingBottom: 0,
    paddingHorizontal: 0,
    flexGrow: 1,
  },
  operatorCodeHelper: {
    marginTop: 5,
    marginBottom: 10,
    textAlign: 'left',
    fontFamily: 'Vodafone Rg',
    fontSize: 14,
    color: colors.primaryText,
  },
  operatorCodeHelperItalic: {
    fontStyle: 'italic',
  },
  accessItem: {
    height: 60,
    backgroundColor: colors.tertiaryBackground,
    justifyContent: 'center',
    overflow: 'hidden',
    marginVertical: 1,
    borderRadius: 7,
    marginBottom: 10,
  },
  touchable: {
    flexGrow: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  name: {
    marginLeft: 15,
  },
  radioInput: {
    flexShrink: 0,
    marginRight: 16,
  },
  accessTextContainer: {
    marginRight: 15,
    flexGrow: 1,
    flexShrink: 1,
    flexDirection: 'row',
  },
  accessWrapper: {
    marginVertical: 15,
  },
  accessLabel: {
    marginBottom: 10,
  },
  rightSelfSpacing: { paddingRight: 10 },
  horizontalSelfSpacing: { paddingHorizontal: 15 },
  topSpacing: { marginTop: 15 },
  errorText: { fontSize: 12, marginBottom: 20 },
  note: { fontSize: 12, lineHeight: 13, marginBottom: 20 },
  sectionText: { marginBottom: 5, marginTop: 15 },
  modalWrapper: {
    padding: 16,
  },
  modalHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  modalCloseIcon: {
    width: 32,
    height: 32,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 32 / 2,
    backgroundColor: colors.lightGray,
  },
  listStyle: {
    marginHorizontal: 1,
  },
  inputPromptText: {
    marginTop: 20,
    marginBottom: 10,
  },
  selectPromptText: {
    marginTop: 16,
    marginBottom: -7.5,
  },
  setCodeButton: {
    marginHorizontal: 16,
    marginTop: 12.5,
  },
}))

const itemStyles = styleSheetFactory(colors => ({
  listItem: {
    backgroundColor: colors.white,
    paddingHorizontal: 15,
    paddingVertical: 5,
    justifyContent: 'center',
    height: 70,
  },
  smallText: {
    lineHeight: 16,
  },
  itemFirstRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  firstItem_true: {
    borderTopLeftRadius: 7,
    borderTopRightRadius: 7,
  },
  lastItem_true: {
    borderBottomLeftRadius: 7,
    borderBottomRightRadius: 7,
  },
  itemContainer: {
    backgroundColor: colors.tertiaryBackground,
    flexDirection: 'row',
    alignItems: 'center',
    overflow: 'hidden',
    height: 70,
    flex: 1,
  },
}))

const hiddenItemStyles = styleSheetFactory(colors => ({
  wrapper: {
    backgroundColor: colors.accent,
    flex: 1,
    justifyContent: 'center',
    alignItems: 'flex-end',
  },
  firstItem_true: {
    borderTopLeftRadius: 7.5,
    borderTopRightRadius: 7.5,
  },
  lastItem_true: {
    borderBottomLeftRadius: 7.5,
    borderBottomRightRadius: 7.5,
  },
  option: {
    width: 100,
    height: 60,
    justifyContent: 'center',
    alignItems: 'center',
  },
  optionFull: {
    flexDirection: 'row',
  },
  confirm: {
    flexGrow: 1,
    height: 60,
    flexDirection: 'row',
    paddingLeft: 15,
    alignItems: 'center',
  },
  confirmText: {
    marginLeft: 10,
  },
  cancel: {
    width: 40,
    height: 40,
    flexShrink: 0,
    marginVertical: 10,
    marginHorizontal: 15,
    borderRadius: 20,
    backgroundColor: colors.whiteRGBA(0.55),
    justifyContent: 'center',
    alignItems: 'center',
  },
  loader: {
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  confirmIcon: {
    width: 30,
    height: 30,
    borderRadius: 20,
    backgroundColor: colors.white,
    justifyContent: 'center',
    alignItems: 'center',
  },
}))

export {
  itemStyles,
  hiddenItemStyles,
  userFormStyles,
}

export default styles
