import { styleSheetFactory } from '../../../../../Themes/Colors'

const billingPeriodStyles = styleSheetFactory((colors) => ({
    AccessItem: {
        justifyContent: 'center',
        overflow: 'hidden',
        marginVertical: 1,
        borderBottomLeftRadius: 7,
        borderBottomRightRadius: 7,
        marginHorizontal: 15,
        paddingBottom: 15,
    },
    touchable: {
        flexGrow: 1,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    radioInput: {
        flexShrink: 0,
    },
    accessTextContainer: {
        flexGrow: 1,
        flexShrink: 1,
        flexDirection: 'row',
    },
    accessWrapper: {
        marginVertical: 15,
    },
    accessLabel: {
        marginBottom: 10,
    },
    buttonWrapper: {
        flexGrow: 1,
        flexDirection: 'row',
        alignItems: 'center',
    },
    leftButton: {
        flex: 1,
        marginRight: 5,
    },
    rightButton: {
        flex: 1,
        marginLeft: 5,
    },
}))

export default billingPeriodStyles
