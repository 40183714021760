import React, { useState, useContext, useEffect, useRef } from 'react'
import {
  View,
  SafeAreaView,
  FlatList,
  TouchableOpacity,
  LayoutAnimation,
  KeyboardAvoidingView,
  Platform,
  TextInput,
} from 'react-native'

import { useTheme } from 'react-native-themed-styles'

import PropTypes from 'prop-types'

import { Button, Text, CheckBox, Icon, Header } from '../../Components'

import Theme, { layoutAnimConfig } from '../../Themes/Theme'

import themedStyles from './IssueRefundDetails.Styles'

import images from '../../Themes/Images'

function Item({
  itemName,
  quantity,
  editableQuantity,
  basePriceMoney,
  checked,
  onPress,
  style,
  decrease,
  increase,
  changeQuantity,
}) {
  const [styles] = useTheme(themedStyles)
  const { colors } = useContext(Theme)

  return (
    <View style={[styles.listInfoContainer, style]}>
      <View style={styles.listDescContainer}>
        <Text
          i18nKey={itemName}
        />
        <Text
          i18nKey={`${quantity} X ${basePriceMoney?.amount} ${basePriceMoney?.currency}`}
          weight="light"
        />
      </View>
      <View style={styles.listPriceContainer}>
        {checked && <View style={styles.quantityContainer}>
          <TouchableOpacity
            style={styles.quantityButton}
            onPress={decrease}
            disabled={parseFloat(editableQuantity) === 0 || editableQuantity === ''}
          >
            <Icon source={images.minusIcon} size={20} color={(parseFloat(editableQuantity) === 0 || editableQuantity === '') ? colors.disabled : colors.secondaryIcon} />
          </TouchableOpacity>
          <TextInput
            placeholder=""
            value={editableQuantity.toString()}
            onChangeText={changeQuantity}
            textAlign="center"
            style={styles.quantityInput}
            keyboardType="numeric"
            autoCapitalize="none"
            onBlur={() => {
              // if the user leaves the TextField with empty quantity we uncheck it
              if (editableQuantity === '') {
                onPress()
              }
            }}
          />
          <TouchableOpacity
            style={styles.quantityButton}
            onPress={increase}
            disabled={parseFloat(editableQuantity) === quantity || editableQuantity === ''}
          >
            <Icon source={images.plusIcon} size={20} color={(parseFloat(editableQuantity) === quantity || editableQuantity === '') ? colors.disabled : colors.secondaryIcon} />
          </TouchableOpacity>
        </View>}
      </View>
      <CheckBox
        checked={checked}
        tintColors={{
          true: colors.secondaryAccent,
          false: colors.placeholder,
        }}
        onValueChange={onPress}
      />
    </View>
  )
}
Item.propTypes = {
  itemName: PropTypes.string,
  quantity: PropTypes.number,
  editableQuantity: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  basePriceMoney: PropTypes.object,
  checked: PropTypes.bool,
  onPress: PropTypes.func,
  style: PropTypes.object,
  decrease: PropTypes.func,
  increase: PropTypes.func,
  changeQuantity: PropTypes.func,
}

function List({ items, checkboxStates, toggleState, decrease, increase, changeQuantity, loading }) {
  const [styles] = useTheme(themedStyles)

  return (
    <KeyboardAvoidingView style={styles.container} behavior={Platform.select({
      ios: 'padding',
      android: undefined,
    })}>
      <FlatList
        showsVerticalScrollIndicator={false}
        keyExtractor={(item, index) => 'row-' + item.id}
        getItemLayout={(dataa, index) => ({
          length: 60,
          offset: 61 * index,
          index,
        })}
        data={items}
        style={styles.listStyle}
        contentContainerStyle={styles.listContainerStyle}
        extraData={[checkboxStates]}
        renderItem={({ item, index }) => {
          const style = {}
          if (index === 0) {
            style.borderTopLeftRadius = 7
            style.borderTopRightRadius = 7
          }
          if (index === items.length - 1) {
            style.borderBottomLeftRadius = 7
            style.borderBottomRightRadius = 7
          }
          return (
            <Item
              {...item}
              key={'row-' + index}
              index={index}
              checked={checkboxStates[index]}
              onPress={() => toggleState(item, index)}
              style={style}
              decrease={() => decrease(index)}
              increase={() => increase(index)}
              changeQuantity={(text) => changeQuantity(text, index)}
            />
          )
        }}
        ListEmptyComponent={
          !loading && <Text
            i18nKey="receipt_no_refund_items"
            align="center"
            weight="bold"
            style={styles.topSpacing}
          />
        }
        ItemSeparatorComponent={() => (
          <View style={styles.separatorContainer}>
            <View style={styles.separator} />
          </View>
        )}
        refreshing={false}
      />
    </KeyboardAvoidingView>
  )
}
List.propTypes = {
  items: PropTypes.array,
  checkboxStates: PropTypes.array,
  toggleState: PropTypes.func,
  decrease: PropTypes.func,
  increase: PropTypes.func,
  changeQuantity: PropTypes.func,
  loading: PropTypes.bool,
}

function IssueRefundDetails({
  route: {
    params: {
      lineItems,
      transactionId,
      type,
      customerId,
      bankId,
      shippingAddress,
    },
  },
  navigation: {
    navigate,
    goBack,
  },
}) {
  const [styles] = useTheme(themedStyles)
  const { colors } = useContext(Theme)
  const [checkboxStates, setCheckboxStates] = useState({
    areAllSelected: false,
    items: [],
  })
  const [refundItems, setRefundItems] = useState([])
  const [loading, setLoading] = useState(true)
  const numberOfSelected = useRef({
    maxNumberOfSelectable: 0,
    currentSelected: 0,
  })
  const itemsChanged = useRef([])

  useEffect(() => {
    if (lineItems) {
      const res = mapData(lineItems)
      LayoutAnimation.configureNext(layoutAnimConfig)
      setRefundItems([...res])
      setLoading(false)
    }
  }, [lineItems])



  const decreaseQuantity = (index) => {
    setRefundItems((prev) => {
      let floatPrecision = 0
      const temp = [...prev]
      if (temp[index].editableQuantity.toString().length > 2) {
        temp[index].editableQuantity = +(temp[index].editableQuantity)
        floatPrecision = 2
      }
      temp[index].editableQuantity -= 1
      temp[index].editableQuantity = temp[index].editableQuantity.toFixed(floatPrecision)
      itemsChanged.current[index].editableQuantity = +itemsChanged.current[index].editableQuantity - 1
      itemsChanged.current[index].editableQuantity = itemsChanged.current[index].editableQuantity.toFixed(floatPrecision)
      if (temp[index].editableQuantity <= 0) {
        temp[index].editableQuantity = 0
        toggleState(null, index)
      }
      return [...temp]
    })
  }

  const increaseQuantity = (index) => {
    setRefundItems((prev) => {
      let floatPrecision = 0
      const temp = [...prev]
      if (typeof temp[index].editableQuantity === 'string') {
        temp[index].editableQuantity = +temp[index].editableQuantity
      }
      if (temp[index].editableQuantity.toString().length > 2) {
        floatPrecision = 2
      }
      temp[index].editableQuantity += 1
      temp[index].editableQuantity = temp[index].editableQuantity.toFixed(floatPrecision)
      itemsChanged.current[index].editableQuantity = +itemsChanged.current[index].editableQuantity + 1
      itemsChanged.current[index].editableQuantity = itemsChanged.current[index].editableQuantity.toFixed(floatPrecision)
      if (temp[index].editableQuantity >= temp[index].quantity) {
        temp[index].editableQuantity = temp[index].quantity
        itemsChanged.current[index].editableQuantity = itemsChanged.current[index].quantity.toFixed(floatPrecision)
      }
      return [...temp]
    })
  }

  const toggleAllStates = () => {
    if (checkboxStates.areAllSelected) {
      setCheckboxStates(() => {
        const tempItems = [...checkboxStates.items]
        tempItems.map((i, index) => {
          tempItems[index] = false
          itemsChanged.current[index].editableQuantity = 0
        })
        return ({
          areAllSelected: false,
          items: [...tempItems],
        })
      })
      numberOfSelected.current = {
        ...numberOfSelected.current,
        currentSelected: 0,
      }
    }
    else {
      // edit checkboxes state
      setCheckboxStates(() => {
        const tempItems = [...checkboxStates.items]
        tempItems.map((i, index) => { tempItems[index] = true })
        return (
          {
            areAllSelected: true,
            items: [...tempItems],
          }
        )
      })
      // edit number of selected
      numberOfSelected.current = {
        ...numberOfSelected.current,
        currentSelected: numberOfSelected.current.maxNumberOfSelectable,
      }
      // set the items
      setRefundItems(prev => {
        const newLineItems = itemsChanged.current.map((item, index) => {
          itemsChanged.current[index].editableQuantity = itemsChanged.current[index].quantity
          return ({
            ...item,
            editableQuantity: itemsChanged.current[index].quantity,
          })
        }
        )
        return newLineItems
      })

    }
  }

  const mapData = (data) => {
    const res = []
    const checkBoxes = []
    // we need to initialise with 0 values due to double calling fucnction
    numberOfSelected.current = {
      currentSelected: 0,
      maxNumberOfSelectable: 0,
    }
    data?.map(item => {
      numberOfSelected.current = {
        ...numberOfSelected.current,
        maxNumberOfSelectable: numberOfSelected.current.maxNumberOfSelectable + 1,
      }
      checkBoxes.push(false)
      const itemObject = {
        id: item?.id,
        itemName: item?.itemName,
        labelColor: item?.labelColor,
        imageUrl: item?.imageUrl,
        itemId: item?.itemId,
        variationName: item?.variationName,
        variationId: item?.variationId,
        quantity: item?.refundableQuantity,
        modifiers: item?.modifiers,
        basePriceMoney: item?.basePriceMoney,
        appliedTax: item?.appliedTax,
        appliedDiscounts: item?.appliedDiscounts,
        totalMoney: item?.totalMoney,
        taxInclusionType: item?.taxInclusionType,
        editableQuantity: 0,
        taxExemptionType: item?.taxExemptionType,
      }
      res.push({
        ...itemObject,
        totalQuantity: item?.quantity,
      })
      itemsChanged.current.push(
        {
          ...itemObject,
        }
      )
    })
    setCheckboxStates({
      areAllSelected: false,
      items: [...checkBoxes],
    })
    return res
  }

  const toggleState = (i, index) => {
    const wasChecked = checkboxStates.items[index]
    if (wasChecked) {
      setCheckboxStates((prev) => {
        const tempData = [...prev.items]
        tempData[index] = false
        return (
          {
            areAllSelected: false,
            items: [...tempData],
          }
        )
      })
      itemsChanged.current[index].editableQuantity = 0
      numberOfSelected.current = {
        ...numberOfSelected.current,
        currentSelected: numberOfSelected.current.currentSelected - 1,
      }
    }
    else {
      const tempData = [...checkboxStates.items]
      tempData[index] = true
      setCheckboxStates({
        areAllSelected: true,
        items: [...tempData],
      })
      numberOfSelected.current = {
        ...numberOfSelected.current,
        currentSelected: numberOfSelected.current.currentSelected + 1,
      }
      setRefundItems(prev => {
        const tempDataRef = [...prev]
        itemsChanged.current[index].editableQuantity = itemsChanged.current[index].quantity
        tempDataRef[index].editableQuantity = tempDataRef[index].quantity
        return [...tempDataRef]
      })
    }
  }

  const replaceAt = (string, index, replacement) => {
    return string.substr(0, index) + replacement + string.substr(index + replacement.length)
  }

  const modifyQuantity = (val, index) => {
    let newString = val
    const indexOfComa = val.indexOf(',')
    if (indexOfComa >= 0) {
      newString = replaceAt(val, indexOfComa, '.')
    }
    if (/^\d{0,12}(\.\d{0,2})?$/.test(newString)) {
      if (newString <= itemsChanged.current[index].quantity || newString === '' || newString == 0) {
        itemsChanged.current[index].editableQuantity = newString
        setRefundItems((prev) => {
          const temp = [...prev]
          temp[index].editableQuantity = newString
          return ([
            ...temp,
          ])
        })
      }
    }
  }

  const cleanNextLine = () => {
    const temp = []
    itemsChanged.current.map((item, index) => {
      if (item?.editableQuantity !== 0) {
        temp.push(
          {
            ...item,
            quantity: item.editableQuantity,
          }
        )
      }
    })
    return temp
  }

  return (
    <SafeAreaView style={styles.container}>
      <Header
        title="header_issue_refund"
        image={images.back}
        onPress={() => goBack()}
      />
      {lineItems?.[0]?.refundableQuantity > 0 ? (
        <View
          style={styles.checkAllContainer}>
          <Text i18nKey="orders_refund_items_title" />
          <CheckBox
            checked={numberOfSelected.current.currentSelected === numberOfSelected.current.maxNumberOfSelectable}
            onValueChange={() => toggleAllStates()}
            tintColors={{
              true: colors.secondaryAccent,
              false: colors.placeholder,
            }}
            style={styles.checkAll}
          />
        </View>
      ) : null}
      <List
        items={refundItems}
        checkboxStates={checkboxStates.items}
        toggleState={toggleState}
        decrease={decreaseQuantity}
        increase={increaseQuantity}
        changeQuantity={modifyQuantity}
        loading={loading}
      />
      <View style={styles.footerComponent}>
        <Text
          style={styles.itemsInfo}
          i18nKey="orders_refund_items_description"
        />
        <Button
          title="orders_refund_button_next"
          variant={
            numberOfSelected.current.currentSelected === 0 ?
              'disabled' :
              'active'
          }
          style={styles.fullIssueButton}
          onPress={() => {
            navigate('IssueRefund', {
              refundItems: cleanNextLine(),
              transactionId: transactionId,
              type: type,
              customerId: customerId,
              bankId,
              shippingAddress,
            })
          }}
        />
      </View>
    </SafeAreaView>
  )
}
IssueRefundDetails.propTypes = {
  route: PropTypes.object,
  navigation: PropTypes.object,
}

export default IssueRefundDetails
