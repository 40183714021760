import { StyleSheet, Dimensions } from 'react-native'

import { styleSheetFactory } from '../../Themes/Colors'

const { width } = Dimensions.get('screen')

const styles = styleSheetFactory((colors) => ({
  container: {
    flex: 1,
  },
  textContainer: {
    flexGrow: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    marginRight: 15,
  },
  subContainer: {
    flex: 1,
    padding: 15,
  },
  subFileContainer: {
    flex: 1,
    alignItems: 'center',
    padding: 15,
  },
  input: {
    height: 35,
    includeFontPadding: false,
    textAlignVertical: 'center',
    color: colors.primaryText,
    fontSize: 16,
    fontFamily: 'Vodafone Lt',
    padding: 0,
  },
  input1: {
    flexShrink: 1,
    // height: 40,
    textAlign: 'left',
    includeFontPadding: false,
    textAlignVertical: 'center',
    color: colors.primaryText,
    fontSize: 16,
    fontFamily: 'Vodafone Rg',
    paddingRight: 2,
    // minWidth: 80,
  },
  clearContainer: {
    margin: 6.5,
    justifyContent: 'center',
    alignItems: 'center',
    width: 30,
    height: 30,
    borderRadius: 20,
    backgroundColor: colors.background,
  },
  codeFieldRoot: {
    marginTop: 20,
    marginBottom: 30,
  },
  cell: {
    borderRadius: 7,
    width: 40,
    height: 40,
    lineHeight: 35,
    fontSize: 24,
    color: colors.primaryText,
    borderWidth: 2,
    borderColor: '#00000030',
    textAlign: 'center',
  },
  focusCell: {
    borderColor: '#000',
  },
  codeConfirm: {
    borderRadius: 7,
    marginTop: 30,
    backgroundColor: colors.white,
    paddingHorizontal: 40,
    paddingVertical: 20,
    justifyContent: 'center',
  },

  fillRemaining: {
    flexGrow: 1,
  },
  initContainer: {
    flexGrow: 1,
    marginHorizontal: 15,
    justifyContent: 'space-around',
  },
  center: {
    alignItems: 'center',
  },
  animationContainer: {
    width: 300,
    height: 300,
    justifyContent: 'center',
    alignItems: 'center',
  },
  containerButtonIOS: {
    marginBottom: 4,
    marginRight: 5,
    fontFamily: 'Vodefone Lt',
    fontSize: 50,
    color: 'red',
  },
  containerButtonAndroid: {
    marginBottom: 8,
    fontFamily: 'Vodefone Lt',
    fontSize: 50,
    color: 'red',
  },
  inputContainer1: {
    flexDirection: 'row',
    marginBottom: 0,
    minHeight: 60,
    paddingLeft: 15,
    alignItems: 'center',
    backgroundColor: colors.tertiaryBackground,
    borderRadius: 7,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: colors.tertiaryBackground,
    justifyContent: 'space-between',
  },
  inputValue: {
    flexGrow: 1,
    flexShrink: 1,
    marginLeft: 5,
    height: 60,
    textAlign: 'right',
    includeFontPadding: false,
    textAlignVertical: 'center',
    color: colors.primaryText,
    fontSize: 16,
    fontFamily: 'Vodafone Lt',
    paddingRight: 15,
  },
  //demo
  inputContainer: {
    minHeight: 60,
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  itemSpacing: {
    marginVertical: 7.5,
  },
  sendButton: { height: 50, marginBottom: 20, marginHorizontal: 15 },
  scrollView: { paddingHorizontal: 15, marginTop: 15 },
  bottomSpacing: { marginBottom: 20 },
  smallBottomSpacing: { marginBottom: 15 },
  underlinedText: { textDecorationLine: 'underline' },
  nextButton: { height: 50, marginBottom: 20, marginHorizontal: 15 },
}))

const locationStyles = styleSheetFactory((colors) => ({
  mylocationContainer: {
    flexGrow: 1,
    minHeight: 232,
    marginVertical: 7.5,
    backgroundColor: colors.tertiaryBackground,
    borderRadius: 7,
  },
  label: {
    marginTop: 15,
    marginHorizontal: 15,
    justifyContent: 'center',
    borderBottomWidth: 1,
    borderBottomColor: colors.separator,
    paddingBottom: 5,
  },
  mapContainer: {
    position: 'relative',
    width: width - 60,
    height: 200,
    marginTop: 15,
    marginHorizontal: 15,
    backgroundColor: colors.tertiaryBackground,
    overflow: 'hidden',
  },
  map: {
    ...StyleSheet.absoluteFillObject,
  },
  currentLocationButton: {
    padding: 15,
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    maxHeight: 50,
  },
  currentLocationText: {
    paddingBottom: 3,
  },
  confirmButton: {
    margin: 15,
    marginBottom: 30,
  },
}))

export { locationStyles }

export default styles
