import { gql } from '@apollo/client'

export const LIST_SUPPORTIVE_INVOICES = gql`
query listWarehouseNotes(
  $filter: Filter, 
  $nextToken: String,
  $limit: Int,
  $beginTime: String,
  $endTime: String,
  $locId: String,
  $cashRegisterId: String
  ) {
    listWarehouseNotes(listWarehouseNotesInput: {
    filter: $filter,
    nextToken: $nextToken,
    limit: $limit,
    beginTime: $beginTime,
    endTime: $endTime
  }
  requestedLocationAndCashRegister: {
    locationId: $locId,
    cashRegisterId: $cashRegisterId
  }
  ){
    nextToken
    warehouseNotes {
        valueOfGoods
        issueDateTime
        status
        id
        wtnNum
        employeeEmail
      },
  },
}
`

export const GET_SUPPORTIVE_INVOICE = gql`
query getWarehouseNote($id: String!, $locId: String, $cashRegisterId: String ){
    getWarehouseNote(
      id: $id
      requestedLocationAndCashRegister: {
        locationId: $locId,
        cashRegisterId: $cashRegisterId
      }
      ){
        businUnitCode
        carrier {
          address
          idNum
          idType
          name
          town
        }
        destinAddr
        destinCity
        destinDateTime
        destinPoint
        fwtnic
        id
        isEscortRequired
        isGoodsFlammable
        issueDateTime
        issuer {
          address
          name
          nuis
          town
        }
        items {
          code
          name
          note
          quantity
          unit
        }
        itemsNum
        note
        operatorCode
        packNum
        packType
        qrCode
        softCode
        startAddr
        startCity
        startDateTime
        startPoint
        status
        transaction
        type
        valueOfGoods
        vehOwnership
        vehPlates
        wtnNum
        wtnOrdNum
        wtnic
    }
}
`
