import { gql } from '@apollo/client'

export const LIST_CASH_DRAWER_SHIFTS_EVENTS = gql`
    query listEvents($status: [GovStatus],$locationId: String!, $cursor: String, $shiftId: String!, $limit: Int, $eventType: EventType, $cashRegisterId: String){
        listCashDrawerShiftEvents(status: $status,locationId: $locationId, cursor: $cursor, shiftId: $shiftId, limit: $limit, eventType: $eventType, cashRegisterId: $cashRegisterId){
            cursor
            events{
                id
                FCDC
                createdAt
                description
                externalId
                eventType
                softCode
                businUnitCode
                eventMoney{
                    amount
                    currency
                }
                employee{
                    email
                    firstName
                    lastName
                    operatorCode
                }
                reason
                statementNumber
                status
            }
        }
   }
`
