import { styleSheetFactory } from '../../Themes/Colors'

const styles = styleSheetFactory((colors) => ({
  container: {
    flex: 1,
  },
  header: {
    height: 56,
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    backgroundColor: colors.background,
  },
  leftContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  headerIcon: {
    marginHorizontal: 20,
  },
  logo: {
    marginBottom: 15,
    alignItems: 'center',
  },
  listHeader: {
    marginBottom: 15,
  },
  listStyle: { marginLeft: 15 },
  listContainerStyle: {
    //   flexDirection: 'row',
    //   flexWrap: 'wrap',
    //   alignItems: 'center',
    //   justifyContent: 'center',
  },
  listInfoContainer: {
    alignItems: 'center',
    flex: 1,
    backgroundColor: colors.tertiaryBackground,
    justifyContent: 'center',
    borderRadius: 7,
    padding: 10,
    marginRight: 15,
    marginBottom: 15,
    minHeight: 80,
  },
  button: {
    height: 50,
    marginTop: 10,
    marginHorizontal: 15,
    marginBottom: 30,
  },
  itemIcon: {
    marginBottom: 10,
  },
  logoImage: {
    width: 200,
    height: 200,
    resizeMode: 'contain',
  },
}))

export default styles
