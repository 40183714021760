/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext, useState, useEffect } from 'react'
import { gql, useLazyQuery } from '@apollo/client'
import PropTypes from 'prop-types'

const TaxDetails = createContext()

const LIST_TAXES = gql`
  query listTaxes{
    listTaxes{
      taxes{
        id
        name
        percentage
        isDefault
        status
      }
      nextToken
    }
  }
`
const DEFAULT_INCLUSION_TYPE = 'INCLUSIVE'

function TaxDetailsProvider({ children }) {
  const [listTaxes, { data }] = useLazyQuery(LIST_TAXES, {
    fetchPolicy: 'network-only',
  })

  const [taxDetails, setTaxDetails] = useState({
    taxInclusionType: DEFAULT_INCLUSION_TYPE,
  })

  const [reload, setReload] = useState(true)

  useEffect(() => {
    listTaxes()
  }, [reload])

  useEffect(() => {
    if (data) {
      const taxes = data.listTaxes?.taxes || []
      const tax = taxes.find(element => element.isDefault)
      if (tax) {
        setTaxDetails(prev => {
          return {
            ...prev,
            taxId: tax.id,
            tax: {
              id: tax.id,
              name: tax.name,
              percentage: tax.percentage,
            },
          }
        })
      }
    }
  }, [data])

  return (
    <TaxDetails.Provider value={{ taxDetails, setReload }}>
      {children}
    </TaxDetails.Provider>
  )
}
TaxDetailsProvider.propTypes = {
  children: PropTypes.any,
}

function useTaxDetails() {
  const context = React.useContext(TaxDetails)
  if (context === undefined) {
    throw new Error('useTaxDetails must be used within a TaxDetailsProvider')
  }
  return context
}

export { TaxDetailsProvider }
export default useTaxDetails
