/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import {
  View,
  SafeAreaView,
  TouchableOpacity,
  FlatList,
  RefreshControl,
  Dimensions,
  useWindowDimensions,
} from 'react-native'
import PropTypes from 'prop-types'
import { useTheme } from 'react-native-themed-styles'
import { useLazyQuery } from '@apollo/client'
import ContentLoader, { Rect } from 'react-content-loader/native'
import Color from 'color'

import { Icon, Text, Button, CheckBox, Header, Loader } from '../../Components'

import { LIST_EMPLOYEES } from '../Users/Users.schema'

import images from '../../Themes/Images'
import themedStyles, {
  itemStyles,
  photoItemStyles,
} from './SelectEmployee.Styles'

import { colors, animations } from '../../Themes'

const { width } = Dimensions.get('screen')

const employeeStatus = {
  Pending: 'gray',
  Active: 'green',
  Inactive: 'red',
}
const avatars = ['maleAvatar', 'femaleAvatar']

function ItemPhoto(props) {
  const [styles] = useTheme(photoItemStyles)

  const { color, title, status } = props

  const pic = avatars.find((el) => el === color)

  return (
    <View>
      {pic ? (
        <View style={styles.avatarContainer}>
          <Icon source={images[pic]} size={70} resizeMode="cover" />
          <View
            style={[
              styles.status,
              {
                backgroundColor: colors[status],
              },
            ]}
          />
        </View>
      ) : (
        <View
          style={[
            {
              backgroundColor: Color(colors[color] || colors.transparent)
                .fade(0.7)
                .toString(),
            },
            styles.colorContainer,
          ]}>
          <Text
            size="h5"
            color={colors[color]}
            align="center"
            i18nKey={title
              ?.match(/\b(\w{1})/g)
              ?.slice(0, 2)
              ?.join('')
              ?.toUpperCase()}
          />
          <View
            style={[
              styles.status,
              {
                backgroundColor: colors[status],
              },
            ]}
          />
        </View>
      )}
    </View>
  )
}

ItemPhoto.propTypes = {
  color: PropTypes.string,
  role: PropTypes.string,
  title: PropTypes.string,
  status: PropTypes.string,
}

function Employee({ item, checked, onPress, isFirst, isLast }) {
  const [styles] = useTheme(itemStyles)

  return (
    <TouchableOpacity
      style={[
        styles.itemContainer,
        styles['fistItem_' + isFirst],
        styles['lastItem_' + isLast],
      ]}
      onPress={onPress}
      disabled={!onPress}>
      <View style={styles.subContainer}>
        <ItemPhoto
          color={item.color}
          role={item.role}
          title={`${item.firstName} ${item.lastName}`}
          status={employeeStatus[item.userStatus]}
        />
        <View style={styles.textContainer}>
          <View style={styles.firstRow}>
            <Text
              i18nKey={`${item.firstName} ${item.lastName}`}
              numberOfLines={1}
            />
            <Text i18nKey={` (${item.role})`} numberOfLines={1} />
          </View>
          <Text
            i18nKey={item.email}
            weight="light"
            color={colors.secondaryText}
            size="footnote"
            numberOfLines={1}
          />
        </View>
      </View>
      <View pointerEvents="none" style={styles.rightSpacing}>
        <CheckBox
          tintColors={styles.checkBoxTintColors}
          checked={checked}
          onValueChange={onPress}
          style={styles.checkBox}
          color={colors.secondaryAccent}
        />
      </View>
    </TouchableOpacity>
  )
}
Employee.propTypes = {
  item: PropTypes.object,
  checked: PropTypes.bool,
  onPress: PropTypes.func,
  isFirst: PropTypes.bool,
  isLast: PropTypes.bool,
}

function EmployeePlaceholder({ isFirst, isLast }) {
  const [styles] = useTheme(itemStyles)

  return (
    <TouchableOpacity
      style={[
        styles.itemContainer,
        styles['fistItem_' + isFirst],
        styles['lastItem_' + isLast],
      ]}
      disabled>
      <ContentLoader
        speed={2}
        width={width - 30}
        height={60}
        backgroundColor="#f3f3f3"
        foregroundColor="#fff">
        <Rect
          x="15"
          y="10"
          rx="6"
          ry="6"
          width={`${(width - 30) * (3 / 5)}`}
          height="18"
        />
        <Rect x="15" y="32" rx="6" ry="6" width="60" height="18" />
        <Rect x={`${width - 63}`} y="21" rx="3" ry="3" width="18" height="18" />
      </ContentLoader>
    </TouchableOpacity>
  )
}
EmployeePlaceholder.propTypes = {
  isFirst: PropTypes.bool,
  isLast: PropTypes.bool,
}

function SelectEmployee({
  navigation: { navigate, goBack },
  route: {
    params: {
      onSave = () => { },
      preSelectedEmployees = [],
      isReport = false,
    } = {},
  },
}) {
  const [styles] = useTheme(themedStyles)
  const { width: windowWidth, height: windowHeight } = useWindowDimensions()
  const [selectedEmployees, setSelectedEmployees] = useState({})

  const [employees, setEmployees] = useState([])
  const [limit] = useState(25)

  const [listEmployees, { loading, data, refetch }] = useLazyQuery(
    LIST_EMPLOYEES,
    {
      fetchPolicy: 'network-only',
      variables: {
        limit: limit,
        name: 'userStatus',
        value: 'Active',
      },
    },
  )

  useEffect(() => {
    listEmployees()

    if (isReport) {
      setSelectedEmployees(JSON.parse(JSON.stringify(preSelectedEmployees)))
    } else {
      preSelectedEmployees.map((item) => changeState(item.id, item))
    }
  }, [])

  const changeState = (id, employee) => {
    setSelectedEmployees((prev) => {
      if (prev[id]) {
        delete prev[id]
      } else {
        prev[id] = employee

      }
      return { ...prev }
    })
  }
  const keys = Object.values(selectedEmployees)
  const preSelected = {}
  !isReport && preSelectedEmployees.map((item) => {
    preSelected[item.id] = true
  })

  useEffect(() => {
    setEmployees([])
  }, [windowWidth, windowHeight])

  useEffect(() => {
    if (data) {
      const employeeData = data?.listEmployee?.empleyees
      setEmployees((prev) => {
        return prev.concat(employeeData)
      })
    }
  }, [data])

  const dataEmployees = employees?.filter((emp) => !preSelected[emp.id])

  const goBackk = () => {
    isReport ? onSave(selectedEmployees) : onSave(keys)
    goBack()
  }

  const onEndReached = () => {
    if (data?.listEmployee?.paginationToken === null ||
      (data?.listEmployee?.paginationToken === undefined && employees.length)) {
      return
    }
    listEmployees({
      variables: {
        limit: limit,
        name: 'userStatus',
        value: 'Active',
        paginationToken: data?.listEmployee?.paginationToken,
      },
    })
  }

  return (
    <SafeAreaView style={styles.container}>
      <Header
        title="header_add_employee"
        image={images.back}
        onPress={goBack}
      />
      {isReport && <View style={styles.countWrapper}>
        <Text size="h5" i18nKey="reports_employees_select_screen_counter" translateOption={{ count: Object.keys(selectedEmployees).length }} />
      </View>}

      <FlatList
        showsVerticalScrollIndicator={false}
        keyExtractor={(item) => item.id}
        getItemLayout={(_, index) => ({
          length: 70,
          offset: 71 * index,
          index,
        })}
        onEndReached={() => onEndReached()}
        onEndReachedThreshold={0.6}
        data={dataEmployees}
        style={styles.listStyle}
        contentContainerStyle={styles.listContainerStyle}
        renderItem={({ item, index }) => {
          let isFirst = false,
            isLast = false
          if (index === 0) {
            isFirst = true
          }
          if (index === dataEmployees.length - 1) {
            isLast = true
          }

          return (
            <Employee
              key={item.id}
              id={item.id}
              item={item}
              checked={Boolean(selectedEmployees[item.id])}
              onPress={() => changeState(item.id, item)}
              isFirst={isFirst}
              isLast={isLast}
            />
          )
        }}
        ListHeaderComponent={
          !isReport && <Text
            weight="light"
            align="center"
            size="footnote"
            i18nKey="cash_register_add_employee_list"
            style={styles.rightSpacing}
          />
        }
        ItemSeparatorComponent={() => <View style={styles.separator} />}
        refreshControl={
          <RefreshControl refreshing={false} onRefresh={() => refetch()} />
        }
        ListFooterComponent={
          loading && (
            <>
              <EmployeePlaceholder isFirst />
              <EmployeePlaceholder isLast />
            </>
          )
        }
      />

      <Button
        title="discount_button_apply"
        variant={loading ? 'disabled' : 'active'}
        style={styles.chargeButton}
        onPress={() => goBackk()}
        loaderComponent={<Loader size={32} source={animations.vfLoaderRed} />}
      />
    </SafeAreaView>
  )
}
SelectEmployee.propTypes = {
  navigation: PropTypes.object,
  route: PropTypes.object,
}

export default SelectEmployee
