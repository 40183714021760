import { styleSheetFactory } from '../../Themes/Colors'

const styles = styleSheetFactory(colors => ({
  container: {
    flexGrow: 1,
    flex: 1,
    backgroundColor: colors.background,
  },
  viewPager: {
    flexGrow: 1,
    flexShrink: 1,
  },
  indicatorContainer: {
    width: '100%',
    height: 20,
    marginVertical: 20,
    alignContent: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    flexShrink: 0,
  },
  indicator_false: {
    width: 10,
    height: 10,
    backgroundColor: colors.disabled,
    borderRadius: 10,
    marginHorizontal: 7.5,
  },
  indicator_true: {
    width: 18,
    height: 10,
    backgroundColor: colors.accent,
    borderRadius: 10,
    marginHorizontal: 7.5,
  },
  rightButton: {
    marginHorizontal: 15,
    marginBottom: 35,
  },
  // certWrapper: {
  //   alignItems: 'center',
  //   // justifyContent:'center',
  //   backgroundColor:'pink',
  // },
  icon: {
    alignSelf: 'center',
    marginVertical: 25,
  },
  upperContentWrapper: {
    marginBottom: 25,
  },
  certDescription: {
    marginBottom: 15,
  },
  certList: {
    marginLeft: 15,
  },
  certTitle: {
    marginHorizontal: 35,
  },
  scrollView: { flex: 1 },
  horizontalSpacing: { marginHorizontal: 15 },
  hidden: { display: 'none' },
}))

export default styles
