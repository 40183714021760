/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useMemo, useContext, useRef } from 'react'
import {
    View,
    SafeAreaView,
    Platform,
} from 'react-native'
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs'
import { useQuery, useApolloClient } from '@apollo/client'
import { useTheme } from 'react-native-themed-styles'
import PropTypes from 'prop-types'
import RNHTMLtoPDF from 'react-native-html-to-pdf'
import Share from 'react-native-share'
import moment from 'moment'
import { useReactToPrint } from 'react-to-print'

import { Margin, usePDF } from 'react-to-pdf'

import {
    Button,
    Loader,
    Header,
    ErrorModal,
    Tabs,
} from '../../Components'
import { printWtnReceipt, useLocationDetails, convertSupportingInvoice, INF, useTemplateLanguage, useConfig } from '../../Utils'
// eslint-disable-next-line import/no-unresolved
import { ConvertSupportingInvoice, ReactToPDF } from '../../Utils/PdfTemplatesWeb'
import ContentManager from '../../Utils/ContentManager'
import Merchant from '../../Utils/Merchant'

import { GET_QR } from '../../Utils/Utils.Schema'

import { GET_MY_SUPPORTING_INVOICE } from './SupportiveInvoiceDetails.Schema'

import images from '../../Themes/Images'
import { colors, animations } from '../../Themes'

import themedStyles from './SupportiveInvoiceDetails.Styles'

import {
    BasicInfo,
    Items,
    TransportInfo,
    IssuerCarrier,
} from './Routes'

const Tab = createMaterialTopTabNavigator()

function SupportiveInvoiceDetails({
    route: {
        params: {
            id,
            title,
        },
    },
    navigation: { goBack, navigate },
}) {

    const { switchLocation: { deviceId, locationId } } = useConfig()

    const { openConfigurationSelector, translate: tempTranslate } = useTemplateLanguage()
    // const convertSuportingInvoiceWebRef = useRef()

    const context = useContext(Merchant)
    const [styles] = useTheme(themedStyles)
    const { NIPT, name, address } = useLocationDetails()
    const { translate } = ContentManager
    const client = useApolloClient()

    const [shareLoading, setShareLoading] = useState(false)
    const [printLoading, setPrintLoading] = useState(false)
    const [loadingDownloadWeb, setLoadingDownloadWeb] = useState(false)
    const [loadingPrintWeb, setLoadingPrintWeb] = useState(false)
    const [isErrorActive, setErrorActive] = useState({
        isVisible: false,
        message: '',
    })

    const [supportiveInvoiceData, setsupportiveInvoiceData] = useState({
        invoice: {},
        loading: true,
    })

    const { data } = useQuery(GET_MY_SUPPORTING_INVOICE, { fetchPolicy: 'network-only', variables: { id: id, locId: locationId, cashRegisterId: deviceId } })

    useEffect(() => {
        if (data) {
            const supportingInvoiceData = data?.getWarehouseNote
            let note = {}
            supportingInvoiceData?.note?.map((item) => {
                const itemValue = item.substring(item.indexOf('=') + 1)
                const itemKey = item.substring(0, item.indexOf('='))
                const noteitem = { [itemKey]: itemValue }
                note = { ...note, ...noteitem }
            })
            const myInvoiceMappedData = {
                basicInfo: {
                    date: moment(supportingInvoiceData?.issueDateTime).format('DD/MM/YYYY hh:mm A'),
                    receiptNr: supportingInvoiceData?.wtnOrdNum,
                    receipt: supportingInvoiceData?.wtnNum,
                    issuerNIPT: supportingInvoiceData?.issuer?.nuis,
                    invoiceType: supportingInvoiceData?.type,
                    transactionType: supportingInvoiceData?.transaction,
                    startDate: moment(supportingInvoiceData?.startDateTime).format('DD/MM/YYYY hh:mm A'),
                    amount: INF.format(supportingInvoiceData?.valueOfGoods),
                    qrCode: supportingInvoiceData?.qrCode,
                    operatorCode: supportingInvoiceData?.operatorCode,
                    NIVF: supportingInvoiceData?.fwtnic,
                    NSLF: supportingInvoiceData?.wtnic,
                    fwtnic: supportingInvoiceData?.fwtnic,
                    softCode: supportingInvoiceData?.softCode,
                    businUnitCode: supportingInvoiceData?.businUnitCode,
                    employeeEmail: supportingInvoiceData?.employeeEmail,
                    employeeDetails: supportingInvoiceData?.employeeDetails,
                },
                transportInfo: {
                    transportOwnership: supportingInvoiceData?.vehOwnership,
                    plates: supportingInvoiceData?.vehPlates,
                    startingAddress: supportingInvoiceData?.startAddr,
                    startingCity: supportingInvoiceData?.startCity,
                    startingPoint: supportingInvoiceData?.startPoint,
                    startingDate: moment(supportingInvoiceData?.startDateTime).format('DD/MM/YYYY hh:mm A'),
                    destinationAddress: supportingInvoiceData?.destinAddr,
                    destinationCity: supportingInvoiceData?.destinCity,
                    destinationPoint: supportingInvoiceData?.destinPoint,
                    destinationDate: moment(supportingInvoiceData?.destinDateTime).format('DD/MM/YYYY hh:mm A'),
                    isItemDangerous: supportingInvoiceData?.isGoodsFlammable,
                    isEscort: supportingInvoiceData?.isEscortRequired,
                },
                issuer: {
                    nuis: supportingInvoiceData?.issuer?.nuis,
                    name: supportingInvoiceData?.issuer?.name,
                    address: supportingInvoiceData?.issuer?.address,
                    city: supportingInvoiceData?.issuer?.town,
                },
                carrier: {
                    idType: supportingInvoiceData?.carrier?.idType,
                    nuis: supportingInvoiceData?.carrier?.idNum,
                    name: supportingInvoiceData?.carrier?.name,
                    address: supportingInvoiceData?.carrier?.address,
                    city: supportingInvoiceData?.carrier?.town,
                },
                items: supportingInvoiceData?.items,
            }
            setsupportiveInvoiceData({
                invoice: { ...myInvoiceMappedData, note },
                loading: false,
            })
        }
    }, [data])

    const shareReceipt = async () => {
        setShareLoading(true)
        const { data: qr } = await client.query({
            query: GET_QR,
            variables: {
                text: supportiveInvoiceData.invoice?.basicInfo?.qrCode,
            },
        })

        openConfigurationSelector(true, () => {
            RNHTMLtoPDF.convert({
                html: convertSupportingInvoice(
                    supportiveInvoiceData.invoice,
                    context?.nipt,
                    context?.company,
                    context?.location,
                    tempTranslate,
                    qr
                ),
                fileName: 'fature',
            })
                .then((res) => {
                    Share.open({
                        url: `file://${res.filePath}`,
                        subject: 'Fatura ' + title,
                        title: 'Fatura',
                    })
                        .catch(() => { })
                    setShareLoading(false)
                })
                .catch(() => {
                    setShareLoading(false)
                })
        })
        setShareLoading(false)
    }

    const printReceipt = () => {
        openConfigurationSelector(false, () => {
            setPrintLoading(true)
            printWtnReceipt({
                ...data?.getWarehouseNote,
                NIPT,
                name,
                address,
                translate: tempTranslate,
            })
                .then(() => setPrintLoading(false))
                .catch(() => {
                    setPrintLoading(false)
                    setErrorActive({
                        isVisible: true,
                        message: 'receipt_printer_error_label',
                    })
                })
        })

    }

    const { toPDF, targetRef } = usePDF({
        method: 'save',
        filename: `${supportiveInvoiceData?.invoice?.basicInfo?.receiptNr}.pdf`,
        page: { margin: Margin.NONE },
    })

    const handlePrintWeb = useReactToPrint({
        content: () => targetRef.current,
        onBeforePrint: () => setLoadingPrintWeb(true),
        onAfterPrint: () => setLoadingPrintWeb(false),
    })

    const onHandlePrintWeb = () => {
        openConfigurationSelector(false, handlePrintWeb)
    }
    const handleDownload = async () => {
        setLoadingDownloadWeb(true)
        await toPDF()
        setLoadingDownloadWeb(false)
    }


    const renderMyTabs = useMemo(() => {
        return (
            <Tabs
                initialRouteName={translate('einvoice_details_info_tab')}
                style={styles.tabView}
                screenOptions={{
                    tabBarItemStyle: Platform.OS === 'web' ? {} : { width: 'auto', minWidth: 100 },
                    tabBarScrollEnabled: Platform.OS === 'web' ? false : true,
                }}
            >
                <Tab.Screen key={'tab-info'} name={translate('einvoice_details_info_tab')}>
                    {(props) => (
                        <BasicInfo
                            {...props}
                            supportiveInvoiceData={supportiveInvoiceData.invoice.basicInfo}
                            id={id}
                            loading={supportiveInvoiceData.loading}
                        />
                    )}
                </Tab.Screen>
                <Tab.Screen key={'tab-transport'} name={translate('supporting_invoice_transport_button')}>
                    {(props) => (
                        <TransportInfo {...props} supportiveInvoiceData={supportiveInvoiceData.invoice.transportInfo} id={id} loading={supportiveInvoiceData.loading} navigate={navigate} />
                    )}
                </Tab.Screen>
                <Tab.Screen key={'tab-items'} name={translate('einvoice_preview_items_tab')}>
                    {(props) => (
                        <Items {...props} items={supportiveInvoiceData?.invoice?.items} loading={supportiveInvoiceData.loading} />
                    )}
                </Tab.Screen>
                <Tab.Screen key={'tab-issuercarrier'} name={translate('supporting_invoice_issuerCarrier_title')}>
                    {(props) => (
                        <IssuerCarrier {...props} carrier={supportiveInvoiceData?.invoice?.carrier} issuer={supportiveInvoiceData?.invoice?.issuer} id={id} loading={supportiveInvoiceData.loading} />
                    )}
                </Tab.Screen>
            </Tabs>
        )
    }, [supportiveInvoiceData.loading])

    return (
        <>
            <SafeAreaView style={styles.container}>
                <Header
                    image={images.closeIcon}
                    title={title}
                    onPress={() => goBack()}
                />
                <View>
                    <View style={styles.inlineButtons}>
                        <Button
                            variant={printLoading || supportiveInvoiceData.loading || loadingPrintWeb ? 'disabled' : 'preActive'}
                            title={Platform.OS === 'web' ? 'receipt_button_print_receipt' : 'receipt_button_print_receipt'}
                            style={styles.inlineButton1}
                            onPress={Platform.OS === 'web' ? () => onHandlePrintWeb() : () => printReceipt()}
                            icon={images.printerActive}
                            titleStyle={{ color: printLoading || supportiveInvoiceData.loading || loadingPrintWeb ? colors.white : colors.buttonTertiaryAccent }}
                            iconColor={printLoading || supportiveInvoiceData.loading || loadingPrintWeb ? colors.white : colors.secondaryIcon}
                            loader={printLoading || loadingPrintWeb}
                            loaderComponent={<Loader source={animations.vfLoaderWhite} size={24} />}
                        />
                        {Platform.OS === 'web' ?

                            <Button
                                variant={shareLoading || supportiveInvoiceData.loading || loadingDownloadWeb ? 'disabled' : 'preActive'}
                                title="receipt_download_as_pdf"
                                style={styles.inlineButton2}
                                onPress={() => {
                                    openConfigurationSelector(true, handleDownload)
                                }}
                                icon={images.download}
                                titleStyle={{ color: shareLoading || supportiveInvoiceData.loading || loadingDownloadWeb ? colors.white : colors.primaryText }}
                                iconColor={shareLoading || supportiveInvoiceData.loading || loadingDownloadWeb ? colors.white : colors.secondaryIcon}
                                loader={shareLoading || loadingDownloadWeb}
                                loaderComponent={<Loader source={animations.vfLoaderWhite} size={24} />}
                            />

                            :
                            <Button
                                variant={shareLoading || supportiveInvoiceData.loading ? 'disabled' : 'preActive'}
                                title="einvoice_share_button"
                                style={styles.inlineButton2}
                                onPress={() => shareReceipt().catch(() => { })}
                                icon={images.share}
                                titleStyle={{ color: shareLoading || supportiveInvoiceData.loading ? colors.white : colors.secondaryIcon }}
                                iconColor={shareLoading || supportiveInvoiceData.loading ? colors.white : colors.primaryText}
                                loader={shareLoading}
                                loaderComponent={<Loader source={animations.vfLoaderWhite} size={24} />}
                            />

                        }

                    </View>
                </View>
                {renderMyTabs}
                <ErrorModal
                    isVisible={isErrorActive.isVisible}
                    icon={images.error}
                    title="receipt_printer_error"
                    description="receipt_printer_error_label"
                    primaryText="receipt_open_printers_button"
                    primaryAction={() => {
                        setErrorActive({
                            isVisible: false,
                            message: '',
                        })
                        navigate('PrinterSetUp')
                    }}
                    secondaryText="checkout_button_skip"
                    secondaryAction={() => setErrorActive({
                        isVisible: false,
                        message: '',
                    })}
                    onBackdropPress={() => setErrorActive({
                        isVisible: false,
                        message: '',
                    })}
                    onBackButtonPress={() => setErrorActive({
                        isVisible: false,
                        message: '',
                    })}
                    onClosePress={() => setErrorActive({
                        isVisible: false,
                        message: '',
                    })}
                />
                {Platform.OS === 'web' && Object.keys(supportiveInvoiceData.invoice).length > 0 &&
                    <div style={styles.webStyle}>
                        <ConvertSupportingInvoice
                            invoice={supportiveInvoiceData.invoice}
                            nipt={context?.nipt}
                            company={context?.company}
                            location={context?.location}
                            ref={targetRef}
                        />
                    </div>
                }

            </SafeAreaView>

        </>
    )
}

SupportiveInvoiceDetails.propTypes = {
    route: PropTypes.object,
    navigation: PropTypes.object,
}

export default SupportiveInvoiceDetails
