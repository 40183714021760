/* eslint-disable react/prop-types */
import React, { useContext, forwardRef } from 'react'
import { TextInput as Input } from 'react-native'

import ContentManager from '../../Utils/ContentManager'

import Theme from '../../Themes/Theme'


function TextInput(props, ref) {
  const { colors } = useContext(Theme)

  const {
    placeholder,
    localize = true,
    placeHolders,
    translateOption,
    placeholderTextColor,
    ...rest
  } = props

  const placeholderKey = placeholder + ''

  return (
    <Input
      {...rest}
      ref={ref}
      allowFontScaling={false}
      placeholder={localize ? ContentManager.translate(placeholderKey, placeHolders, translateOption) : placeholderKey}
      placeholderTextColor={placeholderTextColor || colors.placeholder}
      autoCorrect={false}
    />
  )
}

export default forwardRef(TextInput)
