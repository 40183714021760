import { BluetoothEscposPrinter } from 'react-native-bluetooth-escpos-printer'
import { Platform } from 'react-native'
import moment from 'moment'

import { INF } from './Helpers'

const processTypes = [
  {
    code: 'P1',
    description: 'process_type_p1_description',
    type: 'P1_CONTRACT',
  },
  {
    code: 'P2',
    description: 'process_type_p2_description',
    type: 'P2_PERIODIC_INVOICE',
  },
  {
    code: 'P3',
    description: 'process_type_p3_description',
    type: 'P3_UNFORESEEN_ORDER',
  },
  {
    code: 'P4',
    description: 'process_type_p4_description',
    type: 'P4_ADVANCE_PAYMENT',
  },
  {
    code: 'P5',
    description: 'process_type_p5_description',
    type: 'P5_SPOT_PAYMENT',
  },
  {
    code: 'P6',
    description: 'process_type_p6_description',
    type: 'P6_PAYMENT_BEFORE_PURCHASE',
  },
  {
    code: 'P7',
    description: 'process_type_p7_description',
    type: 'P7_DISPATCH_NOTE',
  },
  {
    code: 'P8',
    description: 'process_type_p8_description',
    type: 'P8_DISPATCH_NOTE_RECEIPT',
  },
  {
    code: 'P9',
    description: 'process_type_p9_description',
    type: 'P9_APPROVAL_OR_NEGATIVE',
  },
  {
    code: 'P10',
    description: 'process_type_p10_description',
    type: 'P10_CORRECTIVE_INVOICE',
    disabled: true,
  },
  {
    code: 'P11',
    description: 'process_type_p11_description',
    type: 'P11_PARTIAL_INVOICING',
  },
  {
    code: 'P12',
    description: 'process_type_p12_description',
    type: 'P12_SELF_INVOICE',
  },
]

const documentTypes = [
  {
    code: '388',
    description: 'document_type_388_description',
    type: 'TAXINVOICE',
  },
  {
    code: '82',
    description: 'document_type_82_description',
    type: 'METERED_SERVICE',
  },
  {
    code: '325',
    description: 'document_type_325_description',
    type: 'PROFORMA_INVOICE',
  },
  {
    code: '326',
    description: 'document_type_326_description',
    type: 'PARTIAL_INVOICE',
  },
  {
    code: '380',
    description: 'document_type_380_description',
    type: 'COMMERCIAL_INVOICE',
  },
  {
    code: '381',
    description: 'document_type_381_description',
    type: 'CREDIT_NOTE',
  },
  {
    code: '383',
    description: 'document_type_383_description',
    type: 'DEBIT_NOTE',
  },
  {
    code: '384',
    description: 'document_type_384_description',
    type: 'CORRECTEDINVOICE',
    disabled: true,
  },
  {
    code: '386',
    description: 'document_type_386_description',
    type: 'PREPAYMENT_INVOICE',
  },
  {
    code: '394',
    description: 'document_type_394_description',
    type: 'LEASE_INVOICE',
  },
]

const getProcessDescr = (processCode) => {
  const description = processTypes.find(item => item.type === processCode)
  return description?.description
}

const getDocumentDescr = (documentCode) => {
  const description = documentTypes.find(item => item.type === documentCode)
  return description?.description
}

const getDocumentDescrCode = (documentCode) => {
  const description = documentTypes.find(item => item.type === documentCode)
  return description?.code
}

const printEinvoice = async ({
  eInvoiceData,
  translate,
}) => {
  const printerTranslate = (val) => {
    return translate(val, true)
  }
  const {
    basicInfo,
    process,
    additionalInfo,
    total,
    invoiceLine,
    supplierParty,
    customerParty,
    note,
  } = eInvoiceData


  let price, c
  const notes = additionalInfo?.note.filter(item => item.includes('note='))
  const valueWithoutSpace = (val) => {
    return val ? INF.format((val)).replace(/\s/g, '') : ''
  }

  try {
    await BluetoothEscposPrinter.printerInit()
    await BluetoothEscposPrinter.printerAlign(
      BluetoothEscposPrinter.ALIGN.CENTER,
    )
    await BluetoothEscposPrinter.setBlob(0)
    await BluetoothEscposPrinter.printText(`${printerTranslate('e_invoice_title_label_1')}\n`, {
      fonttype: 2,
      widthtimes: 1,
      heigthtimes: 1,
    })
    await BluetoothEscposPrinter.printText(`${printerTranslate('e_invoice_title_label_2')}\n`, {
      fonttype: 2,
      widthtimes: 1,
      heigthtimes: 1,
    })
    await BluetoothEscposPrinter.printerAlign(
      BluetoothEscposPrinter.ALIGN.CENTER,
    )
    await BluetoothEscposPrinter.printQRCode(
      note?.QrCode,
      Platform.OS === 'android' ? 300 : 200,
      BluetoothEscposPrinter.ERROR_CORRECTION.Q,
    )

    await BluetoothEscposPrinter.printerAlign(
      BluetoothEscposPrinter.ALIGN.LEFT,
    )
    await BluetoothEscposPrinter.printText(`${printerTranslate('receipt_number_label')}: ${basicInfo?.id}\r\n`, {
      codepage: 0,
      widthtimes: 0,
      heigthtimes: 0,
      fonttype: 5,
    })

    await BluetoothEscposPrinter.printText(`${printerTranslate('e_invoice_date')}: ${moment(note?.IssueDateTime).format('DD/MM/YYYY')}\r\n`, {
      codepage: 0,
      widthtimes: 0,
      heigthtimes: 0,
      fonttype: 5,
    })

    await BluetoothEscposPrinter.printText(`${printerTranslate('receipt_seller_label')}: ${supplierParty?.registrationName}\r\n`, {
      codepage: 0,
      widthtimes: 0,
      heigthtimes: 0,
      fonttype: 5,
    })

    await BluetoothEscposPrinter.printText(`${printerTranslate('receipt_nipt_label')}: ${supplierParty?.issuerNUIS}\r\n`, {
      codepage: 0,
      widthtimes: 0,
      heigthtimes: 0,
      fonttype: 5,
    })
    await BluetoothEscposPrinter.printText(`${printerTranslate('e_invoice_address_label')}: ${supplierParty?.address.streetName} ${supplierParty?.address.cityCode} ${supplierParty?.address.countryCode}\r\n`, {
      codepage: 0,
      widthtimes: 0,
      heigthtimes: 0,
      fonttype: 5,
    })
    await BluetoothEscposPrinter.printText(`${printerTranslate('e_invoice_tel_label')}: ${supplierParty?.contact?.telephone || ''}\r\n`, {
      codepage: 0,
      widthtimes: 0,
      heigthtimes: 0,
      fonttype: 5,
    })
    await BluetoothEscposPrinter.printText(`${printerTranslate('receipt_buyer_label')}: ${customerParty?.name}\r\n`, {
      codepage: 0,
      widthtimes: 0,
      heigthtimes: 0,
      fonttype: 5,
    })
    await BluetoothEscposPrinter.printText(`${printerTranslate('receipt_nipt_label')}: ${customerParty?.issuerNUIS}\r\n`, {
      codepage: 0,
      widthtimes: 0,
      heigthtimes: 0,
      fonttype: 5,
    })
    await BluetoothEscposPrinter.printText(`${printerTranslate('e_invoice_address_label')}: ${customerParty?.address.streetName} ${customerParty?.address.cityCode} ${customerParty?.address.countryCode}\r\n`, {
      codepage: 0,
      widthtimes: 0,
      heigthtimes: 0,
      fonttype: 5,
    })
    await BluetoothEscposPrinter.printText(`${printerTranslate('e_invoice_tel_label')}: ${customerParty?.contact?.telephone || ''}\r\n`, {
      codepage: 0,
      widthtimes: 0,
      heigthtimes: 0,
      fonttype: 5,
    })
    await BluetoothEscposPrinter.printText(
      '--------------------------------\r\n',
      {},
    )
    await BluetoothEscposPrinter.printerAlign(
      BluetoothEscposPrinter.ALIGN.LEFT,
    )
    const len = (invoiceLine || []).length
    for (let i = 0; i < len; i++) {
      const item = invoiceLine[i]
      price = `${valueWithoutSpace(item?.totalMoney?.amount || '')} ${item?.totalMoney?.currency || ''}`
      c = price.length + 2
      await BluetoothEscposPrinter.printColumn(
        [32 - c, c],
        [BluetoothEscposPrinter.ALIGN.LEFT, BluetoothEscposPrinter.ALIGN.RIGHT],
        [item.item.name, price],
        {},
      )
      await BluetoothEscposPrinter.printerAlign(
        BluetoothEscposPrinter.ALIGN.LEFT,
      )
      await BluetoothEscposPrinter.printText(
        `${item.quantity || 0} X ${(
          INF.format(item.item.price?.amount) || 0
        )} ${item.lineExtension?.currency}\r\n`,
        {},
      )

      // for (let j = 0; j < (item?.allowances?.length || 0); j++) {
      //   const modifier = item?.allowances[j]
      //   await BluetoothEscposPrinter.printerAlign(
      //     BluetoothEscposPrinter.ALIGN.LEFT,
      //   )
      //   await BluetoothEscposPrinter.printText(
      //     ` ${modifier.name} (${(
      //       modifier.appliedMoney?.amount || 0
      //     )} ${modifier.appliedMoney?.currency})\r\n`,
      //     {},
      //   )
      // }
    }
    await BluetoothEscposPrinter.printText(
      '--------------------------------\r\n',
      {},
    )
    price = `${valueWithoutSpace((basicInfo?.amount || 0))} ${basicInfo?.amountCurrency}`
    c = price.length + 2
    await BluetoothEscposPrinter.printColumn(
      [32 - c, c],
      [BluetoothEscposPrinter.ALIGN.LEFT, BluetoothEscposPrinter.ALIGN.RIGHT],
      [`${printerTranslate('receipt_total')}`, price],
      {},
    )
    price = `${valueWithoutSpace((total?.allowance || 0))}`
    c = price.length + 2
    await BluetoothEscposPrinter.printColumn(
      [32 - c, c],
      [BluetoothEscposPrinter.ALIGN.LEFT, BluetoothEscposPrinter.ALIGN.RIGHT],
      [`${printerTranslate('receipt_total_discount_label')}`, price],
      {},
    )
    price = `${valueWithoutSpace((total?.taxExclusive || 0))}`
    c = price.length + 2
    await BluetoothEscposPrinter.printColumn(
      [32 - c, c],
      [BluetoothEscposPrinter.ALIGN.LEFT, BluetoothEscposPrinter.ALIGN.RIGHT],
      [`${printerTranslate('e_invoice_total_amount_no_tax')}`, price],
      {},
    )
    price = `${valueWithoutSpace((total?.taxAmount || 0))}`
    c = price.length + 2
    await BluetoothEscposPrinter.printColumn(
      [32 - c, c],
      [BluetoothEscposPrinter.ALIGN.LEFT, BluetoothEscposPrinter.ALIGN.RIGHT],
      [`${printerTranslate('e_invoice_total_tax_amount')}`, price],
      {},
    )
    price = `${valueWithoutSpace((total?.taxInclusive || 0))}`
    c = price.length + 2
    await BluetoothEscposPrinter.printColumn(
      [32 - c, c],
      [BluetoothEscposPrinter.ALIGN.LEFT, BluetoothEscposPrinter.ALIGN.RIGHT],
      [`${printerTranslate('e_invoice_total_amount_with_tax')}`, price],
      {},
    )
    await BluetoothEscposPrinter.printText(
      '\r\n',
      {},
    )

    await BluetoothEscposPrinter.printerAlign(
      BluetoothEscposPrinter.ALIGN.LEFT,
    )
    await BluetoothEscposPrinter.printText('\r\n', {
      codepage: 0,
      widthtimes: 0,
      heigthtimes: 0,
      fonttype: 5,
    })
    await BluetoothEscposPrinter.printText(`${printerTranslate('e_invoice_payment_type_label')}: ${printerTranslate('e_invoice_payment_type_value')}\r\n`, {
      codepage: 0,
      widthtimes: 0,
      heigthtimes: 0,
      fonttype: 5,
    })
    await BluetoothEscposPrinter.printText(`${printerTranslate('e_invoice_bussines_unit_code_label')}: ${note?.BusinessUnitCode}\r\n`, {
      codepage: 0,
      widthtimes: 0,
      heigthtimes: 0,
      fonttype: 5,
    })
    await BluetoothEscposPrinter.printText(`${printerTranslate('e_invoice_bussines_soft_code_label')}: ${note?.SoftwareCode}\r\n`, {
      codepage: 0,
      widthtimes: 0,
      heigthtimes: 0,
      fonttype: 5,
    })
    await BluetoothEscposPrinter.printText(`${printerTranslate('e_invoice_bussines_operator_code_label')}: ${note?.OperatorCode}\r\n`, {
      codepage: 0,
      widthtimes: 0,
      heigthtimes: 0,
      fonttype: 5,
    })
    await BluetoothEscposPrinter.printText(`${printerTranslate('receipt_iic_label')}: ${note?.IIC}\r\n`, {
      codepage: 0,
      widthtimes: 0,
      heigthtimes: 0,
      fonttype: 5,
    })
    await BluetoothEscposPrinter.printText(`${printerTranslate('receipt_fic_label')}: ${note?.FIC}\r\n`, {
      codepage: 0,
      widthtimes: 0,
      heigthtimes: 0,
      fonttype: 5,
    })
    await BluetoothEscposPrinter.printText(`${printerTranslate('e_invoice_eic_label')}: ${note?.EIC}\r\n\n`, {
      codepage: 0,
      widthtimes: 0,
      heigthtimes: 0,
      fonttype: 5,
    })
    await BluetoothEscposPrinter.printText(`${printerTranslate('e_invoice_info_label')}\r\n`, {
      codepage: 0,
      widthtimes: 0,
      heigthtimes: 0,
      fonttype: 5,
    })

    await BluetoothEscposPrinter.printText(`${printerTranslate('e_invoice_payment_bic_label')} / ${printerTranslate('e_invoice_bill_type')}:  ${printerTranslate(getDocumentDescr(process?.invoiceDocumentCode))} / ${getDocumentDescrCode(process?.invoiceDocumentCode)}\r\n`, {
      codepage: 0,
      widthtimes: 0,
      heigthtimes: 0,
      fonttype: 5,
    })
    await BluetoothEscposPrinter.printText(`${printerTranslate('e_invoice_proccess_type')}: ${printerTranslate(getProcessDescr(process?.invoiceProcessCode))}\r\n\n`, {
      codepage: 0,
      widthtimes: 0,
      heigthtimes: 0,
      fonttype: 5,
    })

    await BluetoothEscposPrinter.printText(`${printerTranslate('e_invoice_notes_label')}: ${notes[0]?.slice(5) || '-'} \r\n\n`, {
      codepage: 0,
      widthtimes: 0,
      heigthtimes: 0,
      fonttype: 5,
    })
    if (notes?.length > 0) {
      await BluetoothEscposPrinter.printText(`${printerTranslate('e_invoice_scan_qr')}\r\n\n`, {
        codepage: 0,
        widthtimes: 0,
        heigthtimes: 0,
        fonttype: 5,
      })
    }
    if (additionalInfo?.name || additionalInfo.actualDeliveryDate || additionalInfo?.streetName) {

      await BluetoothEscposPrinter.printText(`${printerTranslate('e_invoice_remittances_label')}\r\n`, {
        codepage: 0,
        widthtimes: 0,
        heigthtimes: 0,
        fonttype: 5,
      })
      await BluetoothEscposPrinter.printText(`${printerTranslate('e_invoice_recipient_name_label')}: ${additionalInfo?.name}\r\n`, {
        codepage: 0,
        widthtimes: 0,
        heigthtimes: 0,
        fonttype: 5,
      })
      await BluetoothEscposPrinter.printText(`${printerTranslate('e_invoice_date_of_shipment_label')}: ${additionalInfo.actualDeliveryDate ? moment(additionalInfo.actualDeliveryDate)?.format('DD/MM/YYYY') : ''}\r\n`, {
        codepage: 0,
        widthtimes: 0,
        heigthtimes: 0,
        fonttype: 5,
      })
      await BluetoothEscposPrinter.printText(`${printerTranslate('e_invoice_address_label')}: ${additionalInfo?.streetName}\r\n`, {
        codepage: 0,
        widthtimes: 0,
        heigthtimes: 0,
        fonttype: 5,
      })
      await BluetoothEscposPrinter.printText(`${printerTranslate('e_invoice_country_label')}: ${additionalInfo?.countryCode}\r\n\n`, {
        codepage: 0,
        widthtimes: 0,
        heigthtimes: 0,
        fonttype: 5,
      })
    }

    if (note?.IICRef && note?.IssueDateRef) {
      await BluetoothEscposPrinter.printText(`${printerTranslate('receipt_section_primary_invoice')}\r\n`, {
        codepage: 0,
        widthtimes: 0,
        heigthtimes: 0,
        fonttype: 5,
      })
      await BluetoothEscposPrinter.printText(`${printerTranslate('receipt_section_primary_iic_label')}: ${note.IICRef}\r\n`, {
        codepage: 0,
        widthtimes: 0,
        heigthtimes: 0,
        fonttype: 5,
      })
      await BluetoothEscposPrinter.printText(`${printerTranslate('receipt_date_time_label')}: ${moment(note.IssueDateRef)?.format('DD/MM/YYYY')}\r\n\n`, {
        codepage: 0,
        widthtimes: 0,
        heigthtimes: 0,
        fonttype: 5,
      })
    }

    await BluetoothEscposPrinter.printText(
      '--------------------------------\r\n\n',
      {},
    )
    await BluetoothEscposPrinter.printerAlign(
      BluetoothEscposPrinter.ALIGN.CENTER,
    )
    await BluetoothEscposPrinter.printText(`${printerTranslate('e_invoice_buyer_label')}\n ${printerTranslate('e_invoice_signature_field_label')}\n\n`, {
      codepage: 0,
      widthtimes: 0,
      heigthtimes: 0,
      fonttype: 5,
    })
    await BluetoothEscposPrinter.printText(
      '--------------------------------\r\n\n',
      {},
    )
    await BluetoothEscposPrinter.printText(`${printerTranslate('e_invoice_carrier_label')}\n ${printerTranslate('e_invoice_signature_field_label')}\n\n`, {
      codepage: 0,
      widthtimes: 0,
      heigthtimes: 0,
      fonttype: 5,
    })
    await BluetoothEscposPrinter.printText(
      '--------------------------------\r\n\n',
      {},
    )
    await BluetoothEscposPrinter.printText(`${printerTranslate('e_invoice_seller_label')}\n ${printerTranslate('e_invoice_signature_field_label')}\n\n`, {
      codepage: 0,
      widthtimes: 0,
      heigthtimes: 0,
      fonttype: 5,
    })
    await BluetoothEscposPrinter.printText(
      '--------------------------------\r\n\n',
      {},
    )

    await BluetoothEscposPrinter.printText(`${printerTranslate('receipt_generated_by')} Vodafone eBiznes\n\n\n`, {
      codepage: 0,
      widthtimes: 0,
      heigthtimes: 0,
      fonttype: 5,
    })
    await BluetoothEscposPrinter.printText('\n\n\n', {})
    return
  } catch (e) {
    throw e
  }
}

export default printEinvoice
