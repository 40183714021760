import React, { useState } from 'react'
import { SafeAreaView, ScrollView, View } from 'react-native'
import { useTheme } from 'react-native-themed-styles'

import { Button, Header, InputWithTags, Text } from '../../Components'

import ContentManager from '../../Utils/ContentManager'

import { colors, images } from '../../Themes'
import themedStyles from './RemoveTCR.Styles'



function RemoveTCR({ navigation: { goBack }, route: { params: { id, usersNr, registerName } } }) {

    const [styles] = useTheme(themedStyles)
    const [value, setValue] = useState(id)
    const [isError, setIsError] = useState(false)
    const { translate } = ContentManager
    return (
        <SafeAreaView style={styles.container}>
            <Header title={"Remove TCR ID"} image={images.back} onPress={() => goBack()} />
            <ScrollView showsVerticalScrollIndicator={false} contentContainerStyle={styles.scrollContainer} >

                <Text i18nKey={"Remove TCR ID"} weight="bold" color={colors.red} size='h5' align='left' />
                <Text i18nKey={"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."} weight="bold" color={colors.primaryText} />
                <Text i18nKey={"Actual TCR"} style={styles.subHeader} />
                <View style={styles.deviceInfoContainer}>
                    <View style={styles.deviceInfoRow}>
                        <Text i18nKey={"TCR ID"} color={colors.midGrey} />
                        <Text i18nKey={id} />
                    </View>
                    <View style={styles.separator} />
                    <View style={styles.deviceInfoRow}>
                        <Text i18nKey={"Users connected"} color={colors.midGrey} />
                        <Text i18nKey={usersNr} />
                    </View>
                    <View style={styles.separator} />
                    <View style={styles.deviceInfoRow}>
                        <Text i18nKey={"Cash register name"} color={colors.midGrey} />
                        <Text i18nKey={registerName} />
                    </View>
                </View>
                <Text i18nKey={"Confirm the TCR ID you want to remove?"} style={styles.subHeader} />

                <InputWithTags
                    label="cash_register_name_label"
                    value={value}
                    onChangeText={setValue}
                    // editable={status === 'ACTIVE'}
                    error={translate('cash_register_name_label')}
                    autoCorrect={false}
                    inputContainerStyle={styles.inputContainer}
                    labelOffset={{
                        x0: 0,
                        y0: -7,
                        x1: 0,
                        y1: 2,
                    }}
                    maxLength={20}
                />
                <Text i18nKey={"Are you sure you want to remove it?"} style={styles.subHeader} />

                <Button
                    title={'Test'}
                    variant="active"
                    style={{ marginVertical: 15 }}
                // onPress={() => { setTcrModalVisible(!TcrModalVisible) }}
                />
                <Button
                    title={'Testt'}
                    variant="text"
                    style={{ marginBottom: 15 }}
                // onPress={() => { setTcrModalVisible(!TcrModalVisible) }}
                />
            </ScrollView>
        </SafeAreaView>
    )
}

export default RemoveTCR
