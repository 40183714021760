const getOnBoardingCertificate = state => (state.onBoardingData.certificateFirst || {})
const getOnBoardingCertificateSecondStep = state => (state.onBoardingData.certificateSecond || {})
const getOnBoardingCashRegister = state => (state.onBoardingData.cashRegister || {})
const getOnBoardinginit = state => (state.onBoardingData || {})

export {
    getOnBoardingCertificate,
    getOnBoardingCertificateSecondStep,
    getOnBoardingCashRegister,
    getOnBoardinginit,
}
