import { gql } from '@apollo/client'

const GET_TAXES = gql`
  query listTaxes ($filter: Filter, $limit: Int, $nextToken: String) {
    listTaxes(
      listTaxesInput:{
        filter: $filter,
        limit: $limit,
        nextToken: $nextToken
      }
    ){
      taxes{
        id
        name
        percentage
        isDefault
      },
      nextToken
    }
  }
`

export default GET_TAXES
