import { gql } from '@apollo/client'

export const LIST_ITEMS = gql`
  query ($filter: Filter, $limit: Int, $nextToken: String ,$locId: String, $cashRegisterId: String ){
    listItems (listItemsInput: {
      filter: $filter,
      limit: $limit,
      nextToken: $nextToken
    }
    requestedLocationAndCashRegister: {
      locationId: $locId,
      cashRegisterId: $cashRegisterId
    }
    ){
      items{
        id
        barcode
        name
        description
        labelColor
        imageUrl
        category{
          id
          name
          labelColor
        }
        tax{
          id
          name
          percentage
        }
        taxInclusionType
        taxExemptionType
        itemVariationsCount
        itemVariation{
          id
          name
          priceMoney{
            amount
            currency
          }
          prices{
            amount
            currency
          }
        }
        discountsCount
        discount{
          id
          name
          discountType
          percentage
          amountMoney{
            amount
            currency
          }
          prices {
            amount
            currency
          }
        }
      },
      nextToken
    }
  }
`

