import { Platform } from 'react-native'

import { styleSheetFactory } from '../../Themes/Colors'



export const modalStyles = styleSheetFactory((colors) => ({
    bottomSheetWrapper: {
        paddingBottom: Platform.OS === 'ios' ? 20 : 20,
    },
    modalWrapper: {
        padding: 16,
    },
    modalHeader: {
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    modalCloseIcon: {
        width: 32,
        height: 32,
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 32 / 2,
        backgroundColor: colors.lightGray,
    },
    inputPromptText: {
        marginTop: 20,
        marginBottom: 10,
    },
    selectPromptText: {
        marginTop: 16,
        marginBottom: -7.5,
    },
    setCodeButton: {
        marginHorizontal: 16,
        marginTop: 12.5,

    },
    setCodeButtonDisabled: {
        marginHorizontal: 16,
        marginTop: 12.5,
        backgroundColor: colors.disabled,
    },
    operatorCodeHelperItalic: {
        fontStyle: 'italic',
    },
    operatorCodeHelper: {
        marginTop: 5,
        marginBottom: 10,
        textAlign: 'left',
        fontFamily: 'Vodafone Rg',
        fontSize: 14,
        color: colors.primaryText,
    },
    listStyle: {
        maxHeight: 300,
    },
    listContainerStyle: {
        flexGrow: 1,
        paddingBottom: 20,
    },
}))
