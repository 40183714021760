/* eslint-disable react-native/no-inline-styles */

import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { View, TouchableOpacity} from 'react-native'

import { useDropzone } from 'react-dropzone'
import { useTheme } from 'react-native-themed-styles'

import Icon from '../Icon'
import Text from '../Text'

import images from '../../Themes/Images'
import Theme from '../../Themes/Theme'

import themedStyles from './CertificateInputWeb.Styles'

const FileDropzone = (props) => {
    const {
        accept,
        disabled,
        files,
        getFilesFromEvent,
        maxFiles,
        maxSize,
        minSize,
        noClick,
        noDrag,
        noDragEventsBubbling,
        noKeyboard,
        onDrop,
        onDropAccepted,
        onDropRejected,
        onFileDialogCancel,
        onRemove,
        onRemoveAll,
        onUpload,
        preventDropOnDocument,
        multiple,
        cleanFile,
        fileName,
        attached,
        formatLabel,
        ...other
    } = props
    const [styles] = useTheme(themedStyles)
    const { colors } = useContext(Theme)

    // We did not add the remaining props to avoid component complexity
    // but you can simply add it if you need to.
    const { getRootProps, getInputProps } = useDropzone({
        accept,
        maxFiles,
        maxSize,
        minSize,
        onDrop,
        multiple: false,
    })

    return (
        <View
        style={{
            backgroundColor: colors.tertiaryBackground,
            overflow: 'hidden',
            borderRadius: 7,
            marginTop: 10,
            borderColor: colors.separator,
            borderWidth: 1,
          }}
        {...other}>
            {!attached && (
                <View style={[styles.subFileContainer, { paddingVertical: 10, cursor:'pointer' }]}   {...getRootProps()}>
                    <input {...getInputProps()} />
                    <Text
                        i18nKey="certificate_choose_label"
                        color={colors.secondaryAccent}
                        size="h4"
                    />
                    <Text
                        i18nKey={formatLabel}
                        weight="light"
                        size="footnote"
                    />
                    <View
                        style={{
                            backgroundColor: colors.background,
                            margin: 5,
                            borderRadius: 7,
                        }}>
                        <Icon
                            source={images.attachFile}
                            size={35}
                            color={colors.secondaryAccent}
                            style={{ margin: 20 }}
                        />
                    </View>
                </View>

            )}
            {attached && (
                 <View
                 style={{
                   flexDirection: 'row',
                   justifyContent: 'space-between',
                   padding: 15,
                   alignItems: 'center',
                 }}>
                 <View
                   style={{ flexGrow: 1, flexShrink: 1, flexDirection: 'row', alignItems: 'center', marginRight: 60 }}>
                   <Icon
                     source={images.uploadedSuccess}
                     size={35}
                     style={{ marginRight: 10 }}
                   />
                   <Text i18nKey={fileName} numberOfLines={1} />
                 </View>
                 <TouchableOpacity onPress={cleanFile} style={styles.clearContainer}>
                   <Icon
                     source={images.close}
                     color={colors.secondaryIcon}
                     size={12}
                   />
                 </TouchableOpacity>
               </View>
            )}
        </View>
    )
}

FileDropzone.propTypes = {
    accept: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(PropTypes.string),
    ]),
    disabled: PropTypes.bool,
    files: PropTypes.array,
    getFilesFromEvent: PropTypes.func,
    maxFiles: PropTypes.number,
    maxSize: PropTypes.number,
    minSize: PropTypes.number,
    noClick: PropTypes.bool,
    noDrag: PropTypes.bool,
    noDragEventsBubbling: PropTypes.bool,
    noKeyboard: PropTypes.bool,
    onDrop: PropTypes.func,
    onDropAccepted: PropTypes.func,
    onDropRejected: PropTypes.func,
    onFileDialogCancel: PropTypes.func,
    onRemove: PropTypes.func,
    onRemoveAll: PropTypes.func,
    onUpload: PropTypes.func,
    preventDropOnDocument: PropTypes.bool,
    multiple: PropTypes.bool,
    cleanFile:PropTypes.func,
    fileName: PropTypes.string,
    attached: PropTypes.bool,
    formatLabel: PropTypes.string,
}

FileDropzone.defaultProps = {
    files: [],
    formatLabel:'certificate_file_format_info',
}

export default FileDropzone
