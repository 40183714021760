import { styleSheetFactory } from '../../../../Themes/Colors'

const styles = styleSheetFactory(colors => ({
  container: {
    flex: 1,
    backgroundColor: colors.background,
  },
  fillRemaining: {
    flexGrow: 1,
    paddingBottom: 20,
  },
  buttons: {
    flexDirection: 'row',
    marginBottom: 15,
    marginTop: 10,
    marginHorizontal: 15,
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  leftButton: {
    flex: 1,
    height: 50,
  },
  rightButton: {
    flex: 1,
    height: 50,
    marginRight: 15,
  },
  topContent: {
    paddingHorizontal: 15,
  },
  sectionTitle: {
    marginBottom: 5,
  },
  sectionNote: {
    marginBottom: 16,
  },
  currency: {
    marginBottom: 16,
  },
  rightButtonIcon: {
    marginLeft: 10,
  },
  endDateInput: {
    marginBottom: 5,
    flex: 1,
    marginLeft: 5,
  },
  startDateInput: {
    flex: 1,
    marginRight: 5,
    marginBottom: 5,
  },
  buttonWrapper: {
    flexGrow: 1,
    flexDirection: 'row',
    alignItems: 'center',
  },
  AccessItem: {
    justifyContent: 'center',
    overflow: 'hidden',
    marginVertical: 16,
    borderBottomLeftRadius: 7,
    borderBottomRightRadius: 7,
    marginHorizontal: 0,
  },
  deliviryWrapper: {
    marginHorizontal: 0,

  },
  touchable: {
    flexGrow: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',

  },
}))

const inputStyles = styleSheetFactory(colors => ({
  touchableInput: {
    marginBottom: 16,
  },
}))

const selectStyles = styleSheetFactory(colors => ({
  itemSelected_false: {
    flexDirection: 'row',
    marginHorizontal: 15,
    borderRadius: 10,
    paddingVertical: 10,
    paddingHorizontal: 15,
    backgroundColor: colors.white,
    alignItems: 'center',
    height: 60,
  },
  itemSelected_true: {
    flexDirection: 'row',
    marginHorizontal: 15,
    borderRadius: 10,
    paddingVertical: 10,
    paddingHorizontal: 15,
    backgroundColor: colors.white,
    alignItems: 'center',
    height: 60,
    borderWidth: 1,
    borderColor: colors.secondaryAccent,
  },
  itemTextSelected_false: {
    flexGrow: 1,
    flexShrink: 1,
  },
  itemTextSelected_true: {
    flexGrow: 1,
    flexShrink: 1,
  },
  separator: {
    width: 1,
    backgroundColor: colors.disabled,
    marginHorizontal: 15,
    height: 40,
  },
  isDisabled_true: {
    backgroundColor: colors.secondaryBackround,
  },
  itemCode: {
    width: 30,
  },
  header: {
    margin: 15,
    marginTop: 30,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  searchContainer: {
    flexDirection: 'row',
    marginHorizontal: 15,
    marginBottom: 15,
    backgroundColor: colors.white,
    borderRadius: 10,
    justifyContent: 'space-between',
    alignItems: 'center',
    flexGrow: 1,
  },
  searchIcon: {
    marginHorizontal: 15,
    marginVertical: 10,
  },
  searchInput: {
    flexGrow: 1,
    borderRadius: 10,
    paddingVertical: 10,
    marginRight: 15,
    color: colors.primaryText,
  },
  clearSearchContainer: {
    borderRadius: 18,
    padding: 5,
    marginRight: 15,
    backgroundColor: colors.background,
  },
  listStyle: {
    height: 300,
  },
  listContainerStyle: {
    flexGrow: 1,
    paddingBottom: 20,
  },
  verticalSelfSpacing: { paddingVertical: 7.5 },
}))

export { inputStyles, selectStyles }

export default styles
