import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { View, Text as RNText, TouchableWithoutFeedback, FlatList, Linking, TouchableOpacity } from 'react-native'
import { useTheme } from 'react-native-themed-styles'
import { useQuery } from '@apollo/client'



import BottomSheetModal from '../BottomSheetModal'
import Text from '../Text'
import Icon from '../Icon'
import InputWithIcon from '../InputWithIcon'
import Button from '../Button'

import Type from '../Type'

import ContentManager from '../../Utils/ContentManager'

import Loader from '../Loader'

import { modalStyles } from './BottomSheetCodeInput.styles'
import { images, colors } from '../../Themes'



function BottomSheetCodeInput({
    isVisible,
    onChangeText,
    editable,
    onClose,
    title,
    inputPrompt,
    inputLabel,
    note,
    buttonLabel,
    data,
    inputValue,
    selected,
    select,
    onSave,
    query,
    setData,
    mapItems,
}) {
    const { translate } = ContentManager
    const [styles] = useTheme(modalStyles)
    const [tcrFormatError, setTcrFormatError] = useState(false)
    const [isButtonDisabled, setisButtonDisabled] = useState(true)
    const regex = /^[a-z]{2}\d{3}[a-z]{2}\d{3}$/
    const inputIsEmpty = inputValue?.trim() === ''
    // const [operatorsData, setOperatorsData] = useState([])



    const { data: operators, loading: operatorsLoading } = useQuery(query, {
        fetchPolicy: 'network-only',
        variables: {
            id: '1d',
        },
    })


    useEffect(() => {
        setData(mapItems(operators))
    }, [operators])


    const handleIconPress = () => {
        onChangeText('')
        setTcrFormatError(false)

    }

    const handleModalClose = () => {
        onClose()
        select('')
        onChangeText('')
    }



    const handleSetOperatorCode = () => {

        if (inputIsEmpty && selected) {
            onSave(selected.code)
            onClose()
        } else {
            if (!regex.test(inputValue) && inputValue) {
                setTcrFormatError(true)
            } else {
                onSave(inputValue)
                onClose()
            }
        }
    }

    useEffect(() => {
        if (inputIsEmpty) {
            setisButtonDisabled(true)
        } else {
            setisButtonDisabled(false)
        }


    }, [inputValue, isVisible, inputIsEmpty])


    const keyExtractor = (_, index) => 'item_' + index
    const renderItem = ({ item }) => (
        <Type
            fullWidth
            item={{ ...item, disabled: inputValue?.length > 0 }}
            selected={selected}
            onPress={() => {
                select(item)
                setisButtonDisabled(false)
            }}
        />

    )

    return (
        <BottomSheetModal
            isVisible={isVisible}
            onClose={handleModalClose}
            contentContainerStyle={styles.bottomSheetWrapper}
        >
            <View style={styles.modalWrapper} >
                <View style={styles.modalHeader} >
                    <Text i18nKey={title} size="body" weight="bold" color={colors.accent} />
                    <TouchableOpacity style={styles.modalCloseIcon} onPress={handleModalClose} >
                        <Icon source={images.closeIcon} color={colors.black} size={20} />
                    </TouchableOpacity>
                </View>
                <Text i18nKey={inputPrompt} size="body" style={styles.inputPromptText} />
                <InputWithIcon
                    label={inputLabel}
                    value={inputValue}
                    onChangeText={(newText) => {
                        setTcrFormatError(false)
                        onChangeText(newText)
                        setisButtonDisabled(false)
                    }}
                    icon={inputIsEmpty ? null : images.closeIcon}
                    iconSize={20}
                    autoCorrect={false}
                    onIconPress={handleIconPress}
                    noIcon={inputIsEmpty}
                    closeIcon
                    editable={editable}
                    autoCapitalize="none"
                    error={tcrFormatError}
                    inputContainerStyle={styles.inputContainer}
                    inputWrapperStyle={styles.itemSpacing}
                    onFocus={() => setTcrFormatError(false)}
                    labelOffset={{
                        x0: 0,
                        y0: -7,
                        x1: 0,
                        y1: 2,
                    }}
                />
                {tcrFormatError && <Text i18nKey={'CodeFormatIncorrect'} size="body" color={colors.accent} />}
                {Boolean(note) && (<RNText
                    onPress={() => Linking.openURL('https://efiskalizimi-app.tatime.gov.al/self-care')}
                    style={styles.operatorCodeHelper}
                    allowFontScaling={false}
                >
                    <RNText>{translate('cash_register_business_code1')}</RNText>
                    <RNText style={{ color: colors.secondaryAccent }}>{translate('cash_register_business_code2')}</RNText>
                    <RNText>{translate('cash_register_business_code3')}</RNText>
                    <RNText style={styles.operatorCodeHelperItalic}>{translate('cash_register_business_code4')}</RNText>
                    <RNText>{translate('cash_register_business_code5')}</RNText>
                </RNText>)}
                <Text i18nKey={'OperatorCodeSelectLabel'} size="body" style={styles.selectPromptText} />

            </View>
            <TouchableWithoutFeedback>
                {operatorsLoading ? <Loader /> : <FlatList
                    data={data}
                    onStartShouldSetResponder={() => true}
                    decelerationRate={0.8}
                    keyExtractor={keyExtractor}
                    renderItem={renderItem}
                    style={styles.listStyle}
                    contentContainerStyle={styles.listContainerStyle}
                />}
            </TouchableWithoutFeedback>
            <Button
                title={buttonLabel}
                backgroundColor={isButtonDisabled ? colors.disabled : colors.accent}
                borderColor={colors.transparent}
                style={styles.setCodeButton}
                onPress={handleSetOperatorCode}
                disabled={isButtonDisabled}
            />
        </BottomSheetModal>
    )
}
BottomSheetCodeInput.propTypes = {
    isVisible: PropTypes.bool,
    children: PropTypes.any,
    onClose: PropTypes.func,
    onChangeText: PropTypes.func,
    searchKey: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
    props: PropTypes.object,
    editable: PropTypes.bool,
    title: PropTypes.string,
    inputPrompt: PropTypes.string,
    inputLabel: PropTypes.string,
    note: PropTypes.any,
    buttonLabel: PropTypes.string,
    data: PropTypes.any,
    inputValue: PropTypes.string,
    isSelectDisabled: PropTypes.bool,
    select: PropTypes.func,
    selected: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    onSave: PropTypes.func,
    query: PropTypes.any,
    setData: PropTypes.func,
    mapItems: PropTypes.func,

}

export default BottomSheetCodeInput
