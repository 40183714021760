import { styleSheetFactory } from '../../Themes/Colors'

const styles = styleSheetFactory(colors => ({
  container: {
    flexDirection: 'row',
    backgroundColor: colors.tertiaryBackground,
    alignItems: 'center',
    overflow: 'hidden',
    minHeight: 70,
  },
  menuItem: {
    height: 70,
  },
  imageContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: colors.tertiaryBackground,
  },
  image: {
    width: 54,
    height: 54,
    resizeMode: 'contain',
  },
  arrowImageContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    width: 30,
    height: 30,
    paddingHorizontal: 30,
    backgroundColor: colors.white,
  },
  arrowImage: {
    width: 25,
    height: 25,
    resizeMode: 'contain',
  },
  titleContainer: {
    flexGrow: 1,
    flexShrink: 1,
    paddingHorizontal: 15,
  },
  initials: {
    fontSize: 22,
  },
  rightLabelContainer: {
    flexShrink: 0,
    paddingRight: 10,
  },
  rightText: {
    fontSize: 16,
  },
  description: {
    color: colors.secondaryText,
  },
  rowView: {
    flexDirection: 'row',
  },
  rightSpacing: { marginRight: 5 },
  subdescription: { fontSize: 12, lineHeight: 14 },
}))

export default styles
