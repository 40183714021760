import { Dimensions } from 'react-native'
import Color from 'color'

import { styleSheetFactory } from '../../Themes/Colors'

const { width } = Dimensions.get('screen')

const styles = styleSheetFactory(colors => ({
    container: {
        flexGrow: 1,
        flex: 1,
        backgroundColor: colors.background,
    },
    inlineButton1: {
        flex: 1,
        height: 60,
        marginRight: 7.5,
        flexDirection: 'column',

    },
    inlineButton2: {
        flex: 1,
        height: 60,
        marginLeft: 7.5,
        flexDirection: 'column',

    },
    inlineButtons: {
        flexDirection: 'row',
        flex: 1,
        marginBottom: 15,
        alignItems: 'center',
    },
    titleStyle: {
        color: colors.secondaryAccent,
        fontSize: 16,
    },
    invValContainer: {
        marginTop: 20,
        paddingVertical: 10,
        borderTopColor: colors.separator,
        borderTopWidth: 1,
        justifyContent: 'flex-start',
    },
    invValContainer1: {
        marginVertical: 20,
        paddingVertical: 10,
        borderTopColor: colors.separator,
        borderTopWidth: 1,
        justifyContent: 'flex-start',
    },
    qrCode: {
        alignSelf: 'center',
        marginVertical: 25,
        padding: 15,
        backgroundColor: colors.background,
    },
    fullButton: {
        height: 50,
        justifyContent: 'flex-start',
        paddingHorizontal: 0,
        marginBottom: 30,
    },
    invoiceButton: {
        marginHorizontal: 15,
    },
    listDescContainer: {
        flexGrow: 1,
        flexShrink: 1,
        marginLeft: 10,
    },
    header: {
        height: 56,
        alignItems: 'center',
        flexDirection: 'row',
        backgroundColor: colors.background,
    },
    headerIcon: {
        marginHorizontal: 20,
    },
    rightLabelStyle: {
        alignSelf: 'flex-start',
        marginRight: 10,
        paddingVertical: 5,
    },
    modalContent: {
        position: 'relative',
        backgroundColor: colors.white,
        alignSelf: 'center',
        paddingBottom: 30,
        borderRadius: 6,
        minWidth: width - 80,
        maxWidth: 400,
    },
    closeContainer: {
        backgroundColor: colors.disabled,
        position: 'absolute',
        top: 0,
        right: 0,
        borderTopRightRadius: 7,
        borderBottomLeftRadius: 7,
        width: 75,
        justifyContent: 'center',
        alignItems: 'center',
        padding: 10,
    },
    listDescContainer1: {
        flexGrow: 1,
        flexShrink: 1,
        marginHorizontal: 15,
        flexDirection: 'row',
        flexWrap: 'wrap',
    },
    separatorStyle: {
        height: 1,
        flex: 1,
        backgroundColor: colors.lightGrey,
    },
    retryButton: {
        margin: 15,
        marginBottom: 25,
    },
    spacing: { margin: 15 },
    bottomSpacing: { marginBottom: 3 },
    topSpacing: { marginTop: 10 },
    webStyles: { overflow: 'hidden', height: 0 },
    leftComponentStyles: icon => ({
        backgroundColor: Color(icon).fade(0.7).toString(),
        height: '100%',
        width: 70,
        justifyContent: 'center',
        alignItems: 'center',
    }),
    list: {
        flex: 1,
    },
}))

const itemStyles = styleSheetFactory(colors => ({
    itemContainer: {
        backgroundColor: colors.tertiaryBackground,
        borderRadius: 7,
        paddingVertical: 5,
    },
    listDescContainer1: {
        flexGrow: 1,
        flexShrink: 1,
        marginHorizontal: 15,
        flexDirection: 'row',
        flexWrap: 'wrap',
    },
    listDescContainer: {
        flexGrow: 1,
        flexShrink: 1,
        marginHorizontal: 15,
        flexWrap: 'wrap',
    },
    itemSubcontainer: {
        flexDirection: 'row',
        alignItems: 'center',
        height: 60,
    },
    iconStyles: {
        marginLeft: 15,
    },
    statusIcon: {
        marginBottom: 3,
    },
    description: {
        fontSize: 12,
        lineHeight: 15,
    },
    subdescription: {
        fontSize: 12,
        lineHeight: 13,
    },
    amountContainer: {
        alignItems: 'center',
        justifyContent: 'center',
        padding: 15,
        flexDirection: 'row',
    },
    amount: {
        marginRight: 5,
    },
    fiscItemContainer: {
        backgroundColor: colors.tertiaryBackground,
        borderTopColor: colors.separator,
        borderTopWidth: 1,
    },
    itemStyle: {
        flexDirection: 'row',
        alignItems: 'center',
        height: 60,
    },
}))

export { itemStyles }

export default styles
