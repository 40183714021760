import Color from 'color'

import { styleSheetFactory } from '../../Themes/Colors'

const styles = styleSheetFactory(colors => ({
  container: {
    flex: 1,
    position: 'relative',
  },
  listStyle: {
    flex: 1,
  },
  listContainerStyle: {
    flexGrow: 1,
    borderRadius: 10,
    padding: 15,
    paddingBottom: 30,
  },
  fab: {
    position: 'absolute',
    bottom: 30,
    right: 15,
    height: 50,
    width: 50,
    borderRadius: 25,
    backgroundColor: colors.accent,
    justifyContent: 'center',
    alignItems: 'center',
    shadowColor: colors.black,
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.22,
    shadowRadius: 2.22,
    elevation: 3,
  },
  fabIcon: {
    transform: [{ rotate: '-90deg' }],
  },
  chargeButton: {
    height: 50,
    margin: 15,
  },
  countWrapper: {
    alignItems: 'center',
    marginVertical: 15,
  },
}))

const itemStyles = styleSheetFactory(colors => ({
  container: {
    backgroundColor: colors.tertiaryBackground,
    height: 70,
    marginVertical: 3,
    borderRadius: 7,
    overflow: 'hidden',
  },
  touchable: {
    flexGrow: 1,
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
  },
  logo: {
    width: 70,
    height: 70,
    backgroundColor: Color(colors.primaryText)
      .fade(0.65)
      .toString(),
    justifyContent: 'center',
    alignItems: 'center',
  },
  detailsContainer: {
    flexGrow: 1,
    flexShrink: 1,
    paddingHorizontal: 15,
  },
  rightElement: {
    flexShrink: 0,
    marginRight: 15,
  },

}))

export { itemStyles }
export default styles
