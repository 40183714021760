import { styleSheetFactory } from '../../Themes/Colors'

const styles = styleSheetFactory(colors => ({
  container: {
    flexGrow: 1,
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  button: active => ({
    margin: 3,
    backgroundColor: active ? colors.secondaryAccent : colors.white,
    borderRadius: 25,
    justifyContent: 'center',
    alignItems: 'center',
    flexGrow: 1,
    height: 35,
    flexDirection: 'column',
    paddingHorizontal: 15,

  }),
  button_true: {
    backgroundColor: colors.disabled,
  },
  label: {
    // margin: 10,
    lineHeight: 16,
  },
}))

export default styles
