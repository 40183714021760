import { Platform } from 'react-native'

import { styleSheetFactory } from '../../Themes/Colors'

const styles = styleSheetFactory(colors => ({
  container: {
    flex: 1,
    backgroundColor: colors.background,
  },
  sectionText: {
    marginBottom: 15,
  },
  inputRow: {
    flex: 1,
    marginRight: 5,
    height: 60,
  },
  rightConfigInput: {
    marginLeft: 5,
    flex: 1,
    marginBottom: 2,
  },
  inlineButtons: {
    flexDirection: 'row',
  },
  inlineButton: {
    flex: 1,
    marginHorizontal: 0,
  },
  inlineButtonRight: {
    marginLeft: 15,
  },
  button: {
    margin: 10,
    height: 50,
  },
  buttonTitle: {
    fontSize: 18,
  },
  tabView: {
    backgroundColor: colors.background,
    paddingTop: 0,
  },
  horizontalSelfSpacing: { paddingHorizontal: 15 },
  rowView: { flexDirection: 'row', alignItems: 'center' },
  reasonSelector: { minHeight: 60, borderWidth: 0 },
}))

const routeStyles = styleSheetFactory(colors => ({
  itemStyle: {
    backgroundColor: colors.tertiaryBackground,
    minHeight: 70,
    justifyContent: 'center',
    paddingVertical: 7,
  },
  listDescContainer: {
    flexGrow: 1,
    flexShrink: 1,
    marginLeft: 10,
    paddingRight: 15,
    justifyContent: 'center',
    marginRight: 15,
  },
  itemContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    minHeight: 60,
  },
  priceContainer: {
    marginRight: 15,
    alignItems: 'center',
    flexShrink: 0,
    flexDirection: 'row',
  },
  rightTexts: {
    alignItems: 'flex-end',
    marginRight: 5,
  },
  listStyle: {
    marginTop: 15,
    flex: 1,
    paddingHorizontal: 15,
  },
  separator: {
    height: 1,
    backgroundColor: colors.separator,
  },
  listContainerStyle: {
    paddingBottom: 30,
  },
  fab: {
    position: 'absolute',
    bottom: 30,
    right: 15,
    height: 50,
    width: 50,
    borderRadius: 25,
    backgroundColor: colors.accent,
    justifyContent: 'center',
    alignItems: 'center',
    shadowColor: colors.black,
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.22,
    shadowRadius: 2.22,
    elevation: 3,
  },
  fabIcon: {
    transform: [{ rotate: '-90deg' }],
  },
  topSpacing: { marginTop: 10 },
  bottomSpacing: { marginBottom: 10 },
  rowView: { flexDirection: 'row', alignItems: 'center' },
}))

const optionStyles = styleSheetFactory(colors => ({
  container: {
    height: 55,
    backgroundColor: colors.tertiaryBackground,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderRadius: 7,
    position: 'relative',
    marginBottom: 15,
    paddingHorizontal: 15,
  },
  statusContainer: {
    minWidth: 16,
    height: 16,
    padding: 2,
    backgroundColor: colors.accent,
    position: 'absolute',
    top: 8,
    right: 8,
    borderRadius: 7.5,
    justifyContent: 'center',
    alignItems: 'center',
  },
  status: {
    marginTop: Platform.OS === 'android' ? 2 : 1,
    fontSize: 12,
    lineHeight: 12,
  },
}))

export { routeStyles, optionStyles }

export default styles
