import { styleSheetFactory } from '../../Themes/Colors'

const styles = styleSheetFactory(colors => ({
  wraper_horizontal: {
    position: 'relative',
    flexDirection: 'row',
    justifyContent: 'center',
    alignSelf: 'center',
    minWidth: '100%',
    flexGrow: 1,
  },
  wraper_vertical: {
    position: 'relative',
    marginLeft: 16,
    flexDirection: 'column',
    flexGrow: 1,
  },
  container_vertical: {
    flex: 1,
    // maxHeight: 100,
    alignSelf: 'stretch',
    flexDirection: 'row',
  },
  container_horizontal: {
    flex: 1,
    flexDirection: 'column',
  },
  outer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: 26,
    height: 26,
    backgroundColor: 'transparent',
    borderRadius: 20,
    borderWidth: 1,
    margin: 0,
  },
  outer_active: {
    borderColor: colors.transparent,
  },
  outer_current: {
    backgroundColor: colors.white,
    borderColor: colors.accent,
  },
  outer_inactive: {
    borderColor: colors.transparent,
  },
  inner: {
    alignSelf: 'center',
    alignContent: 'center',
    marginHorizontal: 2,
    width: 20,
    height: 20,
    borderRadius: 15,
  },
  inner_active: {
    backgroundColor: colors.accent,
  },
  inner_current: {
    backgroundColor: colors.accent,
  },
  inner_inactive: {
    backgroundColor: colors.disabled,
  },
  text_container_vertical: {
    // justifyContent: 'center',
    marginLeft: 16,
    flexGrow: 1,
  },
  text_container_horizontal: {
    alignItems: 'center',
    marginHorizontal: 4,
  },
  iconsContainer: {
    alignItems: 'center',
    marginBottom: 16,
    overflow: 'visible',
  },
  line_vertical: {
    position: 'absolute',
    height: '100%',
    width: 1,
    top: 20,
    left: '50%',
    zIndex: -1,
  },
  line_horizontal: {
    position: 'absolute',
    height: 1,
    width: '100%',
    top: '50%',
    left: '50%',
    zIndex: -1,
  },
  line_active: {
    backgroundColor: colors.accent,
  },
  line_current: {
    backgroundColor: colors.disabled,
  },
  line_inactive: {
    backgroundColor: colors.disabled,
  },
  title: {
    // color: colors.thinDark,
    margin: 0,
  },
  title_horizontal_active: {
    minWidth: 50,
    textAlign: 'center',
  },
  title_horizontal_inactive: {
    minWidth: 50,
    textAlign: 'center',
  },
  title_horizontal_current: {
    color: colors.accent,
    minWidth: 50,
    textAlign: 'center',
  },
  desc: {
    // color: colors.thinDark,
    marginHorizontal: 0,
  },
  desc_vertical: {
    marginBottom: 5,
  },
  desc_horizontal_current: {
    color: colors.accent,
  },
}))

export default styles
