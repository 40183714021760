import React, { useState, useContext } from 'react'
import PropTypes from 'prop-types'
import { TouchableOpacity, View, LayoutAnimation } from 'react-native'
import { useTheme } from 'react-native-themed-styles'
import { useDispatch, useSelector } from 'react-redux'

import {
    Button,
    Icon,
    Text,
    Section,
    BottomSheetSelector,
    Type,
    TouchableInput,
} from '../../../../../Components'
import { EXPORT_VAT } from '../../../Payment.Config'
import BillingPeriod from '../BillingPeriod'
import ContentManager from '../../../../../Utils/ContentManager'

import DatesModalWithLimit from '../DatesModalWithLimit'

import {
    getPaymentBankAccount,
    getPaymentCustomer,
    getPaymentMethod,
    getPaymentOrderSubtype,
} from '../../../Payment.Selectors'
import { updatePaymentAttribute } from '../../../Payment.Actions'

import Theme, { opacityConfig } from '../../../../../Themes/Theme'
import images from '../../../../../Themes/Images'

import themedStyles from './ExportInvoiceType.Styles'


const ID_TYPES = {
    'NUIS': 'certificate_nipt_label',
    'ID': 'certificate_id_label',
    'PASS': 'customer_passport_number_label',
    'VAT': 'customer_vat_number_label',
    'TAX': 'customer_tax_number_label',
    'SOC': 'customer_soc_number_label',
}

function ExportInvoiceType({
    navigate,
    paymentMethods,
}) {

    const [styles] = useTheme(themedStyles)
    const { colors } = useContext(Theme)
    const { translate } = ContentManager

    const dispatchAction = useDispatch()

    const paymentMethod = useSelector(getPaymentMethod)
    const customer = useSelector(getPaymentCustomer)
    const bankAccount = useSelector(getPaymentBankAccount)
    const orderSubType = useSelector(getPaymentOrderSubtype)

    const setCustomer = (value) => dispatchAction(updatePaymentAttribute('customer', value))
    const setPaymentMethod = (value) => dispatchAction(updatePaymentAttribute('paymentMethod', value))
    const setBankAccount = (value) => dispatchAction(updatePaymentAttribute('bankAccount', value))
    const setOrderSubType = (value) => dispatchAction(updatePaymentAttribute('orderSubType', value))

    const mapCustomer = (customerData) => {
        const response = []
        const customerAddress = customerData?.address
        const NIPT = customerData?.issuerNUIS
        if (customerData?.type === 'BUSINESS') {
            response.push(
                {
                    label: 'einvoice_details_nipt_label',
                    value: NIPT,
                },
                {
                    label: 'einvoice_commercial_name_label',
                    value: customerData?.businessName,
                },
                {
                    label: 'einvoice_details_address_label',
                    value: customerAddress?.addressLine1,
                },
                {
                    label: 'einvoice_details_city_label',
                    value: customerAddress?.administrativeDistrictLevel1,
                },
                {
                    label: 'einvoice_details_state_label',
                    value: customerAddress?.country,
                },
                {
                    label: 'users_email_label',
                    value: customerData?.emailAddress,
                },
                {
                    label: 'users_phone_label',
                    value: customerData?.phoneNumber,
                }

            )
        } else if (customerData?.reference?.name) {
            response.push(
                {
                    label: `${ID_TYPES[customerData?.reference?.name]}`,
                    value: customerData?.reference?.value,
                },
                {
                    label: 'users_first_name_label',
                    value: `${customerData?.givenName} ${customerData?.familyName}`,
                },
                {
                    label: 'einvoice_details_address_label',
                    value: customerAddress?.addressLine1,
                },
                {
                    label: 'einvoice_details_city_label',
                    value: customerAddress?.administrativeDistrictLevel1,
                },
                {
                    label: 'einvoice_details_state_label',
                    value: customerAddress?.country,
                },
                {
                    label: 'users_email_label',
                    value: customerData?.emailAddress,
                },
                {
                    label: 'users_phone_label',
                    value: customerData?.phoneNumber,
                }

            )
        }
        else {
            response.push(
                {
                    label: 'users_first_name_label',
                    value: `${customerData?.givenName} ${customerData?.familyName}`,
                },
                {
                    label: 'einvoice_details_address_label',
                    value: customerAddress?.addressLine1,
                },
                {
                    label: 'einvoice_details_city_label',
                    value: customerAddress?.administrativeDistrictLevel1,
                },
                {
                    label: 'einvoice_details_state_label',
                    value: customerAddress?.country,
                },
                {
                    label: 'users_email_label',
                    value: customerData?.emailAddress,
                },
                {
                    label: 'users_phone_label',
                    value: customerData?.phoneNumber,
                }

            )
        }
        return response
    }

    const [customerItems, setCustomerItems] = useState(mapCustomer(customer) || [])

    const setCustomerData = (customerData) => {
        setCustomer(customerData)
        setCustomerItems(mapCustomer(customerData))
    }

    const [searchValue, setSearchValue] = useState('')
    const [modal, setModal] = useState({
        isVisible: false,
        data: [],
        title: '',
        placeholder: '',
        selected: undefined,
        select: () => { },
        Item: Type,
    })

    const closeModal = () => {
        setModal(prev => ({ ...prev, isVisible: false }))
        setSearchValue('')
    }

    const openPaymentMethodSelector = () => {
        setModal({
            isVisible: true,
            data: paymentMethods,
            title: 'payment_total_method',
            placeholder: 'payment_total_method',
            selected: paymentMethod,
            select: setPaymentMethod,
            searchKey: 'code',
            Item: Type,
        })
    }

    const openVatSelector = () => {
        setModal({
            isVisible: true,
            data: EXPORT_VAT,
            title: 'item_vat_label',
            placeholder: 'payment_total_method',
            selected: orderSubType,
            select: setOrderSubType,
            searchKey: 'code',
            Item: Type,
        })
    }

    const setBankAccData = (accountData) => {
        setBankAccount(accountData)
    }

    return (
        <>

            <View style={styles.content}>
                <View style={styles.topContent}>
                    <Text i18nKey="payment_total_method" weight="bold" style={styles.sectionTitle} />
                    <TouchableInput
                        onPress={openPaymentMethodSelector}
                        label="einvoice_payment_button"
                        value={paymentMethod.code ? `${paymentMethod.code} - ${translate(paymentMethod.description)}` : ''}
                        icon={images.filledDownArrow}
                        iconSize={16}
                        autoCorrect={false}
                        autoCapitalize="none"
                        editable={false}
                    />
                </View>
                {bankAccount.id ? <>
                    <View style={styles.bankHeading}>
                        <Text i18nKey="einvoice_payment_bank_account_label" />
                        <TouchableOpacity style={styles.removeBuyer}
                            onPress={() => {
                                LayoutAnimation.configureNext(opacityConfig)
                                setBankAccount({})
                            }}>
                            <Icon source={images.closeIcon} color={colors.secondaryIcon} size={16} />
                        </TouchableOpacity>
                    </View>
                    <View style={styles.bankDetailsWrapper}>
                        <Text i18nKey={`${bankAccount.iban} (${bankAccount.currency})`} />
                        <Text i18nKey={bankAccount.bankName} weight="light" />
                    </View>
                </> : paymentMethod.code ? <Button title={'bank_accounts_add_button_label'}
                    variant="secondary"
                    style={styles.bankAccount}
                    onPress={() => navigate('BankAccounts', {
                        selectable: true,
                        updateSelection: setBankAccData,
                    })} /> : null}
                <View style={styles.topContent}>
                    <Text i18nKey="payment_select_vat_label" weight="bold" style={styles.sectionTitle} />
                    <TouchableInput
                        onPress={() => openVatSelector()}
                        label="item_vat_label"
                        value={orderSubType?.code ? `${orderSubType.code} - ${translate(orderSubType.description)}` : ''}
                        icon={images.filledDownArrow}
                        iconSize={16}
                        autoCorrect={false}
                        autoCapitalize="none"
                        editable={false}
                    />
                </View>
                <BillingPeriod style={styles.horizontalSpacing} />
                <>
                    <View style={styles.customerHeader}>
                        <Text i18nKey="customer_checkout_label" weight="bold" />
                        {!customer?.id ? null :
                            <TouchableOpacity style={styles.removeCustomer} onPress={() => {
                                LayoutAnimation.configureNext(opacityConfig)
                                setCustomer({})
                                setCustomerItems([])
                            }}>
                                <Icon source={images.closeIcon} color={colors.secondaryIcon} size={18} />
                            </TouchableOpacity>}
                    </View>
                </>
                {(!customer?.id) ? <Button
                    title="einvoice_add_buyer_button"
                    icon={images.addCustomer}
                    iconColor={colors.accent}
                    iconStyle={styles.rightSpacing}
                    variant="preActive"
                    style={styles.addCustomer}
                    onPress={() => navigate('AllCustomers', { selectable: true, preselectedCustomer: customer, updateSelection: setCustomerData })}
                /> : <Section data={customerItems} />}
            </View>
            <DatesModalWithLimit />
            <BottomSheetSelector
                {...modal}
                closeModal={closeModal}
                searchValue={searchValue}
                setSearchValue={setSearchValue}
            />
        </>
    )
}


ExportInvoiceType.propTypes = {
    navigate: PropTypes.func,
    paymentMethods: PropTypes.array,
}

export default ExportInvoiceType

