import React from 'react'
import { View, TouchableOpacity } from 'react-native'
import PropTypes from 'prop-types'
import { useTheme } from 'react-native-themed-styles'

import { Text, Icon } from '../../../../../Components'

import themedStyles from './PaymentMethod.Styles'

function PaymentMethod({
  icon,
  title,
  isSelected,
  isDisabled,
  select,
}) {
  const [styles] = useTheme(themedStyles)

  return (
    <TouchableOpacity onPress={select} disabled={isDisabled} style={[styles.itemContainer, styles['itemContainerSelected_' + isSelected], styles['itemContainerDisabled_' + isDisabled]]}>
      <View style={styles.touchable}>
        <Icon source={icon} size={22} style={styles['iconSelected_' + isSelected]} />
        <Text i18nKey={title} size={'footnote'} align="center" style={styles['titleSelected_' + isSelected]} />
      </View>
    </TouchableOpacity>
  )
}
PaymentMethod.defaultProps = {
  isDisabled: false,
}
PaymentMethod.propTypes = {
  icon: PropTypes.any,
  title: PropTypes.string,
  isSelected: PropTypes.bool,
  isDisabled: PropTypes.bool,
  select: PropTypes.func,
}

export default PaymentMethod
