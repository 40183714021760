import React, { createContext, useState, useEffect, useContext } from 'react'
import { Auth } from 'aws-amplify'
import AsyncStorage from '@react-native-async-storage/async-storage'
import PropTypes from 'prop-types'
import { Platform } from 'react-native'

const UserDetails = createContext()
const LocationDetails = createContext()
const LatestUsernames = createContext({})

function AuthDetailsProvider({ children }) {

  const [loading, setLoading] = useState(true)
  const [user, setUser] = useState({})
  const [location, setLocation] = useState({})

  const getAuthDetails = async () => {
    const {
      sub: adminId,
      'cognito:username': id,
      'custom:last_name': lastName,
      email,
      name,
      phone_number: phoneNumber,
      'phone_number_verified': phoneNumberVerified,
      'cognito:groups': authRole,
      'custom:color': color,
      'preferred_username': locationId,
      NIPT,
      bussinesName,
      'custom:defaultLocationName': defaultLocationName,
      addressLine,
      'given_name': merchantId,
      'custom:cashRegisterId': deviceId,
      EUR: euRate,
      USD: usdRate,
      GBP: poundRate,
      androidAppVersion,
      iosAppVersion,
      onBoard,
      'custom:termsCondition': termsCondition,
      'custom:privacyPolicy': privacyPolicy,
      'custom:operatorCode': operatorCode,
    } = (await Auth.currentSession()).getIdToken().decodePayload()
    const firstName = name?.split('#')?.[1]
    const fullName = firstName + ' ' + lastName
    const role = authRole?.[0]
    const storeVersion = parseInt(Platform.OS === 'ios' ? iosAppVersion : androidAppVersion, 10)
    const onBoardStep = parseInt(onBoard, 10)

    setUser({
      adminId,
      id,
      firstName,
      lastName,
      fullName,
      email,
      phoneNumber,
      phoneNumberVerified,
      role,
      color,
      deviceId,
      storeVersion,
      onBoard: onBoardStep,
      termsCondition,
      privacyPolicy,
      operatorCode,
    })
    setLocation({
      id: locationId,
      NIPT,
      name: bussinesName,
      address: addressLine,
      defaultLocationName,
      merchantId,
      euRate,
      usdRate,
      poundRate,
    })
    setLoading(false)
  }

  const refreshToken = async () => {
    const authenticatedUser = await Auth.currentAuthenticatedUser()
    const currentSession = await Auth.currentSession()

    authenticatedUser.refreshSession(currentSession.getRefreshToken(), (err, session) => {
      if (err) { } else {
        const {
          sub: adminId,
          'cognito:username': id,
          'custom:last_name': lastName,
          email,
          name,
          phone_number: phoneNumber,
          'phone_number_verified': phoneNumberVerified,
          'cognito:groups': authRole,
          'custom:color': color,
          'preferred_username': locationId,
          NIPT,
          bussinesName,
          'custom:defaultLocationName': defaultLocationName,
          addressLine,
          'given_name': merchantId,
          'custom:cashRegisterId': deviceId,
          EUR: euRate,
          USD: usdRate,
          GBP: poundRate,
          androidAppVersion,
          iosAppVersion,
          onBoard,
          'custom:termsCondition': termsCondition,
          'custom:privacyPolicy': privacyPolicy,
          'custom:operatorCode': operatorCode,
        } = session?.idToken?.payload

        const firstName = name?.split('#')?.[1]
        const fullName = firstName + ' ' + lastName
        const role = authRole?.[0]
        const storeVersion = parseInt(Platform.OS === 'ios' ? iosAppVersion : androidAppVersion, 10)
        const onBoardStep = parseInt(onBoard, 10)

        setUser({
          adminId,
          id,
          firstName,
          lastName,
          fullName,
          email,
          phoneNumber,
          phoneNumberVerified,
          role,
          color,
          deviceId,
          storeVersion,
          onBoard: onBoardStep,
          termsCondition,
          privacyPolicy,
          operatorCode,
        })
        setLocation({
          id: locationId,
          NIPT,
          name: bussinesName,
          address: addressLine,
          defaultLocationName,
          merchantId,
          euRate,
          usdRate,
          poundRate,
        })
        setLoading(false)
      }
    })
  }

  useEffect(() => {
    getAuthDetails()
  }, [])

  return (
    <UserDetails.Provider value={{ ...user, refreshToken }}>
      <LocationDetails.Provider value={{ ...location, loading }}>
        {children}
      </LocationDetails.Provider>
    </UserDetails.Provider>
  )
}
AuthDetailsProvider.propTypes = {
  children: PropTypes.any,
}


function UsernamesProvider({ children }) {

  const [latestUsernames, setLatestUsernames] = useState([])

  const save = usernames => {
    AsyncStorage.setItem('LATEST_USERNAMES', JSON.stringify(usernames)).catch(() => { })
    return usernames
  }

  const deleteUsername = username => {
    const filter = element => element !== username
    setLatestUsernames(prev => {
      const usernames = prev?.filter(filter)
      return save(usernames)
    })
  }

  const addUsername = username => {
    const find = element => element === username
    setLatestUsernames(prev => {
      if (!prev.find(find)) {
        prev.unshift(username)
        const usernames = prev.slice(0, 3)
        return save(usernames)
      } else {
        return prev
      }
    })
  }

  useEffect(() => {
    AsyncStorage.getItem('LATEST_USERNAMES')
      .then(res => {
        if (res) {
          setLatestUsernames(JSON.parse(res))
        }
      })
      .catch(() => { })
  }, [])

  return (
    <LatestUsernames.Provider value={{
      latestUsernames,
      setLatestUsernames,
      addUsername,
      deleteUsername,
    }}>
      {children}
    </LatestUsernames.Provider>
  )
}
UsernamesProvider.propTypes = {
  children: PropTypes.any,
}

function useUserDetails() {
  const context = useContext(UserDetails)
  if (context === undefined) {
    throw new Error('useUserDetails must be used within a AuthDetailsProvider')
  }
  return context
}

function useLocationDetails() {
  const context = useContext(LocationDetails)
  if (context === undefined) {
    throw new Error('useLocationDetails must be used within a AuthDetailsProvider')
  }
  return context
}

function useUserNames() {
  const context = useContext(LatestUsernames)
  if (context === undefined) {
    throw new Error('useUserNames must be used within a UsernamesProvider')
  }
  return context
}

export { useUserDetails, useLocationDetails, useUserNames, LatestUsernames, UsernamesProvider }
export default AuthDetailsProvider
