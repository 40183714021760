/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, memo, useRef } from 'react'
import {
    SafeAreaView,
    View,
    Image,
    TouchableOpacity,
    Animated,
} from 'react-native'
import PropTypes from 'prop-types'
import { useMutation, useLazyQuery } from '@apollo/client'
import { useTheme } from 'react-native-themed-styles'

import {
    InputWithTags,
    Loader,
    Text,
    Button,
    Header,
    Modal,
    Icon,
    ModalContent,
    ButtonGroup,
    SwipeList,
    CheckBox,
} from '../../Components'

import { SelectColor } from '../../Components/ImageColorPicker/ImageColorPicker'

import { getErrorMessage } from '../../Utils'

import {
    CREATE_GROUP,
    EDIT_GROUP,
    GET_GROUP,
    CREATE_SUPPLIER_GROUP,
    EDIT_SUPPLIER_GROUP,
    GET_SUPPLIER_GROUP,
    EDIT_CUSTOMER,
    EDIT_SUPPLIER,
} from './NewGroup.Schema'

import themedStyles, { itemStyles, hiddenItemStyles } from './NewGroup.Styles'
import colors from '../../Themes/Colors'

import images, { animations } from '../../Themes/Images'

const Item = memo(({
    givenName,
    familyName,
    businessName,
    phoneNumber,
    onPress,
    isFirst,
    isLast,
}) => {
    const [styles] = useTheme(itemStyles)
    const anim = useRef(new Animated.Value(1)).current
    const animate = value => {
        Animated.spring(anim, {
            toValue: value,
            useNativeDriver: true,
        }).start()
    }

    const name = businessName ? businessName : `${givenName} ${familyName}`

    return (
        <Animated.View
            style={[
                styles.container,
                styles['firstItem_' + isFirst],
                styles['lastItem_' + isLast],
                { transform: [{ scale: anim }] },
            ]}>
            <TouchableOpacity
                style={styles.touchable}
                onPress={onPress}
                onPressIn={() => animate(1.05)}
                onPressOut={() => animate(1)}
                activeOpacity={0.7}
                disabled={!onPress}>
                <View style={styles.imageContainer('#00B0CA')}>
                    <Text
                        size={'h5'}
                        color={'#00B0CA'}
                        i18nKey={name
                            ?.match(/\b(\w{1})/g)
                            ?.slice(0, 2)
                            ?.join('')
                            ?.toUpperCase()}
                    />
                </View>
                <View style={styles.infoContainer}>
                    <View style={styles.descContainer}>
                        <Text i18nKey={name} numberOfLines={1} />
                        <Text i18nKey={phoneNumber} numberOfLines={1} />
                    </View>
                    <View style={styles.priceContainer} >
                        <Icon source={images.chevronRight} size={24} color={colors.accent} />
                    </View>
                </View>

            </TouchableOpacity>
        </Animated.View>
    )
})
Item.defaultProps = {
    labelColor: 'white',
}
Item.propTypes = {
    givenName: PropTypes.string,
    familyName: PropTypes.string,
    businessName: PropTypes.string,
    phoneNumber: PropTypes.string,
    isFirst: PropTypes.bool,
    isLast: PropTypes.bool,
    onPress: PropTypes.func,
}

function HiddenItem({
    needsConfirmation,
    onPress,
    onConfirm,
    onCancel,
    isFirst,
    isLast,
    loading,
}) {
    const [styles] = useTheme(hiddenItemStyles)

    return (
        <View
            style={[styles.wrapper, styles['firstItem_' + isFirst], styles['lastItem_' + isLast]]}>
            {loading
                ? <View style={styles.loader}>
                    <Loader size={32} color={colors.white} source={animations.vfLoaderWhite} />
                </View>
                : needsConfirmation ? (
                    <TouchableOpacity style={styles.option} onPress={onPress}>
                        <Icon source={images.delete} color={colors.white} size={24} />
                    </TouchableOpacity>
                ) : (
                    <View style={styles.optionFull}>
                        <TouchableOpacity style={styles.confirm} onPress={onConfirm}>
                            <Icon source={images.delete} color={colors.white} size={24} />
                            <Text
                                i18nKey="opened_orders_delete_confirm"
                                color={colors.white}
                                style={styles.confirmText}
                            />
                        </TouchableOpacity>
                        <TouchableOpacity style={styles.cancel} onPress={onCancel}>
                            <Icon source={images.closeIcon} color={colors.white} size={20} />
                        </TouchableOpacity>
                    </View>
                )}
        </View>
    )
}
HiddenItem.propTypes = {
    needsConfirmation: PropTypes.bool,
    onPress: PropTypes.func,
    onConfirm: PropTypes.func,
    onCancel: PropTypes.func,
    loading: PropTypes.bool,
    isFirst: PropTypes.bool,
    isLast: PropTypes.bool,
}

function CheckItem({ label, onPress, checked }) {
    const [styles] = useTheme(themedStyles)

    return (
        <View style={styles.rightContainer}>
            <CheckBox
                checked={checked}
                tintColors={{
                    true: colors.secondaryAccent,
                    false: colors.placeholder,
                }}
                onValueChange={onPress}
            />
            <Text i18nKey={label} style={styles.leftSpacing} />
        </View>
    )
}
CheckItem.propTypes = {
    label: PropTypes.string,
    onPress: PropTypes.func,
    checked: PropTypes.bool,
}

const GROUP_TYPE = {
    true: 'BUSINESS',
    false: 'INDIVIDUAL',
}

const QUERY_TYPES = {
    CUSTOMER: {
        create: CREATE_GROUP,
        update: EDIT_GROUP,
        get: GET_GROUP,
        edit: EDIT_CUSTOMER,
        deleteGroups: 'customerGroupsToDelete',
        getGroups: 'getCustomerGroup',
        listUsers: 'listCustomers',
        users: 'customers',
        token: 'nextToken',
        createGroup: 'createCustomerGroup',
        updateGroup: 'updateCustomerGroup',
    },
    SUPPLIER: {
        create: CREATE_SUPPLIER_GROUP,
        update: EDIT_SUPPLIER_GROUP,
        get: GET_SUPPLIER_GROUP,
        edit: EDIT_SUPPLIER,
        deleteGroups: 'supplierGroupsToDelete',
        getGroups: 'getSupplierGroup',
        listUsers: 'listSuppliers',
        users: 'suppliers',
        token: 'nextToken',
        createGroup: 'createSupplierGroup',
        updateGroup: 'updateSupplierGroup',

    },
}

function NewGroup({
    route: {
        params: {
            groupItem,
            type,
            onGroupSave = () => { },
        } = {},
    } = {},
    navigation: {
        goBack,
        navigate,
    },
}) {
    const [styles] = useTheme(themedStyles)

    const [itemWidth, setItemWidth] = useState(0)
    const [needsConfirmation, setNeedForConfirmation] = useState(true)

    const [isBusiness, setIsBusiness] = useState(groupItem?.type === 'BUSINESS' ? true : false)
    const [selected, setSelected] = useState(type || 'CUSTOMER')
    const [group, setGroup] = useState({ ...groupItem, type: GROUP_TYPE[isBusiness] })
    const [isSelectColorVisible, setSelectColorVisibility] = useState(false)

    const [createNewGroup, { loading: creating }] = useMutation(QUERY_TYPES[selected].create)
    const [updateGroup, { loading: updating }] = useMutation(QUERY_TYPES[selected].update)
    const [editGroup, { loading: editing }] = useMutation(QUERY_TYPES[selected].edit)
    const [getGroup, { data, loading, refetch, fetchMore }] = useLazyQuery(QUERY_TYPES[selected].get, {
        fetchPolicy: 'network-only',
    })

    const [limit] = useState(25)

    const [customers, setCustomers] = useState([])

    const [types, setTypes] = useState([
        {
            title: 'customers_label',
            type: 'CUSTOMER',
            disabled: groupItem?.id ? true : false,
        },
        {
            title: 'suppliers_label',
            type: 'SUPPLIER',
            disabled: groupItem?.id ? true : false,
        },
    ])

    useEffect(() => {
        const id = group?.id
        if (id) {
            getGroup({ variables: { id, limit: limit } })
        }
    }, [])

    const fetchFieldForQuery = (obj) => {
        return QUERY_TYPES?.[selected]?.[obj]
    }

    useEffect(() => {
        if (data) {
            const getGroups = fetchFieldForQuery('getGroups')
            const listGroups = fetchFieldForQuery('listUsers')
            const lastField = fetchFieldForQuery('users')
            const customersData = data?.[getGroups]?.[listGroups]?.[lastField]
            if (customersData) {
                setCustomers(customersData)
                setTypes(prev => prev.map((item) => {
                    if (item.type !== GROUP_TYPE[isBusiness]) {
                        item.disabled = true
                    } else {
                        item.disabled = false
                    }
                    return item
                }))
            }
        }
    }, [data])

    const onEndReached = () => {
        const getGroups = fetchFieldForQuery('getGroups')
        const listGroups = fetchFieldForQuery('listUsers')
        const query = fetchFieldForQuery('get')

        if (!data?.[getGroups]?.[listGroups]?.nextToken) {
            return
        }

        fetchMore({
            query,
            variables: {
                limit: limit,
                nextToken: data[getGroups][listGroups].nextToken,
                id: group?.id,
            },
        })
    }

    const [modal, setModal] = useState({
        isVisible: false,
        icon: images.warningIcon,
        title: '',
        desc: '',
    })
    const openModal = (
        desc = '',
        title = 'unit_general_error',
        icon = images.warningIcon
    ) => {
        setModal({
            isVisible: true,
            icon: icon,
            title: title,
            desc: desc,
        })
    }
    const closeModal = () => {
        setModal({
            isVisible: false,
            icon: images.delete,
            title: '',
            desc: '',
        })
    }

    const submitForm = () => {
        const createGroup = fetchFieldForQuery('createGroup')
        const editGroupDetails = fetchFieldForQuery('updateGroup')
        if (!group.name) {
            return
        }
        if (!groupItem?.id) {
            createNewGroup({
                variables: group,
            }).then((res) => {
                const { [createGroup]: createdGroup } = res.data
                onGroupSave({ ...createdGroup })
                goBack()
            }).catch(e => {
                openModal(getErrorMessage(e))
                return
            })
        } else {
            updateGroup({
                variables: group,
            }).then((res) => {
                const { [editGroupDetails]: createdGroup } = res.data
                onGroupSave({ ...createdGroup })
                goBack()
            }).catch(e => {
                openModal(getErrorMessage(e))
                return
            })
        }
    }

    const onColorSelect = (color) => {
        setSelectColorVisibility(false)
        setGroup({
            ...group,
            labelColor: color,
        })
    }

    const openRow = (rowMap, id) => {
        setNeedForConfirmation(false)
        rowMap[id].manuallySwipeRow(-itemWidth)
    }

    const closeRow = (rowMap, id) => {
        rowMap[id].closeRow()
        setNeedForConfirmation(true)
    }

    const deleteRow = (rowMap, id) => {
        const groupToDelete = fetchFieldForQuery('deleteGroups')
        editGroup({
            variables: {
                id: id,
                [groupToDelete]: [group?.id],
            },
        })
            .then(() => {
                refetch()
                closeRow(rowMap, id)
            })
            .catch(e => {
                openModal(getErrorMessage(e))
                return
            })
    }

    const renderItem = ({ item, index }) => (
        <Item
            {...item}
            onPress={() => navigate('CustomerDetails', { item, type: GROUP_TYPE[isBusiness], isSupplier: type === 'SUPPLIER', onSave: () => refetch() })}
            isFirst={index === 0}
            isLast={index === customers.length - 1}
        />
    )

    const renderHiddenItem = ({ item, index }, rowMap) => (
        <HiddenItem
            needsConfirmation={needsConfirmation}
            onPress={() => openRow(rowMap, item.id)}
            onConfirm={() => deleteRow(rowMap, item.id)}
            onCancel={() => closeRow(rowMap, item.id)}
            loading={editing}
            isFirst={index === 0}
            isLast={index === customers.length - 1}
        />
    )

    return (
        <>
            <SafeAreaView style={styles.container}>
                <Header
                    title={groupItem ? group?.name : 'header_new_group'}
                    image={images.closeIcon}
                    onPress={goBack}
                />
                <SwipeList
                    data={customers}
                    renderItem={renderItem}
                    renderHiddenItem={renderHiddenItem}
                    rightOpenValue={-100}
                    onRowClose={() => setNeedForConfirmation(true)}
                    disableRightSwipe
                    setItemWidth={setItemWidth}
                    onEndReached={() => onEndReached()}
                    ItemSeparatorComponent={() => <View style={styles.separator} />}
                    ListFooterComponent={loading && <Loader style={styles.footer} size={36} source={animations.vfLoaderRed} />}
                    ListHeaderComponent={<>
                        <TouchableOpacity
                            style={[styles.imagesColorContainer, group.labelColor ? { backgroundColor: colors.white } : {}]}
                            onPress={() => setSelectColorVisibility(true)}
                        >
                            <View style={styles.color}>
                                {!group.labelColor && <Image source={images.palette} style={styles.colorIcon} />}
                                {group.labelColor && <Text size="h2" color={group.labelColor} i18nKey={group?.name?.match(/\b(\w{1})/g)?.slice(0, 2)?.join('')?.toUpperCase() || ''} />}
                            </View>
                        </TouchableOpacity>

                        <View style={styles.inputContainer}>
                            <Text align="left" i18nKey="customer_group_for_label" />
                            <ButtonGroup
                                selected={selected}
                                onPress={setSelected}
                                options={types}
                                style={styles.typesGroup}
                            />
                            <View style={styles.checkItemWrapper}>
                                <CheckItem
                                    label="customer_business_group_label"
                                    checked={isBusiness}
                                    onPress={(val) => {
                                        setIsBusiness(prev => !prev)
                                        setGroup({
                                            ...group,
                                            type: GROUP_TYPE[val],
                                        })
                                    }}
                                />
                            </View>
                            <InputWithTags
                                label="customer_group_name_label"
                                value={group.name}
                                onChangeText={text => setGroup({ ...group, name: text })}
                                autoCorrect={false}
                                inputContainerStyle={styles.inputContainer1}
                                inputWrapperStyle={styles.itemSpacing}
                                labelOffset={{
                                    x0: 0,
                                    y0: -7,
                                    x1: 0,
                                    y1: 2,
                                }}
                            />
                            <Text
                                align="left"
                                i18nKey={customers.length ? 'customers_label' : 'customer_new_group_description'}
                                // eslint-disable-next-line react-native/no-inline-styles
                                style={{
                                    fontSize: customers.length ? 16 : 12,
                                    marginBottom: 5,
                                    marginTop: customers.length ? 15 : 0,
                                }}
                            />
                        </View>
                    </>}
                />
                <Button
                    title={'discount_button_save'}
                    style={styles.primaryButton}
                    onPress={submitForm}
                    variant={(group.name && !creating && !updating) ? 'active' : 'disabled'}
                    loader={creating || updating}
                    loaderComponent={<Loader size={32} source={animations.vfLoaderThinDark} />}
                />
                <SelectColor
                    isVisible={isSelectColorVisible}
                    close={() => setSelectColorVisibility(false)}
                    selectColor={onColorSelect}
                />
            </SafeAreaView >
            <Modal
                isVisible={modal.isVisible}
                onBackButtonPress={() => closeModal()}
                onBackdropPress={() => closeModal()}
                animationIn="fadeIn"
                animationOut="fadeOut"
            >
                <ModalContent onClose={() => closeModal()} contentContainerStyle={styles.modalContent}>
                    <Icon source={modal.icon} style={styles.modalIcon} size={40} />
                    <Text
                        i18nKey={modal.title}
                        color={colors.primaryText}
                        size="h5"
                        align="center"
                        weight="bold"
                        style={styles.modalTitle}
                    />
                    <Text i18nKey={modal.desc} align="center" />
                </ModalContent>
            </Modal>
        </>
    )
}

NewGroup.propTypes = {
    route: PropTypes.object,
    navigation: PropTypes.object,
}

export default NewGroup
