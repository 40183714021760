/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useState,
  useRef,
  useContext,
  useEffect,
  useMemo,
  useLayoutEffect,
  useCallback,
} from 'react'
import {
  View,
  SafeAreaView,
  Dimensions,
  TouchableOpacity,
  KeyboardAvoidingView,
  Platform,
  LayoutAnimation,
  useWindowDimensions,
  Vibration,
  Linking,
} from 'react-native'
import PropTypes from 'prop-types'
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs'
import { v1 as uuidv1 } from 'uuid'
import { useLazyQuery } from '@apollo/client'
import { useDispatch, useSelector } from 'react-redux'
import AsyncStorage from '@react-native-async-storage/async-storage'
import { useTheme } from 'react-native-themed-styles'
import ShadowView from 'react-native-androw'
import Sound from 'react-native-sound'
import { useFocusEffect } from '@react-navigation/native'
import memoize from 'memoize-one'
import DeviceInfo from 'react-native-device-info'

import moment from 'moment'

import {
  Options,
  Icon,
  Text,
  TextInput,
  Button,
  Modal,
  QRScannerOrder,
  Header,
  ModalContent,
  OptionsSwitch,
  BottomSheetSelector,
  Type,
  AnimateToBasket,
  Option,
} from '../../Components'

import {
  getErrorType,
  useConfig,
  ITEM_TYPES,
  INF,
  useLocationDetails,
} from '../../Utils'

import { resetLocation } from '../ProductCatalog/ProductCatalog.Actions'

import { resetState, resetItems, resetCalculatedUBL, supportInvoiceResetState } from '../Payment/Payment.Actions'
import calculatePrice, { useOrderCount } from '../../Utils/Order'
import useTaxDetails from '../../Utils/Tax'

import { useUserDetails } from '../../Utils/AuthDetails'
import { versionCode } from '../../../package.json'

import {
  HeaderStatusIcon,
  RenderList,
  CurrencyOption,
} from './Components'

import {
  changeCurrency,
  updateOrder,
  resetOrder,
  addItemToOrder,
} from './Checkout.Actions'
import {
  getCurrency,
  getOrder,
} from './Checkout.Selectors'

import Theme, { layoutAnimConfig, opacityConfig } from '../../Themes/Theme'
import images from '../../Themes/Images'
import themedStyles, {
  searchlistStyles,
} from './Checkout.Styles'
import {
  LIST_ITEMS,
  LIST_CATEGORIES,
  LIST_CATEGORY_ITEMS,
  GET_ITEM,
} from './Checkout.schema'

const Tab = createMaterialTopTabNavigator()
const tabOptions = {
  scroll_true: {
    tabBarScrollEnabled: true,
    tabBarItemStyle: Platform.OS === 'web'
      ? {
        width: 150,
      } : {
        width: 'auto',
        minWidth: 100,
      },

  },
  scroll_false: {
    tabBarScrollEnabled: false,
  },
}
const ITEM_SIZES = {
  card: 400,
  grid: 200,
  list: Infinity,
}
const DEFAULT_MODAL_PROPS = {
  icon: images.warningIcon,
  title: '',
  desc: '',
  primaryText: '',
  primaryAction: undefined,
  secondaryText: '',
  secondaryAction: undefined,
}
const calculateNumCols = (type, windowWidth) => {
  const width = windowWidth >= 800 ? windowWidth - 280 : windowWidth
  const minItems = Math.floor(width / ITEM_SIZES[type])
  if (minItems === 0) {
    return 1
  } else if (type === ITEM_TYPES.grid) {
    return Math.max(2, minItems)
  }
  return minItems
}
const getNumCols = memoize(calculateNumCols)

const variableItem = {
  id: uuidv1(),
  name: 'checkout_variable_item_title',
  description: 'checkout_variable_item_description',
  labelColor: 'white',
  measurementUnitId: null,
  measurementUnit: null,
  itemVariationsCount: 0,
  modifierListsCount: 0,
  icon: images.calculator,
}
function Category({
  id,
  numCols,
  addToBasket,
  configureProduct,
  addProductPrice,
}) {
  const { switchLocation: { deviceId, locationId } } = useConfig()

  const { width, height } = useWindowDimensions()

  const [items, setItems] = useState([variableItem])
  const [loading, setLoading] = useState(false)
  const cursor = useRef()

  const [listCategoryItems, { data }] = useLazyQuery(LIST_CATEGORY_ITEMS)
  const refetch = () => {
    setLoading(true)
    setItems([variableItem])
    cursor.current = null
    listCategoryItems({ variables: { id, limit: 20, locId: locationId, cashRegisterId: deviceId } })
  }
  useEffect(() => {
    refetch()
  }, [width, height])
  useEffect(() => {
    const { listItems } = data?.getCategory || {}
    if (listItems) {
      setItems((prev) => prev.concat(listItems.items || []))
      cursor.current = listItems.nextToken
      setLoading(false)
    }
  }, [data])
  const onEndReached = () => {
    if (cursor.current) {
      setLoading(true)
      listCategoryItems({
        variables: {
          locId: locationId,
          cashRegisterId: deviceId,
          id,
          limit: 20,
          nextToken: cursor.current,
        },
      })
    }
  }

  return (
    <RenderList
      numCols={numCols}
      addToBasket={addToBasket}
      configureProduct={configureProduct}
      addProductPrice={addProductPrice}
      loading={loading}
      items={items}
      refetch={refetch}
      fetchMore={onEndReached}
    />
  )
}
Category.propTypes = {
  id: PropTypes.string,
  numCols: PropTypes.number,
  items: PropTypes.array,
  addToBasket: PropTypes.func,
  configureProduct: PropTypes.func,
  addProductPrice: PropTypes.func,
}

function SearchList({
  numCols,
  addToBasket,
  configureProduct,
  addProductPrice,
  name,
}) {

  const { switchLocation: { deviceId, locationId } } = useConfig()

  const [styles] = useTheme(searchlistStyles)
  const { width, height } = useWindowDimensions()

  const [items, setItems] = useState([variableItem])
  const [loading, setLoading] = useState(false)
  const cursor = useRef()

  const [searchItems, { data, fetchMore }] = useLazyQuery(LIST_ITEMS, { fetchPolicy: 'network-only' })
  const refetch = () => {
    setLoading(true)
    setItems([variableItem])
    cursor.current = null
    searchItems({
      variables: {
        locId: locationId,
        cashRegisterId: deviceId,
        filter: {
          name: 'name',
          value: name,
        },
        limit: 20,
      },
    })
  }
  useEffect(() => {
    refetch()
  }, [width, height, name])

  useEffect(() => {
    const { listItems } = data || {}
    if (listItems) {
      setItems([variableItem].concat(listItems.items || []))
      cursor.current = listItems.nextToken
      setLoading(false)
    }
  }, [data])
  const onEndReached = () => {
    if (cursor.current) {
      setLoading(true)
      fetchMore({
        variables: {
          locId: locationId,
          cashRegisterId: deviceId,
          filter: {
            name: 'name',
            value: name,
          },
          limit: 20,
          nextToken: cursor.current,
        },
      })
    }
  }

  return (
    <RenderList
      numCols={numCols}
      addToBasket={addToBasket}
      configureProduct={configureProduct}
      addProductPrice={addProductPrice}
      loading={loading}
      items={items}
      refetch={refetch}
      fetchMore={onEndReached}
      containerStyle={styles.container}
      contentStyle={styles.content}
    />
  )
}
SearchList.propTypes = {
  id: PropTypes.string,
  numCols: PropTypes.number,
  addToBasket: PropTypes.func,
  configureProduct: PropTypes.func,
  addProductPrice: PropTypes.func,
  name: PropTypes.string,
}
function ScanList({
  numCols,
  addToBasket,
  configureProduct,
  addProductPrice,
  barcode,
}) {
  const { switchLocation: { deviceId, locationId } } = useConfig()

  const { width, height } = useWindowDimensions()

  const [items, setItems] = useState([variableItem])
  const [loading, setLoading] = useState(false)

  const [scanItems, { data }] = useLazyQuery(GET_ITEM)
  const refetch = () => {
    setLoading(true)
    setItems([variableItem])
    scanItems({
      variables: {
        locId: locationId,
        cashRegisterId: deviceId,
        searchField: 'barcode',
        searchValue: barcode,
      },
    })
  }
  useEffect(() => {
    refetch()
  }, [width, height, barcode])
  useEffect(() => {
    const { getItem } = data || {}
    if (getItem) {
      setItems((prev) =>
        prev.concat([
          Object.assign(
            {},
            getItem.item,
            { itemVariation: getItem.listItemVariations?.itemVariations?.[0] },
            { discount: getItem.listDiscounts?.discounts?.[0] },
          ),
        ]),
      )
      setLoading(false)
    }
  }, [data])

  return (
    <RenderList
      numCols={numCols}
      addToBasket={addToBasket}
      configureProduct={configureProduct}
      addProductPrice={addProductPrice}
      loading={loading}
      items={items}
      refetch={refetch}
      fetchMore={() => { }}
    />
  )
}
ScanList.propTypes = {
  id: PropTypes.string,
  numCols: PropTypes.number,
  addToBasket: PropTypes.func,
  configureProduct: PropTypes.func,
  addProductPrice: PropTypes.func,
  barcode: PropTypes.string,
}

function AllItems({
  numCols,
  addToBasket,
  configureProduct,
  addProductPrice,
  isFocused,
  setIsFocused,
}) {
  const { switchLocation: { deviceId, locationId } } = useConfig()

  const { width, height } = useWindowDimensions()

  const [items, setItems] = useState([variableItem])
  const [loading, setLoading] = useState(false)
  const cursor = useRef()

  const [listAllItems, { data, fetchMore }] = useLazyQuery(LIST_ITEMS, { fetchPolicy: 'network-only' })
  const refetch = () => {
    setLoading(true)
    setItems([variableItem])
    cursor.current = null
    listAllItems({ variables: { limit: 30, locId: locationId, cashRegisterId: deviceId } })
  }
  useEffect(() => {
    if (locationId && deviceId) {
      refetch()
    }
  }, [width, height, locationId, deviceId])

  //check this
  // useEffect(() => {
  //   setLoading(true)
  //   setItems([variableItem])
  //   cursor.current = null
  //   listAllItems({ variables: { limit: 30, locId: locationId, cashRegisterId: deviceId } })
  // }, [])

  useEffect(() => {
    const { listItems } = data || {}
    if (listItems) {
      setItems([variableItem].concat(listItems.items) || [])
      cursor.current = listItems.nextToken
      setLoading(false)
    }
  }, [data])

  const onEndReached = () => {
    if (cursor.current) {
      setLoading(true)
      fetchMore({
        variables: {
          locId: locationId,
          cashRegisterId: deviceId,
          limit: 30,
          nextToken: cursor.current,
        },
      })
    }
  }

  return (
    <RenderList
      numCols={numCols}
      addToBasket={addToBasket}
      configureProduct={configureProduct}
      addProductPrice={addProductPrice}
      loading={loading}
      items={items}
      refetch={() => refetch()}
      fetchMore={() => onEndReached()}
    />
  )
}
AllItems.propTypes = {
  id: PropTypes.string,
  numCols: PropTypes.number,
  addToBasket: PropTypes.func,
  configureProduct: PropTypes.func,
  addProductPrice: PropTypes.func,
  isFocused: PropTypes.bool,
  setIsFocused: PropTypes.func,
}

const initialLayout = {
  height: 0,
  width: Dimensions.get('window').width,
}

function Checkout({
  navigation: {
    goBack,
    navigate,
    openDrawer,
    addListener,
  },
}) {


  const { euRate, usdRate, poundRate } = useLocationDetails()
  const dispatchAction = useDispatch()

  const [styles] = useTheme(themedStyles)
  const { taxDetails } = useTaxDetails()
  const { colors, isOrderRecovered, setRecoveredOrder } = useContext(Theme)
  const { storeVersion } = useUserDetails()
  const {
    itemType,
    setItemType,
    soundEnabled,
    vibrationEnabled,
    switchLocation: { deviceId, locationId },
  } = useConfig()
  const { width: windowWidth, height: windowHeight } = useWindowDimensions()
  const savedOrdersCount = useOrderCount()
  const currency = useSelector(getCurrency)
  const { order } = useSelector(getOrder)
  const len = order?.count || 0

  const setOrder = (obj) => dispatchAction(updateOrder(obj))

  const clearOrder = () => dispatchAction(resetOrder())

  const [currModal, setCurrModal] = useState({
    isVisible: false,
    onModalHide: () => { },
    onConfirm: () => { },
  })
  const [isFocused, setIsFocused] = useState(false)

  const [currencies, setCurrencies] = useState([
    {
      code: 'Lek (ALL)',
      type: 'Lek',
      currencyCode: 'ALL',
      description: '',
      descPlaceHolders: [],
      rate: 1,
      isBuyer: false,
    },
  ])

  const closeCurrencyModal = () => {
    setCurrModal(prev => ({
      ...prev,
      isVisible: false,
    }))
  }

  const setCurrency = (curr) => {
    if (len > 0 && currency.type !== curr.type) {
      setCurrencyModal(prev => ({
        ...prev,
        onModalHide: () => setCurrModal(previous => ({
          ...previous,
          isVisible: true,
          onConfirm: () => {
            closeCurrencyModal()
            setCurrModal({
              isVisible: false,
              onModalHide: () => { },
              onConfirm: () => { },
            })
            dispatchAction(changeCurrency(curr))
            clearOrder()
          },
        })),
      }))
    } else if (currency.type === curr.type) {
      closeCurrencyModal()
    } else {
      closeCurrencyModal()
      dispatchAction(changeCurrency(curr))
      clearOrder()
    }
  }

  const openCurrencySelector = () => {
    setCurrencyModal({
      isVisible: true,
      data: currencies,
      title: 'payment_currency_title',
      placeholder: 'payment_currency_placeholder',
      selected: currency,
      select: (obj) => setCurrency(obj),
      searchKey: 'code',
      Item: Type,
    })
  }
  const updateCurrencies = (euroRate, dollarRate, gbpRate) => setCurrencies([
    {
      code: 'Lek (ALL)',
      type: 'Lek',
      currencyCode: 'ALL',
      description: '',
      descPlaceHolders: [],
      rate: 1,
      isBuyer: false,
    },
    {
      code: 'Euro (€)',
      type: 'Euro',
      currencyCode: 'EUR',
      description: 'payment_euro_currency_label',
      descPlaceHolders: [euroRate],
      rate: euroRate,
      isBuyer: true,
    },
    {
      code: 'USD ($)',
      type: 'USD',
      currencyCode: 'USD',
      description: 'payment_usd_currency_label',
      descPlaceHolders: [dollarRate],
      rate: dollarRate,
      isBuyer: true,
    },
    {
      code: 'Pound (£)',
      type: 'GBP',
      currencyCode: 'GBP',
      description: 'payment_pound_currency_label',
      descPlaceHolders: [gbpRate],
      rate: gbpRate,
      isBuyer: true,
    },
  ])

  useEffect(() => {
    if (euRate && usdRate && poundRate) {
      updateCurrencies(euRate, usdRate, poundRate)
    }

  }, [euRate, usdRate, poundRate])

  useEffect(() => {
    if (currencies.length > 0) {
      const newRateCurrency = currencies.find((item) => item?.currencyCode === currency?.currencyCode)
      if (newRateCurrency) {
        dispatchAction(changeCurrency(newRateCurrency))
      }
    }
  }, [currencies])

  const [currSearchValue, setCurrSearchValue] = useState('')
  const [currencyModal, setCurrencyModal] = useState({
    isVisible: false,
    data: [],
    title: '',
    placeholder: '',
    selected: undefined,
    select: () => { },
    Item: Type,
    onModalHide: () => { },
  })

  const closeModal = () => {
    setCurrencyModal(prev => ({ ...prev, isVisible: false }))
    setCurrSearchValue('')
  }

  const [getCategories, { data: categD, error: categError }] = useLazyQuery(LIST_CATEGORIES, {
    variables: { limit: 20, locId: locationId, cashRegisterId: deviceId },
  })

  //modal queue
  const [modal, setModal] = useState({
    isVisible: false,
    active: DEFAULT_MODAL_PROPS,
    queue: [],
  })

  const dismissModal = () => {
    setModal(prev => ({
      ...prev,
      isVisible: false,
    }))
  }

  const addToModalQueue = (modalProps) => {
    setModal(prev => {
      if (prev.isVisible) {
        prev.queue.push(modalProps)
      } else {
        prev.active = modalProps
        prev.isVisible = true
      }
      return { ...prev }
    })
  }

  const checkModalQueue = () => {
    if (modal.queue.length > 0) {
      LayoutAnimation.configureNext(opacityConfig)
      setModal(prev => {
        prev.active = prev.queue.shift()
        prev.isVisible = true
        return { ...prev }
      })
    } else {
      dismissModal()
    }
  }

  const recover = (localOrder) => {
    dismissModal()
    setOrder(calculatePrice(localOrder))
  }

  const dontRecover = () => {
    checkModalQueue()
    clearOrder()
  }

  const getLocalOrder = () => {
    if (order?.lineItems?.[0]) {
      if (isOrderRecovered) {
        addToModalQueue({
          icon: images.warningIcon,
          title: 'checkount_recover_order_title',
          desc: 'checkount_recover_order_description',
          primaryText: 'checkount_recover_order_confirm',
          primaryAction: () => recover(order),
          secondaryText: 'checkount_recover_order_cancel',
          secondaryAction: dontRecover,
        })
        setRecoveredOrder(false)
      }
    } else {
      setRecoveredOrder(false)
    }
  }

  useLayoutEffect(() => {
    dispatchAction(resetLocation())
    dispatchAction(resetState())
    dispatchAction(supportInvoiceResetState())
    dispatchAction(resetItems())
    dispatchAction(resetCalculatedUBL())
  }, [])

  useFocusEffect(
    useCallback(() => {
      if (locationId && deviceId) {
        getCategories()
      }
    }, [locationId, deviceId]),
  )
  useFocusEffect(
    useCallback(() => {
      checkModalQueue()
    }, []),
  )
  const addVersionModalToQueue = () => {
    addToModalQueue({
      icon: images.newUpdate,
      title: 'checkout_new_release_title',
      desc: 'checkout_new_release_description',
      primaryText: 'checkout_update_button',
      primaryAction: () => {
        Linking.openURL(Platform.select({
          ios: 'https://apps.apple.com/us/app/ebiznes-nga-vodafone/id1549214222',
          android: 'https://play.google.com/store/apps/details?id=com.ebiznes',
        }))
      },
      secondaryText: 'checkout_skip_button',
      secondaryAction: () => {
        AsyncStorage.setItem('NEW_UPDATE', JSON.stringify({ versionCode, date: moment() }))
          .catch(() => { })
        if (modal.queue.length === 0) {
          dismissModal()
        } else {
          checkModalQueue()
        }
      },
    })
  }

  useLayoutEffect(() => {
    if (storeVersion && storeVersion > versionCode) {
      AsyncStorage.getItem('NEW_UPDATE')
        .then((value) => {
          const newUpdate = JSON.parse(value)
          const skippedDate = moment(newUpdate?.date || null)
          if (!newUpdate || (newUpdate?.versionCode < storeVersion && skippedDate.isValid() && !skippedDate.isSame(moment(), 'day'))) {
            addVersionModalToQueue()
          }
        })
        .catch(() => { })
    }
  }, [storeVersion])

  const [routes, setRoutes] = useState([])

  useEffect(() => {
    const categoryData = categD?.listCategories?.categories
    const errorMessage = getErrorType(categError)
    if (categError) {
      if (errorMessage === 'CertificateNotExist') {
        addToModalQueue({
          icon: undefined,
          title: 'certificate_not_registered_title',
          desc: 'CertificateNotExist',
          primaryText: 'certificate_go_button',
          primaryAction: () => {
            navigate('Certificate')
            dismissModal()
          },
          secondaryText: '',
          secondaryAction: undefined,
        })
      } else if (errorMessage === 'CertificateNotValid') {
        addToModalQueue({
          icon: images.warningIcon,
          title: 'certificate_not_valid_title',
          desc: 'CertificateNotValid',
          primaryText: 'certificate_go_button',
          primaryAction: () => {
            navigate('Certificate')
            dismissModal()
          },
          secondaryText: '',
          secondaryAction: undefined,
        })
      } else if (errorMessage === 'CashRegisterInactive') {
        addToModalQueue({
          icon: undefined,
          title: 'checkount_device_not_authorized_title',
          desc: 'checkount_device_not_authorized_description',
          primaryText: 'checkount_device_not_authorized_confirm',
          primaryAction: () => {
            navigate('RegisterDetails', {
              cashId: deviceId,
            })
            dismissModal()
          },
          secondaryText: '',
          secondaryAction: undefined,
        })
      } else if (errorMessage === 'CashRegisterUnknown') {
        addToModalQueue({
          icon: undefined,
          title: 'checkount_device_not_authorized_title',
          desc: 'checkount_device_not_authorized_description',
          primaryText: 'checkount_device_not_authorized_confirm',
          primaryAction: () => {
            navigate('NewRegister', {
              cashId: deviceId,
            })
            dismissModal()
          },
          secondaryText: '',
          secondaryAction: undefined,
        })
      } else if (errorMessage === 'CashRegisterNoAccessEmployee') {
        addToModalQueue({
          icon: undefined,
          title: 'checkount_device_employee_authorization_title',
          desc: 'checkount_device_employee_authorization_description',
          primaryText: 'checkount_device_employee_authorization_confirm',
          primaryAction: () => {
            navigate('RegisterDetails', {
              cashId: deviceId,
            })
            dismissModal()
          },
          secondaryText: '',
          secondaryAction: undefined,
        })
      }
    } else if (categoryData) {
      const categories = routes
      categoryData.map((item, index) => {
        if (categories.findIndex((c) => c.title === item.name) === -1) {
          categories.push({
            id: item.id,
            title: item.name,
          })
        }
      })
      setRoutes([...categories])
    } else {
      // setIsFocused(true)
    }
  }, [categD, categError])

  const addToOrder = (item) => dispatchAction(addItemToOrder(item))

  const [isClearConfirm, setClearConfirm] = useState(false)

  const searchRef = useRef(null)
  const [isScannerVisible, setScannerVisible] = useState(false)
  const [isScannerError, setScannerError] = useState(false)
  const lastScanned = useRef(null)

  const sortOptions = [
    {
      title: 'checkout_button_save_cart',
      icon: images.checkout,
      onPress: () => navigate('SaveItems'),
      disabled: len <= 0,
    },
    {
      title: 'checkout_button_clear_cart',
      icon: images.delete,
      onPress: () => setClearConfirm(true),
      disabled: len <= 0,
    },
  ]

  const substractFromHeight = Platform.select({
    ios: DeviceInfo.hasNotch() ? 140 : 80,
    android: 80,
    web: 80,
  })

  const [toBasketAnimation, setToBasketAnimation] = useState({ nodes: [] })
  const [basketLocation] = useState({
    pageX: 80,
    pageY: windowHeight - substractFromHeight,
  })
  const basketRef = useRef(null)

  const moveLeft = windowWidth > 800 ? 250 : 0

  const onAnimComplete = (animId) => {
    setToBasketAnimation(prev => {
      if (prev.lastNodeId === animId) {
        return { nodes: [] }
      }
      return prev
    })
  }

  const animateAddToBasket = (pressLocation, color, imageUrl, name) => {
    const { pageX, pageY } = pressLocation
    const labelColor = imageUrl ? colors.tertiaryBackground : color
    const animId = uuidv1()

    setToBasketAnimation(({ nodes }) => {
      const newNodes = [...nodes]
      newNodes.push(
        <AnimateToBasket
          animId={animId}
          imageUrl={imageUrl}
          color={labelColor}
          name={name}
          fromX={pageX - moveLeft}
          fromY={pageY}
          toX={basketLocation.pageX}
          toY={basketLocation.pageY}
          onAnimComplete={() => onAnimComplete(animId)}
        />,
      )
      return {
        lastNodeId: animId,
        nodes: newNodes,
      }
    })
  }

  const addToBasket = (pressLocation, color, imageUrl, item) => {
    addToOrder(item)
    animateAddToBasket(pressLocation, color, imageUrl, item?.itemName)
  }

  const configureProduct = (pressLocation, id, name, labelColor, imageUrl) => navigate('AddToBasket', {
    itemId: id,
    itemName: name,
    labelColor,
    imageUrl,
    onSave: () => animateAddToBasket(pressLocation, labelColor, imageUrl, name),
    shouldUpdateItem: false,
  })

  const addProductPrice = (
    pressLocation = null,
    params = {},
    refetchItems = () => { },
    labelColor,
    imageUrl
  ) => navigate('VariableItem', {
    pressLocation,
    params,
    refetchItems,
    labelColor,
    imageUrl,
    onSave: () => animateAddToBasket(pressLocation, labelColor, imageUrl, params.name),
  })

  const [scanResult, setScanResult] = useState('')
  const [searchValue, setSearchValue] = useState('')
  const timeout = useRef(null)

  useEffect(() => {
    getLocalOrder()
    const unsubscribe = addListener('beforeRemove', () => {
      clearTimeout(timeout.current)
    })
    return unsubscribe
  }, [])

  const onBarcodeRead = (event) => {
    const barcode = event.data
    if (barcode !== lastScanned.current) {
      if (soundEnabled) {
        const whoosh = new Sound('scan.mp3', Sound.MAIN_BUNDLE, (error) => {
          if (error) {
            return
          }
          whoosh?.play(() => {
            whoosh.release()
          })
        })
      }
      if (vibrationEnabled) {
        Vibration.vibrate([0, 200])
      }
      lastScanned.current = scanResult
      clearTimeout(timeout.current)
      timeout.current = setTimeout(() => {
        lastScanned.current = null
      }, 1000)
      setScanResult(barcode)
    }
  }

  const toggleScanner = () => {
    if (isScannerVisible) {
      lastScanned.current = null
    }
    LayoutAnimation.configureNext(layoutAnimConfig)
    setScannerVisible((value) => !value)
  }

  const [isSearchFocused, setSearchFocused] = useState(false)
  const numCols = getNumCols(itemType, windowWidth)

  const renderTabs = useMemo(() => {
    if (isScannerVisible) {
      return (
        <ScanList
          key={'list-scan'}
          numCols={numCols}
          addToBasket={addToBasket}
          configureProduct={configureProduct}
          addProductPrice={addProductPrice}
          barcode={scanResult}
          navigation={{ navigate, goBack }}
        />
      )
    }

    if (isSearchFocused || searchValue) {
      return (
        <SearchList
          key={'list-search'}
          numCols={numCols}
          addToBasket={addToBasket}
          configureProduct={configureProduct}
          addProductPrice={addProductPrice}
          name={searchValue}
          navigation={{ navigate, goBack }}
        />
      )
    }

    return (
      <Tab.Navigator
        backBehavior="none"
        style={styles.tabView}
        sceneContainerStyle={styles.scene}
        screenOptions={{
          ...tabOptions[`scroll_${routes.length > 3}`],
          tabBarLabelStyle: styles.label,
          tabBarActiveTintColor: colors.accent,
          tabBarInactiveTintColor: colors.inActiveText,
          tabBarIndicatorStyle: styles.indicatorStyle,
          style: styles.tabContainerStyle,
          tabBarPressColor: colors.background,
          tabBarAllowFontScaling: false,
          // swipeEnabled: false,
          tabStyle: Platform.OS === 'web' ? {
            width: 'auto',
            minWidth: 150,
          } : {},
        }}
        initialLayout={initialLayout}>
        <Tab.Screen key={'tab-all'} name={'All'} initialParams={{ id: 'all' }}>
          {(props) => (
            <AllItems
              {...props}
              key={'list-all'}
              id={'all'}
              numCols={numCols}
              addToBasket={addToBasket}
              configureProduct={configureProduct}
              addProductPrice={addProductPrice}
              isFocused={isFocused}
              setIsFocused={setIsFocused}
            />
          )}
        </Tab.Screen>
        {routes.map((category, index) => (
          <Tab.Screen
            key={index}
            name={category.title}
            initialParams={{ id: category.id }}>
            {(props) => (
              <Category
                {...props}
                key={'list-' + index}
                id={category.id}
                numCols={numCols}
                addToBasket={addToBasket}
                configureProduct={configureProduct}
                addProductPrice={addProductPrice}
              />
            )}
          </Tab.Screen>
        ))}
      </Tab.Navigator>
    )
  }, [
    itemType,
    routes,
    isScannerVisible,
    isSearchFocused,
    searchValue,
    scanResult,
    taxDetails,
  ])

  return (
    <>
      <KeyboardAvoidingView
        style={styles.keyboardWrapper}
        behavior={Platform.select({
          ios: 'padding',
          android: undefined,
        })}>
        <SafeAreaView style={styles.container}>
          <Header
            image={images.menu}
            title="checkout_screen_title"
            onPress={() => openDrawer()}
            rightComponent={
              <View style={styles.headerRightContainer}>
                {storeVersion > versionCode && <HeaderStatusIcon
                  isActive={storeVersion > versionCode}
                  withBadge
                  activeIcon={images.notificationActive}
                  inactiveIcon={images.notificationActive}
                  onPress={addVersionModalToQueue}
                />}
              </View>
            }
          />

          <QRScannerOrder
            error={isScannerError}
            barcode
            retry={() => setScannerError(false)}
            onBarcodeRead={onBarcodeRead}
            visible={isScannerVisible}
            containerStyle={isScannerVisible ? styles.qrContainer : {}}
          />

          {renderTabs}

          <ShadowView style={styles.shadow}>
            <View style={styles.bottomMenuContainer}>
              <Option
                icon={images.bookmark}
                count={savedOrdersCount >= 20 ? '19+' : savedOrdersCount}
                onPress={() => navigate('OpenedOrders')}
                style={styles.leftSpacing}
              />
              <View
                ref={(c) => {
                  basketRef.current = c
                }}
              // onLayout={() => measureBasketLocation()}
              >
                <TouchableOpacity
                  onPress={() => navigate('Cart')}
                  style={styles.cartContainer}
                  disabled={len === 0}>
                  <Icon
                    source={images.checkout}
                    size={18}
                    color={colors.secondaryIcon}
                  />
                  {len > 0 ? (
                    <View style={styles.cartStatusContainer}>
                      <Text
                        i18nKey={len % 1 === 0 ? len : len.toFixed(2)}
                        color={colors.white}
                        style={styles.cartStatus}
                      />
                    </View>
                  ) : null}
                </TouchableOpacity>
              </View>

              <Button
                title="checkout_charge_price_currency"
                placeHolders={[
                  `${INF.format(order?.totalMoney?.amount || 0)}`,
                  currency.type,
                ]}
                style={styles.chargeButton}
                variant={len === 0 || order?.totalMoney?.amount <= 0 ? 'disabled' : 'active'}
                onPress={() => navigate('Payment')}
              />
            </View>
          </ShadowView>

          {toBasketAnimation.nodes}

          <View style={styles.topMenuContainer}>
            <View style={styles.searchWrapper}>
              <TouchableOpacity
                onPress={() => {
                  searchRef.current?.focus()
                  if (isScannerVisible) {
                    toggleScanner()
                  }
                }}
                style={styles.searchContainer}
                activeOpacity={0.5}>
                <Icon
                  source={images.search}
                  size={16}
                  style={styles.searchIcon}
                  color={colors.secondaryIcon}
                />
                <View pointerEvents="none" style={styles.inputContainer}>
                  <TextInput
                    ref={searchRef}
                    placeholder={'cart_search_placeholder'}
                    style={styles.searchInput}
                    placeholderTextColor={colors.placeholder}
                    value={searchValue}
                    onChangeText={setSearchValue}
                    onFocus={() => setSearchFocused(true)}
                    onBlur={() => setSearchFocused(false)}
                  />
                </View>
              </TouchableOpacity>
              {searchValue ? (
                <View style={styles.clearSearchContainer}>
                  <TouchableOpacity
                    onPress={() => setSearchValue('')}
                    style={styles.clearSearchTouchable}
                    activeOpacity={0.5}>
                    <Icon
                      source={images.closeIcon}
                      size={20}
                      style={styles.searchIcon}
                      color={colors.secondaryIcon}
                    />
                  </TouchableOpacity>
                </View>
              ) : Platform.OS !== 'web' && <Option
                icon={isScannerVisible ? images.closeIcon : images.scan}
                iconColor={isScannerVisible ? colors.accent : colors.secondaryIcon}
                onPress={() => {
                  toggleScanner()
                  if (isSearchFocused) {
                    searchRef.current?.blur()
                  }
                }}
              />
              }
            </View>
            <CurrencyOption
              title={currency.type}
              // disabled
              onPress={() => openCurrencySelector()}
            />
            <Options
              items={sortOptions}
              title=""
              listHeaderComponent={(onOptionPress = () => { }) => (
                <OptionsSwitch
                  items={[
                    {
                      title: ITEM_TYPES.card,
                      icon: images.cardView,
                    },
                    {
                      title: ITEM_TYPES.grid,
                      icon: images.gridView,
                    },
                    {
                      title: ITEM_TYPES.list,
                      icon: images.listView,
                    },
                  ]}
                  selected={itemType}
                  select={setItemType}
                  onOptionPress={onOptionPress}
                />
              )}
            />
          </View>
        </SafeAreaView>
        <BottomSheetSelector
          {...currencyModal}
          closeModal={closeModal}
          onModalHide={currencyModal.onModalHide}
          searchValue={currSearchValue}
          setSearchValue={setCurrSearchValue}
        />
      </KeyboardAvoidingView>
      <Modal
        isVisible={isClearConfirm}
        onBackButtonPress={() => setClearConfirm(false)}
        onBackdropPress={() => setClearConfirm(false)}
        animationIn="fadeIn"
        animationOut="fadeOut"
        useNativeDriver
      >
        <ModalContent onClose={() => setClearConfirm(false)}>
          <Icon source={images.delete} size={32} />
          <Text
            i18nKey="cart_clear_ttle"
            align="center"
            size="h4"
            weight="bold"
            style={styles.modalTitle}
          />
          <Text
            i18nKey={'cart_clear_description'}
            translateOption={{ count: len }}
            align="center"
            placeHolders={[`${len}`]}
            style={styles.modalDesc}
          />
          <Button
            title="cart_clear_confirm"
            backgroundColor={colors.accent}
            borderColor={colors.transparent}
            style={styles.modalPrimaryButton}
            onPress={() => {
              setClearConfirm(false)
              clearOrder()
            }}
          />
          <Button
            title="cart_clear_cancel"
            backgroundColor={colors.tertiaryBackground}
            color={colors.primaryText}
            borderColor={colors.transparent}
            style={styles.modalSecondaryButton}
            onPress={() => setClearConfirm(false)}
          />
        </ModalContent>
      </Modal>
      <Modal
        isVisible={modal.isVisible}
        animationIn="fadeIn"
        animationOut="fadeOut"
        useNativeDriver
        cancellable={false}>
        <ModalContent contentContainerStyle={styles.modalContent}>
          {modal.active.icon && <Icon source={modal.active.icon} size={48} />}
          <Text
            i18nKey={modal.active.title}
            align="center"
            size="h4"
            weight="bold"
            style={styles.modalTitle}
          />
          <Text
            i18nKey={modal.active.desc}
            translateOption={{ count: len }}
            align="center"
            style={styles.modalDesc}
          />
          {modal.active.primaryAction && (
            <Button
              title={modal.active.primaryText}
              backgroundColor={colors.accent}
              borderColor={colors.transparent}
              style={styles.modalPrimaryButton}
              onPress={modal.active.primaryAction}
            />
          )}
          {modal.active.secondaryAction && (
            <Button
              title={modal.active.secondaryText}
              backgroundColor={colors.tertiaryBackground}
              color={colors.primaryText}
              borderColor={colors.transparent}
              style={styles.modalSecondaryButton}
              onPress={modal.active.secondaryAction}
            />
          )}
        </ModalContent>
      </Modal>
      <Modal
        isVisible={currModal.isVisible}
        onModalHide={currModal.onModalHide}
        onBackButtonPress={() => closeCurrencyModal()}
        onBackdropPress={() => closeCurrencyModal()}
        animationIn="fadeIn"
        animationOut="fadeOut"
        useNativeDriver
      >
        <ModalContent onClose={() => closeCurrencyModal()}>
          <Icon source={images.warningIcon} size={32} />
          <Text
            i18nKey="checkout_change_currency_label"
            align="center"
            size="h4"
            weight="bold"
            style={styles.modalTitle}
          />
          <Text
            i18nKey={'checkout_currency_clear_description'}
            translateOption={{ count: len }}
            align="center"
            placeHolders={[`${len}`]}
            style={styles.modalDesc}
          />
          <Button
            title="checkout_change_currency_label"
            backgroundColor={colors.accent}
            borderColor={colors.transparent}
            style={styles.modalPrimaryButton}
            onPress={currModal.onConfirm}
          />
          <Button
            title="cart_clear_cancel"
            backgroundColor={colors.tertiaryBackground}
            color={colors.primaryText}
            borderColor={colors.transparent}
            style={styles.modalSecondaryButton}
            onPress={() => setCurrModal({
              isVisible: false,
              onConfirm: () => { },
              onModalHide: openCurrencySelector,
            })}
          />
        </ModalContent>
      </Modal>
    </>
  )
}
Checkout.propTypes = {
  navigation: PropTypes.object,
}

export default Checkout
