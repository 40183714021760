/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useRef, useState } from 'react'
import {
  View,
  TouchableOpacity,
  FlatList,
  TouchableWithoutFeedback,
  Keyboard,
  KeyboardAvoidingView,
  Platform,
} from 'react-native'
import debounce from 'lodash.debounce'
import { useTheme } from 'react-native-themed-styles'
import PropTypes from 'prop-types'

import { useQuery } from '@apollo/client'

import Text from '../Text'
import Icon from '../Icon'
import TextInput from '../TextInput'
import Type from '../Type'

import ContentManager from '../../Utils/ContentManager'
import BottomSheetModal from '../BottomSheetModal'

import Button from '../Button'

import Loader from '../Loader'

import Theme from '../../Themes/Theme'

import images from '../../Themes/Images'

import themedStyles from './BottomSheetWithButton.Styles'

const { translate } = ContentManager

function BottomSheetWithButton({
  closeModal,
  isVisible,
  onModalHide,
  selected,
  select,
  title,
  searchValue,
  setSearchValue,
  placeholder,
  noteText,
  withNote,
  buttonLabel,
  searchKey,
  Item,
  containerStyle,
  withSearch,
  onButtonPress,
  query,
  mapItems,
  queryVariables,
  withVariables,
}) {
  const [styles] = useTheme(themedStyles)
  const { colors } = useContext(Theme)
  const inputRef = useRef()

  const [selectedItem, setSelectedItem] = useState(selected)
  const [resData, setResData] = useState([])
  const [items, setItems] = useState(resData)
  const [isKeyboardVisible, setKeyboardVisible] = useState(false)

  const isDataEmpty = Object.keys(resData).length === 0
  const inputIsEmpty = searchValue?.trim() === ''
  const isItemSelected = resData?.filter(e => e.id === selectedItem.id).length > 0
  const [currentIndex, setCurrentIndex] = useState()
  // const [flatListRef, setFlatListRef] = useState(null)

  useEffect(() => {
    const keyboardDidShowListener = Keyboard.addListener(
      'keyboardDidShow',
      () => {
        setKeyboardVisible(true) // or some other action
      }
    )
    const keyboardDidHideListener = Keyboard.addListener(
      'keyboardDidHide',
      () => {
        setKeyboardVisible(false) // or some other action
      }
    )

    return () => {
      keyboardDidHideListener.remove()
      keyboardDidShowListener.remove()
    }
  }, [])


  const queryOptions = {
    fetchPolicy: 'network-only',
    ...(Boolean(withVariables) && { variables: withVariables }),
  }

  const { data: queryData, loading: dataLoading } = useQuery(query, queryOptions)

  useEffect(() => {

    setResData(mapItems(queryData))
  }, [queryData])


  useEffect(() => {
    if (selectedItem.id !== selected.id) {
      setSelectedItem(selected)
    }
  }, [isVisible, selected])


  useEffect(() => {
    let newItems = []

    if (Array.isArray(resData)) {
      const filterSelf = (item) => item?.toLowerCase().includes(searchValue.toLowerCase())
      const filterKey = ({ [searchKey]: key } = {}) => key?.toLowerCase().includes(searchValue.toLowerCase())
      const filter = searchKey === 'self' ? filterSelf : filterKey
      newItems = resData?.filter(filter)
    }
    setItems(newItems)
  }, [resData, searchValue])





  const keyExtractor = (_, index) => 'item_' + index
  const renderItem = ({ item, index }) => (
    <Item
      item={item}
      selected={selectedItem}
      withSeparator={false}
      onPress={() => {
        setSelectedItem(item)
      }}
    />
  )

  const getSelectedItemIndex = () => {
    const index = resData?.findIndex(object => {
      return object.id === selectedItem?.id
    })
    return index
  }
  useEffect(() => {
    setCurrentIndex(getSelectedItemIndex())
  }, [selectedItem, resData])

  // useEffect(() => {
  // flatListRef?.scrollToIndex({animated: true , index: currentIndex < 0 ? 0 : currentIndex , viewPosition: -1 })

  // }, [items])



  const getItemLayout = (data, index) => {
    return { length: 75, offset: 75 * index, index }
  }

  const updateSearchValue = debounce(text => setSearchValue(text), 300)
  const resetSearchValue = () => {
    updateSearchValue.cancel()
    setSearchValue('')
    inputRef.current.clear()

  }

  const handleModalClose = () => {
    closeModal()
  }
  const handleButtonClick = () => {
    select(selectedItem)
    onButtonPress(selectedItem)
    setSelectedItem({})
    setCurrentIndex(null)
  }


  return (
    <>
      <KeyboardAvoidingView
        behavior={'height'}
      >

        <BottomSheetModal
          isVisible={isVisible}
          onClose={handleModalClose}
          onModalHide={onModalHide}
        >
          <View style={styles.container}>

            <View style={styles.header}>
              <Text
                i18nKey={title}
                weight="bold"
                size="h4"
                color={colors.accent}
              />
              <TouchableOpacity onPress={handleModalClose}>
                <Icon source={images.close} color={colors.secondaryIcon} size={24} />
              </TouchableOpacity>
            </View>

            {withNote && <View style={styles.noteContainer}>
              <View style={styles.noteInnerContainer}>
                <Icon source={images.info} color={colors.secondaryIcon} size={20} />
                <Text
                  i18nKey={'change_location_note'}
                  weight="bold"
                  size="body"
                  color={colors.black}
                  style={styles.noteLabel}
                />
              </View>
              <Text
                i18nKey={noteText}
                weight="normal"
                size="note"
                color={colors.black}
                style={styles.noteText}
              />

            </View>}
            {withSearch && <View style={styles.searchContainer}>
              <Icon
                source={images.search}
                size={20}
                style={styles.searchIcon}
              />
              <TextInput
                ref={ref => { inputRef.current = ref }}
                placeholder={translate(placeholder)}
                onChangeText={updateSearchValue}
                placeholderTextColor={colors.disabled}
                spellCheck={false}
                textContentType="none"
                underlineColorAndroid="transparent"
                style={styles.searchInput}
              />
              {Boolean(searchValue) && (
                <TouchableOpacity
                  onPress={resetSearchValue}
                  style={styles.clearSearchContainer}
                >
                  <Icon source={images.close} color={colors.secondaryIcon} size={15} />
                </TouchableOpacity>
              )}
            </View>}
            <TouchableWithoutFeedback>
              {dataLoading ? <Loader /> : <FlatList
                data={inputIsEmpty ? resData : items}
                onStartShouldSetResponder={() => true}
                decelerationRate={0.8}
                keyExtractor={keyExtractor}
                renderItem={renderItem}
                style={[styles.listStyle, isKeyboardVisible ? styles.list : null]}
                contentContainerStyle={[styles.listContainerStyle, containerStyle]}
                getItemLayout={getItemLayout}
                // ref={(ref)=>setFlatListRef(ref)}
                initialScrollIndex={
                  Platform.select({
                    android: currentIndex < 0 ? 0 : currentIndex === 0 ? 0 : currentIndex - 1,
                    ios: currentIndex < 0 ? 0 : currentIndex === 0 ? 0 : currentIndex - 1,
                    web: 0,
                  })
                }
              />}

            </TouchableWithoutFeedback>
            <Button
              title={buttonLabel}
              backgroundColor={isDataEmpty || !isItemSelected ? colors.disabled : colors.accent}
              borderColor={colors.transparent}
              style={styles.button}
              onPress={handleButtonClick}
              disabled={isDataEmpty || !isItemSelected}
            />
          </View>
        </BottomSheetModal>
      </KeyboardAvoidingView>
    </>
  )
}
BottomSheetWithButton.defaultProps = {
  Item: Type,
  withSearch: true,
  withNote: true,
}
BottomSheetWithButton.propTypes = {
  // data: PropTypes.array,
  select: PropTypes.func,
  selected: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  closeModal: PropTypes.func,
  title: PropTypes.string,
  searchValue: PropTypes.string,
  setSearchValue: PropTypes.func,
  placeholder: PropTypes.string,
  buttonLabel: PropTypes.string,
  searchKey: PropTypes.string,
  Item: PropTypes.any,
  containerStyle: PropTypes.object,
  withSearch: PropTypes.bool,
  isVisible: PropTypes.bool,
  onModalHide: PropTypes.func,
  onButtonPress: PropTypes.func,
  noteText: PropTypes.string,
  withNote: PropTypes.bool,
  query: PropTypes.any,
  mapItems: PropTypes.func,
  queryVariables: PropTypes.object,
  withVariables: PropTypes.any,
}

export default BottomSheetWithButton
