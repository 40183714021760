import PropTypes from 'prop-types'
import React, { PureComponent } from 'react'
import { Animated } from 'react-native'

import styles from './styles'

export default class Affix extends PureComponent {
  static defaultProps = {
    numberOfLines: 1,
  };

  static propTypes = {
    numberOfLines: PropTypes.number,
    style: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),

    color: PropTypes.string.isRequired,
    fontSize: PropTypes.number.isRequired,

    type: PropTypes
      .oneOf(['prefix', 'suffix'])
      .isRequired,

    labelAnimation: PropTypes
      .instanceOf(Animated.Value)
      .isRequired,

    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node,
    ]),
  };

  render() {
    const { labelAnimation, style, children, type, fontSize, color } = this.props

    const containerStyle = {
      height: fontSize * 1.5,
      opacity: labelAnimation,
    }

    const textStyle = {
      includeFontPadding: false,
      textAlignVertical: 'top',

      fontSize,
      color,
    }

    switch (type) {
      case 'prefix':
        containerStyle.paddingRight = 8
        textStyle.textAlign = 'left'
        break

      case 'suffix':
        containerStyle.paddingLeft = 8
        textStyle.textAlign = 'right'
        break
    }

    return (
      <Animated.View style={[styles.container, containerStyle]}>
        <Animated.Text allowFontScaling={false} style={[style, textStyle]}>{children}</Animated.Text>
      </Animated.View>
    )
  }
}
