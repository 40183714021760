/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useRef, useState, useEffect, useCallback } from 'react'
import { View, Animated, Easing, TouchableOpacity } from 'react-native'
import PropTypes from 'prop-types'
import { useTheme } from 'react-native-themed-styles'
import { useLazyQuery } from '@apollo/client'
import moment from 'moment'

import {
  Text,
  Icon,
  Loader,
  DoubleIcon,
} from '../../Components'

import { useLanguage } from '../../Utils/Language'

import { INF } from '../../Utils/Helpers'

import { useConfig } from '../../Utils'

import images from '../../Themes/Images'
import Theme from '../../Themes/Theme'

import { LIST_TRANSACTIONS } from './CustomerDetails.Schema'


import themedStyles, { transactionStyles } from './CustomerDetails.Styles'

const AnimatedTouchable = Animated.createAnimatedComponent(TouchableOpacity)

const LANGUAGES = {
  al: 'sq',
  en: 'en-gb',
}

function ErrorItem({ loading, stylee }) {
  const [styles] = useTheme(themedStyles)

  return (
    <View style={styles.errorContainer}>
      <View style={styles.errorSubContainer}>
        {loading ? null
          : <>
            <Text i18nKey="orders_no_transactions_label" align="center" size="body" weight="light" />
          </>}
      </View>
    </View>
  )
}
ErrorItem.propTypes = {
  loading: PropTypes.bool,
  stylee: PropTypes.object,
}

function SectionItem({ sectionTitle }) {
  const [styles] = useTheme(themedStyles)
  const { colors } = useContext(Theme)

  return (
    <Text
      i18nKey={sectionTitle}
      size="footnote"
      color={colors.secondaryText}
      style={styles.sectionTitle}
    />
  )
}
SectionItem.propTypes = {
  sectionTitle: PropTypes.string,
}

function Item({ item, stylee }) {
  const [styles] = useTheme(transactionStyles)
  const { colors } = useContext(Theme)

  return (
    <View style={[styles.itemStyle, stylee, styles[`firstItem_${item.isFirst}`], styles[`lastItem_${item.isLast}`]]}>
      <TouchableOpacity onPress={item.onPress} disabled={!item.onPress} style={{}}>
        <View style={styles.centeredItemView}>
          <DoubleIcon
            iconSource={images.cash}
            statusIconSource={images.transaction}
            iconSize={30}
            statusSize={10}
            style={styles.leftSpacing}
            statusIconStyle={styles.bottomSpacing}
          />
          <View style={styles.listDescContainer}>
            <Text i18nKey={item.title} numberOfLines={1} size="footnote" />
            <Text
              i18nKey={item.description}
              weight="light"
              color={colors.secondaryText}
              numberOfLines={1}
              style={styles.descriptionStyles(item?.type)}
            />
          </View>
          <View style={styles.rightComponent}>
            <Text i18nKey={item?.amount} style={styles.rightSpacing} />
            <Icon source={images.arrow_right} size={20} />
          </View>
        </View>
      </TouchableOpacity>
    </View>
  )
}
Item.propTypes = {
  item: PropTypes.object,
  stylee: PropTypes.object,
}

const listItems = {
  section: SectionItem,
  element: Item,
}

function Transactions({
  id,
  navigation: {
    navigate,
  },
  options,
  ...rest
}) {
  const { switchLocation: { deviceId, locationId } } = useConfig()

  const [styles] = useTheme(transactionStyles)
  const { colors } = useContext(Theme)
  const lang = useLanguage()

  const sectionDate = useRef('-')
  const [data, setData] = useState([])
  const [loading1, setLoading] = useState(true)

  const [listTransactions, { data: listData, refetch, fetchMore }] = useLazyQuery(
    LIST_TRANSACTIONS,
    {
      fetchPolicy: 'network-only',
    },
  )
  const list = () => {
    listTransactions({
      variables: {
        locationId: locationId,
        cashRegisterId: deviceId,
        limit: 20,
        customerId: id,
      },
    })
  }

  useEffect(() => {
    setLoading(true)
    sectionDate.current = null
    setData([])
    list()
  }, [])


  const mapData = (dataa) => {
    const res = []
    let itemTime
    let isFirst = false, isLast = false
    dataa?.map((item, index) => {
      isFirst = false
      isLast = false
      itemTime = item.createdAt
      const date = moment(itemTime).locale(LANGUAGES[lang]).format('MMM DD, YYYY')
      if (date !== sectionDate.current) {
        if (res?.[res.length - 1]) {
          res[res.length - 1].isLast = true
        }
        sectionDate.current = date
        res.push({
          id: date,
          sectionTitle: date,
          type: 'section',
        })
      }

      if (res?.[res.length - 1]?.type === 'section') {
        isFirst = true
        isLast = false
      }

      const title = item?.receiptNumber
      const amount = INF.format(item?.tender?.amountMoney?.amount)
      const currency = item?.tender?.amountMoney?.currency
      const total = `+${amount} ${currency}`
      const transactionId = item.id
      const onItemPress = () => navigate('Receipt', { id: item.id, receiptNumber: title })
      res.push({
        id: item.id,
        transactionId: transactionId,
        type: 'element',
        title: title,
        description: moment(itemTime).locale(LANGUAGES[lang]).format('hh:mm A'),
        amount: total,
        isFirst: isFirst,
        isLast: isLast,
        onPress: onItemPress,
      })
    })
    if (res?.[res.length - 1]) {
      res[res.length - 1].isLast = true
    }
    return res
  }

  useEffect(() => {
    if (listData) {
      const dataa = listData?.listTransactions?.transactions
      const resp = mapData(dataa)
      setData(resp)
      setLoading(false)
    }
  }, [listData])

  const listRef = useRef(null)
  const fabSize = useRef(new Animated.Value(0)).current
  const animate = val => {
    Animated.timing(fabSize, {
      toValue: val,
      duration: 370,
      useNativeDriver: true,
      easing: Easing.inOut(Easing.cubic),
    }).start()
  }
  const scrollToTop = () => {
    animate(0)
    listRef.current.scrollToOffset({
      offset: 0,
    })
  }

  const keyExtractor = useCallback((_, index) => 'row-' + index, [])

  const getItemLayout = useCallback((_, index) => ({
    length: 61,
    offset: 61 * index,
    index,
  }), [])


  const onEndReached = () => {
    if (listData?.listTransactions?.nextToken === null) {
      return
    }
    setLoading(true)
    fetchMore({
      variables: {
        locationId: locationId,
        cashRegisterId: deviceId,
        limit: 20,
        nextToken: listData?.listTransactions?.nextToken,
        customerId: id,
      },
    })
  }

  return (
    <>
      <Animated.FlatList
        showsVerticalScrollIndicator={false}
        keyExtractor={keyExtractor}
        getItemLayout={getItemLayout}
        ref={(ref) => (listRef.current = ref)}
        onMomentumScrollEnd={(event) => {
          if (event.nativeEvent.contentOffset.y > 800) {
            animate(1)
          } else {
            animate(0)
          }
        }}
        data={data}
        style={styles.listStyle}
        contentContainerStyle={styles.contentContainer}
        ListEmptyComponent={<ErrorItem loading={loading1} />}
        ListFooterComponent={<View style={styles.footer} >{loading1 ? <Loader size={32} /> : null}</View>}
        renderItem={({ item, index }) => {
          const ListItem = listItems[item.type]
          return <ListItem
            sectionTitle={item.sectionTitle}
            key={'row-' + index}
            item={item}
          />
        }}
        {...rest}
        onEndReachedThreshold={0.4}
        onRefresh={() => refetch()}
        refreshing={false}
        onEndReached={onEndReached}
      />
      <AnimatedTouchable
        onPress={scrollToTop}
        activeOpacity={0.5}
        style={[
          styles.fab,
          {
            opacity: fabSize,
            transform: [{ scale: fabSize }],
          },
        ]}>
        <Icon
          source={images.arrow_right}
          size={20}
          color={colors.tertiaryIcon}
          style={styles.fabIcon}
        />
      </AnimatedTouchable></>
  )
}

Transactions.defaultProps = {
  useFlatList: true,
  useSectionList: false,
  setItemWidth: () => { },
}

Transactions.propTypes = {
  id: PropTypes.string,
  navigation: PropTypes.objectOf({
    navigate: PropTypes.func,
  }),
  options: PropTypes.array,
}

export default Transactions
