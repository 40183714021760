import React, { useEffect, useRef, useState } from 'react'
import { SafeAreaView, ScrollView, View, Text as RNText, Linking, KeyboardAvoidingView, Platform } from 'react-native'
import { useTheme } from 'react-native-themed-styles'
import PropTypes from 'prop-types'
import { useMutation } from '@apollo/client'

import { Button, ErrorModal, Header, InputWithTags, Text, TouchableInput } from '../../Components'

import ContentManager from '../../Utils/ContentManager'
import { BUSIN_UNITS_LIST, UPDATE_LOCATION } from '../Location/Location.Schema'
import { getErrorMessage, useConfig } from '../../Utils'


import BottomSheetCodeInput from '../../Components/BottomSheetCodeInput/BottomSheetCodeInput'

import themedStyles from './EditBusinessUnitCode.Styles'
import { colors, images } from '../../Themes'

function EditBusinessUnitCode(
    {
        route: {
            params: {
                location,
            } },
        navigation: {
            navigate,
            goBack,
            pop,

        },
    }) {

    const { switchLocation: { deviceId, locationId } } = useConfig()

    const scrollRef = useRef(null)
    const { translate } = ContentManager
    const [oldBusinCode, setOldBusinCode] = useState('')
    const [newBusinCode, setNewBusinCode] = useState('')
    const [tcrFormatError, setTcrFormatError] = useState(false)
    const [newTcrFormatError, setNewTcrFormatError] = useState(false)
    const regex = /^[a-z]{2}\d{3}[a-z]{2}\d{3}$/
    const [updateLocation, { loading }] = useMutation(UPDATE_LOCATION)
    //Bussines Unit Code
    const [businessCodeModal, setBusinessCodeModal] = useState(false)
    const [businessInputValue, setBusinessInputValue] = useState(newBusinCode)
    const [businCodesData, setBusinCodesData] = useState([])
    const [selectedBusinCode, setselectedBusinCode] = useState({ code: newBusinCode })
    const isAnItemSelected = businCodesData.filter((item) => item.code === newBusinCode).length > 0


    useEffect(() => {
        if (isAnItemSelected) {
            setselectedBusinCode({ code: newBusinCode })
        }
    }, [businessCodeModal])

    useEffect(() => {
        if (!isAnItemSelected) {
            setBusinessInputValue(newBusinCode)
        }
    }, [businessCodeModal])



    const handleTextChange = (text) => {
        setBusinessInputValue(text)
        setselectedBusinCode('')
    }


    const handleCodeInputModalClose = () => {
        setBusinessCodeModal(false)
    }
    const mapBusinCodes = (businCodesList) => {
        const unmapedBusinCodes = businCodesList?.getBusinessUnits?.businessUnits
        const mappedBusinCodes = []

        if (unmapedBusinCodes) {
            unmapedBusinCodes.forEach((item) => {
                mappedBusinCodes.push({
                    id: item?.attributes?.BUName,
                    code: item?.attributes?.BUCode,
                    description: `${item?.attributes?.BUStreetName}`,
                })
            })
        }
        return mappedBusinCodes
    }

    const [modal, setModal] = useState({
        isVisible: false,
        icon: images.warningIcon,
        title: '',
        desc: '',
        descPlaceholders: [],
        primaryText: '',
        primaryAction: null,
        tertiaryText: '',
        tertiaryAction: null,
    })
    const closeModal = () => {
        setModal({
            isVisible: false,
            icon: images.delete,
            title: '',
            desc: '',
            primaryText: '',
            primaryAction: null,
            tertiaryText: '',
            tertiaryAction: null,
        })
    }
    const openModal = ({
        title = '',
        desc = '',
        descPlaceholders = [],
        primaryText = '',
        primaryAction = null,
        tertiaryText = '',
        tertiaryAction = null,
        icon = images.warningIcon,
    }) => {
        setModal({
            isVisible: true,
            icon,
            title,
            desc,
            descPlaceholders,
            primaryText,
            primaryAction,
            tertiaryText,
            tertiaryAction,
        })
    }

    const onButtonPress = () => {
        if (oldBusinCode !== location?.businUnitCode) {
            setTcrFormatError(true)
        } else if (!regex.test(newBusinCode)) {
            setNewTcrFormatError(true)
            setTcrFormatError(false)
        }
        else {
            setNewTcrFormatError(false)
            setTcrFormatError(false)
            updateLocation({
                variables: {
                    locId: locationId,
                    cashRegisterId: deviceId,
                    id: location?.id,
                    businUnitCode: newBusinCode,
                },
            }).then((e) => {
                pop(3)
            }).catch((e) => {
                if (getErrorMessage(e) === 'CashRegisterTCRNotDeleted') {
                    openModal({
                        title: 'certificate_error_label',
                        icon: images?.warning,
                        desc: getErrorMessage(e),
                        primaryAction: () => {
                            pop(1)
                            navigate(translate('cash_register_user_tab'))
                        },
                        primaryText: 'edit_business_redirect_devices',
                        tertiaryText: 'logout_button_cancel',
                        tertiaryAction: () => closeModal(),
                    })
                }
                else {
                    openModal({
                        title: 'certificate_error_label',
                        icon: images?.warning,
                        desc: getErrorMessage(e),
                        primaryText: 'logout_button_cancel',
                        primaryAction: () => closeModal(),
                    })
                }
            })
        }
    }

    const [styles] = useTheme(themedStyles)
    return (
        <>
            <SafeAreaView style={styles.safeAreaContainer}>
                <Header
                    title="location_business_button"
                    image={images.close}
                    onPress={() => goBack()}
                />
                <KeyboardAvoidingView
                    style={{ flex: 1 }}
                    behavior={Platform.OS === 'ios' ? 'padding' : null}
                    keyboardVerticalOffset={50}
                >
                    <ScrollView
                        ref={scrollRef}
                        style={{ flexGrow: 1 }}
                        contentContainerStyle={styles.scrollContainer}
                    >

                        <Text i18nKey="settings_business_code_label" weight="bold" size="h5" />
                        <RNText style={styles.noteText}>
                            <RNText style={styles.boldText}>{translate('location_business_note_1')}</RNText>
                            <RNText>{translate('location_business_note_2')}</RNText>
                        </RNText>
                        <View style={styles.deviceInfoContainer}>
                            <View style={styles.deviceInfoRow}>
                                <Text i18nKey={'settings_business_code_label'} color={colors.midGrey} />
                                <Text i18nKey={location?.businUnitCode} />
                            </View>
                            <View style={styles.deviceInfoRow}>
                                <Text i18nKey={'location_label_business_name'} color={colors.midGrey} />
                                <Text i18nKey={location?.businessName} />
                            </View>
                        </View>
                        <Text i18nKey="edit_business_code_current_header" style={styles.busInfoHeader} />
                        <InputWithTags
                            label="edit_business_code_current_input"
                            value={oldBusinCode}
                            inputContainerStyle={styles.inputContainer}
                            maxLength={50}
                            editable={true}
                            inputWrapperStyle={styles.inputWrapper}
                            onChangeText={setOldBusinCode}
                            error={tcrFormatError}
                            autoCapitalize="none"
                            onBlur={() => {
                                if (oldBusinCode !== location?.businUnitCode && oldBusinCode) {
                                    setTcrFormatError(true)
                                } else if (oldBusinCode === '') {
                                    setTcrFormatError(false)
                                }
                            }}
                            onFocus={() => setTcrFormatError(false)}
                        />
                        {tcrFormatError && <Text i18nKey="edit_business_tcr_error" size="footnote" style={{ color: colors.accent }} />}
                        <Text i18nKey="edit_business_code_new_header" />
                        <TouchableInput
                            onPress={() => setBusinessCodeModal(true)}
                            label="edit_business_code_new_input"
                            value={newBusinCode}
                            icon={images.filledDownArrow}
                            iconSize={16}
                            autoCorrect={false}
                            autoCapitalize="none"
                            editable={false}
                        />

                        {/* <InputWithTags
                            label="edit_business_code_new_input"
                            value={newBusinCode}
                            inputContainerStyle={styles.inputContainer}
                            maxLength={50}
                            editable={true}
                            inputWrapperStyle={styles.inputWrapper}
                            onChangeText={setNewBusinCode}
                            autoCapitalize="none"
                            error={newTcrFormatError}
                            onBlur={() => {
                                if (!regex.test(newBusinCode) && newBusinCode) {
                                    setNewTcrFormatError(true)
                                } else if (newBusinCode === '') {
                                    setNewTcrFormatError(false)
                                }
                            }}
                            onFocus={() => setNewTcrFormatError(false)}
                        /> */}
                        {newTcrFormatError && <Text i18nKey="edit_business_new_tcr_error" size="footnote" style={{ color: colors.accent }} />}

                        <RNText
                            onPress={() => Linking.openURL('https://efiskalizimi-app.tatime.gov.al/self-care')}
                            style={styles.infoText}
                            allowFontScaling={false}
                        >
                            <RNText>{translate('cash_register_business_code1')}</RNText>
                            <RNText style={{ color: colors.secondaryAccent }}>{translate('cash_register_business_code2')}</RNText>
                            <RNText>{translate('cash_register_business_code3')}</RNText>
                            <RNText style={styles.italicText}>{translate('cash_register_business_code4_1')}</RNText>
                            <RNText>{translate('cash_register_business_code5')}</RNText>
                        </RNText>
                    </ScrollView>

                </KeyboardAvoidingView>
                <Button
                    variant={(newTcrFormatError || tcrFormatError || !newBusinCode || !oldBusinCode) ? 'disabled' : 'active'}
                    title={'location_business_button'}
                    style={styles.editBusinessButton}
                    onPress={onButtonPress}
                    disabled={(oldBusinCode && newBusinCode) ? false : true}
                    loader={loading}
                />
            </SafeAreaView >
            <BottomSheetCodeInput
                inputValue={businessInputValue}
                isVisible={businessCodeModal}
                data={businCodesData}
                title={'edit_business_code_label'}
                inputPrompt={'edit_business_code_enter_prompt'}
                inputLabel={'edit_business_code_label'}
                buttonLabel={'edit_business_code_set'}
                onSave={setNewBusinCode}
                setData={setBusinCodesData}
                query={BUSIN_UNITS_LIST}
                note
                mapItems={mapBusinCodes}
                onClose={handleCodeInputModalClose}
                onChangeText={handleTextChange}
                selected={selectedBusinCode}
                select={(obj) => {
                    setselectedBusinCode(obj)
                }}
                editable={true}
            />
            <ErrorModal
                isVisible={modal?.isVisible}
                icon={modal?.icon}
                title={modal?.title}
                description={modal?.desc}
                placeHolders={modal.descPlaceholders}
                primaryText={modal?.primaryText}
                tertiaryText={modal?.tertiaryText}
                primaryAction={modal?.primaryAction}
                tertiaryAction={modal?.tertiaryAction}
                onBackdropPress={() => closeModal()}
                onBackButtonPress={() => closeModal()}
                onClosePress={() => closeModal()}
            />
        </>
    )
}

EditBusinessUnitCode.propTypes = {
    route: PropTypes.object,
    navigation: PropTypes.object,
    setLocation: PropTypes.func,
    goBack: PropTypes.func,
    isVirtual: PropTypes.bool,
    refetch: PropTypes.func,
}

export default EditBusinessUnitCode
