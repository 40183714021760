import { styleSheetFactory } from '../../../../Themes/Colors'

const styles = styleSheetFactory(colors => ({
  container: {
    flex: 1,
    position: 'relative',
    backgroundColor: colors.background,
  },
  searchContainer: {
    flexDirection: 'row',
    marginVertical: 15,
    minHeight: 80,
    justifyContent: 'center',
    alignItems: 'center',
  },
}))

export default styles
