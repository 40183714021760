import { styleSheetFactory } from '../../Themes/Colors'


const styles = styleSheetFactory(colors => ({
    container: {
        flex: 1,
        paddingBottom: 20,
    },
    subFileContainer: {
        flex: 1,
        alignItems: 'center',
        padding: 15,
    },
    clearContainer: {
        margin: 6.5,
        justifyContent: 'center',
        alignItems: 'center',
        width: 30,
        height: 30,
        borderRadius: 20,
        backgroundColor: colors.background,
        flexShrink: 0,
    },

}))

export default styles
