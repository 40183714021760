import { styleSheetFactory } from '../../Themes/Colors'

const styles = styleSheetFactory(colors => ({
  container: {
    position: 'absolute',
    right: 0,
    top: 0,
    backgroundColor: colors.accent,
    borderColor: colors.tertiaryBackground,
    // borderTopColor: colors.transparent,
    // borderRightColor: colors.transparent,
    borderBottomWidth: 1,
    borderLeftWidth: 1,
// borderWidth: 5,
    // borderTopLeftRadius: 7,
    height: 24,
    borderBottomLeftRadius: 7,
    flexDirection: 'row',
    alignItems: 'center',
    paddingRight: 8,
  },
  icon: {
    tintColor: colors.white,
    marginLeft: 8,
    marginRight: 4,
  },
  text: {
    color: colors.white,
  },
}))

export default styles
