import { Platform } from 'react-native'

import { styleSheetFactory } from '../../Themes/Colors'

const isWeb = Platform.OS === 'web'

const styles = styleSheetFactory(colors => ({
    header: {
        marginVertical: 25,
        marginTop: 30,
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    wrapper: {
        marginHorizontal: 15,
        paddingBottom: 10,
    },
    formatListStyle: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent:  isWeb ?  'space-between'  : 'space-around',
    },
    languageListStyle: {
        flexGrow: 1,
    },
    languageListContainerStyle: {
        paddingBottom: 20,
    },
    button: {
        marginBottom: 15,
    },
}))

export default styles
