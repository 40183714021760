import { styleSheetFactory } from '../../../../Themes/Colors'

const styles = styleSheetFactory(colors => ({
  container: {
    flex: 1,
    backgroundColor: colors.background,
  },
  content: {
    flexGrow: 1,
    paddingHorizontal: 16,
    paddingVertical: 24,
  },
  fillRemaining: {
    flexGrow: 1,
    paddingBottom: 20,
  },
  buttons: {
    flexDirection: 'row',
    marginBottom: 15,
    marginTop: 10,
    marginHorizontal: 15,
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  leftButton: {
    flex: 1,
    height: 50,
  },
  rightButton: {
    flex: 1,
    height: 50,
    marginRight: 15,
  },
  addCustomer: {
    height: 50,
    marginTop: 15,
    marginBottom: 5,
  },
  customerHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 15,
    marginBottom: 10,
  },
  removeBuyer: {
    borderRadius: 18,
    padding: 5,
    backgroundColor: colors.separatorBody,
    alignSelf: 'flex-end',
    alignItems:'flex-end',
  },
  loaderContainer: {
    backgroundColor: colors.white,
    borderBottomColor: colors.separator,
    borderBottomWidth: 1,
    height: 60,
  },
  errorContainer: {
    backgroundColor: colors.white,
    alignItems: 'center',
    borderRadius: 10,
    paddingVertical: 15,
  },
  icon: {
    marginBottom: 10,
  },
  errorRetry: {
    color: colors.red,
  },
  leftSpacing: { marginLeft: 10 },
}))

export default styles
