import {StyleSheet} from 'react-native'

export default StyleSheet.create({
  item: {
    width: '100%',
  },
  container: {
    marginTop: 15,
  },
})
