import React, { useContext } from 'react'
import { View, TouchableOpacity, LayoutAnimation } from 'react-native'
import PropTypes from 'prop-types'
import { useTheme } from 'react-native-themed-styles'
import { useDispatch, useSelector } from 'react-redux'

import { Text, InputWithTags, Button, Icon } from '../../../../../Components'

import Theme, { opacityConfig } from '../../../../../Themes/Theme'
import images from '../../../../../Themes/Images'

import { getInitialData } from '../../../Payment.Selectors'
import { updateStepKey } from '../../../Payment.Actions'

import themedStyles from './EInvoiceType.Styles'

function EInvoiceType({
  navigate,
}) {
  const [styles] = useTheme(themedStyles)
  const { colors } = useContext(Theme)

  const storedPayment = useSelector(getInitialData)
  const dispatchAction = useDispatch()

  const updatePaymentMeans = (key, value) => dispatchAction(updateStepKey('init', key, value))

  const getBankAccount = (bankAccount) => {
    updatePaymentMeans('primaryAccountId', bankAccount.iban)
    updatePaymentMeans('code', bankAccount.swiftCode)
    updatePaymentMeans('bankName', bankAccount.bankName)
    updatePaymentMeans('currency', bankAccount.currency)
    updatePaymentMeans('payeeFinantialAccount', bankAccount.holderName)
  }

  return (
    <View>
      <Text style={styles.sectionText} weight="bold" i18nKey="payment_title_type_noncash_einvoice" />
      {storedPayment.code ? <>
        <View style={styles.bankHeading}>
          <Text style={styles.sectionText} i18nKey="einvoice_payment_bank_account_label" />
          <TouchableOpacity style={styles.removeBuyer}
            onPress={() => {
              updatePaymentMeans('code', '')
              updatePaymentMeans('primaryAccountId', '')
              updatePaymentMeans('bankName', '')
              updatePaymentMeans('currency', '')
              updatePaymentMeans('payeeFinantialAccount', '')
              LayoutAnimation.configureNext(opacityConfig)
            }}>
            <Icon source={images.closeIcon} color={colors.secondaryIcon} size={16} />
          </TouchableOpacity>
        </View>
        <View style={styles.bankDetailsWrapper}>
          <Text i18nKey={`${storedPayment.primaryAccountId} (${storedPayment.currency})`} />
          <Text i18nKey={storedPayment.bankName} weight="light" />
        </View>
      </>
        : <Button title={'bank_accounts_add_button_label'}
          variant="secondary"
          style={styles.bankAccount}
          onPress={() => navigate('BankAccounts', {
            selectable: true,
            updateSelection: getBankAccount,
          })}
        />}
      <InputWithTags
        label="payment_description_label"
        value={storedPayment.instructionNote}
        onChangeText={text => updatePaymentMeans('instructionNote', text)}
        autoCompleteType="off"
        inputWrapperStyle={styles.email}
      />
    </View>
  )
}
EInvoiceType.propTypes = {
  iban: PropTypes.string,
  setIban: PropTypes.func,
  accNumber: PropTypes.string,
  setAccNumber: PropTypes.func,
  bic: PropTypes.string,
  setBic: PropTypes.func,
  paymentDesc: PropTypes.string,
  setPaymentDesc: PropTypes.func,
  navigate: PropTypes.func,
  currencies: PropTypes.array,
  currency: PropTypes.object,
  setCurrency: PropTypes.func,
}

export default EInvoiceType
