import { StyleSheet } from 'react-native'

import colors from '../../Themes/Colors'

export default StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.background,
  },
  listContainer: {
    marginHorizontal: 15,
    borderRadius: 7,
    marginTop: 15,
    overflow: 'hidden',
    flex: 1,
  },
  stickyHeader: {
    flex: 1,
    backgroundColor: colors.white,
  },
  searchContainer: {
    flexDirection: 'row',
    margin: 15,
  },
  inputContainer: {
    flexDirection: 'row',
    flexGrow: 1,
    backgroundColor: colors.white,
    borderRadius: 7,
  },
  searchIconContainer: {
    padding: 10,
    justifyContent: 'center',
    alignItems: 'center',
    width: 50,
    height: 50,
  },
  icon: {
    width: 15,
    height: 15,
    resizeMode: 'contain',
    tintColor: colors.secondaryIcon,
  },
  deleteIcon: {
    width: 20,
    height: 20,
    resizeMode: 'contain',
    tintColor: colors.mid_grey,
  },
  searchInput: {
    flexGrow: 1,
    fontSize: 16,
    fontFamily: 'Vodafone Lt',
  },
  scanContainer: {
    marginLeft: 15,
    padding: 10,
    backgroundColor: colors.white,
    justifyContent: 'center',
    alignItems: 'center',
    width: 50,
    height: 50,
    borderRadius: 7,
  },
  scanIcon: {
    width: 24,
    height: 24,
    resizeMode: 'contain',
    tintColor: colors.secondaryIcon,
  },
  plusIcon: {
    tintColor: colors.white,
    height: 19,
    width: 19,
  },
  button: {
    marginBottom: 20,
    height: 50,
  },
  buttonTitle: {
    fontSize: 18,
  },
  modal: {
    flex: 1,
    padding: 15,
  },
  modalContentContainer: {
    backgroundColor: colors.white,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 15,
    borderRadius: 7,
    overflow: 'hidden',
  },
  modalMessage: {
    margin: 15,
  },
  modalActions: {
    width: '50%',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  filterContainer: {
    flexDirection: 'row',
    marginHorizontal: 15,
    alignSelf: 'flex-start',
    backgroundColor: colors.white,
    paddingVertical: 8,
    paddingHorizontal: 15,
  },
  filterRemove: {
    width: 15,
    height: 15,
    tintColor: colors.midGrey,
    marginLeft: 15,
    marginTop: 4,
  },
})
