import React, { useContext, useEffect } from 'react'
import { SafeAreaView, ScrollView } from 'react-native'
import PropTypes from 'prop-types'
import {
  AmplifyTheme,
ChangePassword as ChangePasswordCognito} from 'aws-amplify-react-native'
import { I18n } from '@aws-amplify/core'

import { dict } from '../../Utils/Dict'
import { Header } from '../../Components'

import { useLanguage } from '../../Utils/Language'

import Theme from '../../Themes/Theme'
import images from '../../Themes/Images'

I18n.putVocabularies(dict)
I18n.setLanguage('al')

function ChangePassword({ navigation }) {
  const { colors } = useContext(Theme)
  const lang = useLanguage()

  useEffect(() => {
    I18n.setLanguage(lang)
  }, [lang])

  const signUpConfig = {
    hideAllDefaults: true,
    defaultCountryCode: '355',
    signUpFields: [
      {
        label: I18n.get('Current password *'),
        placeholder: I18n.get('Enter your current password'),
        key: 'name',
        required: true,
        displayOrder: 1,
        type: 'password',
      },
      {
        label: I18n.get('New Password *'),
        placeholder: I18n.get('Enter your new password'),
        key: 'name',
        required: true,
        displayOrder: 1,
        type: 'password',
      },
      {
        label: I18n.get('Confirm Password *'),
        placeholder: I18n.get('Confirm your new password'),
        key: 'password',
        required: true,
        displayOrder: 6,
        type: 'password',
      },
    ],
  }

  const customContainer = Object.assign({}, AmplifyTheme.container, {
    backgroundColor: colors.background,
  })
  const customsectionHeaderText = Object.assign(
    {},
    AmplifyTheme.sectionHeaderText,
    {
      color: colors.primaryText,
      fontFamily: 'Vodafone Rg',
    },
  )

  const customInput = Object.assign({}, AmplifyTheme.input, {
    borderRadius: 10,
    borderWidth: 0,
    backgroundColor: colors.tertiaryBackground,
    color: colors.primaryText,
    fontFamily: 'Vodafone Rg',
  })
  const customPhoneInput = Object.assign({}, AmplifyTheme.phoneInput, {
    color: colors.primaryText,
    fontFamily: 'Vodafone Rg',
    borderWidth: 0,
  })
  const customPhoneContainer = Object.assign({}, AmplifyTheme.phoneContainer, {
    borderRadius: 10,
    borderWidth: 0,
    backgroundColor: colors.tertiaryBackground,
    color: colors.primaryText,
    fontFamily: 'Vodafone Rg',
  })
  const customPasswordInput = Object.assign({}, AmplifyTheme.passwordInput, {
    borderRadius: 10,
    borderWidth: 0,
    backgroundColor: colors.tertiaryBackground,
    color: colors.primaryText,
    fontFamily: 'Vodafone Rg',
    height: 58,
    paddingRight: 58,
  })
  const customButton = Object.assign({}, AmplifyTheme.button, {
    backgroundColor: colors.accent,
    borderRadius: 10,
    marginVertical: 15,
    borderWidth: 0,
    color: colors.invertedText,
    fontFamily: 'Vodafone Rg',
  })
  const customButtonDisabled = Object.assign({}, AmplifyTheme.buttonDisabled, {
    backgroundColor: colors.placeholder,
    color: colors.invertedText,
    opacity: 0.5,
    borderRadius: 10,
    marginVertical: 15,
    borderWidth: 0,
    fontFamily: 'Vodafone Rg',
  })
  const customButtontext = Object.assign({}, AmplifyTheme.buttonText, {
    color: colors.invertedText,
    fontFamily: 'Vodafone Rg',
    borderWidth: 0,
  })
  const customSectionFooterLink = Object.assign(
    {},
    AmplifyTheme.sectionFooterLink,
    {
      color: colors.primaryText,
      fontFamily: 'Vodafone Rg',
    },
  )
  const customImagePicker = Object.assign({}, AmplifyTheme.pickerItem, {
    flex: 1,
    height: 80,
    borderRadius: 50,
    borderWidth: 0,
    padding: 8,
    color: colors.primaryText,
    fontSize: 14,
    fontFamily: 'Vodafone Rg',
  })
  const customPicker = Object.assign({}, AmplifyTheme.picker, {
    borderColor: colors.primaryText,
    borderWidth: 0,
    fontSize: 14,
    fontFamily: 'Vodafone Rg',
  })
  const customInputLabel = Object.assign({}, AmplifyTheme.inputLabel, {
    color: colors.primaryText,
    fontFamily: 'Vodafone Rg',
    fontSize: 14,
  })

  const errorRow = Object.assign({}, AmplifyTheme.errorRow, {
    alignItems: 'center',
  })

  const customSectionFooterLinkDisabled = Object.assign(
    {},
    AmplifyTheme.sectionFooterLinkDisabled,
    {
      color: colors.primaryText,
      fontFamily: 'Vodafone Rg',
      fontSize: 14,
    },
  )

  const customSignedOutMessage = Object.assign(
    {},
    AmplifyTheme.signedOutMessage,
    {
      color: colors.primaryText,
      fontFamily: 'Vodafone Rg',
      fontSize: 14,
    },
  )

  const AuthUiTheme = Object.assign({}, AmplifyTheme, {
    container: customContainer,
    sectionHeaderText: customsectionHeaderText,
    input: customInput,
    phoneContainer: customPhoneContainer,
    passwordInput: customPasswordInput,
    phoneInput: customPhoneInput,
    button: customButton,
    buttonText: customButtontext,
    buttonDisabled: customButtonDisabled,
    sectionFooterLink: customSectionFooterLink,
    pickerItem: customImagePicker,
    inputLabel: customInputLabel,
    picker: customPicker,
    sectionFooterLinkDisabled: customSectionFooterLinkDisabled,
    signedOutMessage: customSignedOutMessage,
    errorRow: errorRow,
  })

  return (
    // eslint-disable-next-line react-native/no-inline-styles
    <SafeAreaView style={{ flex: 1 }}>
      <Header
        title="settings_changePassword_label"
        image={images.back}
        onPress={() => navigation.goBack()}
      />
      <ScrollView>
        <ChangePasswordCognito
          theme={AuthUiTheme}
          signUpConfig={signUpConfig}
          goBack={() => navigation.goBack()}
        />
      </ScrollView>
    </SafeAreaView>
  )
}

ChangePassword.propTypes = {
  navigation: PropTypes.object,
}

export default ChangePassword
