/* eslint-disable import/no-unresolved */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useMemo, useRef } from 'react'
import {
  View,
  Platform,
} from 'react-native'
import {
  PERMISSIONS,
  RESULTS,
  check,
  request,
  openSettings,
} from 'react-native-permissions'
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs'
import { useLazyQuery, useQuery } from '@apollo/client'
import Share from 'react-native-share'
import { useTheme } from 'react-native-themed-styles'
import PropTypes from 'prop-types'
import RNFetchBlob from 'rn-fetch-blob'
import { useReactToPrint } from 'react-to-print'

import { Margin, usePDF } from 'react-to-pdf'



import {
  ErrorModal,
  Button,
  Loader,
  Tabs,
} from '../../../Components'
import { openBase64NewTab, useTemplateLanguage, printEinvoice, useConfig } from '../../../Utils'
import ContentManager from '../../../Utils/ContentManager'

import { GET_PDF, GET_MY_INVOICE } from '../EInvoiceDetails.Schema'

import images from '../../../Themes/Images'
import { colors, animations } from '../../../Themes'

import { ConvertEInvoice, ReactToPDF } from '../../../Utils/PdfTemplatesWeb'

import themedStyles from './MyEInvoiceDetails.Styles'

import {
  BasicInfo,
  Process,
  Refunds,
  Items,
  BuyerSeller,
  AdditionalDetails,
} from './Routes'

const Tab = createMaterialTopTabNavigator()

function MyEInvoiceDetails({ id, title, addListener, navigate }) {

  const { switchLocation: { deviceId, locationId } } = useConfig()

  const [styles] = useTheme(themedStyles)
  // const targetRef = useRef()
  const { openConfigurationSelector, translate: configTranslate } = useTemplateLanguage()
  const { translate } = ContentManager

  const [confirm, setConfirm] = useState(false)

  const [action, setAction] = useState({
    title: '',
    shareLoading: false,
    previewLoading: false,
  })

  const [isErrorActive, setErrorActive] = useState(false)


  const [eInvoiceData, setEInvoiceData] = useState({
    invoice: {},
    loading: true,
  })
  const [loadingDownloadWeb, setLoadingDownloadWeb] = useState(false)
  const [loadingPrintWeb, setLoadingPrintWeb] = useState(false)
  const [getPdf, {
    data: pdfData,
    loading: loadingPdf,
    error: pdfError,
  }] = useLazyQuery(GET_PDF, {
    fetchPolicy: 'network-only',
    variables: {
      eic: eInvoiceData.invoice?.note?.EIC,
    },
  })

  const { data } = useQuery(GET_MY_INVOICE, { fetchPolicy: 'network-only', variables: { id: id, locId: locationId, cashRegisterId: deviceId } })

  useEffect(() => {
    const invoiceData = pdfData?.getEinvoices?.eInvoices?.[0]
    if (invoiceData) {
      const base64File = invoiceData?.Pdf
      if (Platform.OS === 'web') {
        openBase64NewTab(base64File)
        setAction({
          title: '',
          shareLoading: false,
          previewLoading: false,
        })
      } else {
        if (action.title === 'share') {
          sharePdf(base64File)
        } else {
          saveFile(base64File)
        }
      }
    }
  }, [pdfData])

  useEffect(() => {
    if (pdfError) {
      setAction(prev => ({
        ...prev,
        shareLoading: false,
        previewLoading: false,
      }))
    }
  }, [pdfError])

  useEffect(() => {
    if (data) {
      const myInvoiceData = data?.getMyEinvoice?.myEinvoice?.ublInvoice
      let note = {}
      myInvoiceData?.note?.map((item) => {
        const itemValue = item.substring(item.indexOf('=') + 1)
        const itemKey = item.substring(0, item.indexOf('='))
        const noteitem = { [itemKey]: itemValue }
        note = { ...note, ...noteitem }
      })
      const myInvoiceMappedData = {
        basicInfo: {
          startDate: myInvoiceData?.issueDate,
          amount: myInvoiceData?.legalMonetaryTotal?.payable?.amount,
          amountCurrency: myInvoiceData?.legalMonetaryTotal?.payable?.currency,
          id: myInvoiceData?.id,
          deadLine: myInvoiceData?.dueDate,
          status: myInvoiceData?.status,
          buyerNUIS: myInvoiceData?.customerParty?.issuerNUIS,
          sellerNUIS: myInvoiceData?.supplierParty?.issuerNUIS,
          eic: note?.EIC,
          employeeEmail: myInvoiceData?.employeeEmail,
        },
        taxData: {
          taxSubtotal: myInvoiceData?.taxTotal?.taxSubtotal,
        },
        allowances: {
          allowances: myInvoiceData?.allowances,
        },
        process: {
          invoiceDocumentCode: myInvoiceData?.invoiceDocumentCode,
          invoiceProcessCode: myInvoiceData?.invoiceProcessCode,
          docCurrencyCode: myInvoiceData?.docCurrencyCode,
          dueDate: myInvoiceData?.dueDate,
          startDate: myInvoiceData?.invoicePeriod?.startDate,
          endDate: myInvoiceData?.invoicePeriod?.endDate,
          taxCurrencyCode: myInvoiceData?.invoicePeriod?.descriptionCode,
        },
        references: {
          buyerReference: myInvoiceData?.buyerReference,
          projectReference: myInvoiceData?.projectReference?.id,
          contractReference: myInvoiceData?.contractDocumentReference?.id,
          orderReference: myInvoiceData?.orderReference?.id,
          salesOrderId: myInvoiceData?.orderReference?.salesOrderId,
          additionalDocReference: myInvoiceData?.additionalDocumentReferences?.id,
          uri: myInvoiceData?.additionalDocumentReferences?.uri,
          description: myInvoiceData?.additionalDocumentReferences?.description,
          fileName: myInvoiceData?.additionalDocumentReferences?.binObj?.filename,
        },
        additionalInfo: {
          name: myInvoiceData?.delivery?.deliveryPartyName?.name,
          actualDeliveryDate: myInvoiceData?.delivery?.actualDeliveryDate,
          streetName: myInvoiceData?.delivery?.deliveryLocation?.address?.streetName,
          cityName: myInvoiceData?.delivery?.deliveryLocation?.address?.cityName,
          postalCode: myInvoiceData?.delivery?.deliveryLocation?.address?.postalCode,
          countryCode: myInvoiceData?.delivery?.deliveryLocation?.address?.countryCode,
          primaryAccountId: myInvoiceData?.paymentMeans?.card?.primaryAccountId,
          payeeFinantialAccount: myInvoiceData?.paymentMeans?.payeeFinantialAccount,
          code: myInvoiceData?.paymentMeans?.code,
          instructionNote: myInvoiceData?.paymentMeans?.instructionNote,
          buyerReference: myInvoiceData?.buyerReference,
          projectReference: myInvoiceData?.projectReference?.id,
          contractReference: myInvoiceData?.contractDocumentReference?.id,
          orderReference: myInvoiceData?.orderReference?.id,
          salesOrderId: myInvoiceData?.orderReference?.salesOrderId,
          additionalDocReference: myInvoiceData?.additionalDocumentReferences?.id,
          uri: myInvoiceData?.additionalDocumentReferences?.uri,
          description: myInvoiceData?.additionalDocumentReferences?.description,
          fileName: myInvoiceData?.additionalDocumentReferences?.binObj?.filename,
          note: myInvoiceData.note,
        },
        paymentDetails: {
          primaryAccountId: myInvoiceData?.paymentMeans?.card?.primaryAccountId,
          payeeFinantialAccount: myInvoiceData?.paymentMeans?.payeeFinantialAccount,
          code: myInvoiceData?.paymentMeans?.code,
          instructionNote: myInvoiceData?.paymentMeans?.instructionNote,
        },
        total: {
          lineExtension: myInvoiceData?.legalMonetaryTotal?.lineExtension?.amount,
          lineExtensionCurrency: myInvoiceData?.legalMonetaryTotal?.lineExtension?.currency,
          allowance: myInvoiceData?.legalMonetaryTotal?.allowance?.amount,
          allowanceCurrency: myInvoiceData?.legalMonetaryTotal?.allowance?.currency,
          taxExclusive: myInvoiceData?.legalMonetaryTotal?.taxExclusive?.amount,
          taxExclusiveCurrency: myInvoiceData?.legalMonetaryTotal?.taxExclusive?.currency,
          taxAmount: myInvoiceData?.taxTotal?.taxAmount?.amount,
          taxAmountCurrency: myInvoiceData?.taxTotal?.taxAmount?.currency,
          taxInclusive: myInvoiceData?.legalMonetaryTotal?.taxInclusive?.amount,
          taxInclusiveCurrency: myInvoiceData?.legalMonetaryTotal?.taxInclusive?.currency,
          payable: myInvoiceData?.legalMonetaryTotal?.payable?.amount,
          payableCurrency: myInvoiceData?.legalMonetaryTotal?.payable?.currency,
        },
        invoiceLine: myInvoiceData?.invoiceLine,
        supplierParty: myInvoiceData?.supplierParty,
        customerParty: myInvoiceData?.customerParty,
      }
      setEInvoiceData({
        invoice: { ...myInvoiceMappedData, note },
        loading: false,
      })
    }
  }, [data])

  useEffect(() => {
    const deleteFiles = addListener('beforeRemove', () => {
      if (Platform.OS === 'ios') {
        const path = `${RNFetchBlob.fs.dirs.DocumentDir}/${title.replace(/[/]/g, '_')}.pdf`
        RNFetchBlob.fs.exists(path)
          .then((res) => {
            if (res) {
              RNFetchBlob.fs.unlink(path)
            }
          })
          .catch(() => { })
      }
    })
    return deleteFiles
  }, [])

  const sharePdf = (base64) => {
    Share.open({
      url: `data:application/pdf;base64,${base64}`,
    })
      .catch(() => setAction({
        title: '',
        shareLoading: false,
        previewLoading: false,
      }))
    setAction({
      title: '',
      shareLoading: false,
      previewLoading: false,
    })
  }

  const previewPdf = (base64) => {
    const { dirs } = RNFetchBlob.fs
    const dirToSave = Platform.OS === 'ios' ? dirs.DocumentDir : dirs.DownloadDir
    const configfb = {
      fileCache: true,
      useDownloadManager: true,
      notification: true,
      mediaScannable: true,
      title: `${title.replace(/[/]/g, '_')}.pdf`,
      path: `${dirToSave}/${title.replace(/[/]/g, '_')}.pdf`,
    }
    const configOptions = Platform.select({
      ios: {
        fileCache: configfb.fileCache,
        title: configfb.title,
        path: configfb.path,
        appendExt: 'pdf',
      },
      android: configfb,
    })

    RNFetchBlob.config(configOptions)
    RNFetchBlob.fs.writeFile(configfb.path, base64, 'base64')

    if (Platform.OS === 'ios') {
      RNFetchBlob.ios.openDocument(configfb.path)
    } else {
      RNFetchBlob.android.actionViewIntent(configfb.path, 'application/pdf')
      RNFetchBlob.android.addCompleteDownload({
        title: `${title.replace(/[/]/g, '_')}.pdf`,
        description: 'Download complete',
        mime: 'application/pdf',
        path: configfb.path,
        showNotification: true,
      })
    }
  }

  const saveFile = async (base64) => {
    try {
      check(
        Platform.select({
          ios: PERMISSIONS.IOS.MEDIA_LIBRARY,
          android: PERMISSIONS.ANDROID.WRITE_EXTERNAL_STORAGE,
        }),
      )
        .then((result) => {
          setAction({
            title: '',
            shareLoading: false,
            previewLoading: false,
          })
          switch (result) {
            case RESULTS.DENIED:
              request(
                Platform.select({
                  ios: PERMISSIONS.IOS.MEDIA_LIBRARY,
                  android: PERMISSIONS.ANDROID.WRITE_EXTERNAL_STORAGE,
                }),
              ).then((res) => {
                if (res === RESULTS.GRANTED) {
                  previewPdf(base64)
                }
              })
              break
            case RESULTS.LIMITED:
              break
            case RESULTS.GRANTED:
              previewPdf(base64)
              break
            case RESULTS.BLOCKED:
              openSettings()
              break
          }
        })
        .catch(() => setAction({
          title: '',
          shareLoading: false,
          previewLoading: false,
        }))
    } catch {
      setAction({
        title: '',
        shareLoading: false,
        previewLoading: false,
      })
    }
  }

  const renderMyTabs = useMemo(() => {
    return (
      <Tabs
        initialRouteName={translate('einvoice_details_info_tab')}
        style={styles.tabView}
        screenOptions={{
          tabBarItemStyle: {
            width: 'auto',
            minWidth: Platform.OS === 'web' ? 300 : 100,
          },
          tabBarScrollEnabled: true,
        }}
      >
        <Tab.Screen key={'tab-info'} name={translate('einvoice_details_info_tab')}>
          {(props) => (
            <BasicInfo
              {...props}
              eInvoiceData={eInvoiceData.invoice.basicInfo}
              id={id}
              loading={eInvoiceData.loading}
              note={eInvoiceData.invoice.note}
              total={eInvoiceData.invoice.total}
              taxData={eInvoiceData.invoice.taxData}
              allowances={eInvoiceData.invoice.allowances}
            />
          )}
        </Tab.Screen>
        <Tab.Screen key={'tab-refunds'} name={translate('einvoice_details_refunds_tab')}>
          {(props) => (
            <Refunds {...props} eInvoiceData={{}} id={id} loading={eInvoiceData.loading} navigate={navigate} />
          )}
        </Tab.Screen>
        <Tab.Screen key={'tab-items'} name={translate('einvoice_preview_items_tab')}>
          {(props) => (
            <Items {...props} data={eInvoiceData?.invoice?.invoiceLine} loading={eInvoiceData.loading} />
          )}
        </Tab.Screen>
        <Tab.Screen key={'tab-process'} name={translate('einvoice_preview_process_tab')}>
          {(props) => (
            <Process {...props} eInvoiceData={eInvoiceData?.invoice?.process} id={id} loading={eInvoiceData.loading} />
          )}
        </Tab.Screen>
        <Tab.Screen key={'BuyerSeller'} name={translate('einvoice_preview_buyerseller_tab')} >
          {(props) => (
            <BuyerSeller {...props} buyer={eInvoiceData?.invoice?.customerParty} seller={eInvoiceData.invoice?.supplierParty} />
          )}
        </Tab.Screen>
        <Tab.Screen key={'tab-additionalDet'} name={translate('einvoice_preview_additionalDet_tab')}>
          {(props) => (
            <AdditionalDetails {...props} eInvoiceData={eInvoiceData?.invoice?.additionalInfo} id={id} loading={eInvoiceData.loading} />
          )}
        </Tab.Screen>
      </Tabs>
    )
  }, [eInvoiceData.loading])

  const getPdfData = (newAction) => {
    if (newAction === 'share') {
      setAction(prev => ({
        ...prev,
        shareLoading: true,
        title: 'share',
      }))
    } else {
      setAction(prev => ({
        ...prev,
        previewLoading: true,
        title: 'preview',
      }))
    }
    getPdf()
  }

  const { toPDF, targetRef } = usePDF({
    method: 'save',
    filename: `${eInvoiceData?.invoice?.basicInfo?.id}.pdf`,
    page: { margin: Margin.NONE },
  })

  const handlePrintWeb = useReactToPrint({
    content: () => targetRef.current,
    onBeforePrint: () => setLoadingPrintWeb(true),
    onAfterPrint: () => setLoadingPrintWeb(false),
  })
  const onHandlePrintWeb = () => {
    openConfigurationSelector(false, handlePrintWeb)
  }

  const handleDownload = async () => {
    setLoadingDownloadWeb(true)
    await toPDF()
    setLoadingDownloadWeb(false)
  }

  const activateConfirm = () => {
    if (confirm) {
      printEinvoice({
        eInvoiceData: eInvoiceData.invoice,
        translate: configTranslate,
      }
      ).catch((e) => {
        setErrorActive(true)
        throw e
      })
      setConfirm(false)
    } else {
      openConfigurationSelector(false, () => setConfirm(true))
    }
  }

  const buttonProps = {
    backgroundColor: {
      true: colors.secondaryAccent,
      false: colors.buttonSecondaryAccent,
    },
    foregroundColor: {
      true: colors.invertedText,
      false: colors.primaryText,
    },
    text: {
      true: 'receipt_button_confirm',
      false: 'receipt_button_print_receipt',
    },
    iconColor: {
      true: colors.tertiaryIcon,
      false: colors.secondaryAccent,
    },
  }

  return (
    <>
      <View>
        <View style={styles.inlineButtons}>
          <Button
            title="receipt_button_issue_refund"
            titleProps={{ size: 'footnote' }}
            variant={eInvoiceData.loading ? 'disabled' : 'preActive'}
            color={eInvoiceData.loading ? colors.white : colors.primaryText}
            borderColor={colors.transparent}
            icon={images.refundedInvoice}
            iconColor={eInvoiceData.loading ? colors.white : colors.secondaryIcon}
            iconStyle={{ transform: [{ rotate: '90deg' }] }}
            style={styles.refundButton}
            onPress={() => navigate('RefundEinvoice', {
              primaryEinvoiceId: id,
              id: title,
            })}
          />
          {Platform.OS !== 'web' && <Button
            title={buttonProps.text[confirm]}
            variant={eInvoiceData.loading ? 'disabled' : 'preActive'}
            disabled={eInvoiceData.loading}
            // titleStyle={{ color: eInvoiceData.loading || action.perviewLoading ? colors.white : colors.primaryText }}
            titleProps={{ size: 'footnote' }}
            style={styles.printButton}
            onPress={() => activateConfirm()}
            icon={!confirm && images.printerActive}
            iconColor={eInvoiceData.loading ? colors.white : colors.secondaryIcon}
            loaderComponent={<Loader source={animations.vfLoaderWhite} size={24} />}
            iconStyle={
              confirm ? { tintColor: buttonProps.iconColor[confirm] } : null
            }
            backgroundColor={confirm ? buttonProps.backgroundColor[confirm] : null}
            color={eInvoiceData.loading ? colors.white : confirm ? buttonProps.foregroundColor[confirm] : colors.primaryText}
          />}
          <Button
            variant={eInvoiceData.loading || action.shareLoading || loadingPrintWeb ? 'disabled' : 'preActive'}
            disabled={loadingPdf || eInvoiceData.loading || loadingPrintWeb}
            title={Platform.OS === 'web' ? 'receipt_button_print_receipt' : 'orders_button_share_receipt'}
            style={styles.inlineButton1}
            titleProps={{ size: 'footnote' }}
            onPress={Platform.OS === 'web' ? onHandlePrintWeb : () => getPdfData('share')}
            icon={Platform.OS === 'web' ? images.printerActive : images.share}
            titleStyle={{ color: eInvoiceData.loading || action.shareLoading ? colors.white : colors.primaryText }}
            iconColor={eInvoiceData.loading || action.shareLoading ? colors.white : colors.secondaryIcon}
            loader={action.shareLoading || loadingPrintWeb}
            loaderComponent={<Loader source={animations.vfLoaderWhite} size={24} />}
          />

          {Platform.OS === 'web' ?

            <Button
              variant={eInvoiceData.loading || action.previewLoading || loadingDownloadWeb ? 'disabled' : 'preActive'}
              title="receipt_download_as_pdf"
              style={styles.inlineButton2}
              onPress={() => {
                openConfigurationSelector(true, handleDownload)
              }}
              icon={images.download}
              titleStyle={{ color: eInvoiceData.loading || action.previewLoading || loadingDownloadWeb ? colors.white : colors.secondaryAccent }}
              iconColor={eInvoiceData.loading || action.previewLoading || loadingDownloadWeb ? colors.white : colors.secondaryAccent}
              loader={action.previewLoading || loadingDownloadWeb}
              loaderComponent={<Loader source={animations.vfLoaderWhite} size={24} />}
            />


            :
            <Button
              variant={eInvoiceData.loading || action.previewLoading ? 'disabled' : 'preActive'}
              title={'einvoice_preview_button'}
              disabled={loadingPdf || eInvoiceData.loading}
              style={styles.inlineButton2}
              onPress={() => getPdfData('preview')}
              icon={images.previewDoc}
              loader={action.previewLoading}
              titleStyle={{ color: eInvoiceData.loading || action.previewLoading ? colors.white : colors.primaryText }}
              titleProps={{ size: 'footnote' }}
              iconColor={eInvoiceData.loading || action.previewLoading ? colors.white : colors.secondaryIcon}
              loaderComponent={<Loader source={animations.vfLoaderWhite} size={24} />}
            />
          }
          {/* <Button
            variant={'disabled'}
            title="Re-order"
            disabled={loadingPdf || eInvoiceData.loading}
            style={styles.inlineButton3}
            onPress={() => resendInvoice()}
            icon={images.retry}
            titleStyle={{ color: eInvoiceData.loading || action.previewLoading ? colors.white : colors.primaryText }}
            iconColor={eInvoiceData.loading || action.previewLoading ? colors.white : colors.secondaryIcon}
            loader={action.previewLoading}
            loaderComponent={<Loader source={animations.vfLoaderWhite} size={24} />}
          /> */}
        </View>
      </View>
      {renderMyTabs}
      {Platform.OS === 'web' && Object.keys(eInvoiceData.invoice).length > 0 &&
        <div style={styles.hideItem}>
          <ConvertEInvoice
            Einvoice={eInvoiceData.invoice}
            buyer={eInvoiceData?.invoice?.customerParty}
            seller={eInvoiceData.invoice?.supplierParty}
            ref={targetRef}
          />
        </div>
      }
      <ErrorModal
        isVisible={isErrorActive}
        icon={images.error}
        title="receipt_printer_error"
        description="receipt_printer_error_label"
        primaryText="receipt_open_printers_button"
        primaryAction={() => {
          setErrorActive(false)
          navigate('PrinterSetUp')
        }}
        secondaryText="checkout_button_skip"
        secondaryAction={() => setErrorActive(false)}
        onBackdropPress={() => setErrorActive(false)}
        onBackButtonPress={() => setErrorActive(false)}
        onClosePress={() => setErrorActive(false)}
      />

    </>
  )
}
MyEInvoiceDetails.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  status: PropTypes.string,
  addListener: PropTypes.func,
  navigate: PropTypes.func,
}

export default MyEInvoiceDetails

