import React from 'react'
import PropTypes from 'prop-types'
import { View } from 'react-native'


function ShadowView(props) {
  const { children, ...otherProps } = props
  return (
    <View {...otherProps}>
      {children}
    </View>
  )
}
ShadowView.propTypes = {
  children: PropTypes.node,
}

export default ShadowView
