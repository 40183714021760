import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { useTheme } from 'react-native-themed-styles'

import Text from '../Text'
import Modal from '../Modal'
import Icon from '../Icon'
import Button from '../Button'

import ModalContent from '../ModalContent'

import Theme from '../../Themes/Theme'
import themedstyles from './ErrorModal.Styles'

function ErrorModal(props) {
  const [styles] = useTheme(themedstyles)
  const { colors } = useContext(Theme)

  const {
    isVisible,
    onClosePress,
    icon,
    title,
    description,
    placeHolders,
    primaryText,
    secondaryText,
    tertiaryText,
    primaryAction,
    secondaryAction,
    tertiaryAction,
  } = props

  return (
    <Modal
      isVisible={isVisible}
      animationIn="fadeIn"
      animationOut="fadeOut"
      style={styles.modal}>
      <ModalContent
        onClose={onClosePress}
        contentContainerStyle={styles.bottomSpacing}>
        {Boolean(icon) && <Icon source={icon} size={40} />}
        <Text
          i18nKey={title}
          align="center"
          size="h4"
          weight="bold"
          color={colors.accent}
          style={styles.modalTitle}
        />
        <Text
          i18nKey={description}
          placeHolders={placeHolders}
          align="center"
          style={styles.modalDesc}
        />
        {primaryAction && (
          <Button
            title={primaryText}
            variant={'active'}
            style={styles.primaryButton}
            onPress={primaryAction}
          />
        )}
        {secondaryAction && (
          <Button
            title={secondaryText}
            variant={'outline'}
            style={styles.tertiaryButton}
            onPress={secondaryAction}
          />
        )}
        {tertiaryAction && (
          <Button
            title={tertiaryText}
            variant={'text'}
            style={styles.secondaryButton}
            onPress={tertiaryAction}
          />
        )}
      </ModalContent>
    </Modal>
  )
}

ErrorModal.defaultProps = {
  isVisible: true,
  // icon: images.warningIcon,
}

ErrorModal.propTypes = {
  isVisible: PropTypes.bool,
  onClosePress: PropTypes.func,
  icon: PropTypes.any,
  title: PropTypes.string,
  description: PropTypes.string,
  primaryText: PropTypes.string,
  secondaryText: PropTypes.string,
  tertiaryText: PropTypes.string,
  primaryAction: PropTypes.func,
  secondaryAction: PropTypes.func,
  tertiaryAction: PropTypes.func,
  placeHolders: PropTypes.arrayOf(PropTypes.string),
}

export default ErrorModal
