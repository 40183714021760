import { styleSheetFactory } from '../../Themes/Colors'

const styles = styleSheetFactory(colors => ({
  container: {
    position: 'relative',
  },
  background: {
    position: 'absolute',
    backgroundColor: colors.tertiaryBackground,
    width: 14,
    height: 14,
    left: 9,
    top: 9,
    borderRadius: 2,
  },
  selectedtrue: {
    backgroundColor: colors.white,
  },
  defaultTintColorsDisabled_false: {
    true: colors.secondaryAccent,
    false: colors.placeholder,
  },
  defaultTintColorsDisabled_true: {
    true: colors.placeholder,
    false: colors.placeholder,
  },
}))

export default styles
