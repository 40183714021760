import { styleSheetFactory } from '../../Themes/Colors'


const styles = styleSheetFactory(colors => ({
    container: {
        flex: 1,
        paddingBottom: 20,
    },
    textContainer: {
        flexGrow: 1,
        flexDirection: 'column',
        justifyContent: 'center',
        marginRight: 15,
    },
    inputContainer: {
        minHeight: 60,
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
    },
    itemSpacing: {
        marginVertical: 7.5,
    },
    subContainer: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        padding: 15,
    },
    subFileContainer: {
        flex: 1,
        alignItems: 'center',
        padding: 15,
    },
    fileItemWrapper: {
        backgroundColor: colors.tertiaryBackground,
        overflow: 'hidden',
        borderRadius: 7,
        marginTop: 10,
        borderColor: colors.separator,
        borderWidth: 1,
    },
    input: {
        height: 35,
        includeFontPadding: false,
        textAlignVertical: 'center',
        color: colors.primaryText,
        fontSize: 16,
        fontFamily: 'Vodafone Lt',
        padding: 0,
    },
    input1: {
        flexShrink: 1,
        height: 40,
        textAlign: 'left',
        includeFontPadding: false,
        textAlignVertical: 'center',
        color: colors.primaryText,
        fontSize: 16,
        fontFamily: 'Vodafone Rg',
        paddingRight: 2,
        minWidth: 80,
    },
    clearContainer: {
        margin: 6.5,
        justifyContent: 'center',
        alignItems: 'center',
        width: 30,
        height: 30,
        borderRadius: 20,
        backgroundColor: colors.background,
        flexShrink: 0,
    },
    codeFieldRoot: {
        marginTop: 20,
        marginBottom: 30,
    },
    cell: {
        borderRadius: 7,
        width: 40,
        height: 40,
        lineHeight: 35,
        fontSize: 24,
        color: colors.primaryText,
        borderWidth: 2,
        borderColor: '#00000030',
        textAlign: 'center',
    },
    focusCell: {
        borderColor: '#000',
    },
    codeConfirm: {
        borderRadius: 7,
        marginTop: 30,
        backgroundColor: colors.white,
        paddingHorizontal: 40,
        paddingVertical: 20,
        justifyContent: 'center',
        alignItems: 'center',
    },

    // Modal
    modalContent: {
        margin: 15,
        padding: 30,
        borderRadius: 7,
        backgroundColor: colors.tertiaryBackground,
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
        paddingTop: 45,
        overflow: 'hidden',
    },
    modalCloseContainer: {
        position: 'absolute',
        top: 0,
        right: 0,
        height: 30,
        width: 75,
        borderTopRightRadius: 7,
        borderBottomLeftRadius: 7,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: colors.separator,
    },
    modalTitle: {
        marginVertical: 10,
    },
    modalDesc: {
        marginBottom: 15,
    },
    modalPrimaryButton: {
        width: '100%',
        marginVertical: 15,
    },
    modalSecondaryButton: {
        width: '100%',
    },

    fillRemaining: {
        flexGrow: 1,
    },
    initContainer: {
        flexGrow: 1,
        marginHorizontal: 15,
        justifyContent: 'space-around',
    },
    center: {
        alignItems: 'center',
    },
    animationContainer: {
        width: 300,
        height: 300,
        justifyContent: 'center',
        alignItems: 'center',
    },
    containerButtonIOS: {
        marginBottom: 4,
        marginRight: 5,
        fontFamily: 'Vodefone Lt',
        fontSize: 50,
        color: 'red',
    },
    containerButtonAndroid: {
        marginBottom: 8,
        fontFamily: 'Vodefone Lt',
        fontSize: 50,
        color: 'red',
    },
    inputContainer1: {
        flexDirection: 'row',
        marginBottom: 0,
        minHeight: 60,
        paddingLeft: 15,
        alignItems: 'center',
        backgroundColor: colors.tertiaryBackground,
        borderRadius: 7,
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: colors.tertiaryBackground,
        justifyContent: 'space-between',
    },
    inputValue: {
        flexGrow: 1,
        flexShrink: 1,
        marginLeft: 5,
        height: 60,
        textAlign: 'right',
        includeFontPadding: false,
        textAlignVertical: 'center',
        color: colors.primaryText,
        fontSize: 16,
        fontFamily: 'Vodafone Lt',
        paddingRight: 15,
    },
    selfSpacing: { padding: 15 },
    spacing: { padding: 20 },
    verticalSelfSpacing: { paddingVertical: 10 },
    iconContainer: {
        backgroundColor: colors.background,
        margin: 5,
        borderRadius: 7,
    },
    attachedItemContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        padding: 15,
        alignItems: 'center',
    },
    leftContainer: {
        flexGrow: 1,
        flexShrink: 1,
        flexDirection: 'row',
        alignItems: 'center',
        marginRight: 60,
    },
    rightSpacing: { marginRight: 10 },
    scrollView: { paddingHorizontal: 15, marginTop: 15 },
    bottomSpacing: { marginBottom: 20 },
    mediumBottomSpacing: { marginBottom: 15 },
    underlinedText: { textDecorationLine: 'underline' },
}))

export default styles
