import { styleSheetFactory } from '../../Themes/Colors'

const styles = styleSheetFactory(colors => ({
  container: {
    flexGrow: 1,
    flex: 1,
    backgroundColor: colors.background,
  },
  viewPager: {
    flexGrow: 1,
    flexShrink: 1,
  },
  indicatorContainer: {
    width: '100%',
    height: 20,
    marginVertical: 12,
    alignContent: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    flexShrink: 0,
  },
  indicator_false: {
    width: 10,
    height: 10,
    backgroundColor: colors.disabled,
    borderRadius: 10,
    marginHorizontal: 7.5,
  },
  indicator_true: {
    width: 18,
    height: 10,
    backgroundColor: colors.accent,
    borderRadius: 10,
    marginHorizontal: 7.5,
  },
  firstIndicator: {
    display: 'none',
  },
}))

export default styles
