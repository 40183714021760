import { gql } from '@apollo/client'

export const CREATE_MEASUREMENT_UNIT = gql`
  mutation createMeasurementUnit($name: String!, $abbreviation: String!, $precision: Int,$locationId:String) {
    createMeasurementUnit(measurementUnitInput:{
      precision: $precision,
      abbreviation: $abbreviation,
      name: $name,
    }
    locationId:$locationId
    ){
      id,
      precision,
      abbreviation,
      name,
    }
  }
`

export const EDIT_MEASUREMENT_UNIT = gql`
  mutation updateMeasurementUnit($precision: Int, $id: String!,$locationId:String) {
    updateMeasurementUnit(measurementUnitUpdateInput:{
      id: $id,
      precision: $precision,
    }
    locationId:$locationId
    ){
      id,
      precision,
    }
  }
`
