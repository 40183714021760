import { Dimensions } from 'react-native'

import { styleSheetFactory } from '../../Themes/Colors'

const { width } = Dimensions.get('screen')

const styles = styleSheetFactory(colors => ({
  container: {
    flex: 1,
    position: 'relative',
    backgroundColor: colors.background,
  },
  header: {
    height: 56,
    alignItems: 'center',
    flexDirection: 'row',
    backgroundColor: colors.background,
  },
  headerIcon: {
    marginHorizontal: 20,
  },
  sectionTitle: {
    marginHorizontal: 15,
  },
  topMenuContainer: {
    flexDirection: 'row',
    position: 'absolute',
    top: 56,
    left: 0,
    right: 0,
    margin: 15,
    marginBottom: 0,
  },
  scene: {
    flex: 1,
    backgroundColor: colors.background,
  },
  tabContainerStyle: {
    backgroundColor: colors.tertiaryBackground,
    elevation: 0,
    shadowRadius: 0,
    shadowOpacity: 0,
    marginBottom: 0,
  },
  indicatorStyle: {
    backgroundColor: colors.tabAccent,
  },
  label: {
    fontFamily: 'Vodafone Rg',
    textTransform: 'none',
  },
  tabView: {
    backgroundColor: colors.background,
    paddingTop: 80,
    width: width,
  },
  listContainerStyle: {
    paddingBottom: 80,
  },
  listStyle: {
    marginHorizontal: 0,
    marginTop: 10,
  },
  listSeparatorStyle: {
    height: 1,
    backgroundColor: colors.separator,
    // marginLeft: 70,
  },
  searchContainer: {
    flexGrow: 1,
    flexDirection: 'row',
    backgroundColor: colors.tertiaryBackground,
    borderRadius: 7,
    overflow: 'hidden',
    alignItems: 'center',
  },
  searchIcon: {
    marginHorizontal: 10,
  },
  searchInput: {
    flexGrow: 1,
    paddingLeft: 0,
    fontSize: 12,
    lineHeight: 14,
    color: colors.primaryText,
  },
  scan: {
    height: 50,
    width: 50,
    backgroundColor: 'white',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: 15,
    borderRadius: 7,
  },
  bottomMenuContainer: {
    backgroundColor: colors.background,
    borderTopLeftRadius: 7,
    borderTopRightRadius: 7,
  },
  shadow: {
    //shadow
    shadowColor: '#000000',
    shadowOffset: {
      width: 0,
      height: -1,
    },
    shadowOpacity: 0.16,
    shadowRadius: 6,
  },
  bottomMenu: {
    flexDirection: 'row',
    paddingVertical: 15,
    paddingRight: 15,
  },
  separator: {
    height: 1,
    backgroundColor: colors.separator,
    marginTop: 10,
  },
  chargeButton: {
    height: 50,
    margin: 15,
  },

  //
  loadingContainer: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    backgroundColor: colors.blackRGBA(0.5),
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1,
  },

  //
  modalContent: {
    marginHorizontal: 30,
    marginBottom: 15,
  },
  loaderContainer: {
    marginBottom: 32,
  },
  modalTitle: {
    marginVertical: 10,
  },
  modalPrimaryButton: {
    width: '100%',
    marginTop: 30,
    marginVertical: 15,
  },
  modalSecondaryButton: {
    width: '100%',
    marginBottom: 15,
  },
  modalTertiaryButton: {
    width: '100%',
  },
}))

const itemStyles = styleSheetFactory(colors => ({
  wrapper: {
    backgroundColor: colors.tertiaryBackground,
    overflow: 'hidden',
  },
  container: {
    flex: 1,
    backgroundColor: colors.tertiaryBackground,
    flexDirection: 'row',
    alignItems: 'center',
    flexGrow: 1,
    height: 70,
  },
  firstItem_true: {
    borderTopLeftRadius: 7,
    borderTopRightRadius: 7,
  },
  lastItem_true: {
    borderBottomLeftRadius: 7,
    borderBottomRightRadius: 7,
  },
  infoContainer: {
    flexGrow: 1,
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
    paddingHorizontal: 15,
    minHeight: 70,
  },
  leftContainer: {
    flexGrow: 1,
    flexShrink: 1,
  },
  rightContainer: {
    flexShrink: 0,
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
}))


const hiddenItemStyles = styleSheetFactory(colors => ({
  wrapper: {
    backgroundColor: colors.accent,
    flex: 1,
    justifyContent: 'center',
    alignItems: 'flex-end',
  },
  firstItem_true: {
    borderTopLeftRadius: 7.5,
    borderTopRightRadius: 7.5,
  },
  lastItem_true: {
    borderBottomLeftRadius: 7.5,
    borderBottomRightRadius: 7.5,
  },
  option: {
    width: 100,
    height: 70,
    justifyContent: 'center',
    alignItems: 'center',
  },
  optionFull: {
    flexDirection: 'row',
  },
  confirm: {
    flexGrow: 1,
    height: 70,
    flexDirection: 'row',
    paddingLeft: 15,
    alignItems: 'center',
  },
  confirmText: {
    marginLeft: 15,
  },
  cancel: {
    width: 40,
    height: 40,
    flexShrink: 0,
    marginVertical: 10,
    marginHorizontal: 15,
    borderRadius: 20,
    backgroundColor: colors.whiteRGBA(0.55),
    justifyContent: 'center',
    alignItems: 'center',
  },
  loader: {
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
}))


export { itemStyles, hiddenItemStyles }
export default styles
