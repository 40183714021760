import { gql } from '@apollo/client'

export const UPLOAD_BULK = gql`
mutation bulkUpload($forceUpload:Boolean!,$locationAddress:String!,$uploadTransactionId:String!,$uploadType:String!,$languageCode:String!,$locationId:String){
    bulkUpload(
        bulkUploadInput:{
            forceUpload:$forceUpload
            locationAddress:$locationAddress
            uploadTransactionId:$uploadTransactionId
            uploadType:$uploadType
            languageCode:$languageCode
            locationId:$locationId
        }
        ){
    status
    uploadTransactionId
    }
}`

export const UPDATE_STATUS = gql`
mutation updateBulkUploadStatus($locationAddress: String!, $newStatus: String!, $transactionId: String!, $uploadType: String!,$languageCode:String!,$locationId:String){
  updateBulkUploadStatus(
    updateBulkUploadStatusInput:{
         newStatus:$newStatus
            locationAddress:$locationAddress
            transactionId:$transactionId
            uploadType:$uploadType
            languageCode:$languageCode
            locationId:$locationId
    }
  ){
    status
  }
}


`

export const CHECK_UPLOAD_STATUS = gql`
query queryBulkUploadStatus($locationAddress:String!,$uploadTransactionId: String!, $uploadType: String!,$languageCode:String!,$locationId:String){
    queryBulkUploadStatus(
        locationAddress:$locationAddress
        uploadTransactionId: $uploadTransactionId
        uploadType: $uploadType
        languageCode:$languageCode
        locationId:$locationId
    ){
    status
    uploadType
    details {
    failed {
      label
      number
    }
    processed {
      label
      number
    }
    rows {
      label
      number
    }
    }
    }
}


`
