import { styleSheetFactory } from '../../Themes/Colors'

const styles = styleSheetFactory(colors => ({
  container: {
    backgroundColor: colors.white,
    position: 'relative',
    borderRadius: 5,
    borderColor: colors.white,
    borderWidth: 1,
    flexDirection: 'column',
    paddingHorizontal: 15,
    paddingTop: 40,
    paddingBottom: 15,
    shadowColor: colors.primaryText,
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.2,
    shadowRadius: 6,
    elevation: 3,
    maxWidth: 800,
    alignSelf: 'center',
    width: '100%',
  },
  iconWrapper: {
    position: 'absolute',
    top: -25,
    left: '50%',
    marginLeft: -23,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: colors.white,
    width: 76,
    height: 76,
    borderRadius: 50,
    shadowColor: colors.disabled,
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.7,
    shadowRadius: 5,
    elevation: 3,
    alignSelf: 'center',
  },
}))

export default styles
