import { styleSheetFactory } from '../../Themes/Colors'

const styles = styleSheetFactory((colors) => ({
    wrapper: {
        flex: 1,
    },
    container: {
        flexGrow: 1,
        // paddingHorizontal: 15,
    },
    itemContainer: {
        flexGrow: 1,
        minWidth: 100,
        borderColor: colors.disabled,
        borderWidth: 1,
        borderRadius: 7,
        margin: 5,
        justifyContent: 'center',
        alignItems: 'center',
    },
    suggestionContainer: {
        flexGrow: 1,
        borderColor: colors.disabled,
        borderWidth: 1,
        borderRadius: 7,
        margin: 5,
        justifyContent: 'center',
        alignItems: 'center',
        paddingHorizontal: 3,
    },
    itemContainerSelected_true: {
        borderWidth: 0,
        backgroundColor: colors.secondaryAccent,
    },
    touchable: {
        minWidth: 120,
        flexGrow: 1,
        paddingHorizontal: 25,
        paddingVertical: 15,
        justifyContent: 'center',
        alignItems: 'center',
    },
    suggestionTouchable: {
        flexGrow: 1,
        padding: 5,
        justifyContent: 'center',
        alignItems: 'center',
    },
    iconSelected_false: {
        tintColor: colors.secondaryText,
    },
    iconSelected_true: {
        tintColor: colors.white,
    },
    titleSelected_false: {
        color: colors.secondaryText,
    },
    titleSelected_true: {
        color: colors.white,
    },
    listStyle: {
        flexGrow: 1,
        paddingHorizontal: 15,
    },
    listContainerStyle: {
        flexGrow: 1,
        marginTop: 5,
        marginBottom: 15,
    },
    button: {
        flexGrow: 1,
        margin: 15,
        marginTop: 10,
    },
    buttonWrapper: {
        flexGrow: 1,
        flexDirection: 'row',
        alignItems: 'center',
        marginHorizontal: 15,

    },
    leftButton: {
        flex: 1,
        marginRight: 5,
        marginBottom: 0,
    },
    rightButton: {
        flex: 1,
        marginLeft: 5,
        marginBottom: 0,
    },
    shadow: {
        backgroundColor: colors.background,
        margin: 15,
        shadowColor: colors.black,
        shadowOffset: {
            width: 0,
            height: 8,
        },
        shadowOpacity: 0.16,
        shadowRadius: 6,
        borderRadius: 6,
    },
    reportWrapper: {
        backgroundColor: colors.white,
        paddingHorizontal: 15,
        borderRadius: 6,
    },
    reportButtonWrapper: {
        flexDirection: 'row',
        justifyContent: 'space-around',
        alignItems: 'center',
    },
    reportOptions: {
        flexDirection: 'row',
        alignItems: 'center',
        flex: 1,
        padding: 15,
        justifyContent: 'center',
    },
    chargeButton: {
        height: 50,
        margin: 15,
    },
    textBlock: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingHorizontal: 15,
    },
    removeBuyer: {
        borderRadius: 18,
        padding: 10,
        backgroundColor: colors.separatorBody,
    },
    cardHeader: {
        borderBottomColor: colors.separator,
        borderBottomWidth: 1,
        flexDirection: 'row',
        alignItems: 'center',
        padding: 10,
    },
    separator: {
        height: 38,
        width: 1,
        backgroundColor: colors.separator,
    },
    reportsWrapper: {
        paddingBottom: 40,
    },
    resetFilter: {
        borderRadius: 17,
        padding: 6,
        paddingHorizontal: 12,
        flexDirection: 'row',
        alignItems: 'center',
        backgroundColor: colors.separatorBody,
    },
    dateNote: {
        marginLeft: 15,
    },
    callRenderer: { flex: 1 },
    sectionTitle: { padding: 15, paddingBottom: 5 } ,
    listTitle: { padding: 15, paddingBottom: 0 },
    textLineHeight: { lineHeight: 16 },
    leftSpacing: { marginLeft: 3 },
    horizontalSpacing: { paddingHorizontal: 15 },
    loaderContainer: { backgroundColor: colors.white, borderRadius: 7, marginBottom: 15 },
    secondLoader: { backgroundColor: colors.white, borderRadius: 7 },
    rightMediumSpacing: { marginRight: 10 },
    rightSpacing: { marginRight: 5 },
}))

export default styles
