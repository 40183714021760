import React, { useContext, useState, useEffect } from 'react'
import { SafeAreaView, View, TouchableOpacity, FlatList } from 'react-native'
import { useTheme } from 'react-native-themed-styles'
import PropTypes from 'prop-types'

import { Text, Header, Switch } from '../../Components'
import { useConfig } from '../../Utils/Config'

import Theme from '../../Themes/Theme'
import themedStyles, { itemStyles, separatorStyles } from './SoundAndVibration.Styles'
import images from '../../Themes/Images'

function Item({ name, enabled, onPress, isFirst, isLast }) {
  const [styles] = useTheme(itemStyles)
  const { colors } = useContext(Theme)

  return <View style={[styles.container, styles['firstItem_' + isFirst], styles['lastItem_' + isLast]]}>
    <TouchableOpacity onPress={onPress} style={styles.touchable} activeOpacity={0.5}>
      <View style={styles.textContainer}>
        <Text i18nKey={name} numberOfLines={2} style={styles.name} />
      </View>
      <View pointerEvents="none" style={styles.radioInput}>
        <Switch
          value={enabled}
          onPress={onPress}
          color={colors.secondaryAccent}
        />
      </View>
    </TouchableOpacity>
  </View>
}
Item.propTypes = {
  name: PropTypes.string,
  enabled: PropTypes.bool,
  onPress: PropTypes.func,
  isFirst: PropTypes.bool,
  isLast: PropTypes.bool,
}

function Separator() {
  const [styles] = useTheme(separatorStyles)
  return <View style={styles.container} />
}

function SoundAndVibration({
  navigation: {
    goBack,
  },
}) {
  const [styles] = useTheme(themedStyles)
  const { colors } = useContext(Theme)
  const {
    soundEnabled,
    toggleSound,
    vibrationEnabled,
    toggleVibration,
  } = useConfig()

  const [items, setItems] = useState([
    {
      id: 'sound',
      name: 'sound_vibration_label_sound',
      enabled: soundEnabled,
      onPress: toggleSound,
    },
    {
      id: 'vibration',
      name: 'sound_vibration_label_vibration',
      enabled: vibrationEnabled,
      onPress: toggleVibration,
    },
  ])

  useEffect(() => {
    setItems(prev => {
      return prev.map(item => {
        if (item.id === 'sound') {
          return {...item, enabled: soundEnabled}
        }
        return item
      })
    })
  }, [soundEnabled])
  useEffect(() => {
    setItems(prev => {
      return prev.map(item => {
        if (item.id === 'vibration') {
          return {...item, enabled: vibrationEnabled}
        }
        return item
      })
    })
  }, [vibrationEnabled])

  const keyExtractor = item => item.id
  const renderItem = ({ item, index }) => (
    <Item
      {...item}
      isFirst={index === 0}
      isLast={index === items.length - 1}
    />
  )

  return (
    <SafeAreaView style={styles.container}>
      <Header
        title="header_sound_vibration"
        image={images.back}
        onPress={() => goBack()}
      />
      <FlatList
        data={items}
        keyExtractor={keyExtractor}
        renderItem={renderItem}
        ItemSeparatorComponent={Separator}
        ListHeaderComponent={<Text i18nKey="sound_vibration_info" align="center" color={colors.secondaryText} />}
        ListHeaderComponentStyle={styles.header}
        ListFooterComponentStyle={styles.footer}
        contentContainerStyle={styles.contentContainer}
      />
    </SafeAreaView >
  )
}
SoundAndVibration.propTypes = {
  navigation: PropTypes.object,
}

export default SoundAndVibration
