import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import Step from '../Step'

import { getProcessData } from '../../Payment.Selectors'

import ThirdStepOther from './ThirdStep.Other'
import ThirdStepP12 from './ThirdStep.P12'

function ThirdStep({
  goBack,
  stepperLength,
  index,
  navigate,
  addListener,
  dispatch,
}) {
  const { invoiceProcess: { type } } = useSelector(getProcessData)
  const CurrentStep = type === 'P12_SELF_INVOICE' ? ThirdStepP12 : ThirdStepOther

  return (
    <Step
      title="header_buyer_seller_einvoice"
      goBack={goBack}
      stepperLength={stepperLength}
      index={index}
      addListener={addListener}
      dispatch={dispatch}
    >
      <CurrentStep navigate={navigate} />
    </Step>
  )
}

ThirdStep.propTypes = {
  setPaymentStatus: PropTypes.func,
  goBack: PropTypes.func,
  stepperLength: PropTypes.number,
  index: PropTypes.number,
  navigate: PropTypes.func,
  addListener: PropTypes.func,
  dispatch: PropTypes.func,
}

export default ThirdStep

