import { Platform } from 'react-native'

import Colors, { styleSheetFactory } from '../../Themes/Colors'

const styles = styleSheetFactory(colors => ({
    container: {
        flexGrow: 1,
        flex: 1,
    },
    wrapper: {
        marginHorizontal: 15,
        // flex: 1,
    },
    inputContainer: {
        minHeight: 60,
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
    },
    itemSpacing: {
        marginBottom: 15,
    },
    calendarInput: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    touchable: {
        backgroundColor: Colors.white,
        padding: 15,
        borderRadius: 7,
        borderWidth: 1,
        borderColor: Colors.separator,
        marginBottom: 15,
    },
    subHeader: {
        marginBottom: 10,
        marginTop: 5,
    },
    icon: {
        marginRight: 5,
    },
    button: {
        marginTop: 10,
    },
    divider: {
        height: 1,
        backgroundColor: colors.separator,
        marginVertical: 15,
        width: '100%',
    },
    dateSubHeader: { marginBottom: 10 },
}))

const itemStyles = styleSheetFactory((colors) => ({
    itemStyle: {
        backgroundColor: colors.tertiaryBackground,
        height: 70,
        justifyContent: 'center',
        borderTopWidth: 1,
        borderTopColor: colors.separator,
    },
    firstItem_true: {
        borderTopLeftRadius: 7,
        borderTopRightRadius: 7,
        borderTopWidth: 0,
    },
    lastItem_true: {
        borderBottomLeftRadius: 7,
        borderBottomRightRadius: 7,
    },
    listDescContainer: {
        flexGrow: 1,
        flexShrink: 1,
        marginLeft: 10,
        justifyContent: 'center',
    },
    amountContainer: {
        alignItems: 'center',
        justifyContent: 'center',
        padding: 15,
        flexDirection: 'row',
    },
    container: { flexDirection: 'row', alignItems: 'center', paddingLeft: 15 },
    subDescription: { fontSize: Platform.OS === 'web' ? 14 : 12, lineHeight: Platform.OS === 'web' ? 16 : 14 },
    description: (type) => {
        return {
            fontSize: type ? 12 : 14,
            lineHeight: type ? 14 : 16,
            marginRight: 4,
        }
    },
    errorContainer: (search = true) => {
        return {
            backgroundColor: search ? colors.tertiaryBackground : colors.background,
            marginTop: 15,
            borderRadius: 7,
        }
    },
    errorSubcontainer: {
        padding: 15,
        justifyContent: 'center',
        alignItems: 'center',
    },
    rowView: { flexDirection: 'row', alignItems: 'center' },
    rightSpacing: { marginRight: 5 },
    horizontalSpacing: { marginHorizontal: 30 },
}))

export default styles
export { itemStyles }
