/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useMemo, useEffect, useRef, memo, useCallback } from 'react'
import {
    SafeAreaView,
    View,
    TouchableOpacity,
    Animated,
} from 'react-native'
import { useFocusEffect } from '@react-navigation/native'
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs'
import { useTheme } from 'react-native-themed-styles'
import PropTypes from 'prop-types'
import { useMutation, useLazyQuery } from '@apollo/client'
import ContentLoader, { Rect } from 'react-content-loader/native'

import {
    Button,
    Icon,
    SwipeList,
    Header,
    TextInput,
    Text,
    ListLoader,
    Loader,
    ModalContent,
    Modal,
    RadioInput,
    CheckBox,
    ButtonGroup,
    Tabs,
} from '../../Components'
import ContentManager from '../../Utils/ContentManager'
import { getErrorMessage } from '../../Utils'

import themedStyles, { routeStyles, itemStyles, hiddenItemStyles } from './Groups_2.Styles'

import {
    LIST_GROUPS,
    DELETE_GROUP,
    EDIT_CUSTOMER,
    EDIT_SUPPLIER,
    LIST_SUPPLIER_GROUPS,
    DELETE_SUPPLIER_GROUP,
} from './Groups.Schema'

import images from '../../Themes/Images'
import { colors, animations } from '../../Themes'

const Tab = createMaterialTopTabNavigator()
function Group({ name, labelColor, onPress }) {
    const [styles] = useTheme(itemStyles)
    const anim = useRef(new Animated.Value(1)).current
    const animate = value => {
        Animated.spring(anim, {
            toValue: value,
            useNativeDriver: true,
        }).start()
    }

    return <Animated.View style={[
        styles.container,
        { transform: [{ scale: anim }] },
    ]}>
        <TouchableOpacity
            onPress={onPress}
            onPressIn={() => animate(1.05)}
            onPressOut={() => animate(1)}
            style={styles.touchable}
        >
            <View style={styles.textContainer}>
                <View style={styles.abreviation(labelColor)}>
                    <Text i18nKey={name?.match(/\b(\w{1})/g)?.slice(0, 2)?.join('')?.toUpperCase()} size="h5" color={labelColor} />
                </View>
                <Text i18nKey={name} numberOfLines={2} style={styles.name} />
            </View>
            <Icon
                source={images.chevronRight}
                style={styles.rightIcon}
            />
        </TouchableOpacity>
    </Animated.View>
}
Group.defaultProps = {
    labelColor: 'white',
}
Group.propTypes = {
    name: PropTypes.string,
    onPress: PropTypes.func,
    isFirst: PropTypes.bool,
    isLast: PropTypes.bool,
}

function SelectableGroup({ name, labelColor, selected, onPress }) {
    const [styles] = useTheme(itemStyles)
    return <View style={[styles.container]}>
        <TouchableOpacity onPress={onPress} style={styles.touchable}>
            <View style={styles.textContainer}>
                <View style={styles.abreviation(labelColor)}>
                    <Text i18nKey={name?.match(/\b(\w{1})/g)?.slice(0, 2)?.join('')?.toUpperCase()} size="h3" color={labelColor} />
                </View>
                <Text i18nKey={name} numberOfLines={2} style={styles.name} />
            </View>
            <View pointerEvents="none" style={styles.checkBox}>
                <CheckBox
                    checked={selected}
                    onValueChange={() => { }}
                    tintColors={styles.checkBox}
                />
            </View>
        </TouchableOpacity>
    </View>
}
SelectableGroup.defaultProps = {
    labelColor: 'white',
}
SelectableGroup.propTypes = {
    id: PropTypes.string,
    name: PropTypes.string,
    onPress: PropTypes.func,
    selected: PropTypes.string,
    isFirst: PropTypes.bool,
    isLast: PropTypes.bool,
}
const Item = memo(({
    phoneNumber,
    businessName,
    givenName,
    familyName,
    labelColor = '#00B0CA',
    onPress,
}) => {
    const [styles] = useTheme(itemStyles)
    const anim = useRef(new Animated.Value(1)).current
    const animate = value => {
        Animated.spring(anim, {
            toValue: value,
            useNativeDriver: true,
        }).start()
    }

    const name = businessName ? businessName : `${givenName} ${familyName}`

    return (
        <Animated.View
            style={[
                styles.container,
                { transform: [{ scale: anim }] },
            ]}>
            <TouchableOpacity
                style={styles.touchable}
                onPress={onPress}
                onPressIn={() => animate(1.05)}
                onPressOut={() => animate(1)}
                activeOpacity={0.7}
                disabled={!onPress}
            >
                <View style={styles.imageContainer('#00B0CA')}>
                    <Text
                        size={'h5'}
                        color={'#00B0CA'}
                        i18nKey={name
                            ?.match(/\b(\w{1})/g)
                            ?.slice(0, 2)
                            ?.join('')
                            ?.toUpperCase()}
                    />
                </View>
                <View style={styles.infoContainer}>
                    <View style={styles.descContainer}>
                        <Text i18nKey={name} numberOfLines={1} />
                        {Boolean(phoneNumber) && <Text
                            i18nKey={phoneNumber}
                            weight="light"
                            color={colors.secondaryText}
                            size="footnote"
                            numberOfLines={1}
                        />}
                    </View>
                    <View style={styles.priceContainer} >
                        <Icon source={images.chevronRight} size={24} color={colors.accent} />
                    </View>
                </View>
            </TouchableOpacity>
        </Animated.View>
    )
})
Item.defaultProps = {
    labelColor: 'white',
}
Item.propTypes = {
    phoneNumber: PropTypes.string,
    businessName: PropTypes.string,
    givenName: PropTypes.string,
    familyName: PropTypes.string,
    onPress: PropTypes.func,
}

function SelectableCustomer({
    id,
    phoneNumber,
    businessName,
    givenName,
    familyName,
    selected,
    onPress,
}) {
    const [styles] = useTheme(itemStyles)
    const anim = useRef(new Animated.Value(1)).current
    const animate = value => {
        Animated.spring(anim, {
            toValue: value,
            useNativeDriver: true,
        }).start()
    }

    const name = businessName ? businessName : `${givenName} ${familyName}`

    return <Animated.View style={[
        styles.container,
        { transform: [{ scale: anim }] },
    ]}>
        <TouchableOpacity
            onPress={onPress}
            onPressIn={() => animate(1.05)}
            onPressOut={() => animate(1)}
            style={styles.touchable}
        >
            <View style={styles.imageContainer('#00B0CA')}>
                <Text
                    size={'h5'}
                    color={'#00B0CA'}
                    i18nKey={name
                        ?.match(/\b(\w{1})/g)
                        ?.slice(0, 2)
                        ?.join('')
                        ?.toUpperCase()}
                />
            </View>
            <View style={styles.infoContainer}>
                <View style={styles.descContainer}>
                    <Text i18nKey={name} numberOfLines={1} />
                    {Boolean(phoneNumber) && <Text
                        i18nKey={phoneNumber}
                        weight="light"
                        color={colors.secondaryText}
                        size="footnote"
                        numberOfLines={1}
                    />}
                </View>
                <View style={styles.priceContainer} >
                    <View pointerEvents="none" style={styles.radioInput}>
                        <RadioInput
                            index={id}
                            selected={selected}
                            onPress={onPress}
                            style={styles.selector}
                            color={colors.secondaryAccent}
                        />
                    </View>
                </View>
            </View>

        </TouchableOpacity>
    </Animated.View>
}
SelectableCustomer.defaultProps = {
    labelColor: 'white',
}
SelectableCustomer.propTypes = {
    id: PropTypes.string,
    phoneNumber: PropTypes.string,
    businessName: PropTypes.string,
    givenName: PropTypes.string,
    familyName: PropTypes.string,
    selected: PropTypes.string,
    onPress: PropTypes.func,
}

function HiddenItem({
    needsConfirmation,
    onPress,
    onConfirm,
    onCancel,
    loading,
}) {
    const [styles] = useTheme(hiddenItemStyles)

    return (
        <View
            style={styles.wrapper}>
            {loading ? (
                <View style={styles.loader}>
                    <Loader
                        size={32}
                        color={colors.white}
                        source={animations.vfLoaderWhite}
                    />
                </View>
            ) : needsConfirmation ? (
                <TouchableOpacity style={styles.option} onPress={onPress}>
                    <Icon source={images.delete} color={colors.white} size={24} />
                </TouchableOpacity>
            ) : (
                <View style={styles.optionFull}>
                    <TouchableOpacity style={styles.confirm} onPress={onConfirm}>
                        <Icon source={images.delete} color={colors.white} size={24} />
                        <Text
                            i18nKey="opened_orders_delete_confirm"
                            color={colors.white}
                            style={styles.confirmText}
                        />
                    </TouchableOpacity>
                    <TouchableOpacity style={styles.cancel} onPress={onCancel}>
                        <Icon source={images.closeIcon} color={colors.white} size={20} />
                    </TouchableOpacity>
                </View>
            )}
        </View>
    )
}
HiddenItem.propTypes = {
    needsConfirmation: PropTypes.bool,
    onPress: PropTypes.func,
    onConfirm: PropTypes.func,
    onCancel: PropTypes.func,
    loading: PropTypes.bool,
}

const TagLoader = ({ width }) => {
    const [styles] = useTheme(themedStyles)

    return (
        <ContentLoader
            speed={0.7}
            height={35}
            width={width}
            backgroundColor="#e2e2e2"
            foregroundColor="#ecebeb"
            style={styles.horizontalSpacing}
        >
            <Rect x="0" y="2" rx="15" ry="15" width={`${width}`} height="30" />
        </ContentLoader>
    )
}
TagLoader.propTypes = {
    width: PropTypes.number,
}

function RouteContent({
    data,
    editGroup,
    selectedGroups,
    deleteGroup,
    needsConfirmation,
    setNeedForConfirmation,
    itemWidth,
    setItemWidth,
    refetch,
    onEndReached,
    selected,
    onFilterPress,
    filters,
    loading,
    isErrorActive,
    setErrorActive,
    error,
    selectable,
    toggleGroup,
    updateLoading,
    onApplyButton,
    deleting,
}) {
    const [styles] = useTheme(routeStyles)

    const renderNonSelectableItem = ({ item, index }) => (
        <Group
            {...item}
            onPress={() => editGroup(item)}
        />
    )

    const renderSelectableItem = ({ item, index }) => (
        <SelectableGroup
            {...item}
            selected={Boolean(selectedGroups[item.id])}
            onPress={() => toggleGroup(item.id, item)}
        />
    )

    const renderItem = selectable ? renderSelectableItem : renderNonSelectableItem

    const renderHiddenItem = ({ item, index }, rowMap) => (
        <HiddenItem
            needsConfirmation={needsConfirmation}
            onPress={() => openRow(rowMap, item.id)}
            onConfirm={() => deleteRow(rowMap, item.id)}
            onCancel={() => closeRow(rowMap, item.id)}
            loading={deleting}
        />
    )

    const openRow = (rowMap, id) => {
        setNeedForConfirmation(false)
        rowMap[id].manuallySwipeRow(-itemWidth)
    }

    const closeRow = (rowMap, id) => {
        rowMap[id].closeRow()
        setNeedForConfirmation(true)
    }

    const deleteRow = (rowMap, id) => {
        deleteGroup(id)
            .then(() => {
                // displayToast('item_delete_success')
                closeRow(rowMap, id)
                refetch()
            })
            .catch((er) => {
                setErrorActive({
                    isVisible: true,
                    message: er,
                })
                closeRow(rowMap, id)
            })
    }

    return (
        <>
            <SwipeList
                data={data}
                renderItem={renderItem}
                renderHiddenItem={renderHiddenItem}
                rightOpenValue={-100}
                onRowClose={() => setNeedForConfirmation(true)}
                disableRightSwipe
                setItemWidth={setItemWidth}
                refreshing={false}
                onRefresh={refetch}
                onEndReached={onEndReached}
                style={styles.topSpacing}
                ListHeaderComponent={!selectable && <ButtonGroup
                    selected={selected}
                    onPress={(group) => onFilterPress(group)}
                    options={filters}
                    style={styles.bottomSpacing}
                />}
                ListFooterComponent={loading && <ListLoader
                    loading={loading}
                />}
                ListEmptyComponent={loading ? null : <View style={styles.emptyComponent}>
                    <Text i18nKey="customer_empty_groups_title" />
                    <Text weight="light" i18nKey="customer_empty_groups_subtitle" />
                </View>}
            />
            {Boolean(selectable) && (
                <Button
                    iconStyle={styles.plusIcon}
                    title={'discount_button_apply'}
                    style={styles.button}
                    variant={updateLoading ? 'disabled' : 'secondary'}
                    loading={updateLoading}
                    onPress={onApplyButton}
                />
            )}
            <Modal
                isVisible={isErrorActive}
                onBackButtonPress={() => setErrorActive({ isVisible: false, message: '' })}
                onBackdropPress={() => setErrorActive({ isVisible: false, message: '' })}
                animationIn="fadeIn"
                animationOut="fadeOut"
            >
                <ModalContent onClose={() => setErrorActive({ isVisible: false, message: '' })}>
                    <Icon source={images.delete} />
                    <Text i18nKey="orders_error_label" color={colors.accent} size="h5" align="center" weight="bold" />
                    <Text i18nKey={getErrorMessage(error)} align="center" />
                </ModalContent>
            </Modal>
        </>
    )
}
RouteContent.propTypes = {
    data: PropTypes.array,
    editGroup: PropTypes.func,
    selectedGroups: PropTypes.object,
    deleteGroup: PropTypes.func,
    needsConfirmation: PropTypes.bool,
    setNeedForConfirmation: PropTypes.func,
    itemWidth: PropTypes.number,
    setItemWidth: PropTypes.func,
    refetch: PropTypes.func,
    onEndReached: PropTypes.func,
    selected: PropTypes.string,
    onFilterPress: PropTypes.func,
    filters: PropTypes.array,
    loading: PropTypes.bool,
    isErrorActive: PropTypes.bool,
    setErrorActive: PropTypes.func,
    error: PropTypes.object,
    selectable: PropTypes.bool,
    toggleGroup: PropTypes.func,
    updateLoading: PropTypes.func,
    onApplyButton: PropTypes.func,
    deleting: PropTypes.bool,
}

function CustomerRoute({
    selectable = false,
    preselectedGroups = [],
    updateSelection = () => { },
    type = 'BUSINESS',
    customerId,
    navigate,
    goBack,
    searchText,
}) {
    const [needsConfirmation, setNeedForConfirmation] = useState(true)
    const [customerGroups, setGroups] = useState({
        groups: [],
        loading: true,
    })
    const [itemWidth, setItemWidth] = useState(0)
    const [limit] = useState(20)
    const [selectedGroups, setSelectedGroups] = useState(() => {
        const res = {}
        preselectedGroups.map(element => { res[element.id] = element })
        return res
    })
    const [selected, setSelected] = useState(type)

    const types = [
        {
            title: 'customer_business_label',
            type: 'BUSINESS',
            list: () => filteredList('BUSINESS'),
        },
        {
            title: 'customer_individual_label',
            type: 'INDIVIDUAL',
            list: () => filteredList('INDIVIDUAL'),
        },
    ]

    const [listGroups, { data, refetch, fetchMore }] = useLazyQuery(LIST_GROUPS, { fetchPolicy: 'network-only' })

    const filteredList = (val) => {
        setGroups({
            groups: [],
            loading: true,
        })
        setSelected(val)
        listGroups({
            variables: {
                limit: limit,
                filter: {
                    name: 'name',
                    value: searchText,
                },
                type: val,
            },
        })
    }

    useEffect(() => {
        filteredList(type)
    }, [])

    const [isErrorActive, setErrorActive] = useState({
        isVisible: false,
        message: '',
    })

    const [deleteGroup, { loading: deleting }] = useMutation(DELETE_GROUP)

    const [updateCustomer, {
        loading: updateLoading,
    }] = useMutation(EDIT_CUSTOMER)

    useEffect(() => {
        filteredList(selected)
    }, [searchText])

    const onEndReached = () => {
        if (!data?.listCustomerGroups?.nextToken) {
            return
        }

        fetchMore({
            variables: {
                limit: limit,
                nextToken: data.listCustomerGroups.nextToken,
                type: selected,
                filter: {
                    name: 'name',
                    value: searchText,
                },
            },
        })
    }

    useEffect(() => {
        const discs = data?.listCustomerGroups?.customerGroups
        if (discs) {
            setGroups({
                groups: discs,
                loading: false,
            })
        }
    }, [data])

    const toggleGroup = (id, item) => {
        setSelectedGroups(prev => {
            if (prev[id]) {
                delete prev[id]
            } else {
                prev[id] = item
            }
            return { ...prev }
        })
    }

    const editGroup = item => {
        if (item) {
            navigate('NewGroup', { groupItem: item, type: 'CUSTOMER', onGroupSave: () => refetch() })
        } else {
            navigate('NewGroup', { onGroupSave: () => refetch() })
        }
    }

    const onApplyButton = () => {
        if (customerId) {
            const groupIds = Object.keys(selectedGroups)
            const prevGroupIds = preselectedGroups.map((element) => element.id)

            const groupsToEnable = groupIds?.filter(
                (element) => !prevGroupIds.includes(element),
            )
            const groupsToDelete = prevGroupIds?.filter(
                (element) => !groupIds.includes(element),
            )
            updateCustomer({
                variables: {
                    id: customerId,
                    customerGroupsToEnable: groupsToEnable,
                    customerGroupsToDelete: groupsToDelete,
                },
            })
                .then((res) => {
                    updateSelection()
                    goBack()
                })
                .catch((er) => {
                    setErrorActive({
                        isVisible: true,
                        message: er,
                    })
                })

        } else {
            updateSelection(Object.values(selectedGroups))
            goBack()
        }
    }

    return (
        <RouteContent
            data={customerGroups?.groups}
            loading={customerGroups?.loading}
            editGroup={editGroup}
            toggleGroup={toggleGroup}
            onEndReached={onEndReached}
            needsConfirmation={needsConfirmation}
            setNeedForConfirmation={setNeedForConfirmation}
            onApplyButton={onApplyButton}
            setErrorActive={setErrorActive}
            isErrorActive={isErrorActive.isVisible}
            error={isErrorActive.message}
            deleteGroup={(id) => deleteGroup({
                variables: {
                    id,
                },
            })}
            selected={selected}
            filters={types}
            itemWidth={itemWidth}
            setItemWidth={setItemWidth}
            onFilterPress={(group) => {
                setGroups({
                    groups: [],
                    loading: true,
                })
                setSelected(group)
                const index = types?.findIndex((el) => el.type === group)
                types[index].list()
            }}
            selectable={selectable}
            selectedGroups={selectedGroups}
            setSelectedGroups={setSelectedGroups}
            refetch={refetch}
            updateLoading={updateLoading}
            deleting={deleting}
        />
    )

}
CustomerRoute.propTypes = {
    navigate: PropTypes.func,
    type: PropTypes.string,
    selectable: PropTypes.bool,
    updateSelection: PropTypes.func,
    goBack: PropTypes.func,
    preselectedGroups: PropTypes.array,
    customerId: PropTypes.string,
    searchText: PropTypes.string,
}

function SupplierRoute({
    selectable = false,
    preselectedGroups = [],
    updateSelection = () => { },
    type = 'BUSINESS',
    customerId,
    navigate,
    goBack,
    searchText,
}) {
    const [needsConfirmation, setNeedForConfirmation] = useState(true)
    const [customerGroups, setGroups] = useState({
        groups: [],
        loading: true,
    })
    const [itemWidth, setItemWidth] = useState(0)
    const [limit] = useState(20)
    const [selectedGroups, setSelectedGroups] = useState(() => {
        const res = {}
        preselectedGroups.map(element => { res[element.id] = element })
        return res
    })
    const [selected, setSelected] = useState(type)

    const types = [
        {
            title: 'customer_business_label',
            type: 'BUSINESS',
            list: () => filteredList('BUSINESS'),
        },
        {
            title: 'customer_individual_label',
            type: 'INDIVIDUAL',
            list: () => filteredList('INDIVIDUAL'),
        },
    ]

    const [listGroups, { data, refetch, fetchMore }] = useLazyQuery(LIST_SUPPLIER_GROUPS, { fetchPolicy: 'network-only' })

    const filteredList = (val) => {
        setGroups({
            groups: [],
            loading: true,
        })
        setSelected(val)
        listGroups({
            variables: {
                limit: limit,
                filter: {
                    name: 'name',
                    value: searchText,
                },
                type: val,
            },
        })
    }

    useEffect(() => {
        filteredList(type)
    }, [])

    const [isErrorActive, setErrorActive] = useState({
        isVisible: false,
        message: '',
    })

    const [deleteGroup, { loading: deleting }] = useMutation(DELETE_SUPPLIER_GROUP)

    const [updateCustomer, {
        loading: updateLoading,
    }] = useMutation(EDIT_SUPPLIER)

    useEffect(() => {
        filteredList(selected)
    }, [searchText])

    const onEndReached = () => {
        if (!data?.listSupplierGroups?.nextToken) {
            return
        }

        fetchMore({
            variables: {
                limit: limit,
                nextToken: data.listSupplierGroups.nextToken,
                type: selected,
                filter: {
                    name: 'name',
                    value: searchText,
                },
            },
        })
    }

    useEffect(() => {
        const discs = data?.listSupplierGroups?.supplierGroups
        if (discs) {
            setGroups({
                groups: discs,
                loading: false,
            })
        }
    }, [data])

    const toggleGroup = (id, item) => {
        setSelectedGroups(prev => {
            if (prev[id]) {
                delete prev[id]
            } else {
                prev[id] = item
            }
            return { ...prev }
        })
    }

    const editGroup = item => {
        if (item) {
            navigate('NewGroup', { groupItem: item, type: 'SUPPLIER', onGroupSave: () => refetch() })
        } else {
            navigate('NewGroup', { onGroupSave: () => refetch() })
        }
    }

    const onApplyButton = () => {
        if (customerId) {
            const groupIds = Object.keys(selectedGroups)
            const prevGroupIds = preselectedGroups.map((element) => element.id)

            const groupsToEnable = groupIds?.filter(
                (element) => !prevGroupIds.includes(element),
            )
            const groupsToDelete = prevGroupIds?.filter(
                (element) => !groupIds.includes(element),
            )
            updateCustomer({
                variables: {
                    id: customerId,
                    supplierGroupsToEnable: groupsToEnable,
                    supplierGroupsToDelete: groupsToDelete,
                },
            })
                .then((res) => {
                    updateSelection()
                    goBack()
                })
                .catch((er) => {
                    setErrorActive({
                        isVisible: true,
                        message: er,
                    })
                })

        } else {
            updateSelection(Object.values(selectedGroups))
            goBack()
        }
    }

    return (
        <RouteContent
            data={customerGroups?.groups}
            loading={customerGroups?.loading}
            editGroup={editGroup}
            toggleGroup={toggleGroup}
            onEndReached={onEndReached}
            needsConfirmation={needsConfirmation}
            setNeedForConfirmation={setNeedForConfirmation}
            onApplyButton={onApplyButton}
            setErrorActive={setErrorActive}
            isErrorActive={isErrorActive.isVisible}
            error={isErrorActive.message}
            deleteGroup={(id) => deleteGroup({
                variables: { id },
            })}
            selected={selected}
            filters={types}
            itemWidth={itemWidth}
            setItemWidth={setItemWidth}
            onFilterPress={(group) => {
                setGroups({
                    groups: [],
                    loading: true,
                })
                setSelected(group)
                const index = types?.findIndex((el) => el.type === group)
                types[index].list()
            }}
            selectable={selectable}
            selectedGroups={selectedGroups}
            setSelectedGroups={setSelectedGroups}
            refetch={refetch}
            updateLoading={updateLoading}
            deleting={deleting}
        />
    )

}
SupplierRoute.propTypes = {
    navigate: PropTypes.func,
    type: PropTypes.string,
    selectable: PropTypes.bool,
    updateSelection: PropTypes.func,
    goBack: PropTypes.func,
    preselectedGroups: PropTypes.array,
    customerId: PropTypes.string,
    searchText: PropTypes.string,
    isSupplier: PropTypes.bool,
}

function Groups({
    route: {
        params: {
            selectable = false,
            preselectedGroups = [],
            updateSelection = () => { },
            type,
            customerId,
            isSupplier,
            shouldShowCustomers = true,
            shouldShowSuppliers = true,
        } = {},
    } = {},
    navigation: {
        navigate,
        goBack,
    },
}) {
    const [styles] = useTheme(themedStyles)
    const [searchValue, setSearchValue] = useState('')

    const { translate } = ContentManager

    useFocusEffect(useCallback(() => {
        setSearchValue('')
    }, []))


    const renderTabs = useMemo(() => {
        return (
            <Tabs
                initialRouteName={translate('customers_label')}
                style={styles.tabView}
                screenOptions={{
                    swipeEnabled: false,
                }}
            >
                {shouldShowCustomers && <Tab.Screen key={'tab-business'} name={translate('customers_label')}>
                    {(props) => (
                        <>
                            <CustomerRoute
                                {...props}
                                selectable={selectable}
                                preselectedGroups={preselectedGroups}
                                updateSelection={updateSelection}
                                type={type}
                                customerId={customerId}
                                navigate={navigate}
                                goBack={goBack}
                                searchText={searchValue}
                            />
                        </>
                    )}
                </Tab.Screen>}
                {shouldShowSuppliers && <Tab.Screen key={'tab-individ'} name={translate('suppliers_label')}>
                    {(props) => (
                        <SupplierRoute
                            {...props}
                            selectable={selectable}
                            preselectedGroups={preselectedGroups}
                            updateSelection={updateSelection}
                            type={type}
                            customerId={customerId}
                            navigate={navigate}
                            goBack={goBack}
                            searchText={searchValue}
                        />
                    )}
                </Tab.Screen>}
            </Tabs>
        )
    }, [searchValue, preselectedGroups])

    return (
        <>
            <SafeAreaView style={styles.container} >
                <Header
                    title="customers_groups_label"
                    image={images.chevronLeft}
                    onPress={() => goBack()}
                />
                <View style={styles.searchContainer}>
                    <Icon source={images.search} style={styles.icon} />
                    <TextInput
                        placeholder="customer_search_group_placeholder"
                        placeholderTextColor={colors.placeholder}
                        style={styles.input}
                        value={searchValue}
                        onChangeText={setSearchValue}
                    />
                    {Boolean(searchValue) &&
                        <TouchableOpacity onPress={() => setSearchValue('')} style={styles.iconContainer}>
                            <Icon source={images.close} style={styles.icon} />
                        </TouchableOpacity>
                    }
                </View>
                <Button
                    icon={images.plus}
                    iconStyle={styles.plusIcon}
                    title="customer_new_group_button"
                    titleStyle={styles.buttonTitle}
                    style={styles.button}
                    onPress={() => {
                        navigate('NewGroup', { onGroupSave: () => { } })
                        setSearchValue(' ')
                    }}
                    variant={'active'}
                />
                {renderTabs}

            </SafeAreaView>
        </>
    )
}
Groups.propTypes = {
    navigation: PropTypes.object,
    route: PropTypes.object,
}

export default Groups
