/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useMemo, useEffect, useRef, memo, useCallback } from 'react'
import {
    SafeAreaView,
    View,
    TouchableOpacity,
    Animated,
} from 'react-native'
import { useFocusEffect } from '@react-navigation/native'
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs'
import { useTheme } from 'react-native-themed-styles'
import PropTypes from 'prop-types'
import { useMutation, useQuery, useLazyQuery } from '@apollo/client'
import ContentLoader, { Rect } from 'react-content-loader/native'

import {
    TagList,
    Button,
    Icon,
    SwipeList,
    Header,
    TextInput,
    Text,
    ListLoader,
    Loader,
    ModalContent,
    Modal,
    RadioInput,
    Tabs,
} from '../../Components'
import ContentManager from '../../Utils/ContentManager'
import { getErrorMessage } from '../../Utils'

import themedStyles, { routeStyles, itemStyles, hiddenItemStyles } from './AllCustomers.Styles'
import { LIST_GROUPS, LIST_SUPPLIERS_GROUPS, LIST_SUPPLIERS, LIST_CUSTOMERS, DELETE_CUSTOMER, DELETE_SUPPLIER } from './AllCustomers.Schema'

import images from '../../Themes/Images'
import { colors, animations } from '../../Themes'

const Tab = createMaterialTopTabNavigator()

const Item = memo(({
    businessName,
    givenName,
    familyName,
    onPress,
    address: {
        addressLine1,
    },
}) => {
    const [styles] = useTheme(itemStyles)
    const anim = useRef(new Animated.Value(1)).current
    const animate = value => {
        Animated.spring(anim, {
            toValue: value,
            useNativeDriver: true,
        }).start()
    }

    const name = businessName ? businessName : `${givenName} ${familyName}`

    return (
        <Animated.View
            style={[
                styles.container,
                { transform: [{ scale: anim }] },
            ]}>
            <TouchableOpacity
                style={styles.touchable}
                onPress={onPress}
                onPressIn={() => animate(1.05)}
                onPressOut={() => animate(1)}
                activeOpacity={0.7}
                disabled={!onPress}
            >
                <View style={styles.imageContainer('#00B0CA')}>
                    <Text
                        size={'h5'}
                        color={'#00B0CA'}
                        i18nKey={name
                            ?.match(/\b(\w{1})/g)
                            ?.slice(0, 2)
                            ?.join('')
                            ?.toUpperCase()}
                    />
                </View>
                <View style={styles.infoContainer}>
                    <View style={styles.descContainer}>
                        <Text i18nKey={name} numberOfLines={1} />
                        {Boolean(addressLine1) && <Text
                            i18nKey={addressLine1}
                            weight="light"
                            color={colors.secondaryText}
                            size="footnote"
                            numberOfLines={1}
                        />}
                    </View>
                    <View style={styles.priceContainer} >
                        <Icon source={images.chevronRight} size={24} color={colors.accent} />
                    </View>
                </View>

            </TouchableOpacity>
        </Animated.View>
    )
})
Item.defaultProps = {
    labelColor: 'white',
}
Item.propTypes = {
    phoneNumber: PropTypes.string,
    businessName: PropTypes.string,
    givenName: PropTypes.string,
    familyName: PropTypes.string,
    onPress: PropTypes.func,
    address: PropTypes.object,
}
function SelectableCustomer({
    id,
    phoneNumber,
    businessName,
    givenName,
    familyName,
    labelColor = '#00B0CA',
    selected,
    onPress,
    address: {
        addressLine1,
    },
}) {
    const [styles] = useTheme(itemStyles)
    const anim = useRef(new Animated.Value(1)).current
    const animate = value => {
        Animated.spring(anim, {
            toValue: value,
            useNativeDriver: true,
        }).start()
    }

    const name = businessName ? businessName : `${givenName} ${familyName}`

    return <Animated.View style={[
        styles.container,
        { transform: [{ scale: anim }] },
    ]}>
        <TouchableOpacity
            onPress={onPress}
            onPressIn={() => animate(1.05)}
            onPressOut={() => animate(1)}
            style={styles.touchable}
        >
            <View style={styles.imageContainer('#00B0CA')}>
                <Text
                    size={'h5'}
                    color={'#00B0CA'}
                    i18nKey={name
                        ?.match(/\b(\w{1})/g)
                        ?.slice(0, 2)
                        ?.join('')
                        ?.toUpperCase()}
                />
            </View>
            <View style={styles.infoContainer}>
                <View style={styles.descContainer}>
                    <Text i18nKey={name} numberOfLines={1} />
                    {Boolean(addressLine1) && <Text
                        i18nKey={addressLine1}
                        weight="light"
                        color={colors.secondaryText}
                        size="footnote"
                        numberOfLines={1}
                    />}
                </View>
                <View style={styles.priceContainer} >
                    <View pointerEvents="none" style={styles.radioInput}>
                        <RadioInput
                            index={id}
                            selected={selected}
                            onPress={onPress}
                            style={styles.selector}
                            color={colors.secondaryAccent}
                        />
                    </View>
                </View>
            </View>

        </TouchableOpacity>
    </Animated.View>
}
SelectableCustomer.defaultProps = {
    labelColor: 'white',
}
SelectableCustomer.propTypes = {
    id: PropTypes.string,
    phoneNumber: PropTypes.string,
    businessName: PropTypes.string,
    givenName: PropTypes.string,
    familyName: PropTypes.string,
    selected: PropTypes.string,
    onPress: PropTypes.func,
    address: PropTypes.object,
}
function HiddenItem({
    needsConfirmation,
    onPress,
    onConfirm,
    onCancel,
    loading,
}) {
    const [styles] = useTheme(hiddenItemStyles)

    return (
        <View
            style={styles.wrapper}>
            {loading ? (
                <View style={styles.loader}>
                    <Loader
                        size={32}
                        color={colors.white}
                        source={animations.vfLoaderWhite}
                    />
                </View>
            ) : needsConfirmation ? (
                <TouchableOpacity style={styles.option} onPress={onPress}>
                    <Icon source={images.delete} color={colors.white} size={24} />
                </TouchableOpacity>
            ) : (
                <View style={styles.optionFull}>
                    <TouchableOpacity style={styles.confirm} onPress={onConfirm}>
                        <Icon source={images.delete} color={colors.white} size={24} />
                        <Text
                            i18nKey="opened_orders_delete_confirm"
                            color={colors.white}
                            style={styles.confirmText}
                        />
                    </TouchableOpacity>
                    <TouchableOpacity style={styles.cancel} onPress={onCancel}>
                        <Icon source={images.closeIcon} color={colors.white} size={20} />
                    </TouchableOpacity>
                </View>
            )}
        </View>
    )
}
HiddenItem.propTypes = {
    needsConfirmation: PropTypes.bool,
    onPress: PropTypes.func,
    onConfirm: PropTypes.func,
    onCancel: PropTypes.func,
    loading: PropTypes.bool,
}

const widths = [80, 110, 95, 87, 100]

const TagLoader = ({ width }) => {
    const [styles] = useTheme(themedStyles)

    return (
        <ContentLoader
            speed={0.7}
            height={35}
            width={width}
            backgroundColor="#e2e2e2"
            foregroundColor="#ecebeb"
            style={styles.horizontalSpacing}
        >
            <Rect x="0" y="2" rx="15" ry="15" width={`${width}`} height="30" />
        </ContentLoader>
    )
}
TagLoader.propTypes = {
    width: PropTypes.number,
}

const TagListLoader = () => {
    const [styles] = useTheme(themedStyles)

    return (
        <View style={styles.rowView}>
            {widths.map((item, index) => (<TagLoader key={index} width={item} />))}
        </View>
    )
}

const LIST_EMPTY_LABELS = {
    true: {
        title: 'customer_empty_suppliers_title',
        description: 'customer_empty_suppliers_subtitle',
    },
    false: {
        title: 'customer_empty_list_title',
        description: 'customer_empty_list_subtitle',
    },
}

function Route({
    query,
    navigate,
    selected,
    setSelected,
    type,
    searchValue,
    selectable,
    preselectedCustomer,
    updateSelection,
    isSupplier = false,
    goBack,
}) {

    const [selectedCustomer, setSelectedCustomer] = useState({ ...preselectedCustomer })

    const [styles] = useTheme(routeStyles)
    const [itemWidth, setItemWidth] = useState(0)
    const [needsConfirmation, setNeedForConfirmation] = useState(true)
    const [customerData, setCustomerData] = useState({
        customers: [],
        loading: true,
    })
    const [isErrorActive, setErrorActive] = useState(false)
    const [limit] = useState(30)

    const [deleteCustomer, { loading: deleting, error: deleteError }] = useMutation(isSupplier ? DELETE_SUPPLIER : DELETE_CUSTOMER)

    const [listCustomers, { data: listData, refetch, fetchMore }] = useLazyQuery(
        query,
        {
            fetchPolicy: 'network-only',
        }
    )

    const list = () => {
        listCustomers({
            variables: {
                type: type,
                limit: limit,
                filter: searchValue ?
                    {
                        name: 'all',
                        value: searchValue,
                    } : null,
            },
        })
    }

    const filteredList = (val) => {
        listCustomers({
            variables: {
                type: type,
                limit: limit,
                filter: {
                    name: isSupplier ? 'supplierGroupId' : 'customerGroupId',
                    value: val,
                },
            },
        })
    }

    useEffect(() => {
        setCustomerData({
            customers: [],
            loading: true,
        })
        list()
    }, [searchValue])

    const [groups, setGroups] = useState([{
        title: 'users_all_filter',
        type: 'all',
        list: () => list(),
    }])

    useEffect(() => {
        setCustomerData((prev) => ({
            ...prev,
            loading: true,
        }))
        const customerRes = isSupplier ? listData?.listSuppliers?.suppliers : listData?.listCustomers?.customers
        if (customerRes) {
            setCustomerData({
                customers: [...customerRes],
                loading: false,
            })
        }
    }, [listData])

    const { loading, data, fetchMore: fetchMoreGroups } = useQuery(isSupplier ? LIST_SUPPLIERS_GROUPS : LIST_GROUPS, {
        fetchPolicy: 'no-cache',
        variables: {
            limit: 25,
            type: type,
        },
    })

    const onEndListReached = () => {
        if (isSupplier ? listData?.listSuppliers?.nextToken : listData?.listCustomers?.nextToken === null) {
            return
        }

        fetchMore({
            query: query,
            variables: {
                nextToken: isSupplier ? listData?.listSuppliers?.nextToken : listData?.listCustomers?.nextToken,
                limit: limit,
                type: type,
                filter: searchValue ?
                    {
                        name: 'all',
                        value: searchValue,
                    } : null,
            },
        })
    }

    const openRow = (rowMap, id) => {
        setNeedForConfirmation(false)
        rowMap[id].manuallySwipeRow(-itemWidth)
    }

    const closeRow = (rowMap, id) => {
        rowMap[id].closeRow()
        setNeedForConfirmation(true)
    }

    const deleteRow = (rowMap, id) => {
        deleteCustomer({
            variables: {
                id: id,
            },
        })
            .then(() => {
                // displayToast('item_delete_success')
                closeRow(rowMap, id)
                refetch()
            })
            .catch(() => {
                setErrorActive(true)
                closeRow(rowMap, id)
            })
    }

    const renderNonSelectableItem = ({ item, index }) => (
        <Item
            {...item}
            onPress={() => navigate('CustomerDetails', { item, type, isSupplier, onSave: () => { } })}
        />
    )

    const selectCustomer = (item) => {
        setSelectedCustomer(item)
        updateSelection(item)
        goBack()
    }

    const renderSelectableItem = ({ item, index }) => {
        return (<SelectableCustomer
            {...item}
            selected={selectedCustomer?.id}
            onPress={() => selectCustomer(item)}
        />)
    }

    const renderItem = selectable ? renderSelectableItem : renderNonSelectableItem

    const renderHiddenItem = ({ item, index }, rowMap) => (
        <HiddenItem
            needsConfirmation={needsConfirmation}
            onPress={() => openRow(rowMap, item.id)}
            onConfirm={() => deleteRow(rowMap, item.id)}
            onCancel={() => closeRow(rowMap, item.id)}
            loading={deleting}
        />
    )

    const onEndReached = () => {
        if (isSupplier ? !data?.listSupplierGroups?.nextToken : !data?.listCustomerGroups?.nextToken) {
            return
        }

        fetchMoreGroups({
            variables: {
                limit: 25,
                nextToken: isSupplier ? data?.listSupplierGroups?.nextToken : data?.listCustomerGroups?.nextToken,
                type: type,
            },
        })
    }

    useEffect(() => {
        const discs = isSupplier ? data?.listSupplierGroups?.supplierGroups : data?.listCustomerGroups?.customerGroups
        if (discs) {
            const groupItems = [{
                title: 'users_all_filter',
                type: 'all',
                list: () => list(),
            }]
            discs.map((item, index) => (
                groupItems.push({
                    title: item?.name,
                    type: item?.name,
                    list: () => filteredList(item?.id),
                })
            ))
            setGroups(groupItems)
        }
    }, [data])

    const refetchData = async () => {
        if (refetch) {
            await refetch()
        }
    }

    useFocusEffect(
        useCallback(() => {
            refetchData()
        }, [])
    )

    return (
        <>
            <TagList
                selected={selected}
                onPress={(group) => {
                    setCustomerData({
                        customers: [],
                        loading: true,
                    })
                    setSelected(group)
                    const index = groups?.findIndex((el) => el.type === group)
                    groups[index].list()
                }}
                options={groups}
                style={styles.verticalSpacing}
                onEndReached={onEndReached}
                onEndReachedThreshold={0.1}
                ListFooterComponent={loading && <TagListLoader />}
            />
            <SwipeList
                data={customerData.customers}
                renderItem={renderItem}
                renderHiddenItem={renderHiddenItem}
                rightOpenValue={-100}
                onRowClose={() => setNeedForConfirmation(true)}
                disableRightSwipe
                setItemWidth={setItemWidth}
                refreshing={false}
                onRefresh={() => refetch()}
                onEndReached={() => onEndListReached()}
                ListFooterComponent={customerData.loading && <ListLoader
                    loading={customerData.loading}
                />}
                ListEmptyComponent={customerData.loading ? null : <View style={styles.emptyComponent}>
                    <Text i18nKey={LIST_EMPTY_LABELS[isSupplier].title} />
                    <Text weight="light" i18nKey={LIST_EMPTY_LABELS[isSupplier].description} />
                </View>}
            />
            <Modal
                isVisible={isErrorActive}
                onBackButtonPress={() => setErrorActive(false)}
                onBackdropPress={() => setErrorActive(false)}
                animationIn="fadeIn"
                animationOut="fadeOut"
            >
                <ModalContent onClose={() => setErrorActive(false)}>
                    <Icon source={images.delete} style={styles.errorIcon} />
                    <Text i18nKey="orders_error_label" color={colors.accent} size="h5" align="center" weight="bold" style={styles.errorTitle} />
                    <Text i18nKey={getErrorMessage(deleteError)} align="center" />
                </ModalContent>
            </Modal>
        </>
    )

}
Route.propTypes = {
    selected: PropTypes.string,
    query: PropTypes.object,
    navigate: PropTypes.func,
    setSelected: PropTypes.func,
    type: PropTypes.string,
    searchValue: PropTypes.string,
    selectable: PropTypes.bool,
    preselectedCustomer: PropTypes.object,
    updateSelection: PropTypes.func,
    goBack: PropTypes.func,
    isSupplier: PropTypes.bool,
}

function AllCustomers({ navigation: { goBack, navigate }, route: {
    params: {
        selectable = false,
        preselectedCustomer = {},
        updateSelection = () => { },
        showSecondTab = true,
        hideFirstTab,
        isSupplier,
    } = {},
} = {} }) {
    const [styles] = useTheme(themedStyles)
    const [selectedBus, setSelectedBus] = useState('all')
    const [selectedInd, setSelectedInd] = useState('all')
    const [searchValue, setSearchValue] = useState('')

    const { translate } = ContentManager

    const renderTabs = useMemo(() => {
        return (
            <Tabs
                initialRouteName={translate('customer_business_label')}
                style={styles.tabView}
                screenOptions={{
                    swipeEnabled: false,
                }}
            >
                {!hideFirstTab && <Tab.Screen key={'tab-business'} name={translate('customer_business_label')}>
                    {(props) => (
                        <>
                            <Route
                                {...props}
                                selected={selectedBus}
                                setSelected={setSelectedBus}
                                navigate={navigate}
                                query={isSupplier ? LIST_SUPPLIERS : LIST_CUSTOMERS}
                                type="BUSINESS"
                                searchValue={searchValue}
                                selectable={selectable}
                                preselectedCustomer={preselectedCustomer}
                                updateSelection={updateSelection}
                                goBack={goBack}
                                isSupplier={isSupplier}
                            />
                        </>
                    )}
                </Tab.Screen>}
                {showSecondTab && <Tab.Screen key={'tab-individ'} name={translate('customer_individ_label')}>
                    {(props) => (
                        <>
                            <Route
                                {...props}
                                selected={selectedInd}
                                setSelected={setSelectedInd}
                                navigate={navigate}
                                query={isSupplier ? LIST_SUPPLIERS : LIST_CUSTOMERS}
                                type="INDIVIDUAL"
                                searchValue={searchValue}
                                selectable={selectable}
                                preselectedCustomer={preselectedCustomer}
                                updateSelection={updateSelection}
                                goBack={goBack}
                                isSupplier={isSupplier}
                            />
                        </>
                    )}
                </Tab.Screen>}
            </Tabs>
        )
    }, [selectedInd, selectedBus, searchValue, preselectedCustomer])

    return (
        <>
            <SafeAreaView style={styles.container} >
                <Header
                    title={isSupplier ? 'suppliers_all_label' : 'customers_all_label'}
                    image={images.chevronLeft}
                    onPress={() => goBack()}
                />
                <View style={styles.searchContainer}>
                    <Icon source={images.search} style={styles.icon} />
                    <TextInput
                        placeholder="customer_search_placeholder"
                        placeholderTextColor={colors.placeholder}
                        style={styles.input}
                        value={searchValue}
                        onChangeText={setSearchValue}
                    />
                    {Boolean(searchValue) &&
                        <TouchableOpacity onPress={() => setSearchValue('')} style={styles.iconContainer}>
                            <Icon source={images.close} style={styles.icon} />
                        </TouchableOpacity>
                    }
                </View>
                <Button
                    icon={images.plus}
                    iconStyle={styles.plusIcon}
                    title={isSupplier ? 'suppliers_new_button_label' : 'customer_new_button_label'}
                    titleStyle={styles.buttonTitle}
                    style={styles.button}
                    onPress={() => navigate('NewCustomer', { isSupplier: isSupplier })}
                    variant={'active'}
                />
                {renderTabs}

            </SafeAreaView>
        </>
    )
}
AllCustomers.propTypes = {
    navigation: PropTypes.object,
    route: PropTypes.object,
}

export default AllCustomers
