import { styleSheetFactory } from '../../Themes/Colors'

const styles = styleSheetFactory(colors => ({
  container: {
    backgroundColor: colors.tertiaryBackground,
    marginVertical: 8,
    borderRadius: 7,
  },
  separator: {
    height: 1,
    backgroundColor: colors.separator,
  },
  itemContainer: {
    paddingHorizontal: 15,
    paddingVertical: 20,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  title: {

  },
  label: {
    marginRight: 30,
    maxWidth: '50%',
  },
  value: {
    flexGrow: 1,
    flexShrink: 1,
    marginLeft: 40,
  },
}))

export default styles
