/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, memo, useRef } from 'react'
import { SafeAreaView, View, TouchableOpacity, Animated, Keyboard } from 'react-native'
import { useTheme } from 'react-native-themed-styles'
import PropTypes from 'prop-types'
import { useMutation, useLazyQuery } from '@apollo/client'
import _ from 'lodash'

import {
    Icon,
    Text,
    InputWithTags,
    InputPhone,
    CheckBox,
    Button,
    SwipeList,
    LoaderModal,
    ModalContent,
    Modal,
    Loader,
    TouchableInput,
    BottomSheetSelector,
    Type,
} from '../../Components'

import { getErrorMessage } from '../../Utils'

import { STATE_LIST } from './CountryCodes'

import { GET_BUSINESS_CUSTOMER, CREATE_CUSTOMER, EDIT_CUSTOMER, GET_BUSINESS_DETAILS, GET_BUSINESS_SUPPPLIER, EDIT_SUPPLIER, CREATE_SUPPLIER } from './NewCustomer.Schema'

import themedStyles, { hiddenItemStyles, itemStyles, stateitemStyles } from './NewCustomer.Styles'
import images from '../../Themes/Images'
import { colors, animations } from '../../Themes'


function StateType({ item, selected, onPress }) {
    const [itemStyle] = useTheme(stateitemStyles)
    const isSelected = selected === item

    return (
        <View style={itemStyle.container}>
            <TouchableOpacity
                onPress={onPress}
                style={itemStyle['itemSelected_' + isSelected]}
                activeOpacity={0.6}
            >
                <Text
                    i18nKey={item}
                    size="footnote"
                    style={itemStyle['itemTextSelected_' + isSelected]}
                />
            </TouchableOpacity>
        </View>
    )
}
StateType.propTypes = {
    item: PropTypes.string,
    selected: PropTypes.object,
    onPress: PropTypes.func,
}
function TouchableItem({ leftText, group, onPress, disabled }) {
    const [styles] = useTheme(themedStyles)

    return (
        <TouchableOpacity
            onPress={() => onPress()}
            disabled={disabled}
            style={styles.touchableContainer}>
            <View style={[styles.subContainer, styles.rightSelfSpacing]}>
                <View style={styles.textContainer}>
                    <Text
                        weight="light"
                        align="left"
                        style={styles.text}
                        i18nKey={leftText}
                    />
                    {group ? (
                        <View style={styles.rightContainer}>
                            <Text i18nKey={group} align="right" />
                        </View>
                    ) : null}
                </View>
                <Icon source={images.arrow_right} size={20} />
            </View>
        </TouchableOpacity>
    )
}
TouchableItem.propTypes = {
    leftText: PropTypes.string,
    group: PropTypes.string,
    onPress: PropTypes.func,
    disabled: PropTypes.bool,
}

function CheckItem({ label, onPress, checked }) {
    const [styles] = useTheme(themedStyles)

    return (
        <View style={styles.rightContainer}>
            <CheckBox
                checked={checked}
                tintColors={{
                    true: colors.secondaryAccent,
                    false: colors.placeholder,
                }}
                onValueChange={onPress}
            />
            <Text i18nKey={label} style={styles.leftSpacing} />
        </View>
    )
}
CheckItem.propTypes = {
    label: PropTypes.string,
    onPress: PropTypes.func,
    checked: PropTypes.bool,
}

const Item = memo(({
    name,
    labelColor,
    onPress,
    isFirst,
    isLast,
}) => {
    const [styles] = useTheme(itemStyles)
    const anim = useRef(new Animated.Value(1)).current
    const animate = value => {
        Animated.spring(anim, {
            toValue: value,
            useNativeDriver: true,
        }).start()
    }

    return (
        <Animated.View
            style={[
                styles.container,
                styles['firstItem_' + isFirst],
                styles['lastItem_' + isLast],
                { transform: [{ scale: anim }] },
            ]}>
            <TouchableOpacity
                style={styles.touchable}
                onPress={onPress}
                onPressIn={() => animate(1.05)}
                onPressOut={() => animate(1)}
                activeOpacity={0.7}
                disabled={!onPress}>
                <View style={styles.imageContainer(labelColor)}>
                    <Text
                        size={'h5'}
                        color={labelColor}
                        i18nKey={name
                            ?.match(/\b(\w{1})/g)
                            ?.slice(0, 2)
                            ?.join('')
                            ?.toUpperCase()}
                    />
                </View>
                <View style={styles.infoContainer}>
                    <View style={styles.descContainer}>
                        <Text i18nKey={name} numberOfLines={1} />
                    </View>
                    <View style={styles.priceContainer} >
                        <Icon source={images.chevronRight} size={24} color={colors.accent} />
                    </View>
                </View>

            </TouchableOpacity>
        </Animated.View>
    )
})
Item.defaultProps = {
    labelColor: 'white',
}
Item.propTypes = {
    name: PropTypes.string,
    onPress: PropTypes.func,
    isFirst: PropTypes.bool,
    isLast: PropTypes.bool,
}

function HiddenItem({
    needsConfirmation,
    onPress,
    onConfirm,
    onCancel,
    isFirst,
    isLast,
}) {
    const [styles] = useTheme(hiddenItemStyles)

    return (
        <View
            style={[styles.wrapper, styles['firstItem_' + isFirst], styles['lastItem_' + isLast]]}>
            {needsConfirmation ? (
                <TouchableOpacity style={styles.option} onPress={onPress}>
                    <Icon source={images.delete} color={colors.white} size={24} />
                </TouchableOpacity>
            ) : (
                <View style={styles.optionFull}>
                    <TouchableOpacity style={styles.confirm} onPress={onConfirm}>
                        <Icon source={images.delete} color={colors.white} size={24} />
                        <Text
                            i18nKey="opened_orders_delete_confirm"
                            color={colors.white}
                            style={styles.confirmText}
                        />
                    </TouchableOpacity>
                    <TouchableOpacity style={styles.cancel} onPress={onCancel}>
                        <Icon source={images.closeIcon} color={colors.white} size={20} />
                    </TouchableOpacity>
                </View>
            )}
        </View>
    )
}
HiddenItem.propTypes = {
    needsConfirmation: PropTypes.bool,
    onPress: PropTypes.func,
    onConfirm: PropTypes.func,
    onCancel: PropTypes.func,
    loading: PropTypes.bool,
    isFirst: PropTypes.bool,
    isLast: PropTypes.bool,
}

function BusinessForm({ navigate, isSupplier = false, id, goBack, onSave = () => { } }) {
    const [styles] = useTheme(themedStyles)
    const [itemWidth, setItemWidth] = useState(0)
    const [needsConfirmation, setNeedForConfirmation] = useState(true)

    const [nipt, setNipt] = useState('')
    const [businessName, setBusinessName] = useState('')
    const [userEmail, setEmail] = useState('')
    const [userPhone, setPhone] = useState('')
    const [street, setStreet] = useState('')
    const [city, setCity] = useState('')
    const [searchValue, setSearchValue] = useState('')
    const [state, setUserState] = useState()

    const [prevGroups, setPrevGroups] = useState([])
    const [groups, setGroups] = useState([])

    const [emailUnsubsribed, setEmailUnsubsribed] = useState(true)
    const [updatable, setUpdatable] = useState(false)

    const [editable, setEditable] = useState(true)
    const [disabled, setDisabled] = useState(true)
    const [addressDisabled, setAddressDisabled] = useState(true)
    const [businessAddresses, setBusinessAddresses] = useState([])
    const [isErrorActive, setErrorActive] = useState({
        isVisible: false,
        message: '',
    })
    const [limit] = useState(20)
    const [niptError, setNiptError] = useState(false)

    const [modal, setModal] = useState({
        isVisible: false,
        data: [],
        title: '',
        placeholder: '',
        selected: undefined,
        select: () => { },
        Item: StateType,
    })

    const closeModal = () => {
        setModal(prev => ({ ...prev, isVisible: false }))
        setSearchValue('')
    }

    const isNipt = /^[A-Z]{1}\d{8}[A-Z]{1}$/

    const updateGroups = (newGroup) => {
        setGroups([...newGroup])
    }

    const openStateSelector = () => {
        setModal({
            isVisible: true,
            data: STATE_LIST,
            title: 'customers_state_label',
            placeholder: 'customers_state_placeholder',
            selected: state,
            select: (obj) => {
                setUserState(obj)
            },
            searchKey: 'description',
            Item: Type,
        })
    }

    const updateSelectedGroups = updated => {
        updateGroups(updated)
        setGroups(updated?.filter(element => element.name?.toLowerCase()))
    }

    const changeGroup = group => {
        const i = groups.findIndex(element => element.id === group.id)
        if (i !== -1) {
            const updated = groups
            updated[i] = group
            updateSelectedGroups([...updated])
        }
    }

    const editGroup = item => {
        if (item) {
            navigate('NewGroup', { groupItem: item, type: isSupplier ? 'SUPPLIER' : 'CUSTOMER', onGroupSave: changeGroup })
        }
    }
    const [
        getCustomer,
        { data, fetchMore, refetch },
    ] = useLazyQuery(GET_BUSINESS_CUSTOMER, {
        fetchPolicy: 'network-only',
        variables: {
            id: id,
            limit: limit,
        },
    })

    const [
        getSupplier,
        { data: supplierData, fetchMore: supplierFetchMore, refetch: supplierRefetch },
    ] = useLazyQuery(GET_BUSINESS_SUPPPLIER, {
        fetchPolicy: 'network-only',
        variables: {
            id: id,
            limit: limit,
        },
    })

    const [
        getBusinessDetails,
        { loading: businessLoading, error: businessError, data: businessData, refetch: businessRefetch, called },
    ] = useLazyQuery(GET_BUSINESS_DETAILS, { fetchPolicy: 'network-only' })

    const getBusinessData = (val) => {
        getBusinessDetails({
            variables: {
                name: 'businessTin',
                value: val,
            },
        })
    }

    const addGroups = () => {
        navigate('Groups', {
            selectable: true,
            preselectedGroups: groups,
            updateSelection: id ? () => {
                if (isSupplier) {
                    supplierRefetch()
                } else {
                    refetch()
                }
            } : updateSelectedGroups,
            type: 'BUSINESS',
            customerId: id,
            isSupplier,
            shouldShowCustomers: !isSupplier,
            shouldShowSuppliers: isSupplier,
        })
    }

    const [createCustomer, {
        loading: createLoading,
    }] = useMutation(CREATE_CUSTOMER)

    const [updateCustomer, {
        loading: updateLoading,
    }] = useMutation(EDIT_CUSTOMER)

    const [createSupplier, {
        loading: createSupplierLoading,
    }] = useMutation(CREATE_SUPPLIER)

    const [updateSupplier, {
        loading: updateSupplierLoading,
    }] = useMutation(EDIT_SUPPLIER)

    useEffect(() => {
        if (id) {
            setEditable(false)
            isSupplier ? getSupplier() : getCustomer()
        }
    }, [id])

    const removeSpecialCharacters = (str) => (str.replace(/\\\//g, '/').trim(' '))

    useEffect(() => {
        const customerData = isSupplier ? supplierData?.getSupplier?.supplier : data?.getCustomer?.customer
        const customerGroups = isSupplier ? supplierData?.getSupplier?.listSupplierGroups?.supplierGroups : data?.getCustomer?.listCustomerGroups?.customerGroups
        if (customerData) {
            const address = customerData?.address
            const preferences = customerData?.preferences
            const groupsData = customerGroups
            setBusinessName(customerData?.businessName)
            setPhone(customerData?.phoneNumber)
            setEmail(customerData?.emailAddress)
            setNipt(customerData?.issuerNUIS)
            setCity(address?.administrativeDistrictLevel1)
            setStreet(removeSpecialCharacters(address?.addressLine1))
            setUserState(STATE_LIST.find((e) => e.code === address?.country))
            setEmailUnsubsribed(preferences?.emailUnsubscribed)
            setPrevGroups([...groupsData])
            updateGroups(groupsData)
        }

    }, [data, supplierData])

    const checkInputs = () => {
        if (
            nipt === '' ||
            city === '' ||
            userPhone === '' ||
            street === '' ||
            state === ''
        ) {
            setDisabled(true)
        } else {
            setDisabled(false)
        }
    }

    useEffect(() => {
        checkInputs()
        const checkData = isSupplier ? supplierData?.getSupplier?.supplier : data?.getCustomer?.customer
        const checkGroups = isSupplier ? supplierData?.getSupplier?.listSupplierGroups?.supplierGroups : data?.getCustomer?.listCustomerGroups?.customerGroups
        if (checkData) {
            if ((_.isEqual({
                issuerNUIS: nipt,
                businessName: businessName,
                emailAddress: userEmail,
                address: {
                    addressLine1: street,
                    country: state.code,
                    administrativeDistrictLevel1: city,
                },
                phoneNumber: userPhone,
                preferences: {
                    emailUnsubscribed: emailUnsubsribed,
                },
            }, checkData)) && _.isEqual(checkGroups, groups)
            ) {
                setDisabled(true)
            } else {
                setDisabled(false)
            }
        }

    }, [nipt, businessName, userEmail, userPhone, emailUnsubsribed, street, city, state, groups])

    const openRow = (rowMap, groupId) => {
        setNeedForConfirmation(false)
        rowMap[groupId].manuallySwipeRow(-itemWidth)
    }

    const closeRow = (rowMap, groupId) => {
        rowMap[groupId].closeRow()
        setNeedForConfirmation(true)
    }

    const deleteRow = (rowMap, groupId) => {
        if (id) {
            if (isSupplier) {

                updateSupplier({
                    variables: {
                        id: id,
                        supplierGroupsToDelete: [groupId],
                    },
                })
                    .then(() => supplierRefetch())
                    .catch((er) => {
                        setErrorActive({
                            isVisible: true,
                            message: er,
                        })
                    })
            }
            else {
                updateCustomer({
                    variables: {
                        id: id,
                        customerGroupsToDelete: [groupId],
                    },
                })
                    .then(() => refetch())
                    .catch((er) => {
                        setErrorActive({
                            isVisible: true,
                            message: er,
                        })
                    })
            }
        } else {
            updateSelectedGroups(groups?.filter(element => element.id !== groupId))
        }
        closeRow(rowMap, groupId)
    }

    const renderItem = ({ item, index }) => (
        <Item
            {...item}
            onPress={() => editGroup(item)}
            isFirst={index === 0}
            isLast={index === groups.length - 1}
        />
    )

    const renderHiddenItem = ({ item, index }, rowMap) => (
        <HiddenItem
            needsConfirmation={needsConfirmation}
            onPress={() => openRow(rowMap, item.id)}
            onConfirm={() => deleteRow(rowMap, item.id)}
            onCancel={() => closeRow(rowMap, item.id)}
            loading={updateLoading}
            isFirst={index === 0}
            isLast={index === groups.length - 1}
        />
    )

    useEffect(() => {
        if (isNipt.test(nipt) && updatable) {
            getBusinessData(nipt)
            Keyboard.dismiss()
        }
    }, [nipt])

    const setBusinessDetails = (busName, address) => {
        setBusinessName(busName)
        setCity(address?.administrativeDistrictLevel1)
        setStreet(removeSpecialCharacters(address?.addressLine1))
        setUserState({
            'code': address?.country,
            'description': 'Albania',
            'type': address?.country,
        })
    }

    useEffect(() => {
        const businessDataa = businessData?.getBusinessGov?.customers
        if (businessDataa) {
            setBusinessAddresses(businessDataa)
            if (businessDataa?.length === 1) {
                const business = businessDataa?.[0]
                const address = business?.address
                setBusinessDetails(business?.businessName, address)
                setEditable(false)
                setNiptError(false)
            } else if (businessDataa?.length > 1) {
                setAddressDisabled(false)
                navigate('SelectCustomerAddress', { addresses: businessDataa, onSave: setBusinessDetails })
                setNiptError(false)
            } else if (businessDataa?.length === 0 || businessError) {
                setNiptError(true)
                setBusinessName('')
                setCity('')
                setStreet('')
                setUserState('')
                setPhone('')
                setDisabled(true)
            }
        }
    }, [businessData])

    const onButtonSave = () => {
        const groupIds = groups.map((element) => element.id)
        const prevGroupIds = prevGroups.map((element) => element.id)

        const groupsToEnable = groupIds?.filter(
            (element) => !prevGroupIds.includes(element),
        )
        const groupsToDelete = prevGroupIds?.filter(
            (element) => !groupIds.includes(element),
        )
        if (id) {
            if (isSupplier) {
                updateSupplier({
                    variables: {
                        id: id,
                        issuerNUIS: nipt,
                        businessName: businessName,
                        emailAddress: userEmail,
                        phoneNumber: userPhone,
                        addressLine1: street,
                        country: state?.code,
                        administrativeDistrictLevel1: city,
                        emailUnsubscribed: emailUnsubsribed,
                    },
                })
                    .then((res) => {
                        goBack()
                        onSave()
                    })
                    .catch((er) => {
                        setErrorActive({
                            isVisible: true,
                            message: er,
                        })
                    })
            }
            else {
                updateCustomer({
                    variables: {
                        id: id,
                        issuerNUIS: nipt,
                        businessName: businessName,
                        emailAddress: userEmail,
                        phoneNumber: userPhone,
                        addressLine1: street,
                        country: state?.code,
                        administrativeDistrictLevel1: city,
                        emailUnsubscribed: emailUnsubsribed,
                    },
                })
                    .then((res) => {
                        goBack()
                        onSave()
                    })
                    .catch((er) => {
                        setErrorActive({
                            isVisible: true,
                            message: er,
                        })
                    })
            }
        } else {
            if (isSupplier) {
                createSupplier({
                    variables: {
                        emailAddress: userEmail,
                        phoneNumber: userPhone,
                        businessName: businessName,
                        issuerNUIS: nipt,
                        addressLine1: street,
                        country: state?.code,
                        administrativeDistrictLevel1: city,
                        emailUnsubscribed: emailUnsubsribed,
                        supplierGroupsToEnable: groupsToEnable,
                        supplierGroupsToDelete: groupsToDelete,
                        type: 'BUSINESS',
                    },
                })
                    .then((res) => {
                        goBack()
                        onSave()
                    })
                    .catch((er) => {
                        setErrorActive({
                            isVisible: true,
                            message: er,
                        })
                    })
            } else {
                createCustomer({
                    variables: {
                        emailAddress: userEmail,
                        phoneNumber: userPhone,
                        businessName: businessName,
                        issuerNUIS: nipt,
                        addressLine1: street,
                        country: state?.code,
                        administrativeDistrictLevel1: city,
                        emailUnsubscribed: emailUnsubsribed,
                        customerGroupsToEnable: groupsToEnable,
                        customerGroupsToDelete: groupsToDelete,
                        type: 'BUSINESS',
                    },
                })
                    .then((res) => {
                        goBack()
                        onSave()
                    })
                    .catch((er) => {
                        setErrorActive({
                            isVisible: true,
                            message: er,
                        })
                    })
            }
        }
    }

    const onEndReached = () => {
        if (isSupplier) {
            if (!supplierData?.getSupplier?.listSupplierGroups?.nextToken) {
                return
            }

            supplierFetchMore({
                query: GET_BUSINESS_SUPPPLIER,
                variables: {
                    limit: limit,
                    nextToken: supplierData.getSupplier.listSupplierGroups.nextToken,
                    id: id,
                },
            })

        } else {
            if (!data?.getCustomer?.listCustomerGroups?.nextToken) {
                return
            }

            fetchMore({
                query: GET_BUSINESS_CUSTOMER,
                variables: {
                    limit: limit,
                    nextToken: data.getCustomer.listCustomerGroups.nextToken,
                    id: id,
                },
            })
        }
    }

    const onClearAddress = () => {
        setCity('')
        setStreet('')
        setUserState('')
        setEditable(true)
    }

    return (
        <>
            <SafeAreaView style={styles.container}>
                <SwipeList
                    data={groups}
                    renderItem={renderItem}
                    renderHiddenItem={renderHiddenItem}
                    rightOpenValue={-100}
                    onRowClose={() => setNeedForConfirmation(true)}
                    disableRightSwipe
                    setItemWidth={setItemWidth}
                    onEndReached={() => onEndReached()}
                    onRefresh={() => {
                        if (id) {
                            if (isSupplier) {
                                supplierRefetch()
                            } else {
                                refetch()
                            }
                        }
                        else {
                            if (called) {
                                businessRefetch()
                            }
                        }

                    }}
                    refreshing={false}
                    ItemSeparatorComponent={() => <View style={styles.separator} />}
                    ListHeaderComponent={<>
                        <Text i18nKey="demo_business_information" align="left" />
                        <InputWithTags
                            editable={editable}
                            label="certificate_nipt_label"
                            value={nipt}
                            onChangeText={(text) => {
                                setNipt(text)
                                setUpdatable(true)
                            }}
                            error={niptError}
                            autoCorrect={false}
                            autoCapitalize="characters"
                            inputContainerStyle={styles.inputContainer}
                            inputWrapperStyle={styles.itemSpacing}
                            labelOffset={{
                                x0: 0,
                                y0: -7,
                                x1: 0,
                                y1: 2,
                            }}
                        />
                        {niptError && <Text
                            i18nKey="customer_nipt_error"
                            color={colors.accent}
                            weight="light"
                            align="right"
                            size="footnote"
                        />}
                        <InputWithTags
                            label="location_label_business_name"
                            value={businessName}
                            onChangeText={setBusinessName}
                            autoCorrect={false}
                            editable={!id && _.isEmpty(businessData)}
                            inputContainerStyle={styles.inputContainer}
                            inputWrapperStyle={styles.itemSpacing}
                            labelOffset={{
                                x0: 0,
                                y0: -7,
                                x1: 0,
                                y1: 2,
                            }}
                        />
                        <InputWithTags
                            label="users_email_label"
                            value={userEmail}
                            onChangeText={setEmail}
                            autoCorrect={false}
                            autoCapitalize="none"
                            inputContainerStyle={styles.inputContainer}
                            inputWrapperStyle={styles.itemSpacing}
                            labelOffset={{
                                x0: 0,
                                y0: -7,
                                x1: 0,
                                y1: 2,
                            }}
                        />
                        <InputPhone
                            label="users_phone_label"
                            value={userPhone}
                            onChangeText={setPhone}
                            autoCorrect={false}
                            inputContainerStyle={styles.inputContainer}
                            inputWrapperStyle={styles.itemSpacing}
                            labelOffset={{
                                x0: 0,
                                y0: -7,
                                x1: 0,
                                y1: 2,
                            }}
                            keyboardType="phone-pad"
                        />
                        <TouchableOpacity
                            style={styles.selectableAddress}
                            activeOpacity={0.4}
                            onPress={() => navigate('SelectCustomerAddress', {
                                addresses: businessAddresses,
                                onSave: setBusinessDetails,
                                nipt: nipt,
                                businessName: businessName,
                            })}
                            disabled={addressDisabled}
                        >
                            {!addressDisabled && <Icon source={images.chevronRight} size={24} color={colors.accent} />}
                        </TouchableOpacity>
                        <View style={styles.addressLabelWrapper}>
                            <Text i18nKey="demo_address_label" align="left" />
                            {(city || street || state) && !id ? <TouchableOpacity style={styles.clearAddress} onPress={onClearAddress} >
                                <Text i18nKey="customer_clear_address_label" style={styles.lineHeight} color={colors.primaryText} />
                                <Icon source={images.closeS} size={14} color="gray" style={styles.leftSmallSpacing} />
                            </TouchableOpacity> : null}
                        </View>

                        <InputWithTags
                            label="customers_street_label"
                            value={street}
                            onChangeText={setStreet}
                            autoCorrect={false}
                            editable={editable}
                            inputContainerStyle={styles.inputContainer}
                            inputWrapperStyle={styles.itemSpacing}
                            labelOffset={{
                                x0: 0,
                                y0: -7,
                                x1: 0,
                                y1: 2,
                            }}
                        />
                        <InputWithTags
                            label="demo_city_label"
                            value={city}
                            onChangeText={setCity}
                            autoCorrect={false}
                            editable={editable}
                            inputContainerStyle={styles.inputContainer}
                            inputWrapperStyle={styles.itemSpacing}
                            labelOffset={{
                                x0: 0,
                                y0: -7,
                                x1: 0,
                                y1: 2,
                            }}
                        />
                        <TouchableInput
                            onPress={() => openStateSelector()}
                            label="customers_state_label"
                            value={state ? state.description : ''}
                            icon={images.filledDownArrow}
                            iconSize={16}
                            autoCorrect={false}
                            autoCapitalize="none"
                            disabled={!editable}
                            editable={false}
                            style={styles.stateSelector}
                        />
                        <Text i18nKey="customers_other_information_label" align="left" style={styles.topSpacing} />
                        <Button
                            title="customer_add_group_button"
                            icon={images.plus}
                            variant={'secondary'}
                            style={styles.addButton}
                            onPress={() => addGroups()}
                        />
                    </>}
                    ListFooterComponent={<>
                        <Text i18nKey={isSupplier ? 'supplier_consent_label' : 'customer_consent_label'} align="left" style={styles.topSpacing} />
                        <View style={styles.checkItemWrapper}>
                            <CheckItem
                                label="customer_email_receipt_label"
                                checked={!emailUnsubsribed}
                                onPress={() => setEmailUnsubsribed(prev => !prev)}
                            />
                        </View>
                    </>}
                />
            </SafeAreaView>
            <LoaderModal loading={businessLoading} message="customer_searching_data" />
            <Button
                title="users_save_button"
                variant={disabled ||
                    (createLoading || createSupplierLoading) ||
                    (updateLoading || updateSupplierLoading) ?
                    'disabled' :
                    'active'}
                style={styles.saveButton}
                onPress={() => onButtonSave()}
                loader={(createLoading || createSupplierLoading) || (updateLoading || updateSupplierLoading)}
                loaderComponent={<Loader source={animations.vfLoaderWhite} size={24} />}
            />
            <Modal
                isVisible={isErrorActive.isVisible}
                onBackButtonPress={() => setErrorActive({
                    isVisible: false,
                    message: '',
                })}
                onBackdropPress={() => setErrorActive({
                    isVisible: false,
                    message: '',
                })}
                animationIn="fadeIn"
                animationOut="fadeOut"
            >
                <ModalContent onClose={() => setErrorActive({
                    isVisible: false,
                    message: '',
                })}>
                    <Icon source={images.delete} style={styles.errorIcon} />
                    <Text i18nKey="orders_error_label" color={colors.accent} size="h5" align="center" weight="bold" style={styles.errorTitle} />
                    <Text i18nKey={getErrorMessage(isErrorActive.message)} align="center" />
                </ModalContent>
            </Modal>
            <BottomSheetSelector
                {...modal}
                closeModal={closeModal}
                searchValue={searchValue}
                setSearchValue={setSearchValue}
            />
        </>
    )
}

BusinessForm.propTypes = {
    navigate: PropTypes.func,
    id: PropTypes.string,
    goBack: PropTypes.func,
    onSave: PropTypes.func,
    isSupplier: PropTypes.bool,
}

export default BusinessForm
