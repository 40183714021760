import { styleSheetFactory } from '../../../../Themes/Colors'

const styles = styleSheetFactory(() => ({
  fillRemaining: {
    flexGrow: 1,
  },
  detailsContainer: {
    flexGrow: 1,
    justifyContent: 'space-around',
  },
  animationContainer: {
    flexGrow: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  printAnimation: {
    width: 200,
  },
  succesAanimation: {
    width: 100,
  },
  details: {
    flexGrow: 1,
    justifyContent: 'space-around',
    marginHorizontal: 15,
  },
  buttonContainer: {
    height: 50,
    margin: 15,
    marginBottom: 30,
  },
  printButton: {
    flex: 1,
    height: 50,
  },
  startNewButton: {
    height: 50,
    marginLeft: 7.5,
    justifyContent: 'center',
    alignItems: 'center',
    flex: 0.6,
  },
  shareButton: {
    height: 50,
    marginRight: 7.5,
    justifyContent: 'center',
    alignItems: 'center',
    flex: 0.4,
  },
  inlineButtons: {
    flexDirection: 'row',
    alignItems: 'center',
    margin: 15,
    marginBottom: 30,
  },
}))

export default styles
