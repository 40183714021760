import React, { useContext, useEffect, useState } from 'react'
import {
    LayoutAnimation,
    ScrollView,
    View,
    TouchableOpacity,
    Platform,
} from 'react-native'
import RNFS from 'react-native-fs'
import DocumentPicker from 'react-native-document-picker'
import { useTheme } from 'react-native-themed-styles'
import PropTypes from 'prop-types'
import { useMutation } from '@apollo/client'
import { useDispatch, useSelector } from 'react-redux'

import {
    Text,
    Button,
    Icon,
    InputWithTags,
    InputWithIcon,
    DatePickerModal,
    CertificateInputWeb,
} from '../../../../Components'
import { SAVE_EINVOICE } from '../../Payment.Schema'

import Step from '../Step'

import { updateStepKey, updateCalculatedUBL, updatePaymentAttribute } from '../../Payment.Actions'
import { getReferences, getEinvoiceCalculated, getInvoiceId } from '../../Payment.Selectors'

import Theme, { opacityConfig } from '../../../../Themes/Theme'
import images from '../../../../Themes/Images'

import { useConfig } from '../../../../Utils'

import themedStyles, { inputStyles, fileItemStyles } from './FourthStep.Styles'

const DOCUMENT_IMPORT_TYPE = Platform.select({
    ios: ['public.data'],
    android: [DocumentPicker.types.allFiles],
})

function TouchableInput({
    onPress,
    label,
    value,
    setValue,
    icon,
    iconSize,
    ...rest
}) {
    const [styles] = useTheme(inputStyles)

    return (
        <TouchableOpacity
            style={styles.touchableInput}
            onPress={onPress}
        >
            <InputWithIcon
                label={label}
                value={value}
                onChangeText={setValue}
                icon={icon}
                iconSize={iconSize}
                labelOffset={{
                    x0: 0,
                    y0: -7,
                    x1: 0,
                    y1: 2,
                }}
                {...rest}
            />
        </TouchableOpacity>
    )
}
TouchableInput.defaultProps = {
    iconSize: 24,
}
TouchableInput.propTypes = {
    icon: PropTypes.any,
    onPress: PropTypes.func,
    label: PropTypes.string,
    value: PropTypes.string,
    setValue: PropTypes.func,
    iconSize: PropTypes.number,
}

function FileItem({ onPress, attached, fileName, cleanFile }) {
    const [styles] = useTheme(fileItemStyles)
    const { colors } = useContext(Theme)

    return (
        <TouchableOpacity
            style={styles.fileContainer}
            onPress={onPress}
            disabled={attached}>
            {!attached ? (
                <View style={styles.subFileContainer}>
                    <Text
                        i18nKey="certificate_choose_label"
                        color={colors.secondaryAccent}
                        size="h4"
                    />
                    <Text
                        i18nKey="einvoice_file_size_info"
                        weight="light"
                        size="footnote"
                    />
                    <View style={styles.importIcon}>
                        <Icon
                            source={images.attachFile}
                            size={35}
                            color={colors.secondaryAccent}
                            style={styles.attachFile}
                        />
                    </View>
                </View>
            ) : (
                <View
                    style={styles.attachedFileContainer}>
                    <View
                        style={styles.attachedFileSubcontainer}>
                        <Icon
                            source={images.uploadedSuccess}
                            size={35}
                            style={styles.uploadedSuccess}
                        />
                        <Text i18nKey={fileName} numberOfLines={1} />
                    </View>
                    <TouchableOpacity onPress={cleanFile} style={styles.clearContainer}>
                        <Icon
                            source={images.close}
                            color={colors.secondaryIcon}
                            size={12}
                        />
                    </TouchableOpacity>
                </View>
            )}
        </TouchableOpacity>
    )
}
FileItem.propTypes = {
    onPress: PropTypes.func,
    attached: PropTypes.bool,
    fileName: PropTypes.string,
    cleanFile: PropTypes.func,
}

function FourthStep({
    goBack,
    stepperLength,
    index,
    addListener,
    dispatch,
}) {
  const { switchLocation: { deviceId, locationId } } = useConfig()

    const [styles] = useTheme(themedStyles)
    const { colors } = useContext(Theme)

    const [saveEinvoice, { loading }] = useMutation(SAVE_EINVOICE)
    const [disabled, setDisabled] = useState(false)
    const dispatchAction = useDispatch()

    const updateReference = (key, value) => {
        dispatchAction(updateStepKey('references', key, value))
    }
    const setPaymentStatus = (value) => dispatchAction(updatePaymentAttribute('paymentStatus', value))

    const referenceData = useSelector(getReferences)
    const calculatedUBL = useSelector(getEinvoiceCalculated)
    const einvoiceId = useSelector(getInvoiceId)

    const [date, setDate] = useState({
        date: referenceData.einvoiceDate,
        isShown: false,
    })
    const onButtonPress = (step) => {
        LayoutAnimation.configureNext(opacityConfig)
        setPaymentStatus(step)
    }

    const attachfile = async () => {
        try {
            const res = await DocumentPicker.pick({
                type: DOCUMENT_IMPORT_TYPE,
            })
            const uri = res[0]?.uri
            RNFS.readFile(decodeURI(uri), 'base64')
                .then((dataa) => {
                    updateReference('contents', dataa)
                    updateReference('mime', res[0]?.type)
                    updateReference('fileName', res[0]?.name)
                })
                .catch((er) => { })
        } catch (err) {
            if (DocumentPicker.isCancel(err)) {
            } else {
                throw err
            }
        }
    }

    //Note: Function only for web :
    const attachFileWeb = (acceptedFiles) => {
        if (acceptedFiles.length > 0) {
            // eslint-disable-next-line no-undef
            const reader = new FileReader()
            reader.readAsDataURL(acceptedFiles[0])
            reader.onload = function () {
                updateReference('contents', reader.result.replace(`data:${acceptedFiles[0]?.type};base64,`, ''))
                updateReference('mime', acceptedFiles[0]?.type)
                updateReference('fileName', acceptedFiles[0]?.name)
            }
            reader.onerror = function (error) {
            }
        } else {
        }
    }


    const mapAdditionalDoc = (docData) => {
        const initial = {
            id: docData?.additionalDocReference,
            description: docData?.description,
        }
        let additionalDocumentReferences = []
        if (docData?.uri && docData?.contents) {
            additionalDocumentReferences.push(
                {
                    ...initial,
                    uri: docData.uri,
                },
                {
                    ...initial,
                    binObj: {
                        filename: docData.fileName,
                        contents: docData.contents,
                        mime: docData.mime,
                    },
                }
            )
        } else if (docData?.uri) {
            additionalDocumentReferences.push({
                ...initial,
                uri: docData.uri,
            })
        } else if (docData?.contents) {
            additionalDocumentReferences.push({
                ...initial,
                binObj: {
                    filename: docData.fileName,
                    contents: docData.contents,
                    mime: docData.mime,
                },
            })
        } else {
            additionalDocumentReferences = null
        }
        return additionalDocumentReferences
    }

    const next = () => {
        if (einvoiceId) {
            const newUbl = {
                ...calculatedUBL,
                buyerReference: referenceData.buyerReference,
                orderReference: {
                    id: referenceData?.orderReference || null,
                    salesOrderId: referenceData?.salesOrderId || null,
                },
                contractDocumentReference: {
                    id: referenceData?.contractReference || null,
                },
                additionalDocumentReferences: mapAdditionalDoc(referenceData),
                projectReference: {
                    id: referenceData?.projectReference || null,
                },
            }
            dispatchAction(updateCalculatedUBL(newUbl))
            saveEinvoice({
                variables: {
                    locId: locationId,
                    cashRegisterId: deviceId,
                    eInvoiceInput: {
                        ublInvoice: newUbl,
                    },
                },
            })
                .then(() => {
                    setPaymentStatus('delivery')
                })
        }
    }

    useEffect(() => {
        if (Boolean(referenceData?.fileName) !== Boolean(referenceData?.additionalDocReference)) {
            setDisabled(true)
        } else {
            setDisabled(false)

        }
    }, [referenceData?.fileName, referenceData?.additionalDocReference])

    return (
        <Step
            title="header_reference_einvoice"
            goBack={goBack}
            stepperLength={stepperLength}
            index={index}
            addListener={addListener}
            dispatch={dispatch}
        >
            <ScrollView
                contentContainerStyle={styles.fillRemaining}
                showsVerticalScrollIndicator={false}
            >
                <View>
                    <Text i18nKey="einvoice_references_reference_header" weight="bold" style={styles.sectionText} />
                    <InputWithTags
                        label="einvoice_references_buyer"
                        onChangeText={text => updateReference('buyerReference', text)}
                        value={referenceData?.buyerReference}
                        autoCompleteType="off"
                        inputWrapperStyle={styles.input}
                    />
                    <InputWithTags
                        label="einvoice_references_project"
                        value={referenceData?.projectReference}
                        onChangeText={text => updateReference('projectReference', text)}
                        autoCompleteType="off"
                        inputWrapperStyle={styles.input}
                    />
                    <InputWithTags
                        label="einvoice_references_contract"
                        value={referenceData?.contractReference}
                        onChangeText={text => updateReference('contractReference', text)}
                        autoCompleteType="off"
                        inputWrapperStyle={styles.input}
                    />
                    <InputWithTags
                        label="einvoice_references_order_form"
                        value={referenceData?.orderReference}
                        onChangeText={text => updateReference('orderReference', text)}
                        autoCompleteType="off"
                        inputWrapperStyle={styles.input}
                    />
                    <InputWithTags
                        label="einvoice_references_sales_order"
                        value={referenceData?.salesOrderId}
                        onChangeText={text => updateReference('salesOrderId', text)}
                        autoCompleteType="off"
                        inputWrapperStyle={styles.input}
                    />
                </View>
                <View>
                    <Text i18nKey="einvoice_references_document_header" weight="bold" style={styles.sectionText} />
                    <InputWithTags
                        label="einvoice_references_additional_docs"
                        value={referenceData?.additionalDocReference}
                        onChangeText={text => updateReference('additionalDocReference', text)}
                        autoCompleteType="off"
                        inputWrapperStyle={styles.input}
                    />
                    <InputWithTags
                        label="einvoice_references_docs_location"
                        value={referenceData?.uri}
                        onChangeText={text => updateReference('uri', text)}
                        autoCompleteType="off"
                        inputWrapperStyle={styles.input}
                    />
                    <InputWithTags
                        label="einvoice_references_docs_description"
                        value={referenceData?.description}
                        onChangeText={text => updateReference('description', text)}
                        autoCompleteType="off"
                        inputWrapperStyle={styles.input}
                    />
                </View>
                <View>
                    <Text i18nKey="einvoice_references_document_footer" style={styles.sectionText} />
                    {Platform.OS === 'web' ?
                        <CertificateInputWeb
                            formatLabel="einvoice_file_size_info"
                            accept="application/*"
                            onDrop={attachFileWeb}
                            attached={Boolean(referenceData?.fileName)}
                            fileName={referenceData?.fileName}
                            cleanFile={() => {
                                updateReference('contents', '')
                                updateReference('fileName', '')
                                updateReference('mime', '')
                            }}

                        />
                        :
                        <FileItem
                            onPress={attachfile}
                            attached={Boolean(referenceData?.fileName)}
                            fileName={referenceData?.fileName}
                            cleanFile={() => {
                                updateReference('contents', '')
                                updateReference('fileName', '')
                                updateReference('mime', '')
                            }}
                        />
                    }
                </View>
            </ScrollView>
            <View style={styles.buttons}>
                <Button
                    title="einvoice_references_buyer_button"
                    variant={'preActive'}
                    icon={images.arrowLeft}
                    iconColor={colors.accent}
                    style={styles.rightButton}
                    onPress={() => onButtonPress('buyerSeller')}
                />
                <Button
                    title="einvoice_references_delivery_button"
                    variant={loading || disabled ? 'disabled' : 'active'}
                    rightComponent={!loading && <Icon
                        size={20}
                        source={images.arrowRight}
                        color={colors.white}
                        style={styles.deliveryButton}
                    />}
                    onPress={next}
                    loader={loading}
                    style={styles.leftButton}
                />
            </View>
            <DatePickerModal
                onClose={() => setDate(prev => ({
                    ...prev,
                    isShown: false,
                }))}

                isVisible={date.isShown}
                onDateChange={(pickedDate) => setDate(prev => ({
                    ...prev,
                    date: pickedDate,
                }))}
                date={date?.date ? new Date(date?.date) : new Date()}
                onButtonPress={() => {
                    updateReference('einvoiceDate', date?.date)
                    setDate(prev => ({
                        ...prev,
                        isShown: false,
                    }))
                }}
            />
        </Step>
    )
}

FourthStep.propTypes = {
    goBack: PropTypes.func,
    stepperLength: PropTypes.number,
    index: PropTypes.number,
    addListener: PropTypes.func,
    dispatch: PropTypes.func,
}

export default FourthStep

