import React, { useState, useContext } from 'react'
import PropTypes from 'prop-types'
import { TouchableOpacity, View, LayoutAnimation } from 'react-native'
import { useTheme } from 'react-native-themed-styles'
import { useDispatch, useSelector } from 'react-redux'

import {
    Button,
    Icon,
    Text,
    Section,
    Type,
    BottomSheetSelector,
    TouchableInput,
} from '../../../../../Components'

import BillingPeriod from '../BillingPeriod'

import ContentManager from '../../../../../Utils/ContentManager'

import Theme, { opacityConfig } from '../../../../../Themes/Theme'
import images from '../../../../../Themes/Images'

import DatesModalWithLimit from '../DatesModalWithLimit'

import {
    getIsReverseCharge,
    getPaymentBankAccount,
    getPaymentCustomer,
    getPaymentMethod,
    getPaymentOrderSubtype,
    getPaymentOrderType,
} from '../../../Payment.Selectors'
import { updatePaymentAttribute } from '../../../Payment.Actions'

import themedStyles, { stateitemStyles } from './ReverseCharge.Styles'

const ID_TYPES = {
    'NUIS': 'certificate_nipt_label',
    'ID': 'certificate_id_label',
    'PASS': 'customer_passport_number_label',
    'VAT': 'customer_vat_number_label',
    'TAX': 'customer_tax_number_label',
    'SOC': 'customer_soc_number_label',
}

const ACTION_TYPES = [{
    code: 'AUTO INVOICE',
    description: 'payment_self_charge_action_invoice',
    type: false,
}, {
    code: 'REVERSE CHARGE',
    description: 'payment_self_charge_charge',
    type: true,
}]

const INVOICE_TYPES = [{
    code: 'CASH',
    description: 'payment_self_charge_cash_description',
    type: 'CASH',
}, {
    code: 'NONCASH',
    description: 'payment_self_charge_noncash_description',
    type: 'NONCASH',
}]

const REASON_TYPES = [
    {
        code: 'AGREEMENT',
        description: 'payment_self_charge_reason_agreement',
        type: 'SELF_AGREEMENT',
    },
    {
        code: 'DOMESTIC',
        description: 'payment_self_charge_reason_domestic',
        type: 'SELF_DOMESTIC',
    },
    {
        code: 'ABROAD',
        description: 'payment_self_charge_reason_abroad',
        type: 'SELF_ABROAD',
    },
    {
        code: 'SELF',
        description: 'payment_self_charge_reason_self',
        type: 'SELF',
    },
    {
        code: 'OTHER',
        description: 'payment_self_charge_reason_other',
        type: 'SELF_OTHER',
    },
]
function StateType({ item, selected, onPress }) {
    const [itemStyle] = useTheme(stateitemStyles)
    const isSelected = selected === item

    return (
        <View style={itemStyle.container}>
            <TouchableOpacity
                onPress={onPress}
                style={itemStyle['itemSelected_' + isSelected]}
                activeOpacity={0.6}
            >
                <Text
                    i18nKey={item}
                    size="footnote"
                    style={itemStyle['itemTextSelected_' + isSelected]}
                />
            </TouchableOpacity>
        </View>
    )
}
StateType.propTypes = {
    item: PropTypes.string,
    selected: PropTypes.object,
    onPress: PropTypes.func,
}

function ReverseChargeType({
    navigate,
    paymentMethods,
}) {
    const [styles] = useTheme(themedStyles)
    const { colors } = useContext(Theme)
    const { translate } = ContentManager
    const dispatchAction = useDispatch()

    const [searchValue, setSearchValue] = useState('')

    const customer = useSelector(getPaymentCustomer)
    const isReverseCharge = useSelector(getIsReverseCharge)
    const paymentMethod = useSelector(getPaymentMethod)
    const orderType = useSelector(getPaymentOrderType)
    const orderSubType = useSelector(getPaymentOrderSubtype)
    const bankAccount = useSelector(getPaymentBankAccount)

    const setIsReverseCharge = (value) => dispatchAction(updatePaymentAttribute('isReverseCharge', value))
    const setPaymentMethod = (value) => dispatchAction(updatePaymentAttribute('paymentMethod', value))
    const setOrderSubType = (value) => dispatchAction(updatePaymentAttribute('orderSubType', value))
    const setOrderType = (value) => dispatchAction(updatePaymentAttribute('orderType', value))
    const setCustomer = (value) => dispatchAction(updatePaymentAttribute('customer', value))
    const setBankAccount = (value) => dispatchAction(updatePaymentAttribute('bankAccount', value))

    const mapCustomer = (customerData) => {
        const response = []
        const customerAddress = customerData?.address
        const NIPT = customerData?.issuerNUIS
        if (customerData?.type === 'BUSINESS') {
            response.push(
                {
                    label: 'einvoice_details_nipt_label',
                    value: NIPT,
                },
                {
                    label: 'einvoice_commercial_name_label',
                    value: customerData?.businessName,
                },
                {
                    label: 'einvoice_details_address_label',
                    value: customerAddress?.addressLine1,
                },
                {
                    label: 'einvoice_details_city_label',
                    value: customerAddress?.administrativeDistrictLevel1,
                },
                {
                    label: 'einvoice_details_state_label',
                    value: customerAddress?.country,
                },
                {
                    label: 'users_email_label',
                    value: customerData?.emailAddress,
                },
                {
                    label: 'users_phone_label',
                    value: customerData?.phoneNumber,
                }

            )
        } else if (customerData?.reference?.name) {
            response.push(
                {
                    label: `${ID_TYPES[customerData?.reference?.name]}`,
                    value: customerData?.reference?.value,
                },
                {
                    label: 'users_first_name_label',
                    value: `${customerData?.givenName} ${customerData?.familyName}`,
                },
                {
                    label: 'einvoice_details_address_label',
                    value: customerAddress?.addressLine1,
                },
                {
                    label: 'einvoice_details_city_label',
                    value: customerAddress?.administrativeDistrictLevel1,
                },
                {
                    label: 'einvoice_details_state_label',
                    value: customerAddress?.country,
                },
                {
                    label: 'users_email_label',
                    value: customerData?.emailAddress,
                },
                {
                    label: 'users_phone_label',
                    value: customerData?.phoneNumber,
                }

            )
        }
        else {
            response.push(
                {
                    label: 'users_first_name_label',
                    value: `${customerData?.givenName} ${customerData?.familyName}`,
                },
                {
                    label: 'einvoice_details_address_label',
                    value: customerAddress?.addressLine1,
                },
                {
                    label: 'einvoice_details_city_label',
                    value: customerAddress?.administrativeDistrictLevel1,
                },
                {
                    label: 'einvoice_details_state_label',
                    value: customerAddress?.country,
                },
                {
                    label: 'users_email_label',
                    value: customerData?.emailAddress,
                },
                {
                    label: 'users_phone_label',
                    value: customerData?.phoneNumber,
                }

            )
        }
        return response
    }
    const [customerItems, setCustomerItems] = useState(mapCustomer(customer) || [])

    const [modal, setModal] = useState({
        isVisible: false,
        data: [],
        title: '',
        placeholder: '',
        selected: undefined,
        select: () => { },
        Item: Type,
    })

    const closeModal = () => {
        setModal(prev => ({ ...prev, isVisible: false }))
        setSearchValue('')
    }

    const openActionSelector = () => {
        setModal({
            isVisible: true,
            data: ACTION_TYPES,
            title: 'supporting_invoice_invoice_type_label',
            placeholder: 'supporting_invoice_invoice_type_label',
            selected: isReverseCharge,
            select: setIsReverseCharge,
            searchKey: 'code',
            Item: Type,
        })
    }

    const openPaymentMethodSelector = () => {
        setModal({
            isVisible: true,
            data: paymentMethods[orderType?.code],
            title: 'payment_total_method',
            placeholder: 'payment_total_method',
            selected: paymentMethod,
            select: setPaymentMethod,
            searchKey: 'code',
            Item: Type,
        })
    }

    const openReasonSelector = () => {
        setModal({
            isVisible: true,
            data: REASON_TYPES,
            title: 'payment_self_charge_reason_label',
            placeholder: 'payment_self_charge_reason_placeholder',
            selected: orderSubType,
            select: (val) => setOrderSubType(
                {
                    type: val.type,
                    code: val.code,
                    description: val.description,
                }
            ),
            searchKey: 'code',
            Item: Type,
        })
    }

    const openInvoiceTypeSelector = () => {
        setModal({
            isVisible: true,
            data: INVOICE_TYPES,
            title: 'payment_self_charge_payment_type',
            placeholder: 'payment_self_charge_payment_type',
            selected: orderType,
            select: (val) => {
                setOrderType(val)
                setPaymentMethod({})
            },
            searchKey: 'code',
            Item: Type,
        })
    }

    const getBankAccount = (bankAcc) => setBankAccount(bankAcc)

    const setCustomerData = (customerData) => {
        setCustomer(customerData)
        setCustomerItems(mapCustomer(customerData))
    }

    return (
        <>
            <View style={styles.content}>
                <View style={styles.topContent}>
                    <Text i18nKey="supporting_invoice_invoice_type_label" weight="bold" style={styles.sectionTitle} />
                    <TouchableInput
                        onPress={openActionSelector}
                        label="payment_self_charge_type_label"
                        value={isReverseCharge?.code ? `${isReverseCharge.code} - ${translate(isReverseCharge.description)}` : ''}
                        icon={images.filledDownArrow}
                        iconSize={16}
                        autoCorrect={false}
                        autoCapitalize="none"
                        editable={false}
                    />
                    <Text i18nKey="payment_self_charge_payment_type" weight="bold" style={styles.sectionTitle} />
                    <TouchableInput
                        onPress={openInvoiceTypeSelector}
                        label="payment_self_charge_type_label"
                        value={orderType?.code ? orderType.code : ''}
                        icon={images.filledDownArrow}
                        iconSize={16}
                        autoCorrect={false}
                        autoCapitalize="none"
                        editable={false}
                    />
                    <Text i18nKey="payment_total_method" weight="bold" style={styles.sectionTitle} />
                    <TouchableInput
                        onPress={openPaymentMethodSelector}
                        label="einvoice_payment_button"
                        value={paymentMethod.code ? `${paymentMethod.code} - ${translate(paymentMethod.description)}` : ''}
                        icon={images.filledDownArrow}
                        iconSize={16}
                        autoCorrect={false}
                        autoCapitalize="none"
                        editable={false}
                        disabled={!orderType?.code}
                    />
                    {bankAccount?.id ? <>
                        <View style={styles.bankHeading}>
                            <Text style={styles.sectionText} i18nKey="einvoice_payment_bank_account_label" />
                            <TouchableOpacity style={styles.removeBuyer}
                                onPress={() => {
                                    setBankAccount({})
                                    LayoutAnimation.configureNext(opacityConfig)
                                }}>
                                <Icon source={images.closeIcon} color={colors.secondaryIcon} size={16} />
                            </TouchableOpacity>
                        </View>
                        <View style={styles.bankDetailsWrapper}>
                            <Text i18nKey={`${bankAccount.iban} (${bankAccount.currency})`} />
                            <Text i18nKey={bankAccount.bankName} weight="light" />
                        </View>
                    </>
                        : (paymentMethod.code && orderType.code === 'NONCASH') ? < Button title={'bank_accounts_add_button_label'}
                            variant="secondary"
                            style={styles.bankAccount}
                            onPress={() => navigate('BankAccounts', {
                                selectable: true,
                                updateSelection: getBankAccount,
                            })} /> : null}
                    <>
                        <Text i18nKey="payment_self_charge_reason_label" weight="bold" style={styles.sectionTitle} />
                        <TouchableInput
                            onPress={() => openReasonSelector()}
                            label="payment_self_charge_reason_placeholder"
                            value={orderSubType?.code ? `${orderSubType.code} - ${translate(orderSubType.description)}` : ''}
                            icon={images.filledDownArrow}
                            iconSize={16}
                            autoCorrect={false}
                            autoCapitalize="none"
                            editable={false}
                        />
                    </>
                </View>
                <BillingPeriod style={styles.horizontalMargin} />
                <View style={styles.customerHeader}>
                    <Text i18nKey="payment_self_charge_supplier_label" weight="bold" />
                    {!customer?.id ? null :
                        <TouchableOpacity style={styles.removeBuyer} onPress={() => {
                            LayoutAnimation.configureNext(opacityConfig)
                            setCustomer({})
                            setCustomerItems([])
                        }}>
                            <Icon source={images.closeIcon} color={colors.secondaryIcon} size={18} />
                        </TouchableOpacity>
                    }
                </View>
                {(!customer?.id) ? <>
                    <Button
                        title="payment_self_charge_supplier_button"
                        icon={images.addCustomer}
                        iconColor={colors.accent}
                        iconStyle={styles.rightSpacing}
                        variant="preActive"
                        style={styles.addCustomer}
                        onPress={() => navigate('AllCustomers', {
                            selectable: true,
                            preselectedCustomer: customer,
                            updateSelection: setCustomerData,
                            isSupplier: true,
                        })}
                    />
                </> : <Section data={customerItems} />}
            </View>
            <DatesModalWithLimit />
            <BottomSheetSelector
                {...modal}
                closeModal={closeModal}
                searchValue={searchValue}
                setSearchValue={setSearchValue}
            />
        </>
    )
}


ReverseChargeType.propTypes = {
    navigate: PropTypes.func,
    paymentMethods: PropTypes.array,
}

export default ReverseChargeType


