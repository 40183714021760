import React from 'react'
import PropTypes from 'prop-types'
import RNModal from 'react-native-modal'

function Modal(props) {
  const {
    isVisible = false,
    cancellable,
    children,
    ...rest
  } = props

  return (
    <RNModal
      isVisible={isVisible}
      animationType="fade"
      transparent
      hideModalContentWhileAnimating
      backdropTransitionOutTiming={0}
      useNativeDriver
      {...rest}
    >
      {children}
    </RNModal>
  )
}

Modal.defaultProps = {
  isVisible: false,
  cancellable: true,
}

Modal.propTypes = {
  isVisible: PropTypes.bool,
  cancellable: PropTypes.bool,
  children: PropTypes.any,
}

export default Modal
