import React, { useRef } from 'react'
import { TouchableOpacity, Animated } from 'react-native'
import PropTypes from 'prop-types'

const AnimatedTouchableOpacity = Animated.createAnimatedComponent(TouchableOpacity)

function AnimatedTouchable({
  onPressIn,
  onPressOut,
  childern,
  ...rest
}) {
  const anim = useRef(new Animated.Value(1)).current
  const animate = value => {
    Animated.spring(anim, {
      toValue: value,
      useNativeDriver: true,
    }).start()
  }

  const pressIn = () => {
    animate(1.05)
    onPressIn()
  }
  const pressOut = () => {
    animate(1)
    onPressOut()
  }

  return (
    <AnimatedTouchableOpacity
      {...rest}
      onPressIn={pressIn}
      onPressOut={pressOut}
      activeOpacity={0.7}
      >
        {childern}
    </AnimatedTouchableOpacity>
  )
}

AnimatedTouchable.defaultProps = {
  onPressIn: () => {},
  onPressOut: () => {},
}

AnimatedTouchable.propTypes = {
  onPressIn: PropTypes.func,
  onPressOut: PropTypes.func,
  childern: PropTypes.any,
}

export default AnimatedTouchable

