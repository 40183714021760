import {styleSheetFactory} from '../../Themes/Colors'

const styles = styleSheetFactory(colors => ({
  container: {
    position: 'relative',
    height: 50,
    backgroundColor: colors.background,
    flexDirection: 'row',
    // justifyContent: 'space-around',
    // alignItems: 'center',
    margin: 15,
    marginTop: 0,
    borderRadius: 9,
    overflow: 'hidden',
  },
  item: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    margin: 3,
    borderRadius: 6,
  },
  itemSelected_true: {
    backgroundColor: colors.white,
  },
  iconSelected_true: {
    tintColor: colors.accent,
  },
  iconSelected_false: {
    tintColor: colors.placeholder,
  },
}))

export default styles
