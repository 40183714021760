import { Platform } from 'react-native'

import { styleSheetFactory } from '../../../Themes/Colors'

const styles = styleSheetFactory(colors => ({
    container: {
        flex: 1,
    },
    contentContainer: {
        flexGrow: 1,
        justifyContent: 'center',
    },
    leftButton: {
        marginRight: 15,
    },
    mainButton: {
        flex: 2,
        height: 50,
    },
    buttons: {
        flexDirection: 'row',
        marginBottom: 15,
        marginTop: 10,
        marginHorizontal: 15,
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    shadow: {
        marginVertical: 30,
        alignItems: 'center',
        shadowColor: colors.black,
        shadowOffset: {
            width: 0,
            height: -1,
        },
        shadowOpacity: 0.16,
        shadowRadius: 6,
        marginHorizontal: 12,
    },
    successContainer: {
        flex: 1,
        // alignItems: 'center',
        width: '100%',
        marginHorizontal: 10,
        padding: 20,
        backgroundColor: colors.white,
        borderRadius: 6,
    },
    uploadingContainer: {
        alignItems: 'center',
        marginHorizontal: 10,
        padding: 20,
        backgroundColor: colors.background,
        borderRadius: 6,
    },
    printAnimation: {
        alignSelf: 'center',
        height: 125,
        width: 125,
        marginBottom: 30,
    },
    details: {
        flex: 1,
        borderColor: colors.separator,
        borderTopWidth: 1,
        marginTop: 20,
    },
    topSpacing: {
        marginTop: 40,
    },
}))
const optionStyles = styleSheetFactory(colors => ({
    container: {
        height: 50,
        width: 50,
        backgroundColor: colors.tertiaryBackground,
        justifyContent: 'center',
        alignItems: 'center',
        // marginLeft: 15,
        borderRadius: 7,
        position: 'relative',
    },
    containerDisabled_true: {
        backgroundColor: colors.disabled,
    },
    statusContainer: {
        minWidth: 16,
        height: 16,
        padding: 2,
        backgroundColor: colors.accent,
        position: 'absolute',
        top: 8,
        right: 8,
        borderRadius: 7.5,
        justifyContent: 'center',
        alignItems: 'center',
    },
    status: {
        fontSize: 12,
        lineHeight: 12,
        marginTop: Platform.OS === 'android' ? 2 : 1,
    },
}))

export default styles
export { optionStyles }
