import { Platform } from 'react-native'

import { styleSheetFactory } from '../../Themes/Colors'

const isWeb = Platform.OS === 'web'

const styles = styleSheetFactory((colors) => ({
  container: {
    flexGrow: 1,
    position: 'relative',
    flex: 1,
  },
  searchContainer: {
    flexDirection: 'row',
    position: 'absolute',
    top: 45,
    left: 0,
    right: 0,
    margin: 15,
    marginBottom: 0,
  },

  default: {
    flex: 1,
    backgroundColor: colors.tertiaryBackground,
    overflow: 'hidden',
  },
  list: {
    flexDirection: 'row',
    alignItems: 'center',
    flexGrow: 1,
    height: 60,
  },
  listFirst: {
    borderTopLeftRadius: 7,
    borderTopRightRadius: 7,
  },
  listLast: {
    borderBottomLeftRadius: 7,
    borderBottomRightRadius: 7,
  },
  status: {
    fontSize: 12,
    lineHeight: 12,
    marginTop: 2,
  },
  listStyle: {
    marginTop: isWeb ? 35 : 70,
  },
  listSeparatorStyle: {
    height: 1,
    backgroundColor: colors.separator,
    marginLeft: 70,
  },
  inputContainer: {
    flexDirection: 'row',
    flexGrow: 1,
    backgroundColor: colors.white,
    borderRadius: 7,
  },
  searchIconContainer: {
    padding: 10,
    justifyContent: 'center',
    alignItems: 'center',
    width: 50,
    height: 50,
  },
  clearContainer: {
    margin: 6.5,
    justifyContent: 'center',
    alignItems: 'center',
    width: 35,
    height: 35,
    borderRadius: 20,
    backgroundColor: colors.background,
  },
  searchInput: {
    flexGrow: 1,
    fontSize: 16,
    fontFamily: 'Vodafone Lt',
    color: colors.primaryText,
  },
  errorItemWrapper: {
    backgroundColor: colors.background,
    marginTop: 15,
    borderRadius: 7,
  },
  errorItemTextWrapper: {
    padding: 15,
    justifyContent: 'center',
    alignItems: 'center',
  },
  headerLeftButtonIcon: {
    width: 25,
    height: 25,
    marginRight: 2,
  },
  headerLeftButton: {
    height: 50,
  },
  verticalSpacing: {
    marginVertical: 15,
  },
}))

const photoItemStyles = styleSheetFactory((colors) => ({
  avatarContainer: { position: 'relative' },
  status: {
    width: 15,
    height: 15,
    position: 'absolute',
    borderRadius: 7.5,
    bottom: 8,
    right: 5,
    borderWidth: 1,
    borderColor: colors.white,
  },
  colorContainer: {
    height: 70,
    width: 70,
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
  },
}))

const itemStyles = styleSheetFactory((colors) => ({
  default: {
    flex: 1,
    backgroundColor: colors.tertiaryBackground,
    overflow: 'hidden',
  },
  list: {
    flexDirection: 'row',
    alignItems: 'center',
    flexGrow: 1,
    height: 70,
  },
  listFirst: {
    borderTopLeftRadius: 7,
    borderTopRightRadius: 7,
  },
  listLast: {
    borderBottomLeftRadius: 7,
    borderBottomRightRadius: 7,
  },
  subContainer: { flexDirection: 'row', alignItems: 'center', flex: 1 },
  textContainer: {
    flexGrow: 1,
    flexShrink: 1,
    marginLeft: 15,
  },
  firstRow: { flexDirection: 'row' },
  arrowContainer: {
    alignItems: 'flex-end',
    justifyContent: 'center',
    padding: 15,
  },
  rowStyle: {
    flexDirection: 'row',
  },
}))

const hiddenStyles = styleSheetFactory((colors) => ({
  list: {
    flexGrow: 1,
    height: 70,
  },
  hiddenContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'flex-end',
    overflow: 'hidden',
    backgroundColor: colors.tertiaryBackground,
  },
  listFirst: {
    borderTopLeftRadius: 7,
    borderTopRightRadius: 7,
  },
  listLast: {
    borderBottomLeftRadius: 7,
    borderBottomRightRadius: 7,
  },
  hiddenItem: {
    width: 100,
    height: 70,
    justifyContent: 'center',
    alignItems: 'center',
  },
  confirmContainer: {
    flexDirection: 'row',
    backgroundColor: colors.transparent,
  },
  confirmStyles: {
    flexGrow: 1,
    height: 70,
    flexDirection: 'row',
    paddingLeft: 15,
    alignItems: 'center',
  },
  cancelContainer: {
    width: 40,
    height: 40,
    flexShrink: 0,
    marginVertical: 15,
    marginHorizontal: 15,
    borderRadius: 20,
    backgroundColor: colors.whiteRGBA(0.55),
    justifyContent: 'center',
    alignItems: 'center',
  },
  rowStyle: {
    flexDirection: 'row',
  },
  activateStyles: activate => ({
    flexDirection: 'row',
    backgroundColor: activate ? colors.green : colors.red,
  }),
  statusView: status => ({
    flexDirection: 'row',
    backgroundColor: status === 'Active' ? colors.pending : colors.accent,
  }),
  leftSpacing: {
    marginLeft: 15,
  },
}))

export { photoItemStyles, itemStyles, hiddenStyles }

export default styles
