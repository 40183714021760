/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useContext, useRef } from 'react'
import { View, TouchableOpacity, Animated } from 'react-native'
import { useLazyQuery, useMutation } from '@apollo/client'
import PropTypes from 'prop-types'
import { useTheme } from 'react-native-themed-styles'
import { useSelector } from 'react-redux'

import {
  RadioInput,
  Icon,
  SwipeList,
  Text,
  Modal,
  Loader,
  Header,
  ModalContent,
  TutorialNote,
} from '../../Components'
import { getProductCatalog } from '../ProductCatalog/ProductCatalog.Selectors'

import { getErrorMessage, useConfig } from '../../Utils'

import { GET_CATEGORIES, DELETE_CATEGORY } from './Categories.schema'

import Theme from '../../Themes/Theme'
import { images, animations } from '../../Themes'
import themedStyles, { itemStyles, hiddenItemStyles } from './Categories.Styles'

function Category({ name, labelColor, onPress }) {
  const [styles] = useTheme(itemStyles)
  const anim = useRef(new Animated.Value(1)).current
  const animate = value => {
    Animated.spring(anim, {
      toValue: value,
      useNativeDriver: true,
    }).start()
  }

  return <Animated.View style={[
    styles.container,
    { transform: [{ scale: anim }] },
  ]}>
    <TouchableOpacity
      onPress={onPress}
      onPressIn={() => animate(1.05)}
      onPressOut={() => animate(1)}
      style={styles.touchable}
    >
      <View style={styles.textContainer}>
        <View style={styles.abreviation(labelColor)}>
          <Text i18nKey={name?.match(/\b(\w{1})/g)?.slice(0, 2)?.join('')?.toUpperCase()} size="h5" color={labelColor} />
        </View>
        <Text i18nKey={name} numberOfLines={2} style={styles.name} />
      </View>
      <Icon
        source={images.chevronRight}
        style={styles.rightIcon}
      />
    </TouchableOpacity>
  </Animated.View>
}
Category.defaultProps = {
  labelColor: 'white',
}
Category.propTypes = {
  name: PropTypes.string,
  onPress: PropTypes.func,
  isFirst: PropTypes.bool,
  isLast: PropTypes.bool,
}

function SelectableCategory({ id, name, labelColor, selected, onPress }) {
  const [styles] = useTheme(itemStyles)
  const { colors } = useContext(Theme)
  const anim = useRef(new Animated.Value(1)).current
  const animate = value => {
    Animated.spring(anim, {
      toValue: value,
      useNativeDriver: true,
    }).start()
  }

  return <Animated.View style={[
    styles.container,
    { transform: [{ scale: anim }] },
  ]}>
    <TouchableOpacity
      onPress={onPress}
      onPressIn={() => animate(1.05)}
      onPressOut={() => animate(1)}
      style={styles.touchable}
    >
      <View style={styles.textContainer}>
        <View style={styles.abreviation(labelColor)}>
          <Text i18nKey={name?.match(/\b(\w{1})/g)?.slice(0, 2)?.join('')?.toUpperCase()} size="h3" color={labelColor} />
        </View>
        <Text i18nKey={name} numberOfLines={2} style={styles.name} />
      </View>
      <View pointerEvents="none" style={styles.radioInput}>
        <RadioInput
          index={id}
          selected={selected}
          onPress={onPress}
          style={styles.selector}
          color={colors.secondaryAccent}
        />
      </View>
    </TouchableOpacity>
  </Animated.View>
}
SelectableCategory.defaultProps = {
  labelColor: 'white',
}
SelectableCategory.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  onPress: PropTypes.func,
  selected: PropTypes.string,
  isFirst: PropTypes.bool,
  isLast: PropTypes.bool,
}

function HiddenItem({ needsConfirmation, onPress, onConfirm, onCancel, loading, isFirst, isLast }) {
  const [styles] = useTheme(hiddenItemStyles)
  const { colors } = useContext(Theme)

  return (
    <View style={[styles.wrapper, styles['firstItem_' + isFirst], styles['lastItem_' + isLast]]}>
      {loading
        ? <View style={styles.loader}>
          <Loader size={32} color={colors.white} source={animations.vfLoaderWhite} />
        </View>
        : needsConfirmation
          ? <TouchableOpacity
            style={styles.option}
            onPress={onPress}
          >
            <Icon source={images.delete} color={colors.white} size={24} />
          </TouchableOpacity>
          : <View style={styles.optionFull}>
            <TouchableOpacity
              style={styles.confirm}
              onPress={onConfirm}
            >
              <Icon source={images.delete} color={colors.white} size={24} />
              <Text i18nKey="opened_orders_delete_confirm" color={colors.white} style={styles.confirmText} />
            </TouchableOpacity>
            <TouchableOpacity
              style={styles.cancel}
              onPress={onCancel}
            >
              <Icon source={images.closeIcon} color={colors.white} size={20} />
            </TouchableOpacity>
          </View>
      }
    </View>
  )
}
HiddenItem.propTypes = {
  needsConfirmation: PropTypes.bool,
  onPress: PropTypes.func,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  loading: PropTypes.bool,
  isFirst: PropTypes.bool,
  isLast: PropTypes.bool,
}


function Categories({
  route: {
    params: {
      selectable = false,
      preselectedCategory = {},
      updateSelection = () => { },
    } = {},
  } = {},
  navigation: {
    navigate,
    goBack,
  },
}) {

  const { switchLocation: { deviceId, locationId: locId } } = useConfig()

  const [styles] = useTheme(themedStyles)
  const { colors } = useContext(Theme)

  const { locationId, businessName, address } = useSelector(getProductCatalog)



  const [needsConfirmation, setNeedForConfirmation] = useState(true)
  const [categories, setCategories] = useState([])
  const [itemWidth, setItemWidth] = useState(0)
  const [limit] = useState(30)
  const [searchText, setSearchText] = useState('')
  const [selectedCategory, setSelectedCategory] = useState({ ...preselectedCategory })

  const [listCategories, { loading, data, refetch, fetchMore }] = useLazyQuery(GET_CATEGORIES, { fetchPolicy: 'network-only' })

  useEffect(() => {
    listCategories({
      variables: {
        locId: locId,
        cashRegisterId: deviceId,
        limit: limit,
        locationId: locationId ? locationId : null,
        filter: {
          name: 'name',
          value: searchText,
        },
      },
    })
  }, [])

  const [isErrorActive, setErrorActive] = useState(false)
  const [deleteCategory, { loading: deleting, error: deleteError }] = useMutation(DELETE_CATEGORY)

  const onSearch = (text) => {
    setSearchText(text)
    listCategories({
      variables: {
        locId: locId,
        cashRegisterId: deviceId,
        limit: limit,
        locationId: locationId ? locationId : null,
        filter: {
          name: 'name',
          value: text,
        },
      },
    })
  }

  const onEndReached = () => {
    if (!data?.listCategories?.nextToken) {
      return
    }

    fetchMore({
      variables: {
        locId: locId,
        cashRegisterId: deviceId,
        limit: limit,
        locationId: locationId ? locationId : null,
        nextToken: data.listCategories.nextToken,
        filter: {
          name: 'name',
          value: searchText,
        },
      },
    })
  }

  useEffect(() => {
    const discs = data?.listCategories?.categories
    if (discs) {
      setCategories([...discs])
    }
  }, [data])

  const selectCategory = item => {
    setSelectedCategory(item)
    updateSelection(item)
    goBack()
  }

  const renderSelectableItem = ({ item, index }) => (
    <SelectableCategory
      {...item}
      selected={selectedCategory?.id}
      onPress={() => selectCategory(item)}
    />
  )

  const editCategory = item => {
    if (item) {
      navigate('NewCategory', { categoryItem: item, onSave: () => refetch() })
    } else {
      navigate('NewCategory', { onSave: () => refetch() })
    }
  }

  const renderNonSelectableItem = ({ item, index }) => (
    <Category
      {...item}
      onPress={() => editCategory(item)}
    />
  )

  const renderItem = selectable ? renderSelectableItem : renderNonSelectableItem

  const openRow = (rowMap, id) => {
    setNeedForConfirmation(false)
    rowMap[id].manuallySwipeRow(-itemWidth)
  }

  const closeRow = (rowMap, id) => {
    rowMap[id].closeRow()
    setNeedForConfirmation(true)
  }

  const deleteRow = (rowMap, id) => {
    deleteCategory({
      variables: {
        locId: locId,
        cashRegisterId: deviceId,
        id: id,
        locationId: locationId ? locationId : null,
      },
    }).then(() => {
      // displayToast('categories_delete_success')
      closeRow(rowMap, id)
      refetch()
    }).catch(() => {
      setErrorActive(true)
      closeRow(rowMap, id)
    })
  }

  const renderHiddenItem = ({ item, index }, rowMap) => (
    <HiddenItem
      needsConfirmation={needsConfirmation}
      onPress={() => openRow(rowMap, item.id)}
      onConfirm={() => deleteRow(rowMap, item.id)}
      onCancel={() => closeRow(rowMap, item.id)}
      loading={deleting}
    />
  )

  return (
    <>
      <Header
        title="header_categories"
        image={images.back}
        onPress={goBack}
        customBusinessName={businessName}
        customAddress={address}
      />
      <SwipeList
        searchValue={searchText}
        searchPlaceholder="categories_search_plaheholder"
        onSearch={onSearch}
        data={categories}
        renderItem={renderItem}
        renderHiddenItem={!selectable ? renderHiddenItem : undefined}
        disableLeftSwipe={selectable}
        disableRightSwipe
        rightOpenValue={-100}
        onRowClose={() => setNeedForConfirmation(true)}
        setItemWidth={setItemWidth}
        createNewLabel="categories_button_create"
        onCreatePress={() => editCategory(undefined)}
        onEndReached={onEndReached}
        onEndReachedThreshold={0.4}
        buttonVariant={'active'}
        refreshing={loading}
        onRefresh={() => refetch()}
        ListHeaderComponent={<TutorialNote url="https://www.youtube.com/watch?v=S0_pg108qoE" />}
      />
      <Modal
        isVisible={isErrorActive}
        onBackButtonPress={() => setErrorActive(false)}
        onBackdropPress={() => setErrorActive(false)}
        animationIn="fadeIn"
        animationOut="fadeOut"
      >
        <ModalContent onClose={() => setErrorActive(false)}>
          <Icon source={images.delete} style={styles.errorIcon} />
          <Text i18nKey="orders_error_label" color={colors.accent} size="h5" align="center" weight="bold" style={styles.errorTitle} />
          <Text i18nKey={getErrorMessage(deleteError)} align="center" />
        </ModalContent>
      </Modal>
    </>
  )
}

Categories.propTypes = {
  route: PropTypes.object,
  navigation: PropTypes.object,
}

export default Categories
