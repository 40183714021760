const PRODUCT_CATALOG_UPDATE_LOCATION = 'PRODUCT_CATALOG_UPDATE_LOCATION'
const PRODUCT_CATALOG_RESET_LOCATION = 'PRODUCT_CATALOG_RESET_LOCATION'

const updateLocation = (value) => ({
    type: PRODUCT_CATALOG_UPDATE_LOCATION,
    value,
})

const resetLocation = () => ({
    type: PRODUCT_CATALOG_RESET_LOCATION,
})

export {
    PRODUCT_CATALOG_UPDATE_LOCATION,
    PRODUCT_CATALOG_RESET_LOCATION,
    updateLocation,
    resetLocation,
}
