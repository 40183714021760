import { styleSheetFactory } from '../../Themes/Colors'

const styles = styleSheetFactory(colors => ({
  container: {
    flex: 1,
  },
  iconHeader: {
    alignItems: 'center',
  },
  addPhotoContainer: {
    backgroundColor: colors.tertiaryBackground,
    width: 110,
    alignItems: 'center',
    borderRadius: 7,
    marginBottom: 10,
  },
  colorFrame: {
    width: 110,
    height: 80,
    overflow: 'hidden',
    borderTopRightRadius: 7,
    borderTopLeftRadius: 7,
    justifyContent: 'center',
  },
  loaderContainer: {
    backgroundColor: colors.white,
    width: 110,
    height: 110,
    overflow: 'hidden',
    borderRadius: 7,
    justifyContent: 'center',
    alignItems: 'center',
  },
  chooseImage: {
    width: 110,
    height: 80,
    overflow: 'hidden',
    borderTopRightRadius: 7,
    borderTopLeftRadius: 7,
  },
  galleryIcon: {
    marginVertical: 5,
  },
  imageFrame: {
    alignItems: 'center',
  },
  gallerySpacing: {
    margin: 40,
  },

  //Tabs
  tabView: {
    backgroundColor: colors.background,
    paddingTop: 0,
  },
  button: {
    height: 50,
    marginTop: 15,
    marginHorizontal: 15,
    marginBottom: 30,
  },
  centeredItemsView: {
    alignItems: 'center',
  },

}))

export default styles
