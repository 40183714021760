import { gql } from '@apollo/client'

export const LIST_LOCATIONS = gql`
query listLocations($nextToken: String) {
    listLocations(
    nextToken: $nextToken,
  ) {
    locations {
        businUnitCode
        name
        id
      }
    nextToken
  },
}
`

export const UPDATE_EMPLOYEE = gql`
  mutation Mutation_updateEmployee(
    $email: String!
    $firstName: String
    $lastName: String
    $operatorCode: String
    $defaultLocation: String
    $phoneNumber: String
    $color: String
    $id: String!
    $defaultLocationName: String
  ) {
    updateEmployee(
      id: $id
      employeeInput: {
        email: $email
        firstName: $firstName
        lastName: $lastName
        operatorCode: $operatorCode
        defaultLocation: $defaultLocation
        defaultLocationName: $defaultLocationName
        color: $color
        phoneNumber: $phoneNumber
      }
    ) {
      id
      email
      color
      firstName
      lastName
      operatorCode
    }
  }
`
