import React, { useContext } from 'react'
import {
  View,
  SafeAreaView,
  TouchableOpacity,
  RefreshControl,
  SectionList,
} from 'react-native'
import PropTypes from 'prop-types'
import { useTheme } from 'react-native-themed-styles'
import moment from 'moment'
import 'moment/locale/sq'
import 'moment/locale/en-gb'

import { Icon, Text } from '../../Components'

import Theme from '../../Themes/Theme'
import images from '../../Themes/Images'
import themedStyles, {itemStyles, separatorStyle} from './Notifications.Styles'

const LANGUAGES = {
  al: 'sq',
  en: 'en-gb',
}

function Item(props) {
  const [styles] = useTheme(itemStyles)
  const { colors } = useContext(Theme)

  const {
    icon,
    title,
    desc,
    dateTime,
    onPress,
  } = props

  return (
    <View style={styles.container}>
      <TouchableOpacity style={styles.touchableContainer} onPress={onPress} disabled={!onPress}>
        <View style={styles.iconContainer}>
          <Icon source={icon} size={30} color={colors.accent} style={styles.icon} />
        </View>
        <View style={styles.detailsContainer}>
          <Text i18nKey={title}/>
          <Text
            i18nKey={desc}
            weight="light"
            color={colors.secondaryText}
            size="footnote"
          />
          <Text
            i18nKey={dateTime}
            weight="light"
            size="footnote"
          />
        </View>
      </TouchableOpacity>
    </View>
  )
}
Item.propTypes = {
  title: PropTypes.string,
  desc: PropTypes.string,
  dateTime: PropTypes.string,
  onPress: PropTypes.func,
  icon: PropTypes.any,
}

function SectionSeparator(props) {
  const [styles] = useTheme(separatorStyle)

  const {
    label,
    color,
  } = props

  return (
    <View style={styles.container}>
      <View style={styles.separatorLine} />
      <Text i18nKey={label} style={styles.label} color={color} />
    </View>
  )
}

SectionSeparator.propTypes = {
  label: PropTypes.string,
}


function Notifications(props) {
  const [styles] = useTheme(themedStyles)
  const { colors } = useContext(Theme)

  moment.locale(LANGUAGES.en)

  const {
    navigation: { goBack },
    route: { params: {notificationCount} = {notificationCount: 0}},
  } = props

  const hasNewNotifications = notificationCount > 0
  const notifications = [
    {
      title: 'New',
      active: true,
      data: [
        {
          icon: images.connectionActive,
          title: 'You are working offline.',
          desc: 'You may want to fix your connection and continue working online.',
          dateTime: moment().calendar(),
        },
      ],
    },
    {
      title: 'Older',
      active: false,
      data: [
        {
          icon: images.discounts,
          title: 'New Discount is out now.',
          desc: 'Check it out and apply it to your customers.',
          dateTime: moment('2020-08-07T16:16:56+02:00').calendar(),
        },
        {
          icon: images.connectionActive,
          title: 'You are working offline.',
          desc: 'You may want to fix your connection and continue working online.',
          dateTime: moment('2020-07-04T05:25:36+02:00').calendar(),
        },
      ],
    },
  ]

  return (
    <SafeAreaView style={styles.container}>
      <View style={styles.header}>
        <TouchableOpacity onPress={goBack}>
          <Icon source={images.chevronLeft} size={24} color={colors.primaryIcon} style={styles.headerIcon} />
        </TouchableOpacity>
        <Text i18nKey="Notifications" size="h5" />
      </View>

      <SectionList
        keyExtractor={(item, index) => 'item-' + index}
        sections={notifications}
        renderItem={({ item, index }) => (
          <Item
            key={'item-' + index}
            icon={item.icon}
            title={item.title}
            desc={item.desc}
            dateTime={item.dateTime}
          />
        )}
        renderSectionHeader={({ section }) => {
          if (hasNewNotifications) {
            return <SectionSeparator
              label={section.title}
              color={section.active ? colors.accent : undefined}
            />
          }
          return null
        }}
        onEndReached={() => { }}
        refreshControl={
          <RefreshControl
            refreshing={false}
            onRefresh={() => { }}
          />
        }
      />
    </SafeAreaView>
  )
}

Notifications.propTypes = {
  navigation: PropTypes.object,
  route: PropTypes.shape({
    params: PropTypes.object,
  }),
}

export default Notifications
