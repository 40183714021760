import { gql } from '@apollo/client'

export const LIST_SAVED_ORDERS = gql`
  query listOrder($locationId: String!, $nextToken: String , $cashRegisterId: String ){
    listOrders(
      listOrdersInput:{
      locationId: $locationId
      status: SAVE
      nextToken: $nextToken
    },
    requestedLocationAndCashRegister: {
      locationId: $locationId,
      cashRegisterId: $cashRegisterId
    }
    ){
      orders{
        id
        totalMoney{
          amount
          currency
        }
        createdAt
        status
      }
      nextToken
    }
  }
`
export const SAVE_ORDER = gql`
  mutation createOrder($orderInput: OrderInput! ,  $locationId: String , $cashRegisterId: String) {
    createOrder(
      orderInput: $orderInput
      requestedLocationAndCashRegister: {
        locationId: $locationId,
        cashRegisterId: $cashRegisterId
      }
      ){
      id
    }
  }
`

export const UPDATE_ORDER = gql`
  mutation updateOrder($orderInput: OrderUpdateInput!, $locationId: String, $cashRegisterId: String) {
    updateOrder(
      orderUpdateInput: $orderInput
      requestedLocationAndCashRegister: {
        locationId: $locationId,
        cashRegisterId: $cashRegisterId
      }
      ){
      id
    }
  }
`

export const DELETE_SAVED_ORDER = gql`
  mutation deleteSavedOrder($id: String!, $locationId: String!, $locId: String, $cashRegisterId: String ) {
    deleteSavedOrder(
      id: $id,
      locationId: $locationId,
      requestedLocationAndCashRegister: {
        locationId: $locId,
        cashRegisterId: $cashRegisterId
      }
    ){
      id
    }
  }
`

export const RETRIEVE_SAVED_ORDER = gql`
  query getOrder($id: String!, $locationId: String!, $cashRegisterId: String){
    getOrder(
      id: $id,
      locationId: $locationId,
      requestedLocationAndCashRegister: {
        locationId: $locationId,
        cashRegisterId: $cashRegisterId
      }
    ){
      id
      lineItems {
        id
        itemId
        quantity
        itemName
        variationId
        variationName
        labelColor
        imageUrl
        measurementUnitId
        measurementUnitName
        appliedTax{
          id
          name
          percentage
        }
        taxInclusionType
        modifiers{
          id
          name
          modifierListId
          modifierListName
          appliedMoney{
            amount
            currency
          }
        }
        appliedDiscounts{
          id
          name
          discountType
          percentage
          appliedMoney{
            amount
            currency
          }
        }
        basePriceMoney{
          amount
          currency
        }
        totalMoney{
          amount
          currency
        }
        totalTaxMoney{
          amount
          currency
        }
        totalDiscountMoney{
          amount
          currency
        }
      }
      totalMoney{
        amount
        currency
      }
      appliedDiscounts{
        id
        name
        discountType
        percentage
        appliedMoney{
          amount
          currency
        }
      }
      totalTaxMoney{
        amount
        currency
      }
      totalDiscountMoney{
        amount
        currency
      }
    }
  }
`
