import {styleSheetFactory} from '../../Themes/Colors'

const styles = styleSheetFactory(colors => ({
  keyboardAvoidingView: {
    flex: 1,
  },
  container: {
    borderRadius: 7,
    overflow: 'hidden',
    flex: 1,
  },
  contentContainer: {
    backgroundColor: colors.background,
    paddingBottom: 80,
    paddingHorizontal: 15,
    flexGrow: 1,
  },
  searchContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: colors.tertiaryBackground,
    height: 50,
    borderRadius: 7,
    margin: 15,
    marginTop: 5,
  },
  iconContainer: {
    padding: 10,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: colors.backgroundRGBA(0.5),
    borderRadius: 20,
    marginRight: 10,
    width: 30,
    height: 30,
  },
  icon: {
    width: 15,
    height: 15,
    margin: 15,
    resizeMode: 'contain',
    tintColor: colors.secondaryIcon,
  },
  input: {
    flexGrow: 1,
    fontSize: 16,
    fontFamily: 'Vodafone Lt',
    color: colors.primaryText,
  },
  button: {
    marginTop: 5,
    marginBottom: 15,
    marginHorizontal: 15,
    height: 50,
  },
  buttonTitle: {
    fontSize: 18,
  },
  plusIcon: {
    tintColor: colors.white,
    height: 22,
    width: 22,
  },
  fab: {
    position: 'absolute',
    bottom: 15,
    right: 15,
    height: 50,
    width: 50,
    borderRadius: 25,
    backgroundColor: colors.accent,
    justifyContent: 'center',
    alignItems: 'center',
    shadowColor: colors.black,
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.22,
    shadowRadius: 2.22,
    elevation: 3,
  },
  fabIcon: {
    transform: [{ rotate: '-90deg' }],
  },
}))

export default styles
