import React, { useContext, useState, useRef, useEffect } from 'react'
import { TouchableOpacity, View, Platform } from 'react-native'

import PropTypes from 'prop-types'
import { useTheme } from 'react-native-themed-styles'

import { FilledTextField } from '../react-native-material-textfield'
import ContentManager from '../../Utils/ContentManager'
import Icon from '../Icon'

import themedStyles from './InputWithIcon.Styles'
import Theme from '../../Themes/Theme'

function InputWithIcon(props) {
  const [styles] = useTheme(themedStyles)
  const { colors } = useContext(Theme)

  const {
    label,
    value,
    prefix,
    suffix,
    placeHolders,
    onChangeText,
    keyboardType,
    onFocus,
    onBlur,
    inputWrapperStyle,
    inputContainerStyle,
    labelOffset,
    editable,
    icon,
    iconSize,
    onIconPress,
    error,
    closeIcon,
    noIcon,
    ...rest
  } = props

  const inputRef = useRef()
  const [isFocused, setFocused] = useState(false)
  const placeholderKey = label + ''

  useEffect(() => {
    inputRef?.current?.setValue(value)
  }, [value])

  const focused = () => {
    setFocused(true)
    onFocus()
  }

  const blured = () => {
    setFocused(false)
    onBlur()
  }

  return (
    <View style={styles.wrapper}>
      <FilledTextField
        {...rest}
        ref={inputRef}
        label={ContentManager.translate(placeholderKey, placeHolders)}
        keyboardType={keyboardType}
        tintColor={colors.primaryText}
        baseColor={colors.primaryText}
        style={styles.inputStyle(editable)}
        lineWidth={0}
        activeLineWidth={0}
        disabledLineWidth={0}
        prefix={prefix}
        suffix={suffix}
        error={error}
        onChangeText={(text) => onChangeText(text)}
        onFocus={focused}
        onBlur={blured}
        labelOffset={labelOffset}
        labelFontSize={14}
        editable={editable}
        allowFontScaling={false}
        containerStyle={[styles.input, inputWrapperStyle]}
        inputContainerStyle={[
          styles.inputContainer(isFocused),
          inputContainerStyle,
        ]}
        labelTextStyle={styles.label}
        titleTextStyle={styles.title}
        affixTextStyle={[
          styles.affix,
          Boolean(prefix) && Platform.OS === 'ios'
            ? styles.prefixIos
            : styles.prefix,
        ]}
      />
      {noIcon ? null : <TouchableOpacity
        style={closeIcon ? styles.inputCloseIcon : styles.selectContact}
        onPress={onIconPress}
        disabled={!editable || !onIconPress}>
        <Icon
          source={icon}
          size={iconSize} />
      </TouchableOpacity>}
    </View>
  )
}

InputWithIcon.defaultProps = {
  labelOffset: {
    x0: 0,
    y0: -9,
    x1: 0,
    y1: -1,
  },
  keyboardType: 'default',
  onFocus: () => { },
  onBlur: () => { },
  editable: true,
  iconSize: 24,
}

InputWithIcon.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  prefix: PropTypes.string,
  suffix: PropTypes.string,
  labelOffset: PropTypes.shape({
    x0: PropTypes.number,
    y0: PropTypes.number,
    x1: PropTypes.number,
    y1: PropTypes.number,
  }),
  placeHolders: PropTypes.arrayOf(PropTypes.string),
  onChangeText: PropTypes.func,
  keyboardType: PropTypes.string,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  inputWrapperStyle: PropTypes.object,
  inputContainerStyle: PropTypes.object,
  editable: PropTypes.bool,
  icon: PropTypes.any,
  iconSize: PropTypes.number,
  onIconPress: PropTypes.func,
  error: PropTypes.bool,
  closeIcon: PropTypes.bool,
  noIcon: PropTypes.bool,
}

export default InputWithIcon
