import { styleSheetFactory } from '../../Themes/Colors'

const styles =  styleSheetFactory(colors => ({
  container: {
    flex: 1,
    backgroundColor: colors.background,
  },
  nameContainer: {
    marginHorizontal: 15,
    marginTop: 10,
  },
  sectionTitle: {
    marginHorizontal: 15,
    marginTop: 20,
    marginBottom: 10,
  },
  list: {
    marginHorizontal: 15,
    paddingBottom: 80,
  },
  button: {
    margin: 15,
  },
  modalContainer: {
    backgroundColor: colors.white,
    alignSelf: 'center',
    paddingBottom: 30,
    borderRadius: 6,
  },
  closeContainer: {
    backgroundColor: colors.disabled,
    position: 'absolute',
    top: 0,
    right: 0,
    borderTopRightRadius: 7,
    borderBottomLeftRadius: 7,
    width: 75,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 10,
  },
  modalContent: {
    paddingHorizontal: 40,
    marginTop: 45,
  },
  modalIcon: {
    alignSelf: 'center',
    marginBottom: 5,
  },
  modalTitle: {
    marginVertical: 8,
  },
  modalButton: {
    marginTop: 15,
    marginHorizontal: 15,
  },
}))

const itemStyles = styleSheetFactory(colors => ({
  container: {
    height: 60,
    backgroundColor: colors.tertiaryBackground,
    justifyContent: 'center',
    overflow: 'hidden',
  },
  firstItem_true: {
    borderTopLeftRadius: 7,
    borderTopRightRadius: 7,
  },
  lastItem_true: {
    borderBottomLeftRadius: 7,
    borderBottomRightRadius: 7,
  },
  touchable: {
    flexGrow: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  textContainer: {
    marginHorizontal: 15,
    flexGrow: 1,
    flexShrink: 1,
  },
  rightIcon: {
    width: 24,
    height: 24,
    marginRight: 15,
    tintColor: colors.accent,
    flexShrink: 0,
  },
  checkBox: {
    flexShrink: 0,
    marginRight: 8,
  },
  title: {
    marginLeft: 15,
    flexGrow: 1,
    flexShrink: 1,
  },
  desc: {
    marginHorizontal: 15,
    flexShrink: 0,
  },
  radioInput: {
    marginRight: 15,
  },
}))

const separatorStyles = styleSheetFactory(colors => ({
  container: {
    backgroundColor: colors.separator,
    height: 1,
  },
}))

export {
  itemStyles,
  separatorStyles,
}

export default styles
