/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useContext, useEffect, useCallback } from 'react'
import {
  View,
  SafeAreaView,
  TouchableOpacity,
  LayoutAnimation,
  Keyboard,
  ImageBackground,
  Animated,
  Easing,
  Vibration,
  Platform,
} from 'react-native'
import PropTypes from 'prop-types'
import { useTheme } from 'react-native-themed-styles'
import { SwipeListView } from 'react-native-swipe-list-view'
import ShadowView from 'react-native-androw'
import Color from 'color'
import Sound from 'react-native-sound'
import { useFocusEffect } from '@react-navigation/native'
import { useSelector, useDispatch } from 'react-redux'

import ContentManager from '../../Utils/ContentManager'

import {
  Options,
  Icon,
  Text,
  TextInput,
  Button,
  Modal,
  QRScannerOrder,
  Header,
  ModalContent,
  Option,
} from '../../Components'
import calculatePrice, { useOrderCount } from '../../Utils/Order'
import { useUserDetails } from '../../Utils/AuthDetails'
import { usePermissions } from '../../Utils/Permissions'
import { INF } from '../../Utils/Helpers'
import { getCurrency, getOrder } from '../Checkout/Checkout.Selectors'
import { updateOrder, resetOrder } from '../Checkout/Checkout.Actions'

import { useConfig } from '../../Utils/Config'

import Theme, { layoutAnimConfig } from '../../Themes/Theme'
import images from '../../Themes/Images'
import themedStyles, {
  itemStyles,
  hiddenItemStyles,
  cardInfoStyles,
} from './Cart.Styles'

const AnimatedTouchable = Animated.createAnimatedComponent(TouchableOpacity)
function Item({ itemName, quantity, measurementUnitName, totalMoney: { amount, currency }, imageUrl, labelColor, onPress }) {
  const [styles] = useTheme(itemStyles)
  const { colors } = useContext(Theme)
  const anim = useRef(new Animated.Value(1)).current
  const animate = (value) => {
    Animated.spring(anim, {
      toValue: value,
      useNativeDriver: true,
    }).start()
  }

  return (
    <Animated.View style={[styles.itemContainer, { transform: [{ scale: anim }] }]}>
      <TouchableOpacity
        onPress={onPress}
        onPressIn={() => animate(1.05)}
        onPressOut={() => animate(1)}
        style={styles.touchable}>
        {imageUrl ? (
          <ImageBackground
            style={styles.listImageContainer}
            source={{ uri: imageUrl }}
          />
        ) : (
          <View
            style={[
              styles.listImageContainer,
              {
                backgroundColor: Color(labelColor || 'transparent')
                  .fade(0.65)
                  .toString(),
              },
            ]}>
            <Text
              size="h5"
              color={labelColor}
              i18nKey={itemName
                ?.match(/\b(\w{1})/g)
                ?.slice(0, 2)
                ?.join('')
                ?.toUpperCase()}
            />
          </View>
        )}
        <View style={styles.listInfoContainer}>
          <View style={styles.listDescContainer}>
            <Text i18nKey={itemName} numberOfLines={1} />
            <View style={styles.rowView}>
              <Text
                i18nKey="cart_product_price_details"
                placeHolders={[
                  quantity,
                  INF.format((amount || 0) / quantity),
                  currency,
                ]}
                weight="light"
                color={colors.secondaryText}
                size="footnote"
                numberOfLines={1}
                style={styles.rightSpacing}
              />
              {measurementUnitName && <Text
                i18nKey={`(${measurementUnitName})`}
                weight="light"
                color={colors.secondaryText}
                size="footnote"
                numberOfLines={1}
              />}
            </View>
          </View>
          <View style={styles.listPriceContainer}>
            <Text
              i18nKey="checkout_price_currency_value"
              placeHolders={[
                INF.format(amount || 0),
                currency,
              ]}
            />
          </View>
        </View>
      </TouchableOpacity>
    </Animated.View>
  )
}
Item.propTypes = {
  itemName: PropTypes.string,
  quantity: PropTypes.number,
  measurementUnitName: PropTypes.string,
  itemId: PropTypes.string,
  variationName: PropTypes.string,
  variationId: PropTypes.string,
  basePriceMoney: PropTypes.shape({
    amount: PropTypes.number,
    currency: PropTypes.string,
  }),
  totalMoney: PropTypes.shape({
    amount: PropTypes.number,
    currency: PropTypes.string,
  }),
  totalDiscountMoney: PropTypes.shape({
    amount: PropTypes.number,
    currency: PropTypes.string,
  }),
  totalModifiersMoney: PropTypes.shape({
    amount: PropTypes.number,
    currency: PropTypes.string,
  }),
  imageUrl: PropTypes.string,
  labelColor: PropTypes.string,
  onPress: PropTypes.func,
  isFirst: PropTypes.bool,
  isLast: PropTypes.bool,
}
function HiddenItem({ index, remove }) {
  const [styles] = useTheme(hiddenItemStyles)
  const { colors } = useContext(Theme)

  return (
    <View style={styles.itemContainer}>
      <TouchableOpacity
        style={styles.touchableContent}
        onPress={() => remove(index)}>
        <Icon source={images.delete} color={colors.white} size={24} />
      </TouchableOpacity>
    </View>
  )
}
HiddenItem.propTypes = {
  index: PropTypes.number,
  remove: PropTypes.func,
}
function CardInfo(props) {
  const { colors } = useContext(Theme)
  const {
    label,
    value,
    labelPlaceholders,
    valuePlaceholders,
    size = 'footnote',
    color = colors.secondaryText,
  } = props

  return (
    <View style={cardInfoStyles.container}>
      <Text
        i18nKey={label}
        placeHolders={labelPlaceholders}
        size={size}
        color={color}
      />
      <Text
        i18nKey={value}
        placeHolders={valuePlaceholders}
        size={size}
        color={color}
      />
    </View>
  )
}
CardInfo.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  labelPlaceholders: PropTypes.arrayOf(PropTypes.string),
  valuePlaceholders: PropTypes.arrayOf(PropTypes.string),
  size: PropTypes.string,
}
const keyBoardEvents = {
  ios: {
    show: 'keyboardWillShow',
    hide: 'keyboardWillHide',
  },
  android: {
    show: 'keyboardDidShow',
    hide: 'keyboardDidHide',
  },
  web: {},
}

function Cart({
  navigation: { navigate, goBack, addListener },
}) {
  const [styles] = useTheme(themedStyles)
  const { colors } = useContext(Theme)

  const { soundEnabled, vibrationEnabled } = useConfig()
  const { role } = useUserDetails()
  const { checkout = {} } = usePermissions()
  const savedOrdersCount = useOrderCount()
  const currency = useSelector(getCurrency)
  const { order } = useSelector(getOrder)

  const dispatchAction = useDispatch()

  const setOrder = (obj) => dispatchAction(updateOrder(obj))

  const clearOrder = () => dispatchAction(resetOrder())

  const listRef = useRef(null)
  const fabSize = useRef(new Animated.Value(0)).current
  const animate = (value) => {
    Animated.timing(fabSize, {
      toValue: value,
      duration: 370,
      useNativeDriver: true,
      easing: Easing.inOut(Easing.cubic),
    }).start()
  }
  const scrollToTop = () => {
    animate(0)
    listRef.current.scrollToOffset({
      offset: 0,
    })
  }
  const [isKeyBoardVisible, setKeyboardVisible] = useState(false)

  const [isClearConfirm, setClearConfirm] = useState(false)

  const clear = useRef(false)
  const len = order.count || 0

  const [data, setData] = useState([])

  const [isScannerVisible, setScannerVisible] = useState(false)
  const [isScannerError, setScannerError] = useState(false)
  const lastScanned = useRef(null)

  useFocusEffect(useCallback(() => {
    clear.current = true
    if (len === 0) {
      goBack()
    }
    LayoutAnimation.configureNext(layoutAnimConfig)
    setData(order.lineItems)
  }, [order]))

  // Options
  const sortOptions = [
    {
      title: 'checkout_button_save_cart',
      icon: images.checkout,
      onPress: () => navigate('SaveItems'),
      disabled: len <= 0,
    },
    // {
    //   title: 'Add cutomer',
    //   icon: images.user,
    //   onPress: () => { },
    // },
    {
      title: 'checkout_button_clear_cart',
      icon: images.delete,
      onPress: () => setClearConfirm(true),
      disabled: len <= 0,
    },
  ]

  useEffect(() => {
    const unsubscribe = addListener('beforeRemove', () => {
      clearTimeout(timeout.current)
      if (!clear.current) {
        const lineItems = order.lineItems?.reverse()
        order.lineItems = lineItems
        setOrder(calculatePrice(order, currency.currencyCode))
      }
    })
    return unsubscribe
  }, [])

  const timeout = useRef(null)

  const searchRef = useRef(null)
  const [searchValue, setSearchValue] = useState('')
  const textSearch = (text) => {
    setSearchValue(text)
    if (text === ('' || null)) {
      LayoutAnimation.configureNext(layoutAnimConfig)
      setData(order.lineItems)
    } else {
      const searchResult = order.lineItems?.filter((element) =>
        (element.itemName || '')
          .toLocaleLowerCase()
          .match(text.toLocaleLowerCase()),
      )
      LayoutAnimation.configureNext(layoutAnimConfig)
      setData(searchResult)
      if (searchResult.length === 0) {
        setScannerError(true)
      }
    }
  }

  const clearCart = () => {
    clear.current = true
    clearOrder()
    goBack()
  }

  const removeFromCart = (i) => {
    const newOrder = order
    newOrder?.lineItems?.splice(i, 1)
    const updatedOrder = calculatePrice(newOrder)
    if ((updatedOrder.count || 0) === 0) {
      clearCart()
    }
    setData(updatedOrder.lineItems)
    setOrder(updatedOrder)
  }

  const remove = (i, rowMap) => {
    rowMap['row-' + i].closeRow()
    removeFromCart(i)
  }

  function filterPrice(prices) {
    return prices?.find(pr => pr.currency === currency.currencyCode)
  }

  const mapData = (dataa) => {
    const response = []
    dataa?.map((item, index) => {
      let amount = null
      if (item?.appliedMoney) {
        amount = item.appliedMoney
      } else if (item?.prices?.length) {
        amount = filterPrice(item.prices)
      }
      response.push({
        ...item,
        appliedMoney: amount,
      })
    })

    return response
  }

  const applyDiscounts = (discounts) => {
    const newOrder = order
    newOrder.appliedDiscounts = discounts
    const updatedOrder = calculatePrice(newOrder)
    const items = updatedOrder.lineItems
    setOrder(updatedOrder)
    setData(items)
    goBack()
  }

  const changeItemConfig = (item, index) => {
    const routeProps = {
      shouldUpdateItem: true,
      itemId: item.itemId,
      itemName: item.itemName,
      preselectedVariation: {
        id: item.variationId,
        name: item.variationName,
        priceMoney: item.basePriceMoney,
      },
      preselectedModifiers: item.modifiers,
      preSelectedDiscounts: mapData(item.appliedDiscounts),
      preselectedTax: item.appliedTax,
      presetQuantity: item.quantity,
      taxInclusionType: item.taxInclusionType,
      index,
    }
    navigate('AddToBasket', routeProps)
  }

  const barcodeSearch = (barcode) => {
    if (barcode) {
      setScannerError(false)
      const searchResult = order.lineItems?.find(
        (element) => element.barcode === barcode,
      )
      if (searchResult) {
        LayoutAnimation.configureNext(layoutAnimConfig)
        setData([searchResult])
      } else {
        setScannerError(true)
        setData([])
      }
    } else {
      setScannerError(true)
      setData([])
    }
  }

  const onBarcodeRead = (event) => {
    const id = event.data
    if (id !== lastScanned.current) {
      if (soundEnabled) {
        const whoosh = new Sound('scan.mp3', Sound.MAIN_BUNDLE, (error) => {
          if (error) {
            return
          }
          whoosh?.play(() => {
            whoosh.release()
          })
        })
      }
      if (vibrationEnabled) {
        Vibration.vibrate([0, 200])
      }
      lastScanned.current = id
      clearTimeout(timeout.current)
      timeout.current = setTimeout(() => {
        lastScanned.current = null
      }, 1000)
      barcodeSearch(id)
    }
  }

  const toggleScanner = () => {
    setScannerError(false)
    if (isScannerVisible) {
      lastScanned.current = null
      setData(order.lineItems)
    } else {
      textSearch('')
    }
    LayoutAnimation.configureNext(layoutAnimConfig)
    setScannerVisible((value) => !value)
  }

  const keyboardDidShow = () => {
    LayoutAnimation.configureNext(layoutAnimConfig)
    setKeyboardVisible(true)
  }

  const keyboardDidHide = () => {
    LayoutAnimation.configureNext(layoutAnimConfig)
    setKeyboardVisible(false)
  }

  useEffect(() => {
    const showKeyboard = Keyboard.addListener(keyBoardEvents[Platform.OS].show, keyboardDidShow)
    const hideKeyboard = Keyboard.addListener(keyBoardEvents[Platform.OS].hide, keyboardDidHide)
    return () => {
      showKeyboard.remove()
      hideKeyboard.remove()
    }
  }, [])

  const onMomentumScrollEnd = (event) => {
    if (event.nativeEvent.contentOffset.y > 800) {
      animate(1)
    } else {
      animate(0)
    }
  }
  const getItemLayout = (_, index) => ({
    length: 76,
    offset: 76 * index,
    index,
  })
  const keyExtractor = (_, index) => 'row-' + index

  const renderItem = ({ item, index }) => {
    return (
      <Item
        {...item}
        key={'row-' + index}
        onPress={() => changeItemConfig(item, index)}
      />
    )
  }
  const renderHiddenItem = ({ index }, rowMap) => (
    <HiddenItem
      key={'row-' + index}
      index={index}
      remove={(i) => remove(i, rowMap)}
    />
  )

  return (
    <SafeAreaView style={styles.container}>
      <Header
        title={'cart_screen_title'}
        translateOption={{ count: len % 1 === 0 ? len : len.toFixed(2) }}
        titlePlaceHolders={[len]}
        image={images.back}
        onPress={goBack}
      />
      <QRScannerOrder
        error={isScannerError}
        barcode
        retry={() => setScannerError(false)}
        onBarcodeRead={onBarcodeRead}
        visible={isScannerVisible}
        containerStyle={isScannerVisible ? styles.qrVisible : {}}
      />
      <SwipeListView
        listViewRef={(ref) => {
          listRef.current = ref
        }}
        keyExtractor={keyExtractor}
        data={data}
        renderItem={renderItem}
        renderHiddenItem={renderHiddenItem}
        useFlatList
        useAnimatedList
        useNativeDriver
        onMomentumScrollEnd={onMomentumScrollEnd}
        contentContainerStyle={styles.listContainerStyle}
        getItemLayout={getItemLayout}
        closeOnRowBeginSwipe={true}
        closeOnRowOpen={true}
        style={isScannerVisible ? styles.qrInvisible : styles.qrVisible}
        showsVerticalScrollIndicator={false}
        onEndReachedThreshold={0.4}
        onEndReached={() => { }}
        disableRightSwipe={true}
        rightOpenValue={-100}
        closeOnScroll={true}
        closeOnRowPress={true}
      />
      <AnimatedTouchable
        onPress={scrollToTop}
        activeOpacity={0.5}
        style={[
          styles.fab,
          isKeyBoardVisible ? styles.fabKeyboard : styles.fabNoKeyboard,
          {
            opacity: fabSize,
            transform: [{ scale: fabSize }],
          },
        ]}>
        <Icon
          source={images.arrow_right}
          size={20}
          color={colors.tertiaryIcon}
          style={styles.fabIcon}
        />
      </AnimatedTouchable>
      <ShadowView style={styles.shadow}>
        <View style={styles.bottomMenuContainer}>
          <View style={styles.cardInfoWrapper(isKeyBoardVisible)}>
            <View style={styles.cardInfoContainer}>
              <CardInfo
                label="cart_subtotal_label"
                value="checkout_price_currency_value"
                valuePlaceholders={[
                  INF.format(
                    (order?.totalMoney?.amount || 0) +
                    (order?.totalDiscountMoney?.amount || 0) -
                    (order?.totalTaxMoney?.amount || 0),
                  ),
                  order?.totalMoney?.currency,
                ]}
              />
              <CardInfo
                label="cart_total_discount_label"
                value="checkout_price_currency_value"
                valuePlaceholders={[
                  INF.format(order?.totalDiscountMoney?.amount || 0),
                  order?.totalTaxMoney?.currency,
                ]}
              />
              {
                order?.orderGroupedTaxes?.map(tax => (
                  <CardInfo
                    label="checkout_VAT_percentage"
                    labelPlaceholders={[tax.VATRate + '']}
                    value="checkout_price_currency_value"
                    valuePlaceholders={[
                      INF.format(tax?.VATAmt?.amount || 0),
                      tax?.VATAmt?.currency,
                    ]}
                  />
                ))
              }
              <CardInfo
                label="cart_total_label"
                value="checkout_price_currency_value"
                valuePlaceholders={[
                  INF.format(order?.totalMoney?.amount || 0),
                  order?.totalMoney?.currency,
                ]}
                size="h4"
                color={colors.primaryText}
              />
            </View>
            <View style={styles.separator} />
          </View>
          <View style={styles.bottomMenu}>
            <Option
              icon={images.bookmark}
              count={savedOrdersCount >= 20 ? '20+' : savedOrdersCount}
              onPress={() => navigate('OpenedOrders')}
              style={styles.leftSpacing}
            />
            {(checkout.toogleCartDiscounts || []).includes(role) && <Option
              icon={images.discounts}
              count={(order.appliedDiscounts || []).length}
              onPress={() =>
                navigate('SelectDiscount', {
                  onSave: applyDiscounts,
                  preSelectedDiscounts: order.appliedDiscounts,
                })
              }
              style={styles.leftSpacing}
            />}
            <Button
              title="checkout_charge_price_currency"
              placeHolders={[
                INF.format(order?.totalMoney?.amount || 0),
                order?.totalMoney?.currency,
              ]}
              variant={len === 0 || order?.totalMoney?.amount <= 0 ? 'disabled' : 'active'}
              style={styles.chargeButton}
              onPress={() => navigate('Payment')}
            />
          </View>
        </View>
      </ShadowView>
      <Modal
        isVisible={isClearConfirm}
        onBackButtonPress={() => setClearConfirm(false)}
        onBackdropPress={() => setClearConfirm(false)}
        animationIn="fadeIn"
        animationOut="fadeOut"
        useNativeDriver>
        <ModalContent onClose={() => setClearConfirm(false)}>
          <Icon source={images.delete} size={32} />
          <Text
            i18nKey="cart_clear_ttle"
            align="center"
            size="h4"
            weight="bold"
            style={styles.modalTitle}
          />
          <Text
            i18nKey={'cart_clear_description'}
            translateOption={{ count: len }}
            align="center"
            placeHolders={[len]}
            style={styles.modalDesc}
          />
          <Button
            title="cart_clear_confirm"
            backgroundColor={colors.accent}
            borderColor={colors.transparent}
            style={styles.modalPrimaryButton}
            onPress={() => {
              setClearConfirm(false)
              clearCart()
            }}
          />
          <Button
            title="cart_clear_cancel"
            backgroundColor={colors.tertiaryBackground}
            color={colors.primaryText}
            borderColor={colors.transparent}
            style={styles.modalSecondaryButton}
            onPress={() => setClearConfirm(false)}
          />
        </ModalContent>
      </Modal>
      <View style={styles.topMenuContainer}>
        <View style={styles.searchWrapper}>
          <TouchableOpacity
            onPress={() => {
              searchRef.current?.focus()
              if (isScannerVisible) {
                toggleScanner()
              }
            }}
            style={styles.searchContainer}
            activeOpacity={0.5}>
            <Icon
              source={images.search}
              size={16}
              style={styles.searchIcon}
              color={colors.secondaryIcon}
            />
            <View pointerEvents="none" style={styles.inputContainer}>
              <TextInput
                ref={searchRef}
                placeholder={ContentManager.translate(
                  'cart_search_placeholder',
                )}
                style={styles.searchInput}
                placeholderTextColor={colors.placeholder}
                value={searchValue}
                onChangeText={textSearch}
              />
            </View>
          </TouchableOpacity>
          {searchValue ? (
            <View style={styles.clearSearchContainer}>
              <TouchableOpacity
                onPress={() => textSearch('')}
                style={styles.clearSearchTouchable}
                activeOpacity={0.5}>
                <Icon
                  source={images.closeIcon}
                  size={20}
                  style={styles.searchIcon}
                  color={colors.secondaryIcon}
                />
              </TouchableOpacity>
            </View>
          ) : null}
        </View>
        {Platform.OS !== 'web' && <Option
          icon={isScannerVisible ? images.closeIcon : images.scan}
          iconColor={isScannerVisible ? colors.accent : colors.secondaryIcon}
          onPress={() => {
            toggleScanner()
            if (searchRef.current?.isFocused()) {
              searchRef.current?.blur()
            }
          }}
          style={styles.leftSpacing}
        />}
        <Options items={sortOptions} title="" />
      </View>
    </SafeAreaView>
  )
}

Cart.propTypes = {
  navigation: PropTypes.object,
  route: PropTypes.object,
}

export default Cart
