import React from 'react'
import { View, TouchableOpacity, useWindowDimensions } from 'react-native'
import PropTypes from 'prop-types'
import { useTheme } from 'react-native-themed-styles'

import Icon from '../Icon'

import themedstyles from './ModalContent.Styles'
import { images } from '../../Themes'

function ModalContent({ onClose, style, contentContainerStyle, children }) {
  const [styles] = useTheme(themedstyles)
  const { width } = useWindowDimensions()

  return (
    <View style={[styles.modalContent(Math.min(400, width - 30)), style]}>
      {onClose && (
        <TouchableOpacity style={styles.closeContainer} onPress={onClose}>
          <Icon size={15} source={images.close} style={styles.icon} />
        </TouchableOpacity>
      )}
      <View style={[styles.contentContainer, contentContainerStyle]}>
        {children}
      </View>
    </View>
  )
}

ModalContent.propTypes = {
  onClose: PropTypes.func,
  style: PropTypes.object,
  contentContainerStyle: PropTypes.object,
  children: PropTypes.any,
}

export default ModalContent
