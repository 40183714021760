/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useContext } from 'react'
import { View, TouchableOpacity, Dimensions } from 'react-native'
import { useLazyQuery, useMutation } from '@apollo/client'
import ContentLoader, { Rect } from 'react-content-loader/native'
import PropTypes from 'prop-types'
import { useTheme } from 'react-native-themed-styles'
import { useSelector } from 'react-redux'

import {
  CheckBox,
  Button,
  Icon,
  SwipeList,
  Text,
  Modal,
  Loader,
  Header,
  ModalContent,
} from '../../Components'

import { getProductCatalog } from '../ProductCatalog/ProductCatalog.Selectors'


import { getErrorMessage, useConfig } from '../../Utils'

import { GET_MODIFIERS, DELETE_MODIFIER } from './Modifiers.schema'

import Theme from '../../Themes/Theme'
import { images, animations } from '../../Themes'
import themedStyles, {
  modifierStyles,
  hiddenItemStyles,
  separatorStyles,
} from './Modifiers.Styles'

const { width } = Dimensions.get('screen')

function Modifier({ name, onPress, isFirst, isLast }) {
  const [styles] = useTheme(modifierStyles)

  return (
    <View
      style={[
        styles.container,
        styles['firstItem_' + isFirst],
        styles['lastItem_' + isLast],
      ]}>
      <TouchableOpacity onPress={onPress} style={styles.touchable}>
        <View style={styles.textContainer}>
          <Text i18nKey={name} numberOfLines={2} />
        </View>
        <Icon source={images.chevronRight} style={styles.rightIcon} />
      </TouchableOpacity>
    </View>
  )
}

Modifier.propTypes = {
  name: PropTypes.string,
  onPress: PropTypes.func,
  isFirst: PropTypes.bool,
  isLast: PropTypes.bool,
}

function SelectableModifier({ name, selected, onPress, isFirst, isLast }) {
  const [styles] = useTheme(modifierStyles)

  return (
    <View
      style={[
        styles.container,
        styles['firstItem_' + isFirst],
        styles['lastItem_' + isLast],
      ]}>
      <TouchableOpacity onPress={onPress} style={styles.touchable}>
        <View style={styles.textContainer}>
          <Text i18nKey={name} numberOfLines={2} />
        </View>
        <View pointerEvents="none" style={styles.checkBox}>
          <CheckBox
            checked={selected}
            onValueChange={() => { }}
            tintColors={styles.checkBoxTinColors}
          />
        </View>
      </TouchableOpacity>
    </View>
  )
}

SelectableModifier.propTypes = {
  name: PropTypes.string,
  selected: PropTypes.bool,
  onPress: PropTypes.func,
  isFirst: PropTypes.bool,
  isLast: PropTypes.bool,
}

function ModifierPlaceholder({ isFirst, isLast }) {
  const [styles] = useTheme(themedStyles)

  return (
    <TouchableOpacity
      style={[
        styles.itemContainer,
        styles['fistItem_' + isFirst],
        styles['lastItem_' + isLast],
      ]}
      disabled>
      <ContentLoader
        speed={2}
        width={width - 30}
        height={60}
        backgroundColor="#f3f3f3"
        foregroundColor="#fff">
        <Rect
          x="15"
          y="21"
          rx="6"
          ry="6"
          width={`${(width - 30) * (3 / 5)}`}
          height="18"
        />
        <Rect
          x={`${width - 128}`}
          y="21"
          rx="6"
          ry="6"
          width="50"
          height="18"
        />
        <Rect x={`${width - 63}`} y="21" rx="3" ry="3" width="18" height="18" />
      </ContentLoader>
    </TouchableOpacity>
  )
}
ModifierPlaceholder.propTypes = {
  isFirst: PropTypes.bool,
  isLast: PropTypes.bool,
}

function HiddenItem({
  needsConfirmation,
  onPress,
  onConfirm,
  onCancel,
  loading,
  isFirst,
  isLast,
}) {
  const [styles] = useTheme(hiddenItemStyles)
  const { colors } = useContext(Theme)

  return (
    <View
      style={[
        styles.wrapper,
        styles['firstItem_' + isFirst],
        styles['lastItem_' + isLast],
      ]}>
      {loading ? (
        <View style={styles.loader}>
          <Loader
            size={32}
            color={colors.white}
            source={animations.vfLoaderWhite}
          />
        </View>
      ) : needsConfirmation ? (
        <TouchableOpacity style={styles.option} onPress={onPress}>
          <Icon source={images.delete} color={colors.white} size={24} />
        </TouchableOpacity>
      ) : (
        <View style={styles.optionFull}>
          <TouchableOpacity style={styles.confirm} onPress={onConfirm}>
            <Icon source={images.delete} color={colors.white} size={24} />
            <Text
              i18nKey="opened_orders_delete_confirm"
              color={colors.white}
              style={styles.confirmText}
            />
          </TouchableOpacity>
          <TouchableOpacity style={styles.cancel} onPress={onCancel}>
            <Icon source={images.closeIcon} color={colors.white} size={20} />
          </TouchableOpacity>
        </View>
      )}
    </View>
  )
}
HiddenItem.propTypes = {
  needsConfirmation: PropTypes.bool,
  onPress: PropTypes.func,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  loading: PropTypes.bool,
  isFirst: PropTypes.bool,
  isLast: PropTypes.bool,
}

function Separator() {
  const [styles] = useTheme(separatorStyles)
  return <View style={styles.container} />
}

function Modifiers({
  route: {
    params: {
      selectable = false,
      preselectedModifiers = [],
      updateSelection = () => { },
    } = {},
  } = {},
  navigation: { navigate, goBack },
}) {

  const { switchLocation: { deviceId, locationId: locId } } = useConfig()

  const [styles] = useTheme(themedStyles)
  const { colors } = useContext(Theme)
  const [needsConfirmation, setNeedForConfirmation] = useState(true)
  const [modifiers, setModifiers] = useState([])
  const [itemWidth, setItemWidth] = useState(0)
  const [limit] = useState(30)
  const [searchText, setSearchText] = useState('')

  const { locationId, businessName, address } = useSelector(getProductCatalog)

  const [selectedModifiers, setSelectedModifiers] = useState(() => {
    const res = {}
    preselectedModifiers.map((element) => {
      res[element.id] = element
    })
    return res
  })

  const [listModifiers, { loading, data, refetch, fetchMore }] = useLazyQuery(
    GET_MODIFIERS,
    {
      fetchPolicy: 'network-only',
    }
  )

  useEffect(() => {
    listModifiers({
      variables: {
        locId: locId,
        cashRegisterId: deviceId,
        limit: limit,
        locationId: locationId ? locationId : null,
        filter: {
          name: 'name',
          value: searchText,
        },
      },
    })
  }, [])

  const [isErrorActive, setErrorActive] = useState(false)
  const [deleteModifier, { loading: deleting, error: deleteError }] = useMutation(
    DELETE_MODIFIER,
  )

  const onSearch = (text) => {
    setSearchText(text)
    listModifiers({
      variables: {
        locId: locId,
        cashRegisterId: deviceId,
        limit: limit,
        locationId: locationId ? locationId : null,
        filter: {
          name: 'name',
          value: text,
        },
      },
    })
  }

  const onEndReached = () => {
    if (!data?.listModifierLists?.nextToken) {
      return
    }

    fetchMore({
      query: GET_MODIFIERS,
      variables: {
        locId: locId,
        cashRegisterId: deviceId,
        limit: limit,
        locationId: locationId ? locationId : null,
        nextToken: data.listModifierLists.nextToken,
        filter: {
          name: 'name',
          value: searchText,
        },
      },
    })
  }

  useEffect(() => {
    const mods = data?.listModifierLists?.modifierLists
    if (mods) {
      setModifiers([...mods])
    }
  }, [data])

  const toggleModifier = (id, item) => {
    setSelectedModifiers((prev) => {
      if (prev[id]) {
        delete prev[id]
      } else {
        prev[id] = item
      }
      return { ...prev }
    })
  }

  const renderSelectableItem = ({ item, index }) => (
    <SelectableModifier
      id={item.id}
      name={item.name}
      selected={Boolean(selectedModifiers[item.id])}
      onPress={() => toggleModifier(item.id, item)}
      isFirst={index === 0}
      isLast={index === modifiers.length - 1}
    />
  )

  const editModifier = (item) => {
    if (item) {
      navigate('NewModifier', { modifier: item, onSave: () => refetch() })
    } else {
      navigate('NewModifier', { onSave: () => refetch() })
    }
  }

  const renderNonSelectableItem = ({ item, index }) => (
    <Modifier
      id={item.id}
      name={item.name}
      onPress={() => editModifier(item)}
      isFirst={index === 0}
      isLast={index === modifiers.length - 1}
    />
  )

  const renderItem = selectable
    ? renderSelectableItem
    : renderNonSelectableItem

  const openRow = (rowMap, id) => {
    setNeedForConfirmation(false)
    rowMap[id].manuallySwipeRow(-itemWidth)
  }

  const closeRow = (rowMap, id) => {
    rowMap[id].closeRow()
    setNeedForConfirmation(true)
  }

  const deleteRow = (rowMap, id) => {
    deleteModifier({
      variables: {
        locId: locId,
        cashRegisterId: deviceId,
        id: id,
        locationId: locationId ? locationId : null,
      },
    })
      .then(() => {
        // displayToast('modifiers_delete_success')
        closeRow(rowMap, id)
        refetch()
      })
      .catch(() => {
        setErrorActive(true)
        closeRow(rowMap, id)
      })
  }

  const renderHiddenItem = ({ item, index }, rowMap) => (
    <HiddenItem
      needsConfirmation={needsConfirmation}
      onPress={() => openRow(rowMap, item.id)}
      onConfirm={() => deleteRow(rowMap, item.id)}
      onCancel={() => closeRow(rowMap, item.id)}
      loading={deleting}
      isFirst={index === 0}
      isLast={index === modifiers.length - 1}
    />
  )

  return (
    <>
      <Header
        title="header_all_modifiers"
        image={images.closeIcon}
        onPress={goBack}
        customBusinessName={businessName}
        customAddress={address}
      />
      <SwipeList
        searchValue={searchText}
        searchPlaceholder="midifiers_search_placeholder"
        onSearch={onSearch}
        data={modifiers}
        renderItem={renderItem}
        renderHiddenItem={!selectable ? renderHiddenItem : undefined}
        disableLeftSwipe={selectable}
        disableRightSwipe
        rightOpenValue={-100}
        onRowClose={() => setNeedForConfirmation(true)}
        setItemWidth={setItemWidth}
        createNewLabel="modifiers_button_create"
        onCreatePress={() => editModifier()}
        onEndReached={() => onEndReached()}
        buttonVariant={selectable ? 'secondary' : 'active'}
        ItemSeparatorComponent={Separator}
        refreshing={loading}
        onRefresh={() => refetch()}
      />
      {Boolean(selectable) && (
        <Button
          iconStyle={styles.plusIcon}
          title={'discount_button_save'}
          titleStyle={styles.buttonTitle}
          style={styles.button}
          variant={
            Object.keys(selectedModifiers).length === 0 ? 'disabled' : 'active'
          }
          onPress={() => {
            updateSelection(Object.values(selectedModifiers))
            goBack()
          }}
        />
      )}
      <Modal
        isVisible={isErrorActive}
        onBackButtonPress={() => setErrorActive(false)}
        onBackdropPress={() => setErrorActive(false)}
        animationIn="fadeIn"
        animationOut="fadeOut">
        <ModalContent onClose={() => setErrorActive(false)}>
          <Icon source={images.delete} style={styles.errorIcon} />
          <Text
            i18nKey="orders_error_label"
            color={colors.accent}
            size="h5"
            align="center"
            weight="bold"
            style={styles.errorTitle}
          />
          <Text
            i18nKey={getErrorMessage(deleteError)}
            align="center"
          />
        </ModalContent>
      </Modal>
    </>
  )
}

Modifiers.propTypes = {
  route: PropTypes.object,
  navigation: PropTypes.object,
}

export default Modifiers
