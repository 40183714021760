import { styleSheetFactory } from '../../Themes/Colors'

const styles = styleSheetFactory(colors => ({
  container: {
    flexGrow: 1,
    flex: 1,
    backgroundColor: colors.background,
  },
  list: {
    flex: 1,
    marginTop: 15,
  },
  inlineButton1: {
    flex: 1,
    height: 50,
    marginRight: 7.5,
    backgroundColor: colors.white,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 7,
    flexDirection: 'row',
  },
  inlineButton2: {
    flex: 1,
    height: 50,
    marginLeft: 7.5,
    backgroundColor: colors.white,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 7,
    flexDirection: 'row',
  },
  buttonIcon: {
    marginRight: 10,
  },
  inlineButtons: {
    flexDirection: 'row',
    marginVertical: 10,
    alignItems: 'center',
    paddingHorizontal: 15,
  },
  inlineButtonsFooter: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 15,
    marginVertical: 15,
  },
  loaderContainer: {
    backgroundColor: colors.white,
    borderBottomColor: colors.separator,
    borderBottomWidth: 1,
    height: 60,
  },
  listRightComponent: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  statusIcon: {
    marginRight: 5,
  },
  separatorStyle: {
    height: 1,
    flex: 1,
    backgroundColor: colors.lightGrey,
  },

  tabView: {
    backgroundColor: colors.background,
    marginTop: 15,
  },
  scene: {
    flex: 1,
    backgroundColor: colors.background,
  },
  label: {
    fontFamily: 'Vodafone Rg',
    textTransform: 'none',
  },
  indicatorStyle: {
    backgroundColor: colors.tabAccent,
    height: 1,
  },
  tabContainerStyle: {
    backgroundColor: colors.tertiaryBackground,
    elevation: 0,
    shadowRadius: 0,
    shadowOpacity: 0,
    marginBottom: 0,
  },
  refundButton: {
    height: 50,
    marginHorizontal: 15,
    marginTop: 10,
    marginBottom: 20,
  },
  invValContainer: {
    marginVertical: 20,
    paddingVertical: 10,
    borderTopColor: colors.separator,
    borderTopWidth: 1,
    justifyContent: 'flex-start',
  },
  qrCode: {
    alignSelf: 'center',
    marginVertical: 25,
    padding: 15,
    backgroundColor: colors.background,
  },
  invoiceButton: {
    marginHorizontal: 15,
  },
  fullButton: {
    height: 50,
    justifyContent: 'flex-start',
    paddingHorizontal: 0,
    marginBottom: 30,
  },
  sectionStyle: {
    marginTop: 20,
  },
  listStyles: { flex: 1 },
  sectionHeader: { fontWeight: 'bold' },
  separator: {
    height: 1,
    backgroundColor: colors.separator,
  },
  noteSectionContainer: {
    backgroundColor: colors.tertiaryBackground,
    marginVertical: 8,
    borderRadius: 7,
  },
  expandableContainer: {
    paddingVertical: 20,
    paddingHorizontal: 15,
  },
  noteContainer: {
    paddingVertical: 20,
    paddingHorizontal: 15,
    marginVertical: 0,
  },
}))

export default styles
