import { styleSheetFactory } from '../../Themes/Colors'

const styles = styleSheetFactory(colors => ({
    container: {
        flexGrow: 1,
        flex: 1,
        backgroundColor: colors.background,
    },
    contentContainer: {
        flex: 1,
        alignSelf: 'center',
    },
    buttonContainer: {
        marginTop: 15,
        marginHorizontal: 15,
    },
    headerTitle: {
        marginBottom: 10,
        alignSelf: 'center',
    },
    stepText: {
        padding: 5,
    },
    shadow: {
        marginVertical: 30,
        alignItems: 'center',
        shadowColor: colors.black,
        shadowOffset: {
            width: 0,
            height: -1,
        },
        shadowOpacity: 0.16,
        shadowRadius: 6,
        marginHorizontal: 12,
    },
    imageContainer: {
        alignItems: 'center',
        width: '100%',
        marginHorizontal: 10,
        padding: 30,
        backgroundColor: colors.white,
        borderRadius: 6,
    },
    contentItem: {
        flexDirection: 'row',
        alignItems: 'center',
        alignSelf: 'flex-start',
    },
    scrollView: { flex: 1 },
    rightSpacing: { marginRight: 15 },
}))

export default styles
