import React, { forwardRef } from 'react'
import { View, Dimensions, TouchableOpacity } from 'react-native'
import PropTypes from 'prop-types'
import { useTheme } from 'react-native-themed-styles'
import ContentLoader, { Rect } from 'react-content-loader/native'

import Text from '../Text'
import TextInput from '../TextInput'

import Icon from '../Icon'

import themedStyles from './InputRow.Styles'
import colors from '../../Themes/Colors'

const { width } = Dimensions.get('screen')

const MyComponent = forwardRef((props, ref) => {
    const [styles] = useTheme(themedStyles)

    const {
      inputRef,
      label,
      inputType,
      floatingLabel,
      value,
      placeholder,
      onChangeText,
      onBlur,
      inputLabel,
      style,
      linking,
      linkingLabel,
      linkingValue,
      rightIcon,
      onLinkPress,
      underlineColorAndroid,
      multiline,
      rightIconStyle,
      leftInput,
      leftValue,
      leftLabel,
      onChangeLeftText,
      leftPlaceholder,
      leftInputType,
      inputStyle,
      inputLoading,
      editable,
      ...rest
    } = props

    if (floatingLabel) {
      return (
        <View style={[styles.floatingContainer, style]}>
          <Text style={styles.floatingLabel} color={colors.secondaryText} weight="light" size="footnote" i18nKey={floatingLabel} />
          <View style={styles.inputView}>
            {leftInput &&
              <>
                <Text weight="light" style={styles.inputLabel} i18nKey={leftLabel} />
                <TextInput
                  placeholder={leftPlaceholder}
                  underlineColorAndroid={underlineColorAndroid}
                  keyboardType={leftInputType}
                  placeholderTextColor={colors.placeholder}
                  style={[styles.searchInput, styles.leftInput]}
                  onChangeText={text => onChangeLeftText(text)}
                  onBlur={onBlur}
                  value={leftValue ? leftValue + '' : leftValue}
                  multiline={multiline}
                  {...rest}
                />
              </>
            }
            <TextInput
              placeholder={placeholder}
              placeholderTextColor={colors.placeholder}
              value={value || ''}
              keyboardType={inputType}
              onChangeText={text => onChangeText(text)}
              style={[styles.floatingInput, inputStyle]}
              multiline={multiline}
            />
            {Boolean(inputLabel) && <Text weight="light" style={styles.inputLabel} i18nKey={inputLabel} />}
          </View>
        </View>
      )
    }

    return (
      <View style={[styles.inputContainer, style]}>
        {!leftInput && <Text weight="light" style={styles.label} i18nKey={label} />}
        {leftInput &&
          <TextInput
            placeholder={leftPlaceholder}
            underlineColorAndroid={underlineColorAndroid}
            keyboardType={leftInputType}
            placeholderTextColor={colors.placeholder}
            style={[styles.searchInput, styles.leftInput]}
            onChangeText={text => onChangeLeftText(text)}
            onBlur={onBlur}
            value={leftValue}
            multiline={multiline}
            editable={editable}
            {...rest}
          />
        }
        {inputLoading
          ? <ContentLoader
            speed={0.7}
            height={40}
            width={width / 3}
            style={Object.assign({})}
            backgroundColor="#e2e2e2"
            foregroundColor="#ecebeb">
            <Rect x={`${width - (width - 20)}`} y="10" rx="3" ry="3" width="100" height="20" />
          </ContentLoader>
          : <TextInput
            ref={inputRef}
            placeholder={placeholder || ''}
            underlineColorAndroid={underlineColorAndroid}
            keyboardType={inputType}
            placeholderTextColor={colors.placeholder}
            style={[styles.input, styles[`withInputLabel_${Boolean(inputLabel)}`], inputStyle]}
            //style={[styles.searchInput]}
            onChangeText={text => onChangeText(text)}
            onBlur={onBlur}
            value={value}
            multiline={multiline}
            editable={editable}
            {...rest}
          />
        }

        {linking &&
          <TouchableOpacity onPress={onLinkPress} style={styles.category}>
            <Text i18nKey={linkingValue != null ? linkingValue : linkingLabel} style={styles.linkingLabel} align="right" />
            {rightIcon && <Icon source={rightIcon} style={rightIconStyle} size={24} color={colors.accent} />}
          </TouchableOpacity>
        }

        {Boolean(inputLabel) && <Text weight="light" style={styles.inputLabel} i18nKey={inputLabel} />}

      </View>
    )
})

MyComponent.displayName = 'InputRow'


MyComponent.defaultProps = {
  underlineColorAndroid: colors.transparent,
  multiline: false,
  editable: true,
}

MyComponent.propTypes = {
  inputRef: PropTypes.any,
  label: PropTypes.string,
  floatingLabel: PropTypes.string,
  inputType: PropTypes.string,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  onChangeText: PropTypes.func,
  onBlur: PropTypes.func,
  inputLabel: PropTypes.string,
  style: PropTypes.object,
  underlineColorAndroid: PropTypes.string,
  multiline: PropTypes.bool,
  linking: PropTypes.bool,
  linkingLabel: PropTypes.string,
  linkingValue: PropTypes.string,
  rightIcon: PropTypes.any,
  onLinkPress: PropTypes.func,
  rightIconStyle: PropTypes.object,
  leftValue: PropTypes.string,
  onChangeLeftText: PropTypes.func,
  leftPlaceholder: PropTypes.string,
  leftInputType: PropTypes.string,
  leftInput: PropTypes.bool,
  inputStyle: PropTypes.object,
  leftLabel: PropTypes.string,
  inputLoading: PropTypes.bool,
  editable: PropTypes.bool,
}

export default MyComponent
