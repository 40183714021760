/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react'
import { View, SafeAreaView, TouchableOpacity, Animated, Text as RNText } from 'react-native'
import { useTheme } from 'react-native-themed-styles'
import PropTypes from 'prop-types'
import { useMutation, useLazyQuery } from '@apollo/client'
import _ from 'lodash'


import {
    Icon,
    Text,
    InputWithTags,
    InputPhone,
    CheckBox,
    Button,
    SwipeList,
    Modal,
    ModalContent,
    Loader,
    TouchableInput,
    BottomSheetSelector,
    Type,
} from '../../Components'
import { getErrorMessage } from '../../Utils'
import ContentManager from '../../Utils/ContentManager'

import { STATE_LIST } from './CountryCodes'

import { GET_INDIVID_CUSTOMER, CREATE_CUSTOMER, EDIT_CUSTOMER, GET_INDIVID_SUPPLIER, CREATE_SUPPLIER, EDIT_SUPPLIER, REMOVE_CUSTOMER_GROUP, REMOVE_SUPPLIER_GROUP } from './NewCustomer.Schema'

import themedStyles, { itemStyles, hiddenItemStyles, stateitemStyles } from './NewCustomer.Styles'
import images from '../../Themes/Images'

import { colors, animations } from '../../Themes'

const ID_TYPES = [
    {
        code: 'NUIS',
        description: 'certificate_nipt_label',
        type: 'NUIS',
    },
    {
        code: 'ID',
        description: 'certificate_id_label',
        type: 'ID',
    },
    {
        code: 'PASS',
        description: 'customer_passport_number_label',
        type: 'PASS',
    },
    {
        code: 'VAT',
        description: 'customer_vat_number_label',
        type: 'VAT',
    },
    {
        code: 'TAX',
        description: 'customer_tax_number_label',
        type: 'TAX',
    },
    {
        code: 'SOC',
        description: 'customer_soc_number_label',
        type: 'SOC',
    },
]


function StateType({ item, selected, onPress }) {

    const [itemStyle] = useTheme(stateitemStyles)
    const isSelected = selected === item

    return (
        <View style={itemStyle.container}>
            <TouchableOpacity
                onPress={onPress}
                style={itemStyle['itemSelected_' + isSelected]}
                activeOpacity={0.6}
            >
                <Text
                    i18nKey={item}
                    size="footnote"
                    style={itemStyle['itemTextSelected_' + isSelected]}
                />
            </TouchableOpacity>
        </View>
    )
}
StateType.propTypes = {
    item: PropTypes.string,
    selected: PropTypes.object,
    onPress: PropTypes.func,
}

function TouchableItem({ leftText, group, onPress, disabled }) {
    const [styles] = useTheme(themedStyles)

    return (
        <TouchableOpacity
            onPress={() => onPress()}
            disabled={disabled}
            style={styles.touchableContainer}>
            <View style={[styles.subContainer, styles.rightSelfSpacing]}>
                <View style={styles.textContainer}>
                    <Text
                        weight="light"
                        align="left"
                        style={styles.text}
                        i18nKey={leftText}
                    />
                    {group ? (
                        <View style={styles.rightContainer}>
                            <Text i18nKey={group} align="right" />
                        </View>
                    ) : null}
                </View>
                <Icon source={images.arrow_right} size={20} />
            </View>
        </TouchableOpacity>
    )
}
TouchableItem.propTypes = {
    leftText: PropTypes.string,
    group: PropTypes.string,
    onPress: PropTypes.func,
    disabled: PropTypes.bool,
}

function CheckItem({ label, onPress, checked }) {
    const [styles] = useTheme(themedStyles)

    return (
        <View style={styles.rightContainer}>
            <CheckBox
                checked={checked}
                tintColors={{
                    true: colors.secondaryAccent,
                    false: colors.placeholder,
                }}
                onValueChange={onPress}
            />
            <Text i18nKey={label} style={styles.leftSpacing} />
        </View>
    )
}
CheckItem.propTypes = {
    label: PropTypes.string,
    onPress: PropTypes.func,
    checked: PropTypes.bool,
}

const Item = (({
    name,
    labelColor,
    onPress,
    isFirst,
    isLast,
}) => {
    const [styles] = useTheme(itemStyles)
    const anim = useRef(new Animated.Value(1)).current
    const animate = value => {
        Animated.spring(anim, {
            toValue: value,
            useNativeDriver: true,
        }).start()
    }

    return (
        <Animated.View
            style={[
                styles.container,
                styles['firstItem_' + isFirst],
                styles['lastItem_' + isLast],
                { transform: [{ scale: anim }] },
            ]}>
            <TouchableOpacity
                style={styles.touchable}
                onPress={onPress}
                onPressIn={() => animate(1.05)}
                onPressOut={() => animate(1)}
                activeOpacity={0.7}
                disabled={!onPress}>
                <View style={styles.imageContainer(labelColor)}>
                    <Text
                        size={'h5'}
                        color={labelColor}
                        i18nKey={name
                            ?.match(/\b(\w{1})/g)
                            ?.slice(0, 2)
                            ?.join('')
                            ?.toUpperCase()}
                    />
                </View>
                <View style={styles.infoContainer}>
                    <View style={styles.descContainer}>
                        <Text i18nKey={name} numberOfLines={1} />
                    </View>
                    <View style={styles.priceContainer} >
                        <Icon source={images.chevronRight} size={24} color={colors.accent} />
                    </View>
                </View>

            </TouchableOpacity>
        </Animated.View>
    )
})
Item.defaultProps = {
    labelColor: 'white',
}
Item.propTypes = {
    name: PropTypes.string,
    onPress: PropTypes.func,
    isFirst: PropTypes.bool,
    isLast: PropTypes.bool,
}

function HiddenItem({
    needsConfirmation,
    onPress,
    onConfirm,
    onCancel,
    isFirst,
    isLast,
}) {
    const [styles] = useTheme(hiddenItemStyles)

    return (
        <View
            style={[styles.wrapper, styles['firstItem_' + isFirst], styles['lastItem_' + isLast]]}>
            {needsConfirmation ? (
                <TouchableOpacity style={styles.option} onPress={onPress}>
                    <Icon source={images.delete} color={colors.white} size={24} />
                </TouchableOpacity>
            ) : (
                <View style={styles.optionFull}>
                    <TouchableOpacity style={styles.confirm} onPress={onConfirm}>
                        <Icon source={images.delete} color={colors.white} size={24} />
                        <Text
                            i18nKey="opened_orders_delete_confirm"
                            color={colors.white}
                            style={styles.confirmText}
                        />
                    </TouchableOpacity>
                    <TouchableOpacity style={styles.cancel} onPress={onCancel}>
                        <Icon source={images.closeIcon} color={colors.white} size={20} />
                    </TouchableOpacity>
                </View>
            )}
        </View>
    )
}
HiddenItem.propTypes = {
    needsConfirmation: PropTypes.bool,
    onPress: PropTypes.func,
    onConfirm: PropTypes.func,
    onCancel: PropTypes.func,
    loading: PropTypes.bool,
    isFirst: PropTypes.bool,
    isLast: PropTypes.bool,
}

function IndividForm({ navigate, id, goBack, isSupplier = false, onSave = () => { } }) {
    const [styles] = useTheme(themedStyles)
    const { translate } = ContentManager

    const [itemWidth, setItemWidth] = useState(0)
    const [needsConfirmation, setNeedForConfirmation] = useState(true)

    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [userEmail, setEmail] = useState('')
    const [userPhone, setPhone] = useState('')
    const [street, setStreet] = useState('')
    const [city, setCity] = useState('')
    const [state, setUserState] = useState({})
    const [birthday, setBirthday] = useState(null)

    const [prevGroups, setPrevGroups] = useState([])
    const [groups, setGroups] = useState([])
    const [searchValue, setSearchValue] = useState('')
    const [selected, setSelected] = useState({})
    const [customerId, setCustomerId] = useState('')

    const [emailUnsubsribed, setEmailUnsubsribed] = useState(true)
    const [limit] = useState(20)

    const regex = /^([0-2][0-9]|(3)[0-1])(\/)(((0)[0-9])|((1)[0-2]))(\/)\d{4}$/
    const phoneRegex = /^\d+$/g
    const [disabled, setDisabled] = useState(true)
    const [isErrorActive, setErrorActive] = useState({
        isVisible: false,
        message: '',
    })

    const [modal, setModal] = useState({
        isVisible: false,
        data: [],
        title: '',
        placeholder: '',
        selected: undefined,
        select: () => { },
        Item: Type,
    })

    const closeModal = () => {
        setModal(prev => ({ ...prev, isVisible: false }))
        setSearchValue('')
    }

    const openIdTypeSelector = () => {
        isSupplier ?
            setModal({
                isVisible: true,
                data: ID_TYPES,
                title: 'supplier_id_label',
                placeholder: 'customer_id_search_placeholder',
                selected: selected,
                select: (obj) => {
                    setSelected(obj)
                    setCustomerId('')
                },
                searchKey: 'code',
                Item: Type,
            })
            :
            setModal({
                isVisible: true,
                data: ID_TYPES,
                title: 'customer_id_label',
                placeholder: 'customer_id_search_placeholder',
                selected: selected,
                select: (obj) => {
                    setSelected(obj)
                    setCustomerId('')
                },
                searchKey: 'code',
                Item: Type,
            })
    }

    const openStateSelector = () => {
        setModal({
            isVisible: true,
            data: STATE_LIST,
            title: 'customers_state_label',
            placeholder: 'customers_state_placeholder',
            selected: state,
            select: (obj) => {
                setUserState(obj)
            },
            searchKey: 'description',
            Item: Type,
        })
    }

    // const [show, setShow] = useState({
    //     shown: false,
    //     birthday: '',
    // })

    const updateGroups = (newGroup) => {
        setGroups([...newGroup])
    }

    const updateSelectedGroups = updated => {
        updateGroups(updated)
        setGroups(updated?.filter(element => element.name?.toLowerCase()))
    }

    const changeGroup = group => {
        const i = groups.findIndex(element => element.id === group.id)
        if (i !== -1) {
            const updated = groups
            updated[i] = group
            updateSelectedGroups([...updated])
        }
    }

    const editGroup = item => {
        if (item) {
            navigate('NewGroup', { groupItem: item, type: isSupplier ? 'SUPPLIER' : 'CUSTOMER', onGroupSave: changeGroup })
        }
    }

    const [
        getCustomer,
        { data, fetchMore, refetch },
    ] = useLazyQuery(GET_INDIVID_CUSTOMER, {
        fetchPolicy: 'network-only',
        variables: {
            id: id,
            limit: limit,
        },
    })

    const [
        getSupplier,
        { data: supplierData, fetchMore: supplierFetchMore, refetch: supplierRefetch },
    ] = useLazyQuery(GET_INDIVID_SUPPLIER, {
        fetchPolicy: 'network-only',
        variables: {
            id: id,
            limit: limit,
        },
    })

    const addGroups = () => {
        navigate('Groups', {
            selectable: true,
            preselectedGroups: groups,
            updateSelection: id ? isSupplier ? () => supplierRefetch() : () => refetch() : updateSelectedGroups,
            type: 'INDIVIDUAL',
            customerId: id,
            isSupplier,
            shouldShowCustomers: !isSupplier,
            shouldShowSuppliers: isSupplier,
        })
    }

    useEffect(() => {
        const customerData = isSupplier ? supplierData?.getSupplier?.supplier : data?.getCustomer?.customer
        const groupsData = isSupplier ? supplierData?.getSupplier?.listSupplierGroups?.supplierGroups : data?.getCustomer?.listCustomerGroups?.customerGroups
        if (customerData) {
            const address = customerData?.address
            const preferences = customerData?.preferences
            const reference = customerData?.reference
            const idType = reference?.name
            const idValue = reference?.value
            const idObject = idType ? ID_TYPES?.find(item => item?.code === idType) : {}
            setSelected(idObject)
            setCustomerId(idValue)
            setFirstName(customerData?.givenName)
            setLastName(customerData?.familyName)
            setEmail(customerData?.emailAddress)
            setPhone(customerData?.phoneNumber)
            setStreet(address?.addressLine1)
            setCity(address?.administrativeDistrictLevel1)
            // setUserState(address?.country)
            setUserState(STATE_LIST.find((e) => e.code === address?.country))
            setBirthday(customerData?.birthday)
            // setShow(prev => ({
            //     ...prev,
            //     birthday: customerData?.birthday,
            // }))
            setEmailUnsubsribed(preferences?.emailUnsubscribed)
            setPrevGroups([...groupsData])
            updateGroups(groupsData)
        }

    }, [data, supplierData])

    const [createCustomer, {
        loading: createLoading,
    }] = useMutation(CREATE_CUSTOMER)

    const [updateCustomer, {
        loading: updateLoading,
    }] = useMutation(EDIT_CUSTOMER)

    const [createSupplier, {
        loading: createSupplierLoading,
    }] = useMutation(CREATE_SUPPLIER)

    const [updateSupplier, {
        loading: updateSupplierLoading,
    }] = useMutation(EDIT_SUPPLIER)

    const [removeCustomerGroup, {
    }] = useMutation(REMOVE_CUSTOMER_GROUP)

    const [removeSupplierGroup, {
    }] = useMutation(REMOVE_SUPPLIER_GROUP)

    useEffect(() => {
        if (id) {
            isSupplier ? getSupplier() : getCustomer()
        }
    }, [])

    const checkInputs = () => {
        if (
            firstName === '' ||
            lastName === '' ||
            userPhone === '' ||
            city === '' ||
            street === '' ||
            !state?.code ||
            customerId === ''
            // !regex.test(birthday)
        ) {
            setDisabled(true)
        } else {
            setDisabled(false)
        }
    }

    const changeBirthday = (val) => {
        // if (regex.test(val)) {
        setBirthday(val)

        // }
    }

    useEffect(() => {
        checkInputs()
        const checkData = isSupplier ? supplierData?.getSupplier?.supplier : data?.getCustomer?.customer
        const checkGroups = isSupplier ? supplierData?.getSupplier?.listSupplierGroups?.supplierGroups : data?.getCustomer?.listCustomerGroups?.customerGroups
        if (checkData) {
            if ((_.isEqual(checkData, {
                givenName: firstName,
                familyName: lastName,
                emailAddress: userEmail,
                address: {
                    addressLine1: street,
                    country: state?.code,
                    administrativeDistrictLevel1: city,
                },
                phoneNumber: userPhone,
                birthday: birthday,
                preferences: {
                    emailUnsubscribed: emailUnsubsribed,
                },
                reference: customerId ? {
                    name: selected.code,
                    value: customerId,
                } : null,
            })) && _.isEqual(groups, checkGroups)) {
                setDisabled(true)
            }
            else {
                setDisabled(false)
            }
        }

    }, [firstName,
        lastName,
        userPhone,
        street,
        city,
        state,
        birthday,
        emailUnsubsribed,
        userEmail,
        groups,
        customerId]
    )

    const openRow = (rowMap, groupId) => {
        setNeedForConfirmation(false)
        rowMap[groupId].manuallySwipeRow(-itemWidth)
    }

    const closeRow = (rowMap, groupId) => {
        rowMap[groupId].closeRow()
        setNeedForConfirmation(true)
    }

    const deleteRow = (rowMap, groupId) => {
        if (id) {
            if (isSupplier) {
                removeSupplierGroup({
                    variables: {
                        id: id,
                        supplierGroupsToDelete: [groupId],
                    },
                })
                    .then(() => supplierRefetch())
                    .catch((er) => {
                        setErrorActive({
                            isVisible: true,
                            message: er,
                        })
                    })
            }
            else {


                removeCustomerGroup({
                    variables: {
                        id: id,
                        customerGroupsToDelete: [groupId],
                    },
                })
                    .then(() => refetch())
                    .catch((er) => {
                        setErrorActive({
                            isVisible: true,
                            message: er,
                        })
                    })
            }
        } else {
            updateSelectedGroups(groups?.filter(element => element.id !== groupId))
        }
        closeRow(rowMap, groupId)
    }

    const renderItem = ({ item, index }) => (
        <Item
            {...item}
            onPress={() => editGroup(item)}
            isFirst={index === 0}
            isLast={index === groups.length - 1}
        />
    )

    const renderHiddenItem = ({ item, index }, rowMap) => (
        <HiddenItem
            needsConfirmation={needsConfirmation}
            onPress={() => openRow(rowMap, item.id)}
            onConfirm={() => deleteRow(rowMap, item.id)}
            onCancel={() => closeRow(rowMap, item.id)}
            loading={updateLoading}
            isFirst={index === 0}
            isLast={index === groups.length - 1}
        />
    )

    const onEndReached = () => {
        if (isSupplier) {
            if (!supplierData?.getSupplier?.listSupplierGroups?.nextToken) {
                return
            }

            supplierFetchMore({
                query: GET_INDIVID_SUPPLIER,
                variables: {
                    limit: limit,
                    nextToken: supplierData.getSupplier.listSupplierGroups.nextToken,
                    id: id,
                },
            })

        } else {
            if (!data?.getCustomer?.listCustomerGroups?.nextToken) {
                return
            }

            fetchMore({
                query: GET_INDIVID_CUSTOMER,
                variables: {
                    limit: limit,
                    nextToken: data.getCustomer.listCustomerGroups.nextToken,
                    id: id,
                },
            })
        }
    }

    const onButtonSave = () => {
        const groupIds = groups.map((element) => element.id)
        const prevGroupIds = prevGroups.map((element) => element.id)

        const groupsToEnable = groupIds?.filter(
            (element) => !prevGroupIds.includes(element),
        )
        const groupsToDelete = prevGroupIds?.filter(
            (element) => !groupIds.includes(element),
        )

        if (id) {
            if (isSupplier) {
                updateSupplier({
                    variables: {
                        id: id,
                        givenName: firstName,
                        familyName: lastName,
                        emailAddress: userEmail,
                        phoneNumber: userPhone,
                        addressLine1: street,
                        country: state?.code,
                        administrativeDistrictLevel1: city,
                        emailUnsubscribed: emailUnsubsribed,
                        birthday: birthday,
                        reference: {
                            name: selected.code,
                            value: customerId,
                        },
                    },
                })
                    .then(() => {
                        goBack()
                        onSave()
                    })
                    .catch((er) => {
                        setErrorActive({
                            isVisible: true,
                            message: er,
                        })
                    })
            } else {
                updateCustomer({
                    variables: {
                        id: id,
                        givenName: firstName,
                        familyName: lastName,
                        emailAddress: userEmail,
                        phoneNumber: userPhone,
                        addressLine1: street,
                        country: state?.code,
                        administrativeDistrictLevel1: city,
                        emailUnsubscribed: emailUnsubsribed,
                        birthday: birthday,
                        reference: {
                            name: selected.code,
                            value: customerId,
                        },
                    },
                })
                    .then(() => {
                        goBack()
                        onSave()
                    })
                    .catch((er) => {
                        setErrorActive({
                            isVisible: true,
                            message: er,
                        })
                    })
            }
        } else {
            if (isSupplier) {
                createSupplier({
                    variables: {
                        givenName: firstName,
                        familyName: lastName,
                        emailAddress: userEmail,
                        phoneNumber: userPhone,
                        addressLine1: street,
                        country: state?.code,
                        administrativeDistrictLevel1: city,
                        emailUnsubscribed: emailUnsubsribed,
                        supplierGroupsToEnable: groupsToEnable,
                        supplierGroupsToDelete: groupsToDelete,
                        birthday: birthday,
                        type: 'INDIVIDUAL',
                        reference: {
                            name: selected.code,
                            value: customerId,
                        },
                    },
                })
                    .then((res) => {
                        goBack()
                        onSave()
                    })
                    .catch((er) => {
                        setErrorActive({
                            isVisible: true,
                            message: er,
                        })
                    })
            }
            else {
                createCustomer({
                    variables: {
                        givenName: firstName,
                        familyName: lastName,
                        emailAddress: userEmail,
                        phoneNumber: userPhone,
                        addressLine1: street,
                        country: state?.code,
                        administrativeDistrictLevel1: city,
                        emailUnsubscribed: emailUnsubsribed,
                        customerGroupsToEnable: groupsToEnable,
                        customerGroupsToDelete: groupsToDelete,
                        birthday: birthday,
                        type: 'INDIVIDUAL',
                        reference: {
                            name: selected.code,
                            value: customerId,
                        },
                    },
                })
                    .then((res) => {
                        goBack()
                        onSave()
                    })
                    .catch((er) => {
                        setErrorActive({
                            isVisible: true,
                            message: er,
                        })
                    })
            }
        }
    }

    return (
        <>
            <SafeAreaView style={styles.container}>
                <SwipeList
                    data={groups}
                    renderItem={renderItem}
                    renderHiddenItem={renderHiddenItem}
                    rightOpenValue={-100}
                    onRowClose={() => setNeedForConfirmation(true)}
                    disableRightSwipe
                    setItemWidth={setItemWidth}
                    onEndReached={onEndReached}
                    ItemSeparatorComponent={() => <View style={styles.separator} />}
                    ListHeaderComponent={
                        <>
                            <Text i18nKey="users_personal_information_section" align="left" />
                            <InputWithTags
                                label="users_first_name_label"
                                value={firstName}
                                onChangeText={setFirstName}
                                autoCorrect={false}
                                inputContainerStyle={styles.inputContainer}
                                inputWrapperStyle={styles.itemSpacing}
                                labelOffset={{
                                    x0: 0,
                                    y0: -7,
                                    x1: 0,
                                    y1: 2,
                                }}
                            />
                            <InputWithTags
                                label="users_last_name_label"
                                value={lastName}
                                onChangeText={setLastName}
                                autoCorrect={false}
                                inputContainerStyle={styles.inputContainer}
                                inputWrapperStyle={styles.itemSpacing}
                                labelOffset={{
                                    x0: 0,
                                    y0: -7,
                                    x1: 0,
                                    y1: 2,
                                }}
                            />
                            <TouchableInput
                                onPress={() => openIdTypeSelector()}
                                label={isSupplier ? 'supplier_id_label' : 'customer_id_label'}
                                value={translate(selected.description || '')}
                                icon={images.filledDownArrow}
                                iconSize={16}
                                autoCorrect={false}
                                autoCapitalize="none"
                                editable={false}
                                style={styles.stateSelector}
                            />
                            {selected.code && <InputWithTags
                                label={selected.description}
                                value={customerId}
                                onChangeText={setCustomerId}
                                autoCorrect={false}
                                autoCapitalize="characters"
                                inputContainerStyle={styles.inputContainer}
                                inputWrapperStyle={styles.itemSpacing}
                                labelOffset={{
                                    x0: 0,
                                    y0: -7,
                                    x1: 0,
                                    y1: 2,
                                }}
                            />}
                            <InputWithTags
                                label="users_email_label"
                                value={userEmail}
                                onChangeText={setEmail}
                                autoCorrect={false}
                                autoCapitalize="none"
                                inputContainerStyle={styles.inputContainer}
                                inputWrapperStyle={styles.itemSpacing}
                                labelOffset={{
                                    x0: 0,
                                    y0: -7,
                                    x1: 0,
                                    y1: 2,
                                }}
                            />
                            <InputPhone
                                label="users_phone_label"
                                value={userPhone}
                                onChangeText={setPhone}
                                autoCorrect={false}
                                error={!phoneRegex.test(userPhone) && Boolean(userPhone)}
                                // editable={editable}
                                inputContainerStyle={styles.inputContainer}
                                inputWrapperStyle={styles.itemSpacing}
                                labelOffset={{
                                    x0: 0,
                                    y0: -7,
                                    x1: 0,
                                    y1: 2,
                                }}
                            />
                            <Text i18nKey="demo_address_label" align="left" style={styles.topSpacing} />
                            <InputWithTags
                                label="customers_street_label"
                                value={street}
                                onChangeText={setStreet}
                                autoCorrect={false}
                                inputContainerStyle={styles.inputContainer}
                                inputWrapperStyle={styles.itemSpacing}
                                labelOffset={{
                                    x0: 0,
                                    y0: -7,
                                    x1: 0,
                                    y1: 2,
                                }}
                            />
                            <InputWithTags
                                label="demo_city_label"
                                value={city}
                                onChangeText={setCity}
                                autoCorrect={false}
                                inputContainerStyle={styles.inputContainer}
                                inputWrapperStyle={styles.itemSpacing}
                                labelOffset={{
                                    x0: 0,
                                    y0: -7,
                                    x1: 0,
                                    y1: 2,
                                }}
                            />
                            <TouchableInput
                                onPress={() => openStateSelector()}
                                label="customers_state_label"
                                value={state ? state.description : ''}
                                icon={images.filledDownArrow}
                                iconSize={16}
                                autoCorrect={false}
                                autoCapitalize="none"
                                editable={false}
                                style={styles.stateSelector}
                            />
                            <Text i18nKey="customers_other_information_label" align="left" style={styles.topSpacing} />
                            <InputWithTags
                                label="customers_birthdate_label"
                                error={!regex.test(birthday) && Boolean(birthday)}
                                value={birthday}
                                onChangeText={changeBirthday}
                                autoCorrect={false}
                                autoCapitalize="none"
                                inputContainerStyle={styles.inputContainer}
                                inputWrapperStyle={styles.itemSpacing}
                                labelOffset={{
                                    x0: 0,
                                    y0: -7,
                                    x1: 0,
                                    y1: 2,
                                }}
                            />
                            <RNText
                                style={styles.vehiclePlateHelper}
                                allowFontScaling={false}
                            >
                                <RNText>{translate('customer_birdthday_helper_1')}</RNText>
                                <RNText style={styles.vehiclePlateHelperItalic} >{translate('customer_birdthday_helper_2')}</RNText>
                            </RNText>
                            <Text i18nKey="customer_consent_label" align="left" style={styles.topSpacing} />
                            <View style={styles.checkItemWrapper}>
                                <CheckItem
                                    label="customer_email_receipt_label"
                                    checked={!emailUnsubsribed}
                                    onPress={() => setEmailUnsubsribed(prev => !prev)}
                                />
                            </View>
                            <Button
                                title="customer_add_group_button"
                                icon={images.plus}
                                variant={'secondary'}
                                style={styles.addButton}
                                onPress={() => addGroups()}
                            />

                        </>
                    }

                    ListFooterComponent={<View style={styles.mediumTopSpacing}>

                        {/* {loading && <Loader size={24} style={{ marginBottom: 5 }} />} */}
                        {/* <TouchableOpacity
                            style={styles.birthdayContainer}
                            disabled={createLoading || updateLoading}
                            onPress={() => setShow(prev => ({
                                ...prev,
                                shown: true,
                            }))}
                        > */}
                        {/* <InputWithIcon
                                label="customers_birthdate_label"
                                value={birthday ? moment(birthday).format('DD/MM/YYYY') : ''}
                                onChangeText={setBirthday}
                                autoCorrect={false}
                                autoCapitalize="none"
                                editable={false}
                                icon={images.calendarBlack}
                                inputContainerStyle={styles.inputContainer}
                                // inputWrapperStyle={styles.itemSpacing}
                                labelOffset={{
                                    x0: 0,
                                    y0: -7,
                                    x1: 0,
                                    y1: 2,
                                }}
                            /> */}

                        {/* </TouchableOpacity> */}

                    </View>}

                />

            </SafeAreaView>
            <Button
                title="users_save_button"
                variant={disabled || (createLoading || createSupplierLoading) || (updateLoading || updateSupplierLoading) ? 'disabled' : 'active'}
                style={styles.saveButton}
                onPress={() => onButtonSave()}
                loader={(createLoading || createSupplierLoading) || (updateLoading || updateSupplierLoading)}
                loaderComponent={<Loader source={animations.vfLoaderWhite} size={24} />}
            />
            {/* <DatePickerModal
                onClose={() => setShow(prev => ({
                    ...prev,
                    shown: false,
                }))}
                isVisible={show.shown}
                onDateChange={(date) => {
                    setShow(prev => ({
                        ...prev,
                        birthday: date,
                    }))
                }}
                preselectedDate={moment(show.birthday).format('YYYY-MM-DD')}
                onButtonPress={() => {
                    setBirthday(show.birthday)
                    setShow(prev => ({
                        ...prev,
                        shown: false,
                    }))
                }}
                locale={LANGUAGES[lang]}
            /> */}

            <Modal
                isVisible={isErrorActive.isVisible}
                onBackButtonPress={() => setErrorActive({
                    isVisible: false,
                    message: '',
                })}
                onBackdropPress={() => setErrorActive({
                    isVisible: false,
                    message: '',
                })}
                animationIn="fadeIn"
                animationOut="fadeOut"
            >
                <ModalContent onClose={() => setErrorActive({
                    isVisible: false,
                    message: '',
                })}>
                    <Icon source={images.delete} style={styles.errorIcon} />
                    <Text i18nKey="orders_error_label" color={colors.accent} size="h5" align="center" weight="bold" style={styles.errorTitle} />
                    <Text i18nKey={getErrorMessage(isErrorActive.message)} align="center" />
                </ModalContent>
            </Modal>
            <BottomSheetSelector
                {...modal}
                closeModal={closeModal}
                searchValue={searchValue}
                setSearchValue={setSearchValue}
            />
        </>
    )
}

IndividForm.propTypes = {
    navigate: PropTypes.func,
    id: PropTypes.string,
    goBack: PropTypes.func,
    onSave: PropTypes.func,
    isSupplier: PropTypes.bool,
}

export default IndividForm
