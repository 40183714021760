import React, { useContext } from 'react'
import { TouchableOpacity, FlatList } from 'react-native'

import PropTypes from 'prop-types'

import { useTheme } from 'react-native-themed-styles'

import Text from '../Text'

import Theme from '../../Themes/Theme'

import themedstyles from './TagList.Styles'

function TagList(props) {

  const [styles] = useTheme(themedstyles)
  const { colors } = useContext(Theme)
  const { onPress, selected, options, style, onEndReached, contentContainerStyle, ...rest } = props

  const renderItem = ({ item, index }) => {
    const active = item.type === selected
    return (
      <TouchableOpacity
        onPress={() => onPress(item.type)}
        style={styles.button(active)}>
        <Text
          i18nKey={item.title}
          color={active ? colors.white : colors.primaryText}
          size="footnote"
          weight="light"
          align="center"
          style={styles.label}
        />
      </TouchableOpacity>
    )
  }

  return (
    <FlatList
      showsHorizontalScrollIndicator={false}
      horizontal
      keyExtractor={(item, index) => 'row-' + index}
      data={options}
      renderItem={renderItem}
      style={[styles.container, style]}
      contentContainerStyle={[styles.listContainerStyle, contentContainerStyle]}
      onEndReachedThreshold={0.5}
      onEndReached={onEndReached}
      {...rest}
    />
  )
}

TagList.defaultProps = {
}

TagList.propTypes = {
  selected: PropTypes.string,
  options: PropTypes.array,
  style: PropTypes.object,
  onPress: PropTypes.func,
  onEndReached: PropTypes.func,
  contentContainerStyle: PropTypes.object,
}

export default TagList
