import { styleSheetFactory } from '../../../../Themes/Colors'

const styles = styleSheetFactory(colors => ({
  container: {
    flex: 1,
    position: 'relative',
    backgroundColor: colors.background,
  },
  searchContainer: {
    flexDirection: 'row',
    marginTop: 15,
    minHeight: 50,
    justifyContent: 'center',
    alignItems: 'center',
  },
  listContainer: {
    marginTop: 0,
  },
  listRightComponent: {
    flexDirection: 'row',
    alignItems: 'center',
    marginRight: 10,
  },
  listRightComponentText: {
    alignItems: 'flex-end',
    marginRight: 10,
  },
  default: {
    flex: 1,
    backgroundColor: colors.tertiaryBackground,
    overflow: 'hidden',
  },
  list: {
    flexDirection: 'row',
    alignItems: 'center',
    flexGrow: 1,
  },
  listDescContainer: {
    flexGrow: 1,
    flexShrink: 1,
    marginLeft: 10,
    justifyContent: 'center',
  },
  divider: {
    height: 50,
    alignSelf: 'center',
    width: 1,
    backgroundColor: 'rgba(255, 255, 255, 0.4)',
    position: 'absolute',
  },
  listStyle: {
    flex: 1,
    marginHorizontal: 15,
  },
  separatorStyle: {
    height: 1,
    flex: 1,
    backgroundColor: colors.lightGrey,
  },
  fab: {
    position: 'absolute',
    bottom: 30,
    right: 15,
    height: 50,
    width: 50,
    borderRadius: 25,
    backgroundColor: colors.tertiaryAccent,
    justifyContent: 'center',
    alignItems: 'center',
    shadowColor: colors.black,
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.22,
    shadowRadius: 2.22,
    elevation: 3,
  },
  fabIcon: {
    transform: [{ rotate: '-90deg' }],
  },
  firstItem_true: {
    borderTopLeftRadius: 7,
    borderTopRightRadius: 7,
    borderTopWidth: 0,
  },
  lastItem_true: {
    borderBottomLeftRadius: 7,
    borderBottomRightRadius: 7,
  },
  itemStyle: {
    backgroundColor: colors.tertiaryBackground,
    height: 65,
    justifyContent: 'center',
    borderTopWidth: 1,
    borderTopColor: colors.separator,
  },
  errorItemContainer: {
    backgroundColor: colors.background,
    marginTop: 15,
    borderRadius: 7,
  },
  errorItemSubContainer: {
    padding: 15,
    justifyContent: 'center',
    alignItems: 'center',
  },
  sectionTitle: {
    marginBottom: 10,
    marginTop: 15,
  },
  centeredItemView: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  leftSpacing: {
    marginLeft: 10,
  },
  bottomSpacing: {
    marginBottom: 3,
  },
  descriptionStyles: type => ({
    fontSize: type ? 12 : 14,
    lineHeight: type ? 14 : 16,
  }),
  rightComponent: {
    alignItems: 'center',
    justifyContent: 'center',
    padding: 15,
    flexDirection: 'row',
  },
  rightSpacing: {
    marginRight: 5,
  },
  contentContainerStyle: {
    paddingBottom: 30,
    backgroundColor: colors.background,
  },
}))

export default styles
