import React from 'react'
import PropTypes from 'prop-types'

import BottomSheetModal from '../BottomSheetModal'

import SelectType from '../SelectType'
import ConfigurationSelectType from '../ConfigurationSelectType'

function BottomSheetSelector({
  isVisible,
  children,
  onModalHide,
  ...props
}) {
  const { closeModal, isConfiguration } = props

  return (
    <BottomSheetModal
      isVisible={isVisible}
      onClose={closeModal}
      onModalHide={onModalHide}
    >
      {isConfiguration ? <ConfigurationSelectType {...props} /> : <SelectType {...props} />}
      {children}
    </BottomSheetModal>
  )
}
BottomSheetSelector.defaultProps = {
  onModalHide: () => { },
}
BottomSheetSelector.propTypes = {
  isVisible: PropTypes.bool,
  children: PropTypes.any,
  closeModal: PropTypes.func,
  onModalHide: PropTypes.func,
  searchKey: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  props: PropTypes.object,
  isConfiguration: PropTypes.bool,
}

export default BottomSheetSelector
