import moment from 'moment'
import React, { memo, useEffect, useState } from 'react'
import { SafeAreaView, ScrollView, TouchableOpacity, View } from 'react-native'
import { useTheme } from 'react-native-themed-styles'
import PropTypes from 'prop-types'
import { useLazyQuery } from '@apollo/client'

import {
    Header,
    InputWithTags,
    DatePickerModal,
    Icon,
    Text,
    Button,
    DoubleIcon,
    Loader,
    ErrorModal,
} from '../../Components'
import { useLanguage } from '../../Utils/Language'
import { getErrorMessage } from '../../Utils'

import { GET_GOV_INVOICE } from './SearchGovInvoices.schema'

import themedStyles, { itemStyles } from './SearchGovInvoices.Styles'
import { colors, images } from '../../Themes'



const LANGUAGES = {
    al: 'sq',
    en: 'en-gb',
}

const Item = memo(
    ({
        isFirst = true,
        isLast = true,
        onPress = () => { },
        title,
    }) => {
        const [styles] = useTheme(itemStyles)
        // const { colors } = useContext(Theme)
        // const TypeIcon = icons[type]

        return (
            <View
                style={[
                    styles.itemStyle,
                    styles[`firstItem_${isFirst}`],
                    styles[`lastItem_${isLast}`],
                ]}>
                <TouchableOpacity onPress={onPress} disabled={!onPress}>
                    <View style={styles.container}>
                        <DoubleIcon
                            iconSource={images.cash}
                            statusIconSource={images.transaction}
                            iconSize={30}
                            statusSize={10}
                            style={styles.leftSpacing}
                            statusIconStyle={styles.bottomSpacing}
                        />
                        <View style={styles.listDescContainer}>
                            <Text i18nKey={title} numberOfLines={1} size="footnote" />
                        </View>
                        <View style={styles.amountContainer}>
                            <Icon source={images.arrow_right} size={20} />
                        </View>
                    </View>
                </TouchableOpacity>
            </View>
        )
    },
)
Item.propTypes = {
    isFirst: PropTypes.bool,
    isLast: PropTypes.bool,
    onPress: PropTypes.func,
    title: PropTypes.string,
    description: PropTypes.string,
    subDescText: PropTypes.string,
    amount: PropTypes.string,
    type: PropTypes.string,
    status: PropTypes.string,
    code: PropTypes.string,
}

function Divider() {
    const [styles] = useTheme(themedStyles)
    return <View style={styles.divider} />
}
function SearchGovInvoices({
    navigation: {
        goBack,
        navigate,

    } }) {
    const [getGovInvoice, { error, data, loading }] = useLazyQuery(
        GET_GOV_INVOICE,
        {
            fetchPolicy: 'network-only',
        }
    )

    const [styles] = useTheme(themedStyles)
    const lang = useLanguage()
    const [nipt, setNipt] = useState('')
    const [invoice, setInvoice] = useState({})
    const [noInvoicesFound, setNoInvoicesFound] = useState(false)
    const [invoiceId, setInvoiceId] = useState('')
    const [date, setDate] = useState({
        isVisible: false,
        date: '',
        tempDate: '',
    })

    const searchInvoice = () => {
        getGovInvoice({
            variables: {
                invoiceNumber: invoiceId,
                invoiceDate: date.date,
                nipt: nipt,
            },
        })
    }

    const [errorModal, setErrorModal] = useState({
        isVisible: false,
        icon: images.warningIcon,
        title: '',
        desc: '',
        descPlaceholders: [],
        primaryText: '',
        primaryAction: null,
        secondaryText: '',
        secondaryAction: null,
    })
    const openModal = (
        title = 'opened_orders_error',
        desc = '',
        descPlaceholders = [],
        primaryText = '',
        primaryAction = null,
        secondaryText = '',
        secondaryAction = null,
        icon = images.warningIcon,
    ) => {
        setErrorModal({
            isVisible: true,
            icon,
            title,
            desc,
            descPlaceholders,
            primaryText,
            primaryAction,
            secondaryText,
            secondaryAction,
        })
    }
    const closeModal = () => {
        setErrorModal({
            isVisible: false,
            icon: images.delete,
            title: '',
            desc: '',
            primaryText: '',
            primaryAction: null,
            secondaryText: '',
            secondaryAction: null,
        })
    }

    useEffect(() => {
        if (data?.getInvoicesFromGovBook?.invoices?.length > 0) {
            setInvoice(data?.getInvoicesFromGovBook?.invoices[0])
            setNoInvoicesFound(false)
        } else if (data?.getInvoicesFromGovBook?.invoices?.length === 0) {
            setNoInvoicesFound(true)
        }
    }, [data])

    useEffect(() => {
        if (error) {
            openModal(
                'taxes_error_label',
                getErrorMessage(error),
                [],
                'printers_button_close',
                () => closeModal(),
                '',
                undefined,
            )
        }
    }, [error])


    const showCalendarModal = () => {
        setDate(prev => ({
            ...prev,
            isVisible: true,
        }))
    }
    const hideCalendarModal = () => {
        setDate(prev => ({
            ...prev,
            isVisible: false,
        }))
    }

    const isDisabled = () => {
        return nipt && invoiceId && date.date ? false : true
    }

    const onInvoicePress = () => {
        navigate('GovInvoiceDetails', { invoice: invoice })
    }

    return (
        <SafeAreaView style={styles.container}>
            <Header title="gov_invoices_header" onPress={goBack} />
            <ScrollView style={styles.wrapper} >
                <Text i18nKey={'gov_invoices_subheader'} style={styles.subHeader} />
                <InputWithTags
                    label="einvoice_details_nipt_label"
                    value={nipt}
                    onChangeText={setNipt}
                    autoCorrect={false}
                    autoCapitalize="none"
                    inputContainerStyle={styles.inputContainer}
                    inputWrapperStyle={styles.itemSpacing}
                    labelOffset={{
                        x0: 0,
                        y0: -7,
                        x1: 0,
                        y1: 2,
                    }}
                />
                <InputWithTags
                    label="receipt_invoice_number"
                    value={invoiceId}
                    onChangeText={setInvoiceId}
                    autoCorrect={false}
                    autoCapitalize="none"
                    inputContainerStyle={styles.inputContainer}
                    inputWrapperStyle={styles.itemSpacing}
                    labelOffset={{
                        x0: 0,
                        y0: -7,
                        x1: 0,
                        y1: 2,
                    }}
                />
                <TouchableOpacity style={styles.touchable} onPress={showCalendarModal}>
                    <View style={styles.calendarInput}>
                        <Icon source={images.calendarBlack} color={colors.gray} style={styles.icon} />
                        <Text i18nKey={date.date ? date.date : "Date"} weight='light' />
                    </View>
                </TouchableOpacity>
                <Button
                    title='einvoice_search_button'
                    variant={loading || isDisabled() ? 'disabled' : 'active'}
                    loader={loading}
                    style={styles.button}
                    onPress={searchInvoice}
                />
                <Divider />
                {noInvoicesFound ? <Text i18nKey="gov_invoices_not_found" size="footnote" /> : null}
                {loading ?
                    <Loader view="full" size={32} />
                    : Object.keys(invoice).length > 0 &&
                    <>
                        <Text i18nKey={moment(invoice?.date).locale(LANGUAGES[lang])
                            .format('MMM DD, YYYY')} style={styles.dateSubHeader} />
                        <TouchableOpacity>
                            <Item title={invoice?.BookID}
                                onPress={onInvoicePress} />
                        </TouchableOpacity>
                    </>
                }
            </ScrollView>
            <DatePickerModal
                preselectedDate={date.date ? date.date : moment().format('YYYY-MM-DD')}
                onClose={hideCalendarModal}
                isVisible={date.isVisible}
                onDateChange={(tempDate) => {
                    setDate(prev => ({
                        ...prev,
                        tempDate: tempDate,
                    }))
                }}
                onButtonPress={() => {
                    setDate(prev => ({
                        ...prev,
                        date: date.tempDate,
                        isVisible: false,
                    }))
                }}
            />
            <ErrorModal
                isVisible={errorModal?.isVisible}
                title={errorModal?.title}
                description={errorModal?.desc}
                placeHolders={errorModal.descPlaceholders}
                primaryText={errorModal?.primaryText}
                secondaryText={errorModal?.secondaryText}
                primaryAction={errorModal?.primaryAction}
                secondaryAction={errorModal?.secondaryAction}
                onBackdropPress={() => closeModal()}
                onBackButtonPress={() => closeModal()}
                onClosePress={() => closeModal()}
            />
        </SafeAreaView>
    )
}

SearchGovInvoices.propTypes = {
    navigation: PropTypes.object,
    style: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.object),
        PropTypes.object,
    ]),
}

export default SearchGovInvoices
