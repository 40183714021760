import { styleSheetFactory } from '../../../../../../Themes/Colors'

const styles = styleSheetFactory(colors => ({
  container: {
    flex: 1,
  },
  content: {
    flexGrow: 1,
    paddingHorizontal: 16,
    paddingVertical: 24,
  },
  separator: {
    height: 1,
    backgroundColor: colors.separator,
  },
  noteSectionContainer: {
    backgroundColor: colors.tertiaryBackground,
    marginVertical: 8,
    borderRadius: 7,
  },
  expandableContainer: {
    paddingVertical: 20,
    paddingHorizontal: 15,
  },
  noteContainer: {
    paddingVertical: 20,
    paddingHorizontal: 15,
    marginVertical: 0,
  },
}))

export default styles
