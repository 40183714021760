import React, { useContext } from 'react'
import { SafeAreaView, View, Image, FlatList } from 'react-native'

import { useTheme } from 'react-native-themed-styles'
import PropTypes from 'prop-types'

import { Icon, Text, Button, Header } from '../../Components'

import Theme from '../../Themes/Theme'

import themedStyles from './WelcomeDemo.Styles'

import images from '../../Themes/Images'

function Item(props) {
  const [styles] = useTheme(themedStyles)
  const { colors } = useContext(Theme)
  const { item, style } = props
  return (
    <View style={[styles.listInfoContainer, style]}>
      <Icon
        source={item.image}
        color={colors.accent}
        size={22}
        style={styles.itemIcon}
      />
      <Text align="center" size={'footnote'} i18nKey={item.description} />
    </View>
  )
}
Item.propTypes = {
  item: PropTypes.object,
  style: PropTypes.object,
}

function WelcomeDemo({ navigation: { goBack, navigate } }) {
  const [styles] = useTheme(themedStyles)
  const { colors } = useContext(Theme)
  const data = [
    {
      id: 1,
      image: images.checkout,
      description: 'demo_explanation_1',
    },
    {
      id: 2,
      image: images.list,
      description: 'demo_explanation_2',
    },
    {
      id: 3,
      image: images.cashDrawer,
      description: 'demo_explanation_3',
    },
    {
      id: 4,
      image: images.transactions,
      description: 'demo_explanation_4',
    },
    {
      id: 5,
      image: images.customers,
      description: 'demo_explanation_5',
    },
    {
      id: 6,
      image: images.govEvents,
      description: 'demo_explanation_6',
    },
    {
      id: 7,
      image: images.categories,
      description: 'demo_explanation_7',
    },
    {
      id: 8,
      image: images.certificate,
      description: 'demo_explanation_8',
    },
  ]

  return (
    <SafeAreaView style={styles.container}>
      <Header title="" image={images.back} onPress={() => goBack()} />

      <FlatList
        showsVerticalScrollIndicator={false}
        numColumns={2}
        keyExtractor={(item, index) => 'row-' + item.id}
        getItemLayout={(dataa, index) => ({
          length: 80,
          offset: 80 * index,
          index,
        })}
        data={data}
        style={styles.listStyle}
        contentContainerStyle={styles.listContainerStyle}
        ListHeaderComponent={
          <>
            <View style={styles.logo}>
              <Image
                source={images.splash}
                style={styles.logoImage}
              />
              {/* <Text
                i18nKey="demo_welcome_label"
                weight="bold"
                align="center"
                size="h3"
              /> */}
            </View>

            <Text
              i18nKey="demo_welcome_description"
              style={styles.listHeader}
            />
          </>
        }
        renderItem={({ item, index }) => {
          return <Item key={'row-' + index} index={index} item={item} />
        }}
        refreshing={false}
      />
      <Button
        title="demo_get_started_button"
        borderColor={colors.transparent}
        backgroundColor={colors.accent}
        style={styles.button}
        onPress={() => navigate('RequestDemo')}
      />
    </SafeAreaView>
  )
}
WelcomeDemo.propTypes = {
  navigation: PropTypes.object,
}

export default WelcomeDemo
