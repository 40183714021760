import { styleSheetFactory } from '../../../../Themes/Colors'

const styles = styleSheetFactory(colors => ({
  container: {
    flex: 1,
    backgroundColor: colors.background,
  },
  fillRemaining: {
    flexGrow: 1,
    paddingHorizontal: 15,
    paddingBottom: 20,
  },
  buttons: {
    flexDirection: 'row',
    marginBottom: 15,
    marginTop: 10,
    marginHorizontal: 15,
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  leftButton: {
    flex: 1,
    height: 50,
  },
  rightButton: {
    flex: 1,
    height: 50,
    marginRight: 15,
  },
  input: {
    marginBottom: 16,
  },
  sectionText: {
    marginBottom: 5,
  },
  leftSpacing: { marginLeft: 10 },
}))

const inputStyles = styleSheetFactory(colors => ({
  touchableInput: {
    marginBottom: 16,
  },
}))

export { inputStyles }

export default styles
