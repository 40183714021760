import React from 'react'
import { TouchableOpacity } from 'react-native'
import { useTheme } from 'react-native-themed-styles'
import PropTypes from 'prop-types'

import InputWithIcon from '../InputWithIcon'

import themedStyles from './TouchableInput.Styles'

function TouchableInput({
  onPress,
  label,
  value,
  setValue,
  icon,
  iconSize,
  error,
  style,
  disabled,
  ...rest
}) {
  const [styles] = useTheme(themedStyles)

  return (
    <TouchableOpacity
      style={[styles.touchableInput, style]}
      onPress={onPress}
      disabled={disabled}
    >
      <InputWithIcon
        label={label}
        value={value}
        error={error}
        onChangeText={setValue}
        icon={icon}
        iconSize={iconSize}
        labelOffset={{
          x0: 0,
          y0: -7,
          x1: 0,
          y1: 2,
        }}
        {...rest}
      />
    </TouchableOpacity>
  )
}
TouchableInput.defaultProps = {
  iconSize: 24,
}
TouchableInput.propTypes = {
  onPress: PropTypes.func,
  label: PropTypes.string,
  value: PropTypes.string,
  setValue: PropTypes.func,
  icon: PropTypes.object,
  iconSize: PropTypes.number,
  style: PropTypes.object,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
}

export default TouchableInput
