import { styleSheetFactory } from '../../../../../Themes/Colors'

const styles = styleSheetFactory(colors => ({
  buttons: {
    flexDirection: 'row',
    margin: 15,
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  leftButton: {
    marginRight: 15,
  },
  rightButton: {
    flex: 2,
    height: 50,
  },
}))

export default styles
