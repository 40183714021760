import React, { useContext, useState } from 'react'
import { SafeAreaView, View, TouchableOpacity, FlatList } from 'react-native'
import PropTypes from 'prop-types'
import { useTheme } from 'react-native-themed-styles'

import { v1 as uuidv1 } from 'uuid'

import { Text, Header } from '../../Components'

import images from '../../Themes/Images'

import Theme from '../../Themes/Theme'

import themedStyles from './NewTax.Styles'

function Item(props) {
    const [styles] = useTheme(themedStyles)
    const { colors } = useContext(Theme)

    const {
        item,
        onPress,
        stylee,
    } = props

    return (
        <TouchableOpacity onPress={onPress} style={[{ backgroundColor: colors.tertiaryBackground }, stylee]}>
            <View style={styles.itemStyle}>
                <View style={styles.listDescContainer}>
                    <Text i18nKey={`${item.taxName} ${item.taxDescription}%`} />
                </View>
            </View>
        </TouchableOpacity>
    )
}
Item.propTypes = {
    item: PropTypes.object,
    onPress: PropTypes.func,
    stylee: PropTypes.object,
}

function NewTax(props) {
    const { navigation } = props

    const [styles] = useTheme(themedStyles)

    const [items] = useState([
        {
            id: uuidv1(),
            taxName: 'VAT',
            taxDescription: '20',
        },
        {
            id: uuidv1(),
            taxName: 'VAT',
            taxDescription: '6',
        },
        {
            id: uuidv1(),
            taxName: 'VAT',
            taxDescription: '0',
        },
    ])

    return (
        <SafeAreaView style={styles.container} >
            <Header
                title="header_new_tax"
                image={images.back}
                onPress={() => navigation.goBack()}
            />
            <FlatList
                showsVerticalScrollIndicator={false}
                keyExtractor={(item, index) => 'row-' + index}
                getItemLayout={(dataa, index) => ({
                    length: 60,
                    offset: 61 * index,
                    index,
                })}
                data={items}
                style={styles.listStyle}
                contentContainerStyle={styles.listContainerStyle}
                ListHeaderComponent={(<>
                    <Text
                        i18nKey="taxes_select_label"
                        weight="light"
                        size="footnote"
                        align="center"
                        style={styles.bottomSpacing}
                    />
                </>)}
                renderItem={({ item, index }) => {
                    const style = {}
                    if (index === 0) {
                        style.borderTopLeftRadius = 7
                        style.borderTopRightRadius = 7
                    }
                    if (index === items.length - 1) {
                        style.borderBottomLeftRadius = 7
                        style.borderBottomRightRadius = 7
                    }
                    return <Item
                        key={'row-' + index}
                        index={index}
                        item={item}
                        stylee={style}
                        onPress={() => {
                            navigation.navigate('ProductVAT', { name: item.taxName, percentage: item.taxDescription })
                        }}
                    />
                }}
                ItemSeparatorComponent={() => <View style={styles.separatorStyle} />}
            // onEndReached={onEndReach}
            // onRefresh={refetchData}
            // refreshing={false}
            />
        </SafeAreaView>
    )
}
NewTax.propTypes = {
    navigation: PropTypes.object,
}

export default NewTax
