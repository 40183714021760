import { gql } from '@apollo/client'

export const LIST_BANK_ACCOUNTS = gql`
query listBankAccounts($limit: Int, $nextToken: String, $filter: Filter) {
    listBankAccounts(listBankAccountsInput: {
    limit: $limit,
    nextToken: $nextToken,
    filter: $filter,
  }) {
    bankAccounts {
        bankName
        primaryBankIdentificationNumber
        iban
        id
        swiftCode
        holderName
        currency
      }
    nextToken
  },
}
`

export const DELETE_BANK_ACCOUNT = gql`
mutation deleteBankAccount($id: String!) {
    deleteBankAccount(
    id: $id,
  ) {
        id
  },
}
`

export const CREATE_BANK_ACCOUNT = gql`
mutation createBankAccount($createBankAccountInput: createBankAccountInput!) {
    createBankAccount(
    bankName: $bankName,
    holderName: $holderName,
    country: $country,
    iban: $iban,
    city: $city,
    swiftCode: $swiftCode,
  ) {
    bankAccount {
        id
      }
  },
}
`


