import React from 'react'
import { View, TouchableOpacity, TouchableWithoutFeedback } from 'react-native'
import { useTheme } from 'react-native-themed-styles'
import PropTypes from 'prop-types'

import Text from '../Text'

import themedStyles from './Type.Styles'

function Type({ item: { code, disabled, description, descPlaceHolders } = {}, selected, onPress }) {
  const [styles] = useTheme(themedStyles)
  const isSelected = selected?.code === code
  return (
    <TouchableWithoutFeedback>
      <View style={styles.container}>
        <TouchableOpacity
          onPress={onPress}
          style={[
            styles['itemSelected_' + isSelected],
            styles['isDisabled_' + disabled],
          ]}
          disabled={disabled}
          activeOpacity={0.6}
        >
          <Text i18nKey={code} align="left" style={styles.itemCode} />
          {Boolean(description) && <>
            <View style={styles.separator} />
            <Text
              i18nKey={description}
              placeHolders={descPlaceHolders}
              size="footnote"
              numberOfLines={2}
              style={styles['itemTextSelected_' + isSelected]}
            />
          </>}
        </TouchableOpacity>
      </View>
    </TouchableWithoutFeedback>
  )
}
Type.propTypes = {
  item: PropTypes.shape({
    code: PropTypes.string,
    disabled: PropTypes.bool,
    description: PropTypes.string,
    descPlaceHolders: PropTypes.array,
  }),
  selected: PropTypes.object,
  onPress: PropTypes.func,
}

export default Type
