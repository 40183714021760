import { Platform } from 'react-native'

import { styleSheetFactory } from '../../Themes/Colors'

const styles = styleSheetFactory(colors => ({
    container: {
        flex: 1,
    },
    screenWrapper: {
        flex: 1,
    },
    tabView: {
        paddingTop: 75,
    },
    scene: {
        // backgroundColor: 'pink',
        flex: 1,
    },
    searchContainer: {
        flexDirection: 'row',
        position: 'absolute',
        top: 35,
        left: 0,
        right: 0,
        marginTop: 15,
        marginBottom: 0,
    },
    calendar: {
        flexGrow: 1,
    },
    tabContainer: {
        width: 'auto',
    },
}))

const routeStyles = styleSheetFactory(colors => ({
    typesStyle: {
        marginVertical: 15,
        flexWrap: 'nowrap',
    },
    searchContainer: {
        marginTop: 80,
    },
    emptyComponent: {
        alignSelf: 'center',
        flexGrow: 1,
        alignItems: 'center',
        marginTop: 15,
    },
}))

const itemStyles = styleSheetFactory((colors) => ({
    itemStyle: {
        backgroundColor: colors.tertiaryBackground,
        height: 70,
        justifyContent: 'center',
        borderTopWidth: 1,
        borderTopColor: colors.separator,
    },
    firstItem_true: {
        borderTopLeftRadius: 7,
        borderTopRightRadius: 7,
        borderTopWidth: 0,
    },
    section: {
        marginBottom: 10,
        marginTop: 15,
    },
    lastItem_true: {
        borderBottomLeftRadius: 7,
        borderBottomRightRadius: 7,
    },
    listDescContainer: {
        flexGrow: 1,
        flexShrink: 1,
        marginLeft: 10,
        justifyContent: 'center',
    },
    amountContainer: {
        alignItems: 'center',
        justifyContent: 'center',
        // padding: 15,
        paddingHorizontal: 5,
        flexDirection: 'row',
    },
    rightSectionItem: {
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'row',
    },
    rightSectionItemContainer: {
        alignItems: 'flex-end',
    },
    itemText: {
        marginRight: 5,
    },
    container: { flexDirection: 'row', alignItems: 'center' },
    subDescription: { fontSize: Platform.OS === 'web' ? 14 : 12, lineHeight: Platform.OS === 'web' ? 16 : 14 },
    description: (type) => {
        return {
            fontSize: type ? 12 : 14,
            lineHeight: type ? 14 : 16,
            marginRight: 4,
        }
    },
    errorContainer: (search = true) => {
        return {
            backgroundColor: search ? colors.tertiaryBackground : colors.background,
            marginTop: 15,
            borderRadius: 7,
        }
    },
    errorSubcontainer: {
        padding: 15,
        justifyContent: 'center',
        alignItems: 'center',
    },
    icon: {
        marginLeft: 10,
    },
    itemMidSection: {
        flexDirection: 'row',
        alignItems: 'center',
    },
}))

const hiddenItemStyles = styleSheetFactory((colors) => ({
    firstHiddenItem_true: {
        borderTopLeftRadius: 8,
        borderTopRightRadius: 8,
        borderTopWidth: 0,
    },
    lastHiddenItem_true: {
        borderBottomLeftRadius: 8,
        borderBottomRightRadius: 8,
    },
    hiddenItemStyle: {
        height: 70,
        backgroundColor: colors.secondaryAccent,
        flex: 1,
        justifyContent: 'flex-end',
        // alignItems: 'flex-end',
        flexDirection: 'row',
    },
    hiddenSubcontainer: {
        width: 80,
        height: 70,
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'row',
        // backgroundColor: 'pink'
    },
    divider: {
        height: 50,
        alignSelf: 'center',
        width: 1,
        backgroundColor: 'rgba(255, 255, 255, 0.4)',
        // position: 'absolute',
    },
}))

export default styles
export { itemStyles, routeStyles, hiddenItemStyles }
