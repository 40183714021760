
import { styleSheetFactory } from '../../Themes/Colors'

const styles = styleSheetFactory(colors => ({
  container: {
    flex:1,
    justifyContent:'center',
    alignItems: 'center',
  },




}))

export default styles
