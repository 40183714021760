import React, { useContext } from 'react'
import { View } from 'react-native'
import PropTypes from 'prop-types'
import { useTheme } from 'react-native-themed-styles'
import { useSelector } from 'react-redux'

import { Text } from '../../../../../Components'
import { INF } from '../../../../../Utils/Helpers'
import Theme from '../../../../../Themes/Theme'

import { getCurrency, getTotal } from '../../../../Checkout/Checkout.Selectors'
import { getPaymentCashMoney } from '../../../Payment.Selectors'

import themedStyles from './TotalAndChange.Styles'

function TotalAndChange({
  secondaryCurrency,
  rate,
}) {
  const [styles] = useTheme(themedStyles)
  const { colors } = useContext(Theme)

  const { type: currency } = useSelector(getCurrency)
  const total = useSelector(getTotal)
  const cash = useSelector(getPaymentCashMoney)
  //@TODO review
  // const changeAmount = useSelector(getPaymentChangeMoney)

  const change = (currency === 'Lek' ? Math.max((cash * rate) - total, 0) : Math.max(cash - total, 0))

  return (
    <View style={styles.wrapper}>
      <View style={styles.container}>
        <Text
          i18nKey={'receipt_total'}
          color={colors.secondaryText}
          style={styles.label}
        />
        <View style={styles.separator} />
        <View style={styles.amount}>
          <Text
            i18nKey="checkout_price_currency_value"
            placeHolders={[INF.format(total), currency]}
            size="h3"
            weight="bold"
            style={styles.currency}
          />
          {(Boolean(rate) && currency !== secondaryCurrency) && <Text
            i18nKey="checkout_price_currency_value"
            placeHolders={currency === 'Lek' ? [INF.format(total / rate), secondaryCurrency] : [INF.format(total * rate), currency]}
            color={colors.secondaryText}
            style={styles.secondaryCurrency}
          />}
        </View>
      </View>
      <View style={styles.container}>
        <Text
          i18nKey={'checkout_change_label'}
          color={colors.secondaryText}
          style={styles.label}
        />
        <View style={styles.separator} />
        <View style={styles.amount}>
          <Text
            i18nKey="checkout_price_currency_value"
            placeHolders={[INF.format(change), currency]}
            size="h3"
            weight="bold"
            style={styles.currency}
          />
          {(Boolean(rate) && currency !== secondaryCurrency) && <Text
            i18nKey="checkout_price_currency_value"
            placeHolders={[INF.format(change / rate), secondaryCurrency]}
            color={colors.secondaryText}
            style={styles.secondaryCurrency}
          />}
        </View>
      </View>
    </View>
  )
}
TotalAndChange.propTypes = {
  secondaryCurrency: PropTypes.string,
  rate: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}

export default TotalAndChange
