import { styleSheetFactory } from '../../Themes/Colors'


const styles = styleSheetFactory(colors => ({
    codeFieldRoot: {
        marginTop: 20,
        marginBottom: 30,
        maxWidth: 400,
        alignSelf: 'center',
    },
    cell: {
        borderRadius: 7,
        width: 40,
        height: 40,
        lineHeight: 35,
        fontSize: 24,
        color: colors.primaryText,
        borderWidth: 1,
        borderColor: '#00000030',
        textAlign: 'center',
        marginHorizontal: 15,
    },
    focusCell: {
        borderColor: '#007C92',
    },
    codeConfirm: {
        borderRadius: 7,
        marginTop: 30,
        backgroundColor: colors.white,
        paddingHorizontal: 40,
        paddingVertical: 20,
        justifyContent: 'center',
    },
}))

export default styles
