import { styleSheetFactory } from '../../Themes/Colors'

const styles = styleSheetFactory(colors => ({
  modalContent: {
    backgroundColor: colors.white,
    paddingVertical: 30,
    borderRadius: 6,
    justifyContent: 'center',
    alignItems: 'center',
    paddingHorizontal: 15,
  },
  closeContainer: {
    position: 'absolute',
    top: 0,
    right: 0,
    height: 30,
    width: 75,
    borderTopRightRadius: 7,
    borderBottomLeftRadius: 7,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: colors.separator,
  },
  modalTitle: {
    marginVertical: 10,
  },
  modalDesc: {
    marginBottom: 15,
  },
  primaryButton: {
    width: '100%',
    marginVertical: 15,
  },
  secondaryButton: {
    width: '100%',
  },
  tertiaryButton: {
    width: '100%',
    marginBottom: 15,
  },
  modal: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  bottomSpacing: { marginBottom: 15 },
}))

export default styles

