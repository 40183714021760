/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'

import { Header, ErrorModal } from '../../../Components'
import { supportInvoiceResetState, updatePaymentAttribute } from '../Payment.Actions'
import { filterOutCart } from '../Helpers'
import images from '../../../Themes/Images'
import { resetOrder } from '../../Checkout/Checkout.Actions'
import { PAYMENT_STEPS } from '../Payment.Config'

function Step({
  title,
  goBack,
  stepperLength,
  index,
  addListener,
  dispatch,
  children,
}) {

  const [isVisible, setVisible] = useState(false)
  const [backAction, setBackAction] = useState({
    goBack: () => goBack(),
  })

  const dispatchAction = useDispatch()

  const clearOrder = () => dispatchAction(resetOrder())
  const setPaymentStatus = (value) => dispatchAction(updatePaymentAttribute('paymentStatus', value))

  useEffect(() => {
    const saveInvoice = addListener('beforeRemove', (e) => {
      e.preventDefault()
      setBackAction({
        goBack: () => dispatch(e.data.action),
      })
      setVisible(true)
    })
    return saveInvoice
  }, [])

  return (
    <>
      <Header
        title={title}
        titlePlaceHolders={[index, stepperLength]}
        image={images.close}
        onPress={goBack}
      />
      {children}
      <ErrorModal
        isVisible={isVisible}
        onClosePress={() => setVisible(false)}
        description="supportive_invoice_exit_modal_description"
        primaryText="einvoice_delete_modal_button"
        tertiaryText="einvoice_cancel_modal_button"
        primaryAction={() => {
          clearOrder()
          setVisible(false)
          dispatchAction(supportInvoiceResetState())
          dispatch(filterOutCart)
          backAction.goBack()
          setPaymentStatus(PAYMENT_STEPS.init)
        }}
        tertiaryAction={() => setVisible(false)}
      />
    </>
  )
}

Step.propTypes = {
  title: PropTypes.string,
  goBack: PropTypes.func,
  stepperLength: PropTypes.number,
  index: PropTypes.number,
  addListener: PropTypes.func,
  dispatch: PropTypes.func,
  children: PropTypes.node,
}

export default Step

