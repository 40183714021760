import { styleSheetFactory } from '../../Themes/Colors'

const styles = styleSheetFactory(colors => ({
  container: {
    flex: 1,
    backgroundColor: colors.background,
  },
  fillRemaining: {
    paddingBottom: 20,
    paddingHorizontal: 15,
    marginTop: 15,
  },
  buttons: {
    flexDirection: 'row',
    marginBottom: 15,
    marginTop: 10,
    marginHorizontal: 15,
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  leftButton: {
    flex: 1,
    height: 50,
  },
  rightButton: {
    flex: 1,
    height: 50,
    marginRight: 15,
  },
  topContent: {
    paddingHorizontal: 15,
  },
  sectionTitle: {
    marginBottom: 10,
  },
  questionButtonsContainer: {
    flexDirection: 'row',
    flexGrow: 1,
    justifyContent: 'center',
    marginBottom: 20,
  },
  questionButton: {
    paddingHorizontal: 75,
    marginHorizontal: 10,
  },
  sectionNote: {
    marginTop: 5,
    marginBottom: 16,
  },
  currency: {
    marginBottom: 16,
  },
  rightButtonIcon: {
    marginLeft: 10,
  },
  endDateInput: {
    marginBottom: 0,
  },
  buttonTitle: {
    flexShrink: 1,
  },
  vehiclePlateHelper: {
    marginBottom: 20,
    marginTop: 5,
    textAlign: 'left',
    fontFamily: 'Vodafone Rg',
    fontSize: 12,
    color: colors.secondaryText,
  },
  vehiclePlateHelperItalic: {
    fontStyle: 'italic',
  },
  infoHeader: {
    marginTop: 10,
    marginBottom: 5,
  },
  buttonContainer: {
    // alignItems: 'center',
    paddingHorizontal: 15,
    paddingBottom: 15,

  },
  swiftList: {
    marginBottom: 0,
  },
  creditScoreHelper: {
    marginBottom: 20,
  },
}))

const itemStyles = styleSheetFactory(colors => ({
  container: {
    paddingVertical: 7.5,
  },
  itemSelected_false: {
    flexDirection: 'row',
    marginHorizontal: 15,
    borderRadius: 10,
    paddingVertical: 10,
    paddingHorizontal: 15,
    backgroundColor: colors.white,
    alignItems: 'center',
    height: 60,
  },
  itemSelected_true: {
    flexDirection: 'row',
    marginHorizontal: 15,
    borderRadius: 10,
    paddingVertical: 10,
    paddingHorizontal: 15,
    backgroundColor: colors.white,
    alignItems: 'center',
    height: 60,
    borderWidth: 1,
    borderColor: colors.secondaryAccent,
  },
  itemTextSelected_false: {
    flexGrow: 1,
    flexShrink: 1,
  },
  itemTextSelected_true: {
    flexGrow: 1,
    flexShrink: 1,
  },
  separator: {
    width: 1,
    backgroundColor: colors.disabled,
    marginHorizontal: 15,
    height: 40,
  },
  isDisabled_true: {
    backgroundColor: colors.secondaryBackround,
  },
  itemCode: {
    minWidth: 30,
    maxWidth: '40%',
  },


}))

export { itemStyles }
export default styles
