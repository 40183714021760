import { styleSheetFactory } from '../../Themes/Colors'

const styles = styleSheetFactory(colors => ({
  header: {
    margin: 15,
    marginTop: 30,
    flexDirection: 'row',
    justifyContent: 'space-between',

  },
  searchContainer: {
    flexDirection: 'row',
    marginHorizontal: 15,
    marginBottom: 15,
    backgroundColor: colors.white,
    borderRadius: 10,
    justifyContent: 'space-between',
    alignItems: 'center',
    flexGrow: 1,
  },
  searchIcon: {
    marginHorizontal: 15,
    marginVertical: 10,
  },
  searchInput: {
    flexGrow: 1,
    borderRadius: 10,
    paddingVertical: 10,
    marginRight: 15,
    color: colors.primaryText,
  },
  clearSearchContainer: {
    borderRadius: 18,
    padding: 5,
    marginRight: 15,
    backgroundColor: colors.background,
  },
  listStyle: {
    height: 300,
    marginBottom: 24,
  },
  listContainerStyle: {
    flexGrow: 1,
    paddingBottom: 20,
  },
}))

export default styles
