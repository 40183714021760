/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useCallback, useEffect } from 'react'
import {
  View,
  SafeAreaView,
  TouchableOpacity,
  FlatList,
  RefreshControl,
  Dimensions,
} from 'react-native'
import PropTypes from 'prop-types'
import { useFocusEffect } from '@react-navigation/native'
import { useTheme } from 'react-native-themed-styles'
import { useLazyQuery } from '@apollo/client'
import ContentLoader, { Rect } from 'react-content-loader/native'

import { Text, Button, CheckBox, Header, Loader } from '../../Components'

import { LIST_CASH_REGISTERS } from '../CashRegister/CashRegister.Schema'

import { useConfig } from '../../Utils'

import images from '../../Themes/Images'
import themedStyles, { itemStyles } from './SelectCashRegister.Styles'

import { colors, animations } from '../../Themes'

const { width } = Dimensions.get('screen')

function CashRegister({ item, checked, onPress, isFirst, isLast }) {
  const [styles] = useTheme(itemStyles)

  return (
    <TouchableOpacity
      style={[
        styles.itemContainer,
        styles['fistItem_' + isFirst],
        styles['lastItem_' + isLast],
      ]}
      onPress={onPress}
      disabled={!onPress}>
      <View style={styles.subContainer}>
        <View style={styles.textContainer}>
          <Text
            i18nKey={item.name}
            numberOfLines={1}
          />
          <Text
            i18nKey={item.deviceName}
            weight="light"
            color={colors.secondaryText}
            size="footnote"
            numberOfLines={1}
          />
        </View>
      </View>
      <View pointerEvents="none">
        <CheckBox
          tintColors={styles.checkBoxTintColors}
          checked={checked}
          onValueChange={onPress}
          style={styles.checkBox}
          color={colors.secondaryAccent}
        />
      </View>
    </TouchableOpacity>
  )
}
CashRegister.propTypes = {
  item: PropTypes.object,
  checked: PropTypes.bool,
  onPress: PropTypes.func,
  isFirst: PropTypes.bool,
  isLast: PropTypes.bool,
}

function CashRegisterPlaceholder({ isFirst, isLast }) {
  const [styles] = useTheme(itemStyles)

  return (
    <TouchableOpacity
      style={[
        styles.itemContainer,
        styles['fistItem_' + isFirst],
        styles['lastItem_' + isLast],
      ]}
      disabled>
      <ContentLoader
        speed={2}
        width={width - 30}
        height={60}
        backgroundColor="#f3f3f3"
        foregroundColor="#fff">
        <Rect
          x="15"
          y="10"
          rx="6"
          ry="6"
          width={`${(width - 30) * (3 / 5)}`}
          height="18"
        />
        <Rect x="15" y="32" rx="6" ry="6" width="60" height="18" />
        <Rect x={`${width - 63}`} y="21" rx="3" ry="3" width="18" height="18" />
      </ContentLoader>
    </TouchableOpacity>
  )
}
CashRegisterPlaceholder.propTypes = {
  isFirst: PropTypes.bool,
  isLast: PropTypes.bool,
}

function SelectCashRegister({
  navigation: { navigate, goBack },
  route: {
    params: {
      locationId,
      onSave = () => { },
      preSelectedCashRegisters = [],
      onCashRegisterDelete = () => { },
    } = {},
  },
}) {

  const { switchLocation: { deviceId, locationId:locId } } = useConfig()

  const [styles] = useTheme(themedStyles)

  const [keysToDelete] = useState([])
  const [extraData] = useState([])

  const [
    listCashRegisters,
    { loading, data, refetch },
  ] = useLazyQuery(LIST_CASH_REGISTERS, {
    fetchPolicy: 'network-only',
    variables: {
      locId: locId,
      cashRegisterId: deviceId,
      limit: 20,
      filter: {
        name: 'location',
        value: locationId,
      },
    },
  })

  useEffect(() => {
    preSelectedCashRegisters.map((item) => changeState(item.id, item))
  }, [])

  useFocusEffect(
    useCallback(() => {
      listCashRegisters()
      return
    }, []),
  )

  const [selectedCashRegisters, setSelectedCashRegisters] = useState({})

  const changeState = (id, cashRegister) => {
    setSelectedCashRegisters((prev) => {
      if (prev[id]) {
        delete prev[id]
      } else {
        prev[id] = cashRegister
      }
      return { ...prev }
    })
  }
  const cashRegisters = data?.listCashRegisters?.cashRegisters || []

  //   useEffect(() => {
  //     const result = preSelectedCashRegisters.map((a) => a.id)
  //     const intersection = result?.filter((x) => !keys.includes(x))
  //     setKeysToDelete(intersection)
  //     setExtraData(keys?.filter((e) => !result.includes(e)))
  //   }, [selectedCashRegisters])

  const goBackk = () => {
    if (extraData.length > 0 && keysToDelete.length === 0) {
      onSave(extraData)
    } else if (extraData.length > 0 && keysToDelete.length > 0) {
      onSave(extraData)
      onCashRegisterDelete(keysToDelete)
    } else if (extraData.length === 0 && keysToDelete.length > 0) {
      onCashRegisterDelete(keysToDelete)
    }
    goBack()
  }

  return (
    <SafeAreaView style={styles.container}>
      <Header
        title="header_choose_cash_register"
        image={images.back}
        onPress={goBack}
      />

      <FlatList
        showsVerticalScrollIndicator={false}
        keyExtractor={(item) => item.id}
        getItemLayout={(_, index) => ({
          length: 60,
          offset: 60 * index,
          index,
        })}
        data={cashRegisters}
        style={styles.listStyle}
        contentContainerStyle={styles.listContainerStyle}
        renderItem={({ item, index }) => {
          let isFirst = false,
            isLast = false
          if (index === 0) {
            isFirst = true
          }
          if (index === cashRegisters.length - 1) {
            isLast = true
          }

          const cashRegister = {
            id: item.id,
            name: item.name,
            deviceName: item.deviceName,
          }

          return (
            <CashRegister
              key={item.id}
              id={item.id}
              item={item}
              checked={Boolean(selectedCashRegisters[item.id])}
              onPress={() => changeState(item.id, cashRegister)}
              isFirst={isFirst}
              isLast={isLast}
            />
          )
        }}
        ItemSeparatorComponent={() => <View style={styles.separator} />}
        refreshControl={
          <RefreshControl refreshing={false} onRefresh={refetch} />
        }
        ListFooterComponent={
          loading && (
            <>
              <CashRegisterPlaceholder isFirst />
              <CashRegisterPlaceholder isLast />
            </>
          )
        }
      />

      <Button
        title="discount_button_apply"
        variant="active"
        style={styles.chargeButton}
        onPress={goBackk}
        loaderComponent={<Loader size={32} source={animations.vfLoaderRed} />}
      />
    </SafeAreaView>
  )
}
SelectCashRegister.propTypes = {
  navigation: PropTypes.object,
  route: PropTypes.object,
}

export default SelectCashRegister
