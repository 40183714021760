import { StyleSheet, Platform } from 'react-native'

export default StyleSheet.create({
  container: {
    position: 'absolute',
    top: 0,
    left: '-100%',
    width: '200%',
    paddingLeft: Platform.OS === 'web' ? '100%' : '50%',
  },

  text: {
    textAlign: 'left',
    includeFontPadding: false,
    textAlignVertical: 'top',
  },
})
