import Color from 'color'

import { styleSheetFactory } from '../../Themes/Colors'

const styles = styleSheetFactory(colors => ({
  container: {
    flex: 1,
  },
  iconHeader: {
    alignItems: 'center',
  },
  loaderContainer: {
    backgroundColor: colors.white,
    width: 110,
    height: 110,
    overflow: 'hidden',
    borderRadius: 7,
    justifyContent: 'center',
    alignItems: 'center',
  },
  //Tabs
  tabView: {
    backgroundColor: colors.background,
    paddingTop: 0,
  },
  searchContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: colors.tertiaryBackground,
    height: 50,
    borderRadius: 7,
    margin: 15,
    marginTop: 5,
  },
  iconContainer: {
    padding: 10,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: colors.backgroundRGBA(0.5),
    borderRadius: 20,
    marginRight: 10,
    width: 30,
    height: 30,
  },
  icon: {
    width: 15,
    height: 15,
    margin: 15,
    resizeMode: 'contain',
    tintColor: colors.secondaryIcon,
  },
  input: {
    flexGrow: 1,
    fontSize: 16,
    fontFamily: 'Vodafone Lt',
    color: colors.primaryText,
  },
  button: {
    marginTop: 5,
    marginBottom: 15,
    marginHorizontal: 15,
    height: 50,
  },
  buttonTitle: {
    fontSize: 18,
  },
  plusIcon: {
    tintColor: colors.white,
    height: 22,
    width: 22,
  },
  horizontalSpacing: { marginHorizontal: 4 },
}))

const routeStyles = styleSheetFactory(colors => ({
  emptyComponent: {
    alignSelf: 'center',
    flexGrow: 1,
    alignItems: 'center',
    marginTop: 30,
  },
  container: {
    flex: 1,
  },
  iconHeader: {
    alignItems: 'center',
  },
  loaderContainer: {
    backgroundColor: colors.white,
    width: 110,
    height: 110,
    overflow: 'hidden',
    borderRadius: 7,
    justifyContent: 'center',
    alignItems: 'center',
  },

  //Tabs
  tabView: {
    backgroundColor: colors.background,
  },
  scene: {
    flex: 1,
    backgroundColor: colors.background,
  },
  label: {
    fontFamily: 'Vodafone Rg',
    textTransform: 'none',
  },
  indicatorStyle: {
    backgroundColor: colors.tabAccent,
  },
  tabContainerStyle: {
    backgroundColor: colors.tertiaryBackground,
    elevation: 0,
    shadowRadius: 0,
    shadowOpacity: 0,
    marginBottom: 0,
  },
  searchContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: colors.tertiaryBackground,
    height: 50,
    borderRadius: 7,
    margin: 15,
    marginTop: 5,
  },
  iconContainer: {
    padding: 10,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: colors.backgroundRGBA(0.5),
    borderRadius: 20,
    marginRight: 10,
    width: 30,
    height: 30,
  },
  icon: {
    width: 15,
    height: 15,
    margin: 15,
    resizeMode: 'contain',
    tintColor: colors.secondaryIcon,
  },
  input: {
    flexGrow: 1,
    fontSize: 16,
    fontFamily: 'Vodafone Lt',
    color: colors.primaryText,
  },
  button: {
    marginTop: 5,
    marginBottom: 15,
    marginHorizontal: 15,
    height: 50,
  },
  buttonTitle: {
    fontSize: 18,
  },
  plusIcon: {
    tintColor: colors.white,
    height: 22,
    width: 22,
  },
  topSpacing: { marginTop: 15 },
  bottomSpacing: { marginBottom: 15 },
}))

const itemStyles = styleSheetFactory(colors => ({
  container: {
    height: 70,
    borderRadius: 7,
    marginVertical: 3,
    backgroundColor: colors.tertiaryBackground,
    justifyContent: 'center',
    overflow: 'hidden',
  },
  firstItem_true: {
    borderTopLeftRadius: 7,
    borderTopRightRadius: 7,
  },
  lastItem_true: {
    borderBottomLeftRadius: 7,
    borderBottomRightRadius: 7,
  },
  touchable: {
    flexGrow: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  abreviation: labelColor => {
    return {
      width: 70,
      height: 70,
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: Color(labelColor).fade(0.65).toString(),
    }
  },
  textContainer: {
    marginRight: 15,
    flexGrow: 1,
    flexShrink: 1,
    flexDirection: 'row',
    alignItems: 'center',
  },
  name: {
    marginLeft: 15,
  },
  rightIcon: {
    width: 24,
    height: 24,
    marginRight: 15,
    tintColor: colors.accent,
    flexShrink: 0,
  },
  checkBox: {
    flexShrink: 0,
    marginRight: 8,
  },
}))


const hiddenItemStyles = styleSheetFactory(colors => ({
  wrapper: {
    backgroundColor: colors.accent,
    height: 70,
    borderRadius: 7.5,
    marginVertical: 3,
    justifyContent: 'center',
    alignItems: 'flex-end',
  },
  option: {
    width: 100,
    height: 70,
    justifyContent: 'center',
    alignItems: 'center',
  },
  optionFull: {
    flexDirection: 'row',
  },
  confirm: {
    flexGrow: 1,
    height: 70,
    flexDirection: 'row',
    paddingLeft: 15,
    alignItems: 'center',
  },
  confirmText: {
    marginLeft: 15,
  },
  cancel: {
    width: 40,
    height: 40,
    flexShrink: 0,
    margin: 15,
    borderRadius: 20,
    backgroundColor: colors.whiteRGBA(0.55),
    justifyContent: 'center',
    alignItems: 'center',
  },
  loader: {
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
}))


export { routeStyles, itemStyles, hiddenItemStyles }

export default styles
