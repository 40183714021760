import { styleSheetFactory } from '../../../../Themes/Colors'

const styles = styleSheetFactory(colors => ({
  container: {
    flexGrow: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  lottieView: {
    width: 150,
  },
  button: {
    height: 50,
    marginBottom: 30,
    marginHorizontal: 20,
  },

  shadow: {
    marginTop: 40,
    alignItems: 'center',
    shadowColor: colors.black,
    shadowOffset: {
      width: 0,
      height: -1,
    },
    shadowOpacity: 0.16,
    shadowRadius: 6,
    marginHorizontal: 12,
    flexGrow: 1,
  },
  imageContainer: {
    alignItems: 'center',
    width: '100%',
    marginHorizontal: 10,
    padding: 50,
    backgroundColor: colors.white,
    borderRadius: 6,
  },
  printAnimation: {
    alignSelf: 'center',
    height: 125,
    width: 125,
  },
  contentItem: {
    flexDirection: 'row',
    alignItems: 'center',
    marginHorizontal: 35,
    marginBottom: 5,
  },
  lottieText: {
    marginTop: 50,
  },
}))

export default styles
