import React from 'react'
import {
  Image,
  Animated,
} from 'react-native'
import PropTypes from 'prop-types'

import images from '../../Themes/Images'
import colors from '../../Themes/Colors'

function Icon(props) {
  const {
    fadeDuration,
    resizeMode,
    source,
    size,
    color,
    backgroundColor,
    animated,
    style,
    ...rest
  } = props

  const propStyle = Object.assign({}, {
    width: size,
    height: size,
    tintColor: color ? color : undefined,
    backgroundColor: backgroundColor,
    overlayColor: backgroundColor,
  }, style)

  const imageProps = {
    source: source,
    fadeDuration: fadeDuration,
    resizeMode: resizeMode,
    style: propStyle,
  }

  if (animated) {
    return <Animated.Image {...imageProps} {...rest} />
  }

  return <Image {...imageProps} {...rest} />
}

Icon.defaultProps = {
  fadeDuration: 200,
  resizeMode: 'contain',
  source: images.defaultIcon,
  size: 30,
  backgroundColor: colors.transparent,
  animated: false,
}

Icon.propTypes = {
  fadeDuration: PropTypes.number,
  resizeMode: PropTypes.string,
  source: PropTypes.any,
  size: PropTypes.number,
  color: PropTypes.string,
  backgroundColor: PropTypes.string,
  animated: PropTypes.bool,
  style: PropTypes.object,
}

export default Icon
