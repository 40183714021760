import React, { useContext, useRef } from 'react'
import { View, Animated, Easing, TouchableOpacity } from 'react-native'
import { SwipeListView } from 'react-native-swipe-list-view'
import PropTypes from 'prop-types'

import { useTheme } from 'react-native-themed-styles'


import {
  Icon,
} from '../../../Components'

import images from '../../../Themes/Images'
import Theme from '../../../Themes/Theme'

import themedStyles from './SwipeList.Styles'

const AnimatedTouchable = Animated.createAnimatedComponent(TouchableOpacity)

function SwipeList(props) {
  const [styles] = useTheme(themedStyles)
  const { colors } = useContext(Theme)
  const ITEM_HEIGHT = 60
  const {
    measureView,
    containerStyle,
    style,
    contentContainerStyle,
    ...rest
  } = props

  const listRef = useRef(null)
  const fabSize = useRef(new Animated.Value(0)).current
  const animate = value => {
    Animated.timing(fabSize, {
      toValue: value,
      duration: 370,
      useNativeDriver: true,
      easing: Easing.inOut(Easing.cubic),
    }).start()
  }
  const scrollToTop = () => {
    animate(0)
    listRef.current.scrollToOffset({
      offset: 0,
    })
  }

  return (
    <View style={[styles.container, containerStyle]}>
      <SwipeListView
        useFlatList
        listViewRef={ref => { listRef.current = ref }}
        onLayout={measureView}
        onMomentumScrollEnd={(event) => {
          if (event.nativeEvent.contentOffset.y > 800) {
            animate(1)
          } else {
            animate(0)
          }
        }}
        style={[styles.listStyle, style]}
        contentContainerStyle={[styles.contentContainer, contentContainerStyle]}
        keyExtractor={(item, index) => 'row-' + index}
        getItemLayout={(_, index) => ({
          length: ITEM_HEIGHT,
          offset: ITEM_HEIGHT * index,
          index,
        })}
        showsVerticalScrollIndicator={false}
        closeOnRowPress
        closeOnRowOpen
        closeOnRowBeginSwipe
        closeOnScroll
        useNativeDriver
        useAnimatedList
        {...rest}
      />
      <AnimatedTouchable
        onPress={scrollToTop}
        activeOpacity={0.5}
        style={[
          styles.fab,
          {
            opacity: fabSize,
            transform: [{ scale: fabSize }],
          },
        ]}>
        <Icon
          source={images.arrow_right}
          size={20}
          color={colors.tertiaryIcon}
          style={styles.fabIcon}
        />
      </AnimatedTouchable>
    </View>
  )
}

SwipeList.defaultProps = {
}

SwipeList.propTypes = {
  style: PropTypes.object,
  measureView: PropTypes.func,
  contentContainerStyle: PropTypes.object,
  containerStyle: PropTypes.object,
}

export default SwipeList
