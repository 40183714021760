import { gql } from '@apollo/client'

export const LIST_EMPLOYEES = gql`
  query listEmployee(
    $paginationToken: String
    $limit: Int
    $name: String
    $value: String
  ) {
    listEmployee(
      listEmployeeInput: {
        limit: $limit
        paginationToken: $paginationToken
        filter: {name: $name, value: $value}
      }
    ) {
      empleyees {
        id
        firstName
        lastName
        role
        parentId
        color
        email
        userStatus
      }
      paginationToken
    }
  }
`

export const CREATE_EMPLOYEE = gql`
  mutation createEmployee(
    $email: String!
    $firstName: String
    $lastName: String
    $defaultLocation: String
    $phoneNumber: String
    $operatorCode: String
    $color: String
    $parentId: String
    $defaultLocationName: String
    $webAccessCashRegisterId: String
    $apiAccessCashRegisterId: String
  ) {
    createEmployee(
      employeeInput: {
        firstName: $firstName
        lastName: $lastName
        email: $email
        defaultLocation: $defaultLocation
        defaultLocationName: $defaultLocationName
        phoneNumber: $phoneNumber
        operatorCode: $operatorCode
        color: $color
        parentId: $parentId
        webAccessCashRegisterId: $webAccessCashRegisterId
        apiAccessCashRegisterId: $apiAccessCashRegisterId
      }
    ) {
      id
    }
  }
`

export const LIST_LOCATIONS = gql`
  query listLocations($nextToken: String) {
    listLocations(nextToken: $nextToken) {
      locations {
        id
        businessName
        address {
          addressLine1
        }
      }
      nextToken
    }
  }
`

export const GET_EMPLOYEE = gql`
  query Query_retrieveEmployee($id: String!) {
    RetrieveEmployee(id: $id) {
      email
      firstName
      lastName
      phoneNumber
      operatorCode
      color
      role
      userStatus
      defaultLocation
      defaultLocationName
      webAccessCashRegister {
         id
         name
         TCRCode
      }
      apiAccessCashRegister {
         id
         name
         TCRCode
      }
      parent {
        email
      }
    }
  }
`

export const UPDATE_EMPLOYEE = gql`
  mutation updateEmployee(
    $email: String!
    $firstName: String
    $lastName: String
    $operatorCode: String
    $defaultLocation: String
    $phoneNumber: String
    $color: String
    $id: String!
    $defaultLocationName: String
    $webAccessCashRegisterId: String
    $apiAccessCashRegisterId: String
    $privacyPolicy: Boolean
    $termsCondition: Boolean
  ) {
    updateEmployee(
      id: $id
      employeeInput: {
        email: $email
        firstName: $firstName
        lastName: $lastName
        operatorCode: $operatorCode
        defaultLocation: $defaultLocation
        defaultLocationName: $defaultLocationName
        color: $color
        phoneNumber: $phoneNumber
        webAccessCashRegisterId: $webAccessCashRegisterId,
        apiAccessCashRegisterId: $apiAccessCashRegisterId,
        termsCondition:$termsCondition,
        privacyPolicy:$privacyPolicy,

      }
    ) {
      id
      email
      color
      firstName
      lastName
      operatorCode
    }
  }
`

export const CHANGE_STATUS = gql`
  mutation changeEmployeeStatus($id: String, $disable: Boolean) {
    changeEmployeeStatus(statusInput: {id: $id, disable: $disable}) {
      id
    }
  }
`

export const DELETE_EMPLOYEE = gql`
  mutation deleteEmployee($id: String!) {
    deleteEmployee(id: $id) {
      id
    }
  }
`
export const UPDATE_USER_POLICY = gql`
mutation updateUserPolicy($id:String!,$privacyPolicy:Boolean!,$termsCondition:Boolean!){
  updateUserPolicy(
    id:$id,
    userPolicyInput:{
      privacyPolicy:$privacyPolicy,
      termsCondition:$termsCondition,
    }
    ){
      id
    }
}

`

export const RESEND_CREDENTIALS = gql`
  mutation resendUserCredentials($id: String!) {
    resendUserCredentials(id: $id) {
      id
    }
  }
`
