import React from 'react'
import {
  View,
} from 'react-native'
import PropTypes from 'prop-types'
import { useTheme } from 'react-native-themed-styles'

import Loader from '../Loader'
import SimpleList from '../SimpleList'

import themedStyles from './Route.Styles'

function Route(props) {
  const [styles] = useTheme(themedStyles)

  const {
    data,
    loading,
    onItemPress,
    onRefresh,
    refreshing,
    sectionList,
    listHeaderComponent,
    listFooterComponent,
    listEmptyComponent,
    sectionSeparatorComponent,
    style,
    listStyle,
  } = props

  if (loading || refreshing) {
    return (
      <View style={styles.container}>
        <Loader view="full" size={32}  />
      </View>
    )
  }
  return (
    <View style={[styles.scene, style]}>
      <SimpleList
        DATA={data}
        sectionList={sectionList}
        touchableItems
        withSectionHeader
        onRefresh={onRefresh}
        refreshing={refreshing}
        onItemPress={onItemPress}
        listHeaderComponent={listHeaderComponent}
        listFooterComponent={listFooterComponent}
        listEmptyComponent={listEmptyComponent}
        sectionSeparatorComponent={sectionSeparatorComponent}
        //onEndReached={onEndReached}
        style={listStyle}
      />
    </View>
  )
}

Route.defaultProps = {

}

Route.propTypes = {
  data: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
  ]),
  loading: PropTypes.bool,
  onItemPress: PropTypes.func,
  onRefresh: PropTypes.func,
  refreshing: PropTypes.bool,
  sectionList: PropTypes.bool,
  listHeaderComponent: PropTypes.node,
  listFooterComponent: PropTypes.node,
  listEmptyComponent: PropTypes.node,
  sectionSeparatorComponent: PropTypes.node,
  style: PropTypes.object,
  listStyle: PropTypes.object,
}

export default Route
