import { gql } from '@apollo/client'

export const GET_INVOICE = gql`
  query getEinvoices(
    $eic: String
    ) {
    getEinvoices(getEinvoicesInput: {
      eic: $eic,
    }) {
      eInvoices {
        attributes {
          EIC
          DocNumber
          Amount
          Status
          PartyType
          RecDateTime
          DueDateTime
          SellerTin
          BuyerTin
        },
      },
    },
  }
`

export const GET_BUYER_SELLER = gql`
  query getEinvoices(
    $eic: String
    ) {
    getEinvoices(getEinvoicesInput: {
      eic: $eic,
    }) {
      eInvoices {
        attributes {
          SellerTin
          BuyerTin
        },
      },
    },
  }
`

export const GET_TAX_PAYER = gql`
  query getTaxPayers(
    $tin: String
    ) {
      getTaxPayers(getTaxPayersInput: {
        filter: {
          tin: $tin
        }
    }) {
      taxPayers {
        attributes {
          Address
          Country
          Name
          Tin
          Town
        },
      },
    },
  }
`

export const GET_PDF = gql`
  query getEinvoices(
    $eic: String
    ) {
    getEinvoices(getEinvoicesInput: {
      eic: $eic,
    }) {
      eInvoices {
        Pdf
      },
    },
  }
`

export const CHANGE_STATUS = gql`
  mutation changeEinvoiceStatus(
      $eics: [String!]!,
      $status: EinvoiceStatus
  ){
    changeEinvoiceStatus(changeEinvoiceStatusInput:{
      eics: $eics,
      status: $status
    }){
      statusCode
    }
  }
`

export const GET_MAIN_INVOICE = gql`
query getMyEinvoice($id: String!, $locId: String, $cashRegisterId: String){
    getMyEinvoice(
      id: $id
      requestedLocationAndCashRegister: {
        locationId: $locId,
        cashRegisterId: $cashRegisterId
      }
      ){
      myEinvoice {
        ublInvoice {
            id
            legalMonetaryTotal {
              payable {
                amount
                currency
              }
            }
            note
            issueDate
            status
          }
      }
    }
}   
`

export const GET_MY_INVOICE = gql`
  query getMyEinvoice($id: String!,  $locId: String, $cashRegisterId: String){
    getMyEinvoice(
      id: $id
      requestedLocationAndCashRegister: {
        locationId: $locId,
        cashRegisterId: $cashRegisterId
      }
      ){
      myEinvoice {
        ublInvoice {
          employeeEmail
          issueDate
          status
          id
          note
          dueDate
          invoiceDocumentCode
          invoiceProcessCode
          buyerReference
          orderReference {
            id
            salesOrderId
          }
          contractDocumentReference {
            id
          }
          additionalDocumentReferences {
            id
            description
            uri
            binObj {
              filename
              contents
              mime
            }
          }
          projectReference {
            id
          }
          delivery {
            deliveryPartyName {
              name
            }
            actualDeliveryDate
            deliveryLocation {
              address {
                streetName
                cityName
                postalCode
                countryCode
              }
            }
            deliveryPartyName {
              name
            }
          }
          docCurrencyCode
          taxCurrencyCode
          invoicePeriod {
            startDate
            endDate
            descriptionCode
          }
          supplierParty {
            issuerNUIS
            name
            address {
              streetName
              cityCode
              countryCode
            }
            taxSchema
            registrationName
            contact {
              name
              telephone
              electronicMail
            }
          }
          customerParty {
            issuerNUIS
            name
            address {
              streetName
              cityCode
              countryCode
            }
            taxSchema
            registrationName
            contact {
              name
              telephone
              electronicMail
            }
          }
          paymentMeans {
            card {
              primaryAccountId
            }
            payeeFinantialAccount
            instructionNote
            code
          }
          taxTotal {
            taxAmount {
              amount
              currency
            }
            taxSubtotal {
              taxableAmount {
                amount
                currency
              }
              taxAmount {
                amount
                currency
              }
              taxCategory {
                id
                percent
                taxSchema
              }
            }
          }
          legalMonetaryTotal {
            lineExtension {
              amount
              currency
            }
            taxExclusive {
              amount
              currency
            }
            taxInclusive {
              amount
              currency
            }
            payable {
              amount
              currency
            }
            charge {
              amount
              currency
            }
            allowance {
              amount
              currency
            }
          }
          allowances {
            chargeIndicator
            chargeReason
            amount {
              amount
              currency
            }
            taxCategory {
              id
              percent
              taxSchema
            }
            baseAmount {
              amount
              currency
            }
            percent
          }
          invoiceLine {
            id
            quantity
            lineExtension {
              amount
              currency
            }
            allowances {
              chargeIndicator
              chargeReason
              amount {
                amount
                currency
              }
              taxCategory {
                id
                percent
                taxSchema
              }
              baseAmount {
                amount
                currency
              }
              percent
            }
            item {
              name
              sellerIdentification
              description
              price {
                amount
                currency
              }
              taxCategory {
                id
                percent
                taxSchema
              }
              baseQuantity {
                quantity
                unitCode
              }
            }
            totalMoney {
              amount
              currency
            }
            totalTaxMoney {
              amount
              currency
            }
          }
        }
      }
    }
  }
`
