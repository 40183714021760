import React, { useState } from 'react'
import { SafeAreaView, View } from 'react-native'
import PropTypes from 'prop-types'
import { useTheme } from 'react-native-themed-styles'

import {
  ButtonGroup,
} from '../../../../Components'

import SalesList from '../../EventLists/SalesList'
import RefundList from '../../EventLists/RefundList'
import PayInList from '../../EventLists/PayInList'
import DrawerList from '../../EventLists/DrawerList'

import themedStyles from './Events.Styles'

const PAYINOUT = {
  payin: 'PAID_IN',
  payout: 'PAID_OUT',
}

const DRAWER = {
  opened: 'OPEN',
  ended: 'END',
}

function Events({
  navigation,
  id,
}) {

  const [selected, setSelected] = useState('sales')

  const options = [
    {
      id: 1,
      title: 'users_sales_tag',
      list: SalesList,
      type: 'sales',
    },
    {
      id: 2,
      title: 'users_refund_tag',
      list: RefundList,
      type: 'refunds',
    },
    {
      id: 3,
      title: 'gov_pay_in_title',
      list: PayInList,
      type: 'payin',
    },
    {
      id: 4,
      title: 'gov_pay_out_title',
      list: PayInList,
      type: 'payout',
    },
    {
      id: 5,
      title: 'drawer_opened_route',
      list: DrawerList,
      type: 'opened',
    },
    {
      id: 5,
      title: 'drawer_ended_route',
      list: DrawerList,
      type: 'ended',
    },
  ]

  const [styles] = useTheme(themedStyles)

  const index = options.findIndex(el => el.type === selected)
  const List = options[index].list

  return (
    <SafeAreaView style={styles.container} >
      <List
        navigation={navigation}
        id={id}
        eventType={PAYINOUT[selected]}
        state={DRAWER[selected]}
        ListHeaderComponent={
          <View style={styles.searchContainer} >
            <ButtonGroup
              selected={selected}
              options={options}
              onPress={(type) => {
                setSelected(type)
              }}
            />
          </View>}
      />
    </SafeAreaView>
  )
}
Events.propTypes = {
  navigation: PropTypes.object,
  id: PropTypes.string,
}

export default Events
