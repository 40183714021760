/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useContext, memo, useRef, useEffect, useCallback } from 'react'
import { View, SafeAreaView, TouchableOpacity, Animated, Easing } from 'react-native'
import PropTypes from 'prop-types'
import { useLazyQuery } from '@apollo/client'
import { useTheme } from 'react-native-themed-styles'
import moment from 'moment-timezone'

import {
  Icon,
  Text,
  Loader,
  ButtonGroup,
  DoubleIcon,
} from '../../../../Components'

import { LIST_TRANSACTIONS_HISTORY } from '../../UserDetails.Schema'

import images from '../../../../Themes/Images'
import Theme from '../../../../Themes/Theme'

import { useLanguage } from '../../../../Utils/Language'
import Transactions from '../../ActivityLists/Transactions'
import Refunds from '../../ActivityLists/Refunds'
import { INF } from '../../../../Utils/Helpers'

import { useConfig } from '../../../../Utils'

import themedStyles from './Activity.Styles'

const AnimatedTouchable = Animated.createAnimatedComponent(TouchableOpacity)

const LANGUAGES = {
  al: 'sq',
  en: 'en-gb',
}

function SectionItem({ sectionTitle }) {
  const [styles] = useTheme(themedStyles)
  const { colors } = useContext(Theme)

  return (
    <Text
      i18nKey={sectionTitle}
      size="footnote"
      color={colors.secondaryText}
      style={styles.sectionText}
    />
  )
}
SectionItem.propTypes = {
  sectionTitle: PropTypes.string,
}

const TransactionIcon = memo(function TransactionIcon() {
  const [styles] = useTheme(themedStyles)

  return <DoubleIcon
    iconSource={images.cash}
    statusIconSource={images.transaction}
    iconSize={30}
    statusSize={10}
    style={styles.transactionLeftSpacing}
    statusIconStyle={styles.bottomSpacing}
  />
})

const RefundIcon = memo(function RefundIcon() {
  const [styles] = useTheme(themedStyles)

  return <DoubleIcon
    iconSource={images.cash}
    statusIconSource={images.refund}
    iconSize={30}
    statusSize={10}
    style={styles.leftSpacing}
    statusIconStyle={styles.bottomSpacing}
  />
})

const ArrowIcon = memo(function ArrowIcon() {
  return <Icon source={images.arrow_right} size={20} />
})

const icons = {
  transaction: TransactionIcon,
  refund: RefundIcon,
}

const Item = memo(function Item({
  item,
  stylee,
}) {
  const [styles] = useTheme(themedStyles)
  const { colors } = useContext(Theme)

  const TypeIcon = icons[item.type]

  return (
    <View style={[styles.itemStyle, stylee, styles[`firstItem_${item.isFirst}`], styles[`lastItem_${item.isLast}`]]}>
      <TouchableOpacity onPress={item.onPress} disabled={!item.onPress} >
        <View style={styles.centeredItemsView}>
          <TypeIcon />
          <View style={styles.listDescContainer}>
            <Text i18nKey={item.title} numberOfLines={1} size="footnote" />
            <Text
              i18nKey={item.description}
              weight="light"
              color={colors.secondaryText}
              numberOfLines={1}
              style={styles.descriptionStyles(item.type)}
            />
          </View>
          <View style={styles.itemRightComponent}>
            <Text i18nKey={item?.amount} style={styles.textRightSpacing} />
            <ArrowIcon />
          </View>
        </View>
      </TouchableOpacity>
    </View>
  )
})
Item.propTypes = {
  item: PropTypes.object,
  stylee: PropTypes.object,
}

function ErrorItem({
  loading,
}) {
  const [styles] = useTheme(themedStyles)

  return (
    <View style={styles.errorItemWrapper}>
      <View style={styles.errorItemSubwrapper}>
        {loading ? null
          : <>
            <Text i18nKey="orders_no_transactions_label" align="center" size="body" weight="light" />
          </>}
      </View>
    </View>
  )
}
ErrorItem.propTypes = {
  loading: PropTypes.bool,
}

const listItems = {
  section: SectionItem,
  transaction: Item,
  refund: Item,
}

const TransactionsList = function List1({
  list,
  items,
  fetchMore,
  navigation,
  refetch,
  ListHeaderComponent,
}) {

  const { switchLocation: { deviceId, locationId } } = useConfig()

  const [styles] = useTheme(themedStyles)
  const { colors } = useContext(Theme)
  const lang = useLanguage()

  const sectionDate = useRef('-')
  const [data, setData] = useState([])
  const [loading1, setLoading] = useState(true)

  useEffect(() => {
    setLoading(true)
    sectionDate.current = null
    setData([])
    list()
  }, [])


  const mapData = (dataa) => {
    const res = []
    let itemTime, itemType
    let isFirst = false, isLast = false
    dataa?.map((item, index) => {
      isFirst = false
      isLast = false
      itemType = item.type.toLowerCase()
      itemTime = item[itemType].createdAt
      const date = moment(itemTime).locale(LANGUAGES[lang]).format('MMM DD, YYYY')
      if (date !== sectionDate.current) {
        if (res?.[res.length - 1]) {
          res[res.length - 1].isLast = true
        }
        sectionDate.current = date
        res.push({
          id: date,
          sectionTitle: date,
          type: 'section',
        })
      }

      if (res?.[res.length - 1]?.type === 'section') {
        isFirst = true
        isLast = false
      }

      const element = item[itemType]

      let amount, currency, total, title, onItemPress, transactionId
      if (itemType === 'transaction') {
        title = element?.receiptNumber
        amount = INF.format(element?.tender?.amountMoney?.amount)
        currency = element?.tender?.amountMoney?.currency
        total = `+${amount} ${currency}`
        transactionId = element.id
        onItemPress = () => navigation.navigate('Receipt', { id: element.id, receiptNumber: title })
      } else {
        title = element.receiptNumber
        amount = INF.format(element?.amountMoney?.amount)
        currency = element?.amountMoney?.currency
        total = `-${amount} ${currency}`
        transactionId = element.transactionId
        onItemPress = () => navigation.navigate('Refund', { id: element.transactionId, refundId: element.id, type: 'goBack' })
      }
      res.push({
        id: element.id,
        transactionId: transactionId,
        type: itemType,
        title: title,
        description: moment(itemTime).locale(LANGUAGES[lang]).format('hh:mm A'),
        subDescText: element.employee,
        amount: total,
        isFirst: isFirst,
        isLast: isLast,
        onPress: onItemPress,
      })
    })
    if (res?.[res.length - 1]) {
      res[res.length - 1].isLast = true
    }
    return res
  }

  useEffect(() => {
    if (items) {
      const dataa = items?.transactionsHistory
      const resp = mapData(dataa?.items)
      setData(resp)
      setLoading(false)
    }
  }, [items])

  const listRef = useRef(null)
  const fabSize = useRef(new Animated.Value(0)).current
  const animate = val => {
    Animated.timing(fabSize, {
      toValue: val,
      duration: 370,
      useNativeDriver: true,
      easing: Easing.inOut(Easing.cubic),
    }).start()
  }
  const scrollToTop = () => {
    animate(0)
    listRef.current.scrollToOffset({
      offset: 0,
    })
  }

  const keyExtractor = useCallback((item, index) => 'row-' + index, [])

  const getItemLayout = useCallback((_, index) => ({
    length: 61,
    offset: 61 * index,
    index,
  }), [])


  const onEndReached = () => {
    if (items?.transactionsHistory?.nextToken === null) {
      return
    }
    setLoading(true)
    const beginTime = '', endTime = ''
    fetchMore({
      variables: {
        limit: 20,
        nextToken: items?.transactionsHistory?.nextToken,
        locationId: locationId,
        cashRegisterId: deviceId,
        beginTime: beginTime,
        endTime: endTime,
      },
    })
  }

  return (
    <>
      <Animated.FlatList
        showsVerticalScrollIndicator={false}
        keyExtractor={keyExtractor}
        getItemLayout={getItemLayout}
        ref={(ref) => (listRef.current = ref)}
        onMomentumScrollEnd={(event) => {
          if (event.nativeEvent.contentOffset.y > 800) {
            animate(1)
          } else {
            animate(0)
          }
        }}
        data={data}
        style={styles.listStyle}
        contentContainerStyle={styles.contentContainerStyle}
        ListEmptyComponent={<ErrorItem loading={loading1} />}
        ListFooterComponent={<View style={styles.footer} >{loading1 ? <Loader size={32} /> : null}</View>}
        ListHeaderComponent={ListHeaderComponent}
        renderItem={({ item, index }) => {
          const ListItem = listItems[item.type]
          return <ListItem
            sectionTitle={item.sectionTitle}
            key={'row-' + index}
            item={item}
          />
        }}
        onEndReachedThreshold={1}
        onRefresh={() => refetch()}
        refreshing={false}
        onEndReached={onEndReached}
      />
      <AnimatedTouchable
        onPress={scrollToTop}
        activeOpacity={0.5}
        style={[
          styles.fab,
          {
            opacity: fabSize,
            transform: [{ scale: fabSize }],
          },
        ]}>
        <Icon
          source={images.arrow_right}
          size={20}
          color={colors.tertiaryIcon}
          style={styles.fabIcon}
        />
      </AnimatedTouchable></>
  )
}
TransactionsList.propTypes = {
  list: PropTypes.func,
  items: PropTypes.object,
  fetchMore: PropTypes.func,
  navigation: PropTypes.object,
  refetch: PropTypes.func,
  ListHeaderComponent: PropTypes.node,
}

function List({
  navigation,
  id,
  ListHeaderComponent,
}) {

  const { switchLocation: { deviceId, locationId } } = useConfig()


  const [listTransactionHistory, { loading: listLoading, error: listError, data: listData, refetch, fetchMore }] = useLazyQuery(
    LIST_TRANSACTIONS_HISTORY,
    {
      fetchPolicy: 'network-only',
    },
  )

  const list = () => {
    listTransactionHistory({
      variables: {
        locationId: locationId,
        cashRegisterId: deviceId,
        limit: 20,
        employeeId: id,
      },
    })
  }

  return (
    <TransactionsList
      list={list}
      loading={listLoading}
      error={listError}
      items={listData}
      navigation={navigation}
      fetchMore={fetchMore}
      refetch={refetch}
      id={id}
      ListHeaderComponent={ListHeaderComponent}
    />
  )
}
List.propTypes = {
  id: PropTypes.string,
  navigation: PropTypes.object,
  ListHeaderComponent: PropTypes.node,
}


function Activity({
  id,
  navigation,
}) {
  const [styles] = useTheme(themedStyles)

  const [selected, setSelected] = useState('all')
  const options = [
    {
      title: 'checkout_title_tab_all',
      list: List,
      type: 'all',
    },
    {
      title: 'orders_label',
      list: Transactions,
      type: 'transaction',
    },
    {
      title: 'refunds_label',
      list: Refunds,
      type: 'refund',
    },
  ]

  const index = options.findIndex(el => el.type === selected)
  const ListType = options[index].list

  return (
    <SafeAreaView style={styles.container} >
      <ListType
        navigation={navigation}
        id={id}
        ListHeaderComponent={<View style={styles.searchContainer} >
          <ButtonGroup
            selected={selected}
            onPress={(type) => setSelected(type)}
            options={options}
          />
        </View>}
      />
    </SafeAreaView>
  )
}
Activity.propTypes = {
  id: PropTypes.string,
  navigation: PropTypes.object,
}

export default Activity
