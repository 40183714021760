import { Platform } from 'react-native'

import { styleSheetFactory } from '../../Themes/Colors'

const styles = styleSheetFactory(colors => ({
    container: {
        height: 50,
        width: 50,
        backgroundColor: colors.tertiaryBackground,
        justifyContent: 'center',
        alignItems: 'center',
        // marginLeft: 15,
        borderRadius: 7,
        position: 'relative',
    },
    statusContainer: {
        minWidth: 16,
        height: 16,
        padding: 2,
        backgroundColor: colors.accent,
        position: 'absolute',
        top: 8,
        right: 8,
        borderRadius: 7.5,
        justifyContent: 'center',
        alignItems: 'center',
    },
    status: {
        marginTop: Platform.OS === 'android' ? 2 : 1,
        fontSize: 12,
        lineHeight: 12,
    },
}))

export default styles
