import { Dimensions } from 'react-native'
import Color from 'color'

import { styleSheetFactory } from '../../Themes/Colors'

const { width } = Dimensions.get('screen')

const styles = styleSheetFactory(colors => ({
    container: {
        flexGrow: 1,
        flex: 1,
        backgroundColor: colors.background,
    },
    contentContainer: {
        paddingHorizontal: 15,
    },
    inlineButton1: {
        flex: 1,
        height: 60,
        marginRight: 7.5,
        marginLeft: 7.5,
        flexDirection: 'column',
    },
    inlineButton2: {
        flex: 1,
        height: 60,
        marginLeft: 7.5,
        flexDirection: 'column',
    },
    inlineButtons: {
        flexDirection: 'row',
        flex: 1,
        marginBottom: 15,
        alignItems: 'center',
    },
    fullButton: {
        height: 50,
        justifyContent: 'flex-start',
        paddingHorizontal: 0,
        marginBottom: 30,
    },
    fullIssueButton: {
        flex: 1,
        height: 60,
        marginRight: 7.5,
        flexDirection: 'column',
    },
    invValContainer: {
        marginVertical: 20,
        paddingVertical: 10,
        borderTopColor: colors.separator,
        borderTopWidth: 1,
        justifyContent: 'flex-start',
    },
    generalInfo: {
        marginTop: 20,
        borderRadius: 7,
        backgroundColor: colors.tertiaryBackground,
    },
    qrCode: {
        alignSelf: 'center',
        padding: 15,
        backgroundColor: colors.background,
    },
    invoiceButton: {
        marginHorizontal: 15,
    },
    serviceItemsContainer: {
        marginTop: 20,
    },
    totalItems: {
        marginTop: 20,
    },
    listTitles: {
        marginBottom: 10,
    },
    serviceItems: {
        borderRadius: 7,
        backgroundColor: colors.tertiaryBackground,
        padding: 5,
    },
    listItems: {
        backgroundColor: colors.tertiaryBackground,
        borderRadius: 7,
    },
    titleStyle: {
        color: colors.secondaryAccent,
        fontSize: 16,
    },
    listRightComponent: {
        alignSelf: 'center',
    },
    rightLabelStyle: {
        alignSelf: 'flex-start',
        marginRight: 10,
        paddingVertical: 5,
    },
    itemRightLabel: {
    },
    listLeftComponent: {
        flex: 1,
        flexDirection: 'row',
    },
    listRefundRightComponent: {
        alignItems: 'center',
        marginRight: 10,
    },
    listRightComponentText: {
        alignItems: 'flex-end',
        marginRight: 10,
    },
    header: {
        height: 56,
        alignItems: 'center',
        flexDirection: 'row',
        backgroundColor: colors.background,
    },
    headerIcon: {
        marginHorizontal: 20,
    },
    modalButton: {
        height: 45,
        marginTop: 15,
    },
    separatorStyle: {
        height: 1,
        flex: 1,
        backgroundColor: colors.lightGrey,
    },
    modalContent: {
        backgroundColor: colors.white,
        alignSelf: 'center',
        paddingBottom: 30,
        borderRadius: 6,
        minWidth: width - 80,
        maxWidth: 400,
    },
    closeContainer: {
        backgroundColor: colors.disabled,
        position: 'absolute',
        top: 0,
        right: 0,
        borderTopRightRadius: 7,
        borderBottomLeftRadius: 7,
        width: 75,
        justifyContent: 'center',
        alignItems: 'center',
        padding: 10,
    },
    itemStyle: {
        flexDirection: 'row',
        alignItems: 'center',
        height: 60,
    },
    listDescContainer: {
        flexGrow: 1,
        flexShrink: 1,
        marginLeft: 15,
        flexDirection: 'row',
    },
    retryButton: {
        margin: 15,
        marginBottom: 25,
    },
    bottomSpacing: { marginBottom: 15 },
    itemStyles: {
        backgroundColor: colors.tertiaryBackground,
        borderTopColor: colors.separator,
        borderTopWidth: 1,
    },
    leftComponentStyles: icon => ({
        backgroundColor: Color(icon).fade(0.7).toString(),
        height: '100%',
        width: 70,
        justifyContent: 'center',
        alignItems: 'center',
    }),
    refundComponent: {
        marginRight: 10,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
    },
    leftSpacing: { marginLeft: 10 },
    bottomSmallSpacing: { marginBottom: 3 },
    listStyle: { flex: 1 },
    buttonTitle: { fontSize: 16 },
    webStyles: { overflow: 'hidden', height: 0 },
}))

export default styles
