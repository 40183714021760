import { styleSheetFactory } from '../../../../../Themes/Colors'

const styles = styleSheetFactory(colors => ({
  itemContainer: {
    flexGrow: 1,
    minWidth: 100,
    borderColor: colors.disabled,
    borderWidth: 1,
    borderRadius: 7,
    margin: 5,
    justifyContent: 'center',
    alignItems: 'center',
  },
  itemContainerSelected_true: {
    borderWidth: 0,
    backgroundColor: colors.secondaryAccent,
  },
  itemContainerDisabled_true: {
    opacity: 0.5,
  },
  touchable: {
    flexGrow: 1,
    padding: 5,
    justifyContent: 'center',
    alignItems: 'center',
  },
  iconSelected_false: {
    tintColor: colors.secondaryText,
  },
  iconSelected_true: {
    tintColor: colors.white,
  },
  titleSelected_false: {
    color: colors.secondaryText,
  },
  titleSelected_true: {
    color: colors.white,
  },
}))

export default styles
