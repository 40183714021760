import { styleSheetFactory } from '../../Themes/Colors'

const styles = styleSheetFactory(colors => ({
  container: {
    flex: 1,
    backgroundColor: colors.background,
  },
  modalContent: {
    marginTop: 30,
    marginBottom: 15,
  },
  modalCloseContainer: {
    position: 'absolute',
    top: 0,
    right: 0,
    height: 30,
    width: 75,
    borderTopRightRadius: 7,
    borderBottomLeftRadius: 7,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: colors.separator,
  },
  modalTitle: {
    marginVertical: 10,
  },
  modalDesc: {
    marginBottom: 15,
  },
  modalPrimaryButton: {
    width: '100%',
    marginVertical: 15,
  },
  modalSecondaryButton: {
    width: '100%',
  },
}))

export default styles
