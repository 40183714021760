import React, { useCallback, useState } from 'react'
import { ScrollView, View } from 'react-native'

import { useDispatch, useSelector } from 'react-redux'

import { useTheme } from 'react-native-themed-styles'

import { useMutation } from '@apollo/client'

import PropTypes from 'prop-types'

import { Button, Icon, Note, Text } from '../../../../Components'
import { updateCalculatedUBL, updatePaymentAttribute, updateStep } from '../../Payment.Actions'
import Step from '../Step'

import Colors from '../../../../Themes/Colors'


import { colors, images } from '../../../../Themes'

import { SAVE_EINVOICE } from '../../Payment.Schema'
import { getEinvoiceCalculated, getInvoiceId, getNotes } from '../../Payment.Selectors'
import { useConfig } from '../../../../Utils'

import themedStyles from './NoteStep.Styles'

function NoteStep({
    goBack,
    stepperLength,
    index,
    addListener,
    dispatch,
}) {

    const { switchLocation: { deviceId, locationId } } = useConfig()

    const [styles] = useTheme(themedStyles)

    const [saveEinvoice, { loading }] = useMutation(SAVE_EINVOICE)


    const dispatchAction = useDispatch()

    const setPaymentStatus = (value) => dispatchAction(updatePaymentAttribute('paymentStatus', value))
    const calculatedUBL = useSelector(getEinvoiceCalculated)
    const einvoiceId = useSelector(getInvoiceId)
    const eInvoiceNotes = useSelector(getNotes)
    const [notes, setNotes] = useState(eInvoiceNotes)
    const [noteCount, setnoteCount] = useState(eInvoiceNotes?.length)
    const [noteInput, setnoteInput] = useState('')
    const isNoteEmpty = noteInput.trim() === ''

    const maxNotesNumber = 100

    const updateNote = (value) => {
        dispatchAction(updateStep('note', value))
    }

    const pushNote = (note) => {
        if (!isNoteEmpty) {
            setNotes(prev => { return [{ id: noteCount + 1, text: note }, ...prev] })
            setnoteCount(prev => prev + 1)
        }
    }
    const handleAddNote = () => {
        if (notes.length < maxNotesNumber) {
            pushNote(noteInput)
            setnoteInput('')
        }
    }

    const handleEditNote = (id, text,) => {

        const newNotes = notes?.map((note) => {
            if (note.id === id) {
                note.text = text
                return note
            } else {
                return note
            }
        })

        const filteredNotes = newNotes.filter(note => note !== undefined)
        setNotes(filteredNotes)
    }

    const handleDeleteNote = (id) => {
        const newNotes = notes?.map((note) => {
            if (note.id === id) {
                return undefined
            } else {
                return note
            }
        })

        const filteredNotes = newNotes.filter(note => note !== undefined)
        setNotes(filteredNotes)
    }



    const renderNotes = useCallback(() => {
        return (
            notes?.map(note => {
                const editNote = handleEditNote.bind(null, note?.id)
                const deleteNote = handleDeleteNote.bind(null, note?.id)
                return (
                    <Note
                        key={note?.id}
                        withIcon={false}
                        title={'einvoice_notes_label'}
                        withInput
                        titleWeight={'normal'}
                        titleColor={Colors.secondaryText}
                        inputValue={note?.text}
                        onChange={editNote}
                        onBlur={deleteNote}
                        containerStyle={styles.singleNoteContainer}
                    />
                )
            })
        )
    }, [notes])

    const next = () => {
        if (einvoiceId) {
            const newNotes = notes?.map(note => {
                return (
                    `note=${note.text}`
                )
            })
            const newUbl = {
                ...calculatedUBL,
                note: [...calculatedUBL?.note.filter(note => (note?.includes('id=') || note?.includes('CurrencyExchangeRate='))), ...newNotes],
            }
            updateNote(notes)
            dispatchAction(updateCalculatedUBL(newUbl))
            saveEinvoice({
                variables: {
                    locId: locationId,
                    cashRegisterId: deviceId,
                    eInvoiceInput: {
                        ublInvoice: newUbl,
                    },
                },
            })
                .then(() => {
                    setPaymentStatus('preview')
                })
        }
    }


    return (
        <Step
            title="header_notes_einvoice"
            goBack={goBack}
            stepperLength={stepperLength}
            index={index}
            addListener={addListener}
            dispatch={dispatch}
        >

            <ScrollView
                contentContainerStyle={styles.fillRemaining}
                showsVerticalScrollIndicator={false}
            >

                <View style={styles.noteNumberContainer}>
                    <Text i18nKey={'einvoice_notes_label'} />
                    <Text i18nKey={`${notes?.length}/${maxNotesNumber}`} />

                </View>
                <Note
                    withIcon={false}
                    title={'einvoice_notes_label'}
                    withInput
                    titleWeight={'normal'}
                    titleColor={Colors.secondaryText}
                    inputValue={noteInput}
                    onChange={setnoteInput}
                    containerStyle={styles.noteContainer}
                />
                <Text align={'right'} i18nKey={'einvoice_note_character_limit'} color={noteInput.length < 250 ? colors.primaryText : 'red'} />
                <Button title={'einvoice_note_button'}
                    variant="secondary"
                    style={styles.addNote}
                    onPress={handleAddNote}
                />

                {renderNotes()}
            </ScrollView>
            <View style={styles.buttons}>
                <Button title="einvoice_references_delivery_button"
                    variant={'preActive'}
                    icon={images.arrowLeft}
                    iconColor={colors.accent}
                    style={styles.rightButton} onPress={() => setPaymentStatus('delivery')} />
                <Button title="einvoice_delivery_preview_button"
                    variant={loading ? 'disabled' : 'active'}
                    rightComponent={!loading && <Icon
                        size={20}
                        source={images.arrowRight}
                        color={colors.white}
                        style={styles.leftSpacing}
                    />}
                    onPress={next}
                    loader={loading}
                    style={styles.leftButton} />
            </View>
        </Step>




    )
}
NoteStep.propTypes = {
    goBack: PropTypes.func,
    stepperLength: PropTypes.number,
    index: PropTypes.number,
    addListener: PropTypes.func,
    dispatch: PropTypes.func,
}
export default NoteStep
