/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo, useState } from 'react'
import {
  SafeAreaView,
  View,
} from 'react-native'
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs'
import { useTheme } from 'react-native-themed-styles'
import PropTypes from 'prop-types'

import {
  Header,
  ButtonGroup,
  Tabs,
} from '../../Components'
import { IndividForm, BusinessForm } from '../NewCustomer'

import ContentManager from '../../Utils/ContentManager'

import Transactions from './Transactions'
import Refunds from './Refunds'

import themedStyles from './CustomerDetails.Styles'

import images from '../../Themes/Images'

const Tab = createMaterialTopTabNavigator()

const Form = {
  'INDIVIDUAL': IndividForm,
  'BUSINESS': BusinessForm,
}

function Route({
  push,
  type,
  item,
  goBack,
  onSave,
  isSupplier,
}) {
  const FormType = Form[type]
  return (<FormType navigate={push} id={item?.id} goBack={goBack} onSave={onSave} isSupplier={isSupplier} />)

}
Route.propTypes = {
  type: PropTypes.string,
  item: PropTypes.object,
  navigate: PropTypes.func,
  onSave: PropTypes.func,
  push: PropTypes.func,
  goBack: PropTypes.func,
  isSupplier: PropTypes.bool,
}

function Activity({ navigation, id }) {
  const [styles] = useTheme(themedStyles)
  const [selected, setSelected] = useState('sales')

  const types = [
    {
      title: 'users_sales_tag',
      type: 'sales',
      list: Transactions,
    },
    {
      title: 'users_refund_tag',
      type: 'refunds',
      list: Refunds,
    },
  ]

  const index = types.findIndex(el => el.type === selected)
  const ListType = types[index].list

  return (
    <ListType
      navigation={navigation}
      id={id}
      ListHeaderComponent={<View style={styles.searchContainer} >
        <ButtonGroup
          selected={selected}
          onPress={(type) => setSelected(type)}
          options={types}
          style={styles.verticalSpacing}
        />
      </View>}
    />
  )
}
Activity.propTypes = {
  id: PropTypes.string,
  navigation: PropTypes.func,
}

function CustomerDetails({
  route: {
    params: {
      item,
      type,
      isSupplier,
      onSave = () => { },
    } = {},
  } = {},
  navigation: {
    goBack,
    navigate,
    push,
  },
}) {
  const [styles] = useTheme(themedStyles)

  const translate = (key) => {
    return ContentManager.translate(key)
  }

  const renderTabs = useMemo(() => {
    return (
      <Tabs
        initialRouteName={translate('customer_business_label')}
        style={styles.tabView}
        sceneContainerStyle={styles.scene}
        screenOptions={{
          swipeEnabled: false,
        }}
      >
        <Tab.Screen key={'tab-business'} name={'Info'}>
          {(props) => (
            <>
              <Route
                {...props}
                navigate={navigate}
                goBack={goBack}
                push={push}
                type={type}
                item={item}
                isSupplier={isSupplier}
                onSave={onSave}
              />
            </>
          )}
        </Tab.Screen>
        <Tab.Screen key={'tab-individ'} name={translate('users_acivity_tab')}>
          {(props) => (
            <>
              <Activity
                {...props}
                navigate={navigate}
                id={item?.id}
              />
            </>
          )}
        </Tab.Screen>
      </Tabs>
    )
  }, [])

  return (
    <SafeAreaView style={styles.container} >
      <Header
        title={type === 'BUSINESS' ? item?.businessName : `${item?.givenName} ${item?.familyName}`}
        image={images.chevronLeft}
        onPress={() => goBack()}
      />
      {renderTabs}

    </SafeAreaView>
  )
}
CustomerDetails.propTypes = {
  navigation: PropTypes.object,
  item: PropTypes.object,
  type: PropTypes.string,
  onSave: PropTypes.func,
  route: PropTypes.object,
}

export default CustomerDetails
