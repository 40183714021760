import React, { useRef } from 'react'
import { ScrollView, View } from 'react-native'
import { useTheme } from 'react-native-themed-styles'
import moment from 'moment'
import PropTypes from 'prop-types'

import { Note, Section } from '../../../Components'

import themedStyles from '../RefundDetails.Styles'
import Expandable from '../../../Components/Expandable/Expandable'
import Colors from '../../../Themes/Colors'

const dateFormat = 'DD/MM/YYYY'

function AdditionalDetails({ eInvoiceData }) {
    const [styles] = useTheme(themedStyles)
    const scrollRef = useRef()
    const notes = eInvoiceData?.note?.filter(note => note.includes('note='))

    const sectionData = [
        {
            title: 'einvoice_delivery_delivery_header',
        },
        {
            label: 'einvoice_delivery_recipient_name',
            value: eInvoiceData?.name,
        },
        {
            label: 'einvoice_delivery_date',
            value: eInvoiceData?.actualDeliveryDate ? moment(eInvoiceData?.actualDeliveryDate)?.format(dateFormat) : '',
        },
        {
            label: 'einvoice_delivery_street',
            value: eInvoiceData?.streetName,
        },
        {
            label: 'einvoice_delivery_city',
            value: eInvoiceData?.cityName,
        },
        {
            label: 'einvoice_delivery_postal_code',
            value: eInvoiceData?.postalCode,
        },
        {
            label: 'einvoice_delivery_state',
            value: eInvoiceData?.countryCode,
        },
    ]

    const detailsData = [
        {
            title: 'payment_total_header',
        },
        {
            label: 'payment_total_method',
            value: 'Non-cash',
        },
        {
            label: 'payment_iban_label',
            value: eInvoiceData?.primaryAccountId,
        },
        {
            label: 'payment_account_number_label',
            value: eInvoiceData?.payeeFinantialAccount,
        },
        {
            label: 'payment_bic_label',
            value: eInvoiceData?.code,
        },
        {
            label: 'payment_description_label',
            value: eInvoiceData?.instructionNote,
        },
    ]

    const referenceData = [
        {
            title: 'einvoice_references_reference_header',
        },
        {
            label: 'einvoice_references_buyer',
            value: eInvoiceData?.buyerReference,
        },
        {
            label: 'einvoice_references_project',
            value: eInvoiceData?.projectReference,
        },
        {
            label: 'einvoice_references_contract',
            value: eInvoiceData?.contractReference,
        },
        {
            label: 'einvoice_references_order_form',
            value: eInvoiceData?.orderReference,
        },
        {
            label: 'einvoice_references_sales_order',
            value: eInvoiceData?.salesOrderId,
        },
        {
            title: 'einvoice_references_document_header',
        },
        {
            label: 'einvoice_references_additional_docs',
            value: eInvoiceData?.additionalDocReference,
        },
        {
            label: 'einvoice_references_docs_location',
            value: eInvoiceData?.uri,
        },
        {
            label: 'einvoice_references_docs_description',
            value: eInvoiceData?.description,
        },
        {
            label: 'einvoice_document_label',
            value: eInvoiceData?.fileName,
        },
    ]

    return (
        <ScrollView
            style={styles.container}
            contentContainerStyle={styles.content}
            showsVerticalScrollIndicator={false}
            ref={scrollRef}
        >
            <Section data={[...sectionData, ...detailsData, ...referenceData]} />
            {notes?.length !== 0 &&
                <View style={styles.noteSectionContainer} >
                    <Expandable scrollRef={scrollRef} containerStyle={styles.expandableContainer} title={'einvoice_notes_label'} >
                        <View style={styles.separator} />

                        {notes?.map(note => {
                            return (
                                <>
                                    <Note
                                        key={note?.text}
                                        size="body"
                                        containerStyle={styles.noteContainer}
                                        desc={note?.slice(5)}
                                        withIcon={false}
                                        withTitle={false}
                                        color={Colors.primaryText}
                                        weight={'regular'}
                                    />
                                    <View style={styles.separator} />
                                </>
                            )
                        })}


                    </Expandable>
                </View>
            }
        </ScrollView>
    )
}

AdditionalDetails.propTypes = {
    eInvoiceData: PropTypes.object,
}

export default AdditionalDetails
