import React from 'react'
import { Text } from 'react-native'
import PropTypes from 'prop-types'

import { useTheme } from 'react-native-themed-styles'

import {
  CodeField,
  Cursor,
  useBlurOnFulfill,
  useClearByFocusCell,
} from 'react-native-confirmation-code-field'

import themedStyles from './InputPIN.Styles'

function InputPIN({
  value,
  setValue,
  cellCount,
  cursorSymbol,
  rootStyles,
  cellStyles,
}) {
  const [styles] = useTheme(themedStyles)

  const ref = useBlurOnFulfill({ value, cellCount: cellCount })
  const [props, getCellOnLayoutHandler] = useClearByFocusCell({
    value,
    setValue,
  })

  return (
    <CodeField
      ref={ref}
      {...props}
      value={value}
      onChangeText={setValue}
      cellCount={cellCount}
      rootStyle={[styles.codeFieldRoot, rootStyles]}
      keyboardType="default"
      textContentType="oneTimeCode"
      renderCell={({ index, symbol, isFocused }) => (
        <Text
          key={index}
          allowFontScaling={false}
          style={[styles.cell, isFocused && styles.focusCell, cellStyles]}
          onLayout={getCellOnLayoutHandler(index)}>
          {symbol || (isFocused ? <Cursor cursorSymbol={cursorSymbol} /> : null)}
        </Text>
      )}
    />
  )
}

InputPIN.defaultProps = {
  cellCount: 5,
  cursorSymbol: '_',
}

InputPIN.propTypes = {
  value: PropTypes.string,
  setValue: PropTypes.func,
  cellCount: PropTypes.number,
  cursorSymbol: PropTypes.string,
  rootStyles: PropTypes.object,
  cellStyles: PropTypes.object,
}

export default InputPIN
