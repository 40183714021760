/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import {
  View,
  Text,
  Animated,
  Dimensions,
  TouchableOpacity,
  StyleSheet,
  Platform,
  PanResponder,
} from 'react-native'

import Icon from '../Icon'

import { colors, images } from '../../Themes'

const { width, height: deviceHeight } = Dimensions.get('window')
const data = [
  { test: 'test' },
  { test: 'test' },
  { test: 'test' },
  { test: 'test' },
  { test: 'test' },
  { test: 'test' },
  { test: 'test' },
  { test: 'test' },
  { test: 'test' },
]
const Backdrop = Animated.createAnimatedComponent(TouchableOpacity)

function Modal({
  isVisible,
  toggleVisible,
  withHeader,
  title,
  children,
}) {
  const scrollY = useRef(new Animated.Value(0)).current
  const containerY = useRef(new Animated.Value(0)).current
  const underlayOpacity = useRef(new Animated.Value(0)).current
  const contentHeight = useRef(new Animated.Value(0)).current
  const offset = useRef(new Animated.Value(0)).current
  const panY = useRef(new Animated.Value(0)).current
  // const panYClamped = Animated.diffClamp(panY, 0, contentHeight._value )
  const translateY = Animated.add(
    offset,
    panY
  )

  const animate = (visible, callback = () => { }) => {
    let nextOffset = 0, nextOpacity = 0.5, containerPosition = 0, containerDelay = 0
    if (!visible){
      nextOffset = contentHeight._value + 150
      nextOpacity = 0
      containerPosition = deviceHeight
      containerDelay = 250
    }
    Animated.parallel([
      Animated.spring(offset, {
        toValue: nextOffset,
        useNativeDriver: true,
      }),
      Animated.spring(panY, {
        toValue: 0,
        useNativeDriver: true,
      }),
      Animated.spring(underlayOpacity, {
        toValue: nextOpacity,
        useNativeDriver: true,
      }),
      Animated.timing(containerY, {
        toValue: containerPosition,
        duration: 100,
        delay: containerDelay,
        useNativeDriver: true,
      }),
    ]).start(() => {
      callback()
    })
  }

  const modalPanResponder = useRef(
    PanResponder.create({
      onMoveShouldSetPanResponder: (_, gestureState) => gestureState.dy > 40,
      onPanResponderMove: Animated.event(
        [null, { dy: panY }],
        { useNativeDriver: false }
      ),
      onPanResponderRelease: () => {
        if (panY._value < contentHeight._value / 3 ) {
          animate(true)
        } else {
          animate(false)
          toggleVisible(false)
        }
      },
    })
  ).current

  useEffect(() => {
    if (isVisible) {
      animate(true)
    }
  }, [isVisible,contentHeight._value])

  const dismiss = () => {
    animate(false)
    toggleVisible(false)
  }

  return (
    <Animated.View
      pointerEvents="box-none"
      style={[
        styles.container,
        {
          // opacity:  containerY,
          transform: [{translateY: containerY}],
        },
      ]}
    >
      <Backdrop
        onPress={dismiss}
        disabled={true}
        activeOpacity={1}
        // eslint-disable-next-line react-native/no-inline-styles
        style={{
          flex: 1,
          backgroundColor: colors.black,
          opacity: underlayOpacity,
          marginBottom: -20,
          position: 'absolute',
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
        }}
      />
      <Animated.View
        style={[styles.modalInner, {transform: [{ translateY: translateY }]}]}
        onLayout={Animated.event(
          [{ nativeEvent: { layout: { height: contentHeight } } }],
          { useNativeDriver: false }
        )}
        {...modalPanResponder.panHandlers}
      >
        {withHeader &&
          <View style={styles.btnsWrapper}>
            <Text style={styles.cancelText}>{title}</Text>
            <TouchableOpacity onPress={dismiss}>
              <Icon source={images.closeIcon} size={24} style={styles.doneText} />
            </TouchableOpacity>
          </View>
        }
        {children}

        <Animated.FlatList
          onScroll={Animated.event(
            [{ nativeEvent: { contentOffset: { x: scrollY } } }],
            { useNativeDriver: true }
          )}
          horizontal
          data={data}
          keyExtractor={(_, index) => 'item-' + index}
          renderItem={({ item, index }) => {
            return (
              <View style={styles.itemWrapper}>
                <Text style={styles.titleText}>{item.test}</Text>
              </View>
            )
          }}
          style={{ width: width }}
          contentContainerStyle={styles.contentContainerStyle}
          decelerationRate={0.8}
          snapToAlignment="center"
          snapToOffsets={[...Array(data.length)].map((_, i) => (i * (width - 30)))}
        />
      </Animated.View>
    </Animated.View>
  )
}

Modal.defaultProps = {
  isVisible: false,
  toggleVisible: () => {},
  withHeader: true,
  title: '',
  children: null,
}

Modal.propTypes = {
  isVisible: PropTypes.bool,
  toggleVisible: PropTypes.func,
  withHeader: PropTypes.bool,
  title: PropTypes.string,
  children: PropTypes.any,
}




const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: 'transparent',
    justifyContent: 'flex-end',
    alignItems: 'stretch',
  },
  modalInner: {
    maxHeight: deviceHeight - Platform.select({ ios: 22, android: 0 }),
    backgroundColor: '#f4f4f4',
    borderRadius: 20,
  },
  btnsWrapper: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderRadius: 50,
  },
  cancelText: {
    color: '#666666',
    padding: 10,
  },
  doneText: {
    color: '#46cf98',
    padding: 10,
    margin: 15,
  },
})

export default Modal

