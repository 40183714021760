import { styleSheetFactory } from '../../Themes/Colors'

const styles = styleSheetFactory(colors => ({
  container: {
    flexGrow: 1,
    flex: 1,
    backgroundColor: colors.background,
  },
  list: {
    flex: 1,
    marginTop: 15,
  },
  inlineButton1: {
    flex: 1,
    height: 50,
    marginRight: 7.5,
    backgroundColor: colors.white,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 7,
    flexDirection: 'row',
  },
  inlineButton2: {
    flex: 1,
    height: 50,
    marginLeft: 7.5,
    backgroundColor: colors.white,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 7,
    flexDirection: 'row',
  },
  buttonIcon: {
    marginRight: 10,
  },
  inlineButtons: {
    flexDirection: 'row',
    marginVertical: 10,
    alignItems: 'center',
    paddingHorizontal: 15,
  },
  inlineButtonsFooter: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 15,
    marginVertical: 15,
  },
  loaderContainer: {
    backgroundColor: colors.white,
    borderBottomColor: colors.separator,
    borderBottomWidth: 1,
    height: 60,
  },
  listRightComponent: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  statusIcon: {
    marginRight: 5,
  },
  separatorStyle: {
    height: 1,
    flex: 1,
    backgroundColor: colors.lightGrey,
  },

  tabView: {
    backgroundColor: colors.background,
    marginTop: 15,
    paddingTop: 0,
  },
  refundButton: {
    height: 50,
    marginHorizontal: 15,
    marginVertical: 10,
  },
  listContainerStyle: {
    flex: 1,
    marginTop: 20,
  },
  content: {
    position: 'relative',
    flexGrow: 1,
    paddingHorizontal: 15,
    paddingVertical: 20,
  },
  separatorContainer: {
    height: 1,
    backgroundColor: colors.tertiaryBackground,
    paddingLeft: 70,
  },
  separator: {
    height: 1,
    backgroundColor: colors.separator,
  },
  fab: {
    position: 'absolute',
    bottom: 225,
    right: 15,
    height: 50,
    width: 50,
    borderRadius: 25,
    backgroundColor: colors.tertiaryAccent,
    justifyContent: 'center',
    alignItems: 'center',
    shadowColor: colors.black,
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.22,
    shadowRadius: 2.22,
    elevation: 3,
  },
  fabIcon: {
    transform: [{ rotate: '-90deg' }],
  },
  invValContainer: {
    marginVertical: 20,
    paddingVertical: 10,
    borderTopColor: colors.separator,
    borderTopWidth: 1,
    justifyContent: 'flex-start',
  },
  qrCode: {
    alignSelf: 'center',
    marginVertical: 25,
    padding: 15,
    backgroundColor: colors.background,
  },
  invoiceButton: {
    marginHorizontal: 15,
  },
  fullButton: {
    height: 50,
    justifyContent: 'flex-start',
    paddingHorizontal: 0,
    marginBottom: 30,
  },
  sectionStyle: {
    marginTop: 20,
  },
  webStyle: {
    overflow: 'hidden',
    height: 0,
  },
}))

const itemStyles = styleSheetFactory(colors => ({
  itemContainer: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    backgroundColor: colors.tertiaryBackground,
    minHeight: 70,
    overflow: 'hidden',
  },
  listImageContainer: {
    width: 70,
    height: 70,
    flexShrink: 0,
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'flex-start',
  },
  listInfoContainer: {
    flexGrow: 1,
    flexShrink: 1,
    paddingRight: 15,
  },
  listPriceContainer: {
    flexShrink: 0,
    marginTop: 5,
  },
  listFirst: {
    borderTopLeftRadius: 7,
    borderTopRightRadius: 7,
  },
  listLast: {
    borderBottomLeftRadius: 7,
    borderBottomRightRadius: 7,
  },
  listDescContainer: {
    flexGrow: 1,
    flexShrink: 1,
    paddingLeft: 10,
    marginVertical: 5,
    justifyContent: 'center',
  },
}))

export { itemStyles }

export default styles
