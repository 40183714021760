/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useMemo, useEffect } from 'react'
import {
  SafeAreaView,
  View,
  TouchableOpacity,
  ImageBackground,
  Keyboard,
  LayoutAnimation,
  Platform,
} from 'react-native'
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs'
import { useTheme } from 'react-native-themed-styles'
import PropTypes from 'prop-types'
import Color from 'color'

import { useUserDetails } from '../../Utils/AuthDetails'
import { usePermissions } from '../../Utils/Permissions'
import ContentManager from '../../Utils/ContentManager'

import {
  Text,
  Loader,
  Icon,
  ProfilePicPicker,
  Header,
  Tabs,
} from '../../Components'

import Information from './Routes/Information'
import Activity from './Routes/Activity'
import Events from './Routes/Events'
import CashRegisters from './Routes/CashRegisters'

import themedStyles from './UserDetails.Styles'

import images from '../../Themes/Images'
import { colors } from '../../Themes'

const Tab = createMaterialTopTabNavigator()

const avatars = {
  maleAvatar: images.maleAvatar,
  femaleAvatar: images.femaleAvatar,
}

const modalColors = {
  gray: colors.gray,
  red: colors.red,
  purple: colors.purple,
  blue: colors.blue,
  green: colors.green,
  yellow: colors.yellow,
}

function ItemPhoto({ selected, name, loading }) {
  const [styles] = useTheme(themedStyles)
  return (
    <View>
      {loading ? <View style={styles.loaderContainer}><Loader size={40} color={colors.gray} /></View>
        : selected ? modalColors.hasOwnProperty(selected) ? <View style={styles.centeredItemsView}>
          <View style={[{
            backgroundColor: Color(modalColors[selected] || colors.red).fade(0.65).toString(),
          }, styles.colorFrame]}>
            <Text
              size="h1"
              color={modalColors[selected] || colors.transparent}
              align="center"
              i18nKey={name?.match(/\b(\w{1})/g)?.slice(0, 2)?.join('')?.toUpperCase() || ''}
            />
          </View>
          <Icon source={images.gallery} size={20} style={styles.galleryIcon} />
        </View> : <View style={styles.imageFrame}>
          <ImageBackground
            source={avatars[selected] || null}
            style={styles.chooseImage} />
          <Icon source={images.gallery} size={20} style={styles.galleryIcon} />
        </View> : <Icon source={images.gallery} size={40} style={styles.gallerySpacing} />
      }
    </View>
  )
}
ItemPhoto.propTypes = {
  selected: PropTypes.string,
  name: PropTypes.string,
  loading: PropTypes.bool,
}

function HeaderPhoto({ keyboardVisibleProps, selected, firstName, lastName, editable, setOpened }) {
  const [styles] = useTheme(themedStyles)

  return (
    <View style={styles.iconHeader}>
      <TouchableOpacity
        style={[styles.addPhotoContainer, { height: keyboardVisibleProps.imageColorHeight, marginBottom: keyboardVisibleProps.marginBottom }]}
        disabled={!editable}
        onPress={() => setOpened(true)}>
        <ItemPhoto selected={selected} name={`${firstName} ${lastName}`} loading={Boolean(!firstName)} />
      </TouchableOpacity>
    </View>
  )
}
HeaderPhoto.propTypes = {
  keyboardVisibleProps: PropTypes.object,
  selected: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  editable: PropTypes.bool,
  setOpened: PropTypes.func,
}

const keyBoardEvents = {
  ios: {
    show: 'keyboardWillShow',
    hide: 'keyboardWillHide',
  },
  android: {
    show: 'keyboardDidShow',
    hide: 'keyboardDidHide',
  },
  web: {},
}

function UserDetails({ route, navigation }) {
  const {
    id,
    goBack = navigation.goBack,
  } = route.params
  const [styles] = useTheme(themedStyles)
  const { role } = useUserDetails()
  const { users = {} } = usePermissions()
  const editable = users.editable.includes(role)
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [opened, setOpened] = useState(false)
  const [selected, setSelected] = useState('')

  const translate = (key) => {
    return ContentManager.translate(key)
  }
  const renderTabs = useMemo(() => {
    return (
      <Tabs
        style={styles.tabView}
        screenOptions={{
          tabBarScrollEnabled: true,
        }}
      >
        <Tab.Screen key={'tab-info'} name={translate('users_profile_tab')}>
          {(props) => (
            <Information
              {...props}
              id={id}
              firstName={firstName}
              setFirstName={setFirstName}
              lastName={lastName}
              setLastName={setLastName}
              selected={selected}
              setSelected={setSelected}
              goBack={goBack}
            />
          )}
        </Tab.Screen>
        <Tab.Screen key={'tab-cash-register'} name={translate('cash_register_user_tab')}>
          {(props) => (
            <CashRegisters
              id={id}
              navigation={navigation}
              {...props}
            />
          )}
        </Tab.Screen>
        <Tab.Screen key={'tab-info'} name={translate('users_acivity_tab')}>
          {(props) => (
            <Activity
              id={id}
              navigation={navigation}
              {...props}
            />
          )}
        </Tab.Screen>
        <Tab.Screen key={'tab-info'} name={translate('users_events_tab')}>
          {(props) => (
            <Events
              id={id}
              navigation={navigation}
              {...props}
            />
          )}
        </Tab.Screen>
      </Tabs>
    )
  }, [firstName, lastName, selected])

  const [keyboardVisibleProps, setKeyboardVisibleProps] = useState({
    imageColorHeight: 110,
    marginBottom: 10,
  })

  useEffect(() => {
    if (Platform.OS === 'android') {
      const showKeyboard = Keyboard.addListener(keyBoardEvents[Platform.OS].show, keyboardDidShow)
      const hideKeyboard = Keyboard.addListener(keyBoardEvents[Platform.OS].hide, keyboardDidHide)
      return () => {
        showKeyboard.remove()
        hideKeyboard.remove()
      }
    }
  }, [])

  const keyboardDidShow = () => {
    LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut)
    setKeyboardVisibleProps({
      imageColorHeight: 0,
      marginBottom: 0,
    })
  }
  const keyboardDidHide = () => {
    LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut)
    setKeyboardVisibleProps({
      imageColorHeight: 110,
      marginBottom: 10,
    })
  }

  return (
    <SafeAreaView style={styles.container} >
      <Header
        title={`${firstName} ${lastName}`}
        image={images.closeIcon}
        loading={Boolean(!firstName)}
        onPress={() => goBack()}
      />
      <HeaderPhoto
        keyboardVisibleProps={keyboardVisibleProps}
        selected={selected}
        firstName={firstName}
        lastName={lastName}
        editable={editable}
        setOpened={setOpened}
      />
      {renderTabs}
      <ProfilePicPicker
        isVisible={opened}
        onClosePress={() => setOpened(false)}
        selected={selected}
        setSelected={setSelected}
      />
    </SafeAreaView>
  )
}
UserDetails.propTypes = {
  route: PropTypes.object,
  navigation: PropTypes.object,
}

export default UserDetails
