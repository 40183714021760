import React from 'react'
import { View } from 'react-native'
import { useTheme } from 'react-native-themed-styles'
import PropTypes from 'prop-types'

import Text from '../Text'

import themedStyles from './Section.Styles'

/**
 * @var shouldRender determines if component or null will be rendered.
 * If @param displayIf won't be provided, shouldRender will be true if either @param title
 * or @param value are truthy, otherwise shouldRender will be the boolean value of displayIf.
 * @param displayException is an array of falsy values we want to consider as truthy.
 * 0 is considered falsy by default. In most of our use cases 0 should be cansidered as truthy,
 * hence the default value of displayException will be [0], if we want to consider it falsy,
 * displayException param should be set to [].
 */

function Item({ title, label, value, index }) {
  const [styles] = useTheme(themedStyles)

  return (
    <>
      {index !== 0 && <View style={styles.separator} />}
      <View style={styles.itemContainer} >
        {title
          ? <Text i18nKey={title} weight="bold" style={styles.title} />
          : (<>
            <Text i18nKey={label} size="footnote" weight="light" style={styles.label} />
            <Text i18nKey={value} size="footnote" align="right" style={styles.value} />
          </>)
        }
      </View>
    </>
  )
}
Item.defaultProps = {
  label: '',
  value: '',

}
Item.propTypes = {
  title: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  index: PropTypes.number,
}

function Section({ data, style }) {
  const [styles] = useTheme(themedStyles)

  const renderItem = (item, index) => (
    <Item key={'itemNr' + index} index={index} {...item} />
  )

  return (
    <View style={[styles.container, style]} >
      {data?.map(renderItem)}
    </View>
  )
}
Section.defaultProps = {
  data: [],
}
Section.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      label: PropTypes.string,
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]),
    })
  ),
  style: PropTypes.object,
}

export default Section
