/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useContext, useEffect } from 'react'
import {
  SafeAreaView,
  View,
  TouchableOpacity,
  ImageBackground,
} from 'react-native'
import PropTypes from 'prop-types'
import { useMutation } from '@apollo/client'
import { useTheme } from 'react-native-themed-styles'
import Color from 'color'

import { getErrorMessage } from '../../Utils'
import { useUserDetails } from '../../Utils/AuthDetails'
import { usePermissions } from '../../Utils/Permissions'
import { CREATE_EMPLOYEE } from '../Users/Users.schema'
import {
  Icon,
  Text,
  ProfilePicPicker,
  Loader,
  Header,
  Modal,
  ModalContent,
} from '../../Components'
import Merchant from '../../Utils/Merchant'

import UserForm from './UserForm'
import { images, colors, animations } from '../../Themes'
import themedStyles from './NewUser.Styles'

const avatars = {
  maleAvatar: images.maleAvatar,
  femaleAvatar: images.femaleAvatar,
}

const modalColors = {
  gray: colors.gray,
  red: colors.red,
  purple: colors.purple,
  blue: colors.blue,
  green: colors.green,
  yellow: colors.yellow,
}

function ItemPhoto({ selected, name, loading }) {
  const [styles] = useTheme(themedStyles)

  return (
    <View>
      {loading ? (
        <View style={styles.loaderComponent}>
          <Loader size={48} source={animations.vfLoaderThinDark} />
        </View>
      ) : selected ? (
        modalColors.hasOwnProperty(selected) ? (
          <View style={styles.imgBackgroundContainer}>
            <View
              style={[
                styles.imageBackground,
                { backgroundColor: Color(modalColors[selected] || colors.red).fade(0.65).toString() },
              ]}>
              <Text
                size="h1"
                color={modalColors[selected] || colors.transparent}
                align="center"
                i18nKey={
                  name
                    ?.match(/\b(\w{1})/g)
                    ?.slice(0, 2)
                    ?.join('')
                    ?.toUpperCase() || ''
                }
              />
            </View>
            <Icon
              source={images.gallery}
              size={20}
              style={styles.gallery}
            />
          </View>
        ) : (
          <View style={styles.imgBackgroundContainer}>
            <ImageBackground
              source={avatars[selected] || null}
              style={styles.imageBackground}
            />
            <Icon source={images.gallery} size={20} />
          </View>
        )
      ) : (
        <Icon source={images.gallery} size={40} style={styles.verticalSpacing} />
      )}
    </View>
  )
}
ItemPhoto.propTypes = {
  selected: PropTypes.string,
  name: PropTypes.string,
  loading: PropTypes.bool,
}

function NewUser(props) {
  const { navigation: {
    goBack,
    navigate,
  } } = props

  const [styles] = useTheme(themedStyles)
  const { user } = useContext(Merchant)

  const { role } = useUserDetails()
  const { users = {} } = usePermissions()
  const editable = users.editable.includes(role)

  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [userEmail, setEmail] = useState('')
  const [userPhone, setPhone] = useState('')
  const [operatorCode, setOperatorCode] = useState('')
  const [opened, setOpened] = useState(false)
  const [selected, setSelected] = useState('')
  const [error1, setError] = useState(false)
  const [location1, setLocation] = useState('')
  const [locationId, setLocationId] = useState('')
  const [disabled, setDisabled] = useState(true)
  const [webAccess, setWebAccess] = useState(false)
  const [apiAccess, setApiAccess] = useState(false)
  const [webAccessRegister, setWebAccessRegister] = useState({})
  const [apiAccessRegister, setApiAccessRegister] = useState({})

  const [isErrorActive, setErrorActive] = useState({
    isVisible: false,
    message: '',
  })

  const regex = /^[a-z]{2}\d{3}[a-z]{2}\d{3}$/

  const [createEmployee, { error, loading, data }] = useMutation(CREATE_EMPLOYEE)


  const createNewEmployee = () => {
    createEmployee({
      variables: {
        firstName: firstName,
        lastName: lastName,
        email: userEmail.trim(),
        defaultLocation: locationId,
        defaultLocationName: location1,
        phoneNumber: `+355${userPhone}`,
        operatorCode: operatorCode,
        color: selected || colors.gray,
        parentId: user.sub,
        webAccessCashRegisterId: webAccessRegister?.id ? webAccessRegister?.id : null,
        apiAccessCashRegisterId: apiAccessRegister?.id ? apiAccessRegister?.id : null,
      },
    })
      .then(() => {
        goBack()
        setError(false)
      })
  }

  useEffect(() => {
    if (data) {

    }
  }, [data])

  useEffect(() => {
    // if (error) {
    //   setErrorActive({
    //     isVisible: true,
    //     message: error,
    //   })
    // }
    if (error) {
      if (
        error?.graphQLErrors?.[0]?.errorType === 'EmployeeUserAlreadyExist'
      ) {
        setError(true)
      } else {
        setErrorActive({
          isVisible: true,
          message: error,
        })
      }
    }
  }, [error])

  const checkInputs = () => {
    if (
      firstName === '' ||
      lastName === '' ||
      userEmail === '' ||
      userPhone === '' ||
      operatorCode === '' ||
      location1 === '' ||
      !regex.test(operatorCode) ||
      error1
    ) {
      setDisabled(true)
    } else {
      setDisabled(false)
    }
  }

  useEffect(() => {
    checkInputs()
  }, [firstName, lastName, userEmail, userPhone, operatorCode, locationId, selected , error1])

  const changeLocation = (location) => {
    setLocation(location?.businessName)
    setLocationId(location?.id)
  }

  return (
    <SafeAreaView style={styles.container}>
      <Header
        title="header_new_user"
        image={images.closeIcon}
        onPress={() => goBack()}
      />
        <View style={styles.addPhotoWrapper}>
          <TouchableOpacity
            style={styles.addPhotoContainer}
            onPress={() => setOpened(true)}>
            <ItemPhoto
              selected={selected}
              name={`${firstName} ${lastName}`}
              loading={false}
            />
          </TouchableOpacity>
        </View>
        <UserForm
          firstName={firstName}
          setFirstName={setFirstName}
          lastName={lastName}
          setLastName={setLastName}
          userEmail={userEmail}
          setEmail={setEmail}
          userPhone={userPhone}
          setPhone={setPhone}
          operatorCode={operatorCode}
          setOperatorCode={setOperatorCode}
          status="users_pending_tag"
          role="users_seller_label"
          loading1={false}
          locationId={locationId}
          changeLocation={changeLocation}
          location1={location1}
          loading={loading}
          cashLoading={false}
          items={[]}
          editable={editable}
          ListEmptyComponent={null}
          disabled={disabled}
          setDisabled={setDisabled}
          error1={error1}
          setError={setError}
          onSave={() => {
            setDisabled(true)
            createNewEmployee()
          }}
          user={user}
          goBack={goBack}
          navigate={navigate}
          webAccessRegister={webAccessRegister}
          apiAccessRegister={apiAccessRegister}
          setWebAccessRegister={setWebAccessRegister}
          setApiAccessRegister={setApiAccessRegister}
          webAccess={webAccess}
          setWebAccess={setWebAccess}
          apiAccess={apiAccess}
          setApiAccess={setApiAccess}

        />
        <ProfilePicPicker
          isVisible={opened}
          onClosePress={() => setOpened(false)}
          selected={selected}
          setSelected={setSelected}
        />
      <Modal
        isVisible={isErrorActive.isVisible}
        onBackButtonPress={() => setErrorActive({
          isVisible: false,
          message: '',
        })}
        onBackdropPress={() => setErrorActive({
          isVisible: false,
          message: '',
        })}
        animationIn="fadeIn"
        animationOut="fadeOut"
      >
        <ModalContent onClose={() => setErrorActive({
          isVisible: false,
          message: '',
        })}>
          <Icon source={images.delete} style={styles.errorIcon} />
          <Text i18nKey="certificate_error_label" color={colors.accent} size="h5" align="center" weight="bold" style={styles.errorTitle} />
          <Text i18nKey={getErrorMessage(isErrorActive.message)} align="center" />
        </ModalContent>
      </Modal>
    </SafeAreaView>
  )
}
NewUser.propTypes = {
  navigation: PropTypes.object,
}

export default NewUser
