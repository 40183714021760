import { styleSheetFactory } from '../../Themes/Colors'

const styles = styleSheetFactory(colors => ({
  container: {
    flex: 1,
    backgroundColor: colors.background,
    position: 'relative',
  },
  emptyComponent: {
    alignSelf: 'center',
    marginTop: 30,
  },
  errorIcon: {
    alignSelf: 'center',
    marginBottom: 5,
  },
  errorTitle: {
    marginVertical: 8,
  },
  headerStyles: {
    marginBottom: 5,
  },
  listSeparatorStyle: {
    height: 1,
    backgroundColor: colors.separator,
  },
  searchContainer: {
    position: 'absolute',
    top: 50,
    left: 0,
    right: 0,
  },
  tagListContainer: {
    paddingHorizontal: 0,
  },
  eInvoiceList: {
    marginTop: 5,
  },
  allInvoiceList: {
    marginTop: 15,
  },
  myEinvoiceView: {
    backgroundColor: colors.background,
    marginTop: 60,
    flex: 1,
  },
  sectionTitle: {
    marginBottom: 10,
    marginTop: 15,
  },
}))

const itemStyles = styleSheetFactory(colors => ({
  myContainer: {
    backgroundColor: colors.tertiaryBackground,
    height: 70,
    justifyContent: 'center',
    borderTopWidth: 1,
    borderTopColor: colors.separator,
  },
  myFirstItem_true: {
    borderTopLeftRadius: 7,
    borderTopRightRadius: 7,
    borderTopWidth: 0,
  },
  lastItem_true: {
    borderBottomLeftRadius: 7,
    borderBottomRightRadius: 7,
  },
  touchable: {
    flexGrow: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  textContainer: {
    marginHorizontal: 15,
    flexGrow: 1,
    flexShrink: 1,
    flexDirection: 'row',
    alignItems: 'center',
  },
  leftData: {
    marginLeft: 15,
    flexGrow: 1,
    flexShrink: 1,
  },
  rightComponent: {
    flexDirection: 'row',
    alignItems: 'center',
    flexShrink: 0,
    marginRight: 15,
  },
  rightData: {
    marginRight: 5,
    alignItems: 'flex-end',
  },
  rowView: {
    flexDirection: 'row',
    alignItems: 'center',
  },
}))

const hiddenItemStyles = styleSheetFactory(colors => ({
  wrapper: {
    backgroundColor: colors.accent,
    height: 70,
    justifyContent: 'center',
    alignItems: 'flex-end',
    overflow: 'hidden',
  },
  option: {
    width: 100,
    height: 70,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: colors.accent,
  },
  optionFull: shouldAccept => ({
    flexDirection: 'row',
    backgroundColor: shouldAccept ? colors.green : colors.accent,
  }),
  optionFulll: shouldAccept => ({
    backgroundColor: shouldAccept ? colors.green : colors.accent,
  }),
  confirm: {
    flexGrow: 1,
    height: 70,
    flexDirection: 'row',
    paddingLeft: 15,
    alignItems: 'center',
  },
  confirmText: {
    marginLeft: 15,
  },
  cancel: {
    width: 40,
    height: 40,
    flexShrink: 0,
    margin: 15,
    borderRadius: 20,
    backgroundColor: colors.whiteRGBA(0.55),
    justifyContent: 'center',
    alignItems: 'center',
  },
  loader: {
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  hiddenItem: {
    width: 100,
    height: 70,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: colors.green,
  },
  firstItem_true: {
    borderTopLeftRadius: 7.5,
    borderTopRightRadius: 7.5,
  },
  lastItem_true: {
    borderBottomLeftRadius: 7.5,
    borderBottomRightRadius: 7.5,
  },
  options: {
    flexDirection: 'row',
  },
}))

const myHiddenItemStyles = styleSheetFactory(colors => ({
  wrapper: {
    backgroundColor: colors.accent,
    height: 70,
    justifyContent: 'center',
    alignItems: 'flex-end',
  },
  option: {
    width: 100,
    height: 70,
    justifyContent: 'center',
    alignItems: 'center',
  },
  optionFull: {
    flexDirection: 'row',
  },
  confirm: {
    flexGrow: 1,
    height: 70,
    flexDirection: 'row',
    paddingLeft: 15,
    alignItems: 'center',
  },
  confirmText: {
    marginLeft: 15,
  },
  cancel: {
    width: 40,
    height: 40,
    flexShrink: 0,
    margin: 15,
    borderRadius: 20,
    backgroundColor: colors.whiteRGBA(0.55),
    justifyContent: 'center',
    alignItems: 'center',
  },
  loader: {
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  firstItem_true: {
    borderTopLeftRadius: 7.5,
    borderTopRightRadius: 7.5,
  },
  lastItem_true: {
    borderBottomLeftRadius: 7.5,
    borderBottomRightRadius: 7.5,
  },
}))

const routeStyles = styleSheetFactory(colors => ({
  emptyComponent: {
    alignSelf: 'center',
    flexGrow: 1,
    alignItems: 'center',
    marginTop: 30,
  },

}))

export {
  itemStyles,
  hiddenItemStyles,
  routeStyles,
  myHiddenItemStyles,
}

export default styles
