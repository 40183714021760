/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState, useEffect, useLayoutEffect } from 'react'
import {
  LayoutAnimation,
  View,
  TouchableOpacity,
  Dimensions,
  ScrollView,
} from 'react-native'
import { useTheme } from 'react-native-themed-styles'
import PropTypes from 'prop-types'
import ContentLoader, { Rect } from 'react-content-loader/native'
import { useMutation, useQuery } from '@apollo/client'
import { useDispatch, useSelector } from 'react-redux'

import { updateStep, updateCalculatedUBL, updatePaymentAttribute } from '../../Payment.Actions'
import { GET_BUSINESS_DETAILS, SAVE_EINVOICE } from '../../Payment.Schema'
import {
  getBuyerData,
  getSellerData,
  getInvoiceId,
  getEinvoiceCalculated,
} from '../../Payment.Selectors'

import {
  Button,
  Icon,
  Text,
  Section,
} from '../../../../Components'
import {
  useUserDetails,
  useLocationDetails,
  useConfig,
} from '../../../../Utils'
import Theme, { opacityConfig } from '../../../../Themes/Theme'
import images from '../../../../Themes/Images'

import themedStyles from './ThirdStep.Styles'

const { width } = Dimensions.get('screen')

function LoaderContent({ isFirst, isLast, style }) {
  const [styles] = useTheme(themedStyles)
  const loaderStyle = {}
  if (isFirst) {
    loaderStyle.borderTopLeftRadius = 7
    loaderStyle.borderTopRightRadius = 7
  }
  if (isLast) {
    loaderStyle.borderBottomLeftRadius = 7
    loaderStyle.borderBottomRightRadius = 7
    loaderStyle.borderBottomWidth = 0
  }
  return (
    <ContentLoader
      speed={0.7}
      height={70}
      style={Object.assign({}, styles.loaderContainer, loaderStyle, style)}
      backgroundColor="#e2e2e2"
      foregroundColor="#ecebeb">
      <Rect x="20" y="20" rx="3" ry="3" width="90" height="20" />
      <Rect x={`${width - 150}`} y="18" rx="3" ry="3" width="100" height="24" />
    </ContentLoader>
  )
}
LoaderContent.propTypes = {
  isFirst: PropTypes.bool,
  isLast: PropTypes.bool,
  style: PropTypes.object,
}

function SingleLoaderContent({ isFirst, isLast, style }) {
  const loaderStyle = {}
  if (isFirst) {
    loaderStyle.borderTopLeftRadius = 7
    loaderStyle.borderTopRightRadius = 7
  }
  if (isLast) {
    loaderStyle.borderBottomLeftRadius = 7
    loaderStyle.borderBottomRightRadius = 7
  }
  return (
    <ContentLoader
      speed={0.7}
      height={40}
      width={200}
      style={Object.assign({}, loaderStyle, style)}
      backgroundColor="#e2e2e2"
      foregroundColor="#ecebeb">
      <Rect x="0" y="10" rx="3" ry="3" width="120" height="20" />
    </ContentLoader>
  )
}
SingleLoaderContent.propTypes = {
  isFirst: PropTypes.bool,
  isLast: PropTypes.bool,
  style: PropTypes.object,
}

function ItemLoader() {
  return (
    <View>
      <SingleLoaderContent />
      <LoaderContent isFirst />
      <LoaderContent />
      <LoaderContent />
      <LoaderContent />
      <LoaderContent />
      <LoaderContent />
      <LoaderContent isLast />
    </View>
  )
}
function ThirdStepP12({ navigate }) {
  const { switchLocation: { deviceId, locationId } } = useConfig()

  const [styles] = useTheme(themedStyles)
  const { colors } = useContext(Theme)

  const { fullName, email, phoneNumber } = useUserDetails()
  const { NIPT, name } = useLocationDetails()

  const storedBuyer = useSelector(getBuyerData)
  const storedSeller = useSelector(getSellerData)
  const einvoiceId = useSelector(getInvoiceId)
  const calculatedUBL = useSelector(getEinvoiceCalculated)

  const dispatchAction = useDispatch()

  const [sellerItems, setSellerItems] = useState([])
  const [buyerItems, setBuyerItems] = useState([])
  const [buyerDetails, setBuyerDetails] = useState(storedBuyer)
  const [error, setError] = useState()

  const setPaymentStatus = (value) => dispatchAction(updatePaymentAttribute('paymentStatus', value))

  const {
    loading: sellerLoading,
    error: govBusinessError,
    refetch,
  } = useQuery(GET_BUSINESS_DETAILS, {
    variables: {
      name: 'businessTin',
      value: NIPT,
    },
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      const govBusiness = res?.getBusinessGov?.customers
      if (Array.isArray(govBusiness)) {
        govBusiness?.filter(item => item?.issuerNUIS === NIPT)
        mapSeller(govBusiness?.[0] || {})
      }
    },
    skip: Boolean(storedSeller?.issuerNUIS),
  })

  const [saveEinvoice, { loading }] = useMutation(SAVE_EINVOICE)

  const mapSeller = ({
    issuerNUIS,
    address,
  }) => {
    dispatchAction(updateStep('seller', {
      issuerNUIS,
      name: fullName,
      address: {
        streetName: address?.addressLine1,
        countryCode: address?.country,
        postalCode: address?.postalCode,
        cityCode: address?.administrativeDistrictLevel1,
      },
      taxSchema: 'VAT',
      registrationName: name,
      contact: {
        name: null,
        telephone: phoneNumber,
        electronicMail: email,
      },
    }))
  }
  const mapSellerItems = (seller) => {
    const response = []
    const address = seller?.address
    response.push(
      {
        label: 'einvoice_details_nipt_label',
        value: seller?.issuerNUIS,
      },
      {
        label: 'einvoice_details_name_label',
        value: fullName,
      },
      {
        label: 'einvoice_commercial_name_label',
        value: name,
      },
      {
        label: 'einvoice_details_address_label',
        value: address?.streetName,
      },
      {
        label: 'einvoice_city_postalcode_label',
        value: `${address?.cityCode} ${address?.postalCode || ''}`,
      },
      {
        label: 'einvoice_details_state_label',
        value: address?.countryCode,
      },
      {
        label: 'users_email_label',
        value: email,
      },
      {
        label: 'users_phone_label',
        value: phoneNumber,
      },
    )
    return response
  }
  const mapBuyer = ({
    issuerNUIS,
    businessName,
    address,
    phoneNumber: phone,
    emailAddress,
  }) => {
    dispatchAction(updateStep('buyer', {
      issuerNUIS,
      name: businessName,
      address: {
        streetName: address?.addressLine1,
        countryCode: address?.country,
        postalCode: address?.postalCode,
        cityCode: address?.administrativeDistrictLevel1,
      },
      taxSchema: 'VAT',
      registrationName: businessName,
      contact: {
        name: null,
        telephone: phone,
        electronicMail: emailAddress,
      },
    }))
  }
  const mapBuyerItems = (buyer) => {
    const response = []
    const buyerAddress = buyer?.address
    response.push(
      {
        label: 'einvoice_details_nipt_label',
        value: buyer?.issuerNUIS,
      },
      {
        label: 'einvoice_commercial_name_label',
        value: buyer?.name,
      },
      {
        label: 'einvoice_details_address_label',
        value: buyerAddress?.streetName,
      },
      {
        label: 'einvoice_details_city_label',
        value: `${buyerAddress?.cityCode} ${buyerAddress?.postalCode || ''}`,
      },
      {
        label: 'einvoice_details_state_label',
        value: buyerAddress?.countryCode,
      },
      {
        label: 'users_email_label',
        value: buyer?.contact?.electronicMail,
      },
      {
        label: 'users_phone_label',
        value: buyer?.contact?.telephone,
      },
    )
    return response
  }

  useLayoutEffect(() => {
    if (storedSeller?.issuerNUIS) {
      LayoutAnimation.configureNext(opacityConfig)
      setSellerItems(mapSellerItems(storedSeller))
    }
  }, [storedSeller])

  useEffect(() => {
    if (govBusinessError) {
      setBuyerDetails({})
      setError({
        message: 'einvoice_payment_buyer_seller_error_note',
        onPress: () => {
          refetch()
        },
      })
    }
  }, [govBusinessError])

  useLayoutEffect(() => {
    if (storedBuyer?.issuerNUIS) {
      LayoutAnimation.configureNext(opacityConfig)
      setBuyerItems(mapBuyerItems(storedBuyer))
    }
  }, [storedBuyer])

  const getBuyerGov = (buyer) => {
    setBuyerDetails(buyer)
    mapBuyer(buyer || {})
  }

  const onButtonPress = (step) => {
    LayoutAnimation.configureNext(opacityConfig)
    setPaymentStatus(step)
  }

  const nextToReferences = () => {
    if (einvoiceId) {
      const newUbl = {
        ...calculatedUBL,
        supplierParty: {
          issuerNUIS: storedSeller.issuerNUIS,
          name: storedSeller.name,
          address: {
            streetName: storedSeller.address.streetName,
            cityCode: storedSeller.address.cityCode,
            countryCode: storedSeller.address.countryCode,
            postalCode: storedSeller.address.postalCode,
          },
          taxSchema: storedSeller.taxSchema,
          registrationName: storedSeller.registrationName,
          contact: storedSeller.contact,
        },
        customerParty: {
          issuerNUIS: storedBuyer.issuerNUIS,
          name: storedBuyer.name,
          address: {
            streetName: storedBuyer.address.streetName,
            cityCode: storedBuyer.address.cityCode,
            countryCode: storedBuyer.address.countryCode,
            postalCode: storedBuyer.address.postalCode,
          },
          taxSchema: storedBuyer.taxSchema,
          registrationName: storedBuyer.registrationName,
          contact: storedBuyer.contact,
        },
      }
      dispatchAction(updateCalculatedUBL(newUbl))
      saveEinvoice({
        variables: {
          locId: locationId,
          cashRegisterId: deviceId,
          eInvoiceInput: {
            ublInvoice: newUbl,
          },
        },
      })
        .then(() => {
          setPaymentStatus('references')
        })
    }
  }

  return (
    <>
      <ScrollView
        contentContainerStyle={styles.content}
      >
        {error ? <View style={styles.errorContainer}>
          <Icon style={styles.icon} size={35} source={images.attention} />
          <Text align={'center'} i18nKey={error.message} />
          <Text align={'center'} i18nKey={'orders_try_again'} weight={'bold'} style={styles.errorRetry} onPress={error.onPress} />
        </View> : sellerLoading ? <ItemLoader /> : <>
          <Text i18nKey="einvoice_seller_filter" weight="bold" />
          <Section data={sellerItems} />
        </>}
        {/* {buyerDetails?.issuerNUIS &&
          <TouchableOpacity style={styles.removeBuyer} onPress={() => {
            dispatchAction(updateStep('buyer', {}))
            setBuyerDetails({})
            LayoutAnimation.configureNext(opacityConfig)
            setBuyerItems()
          }}>
            <Icon source={images.closeIcon} color={colors.secondaryIcon} size={18} />
          </TouchableOpacity>
        } */}
        {!buyerDetails?.issuerNUIS ? null :
          <>
            <View style={styles.customerHeader}>
              <Text i18nKey="einvoice_buyer_filter" weight="bold" />
              <TouchableOpacity style={styles.removeBuyer} onPress={() => {
                dispatchAction(updateStep('buyer', {}))
                setBuyerDetails({})
                LayoutAnimation.configureNext(opacityConfig)
                setBuyerItems()
              }}>
                <Icon source={images.closeIcon} color={colors.secondaryIcon} size={18} />
              </TouchableOpacity>
            </View>
            <Section data={buyerItems} />
          </>
        }
        {(!buyerDetails?.issuerNUIS || govBusinessError) && <Button
          title="einvoice_add_buyer_button"
          variant={'secondary'}
          style={styles.addCustomer}
          onPress={() => navigate('AllCustomers', {
            selectable: true,
            preselectedCustomer: buyerDetails,
            updateSelection: getBuyerGov,
            showSecondTab: false,
            isSupplier: false,
          })}
        />}

      </ScrollView>
      <View style={styles.buttons}>
        <Button
          title="einvoice_section_process"
          variant={'preActive'}
          icon={images.arrowLeft}
          iconColor={colors.accent}
          style={styles.rightButton}
          onPress={() => onButtonPress('process')}
        />
        <Button
          title="einvoice_references_button"
          variant={Boolean(storedSeller?.issuerNUIS) && Boolean(storedBuyer?.issuerNUIS) && !loading ? 'active' : 'disabled'}
          rightComponent={!loading && <Icon
            size={20}
            source={images.arrowRight}
            color={colors.white}
            style={styles.leftSpacing}
          />}
          loader={loading}
          onPress={nextToReferences}
          style={styles.leftButton}
        />
      </View>
    </>
  )

}
ThirdStepP12.propTypes = {
  navigate: PropTypes.func,
}

export default ThirdStepP12

