import {
    PRODUCT_CATALOG_RESET_LOCATION,
    PRODUCT_CATALOG_UPDATE_LOCATION,
} from './ProductCatalog.Actions'

const ProductCatalogInitialState = {}

function productCatalog(
    state = ProductCatalogInitialState,
    action
) {
    const { type, value } = action || {}

    switch (type) {
        case PRODUCT_CATALOG_UPDATE_LOCATION:
            return { ...value }
        case PRODUCT_CATALOG_RESET_LOCATION:
            return ProductCatalogInitialState
        default:
            return state
    }

}

export default productCatalog
