import { styleSheetFactory } from '../../Themes/Colors'

const styles = styleSheetFactory(colors => ({

    container: (color) => {
        return {
            backgroundColor: color,
            paddingHorizontal: 5,
            borderRadius: 10,
        }
    },

}))

export default styles
