import React from 'react'
import { useTheme } from 'react-native-themed-styles'
import PropTypes from 'prop-types'
import { View } from 'react-native'

import { Button, Option } from '../../../../../Components'

import { images } from '../../../../../Themes'

import themedStyles from './Footer.Styles'

function Footer({
    onOptionPress,
    onButtonPress,
    title,
    loading,
    ...rest
}) {

    const [styles] = useTheme(themedStyles)

    return (
        <View style={styles.buttons}>
            {onOptionPress && <Option
                icon={images.bookmark}
                disabled={loading}
                style={styles.leftButton}
                onPress={onOptionPress}
            />}
            <Button
                title={title}
                style={styles.rightButton}
                onPress={onButtonPress}
                {...rest}
            />
        </View>
    )
}
Footer.defaultProps = {
    title: 'payment_proceed_button',
}

Footer.propTypes = {
    loading: PropTypes.bool,
    onOptionPress: PropTypes.func,
    onButtonPress: PropTypes.func,
    title: PropTypes.string,
    buttonProps: PropTypes.object,
}

export default Footer

