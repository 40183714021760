import React from 'react'
import { ScrollView } from 'react-native'
import { useTheme } from 'react-native-themed-styles'
import PropTypes from 'prop-types'

import { Section } from '../../../../Components'

import themedStyles from '../MyEInvoiceDetails.Styles'

function BuyerSeller({buyer, seller}) {
  const [styles] = useTheme(themedStyles)

  const mapData = (title, data = {}) => ([
    {
      title,
    },
    {
      label: 'einvoice_details_nipt_label',
      value: data.issuerNUIS,
    },
    {
      label: 'einvoice_details_name_label',
      value: data.name,
    },
    {
      label: 'einvoice_commercial_name_label',
      value: data.registrationName,
    },
    {
      label: 'einvoice_details_address_label',
      value: data.address?.streetName,
    },
    {
      label: 'einvoice_city_postalcode_label',
      value: `${data?.address?.cityCode || ''}, ${data?.address?.postalCode || ''}`,
    },
    {
      label: 'einvoice_details_state_label',
      value: data?.address?.countryCode,
    },
    {
      label: 'users_email_label',
      value: data?.contact?.electronicMail,
    },
    {
      label: 'users_phone_label',
      value: data?.contact?.telephone,
    },
  ])

  const sellerData = mapData('einvoice_seller_filter', seller)
  const buyerData = mapData('einvoice_buyer_filter', buyer)

  return (
    <ScrollView
      style={styles.container}
      contentContainerStyle={styles.content}
      showsVerticalScrollIndicator={false}
    >
      <Section data={sellerData} />
      <Section data={buyerData} />
    </ScrollView>
  )
}
BuyerSeller.propTypes = {
  buyer: PropTypes.object,
  seller: PropTypes.object,
}

export default BuyerSeller
