import React, { useState } from 'react'
import {
  SafeAreaView,
  View,
  ScrollView,
  Image,
  TouchableOpacity,
} from 'react-native'
import PropTypes from 'prop-types'
import { useMutation } from '@apollo/client'
import { useSelector } from 'react-redux'

import {
  InputRow,
  Loader,
  Text,
  Button,
  Header,
  Modal,
  Icon,
  ModalContent,
} from '../../Components'

import { SelectColor } from '../../Components/ImageColorPicker/ImageColorPicker'
import { getProductCatalog } from '../ProductCatalog/ProductCatalog.Selectors'


import { getErrorMessage, useConfig } from '../../Utils'

import styles from './NewCategory.Styles'
import colors from '../../Themes/Colors'

import { CREATE_CATEGORY, EDIT_CATEGORY } from './NewCategory.schema'
import images, { animations } from '../../Themes/Images'

const Color = require('color')

function NewCategory({
  route: {
    params: {
      categoryItem = {},
      onSave = () => { },
    } = {},
  } = {},
  navigation: {
    goBack,
  },
}) {

  const { switchLocation: { deviceId, locationId:locId } } = useConfig()

  const [category, setCategory] = useState({ ...categoryItem })
  const [isSelectColorVisible, setSelectColorVisibility] = useState(false)

  const { locationId, businessName, address } = useSelector(getProductCatalog)


  const [addCategory, { loading: creating }] = useMutation(CREATE_CATEGORY)
  const [updateCategory, { loading: updating }] = useMutation(EDIT_CATEGORY)

  const [modal, setModal] = useState({
    isVisible: false,
    icon: images.warningIcon,
    title: '',
    desc: '',
  })
  const openModal = (
    desc = '',
    title = 'unit_general_error',
    icon = images.warningIcon
  ) => {
    setModal({
      isVisible: true,
      icon: icon,
      title: title,
      desc: desc,
    })
  }
  const closeModal = () => {
    setModal({
      isVisible: false,
      icon: images.delete,
      title: '',
      desc: '',
    })
  }


  const submitForm = () => {
    if (!category.name) {
      // displayToast('categories_attention_name_required', images.attention)
      return
    }
    if (!categoryItem.id) {
      addCategory({
        variables: {
          ...category,
          locId: locId,
          cashRegisterId: deviceId,
          locationId: locationId ? locationId : null,
        },
      }).then((data) => {
        // displayToast('categories_add_success')
        const { createCategory } = data.data
        onSave({ ...createCategory })
        goBack()
      }).catch(e => {
        openModal(getErrorMessage(e))
        return
      })
    } else {
      updateCategory({
        variables: {
          ...category,
          locId: locId,
          cashRegisterId: deviceId,
          locationId: locationId ? locationId : null,
        },
      }).then((data) => {
        // displayToast('categories_update_success')
        const { createCategory } = data.data
        onSave({ ...createCategory })
        goBack()
      }).catch(e => {
        openModal(getErrorMessage(e))
        return
      })
    }
  }

  const onColorSelect = (color) => {
    setSelectColorVisibility(false)
    setCategory({
      ...category,
      labelColor: color,
    })
  }

  let textBgColor = ''
  if (category.labelColor) {
    const textColor = Color(category.labelColor)
    textBgColor = textColor.fade(0.85)
  }

  return (
    <SafeAreaView style={styles.container}>
      <Header
        title={Object.keys(categoryItem).length ? 'header_edit_category' : 'header_new_category'}
        image={images.closeIcon}
        onPress={goBack}
        customBusinessName={businessName}
        customAddress={address}
      />
      <ScrollView style={styles.scrollContainer}>
        <TouchableOpacity
          style={[styles.imagesColorContainer, category.labelColor ? { backgroundColor: textBgColor } : {}]}
          onPress={() => setSelectColorVisibility(true)}
        >
          <View style={styles.color}>
            {!category.labelColor && <Image source={images.palette} style={styles.colorIcon} />}
            {category.labelColor && <Text size="h2" color={category.labelColor} i18nKey={category?.name?.match(/\b(\w{1})/g)?.slice(0, 2)?.join('')?.toUpperCase() || ''} />}
          </View>
        </TouchableOpacity>

        <View style={styles.inputContainer}>
          <InputRow
            label="categories_name_label"
            placeholder="categories_name_placeholder"
            value={category.name}
            onChangeText={text => setCategory({ ...category, name: text })}
          />
          <Button
            title={'discount_button_save'}
            style={styles.primaryButton}
            onPress={submitForm}
            variant={(category.name && !creating && !updating) ? 'active' : 'disabled'}
            loader={creating || updating}
            loaderComponent={<Loader size={32} source={animations.vfLoaderThinDark} />}
          />
        </View>
      </ScrollView>
      <SelectColor
        isVisible={isSelectColorVisible}
        close={() => setSelectColorVisibility(false)}
        selectColor={onColorSelect}
      />
      <Modal
        isVisible={modal.isVisible}
        onBackButtonPress={() => closeModal()}
        onBackdropPress={() => closeModal()}
        animationIn="fadeIn"
        animationOut="fadeOut"
      >
        <ModalContent onClose={() => closeModal()} contentContainerStyle={styles.modalContent}>
          <Icon source={modal.icon} style={styles.modalIcon} size={40} />
          <Text
            i18nKey={modal.title}
            color={colors.primaryText}
            size="h5"
            align="center"
            weight="bold"
            style={styles.modalTitle}
          />
          <Text i18nKey={modal.desc} align="center" />
        </ModalContent>
      </Modal>
    </SafeAreaView>
  )
}

NewCategory.defaultProps = {
  edit: false,
}
NewCategory.propTypes = {
  route: PropTypes.object,
  navigation: PropTypes.object,
}

export default NewCategory
