const CITIES = [
  'Tirana',
  'Durrësi',
  'Shkodra',
  'Elbasani',
  'Vlora',
  'Korça',
  'Fieri',
  'Berati',
  'Lushnja',
  'Pogradeci',
  'Kavaja',
  'Laçi',
  'Lezha',
  'Kukësi',
  'Gjirokastra',
  'Patosi',
  'Kruja',
  'Kuçova',
  'Saranda',
  'Peshkopia',
  'Burreli',
  'Cërriku',
  'Çorovoda',
  'Shijaku',
  'Librazhdi',
  'Tepelena',
  'Gramshi',
  'Poliçani',
  'Bulqiza',
  'Përmeti',
  'Fushë-Kruja',
  'Kamëz',
  'Rrësheni',
  'Ballshi',
  'Mamurrasi',
  'Bajram Curri',
  'Erseka',
  'Peqini',
  'Divjaka',
  'Selenica',
  'Bilishti',
  'Roskoveci',
  'Këlcyra',
  'Puka',
  'Memaliaj',
  'Rrogozhina',
  'Ura Vajgurore',
  'Himara',
  'Delvina',
  'Vora',
  'Kopliku',
  'Maliqi',
  'Përrenjasi',
  'Shtërmeni',
  'Kruma',
  'Libohova',
  'Orikumi',
  'Fushë-Arrëza',
  'Shëngjini',
  'Rubiku',
  'Miloti',
  'Leskoviku',
  'Konispoli',
  'Krasta',
  'Kërraba',
]

const TRANSPORT_OWNERSHIP_TYPES = [
  {
    code: 'OWNER',
    description: 'transaction_type_sales_description',
    type: 'OWNER',
  },
  {
    code: 'THIRDPARTY',
    description: 'transaction_type_examination_description',
    type: 'THIRDPARTY',
  },
]

const START_POINT = [
  {
    code: 'WAREHOUSE',
    description: 'supporting_invoice_start_point_warehouse',
    type: 'WAREHOUSE',
  },
  {
    code: 'EXHIBITION',
    description: 'supporting_invoice_start_point_exhibition',
    type: 'EXHIBITION',
  },
  {
    code: 'STORE',
    description: 'supporting_invoice_start_point_store',
    type: 'STORE',
  },
  {
    code: 'SALE',
    description: 'supporting_invoice_start_point_sale',
    type: 'SALE',
  },
  {
    code: 'ANOTHER',
    description: 'supporting_invoice_start_point_another',
    type: 'ANOTHER',
  },
  {
    code: 'CUSTOMS',
    description: 'supporting_invoice_start_point_customs',
    type: 'CUSTOMS',
  },
  {
    code: 'OTHER',
    description: 'supporting_invoice_start_point_other',
    type: 'OTHER',
  },
]

const DESTINATION_POINT = [
  {
    code: 'WAREHOUSE',
    description: 'supporting_invoice_start_point_warehouse',
    type: 'WAREHOUSE',
  },
  {
    code: 'EXHIBITION',
    description: 'supporting_invoice_start_point_exhibition',
    type: 'EXHIBITION',
  },
  {
    code: 'STORE',
    description: 'supporting_invoice_start_point_store',
    type: 'STORE',
  },
  {
    code: 'SALE',
    description: 'supporting_invoice_start_point_sale',
    type: 'SALE',
  },
  {
    code: 'OTHER',
    description: 'supporting_invoice_start_point_other',
    type: 'OTHER',
  },
]

const SELECT_OPTIONS = [
  {
    title: 'supportive_invoice_yes_option',
    type: true,
  },
  {
    title: 'supportive_invoice_no_option',
    type: false,
  },
]

const ID_TYPES = [
  {
    code: 'NUIS',
    description: 'id_type_nuis_description',
    type: 'NUIS',
  },
  {
    code: 'ID',
    description: 'id_type_id_description',
    type: 'ID',
  },
]

const INVOICE_TYPES = [
  {
    code: 'WTN',
    description: 'invoice_type_wtn_description',
    type: 'WTN',
  },
  {
    code: 'SALE',
    description: 'invoice_type_sale_description',
    type: 'SALE',
  },
]

const TRANSACTION_TYPES = [
  {
    code: 'SALES',
    description: 'transaction_type_sales_description',
    type: 'SALES',
  },
  {
    code: 'EXAMINATION',
    description: 'transaction_type_examination_description',
    type: 'EXAMINATION',
  },
  {
    code: 'TRANSFER',
    description: 'transaction_type_transfer_description',
    type: 'TRANSFER',
  },
  {
    code: 'DOOR',
    description: 'transaction_type_door_description',
    type: 'DOOR',
  },
]

export {
  CITIES,
  TRANSPORT_OWNERSHIP_TYPES,
  START_POINT,
  DESTINATION_POINT,
  SELECT_OPTIONS,
  ID_TYPES,
  INVOICE_TYPES,
  TRANSACTION_TYPES,
}
