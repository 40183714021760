import Colors, { styleSheetFactory } from '../../Themes/Colors'

const styles = styleSheetFactory(colors => ({
    container: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginVertical: 0,
        alignItems: 'center',
    },

    text: {
        fontSize: 16,
        fontWeight: '300',
        color: Colors.primaryText,
    },
    title: {
        fontSize: 16,
        fontWeight: 'bold',
        color: Colors.primaryText,
    },
}))

export default styles
