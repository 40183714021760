/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useEffect } from 'react'
import { Animated } from 'react-native'
import { useTheme } from 'react-native-themed-styles'
import PropTypes from 'prop-types'

import Text from '../Text'
import Icon from '../Icon'

import themedStyles from './Attention.Styles'
import { images } from '../../Themes'

function Attention({
  text,
}) {
  const [styles] = useTheme(themedStyles)

  const anim = useRef(new Animated.Value(1)).current

  const animate = () => {
    Animated.sequence([
      Animated.timing(anim, {
        toValue: 1,
        duration: 300,
        useNativeDriver: true,
      }),
      Animated.timing(anim, {
        toValue: 0,
        duration: 300,
        delay: 3000,
        useNativeDriver: true,
      }),
    ]).start()
  }

  useEffect(() => {
    if (text) {
      animate()
    }
  }, [text])

  return (
    <Animated.View style={[
      styles.tip,
      // eslint-disable-next-line react-native/no-inline-styles
      {
        flexDirection: 'row',
        backgroundColor: 'red',
        transform: [{ scale: anim }],
      },
    ]}>
      <Icon source={images.attention} size={24} />
      <Text i18nKey={text} style={styles.tipText} />
    </Animated.View>
  )
}
Attention.propTypes = {
  text: PropTypes.string,
}

export default Attention
