import React, { useContext } from 'react'
import { SafeAreaView, View } from 'react-native'
import { useTheme } from 'react-native-themed-styles'
import { createNativeStackNavigator } from '@react-navigation/native-stack'
import PropTypes from 'prop-types'

import InitialScreen from '../Containers/InitialScreen'
import Authenticator from '../Containers/Authenticator'
import WelcomeDemo from '../Containers/WelcomeDemo'
import RequestDemo from '../Containers/RequestDemo'
import { useLanguage } from '../Utils/Language'

import themedStyles from './Authentication.Styles'
import Theme from '../Themes/Theme'

const Stack = createNativeStackNavigator()

function Screens({ onSuccess, style }) {
  const [styles] = useTheme(themedStyles)
  const { colors } = useContext(Theme)

  return (
    <View style={[styles.stack, [...[style].flat()]]}>
      <Stack.Navigator
        initialRouteName={'Authenticator'}
        screenOptions={{
          cardStyle: {
            backgroundColor: colors.background,
          },
          headerShown: false,
        }}
      >
        <Stack.Screen name="InitialScreen" component={InitialScreen} />
        <Stack.Screen name="WelcomeDemo" >
          {props => <WelcomeDemo {...props} />}
        </Stack.Screen>
        <Stack.Screen name="RequestDemo" >
          {props => <RequestDemo {...props} />}
        </Stack.Screen>
        <Stack.Screen name="Authenticator" >
          {props => <Authenticator {...props} onSuccess={onSuccess} />}
        </Stack.Screen>
      </Stack.Navigator>
    </View>
  )
}

Screens.propTypes = {
  onSuccess: PropTypes.func,
  style: PropTypes.object,
}

function Authentication({ onSuccess }) {
  const [styles] = useTheme(themedStyles)
  useLanguage()

  return (
    <SafeAreaView style={styles.container}>
      <Screens
        navigation={{}}
        onSuccess={onSuccess}
      />
    </SafeAreaView>
  )
}

Authentication.propTypes = {
  onSuccess: PropTypes.func,
}

export default Authentication
