import { styleSheetFactory } from '../../Themes/Colors'

const styles = styleSheetFactory(colors => ({
    container: {
        flexGrow: 1,
        flex: 1,
        backgroundColor: colors.background,
    },
    upperContentWrapper: {
        alignItems: 'center',
        paddingVertical: 40,
    },
    icon: {
        marginBottom: 25,
    },
    contentWrapper: {
        ali: 'space-between',
    },
    lowerContentWrapper: {
        // alignSelf: 'center',
        marginBottom: 25,
    },
    checkBox: {
        marginRight: 10,
    },
    checkBoxWrapper: {
        flexDirection: 'row',
        alignSelf: 'flex-start',
        marginVertical: 5,
        marginLeft: 20,
        alignItems: 'center',
    },
    rightButton: (policyCheck) => {
        const buttonStyle = policyCheck ? {
            marginHorizontal: 15,
            marginBottom: 15,
        } :
            { marginHorizontal: 15 }
        return (buttonStyle)

    },
    privacyText: {
        textAlign: 'left',
        fontFamily: 'Vodafone Rg',
        color: colors.primaryText,
    },
    lottieWrapper: {
        height: '100%',
        paddingHorizontal: 16,
        paddingVertical: 32,
        marginBottom: 32,
        justifyContent: 'center',
        alignItems: 'center',
    },
    lottie: {
        width: 150,
        height: 150,
        marginLeft: 0,
        alignSelf: 'center',
    },
    webViewWrapper: {
        width: '100%',
        height: '100%',
        alignSelf: 'center',
        padding: 15,
        borderTopLeftRadius: 10,
        borderTopRightRadius: 10,
        overflow: 'hidden',
    },
    webView: {
        flex: 1,
        backgroundColor: colors.white,
    },
    modalContainer: {
        justifyContent: 'center',
        height: '80%',
        backgroundColor: colors.background,
    },
    webViewHeader: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        padding: 10,
        // marginBottom:5,
    },
    scrollView: { flex: 1 },
    horizontalSpacing: { marginHorizontal: 15 },
    buttonContainer: {
        // paddingBottom: 15,
    },
}))

export default styles
