import React from 'react'
import { LayoutAnimation, ScrollView } from 'react-native'
import PropTypes from 'prop-types'
import { useTheme } from 'react-native-themed-styles'
import { useDispatch, useSelector } from 'react-redux'

import { Text } from '../../../../../Components'
import { layoutAnimConfig } from '../../../../../Themes/Theme'

import { generateNewKey, resetEinvoiceState, resetState } from '../../../Payment.Actions'

import PaymentMethod from '../PaymentMethod'

import { getCurrency } from '../../../../Checkout/Checkout.Selectors'

import themedStyles from './PaymentMethods.Styles'

function PaymentMethods({
  invoicingMethods,
  invoicingMethod,
  setInvoicingMethod,
  setOtherCurrency,
}) {
  const [styles] = useTheme(themedStyles)

  const dispatchAction = useDispatch()
  const clearPayment = () => {
    dispatchAction(resetState())
    dispatchAction(resetEinvoiceState())
  }
  const newEmpotencyKey = () => dispatchAction(generateNewKey())

  const currency = useSelector(getCurrency)

  return <>
    <Text i18nKey="payment_select_method" weight="bold" style={styles.sectionTitle} />
    <ScrollView horizontal showsHorizontalScrollIndicator={false} contentContainerStyle={styles.contentContainer} style={styles.container} >
      {invoicingMethods.map(item => (
        <PaymentMethod
          {...item}
          isSelected={item.method === invoicingMethod}
          select={() => {
            if (item.method !== invoicingMethod) {
              LayoutAnimation.configureNext(layoutAnimConfig)
              setInvoicingMethod(item.method)
              clearPayment()
              newEmpotencyKey()
              setOtherCurrency(currency)
            }
          }}
        />
      ))}
    </ScrollView>
  </>
}
PaymentMethods.defaultProps = {
  invoicingMethods: [],
  setInvoicingMethod: () => { },
  setOtherCurrency: () => { },

}
PaymentMethods.propTypes = {
  invoicingMethods: PropTypes.array,
  invoicingMethod: PropTypes.string,
  setInvoicingMethod: PropTypes.func,
  setOtherCurrency: PropTypes.func,
}

export default PaymentMethods
