import { Platform } from 'react-native'

import { styleSheetFactory } from '../../Themes/Colors'

const isWeb = Platform.OS === 'web'

const styles = styleSheetFactory(colors => ({
  container: {
    paddingVertical: 7.5,
    flex: isWeb ? '0 0 30%' : 1,
  },
  item_true: {
    marginRight: 5,
  },
  itemSelected_false: {
    flexGrow: 1,
    flexDirection: 'row',
    borderRadius: 10,
    paddingVertical: 10,
    paddingHorizontal: 20,
    backgroundColor: colors.white,
    alignItems: 'center',
    height: 60,
    minWidth: isWeb ? 170 : 110,
  },
  itemSelected_true: {
    flexGrow: 1,
    flexDirection: 'row',
    borderRadius: 10,
    paddingVertical: 10,
    paddingHorizontal: 20,
    backgroundColor: colors.white,
    alignItems: 'center',
    height: 60,
    borderWidth: 1,
    borderColor: colors.secondaryAccent,
    minWidth: isWeb ? 170 : 110,
  },
  itemTextSelected_false: {
    flexGrow: 1,
    flexShrink: 1,
  },
  itemTextSelected_true: {
    flexGrow: 1,
    flexShrink: 1,
  },
  isDisabled_true: {
    backgroundColor: colors.secondaryBackround,
  },
}))

export default styles
