/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import {
    SafeAreaView,
    View,
    TouchableOpacity,
    Dimensions,
    LayoutAnimation,
    Text as RNText,
    Linking,
} from 'react-native'
import { useTheme } from 'react-native-themed-styles'
import PropTypes from 'prop-types'
import ContentLoader, { Rect } from 'react-content-loader/native'
import { SwipeRow } from 'react-native-swipe-list-view'
import Color from 'color'
import { useQuery, useMutation, useLazyQuery } from '@apollo/client'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'

import { getErrorMessage, getErrorType } from '../../Utils'
import ContentManager from '../../Utils/ContentManager'
import {
    Header,
    InputWithTags,
    Text,
    Button,
    Icon,
    Loader,
    ErrorModal,
    TouchableInput,
} from '../../Components'
import { useUserDetails } from '../../Utils/AuthDetails'

import { getOnBoardingCashRegister } from '../OnBoarding/OnBoarding.Selectors'
import { onBoardingUpdateStepKey } from '../OnBoarding/OnBoarding.Actions'

import SwipeList from '../Users/SwipeList'
import {
    GENERATE_TCR_CODE,
    GET_CASH_REGISTER,
    UPDATE_CASH_REGISTER,
} from '../CashRegister/CashRegister.Schema'

import { BUSIN_UNITS_LIST, GET_LOCATION } from '../Location/Location.Schema'

import BottomSheetCodeInput from '../../Components/BottomSheetCodeInput/BottomSheetCodeInput'

import { layoutAnimConfig } from '../../Themes/Theme'
import themedStyles, {
    photoItemStyles,
    itemStyles,
    hiddenStyles,
} from './OnBoardingFourthStep.Styles'

import images from '../../Themes/Images'
import { colors, animations } from '../../Themes'

const employeeStatus = {
    Pending: 'gray',
    Active: 'green',
    Inactive: 'red',
}
const avatars = ['maleAvatar', 'femaleAvatar']
const { width } = Dimensions.get('screen')

function ItemPhoto(props) {
    const [styles] = useTheme(photoItemStyles)

    const { color, title, status } = props

    const pic = avatars.find((el) => el === color)

    return (
        <View>
            {pic ? (
                <View style={styles.avatarContainer}>
                    <Icon source={images[pic]} size={70} resizeMode="cover" />
                    <View
                        style={[
                            styles.status,
                            {
                                backgroundColor: colors[status],
                            },
                        ]}
                    />
                </View>
            ) : (
                <View
                    style={[
                        {
                            backgroundColor: Color(colors[color] || colors.transparent)
                                .fade(0.7)
                                .toString(),
                        },
                        styles.colorContainer,
                    ]}>
                    <Text
                        size="h5"
                        color={colors[color]}
                        align="center"
                        i18nKey={title
                            ?.match(/\b(\w{1})/g)
                            ?.slice(0, 2)
                            ?.join('')
                            ?.toUpperCase()}
                    />
                    <View
                        style={[
                            styles.status,
                            {
                                backgroundColor: colors[status],
                            },
                        ]}
                    />
                </View>
            )}
        </View>
    )
}
ItemPhoto.propTypes = {
    color: PropTypes.string,
    role: PropTypes.string,
    title: PropTypes.string,
    status: PropTypes.string,
}

function Employee(props) {
    const [styles] = useTheme(itemStyles)
    const { item, isFirst, isLast, stylee } = props

    let propStyle = {},
        propStylee = {}
    if (isFirst) {
        propStyle = styles.listFirst
    }
    if (isLast) {
        propStylee = styles.listLast
    }
    const style = Object.assign(
        {},
        styles.default,
        styles.list,
        propStyle,
        propStylee,
    )

    return (
        <View style={[{ backgroundColor: colors.tertiaryBackground }, stylee, style]}>
            <View style={styles.subContainer}>
                <ItemPhoto
                    color={item.color}
                    role={item.role}
                    title={`${item.firstName} ${item.lastName}`}
                    status={employeeStatus[item.userStatus]}
                />
                <View style={styles.textContainer}>
                    <View style={styles.firstRow}>
                        <Text
                            i18nKey={`${item.firstName} ${item.lastName}`}
                            numberOfLines={1}
                        />
                        <Text i18nKey={` (${item.role})`} numberOfLines={1} />
                    </View>
                    <Text
                        i18nKey={item.email}
                        weight="light"
                        color={colors.secondaryText}
                        size="footnote"
                        numberOfLines={1}
                    />
                </View>
            </View>
        </View>
    )
}
Employee.propTypes = {
    item: PropTypes.object,
    isFirst: PropTypes.bool,
    isLast: PropTypes.bool,
    stylee: PropTypes.object,
}

function HiddenItem(props) {
    const [styles] = useTheme(hiddenStyles)
    const {
        needsConfirmation,
        isFirst,
        isLast,
        onPress,
        onConfirm,
        onCancel,
        style,
        loading,
    } = props

    let propStyle = {},
        propStylee = {}
    if (isFirst) {
        propStyle = styles.listFirst
    }
    if (isLast) {
        propStylee = styles.listLast
    }
    const stylee = Object.assign(
        {},
        styles.default,
        styles.list,
        propStyle,
        propStylee,
    )

    return (
        <View style={[styles.hiddenContainer, style, stylee]}>
            {loading
                ? <View style={styles.loader}>
                    <Loader size={32} color={colors.white} source={animations.vfLoaderWhite} />
                </View>
                : needsConfirmation ? (
                    <View style={styles.leftContainer}>
                        <TouchableOpacity style={styles.hiddenItem} onPress={onPress}>
                            <Text i18nKey="cash_register_remove_button" color="white" />
                        </TouchableOpacity>
                    </View>
                ) : (
                    <View style={styles.confirmContainer}>
                        <TouchableOpacity style={styles.confirmStyles} onPress={onConfirm}>
                            <Icon source={images.bin_light} color={colors.white} size={24} />
                            <Text
                                i18nKey="receipt_button_confirm"
                                color={colors.white}
                                style={styles.confirmText}
                            />
                        </TouchableOpacity>
                        <TouchableOpacity style={styles.cancelContainer} onPress={onCancel}>
                            <Icon source={images.closeIcon} color={colors.white} size={20} />
                        </TouchableOpacity>
                    </View>
                )}
        </View>
    )
}
HiddenItem.propTypes = {
    needsConfirmation: PropTypes.bool,
    isFirst: PropTypes.bool,
    isLast: PropTypes.bool,
    onPress: PropTypes.func,
    onConfirm: PropTypes.func,
    onCancel: PropTypes.func,
    style: PropTypes.object,
    loading: PropTypes.bool,
}

function Item(props) {
    const [styles] = useTheme(themedStyles)
    const {
        title,
        value,
        isFirst,
        isLast,
        itemStyle,
        containerStyle,
        loading,
    } = props

    const style = {}
    if (isFirst) {
        style.borderTopLeftRadius = 7
        style.borderTopRightRadius = 7
    }
    if (isLast) {
        style.borderBottomLeftRadius = 7
        style.borderBottomRightRadius = 7
    }

    return (
        <View
            style={[
                {
                    backgroundColor: colors.tertiaryBackground,
                },
                style,
                containerStyle,
            ]}>
            <View style={[styles.itemStyle, itemStyle]}>
                <View style={styles.listDescContainer}>
                    <Text i18nKey={title} weight="light" style={styles.rightSpacing} />
                    {loading ? (
                        <ContentLoader
                            speed={0.7}
                            height={25}
                            width={100}
                            style={Object.assign({ borderRadius: 7 })}
                            backgroundColor="#e2e2e2"
                            foregroundColor="#ecebeb">
                            <Rect x="0" y="5" rx="3" ry="3" width={100} height="15" />
                        </ContentLoader>
                    ) : (
                        <Text i18nKey={value} numberOfLines={1} style={styles.shrinkText} />
                    )}
                </View>
            </View>
        </View>
    )
}
Item.propTypes = {
    title: PropTypes.string,
    value: PropTypes.string,
    isFirst: PropTypes.bool,
    isLast: PropTypes.bool,
    itemStyle: PropTypes.object,
    containerStyle: PropTypes.object,
    loading: PropTypes.bool,
}

function OnBoardingFourthStep({ next, selectedPage, logout }) {
    const [styles] = useTheme(themedStyles)
    const { deviceId, id } = useUserDetails()
    const { translate } = ContentManager
    const storedCashRegister = useSelector(getOnBoardingCashRegister)

    const dispatchAction = useDispatch()

    const updateCashRegister = (key, value) => {
        dispatchAction(onBoardingUpdateStepKey('cashRegister', key, value))
    }

    const [disabled, setDisabled] = useState(true)
    const [needsConfirmation, setNeedForConfirmation] = useState(true)
    const [expDate] = useState('')
    const [rightOpenValueFull, setRightOpenValueFull] = useState(-width + 30)

    const [businessCodeModal, setBusinessCodeModal] = useState(false)
    const [businessInputValue, setBusinessInputValue] = useState(storedCashRegister.businessUnitCode || '')
    const [businCodesData, setBusinCodesData] = useState([])
    const [selectedBusinCode, setselectedBusinCode] = useState({ code: storedCashRegister.businessUnitCode })
    const isAnItemSelected = businCodesData.filter((item) => item.code === storedCashRegister.businessUnitCode).length > 0


    useEffect(() => {
        if (isAnItemSelected) {
            setselectedBusinCode({ code: storedCashRegister.businessUnitCode })
        }
    }, [businessCodeModal])

    useEffect(() => {
        if (!isAnItemSelected) {
            setBusinessInputValue(storedCashRegister.businessUnitCode)
        }
    }, [businessCodeModal])



    const handleTextChange = (text) => {
        setBusinessInputValue(text)
        setselectedBusinCode('')
    }


    const handleCodeInputModalClose = () => {
        setBusinessCodeModal(false)
    }
    const mapBusinCodes = (businCodesList) => {
        const unmapedBusinCodes = businCodesList?.getBusinessUnits?.businessUnits
        const mappedBusinCodes = []

        if (unmapedBusinCodes) {
            unmapedBusinCodes.forEach((item) => {
                mappedBusinCodes.push({
                    id: item?.attributes?.BUName,
                    code: item?.attributes?.BUCode,
                    description: `${item?.attributes?.BUStreetName}`,
                })
            })
        }
        return mappedBusinCodes
    }

    const [modal, setModal] = useState({
        isVisible: false,
        icon: images.warningIcon,
        title: '',
        desc: '',
        descPlaceholders: [],
        primaryText: '',
        primaryAction: null,
        secondaryText: '',
        secondaryAction: null,
    })
    const openModal = (
        title = 'opened_orders_error',
        desc = '',
        descPlaceholders = [],
        primaryText = '',
        primaryAction = null,
        secondaryText = '',
        secondaryAction = null,
        icon = images.warningIcon,
    ) => {
        setModal({
            isVisible: true,
            icon,
            title,
            desc,
            descPlaceholders,
            primaryText,
            primaryAction,
            secondaryText,
            secondaryAction,
        })
    }
    const closeModal = () => {
        setModal({
            isVisible: false,
            icon: images.delete,
            title: '',
            desc: '',
            primaryText: '',
            primaryAction: null,
            secondaryText: '',
            secondaryAction: null,
        })
    }

    const measureView = (event) => {
        setRightOpenValueFull(-event.nativeEvent.layout.width)
    }

    const resetNeedForConfirmation = () => {
        setNeedForConfirmation(true)
    }

    const { loading: devLoading, data: devData, refetch } = useQuery(GET_CASH_REGISTER, {
        fetchPolicy: 'network-only',
        variables: {
            searchBy: {
                name: 'id',
                value: deviceId,
            },
        },
        skip: !deviceId || selectedPage !== 4,
    })

    const expRegex = /^([0-2][0-9]|(3)[0-1])(\/)(((0)[0-9])|((1)[0-2]))(\/)\d{4}$/i

    const [
        updateDevice,
        { loading: updating },
    ] = useMutation(UPDATE_CASH_REGISTER)

    const [getLocation, {
        data: details,
        loading: locationLoading,
    }] = useLazyQuery(GET_LOCATION)

    const [generateCode, { loading }] = useMutation(GENERATE_TCR_CODE)

    useEffect(() => {
        const deviceInfo = devData?.getCashRegister
        if (deviceInfo) {
            LayoutAnimation.configureNext(layoutAnimConfig)
            updateCashRegister('name', deviceInfo?.name)
            getLocation({ variables: { id: deviceInfo?.locationId } })
            updateCashRegister('deviceData', deviceInfo)
            updateCashRegister('businessUnitCode', deviceInfo?.businUnitCode || '')
            const hasEmp = deviceInfo?.employees.find((element) => element.id === id)
            updateCashRegister('employees', deviceInfo?.employees)
            updateCashRegister('tcr', deviceInfo?.TCRCode)
            if (!hasEmp && deviceInfo?.name) {
                updateDevice({
                    variables: {
                        id: deviceId,
                        employeeToEnable: [id],
                    },
                })
                    .then(async () => {
                        if (refetch) {
                            await refetch()
                        }
                    })
            }
        }
    }, [devData])

    const generateTcr = () => {
        generateCode({
            variables: {
                cashRegisterId: deviceId,
                businUnitCode: storedCashRegister.businessUnitCode,
            },
        })
            .then((response) => {
                const tcr = response?.data?.registerTCR?.TCRCode
                LayoutAnimation.configureNext(layoutAnimConfig)
                updateCashRegister('tcr', tcr)
                const deviceInfo = { ...storedCashRegister.deviceData, TCRCode: tcr }
                updateCashRegister('deviceData', deviceInfo)
                updateDevice({
                    variables: {
                        id: deviceId,
                        name: storedCashRegister.name,
                        status: 'ACTIVE',
                    },
                })
                    .then(async () => {
                        if (refetch) {
                            await refetch()
                        }
                    })
            })
            .catch((er) => {
                const errorMessage = getErrorType(er)
                openModal(
                    'certificate_error_label',
                    `${errorMessage}`,
                    [],
                    'orders_try_again',
                    () => {
                        closeModal()
                        generateTcr()
                    },
                    '',
                    undefined,
                )
            })
    }

    useEffect(() => {
        if (storedCashRegister.tcr && storedCashRegister.name && (expRegex.test(expDate) || !expDate)) {
            setDisabled(false)
        } else {
            setDisabled(true)
        }
    }, [storedCashRegister.tcr, storedCashRegister.name, expDate])

    useEffect(() => {
        const locationData = details?.getLocation
        if (locationData) {
            updateCashRegister('location', locationData?.businessName)
        }
    }, [details])

    const onFinish = () => {
        updateDevice({
            variables: {
                id: deviceId,
                name: storedCashRegister.name,
                status: 'ACTIVE',
                validTo: expDate ? moment(expDate, 'DD/MM/YYYY').format('YYYY-MM-DD') : null,
            },
        }).catch((err) => {
            openModal(
                'certificate_error_label',
                getErrorMessage(err),
                [],
                '',
                undefined,
                'logout_button_cancel',
                () => {
                    closeModal()
                },
            )
        })
            .then(() => {
                next()
            })
    }

    const regex = /^[a-z]{2}\d{3}[a-z]{2}\d{3}$/

    return (
        <>
            <SafeAreaView style={styles.container}>
                <Header
                    title={'header_cash_register'}
                    image={images.close}
                    onPress={() => {
                        openModal(
                            'logout_key',
                            'onboarding_logout_message_description',
                            [],
                            'logout_key',
                            () => {
                                logout()
                            },
                            'logout_button_cancel',
                            () => {
                                closeModal()
                            },
                        )
                    }}
                />
                <SwipeList
                    onLayout={(event) => measureView(event)}
                    ItemSeparatorComponent={() => <View style={styles.separator} />}
                    style={styles.topSpacing}
                    contentContainerStyle={styles.bottomSelfSpacing}
                    data={storedCashRegister.employees}
                    onRowClose={resetNeedForConfirmation}
                    renderItem={(rowData, rowMap) => {
                        const { item, index } = rowData
                        let isFirst = false,
                            isLast = false
                        if (index === 0) {
                            isFirst = true
                        }
                        if (index === storedCashRegister.employees?.length - 1) {
                            isLast = true
                        }
                        return (
                            <SwipeRow
                                key={`item-${index}`}
                                disableRightSwipe
                                disableLeftSwipe
                                rightOpenValue={-100}
                                closeOnRowPress
                                restSpeedThreshold={0.004}
                                useNativeDriver={true}>
                                <></>
                                <Employee
                                    key={item?.id}
                                    item={item}
                                    isFirst={isFirst}
                                    isLast={isLast}
                                />
                            </SwipeRow>
                        )
                    }}
                    ListHeaderComponent={
                        <>
                            <InputWithTags
                                label="cash_register_name_label"
                                value={storedCashRegister.name}
                                onChangeText={(val) => {
                                    updateCashRegister('name', val)
                                }}
                                autoCorrect={false}
                                inputContainerStyle={styles.inputContainer}
                                labelOffset={{
                                    x0: 0,
                                    y0: -7,
                                    x1: 0,
                                    y1: 2,
                                }}
                                maxLength={20}
                            />
                            <Item
                                title="users_location_label"
                                value={storedCashRegister.location}
                                loading={locationLoading || devLoading}
                                isFirst
                                isLast
                                containerStyle={styles.itemSpacing}
                            />
                            <TouchableInput
                                onPress={() => {
                                    setBusinessInputValue('')
                                    setselectedBusinCode('')
                                    setBusinessCodeModal(true)
                                }}
                                label="cash_register_business_code"
                                value={storedCashRegister.businessUnitCode}
                                icon={images.filledDownArrow}
                                iconSize={16}
                                autoCorrect={false}
                                autoCapitalize="none"
                                editable={false}
                            />
                            {/* <InputWithTags
                                label="cash_register_business_code"
                                value={storedCashRegister.businessUnitCode}
                                onChangeText={(val) => { updateCashRegister('businessUnitCode', val) }}
                                autoCorrect={false}
                                autoCapitalize="none"
                                inputContainerStyle={styles.inputContainer}
                                labelOffset={{
                                    x0: 0,
                                    y0: -7,
                                    x1: 0,
                                    y1: 2,
                                }}
                                maxLength={20}
                            /> */}
                            <TouchableOpacity onPress={() => Linking.openURL('https://efiskalizimi-app.tatime.gov.al/self-care')}>
                                <RNText style={styles.textWrapper} allowFontScaling={false} >
                                    <RNText>{translate('cash_register_business_code1')}</RNText>
                                    <RNText style={{ color: colors.secondaryAccent }}>{translate('cash_register_business_code2')}</RNText>
                                    <RNText>{translate('cash_register_business_code3')}</RNText>
                                    <RNText style={styles.italicText}>{translate('cash_register_business_code4_1')}</RNText>
                                    <RNText>{translate('cash_register_business_code5')}</RNText>
                                </RNText>
                            </TouchableOpacity>

                            <Text i18nKey="cash_register_about_label" align="left" />
                            <View style={styles.verticalSpacing}>
                                <Item
                                    title="cash_register_device_name"
                                    value={storedCashRegister.deviceData?.deviceName}
                                    loading={devLoading}
                                    isFirst={true}
                                    itemStyle={styles.aboutItem}
                                />
                                <Item
                                    title="cash_register_device_type"
                                    value={
                                        storedCashRegister.deviceData?.isTablet
                                            ? 'cash_register_tablet_label'
                                            : 'cash_register_mobile_label'
                                    }
                                    loading={devLoading}
                                    itemStyle={styles.aboutItem}
                                />
                                <Item
                                    title="cash_register_device_model"
                                    value={storedCashRegister.deviceData?.model}
                                    loading={devLoading}
                                    itemStyle={styles.aboutItem}
                                />
                                <Item
                                    title="cash_register_operating_system"
                                    value={storedCashRegister.deviceData?.os}
                                    loading={devLoading}
                                    itemStyle={styles.aboutItem}
                                />
                                <Item
                                    title="cash_register_device_id"
                                    value={storedCashRegister.deviceData?.deviceId}
                                    loading={devLoading}
                                    isLast={true}
                                    itemStyle={styles.aboutItem}
                                />
                            </View>
                            <Item
                                title="receipt_user"
                                value={storedCashRegister.deviceData?.requestUserEmail}
                                loading={devLoading}
                                isFirst
                                isLast
                            />

                            <>
                                {!storedCashRegister.deviceData.TCRCode ? (

                                    <Button
                                        title="cash_register_tcr_button"
                                        backgroundColor={
                                            (loading || !storedCashRegister.name || !regex.test(storedCashRegister.businessUnitCode)) ? colors.disabled : colors.secondaryAccent
                                        }
                                        disabled={!storedCashRegister.name || !regex.test(storedCashRegister.businessUnitCode)}
                                        style={styles.button}
                                        onPress={() => generateTcr()}
                                    />

                                ) :
                                    <Item
                                        title="cash_register_tcr_label"
                                        value={storedCashRegister.deviceData?.TCRCode}
                                        loading={loading}
                                        isFirst
                                        isLast
                                        containerStyle={styles.itemSpacing}
                                    />}
                                {/* <Text i18nKey="cash_register_end_date" />
                                <InputWithTags
                                    label="DD/MM/YYYY"
                                    error={!expRegex.test(expDate) && Boolean(expDate)}
                                    value={expDate}
                                    onChangeText={setExpDate}
                                    autoCorrect={false}
                                    autoCapitalize="none"
                                    inputContainerStyle={styles.inputContainer}
                                    inputWrapperStyle={styles.itemSpacing}
                                    labelOffset={{
                                        x0: 0,
                                        y0: -7,
                                        x1: 0,
                                        y1: 2,
                                    }}
                                /> */}
                                <View style={styles.noteView}>
                                    <View style={styles.noteHeader}>
                                        <Icon source={images.info} size={20} />
                                        <Text
                                            i18nKey="cash_register_note"
                                            weight="bold"
                                            style={styles.noteTitle}
                                        />
                                    </View>
                                    <Text i18nKey="cash_register_new_note" />
                                </View>
                            </>
                        </>
                    }
                    refreshing={false}
                />
                <View style={styles.buttonContainer}>
                    <Button
                        loader={updating}
                        title="orders_refund_button_next"
                        variant={disabled || updating ? 'disabled' : 'active'}
                        iconColor={colors.accent}
                        style={styles.rightButton}
                        onPress={() => onFinish()}
                    />
                </View>
            </SafeAreaView>
            <BottomSheetCodeInput
                inputValue={businessInputValue}
                isVisible={businessCodeModal}
                data={businCodesData}
                title={'edit_business_code_label'}
                inputPrompt={'edit_business_code_enter_prompt'}
                inputLabel={'edit_business_code_label'}
                buttonLabel={'edit_business_code_set'}
                onSave={(val) => { updateCashRegister('businessUnitCode', val) }}
                setData={setBusinCodesData}
                query={BUSIN_UNITS_LIST}
                note
                mapItems={mapBusinCodes}
                onClose={handleCodeInputModalClose}
                onChangeText={handleTextChange}
                selected={selectedBusinCode}
                select={(obj) => {
                    setselectedBusinCode(obj)
                }}
                editable={true}
            />
            <ErrorModal
                isVisible={modal?.isVisible}
                title={modal?.title}
                description={modal?.desc}
                placeHolders={modal.descPlaceholders}
                primaryText={modal?.primaryText}
                secondaryText={modal?.secondaryText}
                primaryAction={modal?.primaryAction}
                secondaryAction={modal?.secondaryAction}
                onBackdropPress={() => closeModal()}
                onBackButtonPress={() => closeModal()}
                onClosePress={() => closeModal()}
            />
        </>
    )
}

OnBoardingFourthStep.propTypes = {
    next: PropTypes.func,
    selectedPage: PropTypes.number,
    logout: PropTypes.func,
}

export default OnBoardingFourthStep
