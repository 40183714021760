/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback, useRef } from 'react'
import {
  SafeAreaView,
  TouchableOpacity,
  View,
  useWindowDimensions,
} from 'react-native'
import { useFocusEffect } from '@react-navigation/native'
import { SwipeRow } from 'react-native-swipe-list-view'
import PropTypes from 'prop-types'
import { useTheme } from 'react-native-themed-styles'
import { useLazyQuery, useMutation } from '@apollo/client'
import { Portal } from 'react-native-portalize'
import moment from 'moment'

import {
  SwipeList,
  ModalContent,
  Modal,
  Icon,
  Text,
  ListLoader,
  LoaderModal,
  Loader,
  ItemStatus,
} from '../../../Components'

import { getErrorMessage, INF, useConfig } from '../../../Utils'
import { useLanguage } from '../../../Utils/Language'
import ContentManager from '../../../Utils/ContentManager'

import {
  LIST_MY_INVOICES,
  DELETE_INVOICE,
} from '../EInvoice.Schema'

import { images, colors, animations } from '../../../Themes'
import themedStyles, { itemStyles, myHiddenItemStyles } from '../EInvoice.Styles'

const statusIcons = {
  IN_PROGRESS: {
    image: images.inProgressInvoice,
  },
  REGISTERED: {
    image: images.waiting,
    color: colors.secondaryAccent,
  },
  CORRECTEDINVOICE: {
    image: images.refundedInvoice,
  },
}

const LANGUAGES = {
  al: 'sq',
  en: 'en-gb',
}

function SectionItem({ sectionTitle }) {
  const [styles] = useTheme(themedStyles)

  return (
    <Text
      i18nKey={sectionTitle}
      size="footnote"
      color={colors.secondaryText}
      style={styles.sectionTitle}
    />
  )
}
SectionItem.propTypes = {
  sectionTitle: PropTypes.string,
}

function Invoice({
  type,
  createdAt,
  title,
  employeeEmail,
  amount,
  onPress,
  isFirst,
  isLast,
  status,
}) {
  const [styles] = useTheme(itemStyles)
  const { translate } = ContentManager

  const ItemStatuses = {
    ACCEPTED: <ItemStatus
      color={colors.green}
      title="invoice_status_success"
    />,
    REJECTED: <ItemStatus
      color={colors.accent}
      title="invoice_status_failed"
      code="12" />,
    IN_PROGRESS: null,
    null: null,
    undefined: null,
  }

  return <View style={[
    styles.myContainer,
    styles['myFirstItem_' + isFirst],
    styles['lastItem_' + isLast],
  ]}>
    <TouchableOpacity
      onPress={onPress}
      style={styles.touchable}
    >
      <View style={styles.textContainer}>
        <Icon source={statusIcons?.[type]?.image} color={statusIcons?.[type]?.color} />
        <View style={styles.leftData}>
          <Text i18nKey={`${title} | ${translate(type)}`} numberOfLines={1} />
          <View style={styles.rowView}>
            {createdAt ? <Text i18nKey={createdAt} weight="light" size="footnote" numberOfLines={1} /> : null}
            {ItemStatuses[status]}
          </View>
          <Text i18nKey={employeeEmail} weight="light" size="footnote" numberOfLines={1} />
        </View>
      </View>
      <View style={styles.rightComponent}>
        <View style={styles.rightData}>
          <Text i18nKey={amount} />
        </View>
        <Icon
          source={images.chevronRight}
          size={26}
          color={colors.accent}
          style={styles.rightIcon}
        />
      </View>

    </TouchableOpacity>
  </View>
}
Invoice.propTypes = {
  type: PropTypes.string,
  partyType: PropTypes.string,
  createdAt: PropTypes.string,
  title: PropTypes.string,
  amount: PropTypes.string,
  employeeEmail: PropTypes.string,
  onPress: PropTypes.func,
  isFirst: PropTypes.bool,
  isLast: PropTypes.bool,
  status: PropTypes.string,
}

function HiddenItem({
  needsConfirmation,
  onPress, onConfirm,
  onCancel, loading,
  isFirst,
  isLast,
}) {
  const [styles] = useTheme(myHiddenItemStyles)
  return (
    <View style={[styles['firstItem_' + isFirst], styles['lastItem_' + isLast]]}>
      {loading
        ? <View style={styles.loader}>
          <Loader size={32} color={colors.white} source={animations.vfLoaderWhite} />
        </View>
        : needsConfirmation
          ? <TouchableOpacity
            style={styles.option}
            onPress={onPress}
          >
            <Icon source={images.delete} color={colors.white} size={24} />
          </TouchableOpacity>
          : <View style={styles.optionFull}>
            <TouchableOpacity
              style={styles.confirm}
              onPress={onConfirm}
            >
              <Icon source={images.delete} color={colors.white} size={24} />
              <Text i18nKey="opened_orders_delete_confirm" color={colors.white} style={styles.confirmText} />
            </TouchableOpacity>
            <TouchableOpacity
              style={styles.cancel}
              onPress={onCancel}
            >
              <Icon source={images.closeIcon} color={colors.white} size={20} />
            </TouchableOpacity>
          </View>
      }
    </View>
  )
}
HiddenItem.propTypes = {
  needsConfirmation: PropTypes.bool,
  onPress: PropTypes.func,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  loading: PropTypes.bool,
  isFirst: PropTypes.bool,
  isLast: PropTypes.bool,
}

function EmptyItem() {
  return null
}

const listItems = {
  section: SectionItem,
  IN_PROGRESS: Invoice,
  REGISTERED: Invoice,
  CORRECTEDINVOICE: Invoice,
}

const hiddenItems = {
  section: EmptyItem,
  IN_PROGRESS: HiddenItem,
  REGISTERED: HiddenItem,
  CORRECTEDINVOICE: HiddenItem,
}

function EInvoice({ navigation: { navigate }, selectedDates }) {

  const { switchLocation: { deviceId, locationId } } = useConfig()

  const [styles] = useTheme(themedStyles)
  const { width, height } = useWindowDimensions()
  const lang = useLanguage()

  const { translate } = ContentManager

  const [needsConfirmation, setNeedForConfirmation] = useState(true)
  const [eInvoices, setEinvoices] = useState({
    invoices: [],
    loading: true,
  })
  const [itemWidth, setItemWidth] = useState(0)
  // eslint-disable-next-line no-unused-vars
  const [refetching, setRefetching] = useState(false)
  const sectionDate = useRef('-')

  const [selected, setSelected] = useState()
  const [ublLoading] = useState(false)

  const types = [
    // {
    //   title: 'users_all_filter',
    //   type: 'all',
    //   list: () => list(),
    // },
    {
      title: 'einvoice_registered_filter',
      type: 'REGISTERED',
      name: 'status',
      list: () => filteredList('status', 'REGISTERED'),
    },
    {
      title: 'einvoice_refunds_filter',
      type: 'UblRefund',
      name: 'type',
      list: () => filteredList('type', 'UblRefund'),
    },
  ]

  const getItemLayout = useCallback(
    (_, index) => ({
      length: 94,
      offset: 94 * index,
      index,
    }),
    [],
  )
  const [listmyInvoices, { data, refetch, fetchMore, called, networkStatus }] = useLazyQuery(LIST_MY_INVOICES, { fetchPolicy: 'network-only', notifyOnNetworkStatusChange: true })
  const [deleteInvoice, { loading: deleting }] = useMutation(DELETE_INVOICE)

  const list = () => {
    listmyInvoices({
      fetchPolicy: 'network-only',
      variables: {
        locId: locationId,
        cashRegisterId: deviceId,
        limit: 20,
        filter: {
          name: 'status',
          value: 'REGISTERED',
        },
      },
    })
  }

  const filteredList = (key, val) => {
    setSelected(val)
    listmyInvoices({
      variables: {
        locId: locationId,
        cashRegisterId: deviceId,
        filter: {
          name: key,
          value: val,
        },
      },
    })
  }

  const [isErrorActive, setErrorActive] = useState({
    isVisible: false,
    message: '',
  })

  useEffect(() => {
    setSelected('REGISTERED')
  }, [])

  useEffect(() => {
    const invoices = data?.listMyEinvoices?.myEinvoices
    if (Array.isArray(invoices)) {
      const newArray = mapData(invoices)
      setEinvoices({
        invoices: newArray,
        loading: false,
      })
    }
  }, [data])

  const refetchData = async () => {
    setRefetching(true)
    setEinvoices(prev => ({
      ...prev,
      loading: true,
    }))
    if (called) {
      await refetch()
    }
  }

  useFocusEffect(
    useCallback(() => {
      refetchData()
    }, [called])
  )

  useEffect(() => {
    if (selected) {
      const index = types.findIndex((el) => el.type === selected)
      setRefetching(false)
      setEinvoices({
        invoices: [],
        loading: true,
      })
      types[index].list()
    }
  }, [selected])

  useEffect(() => {
    setEinvoices({
      invoices: [],
      loading: true,
    })
  }, [width, height])

  const mapNote = (prevNote) => {
    let note = {}
    prevNote?.map((element) => {
      const itemValue = element.substring(element.indexOf('=') + 1)
      const itemKey = element.substring(0, element.indexOf('='))
      const noteitem = { [itemKey]: itemValue }
      note = { ...note, ...noteitem }
    })
    return note
  }

  const mapData = (unmappedData) => {
    const response = []
    let isFirst = false, isLast = false
    sectionDate.current = null
    unmappedData?.map((item, index) => {
      const itemDetails = item?.ublInvoice

      const mappedNote = mapNote(itemDetails?.note)
      const id = mappedNote.id
      const itemId = id.substring(id.indexOf('=') + 1)
      let status = itemDetails?.status
      if (itemDetails?.invoiceDocumentCode === 'CORRECTEDINVOICE') {
        status = 'CORRECTEDINVOICE'
      }
      isFirst = false
      isLast = false
      const itemTime = itemDetails?.issueDate
      const date = moment(itemTime).locale(LANGUAGES[lang]).format('MMM DD, YYYY')
      if (date !== sectionDate.current) {
        if (response?.[response.length - 1]) {
          response[response.length - 1].isLast = true
        }
        sectionDate.current = date
        response.push({
          id: date,
          sectionTitle: date,
          type: 'section',
        })
      }

      if (response?.[response.length - 1]?.type === 'section') {
        isFirst = true
        isLast = false
      }
      response.push({
        id: itemId,
        type: status,
        createdAt: moment(itemDetails?.issueDate).format('hh:mm A'),
        title: itemDetails.status === 'REGISTERED' ? itemDetails?.id : itemId,
        amount: `${INF.format(itemDetails?.legalMonetaryTotal?.payable?.amount)} ${itemDetails?.legalMonetaryTotal?.payable?.currency}`,
        employeeEmail: itemDetails?.employeeEmail,
        isFirst: isFirst,
        isLast: isLast,
        status: itemDetails?.status,
      })
    })
    if (response?.[response.length - 1]) {
      response[response.length - 1].isLast = true
    }
    return response
  }

  const openRow = (rowMap, id) => {
    setNeedForConfirmation(false)
    rowMap[id].manuallySwipeRow(-itemWidth)
  }

  const closeRow = (rowMap, id) => {
    rowMap[id].closeRow()
    setNeedForConfirmation(true)
  }

  const deleteRow = (rowMap, id) => {
    deleteInvoice({
      variables: {
        locId: locationId,
        cashRegisterId: deviceId,
        id: id,
      },
    }).then((res) => {
      closeRow(rowMap, id)
      refetch()
    }).catch((er) => {
      setErrorActive({
        isVisible: true,
        message: er,
      })
      closeRow(rowMap, id)
    })
  }

  const onEndReached = () => {
    if (!data?.listMyEinvoices?.nextToken) {
      return
    }
    if (networkStatus === 3) {
      return
    }
    setRefetching(false)
    setEinvoices((prev) => ({
      ...prev,
      loading: true,
    }))
    const index = types.findIndex((el) => el.type === selected)
    fetchMore({
      variables: {
        locId: locationId,
        cashRegisterId: deviceId,
        limit: 20,
        filter: {
          name: types[index].name,
          value: types[index].type,
        },
        nextToken: data.listMyEinvoices.nextToken,
      },
    })
  }

  const renderItem = (rowData, rowMap) => {
    const { item, index } = rowData
    const ListItem = listItems[item?.type]
    const ListHiddenItem = hiddenItems[item?.type]
    return (
      <SwipeRow
        key={'row-' + index}
        disableRightSwipe
        disableLeftSwipe={item?.type !== 'IN_PROGRESS'}
        rightOpenValue={-100}
        closeOnRowPress
        recalculateHiddenLayout={false}
        useNativeDriver={true}
        getItemLayout={getItemLayout}

      >
        <ListHiddenItem
          key={'row-' + index}
          needsConfirmation={needsConfirmation}
          onPress={() => openRow(rowMap, item.id)}
          onConfirm={() => deleteRow(rowMap, item.id)}
          onCancel={() => closeRow(rowMap, item.id)}
          loading={deleting}
          isFirst={item.isFirst}
          isLast={item.isLast}
        />
        <ListItem
          {...item}
          key={'row-' + index}
          sectionTitle={item?.sectionTitle}
          onPress={() => {
            // if (item?.status === 'IN_PROGRESS') {
            //   setUblLoading(true)
            //   dispatchAction(newInvoice(`id=${item?.id}`))
            //   dispatchAction(updateCalculatedUBL({
            //     note: [`id=${item?.id}`],
            //   }))
            //   getEinvoice({
            //     variables: {
            //       id: item?.id,
            //     },
            //   })
            // } else {
            if (item.type === 'CORRECTEDINVOICE') {
              navigate('RefundDetails', {
                id: item?.id,
                title: `${item?.title} - ${translate('refund_label')}`,
              })
            } else {
              navigate('EInvoiceDetails', {
                id: item?.id,
                title: item?.title,
                status: item?.type,
                isMyInvoice: true,
              })
            }
            // }
          }}
        />
      </SwipeRow>
    )
  }

  const onRefresh = () => {
    setRefetching(true)
    refetchData()
  }

  useEffect(() => {
    setEinvoices((prev) => ({
      ...prev,
      loading: true,
    }))
    if (selectedDates) {
      if (Object.keys(selectedDates).length > 0) {
        listmyInvoices({
          variables: {
            locId: locationId,
            cashRegisterId: deviceId,
            filter: {
              name: 'status',
              value: 'REGISTERED',
            },
            endTime: selectedDates?.endingDay,
            beginTime: selectedDates?.startingDay,
          },
        })
      } else {
        list()
      }
    }
  }, [selectedDates])

  return (
    <SafeAreaView style={styles.container}>
      <SwipeList
        style={styles.eInvoiceList}
        data={eInvoices.invoices}
        renderItem={renderItem}
        disableRightSwipe
        onRowClose={() => setNeedForConfirmation(true)}
        setItemWidth={setItemWidth}
        onEndReached={onEndReached}
        // ListHeaderComponent={<TagList
        //   selected={selected}
        //   onPress={setSelected}
        //   options={types}
        //   style={styles.headerStyles}
        //   contentContainerStyle={styles.tagListContainer}
        // />}
        onEndReachedThreshold={0.2}
        ListFooterComponent={eInvoices.loading && <ListLoader loading={eInvoices.loading} />}
        ListEmptyComponent={eInvoices.loading ? null : <Text weight="light" i18nKey="einvoice_list_empty_message" style={styles.emptyComponent} />}
        refreshing={false}
        onRefresh={onRefresh}
      />
      <Modal
        isVisible={isErrorActive.isVisible}
        onBackButtonPress={() => setErrorActive({
          isVisible: false,
          message: '',
        })}
        onBackdropPress={() => setErrorActive({
          isVisible: false,
          message: '',
        })}
        animationIn="fadeIn"
        animationOut="fadeOut"
      >
        <ModalContent onClose={() => setErrorActive({
          isVisible: false,
          message: '',
        })}>
          <Icon source={images.delete} style={styles.errorIcon} />
          <Text i18nKey="orders_error_label" color={colors.accent} size="h5" align="center" weight="bold" style={styles.errorTitle} />
          <Text i18nKey={getErrorMessage(isErrorActive.message)} align="center" />
        </ModalContent>
      </Modal>
      <Portal>
        <LoaderModal
          loading={ublLoading}
          message="einvoice_retrieving_selected"
        />
      </Portal>
    </SafeAreaView>
  )
}
EInvoice.propTypes = {
  navigation: PropTypes.object,
  selectedDates: PropTypes.object,
}

export default EInvoice
