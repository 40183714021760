import { styleSheetFactory } from '../../../../../Themes/Colors'

const styles = styleSheetFactory((colors) => ({
  sectionText: {
    marginTop: 15,
    marginHorizontal: 15,
  },
  email: {
    margin: 15,
  },
  bankAccount: {
    marginHorizontal: 15,
    marginTop: 15,
  },
  removeBuyer: {
    borderRadius: 18,
    padding: 5,
    backgroundColor: colors.separatorBody,
    marginRight: 15,
  },
  bankHeading: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 15,
    marginBottom: 10,
  },
  bankDetailsWrapper: {
    backgroundColor: colors.white,
    padding: 10,
    marginHorizontal: 15,
    borderRadius: 7,
    alignItems: 'flex-start',
  },
}))

export default styles
