import { createStore, combineReducers, applyMiddleware, compose } from 'redux'
import { persistStore, persistReducer } from 'redux-persist'
import thunk from 'redux-thunk'
import AsyncStorage from '@react-native-async-storage/async-storage'
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2'

import {
  eInvoiceData,
  eInvoiceLineItems,
  eInvoiceCalculated,
  supportInvoiceData,
  paymentData,
} from '../Containers/Payment/Payment.Reducer'

import onBoardingData from '../Containers/OnBoarding/OnBoarding.Reducer'

import productCatalog from '../Containers/ProductCatalog/ProductCatalog.Reducer'

import {
  checkoutData,
} from '../Containers/Checkout/Checkout.Reducer'

const enhancerList = []
const devToolsExtension = window && window.__REDUX_DEVTOOLS_EXTENSION__

if (typeof devToolsExtension === 'function') {
  enhancerList.push(devToolsExtension())
}

const persistConfig = {
  key: 'root',
  storage: AsyncStorage,
  timeout: 0,
  stateReconciler: autoMergeLevel2,
}

const reducers = combineReducers({
  eInvoiceData,
  eInvoiceLineItems,
  eInvoiceCalculated,
  supportInvoiceData,
  paymentData,
  checkoutData,
  onBoardingData,
  productCatalog,
})

const composedEnhancer = compose(
  applyMiddleware(thunk),
  ...enhancerList,
)

const persistedReducer = persistReducer(persistConfig, reducers)

const store = createStore(persistedReducer, {}, composedEnhancer)

const persistor = persistStore(store)

export { store, persistor }
