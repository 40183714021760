const en = {
  app_description: 'To activate the eBiznes service, go to any Vodafone store and register your business credentials.',
  logout_key: 'Log Out',
  logout_message: 'Are you sure you want to logout?',
  logout_button_cancel: 'Cancel',
  support_title: '24/7 Support',
  app_title: 'Get started!',
  app_learn_more: 'Learn more.',
  app_account_title: 'Don\'t have an account yet?',

  //Menu items
  menu_checkout_label: 'Checkout',
  menu_items_label: 'Items',
  menu_transactions_label: 'Transactions',
  menu_invoices_label: 'Invoices',
  menu_gov_label: 'Failed events',
  menu_drawer_label: 'Cash Drawer',
  menu_users_label: 'Users',
  menu_customers_label: 'Customers',
  menu_partners_label: 'Partners',
  menu_analytics_label: 'Analytics',
  menu_settings_label: 'Settings',
  menu_einvoice_label: 'E-Invoices',
  menu_reports_label: 'Reports',
  menu_supporting_invoice_label: 'Supporting invoices',
  menu_bulk_upload_label: 'Bulk Upload',

  //Header titles
  header_settings: 'Settings',
  header_transactions: 'Transactions',
  header_gov_events: 'Failed events',
  header_issue_refund: 'Issue Refund',
  header_printers: 'Printers',
  header_taxes: 'Taxes',
  header_new_tax: 'Create Tax',
  header_new_user: 'New user',
  header_users: 'Users',
  header_user_location: 'Choose Location',
  header_items: 'Items',
  header_cash_drawer: 'Cash Drawer',
  header_cash_drawer_history: 'Cash Drawer History',
  header_cash_drawer_report: 'Cash Drawer Report',
  header_current_drawer: 'Current Drawer',
  header_end_drawer: 'End Drawer',
  header_pay_in_out: 'Pay In/Out',
  header_cash_sales: 'Cash Sales',
  header_cash_refunds: 'Cash Refunds',
  header_all_taxes: 'All taxes',
  header_new_variation: 'New Variation',
  header_edit_variation: 'Edit Variation',
  header_new_modifier: 'New modifier set',
  header_edit_modifier: 'Edit modifier set',
  header_all_modifiers: 'All Modifiers',
  header_new_discount: 'New Discount',
  header_edit_discount: 'Edit Discount',
  header_discounts: 'Discounts',
  header_all_items: 'All Items',
  header_categories: 'Categories',
  header_new_category: 'New Category',
  header_edit_category: 'Edit Category',
  header_new_item: 'New Item',
  header_edit_item: 'Edit Item',
  header_sign_in: 'Sign In',
  header_new_unit: 'New Unit',
  header_update_unit: 'Edit unit',
  header_locations: 'Locations',
  header_online_store: 'Online Store',
  header_cash_register: 'Electronic Cash Device',
  header_new_register: 'New Electronic Cash Device',
  header_request_demo: 'Request a demo',
  header_add_employee: 'Add employees',
  header_choose_cash_register: 'Choose Electronic Cash Device',
  header_analytics: 'Analytics',
  header_sound_vibration: 'Sound and vibration',
  header_terms_conditions: 'Terms & Conditions',
  header_privacy_policy: 'Privacy statement',
  header_customers: 'Customers',
  header_new_customer: 'New Customer',
  header_new_supplier: 'New Supplier',
  header_new_group: 'New group',
  header_einvoice: 'E-Invoice',
  header_process_einvoice: 'Process (%1$s/%2$s)',
  header_buyer_seller_einvoice: 'Buyer & Seller (%1$s/%2$s)',
  header_reference_einvoice: 'References (%1$s/%2$s)',
  header_delivery_einvoice: 'Delivery (%1$s/%2$s)',
  header_notes_einvoice: 'Notes (%1$s/%2$s)', // added by erik
  header_preview_einvoice: 'Preview (%1$s/%2$s)',
  header_transport_supportive_invoice: 'Transport Info (%1$s/%2$s)',
  header_issuerCarrier_supportive_invoice: 'Issuer and Carrier (%1$s/%2$s)',
  header_supporting_invoice: 'Supporting invoices',

  analytics_sales_label: 'Sales',
  analytics_employees_label: 'Employees',
  analytics_calendar_placeholder: 'Search with dates...',

  //Transaction
  receipt_print_a4: 'Print A4',
  receipt_print_58mm: 'Print 58mm',
  orders_try_again: 'Try again',
  orders_not_found_title: 'We are Sorry!',
  orders_not_found_description:
    'We searched all the list, but this transaction was not found.',
  orders_no_transactions_label: 'There are no transactions made.',
  orders_no_refunds_label: 'There are no refunds made.',
  orders_label: 'Orders',
  refunds_label: 'Refunds',
  receipt_total_method: 'Payment method',

  choose_color_label: 'Choose a color',
  choose_status_label: 'Choose a status',

  orders_calendar_placeholder: 'Search for receipt...',
  orders_options_title: 'Sort',

  orders_button_go_back: 'Go Back',
  orders_button_share_receipt: 'Share',
  orders_refund_items_title: 'Select all items',
  orders_refund_items_description:
    'Item amount includes taxes and discounts where applicable.',
  orders_refund_button_next: 'Next',
  orders_refund_amount_description: 'Total refundable amount is %1$s ALL.',
  orders_refund_amount_label: 'Amount to refund',
  orders_old_refund_amount_label: 'Add amount you want to refund',

  orders_getting_receipt_label: 'Getting receipt details...',
  orders_error_label: 'ERROR',
  orders_printing_interrupted_label: 'Printing process was interrupted.',
  orders_printing_label: 'Printing...',
  orders_success_label: 'Success',

  refund_continue_to_refund: 'Continue to refund',

  receipt_printer_error: "Couldn't connect to printer",
  receipt_printer_error_label:
    'Either connect to a printer or make sure the printer you are already connected is turned on',
  receipt_total: 'Total',
  receipt_subtotal: 'Subtotal',
  receipt_section_total: 'Total',
  receipt_tax: 'VAT (%1$s%)',
  receipt_total_without_tax: 'Total without tax',
  receipt_section_items_service: 'Items and services',
  receipt_section_invoice_validation: 'Invoice Validation',
  receipt_button_invoice_validation: 'Link for Invoice Validation.',
  receipt_button_confirm: 'Confirm',
  receipt_button_print_receipt: 'Print',
  receipt_download_as_pdf: 'Download Pdf',
  receipt_cash_payment: 'Cash payment',
  receipt_noncash_payment: 'Noncash payment',
  receipt_cash: 'Cash',
  receipt_user: 'User',
  receipt_invoice_number: 'Invoice Number',
  receipt_invoice_ordinal_number: 'Invoice ordinal number',
  receipt_label: 'Receipt',
  receipt_label_number: 'Receipt number',
  receipt_button_issue_refund: 'Refund',
  receipt_fic_label: 'FIC',
  receipt_iic_label: 'IIC',
  receipt_reference_fic_label: 'FIC Prim',
  receipt_reference_iic_label: 'IIC Prim',
  receipt_operator_code_label: 'Operator code',
  receipt_software_code_label: 'Software code',
  receipt_business_code_label: 'Bussines unit code',
  receipt_section_primary_invoice: 'Primary invoice',
  receipt_refund_reason: 'Reason for refund',
  receipt_button_creating_refund: 'Creating refund...',
  receipt_drawer_warning_title: 'Cash drawer is not opened',
  receipt_drawer_warning_description:
    'Please open cash drawer to continue with the refund.',
  receipt_button_open_drawer: 'Open Cash Drawer',
  receipt_no_refund_items: 'No items to refund',
  receipt_total_discount: 'Total discount',
  receipt_refund_success: 'Items successfully refunded!',
  receipt_open_printers_button: 'Go to printer settings',
  receipt_discount_line: 'discount',
  receipt_foreign_currency_label: 'Total in %1$s',
  receipt_currency_rate_label: 'Exchange rate',
  receipt_retry_subtitle: 'Event was not synchronised because:',
  receipt_retry_title: 'Synchronise',
  receipt_retry_no_internet: 'No internet connection',
  receipt_retry_technical_problem: 'Technical problem',
  receipt_retry_general_problem: 'Problems with the fiscalization service',
  receipt_retry_boundbook_problem: 'Boundbook Problems',
  receipt_retry_synchronize_button: 'Synchronise',
  invoice_status_success: 'Success',
  invoice_status_failed: 'Failed',
  invoice_synchronising_label: 'Synchronising...',

  refund_label: 'Refund',
  refund_amount_label: 'Refunded amount',
  cash_sales_no_data: 'No cash sales',
  cash_refunds_no_data: 'No cash refunds',
  cart_refunds_no_data: 'No credit card refunds',
  cart_sales_no_data: 'No credit card sales',
  cart_payment_no_data: 'No credit card payment',
  search_employee_event_placeholder: 'Search by employee email...',

  orders_refund_reason_return: 'Return Goods',
  orders_refund_reason_accidental: 'Accidental Charge',
  orders_refund_reason_cancel: 'Cancelled Order',
  orders_refund_reason_fraud: 'Fraudulent Charge',
  orders_refund_reason_other: 'Add other reason...',
  orders_refund_reason_button: 'Refund',
  order_accept_label: 'Accept order',
  order_in_progress_label: 'Mark in progress',
  order_complete_label: 'Mark as completed',

  drawer_current: 'Current Drawer',
  drawer_history: 'Cash Drawer History',
  drawer_calendar_placeholder: 'Search for drawer...',
  drawer_options_title: 'Sort',
  drawer_actual_label: 'Actual in Drawer',
  drawer_difference_label: 'Difference',
  drawer_description_label: 'Description',
  drawer_current_details: 'Current Drawer Details',
  drawer_close_button: 'Close Drawer',
  drawer_confirm_close: 'Confirm Close Drawer',
  drawer_end_button: 'End Drawer',
  drawer_confirm_end: 'Confirm End Drawer',
  drawer_start_button: 'Start',
  drawer_confirm_start: 'Confirm Start',
  drawer_retry_button: 'Retry',
  drawer_starting_amount_label: 'Starting Cash Amount',
  drawer_start_drawer: 'Start Drawer',
  drawer_pay_inout_button: 'Pay In/Out',
  drawer_description_placeholder: 'Drawer Description',
  drawer_id_label: 'ID',
  drawer_started_label: 'Started',
  drawer_starting_date_label: 'Starting Date',
  drawer_started_by_label: 'Started By',
  drawer_ended_label: 'Ended',
  drawer_ended_by_label: 'Ended By',
  drawer_closed_label: 'Closed',
  drawer_closed_by_label: 'Closed By',
  drawer_starting_cash_label: 'Starting Cash',
  drawer_paid_inout_label: 'Paid In/Out',
  drawer_cash_sales_label: 'Cash Sales',
  drawer_cash_refunds_label: 'Cash Refunds',
  drawer_expected_label: 'Expected in Drawer',
  drawer_total_paid_label: 'Total Paid In/Out',
  drawer_paid_out_label: 'Paid Out',
  drawer_paid_in_label: 'Paid In',
  drawer_processing_label: 'Processing...',
  drawer_error_label: 'Error %1$s',
  drawer_label_amount: 'Amount',
  drawer_total_label: 'Total',
  drawer_no_events_label: 'No events',
  drawer_button_confirm_payin: 'Confirm Pay In',
  drawer_button_confirm_payout: 'Confirm Pay Out',
  drawer_button_payout: 'Pay Out',
  drawer_button_payin: 'Pay In',
  drawer_opened_route: 'Opened Drawers',
  drawer_ended_route: 'Ended Drawers',
  drawer_closed_route: 'Closed Drawers',
  drawer_no_paid_in: 'No paid in events',
  drawer_no_paid_out: 'No paid out events',
  drawer_no_end_drawer: 'No end drawers',
  drawer_no_open_drawer: 'No open drawers',
  drawer_end_drawer_label: 'Ended Drawer',
  drawer_open_drawer_label: 'Opened Drawer',
  drawer_date_time_label: 'Date & Time',
  drawer_pay_in_label: 'Deposit',
  drawer_card_pay_in_label: 'Card deposit',
  drawer_pay_out_label: 'Withdrawal',
  drawer_pay_in_reason: 'Deposit in drawer',
  drawer_pay_out_reason: 'Withdraw from drawer',
  drawer_pay_inout_payment_reason: 'Payment receipt',
  drawer_pay_inout_credit_card_reason: 'Credit Card Payment',
  drawer_pay_inout_number: 'Payment receipt number',
  drawer_payment_button: 'Payment',
  drawer_card_sales: 'Card Sales',
  drawer_card_refunds: 'Card Refunds',
  drawer_card_payment: 'Card Payment',

  drawer_open_success: 'Drawer successfully started!',
  drawer_end_success: 'Drawer successfully ended!',
  drawer_close_success: 'Drawer successfully closed!',

  drawer_default_description: 'Description',
  drawer_default_amount: '0.00',

  drawer_options_day: 'Day',
  drawer_options_week: 'Week',
  drawer_options_month: 'Month',
  drawer_not_found: 'No drawers found',

  drawer_currency_all: 'ALL',
  drawer_currency_euro: 'EUR',
  drawer_currency_dollar: '$',

  drawer_continue_to_payment: 'Continue to payment',
  drawer_bottom_disclaimer: 'Credit cart sales, refunds and payments will be not calculated in the total amount of the cash drawer. ',

  gov_calendar_placeholder: 'Search for event by date...',
  gov_options_title: 'Sort',
  gov_select_all_label: 'Select all',
  gov_sync_button_title: 'Synchronize events',
  gov_tabs_title_drawer: 'Drawer',
  gov_tabs_title_pay: 'Pay In/Out',
  gov_tabs_title_order: 'Order',
  gov_swipe_hidden_message: 'Synchronizing...',
  gov_drawer_title: 'Drawer',
  gov_pay_in_title: 'Pay In',
  gov_pay_out_title: 'Pay Out',
  gov_order_title: 'Order',
  gov_processing_description: 'Processing...',
  gov_hidden_label: 'Sync',
  gov_technical_fail_reason: 'Technical problem',
  gov_internet_fail_reason: 'No internet connection',
  gov_fisc_fail_reason: 'Problems with the fiscalization service',
  gov_boundbook_fail_reson: 'Boundbook Problems',
  gov_no_failed_events: 'No unfiscalized events.',
  gov_synchronize_header: 'Synchronize event',
  gov_reason_label: 'Event failed because:',
  gov_select_reason: 'Select a reason',
  gov_button_synchronize: 'Synchronize',
  gov_button_synchronizing: 'Synchronizing...',

  // Checkout
  checkout_screen_title: 'Checkout',
  checkout_title_tab_all: 'All',
  checkout_price_currency_value: '%1$s %2$s',
  checkout_charge_price_currency: 'Charge %1$s %2$s',
  checkout_VAT_percentage: 'VAT (%1$s%):',
  checkount_recover_order_title: 'Pending order',
  checkount_recover_order_description:
    'The last order is not complete.\nDo you want to continue processing it?',
  checkount_recover_order_confirm: 'Yes, continue',
  checkount_recover_order_cancel: 'No, clear the order',
  checkount_device_not_authorized_title: 'Device is not registered!',
  checkount_device_not_authorized_description:
    'Your device is not registered, to make sales you need to active the device',
  checkount_device_not_authorized_confirm: 'Send me to activation!',
  checkount_device_employee_authorization_title: 'You are not authorized!',
  checkount_device_employee_authorization_description:
    'You are not authorized in this device, add yourself at list of employees',
  checkount_device_employee_authorization_confirm: 'Give me access!',
  checkount_device_not_authorized_cancel: "No, I won't sell!",
  checkout_new_release_title: 'New Release',
  checkout_new_release_description: 'New features are waiting for you!',
  checkout_update_button: 'Update e-Biznes',
  checkout_skip_button: 'Skip for now',

  checkout_title_about_item: 'About item',
  checkout_title_choose_variation: 'Choose variation',
  checkout_title_add_modifiers: '%1$s (Choose up to %2$s)',
  checkout_title_add_discount: 'Add Discount',
  checkout_title_select_discount: 'Select Discounts (%1$s selected)',
  checkout_title_add_tax: 'Add Tax',
  checkout_button_add_to_basket: 'Add to cart',
  checkout_button_add_price: 'Add price',
  checkout_button_select_variation: 'Select variation',
  checkout_button_add_tax: 'Add tax',
  checkout_button_remove_item: 'Remove from cart',
  checkout_title_applied_tax: 'Applied tax',
  checkout_button_create_item: 'Create item',
  checkout_no_items_description: "Can't find any item in here",
  checkout_variable_item_title: 'Add a non inventory item',
  checkout_variable_item_description: 'On demand price',
  checkout_list_view_title: 'List View',
  checkout_grid_view_title: 'Grid View',
  checkout_button_save_cart: 'Save Cart',
  checkout_button_clear_cart: 'Clear Cart',
  checkout_variable_label: 'Variable',
  checkout_variable_price_label: ' prices',
  checkout_multiple_variations_label: ' variations',
  checkout_noninventory_label: 'Sell a non-inventory item',
  checkout_payment_label: 'Payment %1$s',
  checkount_error_message: 'Something went wrong',
  checkout_change_label: 'Change',
  checkout_button_pay: 'Pay',
  checkout_drawer_error_message:
    'Please open cash drawer to continue with order.',
  checkout_button_connect: 'Connect',
  checkout_button_skip: 'Skip',
  checkout_print_title: 'Print receipt',
  checkout_printing_description: 'Printing receipt',
  checkout_button_start_new: 'Start a new order',
  checkout_discount_number: '%1$s Discounts',
  checkout_empty_message: 'There are no items in %1$s currency.',
  checkout_change_currency_label: 'Clear cart and change currency',
  checkout_change_currency_title: 'Change currency',
  checkout_currency_clear_description: {
    one: 'There is one item in this cart.\nIn order to change the currency you should clear the existing cart.',
    other: 'There are {{count}} items in this cart.\nIn order to change the currency you should clear the existing cart.',
  },

  cart_search_placeholder: 'Search items...',
  cart_screen_title: {
    zero: 'Cart',
    one: 'Cart (1 item)',
    other: 'Cart ({{count}} items)',
  },
  cart_product_price_details: '%1$s X %2$s %3$s',
  cart_subtotal_label: 'Subtotal:',
  cart_total_discount_label: 'Total Discount:',
  cart_total_label: 'TOTAL:',
  cart_euro_total_label: 'Total in Euro:',

  cart_clear_ttle: 'Clear Cart',
  cart_clear_description: {
    zero: 'There are no items in this cart, all the discounts applied for it will be cleared.\nAre you sure you want to clear it?',
    one: 'There is one item in this cart.\nAre you sure you want to clear it?',
    other: 'There are {{count}} items in this cart.\nAre you sure you want to clear it?',
  },
  cart_clear_confirm: 'Clear Cart',
  cart_clear_cancel: 'Cancel',

  opened_orders_title: {
    zero: 'Opened orders',
    one: 'Opened orders (1 order)',
    other: 'Opened orders ({{count}} orders)',
  },
  opened_orders_section_title: 'Opened orders',
  opened_orders_clear_title: 'Attention!',
  opened_orders_clear_desc_0:
    'You have an opened cart with %1$s item on it.\nDo you want to save it?',
  opened_orders_clear_desc_1:
    'You have an opened cart with %1$s items on it.\nDo you want to save it?',
  opened_orders_clear_save_first: 'Yes, save current cart',
  opened_orders_clear_override: 'No, override current cart',
  opened_orders_clear_cancel: 'Cancel',
  opened_orders_create_new: 'Create new order',
  opened_orders_delete_confirm: 'Delete',
  opened_orders_error: 'Error',
  opened_orders_save_success: 'Current cart successfully saved!',
  opened_orders_get_success: 'Selected order successfully opened!',
  opened_orders_delete_success: 'Order successfully deleted!',
  opened_orders_continue: 'Continue to opened orders',

  order_status: 'Order Status',
  order_choose_status: 'Choose Status',
  order_new_status: 'New',
  order_active_status: 'Active',
  order_in_progress_status: 'In Progress',
  order_completed_status: 'Completed',
  order_cancelled_status: 'Cancelled',

  online_orders_header: 'Online Orders',
  online_orders_empty_list: 'No orders found',
  online_orders_active_item: 'Active',
  online_orders_completed_item: 'Completed',
  online_orders_new_item: 'New',
  online_orders_cancelled_item: 'Cancelled',
  online_orders_pickup_filter: 'Pick up',
  online_orders_curbside_filter: 'Curbside',
  online_orders_shipment_filter: 'Shipment',
  online_orders_delivery_filter: 'Delivery',
  online_orders_digital_filter: 'Digital',
  online_orders_managed_filter: 'Managed Delivery',
  online_orders_search_placeholder: 'Search for online orders...',

  save_items_title: 'Save items to...',
  save_items_save_new: 'Save to a new order',
  save_items_existing_section_title: 'Save items to existing orders',
  save_items_no_existing_orders: 'There are no existing orders',
  save_items_save_existing: 'Save',
  save_items_saving: 'Saving order',
  save_items_retrieving_selected: 'Retrieving selected order',
  save_items_processing: 'Processing',
  save_items_save_success: 'Cart successfully saved!',
  save_items_continue: 'Continue to save order',
  save_non_invetory_item: 'Save at item list',

  payment_price_currency: '%1$s %2$s',
  payment_amount_change: '%1$s %2$s change',
  payment_out_of_total: 'out of %1$s %2$s',
  payment_calculating_change: 'Calculating change',
  payment_save_success: 'Cart successfully saved!',

  payment_select_method: 'Select invoicing method',
  payment_title_type_cash: 'Cash payment',
  payment_title_type_noncash: 'Noncash',
  payment_title_type_card: 'Card payment',
  payment_title_type_order: 'Open tab payment',
  payment_title_type_voucher: 'Voucher payment',
  payment_title_type_check: 'Check payment',
  payment_title_type_other: 'Other payment method',
  payment_label_type_cash: 'Cash',
  payment_label_type_noncash: 'Bank Invoice \n(Non-cash)',
  payment_label_type_card: 'Credit Card',
  payment_label_type_export: 'Export Invoice \n(Non-cash)',
  payment_label_type_order: 'Order',
  payment_label_type_voucher: 'Voucher',
  payment_label_type_einvoice: 'E-Invoice \n(Business)',
  payment_label_type_warehouse: 'Support Invoice',
  payment_label_type_reverse_charge: 'Auto invoice',
  payment_label_type_reverse_charge_label: 'Auto Invoice/Charge',
  payment_label_type_self_invoice: 'Self-Invoice',
  payment_label_type_other: 'Other',
  payment_success_description: 'Invoice successfully completed!',
  payment_cash_helper: 'The maximum amount of payment per transaction in cash between two taxpayers is 150,000 ALL',
  payment_receipt_configuration_title: 'Select receipt configurations',
  payment_receipt_size_header: 'Receipt template size',
  payment_receipt_language_header: 'Receipt language',
  payment_receipt_language_note: 'Receipt will be printed and shared in this language',
  payment_receipt_input_header: 'Receipt format',
  payment_delivery_information_section: 'Delivery details',
  payment_delivery_receiver_label: 'Delivery receiver',
  payment_supplying_period_label: 'Supplying period',

  payment_fiscalizing_description: 'Fiscalizing invoice...',

  payment_title_type_noncash_einvoice: 'E-Invoice payment details',
  payment_iban_label: 'IBAN',
  payment_account_number_label: 'Account holder name',
  payment_bic_label: 'BIC',
  payment_description_label: 'Payment description',
  payment_proceed_button: 'Proceed',
  payment_total_method: 'Payment method',
  payment_total_header: 'Payment details',
  payment_supporting_invoice_title: 'Invoice details',
  payment_select_currency: 'Select other currency',
  payment_currency_title: 'Currency',
  payment_currency_placeholder: 'Search for currency',
  payment_euro_currency_label: '1 Euro = %1$s Lekë',
  payment_none_currency_label: 'No secondary currency',
  payment_usd_currency_label: '1 USD = %1$s Lekë',
  payment_pound_currency_label: '1 GBP = %1$s Lekë',
  payment_none_currency_title: 'None',

  payment_select_vat_label: 'Select VAT',
  payment_banknote_description: 'Banknotes and coins',
  payment_card_description: 'Debit and credit card',
  payment_check_description: 'Bank check',
  payment_voucher_description: 'Voucher',
  payment_company_description: 'Company cards',
  payment_order_description: 'The bill is still unpaid. Will be paid with summary bill.',

  payment_account_description: 'Account transaction',
  payment_factoring_description: 'Factoring',
  payment_compensation_description: 'Compensation',
  payment_waiver_description: 'Debt write-off',
  payment_transfer_description: 'Transfer of rights or debts',
  payment_kind_description: 'In kind payment',
  payment_other_description: 'Other noncash payments',
  payment_tax_free_description: 'Export of the services',
  payment_exportof_goods_description: 'Export of the products',

  payment_self_charge_type_label: 'Type',
  payment_self_charge_reason_label: 'Reason for Auto-Invoice',
  payment_self_charge_reason_placeholder: 'Reason',
  payment_self_charge_reason_agreement: 'Prior agreement between the parties',
  payment_self_charge_reason_domestic: 'Purchase from area farmers',
  payment_self_charge_reason_abroad: 'Purchases from services abroad',
  payment_self_charge_reason_self: 'Self-consumption',
  payment_self_charge_reason_other: 'Other',
  payment_self_charge_supplier_label: 'Supplier',
  payment_self_charge_supplier_button: 'Add supplier',
  payment_self_charge_noncash_description: 'Non-cash Invoice',
  payment_self_charge_cash_description: 'Cash Invoice',
  payment_self_charge_action_invoice: 'Auto Invoice',
  payment_self_charge_charge: 'Reverse Charge',
  payment_self_charge_payment_type: 'Invoice payment type',
  payment_exchange_rate_label: 'Exchange rate',

  einvoice_section_process: 'Process',
  einvoice_section_doc_info: 'Document information',
  einvoice_section_delivery_info: 'Delivery period',
  einvoice_process_type_label: 'Process types',
  einvoice_doc_type_label: 'Document types',
  einvoice_currency_label: 'Currency',
  einvoice_payment_deadline_label: 'Payment Deadline',
  einvoice_start_date_label: 'Start Date',
  einvoice_end_date_label: 'End Date',
  einvoice_effective_vat_label: 'Date code for effective VAT',
  einvoice_payment_button: 'Payment',
  einvoice_buyer_seller_button: 'Buyer & Seller',
  einvoice_process_type_modal_header: 'Type of process',
  einvoice_process_type_search_placeholder: 'Search process type code',
  einvoice_doc_type_modal_header: 'Type of document',
  einvoice_doc_type_search_placeholder: 'Search document type code',
  einvoice_effective_vat_modal_header: 'Date codes for effective VAT',
  einvoice_effective_vat_search_placeholder: 'Search date code',
  einvoice_add_buyer_button: 'Add buyer',
  einvoice_add_seller_button: 'Add seller',
  einvoice_references_button: 'References',
  einvoice_city_postalcode_label: 'City and Postal code',
  einvoice_commercial_name_label: 'Commercial name',
  einvoice_references_buyer: 'Buyer',
  einvoice_references_project: 'Project',
  einvoice_references_contract: 'Contract',
  einvoice_references_order_form: 'Purchase order',
  einvoice_references_sales_order: 'Sales order',
  einvoice_references_additional_docs: 'Supporting document',
  einvoice_references_docs_location: 'Location of external document',
  einvoice_references_docs_description: 'Description of supporting document',
  einvoice_references_buyer_button: 'Buyer & Seller',
  einvoice_references_delivery_button: 'Delivery',
  einvoice_references_reference_header: 'References',
  einvoice_references_document_header: 'Additional documents',
  einvoice_references_document_footer: 'Upload additional documents',
  einvoice_delivery_delivery_header: 'Delivery',
  einvoice_delivery_address_header: 'Address',
  einvoice_delivery_recipient_name: 'Recipient Name',
  einvoice_delivery_date: 'Delivery Date',
  einvoice_delivery_street: 'Street',
  einvoice_delivery_city: 'City',
  einvoice_delivery_postal_code: 'Postal Code',
  einvoice_delivery_state: 'State',
  einvoice_delivery_reference_button: 'References',
  einvoice_delivery_preview_button: 'Preview',
  einvoice_delivery_notes_button: 'Notes',
  einvoice_document_label: 'Document',
  einvoice_process_preview_section: 'Process & Document Information',
  einvoice_doc_type_preview: 'Document type',
  einvoice_file_size_info: 'Maximum upload size is 1 MB.',
  einvoice_preview_process_tab: 'Process & Document Info',
  einvoice_preview_buyerseller_tab: 'Seller & Buyer',
  einvoice_preview_items_tab: 'Items',
  einvoice_preview_references_tab: 'References',
  einvoice_preview_delivery_tab: 'Delivery',
  einvoice_preview_payment_tab: 'Payment Details',
  einvoice_preview_additionalDet_tab: 'Additional information',
  einvoice_preview_total_amount: 'Total net amount',
  einvoice_preview_total_withvat: 'Total amount with VAT',
  einvoice_preview_total_vat: 'Total amount of VAT',
  einvoice_preview_total_withoutvat: 'Total amount without VAT',
  einvoice_preview_total_paid: 'Total amount to be paid',
  einvoice_date_note: 'Note: Dates must be from the same month',
  einvoice_save_modal_title: 'Save invoice',
  einvoice_save_modal_description: 'Save this invoice for later or delete it and start a new order.',
  einvoice_save_modal_button: 'Save invoice',
  einvoice_delete_modal_button: 'Delete invoice',
  einvoice_cancel_modal_button: 'Cancel',
  einvoice_retrieving_selected: 'Retrieving e-invoice',
  einvoice_opcode_error_title: 'Operator code not valid',
  einvoice_opcode_error_description: 'Add the correct operator code to continue with the process.',
  einvoice_opcode_error_button: 'Modify operator code',
  einvoice_payment_bank_account_label: 'Bank account',
  einvoice_payment_seller_error_note: 'Something went wrong! \n We couldn\'t find the seller. Try again to continue.',
  einvoice_payment_buyer_error_note: 'Something went wrong! \n We couldn\'t find the buyer. Try again to continue.',
  einvoice_payment_buyer_seller_error_note: 'Something went wrong! \n We couldn\'t find the buyer and seller. Try again to continue.',
  einvoice_print_button: 'Print e-invoice',

  //bank accounts
  bank_accounts_add_button_label: 'Add bank account',
  bank_accounts_account_holder_name: 'Account holder\'s name',
  bank_accounts_bank_name: 'Bank name',
  bank_accounts_swift_code: 'SWIFT / BIC code',
  bank_accounts_create_page_label: 'New Bank Account',
  bank_accounts_card_info_label: 'Card Information',
  bank_accounts_card_iban_helper1: 'IBAN format for Albania e.g. ',
  bank_accounts_card_iban_helper2: 'AL47123123411234567890123456.',
  bank_accounts_card_swift_helper1: 'SWIFT / BIC Code is different in every bank',
  bank_accounts_card_credit_helper1: 'This will not affect your credit score.',
  bank_accounts_swift_search: 'Search swift code',
  bank_accounts_bank_search: 'Search bank name',
  bank_accounts_empty_list_subtitle: 'No bank accounts registered.',
  bank_accounts_empty_list_title: 'Welcome to you bank accounts directory',
  bank_accounts_note_description: 'Manage your business\' bank accounts',

  //supporting invoices
  supporting_invoice_invoice_type_label: 'Invoice type',
  supporting_invoice_transaction_type_label: 'Transaction type',
  supporting_invoice_invoice_type_search: 'Invoice types',
  supporting_invoice_transaction_type_search: 'Transaction types',
  supporting_invoice_transport_ownership: 'Transport Ownership',
  supporting_invoice_transport_plate: 'License Plate',
  supporting_invoice_vehicle_plate_helper_1: 'License plate format must be ',
  supporting_invoice_vehicle_plate_helper_2: 'e.g. AA123AA',
  supporting_invoice_transport_starting_point: 'Starting Point',
  supporting_invoice_transport_destination_point: 'Destination Point',
  supporting_invoice_transport_starting_address: 'Starting Address',
  supporting_invoice_transport_destination_date: 'Destination Date',
  supporting_invoice_transport_button_question: 'No',
  supporting_invoice_transport_button: 'Transport Info',
  supporting_invoice_transport_destination_address: 'Destination Address',
  supporting_invoice_issuerCarrier_title: 'Issuer & Carrier',
  supporting_invoice_issuerCarrier_header: 'Carrier',
  supporting_invoice_issuerCarrier_id_type: 'ID Type',
  supporting_invoice_issuerCarrier_id_personal: 'Personal ID',
  supporting_invoice_personal_id_helper_1: 'Personal id format must be ',
  supporting_invoice_personal_id_helper_2: 'e.g. L12345678A',
  supporting_invoice_nipt_id_helper_1: 'NIPT format must be ',
  supporting_invoice_issuer_error_message: 'Something went wrong! \n We couldn\'t find the issuer. Try again to continue.',
  supporting_invoice_nipt_id_helper_2: 'e.g. L12345678A',
  supporting_invoice_issuerCarrier_id_type_seach: 'ID Types',
  supporting_invoice_flammable_item_question: 'Are these items flammable or dangerous?',
  supporting_invoice_accompany_goods_questin: 'Is the accompaniment of goods required or not?',
  supporting_invoice_preview_dangerous: 'Dangerous',
  supporting_invoice_preview_accompaniment: 'Accompaniment',
  supporting_invoice_item_descr: '%1$s %2$s',
  supporting_invoice_transport_ownership_search: 'Search for transport ownership',
  supporting_invoice_city_search: 'Search city',
  supporting_invoice_transport_starting_search: 'Search for starting point',
  supporting_invoice_transport_destination_search: 'Search for destination point',
  supporting_invoice_issuerCarrier_id_type_seach_label: 'Search ID type',
  supporting_invoice_start_point_warehouse: 'Warehouse',
  supporting_invoice_start_point_exhibition: 'Exhibition',
  supporting_invoice_start_point_store: 'Store',
  supporting_invoice_start_point_sale: 'Sale point',
  supporting_invoice_start_point_another: 'Third party warehouse',
  supporting_invoice_start_point_customs: 'Customs warehouse',
  supporting_invoice_start_point_other: 'Other',
  supporting_invoice_list_empty_message: 'There are no supporting invoices',
  supporting_invoice_search_placeholder: 'Search for supporting invoice...',
  supportive_invoice_exit_modal_description: 'Your invoice will be lost if you exit now. Are you sure you want to exit?',
  supportive_invoice_yes_option: 'YES',
  supportive_invoice_no_option: 'NO',
  supportive_invoice_information_intro: 'Invoice Details Information about usage for Invoice Type / Transaction Type.',
  supportive_invoice_information_sales: 'Used for the transfer of goods when ownership changes, so they are sold. For the sector of fuels as well as for other sectors of economy. It is also used for exports, ie escort of goods to customs.',
  supportive_invoice_information_examination: 'Used for the fuel sector for cases of equipment and fluid verification by responsible institutions in the field (DPM). About taking fluid samples for verifications.',
  supportive_invoice_information_door: 'Used for the transfer of goods from warehouse to the sales unit (vehicle) for door to door sales.',
  supportive_invoice_information_transfer: 'Used for the transfer of goods (without sector definition) when it does not change ownership, e.g., from warehouse to warehouse, point of sale magazine, shop, etc, and for cases when we transfer a good, item, an object for repair, which we transfer temporarily to the business unit of a the other taxpayer.',

  process_type_p2_description: 'Invoicing deliveries of goods and services based on a contract',
  process_type_p10_description: 'Corrective invoicing',
  process_type_p1_description: 'Invoicing of deliveries of goods and services against purchase orders, based on a contract',
  process_type_p3_description: 'Invoicing the delivery of an incidental purchase order',
  process_type_p4_description: 'Pre-payment',
  process_type_p5_description: 'Spot payment',
  process_type_p6_description: 'Payment in advance of delivery',
  process_type_p7_description: 'Invoices with references to a despatch advice',
  process_type_p8_description: 'Invoices with references to a despatch advice and a receiving advice',
  process_type_p9_description: 'Credit notes or invoices with negative amounts, issued for a variety of reasons including the return of empty packaging',
  process_type_p11_description: 'Partial and final invoicing',
  process_type_p12_description: 'Self billing',

  invoice_type_wtn_description: 'Accompanying invoice without changing ownership',
  invoice_type_sale_description: 'Transport document for sale of fuels',

  transport_ownership_type_owner_description: 'The issuer is the owner of the vehicle',
  transport_ownership_type_third_party_description: 'The third party is the owner of the vehicle',

  id_type_nuis_description: 'NIPT',
  id_type_id_description: 'Personal identification number',

  transaction_type_sales_description: 'Regular type of sales transaction for fuels',
  transaction_type_examination_description: 'Transport document for sale of fuels',
  transaction_type_transfer_description: 'Type of transfer transaction',
  transaction_type_door_description: 'Goods are transferred for the door-to-door sales transaction type',

  document_type_384_description: 'Corrected invoice',
  document_type_388_description: 'Tax invoice',
  document_type_82_description: 'Metered services invoice',
  document_type_325_description: 'Proforma invoice',
  document_type_326_description: 'Partial invoice',
  document_type_380_description: 'Commercial invoice',
  document_type_381_description: 'Credit note',
  document_type_383_description: 'Debit note',
  document_type_386_description: 'Prepayment invoice',
  document_type_394_description: 'Lease invoice',

  date_codes_3_description: 'Invoice document issue date time',
  date_codes_35_description: 'Delivery date/time, actual',
  date_codes_432_description: 'Paid to date',

  variable_item_tax_missing: 'Sorry, your tax could not be applied. You can try again later. If the problem persists, please contact us.',

  //Setting
  settings_tax_label: 'Taxes',
  settings_printers_label: 'Printers',
  settings_tax_description: 'Set and update your taxes',
  settings_printers_description: 'Add and connect printers',
  settings_language_label: 'Language',
  settings_sound_vibration_label: 'Sound and vibration',
  settings_sound_vibration_description: 'Enable/disable sound or vibration',
  settings_language_description: 'Choose your preferred language',
  settings_changePassword_label: 'Password',
  password_succes_title: 'Password succesfully changed!',
  password_succes_message: 'Your password was successfully changed. Login in with your new password.',
  settings_changePassword_description: 'Change your password',
  settings_albanian_label: 'Albanian',
  settings_english_label: 'English',
  settings_locations_label: 'Locations',
  settings_online_store_label: 'Online Stores',
  settings_online_store_description: 'Configure your online stores',
  settings_locations_description: 'View your business units',
  settings_cash_register_label: 'Electronic Cash Device',
  settings_cash_register_description: 'Configure your devices',
  settings_terms_conditions_label: 'Terms & Conditions',
  settings_terms_conditions_description: 'View terms and conditions',
  settings_certificate_label: 'Certificate',
  settings_certificate_description: 'Configure & register your certificate',
  settings_bank_accounts_label: 'Bank Accounts',
  settings_bank_accounts_description: 'Add and update your bank accounts',
  settings_upload_files_description: 'Upload items, discounts and more',
  settings_upload_files_label: 'Upload files',
  settings_privacy_policy_label: 'Privacy statement',
  settings_privacy_policy_description: 'View privacy statement',
  settings_delete_account_label: 'Delete Account',
  settings_delete_account_description: 'Delete your account and data',
  delete_account_success_modal_title: 'Delete request sent successfully!',
  delete_account_success_modal_description: 'Check your email for the next steps.',
  settings_business_code_label: 'Business Unit Code',
  settings_business_code_label_description: 'Manage your business unit code',


  // Location
  location_use_my_location: 'Use my current location',
  location_permission_request_message: 'We need permission to access your location.',
  location_permission_open_settings: 'Open settings',
  location_label_business_name: 'Business name',
  location_label_vat_number: 'NIPT',
  location_label_location: 'Location',
  location_label_email: 'Email',
  location_label_phone: 'Phone',
  location_section_cash_register: 'Electronic Cash Devices',
  location_no_cash_register: 'There are no cash devices for this location',
  location_no_locations: 'No locations found',
  location_note_helper: 'Be careful when changing locations because every operation and event you make will reflect on that location.',
  location_change_button: 'Change location',
  location_search_placeholder: 'Search location',
  location_info_tab_label: 'Info',
  location_info_catalog_title: 'Catalog Configuration',
  location_info_catalog_description: 'Select if you want to use the Business Catalog for this location. If you choose not to use it, you can create your own items in this location.',
  location_info_catalog_config_global: 'Business Catalog',
  location_info_catalog_config_local: 'Location Catalog',
  location_confrim_modal_title: 'Catalog Configuration',
  location_confrim_modal_desc: 'You are changing from a local catalog to the business catalog. The items will be updated to the business catalog version. The item locations are not lost.',

  location_business_about_location_header: 'About Location',
  location_business_note_1: 'NOTE: ',
  location_business_note_2: 'The business unit code can be changed only when you have removed all the devices related to this location.',
  location_business_connected_devices: 'Connected Devices',
  location_business_noncash_invoices: 'Non-Cash Invoices',
  location_business_e_invoices: 'E-Invoices',
  location_business_button: 'Change Business Unit Code',

  edit_business_code_current_header: 'Confirm the current Business Unit code you want to change:',
  edit_business_code_new_header: 'Confirm the new Business Unit code you want to add:',
  edit_business_code_current_input: 'Old Business Unit Code',
  edit_business_code_new_input: 'New Business Unit Code',
  edit_business_tcr_error: 'Business Unit Code is incorrect. Find the current Business Unit Code at the information above.',
  edit_business_new_tcr_error: 'Business Unit Code format is incorrect. The correct format is e.g aa123aa123.',
  edit_business_redirect_devices: 'Take me to devices',
  edit_business_code_label: 'Business Unit Code',
  edit_business_code_enter_prompt: 'Write business unit code',
  edit_business_code_set: 'Set business unit code',

  // Taxes
  taxes_name_default_true: '%1$s (default)',
  taxes_name_default_false: '%1$s',
  taxes_error_label: 'Oops!',
  taxes_info: 'Tax rates can be applied to items and will be automatically calculated at purchase.',
  taxes_label_note: 'NOTE',
  taxes_tax_free_label: 'Exempt from tax',
  taxes_tax_free_reason_section: 'Reason for tax exemption',
  taxes_note: 'Your default tax will be by default the tax of your items.\nEvery other tax that you activate will be secondary, and you can choose to use them for specific items.',
  taxes_change_default_button: 'Change default tax',
  taxes_change_default_info: 'Select the new default tax you want to apply to your business.',
  taxes_change_default_note: 'Be careful, the default tax will be applied to all your new items and non-inventory ones by default. When changing the default tax you will be responsible for selling items with this new tax.\nNote that you can choose only from the active tax, if the tax you want is not listed here go back and activate it.',

  // Sound & Vibration
  sound_vibration_label_sound: 'Sound',
  sound_vibration_label_vibration: 'Vibration',
  sound_vibration_info: 'Activate or deactivate sound and vibration feedback for successfull barcode or QR code scan.',

  taxes_confirm_delete: 'Confirm Delete Tax',
  taxes_not_found_label: 'No tax found with this name.',
  no_taxes_label: 'YOU HAVE NO TAXES.',
  taxes_info_label: 'Tax rates can be applied to items and will be automatically calculated at purchase.',
  taxes_search_placeholder: 'Search for tax...',
  taxes_create_button: 'Create Tax',
  taxes_deleting_notification: 'Deleting tax...',
  taxes_deletion_warning: 'You can not delete a tax that has been applied at least to one item.',
  taxes_select_label: 'Select the taxes that you want to apply in your business.',
  taxes_item_pricing_section: 'Item Pricing',
  tax_exists_label: 'Tax already exists!',
  taxes_item_pricing_description: 'Item pricing allows you to decide whether the tax is added to the final price or included in the item price.',
  taxes_save_button: 'Save',
  taxes_creating_tax_label: 'Creating tax...',
  taxes_inclusion_type1: 'Add tax to item price',
  taxes_inclusion_type2: 'Include tax in item price',
  taxes_note_select: 'Note that you can decide whether the tax is added to the final price or included in the item price.',

  //Printers
  printers_error_description: 'Printer not connected',
  printers_receipt_error_description: 'Connect to printer to print the receipt',
  printers_check_warning_message: 'Please check your printer.',
  printers_button_create: 'Create Printer Station',
  printers_button_search: 'Search a Bluetooth printer',
  printers_button_save: 'Save',
  printers_buttoon_confirm_delete: 'Confirm Delete Printer',
  printers_button_print_test: 'Print test',
  printers_button_search_again: 'Search again',
  printers_button_cancel: 'Cancel',
  printers_button_resume: 'Resume',
  printers_button_discard: 'Discard',
  printers_button_close: 'Close',
  printers_label_learn_more: '%1$sLearn More',
  printers_label_more_supported: 'Learn more about the supported printers.',
  printers_label_no_bluetooth: "Can't find your Bluetooth printer?",
  printers_label_printer_info: 'Refresh the list and if the printer you wanted is not found, make sure the printer is paired.',
  printers_label_no_selected: 'No printer selected',
  printers_label_connect_later: 'Connect printer later',
  printers_input_label_name: 'Printer Name',
  printers_label_printer: 'Printer',
  printers_label_select_printer: 'Select Printer',
  printers_label_printer_setup: 'Printer Set Up',
  printers_modal_resume_title: 'Unsaved Changes',
  printers_modal_resume_description: 'Do you want to resume editing or discard these changes?',
  printers_modal_connection_title: 'Connection failed',
  printers_modal_connection_description: 'This device is either not a printer or your printer is turned off.',
  printers_modal_connecting: 'Connecting to printer',
  printers_modal_search_title: "We couldn't find a Bluetooth printer.",
  printers_modal_search_description: 'Make sure the device is in Bluetooth pairing mode.',
  printers_enabled_section: 'Enabled',
  printers_disabled_section: 'Disabled',
  printers_instructions_first1: '1. Turn the printer ',
  printers_instructions_first2: 'on.',
  printers_instructions_second1: "2. Go to your device's ",
  printers_instructions_second2: 'Settings > Bluetooth.',
  printers_instructions_third1: '3. Turn on Bluetooth and ',
  printers_instructions_third2: 'select the printer.',
  printers_instructions_fourth1: '4. If prompted for a PIN, enter ',
  printers_instructions_fourth2: '%1$s or %2$s.',
  printers_instructions_fifth: '5. Return to this page.',
  printers_empty_message: 'There are no printers registered.',

  // CANCEL ORDER
  cancel_order_title: 'Cancel Order',
  cancel_order_no_items_stock: 'Items not in stock',
  cancel_order_accidental: 'Accidental order',
  cancel_order_reason_label: 'Reason for cancel',
  cancel_order_button_label: 'Cancel Order',
  order_transaction_label: 'Order\'s Transaction',


  //Certificate
  certificate_header_title: 'Register certificate',
  certificate_button_register: 'Register',
  certificate_button_update: 'Update',
  certificate_update_header: 'Update certificate',
  certificate_or_label: 'or',
  certificate_choose_label: 'Choose file',
  certificate_file_format_info: 'File must be .p12 or .pfx',
  certificate_password_placeholder: 'Password',
  certificate_company_information_section: 'Company information',
  certificate_enter_information_label: 'Enter your company information.',
  certificate_nipt_label: 'NIPT',
  certificate_id_label: 'Personal ID',
  certificate_email_label: 'Email',
  certificate_phone_label: 'Phone',
  certificate_upload_label: 'Upload Certificate',
  certificate_password_label: 'Certificate password',
  certificate_button_next: 'Next',
  certificate_confirmation_label: 'Confirmation',
  certificate_confirmation_code_description:
    'We have sent you an SMS with a 5-digits code.',
  certificate_enter_code_label: 'Enter Code',
  certificate_resend_code_label: 'Resend code',
  certificate_button_finish: 'Finish',
  certificate_congratulations_label: 'Congratulations',
  certificate_success_description:
    'Your certificate was successfully registered.',
  certificate_button_done: 'Done',
  certificate_error_label: 'Oops!',
  certificate_error_description:
    'Something went wrong. Try again to register the certificate.',
  certificate_error2_description:
    'Something went wrong. Try again to update the certificate.',
  certificate_button_try_again: 'Try Again',
  certificate_organisation_label: 'Organisation Name',
  certificate_start_date_label: 'Start Date',
  certificate_expiration_date_label: 'Expiration Date',
  certificate_issuer_label: 'Issuer',
  certificate_subject_label: 'Subject',
  certificate_renew_description: 'You can renew your certificate anytime.',
  certificate_upload_description: 'Upload your certificate to start sales.',
  certificate_empty_description: 'No certificate has been uploaded.',
  certificate_info_section: 'Certificate Information',
  certificate_not_registered_title: 'Certificate is not registered',
  certificate_not_valid_title: 'Certificate is not valid',
  certificate_go_button: 'Go to certificate',
  certificate_note_description: 'For more information on the steps click here.',
  certificate_upload_error: 'An error occurred while trying to upload this file',
  certificate_incorrect_format_description: 'The file you are trying to upload is not in the requested format.',
  certificate_password_helper: 'This password is sent to the same email as your certificate.',

  //DELIVERY_OPTIONS

  DELIVERY: 'Delivery',
  SHIPMENT: 'Shipment',
  PICKUP: 'Pickup',
  CURBSIDE: 'Curbside',

  // ORDER DETAILS LABELS
  order_details_last_update: 'Last Update: ',
  order_details_label: 'Order Details',
  order_details_date_placed: 'Date Place',
  order_details_type: 'Type',
  order_details_price: 'Amount',
  order_details_delivery_time: 'Delivery time',
  order_details_lineItems: 'Order items',

  order_details_total_details_label: 'Total Details',
  order_details_total_details_subtotal: 'Subtotal',
  order_details_total_details_total: 'Total ',
  order_details_total_details_vat: 'VAT ',
  order_details_total_details_total_discount: 'Total discount',
  order_details_total_details_total_without_tax: 'Total without tax',
  order_costumer_label: 'Costumer Details',
  order_costumer_NIPT: 'NIPT',
  order_costumer_address: 'Addres',
  order_costumer_name: 'Name',
  order_costumer_country: 'Country',
  order_costumer_email: 'Email',
  order_costumer_phone: 'Phone',
  order_shipment_label: 'Shipment Address',
  order_shipment_name: 'Name',
  order_shipment_phone: 'Phone',
  order_shipment_address: 'Address',
  order_delivery_label: 'Delivery Details',
  order_delivery_carrier: 'Carrier',
  order_invoice_label: 'Invoice Validation',
  order_invoice_link: 'Link for Invoice Validation.',
  order_invoice_iix: 'IIC',
  order_invoice_fix: 'FIC',
  order_invoice_operator_code: 'Operator code: ',
  order_invoice_software: 'Software code: ',
  order_info_label: 'Order Info',
  order_activity_label: 'Activity',
  order_activity_new: 'New Order',
  order_activity_confirmed: 'Order Confirmed',
  order_activity_in_progress: 'Order in Progress',
  order_activity_proccessed: 'Order Proccessed',
  order_activity_completed: 'Order Completed',
  order_activity_cancelled: 'Order Cancelled',




  //Users
  users_active_tag: 'Active',
  users_inactive_tag: 'Inactive',
  users_pending_tag: 'Pending',
  users_activate_button: 'Activate',
  users_deactivate_button: 'Deactivate',
  users_delete_button: 'Delete',
  users_confirm_activate: 'Confirm activating user',
  users_confirm_deactivate: 'Confirm deactivating user',
  users_confirm_delete: 'Confirm deleting user',
  users_no_data: 'No users found.',
  users_all_filter: 'All',
  users_active_filter: 'Active',
  users_inactive_filter: 'Inactive',
  users_pending_filter: 'Pending',
  users_search_placeholder: 'Search users...',
  users_filter_title: 'Filter',
  users_button_create: 'Create user',
  users_choose_location_label: 'Choose Location',
  users_personal_information_section: 'Personal Information',
  users_professional_information_section: 'Professional Information',
  users_first_name_label: 'First name',
  users_last_name_label: 'Last name',
  users_email_label: 'Email',
  users_phone_label: 'Phone',
  users_role_label: 'Role',
  users_seller_label: 'Seller',
  users_location_label: 'Location',
  users_assigned_to_label: 'Assigned to',
  users_status_label: 'Status',
  users_save_button: 'Save',
  users_saving_button: 'Saving...',
  users_existing_user_error: 'You already have a user with this email',
  users_choose_avatar_title: 'Choose avatar',
  users_choose_color_title: 'or color',
  users_add_cash_register: 'Add electronic cash device',
  users_email_usage_message: 'This email address will be used to obtain credentials and will be printed on the invoice when a sale is made.',
  users_operatorcode_format_message: 'The correct format of the operator code is e.g. aa123aa123',
  users_no_cash_register: 'No electronic cash devices assigned to this user.',
  users_create_success: 'User successfully created!',
  users_update_success: 'User updated successfully!',
  users_web_access_label: 'Web Cash Register',
  users_web_access_placeholder: ' Search for Web Cash Register',
  users_api_access_label: 'Api Cash Register',
  users_api_access_placeholder: 'Search for Api Cash Register',
  users_resend_credentials_button: 'Resend Credentials',
  users_resend_credentials_modal_title: 'Credentials sent!',
  users_resend_credentials_modal_description: 'Credentials were sent to the user\'s email. Notify the user to check their email and spam',
  users_resend_credentials_description: 'Credentials will be sent to the user\'s email.',

  users_profile_tab: 'Profile',
  users_analytics_tab: 'Analytics',
  users_acivity_tab: 'Activity',
  users_events_tab: 'Events',

  users_sales_tag: 'Sales',
  users_refund_tag: 'Refunds',
  OperatorCodeSelectLabel: 'or select it on the list below.',
  OperatorCodeEnterPrompt: 'Write operator code',
  OperatorCodeSet: 'Set operator code',
  CodeFormatIncorrect: 'Format is incorrect. Please try again.',

  // Item
  modifiers_count: '%1$s modifiers',
  midifiers_search_placeholder: 'Search For Modifiers...',
  modifiers_button_create: 'Create Modifier',
  modifiers_delete_error: 'Error deleting Modifier',
  modifiers_general_error: 'Error',
  modifier_name_placeholder: 'Modifier name',
  modifier_set_name_placeholder: 'Modifier set name',
  modifiers_button_new: 'Add new',
  modifier_button_add: 'Add Modifier',
  modifier_no_selected_message: 'No selected modifiers',
  modifiers_warning_message: 'You must add at least one modifier.',
  modifiers_attention_name_required: 'Name is Required!',
  modifiers_update_success: 'Modifier successfully updated!',
  modifiers_add_success: 'Modifier successfully added!',
  modifiers_delete_success: 'Modifier successfully deleted!',

  categories_search_plaheholder: 'Search For Categories...',
  categories_button_create: 'Create category',
  categories_delete_error: 'Error deleting Category',
  categories_name_label: 'Name',
  categories_name_placeholder: 'Category Name',
  categories_attention_name_required: 'Name is Required!',
  categories_update_success: 'Category successfully updated!',
  categories_add_success: 'Category successfully added!',
  categories_delete_success: 'Category successfully deleted!',

  discount_amount: '%1$s %2$s',
  discount_percentage: '%1$s%',
  discount_search_placeholder: 'Search For Discounts...',
  discount_button_create: 'Create Discount',
  discount_button_save: 'Save',
  discount_delete_error: 'Error deleting Discount',
  discount_edit_header: 'Edit Discount',
  discount_name_placeholder: 'Discount name',
  discount_value_label: 'Value',
  discount_select_label: 'Select discount',
  discount_button_create_new: 'Create new discount',
  discount_button_apply: 'Apply',
  discount_button_add: 'Add Discount',
  discount_no_selected_message: 'No selected discounts',
  discount_error_message: 'Only numeric values bigger than 0 allowed.',
  discount_attention_name_required: 'Name is Required!',
  discount_update_success: 'Discount successfully updated!',
  discount_add_success: 'Discount successfully added!',
  discount_delete_success: 'Discount successfully deleted!',
  discount_multiple_prices_label: {
    one: 'Discount with 1 price',
    other: 'Discount with {{count}} prices',
  },

  unit_screen_name: 'Unit',
  unit_abbreviation_label: 'Abbreviation',
  unit_search_placeholder: 'Search for unit...',
  unit_precision_section: 'Precision',
  unit_button_create_custom: 'Create custom unit',
  unit_new_cubic: 'Cubic inch (cu in)',
  unit_new_gram: 'Gram (g)',
  unit_button_create: 'Create Unit',
  unit_button_save: 'Save',
  unit_name_abreviation: '%1$s (%2$s)',
  unit_general_error: 'Error',
  unit_attention_name_required: 'Name is Required!',
  unit_attention_abreviation_required: 'Abbreviation is Required!',
  unit_update_success: 'Unit successfully updated!',
  unit_add_success: 'Unit successfully added!',
  unit_delete_success: 'Unit successfully deleted!',

  item_label_all: 'All Items',
  item_label_categories: 'Categories',
  item_label_modifiers: 'Modifiers',
  item_label_discounts: 'Discounts',
  item_label_units: 'Units',
  item_button_create: 'Create Item',
  item_delete_error: 'Error deleting item',
  item_button_save: 'Save Item',
  item_info_tab: 'Item Info',
  item_in_stock_label: ' in stock',
  item_name_label: 'Item name',
  item_name_placeholder: 'Enter item name',
  item_category_label: 'Category',
  item_choose_category_label: 'Choose category',
  item_barcode_label: 'Barcode',
  item_price_inventory_section: 'Price',
  item_unit_type_label: 'Unit Type',
  item_choose_unit_label: 'Unit',
  item_price_label: 'Price',
  item_sku_label: 'SKU',
  item_sku_placeholder: 'None',
  item_stock_label: 'Stock',
  item_input_stock_label: ' in stock',
  item_variations_section: 'Variations',
  item_vat_label: 'VAT',
  item_choose_tax_label: 'Apply taxes',
  item_description_placeholder: 'Item description',
  item_button_add_variation: 'Add variation',
  item_attention_name_required: 'Name is Required!',
  item_update_success: 'Item successfully updated!',
  item_add_success: 'Item successfully added!',
  item_delete_success: 'Item successfully deleted!',
  item_prices_label: {
    other: '{{count}} prices',
  },
  item_tax_type1_title: 'Exemption of type 1',
  item_tax_type2_title: 'Exemption of type 2',
  item_tax_type3_title: 'Sale without VAT',
  item_tax_type4_title: 'Margin Scheme',
  item_tax_type1_description: 'Exemption under Article 51 of the VAT Law.',
  item_tax_type2_description: 'Exemption under Articles 53 and 54 of the VAT Law.',
  item_tax_type3_description: 'Sales without VAT which are exempt under VAT law, except for Articles 51, 53 and 54 of the VAT Law, and is neither a margin scheme nor an export of goods.',
  item_tax_type4_description: 'VAT scheme for travel agents, VAT scheme for second-hand goods, VAT scheme for works of art, articles, etc.',
  tax_exempt: 'VAT-Exempt',

  variation_delete_success: 'Variation successfully deleted!',
  item_dublicated_non_inventory_label: 'Duplicated item name!',
  item_dublicated_non_inventory_desc: 'There is already an item with this name in your catalog. Change the name or deactivate the save option as an  inventory item.',
  tax_exempt: 'VAT-Exempt',

  //Cash Register
  cash_register_note: 'Note',
  cash_register_note_description:
    'The device must be connected to an electronic cash device. This way the user can log in and start selling. Select them for more information.',
  cash_register_pending_label: 'Pending',
  cash_register_active_label: 'Active',
  cash_register_inactive_label: 'Inactive',
  cash_register_name_label: 'Electronic cash device name',
  cash_register_deny_button: 'Deny access',
  cash_register_new_note:
    'In order for the electronic cash device to work successfully, a TCR ID needs to be generated. You are unable to undo the action once it\'s generated.',
  cash_register_tcr_button: 'Generate TCR ID',
  cash_register_tcr_label: 'TCR ID',
  cash_register_about_label: 'About Device',
  cash_register_device_name: 'Device name',
  cash_register_device_type: 'Device type',
  cash_register_device_model: 'Device model',
  cash_register_operating_system: 'Operating system',
  cash_register_device_id: 'Device ID',
  cash_register_yes_button: 'Yes',
  cash_register_modal_description: 'If you delete this cash device, users will no longer be able to access it and its TCR ID will be deleted. This action cannot be undone. Are you sure you want to delete it?',
  cash_register_modal_title: 'Deleting electronic cash device',
  cash_register_search_placeholder: 'Search electronic cash device...',
  cash_register_employees_section: 'Employees',
  cash_register_deactivate_button: 'Deactivate electronic cash device',
  cash_register_activate_button: 'Activate electronic cash device',
  cash_register_delete_button: 'Delete electronic cash device',
  cash_register_remove_button: 'Remove',
  cash_register_deleting_title: 'Deleting electronic cash device',
  cash_register_deactivate_message: 'Careful, if you deactivate this electronic cash device, your users won\'t be able to log in and make sales from this device. Are you sure you want to deactivate it?',
  cash_register_activate_message: 'If you activate this electronic cash device, your users will be able to log in and make sales from this device. Are you sure you want to activate it?',
  cash_register_add_employee: 'Add employees',
  cash_register_empty: 'No electronic cash devices',
  cash_register_tablet_label: 'Tablet',
  cash_register_mobile_label: 'Mobile',
  cash_register_add_employee_message: 'Keep in this list only the employees you want to have access to this electronic cash device.',
  cash_register_add_employee_list: 'Choose the employees you want to have access to this electronic cash device.',
  cash_register_deny_success: 'Device successfully denied!',
  cash_register_deny_error: 'Something wrong happened',
  cash_register_delete_success: 'Electronic cash device successfully deleted!',
  cash_register_deactivate_success: 'Electronic cash device successfully deactivated!',
  cash_register_deactivate_error: "Couldn't deactivate electronic cash device",
  cash_register_tcr_success: 'TCR ID successfully generated!',
  cash_register_delete_error: 'Removing employee from this electronic cash device failed! You can try again later.',
  cash_register_add_error_0: 'Adding %1$s employees to this electronic cash device failed! You can try again later.',
  cash_register_add_error_1: 'Adding %1$s employee to this electronic cash device failed! You can try again later.',
  cash_register_okay_button: 'Okay',
  cash_register_business_code: 'Business Unit Code',
  cash_register_business_code1: '* This information can be obtained on the',
  cash_register_business_code2: ' Self Care portal Central Invoice Platform ',
  cash_register_business_code3: 'in the menu ',
  cash_register_business_code4: 'Fiscalization > Operators ',
  cash_register_business_code4_1: 'Fiscalization > Places of Exercising Business Activity ',
  cash_register_business_code5: ' (e.g. aa123aa123)',
  cash_register_access_label: 'Access of the device',
  cash_register_web_access_label: 'Access from web',
  cash_register_api_access_label: 'Access from api',
  cash_register_user_tab: 'Devices',
  cash_register_current_device: 'Currently logged in this device',
  cash_register_end_date: 'Expiration date of TCR',
  cash_register_cash_invoice_nr: 'Cash Invoice Number',
  cash_register_noncash_invoice_nr: 'Non-Cash Invoice Number',
  cash_register_incorrect_tcr_label: 'TCR ID is incorrect. Find the right TCR ID above.',
  cash_register_remove_tcr_header: 'Remove TCR ID',
  cash_register_remove_tcr_button: 'Remove TCR ID (Electronic Device)',
  cash_register_remove_tcr_actual: 'Actual TCR ID (electronic device)',
  cash_register_remove_tcr_users: 'Users connected',
  cash_register_remove_confirm_subheader: 'Confirm the TCR ID you want to remove:',
  cash_register_remove_confirm_delete: 'Are you sure you want to remove it?',
  cash_register_remove_tcr_descr: 'If you remove the TCR ID (electronic device), the users will no longer be able to access this cash register and will be logged out. The previous transactions will remain. Note that this action cannot be undone.',
  cash_register_manage_busin_code: 'Manage Business Unit Code',
  cash_register_businCode_label: 'Business Unit Code',


  // Request demo
  demo_request_button: 'Request a demo',
  demo_welcome_label: 'Welcome',
  demo_welcome_description: 'This demo will teach you how to:',
  demo_explanation_1: 'Create an order for your customer.',
  demo_explanation_2: 'List the items you sell.',
  demo_explanation_3: 'Open, end, and close your electronic cash device every day.',
  demo_explanation_4: 'Keep track of all your sales and refunds.',
  demo_explanation_5: 'Add and oversee the work of your team.',
  demo_explanation_6: 'Fiscalise an event and get notified if it fails (and why).',
  demo_explanation_7: 'Apply taxes, discounts, etc. to your items and orders.',
  demo_explanation_8: 'Register and update your business\'s certificate online.',
  demo_get_started_button: 'Get started',
  demo_account_information_label: 'Your account information',
  demo_business_information: 'Business Information',
  demo_business_information_label: 'Your business information',
  demo_city_label: 'City',
  demo_company_name: 'Company Name',
  demo_send_button: 'Send',
  demo_address_label: 'Address',
  demo_pin_verification_title: 'PIN Verification',
  demo_pin_verification_description: 'We have sent the 5 digits verification PIN to %1$s',
  demo_success_message: 'The request was sent successfully.',
  demo_success_description: 'Within 24 hours you will receive an email from us with the credentials and then you can access your demo mode.',
  demo_back_login_button: 'Back to Log In',
  demo_error_description: 'Something went wrong. Try again later.',

  //Customers
  customers_label: 'Customers',
  suppliers_label: 'Suppliers',
  customers_all_label: 'All customers',
  suppliers_all_label: 'All suppliers',
  customers_groups_label: 'Groups',
  customers_street_label: 'Street',
  customers_state_label: 'State',
  customers_state_placeholder: 'Search for state...',
  customers_other_information_label: 'Other Information',
  customers_birthdate_label: 'Birthdate',
  customers_group_label: 'Group',
  customer_consent_label: 'Customer consent',
  supplier_consent_label: 'Supplier consent',
  customer_email_receipt_label: 'Send receipt via email.',
  customer_push_notifications_label: 'Push notification on mobile',
  customer_notifiy_new_label: 'Notifications for new things.',
  customer_customer_type_label: 'Customer type',
  customer_supplier_type_label: 'Supplier type',
  customer_new_button_label: 'Create new customer',
  suppliers_new_button_label: 'Create new supplier',
  customer_search_placeholder: 'Search by NIPT, name or phone...',
  customer_business_label: 'Business',
  customer_individ_label: 'Individ',
  customer_individual_label: 'Individual',
  customer_empty_list_title: 'Welcome to your customer directory',
  customer_empty_suppliers_title: 'Welcome to your supplier directory',
  customer_empty_list_subtitle: 'No customer registered',
  customer_empty_suppliers_subtitle: 'No supplier registered',
  customer_search_group_placeholder: 'Search group...',
  customer_new_group_button: 'Create group',
  customer_group_name_label: 'Group name',
  customer_group_type_label: 'Group type',
  customer_new_group_description: 'Add descriptive group names, e.g. SME, Corporates, etc.',
  customer_empty_groups_title: 'Welcome to your customer groups',
  customer_empty_groups_subtitle: 'No group created',
  customer_add_group_button: 'Add groups',
  customer_searching_data: 'Searching for data...',
  customer_select_customer_address: 'Select Address',
  customer_nipt_error: 'This NIPT doesn\'t exist. Try again.',
  customer_checkout_label: 'Customer',
  customer_passport_number_label: 'Passport number',
  customer_vat_number_label: 'VAT number',
  customer_tax_number_label: 'Tax number',
  customer_soc_number_label: 'Social Security Number (SSN)',
  customer_id_label: 'Customer ID',
  supplier_id_label: 'Supplier ID',
  customer_id_search_placeholder: 'Search for ID type...',
  customer_business_group_label: 'Business group',
  customer_group_for_label: 'Group for',
  customer_birdthday_helper_1: 'Birthday format must be ',
  customer_birdthday_helper_2: 'e.g DD/MM/YYYY',
  customer_clear_address_label: 'Clear address',

  //E-Invoice
  einvoice_search_placeholder: 'Search for e-invoice...',
  einvoice_create_button: 'Create e-Invoice',
  einvoice_buyer_filter: 'Buyer',
  einvoice_seller_filter: 'Seller',
  einvoice_delivered_filter: 'Delivered',
  einvoice_in_progress_filter: 'In-Progress',
  einvoice_accepted_filter: 'Accepted',
  einvoice_refused_filter: 'Refused',
  einvoice_registered_filter: 'Delivered',
  einvoice_refunds_filter: 'Refunds',
  einvoice_accept_button: 'Accept',
  einvoice_refuse_button: 'Refuse',
  einvoice_list_empty_message: 'There are no e-invoices',
  einvoice_currency_all: '%1$s ALL',
  DELIVERED: 'Delivered',
  REFUSED: 'Refused',
  ACCEPTED: 'Accepted',
  BUYER: 'Buyer',
  SELLER: 'Seller',
  REGISTERED: 'Delivered',
  IN_PROGRESS: 'In progress',
  CORRECTEDINVOICE: 'Corrective invoice',
  einvoice_search_button: 'Search',
  einvoice_cancel_button: 'Cancel',
  einvoice_party_type_label: 'Party Type',
  einvoice_date_label: 'Date',
  einvoice_amount_label: 'Amount',
  einvoice_receipt_label: 'Receipt',
  einvoice_deadline_label: 'Pay Deadline',
  einvoice_status_label: 'Status',
  einvoice_EIC_label: 'EIC',
  einvoice_share_button: 'Share',
  einvoice_preview_button: 'Preview PDF',
  einvoice_confirm_button: 'Confirm',
  einvoice_confirm_accept_button: 'Confirm accept',
  einvoice_confirm_refuse_button: 'Confirm refuse',
  einvoice_my_invoices_tab: 'My e-Invoices',
  einvoice_all_invoices_tab: 'All Invoices',
  einvoice_buyertin_label: 'Buyer NIPT',
  einvoice_sellertin_label: 'Seller NIPT',
  einvoice_details_info_tab: 'Basic Info',
  einvoice_details_buyerseller_tab: 'Buyer & Seller',
  einvoice_details_process_tab: 'Process',
  einvoice_details_items_tab: 'Items',
  einvoice_details_references_tab: 'References',
  einvoice_details_refunds_tab: 'Corrective invoices',
  einvoice_details_delivery_tab: 'Delivery',
  einvoice_payment_details_tab: 'Payment Details & Total',
  einvoice_details_refund_button: 'Issue Refund',
  einvoice_details_nipt_label: 'NIPT',
  einvoice_details_name_label: 'Name',
  einvoice_details_address_label: 'Address',
  einvoice_details_city_label: 'City',
  einvoice_details_state_label: 'State',
  taxable_amount_label: 'Taxable amount',
  einvoice_discount_amount_receipt_label: 'Discount value',
  einvoice_discount_base_receipt_label: 'Discount base',
  einvoice_discount_percentage_receipt_label: '% of discount',
  einvoice_discount_receipt_header: 'Invoice level discounts',
  einvoice_addition_receipt_header: 'Invoice level extras',
  tax_sum_label: 'Tax amount',

  einvoice_notes_label: 'Notes',
  einvoice_note_button: '+ Add notes',
  einvoice_note_character_limit: 'Maximum 250 characters.',



  //OnBoarding
  onboarding_setup_title: 'Let\'s set you up!',
  onboarding_logout_message_description: 'Are you sure you want to leave without finishing?',
  onboarding_setup_button: 'Let\'s go!',
  onboarding_back_button: 'Back',
  onboarding_certificate_title: 'Upload your Business Certificate',
  onboarding_device_title: 'Configure your Electronic cash device',
  onboarding_operator_code_title: 'Add your operator code',
  onboarding_operator_code_label: 'Enter your operator code',
  onboarding_sales_declaration_title: 'Sales will be automatically declared in real time!',
  onboarding_internet_title: 'Uninterrupted internet connection powered by Vodafone.',
  onboarding_reports_title: 'Daily and monthly sales reports.',
  onboarding_congatulations_title: 'Congratulations!',
  onboarding_account_ready: ' Your account is ready',
  onboarding_motto: 'Together we can!',
  onboarding_user_detail_label: 'User Details',
  onboarding_terms_condition_1: 'I accept ',
  onboarding_terms_condition_2: 'Terms & Conditions.',
  onboarding_terms_condition_3: 'of eBiznes',
  onboarding_permission_1: 'I accept ',
  onboarding_permission_2: 'Privacy Policy ',
  onboarding_permission_3: 'politics',
  onboarding_terms_privacy_label: 'eBiznes Terms and \nPrivacy politics',
  onboarding_terms_privacy_header: 'Terms and Privacy Policy',
  onboarding_terms_privacy_link: 'https://api-web-vfal.vodafone.com/file/eBiznes/Privatesise_eBiznes_EN.html',
  onboarding_permission_link: 'https://api-web-vfal.vodafone.com/file/eBiznes/Licence_Software_Agreement.html',
  onboarding_terms_condition_description: 'To continue using eBiznes services, you must accept the Terms & Conditions and Privacy Policy. \nTo read the relevant details, click on the link next to it.',
  onboarding_cert_warning_header: 'Certificate expiring',
  onboarding_cert_warning_title: 'Certificate expiring soon!',
  onboarding_cert_warning_desc_1: 'Certificate expiring soon. To ensure successful fiscalization of future invoices, you must renew your certificate by reapplying in e-Albania',
  onboarding_cert_warning_desc_2: 'After receiving your certificate by email:',
  onboarding_cert_warning_desc_3: '- Login to eBiznes\n- Go to menu Settings/Certificate\n- Select Modify Certificate and upload new certificate',
  onboarding_cert_warning_skip: 'Skip for now',

  //Reports
  reports_article_summary_title: 'Article \n summary',
  reports_business_turnover_title: 'Business \n turnover',
  reports_analytical_sales_title: 'Analytical \n sales',
  reports_locations_button_title: 'Select business unit',
  reports_today_date_title: 'Today',
  reports_week_date_title: 'This week',
  reports_month_date_title: 'This month',
  reports_custom_date_title: 'Custom',
  reports_report_type_title: 'Choose report type',
  reports_business_location_title: {
    zero: 'Select location',
    one: 'Select business locations ({{count}} selected)',
    other: 'Select business locations ({{count}} selected)',
  },
  reports_business_location_screen_counter: {
    one: '{{count}} location selected',
    other: '{{count}} locations selected',
  },
  reports_date_title: 'Select date',
  reports_employees_filter_title: 'Select employees',
  reports_employees_select_title: {
    zero: 'Select employees',
    one: 'Select employees ({{count}} selected)',
    other: 'Select employees ({{count}} selected)',
  },
  reports_employees_select_screen_counter: {
    one: '{{count}} employee selected',
    other: '{{count}} employees selected',
  },
  report_clear_filter_warning_title: 'Generate a new report',
  report_clear_filter_warning_description: 'You will lose the current reports and start a new one',
  report_clear_filter_warning_yes: 'Yes, start a new report',
  reports_download_button: 'Download',
  reports_generate_button: 'Generate report',
  reports_generated_section: 'Reports generated',
  reports_filer_clear: 'Clear filters',
  reports_date_note: 'Selected dates must be within the same month',

  upload_type_note_description: 'You can import items from a CSV file from your device or import from the general catalog',
  upload_type_config_upload: 'Upload CSV File',
  upload_type_config_import: 'Import from general catalog',
  upload_type_config_label: 'Import from',

  upload_items_header: 'Import Items (%1$s/2)',
  upload_categories_header: 'Import Categories (%1$s/2)',
  upload_sales_header: 'Import Sales (%1$s/2)',
  upload_items_note_text: 'Import items from your Excel file and make sure they are in the correct format.\n\nIf you don\'t have the right format download it here and complete it with all your items.',
  upload_categories_note_text: 'Import categories from your Excel file and make sure they are in the correct format.\n\nIf you don\'t have the right format download it here and complete it with all your categories.',
  upload_sales_note_text: 'Import sales from your Excel file and make sure they are in the correct format.\n\nIf you don\'t have the right format download it here and complete it with all your sales.',
  upload_items_upload_file: 'Upload File',
  upload_items_upload_file_note: 'File must be .xlsx',
  upload_items_file_details: 'File Details',
  upload_items_uploading: 'Uploading...',
  upload_items_incorrect_format_label: 'Incorrect file format!',
  upload_items_incorrect_format_message: 'There are some issues you need to fix in your file.There are:',
  upload_items_incorrect_format_message1: 'Try correcting your file and upload it again.',
  upload_items_empty_list: 'There are currently no items in your item list.',
  upload_items_empty_header: 'Import Items',
  upload_items_type: 'Choose upload type',
  upload_items_item_option: 'Items',
  upload_items_categories_option: 'Categories',
  upload_items_sales_option: 'Sales',
  upload_items_continue_button_option: 'Continue upload',
  upload_items_keep_duplicate_radio: 'Keep all items',
  upload_items_replace_duplicate_radio: 'Replace existing items',
  upload_items_duplicate_desription: 'One or more items already exist in this location. Do you want to replace the existing items with new versions or keep them both?',
  upload_items_replace_title: 'Upload options',
  upload_items_success_title: 'Import finished successfully!',
  upload_items_cancel: 'Cancel import',

  privacy_policy_link: 'https://api-web-vfal.vodafone.com/file/eBiznes/Privatesise_eBiznes_EN.html',

  delete_account_title: 'Delete my eBiznes Account',
  delete_account_description_admin: 'If you request the deletion of the account, you will lose your business data, transaction history, along with the data of employees and partners you have registered and you can no longer log in to the application.\n\nDeleting the account does not relieve you of the contractual payment obligations for this service. This request must be made in writing through your dedicated agent or at one of the Vodafone stores.\n\nAfter receiving this request, you will be instructed by an email on the next steps.\n',
  delete_account_description_seller: 'If you request the deletion of the account, you will lose the transaction history and you can no longer log in to the application.\n\nThis request will go to the eBiznes admin account for your entity.',
  delete_account_contact_section: 'If you are having any trouble that we can help you with, let us know at:',
  delete_account_reason_title: 'Tell us why you\'re deleting your account',
  delete_account_reason_description: 'Tell us more',
  delete_account_reason_placeholder: 'Type here...',
  delete_account_button_title: 'Request account deletion',
  delete_account_reason_closed_bussiness: 'Closed my business',
  delete_account_reason_useless_app: 'I don\'t find the app useful',
  delete_account_reason_other: 'Other',
  delete_account_reason_leave: 'Resign',
  delete_account_modal_title: 'Request Deletion',
  delete_account_modal_description: 'Are you sure you want to request your account deletion? \nOnce you do this you will be notified in your email for the next steps.',
  delete_account_modal_delete: 'Yes, request deletion',

  product_catalog_title: 'Product Catalog',
  product_catalog_catalog: 'Catalog',
  product_catalog_general_business: 'General Business',
  product_catalog_general_note: 'Every item, category, discount and modifier you add to the  general catalog will appear in all the locations that use this  as their catalog.',
  product_catalog_location_note: 'Every item, category, discount and modifier you add to this  catalog will only appear to this location.',

  old_invoices_button: 'Old Invoices',
  gov_invoices_header: 'Search Invoice',
  gov_invoices_subheader: 'Search for previous invoices:',
  gov_invoices_invoice_date: 'Invoice Date',
  gov_invoices_invoice_total: 'Invoice Total',
  gov_invoices_taxpayer_name: 'Tax payer Name',
  gov_invoices_taxpayer_nipt: 'Tax payer NIPT',
  gov_invoices_not_found: 'No invoices found',


  // added by Erik
  change_location_select_prompt: 'Select Location',
  change_location_note: 'Note',
  change_location_note_text: 'Be careful when changing locations because every operation and event you make will reflect on that location.',
  change_location_button_label: 'Next',
  change_location_changing_text: 'Changing to ',

  change_devices_select_prompt: 'Select electronic device',
  change_devices_note: 'Note',
  change_devices_note_text: 'Be careful when choosing the electronic device because every operation will be registered to that device.',
  change_devices_button_label: 'Switch',
  change_devices_search_placeholder: 'Search electronic device',


  /**
   * Backend error messages
   */
  GeneralError: 'Oops, something went wrong. Please try again later.',
  DiscountGeneralError: 'Something went wrong. The discount was not applied to the item. Please try again later.',
  DiscountNameDuplicate: 'The discount name you entered already exists. Please enter a new discount name to proceed.',
  DiscountIsUsedByItems: 'This discount is applied to at least one item so you can not delete it.',
  TaxGeneralError: 'Something went wrong. The action on the tax was not performed. Please try again later.',
  TaxNameDuplicate: 'The tax name you entered already exists. Please enter a new tax name to proceed.',
  TaxIsUsedByItems: 'This tax is applied to at least one item so you can not delete it.',
  ModifierListGeneralError: 'Something went wrong. The action on the modifier was not performed. Please try again later.',
  ModifierListNameDuplicate: 'The modifier name you entered already exists. Please enter a new modifier name to proceed.',
  ModifierListIsUsedByItems: 'This modifier is applied to at least one item, so you can not delete it.',
  MeasurementUnitGeneralError: 'Something went wrong. The action on the measurement unit was not performed. Please try again later.',
  MeasurementUnitNameDuplicate: 'The measurement unit name you entered already exists. Please enter a new one to proceed.',
  MeasurementUnitIsUsedByItems: 'This measurement unit is applied to at least one item, so you can not delete it.',
  CategoryGeneralError: 'Something went wrong. The action on the category was not performed. Please try again later.',
  CategoryNameDuplicate: 'The category name you entered already exists. Please enter a new category name to proceed.',
  CategoryIsUsedByItems: 'You can not delete a category that has at least one item in it. Remove all items and then you can delete the category.',
  ItemGeneralError: 'Something went wrong. The action on the item was not performed. Please try again later.',
  ItemNameDuplicate: 'The item name you entered already exists. Please enter a new item name to proceed.',
  ItemBarcodeDuplicate: 'The barcode you entered already belongs to an item. Please enter a new barcode for this item.',
  OrderGeneralError: 'Something went wrong. The action on the order was not performed. Please try again later.',
  OrderInvalidMoneyAmount: 'The monetary amount you have entered is not valid. Please enter a valid amount and try again.',
  OrderNoOpenDrawer: 'We can not seem to find this order. Please try again.',
  CashDrawerShiftGeneralError: 'Something went wrong. The action on the cash drawer was not performed. Please try again later.',
  CashDrawerShiftUnexpectedState: "This cash drawer has already been closed, so its status can't change.",
  CashDrawerShiftAmountError: 'The amount you have entered is not valid. Please enter a valid amount and try again.',
  CashDrawerShiftUnauthorized: 'You are not authorized to perform this action!',
  CashDrawerShiftEventGeneralError: 'Something went wrong. The action on the cash drawer was not performed. Please try again later.',
  CashDrawerShiftEventAmountError: 'The amount you have entered is not valid. Please enter a valid amount and try again.',
  TransactionGeneralError: 'Something went wrong. The action on the transaction was not performed. Please try again later.',
  RefundGeneralError: 'Something went wrong. The action on the refund was not performed. Please try again later.',
  RefundInvalidMoneyAmount: 'The refund amount you have entered is not accurate. Please enter a valid amount and try again.',
  RefundNoOpenDrawer: 'We can not find the order you want to refund. Please try again.',
  RefundIdempotentParameterMismatchException: 'Your request to perform this refund has already been sent. Executing it again would only duplicate it.',
  EmployeeUserAlreadyExist: 'The email you entered already exists. Please enter a new one to proceed.',
  EmployeeGeneralError: 'Something went wrong. The action on employee was not performed. Please try again later.',
  CashRegisterGeneralError: 'Something went wrong. The action on the electronic cash device was not performed. Please try again later.',
  CashRegisterNameDuplicate: 'The electronic cash device name you entered already exists. Please enter a new name to proceed.',
  CashRegisterTCRExist: 'The electronic cash device TCR you entered already exists. Please generate a new electronic cash device TCR to proceed.',
  CashRegisterTCRGeneralError: 'Something went wrong. Please try to generate the TCR ID again.',
  OrderInvalidStatus: 'The selected status is not valid.',
  CashRegisterNotActive: 'This electronic cash device is not active.',
  CashRegisterUnknown: 'This electronic cash device is not registered.',
  CashRegisterNoAccessEmployee: 'There are no employees assigned to this electronic cash device.',
  CertificateNotExist: 'You do not have a business certificate. Please upload your business certificate to continue working.',
  CertificateNotValid: 'Your business certificate is not valid. Please upload your business certificate to continue working.',
  CustomerGeneralError: 'Something went wrong. The action on the customer was not performed. Please try again later.',
  CustomerGroupGeneralError: 'Something went wrong. The action on the customer group was not performed. Please try again later.',
  CustomerGroupNameDuplicate: 'The group name you entered already exists. Please enter a new group name to proceed.',
  CustomerGroupIsUsedByCustomers: 'You can not delete a group that has at least one customer in it. Remove all customers and then you can delete the group.',
  CustomerIssuerNUISDuplicate: 'A business customer with this NIPT and location already exists. Please enter a new NIPT and location to proceed.',
  SupplierIssuerNUISDuplicate: 'A business supplier with this NIPT and location already exists. Please enter a new NIPT and location to proceed.',
  MappingTemplate: 'The size of the image you are uploading is too large.',

  DifferentNipts: 'Something wrong happened while trying to upload the certificate with this NIPT. Please check your certificate and try again.',
  IncorrectPhoneNumberFormat: 'The phone number is wrong! Please make sure you enter a valid phone number following the format 06XXXXXXXX.',
  PinLimitReached: 'You have reached the limit of PINs you can enter. Please try again later.',
  EmptyCertificate: 'An error occurred while trying to upload this certificate. Please check your certificate and try again.',
  InvalidPinException: 'The PIN you entered is invalid. Please try again.',
  TaxVerifyTokenInvalid: 'The PIN you entered is invalid. Please try again.',
  InvalidNiptOrPersonalId: 'The NIPT is not valid. Please try again.',
  WrongPassword: 'The password you entered for this certificate is not correct. Please try again.',
  CouldNotSave: 'An error occurred while trying to upload this certificate. The certificate was not uploaded. Please try again later.',
  InvalidCertificate: 'An error occurred while trying to upload this certificate. Please check your certificate and try again.',
  InvalidPhoneNumber: 'The phone number is wrong! Please make sure you enter a valid phone number following the format 06XXXXXXXX.',
  ChangeEinvoiceStatusRefusedError: 'An error occurred while trying to refuse this e-invoice. The e-invoice was not refused. Please try again later.',
  ChangeEinvoiceStatusAcceptedError: 'An error occurred while trying to accept this e-invoice. The e-invoice was not accepted. Please try again later.',
  EmployeeOperatorCodeInvalid: 'This operator code is not valid. Please, make sure you have the correct operator code.',
  TaxpayerDoesNotExist: 'Something went wrong. Please, make sure you have the correct operator code.',

  FailedToRecreatePdf: 'Sorry, something went wrong. We are working to get the problem fixed. Please try again later.',
  FiscalTaxError: 'Sorry, something went wrong. We are working to get the problem fixed. Please try again later.',
  IncorrectInvoiceIdNumber: 'Sorry, something went wrong. We are working to get the problem fixed. Please try again later.',
  InvoiceIssueDateTimeReferenceInvalid: 'Sorry, something went wrong. We are working to get the problem fixed. Please try again later.',
  NegativeValuesOnlyInSpecialCases: 'Sorry, something went wrong. We are working to get the problem fixed. Please try again later.',
  SoftCodeMustExistToRegisterFirstTcr: 'Sorry, something went wrong. We are working to get the problem fixed. Please try again later.',
  MaintainerCodeMustExistToRegisterFirstTcr: 'Sorry, something went wrong. We are working to get the problem fixed. Please try again later.',
  TcrTypeMustExistToRegisterFirstTcr: 'Sorry, something went wrong. We are working to get the problem fixed. Please try again later.',
  InvalidPaymentMethod: 'Sorry, something went wrong. We are working to get the problem fixed. Please try again later.',
  InvalidTVSHValue: 'Sorry, something went wrong. We are working to get the problem fixed. Please try again later.',
  AddrFiledMustExistWhenSellerIsForeign: 'Sorry, something went wrong. We are working to get the problem fixed. Please try again later.',
  AllBuyerFieldsMustExistInSelfChargeInvoice: 'Sorry, something went wrong. We are working to get the problem fixed. Please try again later.',
  InvalidIssueDateTimeInvoiceFiled: 'Sorry, something went wrong. We are working to get the problem fixed. Please try again later.',
  InvalidAttributesForSupplyDate: 'Sorry, something went wrong. We are working to get the problem fixed. Please try again later.',
  TotalCostAndMethodPaymentDontCorrespond: 'Sorry, something went wrong. We are working to get the problem fixed. Please try again later.',
  EinvoiceMustBeOfTypeNonCash: 'Sorry, something went wrong. We are working to get the problem fixed. Please try again later.',
  RevokedIicMustNoteBeTheSameAsActualIic: 'Sorry, something went wrong. We are working to get the problem fixed. Please try again later.',
  ThirdPartyDeclarationForVehOwner: 'Sorry, something went wrong. We are working to get the problem fixed. Please try again later.',
  CashDepositInvalidChangeDateTime: 'Sorry, something went wrong. We are working to get the problem fixed. Please try again later.',
  WtnInvalidIssueDateTime: 'Sorry, something went wrong. We are working to get the problem fixed. Please try again later.',
  UnsupportedEinvoice: 'Sorry, an error occurred while trying to create this e-invoice. The e-invoice was not created. Please try again later.',
  XmlSchemaValidationError: 'Sorry, something went wrong. We are working to get the problem fixed. Please try again later.',
  UnauthorizedException: 'Sorry, something went wrong. We are working to get the problem fixed. Please try again later.',

  BankAccountIbanDuplicate: 'A bank account with this IBAN already exists! Please try a new one.',
  BankAccountGeneralError: 'Something went wrong. The action on the bank account was not performed. Please try again later.',
  RealVatDifferentThanClaimed: 'VAT is different from what is claimed for your business.',

  BankAccountNotValidSwiftCode: 'The Swift code you entered is invalid. Please check it and try again.',
  RefundCustomerMissing: 'Buyer is missing. Please make sure you have included a buyer for this operation.',
  OrderCustomerMissing: 'Buyer is missing. Please make sure you have included a buyer for this operation.',
  OrderExportTaxLineItem: 'Items tax mismatch with invoicing type. Please check items tax and try again.',
  ReportsGeneralError: 'Sorry, something went wrong. We are working to get the problem fixed. Please try again later.',

  EInvoiceCustomerError: 'You can not make an e-invoice for a buyer with a foreign NUIS. Make sure the buyer has an albanian NUIS.',
  SupplierNUISMismatchPattern: 'Issuer NUIS does not match the correct pattern.',
  SupplierEmailMismatchPattern: 'Email does not match the correct pattern.',
  SupplierArgumentInvalid: 'The arguments provided are not valid.',
  CustomerNUISMismatchPattern: 'Issuer NUIS does not match the correct pattern.',
  CustomerEmailMismatchPattern: 'The arguments provided are not valid.',
  CustomerArgumentInvalid: 'Email does not match the correct pattern.',

  EmployeeLocationMismatchError: 'You are trying to connect this employee to a device from another location. You can not proceed with this action. Try connecting with a device from the same location as the employee.',
  CashRegisterLocationMismatchError: 'The employee you are trying to add has a different location from the location of the device. You can not continue with this action. Make sure the employee and the device have the same location.',
  EntitiesLocationMismatchError: 'You can not proceed with this action. Seems like the user and the device used are not in the same location. They should be registered in the same location to continue with this action.',
  CashRegisterTCRNotDeleted: 'You still have connected devices to this Business Unit Code. Remove them all and try this again. Click below to see all the connected devices.',
  UsedBusinessUnitCodeError: 'You can not use any of your old Business Unit Codes. Make sure to add the new Business Unit Code.',

  CashRegisterNotFound: 'We can not find a Cash Register with this ID.',
  CashRegisterIncorrectTCR: 'This TCR ID is incorrect. Find the right TCR ID above.',
  CashRegisterTCRDeleted: 'This TCR ID has already been deleted.',
  CashRegisterTCRDeleteError: 'The TCR ID of the admin can not be deleted before the Business Unit Code is updated.',
  CashRegisterBusinessUnitIdOrIssuerNUISNotFound: 'We can not find this Business Unit Code. Please check again.',
}

export default en
