import React from 'react'
import { SafeAreaView } from 'react-native'
import { useTheme } from 'react-native-themed-styles'
import PropTypes from 'prop-types'

import { Header } from '../../Components'

import images from '../../Themes/Images'

import themedStyles from './EInvoiceDetails.Styles'

import GovEInvoiceDetails from './GovInvoices'
import MyEInvoiceDetails from './MyInvoices'

function EInvoiceDetails({
  route: {
    params: {
      id,
      title,
      status,
      isMyInvoice,
      partyType,
    },
  },
  navigation: { goBack, addListener, navigate },
}) {
  const [styles] = useTheme(themedStyles)

  const renderContainer = () => (isMyInvoice
    ? <MyEInvoiceDetails
      id={id}
      title={title}
      status={status}
      addListener={addListener}
      navigate={navigate}
    />
    : <GovEInvoiceDetails
      id={id}
      title={title}
      status={status}
      partyType={partyType}
      addListener={addListener}
      navigate={navigate}
    />
  )
  return (
    <SafeAreaView style={styles.container}>
      <Header
        image={images.closeIcon}
        title={title}
        onPress={() => goBack()}
      />
      {renderContainer()}
    </SafeAreaView>
  )
}
EInvoiceDetails.propTypes = {
  route: PropTypes.object,
  navigation: PropTypes.object,
}

export default EInvoiceDetails
