import React, { useRef, useState, useEffect } from 'react'
import { View, TouchableOpacity, Animated, FlatList, TextInput } from 'react-native'
import PropTypes from 'prop-types'
import { useTheme } from 'react-native-themed-styles'
import { useQuery } from '@apollo/client'

import {
    Button,
    Icon,
    Header,
    Text,
    ListLoader,
    RadioInput,
} from '../../Components'
import ContentManager from '../../Utils/ContentManager'


import themedStyles, { itemStyles } from './NewLocation.Styles'

import images from '../../Themes/Images'
import { colors } from '../../Themes'

import { LIST_LOCATIONS } from './NewLocation.Schema'


function SelectableLocation({
    businUnitCode,
    name,
    onPress,
    selected,
    id,
}) {
    const [styles] = useTheme(itemStyles)
    const anim = useRef(new Animated.Value(1)).current
    const animate = value => {
        Animated.spring(anim, {
            toValue: value,
            useNativeDriver: true,
        }).start()
    }

    return <Animated.View style={[
        styles.container,
        { transform: [{ scale: anim }] },
    ]}>
        <TouchableOpacity
            onPress={onPress}
            onPressIn={() => animate(1.05)}
            onPressOut={() => animate(1)}
            style={styles.touchable}
        >
            <View style={styles.infoContainer}>
                <View style={styles.descContainer}>
                    <Text i18nKey={name} numberOfLines={1} />
                    <Text
                        i18nKey={businUnitCode}
                        weight="light"
                        color={colors.secondaryText}
                        size="footnote"
                        numberOfLines={1}
                    />
                </View>
                <View style={styles.priceContainer} >
                    <View pointerEvents="none" style={styles.radioInput}>
                        <RadioInput
                            index={id}
                            selected={selected}
                            onPress={onPress}
                            style={styles.selector}
                            color={colors.secondaryAccent}
                        />
                    </View>
                </View>
            </View>

        </TouchableOpacity>
    </Animated.View>
}
SelectableLocation.propTypes = {
    name: PropTypes.string,
    businUnitCode: PropTypes.string,
    onPress: PropTypes.func,
    id: PropTypes.string,
    selected: PropTypes.string,
}


function NewLocation({ route, navigation }) {
    const {
        goBack = navigation.goBack,
    } = route.params
    const [selectedLocation, setSelectedLocation] = useState({'businUnitCode': 'ws552dv643', 'id': '1nbSR8nnnfvEhu15WNCz6AonLwt', 'name': 'Pica Sindi'})
    const [styles] = useTheme(themedStyles)
    const {translate} = ContentManager
    const [searchValue, setSeachValue] = useState('')
    const [locationList, setLocationList] = useState({
        locationLists: [],
        loading: true,
    })

    const { data } = useQuery(LIST_LOCATIONS, {
        variables: {
            nextToken: '',
        },
    })

    useEffect(() => {
        setLocationList((prev) => ({
            ...prev,
            loading: true,
        }))
        if (data) {
            const listData = data?.listLocations?.locations
            setLocationList({
                locationLists: [...listData],
                loading: false,
            })
        }
    }, [data])

    const renderLocationItem = ({ item, index }) => {
        return (<SelectableLocation
            {...item}
            selected={selectedLocation?.id}
            onPress={() => {
                setSelectedLocation(item)
            }} />)
    }

    const renderItemSeparatorComponent = () => <View style={styles.separator} />

    const renderListHeaderComponent = () => {
        return (
            <View style={styles.noteWrapper}>
                <View style={styles.infoWrapper}>
                    <Icon size={20} source={images.info} style={styles.icon} />
                    <Text weight="bold" i18nKey="cash_register_note" />
                </View>
                <Text size="footnote" weight="light" i18nKey="location_note_helper" />
            </View>
        )
    }

    const keyExtractor = (_, index) => `${index}`

    return (
        <View style={styles.container}>
            <Header
                title="header_locations"
                image={images.chevronLeft}
                onPress={() => goBack()}
            />
             <View style={styles.searchContainer}>
          <Icon source={images.search} style={styles.searchicon} />
          <TextInput
            placeholder={translate('location_search_placeholder')}
            placeholderTextColor={colors.placeholder}
            style={styles.input}
            value={searchValue}
            onChangeText={(text)=>{ setSeachValue(text)}}
          />
            <TouchableOpacity onPress={() => { setSeachValue('') }} style={styles.iconContainer}>
              <Icon source={images.close} style={styles.searchicon} />
            </TouchableOpacity>
        </View>
            <FlatList
                data={locationList.locationLists}
                contentContainerStyle={styles.container}
                ListHeaderComponent={renderListHeaderComponent}
                keyExtractor={keyExtractor}
                renderItem={renderLocationItem}
                ListFooterComponent={locationList.loading && <ListLoader
                    loading={locationList.loading}
                    isInitial={locationList.locationLists.length === 0}
                />}
                ListEmptyComponent={locationList.loading ? null : <View style={styles.emptyComponent}>
                    <Text i18nKey="customer_empty_list_title" />
                    <Text weight="light" i18nKey="customer_empty_list_subtitle" />
                </View>}
                ItemSeparatorComponent={renderItemSeparatorComponent}
            />
            <Button style={styles.button} title="location_change_button" variant="disabled" />
        </View>
    )
}

NewLocation.propTypes = {
    route: PropTypes.object,
    navigation: PropTypes.object,
}

export default NewLocation
