import { StyleSheet } from 'react-native'

import colors from '../../Themes/Colors'

export default StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.background,
  },
  sceneContainer: {
    flex: 1,
  },
  tabContainerStyle: {
    backgroundColor: colors.white,
    elevation: 0,
    shadowRadius: 0,
    shadowOpacity: 0,
    marginBottom: 0,
    height: 50,
  },
  button: {
    height: 50,
    marginTop: 15,
    marginHorizontal: 15,
    marginBottom: 30,
  },
  modalContainer: {
    backgroundColor: colors.white,
    alignSelf: 'center',
    paddingBottom: 30,
    borderRadius: 6,
  },
  closeContainer: {
    backgroundColor: colors.disabled,
    position: 'absolute',
    top: 0,
    right: 0,
    borderTopRightRadius: 7,
    borderBottomLeftRadius: 7,
    width: 75,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 10,
  },
  modalContent: {
    paddingHorizontal: 40,
    marginTop: 45,
  },
  modalIcon: {
    alignSelf: 'center',
    marginBottom: 5,
  },
  modalTitle: {
    marginVertical: 8,
  },
  modalButton: {
    marginTop: 15,
    marginHorizontal: 15,
  },
  tabs: { paddingTop: 0 },
})
