import { Platform } from 'react-native'

import { styleSheetFactory } from '../../Themes/Colors'

const isWeb = Platform.OS === 'web'

const styles = styleSheetFactory(colors => ({
    container: {
        flex: 1,
    },
    itemsInfo: {
        fontSize: 12,
        alignSelf: 'center',
        color: colors.secondaryText,
        marginTop: 10,
        marginBottom: 30,
    },
    listStyle: {
        marginHorizontal: 15,
    },
    fullIssueButton: {
        height: 50,
        marginBottom: 15,
    },
    amountContainer: {
        marginTop: 5,
    },
    inputRow: {
        marginTop: 10,
    },
    listInfoContainer: {
        paddingHorizontal: 15,
        alignItems: 'center',
        flexDirection: 'row',
        backgroundColor: colors.tertiaryBackground,
        overflow: 'hidden',
        minHeight: 70,
    },
    listDescContainer: {
        flexGrow: 1,
        flexShrink: 1,
        alignItems: 'flex-start',
        marginVertical: 5,
    },
    listPriceContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        flexShrink: 0,
        backgroundColor: colors.tertiaryBackground,

    },
    listFirst: {
        borderTopLeftRadius: 7,
        borderTopRightRadius: 7,
    },
    listLast: {
        borderBottomLeftRadius: 7,
        borderBottomRightRadius: 7,
    },
    separator: {
        height: 1,
        backgroundColor: colors.separator,
    },
    separatorContainer: {
        backgroundColor: colors.tertiaryBackground,
        height: 1,
    },
    footerComponent: {
        marginHorizontal: 15,
    },
    checkAllSection: {
        flexDirection: 'row',
        margin: 15,
        marginBottom: 20,
        justifyContent: 'space-between',
        marginTop: 0,
    },
    listImageContainer: {
        width: 70,
        height: 70,
        flexShrink: 0,
        justifyContent: 'center',
        alignItems: 'center',
    },
    itemPricing: {
        marginHorizontal: 15,
        alignItems: 'flex-end',
    },
    quantityContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        marginRight: 10,
        justifyContent: 'space-between',
    },
    quantityButton: {
        width: 30,
        height: 30,
        borderRadius: 7,
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: colors.separator,
        justifyContent: 'center',
        alignItems: 'center',
    },
    quantityInput: {
        width: 75,
        height: 30,
        fontSize: 22,
        paddingVertical: 2,
        color: colors.primaryText,
        alignSelf: 'center',
        alignItems: 'center',
        textAlign: 'center',
    },
    emptyComponent: { marginTop: 20 },
    checkbox: { marginRight: isWeb ? 18 : 15 },
    deliviryWrapper: {
        marginHorizontal: 15,

    },
    buttonWrapper: {
        // flexGrow: 1,
        flexDirection: 'row',
        alignItems: 'center',
    },
    endDateInput: {
        flex: 1,
        marginLeft: 15,
        marginBottom: 8,
    },
    startDateInput: {
        flex: 1,
        marginBottom: 8,

    },
    sectionNote: {
        marginBottom: 24,
    },
    sectionTitle: {
        marginBottom: 12,
    },
    itemsSectionTitle: {
        marginBottom: 16,
        marginHorizontal: 15,
    },
    AccessItem: {
        justifyContent: 'center',
        overflow: 'hidden',
        marginVertical: 16,
        borderBottomLeftRadius: 7,
        borderBottomRightRadius: 7,
        marginHorizontal: 15,
    },
    touchable: {
        flexGrow: 1,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    radioInput: {
        flexShrink: 0,
    },
    accessTextContainer: {
        flexGrow: 1,
        flexShrink: 1,
        flexDirection: 'row',
    },
    accessWrapper: {
        marginVertical: 15,
    },
    accessLabel: {
        marginBottom: 10,
    },

}))
const selectStyles = styleSheetFactory(colors => ({
    itemSelected_false: {
        flexDirection: 'row',
        marginHorizontal: 15,
        borderRadius: 10,
        paddingVertical: 10,
        paddingHorizontal: 15,
        backgroundColor: colors.white,
        alignItems: 'center',
        height: 60,
    },
    itemSelected_true: {
        flexDirection: 'row',
        marginHorizontal: 15,
        borderRadius: 10,
        paddingVertical: 10,
        paddingHorizontal: 15,
        backgroundColor: colors.white,
        alignItems: 'center',
        height: 60,
        borderWidth: 1,
        borderColor: colors.secondaryAccent,
    },
    itemTextSelected_false: {
        flexGrow: 1,
        flexShrink: 1,
    },
    itemTextSelected_true: {
        flexGrow: 1,
        flexShrink: 1,
    },
    separator: {
        width: 1,
        backgroundColor: colors.disabled,
        marginHorizontal: 15,
        height: 40,
    },
    isDisabled_true: {
        backgroundColor: colors.secondaryBackround,
    },
    itemCode: {
        width: 30,
    },
    header: {
        margin: 15,
        marginTop: 30,
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    searchContainer: {
        flexDirection: 'row',
        marginHorizontal: 15,
        marginBottom: 15,
        backgroundColor: colors.white,
        borderRadius: 10,
        justifyContent: 'space-between',
        alignItems: 'center',
        flexGrow: 1,
    },
    searchIcon: {
        marginHorizontal: 15,
        marginVertical: 10,
    },
    searchInput: {
        flexGrow: 1,
        borderRadius: 10,
        paddingVertical: 10,
        marginRight: 15,
        color: colors.primaryText,
    },
    clearSearchContainer: {
        borderRadius: 18,
        padding: 5,
        marginRight: 15,
        backgroundColor: colors.background,
    },
    listStyle: {
        height: 300,
    },
    listContainerStyle: {
        flexGrow: 1,
        paddingBottom: 20,
    },
    verticalSelfSpacing: { paddingVertical: 7.5 },
}))

export { selectStyles }
export default styles
