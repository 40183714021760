/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useContext } from 'react'
import { TouchableOpacity, View, SectionList } from 'react-native'
import { useQuery } from '@apollo/client'
import { useTheme } from 'react-native-themed-styles'
import PropTypes from 'prop-types'

import { Text, RadioInput, Header, Button, Loader } from '../../Components'
import useTaxDetails from '../../Utils/Tax'

import ContentManager from '../../Utils/ContentManager'

import Theme from '../../Themes/Theme'
import themedStyles, { itemStyles, separatorStyles } from './SelectTax.Styles'

import GET_TAXES from './SelectTax.schema'

import images from '../../Themes/Images'
import TaxFree from './TaxFree'

const REASONS = [
  {
    code: 'item_tax_type1_title',
    description: 'item_tax_type1_description',
    type: 'TYPE_1',
  },
  {
    code: 'item_tax_type2_title',
    description: 'item_tax_type2_description',
    type: 'TYPE_2',
  },
  {
    code: 'item_tax_type3_title',
    description: 'item_tax_type3_description',
    type: 'TAX_FREE',
  },
  {
    code: 'item_tax_type4_title',
    description: 'item_tax_type4_description',
    type: 'MARGIN_SCHEME',
  },
]

const INCLUSION_TYPES = [
  {
    id: 'INCLUSIVE',
    name: 'INCLUSIVE',
  },
  {
    id: 'ADDITIVE',
    name: 'ADDITIVE',
  },
]

function Tax({ id, name, selected, isDefault, onPress, isFirst, isLast, isTaxFree }) {

  const [styles] = useTheme(itemStyles)
  const { colors } = useContext(Theme)

  const isSelected = selected === id

  return <View style={[styles.container, styles['containerSelected_' + isSelected], styles['firstItem_' + isFirst], styles['lastItem_' + isLast]]}>
    <TouchableOpacity onPress={onPress} style={styles.touchable}>
      <View style={styles.textContainer}>
        <Text i18nKey={'taxes_name_default_' + isDefault} placeHolders={[ContentManager.translate(name)]} numberOfLines={2} style={styles.name} />
      </View>
      <View pointerEvents="none" style={styles.radioInput}>
        <RadioInput
          index={id}
          selected={selected}
          onPress={onPress}
          color={colors.secondaryAccent}
        />
      </View>
    </TouchableOpacity>
  </View>
}
Tax.defaultProps = {
  labelColor: 'white',
}
Tax.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  selected: PropTypes.string,
  isDefault: PropTypes.bool,
  onPress: PropTypes.func,
  isFirst: PropTypes.bool,
  isLast: PropTypes.bool,
}

function InclusionType({ name, selected, onPress, isFirst, isLast }) {
  const [styles] = useTheme(itemStyles)
  const { colors } = useContext(Theme)

  return <View style={[styles.container, styles['firstItem_' + isFirst], styles['lastItem_' + isLast]]}>
    <TouchableOpacity onPress={onPress} style={styles.touchable}>
      <View style={styles.textContainer}>
        <Text i18nKey={name} numberOfLines={2} style={styles.name} />
      </View>
      <View pointerEvents="none" style={styles.radioInput}>
        <RadioInput
          index={name}
          selected={selected}
          onPress={onPress}
          color={colors.secondaryAccent}
        />
      </View>
    </TouchableOpacity>
  </View>
}
InclusionType.propTypes = {
  name: PropTypes.string,
  onPress: PropTypes.func,
  selected: PropTypes.string,
  isFirst: PropTypes.bool,
  isLast: PropTypes.bool,
}

function Separator() {
  const [styles] = useTheme(separatorStyles)
  return <View style={styles.container} />
}

function SelectTax({
  route: {
    params: {
      preselectedTax = {},
      preselectedInclusionType = INCLUSION_TYPES[0].name,
      updateSelection = () => { },
      preselectedExemptionType,
    } = {},
  } = {},
  navigation: {
    goBack,
  },
}) {
  const { taxDetails } = useTaxDetails()
  const [styles] = useTheme(themedStyles)

  const [taxes, setTaxes] = useState([])
  const [selectedTax, setSelectedTax] = useState(preselectedExemptionType ? {
    id: 'Tax_Exempt',
    isDefault: false,
    name: 'tax_exempt',
    percentage: 0,
  } : preselectedTax)
  const [selectedInclusionType, setSelectedInclusionType] = useState(preselectedInclusionType)



  const { loading, data } = useQuery(GET_TAXES, {
    variables: {
      filter: {
        name: 'status',
        value: 'ACTIVE',
      },
    },
  })

  const [taxExemptionType, setTaxExemptionType] = useState({
    isTaxFree: Boolean(preselectedExemptionType),
    reason: REASONS.find(item => item?.type === preselectedExemptionType) || {},
  })

  const apply = () => {
    updateSelection({
      tax: selectedTax?.id === 'Tax_Exempt' ? {
        id: 'Tax0%',
        name: '0%',
        percentage: 0,
        isDefault: false,
      } : selectedTax,
      taxInclusionType: selectedInclusionType,
      taxExemptionType: taxExemptionType?.reason?.type || null,
    })
    goBack()
  }

  const keyExtractor = item => item.id
  const renderItem = ({ item, index }) => (
    <Tax
      {...item}
      selected={selectedTax?.id}
      onPress={() => {
        if (item?.id !== 'Tax_Exempt') {
          setSelectedTax(item)
          setTaxExemptionType({
            isTaxFree: false,
            reason: {},
          })
        } else {
          setSelectedTax(item)
        }
      }}
      isFirst={index === 0}
      isLast={index === taxes.length - 1}
      isTaxFree={taxExemptionType?.isTaxFree}
    />
  )

  const renderInclusionType = ({ item, index, section: { data: { length: len } } }) => {
    return (<InclusionType
      {...item}
      selected={selectedInclusionType}
      onPress={() => setSelectedInclusionType(item.name)}
      isFirst={index === 0}
      isLast={index === len - 1}
    />
    )
  }

  const [sections, setSections] = useState([])

  const checkIfTaxExempt = () => {
    if (preselectedTax?.id === 'Tax0%') {
      if (preselectedExemptionType?.isTaxFree) {
        setSelectedTax({
          id: 'Tax_Exempt',
          isDefault: false,
          name: 'tax_exempt',
          percentage: 0,
        })
        return true
      }
    } else {
      return false
    }

  }

  useEffect(() => {
    if (data) {
      const taxesArray = [...(data.listTaxes?.taxes || [])]
      checkIfTaxExempt()
      setTaxes([...taxesArray, {
        id: 'Tax_Exempt',
        isDefault: false,
        name: 'tax_exempt',
        percentage: 0,
      }])
      const newSections = [...sections]
      newSections.unshift(
        {
          data: [...taxesArray, {
            id: 'Tax_Exempt',
            isDefault: false,
            name: 'tax_exempt',
            percentage: 0,
          }],
        },
      )
      setSections(newSections)
    }
  }, [data])

  useEffect(() => {
    const newSections = [...sections]
    if (selectedTax.percentage === 0 && !taxExemptionType.isTaxFree && taxDetails.tax.percentage !== 0) {
      setSections([{
        data: taxes,
      }])
    } else {
      if (sections.length > 1) {
        newSections.pop()
      }
      if (selectedTax.percentage !== 0 || taxDetails.tax.percentage === 0) {
        newSections.push({
          title: 'taxes_note_select',
          data: INCLUSION_TYPES,
          renderItem: renderInclusionType,
        })
      }

      setSections(newSections)
    }


  }, [selectedTax, selectedInclusionType, taxExemptionType])

  const renderSectionHeader = ({ section: { title } }) => Boolean(title) && <Text i18nKey={title} style={styles.sectionHeader} />

  const disabled = (selectedTax.id === 'Tax_Exempt' && !taxExemptionType.isTaxFree && !taxExemptionType.reason.code) ? 'disabled' : 'active'

  return (
    <>
      <Header
        title="header_all_taxes"
        image={images.back}
        onPress={goBack}
      />
      {loading && <Loader size={32} style={styles.contentContainer} />}
      <SectionList
        sections={sections}
        keyExtractor={keyExtractor}
        renderItem={renderItem}
        ItemSeparatorComponent={Separator}
        renderSectionHeader={renderSectionHeader}
        ListFooterComponent={(selectedTax?.id === 'Tax_Exempt') && <TaxFree
          isTaxFree={true}
          setTaxFree={() => setTaxExemptionType(prev => ({
            ...prev,
            isTaxFree: !prev.isTaxFree,
          }))}
          reason={taxExemptionType.reason}
          setReason={(reason) => setTaxExemptionType((prev) => ({
            ...prev,
            reason,
          }))}
          disabled={loading}
          showRadio={false}
        />}
        ListFooterComponentStyle={styles.footer}
        contentContainerStyle={styles.contentContainer}
      />
      <Button
        title="discount_button_apply"
        variant={disabled}
        style={styles.button}
        onPress={apply}
      />
    </>
  )
}

SelectTax.propTypes = {
  route: PropTypes.object,
  navigation: PropTypes.object,
}

export default SelectTax
