import React, { useState, useContext } from 'react'
import { Animated, View, TouchableOpacity, Platform } from 'react-native'
import PropTypes from 'prop-types'
import moment from 'moment'
import {
  Calendar as CalendarSelector,
  LocaleConfig,
} from 'react-native-calendars'
import { useTheme } from 'react-native-themed-styles'
import _ from 'lodash'

import { useLanguage } from '../../Utils/Language'

import Icon from '../Icon'
import Text from '../Text'
import Button from '../Button'
import { calendarDict } from '../../Utils/Dict'
import TextInput from '../TextInput'

import Theme from '../../Themes/Theme'
import images from '../../Themes/Images'
import themedStyles from './PeriodCalendar.Styles'

const AnimatedTouchable = Animated.createAnimatedComponent(TouchableOpacity)
const LANGUAGES = {
  en: calendarDict.en,
  al: calendarDict.al,
}

function Calendar(props) {
  const [styles] = useTheme(themedStyles)
  const { colors } = useContext(Theme)
  const lang = useLanguage()

  const calendarTheme = {
    'stylesheet.calendar.main': {
      container: {
        paddingLeft: 0,
        paddingRight: 0,
        backgroundColor: colors.tertiaryBackground,
        borderBottomLeftRadius: 7,
        borderBottomRightRadius: 7,
      },
      monthView: {
        paddingVertical: 3,
      },
      week: {
        marginTop: 0,
        marginBottom: 0,
        flexDirection: 'row',
        justifyContent: 'space-evenly',
      },
    },
    'stylesheet.calendar.header': {
      header: {
        flexDirection: 'row',
        height: 40,
        justifyContent: 'space-between',
        paddingLeft: 15,
        paddingRight: 15,
        marginTop: 0,
        alignItems: 'center',
        backgroundColor: colors.tertiaryBackground,
      },
      monthText: {
        fontSize: 18,
        ...Platform.select({
          ios: {
            fontFamily: 'Vodafone Rg',
            fontWeight: 'bold',
          },
          android: {
            fontFamily: 'Vodafone Rg Bold',
          },
        }),
        color: colors.primaryText,
        margin: 0,
      },
      arrow: {
        padding: 0,
      },
      arrowImage: {
        tintColor: colors.primaryText,
      },
      disabledArrowImage: {
        tintColor: colors.placeholder,
      },
      week: {
        backgroundColor: colors.tertiaryBackground,
        height: 40,
        marginTop: 0,
        flexDirection: 'row',
        justifyContent: 'space-evenly',
        alignItems: 'center',
      },
      dayHeader: {
        marginTop: 0,
        marginBottom: 0,
        textAlign: 'center',
        fontSize: 12,
        fontFamily: 'Vodafone Rg',
        color: colors.primaryText,
        flex: 1,
      },
    },
    'stylesheet.day.basic': {
      base: {
        minWidth: 42,
        height: 42,
        borderRadius: 21,
        margin: 0,
        justifyContent: 'center',
        alignItems: 'center',
      },
      text: {
        marginTop: 4,
        fontSize: 16,
        lineHeight: 20,
        ...Platform.select({
          ios: {
            fontFamily: 'Vodafone Rg',
            fontWeight: 'bold',
          },
          android: {
            fontFamily: 'Vodafone Rg Bold',
          },
        }),
        color: colors.primaryText,
      },
      selected: {
        backgroundColor: colors.calendarAccentRGBA(),
      },
      today: {
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: colors.calendarAccent,
      },
      todayText: {
        color: colors.calendarAccent,
      },
      selectedText: {
        color: colors.calendarText,
      },
      disabledText: {
        color: colors.disabled,
        fontFamily: 'Vodafone Rg',
      },
    },
  }
  const {
    onIntervalChange,
    onSearch,
    onSearchClean,
    value,
    withInput,
    onChangeText,
  } = props

  const today = moment().format('YYYY-MM-DD')

  const [open, setOpen] = useState(false)
  const [anim] = useState(new Animated.Value(0))
  const [calendarHeight, setCalendarHeight] = useState(100)
  const [markedDates, setMarkedDates] = useState({
    interval: {},
    label: props.placeholder,
  })


  const getCalendarDimensions = dimensions => {
    setCalendarHeight(dimensions.height / 2)
  }

  LocaleConfig.locales.en = LANGUAGES[lang]
  LocaleConfig.defaultLocale = 'en'

  const options = {
    selected: props.placeholder,
    arrows: {
      left: images.arrow_left,
      right: images.arrow_right,
    },
    scale: {
      true: 0,
      false: 1,
    },
    touchable: {
      true: 'auto',
      false: 'none',
    },
  }

  const animate = () => {
    Animated.timing(anim, {
      toValue: options.scale[open],
      duration: 200,
      useNativeDriver: true,
    }).start()
  }

  const toggleCalendar = () => {
    setOpen(!open)
    animate()
  }

  const handleDayPress = day => {
    const selectedDayy = moment(day)
    const selectedDayFormatted = selectedDayy.format('YYYY-MM-DD')

    setMarkedDates((prev) => {
      if (prev[selectedDayFormatted]) {
        return {
          interval: {},
        }
      } else {
        const selectedDays = Object.keys(prev.interval)
        if (selectedDays.length !== 1 || selectedDayy.isBefore(selectedDays[0])) {
          onIntervalChange({
            startingDay: selectedDayFormatted,
          })
          return {
            startingDay: selectedDayFormatted,
            interval: { [selectedDayFormatted]: { startingDay: true, color: colors.accent, textColor: colors.white } },
            label: selectedDayFormatted,
          }
        } else {
          const interval = { ...prev.interval }
          const difference = moment.duration(selectedDayy.diff(moment(selectedDays[0]))).asDays()
          const startingDay = moment(selectedDays[0])
          for (let i = 0; i < difference; i++) {
            interval[startingDay.add(1, 'days').format('YYYY-MM-DD')] = { color: colors.accent, textColor: colors.white }
          }
          onIntervalChange({
            startingDay: moment(selectedDays[0]).format('YYYY-MM-DD'),
            endingDay: selectedDayFormatted,
          })
          return {
            ...prev,
            interval: { ...interval, [selectedDayFormatted]: { endingDay: true, color: colors.accent, textColor: colors.white } },
            endingDay: selectedDayFormatted,
            label: `${moment(selectedDays[0]).format('YYYY-MM-DD')} - ${selectedDayFormatted}`,
          }
        }
      }
    })
  }

  const removeSelectedDay = () => {
    onIntervalChange({})
    setMarkedDates({
      interval: {},
      label: props.placeholder,
    })
    onSearchClean()
  }

  return (
    <View style={[styles.container, props.style]}>
      <View style={styles.touchableBackdrop} />
      <TouchableOpacity
        style={[styles.touchable, styles[`touchable_${open}`]]}
        onPress={!value ? toggleCalendar : () => { }}
        activeOpacity={0.5}>
        <View
          style={[
            styles.calendarIconContainer,
            styles[`calendarIconContainer_${open}`],
          ]}>
          <Icon source={images.calendar} size={20} color={colors.secondaryIcon} />
        </View>
        <View style={styles.searchResultContainer}>
          {withInput && !open ? <TextInput
            placeholder={markedDates.label ? markedDates.label : props.placeholder}
            editable={_.isEmpty(markedDates.interval) ? true : false}
            keyboardType="default"
            placeholderTextColor={colors.placeholder}
            style={styles.input}
            onChangeText={onChangeText}
            value={value}
          /> : <Text color={colors.placeholder} i18nKey={markedDates.label || props.placeholder} />}
        </View>
        {!!value &&
          <TouchableOpacity onPress={onSearchClean} style={styles.removeSelected}>
            <Icon source={images.close} size={15} color={colors.midGrey} />
          </TouchableOpacity>
        }
        {Boolean(markedDates.startingDay || markedDates.endingDay) &&
          <TouchableOpacity onPress={removeSelectedDay} style={styles.removeSelected}>
            <Icon source={images.close} size={15} color={colors.midGrey} />
          </TouchableOpacity>
        }
        {/* {Boolean(markedDates.startingDay || markedDates.endingDay) && (
          <TouchableOpacity
            style={styles.removeSelected}
            onPress={removeSelectedDay}>
            <Icon source={images.close} size={15} color={colors.midGrey} />
          </TouchableOpacity>
        )} */}
      </TouchableOpacity>
      <View
        style={[
          styles.calendarToggleBackdrop,
          styles[`calendarToggleBackdrop_${open}`],
        ]}
      />
      <Animated.View
        onLayout={event => getCalendarDimensions(event.nativeEvent.layout)}
        style={[
          styles.calendarContainer,
          styles[`calendarContainer_${open}`],
          {
            transform: [
              {
                translateY: anim.interpolate({
                  inputRange: [0, 1],
                  outputRange: [-calendarHeight, 0],
                }),
              },
              { scaleY: anim },
            ],
            opacity: anim.interpolate({
              inputRange: [0, 0.5, 0.8, 1],
              outputRange: [0, 0, 1, 1],
            }),
          },
        ]}
        pointerEvents={options.touchable[open]}>
        <CalendarSelector
          maxDate={today}
          // displayLoadingIndicator={true}
          onDayPress={day => handleDayPress(day.dateString)}
          markingType={'period'}
          monthFormat={'MMM yyyy'}
          markedDates={markedDates.interval}
          firstDay={1}
          renderArrow={direction => (
            <Icon
              source={options.arrows[direction]}
              size={12}
              color={colors.primaryText}
            />
          )}
          theme={calendarTheme}
        />
        <View style={styles.separator} />
        <View style={styles.footer}>
          <Button
            variant="link"
            title="einvoice_cancel_button"
            style={styles.firstButton}
            onPress={toggleCalendar}
          />
          <Button
            variant="link"
            title="einvoice_search_button"
            color={colors.accent}
            onPress={() => {
              onSearch()
              toggleCalendar()
            }}
          />
        </View>
      </Animated.View>
      {open && (
        <AnimatedTouchable
          onPress={toggleCalendar}
          style={[
            styles.backdrop,
            {
              opacity: anim.interpolate({
                inputRange: [0, 1],
                outputRange: [0, 1],
              }),
            },
          ]}
          activeOpacity={1}
        />
      )}
    </View>
  )
}
Calendar.defaultProps = {
  onSearchClean: () => { },
}

Calendar.propTypes = {
  onIntervalChange: PropTypes.func,
  onSearch: PropTypes.func,
  onSearchClean: PropTypes.func,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  withInput: PropTypes.bool,
  onChangeText: PropTypes.func,
  style: PropTypes.object,
}

export default Calendar
