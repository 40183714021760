// Amplify Dictionary
const dict = {
  en: {
    'Name': 'Email',
    Username: 'Email',
    'Enter your username': 'Enter your email',
    'Enter your name': 'Enter your email',
    'Family Name': 'Family Name',
    'Enter your family name': 'Enter your family name',
    'Enter your phone number': 'Enter your phone number',
    'Enter your email': 'Enter your email',
    'WrongPIN': 'PIN is not correct!',
    'NotAuthorized': 'Your device is not authorized. Try again as soon as you receive confirmation that your Admin has authorized it.',
    'General': 'Something went wrong. Please, try again later.',
    'NotAuthorizedException': 'Incorrect username or password.',
    'Don\'t have an account?': 'Don\'t have an account?',
    'UserLambdaValidationException"': 'The limit for obtaining the PIN was reached!',
    'AdminLocationStatusDeactiveError': 'Your account has been deactivated due to unpaid invoices. To activate it again, please visit the nearest Vodafone store.',
    'SellerLocationStatusDeactiveError': 'Your account has been deactivated. Please contact your administrator for further information.',

  },
  al: {
    'AdminLocationStatusDeactiveError': 'Llogaria juaj është çaktivizuar për shkak të detyrimeve të prapambetura. Për ta aktivizuar sërish, ju lutem vizitoni dyqanin më të afërt Vodafone.',
    'SellerLocationStatusDeactiveError': 'Llogaria juaj është çaktivizuar. Ju lutem kontaktoni administratorin tuaj për informacione të mëtejshme.',
    'UserLambdaValidationException"': 'U arrit limiti per marrjen e PIN-it!',
    'Change your password': 'Ndrysho fjalëkalimin',
    'Current password': 'Fjalëkalimi juaj',
    'E-mail': 'E-mail',
    'New Password': 'Fjalëkalimi i ri',
    'Confirm New Password': 'Konfirmo Fjalëkalimim i ri',
    'Confirm password': 'Rishkruaj fjalëkalimin e ri',
    'Confirm your new password': 'Vendos fjalëkalimin e ri',
    'Enter your new password': 'Vendos fjalëkalimin e ri ',
    'Enter your current password': 'Vendos fjalëkalimin aktual',
    'WrongPIN': 'Kodi PIN nuk është i saktë!',
    'NotAuthorized': 'Pajisja juaj nuk është e autorizuar. Tento sërish sapo t’ju vijë konfirmimi që Admin juaj e ka autorizuar.',
    'Name': 'Email',
    'Enter your name': 'Vendos email-in tënd',
    'Family Name': 'Mbiemri',
    'Enter your family name': 'Vendos mbiemrin tënd',
    'Enter your phone number': 'Vendos numrin tënd të telefonit',
    'Enter your email': 'Vendos email-in tënd',
    'Login': 'Hyr në llogarinë tënde',
    'Create a new account': 'Krijo një llogari të re',
    'Confirm Sign Up': 'Konfirmo Regjistrimin',
    Send: 'Dërgo',
    'Log In': 'Hyr',
    'Sign in': 'Hyr',
    'Sign Up': 'Regjistrohu',
    'Username *': 'Email i përdoruesit *',
    Username: 'Email',
    'Enter your username': 'Vendos email tënd të përdoruesit',
    'Password *': 'Fjalëkalimi *',
    Password: 'Fjalëkalimi',
    'Enter your password': 'Vendos fjalëkalimin tënd',
    'Recover Account': 'Gjej llogarinë tënde',
    'Enter your account email': 'Vendosni emaili-n tuaj',
    Email: 'Email-i',
    'Phone Number *': 'Numri i telefonit *',
    'Phone Number': 'Numri i telefonit',
    'No account?': 'Nuk ke një llogari?',
    'Create account': 'Krijo një llogari',
    'Create Account': 'Krijo një llogari',
    'Have an account?': 'Ke një llogari?',
    'Confirm a Code': 'Konfirmo një kod',
    'Confirmation Code *': 'Kodi i konfirmimit *',
    'Confirmation code *': 'Kodi i konfirmimit *',
    'Enter your confirmation code': 'Vendos kodin tënd të konfirmimit',
    'Have a code?': 'Ke një kod?',
    'Code *': 'Kodi *',
    'PIN Confirmation': 'Kodi i konfirmimit ',
    Confirm: 'Konfirmo',
    'Forgot your password?': 'Harruat fjalëkalimin?',
    'Reset Password': 'Rivendos fjalëkalimin',
    'Reset password': 'Rivendos fjalëkalimin',
    'Reset your password': 'Rivendos fjalëkalimin tënd',
    'Back to Sign In': 'Kthehu te hyrja',
    'Back to Sign in': 'Kthehu te hyrja',
    'You will receive a verification code to reset your password': 'Do të marrësh një kod për të rivendosur fjalëkalimin tënd',
    Submit: 'Dërgo',
    Verify: 'Verifiko',
    'Verify Contact': 'Verifiko kontaktin',
    Skip: 'Tejkalo',
    'Next': 'Vazhdo',
    'Save': 'Ruaj',
    'Back to log in' : 'Kthehuni tek log in' ,
    "Lost your code?'": 'Ke humbur kodin?',
    'Verification': 'Verifiko',
    Resend: 'Ridërgo',
    'Resend code': 'Ridërgo kodin',
    'Forgot Password?': 'Ke harruar fjalëkalimin?',
    'You will receive a verification code': 'Do të marrësh një kod verifikimi',
    Code: 'Kodi',
    'Account recovery requires verified contact information': 'Rikthimi i llogarisë kërkon një formë kontakti të verifikuar',
    'User does not exist': 'Përdoruesi nuk ekziston',
    'User already exists': 'Përdoruesi ekziston',
    'Incorrect username or password': 'Email i përdoruesit ose fjalëkalimi është i gabuar.',
    'Invalid password format': 'Modeli i fjalëkalimit nuk është i saktë',
    'Invalid phone number format': 'Modeli i numrit të telefonit nuk është i saktë. Përdor modelin +355 6XXXXXXXX',
    'Username cannot be empty': 'Email i përdoruesit nuk mund të lihet bosh',
    'Custom auth lambda trigger is not configured for the user pool.': 'Inicializuesi i identifikimit të përshtatur në lamda nuk është konfiguruar në grupin e përdoruesve.',
    'Incorrect username or password.': 'Email i përdoruesit ose fjalëkalimi është i gabuar',
    'Password attempts exceeded': 'Keni arritur limitin e tentativave të fjalëkalimit',
    'Please Sign In / Sign Up': 'Hyr ose Regjistrohu',
    'Don\'t have an account?': 'Nuk keni një llogari?',

    'Confirm Sign In': 'Konfirmo hyrjen',
    'Confirmation Code': 'Kodi i konfirmimit',
    'Send Pin Again': 'Ridërgo kodin',
    'Resend Code': 'Ridërgo kodin',
    // 'Current password *': 'Fjalekalimi',
    'Change Password': 'Ndrysho fjalëkalimin',
    'General': 'Diçka shkoi gabim. Ju lutem, provoni përsëri më vonë.',
    'NotAuthorizedException': 'Email i përdoruesit ose fjalëkalimi është i gabuar.',
    'You should receive a SMS with a five digits code in the number provided.': 'Ju duhet të merrni një SMS me një kod pesë shifror në numrin e dhënë.',
    'You should receive an email with a six digits code. Enter your code.': 'Ju duhet të merrni një email me një kod gjashtë shifror. Vendosni kodin.',
    'Don&#39t have an account?': 'Nuk keni akoma një llogari?',
    'To activate the eBiznes service, go to any Vodafone store and register your business credentials.': 'Për të aktivizuar shërbimin eBiznes, paraqituni në çdo dyqan Vodafone dhe regjistroni kredencialet e biznesit.',
    'Learn more.': 'Mëso më shumë.',


  },
}

// Calendar Dictionary
const calendarDict = {
  en: {
    monthNames: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
    monthNamesShort: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    dayNames: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
    dayNamesShort: ['S', 'M', 'T', 'W', 'T', 'F', 'S'],
    today: 'Today',
  },
  al: {
    monthNames: ['Janar', 'Shkurt', 'Mars', 'Prill', 'Maj', 'Qershor', 'Korrik', 'Gusht', 'Shtator', 'Tetor', 'Nëntor', 'Dhjetor'],
    monthNamesShort: ['Jan', 'Shku', 'Mars', 'Pri', 'Maj', 'Qer', 'Korr', 'Gush', 'Shta', 'Tet', 'Nën', 'Dhje'],
    dayNames: ['E Dielë', 'E Hënë', 'E Martë', 'E Mërkurë', 'E Enjte', 'E Premte', 'E Shtunë'],
    dayNamesShort: ['D', 'H', 'M', 'M', 'E', 'P', 'S'],
    today: 'Sot',
  },
}

export {
  dict,
  calendarDict,
}

