import React, { createContext, useState, useContext } from 'react'
import PropTypes from 'prop-types'
import AsyncStorage from '@react-native-async-storage/async-storage'

import en from '../I18n/languages/en'
import al from '../I18n/languages/al'

import ContentManager from './ContentManager'

const LanguageContext = createContext()
const ChangeLanguage = createContext()

const AcceptedLanguages = ['en', 'al']

const LANGUAGES = {
  al: al,
  en: en,
}

function LanguageProvider({ children }) {
  const [lang, setLang] = useState()

  const configureLanguage = (value) => {
    const language = LANGUAGES?.[value]
    ContentManager.configureI18nTranslations({ en: { ...language } })
    setLang(value)
  }

  // eslint-disable-next-line no-unused-vars
  const getLanguage = () => {
    AsyncStorage.getItem('lang')
      .then((value) => {
        if (AcceptedLanguages.includes(value)) {
          configureLanguage(value)
        } else {
          configureLanguage('al')
        }
      })
      .catch(() => {
        configureLanguage('al')
      })
  }

  const setLanguage = (value) => {
    if (AcceptedLanguages.includes(value)) {
      AsyncStorage.setItem('lang', value)
        .then(() => {
          configureLanguage(value)
        })
        .catch(() => {
          configureLanguage('al')
        })
    } else {
      configureLanguage('al')
    }
  }

  return (
    <LanguageContext.Provider value={lang}>
      <ChangeLanguage.Provider value={setLanguage}>
        {children}
      </ChangeLanguage.Provider>
    </LanguageContext.Provider>
  )
}

LanguageProvider.propTypes = {
  children: PropTypes.any,
}

function useLanguage() {
  const context = useContext(LanguageContext)
  if (context === undefined) {
    return
    // throw new Error('useLanguage must be used within a LanguageProvider')
  }
  return context
}

function useChangeLanguage() {
  const context = useContext(ChangeLanguage)
  if (context === undefined) {
    return
    // throw new Error('useChangeLanguage must be used within a LanguageProvider')
  }
  return context
}

export { useLanguage, useChangeLanguage, LanguageProvider }
