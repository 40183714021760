import { styleSheetFactory } from '../../../Themes/Colors'

const styles = styleSheetFactory(colors => ({
    container: {
        flex: 1,
        paddingHorizontal: 15,
    },
    wrapper: {
        flex: 1,
    },
    noteContainer: {
        marginVertical: 20,
        borderRadius: 7,
        backgroundColor: colors.white,
        padding: 15,
    },
    errorContainer: {
        marginTop: 20,
        borderRadius: 7,
        backgroundColor: colors.white,
        padding: 15,
    },
    separator: {
        width: '100%',
        height: 2,
        alignSelf: 'center',
        backgroundColor: colors.background,
        marginVertical: 10,
    },
    noteHeader: {
        flexDirection: 'row',
        marginBottom: 5,
    },
    buttonContainer: {
        flexDirection: 'row',
        marginTop: 15,
        marginHorizontal: 15,
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    leftButton: {
        flex: 1,
        height: 50,
    },
    rightButton: {
        flex: 1,
        height: 50,
        marginRight: 15,
    },
    rightButtonIcon: {
        marginLeft: 10,
    },
    modalContent: {
        marginHorizontal: 30,
        marginBottom: 15,
    },
    modalTitle: {
        marginVertical: 10,
    },
    modalPrimaryButton: {
        width: '100%',
        marginTop: 15,
        marginVertical: 15,
    },
    modalSecondaryButton: {
        width: '100%',
        marginBottom: 0,
    },
    radioWrapper: {
        alignSelf: 'flex-start',
        paddingTop: 20,
    },
    radioInputRow: {
        flexDirection: 'row',
        paddingVertical: 7,
        alignItems: 'center',
        marginVertical: 2,
    },
    rightSpacing: {
        marginRight: 5,
    },
    topSpacing: {
        marginTop: 15,
    },
    contentContainerStyle: {
        paddingBottom: 30,
    },
    downloadButton: {
        flexDirection: 'row',
        alignItems: 'flex-end',
    },
}))

const fileItemStyles = styleSheetFactory(colors => ({
    fileContainer: {
        // flexGrow: 1,
        backgroundColor: colors.tertiaryBackground,
        overflow: 'visible',
        borderRadius: 7,
        marginTop: 10,
        borderColor: colors.separator,
        borderWidth: 1,
    },
    clearContainer: {
        margin: 6.5,
        justifyContent: 'center',
        alignItems: 'center',
        width: 30,
        height: 30,
        borderRadius: 20,
        backgroundColor: colors.background,
        flexShrink: 0,
    },
    subFileContainer: {
        alignItems: 'center',
        padding: 15,
        paddingVertical: 10,
    },
    importIcon: {
        backgroundColor: colors.background,
        margin: 5,
        borderRadius: 7,
    },
    attachedFileContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        padding: 15,
        alignItems: 'center',
    },
    attachedFileSubcontainer: {
        flexGrow: 1,
        flexShrink: 1,
        flexDirection: 'row',
        alignItems: 'center',
        marginRight: 60,
    },
    uploadedSuccess: {
        marginRight: 10,
    },
    attachFile: {
        margin: 20,
    },
}))

const listItemStyles = styleSheetFactory(colors => ({
    wrapper: {
        flex: 1,
    },
    container: {
        flexGrow: 1,
        // paddingHorizontal: 15,
    },
    itemContainer: {
        flexGrow: 1,
        minWidth: 100,
        borderColor: colors.disabled,
        borderWidth: 1,
        borderRadius: 7,
        margin: 5,
        justifyContent: 'center',
        alignItems: 'center',
    },
    itemContainerDisabled_true: {
        opacity: 0.5,
    },
    itemContainerSelected_true: {
        borderWidth: 0,
        backgroundColor: colors.secondaryAccent,
    },
    touchable: {
        minWidth: 100,
        flexGrow: 1,
        paddingHorizontal: 20,
        paddingVertical: 7,
        justifyContent: 'center',
        alignItems: 'center',
    },
    suggestionTouchable: {
        flexGrow: 1,
        padding: 5,
        justifyContent: 'center',
        alignItems: 'center',
    },
    iconSelected_false: {
        tintColor: colors.secondaryText,
    },
    iconSelected_true: {
        tintColor: colors.white,
    },
    titleSelected_false: {
        color: colors.secondaryText,
    },
    titleSelected_true: {
        color: colors.white,
    },
    listStyle: {
        flexGrow: 1,
        // backgroundColor: 'pink',
    },
    listContainerStyle: {
        flexGrow: 1,
        marginTop: 5,
        // marginBottom: 15,
    },
    button: {
        flexGrow: 1,
        margin: 15,
        marginTop: 10,
    },
    buttonWrapper: {
        flexGrow: 1,
        flexDirection: 'row',
        alignItems: 'center',
        marginHorizontal: 15,
    },
    leftButton: {
        flex: 1,
        marginRight: 5,
    },
    rightButton: {
        flex: 1,
        marginLeft: 5,
    },
    shadow: {
        backgroundColor: colors.background,
        margin: 15,
        shadowColor: colors.black,
        shadowOffset: {
            width: 0,
            height: 8,
        },
        shadowOpacity: 0.16,
        shadowRadius: 6,
        borderRadius: 6,
    },
    reportWrapper: {
        backgroundColor: colors.white,
        paddingHorizontal: 15,
        borderRadius: 6,
    },
    reportButtonWrapper: {
        flexDirection: 'row',
        justifyContent: 'space-around',
        alignItems: 'center',
    },
    reportOptions: {
        flexDirection: 'row',
        alignItems: 'center',
        flex: 1,
        padding: 15,
        justifyContent: 'center',
    },
    chargeButton: {
        height: 50,
        margin: 15,
    },
    textBlock: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingHorizontal: 15,
    },
    removeBuyer: {
        borderRadius: 18,
        padding: 10,
        backgroundColor: colors.separatorBody,
    },
    cardHeader: {
        borderBottomColor: colors.separator,
        borderBottomWidth: 1,
        flexDirection: 'row',
        alignItems: 'center',
        padding: 10,
    },
    separator: {
        height: 38,
        width: 1,
        backgroundColor: colors.separator,
    },
    reportsWrapper: {
        paddingBottom: 40,
    },
    resetFilter: {
        borderRadius: 17,
        padding: 6,
        paddingHorizontal: 12,
        flexDirection: 'row',
        alignItems: 'center',
        backgroundColor: colors.separatorBody,
    },
    webFullWidth: {
        width: '100%',
    },
    callRenderer: {
        flex: 1,
    },
}))

const placeholderStyles = styleSheetFactory(colors => ({
    sectionText: {
        backgroundColor: colors.background,
    },
    firstItem: {
        backgroundColor: colors.white,
        // marginTop: 1,
        borderTopRightRadius: 7,
        borderTopLeftRadius: 7,
    },
    middleItem: {
        backgroundColor: colors.white,
        marginTop: 1,
    },
    lastItem: {
        backgroundColor: colors.white,
        marginTop: 1,
        borderBottomLeftRadius: 7,
        borderBottomRightRadius: 7,
    },
    wrapper: {
        overflow: 'hidden',
        borderRadius: 7,
    },
}))

export { fileItemStyles, listItemStyles, placeholderStyles }
export default styles
