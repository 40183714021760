import React from 'react'
import { SafeAreaView } from 'react-native'
import PropTypes from 'prop-types'

import ListContainer from '../../Components/ListContainer'
import {
  Icon,
  Header,
} from '../../Components'

import styles from './Customers.Styles'
import images from '../../Themes/Images'

function Customers({ navigation: { navigate, openDrawer } }) {

  const listItems = [
    {
      id: '9c4195a8-7861-11ea-bc55-0242ac130002',
      title: 'customers_all_label',
      rightComponent: <Icon source={images.arrow_right} style={styles.arrow} size={20} />,
      image: images.customersPadding,
      onPress: () => navigate('AllCustomers'),
      style: styles.itemList,
    },
    {
      id: '9c4195a8-7861-11ea-bc55-0242ac130003',
      title: 'suppliers_all_label',
      rightComponent: <Icon source={images.arrow_right} style={styles.arrow} size={20} />,
      image: images.suppliers,
      onPress: () => navigate('AllCustomers', { isSupplier: true }),
      style: styles.itemList,
    },
    {
      id: '9c419698-7861-11ea-bc55-0242ac130005',
      title: 'customers_groups_label',
      rightComponent: <Icon source={images.arrow_right} style={styles.arrow} size={20} />,
      image: images.categories,
      onPress: () => navigate('Groups'),
      style: styles.itemList,
    },
  ]
  return (
    <>
      <SafeAreaView style={styles.container}>
        <Header
          title="menu_partners_label"
          image={images.menu}
          onPress={() => openDrawer()}
        />
        <ListContainer
          menu={true}
          items={listItems}
          showCreateButton={false}
          showSearch={false}
          swipeLeft={false}
          swipeRight={false}
          useHidden={false}
          useDivider={false}
          listItemStyle={{
            leftImageStyle: {
              width: 25,
            },
            itemContainer: {
              marginVertical: 8,
              padding: 5,
              borderRadius: 7,
              overflow: 'hidden',
            },
          }}
        />
      </SafeAreaView>
    </>
  )
}
Customers.propTypes = {
  navigation: PropTypes.object,
}

export default Customers

