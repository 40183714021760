import { gql } from '@apollo/client'


export const GET_GOV_INVOICE = gql`
  query getInvoicesFromGovBook($invoiceNumber: String $invoiceDate: String $nipt: String) {
    getInvoicesFromGovBook(
        getInvoicesFromGovBookInput:{
            invoiceNumber: $invoiceNumber
            invoiceDate: $invoiceDate
            nipt: $nipt
        }) {
    invoices {
      BookID
      BookVersion
      IICRef
      InvoiceDate
      InvoiceNumber
      IsSalesInvoice
      LineID
      NUIS
      TargetTaxpayerID
      TargetTaxpayerNUIS
      TargetTaxpayerName
      }
    }
  }
`