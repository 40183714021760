import { gql } from '@apollo/client'

//example on how to pass parameters
export const LIST_EMPLOYEE = gql`
  query listEmplyee($limit: Int, $paginationToken: String) {
    listEmplyee(listEmployeeInput:{
      limit: $limit
      paginationToken: $paginationToken
      }){
        paginationToken
        empleyees{
          id
          firstName
          lastName
        }
      }
  }
`


export const CREATE_CASH_DRAWER_SHIFT = gql`
  mutation createCashDrawerShift($locationId: String!,
    $description: String, $amount: Float, $currency: String, $cashRegisterId: String ){
    createCashDrawerShift(locationId: $locationId, cashDrawerShift:{
      openedCashMoney:{
        amount: $amount
        currency: $currency
      }
      description: $description
    }
    requestedLocationAndCashRegister: {
      locationId: $locationId,
      cashRegisterId: $cashRegisterId
    }
    ){
      id
      openedCashMoney{
        amount
        currency
      }
      description
    }
}
`


// eventType can be PAID_OUT or PAID_IN
export const CREATE_CASH_DRAWER_SHIFT_EVENT = gql`
  mutation createCashDrawerShiftEvent(
      $locationId: String!,
      $shiftId: String!
      $eventType: EventType!
      $description: String
      $amount: Float
      $currency: String
      $reason: String!
      $locId: String 
      $cashRegisterId: String
    ){
    createCashDrawerShiftEvent(
        locationId: $locationId
        shiftId: $shiftId
        cashDrawerShiftEvent: {
          eventType: $eventType
          eventMoney: {
            amount: $amount
            currency: $currency
          }
          description: $description
          reason: $reason
        }
        requestedLocationAndCashRegister: {
          locationId: $locId,
          cashRegisterId: $cashRegisterId
        }
    ){
      statementNumber
      id
      FCDC
      createdAt
      description
      externalId
      eventType
      softCode
      businUnitCode
      eventMoney{
          amount
          currency
      }
      employee{
          email
          firstName
          lastName
          operatorCode
      }
      reason
    }
  }
`


// eventType can be PAID_OUT or PAID_IN
export const CHANGE_CASH_DRAWER_SHIFT_STATE = gql`
  mutation changeCashDrawerShiftState($shiftId: String!, $newState: CashDrawerShiftState!, $description: String, $amount: Float, $currency: String,$locId: String, $cashRegisterId: String ){
    changeCashDrawerShiftState(
      shiftId: $shiftId, 
      newState: $newState, 
      actualAmount:{
        amount: $amount
        currency: $currency
      }, 
      description: $description,
      requestedLocationAndCashRegister: {
        locationId: $locId,
        cashRegisterId: $cashRegisterId
      }

    ){
      id
    }
  }
`

export const END_CASH_DRAWER_SHIFT_EVENT = gql`
  mutation EndCashDrawerShift($shiftId: String!,
   $amount: Float, $currency: String ){
  endCashDrawerShift(shiftId: $shiftId, cashDrawerShift:{
    closedCashMoney:{
      amount: $amount
      currency: $currency
    }
  }){
    id
  }
}
`

export const GET_CASH_DRAWER = gql`
  query Retrive_CashDrawer($locationId: String!, $shiftId: String!, $cashRegisterId: String){
  retrieveCashDrawerShift(locationId:$locationId, shiftId:$shiftId, cashRegisterId: $cashRegisterId){
    id
    openedAt
    openingEmployee {
      id
      email
    }
    endedAt
    endingEmployee {
      id
      email
    }
    closedAt
    closingEmployee {
      id
      email
    }
    description
    state
    status
    openedCashMoney{
      amount
    }
    expectedCashMoney{
      amount
    }
    cashPaidInMoney{
      amount
    }
    cardPaidInMoney{
        amount
      }
      cardPaymentsMoney{
        amount
      }
      cardRefundsMoney{
        amount
      }
    cashPaidOutMoney{
      amount
    }
    closedCashMoney{
      amount
    }
    cashPaymentMoney{
      amount
    }
    cashRefundsMoney{
      amount
    }
    FCDC
    failMessage
  }
}
`

// (locationId:"1234123", beginTime:"2020-04-29T10:21:01.650Z")
export const LIST_CASH_DRAWER_SHIFTS = gql`
query listCashDrawerShifts(
  $locationId: String!,
  $beginTime: String,
  $endTime: String,
  $state: CashDrawerShiftState,
  $cursor: String,
  $limit: Int,
  $locId: String,
  $cashRegisterId: String
){
  listCashDrawerShifts(
    locationId: $locationId,
    beginTime: $beginTime,
    endTime: $endTime,
    state: $state,
    cursor: $cursor,
    limit: $limit,
    requestedLocationAndCashRegister: {
      locationId: $locId,
      cashRegisterId: $cashRegisterId
    }
  ){
    cursor
    items{
      id
      cashRegisterId
      state
      status
      FCDC
      openedAt
      expectedCashMoney {
        amount
        currency
      }
      openingEmployee {
        id
        email
      }
      endedAt
      endingEmployee {
        id
        email
      }
      closedAt
      closingEmployee {
        id
        email
      }
    }
  }
}
`

export const GET_EMPLOYEE_INFO = gql`
  query RetrieveEmployee($id: String!) {
    RetrieveEmployee(id: $id){
        firstName
        lastName
      }
  }
`

export const GET_CURRENT_DRAWER = gql`
  query CurrentCashDrawerShift($locationId: String! , $cashRegisterId: String   ) {
    currentCashDrawerShift(
      locationId: $locationId
      requestedLocationAndCashRegister: {
        locationId: $locationId,
        cashRegisterId: $cashRegisterId
      }
      ){
      id
      openedAt
      openingEmployee {
        id
        email
      }
      endedAt
      endingEmployee {
        id
        email
      }
      closedAt
      closingEmployee {
        id
        email
      }
      description
      state
      status
      openedCashMoney{
        amount
      }
      expectedCashMoney{
        amount
      }
      cashPaidInMoney{
        amount
      }
      cashPaidOutMoney{
        amount
      }
      cardPaidInMoney{
        amount
      }
      cardPaymentsMoney{
        amount
      }
      cardRefundsMoney{
        amount
      }
      closedCashMoney{
        amount
      }
      cashPaymentMoney{
        amount
      }
      cashRefundsMoney{
        amount
      }
      FCDC
      cashRegister {
        id
      }
      failMessage
    }
  }
`

export const SYNC_CASH_DRAWER_SHIFTS = gql`
    mutation retry($shiftSyncObjects: [SyncObject]!, $locId: String, $cashRegisterId: String){
        syncCashDrawerShifts(
          shiftSyncObjects: $shiftSyncObjects
          requestedLocationAndCashRegister: {
            locationId: $locId,
            cashRegisterId: $cashRegisterId
          }
          ){
            cursor
            items{
              id
              FCDC
            }
        }
    }
`
