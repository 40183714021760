import { gql } from '@apollo/client'

export const LIST_ITEMS = gql`
  query listItems($filter: Filter, $limit: Int, $nextToken: String, $locId: String, $cashRegisterId: String){
    listItems(listItemsInput: {
      filter: $filter,
      limit: $limit,
      nextToken: $nextToken
    }
    requestedLocationAndCashRegister: {
      locationId: $locId,
      cashRegisterId: $cashRegisterId
    }
    ){
      items{
        id
        barcode
        name
        description
        labelColor
        tax{
          id
          name
          percentage
        }
        measurementUnitId
        measurementUnit {
          name
        }
        taxInclusionType
        itemVariationsCount
        modifierListsCount
        itemVariation{
          id
          name
          priceMoney{
            amount
            currency
          }
          prices {
            amount
            currency
          }
        }
        discountsCount
        discount{
          id
          name
          discountType
          percentage
          amountMoney{
            amount
            currency
          }
          prices {
            amount
            currency
          }
        }
        taxExemptionType
        imageUrl
      },
      nextToken
    }
  }
`
export const LIST_CATEGORIES = gql`
  query listCategories($limit: Int, $nextToken: String, $locId: String, $cashRegisterId: String) {
    listCategories(listCategoriesInput: {
      limit: $limit,
      nextToken: $nextToken
    }
    requestedLocationAndCashRegister: {
      locationId: $locId,
      cashRegisterId: $cashRegisterId
    }
    ) {
      categories {
        id
        name
      },
      nextToken
    },
  }
`

export const LIST_CATEGORY_ITEMS = gql`
  query getCategory($id: String!, $filter: Filter, $limit: Int, $nextToken: String, $locId: String, $cashRegisterId: String){
    getCategory(getCategoryInput: {
      id: $id,
      listItemsInput: {
        filter: $filter,
        limit: $limit,
        nextToken: $nextToken
      }
    }
    requestedLocationAndCashRegister: {
      locationId: $locId,
      cashRegisterId: $cashRegisterId
    }
    ){
      listItems{
        items{
          id
          barcode
          name
          description
          labelColor
          measurementUnitId
          measurementUnit {
            name
          }
          tax{
            id
            name
            percentage
          }
          taxInclusionType
          itemVariationsCount
          modifierListsCount
          discountsCount
          discount{
            id
            name
            discountType
            percentage
            amountMoney{
              amount
              currency
            }
            prices {
              amount
              currency
            }
          }
          itemVariation{
            id
            name
            priceMoney{
              amount
              currency
            }
            prices {
              amount
              currency
            }
          }
          taxExemptionType
          imageUrl
        },
        nextToken
      }
    }
  }
`

export const GET_ITEM = gql`
  query getItem($searchField: String!, $searchValue: String!, $nextVariations: String, $locId: String, $cashRegisterId: String ) {
    getItem(getItemInput: {
      searchBy: {
        name: $searchField
        value: $searchValue
      }
      listItemVariationsInput: {
        nextToken: $nextVariations
      }
    }
    requestedLocationAndCashRegister: {
      locationId: $locId,
      cashRegisterId: $cashRegisterId
    }
    ){
      item{
        id
        barcode
        name
        description
        labelColor
        tax{
          id
          name
          percentage
        }
        taxExemptionType
        taxInclusionType
        itemVariationsCount
        modifierListsCount
        discountsCount
        imageUrl
      }
      listItemVariations{
        itemVariations{
          itemId
          id
          name
          priceMoney{
            amount
            currency
          }
        }
        nextToken
      }
      listDiscounts{
        discounts{
          id
          name
          discountType
          percentage
          amountMoney{
            amount
            currency
          }
        }
      }
    }
  }
`

export const LIST_SAVED_ORDERS = gql`
  query listOrder($locationId: String!, $cashRegisterId: String){
    listOrders(
      listOrdersInput:{
      locationId: $locationId
      status: SAVE
    },
    requestedLocationAndCashRegister: {
      locationId: $locationId,
      cashRegisterId: $cashRegisterId
    }
    ){
      orders{
        id
        totalMoney{
          amount
          currency
        }
        status
      }
    }
  }
`
