/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useContext, useEffect, useRef } from 'react'
import {
  View,
  SafeAreaView,
  TouchableOpacity,
  useWindowDimensions,
} from 'react-native'
import PropTypes from 'prop-types'
import { useTheme } from 'react-native-themed-styles'
import { useMutation, useLazyQuery } from '@apollo/client'
import moment from 'moment'
import 'moment/locale/sq'
import 'moment/locale/en-gb'
import { useDispatch, useSelector } from 'react-redux'

import {
  Icon,
  Text,
  Button,
  Loader,
  Header,
  LoaderModal,
  Modal,
  ModalContent,
  SwipeList,
  ListLoader,
} from '../../Components'
import { getCurrency, getOrder } from '../Checkout/Checkout.Selectors'
import calculatePrice, {
  clearExtraDetails,
  useCountOrders,
  useOrderCount,
} from '../../Utils/Order'
import { getErrorMessage, useConfig } from '../../Utils/index'
import { useLanguage } from '../../Utils/Language'
import { INF } from '../../Utils/Helpers'

import { useLocationDetails } from '../../Utils/AuthDetails'
import { resetOrder, updateOrder } from '../Checkout/Checkout.Actions'

import Theme from '../../Themes/Theme'
import { images, animations } from '../../Themes'
import themedStyles, {
  itemStyles,
  hiddenItemStyles,
} from './OpenedOrders.Styles'

import {
  LIST_SAVED_ORDERS,
  DELETE_SAVED_ORDER,
  SAVE_ORDER,
  RETRIEVE_SAVED_ORDER,
} from './OpenedOrders.Schema'

const LANGUAGES = {
  al: 'sq',
  en: 'en-gb',
}

function Item(props) {
  const lang = useLanguage()
  const [styles] = useTheme(itemStyles)
  const { colors } = useContext(Theme)

  const {
    id,
    totalMoney,
    createdAt,
    onPress,
    isFirst,
    isLast,
  } = props

  return (
    <View
      style={[
        styles.wrapper,
        styles['firstItem_' + isFirst],
        styles['lastItem_' + isLast],
      ]}>
      <TouchableOpacity style={styles.container} onPress={onPress}>
        <View style={styles.infoContainer}>
          <View style={styles.leftContainer}>
            <Text i18nKey={id} numberOfLines={1} />
            <Text
              i18nKey={moment(createdAt).locale(LANGUAGES[lang]).fromNow()}
              weight="light"
              color={colors.secondaryText}
              size="footnote"
              numberOfLines={1}
            />
          </View>
          <View style={styles.rightContainer}>
            <Text
              i18nKey="checkout_price_currency_value"
              placeHolders={[
                INF.format(totalMoney?.amount || 0),
                totalMoney?.currency,
              ]}
              numberOfLines={1}
            />
            {/* {Boolean(exchangeRate) && <Text
              i18nKey={EINF.format((totalMoney?.amount || 0) / exchangeRate)}
              weight="light"
              color={colors.secondaryText}
              size="footnote"
              numberOfLines={1}
            />} */}
          </View>
        </View>
      </TouchableOpacity>
    </View>
  )
}
Item.propTypes = {
  id: PropTypes.string,
  totalMoney: PropTypes.shape({
    amount: PropTypes.number,
    currency: PropTypes.string,
  }),
  exchangeRate: PropTypes.string,
  createdAt: PropTypes.string,
  onPress: PropTypes.func,
  isFirst: PropTypes.bool,
  isLast: PropTypes.bool,
}

function HiddenItem(props) {
  const [styles] = useTheme(hiddenItemStyles)
  const { colors } = useContext(Theme)

  const {
    needsConfirmation,
    onPress,
    onConfirm,
    onCancel,
    loading,
    isFirst,
    isLast,
  } = props

  return (
    <View
      style={[
        styles.wrapper,
        styles['firstItem_' + isFirst],
        styles['lastItem_' + isLast],
      ]}>
      {loading ? (
        <View style={styles.loader}>
          <Loader size={32} source={animations.vfLoaderWhite} />
        </View>
      ) : needsConfirmation ? (
        <TouchableOpacity style={styles.option} onPress={onPress}>
          <Icon source={images.delete} color={colors.white} size={24} />
        </TouchableOpacity>
      ) : (
        <View style={styles.optionFull}>
          <TouchableOpacity style={styles.confirm} onPress={onConfirm}>
            <Icon source={images.delete} color={colors.white} size={24} />
            <Text
              i18nKey="opened_orders_delete_confirm"
              color={colors.white}
              style={styles.confirmText}
            />
          </TouchableOpacity>
          <TouchableOpacity style={styles.cancel} onPress={onCancel}>
            <Icon source={images.closeIcon} color={colors.white} size={20} />
          </TouchableOpacity>
        </View>
      )}
    </View>
  )
}
HiddenItem.propTypes = {
  needsConfirmation: PropTypes.bool,
  onPress: PropTypes.func,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  loading: PropTypes.bool,
  isFirst: PropTypes.bool,
  isLast: PropTypes.bool,
}

function OpenedOrders({
  navigation: { goBack, navigate, addListener },
}) {
  const { switchLocation: { deviceId, locationId } } = useConfig()


  const [styles] = useTheme(themedStyles)
  const { colors } = useContext(Theme)
  const { euRate} = useLocationDetails()
  const { width, height } = useWindowDimensions()
  const savedOrdersCount = useOrderCount()
  const countSavedOrders = useCountOrders()
  const currency = useSelector(getCurrency)
  const { order: orderObj } = useSelector(getOrder)

  const dispatchAction = useDispatch()

  const clearOrder = () => dispatchAction(resetOrder())
  const setOrder = (obj) => dispatchAction(updateOrder(obj))

  moment.locale(LANGUAGES.al)

  const [loading, setLoading] = useState(true)
  const [savedOrders, setSavedOrders] = useState([])
  const cursor = useRef()
  const [listSavedOrders, { error: listingError, data }] = useLazyQuery(
    LIST_SAVED_ORDERS,
    { fetchPolicy: 'network-only' }
  )
  const refetch = () => {
    setLoading(true)
    setSavedOrders([])
    cursor.current = null
    listSavedOrders({
      variables: {
        locationId: locationId,
        cashRegisterId: deviceId,
      },
    })
  }
  useEffect(() => {
    refetch()
  }, [width, height])

  const filterOrders = (orders) => orders?.filter(item => item?.totalMoney?.currency === currency?.currencyCode)

  useEffect(() => {
    const listOrders = data?.listOrders
    if (listOrders) {
      setSavedOrders((prev) => prev.concat(filterOrders(listOrders?.orders) || []))
      cursor.current = listOrders?.nextToken
      setLoading(false)
    }
  }, [data])

  const onEndReached = () => {
    if (cursor.current) {
      setLoading(true)
      listSavedOrders({
        variables: {
          locationId: locationId,
          cashRegisterId: deviceId,
          nextToken: cursor.current,
        },
      })
    }
  }

  const [deleteOrder, { loading: deleting }] = useMutation(DELETE_SAVED_ORDER)
  const [saveOrder, { loading: saving }] = useMutation(SAVE_ORDER)
  const [retrieveOrder, { loading: retrieving, data: retrievedOrder }] = useLazyQuery(RETRIEVE_SAVED_ORDER, { fetchPolicy: 'network-only' })

  useEffect(() => {
    const unsubscribe = addListener('beforeRemove', () => {
      countSavedOrders()
    })
    return unsubscribe
  }, [])

  const [rightOpenValueFull, setRightOpenValueFull] = useState(-width + 30)
  const [needsConfirmation, setNeedForConfirmation] = useState(true)

  const measureView = (event) => {
    setRightOpenValueFull(-event.nativeEvent.layout.width)
  }
  const openRow = (rowMap, id) => {
    setNeedForConfirmation(false)
    rowMap[id].manuallySwipeRow(rightOpenValueFull)
  }
  const closeRow = (rowMap, id) => {
    rowMap[id].closeRow()
    setNeedForConfirmation(true)
  }
  const deleteRow = (rowMap, id) => {
    deleteOrder({
      variables: {
        id: id,
        locationId,
        locId: locationId,
        cashRegisterId: deviceId,
      },
    })
      .then((r) => {
        const deletedId = r?.data?.deleteSavedOrder?.id
        if (deletedId) {
          closeRow(rowMap, id)
          // displayToast('opened_orders_delete_success')
          setSavedOrders((prev) => prev?.filter(el => el.id !== deletedId))
        }
      })
      .catch((e) => {
        handleError(e)
        closeRow(rowMap, id)
      })
  }
  const resetNeedForConfirmation = () => {
    setNeedForConfirmation(true)
  }

  const reducer = (acc, curr) => acc + (curr?.quantity || 0)
  const len = (orderObj.lineItems || []).reduce(reducer, 0)
  const openChangeConfirm = () => {
    openModal(
      'opened_orders_clear_title',
      'opened_orders_clear_desc_' + ((len + 1) % len),
      [len + ''],
      'opened_orders_clear_save_first',
      () => saveNewOrder(),
      'opened_orders_clear_cancel',
      () => closeModal(),
      'opened_orders_clear_override',
      () => overrideCart(),
    )
  }
  const cartToRetrieve = useRef(null)
  const changeCart = (item) => {
    if (len > 0) {
      cartToRetrieve.current = item
      openChangeConfirm()
    } else {
      retrieveOrder({
        variables: {
          id: item.id,
          locationId: locationId,
          cashRegisterId: deviceId,
        },
      })
    }
  }
  const startNewOrder = () => {
    if (len > 0) {
      cartToRetrieve.current = null
      openChangeConfirm()
    } else {
      clearOrder()
      goBack()
    }
  }
  const overrideCart = () => {
    closeModal()
    if (!cartToRetrieve.current) {
      clearOrder()
      goBack()
    } else {
      retrieveOrder({
        variables: {
          id: cartToRetrieve.current.id,
          locationId: locationId,
          cashRegisterId: deviceId,
        },
      })
    }
  }

  useEffect(() => {
    if (retrievedOrder) {
      setOrder(calculatePrice(clearExtraDetails({
        ...(retrievedOrder?.getOrder || {
          lineItems: [],
          appliedDiscounts: [],
        }),
      })), currency.currencyCode)
      goBack()
    }
  }, [retrievedOrder])

  const saveNewOrder = (orderParam = orderObj) => {
    const order = { ...orderParam }
    saveOrder({
      variables: {
        locationId: locationId,
        cashRegisterId: deviceId,
        orderInput: {
          status: 'SAVE',
          locationId,
          ...clearExtraDetails(order),
        },
      },
    })
      .then(r => {
        if (r) {
          if (cartToRetrieve.current === null) {
            clearOrder()
            goBack()
          } else {
            retrieveOrder({
              variables: {
                id: cartToRetrieve.current.id,
                locationId: locationId,
                cashRegisterId: deviceId,
              },
            })
          }
        }
      })
      .catch(e => {
        handleError(e)
      })
    closeModal()
  }

  const [modal, setModal] = useState({
    isVisible: false,
    icon: images.warningIcon,
    title: '',
    desc: '',
    descPlaceholders: [],
    primaryText: '',
    primaryAction: null,
    secondaryText: '',
    secondaryAction: null,
    tertiaryText: '',
    tertiaryAction: null,
  })
  const openModal = (
    title = 'opened_orders_error',
    desc = '',
    descPlaceholders = [],
    primaryText = '',
    primaryAction = null,
    tertiaryText = '',
    tertiaryAction = null,
    secondaryText = '',
    secondaryAction = null,
    icon = images.warningIcon,
  ) => {
    setModal({
      isVisible: true,
      icon,
      title,
      desc,
      descPlaceholders,
      primaryText,
      primaryAction,
      secondaryText,
      secondaryAction,
      tertiaryText,
      tertiaryAction,
    })
  }
  const closeModal = () => {
    setModal({
      isVisible: false,
      icon: images.delete,
      title: '',
      desc: '',
      primaryText: '',
      primaryAction: null,
      secondaryText: '',
      secondaryAction: null,
      tertiaryText: '',
      tertiaryAction: null,
    })
  }

  const handleError = (e) => {
    if (e) {
      const errorMessage = getErrorMessage(e)
      if (errorMessage === 'OrderNoOpenDrawer') {
        openModal(
          'receipt_drawer_warning_title',
          'checkout_drawer_error_message',
          [],
          'receipt_button_open_drawer',
          () => {
            navigate('CurrentDrawer', { nextAction: 'opened_orders_continue' })
            closeModal()
          },
          'orders_button_go_back',
          () => closeModal(),
        )
      } else {
        openModal(
          'opened_orders_error',
          errorMessage,
          [],
          'orders_button_go_back',
          () => closeModal(),
          '',
          undefined,
        )
      }
    }
  }

  useEffect(() => {
    handleError(listingError)
  }, [listingError])

  const getItemLayout = (_, index) => ({
    length: 60,
    offset: 60 * index,
    index,
  })
  const keyExtractor = (item) => item.id
  const renderItem = ({ item, index }) => (
    <Item
      {...item}
      exchangeRate={euRate}
      onPress={() => changeCart(item)}
      isFirst={index === 0}
      isLast={index === savedOrders.length - 1}
    />
  )
  const renderHiddenItem = ({ item, index }, rowMap) => (
    <HiddenItem
      key={item.id}
      needsConfirmation={needsConfirmation}
      onPress={() => openRow(rowMap, item.id)}
      onConfirm={() => deleteRow(rowMap, item.id)}
      onCancel={() => closeRow(rowMap, item.id)}
      loading={deleting}
      isFirst={index === 0}
      isLast={index === savedOrders.length - 1}
    />
  )

  return (
    <SafeAreaView style={styles.container}>
      <Header
        title="opened_orders_title"
        translateOption={{ count: savedOrdersCount }}
        titlePlaceHolders={[savedOrdersCount]}
        image={images.closeIcon}
        onPress={goBack}
      />

      <Button
        backgroundColor={colors.accent}
        title="opened_orders_create_new"
        borderColor={colors.transparent}
        style={styles.chargeButton}
        onPress={startNewOrder}
      />

      <Text i18nKey="opened_orders_section_title" style={styles.sectionTitle} />

      <SwipeList
        onLayout={(event) => measureView(event)}
        useFlatList={true}
        contentContainerStyle={styles.listContainerStyle}
        ItemSeparatorComponent={() => (
          <View style={styles.listSeparatorStyle} />
        )}
        keyExtractor={keyExtractor}
        getItemLayout={getItemLayout}
        data={savedOrders}
        closeOnRowBeginSwipe={true}
        closeOnRowOpen={true}
        style={styles.listStyle}
        showsVerticalScrollIndicator={false}
        onRowClose={resetNeedForConfirmation}
        renderItem={renderItem}
        renderHiddenItem={renderHiddenItem}
        ListFooterComponent={<ListLoader loading={loading} isInitial={savedOrders.length === 0} />}
        ListEmptyComponent={!loading && <Text i18nKey="save_items_no_existing_orders" />}
        onEndReachedThreshold={0.5}
        onEndReached={() => onEndReached()}
        refreshing={false}
        onRefresh={() => refetch()}
        closeOnScroll={true}
        disableRightSwipe
        rightOpenValue={-100}
        closeOnRowPress
      />
      <LoaderModal loading={saving} message="save_items_saving" />
      <LoaderModal
        loading={retrieving}
        message="save_items_retrieving_selected"
      />
      <Modal
        isVisible={modal.isVisible}
        onBackButtonPress={closeModal}
        onBackdropPress={closeModal}
        animationIn="fadeIn"
        animationOut="fadeOut">
        <ModalContent
          onClose={closeModal}
          contentContainerStyle={styles.modalContent}>
          <Icon source={modal.icon} size={40} />
          <Text
            i18nKey={modal.title}
            align="center"
            size="h4"
            weight="bold"
            style={styles.modalTitle}
          />
          <Text i18nKey={modal.desc} placeHolders={modal.descPlaceholders} align="center" />
          {modal.primaryAction && (
            <Button
              title={modal.primaryText}
              variant="active"
              style={styles.modalPrimaryButton}
              onPress={modal.primaryAction}
            />
          )}
          {modal.secondaryAction && (
            <Button
              title={modal.secondaryText}
              variant="outline"
              style={styles.modalSecondaryButton}
              onPress={modal.secondaryAction}
            />
          )}
          {modal.tertiaryAction && (
            <Button
              title={modal.tertiaryText}
              variant="text"
              style={styles.modalTertiaryButton}
              onPress={modal.tertiaryAction}
            />
          )}
        </ModalContent>
      </Modal>
    </SafeAreaView>
  )
}

OpenedOrders.propTypes = {
  route: PropTypes.object,
  navigation: PropTypes.object,
}

export default OpenedOrders
