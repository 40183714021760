import React, { useState, useContext } from 'react'
import { Easing, TouchableOpacity, Animated, Platform } from 'react-native'
import PropTypes from 'prop-types'
import { useTheme } from 'react-native-themed-styles'

import themedStyles from './RadioInput.styles'
import Theme from '../../Themes/Theme'

const outputRange = Platform.select({
  ios: [0, 1.2, 0.6],
  android: [0, 1.8, 1],
  web: [0, 1.8, 1],
})

function RadioInput(props) {
  const [styles] = useTheme(themedStyles)
  const { colors } = useContext(Theme)

  const {
    index,
    selected,
    onPress,
    color = colors.accent,
    style,
  } = props

  const [anim] = useState(new Animated.Value(0))

  let touchableStyle, viewStyle
  if (selected === index) {
    Animated.timing(anim, {
      toValue: 1,
      duration: 300,
      useNativeDriver: true,
      easing: Easing.linear,
    }).start()
    touchableStyle = { borderColor: color }
    viewStyle = { backgroundColor: color }
  } else {
    Animated.timing(anim, {
      toValue: 0,
      duration: 300,
      useNativeDriver: true,
      easing: Easing.linear,
    }).start()
    touchableStyle = {}
    viewStyle = { backgroundColor: colors.placeholder }
  }

  return (
    <TouchableOpacity
      style={[styles.container, style, touchableStyle]}
      onPress={() => onPress(index)}
    >
      <Animated.View style={[
        styles.selection,
        viewStyle,
        {
          transform: [
            {
              scale: anim.interpolate({
                inputRange: [0, 0.3, 1],
                outputRange: outputRange,
              }),
            },
          ],
        },
      ]} />
    </TouchableOpacity>
  )
}

RadioInput.propTypes = {
  index: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  selected: PropTypes.any,
  onPress: PropTypes.func,
  color: PropTypes.string,
  style: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
}

export default RadioInput
