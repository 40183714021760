
import { styleSheetFactory } from '../../Themes/Colors'

const styles = styleSheetFactory(colors => ({
    detailsContainer: {
        flexGrow: 1,
        // marginHorizontal: 15,
        // marginTop: 15,
        justifyContent: 'space-between',
    },
    inputWrapper: {
        marginVertical: 5,
    },
    inputContainer: {
        minHeight: 60,
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
    },
    container: {
        flexGrow: 1,
    },
    sectionTitle: {
        marginTop: 15,
        marginBottom: 5,
    },
    sectionDescription: {
        marginBottom: 5,
    },
    listStyle: {
        flex: 1,
    },
    listContainerStyle: {
        flexGrow: 1,
        paddingHorizontal: 15,
        // paddingTop: 10,
        paddingBottom: 30,
    },
    fab: {
        position: 'absolute',
        bottom: 30,
        right: 15,
        height: 50,
        width: 50,
        borderRadius: 25,
        backgroundColor: colors.background,
        shadowColor: colors.black,
        shadowOffset: {
            width: 0,
            height: 1,
        },
        shadowOpacity: 0.22,
        shadowRadius: 2.22,
        elevation: 3,
    },
    fabTouchable: {
        height: 50,
        width: 50,
        borderRadius: 25,
        backgroundColor: colors.tertiaryAccent,
        justifyContent: 'center',
        alignItems: 'center',
    },
    fabIcon: {
        tintColor: colors.white,
    },
    tabView: {
        backgroundColor: colors.background,
        paddingTop: 10,
    },
    button: {
        marginHorizontal: 15,
        marginBottom: 15,
    },
    virtualButton: {
        marginBottom: 15,
    },
    buttonWrap: {
        flex: 1,
        flexGrow: 1,
        justifyContent: 'flex-end',
    },
    deviceInfoContainer: {
        backgroundColor: colors.white,
        padding: 15,
        borderRadius: 7,
        marginBottom: 10,
    },
    invoiceInfoContainer: {
        backgroundColor: colors.white,
        padding: 15,
        borderRadius: 7,
        marginVertical: 15,
    },
    deviceInfoRow: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingVertical: 3,
    },
    busInfoHeader: {
        marginBottom: 5,
    },
    editBusinessButton: {
        marginHorizontal: 15,
        marginBottom: 10,
    },
    infoText: {
        marginBottom: 15,
        marginTop: 5,
        textAlign: 'left',
        fontFamily: 'Vodafone Rg',
        fontSize: 14,
        color: colors.secondaryText,
    },
    noteText: {
        marginBottom: 15,
        marginTop: 10,
        textAlign: 'left',
        fontFamily: 'Vodafone Rg',
        fontSize: 16,
        color: colors.secondaryText,
    },
    boldText: {
        fontWeight: 'bold',
        color: colors.black,
    },
    scrollContainer: {
        marginHorizontal: 15,
        marginTop: 15,
        backgroundColor: colors.background,
        paddingBottom: 50,
    },
    marginTop: {
        marginTop: 25,
    },
    safeAreaContainer: {
        flex: 1,
    },
}))

export default styles
