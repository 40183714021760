import React, { useState, useMemo } from 'react'
import {
  SafeAreaView,
  View,
} from 'react-native'
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs'
import { useTheme } from 'react-native-themed-styles'
import PropTypes from 'prop-types'
import { Host } from 'react-native-portalize'

import {
  Header,
  PeriodCalendar,
  Tabs,
} from '../../Components'
import ContentManager from '../../Utils/ContentManager'

import { useUserDetails } from '../../Utils'

import themedStyles from './EInvoice.Styles'

import images from '../../Themes/Images'

import AllInvoices from './Routes/AllInvoices'
import MyInvoices from './Routes/MyInvoices'

const ALLOW_ROUTE = ['Admin']
const Tab = createMaterialTopTabNavigator()

function EInvoice({ navigation: { goBack, navigate } }) {
  const [styles] = useTheme(themedStyles)
  const { role } = useUserDetails()
  const [selectedDates, setSelectedDates] = useState({})
  const [allInvoicesDate, setAllInvoicesDates] = useState({})
  const translate = (key) => {
    return ContentManager.translate(key)
  }

  const renderTabs = useMemo(() => {
    return (
      <Tabs
        initialRouteName={translate('einvoice_my_invoices_tab')}
        screenOptions={
          {
            swipeEnabled: false,
          }
        }
      >
        <Tab.Screen key={'tab-myInvoices'} name={translate('einvoice_my_invoices_tab')}>
          {(props) => (
            <MyInvoices  {...props} selectedDates={allInvoicesDate} />
          )}
        </Tab.Screen>
        <Tab.Screen key={'tab-allInvoices'} name={translate('einvoice_all_invoices_tab')}>
          {(props) => (
            <AllInvoices {...props} selectedDates={allInvoicesDate} />
          )}
        </Tab.Screen>
      </Tabs>
    )
  }, [allInvoicesDate])

  const onSearchPress = () => {
    setAllInvoicesDates(selectedDates)
  }

  return (
    <SafeAreaView style={styles.container} >
      <Host>
        <Header title="header_einvoice"
          image={images.back}
          onPress={() => goBack()}
        />
        {ALLOW_ROUTE.includes(role) ? renderTabs :
          <View style={styles.myEinvoiceView}>
            <MyInvoices navigation={{ navigate }} selectedDates={allInvoicesDate} />
          </View>
        }
        <View style={styles.searchContainer}>
          <PeriodCalendar
            placeholder="einvoice_search_placeholder"
            onSearch={onSearchPress}
            onIntervalChange={setSelectedDates}
            onSearchClean={() => setAllInvoicesDates({})}
          />
        </View>
      </Host>
    </SafeAreaView>
  )
}
EInvoice.propTypes = {
  navigation: PropTypes.object,
  route: PropTypes.object,
}

export default EInvoice
