/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext, useCallback } from 'react'
import { View, SafeAreaView, Text as RNText } from 'react-native'
import { useFocusEffect } from '@react-navigation/native'
import { useLazyQuery, useMutation } from '@apollo/client'
import moment from 'moment'
import { v1 as uuidv1 } from 'uuid'
import PropTypes from 'prop-types'

import { useTheme } from 'react-native-themed-styles'

import {
  SimpleList,
  SimpleListElement,
  Text,
  Loader,
  Button,
  InputRow,
  Icon,
  Header,
  ScrollWrapperWeb,
} from '../../Components'
import {
  CHANGE_CASH_DRAWER_SHIFT_STATE,
  GET_CASH_DRAWER,
} from '../CashDrawer/CashDrawer.schema'

import { useLanguage } from '../../Utils/Language'
import { usePermissions } from '../../Utils/Permissions'
import { useUserDetails } from '../../Utils/AuthDetails'
import { INF } from '../../Utils/Helpers'

import { useConfig } from '../../Utils'

import Theme from '../../Themes/Theme'
import themedStyles from './CashDrawerReport.Styles'

import images, { animations } from '../../Themes/Images'


const LANGUAGES = {
  al: 'sq',
  en: 'en-gb',
}

const CLOSE_BUTTON_TITLE = {
  true: 'drawer_confirm_close',
  false: 'drawer_close_button',
}

function CashDrawerReport({
  route: {
    params: { id, cashRegisterId },
  },
  navigation,
}) {

  const { switchLocation: { deviceId, locationId } } = useConfig()

  const [styles] = useTheme(themedStyles)
  const { colors } = useContext(Theme)
  const { role } = useUserDetails()
  const { cashDrawer = {} } = usePermissions()
  const lang = useLanguage()

  // Get drawer data
  const [drawerData, setDrawerData] = useState([])
  const [drawerDataLoading, setDrawerDataLoading] = useState(true)

  const [expectedInDrawer, setExpectedInDrawer] = useState('')
  const setValueFormat = /^\d*(\.\d{0,2})?$/
  const submitValueFormat = /^\d+(\.\d{0,2})?$/
  const [actualldInDrawer, setActualldInDrawer] = useState('')
  const [description, setDescription] = useState('')
  const [state, setState] = useState('')

  // Close drawer
  const [confirmClose, setConfirmClose] = useState(false)
  const [disabled, setDisabled] = useState(true)

  const [changeShiftState, { loading: closing }] = useMutation(
    CHANGE_CASH_DRAWER_SHIFT_STATE,
    {
      variables: {
        locId: locationId,
        cashRegisterId: deviceId,
        shiftId: id,
        newState: 'CLOSE',
        amount: actualldInDrawer,
        currency: 'Lekë',
        description: description,
      },
    },
  )

  const [getCashDrawer, { loading, error, data }] = useLazyQuery(
    GET_CASH_DRAWER,
    {
      fetchPolicy: 'network-only',
      variables: {
        locationId: 'test',
        shiftId: id,
        cashRegisterId: cashRegisterId,
      },
    },
  )

  const replaceAt = (string, index, replacement) => {
    return string.substr(0, index) + replacement + string.substr(index + replacement.length)
  }

  const changeValue = (text) => {
    let newString = text
    const indexOfComa = text.indexOf(',')
    if (indexOfComa >= 0) {
      newString = replaceAt(text, indexOfComa, '.')
    }
    if (setValueFormat.test(newString)) {
      setActualldInDrawer(newString)
      if (text === '') {
        setDisabled(true)
      } else {
        setDisabled(false)
      }
    }
  }

  const closeDrawer = () => {
    if (submitValueFormat.test(actualldInDrawer)) {
      if (confirmClose) {
        changeShiftState()
          .then((r) => {
            if (r) {
              // displayToast('drawer_close_success')
            }
            getCashDrawer()
          })
          .catch(e => {
            // displayToast(getErrorMessage(e), images.warning)
          })
      } else {
        setConfirmClose(true)
      }
    }
  }

  useFocusEffect(
    useCallback(() => {
      getCashDrawer()
      return
    }, []),
  )

  const renderRightComponent = (rightLabel) => {
    return (
      <View style={styles.rightComponentStyle}>
        <Text size="footnote" style={styles.itemRightLabel} i18nKey={rightLabel} align="center" />
        <Icon source={images.chevronRight} size={20} color={colors.primaryIcon} />
      </View>
    )
  }

  const renderLeftComponent = (leftLabel) => {
    return (
      <View style={styles.leftComponentWrapper}>
        {/* <RNText style={styles.leftText}>{translate(leftLabel)}</RNText> */}
        <Text i18nKey={leftLabel} weight="light" size="footnote"  />
        <RNText style={styles.listElementStar}>{'*'}</RNText>
      </View>
    )
  }

  useEffect(() => {
    if (data) {
      const res = data.retrieveCashDrawerShift
      const totalPayIO =
        res?.cashPaidInMoney?.amount - res?.cashPaidOutMoney?.amount
      let result = [
        {
          id: uuidv1(),
          leftText: 'drawer_id_label',
          rightText: res?.FCDC,
          ratio: '1:3',
        },
        {
          id: uuidv1(),
          leftText: 'drawer_started_label',
          rightText: moment(res?.openedAt).locale(LANGUAGES[lang]).format('DD/MM/YYYY hh:mm A'),
          ratio: '1:2',
        },
        {
          id: uuidv1(),
          leftText: 'drawer_started_by_label',
          rightText: res?.openingEmployee?.email,
          ratio: '1:2',
        },
      ]

      if (res?.endingEmployee) {
        result = result.concat([
          {
            id: uuidv1(),
            leftText: 'drawer_ended_label',
            rightText: moment(res?.endedAt).locale(LANGUAGES[lang]).format('DD/MM/YYYY hh:mm A'),
            ratio: '1:2',
          },
          {
            id: uuidv1(),
            leftText: 'drawer_ended_by_label',
            rightText: res?.endingEmployee?.email,
            ratio: '1:2',
          },
        ])
      }

      if (res?.closingEmployee) {
        result = result.concat([
          {
            id: uuidv1(),
            leftText: 'drawer_closed_label',
            rightText: moment(res?.endedAt).locale(LANGUAGES[lang]).format('DD/MM/YYYY hh:mm A'),
            ratio: '1:2',
          },
          {
            id: uuidv1(),
            leftText: 'drawer_closed_by_label',
            rightText: res?.closingEmployee?.email,
            ratio: '1:2',
          },
        ])
      }

      result = result.concat([
        {
          id: uuidv1(),
          leftText: 'drawer_starting_cash_label',
          rightText: INF.format(res?.openedCashMoney?.amount) + ' Lekë',
        },
        {
          id: uuidv1(),
          leftText: 'drawer_paid_inout_label',
          rightComponent: renderRightComponent(INF.format(Number(totalPayIO.toFixed(2))) + ' Lekë'),
          onItemPress: () => navigation.navigate('PayInOut', { shiftId: id, cashRegisterId: cashRegisterId }),
          touchableItems: true,
        },
        {
          id: uuidv1(),
          leftText: 'drawer_cash_sales_label',
          rightComponent: renderRightComponent(INF.format(res?.cashPaymentMoney?.amount || 0) + ' Lekë'),
          onItemPress: () => navigation.navigate('TodayDrawer', {
            shiftId: id,
            cashRegisterId: cashRegisterId,
            title: 'drawer_cash_sales_label',
            eventType: 'CASH_TENDER_PAYMENT',
            navigateItem: 'Receipt',
            isRefund: false,
            listIsBlank: 'cash_sales_no_data',
          }),
          touchableItems: true,
        },
        {
          id: uuidv1(),
          leftText: 'drawer_cash_refunds_label',
          rightComponent: renderRightComponent(INF.format(res?.cashRefundsMoney?.amount || 0) + ' Lekë'),
          onItemPress: () => navigation.navigate('TodayDrawer', {
            shiftId: id,
            cashRegisterId: cashRegisterId,
            title: 'drawer_cash_refunds_label',
            eventType: 'CASH_TENDER_REFUND',
            navigateItem: 'Refund',
            isRefund: true,
            listIsBlank: 'cash_refunds_no_data',
          }),
          touchableItems: true,
        },
        {
          id: uuidv1(),
          leftComponent: renderLeftComponent('drawer_card_sales'),
          rightComponent: renderRightComponent(INF.format(res?.cardPaymentsMoney?.amount || 0) + ' Lekë'),
          onItemPress: () => navigation.navigate('TodayDrawer', {
            title: 'drawer_card_sales',
            shiftId: id,
            eventType: 'CARD_TENDER_PAYMENT',
            navigateItem: 'Receipt',
            isRefund: false,
            listIsBlank: 'cart_sales_no_data',
            cashRegisterId: cashRegisterId || drawerData.cashRegisterId,
          }),
          touchableItems: true,
        },
        {
          id: uuidv1(),
          leftComponent: renderLeftComponent('drawer_card_refunds'),
          rightComponent: renderRightComponent(INF.format(res?.cardRefundsMoney?.amount || 0) + ' Lekë'),
          onItemPress: () => navigation.navigate('TodayDrawer', {
            title: 'drawer_card_refunds',
            shiftId: id,
            eventType: 'CARD_TENDER_REFUND',
            navigateItem: 'Refund',
            isRefund: true,
            listIsBlank: 'cart_refunds_no_data',
            cashRegisterId: cashRegisterId || drawerData.cashRegisterId,
          }),
          touchableItems: true,
        },
        {
          id: uuidv1(),
          leftComponent: renderLeftComponent('drawer_card_payment'),
          rightComponent: renderRightComponent(INF.format(res?.cardPaidInMoney?.amount || 0) + ' Lekë'),
          onItemPress: () => navigation.navigate('TodayDrawer', {
            title: 'drawer_card_payment',
            eventType: 'CARD_PAID_IN',
            listIsBlank: 'cart_sales_no_data',
            navigateItem: 'PayInOutDetails',
            shiftId: id,
            cashRegisterId: cashRegisterId || drawerData.cashRegisterId,
          }),
          touchableItems: true,
        },
        {
          id: uuidv1(),
          leftText: 'drawer_expected_label',
          rightText: INF.format(res?.expectedCashMoney?.amount) + ' Lekë',
        },
      ])
      const expected = res?.expectedCashMoney?.amount
      const actuall = res?.closedCashMoney?.amount || 0
      setExpectedInDrawer(expected)
      setDescription(res?.description)
      setState(res?.state)
      if (res?.state === 'CLOSE') {
        result.push({
          id: uuidv1(),
          leftText: 'drawer_actual_label',
          rightText: INF.format(actuall) + ' Lekë',
        })
        result.push({
          id: uuidv1(),
          leftText: 'drawer_difference_label',
          rightText: INF.format(actuall - expected) + ' Lekë',
        })
      }
      setDrawerData(result)
    }
  }, [data])

  useEffect(() => {
    if (!data || drawerData === []) {
      setDrawerDataLoading(true)
    } else {
      setDrawerDataLoading(false)
    }
  }, [data, drawerData])

  useEffect(() => {
    if (error) {
      // displayToast(getErrorMessage(error), images.warning)
    }
  }, [error])

  if (loading || drawerDataLoading) {
    return (
      <View style={styles.container}>
        <Loader view="full" size={124} />
      </View>
    )
  }

  const renderListHeader = () => {
    if (state === 'END' && (cashDrawer.closeDrawerEdit || []).includes(role)) {
      return (
        <>
          <InputRow
            label="drawer_actual_label"
            inputType="numeric"
            inputLabel="Lekë"
            value={`${actualldInDrawer}`}
            placeholder="0.00"
            onChangeText={changeValue}
            style={styles.inputRow}
            returnKeyType="next"
          />
          {Number(actualldInDrawer) - Number(expectedInDrawer) !== 0 && (
            <SimpleListElement
              leftText="drawer_difference_label"
              rightText={`${Number(actualldInDrawer) -
                Number(expectedInDrawer).toFixed(2)} Lekë`}
              style={styles.description}
            />
          )}
          <Button
            title={CLOSE_BUTTON_TITLE[confirmClose]}
            titleStyle={styles.buttonTitle}
            style={styles.button}
            variant={disabled ? 'disabled' : confirmClose ? 'active' : 'preActive'}
            onPress={closeDrawer}
            disabled={disabled}
            isActive
            loader={closing}
            loaderComponent={<Loader source={animations.vfLoaderWhite} size={32} />}
          />
        </>
      )
    }

    return null
  }

  function renderSectionSeparator() {
    return <View style={styles.sectionSeparator} />
  }

  return (
    <SafeAreaView style={styles.container}>
      <Header
        title="header_cash_drawer_report"
        image={images.closeIcon}
        onPress={() => navigation.goBack()}
      />
      <ScrollWrapperWeb>
        <SimpleList
          DATA={[{ data: drawerData }]}
          listHeaderComponent={renderListHeader()}
          containerStyle={styles.list}
          sectionList
          sectionSeparatorComponent={renderSectionSeparator}
          onRefresh={() => getCashDrawer()}
          refreshing={drawerDataLoading}
        />
      </ScrollWrapperWeb>
    </SafeAreaView>
  )
}

CashDrawerReport.propTypes = {
  route: PropTypes.objectOf({
    params: PropTypes.objectOf({
      id: PropTypes.string.isRequired,
    }),
  }),
  navigation: PropTypes.object,
}

export default CashDrawerReport
