import { Platform } from 'react-native'

import { styleSheetFactory } from '../../Themes/Colors'

const VODAFONE_RG_BOLD = Platform.select({
  ios: {
    fontFamily: 'Vodafone Rg',
    fontWeight: 'bold',
  },
  android: {
    fontFamily: 'Vodafone Rg Bold',
  },
})

const styles = styleSheetFactory(colors => ({
  default: {
    fontSize: 16,
    color: colors.primaryText,
    fontFamily: 'Vodafone Rg',
    includeFontPadding: false,
    textAlignVertical: 'center',
  },
  defaultTablet: {
    fontSize: 18,
  },
  defaultDesktop: {
    fontSize: 18,
  },
  italic: {
    fontFamily: 'Vodafone Lt',
  },
  light: {
    fontFamily: 'Vodafone Lt',
  },
  lightItalic: {
    fontFamily: 'Vodafone Lt',
  },
  regular: {
    fontFamily: 'Vodafone Rg',
  },
  semiBold: {
    fontFamily: 'Vodafone Rg',
  },
  semiBoldItalic: {
    fontFamily: 'Vodafone Rg',
  },
  bold: VODAFONE_RG_BOLD,
  boldItalic: VODAFONE_RG_BOLD,
  extraBold: VODAFONE_RG_BOLD,
  extraBoldItalic: VODAFONE_RG_BOLD,
  h1: {
    mobile: {
      fontSize: 32,
      lineHeight: 40,
    },
    tablet: {
      fontSize: 56,
      lineHeight: 62,
    },
    desktop: {
      fontSize: 48,
      lineHeight: 62,
    },
  },
  h2: {
    mobile: {
      fontSize: 28,
      lineHeight: 36,
    },
    tablet: {
      fontSize: 40,
      lineHeight: 48,
    },
    desktop: {
      fontSize: 42,
      lineHeight: 50,
    },
  },
  h3: {
    mobile: {
      fontSize: 24,
      lineHeight: 30,
    },
    tablet: {
      fontSize: 34,
      lineHeight: 40,
    },
    desktop: {
      fontSize: 32,
      lineHeight: 42,
    },
  },
  h4: {
    mobile: {
      fontSize: 20,
      lineHeight: 28,
    },
    tablet: {
      fontSize: 24,
      lineHeight: 30,
    },
    desktop: {
      fontSize: 24,
      lineHeight: 34,
    },
  },
  h5: {
    mobile: {
      fontSize: 18,
      lineHeight: 24,
    },
    tablet: {
      fontSize: 20,
      lineHeight: 28,
    },
    desktop: {
      fontSize: 20,
      lineHeight: 28,
    },
  },
  body: {
    mobile: {
      fontSize: 16,
      lineHeight: 22,
    },
    tablet: {
      fontSize: 18,
      lineHeight: 24,
    },
    desktop: {
      fontSize: 18,
      lineHeight: 24,
    },
  },
  footnote: {
    mobile: {
      fontSize: 14,
      lineHeight: 18,
    },
    tablet: {
      fontSize: 14,
      lineHeight: 18,
    },
    desktop: {
      fontSize: 16,
      lineHeight: 18,
    },
  },
  note: {
    mobile: {
      fontSize: 12,
      lineHeight: 18,
    },
    tablet: {
      fontSize: 14,
      lineHeight: 18,
    },
    desktop: {
      fontSize: 16,
      lineHeight: 18,
    },
  },
}))

export default styles
