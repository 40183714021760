/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState, useEffect } from 'react'
import {
    LayoutAnimation,
    ScrollView,
    View,
    TouchableOpacity,
    FlatList,
} from 'react-native'
import moment from 'moment'
import { useMutation } from '@apollo/client'
import { useTheme } from 'react-native-themed-styles'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'

import {
    Text,
    Button,
    Icon,
    InputWithIcon,
    InputWithTags,
    BottomSheetModal,
    DatePickerModal,
    TextInput,
    Switch,
} from '../../../../Components'

import Step from '../Step'

import { updateStepKey, updateCalculatedUBL, updateStep, updatePaymentAttribute } from '../../Payment.Actions'
import { getProcessData, getInvoiceId, getEinvoiceCalculated } from '../../Payment.Selectors'
import { SAVE_EINVOICE } from '../../Payment.Schema'

import ContentManager from '../../../../Utils/ContentManager'
import Theme, { layoutAnimConfig, opacityConfig } from '../../../../Themes/Theme'
import images from '../../../../Themes/Images'

import { useConfig } from '../../../../Utils'

import themedStyles, { inputStyles, selectStyles } from './SecondStep.Styles'

const processTypes = [
    {
        code: 'P1',
        description: 'process_type_p1_description',
        type: 'P1_CONTRACT',
    },
    {
        code: 'P2',
        description: 'process_type_p2_description',
        type: 'P2_PERIODIC_INVOICE',
    },
    {
        code: 'P3',
        description: 'process_type_p3_description',
        type: 'P3_UNFORESEEN_ORDER',
    },
    {
        code: 'P4',
        description: 'process_type_p4_description',
        type: 'P4_ADVANCE_PAYMENT',
    },
    {
        code: 'P5',
        description: 'process_type_p5_description',
        type: 'P5_SPOT_PAYMENT',
    },
    {
        code: 'P6',
        description: 'process_type_p6_description',
        type: 'P6_PAYMENT_BEFORE_PURCHASE',
    },
    {
        code: 'P7',
        description: 'process_type_p7_description',
        type: 'P7_DISPATCH_NOTE',
    },
    {
        code: 'P8',
        description: 'process_type_p8_description',
        type: 'P8_DISPATCH_NOTE_RECEIPT',
    },
    {
        code: 'P9',
        description: 'process_type_p9_description',
        type: 'P9_APPROVAL_OR_NEGATIVE',
    },
    {
        code: 'P11',
        description: 'process_type_p11_description',
        type: 'P11_PARTIAL_INVOICING',
    },
    {
        code: 'P12',
        description: 'process_type_p12_description',
        type: 'P12_SELF_INVOICE',
    },
    {
        code: 'P10',
        description: 'process_type_p10_description',
        type: 'P10_CORRECTIVE_INVOICE',
        disabled: true,
    },
]

const documentTypes = [
    {
        code: '388',
        description: 'document_type_388_description',
        type: 'TAXINVOICE',
    },
    {
        code: '82',
        description: 'document_type_82_description',
        type: 'METERED_SERVICE',
    },
    {
        code: '325',
        description: 'document_type_325_description',
        type: 'PROFORMA_INVOICE',
    },
    {
        code: '326',
        description: 'document_type_326_description',
        type: 'PARTIAL_INVOICE',
    },
    {
        code: '380',
        description: 'document_type_380_description',
        type: 'COMMERCIAL_INVOICE',
    },
    {
        code: '381',
        description: 'document_type_381_description',
        type: 'CREDIT_NOTE',
        disabled: true,
    },
    {
        code: '383',
        description: 'document_type_383_description',
        type: 'DEBIT_NOTE',
    },
    {
        code: '384',
        description: 'document_type_384_description',
        type: 'CORRECTEDINVOICE',
        disabled: true,
    },
    {
        code: '386',
        description: 'document_type_386_description',
        type: 'PREPAYMENT_INVOICE',
    },
    {
        code: '394',
        description: 'document_type_394_description',
        type: 'LEASE_INVOICE',
    },
]

const dateCodes = [
    {
        code: '3',
        description: 'date_codes_3_description',
        type: '3',
    },
    {
        code: '35',
        description: 'date_codes_35_description',
        type: '35',
    },
    {
        code: '432',
        description: 'date_codes_432_description',
        type: '432',
    },
]

function TouchableInput({
    onPress,
    label,
    value,
    setValue,
    icon,
    iconSize,
    style,
    disabled,
    ...rest
}) {
    const [styles] = useTheme(inputStyles)

    return (
        <TouchableOpacity
            style={[styles.touchableInput, style]}
            onPress={onPress}
            disabled={disabled}
        >
            <InputWithIcon
                label={label}
                value={value}
                onChangeText={setValue}
                icon={icon}
                iconSize={iconSize}
                labelOffset={{
                    x0: 0,
                    y0: -7,
                    x1: 0,
                    y1: 2,
                }}
                {...rest}
            />
        </TouchableOpacity>
    )
}
TouchableInput.defaultProps = {
    iconSize: 24,
}
TouchableInput.propTypes = {
    onPress: PropTypes.func,
    label: PropTypes.string,
    value: PropTypes.string,
    setValue: PropTypes.func,
    iconSize: PropTypes.number,
    style: PropTypes.object,
    disabled: PropTypes.bool,
    icon: PropTypes.any,
}

function SelectType({
    data = [],
    closeModal,
    selected,
    select,
    title,
    searchValue,
    setSearchValue,
    placeholder,
}) {
    const [styles] = useTheme(selectStyles)
    const { colors } = useContext(Theme)
    const { translate } = ContentManager

    return (
        <>
            <View style={styles.header}>
                <Text
                    i18nKey={title}
                    weight="bold"
                    size="h4"
                    color={colors.accent}
                />
                <TouchableOpacity onPress={closeModal}>
                    <Icon source={images.close} color={colors.secondaryIcon} size={24} />
                </TouchableOpacity>
            </View>
            <View style={styles.searchContainer}>
                <Icon
                    source={images.search}
                    size={20}
                    style={styles.searchIcon}
                />
                <TextInput
                    placeholder={translate(placeholder)}
                    value={searchValue}
                    onChangeText={setSearchValue}
                    placeholderTextColor={colors.disabled}
                    spellCheck={false}
                    textContentType="none"
                    underlineColorAndroid="transparent"
                    style={styles.searchInput}
                />
                {Boolean(searchValue) && (
                    <TouchableOpacity
                        onPress={() => setSearchValue('')}
                        style={styles.clearSearchContainer}
                    >
                        <Icon source={images.close} color={colors.secondaryIcon} size={15} />
                    </TouchableOpacity>
                )}
            </View>
            <FlatList
                data={data?.filter((item) =>
                    item?.code?.toLowerCase().includes(searchValue.toLowerCase())
                )}
                onStartShouldSetResponder={() => true}
                decelerationRate={0.8}
                keyExtractor={(item) => item.code}
                renderItem={({ item }) => (
                    <View style={styles.verticalSelfSpacing}>
                        <TouchableOpacity
                            onPress={() => {
                                select(item)
                                closeModal()
                            }}
                            style={[
                                styles['itemSelected_' + (selected?.code === item?.code)],
                                styles['isDisabled_' + item?.disabled],
                            ]}
                            disabled={item?.disabled}
                            activeOpacity={0.6}
                        >
                            <Text
                                i18nKey={item.code}
                                align="center"
                                style={styles.itemCode}
                            />
                            <View style={styles.separator} />
                            <Text
                                i18nKey={item.description}
                                size="footnote"
                                style={styles['itemTextSelected_' + (selected?.code === item?.code)]}
                            />
                        </TouchableOpacity>
                    </View>
                )}
                style={styles.listStyle}
                contentContainerStyle={styles.listContainerStyle}
            />
        </>
    )
}
SelectType.propTypes = {
    data: PropTypes.array,
    select: PropTypes.func,
    selected: PropTypes.object,
    closeModal: PropTypes.func,
    title: PropTypes.string,
    searchValue: PropTypes.func,
    setSearchValue: PropTypes.func,
    placeholder: PropTypes.func,
    icon: PropTypes.any,
}

function SecondStep({
    goBack,
    stepperLength,
    index,
    addListener,
    dispatch,
    otherCurrency,
}) {
    const { switchLocation: { deviceId, locationId } } = useConfig()

    const [styles] = useTheme(themedStyles)
    const { colors } = useContext(Theme)
    const { translate } = ContentManager

    const storedProcess = useSelector(getProcessData)
    const einvoiceId = useSelector(getInvoiceId)
    const calculatedUBL = useSelector(getEinvoiceCalculated)
    const dispatchAction = useDispatch()
    const { invoiceProcess: { type } } = storedProcess

    const [saveEinvoice, { loading }] = useMutation(SAVE_EINVOICE)

    const updateProcess = (key, value) => {
        dispatchAction(updateStepKey('process', key, value))
    }

    const updateBuyerSeller = () => {
        dispatchAction(updateStep('buyer', {}))
        dispatchAction(updateStep('seller', {}))
    }

    const setPaymentStatus = (value) => dispatchAction(updatePaymentAttribute('paymentStatus', value))

    const [processModal, setProcessModal] = useState(false)
    const [documentModal, setDocumentModal] = useState(false)
    const [effectiveVatModal, setEffectiveModal] = useState(false)
    const [disabled, setDisabled] = useState(true)

    const [searchValue, setSearchValue] = useState('')
    const setShouldHaveBillingPeriod = (val) => updateProcess('shouldHaveBillingPeriod', val)
    const [dates, setDates] = useState({
        dueDate: storedProcess.dueDate,
        startDate: storedProcess.startDate,
        endDate: storedProcess.endDate,
        shown: false,
        willUpdate: '',
        tempDate: '',
    })

    const showDatesModal = (willUpdate) => {
        setDates(prev => ({
            ...prev,
            shown: true,
            willUpdate,
            tempDate: prev[willUpdate],
        }))
    }

    const onButtonPress = (step) => {
        LayoutAnimation.configureNext(opacityConfig)
        setPaymentStatus(step)
    }

    useEffect(() => {
        if (
            storedProcess.shouldHaveBillingPeriod && (
                storedProcess.startDate === '' ||
                storedProcess.endDate === '' ||
                Object.keys(storedProcess.effectiveVat).length === 0
            ) ||
            storedProcess.dueDate === '' ||
            Object.keys(storedProcess.invoiceProcess).length === 0 ||
            Object.keys(storedProcess.invoiceDocument).length === 0
        ) {
            setDisabled(true)
        } else {
            setDisabled(false)
        }
    }, [
        storedProcess.startDate,
        storedProcess.endDate,
        storedProcess.dueDate,
        storedProcess.invoiceProcess,
        storedProcess.invoiceDocument,
        storedProcess.effectiveVat,
        storedProcess.shouldHaveBillingPeriod,

    ])

    useEffect(() => {
        if (otherCurrency) {
            updateProcess('currency', otherCurrency?.currencyCode)
        }
    }, [otherCurrency])

    const nextToBuyerSeller = () => {
        if (einvoiceId) {
            const newUbl = {
                ...calculatedUBL,
                dueDate: moment(storedProcess?.dueDate).format('YYYY-MM-DD'),
                invoiceDocumentCode: storedProcess.invoiceDocument.type,
                invoiceProcessCode: storedProcess.invoiceProcess.type,
            }
            if (storedProcess.shouldHaveBillingPeriod) {
                newUbl.invoicePeriod = {
                    startDate: moment(storedProcess.startDate).format('YYYY-MM-DD'),
                    endDate: moment(storedProcess.endDate).format('YYYY-MM-DD'),
                    descriptionCode: storedProcess.effectiveVat.code,
                }
            } else {
                newUbl.invoicePeriod = {
                    startDate: null,
                    endDate: null,
                    descriptionCode: null,
                }
            }
            dispatchAction(updateCalculatedUBL(newUbl))
            saveEinvoice({
                variables: {
                    locId: locationId,
                    cashRegisterId: deviceId,
                    eInvoiceInput: {
                        ublInvoice: newUbl,
                    },
                },
            })
                .then(() => {
                    setPaymentStatus('buyerSeller')
                })
        }
    }


    const renderDeliveryDate = () => {

        const onToggle = () => {
            LayoutAnimation.configureNext(layoutAnimConfig)
            setShouldHaveBillingPeriod(!storedProcess.shouldHaveBillingPeriod)
            setDates(prev => ({
                ...prev,
                startDate: '',
                endDate: '',
                shown: false,
                tempDate: ' ',
                willUpdate: '',
            }))
            updateProcess('effectiveVat', {})
            updateProcess('startDate', '')
            updateProcess('endDate', '')
        }

        return (
            <>
                <View style={[styles.AccessItem]}>
                    <TouchableOpacity
                        onPress={onToggle}
                        style={styles.touchable} activeOpacity={0.5}>
                        <View style={styles.accessTextContainer}>
                            <Text
                                i18nKey="einvoice_section_delivery_info"
                                weight="bold"
                                numberOfLines={2}
                                style={styles.name}
                            />
                        </View>
                        <View pointerEvents="none" style={styles.radioInput}>
                            <Switch
                                value={storedProcess.shouldHaveBillingPeriod}
                                color={colors.secondaryAccent}
                            />
                        </View>
                    </TouchableOpacity>
                </View>
                {storedProcess.shouldHaveBillingPeriod && <View style={styles.deliviryWrapper}>
                    <View style={styles.buttonWrapper}>
                        <TouchableInput
                            onPress={() => showDatesModal('startDate')}
                            value={dates?.startDate ? moment(dates?.startDate).format('DD/MM/YYYY') : ''}
                            label="einvoice_start_date_label"
                            icon={images.calendarBlack}
                            style={styles.startDateInput}
                            autoCorrect={false}
                            autoCapitalize="none"
                            editable={false}
                        />
                        <TouchableInput
                            onPress={() => showDatesModal('endDate')}
                            value={dates?.endDate ? moment(dates?.endDate).format('DD/MM/YYYY') : ''}
                            disabled={!dates.startDate}
                            label="einvoice_end_date_label"
                            icon={images.calendarBlack}
                            autoCorrect={false}
                            autoCapitalize="none"
                            editable={false}
                            style={styles.endDateInput}
                        />
                    </View>
                    <Text
                        i18nKey="einvoice_date_note"
                        weight="bold"
                        size="footnote"
                        style={styles.sectionNote}
                        align="left"
                        color={colors.secondaryAccent}
                    />
                    <TouchableInput
                        onPress={() => setEffectiveModal(true)}
                        label="einvoice_effective_vat_label"
                        value={translate(storedProcess.effectiveVat.description || '')}
                        icon={images.filledDownArrow}
                        iconSize={16}
                        autoCorrect={false}
                        autoCapitalize="none"
                        editable={false}
                    />
                </View>}
            </>
        )
    }

    return (
        <>
            <Step
                title="header_process_einvoice"
                goBack={goBack}
                stepperLength={stepperLength}
                index={index}
                addListener={addListener}
                dispatch={dispatch}
            >
                <ScrollView
                    contentContainerStyle={styles.fillRemaining}
                    showsVerticalScrollIndicator={false}
                >
                    <View style={styles.topContent}>
                        <Text i18nKey="einvoice_section_process" weight="bold" style={styles.sectionTitle} />
                        <TouchableInput
                            onPress={() => setProcessModal(true)}
                            label="einvoice_process_type_label"
                            value={translate(storedProcess.invoiceProcess.description || '')}
                            icon={images.filledDownArrow}
                            iconSize={16}
                            autoCorrect={false}
                            autoCapitalize="none"
                            editable={false}
                        />
                        <TouchableInput
                            onPress={() => setDocumentModal(true)}
                            label="einvoice_doc_type_label"
                            value={translate(storedProcess.invoiceDocument.description || '')}
                            icon={images.filledDownArrow}
                            iconSize={16}
                            autoCorrect={false}
                            autoCapitalize="none"
                            editable={false}
                        />
                    </View>
                    <View style={styles.topContent}>
                        <Text i18nKey="einvoice_section_doc_info" weight="bold" style={styles.sectionTitle} />
                        <InputWithTags
                            label="einvoice_currency_label"
                            value={storedProcess.currency}
                            onChangeText={text => updateProcess('currency', text)}
                            disabled
                            autoCompleteType="off"
                            inputWrapperStyle={styles.currency}
                        />
                        <TouchableInput
                            onPress={() => showDatesModal('dueDate')}
                            value={dates?.dueDate ? moment(dates?.dueDate).format('DD/MM/YYYY') : ''}
                            label="einvoice_payment_deadline_label"
                            icon={images.calendarBlack}
                            autoCorrect={false}
                            autoCapitalize="none"
                            editable={false}
                        />
                        {renderDeliveryDate()}
                    </View>
                </ScrollView>

                <View style={styles.buttons}>
                    <Button
                        title="einvoice_payment_button"
                        variant={'preActive'}
                        icon={images.arrowLeft}
                        iconColor={colors.accent}
                        style={styles.rightButton}
                        onPress={() => onButtonPress('init')}
                    />
                    <Button
                        title="einvoice_buyer_seller_button"
                        variant={disabled || loading ? 'disabled' : 'active'}
                        rightComponent={!loading && <Icon
                            size={20}
                            source={images.arrowRight}
                            color={colors.white}
                            style={styles.rightButtonIcon}
                        />}
                        loader={loading}
                        onPress={() => nextToBuyerSeller()}
                        style={styles.leftButton}
                    />
                </View>
            </Step>
            <BottomSheetModal
                isVisible={processModal}
                onClose={() => setProcessModal(false)}
            >
                <SelectType
                    data={processTypes}
                    title="einvoice_process_type_modal_header"
                    searchValue={searchValue}
                    setSearchValue={setSearchValue}
                    placeholder="einvoice_process_type_search_placeholder"
                    selected={storedProcess.invoiceProcess}
                    select={obj => {
                        if ((type === 'P12_SELF_INVOICE' || obj.type === 'P12_SELF_INVOICE') && type !== obj.type) {
                            updateBuyerSeller()
                        }
                        updateProcess('invoiceProcess', obj)
                    }}
                    closeModal={() => setProcessModal(false)}
                />
            </BottomSheetModal>
            <BottomSheetModal
                isVisible={documentModal}
                onClose={() => setDocumentModal(false)}
            >
                <SelectType
                    data={documentTypes}
                    title="einvoice_doc_type_modal_header"
                    searchValue={searchValue}
                    setSearchValue={setSearchValue}
                    placeholder="einvoice_doc_type_search_placeholder"
                    selected={storedProcess.invoiceDocument}
                    select={obj => updateProcess('invoiceDocument', obj)}
                    closeModal={() => setDocumentModal(false)}
                />
            </BottomSheetModal>
            <BottomSheetModal
                isVisible={effectiveVatModal}
                onClose={() => setEffectiveModal(false)}
            >
                <SelectType
                    data={dateCodes}
                    title="einvoice_effective_vat_modal_header"
                    searchValue={searchValue}
                    setSearchValue={setSearchValue}
                    placeholder="einvoice_effective_vat_search_placeholder"
                    selected={storedProcess.effectiveVat}
                    select={obj => updateProcess('effectiveVat', obj)}
                    closeModal={() => setEffectiveModal(false)}
                />
            </BottomSheetModal>
            <DatePickerModal
                preselectedDate={dates?.tempDate === '' ? moment().format('YYYY-MM-DD') : moment(dates?.tempDate).format('YYYY-MM-DD')}
                onClose={() => setDates(prev => ({
                    ...prev,
                    shown: false,
                }))}
                isVisible={dates.shown}
                onDateChange={(date) => {
                    setDates(prev => {
                        if (
                            prev.willUpdate === 'endDate'
                            && (!moment(dates.startDate).isSame(moment(date), 'month')
                                || moment(date).isBefore(moment(dates.startDate)))
                        ) {
                            return ({
                                ...prev,
                            })
                        }
                        return ({
                            ...prev,
                            tempDate: date,
                        })
                    })
                }}
                onButtonPress={() => {
                    setDates(prev => {
                        if (prev.willUpdate === 'startDate') {
                            return ({
                                ...prev,
                                startDate: prev.tempDate,
                                endDate: '',
                                shown: false,
                            })
                        } else if (
                            prev.willUpdate === 'endDate'
                            && (!moment(dates.startDate).isSame(moment(prev.tempDate), 'month')
                                || moment(prev.tempDate).isBefore(moment(dates.startDate)))
                        ) {
                            return ({
                                ...prev,
                                shown: false,
                            })
                        }
                        return ({
                            ...prev,
                            [prev.willUpdate]: prev.tempDate,
                            shown: false,
                        })
                    })
                    updateProcess(dates.willUpdate, dates.tempDate)
                }}
            />
        </>
    )
}

SecondStep.propTypes = {
    setPaymentStatus: PropTypes.func,
    goBack: PropTypes.func,
    stepperLength: PropTypes.number,
    index: PropTypes.number,
    addListener: PropTypes.func,
    dispatch: PropTypes.func,
    otherCurrency: PropTypes.object,
}

export default SecondStep

