import { styleSheetFactory } from '../../Themes/Colors'

const styles = styleSheetFactory(colors => ({
  container: {
    maxHeight: 35,
  },
  listContainerStyle: {
    paddingHorizontal: 11,
  },
  button: active => ({
    marginHorizontal: 4,
    backgroundColor: active ? colors.secondaryAccent : colors.white,
    borderRadius: 25,
    justifyContent: 'center',
    alignItems: 'center',
    flexGrow: 1,
    height: 35,
    flexDirection: 'column',
    paddingHorizontal: 15,

  }),
  label: {
    // margin: 10,
    lineHeight: 16,
  },
}))

export default styles
