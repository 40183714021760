/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { Platform, UIManager, LogBox, View, StyleSheet, StatusBar, useWindowDimensions } from 'react-native'
import { ApolloClient, ApolloProvider, InMemoryCache, HttpLink } from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import AsyncStorage from '@react-native-async-storage/async-storage'
import { NavigationContainer } from '@react-navigation/native'
import { Auth } from 'aws-amplify'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import PropTypes from 'prop-types'
import Amplify from '@aws-amplify/core'
import moment from 'moment'

import { store, persistor } from '../Redux'
import { Navigation, Authentication, OnboardingStack } from '../Navigation'
import { Loader } from '../Components'
import { OrderCountProvider } from '../Utils/Order'
import { TaxDetailsProvider } from '../Utils/Tax'
import { UnitsProvider } from '../Utils/Units'

import { LanguageProvider, useChangeLanguage } from '../Utils/Language'
import Merchant from '../Utils/Merchant'
import PermissionsProvider from '../Utils/Permissions'
import TemplateLangProvider from '../Utils/TemplatesLanguage'
import AuthDetailsProvider, { UsernamesProvider } from '../Utils/AuthDetails'
import ConfigProvider from '../Utils/Config'
import { amplifyConfig, appsyncConfig } from '../Config'

import en from '../I18n/languages/en'
import al from '../I18n/languages/al'
import ContentManager from '../Utils/ContentManager'

import UnsafeDevice from './UnsafeDevice'
import SecurityScreen from './SecurityScreen'

import { palete } from '../Themes/Colors'
import Theme from '../Themes/Theme'

LogBox.ignoreLogs([
  'Warning: Each child in a list should have a unique "key" prop.',
  'Require cycle:',
  'Warning: Function components cannot be given refs',
  'Non-serializable values were found',
  'Animated: `useNativeDriver`',
  'Possible Unhandled Promise Rejection (id: 0): Error: Local storage',
  'Warning: Overriding previous layout animation',
  '`new NativeEventEmitter()` was called with a non-null',
  'Local storage is missing an ID Token',
  'Warning: Encountered two children with the same',
])
const backup = console.error

console.error = function filterWarnings(msg) {
  const supressedWarnings = ['VirtualizedLists should never be nested inside plain ScrollViews']

  if (!supressedWarnings.some(entry => msg.includes(entry))) {
    backup.apply(console, arguments)
  }
}

StatusBar.setBarStyle('dark-content')
Amplify.configure(amplifyConfig)

const cache = new InMemoryCache({
  addTypename: false,
  typePolicies: {
    Query: {
      fields: {
        listCustomerGroups: {
          keyArgs: ['listCustomerGroupsInput', ['type']],
          merge(existing, incoming, { args }) {
            if (args?.listCustomerGroupsInput?.nextToken) {
              if (!incoming) { return existing }
              if (!existing) { return incoming }
              const { customerGroups, ...rest } = incoming
              const result = rest
              result.customerGroups = [...existing.customerGroups, ...customerGroups]
              return result
            }
            return incoming
          },
        },
        listSupplierGroups: {
          keyArgs: ['listSupplierGroupsInput', ['type']],
          merge(existing, incoming, { args }) {
            if (args?.listSupplierGroupsInput?.nextToken) {
              if (!incoming) { return existing }
              if (!existing) { return incoming }
              const { supplierGroups, ...rest } = incoming
              const result = rest
              result.supplierGroups = [...existing.supplierGroups, ...supplierGroups]
              return result
            }
            return incoming
          },
        },
        listCustomers: {
          keyArgs: ['listCustomersInput', ['type']],
          merge(existing = { customers: [] }, incoming, { args }) {
            if (!incoming) return existing;

            if (args?.listCustomersInput?.nextToken) {
              // Merge existing customers with incoming customers
              const mergedCustomers = [...existing.customers, ...incoming.customers];

              // Deduplicate items by their unique identifier (assuming 'id')
              const deduplicatedCustomers = Array.from(new Set(mergedCustomers.map(item => item.id)))
                .map(id => mergedCustomers.find(item => item.id === id));

              return {
                ...incoming,
                customers: deduplicatedCustomers,
              };
            }

            return incoming;
          },
        },
        listSuppliers: {
          keyArgs: ['listSuppliersInput', ['type']],
          merge(existing, incoming, { args }) {
            if (args?.listSuppliersInput?.nextToken) {
              if (!incoming) { return existing }
              if (!existing) { return incoming }
              const { suppliers, ...rest } = incoming
              const result = rest
              result.suppliers = [...existing.suppliers, ...suppliers]
              return result
            }
            return incoming
          },
        },
        listBankAccounts: {
          keyArgs: false,
          merge(existing, incoming, { args }) {
            if (args?.listBankAccountsInput?.nextToken) {
              if (!incoming) { return existing }
              if (!existing) { return incoming }
              const { bankAccounts, ...rest } = incoming
              const result = rest
              result.bankAccounts = [...existing.bankAccounts, ...bankAccounts]
              return result
            }
            return incoming
          },
        },
        transactionsHistory: {
          keyArgs: false,
          merge(existing, incoming, { args }) {
            if (args?.nextToken) {
              if (!incoming) { return existing }
              if (!existing) { return incoming }
              const { items, ...rest } = incoming
              const result = rest
              result.items = [...existing.items, ...items]
              return result
            }
            return incoming
          },
        },
        getCustomerGroup: {
          keyArgs: false,
          merge(existing, incoming, { args }) {
            if (args?.getCustomerGroupInput?.listCustomersInput?.nextToken) {
              if (!incoming) { return existing }
              if (!existing) { return incoming }
              const { listCustomers, ...rest } = incoming
              const result = rest
              result.listCustomers = { customers: [...existing?.listCustomers?.customers, ...listCustomers?.customers], nextToken: listCustomers.nextToken }
              return result
            }
            return incoming
          },
        },
        getSupplierGroup: {
          keyArgs: false,
          merge(existing, incoming, { args }) {
            if (args?.getSupplierGroupInput?.listSuppliersInput?.nextToken) {
              if (!incoming) { return existing }
              if (!existing) { return incoming }
              const { listSuppliers, ...rest } = incoming
              const result = rest
              result.listSuppliers = { suppliers: [...existing?.listSuppliers?.suppliers, ...listSuppliers?.suppliers], nextToken: listSuppliers.nextToken }
              return result
            }
            return incoming
          },
        },
        getCustomer: {
          keyArgs: false,
          merge(existing, incoming, { args }) {
            if (args?.getCustomerInput?.listCustomerGroupsInput?.nextToken) {
              if (!incoming) { return existing }
              if (!existing) { return incoming }
              const { listCustomerGroups, ...rest } = incoming
              const result = rest
              result.listCustomerGroups = { customerGroups: [...existing?.listCustomerGroups?.customerGroups, ...listCustomerGroups?.customerGroups], nextToken: listCustomerGroups.nextToken }
              return result
            }
            return incoming
          },
        },
        listDiscounts: {
          keyArgs: false,
          merge(existing, incoming, { args }) {
            if (args?.listDiscountsInput?.nextToken) {
              if (!incoming) { return existing }
              if (!existing) { return incoming }
              const { discounts, ...rest } = incoming
              const result = rest
              result.discounts = [...existing.discounts, ...discounts]
              return result
            }
            return incoming
          },
        },
        listCategories: {
          keyArgs: false,
          merge(existing, incoming, { args }) {
            if (args?.listCategoriesInput?.nextToken) {
              if (!incoming) { return existing }
              if (!existing) { return incoming }
              const { categories, ...rest } = incoming
              const result = rest
              result.categories = [...existing.categories, ...categories]
              return result
            }
            return incoming
          },
        },
        listItems: {
          keyArgs: false,
          merge(existing, incoming, { args }) {
            if (args?.listItemsInput?.nextToken) {
              if (!incoming) { return existing }
              if (!existing) { return incoming }
              const { items, ...rest } = incoming
              const result = rest
              result.items = [...existing.items, ...items]
              return result
            }
            return incoming
          },
        },
        listModifierLists: {
          keyArgs: false,
          merge(existing, incoming, { args }) {
            if (args?.listModifierListsInput?.nextToken) {
              if (!incoming) { return existing }
              if (!existing) { return incoming }
              const { modifierLists, ...rest } = incoming
              const result = rest
              result.modifierLists = [...existing.modifierLists, ...modifierLists]
              return result
            }
            return incoming
          },
        },
        listMeasurementUnits: {
          keyArgs: false,
          merge(existing, incoming, { args }) {
            if (args?.listMeasurementUnitsInput?.nextToken) {
              if (!incoming) { return existing }
              if (!existing) { return incoming }
              const { measurementUnits, ...rest } = incoming
              const result = rest
              result.measurementUnits = [...existing.measurementUnits, ...measurementUnits]
              return result
            }
            return incoming
          },
        },
        listLocations: {
          keyArgs: false,
          merge(existing, incoming, { args }) {
            if (args?.nextToken) {
              if (!incoming) { return existing }
              if (!existing) { return incoming }
              const { locations, ...rest } = incoming
              const result = rest
              result.locations = [...existing.locations, ...locations]
              return result
            }
            return incoming
          },
        },
        listWarehouseNotes: {
          keyArgs: false,
          merge(existing, incoming, { args }) {
            if (args?.listWarehouseNotesInput?.nextToken) {
              if (!incoming) { return existing }
              if (!existing) { return incoming }
              const { warehouseNotes, ...rest } = incoming
              const result = rest
              result.warehouseNotes = [...existing.warehouseNotes, ...warehouseNotes]
              return result
            }
            return incoming
          },
        },
        listRefunds: {
          keyArgs: false,
          merge(existing, incoming, { args }) {
            if (args?.nextToken) {
              if (!incoming) { return existing }
              if (!existing) { return incoming }
              const { refunds, ...rest } = incoming
              const result = rest
              result.refunds = [...existing.refunds, ...refunds]
              return result
            }
            return incoming
          },
        },
        listTransactions: {
          keyArgs: false,
          merge(existing, incoming, { args }) {
            if (args?.nextToken) {
              if (!incoming) { return existing }
              if (!existing) { return incoming }
              const { transactions, ...rest } = incoming
              const result = rest
              result.transactions = [...existing.transactions, ...transactions]
              return result
            }
            return incoming
          },
        },
        listCashRegisters: {
          keyArgs: false,
          merge(existing, incoming, { args }) {
            if (args?.nextToken) {
              if (!incoming) { return existing }
              if (!existing) { return incoming }
              const { cashRegisters, ...rest } = incoming
              const result = rest
              result.cashRegisters = [...existing.cashRegisters, ...cashRegisters]
              return result
            }
            return incoming
          },
        },
        listMyEinvoices: {
          keyArgs: false,
          merge(existing, incoming, { args }) {
            if (args?.listEinvoicesInput?.nextToken) {
              if (!incoming) { return existing }
              if (!existing) { return incoming }
              const { myEinvoices, ...rest } = incoming
              const result = rest
              result.myEinvoices = [...existing.myEinvoices, ...myEinvoices]
              return result
            }
            return incoming
          },
        },
        listCashDrawerShifts: {
          keyArgs: ['state'],
          merge(existing, incoming, { args }) {
            if (args?.cursor) {
              if (!incoming) { return existing }
              if (!existing) { return incoming }
              const { items, ...rest } = incoming
              const result = rest
              result.items = [...existing.items, ...items]
              return result
            }
            return incoming
          },
        },
        listCashDrawerShiftEvents: {
          keyArgs: ['eventType'],
          merge(existing, incoming, { args }) {
            if (args?.cursor) {
              if (!incoming) { return existing }
              if (!existing) { return incoming }
              const { events, ...rest } = incoming
              const result = rest
              result.events = [...existing.events, ...events]
              return result
            }
            return incoming
          },
        },
        listOrders: {
          keyArgs: ['listOrdersInput', ['status', 'filter', ['name', 'value']]],
          merge(existing, incoming, { args }) {
            if (args?.listOrdersInput?.nextToken) {
              if (!incoming) { return existing }
              if (!existing) { return incoming }
              const { orders, ...rest } = incoming
              const result = rest
              result.orders = [...existing.orders, ...orders]
              return result
            }
            return incoming
          },
        },
        // getEinvoices: {
        //   keyArgs: false,
        //   keyFields: ["attributes", ["EIC"]],
        //   merge(existing, incoming, { args }) {
        //     if (args?.getEinvoicesInput?.nextToken) {
        //       if (!incoming) { return existing }
        //       if (!existing) { return incoming }
        //       const { eInvoices, ...rest } = incoming
        //       const result = rest
        //       result.eInvoices = [...existing.eInvoices, ...eInvoices]
        //       return result
        //     }
        //     return incoming
        //   },
        // }
      },
    },
  },
})

async function GetCurrentSession() {
  try {
    return (await Auth.currentSession()).getIdToken().getJwtToken()
  } catch (error) { }
}

const awsGraphqlFetch = async (uri, options) => {
  const token = await GetCurrentSession()
  options.headers.Authorization = token
  return fetch(uri, options)
}

const httpLink = new HttpLink({
  uri: appsyncConfig.AppSync.Default.ApiUrl,
  fetch: awsGraphqlFetch,
})

const defaultOptions = {
  watchQuery: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  },
  query: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  },
}


if (Platform.OS === 'android') {
  if (UIManager.setLayoutAnimationEnabledExperimental) {
    UIManager.setLayoutAnimationEnabledExperimental(true)
  }
}

const AcceptedLanguages = ['en', 'al']
const LANGUAGES = {
  al: al,
  en: en,
}

function AuthContent({ logout, userInfo, refreshToken, shouldShowWarning, setShouldShowWarning }) {
  //check if they dont exist or are false (custome token fields can't ne booleans only string)
  const checkIfPolicy = () => {
    if (userInfo) {
      if (userInfo?.['custom:termsCondition'] === 'true' && userInfo?.['custom:privacyPolicy'] === 'true') {
        return false
      } else { return true }
    }
    return false
  }

  return (
    // eslint-disable-next-line react-native/no-inline-styles
    <View style={{ flex: 1, backgroundColor: '#F4F4F4' }}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <AuthDetailsProvider>
            <PermissionsProvider>
              <ConfigProvider>
                <TemplateLangProvider>
                  <TaxDetailsProvider>
                    <UnitsProvider>
                      <OrderCountProvider>
                        <Merchant.Provider value={{
                          company: userInfo?.bussinesName || '',
                          nipt: userInfo?.NIPT || '',
                          location: userInfo?.addressLine || '',
                          user: userInfo || {},
                        }}>
                          {!isNaN(parseInt(userInfo?.onBoard, 10)) || shouldShowWarning
                            ? <OnboardingStack
                              logout={logout}
                              refreshToken={refreshToken}
                              onBoard={parseInt(userInfo?.onBoard, 10)}
                              policyCheck={false}
                              shouldShowWarning={shouldShowWarning}
                              setShouldShowWarning={setShouldShowWarning}
                            />
                            : checkIfPolicy() ?
                              <OnboardingStack
                                logout={logout}
                                refreshToken={refreshToken}
                                onBoard={parseInt(3, 10)}
                                policyCheck={true}
                              />
                              : <Navigation logout={logout} />}
                        </Merchant.Provider>
                      </OrderCountProvider>
                    </UnitsProvider>
                  </TaxDetailsProvider>
                </TemplateLangProvider>
              </ConfigProvider>
            </PermissionsProvider>
          </AuthDetailsProvider>
        </PersistGate>
      </Provider>
    </View>
  )
}
AuthContent.propTypes = {
  logout: PropTypes.func,
  userInfo: PropTypes.object,
  refreshToken: PropTypes.func,
  shouldShowWarning: PropTypes.bool,
  setShouldShowWarning: PropTypes.func,
}

function AppContainer() {
  const setLanguage = useChangeLanguage()
  const colors = palete.light

  const [isCheckingDeviceSafety, setCheckingDeviceSafety] = useState(true)
  const [isSafeDevice] = useState(true)
  const [shouldShowWarning, setShouldShowWarning] = useState(false)
  const [isOrderRecovered, setRecoveredOrder] = useState(true)

  const [loading, setLoading] = useState(true)
  const [translating, setTranslating] = useState(true)
  const [userInfo, setUserInfo] = useState()
  const [isAuthenticated, setAuthenticated] = useState(false)

  const getUserInfo = async () => {
    setUserInfo((await Auth.currentSession()).getIdToken().decodePayload())
  }

  const configureLanguage = (value) => {
    const language = LANGUAGES?.[value]
    ContentManager.configureI18nTranslations({ en: { ...language } })
    setLanguage(value)
  }

  const getLanguage = () => {
    setTranslating(true)
    AsyncStorage.getItem('lang')
      .then((value) => {
        if (AcceptedLanguages.includes(value)) {
          configureLanguage(value)
          setTranslating(false)
        } else {
          configureLanguage('al')
          setTranslating(false)
        }
      })
      .catch(() => {
        configureLanguage('al')
        setTranslating(false)
      })
  }

  const clearSwitchLocationConfig = () => {
    const CONFIG_KEY = 'config'

    AsyncStorage.getItem(CONFIG_KEY)
      .then(data => {

        // transform it back to an object
        data = JSON.parse(data)

        // change switchLocation values
        data.switchLocation = {
          hasTokenValueSet: false,
          locationId: '',
          address: '',
          deviceId: '',
          businessName: '',
        }
        //save the value to AsyncStorage again
        AsyncStorage.setItem(CONFIG_KEY, JSON.stringify(data))

      }).catch(e => console.log(e))


  }

  const logout = () => {
    Auth.signOut()
      .then(() => {
        clearSwitchLocationConfig()
        setAuthenticated(false)
        getAuthenticatedUser()
      })
      .catch(() => { })
  }

  const logoutLink = onError(({ networkError, graphQLErrors }) => {
    console.log('Global network error', networkError, graphQLErrors)

    function IsUnauthorizedToken(error) {
      return error.errorType === 'UnauthorizedException' && (error.message === 'Valid authorization header not provided.' || error.message === 'Unable to parse JWT token.')
    }

    if (networkError && networkError.statusCode === 401 && IsUnauthorizedToken(graphQLErrors[0])) {
      //Token expired
      logout()
    }
  })

  // const apiKeyClient = new ApolloClient({
  //   cache: cache,
  //   link: logoutLink.concat(certHttpLink),
  //   defaultOptions: defaultOptions,
  // })

  const client = new ApolloClient({
    cache: cache,
    link: logoutLink.concat(httpLink),
    defaultOptions: defaultOptions,
  })

  const getAuthenticatedUser = () => {
    if (!isAuthenticated) {
      Auth.currentAuthenticatedUser()
        .then(() => {
          getUserInfo()
          setAuthenticated(true)
          setLoading(false)
        })
        .catch(() => {
          setLoading(false)
        })
    }
  }

  const checkDeviceSafety = () => {
    // let isSafe = Platform.select({
    //   ios: SafetyChecker.isJailBroken()
    //     || SafetyChecker.canMockLocation(),
    //   android: SafetyChecker.isJailBroken()
    //     || SafetyChecker.canMockLocation()
    //     || SafetyChecker.hookDetected()
    //     || SafetyChecker.AdbEnabled()
    //     || SafetyChecker.isOnExternalStorage(),
    // })

    // if (isSafe) {
    //   SafetyChecker.isDebuggedMode()
    //   .then(res => { isSafe = res })
    //   .catch(() => {})
    //   .finally(() => {
    //     if (isSafe && Platform.OS === 'android') {
    //       SafetyChecker.isDevelopmentSettingsMode()
    //       .then(res => { isSafe = isSafe || res })
    //       .catch(() => {})
    //       .finally(() => {
    //         setDeviceSafety(!isSafe)
    //         setCheckingDeviceSafety(false)
    //       })
    //     } else {
    //       setDeviceSafety(false)
    //       setCheckingDeviceSafety(false)
    //     }
    //   })
    // } else {
    //   setDeviceSafety(false)
    setCheckingDeviceSafety(false)
    // }
  }

  useEffect(() => {
    checkDeviceSafety()
    getAuthenticatedUser()
    getLanguage()
  }, [])

  const refreshToken = async () => {
    const authenticatedUser = await Auth.currentAuthenticatedUser()
    const currentSession = await Auth.currentSession()

    authenticatedUser.refreshSession(currentSession.getRefreshToken(), (err, session) => {
      if (err) { } else {
        setLoading(false)
        setUserInfo(session?.idToken?.payload)
      }
    })
  }

  useEffect(() => {
    if (!isNaN(parseInt(userInfo?.onBoard, 10))) {
      setLoading(true)
      refreshToken()
    }
  }, [userInfo?.onBoard])

  useEffect(() => {
    if (userInfo?.WARNING === 'CERTEXPIRING') {
      AsyncStorage.getItem('LAST_CERT_WARNING')
        .then((value) => {
          const lastDate = JSON.parse(value)

          if (lastDate) {
            const skippedDate = moment(lastDate?.date || null)
            if (!skippedDate.isSame(moment(), 'day')) {
              setShouldShowWarning(true)
            } else {
              setShouldShowWarning(false)
            }
          } else {
            setShouldShowWarning(true)
          }
        })
    }
  }, [userInfo?.WARNING])

  const dimensions = useWindowDimensions()
  const isLargeScreen = dimensions.width >= 800
  if (isLargeScreen) {
    StatusBar.setHidden(true, 'fade')
  } else {
    StatusBar.setHidden(false, 'fade')
  }

  if (loading || translating || isCheckingDeviceSafety) {
    return <View style={Platform.OS !== 'web' && styles.loader(colors.background)}>
      <Loader size={124} />
    </View>
  }

  return (
    <Theme.Provider value={{
      colors,
      isOrderRecovered,
      setRecoveredOrder,
    }}>
      {isSafeDevice
        ? (
          <ApolloProvider client={client}>
            <NavigationContainer>
              {isAuthenticated
                ? <AuthContent logout={logout} userInfo={userInfo} refreshToken={refreshToken} shouldShowWarning={shouldShowWarning} setShouldShowWarning={setShouldShowWarning} />
                : (
                  <UsernamesProvider>
                    <Authentication onSuccess={getAuthenticatedUser} />
                  </UsernamesProvider>
                )
              }
            </NavigationContainer>
          </ApolloProvider>
        )
        : (
          <UnsafeDevice />
        )}
    </Theme.Provider>
  )
}

const styles = StyleSheet.create({
  loader: color => {
    return {
      backgroundColor: color,
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
    }
  },
})

function App() {
  return <LanguageProvider>
    <AppContainer />
    <SecurityScreen />
  </LanguageProvider>
}

export default App
