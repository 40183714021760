import { styleSheetFactory } from '../../Themes/Colors'

const styles = styleSheetFactory((colors) => ({
  container: {
    flex: 1,
    flexGrow: 1,
  },
  inputWrapper: {
    marginHorizontal: 15,
  },
  subHeader: {
    marginBottom: 10,
    marginTop: 5,
  },
  description: {
    marginHorizontal: 15,
    borderRadius: 7,
    marginTop: 5,
  },
  inputContainer: {
    minHeight: 60,
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  itemSpacing: {
    marginBottom: 15,
  },
  fullIssueButton: {
    height: 50,
    marginTop: 30,
  },
  listContainer: {
    marginTop: 10,
  },
  titleStyle: {
    color: colors.primaryText,
    fontSize: 16,
  },
  titleStyle1: {
    color: colors.invertedText,
    fontSize: 16,
  },
  textInputContainer: {
    flex: 1,
    height: 40,
  },
  listInfoContainer: {
    flexGrow: 1,
    padding: 15,
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
  },
  listFirst: {
    borderTopLeftRadius: 7,
    borderTopRightRadius: 7,
  },
  listLast: {
    borderBottomLeftRadius: 7,
    borderBottomRightRadius: 7,
  },
  listStyle: {
    flexShrink: 1,
    paddingHorizontal: 15,
  },
  listDescContainer: {
    flexGrow: 1,
    flexShrink: 1,
    paddingRight: 5,
  },
  listPriceContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    flexShrink: 0,
  },
  separator: {
    height: 1,
    backgroundColor: colors.separator,
  },
  input: {
    flexShrink: 1,
    height: 40,
    textAlign: 'left',
    includeFontPadding: false,
    textAlignVertical: 'center',
    color: colors.primaryText,
    fontSize: 14,
    //fontFamily: 'Vodafone Lt',
    paddingRight: 2,
  },
  closeContainer: {
    backgroundColor: colors.disabled,
    position: 'absolute',
    top: 0,
    right: 0,
    borderTopRightRadius: 7,
    borderBottomLeftRadius: 7,
    width: 75,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 10,
  },
  modalButton: {
    marginTop: 15,
  },
  modalContent: {
    marginHorizontal: 30,
    marginBottom: 15,
  },
  loaderContainer: {
    marginBottom: 32,
  },
  modalTitle: {
    marginVertical: 10,
  },
  modalPrimaryButton: {
    width: '100%',
    marginTop: 30,
    marginVertical: 15,
  },
  modalSecondaryButton: {
    width: '100%',
    marginBottom: 15,
  },
  modalTertiaryButton: {
    width: '100%',
  },
  loaderView: {
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
  },
  reasonSection: {
    marginTop: 20,
    marginLeft: 15,
    marginBottom: 10,
  },
  leftSpacing: {
    marginLeft: 10,
  },
}))

export default styles
