/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from 'react'
import { View } from 'react-native'
import LottieView from 'lottie-react-native'
import ShadowView from 'react-native-androw'
import { useTheme } from 'react-native-themed-styles'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'

import { Text, Button, Header } from '../../../../Components'
import images, { animations } from '../../../../Themes/Images'
import Theme from '../../../../Themes/Theme'

import { supportInvoiceResetState, updatePaymentAttribute } from '../../Payment.Actions'
import { filterOutCart } from '../../Helpers'
import { resetOrder } from '../../../Checkout/Checkout.Actions'
import { PAYMENT_STEPS } from '../../Payment.Config'

import themedStyles from './SixthStep.Styles'

function SixthStep({
  addListener,
  goBackCustom,
  dispatch,
  removeListener,
}) {
  const [styles] = useTheme(themedStyles)
  const { colors } = useContext(Theme)
  const dispatchAction = useDispatch()

  const clearOrder = () => dispatchAction(resetOrder())
  const setPaymentStatus = (value) => dispatchAction(updatePaymentAttribute('paymentStatus', value))

  useEffect(() => {
    const clearInvoice = addListener('blur', () => {
      dispatchAction(supportInvoiceResetState())
      clearOrder()
      dispatch(filterOutCart)
    })
    return clearInvoice
  }, [])

  const onClose = () => {
    removeListener('beforeRemove')
    goBackCustom()
    dispatchAction(supportInvoiceResetState())
    clearOrder()
    dispatch(filterOutCart)
    setPaymentStatus(PAYMENT_STEPS.init)
  }

  return (
    <>
      <Header
        title="checkout_payment_label"
        titlePlaceHolders={['']}
        image={images.close}
        onPress={onClose}
      />
      <ShadowView style={styles.shadow}>
        <View style={styles.imageContainer}>
          <LottieView
            source={animations.celebrate}
            autoPlay
            loop={true}
            resizeMode="contain"
            style={styles.printAnimation}
          />
          <Text i18nKey="payment_success_description" style={styles.lottieText} size="h3" weight="bold" align="center" />
        </View>
      </ShadowView>
      <Button
        title="checkout_button_start_new"
        backgroundColor={colors.accent}
        borderColor={colors.transparent}
        style={styles.button}
        onPress={onClose}
      />
    </>
  )
}

SixthStep.propTypes = {
  addListener: PropTypes.func,
  goBackCustom: PropTypes.func,
  dispatch: PropTypes.func,
  removeListener: PropTypes.func,
}

export default SixthStep

