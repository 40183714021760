import { Platform } from 'react-native'

import { styleSheetFactory } from '../../../../Themes/Colors'

const styles = styleSheetFactory(colors => ({
  container: {
    flex: 1,
    backgroundColor: colors.background,
  },
  fillRemaining: {
    flexGrow: 1,
  },
  buttons: {
    flexDirection: 'row',
    marginBottom: 15,
    marginTop: 10,
    marginHorizontal: 15,
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  leftButton: {
    marginRight: 15,
  },
  mainButton: {
    flex: 2,
    height: 50,
  },

  // Tabs
  tabView: {
    backgroundColor: colors.background,
    paddingTop: 0,
  },
  leftSpacing: { marginLeft: 10 },
}))

const optionStyles = styleSheetFactory(colors => ({
  container: {
    height: 50,
    width: 50,
    backgroundColor: colors.tertiaryBackground,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 7,
    position: 'relative',
  },
  containerDisabled_true: {
    backgroundColor: colors.disabled,
  },
  statusContainer: {
    minWidth: 16,
    height: 16,
    padding: 2,
    backgroundColor: colors.accent,
    position: 'absolute',
    top: 8,
    right: 8,
    borderRadius: 7.5,
    justifyContent: 'center',
    alignItems: 'center',
  },
  status: {
    fontSize: 12,
    lineHeight: 12,
    marginTop: Platform.OS === 'android' ? 2 : 1,
  },
}))

export {
  optionStyles,
}

export default styles
