import React, { useContext, useState } from 'react'
import { SafeAreaView, View, FlatList, TouchableOpacity, Platform } from 'react-native'
import { useTheme } from 'react-native-themed-styles'
import PropTypes from 'prop-types'

import { Icon, Text, Header } from '../../Components'
import { useLanguage } from '../../Utils/Language'
import { useUserDetails } from '../../Utils/AuthDetails'
import { usePermissions } from '../../Utils/Permissions'

import Theme from '../../Themes/Theme'
import themedStyles from './Settings.Styles'
import images from '../../Themes/Images'

function Item({
  onPress,
  image,
  title,
  description,
  icon,
  style,
}) {
  const [styles] = useTheme(themedStyles)
  const { colors } = useContext(Theme)

  return (
    <TouchableOpacity
      onPress={onPress}
      style={[styles.listInfoContainer, style]}>
      <Icon source={image} size={32} style={styles.rightSpacing} color={colors.black} />
      <View style={styles.leftComponent}>
        <Text i18nKey={title} />
        <Text
          weight="light"
          size={'footnote'}
          i18nKey={description}
        />
      </View>
      <Icon source={icon} size={24} color={colors.accent} />
    </TouchableOpacity>
  )
}
Item.propTypes = {
  onPress: PropTypes.func,
  image: PropTypes.any,
  title: PropTypes.string,
  description: PropTypes.string,
  icon: PropTypes.any,
  style: PropTypes.object,
}

const platform = Platform.OS === 'web' ? 'Web' : 'App'
function Settings({ navigation: { navigate, openDrawer } }) {
  const [styles] = useTheme(themedStyles)
  const { role } = useUserDetails()
  const { settings = {} } = usePermissions()
  useLanguage()

  // const filter = element => (settings[element.id] || []).includes(role)
  const roleAndPlatform = [role, platform]
  const filter = element => {
    return roleAndPlatform.every(r => (settings[element.id] || []).includes(r))
  }

  const [data] = useState(() => [
    {
      id: 'locations',
      image: images.location,
      title: 'settings_locations_label',
      description: 'settings_locations_description',
      icon: images.chevronRight,
      onPress: () => navigate('Locations', { isVirtual: false }),
    },
    {
      id: 'businCode',
      image: images.location,
      title: 'settings_business_code_label',
      description: 'settings_business_code_label_description',
      icon: images.chevronRight,
      onPress: () => navigate('Locations', { isVirtual: false, isBusinessUnitList: true }),
    },
    // {
    //   id: 'onlineStore',
    //   image: images.location,
    //   title: 'settings_online_store_label',
    //   description: 'settings_online_store_description',
    //   icon: images.chevronRight,
    //   onPress: () => navigate('Locations', { isVirtual: true }),
    // },
    {
      id: 'certificate',
      image: images.certificate,
      title: 'settings_certificate_label',
      description: 'settings_certificate_description',
      icon: images.chevronRight,
      onPress: () => navigate('Certificate'),
    },
    {
      id: 'taxes',
      image: images.tax,
      title: 'settings_tax_label',
      description: 'settings_tax_description',
      icon: images.chevronRight,
      onPress: () => navigate('Taxes'),
    },
    {
      id: 'uploadItems',
      image: images.attachFile,
      title: 'settings_upload_files_label',
      description: 'settings_upload_files_description',
      icon: images.chevronRight,
      onPress: () => navigate('UploadItems'),
    },
    {
      id: 'bankAccounts',
      image: images.creditCardPayment,
      title: 'settings_bank_accounts_label',
      description: 'settings_bank_accounts_description',
      icon: images.chevronRight,
      onPress: () => navigate('BankAccounts', {}),
    },
    {
      id: 'cashRegisters',
      image: images.cash_drawer,
      title: 'settings_cash_register_label',
      description: 'settings_cash_register_description',
      icon: images.chevronRight,
      onPress: () => navigate('CashRegister'),
    },
    {
      id: 'printers',
      image: images.printerActive,
      title: 'settings_printers_label',
      description: 'settings_printers_description',
      icon: images.chevronRight,
      onPress: () => navigate('PrinterSetUp'),
    },
    {
      id: 'password',
      image: images.password,
      title: 'settings_changePassword_label',
      description: 'settings_changePassword_description',
      icon: images.chevronRight,
      onPress: () => navigate('ChangePassword'),
    },
    {
      id: 'sound&vibration',
      image: images.soundAndVibration,
      title: 'settings_sound_vibration_label',
      description: 'settings_sound_vibration_description',
      icon: images.chevronRight,
      onPress: () => navigate('SoundAndVibration'),
    },
    {
      id: 'languages',
      image: images.translate,
      title: 'settings_language_label',
      description: 'settings_language_description',
      icon: images.chevronRight,
      onPress: () => navigate('Language'),
    },
    {
      id: 'terms&conditions',
      image: images.doc,
      title: 'settings_terms_conditions_label',
      description: 'settings_terms_conditions_description',
      icon: images.chevronRight,
      onPress: () => navigate('TermsAndConditions'),
    },
    {
      id: 'privacyPolicy',
      image: images.privacyPolicy,
      title: 'settings_privacy_policy_label',
      description: 'settings_privacy_policy_description',
      icon: images.chevronRight,
      onPress: () => navigate('PrivacyPolicy'),
    },
    {
      id: 'deleteAccount',
      image: images.bin_light,
      title: 'settings_delete_account_label',
      description: 'settings_delete_account_description',
      icon: images.chevronRight,
      onPress: () => navigate('DeleteAccount'),
    },
  ]?.filter(filter))

  const keyExtractor = item => 'row-' + item.id
  const getItemLayout = (_, index) => ({
    length: 60,
    offset: 61 * index,
    index,
  })
  const renderItem = ({ item }) => <Item {...item} />

  return (
    <SafeAreaView style={styles.container}>
      <Header title="header_settings" image={images.menu} onPress={() => openDrawer()} />
      <FlatList
        showsVerticalScrollIndicator={false}
        keyExtractor={keyExtractor}
        getItemLayout={getItemLayout}
        data={data}
        style={styles.listStyle}
        contentContainerStyle={styles.listContainerStyle}
        renderItem={renderItem}
        refreshing={false}
      />
    </SafeAreaView>
  )
}

Settings.propTypes = {
  navigation: PropTypes.object,
}

export default Settings
