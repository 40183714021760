import { styleSheetFactory } from '../../Themes/Colors'

const styles = styleSheetFactory(colors => ({
  container: {
    flex: 1,
    position: 'relative',
    backgroundColor: colors.background,
  },
  header: {
    height: 56,
    alignItems: 'center',
    flexDirection: 'row',
    backgroundColor: colors.tertiaryBackground,
  },
  headerIcon: {
    marginHorizontal: 20,
  },
  screenTitle: {
    minWidth: 150,
  },
  scrollContainer: {
    paddingBottom: 80,
  },
  productInfoContainer: {
    backgroundColor: colors.tertiaryBackground,
    alignItems: 'center',
  },
  productImage: {
    height: 150,
    width: '100%',
  },
  fill: {
    flex: 1,
  },
  quantityPriceContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    paddingHorizontal: 16,
    paddingVertical: 15,
  },
  quantityContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  quantityButton: {
    width: 45,
    height: 45,
    borderRadius: 7,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: colors.separator,
    justifyContent: 'center',
    alignItems: 'center',
  },
  quantityInput: {
    width: 80,
    height: 45,
    fontSize: 22,
    paddingVertical: 2,
    color: colors.primaryText,
    alignSelf: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
  uncauntable: {
    width: 200,
    borderWidth: 1,
    borderColor: colors.separator,
    borderRadius: 7,
    minHeight: 45,
    maxHeight: 45,
  },
  pricesContainer: {

  },
  priceContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  productTitle: {
    marginTop: 20,
    marginHorizontal: 15,
    marginBottom: 10,
  },
  productDesc: {
    fontSize: 14,
    margin: 15,
    marginTop: 0,
    marginBottom: 5,
  },
  listTitle: {
    marginLeft: 15,
    marginTop: 15,
  },
  listStyle: {
    flex: 1,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    margin: 0,
  },
  listContainerStyle: {
    paddingBottom: 136,
  },
  actionButtonContainer: {
    padding: 15,
    backgroundColor: colors.background,
  },
  actionButton: {
    height: 50,
  },

  itemContainer: {
    backgroundColor: colors.tertiaryBackground,
    flexDirection: 'row',
    alignItems: 'center',
    height: 60,
    marginHorizontal: 15,
    borderTopWidth: 1,
    borderTopColor: colors.separator,
  },
  itemTitle: {
    flexGrow: 1,
    flexShrink: 1,
    margin: 15,
  },
  itemPriceContainer: {
    flexShrink: 0,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  priceCurrencyContainer: {
    alignItems: 'flex-end',
  },
  selector: {
    margin: 15,
  },
  checkBox: {
    margin: 15,
    marginRight: 6,
    marginLeft: 10,
  },

  sectionHeader: {
    marginHorizontal: 15,
  },
  separator: {
    height: 1,
    flex: 1,
    marginHorizontal: 15,
    backgroundColor: colors.separator,
  },
  separatorContainer: {
    backgroundColor: colors.background,
  },
  fab: {
    position: 'absolute',
    bottom: 50,
    right: 15,
    height: 50,
    width: 50,
    borderRadius: 25,
    backgroundColor: colors.tertiaryAccent,
    justifyContent: 'center',
    alignItems: 'center',
    shadowColor: colors.black,
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.22,
    shadowRadius: 2.22,
    elevation: 3,
  },
  fistItem_true: {
    borderTopLeftRadius: 7,
    borderTopRightRadius: 7,
    borderTopWidth: 0,
  },
  lastItem_true: {
    borderBottomLeftRadius: 7,
    borderBottomRightRadius: 7,
  },
  sectionSeparator: {
    marginHorizontal: 15,
    marginTop: 20,
    marginBottom: 10,
  },
  addDiscountButton: {
    marginHorizontal: 15,
    height: 50,
    marginTop: 20,
  },
  abreviationContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  abreviation: {
    fontSize: 50,
    lineHeight: 56,
  },
  taxContainer: {
    backgroundColor: colors.tertiaryBackground,
    flexDirection: 'row',
    alignItems: 'center',
    height: 60,
    marginHorizontal: 15,
    paddingHorizontal: 15,
  },
  isFirst_true: {
    borderTopLeftRadius: 7,
    borderTopRightRadius: 7,
  },
  isLast_true: {
    borderBottomLeftRadius: 7,
    borderBottomRightRadius: 7,
  },
  taxName: {
    flexGrow: 1,
    flexShrink: 1,
    marginRight: 15,
  },
  taxInclusionType: {
    flexShrink: 0,
  },
  leftSpacing: { marginLeft: 10 },
}))

const optionStyles = styleSheetFactory(colors => ({
  container: {
    height: 50,
    width: 50,
    backgroundColor: colors.tertiaryBackground,
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: 15,
    borderRadius: 7,
    position: 'relative',
  },
  statusContainer: {
    minWidth: 16,
    height: 16,
    padding: 2,
    backgroundColor: colors.accent,
    position: 'absolute',
    top: 8,
    right: 8,
    borderRadius: 7.5,
    justifyContent: 'center',
    alignItems: 'center',
  },
  status: {
    fontSize: 12,
    lineHeight: 12,
    marginTop: 2,
  },
}))

export { optionStyles }
export default styles
