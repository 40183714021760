import { styleSheetFactory } from '../../Themes/Colors'

const styles = styleSheetFactory(colors => ({
  container: {
    flex: 1,
    position: 'relative',
    backgroundColor: colors.background,
  },
  header: {
    height: 56,
    alignItems: 'center',
    flexDirection: 'row',
    backgroundColor: colors.background,
  },
  headerIcon: {
    marginHorizontal: 20,
  },
  inputError_false: {
    marginHorizontal: 15,
    marginVertical: 15,
    height: 50,
    borderWidth: 1,
    borderColor: colors.tertiaryBackground,
  },
  inputError_true: {
    marginHorizontal: 15,
    marginTop: 15,
    height: 50,
    borderWidth: 1,
    borderColor: colors.error,
  },
  errorText: {
    marginHorizontal: 15,
  },
  chargeButton: {
    height: 50,
    margin: 15,
    marginTop: 30,
  },
}))

export default styles
