/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState, useEffect } from 'react'
import { SafeAreaView, View, TouchableOpacity, FlatList } from 'react-native'
import { useTheme } from 'react-native-themed-styles'
import { useLazyQuery, useMutation } from '@apollo/client'
import PropTypes from 'prop-types'

import { Text, Icon, Modal, Header, Switch, ModalContent, Button } from '../../Components'

import { getErrorMessage } from '../../Utils'

import Theme from '../../Themes/Theme'
import themedStyles, { itemStyles, separatorStyles } from './Taxes.Styles'
import images from '../../Themes/Images'
import { LIST_TAXES, TOGGLE_TAX } from './Taxes.schema'

const IS_TAX_SELECTED = {
  ACTIVE: true,
  INACTIVE: false,
}

const TAX_STATUS = {
  true: 'ACTIVE',
  false: 'INACTIVE',
}

function Tax({ name, status, isDefault, onPress, isLoading, isFirst, isLast }) {
  const [styles] = useTheme(itemStyles)
  const { colors } = useContext(Theme)

  return <View style={[styles.container, styles['firstItem_' + isFirst], styles['lastItem_' + isLast]]}>
    <TouchableOpacity onPress={onPress} disabled={isDefault} style={styles.touchable} activeOpacity={0.5}>
      <View style={styles.textContainer}>
        <Text i18nKey={'taxes_name_default_' + isDefault} placeHolders={[name]} numberOfLines={2} style={styles.name} />
      </View>
      {!isDefault && <View pointerEvents="none" style={styles.radioInput}>
        <Switch
          value={IS_TAX_SELECTED[status]}
          isLoading={isLoading}
          onPress={onPress}
          color={colors.secondaryAccent}
        />
      </View>}
    </TouchableOpacity>
  </View>
}
Tax.propTypes = {
  name: PropTypes.string,
  status: PropTypes.oneOf(Object.values(TAX_STATUS)),
  isDefault: PropTypes.bool,
  isLoading: PropTypes.bool,
  onPress: PropTypes.func,
  isFirst: PropTypes.bool,
  isLast: PropTypes.bool,
}

function Separator() {
  const [styles] = useTheme(separatorStyles)
  return <View style={styles.container} />
}

function Taxes({
  navigation: {
    goBack,
    navigate,
  },
}) {
  const [styles] = useTheme(themedStyles)
  const { colors } = useContext(Theme)

  const [items, setItems] = useState([])
  const [error, setError] = useState({
    isVisible: false,
    message: '',
  })

  const [listTaxes, { loading, data }] = useLazyQuery(LIST_TAXES, { fetchPolicy: 'network-only' })
  const [toggleTax] = useMutation(TOGGLE_TAX)

  useEffect(() => listTaxes(), [])

  useEffect(() => {
    if (data) {
      setItems(data?.listTaxes?.taxes || [])
    }
  }, [data])

  const displayError = message => {
    setError({
      isVisible: true,
      message,
    })
  }

  const dismissError = () => {
    setError({
      isVisible: false,
      message: undefined,
    })
  }

  const toggle = item => {
    const { id, status } = item
    const isEnabled = IS_TAX_SELECTED[status]
    setItems(prev => prev.map(element => {
      if (element.id === id) {
        return {
          ...element,
          isLoading: true,
        }
      }
      return element
    }))
    toggleTax({
      variables: {
        id,
        status: TAX_STATUS[!isEnabled],
      },
    })
      .then(res => {
        const { status: newStatus } = res?.data?.updateTax || {}
        setItems(prev => prev.map(element => {
          if (element.id === id) {
            return {
              ...element,
              isLoading: false,
              status: newStatus,
            }
          }
          return element
        }))
      })
      .catch(e => {
        setItems(prev => prev.map(element => {
          if (element.id === id) {
            return {
              ...element,
              isLoading: false,
            }
          }
          return element
        }))
        displayError(getErrorMessage(e))
      })
  }

  const keyExtractor = item => item.id
  const renderItem = ({ item, index }) => (
    <Tax
      {...item}
      onPress={() => toggle(item)}
      isFirst={index === 0}
      isLast={index === items.length - 1}
    />
  )


  return (
    <SafeAreaView style={styles.container}>
      <Header
        title="header_taxes"
        image={images.back}
        onPress={() => goBack()}
      />
      <FlatList
        data={items}
        keyExtractor={keyExtractor}
        renderItem={renderItem}
        ItemSeparatorComponent={Separator}
        refreshing={loading}
        onRefresh={() => listTaxes()}
        ListHeaderComponent={<Text i18nKey="taxes_info" align="center" color={colors.secondaryText} />}
        ListFooterComponent={(
          <>
            <View style={styles.footer}>
              <View style={styles.note}>
                <Icon source={images.info} size={20} style={styles.icon} />
                <Text i18nKey="taxes_label_note" weight="bold" />
              </View>
              <Text i18nKey="taxes_note" size="footnote" weight="light" color={colors.secondaryText} />
            </View>
            <Button variant="secondary" title="taxes_change_default_button" style={styles.button} onPress={() => navigate('SelectDefaultTax', { items, listTaxes: () => listTaxes() })} />
          </>
        )}
        ListHeaderComponentStyle={styles.header}
        // ListFooterComponentStyle={styles.footer}
        contentContainerStyle={styles.contentContainer}
      />

      <Modal
        isVisible={error.isVisible}
        onBackButtonPress={dismissError}
        onBackdropPress={dismissError}
        animationIn="fadeIn"
        animationOut="fadeOut"
      >
        <ModalContent onClose={dismissError}>
          <Icon source={images.delete} style={styles.modalIcon} />
          <Text i18nKey="taxes_error_label" color={colors.accent} size="h5" align="center" weight="bold" />
          <Text i18nKey={error.message} align="center" />
        </ModalContent>
      </Modal>
    </SafeAreaView >
  )
}
Taxes.propTypes = {
  navigation: PropTypes.object,
}

export default Taxes
