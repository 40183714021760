import { styleSheetFactory } from '../../../../Themes/Colors'

const styles = styleSheetFactory((colors) => ({
  fillRemaining: {
    flexGrow: 1,
  },
  detailsContainer: {
    flexGrow: 1,
  },
  succesAanimation: {
    width: 100,
  },
  details: {
    flex: 1,
    borderColor: colors.separator,
    borderTopWidth: 1,
    marginTop: 20,
  },
  buttonContainer: {
    height: 50,
    margin: 15,
    marginBottom: 30,
  },
  printButton: {
    flex: 1,
    height: 50,
  },
  startNewButton: {
    height: 50,
    marginLeft: 7.5,
    justifyContent: 'center',
    alignItems: 'center',
    flex: 0.6,
  },
  shareButton: {
    height: 50,
    // marginHorizontal: 7.5,
    marginRight: 7.5,
    justifyContent: 'center',
    alignItems: 'center',
    flex: 0.4,
  },
  inlineButtons: {
    flexDirection: 'row',
    alignItems: 'center',
    margin: 15,
    marginBottom: 30,
  },

  shadow: {
    marginVertical: 30,
    alignItems: 'center',
    shadowColor: colors.black,
    shadowOffset: {
      width: 0,
      height: -1,
    },
    shadowOpacity: 0.16,
    shadowRadius: 6,
    marginHorizontal: 12,
  },
  imageContainer: {
    flex: 1,
    width: '100%',
    marginHorizontal: 10,
    padding: 20,
    backgroundColor: colors.white,
    borderRadius: 6,
  },
  printAnimation: {
    alignSelf: 'center',
    height: 125,
    width: 125,
    marginBottom: 30,
  },
  webStyles: { overflow: 'hidden', height: 0 },
}))

export default styles
