import React, { useContext, useState } from 'react'
import { View, TouchableOpacity } from 'react-native'
import { useTheme } from 'react-native-themed-styles'
import ContentLoader, { Rect } from 'react-content-loader/native'
import PropTypes from 'prop-types'

import Icon from '../Icon'
import Text from '../Text'

import { useLocationDetails } from '../../Utils/AuthDetails'

import SelectableItem from '../SelectableItem/SelectableItem'

import SwitchLocation from '../SwitchLocation'

import { useConfig } from '../../Utils'

import Theme from '../../Themes/Theme'

import themedStyles from './Header.Styles'


import images from '../../Themes/Images'

function LocationDetails({ customBusinessName, customAddress }) {
  const [styles] = useTheme(themedStyles)
  const { loading  } = useLocationDetails()
  const { switchLocation: {address , businessName}} = useConfig()






  if (loading) {
    return <ContentLoader
      speed={2}
      width={100}
      height={18}
      backgroundColor={'#e4e4e4'}
      foregroundColor={'#d4d4d4'}
    >
      <Rect x="0" y="0" rx="6" ry="6" width={100} height={14} />
    </ContentLoader>
  }
  return (
    <View style={styles.locationContainer}>
      <Icon source={images.location} size={18} />
      <Text i18nKey={`${customBusinessName || businessName} (${customAddress || address})`} size="footnote" numberOfLines={1} />
    </View>
  )
}

LocationDetails.propTypes = {
  customBusinessName: PropTypes.string,
  customAddress: PropTypes.string,
}


function Header(props) {
  const {
    image,
    title,
    translateOption,
    titlePlaceHolders,
    onPress,
    loading,
    rightComponent,
    authenticated,
    withLogo,
    headerCostumizedStyle,
    customBusinessName,
    customAddress,
  } = props
  const [styles] = useTheme(themedStyles)
  const { colors } = useContext(Theme)
  const [modalPage, setmodalPage] = useState(1)




  const [modal, setModal] = useState({
    isVisible: false,
    data:[],
    title: '',
    placeholder: '',
    buttonLabel: '',
    onButtonPress: ()=> {},
    selected: undefined,
    select: () => { },
    Item: SelectableItem,
    searchKey: 'code',
    noteText: '',
  })


  const openLocationModal = () => {
    setModal({
      isVisible: true,
      title: 'change_location_select_prompt',
      placeholder: 'bahy',
      buttonLabel: 'change_location_button_label',
      searchKey: 'code',
      Item: SelectableItem,
      noteText: 'change_location_note_text',
      withSearch: false,
      withVariables: false,
    })
  }


  return (
    <View style={[styles.header, headerCostumizedStyle]}>
      <View style={styles.leftContainer}>
        <TouchableOpacity onPress={onPress}>
          <Icon source={image} size={20} color={colors.primaryIcon} style={styles.headerIcon} />
        </TouchableOpacity>
        {loading ? <ContentLoader
          speed={0.7}
          height={40}
          width={180}
          backgroundColor="#e2e2e2"
          foregroundColor="#ecebeb">
          <Rect x="0" y="10" rx="3" ry="3" width="180" height="20" />
        </ContentLoader> : <TouchableOpacity onPress={openLocationModal } >

        <View style={styles.headerContent}>
          <Text i18nKey={title} translateOption={translateOption} placeHolders={titlePlaceHolders} size="h5" numberOfLines={1} />
          {authenticated && <LocationDetails customBusinessName={customBusinessName} customAddress={customAddress} />}
        </View>
        </TouchableOpacity> }
      </View>
      <View style={styles.rightContainer}>
        {rightComponent}
        {withLogo && <Icon source={images.vfLogo} size={32} style={styles.logo} />}
      </View>

      {authenticated && <SwitchLocation modal={modal} setModal={setModal} modalPage={modalPage} setmodalPage={setmodalPage} />}
    </View>
  )
}

Header.defaultProps = {
  title: '',
  titlePlaceHolders: [],
  image: images.back,
  onPress: () => { },
  loading: false,
  authenticated: true,
  withLogo: true,
}

Header.propTypes = {
  title: PropTypes.string,
  translateOption: PropTypes.object,
  titlePlaceHolders: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  image: PropTypes.object,
  onPress: PropTypes.func,
  loading: PropTypes.bool,
  rightComponent: PropTypes.any,
  authenticated: PropTypes.bool,
  withLogo: PropTypes.bool,
  headerCostumizedStyle: PropTypes.object,
  customBusinessName: PropTypes.string,
  customAddress: PropTypes.string,
  navigation: PropTypes.object,
}

export default Header
