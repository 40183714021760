import { v1 as uuidv1 } from 'uuid'

import {
  UPDATE_STEP,
  UPDATE_STEP_KEY,
  RESET_STATE,
  RESET_PAYMENT_STATE,
  NEW_INVOICE,
  RESET_ITEMS,
  STORE_ITEMS,
  UPDATE_UBL,
  RESET_UBL,
  NEW_EINVOICE_STATE,
  SUPPORT_INVOICE_NEW_EINVOICE_STATE,
  SUPPORT_INVOICE_NEW_INVOICE,
  SUPPORT_INVOICE_RESET_STATE,
  SUPPORT_INVOICE_UPDATE_STEP,
  SUPPORT_INVOICE_UPDATE_STEP_KEY,
  UPDATE_PAYMENT_ATTRIBUTE,
  SHOW_PAYMENT_DATES_MODAL,
  PAYMENT_DISPLAY_ERROR,
  PAYMENT_DISMISS_ERROR,
  RESET_PAYMENT_DATES_MODAL,
  NEW_EMPOTENCY_KEY,
} from './Payment.Actions'

const paymentState = {
  idempotencyKey: uuidv1(),
  paymentStatus: 'init',
  cash: '',
  change: '',
  customer: {},
  bankAccount: {},
  delivery: {},
  dates: {
    startDate: '',
    endDate: '',
    shown: false,
    willUpdate: '',
    tempDate: '',
  },
  orderType: {},
  orderSubType: {},
  paymentId: '',
  paymentMethod: {},
  lastTransaction: {},
  shouldHaveDelivery: false,
  shouldHaveBillingPeriod: false,
  error: {
    isVisible: false,
    title: '',
    desc: '',
    primaryText: '',
    primaryAction: null,
    secondaryText: '',
    secondaryAction: null,
    isDismissable: true,
  },
  isReverseCharge: {},
}

const initialState = {
  invoiceId: '',
  init: {
    code: '',
    instructionNote: '',
    primaryAccountId: '',
    networkId: '35',
    payeeFinantialAccount: '',
  },
  process: {
    invoiceProcess: {},
    invoiceDocument: {},
    currency: 'ALL',
    dueDate: '',
    startDate: '',
    endDate: '',
    shouldHaveBillingPeriod: true,
    effectiveVat: {},
  },
  references: {},
  buyer: {},
  seller: {},
  delivery: {
    name: '',
    actualDeliveryDate: '',
    streetName: '',
    cityName: '',
    postalZone: '',
    countyCode: 'AL',
  },
  note: [],
}

const lineItems = {}

const calculatedUBL = {}

const supportInvoiceInit = {
  invoiceId: '',
  initial: {
    type: {},
    transaction: {},
  },
  transportInfo: {
    vehOwnership: {},
    vehPlates: '',
    startAddr: '',
    startDateTime: '',
    startCity: '',
    startPoint: {},
    destinAddr: '',
    destinCity: '',
    destinDateTime: '',
    destinPoint: {},
    isEscortRequired: undefined,
    isGoodsFlammable: undefined,
  },
  issuer: {
    nuis: '',
    name: '',
    address: '',
    town: '',
  },
  carrier: {
    idNum: '',
    idType: null,
    name: '',
    address: '',
    town: '',
  },
  items: [],
}

function paymentData(
  state = paymentState,
  action,
) {
  const { type, step, value } = action || {}
  switch (type) {
    case UPDATE_PAYMENT_ATTRIBUTE:
      return {
        ...state,
        [step]: value,
      }
    case SHOW_PAYMENT_DATES_MODAL:
      return {
        ...state,
        dates: {
          ...state.dates,
          shown: true,
          willUpdate: value,
          tempDate: state.dates[value],
        },
      }
    case RESET_PAYMENT_DATES_MODAL:
      return {
        ...state,
        dates: {
          startDate: '',
          endDate: '',
          shown: false,
          willUpdate: '',
          tempDate: '',
        },
      }
    case PAYMENT_DISPLAY_ERROR:
      const {
        title = '',
        desc = '',
        primaryText = '',
        primaryAction = null,
        secondaryText = '',
        secondaryAction = null,
        isDismissable = true,
      } = value
      return {
        ...state,
        error: {
          isVisible: true,
          title: title,
          desc: desc,
          primaryText: primaryText,
          primaryAction: primaryAction,
          secondaryText: secondaryText,
          secondaryAction: secondaryAction,
          isDismissable: isDismissable,
        },
      }
    case PAYMENT_DISMISS_ERROR:
      return {
        ...state,
        error: {
          isVisible: false,
          title: '',
          desc: '',
          primaryText: '',
          primaryAction: null,
          secondaryText: '',
          secondaryAction: null,
          isDismissable: true,
        },
      }
    case NEW_EMPOTENCY_KEY:
      return {
        ...state,
        idempotencyKey: uuidv1(),
      }
    case RESET_PAYMENT_STATE:
      return {
        ...paymentState,
      }

    default:
      return state
  }
}

function eInvoiceData(
  state = initialState,
  action,
) {
  const { type, step, key, value, newEinvoice } = action || {}
  switch (type) {
    case UPDATE_STEP:
      return {
        ...state,
        [step]: value,
      }

    case UPDATE_STEP_KEY:
      return {
        ...state,
        [step]: {
          ...(state[step] || {}),
          [key]: value,
        },
      }

    case RESET_STATE:
      return {
        ...initialState,
      }

    case NEW_INVOICE:
      return {
        ...initialState,
        init: { ...state.init },
        invoiceId: value,
      }

    case NEW_EINVOICE_STATE:
      return {
        ...newEinvoice,
      }

    default:
      return state
  }
}

function eInvoiceLineItems(
  state = lineItems,
  action,
) {
  const { type, newItems } = action || {}
  switch (type) {
    case STORE_ITEMS:
      return {
        ...newItems,
      }

    case RESET_ITEMS:
      return {
        ...lineItems,
      }

    default:
      return state
  }
}

function eInvoiceCalculated(
  state = calculatedUBL,
  action,
) {
  const { type, newUBL } = action || {}
  switch (type) {
    case UPDATE_UBL:
      return {
        ...newUBL,
      }

    case RESET_UBL:
      return {
        ...calculatedUBL,
      }

    default:
      return state
  }
}

function supportInvoiceData(
  state = supportInvoiceInit,
  action,
) {
  const { type, step, key, value, newEinvoice } = action || {}
  switch (type) {
    case SUPPORT_INVOICE_UPDATE_STEP:
      return {
        ...state,
        [step]: value,
      }

    case SUPPORT_INVOICE_UPDATE_STEP_KEY:
      return {
        ...state,
        [step]: {
          ...(state[step] || {}),
          [key]: value,
        },
      }

    case SUPPORT_INVOICE_RESET_STATE:
      return {
        ...supportInvoiceInit,
      }

    case SUPPORT_INVOICE_NEW_INVOICE:
      return {
        ...supportInvoiceInit,
        initial: { ...state.initial },
        items: state.items,
        total: state.total,
        invoiceId: value,
      }

    case SUPPORT_INVOICE_NEW_EINVOICE_STATE:
      return {
        ...newEinvoice,
      }

    default:
      return state
  }
}

export {
  eInvoiceData,
  eInvoiceLineItems,
  eInvoiceCalculated,
  supportInvoiceData,
  paymentData,
}

