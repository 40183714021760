import { gql } from '@apollo/client'

export const GET_DISCOUNTS = gql`
  query listDiscounts($filter: Filter, $limit: Int, $nextToken: String,$locationId:String,  $locId: String, $cashRegisterId: String) {
    listDiscounts(
      listDiscountsInput: {
        filter: $filter,
        limit: $limit,
        nextToken: $nextToken
      }
      locationId:$locationId
      requestedLocationAndCashRegister: {
        locationId: $locId,
        cashRegisterId: $cashRegisterId
      }
    ){
      discounts{
        id
        name
        amountMoney{
          amount
          currency
        }
        prices {
          amount
          currency
        }
        discountType
        labelColor
        modifyTaxBasis
        percentage
      }
      nextToken
    }
  }
`

export const DELETE_DISCOUNT = gql`
  mutation deleteDiscount ($id: String!,$locationId:String, $locId: String, $cashRegisterId: String) {
    deleteDiscount(
      id: $id,
      locationId:$locationId
      requestedLocationAndCashRegister: {
        locationId: $locId,
        cashRegisterId: $cashRegisterId
      }
      ){
      id
    }
  }
`
