import Color from 'color'

import { styleSheetFactory } from '../../../Themes/Colors'

const styles = styleSheetFactory(colors => ({
    qrScanner: {
        marginTop: 80,
    },
    withQR: {
        marginTop: 15,
    },
    withoutQR: {
        marginTop: 80,
    },
    topMenuContainer: {
        flexDirection: 'row',
        position: 'absolute',
        top: 50,
        left: 0,
        right: 0,
        margin: 15,
        marginBottom: 0,
    },
    searchWrapper: {
        flexGrow: 1,
        flexDirection: 'row',
        borderRadius: 7,
        overflow: 'hidden',
        backgroundColor: colors.tertiaryBackground,
    },
    searchContainer: {
        flexGrow: 1,
        flexDirection: 'row',
        backgroundColor: colors.tertiaryBackground,
        borderRadius: 7,
        overflow: 'hidden',
        alignItems: 'center',
    },
    searchIcon: {
        marginHorizontal: 10,
    },
    inputContainer: {
        flexGrow: 1,
    },
    searchInput: {
        flexGrow: 1,
        paddingLeft: 0,
        fontSize: 16,
        fontFamily: 'Vodafone Rg',
        color: colors.primaryText,
    },
    clearSearchContainer: {
        height: 50,
        aspectRatio: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
    clearSearchTouchable: {
        backgroundColor: colors.background,
        height: 30,
        aspectRatio: 1,
        borderRadius: 15,
        justifyContent: 'center',
        alignItems: 'center',
    },

}))
const itemStyles = styleSheetFactory(colors => ({
    container: {
        height: 70,
        backgroundColor: colors.tertiaryBackground,
        justifyContent: 'center',
        overflow: 'hidden',
        marginVertical: 3,
        borderRadius: 7,
    },
    touchable: {
        flexGrow: 1,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        position: 'relative',
    },
    imageContainer: labelColor => {
        return {
            width: 70,
            height: '100%',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: Color(labelColor).fade(0.65).toString(),
        }
    },
    infoContainer: {
        flexGrow: 1,
        flexShrink: 1,
        justifyContent: 'space-between',
        alignItems: 'center',
        flexDirection: 'row',
        padding: 15,
        paddingLeft: 5,
    },
    descContainer: {
        flexGrow: 1,
        flexShrink: 1,
    },
    priceContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        // backgroundColor: 'pink',
        flexShrink: 0,
        marginLeft: 10,
    },
}))

export { itemStyles }
export default styles
