import { gql } from '@apollo/client'

export const GET_REPORTS = gql`
query getReport ($type: String, $filter: ReportSpecificationFilterInput, $beginDate: String, $endDate: String){
    getReport(
        reportSpecification:{
            type: $type
            filter: $filter
            beginDate: $beginDate
            endDate: $endDate
        }
    ){
        reports{
            AcceptRanges
            AcceptRanges
            LastModified
            ContentLength
            ETag
            ContentType
            SourceUrl
            DisplayName
        }
    }
}
`
