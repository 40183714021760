import { gql } from '@apollo/client'

const LIST_LOCATIONS = gql`
query listLocations($nextToken: String){
    listLocations(nextToken: $nextToken){
      locations{
        id
        businessName
        phoneNumber
        businUnitCode
        businessEmail
        address{
          addressLine1
        }
        logoUrl
      }
      nextToken
    }
  }
`

export default LIST_LOCATIONS
