import { useState, useEffect } from 'react'
import { Keyboard } from 'react-native'
import currencyformatter from 'currencyformatter.js'

const DEFAULT_ERROR_MESSAGE = 'GeneralError'
export const getErrorMessage = e => {
  const message = e?.graphQLErrors?.[0]?.errorType || e?.message || DEFAULT_ERROR_MESSAGE
  return message
}

export const getErrorType = e => {
  return e?.graphQLErrors?.[0]?.errorType
}

export const useKeyboard = () => {
  const [isVisible, setVisible] = useState(false)

  useEffect(() => {
    const keyboardDidShow = Keyboard.addListener('keyboardDidShow', () => setVisible(true))
    const keyboardDidHide = Keyboard.addListener('keyboardDidHide', () => setVisible(false))
    return () => {
      keyboardDidShow.remove()
      keyboardDidHide.remove()
    }
  }, [])

  return {
    isVisible,
  }
}

// export const INF = Intl.NumberFormat('sq-AL', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 })
export const INF = currencyformatter
// export const EINF = Intl.NumberFormat('sq-AL', {
//   style: 'currency',
//   currency: 'EUR',
//   currencyDisplay: 'symbol',
// })
