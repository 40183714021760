/* eslint-disable react-hooks/exhaustive-deps */
import { Buffer } from 'buffer'

import React, { useEffect, useState } from 'react'
import {
    View,
    TouchableOpacity,
    SafeAreaView,
    ScrollView,
    Platform,
    FlatList,
    LayoutAnimation,
    TouchableWithoutFeedback,
} from 'react-native'
import PropTypes from 'prop-types'
import { useTheme } from 'react-native-themed-styles'
import DocumentPicker from 'react-native-document-picker'
import RNFS from 'react-native-fs'
import { useLazyQuery } from '@apollo/client'
import ContentLoader, { Rect } from 'react-content-loader/native'
import RNFetchBlob from 'rn-fetch-blob'
import axios from 'axios'
import { useSelector } from 'react-redux'

import {
    Header,
    Text,
    Icon,
    Button,
    Section,
    Modal,
    ModalContent,
    RadioInput,
    CertificateInputWeb,
} from '../../../Components'
import { getProductCatalog } from '../../ProductCatalog/ProductCatalog.Selectors'

import { useLanguage } from '../../../Utils/Language'

import images from '../../../Themes/Images'
import { layoutAnimConfig } from '../../../Themes/Theme'
import { colors } from '../../../Themes'

import { GET_URL } from './FirstStep.Schema'

import themedStyles, { fileItemStyles, listItemStyles, placeholderStyles } from './FirstStep.Styles'

const DOCUMENT_IMPORT_TYPE = Platform.select({
    ios: ['public.data'],
    android: [DocumentPicker.types.allFiles],
})

const uploadOptions = [
    {
        title: 'upload_items_item_option',
        type: 'items',
        disabled: false,
    },
    {
        title: 'upload_items_categories_option',
        type: 'categories',
        disabled: true,

    },
    {
        title: 'upload_items_sales_option',
        type: 'sales',
        disabled: true,

    },
]

const labelTypes = {
    items: {
        header: 'upload_items_header',
        note: 'upload_items_note_text',
    },
    categories: {
        header: 'upload_categories_header',
        note: 'upload_categories_note_text',
    },
    sales: {
        header: 'upload_sales_header',
        note: 'upload_sales_note_text',
    },
}

function Option({ title, isSelected, select, disabled, loading }) {
    const [styles] = useTheme(listItemStyles)

    return (
        <View style={[styles.itemContainer, styles['itemContainerSelected_' + isSelected], styles['itemContainerDisabled_' + disabled]]}>
            <TouchableOpacity disabled={disabled || loading} onPress={select} style={[styles.touchable, Platform.OS === 'web' && styles.webFullWidth]}>
                <Text i18nKey={title} size={'body'} align="center" style={styles['titleSelected_' + isSelected]} />
            </TouchableOpacity>
        </View>
    )
}
Option.propTypes = {
    title: PropTypes.string,
    isSelected: PropTypes.bool,
    select: PropTypes.func,
    disabled: PropTypes.bool,
    loading: PropTypes.bool,
}

function ListComponent({
    data,
    selectedType,
    setSelectedType,
    contentContainerStyle,
    loading,
    ...rest
}) {
    const [styles] = useTheme(listItemStyles)

    const keyExtractor = (_, index) => 'item_' + index

    const renderItem = ({ item, index }) => {
        return (
            <Option
                {...item}
                loading={loading}
                isSelected={item?.type === selectedType}
                select={() => setSelectedType(item?.type)}
            />
        )
    }

    return (
        <FlatList
            data={data}
            keyExtractor={keyExtractor}
            renderItem={renderItem}
            horizontal
            style={styles.listStyle}
            contentContainerStyle={[styles.listContainerStyle, contentContainerStyle]}
            showsHorizontalScrollIndicator={false}
            CellRendererComponent={({ children }) => <View style={styles.callRenderer}>{children}</View>}
            {...rest}
        />

    )
}
ListComponent.propTypes = {
    data: PropTypes.array,
    setReportsList: PropTypes.func,
    reports: PropTypes.array,
    selectedType: PropTypes.object,
    setSelectedType: PropTypes.func,
    contentContainerStyle: PropTypes.object,
    openModal: PropTypes.func,
    closeModal: PropTypes.func,
    loading: PropTypes.bool,
}

function FileItem({ onPress, attached, fileName, cleanFile, loading }) {
    const [styles] = useTheme(fileItemStyles)

    return (
        <TouchableOpacity
            style={styles.fileContainer}
            onPress={onPress}
            disabled={attached}>
            {!attached ? (
                <View style={styles.subFileContainer}>
                    <Text
                        i18nKey="certificate_choose_label"
                        color={colors.secondaryAccent}
                    />
                    <Text
                        i18nKey="upload_items_upload_file_note"
                        weight="light"
                        size="footnote"
                    />
                    <View style={styles.importIcon}>
                        <Icon
                            source={images.attachFile}
                            size={35}
                            color={colors.secondaryAccent}
                            style={styles.attachFile}
                        />
                    </View>
                </View>
            ) : (
                <View
                    style={styles.attachedFileContainer}>
                    <View
                        style={styles.attachedFileSubcontainer}>
                        <Icon
                            source={images.uploadedSuccess}
                            size={35}
                            style={styles.uploadedSuccess}
                        />
                        <Text i18nKey={fileName} numberOfLines={1} />
                    </View>
                    <TouchableOpacity onPress={cleanFile} style={styles.clearContainer} disabled={loading}>
                        <Icon
                            source={images.close}
                            color={colors.secondaryIcon}
                            size={12}
                        />
                    </TouchableOpacity>
                </View>
            )}
        </TouchableOpacity>
    )
}
FileItem.propTypes = {
    onPress: PropTypes.func,
    attached: PropTypes.bool,
    fileName: PropTypes.string,
    cleanFile: PropTypes.func,
    loading: PropTypes.bool,
}

function PlaceHolders() {
    const [styles] = useTheme(placeholderStyles)

    return (
        <>
            <ContentLoader
                speed={0.7}
                height={51}
                width={'100%'}
                style={styles.sectionText}
                backgroundColor="#e2e2e2"
                foregroundColor="#ecebeb">
                <Rect x="0" y="25" rx="3" ry="3" width="120" height="14" />
            </ContentLoader>
            <View style={styles.wrapper}>
                <ContentLoader
                    speed={0.7}
                    height={60}
                    width={'100%'}
                    style={styles.firstItem}
                    backgroundColor="#e2e2e2"
                    foregroundColor="#ecebeb"
                >
                    <Rect x="15" y="25" rx="3" ry="3" width="200" height="14" />
                </ContentLoader>

                <ContentLoader
                    speed={0.7}
                    height={60}
                    width={'100%'}
                    style={styles.middleItem}
                    backgroundColor="#e2e2e2"
                    foregroundColor="#ecebeb"
                >
                    <Rect x="15" y="25" rx="3" ry="3" width="200" height="14" />
                </ContentLoader>
                <ContentLoader
                    speed={0.7}
                    height={60}
                    width={'100%'}
                    style={styles.middleItem}
                    backgroundColor="#e2e2e2"
                    foregroundColor="#ecebeb"
                >
                    <Rect x="15" y="25" rx="3" ry="3" width="200" height="14" />
                </ContentLoader>
                <ContentLoader
                    speed={0.7}
                    height={60}
                    width={'100%'}
                    style={styles.lastItem}
                    backgroundColor="#e2e2e2"
                    foregroundColor="#ecebeb"
                >
                    <Rect x="15" y="25" rx="3" ry="3" width="200" height="14" />
                </ContentLoader>
            </View>
        </>
    )
}

function UploadItems({
    goBack,
    next,
    selectedBulk,
    setSelectedBulk,
    keepDuplicates,
    setKeepDuplicates,
    setLocationAddress,
}) {
    const [styles] = useTheme(themedStyles)
    const [metaData, setMetadata] = useState({})
    const [fileDetails, setFileDetails] = useState([])
    const [refreshing, setRefreshing] = useState(false)
    const { locationId, businessName, address } = useSelector(getProductCatalog)

    const lang = useLanguage()

    const [uploadedFile, setUploadedFile] = useState({
        attached: false,
        content: {},
        mime: '',
        fileName: '',
    })
    const [modalContent, setModalContent] = useState({
        isVisible: false,
        title: '',
        icon: '',
        desc: '',
        primaryText: '',
        primaryAction: null,
        secondaryText: '',
        secondaryAction: null,
        hasRadioInput: false,
    })

    const displayModal = (
        title = '',
        icon = '',
        desc = '',
        primaryText = '',
        primaryAction = null,
        secondaryText = '',
        secondaryAction = null,
        hasRadioInput = false,

    ) => {
        setModalContent({
            isVisible: true,
            title: title,
            icon: icon,
            desc: desc,
            primaryText: primaryText,
            primaryAction: primaryAction,
            secondaryText: secondaryText,
            secondaryAction: secondaryAction,
            hasRadioInput: hasRadioInput,
        })
    }

    const dismissModal = () => {
        setModalContent({
            isVisible: false,
            title: '',
            icon: '',
            desc: '',
            primaryText: '',
            primaryAction: null,
            secondaryText: '',
            secondaryAction: null,
            hasRadioInput: false,
        })
    }

    useEffect(() => {
        if (Object.keys(metaData).length > 0) {
            const r = []
            metaData?.details.forEach(element => {
                r.push({
                    title: `${element.Value} ${element.Name}`,
                })
            })
            setFileDetails(r)
        }
    }, [metaData])

    const [getUrl, { data: urlData, loading: urlLoading }] = useLazyQuery(GET_URL, { fetchPolicy: 'network-only' })

    const attachfile = async () => {
        try {
            const res = await DocumentPicker.pick({
                type: DOCUMENT_IMPORT_TYPE,
            })
            const uri = res[0]?.uri
            if (res[0].type === 'application/vnd.ms-excel' || res[0].type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
                RNFS.readFile((uri), 'base64')
                    .then((dataa) => {
                        setRefreshing(true)
                        setUploadedFile({
                            attached: true,
                            content: dataa,
                            mime: res[0]?.type,
                            fileName: res[0]?.name,
                        })
                        getUrl({
                            variables: {
                                fileName: res[0]?.name,
                                uploadType: selectedBulk,
                                languageCode: lang,
                                locationId: locationId ? locationId : null,

                            },
                        })

                    })
                    .catch((er) => {
                        setRefreshing(false)
                    })
            } else {
                setRefreshing(false)
                displayModal(
                    'certificate_error_label',
                    images.error,
                    'certificate_incorrect_format_description',
                    'logout_button_cancel',
                    () => dismissModal(),
                )
            }

        } catch (err) {
            setRefreshing(false)

            if (DocumentPicker.isCancel(err)) {
            } else {
                throw err
            }
        }
    }

    // function to atach files only on web NOTE:Is not used in react-native
    const attachFileWeb = (acceptedFiles) => {
        if (acceptedFiles.length > 0) {
            // eslint-disable-next-line no-undef
            const reader = new FileReader()
            reader.readAsDataURL(acceptedFiles[0])
            reader.onload = function () {
                setRefreshing(true)
                setUploadedFile({
                    attached: true,
                    content: reader.result.replace(`data:${acceptedFiles[0]?.type};base64,`, ''),
                    mime: acceptedFiles[0]?.type,
                    fileName: acceptedFiles[0]?.name,
                })
                getUrl({
                    variables: {
                        fileName: acceptedFiles[0]?.name,
                        uploadType: selectedBulk,
                        languageCode: lang,
                        locationId: locationId ? locationId : null,
                    },
                })

            }
            reader.onerror = function (error) {
                setRefreshing(false)
                displayModal(
                    'certificate_error_label',
                    images.error,
                    'certificate_incorrect_format_description',
                    'logout_button_cancel',
                    () => dismissModal(),
                )
            }
        } else {
            setRefreshing(false)
        }

    }

    const getMetaData = async (iterator) => {
        if (iterator < 6) {
            fetch(urlData.getPresignedUrl.metadataUrl).then((response) => {
                if (response?.status === 404) {
                    iterator = iterator + 1
                    setTimeout(() => { getMetaData(iterator) }, 1000)
                }
                else {
                    response.json().then((json) => {
                        LayoutAnimation.configureNext(layoutAnimConfig)
                        setMetadata(json)
                        setRefreshing(false)

                    }).catch(e => { })
                }
            })
        } else {
            setRefreshing(false)
        }
    }

    const previewFile = (base64, title, fileTyte, mimeType) => {
        const { dirs } = RNFetchBlob.fs
        const dirToSave = Platform.OS === 'ios' ? dirs.DocumentDir : dirs.DownloadDir
        const configfb = {
            fileCache: true,
            useDownloadManager: true,
            notification: true,
            mediaScannable: true,
            title: `${title.replace(/[/]/g, '_')}${fileTyte}`,
            path: `${dirToSave}/${title.replace(/[/]/g, '_')}${fileTyte}`,
        }
        const configOptions = Platform.select({
            ios: {
                fileCache: configfb.fileCache,
                title: configfb.title,
                path: configfb.path,
                appendExt: 'xlsx',
            },
            android: configfb,
        })

        RNFetchBlob.config(configOptions)
        RNFetchBlob.fs.writeFile(configfb.path, base64, 'base64')

        if (Platform.OS === 'ios') {
            RNFetchBlob.ios.openDocument(configfb.path)
        } else {
            RNFetchBlob.android.actionViewIntent(configfb.path, mimeType)
            RNFetchBlob.android.addCompleteDownload({
                title: `${title.replace(/[/]/g, '_')}${fileTyte}`,
                description: 'Download complete',
                mime: mimeType,
                path: configfb.path,
                showNotification: true,
            })
        }
    }

    const downloadFile = async () => {
        const fileTyte = '.xlsx'
        const mimeType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        if (Platform.OS === 'web') {
            return (
                axios({
                    method: 'GET',
                    url: 'https://sandbox-bulkuploadstatics.s3.eu-central-1.amazonaws.com/static/examples/ItemsTemplate.xlsx',
                    responseType: 'blob',
                }).then((res) => {
                    // eslint-disable-next-line no-undef
                    const url = window.URL.createObjectURL(new Blob([res.data]))
                    const link = document.createElement('a')
                    link.href = url
                    link.setAttribute('download', `ItemsTemplate${fileTyte}`)
                    document.body.appendChild(link)
                    link.click()

                }))
        } else {
            const { dirs } = RNFetchBlob.fs

            const options = Platform.OS === 'ios' ?
                {
                    fileCache: true,
                    path: dirs.DocumentDir + `/${'ItemsTemplate'}${fileTyte}`,
                    mime: mimeType,
                    // appendExt: 'xlsx',
                    //path: filePath,
                    //appendExt: fileExt,
                    notification: true,
                }
                : {
                    addAndroidDownloads: {
                        useDownloadManager: true,
                        path: dirs.DownloadDir + `/${'ItemsTemplate'}${fileTyte}`,
                        notification: true,
                        mime: mimeType,
                        title: `${'ItemsTemplate'}${fileTyte}`,
                    },
                    fileCache: true,

                }
            RNFetchBlob.config(options).fetch('GET', 'https://sandbox-bulkuploadstatics.s3.eu-central-1.amazonaws.com/static/examples/ItemsTemplate.xlsx')
                .then((res) => {
                    return res.readFile('base64')
                }).then(async base64 => {
                    if (Platform.OS === 'ios') {
                        previewFile(base64, 'ItemsTemplate', fileTyte, mimeType)
                    }
                })
                .catch(e => {

                })
        }
    }

    useEffect(() => {
        if (urlData) {
            var buf = Buffer.from(uploadedFile.content, 'base64')
            const uploadTransactionId = urlData?.getPresignedUrl?.fileName.split('-')
            setLocationAddress({
                locationAddress: urlData?.getPresignedUrl?.fileName,
                uploadTransactionId: uploadTransactionId[1],
            })

            fetch(urlData.getPresignedUrl.uploadURL, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                },
                body: buf,
            }).then(async function (res) {
                getMetaData(0)
            })
                .catch(error => {
                    setRefreshing(false)

                })
        }
    }, [urlData])

    return (
        <>
            <SafeAreaView style={styles.wrapper}>
                <Header
                    image={images.close}
                    title={labelTypes?.[selectedBulk]?.header}
                    titlePlaceHolders={[1]}
                    onPress={() => goBack()}
                    customBusinessName={businessName}
                    customAddress={address}
                />
                <ScrollView showsVerticalScrollIndicator={false} contentContainerStyle={styles.contentContainerStyle} >
                    <View style={styles.container}>
                        <Text weight="bold" i18nKey="upload_items_type" style={styles.topSpacing} />
                        <ListComponent
                            loading={urlLoading}
                            data={uploadOptions}
                            isSuggestion={false}
                            selectedType={selectedBulk}
                            setSelectedType={setSelectedBulk}
                        />
                        {(typeof metaData?.isFileCorrectFormat === 'boolean' && metaData?.isFileCorrectFormat === false) ?
                            <View style={styles.errorContainer}>
                                <View style={styles.noteHeader}>
                                    <Icon source={images.error} style={styles.rightSpacing} />
                                    <Text weight="bold" i18nKey="upload_items_incorrect_format_label" color={colors.error} />
                                </View>
                                <Text weight="light" i18nKey="upload_items_incorrect_format_message" />
                                {metaData?.errors.map((e) => (
                                    < Text weight="light" i18nKey={`${e.Value} ${e.Name}`} />
                                ))}
                                <Text weight="light" i18nKey="upload_items_incorrect_format_message1" />
                            </View>
                            : null}
                        <View style={styles.noteContainer}>
                            <View style={styles.noteHeader}>
                                <Icon source={images.info} style={styles.rightSpacing} />
                                <Text weight="bold" i18nKey="cash_register_note" />
                            </View>
                            <Text weight="light" i18nKey={labelTypes?.[selectedBulk]?.note} />
                            <View style={styles.separator} />
                            <View style={styles.downloadButton}>
                                <Icon source={images.download} size={25} style={styles.rightSpacing} />
                                <TouchableOpacity activeOpacity={0.6} onPress={downloadFile}>
                                    <Text color={colors.secondaryAccent} i18nKey="ItemsTemplate.xlsx" />
                                </TouchableOpacity>
                            </View>
                        </View>
                        <Text i18nKey="upload_items_upload_file" weight="bold" />
                        {Platform.OS === 'web' ?
                            <CertificateInputWeb
                                onDrop={attachFileWeb}
                                accept="application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                attached={uploadedFile.attached}
                                fileName={uploadedFile.fileName || ''}
                                cleanFile={() => {
                                    if (!refreshing) {
                                        setUploadedFile({
                                            attached: false,
                                            content: {},
                                            mime: '',
                                            fileName: '',
                                        })
                                        setMetadata({})
                                    }
                                }}
                                formatLabel="upload_items_upload_file_note"
                            />

                            :
                            <FileItem
                                onPress={() => attachfile()}
                                attached={uploadedFile.attached}
                                fileName={uploadedFile.fileName || ''}
                                cleanFile={() => {
                                    if (!refreshing) {
                                        setUploadedFile({
                                            attached: false,
                                            content: {},
                                            mime: '',
                                            fileName: '',
                                        })
                                        setMetadata({})
                                    }
                                }}
                            />
                        }
                        {metaData?.isFileCorrectFormat ?
                            <>
                                <Text i18nKey="upload_items_file_details" weight="bold" style={styles.topSpacing} />
                                <Section data={fileDetails} />
                            </> : refreshing ?
                                <PlaceHolders />
                                : null}
                    </View>
                </ScrollView>
                <View style={styles.buttonContainer}>
                    <Button
                        title="onboarding_back_button"
                        variant={'preActive'}
                        iconColor={colors.accent}
                        style={styles.rightButton}
                        onPress={goBack}
                        icon={images.arrowLeft}
                    />
                    <Button
                        title="orders_refund_button_next"
                        variant={(refreshing || !uploadedFile.attached || !metaData?.isFileCorrectFormat) ? 'disabled' : 'active'}
                        iconColor={colors.accent}
                        style={styles.leftButton}
                        onPress={() => {
                            displayModal(
                                'upload_items_replace_title',
                                '',
                                'upload_items_duplicate_desription',
                                'upload_items_continue_button_option',
                                () => {
                                    dismissModal()
                                    next()
                                },
                                'logout_button_cancel',
                                () => dismissModal(),
                                true)
                        }}
                        rightComponent={<Icon
                            size={20}
                            source={images.arrowRight}
                            color={colors.white}
                            style={styles.rightButtonIcon}
                        />}
                    />
                </View>
            </SafeAreaView >
            <Modal
                isVisible={modalContent.isVisible}
                onBackButtonPress={() => dismissModal()}
                onBackdropPress={() => dismissModal()}
                animationIn="fadeIn"
                animationOut="fadeOut">
                <ModalContent
                    onClose={() => dismissModal()}
                    contentContainerStyle={styles.modalContent}>
                    {modalContent.icon ? <Icon source={modalContent.icon} size={40} /> : null}
                    <Text
                        i18nKey={modalContent.title}
                        align="center"
                        size="h4"
                        weight="bold"
                        style={styles.modalTitle}
                    />
                    <Text
                        i18nKey={modalContent.desc}
                        align="left"
                    />
                    {modalContent.hasRadioInput ?
                        <View style={styles.radioWrapper}>
                            <TouchableWithoutFeedback onPress={() => setKeepDuplicates(true)} >
                                <View style={styles.radioInputRow}>
                                    <RadioInput
                                        index={true}
                                        selected={keepDuplicates}
                                        onPress={() => setKeepDuplicates(true)}
                                        style={styles.rightSpacing}
                                        color={colors.secondaryAccent}
                                    />
                                    <Text i18nKey={'upload_items_keep_duplicate_radio'} />
                                </View>
                            </TouchableWithoutFeedback>
                            <TouchableWithoutFeedback onPress={() => setKeepDuplicates(false)}>
                                <View style={styles.radioInputRow}>
                                    <RadioInput
                                        index={false}
                                        selected={keepDuplicates}
                                        onPress={() => setKeepDuplicates(false)}
                                        style={styles.rightSpacing}
                                        color={colors.secondaryAccent}
                                    />
                                    <Text i18nKey={'upload_items_replace_duplicate_radio'} />
                                </View>
                            </TouchableWithoutFeedback>
                        </View> : null}
                    {modalContent.primaryAction && (
                        <Button
                            title={modalContent.primaryText}
                            variant="active"
                            style={styles.modalPrimaryButton}
                            onPress={() => modalContent.primaryAction?.()}
                        />
                    )}
                    {modalContent.secondaryAction && (
                        <Button
                            title={modalContent.secondaryText}
                            variant="link"
                            style={styles.modalSecondaryButton}
                            onPress={() => modalContent.secondaryAction?.()}
                        />
                    )}
                </ModalContent>
            </Modal>
        </>
    )
}

UploadItems.propTypes = {
    goBack: PropTypes.func,
    next: PropTypes.func,
    selectedBulk: PropTypes.string,
    setSelectedBulk: PropTypes.func,
    keepDuplicates: PropTypes.bool,
    setKeepDuplicates: PropTypes.func,
    setLocationAddress: PropTypes.func,
    locationId: PropTypes.string,
}



export default UploadItems
