/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react'
import {
  View,
  TouchableOpacity,
  Text as RNText,
  Linking,
  LayoutAnimation,
  ScrollView,
  Platform,
} from 'react-native'
import { useTheme } from 'react-native-themed-styles'
import PropTypes from 'prop-types'
import ContentLoader, { Rect } from 'react-content-loader/native'
import { useQuery } from '@apollo/client'
import _ from 'lodash'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'

import {
  Icon,
  Text,
  Button,
  Loader,
  InputWithTags,
  InputPhone,
  BottomSheetSelector,
  Type,
  TouchableInput,
  Switch,
  ErrorModal,
} from '../../Components'
import { LIST_CASH_REGISTERS, OPERATORS_LIST } from '../CashRegister/CashRegister.Schema'

import ContentManager from '../../Utils/ContentManager'

import BottomSheetCodeInput from '../../Components/BottomSheetCodeInput/BottomSheetCodeInput'

import { useConfig } from '../../Utils'

import { images, colors, animations } from '../../Themes'
import { layoutAnimConfig } from '../../Themes/Theme'
import {
  userFormStyles,
} from './NewUser.Styles'

function SingleLoaderContent({ width: loaderWidth }) {
  return (
    <ContentLoader
      speed={0.7}
      height={40}
      width={loaderWidth}
      style={Object.assign({ borderRadius: 7 })}
      backgroundColor="#e2e2e2"
      foregroundColor="#ecebeb">
      <Rect x="0" y="10" rx="3" ry="3" width={loaderWidth} height="20" />
    </ContentLoader>
  )
}
SingleLoaderContent.propTypes = {
  width: PropTypes.number,
}

function SimpleItem(props) {
  const [styles] = useTheme(userFormStyles)
  const { leftText, rightText, loading } = props

  return (
    <View style={styles.container}>
      <View style={styles.subContainer}>
        <View style={styles.textContainer}>
          <Text
            weight="light"
            align="left"
            style={styles.text}
            i18nKey={leftText}
          />
        </View>
        <View style={styles.rightTextContainer}>
          {loading ? (
            <SingleLoaderContent width={150} />
          ) : (
            <Text
              align="right"
              style={styles.text}
              i18nKey={rightText}
              numberOfLines={1}
            />
          )}
        </View>
      </View>
    </View>
  )
}
SimpleItem.propTypes = {
  leftText: PropTypes.string,
  rightText: PropTypes.string,
  loading: PropTypes.bool,
}

function TouchableItem(props) {
  const [styles] = useTheme(userFormStyles)
  const { leftText, location, onPress, disabled } = props

  return (
    <TouchableOpacity
      onPress={() => onPress()}
      disabled={disabled}
      style={styles.touchableContainer}>
      <View style={[styles.subContainer, styles.rightSelfSpacing]}>
        <View style={styles.textContainer}>
          <Text
            weight="light"
            align="left"
            style={styles.text}
            i18nKey={leftText}
          />
          {location ? (
            <View style={styles.rightContainer}>
              <Text i18nKey={location} align="right" />
            </View>
          ) : null}
        </View>
        <Icon source={images.arrow_right} size={20} />
      </View>
    </TouchableOpacity>
  )
}
TouchableItem.propTypes = {
  leftText: PropTypes.string,
  location: PropTypes.string,
  onPress: PropTypes.func,
  disabled: PropTypes.bool,
}

// function HiddenItem({
//   needsConfirmation,
//   onPress,
//   onConfirm,
//   onCancel,
//   loading,
//   isFirst,
//   isLast,
// }) {
//   const [styles] = useTheme(hiddenItemStyles)

//   return (
//     <View
//       style={[
//         styles.wrapper,
//         styles['firstItem_' + isFirst],
//         styles['lastItem_' + isLast],
//       ]}>
//       {loading ? (
//         <View style={styles.loader}>
//           <Loader size={32} />
//         </View>
//       ) : needsConfirmation ? (
//         <TouchableOpacity style={styles.option} onPress={onPress}>
//           <Text
//             i18nKey="cash_register_remove_button"
//             color={colors.white}
//           />
//         </TouchableOpacity>
//       ) : (
//         <View style={styles.optionFull}>
//           <TouchableOpacity style={styles.confirm} onPress={onConfirm}>
//             <Icon source={images.bin_light} size={24} />
//             <Text
//               i18nKey="receipt_button_confirm"
//               color={colors.white}
//               style={styles.confirmText}
//             />
//           </TouchableOpacity>
//           <TouchableOpacity style={styles.cancel} onPress={onCancel}>
//             <Icon source={images.closeIcon} color={colors.white} size={18} />
//           </TouchableOpacity>
//         </View>
//       )}
//     </View>
//   )
// }
// HiddenItem.propTypes = {
//   needsConfirmation: PropTypes.bool,
//   onPress: PropTypes.func,
//   onConfirm: PropTypes.func,
//   onCancel: PropTypes.func,
//   loading: PropTypes.bool,
//   isFirst: PropTypes.bool,
//   isLast: PropTypes.bool,
// }

const ScrollComponent = React.forwardRef(({ children},ref) =>
  Platform.select({
    ios: <KeyboardAwareScrollView ref={ref} showsVerticalScrollIndicator={false} enableOnAndroid={true}>{children}</KeyboardAwareScrollView>,
    android: <ScrollView ref={ref} >{children}</ScrollView>,
    web: <ScrollView ref={ref}> {children}</ScrollView>,
  }))

function UserForm(props) {
  const {
    navigate,
    firstName,
    setFirstName,
    lastName,
    setLastName,
    userEmail,
    setEmail,
    userPhone,
    setPhone,
    operatorCode,
    setOperatorCode,
    status,
    role,
    loading1,
    locationId,
    changeLocation,
    location1,
    loading,
    editable,
    disabled,
    error1,
    onSave,
    webAccessRegister,
    setWebAccessRegister,
    apiAccessRegister,
    setApiAccessRegister,
    webAccess,
    setWebAccess,
    apiAccess,
    setApiAccess,
    confirm,
    credentialsOnPress,
    credentialsLoading,
    setError,
  } = props
  const [styles] = useTheme(userFormStyles)
  const [apiRegistersItems, setApiRegistersItems] = useState([])
  const [webRegistersItems, setWebRegistersItems] = useState([])
  const [searchValue, setSearchValue] = useState('')


  //---------------------------------------------------------------code by Erik
  const { switchLocation: { deviceId, locationId: locId } } = useConfig()


  const [operatorCodeModal, setOperatorCodeModal] = useState(false)
  const [operatorInputValue, setOperatorInputValue] = useState(operatorCode)
  const [operatorsData, setOperatorsData] = useState([])
  const [selectedOperatorCode, setselectedOperatorCode] = useState({ code: operatorCode })
  const isAnItemSelected = operatorsData.filter((item) => item.code === operatorCode).length > 0


  const containerRef = useRef()

  useEffect(() => {
    if (error1){
      containerRef.current.scrollTo({
        y:0,
        animated: true,
      })
    }

  }, [error1])



  useEffect(() => {
    if (isAnItemSelected) {
      setselectedOperatorCode({ code: operatorCode })
    }
  }, [operatorCodeModal])

  useEffect(() => {
    if (!isAnItemSelected) {
      setOperatorInputValue(operatorCode)
    }
  }, [operatorCodeModal])



  const handleTextChange = (text) => {
    setOperatorInputValue(text)
    setselectedOperatorCode('')
  }


  const handleCodeInputModalClose = () => {
    setOperatorCodeModal(false)
  }

  const onChangeEmailInput = (text)=>{
    setEmail(text)
    if (error1) {
      setError(false)
    }
  }

  const mapOperators = (operatorsList) => {
    const unmappedOperators = operatorsList?.getOperators?.operators
    const mappedOperators = []

    if (unmappedOperators) {
      unmappedOperators.forEach((item) => {
        mappedOperators.push({
          id: item?.attributes.OprID,
          code: item?.attributes.OprCode,
          description: `${item?.attributes.OprFirstName} ${item?.attributes.OprLastName ? item?.attributes.OprLastName : ' '}`,
        })
      })
    }
    return mappedOperators
  }

  const { data: apiRegisters, loading: apiLoading } = useQuery(LIST_CASH_REGISTERS, {
    fetchPolicy: 'network-only',
    variables: {
      locId: locId,
      cashRegisterId: deviceId,
      filter: {
        name: 'access',
        value: 'api',
      },
    },
  })

  const { data: webRegisters, loading: webLoading } = useQuery(LIST_CASH_REGISTERS, {
    fetchPolicy: 'network-only',
    variables: {
      locId: locId,
      cashRegisterId: deviceId,
      filter: {
        name: 'access',
        value: 'web',
      },
    },
  })

  const [credentialModal, setCredentialModal] = useState({
    isVisible: false,
    errorMessage: '',
    title: '',
    desc: '',
  })


  const mapRegister = (register) => {
    const unmappedItems = register?.listCashRegisters?.cashRegisters
    const mappedItems = []

    if (unmappedItems) {
      unmappedItems.forEach((item) => {
        mappedItems.push({
          id: item?.id,
          code: item?.name,
          description: item?.TCRCode,
        })
      })
    }
    return mappedItems
  }





  useEffect(() => {
    setApiRegistersItems(mapRegister(apiRegisters))
  }, [apiRegisters])

  useEffect(() => {
    setWebRegistersItems(mapRegister(webRegisters))
  }, [webRegisters])

  useEffect(() => {
    if (apiAccessRegister && !_.some(apiRegistersItems, apiAccessRegister)) {

      setApiRegistersItems([apiAccessRegister, ...apiRegistersItems])
    }
  }, [apiAccessRegister])

  useEffect(() => {
    if (webAccessRegister && !_.some(webRegistersItems, webAccessRegister)) {
      setWebRegistersItems([webAccessRegister, ...webRegistersItems])
    }
  }, [webAccessRegister])

  const [modal, setModal] = useState({
    isVisible: false,
    data: [],
    title: '',
    placeholder: '',
    selected: undefined,
    select: () => { },
    Item: Type,
  })

  const closeModal = () => {
    setModal(prev => ({ ...prev, isVisible: false }))
  }

  const openApiSelector = () => {
    setModal({
      isVisible: true,
      data: apiRegistersItems,
      title: 'users_api_access_label',
      placeholder: 'users_api_access_placeholder',
      selected: apiAccessRegister,
      select: (obj) => {
        setApiAccessRegister(obj)
      },
      searchKey: 'code',
      Item: Type,
    })
  }

  const openWebSelector = () => {
    setModal({
      isVisible: true,
      data: webRegistersItems,
      title: 'users_web_access_label',
      placeholder: 'users_web_access_placeholder',
      selected: webAccessRegister,
      select: (obj) => {
        setWebAccessRegister(obj)
      },
      searchKey: 'code',
      Item: Type,
    })
  }

  const { translate } = ContentManager

  // const renderHiddenItem = ({item, index}, rowMap) => {
  //   return (
  //     <HiddenItem
  //       selected={item.status}
  //       needsConfirmation={needsConfirmation}
  //       onPress={() => openRow(rowMap, item?.id)}
  //       onConfirm={() => deleteRow(rowMap, item?.id)}
  //       onCancel={() => closeRow(rowMap, item?.id)}
  //       loading={false}
  //       isFirst={index === 0}
  //       isLast={index === items?.length - 1}
  //     />
  //   )
  // }

  // const openRow = (rowMap, id) => {
  //   setNeedForConfirmation(false)
  //   rowMap[id].manuallySwipeRow(rightOpenValueFull)
  // }

  // const closeRow = (rowMap, id) => {
  //   rowMap[id].closeRow()
  //   setNeedForConfirmation(true)
  // }

  // const deleteRow = (rowMap, id) => {}

  const buttonProps = {
    backgroundColor: {
      true: colors.secondaryAccent,
      false: colors.buttonSecondaryAccent,
    },
    foregroundColor: {
      true: colors.invertedText,
      false: colors.secondaryAccent,
    },
    text: {
      true: 'receipt_button_confirm',
      false: 'users_resend_credentials_button',
    },
    iconColor: {
      true: colors.tertiaryIcon,
      false: colors.secondaryIcon,
    },
  }
  return (
    <ScrollComponent ref={containerRef}>
      <View style={styles.horizontalSelfSpacing}>
        <Text
          i18nKey="users_personal_information_section"
          align="left"
          style={styles.topSpacing}
        />
        <InputWithTags
          label="users_first_name_label"
          value={firstName}
          onChangeText={setFirstName}
          autoCorrect={false}
          editable={editable}
          inputContainerStyle={styles.inputContainer}
          inputWrapperStyle={styles.itemSpacing}
          labelOffset={{
            x0: 0,
            y0: -7,
            x1: 0,
            y1: 2,
          }}
        />
        <InputWithTags
          label="users_last_name_label"
          value={lastName}
          onChangeText={setLastName}
          autoCorrect={false}
          editable={editable}
          inputContainerStyle={styles.inputContainer}
          inputWrapperStyle={styles.itemSpacing}
          labelOffset={{
            x0: 0,
            y0: -7,
            x1: 0,
            y1: 2,
          }}
        />
        <InputWithTags
          label="users_email_label"
          value={userEmail}
          onChangeText={(text)=>onChangeEmailInput(text)}
          autoCorrect={false}
          editable={editable}
          autoCapitalize="none"
          inputContainerStyle={styles.inputContainer}
          inputWrapperStyle={styles.itemSpacing}
          error={error1}

          labelOffset={{
            x0: 0,
            y0: -7,
            x1: 0,
            y1: 2,
          }}
        />
        {error1 ? (
          <Text
            i18nKey="users_existing_user_error"
            style={styles.errorText}
            weight="light"
            color={colors.accent}
            align="left"
          />
        ) : (
          <Text
            i18nKey="users_email_usage_message"
            style={styles.note}
            weight="light"
            align="left"
          />
        )}
        <InputPhone
          label="users_phone_label"
          value={userPhone}
          onChangeText={setPhone}
          autoCorrect={false}
          prefix="+355"
          editable={editable}
          inputContainerStyle={styles.inputContainer}
          inputWrapperStyle={styles.itemSpacing}
          labelOffset={{
            x0: 0,
            y0: -7,
            x1: 0,
            y1: 2,
          }}
        />
        <TouchableInput
          onPress={() => setOperatorCodeModal(true)}
          label="receipt_operator_code_label"
          value={operatorCode}
          icon={images.filledDownArrow}
          iconSize={16}
          autoCorrect={false}
          autoCapitalize="none"
          editable={false}
        />
        {/* <InputWithTags
          label="receipt_operator_code_label"
          value={operatorCode}
          autoCapitalize="none"
          onChangeText={setOperatorCode}
          autoCorrect={false}
          editable={editable}
          inputContainerStyle={styles.inputContainer}
          inputWrapperStyle={styles.itemSpacing}
          labelOffset={{
            x0: 0,
            y0: -7,
            x1: 0,
            y1: 2,
          }}
        /> */}
        <RNText
          onPress={() => Linking.openURL('https://efiskalizimi-app.tatime.gov.al/self-care')}
          style={styles.operatorCodeHelper}
          allowFontScaling={false}
        >
          <RNText>{translate('cash_register_business_code1')}</RNText>
          <RNText style={{ color: colors.secondaryAccent }}>{translate('cash_register_business_code2')}</RNText>
          <RNText>{translate('cash_register_business_code3')}</RNText>
          <RNText style={styles.operatorCodeHelperItalic}>{translate('cash_register_business_code4')}</RNText>
          <RNText>{translate('cash_register_business_code5')}</RNText>
        </RNText>
        <Text
          i18nKey="users_professional_information_section"
          align="left"
          style={styles.sectionText}
        />
        <SimpleItem
          leftText="users_role_label"
          rightText={role}
          loading={loading1}
        />
        <TouchableItem
          loading={loading1}
          leftText="users_location_label"
          location={location1}
          disabled={!editable}
          onPress={() =>
            navigate('UserLocation', {
              changeLocation,
              id: locationId,
            })
          }
        />
        {/* <Button
          title="users_add_cash_register"
          icon={images.plus}
          variant={location1 === '' ? 'disabled' : 'secondary'}
          onPress={() => navigate('SelectCashRegister', {
              onSave: () => {},
              preSelectedEmployees: items || [],
              onEmployeeDelete: () => {},
              locationId: locationId,
          })}
          style={styles.addCashRegister}
        /> */}
        {/* {role === 'Seller' && <SimpleItem
        leftText="users_assigned_to_label"
        rightText={user?.email}
        loading={loading1}
      />} */}
        <SimpleItem
          leftText="users_status_label"
          rightText={status}
          loading={loading1}
        />
        <View style={styles.accessWrapper}>
          <Text i18nKey="cash_register_access_label" style={styles.accessLabel} />
          <View style={styles.accessItem}>
            <TouchableOpacity onPress={() => {
              // LayoutAnimation.configureNext(layoutAnimConfig)
              !webAccess ? openWebSelector() : null
              setWebAccess(!webAccess)
              setWebAccessRegister(null)
            }} style={styles.touchable} activeOpacity={0.5}>
              <View style={styles.accessTextContainer}>
                <Text i18nKey="cash_register_web_access_label" numberOfLines={2} style={styles.name} />
              </View>
              <View pointerEvents="none" style={styles.radioInput}>
                {webLoading ? (
                  <ContentLoader
                    speed={0.7}
                    height={25}
                    width={100}
                    style={Object.assign({ borderRadius: 15 })}
                    backgroundColor="#e2e2e2"
                    foregroundColor="#ecebeb">
                    <Rect x="65" y="1" rx="10" ry="10" width={35} height="20" />
                  </ContentLoader>
                ) : (
                  <Switch
                    value={webAccess}
                    color={colors.secondaryAccent}
                  />
                )}

              </View>
            </TouchableOpacity>
          </View>
          {webAccess && <TouchableInput
            onPress={openWebSelector}
            style={styles.accessLabel}
            label="users_web_access_label"
            value={webAccessRegister ? `${webAccessRegister.code} | ${webAccessRegister.description}` : ''}
            icon={images.filledDownArrow}
            iconSize={16}
            autoCorrect={false}
            autoCapitalize="none"
            editable={false}
          />}
          <View style={styles.accessItem}>
            <TouchableOpacity onPress={() => {
              // LayoutAnimation.configureNext(layoutAnimConfig)
              !apiAccess ? openApiSelector() : null
              setApiAccess(!apiAccess)
              setApiAccessRegister(null)
            }} style={styles.touchable} activeOpacity={0.5}>
              <View style={styles.accessTextContainer}>
                <Text i18nKey="cash_register_api_access_label" numberOfLines={2} style={styles.name} />
              </View>
              <View pointerEvents="none" style={styles.radioInput}>
                {apiLoading ? (
                  <ContentLoader
                    speed={0.7}
                    height={25}
                    width={100}
                    style={Object.assign({ borderRadius: 15 })}
                    backgroundColor="#e2e2e2"
                    foregroundColor="#ecebeb">
                    <Rect x="65" y="1" rx="10" ry="10" width={35} height="20" />
                  </ContentLoader>
                ) : (
                  <Switch
                    value={apiAccess}
                    color={colors.secondaryAccent}
                  />
                )}
              </View>
            </TouchableOpacity>
          </View>
          {apiAccess && <TouchableInput
            onPress={openApiSelector}
            style={styles.accessLabel}
            label="users_api_access_label"
            value={apiAccessRegister ? `${apiAccessRegister?.code} | ${apiAccessRegister?.description}` : ''}
            icon={images.filledDownArrow}
            iconSize={16}
            autoCorrect={false}
            autoCapitalize="none"
            editable={false}
          />}
        </View>
        {status === 'Pending' ? <>
          <Text i18nKey="users_resend_credentials_button" />
          <Text i18nKey="users_resend_credentials_description" size="footnote" />
          <Button
            title={buttonProps.text[confirm]}
            // disabled={disabled}
            backgroundColor={buttonProps.backgroundColor[confirm]}
            borderWidth={0}
            borderColor={buttonProps.backgroundColor[confirm]}
            color={buttonProps.foregroundColor[confirm]}
            variant={'preActive'}
            style={styles.credentialButton}
            onPress={credentialsOnPress}
            loader={credentialsLoading}
            loaderComponent={<Loader size={32} source={animations.vfLoaderRed} />}
          />
        </>
          : null}
        {editable ? <Button
          title="users_save_button"
          disabled={disabled}
          variant={disabled ? 'disabled' : 'active'}
          style={styles.saveButton}
          onPress={onSave}
          loader={loading}
          loaderComponent={<Loader size={32} source={animations.vfLoaderWhite} />}
        /> : null}
        <BottomSheetCodeInput
          inputValue={operatorInputValue}
          isVisible={operatorCodeModal}
          data={operatorsData}
          title={'receipt_operator_code_label'}
          inputPrompt={'OperatorCodeEnterPrompt'}
          inputLabel={'receipt_operator_code_label'}
          buttonLabel={'OperatorCodeSet'}
          onSave={setOperatorCode}
          setData={setOperatorsData}
          query={OPERATORS_LIST}
          mapItems={mapOperators}
          note
          onClose={handleCodeInputModalClose}
          onChangeText={handleTextChange}
          selected={selectedOperatorCode}
          select={(obj) => {
            setselectedOperatorCode(obj)
          }}
          editable={true}
        />
        <BottomSheetSelector
          {...modal}
          closeModal={closeModal}
          searchValue={searchValue}
          setSearchValue={setSearchValue}
        />
        <ErrorModal
          isVisible={credentialModal.isVisible}
          onClosePress={() => setCredentialModal({
            isVisible: false,
          })}
          title={credentialModal.title}
          description={credentialModal.desc}
          primaryText="cash_register_okay_button"
          primaryAction={() => setCredentialModal({
            isVisible: false,
          })}
        />
      </View>
    </ScrollComponent>
  )
}
UserForm.defaultProps = {
  onEndReached: () => { },
}

UserForm.propTypes = {
  goBack: PropTypes.func,
  navigate: PropTypes.func,
  firstName: PropTypes.string,
  setFirstName: PropTypes.func,
  lastName: PropTypes.string,
  setLastName: PropTypes.func,
  userEmail: PropTypes.string,
  setEmail: PropTypes.func,
  userPhone: PropTypes.string,
  setPhone: PropTypes.func,
  operatorCode: PropTypes.string,
  setOperatorCode: PropTypes.func,
  status: PropTypes.string,
  role: PropTypes.string,
  loading1: PropTypes.bool,
  locationId: PropTypes.string,
  changeLocation: PropTypes.func,
  location1: PropTypes.bool,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  setDisabled: PropTypes.func,
  error1: PropTypes.bool,
  onSave: PropTypes.func,
  cashLoading: PropTypes.bool,
  items: PropTypes.array,
  addCashRegister: PropTypes.func,
  editable: PropTypes.bool,
  ListEmptyComponent: PropTypes.any,
  otherHeader: PropTypes.any,
  webAccessRegister: PropTypes.object,
  setWebAccessRegister: PropTypes.func,
  apiAccessRegister: PropTypes.object,
  setApiAccessRegister: PropTypes.func,
  webAccess: PropTypes.bool,
  setWebAccess: PropTypes.func,
  apiAccess: PropTypes.bool,
  setApiAccess: PropTypes.func,
  confirm: PropTypes.bool,
  credentialsOnPress: PropTypes.func,
  credentialsLoading: PropTypes.bool,
  setError: PropTypes.func,
}

export default UserForm
