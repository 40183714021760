/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useMemo } from 'react'
import {
  View,
  Platform,
} from 'react-native'
import {
  PERMISSIONS,
  RESULTS,
  check,
  request,
  openSettings,
} from 'react-native-permissions'
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs'
import { useMutation, useLazyQuery } from '@apollo/client'
import Share from 'react-native-share'
import { useTheme } from 'react-native-themed-styles'
import PropTypes from 'prop-types'
import RNFetchBlob from 'rn-fetch-blob'

import {
  Text,
  Icon,
  ModalContent,
  Modal,
  Button,
  Loader,
  Tabs,
} from '../../../Components'
import { getErrorMessage, openBase64NewTab } from '../../../Utils'
import ContentManager from '../../../Utils/ContentManager'

import { CHANGE_STATUS, GET_PDF } from '../EInvoiceDetails.Schema'

import images from '../../../Themes/Images'
import { colors, animations } from '../../../Themes'

import themedStyles from './GovEInvoiceDetails.Styles'

import BasicInfo from './Routes/BasicInfo'
import BuyerSeller from './Routes/BuyerSeller'

const Tab = createMaterialTopTabNavigator()

function GovEInvoiceDetails({ id, title, status, addListener, partyType }) {
  const [styles] = useTheme(themedStyles)

  const { translate } = ContentManager

  const [action, setAction] = useState({
    title: '',
    shareLoading: false,
    perviewLoading: false,
  })
  const [confirmAccept, setConfirmAccept] = useState(false)
  const [confirmRefuse, setConfirmRefuse] = useState(false)
  const [refetchOnFinish, setRefetchOnFinish] = useState(false)
  const [invoicestatus, setInvoiceStatus] = useState(status)
  const [isErrorActive, setErrorActive] = useState({
    isVisible: false,
    message: '',
  })

  const [acceptLoading, setAcceptLoading] = useState(false)
  const [refuseLoading, setRefuseLoading] = useState(false)
  const [loading, setLoading] = useState(true)

  const [getPdf, { data: pdfData, loading: loadingPdf, error: pdfError }] = useLazyQuery(GET_PDF, { fetchPolicy: 'network-only', variables: { eic: id } })

  const confirmAcceptRefuse = (accept, refuse) => {
    setConfirmAccept(accept)
    setConfirmRefuse(refuse)
  }

  useEffect(() => {
    const invoiceData = pdfData?.getEinvoices?.eInvoices?.[0]
    if (invoiceData) {
      const base64File = invoiceData?.Pdf
      if (Platform.OS === 'web') {
        openBase64NewTab(base64File)
        setAction({
          title: '',
          shareLoading: false,
          perviewLoading: false,
        })
      } else {
        if (action.title === 'share') {
          sharePdf(base64File)
        } else {
          saveFile(base64File)
        }
      }
    }
  }, [pdfData])

  useEffect(() => {
    if (pdfError) {
      setAction(prev => ({
        ...prev,
        shareLoading: false,
        perviewLoading: false,
      }))
    }
  }, [pdfError])

  const sharePdf = (base64) => {
    Share.open({
      url: `data:application/pdf;base64,${base64}`,
    })
      .catch(() => setAction({
        title: '',
        shareLoading: false,
        perviewLoading: false,
      }))
    setAction({
      title: '',
      shareLoading: false,
      perviewLoading: false,
    })
  }

  const previewPdf = (base64) => {
    const { dirs } = RNFetchBlob.fs
    const dirToSave = Platform.OS === 'ios' ? dirs.DocumentDir : dirs.DownloadDir
    const configfb = {
      fileCache: true,
      useDownloadManager: true,
      notification: true,
      mediaScannable: true,
      title: `${title.replace(/[/]/g, '_')}.pdf`,
      path: `${dirToSave}/${title.replace(/[/]/g, '_')}.pdf`,
    }
    const configOptions = Platform.select({
      ios: {
        fileCache: configfb.fileCache,
        title: configfb.title,
        path: configfb.path,
        appendExt: 'pdf',
      },
      android: configfb,
    })

    RNFetchBlob.config(configOptions)
    RNFetchBlob.fs.writeFile(configfb.path, base64, 'base64')

    if (Platform.OS === 'ios') {
      RNFetchBlob.ios.openDocument(configfb.path)
    } else {
      RNFetchBlob.android.actionViewIntent(configfb.path, 'application/pdf')
      RNFetchBlob.android.addCompleteDownload({
        title: `${title.replace(/[/]/g, '_')}.pdf`,
        description: 'Download complete',
        mime: 'application/pdf',
        path: configfb.path,
        showNotification: true,
      })
    }
  }

  const saveFile = async (base64) => {
    try {
      check(
        Platform.select({
          ios: PERMISSIONS.IOS.MEDIA_LIBRARY,
          android: PERMISSIONS.ANDROID.WRITE_EXTERNAL_STORAGE,
        }),
      )
        .then((result) => {
          setAction({
            title: '',
            shareLoading: false,
            perviewLoading: false,
          })
          switch (result) {
            case RESULTS.DENIED:
              request(
                Platform.select({
                  ios: PERMISSIONS.IOS.MEDIA_LIBRARY,
                  android: PERMISSIONS.ANDROID.WRITE_EXTERNAL_STORAGE,
                }),
              ).then((res) => {
                if (res === RESULTS.GRANTED) {
                  previewPdf(base64)
                }
              })
              break
            case RESULTS.LIMITED:
              break
            case RESULTS.GRANTED:
              previewPdf(base64)
              break
            case RESULTS.BLOCKED:
              openSettings()
              break
          }
        })
        .catch(() => setAction({
          title: '',
          shareLoading: false,
          perviewLoading: false,
        }))
    } catch {
      setAction({
        title: '',
        shareLoading: false,
        perviewLoading: false,
      })
    }
  }

  useEffect(() => {
    const deleteFiles = addListener('beforeRemove', () => {
      if (Platform.OS === 'ios') {
        const path = `${RNFetchBlob.fs.dirs.DocumentDir}/${title.replace(/[/]/g, '_')}.pdf`
        RNFetchBlob.fs.exists(path)
          .then((res) => {
            if (res) {
              RNFetchBlob.fs.unlink(path)
            }
          })
          .catch(() => { })
      }
    })
    return deleteFiles
  }, [])

  const [changeStatus, { }] = useMutation(CHANGE_STATUS)

  const accept = () => {
    if (confirmAccept) {
      setAcceptLoading(true)
      changeStatus({
        variables: {
          eics: [id],
          status: 'ACCEPTED',
        },
      }).then(() => {
        setRefetchOnFinish(true)
        confirmAcceptRefuse(false, false)
        setAcceptLoading(false)
        setInvoiceStatus('ACCEPTED')
      }).catch((er) => {
        confirmAcceptRefuse(false, false)
        setErrorActive({
          isVisible: true,
          message: er,
        })
        setAcceptLoading(false)
      })
    } else {
      confirmAcceptRefuse(true, false)
    }
  }

  const refuse = () => {
    if (confirmRefuse) {
      setRefuseLoading(true)
      changeStatus({
        variables: {
          eics: [id],
          status: 'REFUSED',
        },
      }).then(() => {
        setRefetchOnFinish(true)
        confirmAcceptRefuse(false, false)
        setRefuseLoading(false)
        setInvoiceStatus('REFUSED')
      }).catch((er) => {
        confirmAcceptRefuse(false, false)
        setErrorActive({
          isVisible: true,
          message: er,
        })
        setRefuseLoading(false)
      })
    } else {
      confirmAcceptRefuse(false, true)
    }
  }

  const buttonProps = {
    disabled_true: {
      backgroundColor: colors.disabled,
      foregroundColor: colors.buttonSecondaryAccent,
    },
    backgroundColor: {
      true: colors.accent,
      false: colors.buttonSecondaryAccent,
    },
    foregroundColor: {
      true: colors.buttonSecondaryAccent,
      false: colors.accent,
    },
    textPayIn: {
      true: 'einvoice_confirm_accept_button',
      false: 'einvoice_accept_button',
    },
    textPayOut: {
      true: 'einvoice_confirm_refuse_button',
      false: 'einvoice_refuse_button',
    },
  }

  const getPdfData = (newAction) => {
    if (newAction === 'share') {
      setAction(prev => ({
        ...prev,
        shareLoading: true,
        title: 'share',
      }))
    } else {
      setAction(prev => ({
        ...prev,
        perviewLoading: true,
        title: 'preview',
      }))
    }
    getPdf()
  }

  const renderAllTabs = useMemo(() => {
    return (
      <Tabs
        initialRouteName={translate('einvoice_details_info_tab')}
        style={styles.tabView}
        screenOptions={{
          lazy: false,
        }}
      >
        <Tab.Screen key={'tab-info'} name={translate('einvoice_details_info_tab')}>
          {(props) => (
            <BasicInfo
              {...props}
              id={id}
              setLoading={setLoading}
              refetchOnFinish={refetchOnFinish}
            />
          )}
        </Tab.Screen>
        <Tab.Screen key={'tab-buyerseller'} name={translate('einvoice_details_buyerseller_tab')}>
          {(props) => (<BuyerSeller {...props} id={id} />)}
        </Tab.Screen>
      </Tabs>
    )
  }, [loading, refetchOnFinish])

  return (
    <>
      <View style={styles.inlineButtons}>
        <Button
          variant={loading || action.shareLoading ? 'disabled' : 'preActive'}
          disabled={loadingPdf || loading}
          title="einvoice_share_button"
          style={styles.inlineButton1}
          onPress={() => getPdfData('share')}
          icon={images.share}
          titleStyle={{ color: loading || action.shareLoading ? colors.white : colors.secondaryAccent }}
          iconColor={loading || action.shareLoading ? colors.white : colors.secondaryAccent}
          loader={action.shareLoading}
          loaderComponent={<Loader source={animations.vfLoaderWhite} size={24} />}
        />

        <Button
          variant={loading || action.perviewLoading ? 'disabled' : 'preActive'}
          title="einvoice_preview_button"
          disabled={loadingPdf || loading}
          style={styles.inlineButton2}
          onPress={() => getPdfData('preview')}
          icon={images.previewDoc}
          titleStyle={{ color: loading || action.perviewLoading ? colors.white : colors.secondaryAccent }}
          iconColor={loading || action.perviewLoading ? colors.white : colors.secondaryAccent}
          loader={action.perviewLoading}
          loaderComponent={<Loader source={animations.vfLoaderWhite} size={24} />}
        />
      </View>
      {renderAllTabs}
      {invoicestatus === 'DELIVERED' && partyType === 'BUYER' && <View style={styles.inlineButtonsFooter}>
        <Button
          title={buttonProps.textPayIn[confirmAccept]}
          titleStyle={styles.buttonTitle}
          style={[styles.button, styles.inlineButton1]}
          variant={loading || refuseLoading || acceptLoading ? 'disabled' : confirmAccept ? 'active' : 'preActive'}
          onPress={accept}
          loader={acceptLoading}
          loaderComponent={<Loader source={animations.vfLoaderWhite} size={24} />}
        />
        <Button
          title={buttonProps.textPayOut[confirmRefuse]}
          titleStyle={styles.buttonTitle}
          style={[
            styles.button,
            styles.inlineButton2,
            styles.inlineButtonRight,
          ]}
          variant={loading || refuseLoading || acceptLoading ? 'disabled' : confirmRefuse ? 'active' : 'preActive'}
          onPress={refuse}
          loader={refuseLoading}
          loaderComponent={<Loader source={animations.vfLoaderWhite} size={24} />}
        />
      </View>}
      <Modal
        isVisible={isErrorActive.isVisible}
        onBackButtonPress={() => setErrorActive({
          isVisible: false,
          message: '',
        })}
        onBackdropPress={() => setErrorActive({
          isVisible: false,
          message: '',
        })}
        animationIn="fadeIn"
        animationOut="fadeOut"
      >
        <ModalContent onClose={() => setErrorActive({
          isVisible: false,
          message: '',
        })}>
          <Icon source={images.delete} style={styles.errorIcon} />
          <Text i18nKey="orders_error_label" color={colors.accent} size="h5" align="center" weight="bold" style={styles.errorTitle} />
          <Text i18nKey={getErrorMessage(isErrorActive.message)} align="center" />
        </ModalContent>
      </Modal>
    </>
  )
}
GovEInvoiceDetails.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  status: PropTypes.string,
  partyType: PropTypes.string,
  addListener: PropTypes.func,
}

export default GovEInvoiceDetails
