import React from 'react'
import {
  Platform,
  TouchableNativeFeedback,
  TouchableOpacity,
  View,
} from 'react-native'
import PropTypes from 'prop-types'

const ANDROID_VERSION_LOLLIPOP = 21

function Touchable({
  borderless,
  pressColor,
  style,
  children,
  ...rest
}) {
  /*
   * TouchableNativeFeedback.Ripple causes a crash on old Android versions,
   * therefore only enable it on Android Lollipop and above.
   *
   * All touchables on Android should have the ripple effect according to
   * platform design guidelines.
   * We need to pass the background prop to specify a borderless ripple effect.
   */
  if (
    Platform.OS === 'android' &&
    Platform.Version >= ANDROID_VERSION_LOLLIPOP
  ) {
    return (
      <TouchableNativeFeedback
        {...rest}
        useForeground={TouchableNativeFeedback.canUseNativeForeground()}
        background={TouchableNativeFeedback.Ripple(pressColor, borderless)}
      >
        <View style={style}>{children}</View>
      </TouchableNativeFeedback>
    )
  } else {
    return (
      <TouchableOpacity style={style} {...rest}>
        {children}
      </TouchableOpacity>
    )
  }
}

Touchable.defaultProps = {
  borderless: false,
  pressColor: 'rgba(0, 0, 0, .32)',
  delayPressIn: 0,
}
Touchable.propTypes = {
  disabled: PropTypes.bool,
  borderless: PropTypes.bool,
  delayPressIn: PropTypes.number,
  onPress: PropTypes.func,
  children: PropTypes.node,
  style: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
}

export default Touchable
