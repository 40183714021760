import { styleSheetFactory } from '../../Themes/Colors'

const styles = styleSheetFactory(colors => ({
    container: {
        flex: 1,
    },
    contentContainer: {
        flexGrow: 1,
        padding: 15,
    },
    header: {
        marginBottom: 15,
    },
    footer: {
        marginVertical: 15,
        padding: 15,
        borderRadius: 7,
        backgroundColor: colors.tertiaryBackground,
    },
    note: {
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: 5,
    },
    icon: {
        marginRight: 5,
    },
    modalIcon: {
        alignSelf: 'center',
        marginBottom: 5,
    },
    button: {
        marginHorizontal: 15,
        marginBottom: 15,
    },
}))

const itemStyles = styleSheetFactory(colors => ({
    container: {
        height: 60,
        backgroundColor: colors.tertiaryBackground,
        justifyContent: 'center',
        overflow: 'hidden',
    },
    containerSelected_true: {
        height: 80,
    },
    firstItem_true: {
        borderTopLeftRadius: 7,
        borderTopRightRadius: 7,
    },
    lastItem_true: {
        borderBottomLeftRadius: 7,
        borderBottomRightRadius: 7,
    },
    touchable: {
        flexGrow: 1,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    textContainer: {
        marginRight: 15,
        flexGrow: 1,
        flexShrink: 1,
        flexDirection: 'row',
    },
    name: {
        marginLeft: 15,
    },
    radioInput: {
        flexShrink: 0,
        marginRight: 16,
    },
    clusivityContainer: {
        flexDirection: 'row',
    },
    withClusivity_true: {
        height: 44,
    },
    withClusivity_false: {
        height: 0,
        overflow: 'hidden',
    },
    clusivityItem: {
        flex: 1,
        marginHorizontal: 15,
        marginVertical: 10,
        borderRadius: 6,
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: colors.background,
    },
    clusivityItemSelected_true: {
        backgroundColor: colors.secondaryAccent,
    },
    selectedIcon: { marginRight: 5 },
    inclusionTypeSelected_true: {
        color: colors.white,
    },
    inclusionTypeSelected_false: {
        color: colors.secondaryText,
    },
}))

const separatorStyles = styleSheetFactory(colors => ({
    container: {
        backgroundColor: colors.separator,
        height: 1,
    },
}))

export {
    itemStyles,
    separatorStyles,
}

export default styles
