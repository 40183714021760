import React, { useState, useContext, useEffect, useMemo } from 'react'
import {
  SafeAreaView,
  View,
  Platform,
  KeyboardAvoidingView,
  useWindowDimensions,
  Linking,
} from 'react-native'
import { useTheme } from 'react-native-themed-styles'
import { CommonActions } from '@react-navigation/native'
import { createNativeStackNavigator } from '@react-navigation/native-stack'
import {
  createDrawerNavigator,
  DrawerContentScrollView,
  useDrawerProgress,
} from '@react-navigation/drawer'
import PropTypes from 'prop-types'
import Animated from 'react-native-reanimated'

import ContentManager from '../Utils/ContentManager'
import { useUserDetails } from '../Utils/AuthDetails'
import { usePermissions } from '../Utils/Permissions'

import { Text, Icon, Touchable, ErrorModal, Button } from '../Components'

import Empty from '../Containers/Empty'

import CashDrawer from '../Containers/CashDrawer'
import CurrentDrawer from '../Containers/CurrentDrawer'
import EndDrawer from '../Containers/EndDrawer'
import PayInOut from '../Containers/PayInOut'
import PayInOutDetails from '../Containers/PayInOutDetails'
import CashDrawerHistory from '../Containers/CashDrawerHistory'
import CashDrawerReport from '../Containers/CashDrawerReport'
import TodaySales from '../Containers/TodaySales'
import TodayRefunds from '../Containers/TodayRefunds'
import TodayDrawer from '../Containers/TodayDrawer'
import Settings from '../Containers/Settings'

// Orders and receipts (Transactions)
import Transactions from '../Containers/Orders'
import Receipt from '../Containers/Receipt'
import IssueRefundDetails from '../Containers/IssueRefundDetails'
import IssueRefund from '../Containers/IssueRefund'
import Refund from '../Containers/Refund'

import ItemsList from '../Containers/ItemsList'
import Items from '../Containers/Items'
import NewItem from '../Containers/NewItem'
import Categories from '../Containers/Categories'
import NewCategory from '../Containers/NewCategory'

// Checkout
import Checkout from '../Containers/Checkout'
import AddToBasket from '../Containers/AddToBasket'
import Cart from '../Containers/Cart'
import OpenedOrders from '../Containers/OpenedOrders'
import SaveItems from '../Containers/SaveItems'
import VariableItem from '../Containers/VariableItem'
import Notifications from '../Containers/Notifications'
import SelectDiscount from '../Containers/SelectDiscount'
import SetDiscountValue from '../Containers/SetDiscountValue'
import Payment from '../Containers/Payment'

//Settings
import PrinterSetUp from '../Containers/PrinterSetUp'
import Taxes from '../Containers/Taxes'
import NewTax from '../Containers/NewTax'
import Language from '../Containers/Language'
import SoundAndVibration from '../Containers/SoundAndVibration'
import ChangePassword from '../Containers/ChangePassword'
import Locations from '../Containers/Locations'
import Location from '../Containers/Location'
import CashRegister from '../Containers/CashRegister'
import NewRegister from '../Containers/NewRegister'
import RegisterDetails from '../Containers/RegisterDetails'
import SelectEmployee from '../Containers/SelectEmployee'
import SelectCashRegister from '../Containers/SelectCashRegister'
import TermsAndConditions from '../Containers/TermsAndConditions'
import PrivacyPolicy from '../Containers/PrivacyPolicy'
import NewLocation from '../Containers/NewLocation'
import DeleteAccount from '../Containers/DeleteAccount'

/**
 * Units screens
 */
import Units from '../Containers/Units'
import NewUnit from '../Containers/NewUnit'

/**
 * Discounts
 */
import Discounts from '../Containers/Discounts'
import NewDiscount from '../Containers/NewDiscount'

import Users from '../Containers/Users'
import NewUser from '../Containers/NewUser'
import UserDetails from '../Containers/UserDetails'
import UserLocation from '../Containers/UserLocation'

import NewVariation from '../Containers/NewVariation'

import Modifiers from '../Containers/Modifiers'
import NewModifier from '../Containers/NewModifier'

import SelectTax from '../Containers/SelectTax'

//Certificate
import Certificate from '../Containers/Certificate'
import RegisterCertificate from '../Containers/RegisterCertificate'

//Customers
import Customers from '../Containers/Customers'
import AllCustomers from '../Containers/AllCustomers'
import NewCustomer from '../Containers/NewCustomer'
import Groups from '../Containers/Groups'
import Groups_2 from '../Containers/Groups/Groups_2'
import NewGroup from '../Containers/NewGroup'
import CustomerDetails from '../Containers/CustomerDetails'
import SelectCustomerAddress from '../Containers/SelectCustomerAddress'

//E-Invoice
import EInvoice from '../Containers/EInvoice'
import EInvoiceDetails from '../Containers/EInvoiceDetails'
import RefundEinvoice from '../Containers/RefundEinvoice'
import RefundDetails from '../Containers/RefundDetails'
import SupportingInvoice from '../Containers/SupportingInvoice'
import SupportingInvoiceDetails from '../Containers/SupportiveInvoiceDetails'
import BankAccounts from '../Containers/BankAccounts'
import NewBankAccount from '../Containers/NewBankAccount'
import Invoices from '../Containers/Invoices'
import { useLanguage } from '../Utils/Language'

//OnBoarding
import OnBoarding from '../Containers/OnBoarding'

//Reports
import Reports from '../Containers/Reports'

//Upload Items
import UploadItems from '../Containers/UploadItems'
import UploadType from '../Containers/UploadType'

//Product Catalog
import ProductCatalog from '../Containers/ProductCatalog'

//SelectDefaultTax
import SelectDefaultTax from '../Containers/SelectDefaultTax'

import OnlineOrders from '../Containers/OnlineOrders'
import OrderDetails from '../Containers/OrderDetails'
import CancelOrder from '../Containers/CancelOrder'

import RemoveTCR from '../Containers/RemoveTCR'

import EditBusinessUnitCode from '../Containers/EditBusinessUnitCode'

import SearchGovInvoices from '../Containers/SearchGovInvoices'
import GovInvoiceDetails from '../Containers/GovInvoiceDetails'


import SelectableItem from '../Components/SelectableItem/SelectableItem'


import LocationChangeLoader from '../Containers/LocationChangeLoader'

import { useConfig } from '../Utils'

import SwitchLocation from '../Components/SwitchLocation'

import themedStyles from './Navigation.Styles'
import images from '../Themes/Images'
import Theme from '../Themes/Theme'

// to clear the stack use: navigation.dispatch(StackActions.popToTop()),
const Stack = createNativeStackNavigator()
const OnboardStack = createNativeStackNavigator()
const Drawer = createDrawerNavigator()

const OnboardingStack = ({ logout, refreshToken, onBoard, policyCheck, shouldShowWarning, setShouldShowWarning }) => {
  return <OnboardStack.Navigator screenOptions={{ headerShown: false }} >
    <OnboardStack.Screen name="OnBoarding">
      {(props) => <OnBoarding {...props} logout={logout} refreshToken={refreshToken} onBoard={onBoard} policyCheck={policyCheck} shouldShowWarning={shouldShowWarning} setShouldShowWarning={setShouldShowWarning} />}
    </OnboardStack.Screen>
  </OnboardStack.Navigator>
}
OnboardingStack.propTypes = {
  logout: PropTypes.func,
  refreshToken: PropTypes.func,
  onBoard: PropTypes.number,
  policyCheck: PropTypes.bool,
  shouldShowWarning: PropTypes.bool,
  setShouldShowWarning: PropTypes.func,
}

function Screens({ style, setActiveRoute }) {
  const [styles] = useTheme(themedStyles)
  const { colors } = useContext(Theme)

  return (
    <Animated.View
      style={[
        styles.stack,
        // [style].flat()
        style,
      ]}>
      <Stack.Navigator
        initialRouteName="Checkout"
        screenOptions={{
          cardStyle: {
            backgroundColor: colors.background,
          },
          headerShown: false,
          animationTypeForReplace: 'push',
        }}>
        <Stack.Screen name="Empty" component={Empty} />

        <Stack.Screen name="Checkout" component={Checkout} />
        <Stack.Screen name="AddToBasket" component={AddToBasket} />
        <Stack.Screen name="VariableItem" component={VariableItem} />
        <Stack.Screen name="Cart" component={Cart} />
        <Stack.Screen name="OpenedOrders" component={OpenedOrders} />
        <Stack.Screen name="SaveItems" component={SaveItems} />
        <Stack.Screen name="Notifications" component={Notifications} />
        <Stack.Screen name="SelectDiscount" component={SelectDiscount} />
        <Stack.Screen name="SetDiscountValue" component={SetDiscountValue} />
        <Stack.Screen name="Payment" component={Payment} />

        <Stack.Screen name="CashDrawer" component={CashDrawer} />
        <Stack.Screen name="CurrentDrawer" component={CurrentDrawer} />
        <Stack.Screen name="EndDrawer" component={EndDrawer} />
        <Stack.Screen name="PayInOut" component={PayInOut} />
        <Stack.Screen name="PayInOutDetails" component={PayInOutDetails} />
        <Stack.Screen name="CashDrawerHistory" component={CashDrawerHistory} />
        <Stack.Screen name="CashDrawerReport" component={CashDrawerReport} />

        <Stack.Screen name="Items" component={Items} />
        <Stack.Screen name="AllItems" component={ItemsList} />
        <Stack.Screen name="NewItem" component={NewItem} />
        <Stack.Screen name="Categories" component={Categories} />
        <Stack.Screen name="NewCategory" component={NewCategory} />
        <Stack.Screen name="Modifiers" component={Modifiers} />
        <Stack.Screen name="NewModifier" component={NewModifier} />
        <Stack.Screen name="Discounts" component={Discounts} />
        <Stack.Screen name="NewDiscount" component={NewDiscount} />
        <Stack.Screen name="Units" component={Units} />
        <Stack.Screen name="NewUnit" component={NewUnit} />
        <Stack.Screen name="NewVariation" component={NewVariation} />

        <Stack.Screen name="SelectTax" component={SelectTax} />

        <Stack.Screen name="TodaySales" component={TodaySales} />
        <Stack.Screen name="TodayRefunds" component={TodayRefunds} />
        <Stack.Screen name="TodayDrawer" component={TodayDrawer} />


        <Stack.Screen name="Users" component={Users} />
        <Stack.Screen name="NewUser" component={NewUser} />
        <Stack.Screen name="UserDetails" component={UserDetails} />
        <Stack.Screen name="NewLocation" component={NewLocation} />
        <Stack.Screen name="UserLocation" component={UserLocation} />

        <Stack.Screen name="Settings" component={Settings} />

        <Stack.Screen name="PrinterSetUp" component={PrinterSetUp} />

        <Stack.Screen name="Taxes" component={Taxes} />
        <Stack.Screen name="NewTax" component={NewTax} />

        <Stack.Screen name="CashRegister" component={CashRegister} />
        <Stack.Screen name="NewRegister" component={NewRegister} />
        <Stack.Screen name="RegisterDetails" component={RegisterDetails} />
        <Stack.Screen name="SelectEmployee" component={SelectEmployee} />
        <Stack.Screen name="SelectCashRegister" component={SelectCashRegister} />

        <Stack.Screen name="Language" component={Language} />
        <Stack.Screen name="SoundAndVibration" component={SoundAndVibration} />
        <Stack.Screen name="TermsAndConditions" component={TermsAndConditions} />
        <Stack.Screen name="PrivacyPolicy" component={PrivacyPolicy} />
        <Stack.Screen name="DeleteAccount" component={DeleteAccount} />

        <Stack.Screen name="ChangePassword" component={ChangePassword} />

        <Stack.Screen name="Locations" component={Locations} />
        <Stack.Screen name="Location" component={Location} />

        <Stack.Screen name="Transactions" component={Transactions} />
        <Stack.Screen name="Receipt" component={Receipt} />
        <Stack.Screen name="IssueRefundDetails" component={IssueRefundDetails} />
        <Stack.Screen name="IssueRefund" component={IssueRefund} />
        <Stack.Screen name="Refund" component={Refund} />

        <Stack.Screen name="Reports" component={Reports} />

        <Stack.Screen name="UploadItems" component={UploadItems} />
        <Stack.Screen name="UploadType" component={UploadType} />

        <Stack.Screen name="Certificate" component={Certificate} />
        <Stack.Screen name="RegisterCertificate" component={RegisterCertificate} />

        <Stack.Screen name="Customers" component={Customers} />
        <Stack.Screen name="AllCustomers" component={AllCustomers} />
        <Stack.Screen name="NewCustomer" component={NewCustomer} />
        <Stack.Screen name="Groups" component={Groups} />
        <Stack.Screen name="Groups_2" component={Groups_2} />
        <Stack.Screen name="NewGroup" component={NewGroup} />
        <Stack.Screen name="CustomerDetails" component={CustomerDetails} />
        <Stack.Screen name="SelectCustomerAddress" component={SelectCustomerAddress} />

        <Stack.Screen name="EInvoice" component={EInvoice} />
        <Stack.Screen name="EInvoiceDetails" component={EInvoiceDetails} />
        <Stack.Screen name="RefundEinvoice" component={RefundEinvoice} />
        <Stack.Screen name="RefundDetails" component={RefundDetails} />
        <Stack.Screen name="SupportingInvoice" component={SupportingInvoice} />
        <Stack.Screen name="SupportingInvoiceDetails" component={SupportingInvoiceDetails} />
        <Stack.Screen name="BankAccounts" component={BankAccounts} />
        <Stack.Screen name="NewBankAccount" component={NewBankAccount} />
        <Stack.Screen name="Invoices" component={Invoices} />

        <Stack.Screen name="SelectDefaultTax" component={SelectDefaultTax} />
        <Stack.Screen name="ProductCatalog" component={ProductCatalog} />

        <Stack.Screen name="OnlineOrders" component={OnlineOrders} />
        <Stack.Screen name="OrderDetails" component={OrderDetails} />
        <Stack.Screen name="CancelOrder" component={CancelOrder} />

        <Stack.Screen name="RemoveTCR" component={RemoveTCR} />
        <Stack.Screen name="EditBusinessUnitCode" component={EditBusinessUnitCode} />

        <Stack.Screen name="SearchGovInvoices" component={SearchGovInvoices} />
        <Stack.Screen name="GovInvoiceDetails" component={GovInvoiceDetails} />
        <Stack.Screen
          name="LocationChangeLoader"
          component={LocationChangeLoader}
          initialParams={{ setActiveRoute }}
        />



      </Stack.Navigator>
    </Animated.View>
  )
}

Screens.propTypes = {
  isLargeScreen: PropTypes.bool,
  style: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.object),
    PropTypes.object,
  ]),
  setActiveRoute: PropTypes.func,
}

const DrawerContent = (props) => {

  const { switchLocation: { businessName: bussinesName } } = useConfig()
  const lang = useLanguage()

  const [styles] = useTheme(themedStyles)
  const { colors } = useContext(Theme)
  const { id, fullName, role } = useUserDetails()
  const { menu = {} } = usePermissions()
  const { translate } = ContentManager

  const progress = useDrawerProgress()

  const [visible, setVisible] = useState(false)

  const {
    activeRoute,
    setActiveRoute,
    navigation: { openDrawer, closeDrawer, dispatch },
    logout,
    setProgress,
  } = props

  const MENU_ITEMS = {
    routes: [
      {
        key: 'Checkout',
        screenName: 'Checkout',
        options: {
          drawerLabel: 'menu_checkout_label',
          title: 'Checkout',
          drawerIcon: images.checkout,
        },
      },
      {
        key: 'ProductCatalog',
        screenName: 'ProductCatalog',
        options: {
          drawerLabel: 'product_catalog_title',
          title: 'ProductCatalog',
          drawerIcon: images.listView,
        },
      },
      {
        key: 'Invoices',
        screenName: 'Invoices',
        options: {
          drawerLabel: 'menu_invoices_label',
          title: 'Invoices',
          drawerIcon: images.transactions,
        },
      },
      // {
      //   key: 'OnlineOrders',
      //   screenName: 'OnlineOrders',
      //   options: {
      //     drawerLabel: 'online_orders_header',
      //     title: 'OnlineOrders',
      //     drawerIcon: images.listView,
      //   },
      // },
      {
        key: 'Reports',
        screenName: 'Reports',
        options: {
          drawerLabel: 'menu_reports_label',
          title: 'Reports',
          drawerIcon: images.report,
        },
      },
      {
        key: 'Customers',
        screenName: 'Customers',
        options: {
          drawerLabel: 'menu_partners_label',
          title: 'Customers',
          drawerIcon: images.partner,
        },
      },
      {
        key: 'GOVEvents',
        screenName: 'GOVEvents',
        options: {
          drawerLabel: 'menu_gov_label',
          title: 'Gov Events',
          drawerIcon: images.govEvents,
        },
      },
      {
        key: 'CashDrawer',
        screenName: 'CashDrawer',
        options: {
          drawerLabel: 'menu_drawer_label',
          title: 'Cash Drawer',
          drawerIcon: images.cashDrawer,
        },
      },
      {
        key: 'Users',
        screenName: 'Users',
        options: {
          drawerLabel: 'menu_users_label',
          title: 'Users',
          drawerIcon: images.user,
        },
      },
      {
        key: 'Settings',
        screenName: 'Settings',
        options: {
          drawerLabel: 'menu_settings_label',
          title: 'Settings',
          drawerIcon: images.settings,
        },
      },
    ],
  }

  useEffect(() => {
    setProgress(progress)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // const { switchLocation: { deviceId, locationId } } = useConfig()

  const [modalPage, setmodalPage] = useState(1)


  const [modal, setModal] = useState({
    isVisible: false,
    data: [],
    title: '',
    placeholder: '',
    buttonLabel: '',
    onButtonPress: () => { },
    selected: undefined,
    select: () => { },
    Item: SelectableItem,
    searchKey: 'code',
    noteText: '',
  })

  const openLocationModal = () => {
    setModal({
      isVisible: true,
      title: 'change_location_select_prompt',
      placeholder: 'bahy',
      buttonLabel: 'change_location_button_label',
      searchKey: 'code',
      Item: SelectableItem,
      noteText: 'change_location_note_text',
      withSearch: false,
      withVariables: false,
    })
  }

  const renderSwitchLocationButton = useMemo(() => {
    if (role === 'Admin') {
      return (
        <Button
          style={styles.switchLocationButton}
          variant="outline"
          icon={images.location}
          iconColor={colors.primaryText}
          onPress={() => {
            closeDrawer()
            openLocationModal()
          }}
          title="location_change_button"
        />
      )
    }
    return

  }, [role, lang])



  return (
    <KeyboardAvoidingView
      style={styles.full}
      behavior={Platform.select({
        ios: 'padding',
        android: undefined,
      })}
    >
      <DrawerContentScrollView
        {...props}
        showsVerticalScrollIndicator={false}
        contentContainerStyle={styles.fillRemaining}>
        <View style={styles.topContainer}>
          <Touchable
            style={[styles.drawerItem, styles['drawerItemFocused_' + (activeRoute === 'UserDetails')]]}
            onPress={() => {
              closeDrawer()
              // if (role === 'Admin' && activeRoute !== 'NewLocation') {
              //   setActiveRoute('NewLocation')
              //   dispatch(
              //     CommonActions.reset({
              //       index: 0,
              //       routes: [{ name: 'NewLocation', params: { id, goBack: openDrawer }}],
              //     }),
              //   )
              // }
              if (activeRoute !== 'UserDetails') {
                setActiveRoute('UserDetails')
                dispatch(
                  CommonActions.reset({
                    index: 0,
                    routes: [{ name: 'UserDetails', params: { id, goBack: openDrawer } }],
                  }),
                )
              }
            }}>
            <View style={styles.companyInfoContainer}>
              <Icon source={images.vfLogo} size={32} />
              <View style={styles.topInfo}>
                <Text style={styles.companyInfo} i18nKey={bussinesName} weight="bold" numberOfLines={2} />
                <Text
                  style={styles.userInfo}
                  i18nKey={fullName}
                />
              </View>
              <Icon
                source={images.arrow_right}
                size={20}
                color={colors.drawerIcon}
                style={styles.rightIcon}
              />
            </View>
          </Touchable>
          {renderSwitchLocationButton}
        </View>

        {MENU_ITEMS?.routes?.map(item => {
          if ((menu[item?.key] || []).includes(role)) {
            return (
              <View style={styles.drawerItemContainer} key={item?.key}>
                <Touchable
                  style={[styles.drawerItem, styles['drawerItemFocused_' + (item?.key === activeRoute)]]}
                  onPress={() => {
                    setActiveRoute(item?.key)
                    closeDrawer()
                    if (item.screenName !== activeRoute) {
                      dispatch(
                        CommonActions.reset({
                          index: 0,
                          routes: [{ name: item.screenName }],
                        }),
                      )
                    }
                  }}>
                  <Icon source={item.options.drawerIcon} size={16} color={colors.drawerIcon} />
                  <Text i18nKey={item.options.drawerLabel} color={colors.drawerText} style={styles.drawerItemLabel} numberOfLines={1} />
                </Touchable>
              </View>
            )
          }
          return null
        })}
      </DrawerContentScrollView>

      <View style={[styles.drawerItemContainer, styles.login]}>
        <Touchable
          style={styles.drawerItem}
          onPress={() => Linking.openURL('https://api.whatsapp.com/send?phone=355699000149')}>
          <Icon source={images.whatsapp} size={16} color={colors.drawerIcon} />
          <Text i18nKey="support_title" color={colors.drawerText} style={styles.drawerItemLabel} numberOfLines={1} />
        </Touchable>
        <View style={styles.separator} />
        <Touchable
          style={styles.drawerItem}
          onPress={() => setVisible(true)}>
          <Icon source={images.logout} size={16} color={colors.drawerIcon} />
          <Text i18nKey="logout_key" color={colors.drawerText} style={styles.drawerItemLabel} numberOfLines={1} />
        </Touchable>
      </View>
      {role === 'Admin' && <SwitchLocation modal={modal} setModal={setModal} modalPage={modalPage} setmodalPage={setmodalPage} />}

      <ErrorModal
        title={translate('logout_key')}
        description={translate('logout_message')}
        isVisible={visible}
        primaryText={translate('logout_key')}
        tertiaryText={translate('logout_button_cancel')}
        primaryAction={() => {
          logout()
          closeDrawer()
        }}
        tertiaryAction={() => setVisible(false)}
        onClosePress={() => setVisible(false)}
      />
    </KeyboardAvoidingView>
  )
}
DrawerContent.defaultProps = {
  firstName: '',
  lastName: '',
  company: '',
  activeRoute: 'Checkout',
  setActiveRoute: () => { },
  isLargeScreen: false,
  navigation: {},
  setVisible: () => { },
}

DrawerContent.propTypes = {
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  company: PropTypes.string,
  activeRoute: PropTypes.string,
  setActiveRoute: PropTypes.func,
  isLargeScreen: PropTypes.bool,
  navigation: PropTypes.object,
  logout: PropTypes.func,
  setProgress: PropTypes.func,
  progress: PropTypes.any,
}

function Navigation({ logout }) {
  const [styles] = useTheme(themedStyles)
  const { colors } = useContext(Theme)
  useLanguage()

  const [progress, setProgress] = useState(new Animated.Value(0))

  const scale = Animated.interpolateNode(progress, {
    inputRange: [0, 1],
    outputRange: [1, 0.9],
  })
  // const borderWidth = Animated.interpolateNode(progress, {
  //   inputRange: [0, 0.01, 1],
  //   outputRange: [0, 1, 2],
  // })
  const borderRadius = Animated.interpolateNode(progress, {
    inputRange: [0, 0.2, 1],
    outputRange: [0, 20, 20],
  })
  const animatedStyle = {
    backgroundColor: colors.background,
    // borderColor: colors.drawerScreenSeparator,
    // borderWidth,
    borderRadius,
    transform: [{ scale }],
  }

  //renaldo tablet support
  const [activeRoute, setActiveRoute] = useState('Checkout')
  const { width } = useWindowDimensions()
  const isLargeScreen = width >= 800

  return (
    <SafeAreaView style={styles.container}>
      <View style={styles.drawerContainer}>
        <Drawer.Navigator
          screenOptions={{
            drawerStyle: styles.drawerStyles,
            headerShown: false,
            sceneContainerStyle: !isLargeScreen && styles.drawerNavigatorSceene,
            drawerType: isLargeScreen ? 'permanent' : 'back',
            overlayColor: 'transparent',
            drawerContentContainerStyle: !isLargeScreen && styles.drawerNavigatorContent,
          }}
          drawerContent={(props) => {
            return (
              <DrawerContent
                {...props}
                setProgress={setProgress}
                isLargeScreen={isLargeScreen}
                activeRoute={activeRoute}
                setActiveRoute={setActiveRoute}
                logout={logout}
              />
            )
          }}>
          <Drawer.Screen name="Screens">
            {(props) => (
              <Screens
                {...props}
                style={isLargeScreen ? styles.full : animatedStyle}
                setActiveRoute={setActiveRoute}
              />
            )}
          </Drawer.Screen>

        </Drawer.Navigator>
      </View>
    </SafeAreaView>
  )
}

Navigation.propTypes = {
  logout: PropTypes.func,
  user: PropTypes.object,
  props: PropTypes.object,
}

export default Navigation
export {
  OnboardingStack,
}
