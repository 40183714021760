import React from 'react'
import PropTypes from 'prop-types'
import { Text, View } from 'react-native'
import { useTheme } from 'react-native-themed-styles'

import themedStyles from './ListItem.Styles'

function ListItem(props) {

    const [styles] = useTheme(themedStyles)

    return (
        <View style={[styles.container, styles['container' + props.isLast]]}>
            <Text style={styles.containerTitle}>{props.leftText}</Text>
            <Text style={styles.containerInfo}>{props.rightText}</Text>
        </View>
    )
}
ListItem.propTypes = {
    isLast: PropTypes.bool,
    leftText: PropTypes.string,
    rightText: PropTypes.string,
}

export default ListItem
