const UPDATE_STEP = 'UPDATE_STEP'
const UPDATE_STEP_KEY = 'UPDATE_STEP_KEY'
const RESET_STATE = 'RESET_STATE'
const RESET_PAYMENT_STATE = 'RESET_PAYMENT_STATE'
const NEW_INVOICE = 'NEW_INVOICE'
const RESET_ITEMS = 'RESET_ITEMS'
const SUPPORT_INVOICE_UPDATE_STEP = 'SUPPORT_INVOICE_UPDATE_STEP'
const SUPPORT_INVOICE_UPDATE_STEP_KEY = 'SUPPORT_INVOICE_UPDATE_STEP_KEY'
const SUPPORT_INVOICE_RESET_STATE = 'SUPPORT_INVOICE_RESET_STATE'
const SUPPORT_INVOICE_NEW_INVOICE = 'SUPPORT_INVOICE_SUPPORT_INVOICE_NEW_INVOICE'
const SUPPORT_INVOICE_NEW_EINVOICE_STATE = 'SUPPORT_INVOICE_NEW_EINVOICE_STATE'
const STORE_ITEMS = 'STORE_ITEMS'
const UPDATE_UBL = 'UPDATE_UBL'
const RESET_UBL = 'RESET_UBL'
const NEW_EINVOICE_STATE = 'NEW_EINVOICE_STATE'

const UPDATE_PAYMENT_ATTRIBUTE = 'UPDATE_PAYMENT_ATTRIBUTE'
const SHOW_PAYMENT_DATES_MODAL = 'SHOW_PAYMENT_DATES_MODAL'
const PAYMENT_DISPLAY_ERROR = 'PAYMENT_DISPLAY_ERROR'
const PAYMENT_DISMISS_ERROR = 'PAYMENT_DISMISS_ERROR'
const RESET_PAYMENT_DATES_MODAL = 'RESET_PAYMENT_DATES_MODAL'

const NEW_EMPOTENCY_KEY = 'NEW_EMPOTENCY_KEY'


const updateStep = (step, value) => ({
  type: UPDATE_STEP,
  step,
  value,
})

const updateStepKey = (step, key, value) => ({
  type: UPDATE_STEP_KEY,
  step,
  key,
  value,
})

const resetState = () => ({
  type: RESET_PAYMENT_STATE,
})

const resetEinvoiceState = () => ({
  type: RESET_STATE,
})

const newInvoice = (id) => ({
  type: NEW_INVOICE,
  value: id,
})

const newEinvoiceState = (newEinvoice) => ({
  type: NEW_EINVOICE_STATE,
  newEinvoice,
})

const supportInvoiceUpdateStep = (step, value) => ({
  type: SUPPORT_INVOICE_UPDATE_STEP,
  step,
  value,
})

const supportInvoiceUpdateStepKey = (step, key, value) => ({
  type: SUPPORT_INVOICE_UPDATE_STEP_KEY,
  step,
  key,
  value,
})

const supportInvoiceResetState = () => ({
  type: SUPPORT_INVOICE_RESET_STATE,
})

const supportInvoiceNewInvoice = (id) => ({
  type: SUPPORT_INVOICE_NEW_INVOICE,
  value: id,
})

const supportInvoiceNewEinvoiceState = (newEinvoice) => ({
  type: SUPPORT_INVOICE_NEW_EINVOICE_STATE,
  newEinvoice,
})

const resetItems = () => ({
  type: RESET_ITEMS,
})

const storeItems = (newItems) => ({
  type: STORE_ITEMS,
  newItems,
})

const updateCalculatedUBL = (newUBL) => ({
  type: UPDATE_UBL,
  newUBL,
})

const resetCalculatedUBL = () => ({
  type: RESET_UBL,
})

const updatePaymentAttribute = (step, value) => ({
  type: UPDATE_PAYMENT_ATTRIBUTE,
  step,
  value,
})

const showPaymentDatesModal = (value) => ({
  type: SHOW_PAYMENT_DATES_MODAL,
  value,
})

const resetPaymentDatesModal = () => ({
  type: RESET_PAYMENT_DATES_MODAL,
})

const displayPaymentError = (value) => ({
  type: PAYMENT_DISPLAY_ERROR,
  value,
})

const dismissPaymentError = () => ({
  type: PAYMENT_DISMISS_ERROR,
})

const generateNewKey = () => ({
  type: NEW_EMPOTENCY_KEY,
})

export {
  UPDATE_STEP,
  SUPPORT_INVOICE_UPDATE_STEP,
  SUPPORT_INVOICE_UPDATE_STEP_KEY,
  SUPPORT_INVOICE_RESET_STATE,
  SUPPORT_INVOICE_NEW_INVOICE,
  SUPPORT_INVOICE_NEW_EINVOICE_STATE,
  UPDATE_STEP_KEY,
  RESET_STATE,
  RESET_PAYMENT_STATE,
  NEW_INVOICE,
  RESET_ITEMS,
  STORE_ITEMS,
  RESET_UBL,
  UPDATE_UBL,
  NEW_EINVOICE_STATE,
  UPDATE_PAYMENT_ATTRIBUTE,
  SHOW_PAYMENT_DATES_MODAL,
  PAYMENT_DISPLAY_ERROR,
  PAYMENT_DISMISS_ERROR,
  RESET_PAYMENT_DATES_MODAL,
  NEW_EMPOTENCY_KEY,
  updateStep,
  updateStepKey,
  resetState,
  resetEinvoiceState,
  newInvoice,
  resetItems,
  storeItems,
  updateCalculatedUBL,
  resetCalculatedUBL,
  newEinvoiceState,
  supportInvoiceUpdateStep,
  supportInvoiceUpdateStepKey,
  supportInvoiceResetState,
  supportInvoiceNewInvoice,
  supportInvoiceNewEinvoiceState,
  updatePaymentAttribute,
  showPaymentDatesModal,
  displayPaymentError,
  dismissPaymentError,
  resetPaymentDatesModal,
  generateNewKey,
}
