/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, memo, useRef } from 'react'
import {
  View,
  ScrollView,
  TouchableOpacity,
  Platform,
  Animated,
  KeyboardAvoidingView,
} from 'react-native'
import Config from 'react-native-config'
import Geolocation from '@react-native-community/geolocation'
import MapView, {
  AnimatedRegion,
  Marker,
  PROVIDER_GOOGLE,
} from 'react-native-maps'
import {
  check,
  request,
  PERMISSIONS,
  RESULTS,
  openSettings,
} from 'react-native-permissions'
import { useTheme } from 'react-native-themed-styles'
import PropTypes from 'prop-types'
import axios from 'axios'

import {
  InputWithTags,
  ErrorModal,
  Text,
  Button,
  Header,
  Loader,
  Icon,
  InputPIN,
  InputPhone,
} from '../../Components'

import { colors, animations } from '../../Themes'
import themedStyles, { locationStyles } from './RequestDemo.Styles'

import images from '../../Themes/Images'

const INITIAL_REGION = {
  latitude: 41.3275,
  longitude: 19.8187,
  latitudeDelta: 0.015,
  longitudeDelta: 0.0121,
}

const DEMO_STEPS = {
  info: 'info',
  confirm: 'confirm',
  success: 'success',
  error: 'error',
}
const API_KEY = Platform.select({
  ios: Config.GOOGLE_MAPS_IOS_API_KEY,
  android: Config.GOOGLE_MAPS_ANDROID_API_KEY,
})
const ADDRESS_TYPES = ['locality', 'administrative_area_level_1']

const Location = memo(function Maps({
  coordinates,
  updateCoordinates,
  setAddress,
  setCity,
}) {
  const [styles] = useTheme(locationStyles)
  const region = useRef(INITIAL_REGION)
  const location = useRef(new AnimatedRegion(INITIAL_REGION)).current
  const opacity = useRef(new Animated.Value(0)).current

  const marker = useRef()
  const map = useRef()

  const animate = (nextProps) => {
    Animated.spring(opacity, { toValue: 1, useNativeDriver: false }).start()
    location
      .timing({ ...nextProps, duration: 300, useNativeDriver: false })
      .start()
    map.current?.animateToRegion({ ...nextProps })
  }

  const getAddress = () => {
    const coords = coordinates?.latitude + ', ' + coordinates?.longitude
    axios
      .get(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${coords}&key=+${API_KEY}`,
      )
      .then((res) => {
        const result1 = res.data.results[0].address_components || []
        const cityName = result1
          .find((element) => ADDRESS_TYPES.includes(element.types[0]))
          ?.long_name?.normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .normalize('NFC')
        const result =
          res?.data?.results?.[0]?.formatted_address + '; ' + coords || ''
        setAddress(result)
        setCity(cityName)
      })
      .catch(() => { })
  }

  useEffect(() => {
    if (coordinates) {
      animate({ ...region.current, ...coordinates })
      getAddress()
    }
  }, [coordinates])

  const onMapPress = ({ nativeEvent: { coordinate } }) => {
    updateCoordinates(coordinate)
    animate({ ...region.current, ...coordinate })
  }

  const onMarkerDragEnd = ({ nativeEvent: { coordinate } }) => {
    updateCoordinates(coordinate)
    Animated.spring(opacity, { toValue: 1 }).start()
    const newCoordinates = { ...region.current, ...coordinate }
    location.setValue(newCoordinates)
    map.current?.animateToRegion(newCoordinates)
  }

  const onRegionChangeComplete = (newRegion) => {
    region.current = newRegion
  }

  const locate = () => {
    Geolocation.getCurrentPosition(
      ({ coords }) => {
        updateCoordinates(coords)
        animate({ ...region.current, ...coords })
      },
      () => { },
    )
  }

  const getCurrentLocation = () => {
    if (Platform.OS === 'ios') {
      Geolocation.requestAuthorization()
      locate()
    } else {
      const permission = PERMISSIONS.ANDROID.ACCESS_FINE_LOCATION
      check(permission)
        .then((result) => {
          switch (result) {
            case RESULTS.UNAVAILABLE:
              break
            case RESULTS.DENIED:
              request(permission)
                .then((res) => {
                  if (res === RESULTS.GRANTED) {
                    locate()
                  }
                })
                .catch(() => { })
              break
            case RESULTS.GRANTED:
              locate()
              break
            case RESULTS.BLOCKED:
              openSettings()
              break
          }
        })
        .catch(() => { })
    }
  }

  return (
    <>
      <View style={styles.mylocationContainer}>
        <View style={styles.label}>
          <Text i18nKey="location_label_location" weight="light" />
        </View>

        <View
          style={styles.mapContainer}
          onStartShouldSetResponder={() => true}>
          <MapView
            ref={(ref) => {
              map.current = ref
            }}
            provider={PROVIDER_GOOGLE}
            style={styles.map}
            initialRegion={INITIAL_REGION}
            onPress={onMapPress}
            onMarkerDragEnd={onMarkerDragEnd}
            onRegionChangeComplete={onRegionChangeComplete}
            mapPadding={{}}>
            <Marker.Animated
              ref={(ref) => {
                marker.current = ref
              }}
              coordinate={location}
              anchor={{ x: 0.5, y: 0.925 }}
              opacity={opacity}
              draggable>
              <Icon source={images.locationMarker} size={70} />
            </Marker.Animated>
          </MapView>
        </View>
        <TouchableOpacity
          onPress={getCurrentLocation}
          style={styles.currentLocationButton}>
          <Text
            i18nKey="location_use_my_location"
            color={colors.accent}
            align="center"
            style={styles.currentLocationText}
          />
        </TouchableOpacity>
      </View>
    </>
  )
})
Location.defaultProps = {
  updateCoordinates: () => { },
}
Location.propTypes = {
  coordinates: PropTypes.shape({
    latitude: PropTypes.number,
    longitude: PropTypes.number,
  }),
  updateCoordinates: PropTypes.func,
  setAddress: PropTypes.func,
  setCity: PropTypes.func,
}

function Information(props) {
  const [styles] = useTheme(themedStyles)

  const {
    userPhone,
    setPhone,
    nipt,
    setNipt,
    personalId,
    setId,
    userEmail,
    setEmail,
    firstName,
    setFirstName,
    lastName,
    setLastName,
    city,
    setCity,
    company,
    setCompany,
    location,
    setLocation,
    address,
    setAddress,
    disabled,
    onBackPress,
    onNextPress,
    niptError,
    idError,
  } = props

  return (
    <>
      <Header
        title="header_request_demo"
        image={images.back}
        onPress={onBackPress}
      />
      <ScrollView
        showsVerticalScrollIndicator={false}
        style={styles.scrollView}>
        <View style={styles.bottomSpacing}>
          <Text
            i18nKey="users_personal_information_section"
            weight="bold"
            align="left"
            size="h3"
          />
          <Text i18nKey="demo_account_information_label" align="left" />
          <InputWithTags
            label="users_first_name_label"
            value={firstName}
            onChangeText={setFirstName}
            autoCorrect={false}
            inputContainerStyle={styles.inputContainer}
            inputWrapperStyle={styles.itemSpacing}
            labelOffset={{
              x0: 0,
              y0: -7,
              x1: 0,
              y1: 2,
            }}
          />
          <InputWithTags
            label="users_last_name_label"
            value={lastName}
            onChangeText={setLastName}
            autoCorrect={false}
            inputContainerStyle={styles.inputContainer}
            inputWrapperStyle={styles.itemSpacing}
            labelOffset={{
              x0: 0,
              y0: -7,
              x1: 0,
              y1: 2,
            }}
          />
          <InputWithTags
            label="certificate_email_label"
            value={userEmail}
            onChangeText={setEmail}
            autoCorrect={false}
            autoCapitalize="none"
            inputContainerStyle={styles.inputContainer}
            inputWrapperStyle={styles.itemSpacing}
            labelOffset={{
              x0: 0,
              y0: -7,
              x1: 0,
              y1: 2,
            }}
          />
          <InputPhone
            label="certificate_phone_label"
            value={userPhone}
            onChangeText={setPhone}
            autoCorrect={false}
            prefix="+355"
            inputContainerStyle={styles.inputContainer}
            inputWrapperStyle={styles.itemSpacing}
            keyboardType="numeric"
            labelOffset={{
              x0: 0,
              y0: -7,
              x1: 0,
              y1: 2,
            }}
          />
          <InputWithTags
            label="certificate_id_label"
            value={personalId}
            onChangeText={setId}
            autoCorrect={false}
            error={idError}
            autoCapitalize="characters"
            inputContainerStyle={styles.inputContainer}
            inputWrapperStyle={styles.itemSpacing}
            labelOffset={{
              x0: 0,
              y0: -7,
              x1: 0,
              y1: 2,
            }}
          />
        </View>

        <View style={styles.smallBottomSpacing}>
          <Text
            i18nKey="demo_business_information"
            weight="bold"
            align="left"
            size="h3"
          />
          <Text i18nKey="demo_business_information_label" align="left" />
          <InputWithTags
            label="certificate_nipt_label"
            value={nipt}
            onChangeText={setNipt}
            autoCorrect={false}
            error={niptError}
            autoCapitalize="characters"
            inputContainerStyle={styles.inputContainer}
            inputWrapperStyle={styles.itemSpacing}
            labelOffset={{
              x0: 0,
              y0: -7,
              x1: 0,
              y1: 2,
            }}
          />
          <InputWithTags
            label="demo_company_name"
            value={company}
            onChangeText={setCompany}
            autoCorrect={false}
            inputContainerStyle={styles.inputContainer}
            inputWrapperStyle={styles.itemSpacing}
            labelOffset={{
              x0: 0,
              y0: -7,
              x1: 0,
              y1: 2,
            }}
          />
          <Location
            coordinates={location?.coordinates}
            setAddress={setAddress}
            setCity={setCity}
            updateCoordinates={(coords) =>
              setLocation((prev) => {
                return { ...prev, coordinates: coords }
              })
            }
          />
          <InputWithTags
            label="demo_city_label"
            value={city}
            onChangeText={setCity}
            autoCorrect={false}
            inputContainerStyle={styles.inputContainer}
            inputWrapperStyle={styles.itemSpacing}
            labelOffset={{
              x0: 0,
              y0: -7,
              x1: 0,
              y1: 2,
            }}
          />
          <InputWithTags
            label="demo_address_label"
            value={address}
            onChangeText={setAddress}
            autoCorrect={false}
            inputContainerStyle={styles.inputContainer}
            inputWrapperStyle={styles.itemSpacing}
            labelOffset={{
              x0: 0,
              y0: -7,
              x1: 0,
              y1: 2,
            }}
          />
        </View>
      </ScrollView>
      <Button
        title="demo_send_button"
        disabled={disabled}
        backgroundColor={disabled ? colors.disabled : colors.accent}
        style={styles.sendButton}
        onPress={onNextPress}
      />
    </>
  )
}
Information.propTypes = {
  userPhone: PropTypes.string,
  setPhone: PropTypes.func,
  nipt: PropTypes.string,
  setNipt: PropTypes.func,
  personalId: PropTypes.string,
  setId: PropTypes.func,
  userEmail: PropTypes.string,
  setEmail: PropTypes.func,
  firstName: PropTypes.string,
  setFirstName: PropTypes.func,
  lastName: PropTypes.string,
  setLastName: PropTypes.func,
  city: PropTypes.string,
  setCity: PropTypes.func,
  company: PropTypes.string,
  setCompany: PropTypes.func,
  location: PropTypes.string,
  setLocation: PropTypes.func,
  address: PropTypes.string,
  setAddress: PropTypes.func,
  disabled: PropTypes.bool,
  onBackPress: PropTypes.func,
  onNextPress: PropTypes.func,
  niptError: PropTypes.bool,
  idError: PropTypes.bool,
}

function Confirmation(props) {
  const [styles] = useTheme(themedStyles)
  const {
    value,
    setValue,
    onPress,
    resend,
    disabled,
    userPhone,
    sendData,
    loading,
  } = props

  return (
    <>
      <Header
        title="header_request_demo"
        image={images.back}
        onPress={onPress}
      />
      <ScrollView
        showsVerticalScrollIndicator={false}
        style={styles.scrollView}>
        <View style={styles.bottomSpacing}>
          <Text
            i18nKey="demo_pin_verification_title"
            align="left"
            weight="bold"
            size="h3"
          />
          <Text
            i18nKey="demo_pin_verification_description"
            placeHolders={[`0${userPhone}`]}
            align="left"
          />
          <View style={styles.codeConfirm}>
            <Text
              i18nKey="certificate_enter_code_label"
              size="footnote"
              weight="light"
              align="center"
            />
            <InputPIN value={value} setValue={setValue} cellCount={5} />
            <Text
              i18nKey="certificate_resend_code_label"
              size="footnote"
              weight="light"
              color={colors.secondaryAccent}
              align="center"
              style={styles.underlinedText}
              onPress={resend}
            />
          </View>
        </View>
      </ScrollView>
      <Button
        title="certificate_button_next"
        disabled={disabled}
        backgroundColor={disabled ? colors.disabled : colors.accent}
        loader={loading}
        loaderComponent={<Loader size={32} source={animations.vfLoaderWhite} />}
        style={styles.nextButton}
        onPress={sendData}
      />
    </>
  )
}
Confirmation.propTypes = {
  getCellOnLayoutHandler: PropTypes.func,
  value: PropTypes.string,
  setValue: PropTypes.func,
  CELL_COUNT: PropTypes.number,
  onPress: PropTypes.func,
  resend: PropTypes.func,
  disabled: PropTypes.bool,
  userPhone: PropTypes.string,
  sendData: PropTypes.func,
  loading: PropTypes.bool,
}

const DemoSteps = {
  info: Information,
  confirm: Confirmation,
}

function RequestDemo({ navigation: { navigate, goBack } }) {
  const [styles] = useTheme(themedStyles)
  const [demoStatus, setDemoStatus] = useState(DEMO_STEPS.info)
  const DemoStep = DemoSteps[demoStatus]

  const [nipt, setNipt] = useState('')
  const [personalId, setId] = useState('')
  const [userEmail, setEmail] = useState('')
  const [userPhone, setPhone] = useState('')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [city, setCity] = useState('')
  const [company, setCompany] = useState('')
  const [location, setLocation] = useState({})
  const [address, setAddress] = useState('')
  const [loading, setLoading] = useState(false)
  const [disabled, setDisabled] = useState(false)
  const [niptError, setNError] = useState(false)
  const [idError, setIError] = useState(false)

  const [value, setValue] = useState('')

  const [modalContent, setModalContent] = useState({
    isVisible: false,
    title: '',
    icon: '',
    desc: '',
    primaryText: '',
    primaryAction: null,
  })
  const displayModal = (
    title = '',
    icon = '',
    desc = '',
    primaryText = '',
    primaryAction = null,
  ) => {
    setModalContent({
      isVisible: true,
      title: title,
      icon: icon,
      desc: desc,
      primaryText: primaryText,
      primaryAction: primaryAction,
    })
  }
  const dismissModal = () => {
    setModalContent({
      isVisible: false,
      title: '',
      icon: '',
      desc: '',
      primaryText: '',
      primaryAction: null,
    })
  }

  const userContent = {
    PersonalId: personalId,
    nipt: nipt,
    compName: company,
    city: city,
    location: address,
  }

  const body = {
    _links: null,
    id: [
      {
        value: 'New Partner SR',
        schemeID: '',
        schemeName: 'Customer Interaction ID',
        schemeAgencyName: 'Vodafone',
      },
    ],
    name: 'New Partner SR',
    desc: 'New Partner SR',
    type: 'eBusiness',
    typeName: 'Click-to-Call',
    category: [
      {
        value: 'Sherbim',
        listHierarchyId: '0',
        listName: 'Answered Retention',
        name: 'Reward',
      },
    ],
    status: 'open',
    statusName: 'open',
    version: '3-Medium',
    created: {
      value: '01/06/2018 14:50',
      format: 'DD/MM/YYYY HH24:MI',
    },
    createdBy: null,
    lastModified: {
      value: '01/06/2018 14:52',
      format: 'DD/MM/YYYY HH24:MI',
    },
    lastModifiedBy: null,
    validityPeriod: {
      fromDate: {
        dateString: null,
        date: '01/06/2018 14:50',
      },
      toDate: {
        dateString: null,
        date: '06/06/2018 14:50',
      },
    },
    lastActivityDate: null,
    details: {
      reason: {
        reasonCode: value,
        reasonText: '.Cust - Unsatisfied',
        category: null,
      },
      subReason: [],
    },
    relatedCustomerInteraction: null,
    roles: null,
    relatedParty: [
      {
        id: [
          {
            value: 'vodafoneagent',
            schemeID: null,
            schemeName: 'Related Party ID',
            schemeAgencyName: 'Vodafone',
          },
        ],
        name: 'Vodafone Party',
        desc: 'Njofto pergjegjesin',
        type: 'child',
        category: [
          {
            value: 'agent',
            listHierarchyId: '1',
          },
        ],
        status: 'active',
        role: 'caseowner/management',
        contactPoint: [
          {
            id: null,
            preferred: null,
            email: null,
            telephone: null,
            postal: {
              town: 'tirane',
              levelNr: 0,
            },
            sms: null,
            fax: null,
          },
        ],
        _links: null,
      },
    ],
    parts: {
      activity: null,
      event: [
        {
          eventImpact: null,
        },
      ],
      artefact: null,
      attachment: null,
      channel: {
        id: [
          {
            value: 'selfcare',
            schemeID: null,
            schemeName: 'Channel ID',
            schemeAgencyName: 'Vodafone',
          },
        ],
        name: 'MyVodafone',
        desc: 'MyVodafone',
        type: 'digital',
        category: [
          {
            value: 'Phone',
            listHierarchyId: '1',
          },
        ],
        status: 'Active',
        _links: null,
      },
      party: null,
      contactPoint: [
        {
          id: null,
          preferred: null,
          email: {
            fullAddress: userEmail,
          },
          telephone: null,
          postal: null,
          sms: null,
          fax: null,
        },
      ],
      customerAccount: [
        {
          id: [
            {
              value: `355${userPhone}`,
              schemeID: null,
              schemeName: 'Party ID',
              schemeAgencyName: 'Vodafone',
            },
          ],
          name: `${firstName} ${lastName}`,
          desc: '',
          type: null,
          category: null,
          status: null,
          _links: null,
        },
      ],
      note: [
        {
          desc: '',
          content: `${userContent}`,
        },
      ],
    },
  }

  const checkInputs = () => {
    if (
      nipt === '' ||
      personalId === '' ||
      userEmail === '' ||
      userPhone === '' ||
      firstName === '' ||
      lastName === '' ||
      city === '' ||
      company === '' ||
      address === ''
    ) {
      setDisabled(true)
    } else {
      const regex = /^[A-Z]\d{8}[A-Z]$/
      const niptRegex = regex.test(nipt)
      const idRegex = regex.test(personalId)

      if (niptRegex && idRegex) {
        setNError(false)
        setIError(false)
        setDisabled(false)
      } else {
        if (!niptRegex) {
          setNError(true)
        } else {
          setNError(false)
        }
        if (!idRegex) {
          setIError(true)
        } else {
          setIError(false)
        }
        setDisabled(true)
      }
    }
  }

  const checkInput = () => {
    if (value.length < 5) {
      setDisabled(true)
    } else {
      setDisabled(false)
    }
  }

  useEffect(() => {
    checkInputs()
  }, [
    nipt,
    personalId,
    userEmail,
    userPhone,
    firstName,
    lastName,
    city,
    company,
    address,
  ])

  useEffect(() => {
    checkInput()
  }, [value])

  const pinBody = {
    name: 'Generate Pin',
    desc: 'Generate Pin',
    type: 'pin',
    category: [
      {
        value: 'pin',
        listHierarchyId: 'Vodafone',
      },
    ],
    details: {
      lifespan: {
        value: '',
        unitCode: '',
      },
      requestType: 'pin',
    },
    roles: {
      requestor: {
        id: [
          {
            value: `355${userPhone}`,
            schemeName: 'Requestor ID',
            schemeAgencyName: 'Vodafone',
          },
        ],
        name: 'Syrja',
        desc: '',
        type: '',
        category: [
          {
            value: '',
            listHierarchyId: 'Vodafone',
          },
        ],
        status: '',
      },
    },
    parts: {
      specification: {
        id: [
          {
            schemeAgencyName: 'Vodafone',
            schemeName: 'Specification ID',
            value: '123',
          },
        ],
        name: 'Consumer Change Authentication Credential',
        desc: 'Consumer Change Authentication Credential',
        type: 'token',
        category: [
          {
            listHierarchyId: '1',
            value: 'token',
          },
        ],
        characteristicsValue: [
          {
            characteristicName: 'client_id',
            value: 'A4xthffk0IEhlRUFJxn8PJbc1OGHu0GW',
            creationTime: {
              value: '',
            },
            validityPeriod: {
              fromDate: {
                date: '',
              },
              toDate: {
                date: '',
              },
            },
          },
          {
            characteristicName: 'client_secret',
            value: 'gW7JF7sRr222l1ai',
            creationTime: {
              value: '',
            },
            validityPeriod: {
              fromDate: {
                date: '',
              },
              toDate: {
                date: '',
              },
            },
          },
        ],
      },
    },
  }

  const sendCode = () => {
    axios({
      method: 'POST',
      url:
        'https://eu2.api.vodafone.com/csm-api/securityTokenAPI/v2/generateSecurityToken',
      headers: {
        'vf-source': 'selfcare',
        'vf-operator': 'myvodafone',
        'vf-country-code': 'al',
        'Accept-Language': 'en',
        'Content-Type': 'application/json',
      },
      data: pinBody,
    }).then(function () {
      setDemoStatus(DEMO_STEPS.confirm)
      setDisabled(true)
      setValue('')
    })
  }

  const headers = {
    'X-Source-System': 'reward_sr',
    'X-Source-Operator': 'reward_sr',
    'X-Source-CountryCode': 'al',
    'Content-Type': 'application/json',
    'Accept-Language': 'al',
  }

  const sendData = () => {
    setLoading(true)
    const url =
      'https://api-mobile-ci-vfal.vodafone.com/public/customerInteractionAPI/v2.1/customerInteraction'
    return axios
      .post(url, body, {
        headers: headers,
      })
      .then((res) => {
        setLoading(false)
        if (res.status === 200) {
          displayModal(
            'demo_success_message',
            images.success,
            'demo_success_description',
            'demo_back_login_button',
            () => {
              dismissModal()
              navigate('InitialScreen')
            },
          )
        } else {
          displayModal(
            'certificate_error_label',
            images.error,
            'certificate_error_description',
            'certificate_button_try_again',
            () => {
              dismissModal()
              goBack()
            },
          )
        }
      })
      .catch((error) => {
        let message
        if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          displayModal(
            'certificate_error_label',
            images.error,
            'certificate_error_description',
            'certificate_button_try_again',
            () => {
              dismissModal()
              goBack()
            },
          )
          message = error.response.data.message
        } else {
          displayModal(
            'certificate_error_label',
            images.error,
            'certificate_error_description',
            'certificate_button_try_again',
            () => {
              dismissModal()
              goBack()
            },
          )
          message = 'Diçka nuk funksionoi. Ju lutem provoni perseri me vone.'
        }
        throw message
      })
  }

  return (
    <KeyboardAvoidingView
      behavior={Platform.select({
        ios: 'padding',
        android: undefined,
      })}
      style={styles.container}>
      <DemoStep
        userPhone={userPhone}
        setPhone={setPhone}
        nipt={nipt}
        setNipt={setNipt}
        niptError={niptError}
        idError={idError}
        personalId={personalId}
        setId={setId}
        userEmail={userEmail}
        setEmail={setEmail}
        firstName={firstName}
        setFirstName={setFirstName}
        lastName={lastName}
        setLastName={setLastName}
        city={city}
        setCity={setCity}
        company={company}
        setCompany={setCompany}
        location={location}
        setLocation={setLocation}
        address={address}
        setAddress={setAddress}
        disabled={disabled}
        onBackPress={() => {
          goBack()
          setDisabled(false)
        }}
        sendData={sendData}
        onNextPress={sendCode}
        loading={loading}
        demoStatus={demoStatus}
        setDemoStatus={setDemoStatus}
        resend={sendCode}
        value={value}
        setValue={setValue}
        onPress={() => setDemoStatus(DEMO_STEPS.info)}
      />
      <ErrorModal
        isVisible={modalContent.isVisible}
        icon={modalContent.icon}
        title={modalContent.title}
        description={modalContent.desc}
        primaryText={modalContent.primaryText}
        primaryAction={() => modalContent.primaryAction?.()}
        onBackButtonPress={() => { }}
        onBackdropPress={() => { }}
        onClosePress={() => modalContent.primaryAction?.()}
      />
    </KeyboardAvoidingView>
  )
}
RequestDemo.propTypes = {
  navigation: PropTypes.object,
}

export default RequestDemo
