import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { TouchableOpacity, View } from 'react-native'
import { useTheme } from 'react-native-themed-styles'

import Text from '../Text'

import Theme from '../../Themes/Theme'
import themedStyles from './SimpleType.Styles'

function SimpleType({ item, selected, onPress, isHorizontal, index, length, textAlign, textSize }) {
    const [styles] = useTheme(themedStyles)
    const { colors } = useContext(Theme)

    const isSelected = selected === item?.id
    return (
        <View style={styles.container}>
            <TouchableOpacity
                onPress={onPress}
                style={[
                    styles['item_' + (isHorizontal && index < length - 1)],
                    styles['itemSelected_' + isSelected],
                    styles['isDisabled_' + item?.disabled],
                    styles['text_' + textAlign],
                ]}
                activeOpacity={0.6}
                disabled={item?.disabled}
            >
                <Text
                    i18nKey={item?.label}
                    size={textSize}
                    align={textAlign}
                    color={item?.disabled && colors.white}
                    style={styles['itemTextSelected_' + isSelected]}
                />
            </TouchableOpacity>
        </View>
    )
}

SimpleType.defaultProps = {
    textAlign: 'center',
    size: 'footnote',
}

SimpleType.propTypes = {
    item: PropTypes.string,
    selected: PropTypes.object,
    onPress: PropTypes.func,
    isHorizontal: PropTypes.bool,
    index: PropTypes.number,
    length: PropTypes.number,
    textAlign: PropTypes.string,
    textSize: PropTypes.string,
}

export default SimpleType
