import { styleSheetFactory } from '../../../../../Themes/Colors'

const styles = styleSheetFactory(colors => ({
  content: {
    flexGrow: 1,
    paddingHorizontal: 16,
    paddingBottom: 24,
  },
  addCustomer: {
    height: 50,
    marginBottom: 5,
  },
  customerHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  removeBuyer: {
    borderRadius: 18,
    padding: 5,
    backgroundColor: colors.separatorBody,
    alignSelf: 'flex-end',
    alignItems: 'flex-end',
  },
  bankHeading: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 10,
  },
  bankDetailsWrapper: {
    backgroundColor: colors.white,
    padding: 10,
    borderRadius: 7,
    alignItems: 'flex-start',
    marginBottom: 10,
  },
  bankAccount: {
    marginBottom: 10,
  },
  horizontalMargin: { marginHorizontal: 0 },
  rightSpacing: { marginRight: 10 },
}))

const stateitemStyles = styleSheetFactory(colors => ({
  container: {
    paddingVertical: 7.5,
  },
  itemSelected_false: {
    flexDirection: 'row',
    marginHorizontal: 15,
    borderRadius: 10,
    paddingVertical: 10,
    paddingHorizontal: 15,
    backgroundColor: colors.white,
    alignItems: 'center',
    height: 60,
  },
  itemSelected_true: {
    flexDirection: 'row',
    marginHorizontal: 15,
    borderRadius: 10,
    paddingVertical: 10,
    paddingHorizontal: 15,
    backgroundColor: colors.white,
    alignItems: 'center',
    height: 60,
    borderWidth: 1,
    borderColor: colors.secondaryAccent,
  },
  itemTextSelected_false: {
    flexGrow: 1,
    flexShrink: 1,
  },
  itemTextSelected_true: {
    flexGrow: 1,
    flexShrink: 1,
  },
  separator: {
    width: 1,
    backgroundColor: colors.disabled,
    marginHorizontal: 15,
    height: 40,
  },
  isDisabled_true: {
    backgroundColor: colors.secondaryBackround,
  },
  itemCode: {
    minWidth: 30,
    maxWidth: '40%',
  },


}))

export { stateitemStyles }
export default styles
