import { gql } from '@apollo/client'

const GET_CASH_DRAWER_SHIFT_EVENT = gql`
  query retrieveCashDrawerShiftEvent(
    $cashRegisterId: String
    $eventId: String!
    ) {
    retrieveCashDrawerShiftEvent(cashRegisterId: $cashRegisterId, eventId: $eventId) {
        id
        FCDC
        createdAt
        description
        externalId
        eventType
        softCode
        businUnitCode
        eventMoney{
            amount
            currency
        }
        employee{
            email
            firstName
            lastName
            operatorCode
        }
        reason
        statementNumber
        status
        failMessage
    }
  }
`
 const SYNC_CASH_DRAWER_SHIFT_EVENTS = gql`
    mutation syncEvents($eventSyncObjects: [SyncObject]!, $locId: String, $cashRegisterId: String){
        syncCashDrawerShiftEvents(
            eventSyncObjects: $eventSyncObjects
            requestedLocationAndCashRegister: {
                locationId: $locId,
                cashRegisterId: $cashRegisterId
              }
            ){
            cursor
            events{
                id
                FCDC
            }
        }
    }
`
export { GET_CASH_DRAWER_SHIFT_EVENT, SYNC_CASH_DRAWER_SHIFT_EVENTS }
