/* eslint-disable react/prop-types */
import React, { useContext, forwardRef, useRef, useState } from 'react'
import { TextInput, Animated, View, TouchableOpacity } from 'react-native'
import { useTheme } from 'react-native-themed-styles'

import ContentManager from '../../Utils/ContentManager'
import Text from '../Text'

import Theme from '../../Themes/Theme'
import themedStyles from './AnimatedTextInput.Styles'

const inputPointerEvents = {
  true: 'auto',
  false: 'none',
}

function AnimatedTextInput(props, ref) {
  const [styles] = useTheme(themedStyles)
  const { colors } = useContext(Theme)

  const {
    label = 'Label',
    localize = true,
    placeHolders,
    translateOption,
    placeholderTextColor,
    value: initialValue,
    onChangeText,
    style,
    ...rest
  } = props

  const input = useRef(null)
  const scaleAnim = useRef(new Animated.Value(1)).current
  const labelAnim = useRef(new Animated.Value(1)).current
  const isFocused = input.current?.isFocused()
  const [value, setValue] = useState(initialValue)

  const scale = (direction) => {
    const animValue = direction === 'up' ? 1 : 0.9
    Animated.timing(scaleAnim, {
      toValue: animValue,
      duration: 150,
      useNativeDriver: true,
    }).start()
  }
  const moveLabel = (direction) => {
    const animValue = direction === 'up' ? -7 : 7
    Animated.timing(labelAnim, {
      toValue: animValue,
      duration: 300,
      useNativeDriver: true,
    }).start()
  }

  const placeholderKey = label + ''

  return (
    <Animated.View style={[
      styles.container,
      styles['containerActive_' + isFocused],
      {transform: [{ scale: scaleAnim }]},
      style,
    ]}>
      <TouchableOpacity style={styles.touchable}
        onPressIn={() => {
          scale('down')
        }}
        onPressOut={() => {
          input.current?.focus()
          moveLabel('up')
          scale('up')
        }}
      >
        <Animated.View style={[
          styles.label,
          {
            transform: [
              {
                scale: labelAnim.interpolate({
                  inputRange: [-7, 7],
                  outputRange: [0.8, 1],
                }),
              },
              { translateY: labelAnim },
            ],
          },
        ]}>
          <Text
            i18nKey={localize ? ContentManager.translate(placeholderKey, placeHolders, translateOption) : placeholderKey}
            color={placeholderTextColor || colors.primaryText}

          />
        </Animated.View>
        <View pointerEvents={inputPointerEvents[isFocused]} >
          <TextInput
            {...rest}
            ref={r => { input.current = r }}
            forwardedRef={ref}
            onChangeText={text => {
              setValue(text)
              onChangeText?.(text)
            }}
            onBlur={() => {
              if (!value) {
                moveLabel('down')
              }
            }}
            style={styles.inputText}
          />
        </View>

      </TouchableOpacity>
    </Animated.View>
  )
}

export default forwardRef(AnimatedTextInput)
