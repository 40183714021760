/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState, useRef } from 'react'
import { View } from 'react-native'
import LottieView from 'lottie-react-native'
import { useTheme } from 'react-native-themed-styles'
import { useQuery } from '@apollo/client'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import { Text, Button, Header } from '../../../../Components'
import images, { animations } from '../../../../Themes/Images'

import { filterOutCart } from '../../Helpers'
import { dismissPaymentError, displayPaymentError, supportInvoiceResetState, updatePaymentAttribute } from '../../Payment.Actions'
import { GET_WAREHOUSE_NOTE } from '../../Payment.Schema'
import { getSupportRegisteredId } from '../../Payment.Selectors'
import { printWtnReceipt, useConfig, useTemplateLanguage } from '../../../../Utils'
import { useLocationDetails } from '../../../../Utils/AuthDetails'

import { resetOrder } from '../../../Checkout/Checkout.Actions'

import themedStyles from './FifthStep.Styles'

function FifthStep({
  addListener,
  navigate,
  goBackCustom,
  dispatch,
  removeListener,
}) {

  const { switchLocation: { deviceId, locationId } } = useConfig()

  const [styles] = useTheme(themedStyles)
  const { NIPT, name, address } = useLocationDetails()
  const { translate } = useTemplateLanguage()
  const invoiceId = useSelector(getSupportRegisteredId)
  const dispatchAction = useDispatch()
  const anim = useRef()

  const clearOrder = () => dispatchAction(resetOrder())

  const setPaymentStatus = (value) => dispatchAction(updatePaymentAttribute('paymentStatus', value))

  const dismissError = () => dispatchAction(dismissPaymentError())

  const displayError = (
    title = '',
    desc = '',
    primaryText = '',
    primaryAction = null,
    secondaryText = '',
    secondaryAction = null,
    isDismissable = true,
  ) => {
    dispatchAction(displayPaymentError({
      isVisible: true,
      title: title,
      desc: desc,
      primaryText: primaryText,
      primaryAction: primaryAction,
      secondaryText: secondaryText,
      secondaryAction: secondaryAction,
      isDismissable: isDismissable,
    }))
  }

  const [retryPrint, setRetryPrint] = useState({
    active: false,
    receipt: {},
  })

  const { data } = useQuery(GET_WAREHOUSE_NOTE, {
    fetchPolicy: 'network-only',
    variables: {
      id: invoiceId,
      locId: locationId,
      cashRegisterId: deviceId,
    },
  })

  const print = (receipt) => {
    anim.current?.play()
    printWtnReceipt({ ...receipt, translate })
      .then(() => setPaymentStatus('success'))
      .catch(() => {
        displayError(
          'checkount_error_message',
          'printers_check_warning_message',
          'checkout_button_connect',
          () => {
            dismissError()
            navigate('PrinterSetUp')
            setRetryPrint({
              active: true,
              receipt,
            })
          },
          'checkout_button_skip',
          () => {
            dismissError()
            setPaymentStatus('success')
          },
          false,
        )
      })
  }

  useEffect(() => {
    if (data) {
      print({ ...data?.getWarehouseNote, NIPT, name, address })
    }
  }, [data])

  useEffect(() => {
    const clearInvoice = addListener('blur', () => {
      dispatchAction(supportInvoiceResetState())
      clearOrder()
      dispatch(filterOutCart)
    })
    return clearInvoice
  }, [])

  const onClose = () => {
    removeListener('beforeRemove')
    dispatchAction(supportInvoiceResetState())
    clearOrder()
    dispatch(filterOutCart)
    goBackCustom()
  }

  return (
    <>
      <Header
        title="checkout_payment_label"
        titlePlaceHolders={['']}
        image={images.close}
        onPress={onClose}
      />
      <View style={styles.container}>
        <LottieView
          ref={(ref) => {
            anim.current = ref
          }}
          source={animations.print}
          autoPlay
          loop={false}
          resizeMode="cover"
          style={styles.lottieView}
        />
        <View style={styles.buttonContainer}>
          {retryPrint.active ? (
            <Button
              title="checkout_print_title"
              variant="active"
              style={styles.printButton}
              onPress={() => {
                const { receipt } = retryPrint
                setRetryPrint({
                  active: false,
                  receipt: {},
                })
                print(receipt)
              }}
            />
          ) : (
            <Text
              size="h4"
              i18nKey="orders_printing_label"
              align="center"
            />
          )}
        </View>
      </View>
    </>
  )
}
FifthStep.propTypes = {
  addListener: PropTypes.func,
  navigate: PropTypes.func,
  goBackCustom: PropTypes.func,
  dispatch: PropTypes.func,
  removeListener: PropTypes.func,
}

export default FifthStep

