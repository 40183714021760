import { styleSheetFactory } from '../../Themes/Colors'

const styles = styleSheetFactory(colors => ({
  container: {
    backgroundColor: colors.white,
    padding: 15,
    marginVertical: 15,
    borderRadius: 7,
  },
  note: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 5,
  },
  icon: {
    marginRight: 5,
  },
  textInputStyle: {
    padding: 0,
    color: 'black',
  },
}))

export default styles
