// import { useColorScheme } from 'react-native'
import { registerThemes } from 'react-native-themed-styles'

const light = {
  drawerBackground: '#222222',
  drawerText: '#FFFFFF',
  drawerIcon: '#FFFFFF',
  drawerActiveBackgroundRGBA: (alpha = 1) => `rgba(255, 255, 255, ${alpha})`,
  drawerScreenSeparator: '#ECECEC',
  accent: '#E60000',
  accentRGBA: (alpha = 1) => `rgba(230, 0, 0, ${alpha})`,
  secondaryAccent: '#007C92',
  secondaryAccentRGBA: (alpha = 1) => `rgba(0, 124, 146, ${alpha})`,
  tertiaryAccent: '#E60000',
  background: '#F4F4F4',
  backgroundRGBA: (alpha = 1) => `rgba(244, 244, 244, ${alpha})`,
  qrBackGround: '#F4F4F4',
  qrMask: '#333333',
  borderColor: '#000000',
  secondaryBackround: '#DDDDDD',
  tertiaryBackground: '#FFFFFF',
  primaryText: '#333333',
  primaryTextRGBA: (alpha = 1) => `rgba(51, 51, 51, ${alpha})`,
  invertedText: '#FFFFFF',
  secondaryText: '#666666',
  tertiaryText: '#E60000',
  quartenaryText: '#E60000',
  inActiveText: '#666666',
  white: '#FFFFFF',
  whiteRGBA: (alpha = 1) => `rgba(255, 255, 255, ${alpha})`,
  black: '#000000',
  blackRGBA: (alpha = 1) => `rgba(0, 0, 0, ${alpha})`,
  transparent: 'transparent',
  separator: '#ECECEC',
  separatorBody: '#EBEBEB',
  error: '#FF0000',
  disabled: '#CCCCCC',
  tabAccent: '#E60000',
  tabAccentRGBA: (alpha = 1) => `rgba(230, 0, 0, ${alpha})`,
  calendarAccent: '#E60000',
  scanLineAccent: '#E60000',
  scanEdgeAccent: '#303030',
  calendarAccentRGBA: (alpha = 0.2) => `rgba(230, 0, 0, ${alpha})`,
  calendarText: '#E60000',
  // Icons
  primaryIcon: '#E60000',
  primaryIconRGBA: (alpha = 0.2) => `rgba(230, 0, 0, ${alpha})`,
  secondaryIcon: '#333333',
  tertiaryIcon: '#FFFFFF',
  quartenaryIcon: '#E60000',
  // Button
  buttonTextAccent: '#E60000',
  buttonSecondaryAccent: '#FFFFFF',
  buttonTertiaryAccent: '#666666',
  //Switch
  activeSwitch: 'rgba(0, 124, 146, 1)',
  inactiveSwitch: 'rgba(153, 153, 153, 1)',
  //Grey
  lightGray: 'rgba(204, 204, 204, 0.3)',
  lightGrey: '#ECECEC',
  midGrey: '#565656',
  placeholder: '#ACACAC',
  //yellow: '#FCD716',
  //orange: '#FBAC5B',
  pink: '#F15C9A',
  tomato: '#FF623E',
  sky: '#6CCDFE',
  //green: '#71BE35',

  //Color Picker
  orange: '#EB9700',
  purple: '#9C2AA0',
  green: '#009900',
  yellow: '#FECB00',
  red: '#E60000',
  blue: '#007C92',
  pending: '#333333',
  gray: '#333333',

  turquoise: '#06D4D4',
  turquoiseRGBA: (alpha = 1) => `rgba(6, 212, 212, ${alpha})`,
}
const dark = {
  drawerBackground: '#222222',
  drawerText: '#FFFFFF',
  drawerIcon: '#FFFFFF',
  drawerActiveBackgroundRGBA: (alpha = 1) => `rgba(255, 255, 255, ${alpha})`,
  drawerScreenSeparator: '#F4F4F4',
  accent: '#E60000',
  accentRGBA: (alpha = 1) => `rgba(230, 0, 0, ${alpha})`,
  secondaryAccent: '#00B0CA',
  secondaryAccentRGBA: (alpha = 1) => `rgba(0, 176, 202, ${alpha})`,
  tertiaryAccent: '#00B0CA',
  background: '#222222',
  backgroundRGBA: (alpha = 1) => `rgba(34, 34, 34, ${alpha})`,
  qrBackGround: '#F4F4F4',
  qrMask: '#333333',
  borderColor: '#FFFFFF',
  secondaryBackround: '#2A2A2A',
  tertiaryBackground: '#333333',
  primaryText: '#FFFFFF',
  primaryTextRGBA: (alpha = 1) => `rgba(255, 255, 255, ${alpha})`,
  invertedText: '#FFFFFF',
  secondaryText: '#F8F8F8',
  tertiaryText: '#333333',
  quartenaryText: '#00B0CA',
  inActiveText: '#CCCCCC',
  white: '#ffffff',
  whiteRGBA: (alpha = 1) => `rgba(255, 255, 255, ${alpha})`,
  black: '#000000',
  blackRGBA: (alpha = 1) => `rgba(0, 0, 0, ${alpha})`,
  transparent: 'transparent',
  separator: '#666666',
  separatorBody: '#EBEBEB',
  error: '#EB9700',
  disabled: '#666666',
  tabAccent: '#FFFFFF',
  tabAccentRGBA: (alpha = 1) => `rgba(255, 255, 255, ${alpha})`,
  calendarAccent: '#00B0CA',
  scanLineAccent: '#00B0CA',
  scanEdgeAccent: '#FFFFFF',
  calendarAccentRGBA: (alpha = 1) => `rgba(0, 176, 202, ${alpha})`,
  calendarText: '#FFFFFF',
  // Icons
  primaryIcon: '#FFFFFF',
  primaryIconRGBA: (alpha = 0) => `rgba(255, 255, 255, ${alpha})`,
  secondaryIcon: '#FFFFFF',
  tertiaryIcon: '#FFFFFF',
  quartenaryIcon: '#333333',
  // Button
  buttonTextAccent: '#E60000',
  buttonSecondaryAccent: '#FFFFFF',
  buttonTertiaryAccent: '#666666',
  //Switch
  activeSwitch: 'rgba(0, 124, 146, 1)',
  inactiveSwitch: 'rgba(153, 153, 153, 1)',
  //Grey
  lightGrey: '#ECECEC',
  midGrey: '#ACACAC',
  placeholder: '#ACACAC',
  //yellow: '#FCD716',
  //orange: '#FBAC5B',
  pink: '#F15C9A',
  tomato: '#FF623E',
  sky: '#6CCDFE',
  //green: '#71BE35',

  //Color Picker
  orange: '#EB9700',
  purple: '#9C2AA0',
  green: '#009900',
  yellow: '#FECB00',
  red: '#E60000',
  blue: '#007C92',
  pending: '#333333',
  gray: '#333333',

  turquoise: '#06D4D4',
  turquoiseRGBA: (alpha = 1) => `rgba(6, 212, 212, ${alpha})`,
}

const palete = {
  light: light,
  dark: dark,
}

const styleSheetFactory = registerThemes({ light, dark }, () => {
  // const colorScheme = useColorScheme()
  // return ['light', 'dark'].includes(colorScheme) ? colorScheme : 'light'
  return 'light'
})

export default palete.light
export { palete, styleSheetFactory }
