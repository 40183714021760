import { styleSheetFactory } from '../../Themes/Colors'

const styles = styleSheetFactory(colors => ({
  container: {
    flex: 1,
    backgroundColor: colors.background,
    marginHorizontal: 5,
  },
  fillRemaining: {
    paddingBottom: 20,
    paddingHorizontal: 15,
  },
  buttons: {
    flexDirection: 'row',
    marginBottom: 15,
    marginTop: 10,
    marginHorizontal: 15,
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  leftButton: {
    flex: 1,
    height: 50,
  },
  rightButton: {
    flex: 1,
    height: 50,
    marginRight: 15,
  },
  topContent: {
    paddingHorizontal: 15,
  },
  sectionTitle: {
    marginBottom: 10,
  },
  questionButtonsContainer: {
    flexDirection: 'row',
    flexGrow: 1,
    justifyContent: 'center',
    marginBottom: 20,
  },
  questionButton: {
    paddingHorizontal: 75,
    marginHorizontal: 10,
  },
  sectionNote: {
    marginTop: 5,
    marginBottom: 16,
  },
  currency: {
    marginBottom: 16,
  },
  rightButtonIcon: {
    marginLeft: 10,
  },
  endDateInput: {
    marginBottom: 0,
  },
  buttonTitle: {
    flexShrink: 1,
  },
  vehiclePlateHelper: {
    marginBottom: 20,
    marginTop: 5,
    textAlign: 'left',
    fontFamily: 'Vodafone Rg',
    fontSize: 12,
    color: colors.secondaryText,
  },
  vehiclePlateHelperItalic: {
    fontStyle: 'italic',
  },
  infoHeader: {
    marginTop: 10,
    marginBottom: 5,
  },
  buttonContainer: {
    // alignItems: 'center',
    paddingHorizontal: 15,
    paddingBottom: 15,

  },
  swiftList: {
    marginBottom: 0,
  },
  creditScoreHelper: {
    marginBottom: 20,
  },
  noteWrapper: {
    marginVertical: 20,
    padding: 15,
    backgroundColor: colors.white,
    borderRadius: 7,
  },
  separator: {
    height: 7,
    // flex: 1,
    backgroundColor: colors.background,
  },
  infoWrapper: {
    flexDirection: 'row',
  },
  icon: {
    marginRight:5,
  },
  button: {
    marginBottom: 25,
  },
  searchContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: colors.tertiaryBackground,
    height: 60,
    borderRadius: 7,
    // margin: 15,
    marginTop: 15,
    marginHorizontal: 5,
  },
  input: {
    flexGrow: 1,
    fontSize: 16,
    fontFamily: 'Vodafone Lt',
    color: colors.primaryText,
  },
  searchicon: {
    width: 20,
    height: 20,
    margin: 15,
    resizeMode: 'contain',
    tintColor: colors.secondaryIcon,
  },
  iconContainer: {
    padding: 10,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: colors.backgroundRGBA(0.5),
    borderRadius: 20,
    marginRight: 10,
    width: 30,
    height: 30,
  },
}))

const itemStyles = styleSheetFactory(colors => ({
  container: {
    height: 70,
    backgroundColor: colors.tertiaryBackground,
    justifyContent: 'center',
    overflow: 'hidden',
    marginVertical: 3,
    borderRadius: 7,
  },
  touchable: {
    flexGrow: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    position: 'relative',
  },
  infoContainer: {
    flexGrow: 1,
    flexShrink: 1,
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
    padding: 15,
  },
  descContainer: {
    flexGrow: 1,
    flexShrink: 1,
  },
  priceContainer: {
    flexDirection: 'column',
    alignItems: 'baseline',
    flexShrink: 0,
    marginLeft: 10,
  },
}))

export { itemStyles }
export default styles
