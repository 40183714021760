/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useContext } from 'react'
import { View, TouchableOpacity, Dimensions } from 'react-native'
import { useLazyQuery, useMutation } from '@apollo/client'
import ContentLoader, { Rect } from 'react-content-loader/native'
import PropTypes from 'prop-types'
import { useTheme } from 'react-native-themed-styles'
import { useSelector } from 'react-redux'

import {
  CheckBox,
  Button,
  Icon,
  SwipeList,
  Text,
  Modal,
  Loader,
  Header,
  ModalContent,
  TutorialNote,
} from '../../Components'

import { getProductCatalog } from '../ProductCatalog/ProductCatalog.Selectors'

import { getErrorMessage, useConfig } from '../../Utils'
import ContentManager from '../../Utils/ContentManager'

import { GET_DISCOUNTS, DELETE_DISCOUNT } from './Discounts.schema'

import Theme from '../../Themes/Theme'
import { images, animations } from '../../Themes'
import themedStyles, { discountStyles, hiddenItemStyles, separatorStyles } from './Discounts.Styles'

const { width } = Dimensions.get('screen')
const types = {
  FIXED_AMOUNT: 'FIXED_AMOUNT',
  VARIABLE_AMOUNT: 'VARIABLE_AMOUNT',
  FIXED_PERCENTAGE: 'FIXED_PERCENTAGE',
  VARIABLE_PERCENTAGE: 'VARIABLE_PERCENTAGE',
  VARIABLE: 'VARIABLE',
  FIXED: 'FIXED',
}

function Discount({ name, discountType, percentage, prices, onPress, isFirst, isLast }) {
  const [styles] = useTheme(discountStyles)

  const getItemPrice = (discPrices) => {
    let result
    if (discPrices?.length === 1) {
      result = `${discPrices?.[0]?.amount} ${discPrices?.[0]?.currency}`
    } else {
      result = `${discPrices?.length} ${ContentManager.translate('checkout_variable_price_label')}`
    }
    return result
  }

  return <View style={[styles.container, styles['firstItem_' + isFirst], styles['lastItem_' + isLast]]}>
    <TouchableOpacity onPress={onPress} style={styles.touchable}>
      <Text i18nKey={name} numberOfLines={2} style={styles.title} />
      {discountType?.match(types.VARIABLE)
        ? <Icon source={images.chevronRight} style={styles.rightIcon} />
        : discountType === types.FIXED_AMOUNT
          ? <Text i18nKey={getItemPrice(prices)} style={styles.desc} />
          : <Text i18nKey="discount_percentage" placeHolders={[percentage]} style={styles.desc} />
      }
    </TouchableOpacity>
  </View>
}

Discount.propTypes = {
  name: PropTypes.string,
  amountMoney: PropTypes.shape({
    amount: PropTypes.number,
    currency: PropTypes.string,
  }),
  prices: PropTypes.array,
  discountType: PropTypes.string,
  percentage: PropTypes.number,
  onPress: PropTypes.func,
  isFirst: PropTypes.bool,
  isLast: PropTypes.bool,
}

function SelectableDiscount({ name, percentage, amountMoney, prices, selected, onPress, isFirst, isLast }) {
  const [styles] = useTheme(discountStyles)

  const getItemPrice = (discPrices) => {
    let result
    if (discPrices?.length === 1) {
      result = `${discPrices?.[0]?.amount} ${discPrices?.[0]?.currency}`
    } else {
      result = `${discPrices?.length} ${ContentManager.translate('checkout_variable_price_label')}`
    }
    return result
  }

  return <View style={[styles.container, styles['firstItem_' + isFirst], styles['lastItem_' + isLast]]}>
    <TouchableOpacity onPress={onPress} style={styles.touchable}>
      <View style={styles.textContainer}>
        <Text i18nKey={name} numberOfLines={1} />
        {amountMoney?.amount
          ? <Text i18nKey="discount_amount" placeHolders={[]} />
          : prices?.length ? <Text i18nKey={getItemPrice(prices)} />
            : percentage
              ? <Text i18nKey="discount_percentage" placeHolders={[percentage]} />
              : null
        }
      </View>
      <View pointerEvents="none" style={styles.checkBox}>
        <CheckBox
          checked={selected}
          onValueChange={() => { }}
          tintColors={styles.checkBoxTinColors}
        />
      </View>
    </TouchableOpacity>
  </View>
}

SelectableDiscount.propTypes = {
  name: PropTypes.string,
  amountMoney: PropTypes.shape({
    amount: PropTypes.number,
    currency: PropTypes.string,
  }),
  prices: PropTypes.array,
  discountType: PropTypes.string,
  percentage: PropTypes.string,
  selected: PropTypes.bool,
  onPress: PropTypes.func,
  isFirst: PropTypes.bool,
  isLast: PropTypes.bool,
}

function DiscountPlaceholder({ isFirst, isLast }) {
  const [styles] = useTheme(themedStyles)

  return (
    <TouchableOpacity style={[styles.itemContainer, styles['fistItem_' + isFirst], styles['lastItem_' + isLast]]} disabled>
      <ContentLoader
        speed={2}
        width={width - 30}
        height={60}
        backgroundColor="#f3f3f3"
        foregroundColor="#fff"
      >
        <Rect x="15" y="21" rx="6" ry="6" width={`${(width - 30) * (3 / 5)}`} height="18" />
        <Rect x={`${width - 128}`} y="21" rx="6" ry="6" width="50" height="18" />
        <Rect x={`${width - 63}`} y="21" rx="3" ry="3" width="18" height="18" />
      </ContentLoader>
    </TouchableOpacity>
  )
}
DiscountPlaceholder.propTypes = {
  isFirst: PropTypes.bool,
  isLast: PropTypes.bool,
}

function HiddenItem({ needsConfirmation, onPress, onConfirm, onCancel, loading, isFirst, isLast }) {
  const [styles] = useTheme(hiddenItemStyles)
  const { colors } = useContext(Theme)

  return (
    <View style={[styles.wrapper, styles['firstItem_' + isFirst], styles['lastItem_' + isLast]]}>
      {loading
        ? <View style={styles.loader}>
          <Loader size={32} color={colors.white} source={animations.vfLoaderWhite} />
        </View>
        : needsConfirmation
          ? <TouchableOpacity
            style={styles.option}
            onPress={onPress}
          >
            <Icon source={images.delete} color={colors.white} size={24} />
          </TouchableOpacity>
          : <View style={styles.optionFull}>
            <TouchableOpacity
              style={styles.confirm}
              onPress={onConfirm}
            >
              <Icon source={images.delete} color={colors.white} size={24} />
              <Text i18nKey="opened_orders_delete_confirm" color={colors.white} style={styles.confirmText} />
            </TouchableOpacity>
            <TouchableOpacity
              style={styles.cancel}
              onPress={onCancel}
            >
              <Icon source={images.closeIcon} color={colors.white} size={20} />
            </TouchableOpacity>
          </View>
      }
    </View>
  )
}
HiddenItem.propTypes = {
  needsConfirmation: PropTypes.bool,
  onPress: PropTypes.func,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  loading: PropTypes.bool,
  isFirst: PropTypes.bool,
  isLast: PropTypes.bool,
}

function Separator() {
  const [styles] = useTheme(separatorStyles)
  return <View style={styles.container} />
}


function Discounts({
  route: {
    params: {
      selectable = false,
      preselectedDiscounts = [],
      updateSelection = () => { },
    } = {},
  } = {},
  navigation: {
    navigate,
    goBack,
  },
}) {
  const { switchLocation: { deviceId, locationId:locId } } = useConfig()

  const [styles] = useTheme(themedStyles)
  const { colors } = useContext(Theme)
  const [needsConfirmation, setNeedForConfirmation] = useState(true)
  const [discounts, setDiscounts] = useState([])
  const [itemWidth, setItemWidth] = useState(0)
  const [limit] = useState(30)
  const [searchText, setSearchText] = useState('')
  const [selectedDiscounts, setSelectedDiscounts] = useState(() => {
    const res = {}
    preselectedDiscounts.map(element => { res[element.id] = element })
    return res
  })

  const { locationId, businessName, address } = useSelector(getProductCatalog)


  const [listDiscounts, { loading, data, refetch, fetchMore }] = useLazyQuery(GET_DISCOUNTS, { fetchPolicy: 'network-only' })

  useEffect(() => {
    listDiscounts({
      variables: {
        locId: locId,
        cashRegisterId: deviceId,
        limit: limit,
        locationId: locationId ? locationId : null,
        filter: {
          name: 'name',
          value: searchText,
        },
      },
    })
  }, [])

  const [isErrorActive, setErrorActive] = useState(false)
  const [deleteDiscount, { loading: deleting, error: deleteError }] = useMutation(DELETE_DISCOUNT)

  const onSearch = (text) => {
    setSearchText(text)
    listDiscounts({
      variables: {
        locId: locId,
        cashRegisterId: deviceId,
        fetchPolicy: 'network-only',
        limit: limit,
        locationId: locationId ? locationId : null,
        filter: {
          name: 'name',
          value: text,
        },
      },
    })
  }

  useEffect(() => {
    const discs = data?.listDiscounts?.discounts
    if (discs) {
      setDiscounts(discs)
    }
  }, [data])

  const toggleDiscount = (id, item) => {
    setSelectedDiscounts(prev => {
      if (prev[id]) {
        delete prev[id]
      } else {
        prev[id] = item
      }
      return { ...prev }
    })
  }

  // const onItemPress = item => {
  //   const { id, name, discountType } = item
  //   if (discountType.match(types.VARIABLE) && !selectedDiscounts[id]){
  //     navigate('SetDiscountValue', {
  //       name,
  //       type: discountType,
  //       onSave: res => {
  //         goBack()
  //         if (discountType === types.VARIABLE_AMOUNT) {
  //           item.amountMoney = res
  //         } else {
  //           item.percentage = res
  //         }
  //         toggleDiscount(id, item)
  //       },
  //     })
  //   } else {
  //     toggleDiscount(id, item)
  //   }
  // }


  const renderSelectableItem = ({ item, index }) => (
    <SelectableDiscount
      {...item}
      selected={Boolean(selectedDiscounts[item.id])}
      onPress={() => toggleDiscount(item.id, item)}
      isFirst={index === 0}
      isLast={index === discounts.length - 1}
    />
  )

  const editDiscount = item => {
    if (item) {
      navigate('NewDiscount', { discountItem: item, onSave: () => refetch() })
    } else {
      navigate('NewDiscount', { onSave: () => refetch() })
    }
  }

  const renderNonSelectableItem = ({ item, index }) => (
    <Discount
      id={item?.id}
      {...item}
      onPress={() => editDiscount(item)}
      isFirst={index === 0}
      isLast={index === discounts.length - 1}
    />
  )

  const renderItem = selectable ? renderSelectableItem : renderNonSelectableItem

  const openRow = (rowMap, id) => {
    setNeedForConfirmation(false)
    rowMap[id].manuallySwipeRow(-itemWidth)
  }

  const closeRow = (rowMap, id) => {
    rowMap[id].closeRow()
    setNeedForConfirmation(true)
  }

  const deleteRow = (rowMap, id) => {
    deleteDiscount({
      variables: {
        locId: locId,
        cashRegisterId: deviceId,
        id: id,
        locationId: locationId ? locationId : null,
      },
    }).then(() => {
      // displayToast('discount_delete_success')
      closeRow(rowMap, id)
      refetch()
    }).catch(() => {
      setErrorActive(true)
      closeRow(rowMap, id)
    })
  }

  const renderHiddenItem = ({ item, index }, rowMap) => (
    <HiddenItem
      needsConfirmation={needsConfirmation}
      onPress={() => openRow(rowMap, item.id)}
      onConfirm={() => deleteRow(rowMap, item.id)}
      onCancel={() => closeRow(rowMap, item.id)}
      loading={deleting}
      isFirst={index === 0}
      isLast={index === discounts.length - 1}
    />
  )

  const onEndReached = () => {
    if (!data?.listDiscounts?.nextToken) {
      return
    }
    fetchMore({
      variables: {
        locId: locId,
        cashRegisterId: deviceId,
        limit: limit,
        locationId: locationId ? locationId : null,
        nextToken: data.listDiscounts.nextToken,
        filter: {
          name: 'name',
          value: searchText,
        },
      },
    })
  }

  return (
    <>
      <Header
        title="header_discounts"
        image={images.closeIcon}
        onPress={goBack}
        customBusinessName={businessName}
        customAddress={address}
      />
      <SwipeList
        searchValue={searchText}
        searchPlaceholder="discount_search_placeholder"
        onSearch={onSearch}
        data={discounts}
        renderItem={renderItem}
        renderHiddenItem={!selectable ? renderHiddenItem : undefined}
        disableLeftSwipe={selectable}
        disableRightSwipe
        rightOpenValue={-100}
        onRowClose={() => setNeedForConfirmation(true)}
        setItemWidth={setItemWidth}
        createNewLabel="discount_button_create"
        onCreatePress={() => editDiscount(undefined)}
        onEndReached={onEndReached}
        buttonVariant={selectable ? 'secondary' : 'active'}
        ItemSeparatorComponent={Separator}
        refreshing={loading}
        onEndReachedThreshold={0}
        onRefresh={() => refetch()}
        ListHeaderComponent={<TutorialNote url="https://www.youtube.com/watch?v=63JwhKLzXVs" />}
      />
      {Boolean(selectable) && (
        <Button
          iconStyle={styles.plusIcon}
          title={'discount_button_save'}
          titleStyle={styles.buttonTitle}
          style={styles.button}
          variant={'active'}
          onPress={() => {
            updateSelection(Object.values(selectedDiscounts))
            goBack()
          }}
        />
      )}
      <Modal
        isVisible={isErrorActive}
        onBackButtonPress={() => setErrorActive(false)}
        onBackdropPress={() => setErrorActive(false)}
        animationIn="fadeIn"
        animationOut="fadeOut"
      >
        <ModalContent onClose={() => setErrorActive(false)}>
          <Icon source={images.delete} style={styles.errorIcon} />
          <Text i18nKey="orders_error_label" color={colors.accent} size="h5" align="center" weight="bold" style={styles.errorTitle} />
          <Text i18nKey={getErrorMessage(deleteError)} align="center" />
        </ModalContent>
      </Modal>
    </>
  )
}

Discounts.propTypes = {
  route: PropTypes.object,
  navigation: PropTypes.object,
}

export default Discounts
