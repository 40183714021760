/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useRef, useState, useEffect } from 'react'
import { View, Animated, Easing, TouchableOpacity } from 'react-native'
import PropTypes from 'prop-types'

import { useTheme } from 'react-native-themed-styles'

import { useLazyQuery } from '@apollo/client'

import moment from 'moment'

import {
  Text,
  Icon,
  Loader,
} from '../../../../Components'
import { useUserDetails } from '../../../../Utils'


import images from '../../../../Themes/Images'
import Theme from '../../../../Themes/Theme'

import ContentManager from '../../../../Utils/ContentManager'

import { LIST_CASH_DRAWER_SHIFTS_EVENTS } from '../../UserDetails.Schema'

import { useLanguage } from '../../../../Utils/Language'
import { INF } from '../../../../Utils/Helpers'

import themedStyles from './PayIn.Styles'

const AnimatedTouchable = Animated.createAnimatedComponent(TouchableOpacity)

const eventTypeDesc = {
  title: {
    PAID_IN: 'drawer_paid_in_label',
    PAID_OUT: 'drawer_paid_out_label',
  },
  empty: {
    PAID_IN: 'drawer_no_paid_in',
    PAID_OUT: 'drawer_no_paid_out',
  },
  amountSign: {
    PAID_IN: '',
    PAID_OUT: '-',
  },
}

const LANGUAGES = {
  al: 'sq',
  en: 'en-gb',
}

function SectionItem({ sectionTitle }) {
  const [styles] = useTheme(themedStyles)
  const { colors } = useContext(Theme)

  return (
    <Text
      i18nKey={sectionTitle}
      size="footnote"
      color={colors.secondaryText}
      style={styles.sectionTitle}
    />
  )
}
SectionItem.propTypes = {
  sectionTitle: PropTypes.string,
}

function Item({ item, style }) {
  const [styles] = useTheme(themedStyles)
  const { colors } = useContext(Theme)
  const lang = useLanguage()

  const { translate } = ContentManager

  return (
    <View style={[styles.itemStyle, style, styles[`firstItem_${item.isFirst}`], styles[`lastItem_${item.isLast}`]]}>
      <View style={styles.itemContainer}>
        <View style={styles.listDescContainer}>
          <Text
            i18nKey={`${translate(eventTypeDesc.title[item.eventType])}`}
            numberOfLines={1} size="footnote"
          />
          <Text
            i18nKey={item?.FCDC || '...'}
            numberOfLines={1}
            weight="light"
            color={colors.secondaryText}
            size="footnote"
          />
          <Text i18nKey={item.description} weight="light" size="footnote" />
        </View>
        <View style={styles.priceContainer}>
          <Text i18nKey={moment(item?.createdAt).locale(LANGUAGES[lang]).format('hh:mm A')} weight="light" size="footnote" />
          <Text i18nKey={`${translate(eventTypeDesc.amountSign[item.eventType])}${INF.format(item?.eventMoney?.amount)} ${item?.eventMoney?.currency || 'Lekë'}`} />
        </View>
      </View>
    </View >
  )
}
Item.propTypes = {
  item: PropTypes.object,
  style: PropTypes.object,
}

const listItems = {
  section: SectionItem,
  element: Item,
}

function PayInList({
  id,
  eventType,
  ...rest
}) {
  const [styles] = useTheme(themedStyles)
  const { colors } = useContext(Theme)
  const lang = useLanguage()

  const { deviceId } = useUserDetails()

  const [items, setItems] = useState([])
  const [endLoading, setEndLoading] = useState(false)
  const [loading1, setLoading] = useState(true)
  const sectionDate = useRef('-')
  const listRef = useRef(null)
  const fabSize = useRef(new Animated.Value(0)).current
  const animate = val => {
    Animated.timing(fabSize, {
      toValue: val,
      duration: 370,
      useNativeDriver: true,
      easing: Easing.inOut(Easing.cubic),
    }).start()
  }

  const scrollToTop = () => {
    animate(0)
    listRef.current.scrollToOffset({
      offset: 0,
    })
  }

  const [list, { loading: listLoading, data: listData, refetch, fetchMore }] = useLazyQuery(
    LIST_CASH_DRAWER_SHIFTS_EVENTS,
    {
      fetchPolicy: 'network-only',
    },
  )

  const listShifts = () => {
    list({
      variables: {
        locationId: '123123',
        limit: 20,
        shiftId: 'drgf',
        eventType: eventType,
        employeeId: id,
        cashRegisterId: deviceId,
      },
    })
  }

  useEffect(() => {
    if (eventType) {
      sectionDate.current = null
      setLoading(true)
      setItems([])
      listShifts()
    }
  }, [eventType])

  const mapData = (dataa) => {
    const response = []
    let isFirst = false, isLast = false
    dataa?.map((item, index) => {
      isFirst = false
      isLast = false
      const date = moment(item.createdAt).locale(LANGUAGES[lang]).format('MMM DD, YYYY')
      if (date !== sectionDate.current) {
        if (response?.[response.length - 1]) {
          response[response.length - 1].isLast = true
        }
        sectionDate.current = date
        response.push({
          id: date,
          sectionTitle: date,
          type: 'section',
        })
      }

      if (response?.[response.length - 1]?.type === 'section') {
        isFirst = true
        isLast = false
      }

      response.push({
        id: item.id,
        type: 'element',
        FCDC: item.FCDC,
        createdAt: item.createdAt,
        description: item.description,
        eventMoney: item.eventMoney,
        eventType: item.eventType,
        isFirst: isFirst,
        isLast: isLast,
      })
    })
    if (response?.[response.length - 1]) {
      response[response.length - 1].isLast = true
    }
    return response
  }

  useEffect(() => {
    if (listData) {
      const response = mapData(listData?.listCashDrawerShiftEvents?.events)
      setItems(response)
      setLoading(false)
      setEndLoading(false)
    }
  }, [listData])

  const onEndReached = () => {
    if (listData?.listCashDrawerShiftEvents?.cursor === null) {
      return
    }
    setEndLoading(true)
    fetchMore({
      query: LIST_CASH_DRAWER_SHIFTS_EVENTS,
      variables: {
        locationId: '123123',
        shiftId: 'drgf',
        cursor: listData?.listCashDrawerShiftEvents?.cursor,
        limit: 20,
        eventType: eventType,
        cashRegisterId: deviceId,
      },
    })
  }


  return (
    <>
      <Animated.FlatList
        showsVerticalScrollIndicator={false}
        keyExtractor={(item, index) => 'row-' + index}
        ref={(ref) => (listRef.current = ref)}
        onMomentumScrollEnd={(event) => {
          if (event.nativeEvent.contentOffset.y > 800) {
            animate(1)
          } else {
            animate(0)
          }
        }}
        data={items}
        style={styles.listStyle}
        contentContainerStyle={styles.listContainerStyle}
        renderItem={({ item, index }) => {
          const ListItem = listItems[item.type]
          return <ListItem
            sectionTitle={item.sectionTitle}
            key={'row-' + index}
            item={item}
          />
        }}
        ListFooterComponent={listLoading || endLoading ? <Loader size={32} /> : null}
        ListEmptyComponent={listLoading || loading1 ? null : <Text i18nKey={eventTypeDesc.empty[eventType]} style={styles.emptyComponent} />}
        refreshing={false}
        onRefresh={() => refetch()}
        onEndReached={onEndReached}
        {...rest}
      />
      <AnimatedTouchable
        onPress={scrollToTop}
        activeOpacity={0.5}
        style={[
          styles.fab,
          {
            opacity: fabSize,
            transform: [{ scale: fabSize }],
          },
        ]}>
        <Icon
          source={images.arrow_right}
          size={20}
          color={colors.tertiaryIcon}
          style={styles.fabIcon}
        />
      </AnimatedTouchable>
    </>
  )
}
PayInList.propTypes = {
  id: PropTypes.string,
  eventType: PropTypes.string,
}

export default PayInList
