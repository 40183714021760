import { BluetoothEscposPrinter } from 'react-native-bluetooth-escpos-printer'
import moment from 'moment'

const printWtnReceipt = async ({
  fwtnic,
  qrCode,
  type,
  transaction,
  issueDateTime,
  operatorCode,
  softCode,
  wtnOrdNum,
  wtnNum,
  vehOwnership,
  vehPlates,
  NIPT,
  name,
  address,
  businUnitCode,
  translate,
}) => {

  const printerTranslate = (val) => {
    return translate(val, true)
  }

  try {
    await BluetoothEscposPrinter.printerInit()
    await BluetoothEscposPrinter.printerAlign(
      BluetoothEscposPrinter.ALIGN.CENTER,
    )
    await BluetoothEscposPrinter.setBlob(0)
    await BluetoothEscposPrinter.printText(`${name}\r\n`, {
      fonttype: 1,
      widthtimes: 1,
      heigthtimes: 1,
    })
    await BluetoothEscposPrinter.printerAlign(
      BluetoothEscposPrinter.ALIGN.CENTER,
    )
    await BluetoothEscposPrinter.printText(`${address}\n`, {})
    await BluetoothEscposPrinter.printerAlign(
      BluetoothEscposPrinter.ALIGN.CENTER,
    )
    await BluetoothEscposPrinter.printText(`${printerTranslate('receipt_nipt_label')}: ${NIPT}\n`, {})
    await BluetoothEscposPrinter.printerAlign(
      BluetoothEscposPrinter.ALIGN.CENTER,
    )
    await BluetoothEscposPrinter.printText(`${wtnNum}\n\n`, {})
    await BluetoothEscposPrinter.printText(
      '--------------------------------\r\n',
      {},
    )
    await BluetoothEscposPrinter.printerAlign(
      BluetoothEscposPrinter.ALIGN.CENTER,
    )
    await BluetoothEscposPrinter.printQRCode(
      qrCode,
      200,
      BluetoothEscposPrinter.ERROR_CORRECTION.L,
    )
    await BluetoothEscposPrinter.printerAlign(
      BluetoothEscposPrinter.ALIGN.CENTER,
    )
    await BluetoothEscposPrinter.printText(
      `${printerTranslate('wtn_receipt_info')}\r\n`,
      {
        widthtimes: 1,
      },
    )
    await BluetoothEscposPrinter.printerAlign(
      BluetoothEscposPrinter.ALIGN.LEFT,
    )
    await BluetoothEscposPrinter.printText(
      `${printerTranslate('wtn_fwtnic_label')}: ${fwtnic}\r\n`,
      {
        codepage: 0,
        widthtimes: 0,
        heigthtimes: 0,
        fonttype: 5,
      },
    )
    await BluetoothEscposPrinter.printText(
      `${printerTranslate('e_invoice_bussines_unit_code_label')}: ${businUnitCode}\r\n`,
      {
        codepage: 0,
        widthtimes: 0,
        heigthtimes: 0,
        fonttype: 5,
      },
    )
    await BluetoothEscposPrinter.printText(
      `${printerTranslate('receipt_operator_code')}: ${operatorCode}\r\n`,
      {
        codepage: 0,
        widthtimes: 0,
        heigthtimes: 0,
        fonttype: 5,
      },
    )
    await BluetoothEscposPrinter.printText(
      `${printerTranslate('receipt_software_code')}: ${softCode}\r\n`,
      {
        codepage: 0,
        widthtimes: 0,
        heigthtimes: 0,
        fonttype: 5,
      },
    )
    await BluetoothEscposPrinter.printText(
      `${printerTranslate('receipt_number_label')}: ${wtnOrdNum}\r\n`,
      {
        codepage: 0,
        widthtimes: 0,
        heigthtimes: 0,
        fonttype: 5,
      },
    )
    await BluetoothEscposPrinter.printText(
      moment(issueDateTime).format(`[${printerTranslate('receipt_date_time_label')}: ]DD-MM-YY HH:mm`) + '\r\n',
      {
        codepage: 0,
        widthtimes: 0,
        heigthtimes: 0,
        fonttype: 7,
      },
    )
    await BluetoothEscposPrinter.printText(
      `${printerTranslate('wtn_invoice_type')}: ${type}\r\n`,
      {
        codepage: 0,
        widthtimes: 0,
        heigthtimes: 0,
        fonttype: 5,
      },
    )
    await BluetoothEscposPrinter.printText(
      `${printerTranslate('wtn_transaction_type')}: ${transaction}\n\n`,
      {
        codepage: 0,
        widthtimes: 0,
        heigthtimes: 0,
        fonttype: 5,
      },
    )
    await BluetoothEscposPrinter.printText(
      '--------------------------------\r\n',
      {},
    )
    await BluetoothEscposPrinter.printerAlign(
      BluetoothEscposPrinter.ALIGN.LEFT,
    )
    await BluetoothEscposPrinter.printText(
      `${printerTranslate('wtn_information_label_1')}\n`,
      {
        widthtimes: 1,
      },
    )
    await BluetoothEscposPrinter.printerAlign(
      BluetoothEscposPrinter.ALIGN.LEFT,
    )
    await BluetoothEscposPrinter.printText(
      `${printerTranslate('wtn_information_label_2')}\r\n`,
      {
        widthtimes: 1,
      },
    )
    await BluetoothEscposPrinter.printText(
      `${printerTranslate('wtn_owner_veh')}: ${vehOwnership}\r\n`,
      {
        codepage: 0,
        widthtimes: 0,
        heigthtimes: 0,
        fonttype: 5,
      },
    )
    await BluetoothEscposPrinter.printText(
      `${printerTranslate('wtn_plate_label')}: ${vehPlates}\n\n`,
      {
        codepage: 0,
        widthtimes: 0,
        heigthtimes: 0,
        fonttype: 5,
      },
    )
    await BluetoothEscposPrinter.printerAlign(
      BluetoothEscposPrinter.ALIGN.CENTER,
    )
    await BluetoothEscposPrinter.setBlob(1)
    await BluetoothEscposPrinter.printText('\n\n\n\n', {})
    return
  } catch (e) {
    throw e
  }
}

export default printWtnReceipt
