import { styleSheetFactory } from '../../Themes/Colors'

const styles = styleSheetFactory(colors => ({
  container: {
    flex: 1,
    backgroundColor: colors.background,
  },
  subcontainer: {
    paddingHorizontal: 15,
  },
  header: {
    height: 56,
    alignItems: 'center',
    flexDirection: 'row',
  },
  headerIcon: {
    marginLeft: 15,
    marginRight: 20,
  },
  sectionText: {
    marginTop: 15,
  },
  inputRow: {
    marginTop: 15,
  },
  actionButton: {
    height: 50,
    marginVertical: 30,
  },
  taxContainer: {
    backgroundColor: colors.tertiaryBackground,
    flexDirection: 'row',
    alignItems: 'center',
    height: 60,
    marginVertical: 15,
    paddingHorizontal: 15,
    borderRadius: 7,
  },
  taxDetailsGrow: {
    flexGrow: 1,
    flexShrink: 1,
    alignItems: 'center',
    flexDirection: 'row',
  },
  taxGrow: {
    flexGrow: 1,
    flexShrink: 1,
    alignItems: 'flex-end',
    justifyContent: 'center',
  },
  taxNoShrink: {
    flexShrink: 0,
    maxWidth: '50%',
    flexWrap: 'wrap',
  },
  saveItemContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  unitComponentStyle: {
    marginVertical: 15,
    marginBottom: 0,
  },
}))

export default styles
