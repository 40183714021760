import React, { useContext } from 'react'
import { View, Dimensions } from 'react-native'
import PropTypes from 'prop-types'
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs'
import { useTheme } from 'react-native-themed-styles'

import Theme from '../../Themes/Theme'

import themedStyles from './Tabs.Styles'

const Tab = createMaterialTopTabNavigator()

const initialLayout = {
    height: 0,
    width: Dimensions.get('window').width,
}
function Tabs(props) {
    const [styles] = useTheme(themedStyles)
    const { colors } = useContext(Theme)
    const {
        data,
        loading,
        sectionList,
        screenOptions,
        style,
        tabContainerStyle,
        routeContainerStyle,
        contentContainerStyle,
        listStyle,
        children,
        ...rest
    } = props

    const routePlaceholder = () => (
        <View style={styles.routePlaceholder} />
    )

    return (
        <Tab.Navigator
            initialLayout={initialLayout}
            style={[styles.tabView, style]}
            sceneContainerStyle={[styles.scene, routeContainerStyle]}
            screenOptions={{
                lazy: true,
                lazyPreloadDistance: 1,
                tabBarLabelStyle: styles.label,
                tabBarActiveTintColor: colors.accent,
                tabBarInactiveTintColor: colors.inActiveText,
                tabBarIndicatorStyle: styles.indicatorStyle,
                tabBarPressColor: colors.drawerScreenSeparator,
                tabBarPressOpacity: 0.4,
                tabBarStyle: [styles.tabContainerStyle, tabContainerStyle],
                tabBarAllowFontScaling: false,
                lazyPlaceholder: routePlaceholder,
                ...screenOptions,
            }}
            {...rest}
        >
            {children}
            {/* {data.map((item, index) => (
                <Tab.Screen key={index} name={item.title}>
                    {props => (
                        <Route
                            {...props}
                            data={item.data}
                            sectionList={sectionList}
                            onRefresh={item.onRefresh}
                            refreshing={item.refreshing}
                            onItemPress={item.onItemPress}
                            loading={loading[index]}
                            listHeaderComponent={item.listHeaderComponent}
                            listFooterComponent={item.listFooterComponent}
                            listEmptyComponent={item.listEmptyComponent}
                            sectionSeparatorComponent={item.sectionSeparatorComponent}
                            onEndReached={item.onEndReached}
                            style={contentContainerStyle}
                            listStyle={listStyle}
                        />
                    )}
                </Tab.Screen>
            ))} */}
        </Tab.Navigator>
    )
}

Tabs.defaultProps = {
    data: [],
    loading: [],
    onEndReached: () => { },
    screenOptions: {},
    sectionList: true,
}

Tabs.propTypes = {
    data: PropTypes.array,
    loading: PropTypes.array,
    sectionList: PropTypes.bool,
    screenOptions: PropTypes.object,
    style: PropTypes.object,
    tabContainerStyle: PropTypes.object,
    routeContainerStyle: PropTypes.object,
    contentContainerStyle: PropTypes.object,
    listStyle: PropTypes.object,
    children: PropTypes.node,
}

export default Tabs
