import { StyleSheet } from 'react-native'

import colors from '../../Themes/Colors'

export default StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.background,
  },
  arrow: {
    marginLeft: 12,
    resizeMode: 'contain',
  },
  itemList: {
    marginVertical: 12,
    padding: 5,
    borderRadius: 7,
    overflow: 'hidden',
  },
})
