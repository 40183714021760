import React, { useRef, useContext } from 'react'
import {
  View,
  FlatList,
  TouchableOpacity,
  ImageBackground,
  Animated,
  Easing,
} from 'react-native'
import { useTheme } from 'react-native-themed-styles'
import { useSelector } from 'react-redux'
import Color from 'color'
import PropTypes from 'prop-types'

import { Icon, Text } from '../../../../../../Components'
import { INF } from '../../../../../../Utils/Helpers'

import Theme from '../../../../../../Themes/Theme'
import images from '../../../../../../Themes/Images'

import { getLineItems } from '../../../../Payment.Selectors'

import themedStyles, { itemStyles } from './Items.Styles'


const AnimatedTouchable = Animated.createAnimatedComponent(TouchableOpacity)

const getAbreviation = (name = '') => name?.match(/\b(\w{1})/g)?.slice(0, 2)?.join('')?.toUpperCase() || ''
const getFadedColor = (color = 'transparent', fadeValue = 0.65) => Color(color || 'transparent').fade(fadeValue).toString()

// @TODO Check props and implementation based on props
function ImageOrAbreviation({
  name,
  imageUrl,
  labelColor, // @TODO Please check.
}) {
  const [styles] = useTheme(itemStyles)

  if (imageUrl) {
    return (
      <ImageBackground
        source={{ uri: imageUrl }}
        style={styles.listImageContainer}
      />
    )
  }

  return (
    <View
      style={[
        styles.listImageContainer,
        { backgroundColor: getFadedColor(labelColor) },
      ]}>
      <Text
        size="h5"
        color={labelColor}
        i18nKey={getAbreviation(name)}
      />
    </View>
  )
}
ImageOrAbreviation.propTypes = {
  name: PropTypes.string,
  imageUrl: PropTypes.string,
  labelColor: PropTypes.string,
}
function Item({
  quantity,
  totalMoney,
  imageUrl,
  name,
  description,
  labelColor,
  isFirst,
  isLast,
  taxCategory,
}) {
  const [styles] = useTheme(itemStyles)
  const { colors } = useContext(Theme)

  return (
    <View style={[styles.itemContainer, isFirst && styles.listFirst, isLast && styles.listLast]}>
      <ImageOrAbreviation
        name={name}
        imageUrl={imageUrl}
        labelColor={labelColor}
      />
      <View style={styles.listInfoContainer}>
        <View style={styles.listDescContainer}>
          <Text i18nKey={name} numberOfLines={1} />
          <Text
            i18nKey="cart_product_price_details"
            placeHolders={[
              quantity,
              INF.format((totalMoney?.amount || 0) / quantity),
              totalMoney?.currency,
            ]}
            weight="light"
            color={colors.secondaryText}
            size="footnote"
            numberOfLines={1}
          />
          <Text
            i18nKey={description}
            weight="light"
            color={colors.secondaryText}
            size="footnote"
          />
        </View>
        <View style={styles.listPriceContainer}>
          <Text
            i18nKey="checkout_price_currency_value"
            placeHolders={[
              INF.format(totalMoney?.amount || 0),
              totalMoney?.currency,
            ]}
          />
          <Text
            size="footnote"
            weight="light"
            align="right"
            i18nKey={taxCategory}
          />
        </View>
      </View>
    </View>
  )
}
Item.propTypes = {
  quantity: PropTypes.number,
  totalMoney: PropTypes.shape({
    amount: PropTypes.number,
    currency: PropTypes.string,
  }),
  name: PropTypes.string,
  description: PropTypes.string,
  imageUrl: PropTypes.string,
  labelColor: PropTypes.string,
  isFirst: PropTypes.bool,
  isLast: PropTypes.bool,
  taxCategory: PropTypes.string,
}


function Items() {
  const [styles] = useTheme(themedStyles)
  const { colors } = useContext(Theme)
  const { invoiceLine: data = [] } = useSelector(getLineItems)

  const listRef = useRef(null)
  const fabSize = useRef(new Animated.Value(0)).current
  const animate = (value) => {
    Animated.timing(fabSize, {
      toValue: value,
      duration: 370,
      useNativeDriver: true,
      easing: Easing.inOut(Easing.cubic),
    }).start()
  }
  const scrollToTop = () => {
    animate(0)
    listRef.current.scrollToOffset({
      offset: 0,
    })
  }

  const onMomentumScrollEnd = (event) => {
    if (event.nativeEvent.contentOffset.y > 800) {
      animate(1)
    } else {
      animate(0)
    }
  }
  const getItemLayout = (_, index) => ({
    length: 76,
    offset: 76 * index,
    index,
  })
  const keyExtractor = (_, index) => 'row-' + index
  const renderItem = ({ item, index }) => (
    <Item id={item?.id} {...item} isFirst={index === 0} isLast={index === data.length - 1} />
  )
  const ItemSeparatorComponent = () => (
    <View style={styles.separatorContainer} >
      <View style={styles.separator} />
    </View>
  )

  const mapData = (unmappedData) => {
    const response = []
    unmappedData.map((item) => {
      const itemDetails = item?.item
      const taxCategory = itemDetails?.taxCategory
      let description = ''
      const allowance = item?.allowances
      allowance.map((el, ind) => {
        const amount = el?.amount
        if (ind === 0) {
          description = `${el.chargeReason} (${amount?.amount} ${amount?.currency})`
        } else {
          description = `${description}\n${el.chargeReason} (${amount?.amount} ${amount?.currency})`
        }
      })
      response.push({
        id: item?.id,
        name: itemDetails?.name,
        imageUrl: itemDetails?.description,
        description: description,
        totalMoney: item?.lineExtension,
        quantity: item?.quantity,
        taxCategory: `${taxCategory?.id}-${taxCategory?.taxSchema} ${taxCategory?.percent} %`,
      })
    })
    return response
  }

  return (
    <>
      <FlatList
        listViewRef={(ref) => {
          listRef.current = ref
        }}
        onMomentumScrollEnd={onMomentumScrollEnd}
        contentContainerStyle={styles.content}
        keyExtractor={keyExtractor}
        getItemLayout={getItemLayout}
        data={mapData(data)}
        showsVerticalScrollIndicator={true}
        renderItem={renderItem}
        ItemSeparatorComponent={ItemSeparatorComponent}
      />
      <AnimatedTouchable
        onPress={scrollToTop}
        activeOpacity={0.5}
        style={[
          styles.fab,
          {
            opacity: fabSize,
            transform: [{ scale: fabSize }],
          },
        ]}>
        <Icon
          source={images.arrow_right}
          size={20}
          color={colors.tertiaryIcon}
          style={styles.fabIcon}
        />
      </AnimatedTouchable>
    </>
  )
}

export default Items

