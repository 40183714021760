import {StyleSheet} from 'react-native'

import colors from '../../Themes/Colors'

export default StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.background,
  },
  scrollContainer: {
    flex: 1,
  },
  imagesColorContainer: {
    alignSelf: 'center',
    marginTop: 25,
    backgroundColor: colors.white,
    borderRadius: 7,
    width: 132,
    height: 93,
    padding: 7.5,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  color: {
    width: 58.5,
    height: 39,
    justifyContent: 'center',
    alignItems: 'center',
  },
  colorIcon: {
    width: 35,
    height: 35,
    resizeMode: 'contain',
  },
  inputContainer: {
    marginHorizontal: 15,
    marginTop: 10,
  },


  image: {
    width: 58.5,
    height: 78,
    justifyContent: 'center',
    alignItems: 'center',
  },

  galleryIcon: {
    width: 35,
    height: 35,
    resizeMode: 'contain',
    tintColor: colors.white,
  },
  seperator: {
    height: 78,
    width: 1.5,
    backgroundColor: colors.white,
  },


  nameInput: {
    fontSize: 16,
    fontFamily: 'Vodafone Rg',
    padding: 0,
    paddingBottom: 3.5,
    flexGrow: 1,
  },

  categoryContainer: {
    height: 70,
    backgroundColor: colors.white,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginHorizontal: 15,
    marginTop: 10,
    borderRadius: 7,
    paddingHorizontal: 17,
    paddingVertical: 12,
  },
  category: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  primaryButton: {
    marginTop: 15,
  },
  modalContainer: {
    backgroundColor: colors.white,
    alignSelf: 'center',
    paddingBottom: 30,
    borderRadius: 6,
  },
  modalContent: {
    paddingHorizontal: 40,
    marginTop: 5,
    marginBottom: 30,
  },
  modalIcon: {
    alignSelf: 'center',
    marginBottom: 5,
  },
  modalTitle: {
    marginVertical: 8,
  },
  modalButton: {
    marginTop: 15,
    marginHorizontal: 15,
  },
})
