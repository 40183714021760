/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext, useState, useEffect } from 'react'
import { Platform } from 'react-native'
import Big from 'big.js'
import PropTypes from 'prop-types'
import moment from 'moment'
import { gql, useLazyQuery } from '@apollo/client'
import { BluetoothEscposPrinter } from 'react-native-bluetooth-escpos-printer'

import { useConfig } from './Config'

const LANGUAGES = {
  al: 'sq',
  en: 'en-gb',
}

const ID_TYPES = {
  NUIS: 'receipt_nipt_label',
  ID: 'pay_in_out_id',
  PASS: 'customer_passport_number_label',
  VAT: 'customer_vat_number_label',
  TAX: 'customer_tax_number_label',
  SOC: 'customer_soc_number_label',
}

const PAYMENT_METHOD = {
  BANKNOTE: 'payment_banknote_description',
  CARD: 'payment_card_description',
  CHECK: 'payment_check_description',
  SVOUCHER: 'payment_voucher_description',
  COMPANY: 'payment_company_description',
  ORDER: 'payment_order_description',
  ACCOUNT: 'payment_account_description',
  FACTORING: 'payment_factoring_description',
  COMPENSATION: 'payment_compensation_description',
  TRANSFER: 'payment_transfer_description',
  WAIVER: 'payment_waiver_description',
  KIND: 'payment_kind_description',
  OTHER: 'payment_other_description',
}
moment.locale(LANGUAGES.al)

const OrderCount = createContext()
const CountOrders = createContext()

const LIST_SAVED_ORDERS = gql`
  query listOrder($locationId: String!, $cashRegisterId: String) {
    listOrders(
      listOrdersInput: {locationId: $locationId, status: SAVE} 
      requestedLocationAndCashRegister: {
      locationId: $locationId,
      cashRegisterId: $cashRegisterId
    }  
    ) {
      orders {
        id
      }
    }
  }
`

function OrderCountProvider({ children }) {
  const { switchLocation: { deviceId, locationId:id } } = useConfig()



  const [countSavedOrders, { data: savedOrders }] = useLazyQuery(
    LIST_SAVED_ORDERS,
    {
      fetchPolicy: 'network-only',
      variables: {
        locationId: id,
        cashRegisterId: deviceId,
        limit: 21,
      },
    },
  )

  const [savedOrdersCount, setSavedOrdersCount] = useState(0)

  useEffect(() => {
    if (id) {
      countSavedOrders()
    }
  }, [id])

  useEffect(() => {
    const count = savedOrders?.listOrders?.orders?.length
    if (Number.isInteger(count) && count >= 0) {
      setSavedOrdersCount(count)
    }
  }, [savedOrders])

  return (
    <OrderCount.Provider value={savedOrdersCount}>
      <CountOrders.Provider value={countSavedOrders}>
        {children}
      </CountOrders.Provider>
    </OrderCount.Provider>
  )
}
OrderCountProvider.propTypes = {
  children: PropTypes.any,
}

function useOrderCount() {
  const context = React.useContext(OrderCount)
  if (context === undefined) {
    throw new Error('useOrderCount must be used within a OrderCountProvider')
  }
  return context
}

function useCountOrders() {
  const context = React.useContext(CountOrders)
  if (context === undefined) {
    throw new Error('useCountOrders must be used within a CountOrdersProvider')
  }
  return context
}

function calculatePrice(order, orderCurrency) {
  let orderTotalMoney = 0
  let orderTotalTax = 0
  let orderTotalDiscount = 0
  let currency = orderCurrency
  let count = 0

  //to avoid if else at EVERY reduce function we can add empty array if no data
  order.appliedDiscounts = order.appliedDiscounts || []

  const lineItemsWithItemAmountDiscount = order.lineItems.map(
    (orderLineItem, index) => {
      //to avoid if else at EVERY reduce function we can add empty array if no data
      orderLineItem.modifiers = orderLineItem.modifiers || []
      orderLineItem.modifiers = orderLineItem.modifiers || []
      orderLineItem.appliedDiscounts = orderLineItem.appliedDiscounts || []
      currency = orderLineItem.basePriceMoney.currency
      count += orderLineItem.quantity

      // percentage might be 0.1% or less so DP=2 would round that to 0
      Big.DP = 10

      const modifiersPrice = orderLineItem.modifiers.reduce(
        (sum, { appliedMoney }) => +Big(sum).plus(appliedMoney.amount),
        0,
      )

      const basePrice = +Big(orderLineItem.basePriceMoney.amount).plus(
        modifiersPrice,
      )

      const priceWithQuantity = +Big(basePrice).times(orderLineItem.quantity).round(2)

      // when multiple appliedDiscounts apply the next discount the the product who has been discounted
      // by the previous discount
      const itemPercentageDiscounts = orderLineItem.appliedDiscounts.filter(
        (discount) =>
          discount.discountType === 'FIXED_PERCENTAGE' ||
          discount.discountType === 'VARIABLE_PERCENTAGE',
      )
      const priceWithItemDiscounts = +Big(
        itemPercentageDiscounts.reduce((itemPrice, { percentage }) => {
          const discountPercentage = +Big(percentage).div(100)
          const discountValue = Big(itemPrice).times(discountPercentage).round(2)
          return +Big(itemPrice).minus(discountValue)
        }, priceWithQuantity),
      )

      // do order % discount here
      const orderPercentageDiscounts = order.appliedDiscounts.filter(
        (discount) =>
          discount.discountType === 'FIXED_PERCENTAGE' ||
          discount.discountType === 'VARIABLE_PERCENTAGE',
      )
      const priceWithOrderPercentageDiscounts = +Big(
        orderPercentageDiscounts.reduce((itemPrice, { percentage }) => {
          const discountPercentage = +Big(percentage).div(100)
          const discountValue = Big(itemPrice).times(discountPercentage).round(2)
          return +Big(itemPrice).minus(discountValue)
        }, priceWithItemDiscounts),
      )

      // line item discount amount
      const itemAmountDiscounts = orderLineItem.appliedDiscounts.filter(
        (discount) =>
          discount.discountType === 'FIXED_AMOUNT' ||
          discount.discountType === 'VARIABLE_AMOUNT',
      )
      const priceWithItemAmountDiscount = +Big(
        itemAmountDiscounts.reduce((itemPrice, { appliedMoney }) => {
          return +Big(itemPrice).minus(
            +Big(appliedMoney.amount).times(orderLineItem.quantity),
          )
        }, priceWithOrderPercentageDiscounts),
      )

      // UPDATE ORDER TOTAL Discount
      const itemDiscount = +Big(priceWithQuantity).minus(
        priceWithItemAmountDiscount,
      )
      orderTotalDiscount = +Big(orderTotalDiscount).plus(itemDiscount)

      orderLineItem.priceWithItemAmountDiscount = priceWithItemAmountDiscount

      order.lineItems[index].totalModifiersMoney = {
        amount: modifiersPrice,
        currency: currency,
      }
      order.lineItems[index].totalDiscountMoney = {
        amount: itemDiscount,
        currency: currency,
      }

      return orderLineItem
    },
  )

  // used to calculate portions of each items  order amount discount
  const orderCurrentSubtotal = +Big(
    lineItemsWithItemAmountDiscount.reduce(
      (itemPrice, { priceWithItemAmountDiscount }) => {
        return +Big(itemPrice).plus(priceWithItemAmountDiscount)
      },
      0,
    ),
  )

  // update order total discount
  const orderAmountDiscounts = order.appliedDiscounts.filter(
    (discount) =>
      discount.discountType === 'FIXED_AMOUNT' ||
      discount.discountType === 'VARIABLE_AMOUNT',
  )
  const orderDiscountAmountsTotal = +Big(
    orderAmountDiscounts.reduce((price, { appliedMoney }) => {
      return +Big(price).plus(appliedMoney.amount)
    }, 0),
  )
  orderTotalDiscount = +Big(orderTotalDiscount).plus(orderDiscountAmountsTotal)

  const groupedTaxes = {}

  lineItemsWithItemAmountDiscount.map((orderLineItem, index) => {
    // order amount discount here
    const priceWithOrderAmountDiscounts = +Big(
      orderAmountDiscounts.reduce((itemPrice, { appliedMoney }) => {
        const itemCurrentSubtotalPortion = +new Big(
          orderLineItem.priceWithItemAmountDiscount,
        ).div(orderCurrentSubtotal)
        const itemOrderDiscount = +new Big(itemCurrentSubtotalPortion).times(
          appliedMoney.amount,
        ).round(2)

        return +Big(itemPrice).minus(itemOrderDiscount)
      }, orderLineItem.priceWithItemAmountDiscount),
    )

    let priceWithTax = priceWithOrderAmountDiscounts
    if (orderLineItem.appliedTax) {
      let basePriceValue, taxValue

      if (orderLineItem.taxInclusionType === 'ADDITIVE') {
        const taxPercentage = +Big(orderLineItem.appliedTax.percentage).div(
          100,
        )
        // UPDATE ORDER TOTAL TAX
        taxValue = +Big(priceWithOrderAmountDiscounts).times(taxPercentage).round(2)

        // values will be used at grouped taxes
        basePriceValue = priceWithOrderAmountDiscounts

        priceWithTax = +Big(priceWithTax).plus(taxValue)
      } else if (orderLineItem.taxInclusionType === 'INCLUSIVE') {
        // inclusive basePrice price/(1+vat/100)
        basePriceValue = +Big(priceWithOrderAmountDiscounts).div(
          +Big(1).plus(+Big(orderLineItem.appliedTax.percentage).div(100)),
        )
        // inclusive vatValue price - basePrice
        taxValue = +Big(priceWithOrderAmountDiscounts).minus(basePriceValue).round(2)
      }

      orderTotalTax = +Big(orderTotalTax).plus(taxValue)

      if (!(orderLineItem.appliedTax.percentage in groupedTaxes)) {
        groupedTaxes[orderLineItem.appliedTax.percentage] = {
          VATRate: orderLineItem.appliedTax.percentage,
          NumOfItems: orderLineItem.quantity,
          // updated at end
          PriceBefVat: {
            amount: basePriceValue,
            currency,
          },
          // money amount of tax
          VATAmt: {
            amount: taxValue,
            currency,
          },
        }
      } else {
        groupedTaxes[orderLineItem.appliedTax.percentage].NumOfItems = +Big(groupedTaxes[orderLineItem.appliedTax.percentage].NumOfItems).plus(orderLineItem.quantity)
        groupedTaxes[
          orderLineItem.appliedTax.percentage
        ].VATAmt.amount += taxValue
        groupedTaxes[
          orderLineItem.appliedTax.percentage
        ].PriceBefVat.amount += basePriceValue
      }

      order.lineItems[index].totalTaxMoney = {
        amount: taxValue,
        currency: currency,
      }
    }

    orderTotalMoney = +Big(orderTotalMoney).plus(priceWithTax)
    priceWithTax = +Big(priceWithTax).round(2)
    order.lineItems[index].totalMoney = {
      amount: priceWithTax,
      currency: currency,
    }
  }, 0)

  order.orderGroupedTaxes = Object.values(groupedTaxes).map((tax) => {
    return {
      ...tax,
      PriceBefVat: {
        ...tax.PriceBefVat,
        amount: +Big(tax.PriceBefVat.amount).round(2),
      },
      VATAmt: {
        ...tax.VATAmt,
        amount: +Big(tax.VATAmt.amount).round(2),
      },
    }
  })

  order.totalMoney = {
    amount: +Big(orderTotalMoney).round(2),
    currency: currency,
  }
  order.totalTaxMoney = {
    amount: +Big(orderTotalTax).round(2),
    currency: currency,
  }
  order.totalDiscountMoney = {
    amount: +Big(orderTotalDiscount).round(2),
    currency: currency,
  }

  order.count = count

  return order
}

const getLineItemIndex = (prev, item) => {
  return prev.lineItems.findIndex((el) => {
    if (el.itemId === item.itemId) {
      if (el.itemName !== item.itemName) {
        return false
      }
      if (el.variationId !== item.variationId) {
        return false
      }
      if (el.appliedTax?.id !== item.appliedTax?.id) {
        return false
      }
      if (item.basePriceMoney?.amount !== el.basePriceMoney?.amount) {
        return false
      }
      const elMods = (el.modifiers || []).map((mod) => mod.id).sort()
      const itemMods = (item.modifiers || []).map((mod) => mod.id).sort()
      if (elMods.length !== itemMods.length) {
        return false
      } else if (
        elMods.findIndex((mod, idx) => itemMods?.[idx] !== mod) !== -1
      ) {
        return false
      }
      const elDiscs = (el.appliedDiscounts || [])
        .map((disc) => disc.id)
        .sort()
      const itemDiscs = (item.appliedDiscounts || [])
        .map((disc) => disc.id)
        .sort()
      if (elDiscs.length !== itemDiscs.length) {
        return false
      } else if (
        elDiscs.findIndex((disc, idx) => itemDiscs?.[idx] !== disc) !== -1
      ) {
        return false
      }
      return true
    } else {
      return false
    }
  })
}

const clearExtraDetails = (
  order,
  keysToRemove = ['id', 'totalModifiersMoney', 'priceWithItemAmountDiscount'],
) => {
  delete order.id
  delete order.count
  const lineItems = [...order.lineItems] || []
  lineItems.map((item, index) => {
    const lineItem = { ...item }
    keysToRemove.forEach((element) => delete lineItem[element])
    lineItems[index] = lineItem
  })
  order.lineItems = lineItems
  return order
}

const clearOrderUpdate = (
  order,
  keysToRemove = ['id', 'totalModifiersMoney', 'priceWithItemAmountDiscount'],
) => {
  delete order.count
  const lineItems = [...order.lineItems] || []
  lineItems.map((item, index) => {
    const lineItem = { ...item }
    keysToRemove.forEach((element) => delete lineItem[element])
    lineItems[index] = lineItem
  })
  order.lineItems = lineItems
  return order
}

const clearRefundExtraDetails = (order) => {
  delete order.id
  delete order.count
  const lineItems = [...order.lineItems] || []
  lineItems.map((item, index) => {
    const lineItem = { ...item }
    const keysToRemove = ['totalModifiersMoney', 'priceWithItemAmountDiscount', 'editableQuantity']
    keysToRemove.forEach((element) => delete lineItem[element])
    lineItems[index] = lineItem
  })
  order.lineItems = lineItems
  return order
}

const printOldReceipt = async (
  oldInvoice,
  translate
) => {

  const printerTranslate = (val) => {
    return translate(val, true)
  }
  try {
    await BluetoothEscposPrinter.printerInit()
    await BluetoothEscposPrinter.printerAlign(
      BluetoothEscposPrinter.ALIGN.CENTER,
    )
    await BluetoothEscposPrinter.printText(`${printerTranslate('receipt_header')}\n`, {
      fonttype: 2,
      widthtimes: 1,
      heigthtimes: 1,
    })
    await BluetoothEscposPrinter.printText(
      '--------------------------------\r\n',
      {},
    )
    await BluetoothEscposPrinter.printerAlign(
      BluetoothEscposPrinter.ALIGN.LEFT,
    )

    await BluetoothEscposPrinter.printText(`${printerTranslate('receipt_nsfl_label')}: ${oldInvoice?.IICRef}\r\n`, {
      codepage: 0,
      widthtimes: 0,
      heigthtimes: 0,
      fonttype: 5,
    })
    await BluetoothEscposPrinter.printText(`${printerTranslate('receipt_invoice_number')}: ${oldInvoice?.InvoiceNumber || ' '}\r\n`, {
      codepage: 0,
      widthtimes: 0,
      heigthtimes: 0,
      fonttype: 5,
    })
    await BluetoothEscposPrinter.printText(`${printerTranslate('receipt_created_at')}: ${oldInvoice.InvoiceDate}\r\n`, {
      codepage: 0,
      widthtimes: 0,
      heigthtimes: 0,
      fonttype: 5,
    })
    await BluetoothEscposPrinter.printText(
      '--------------------------------\r\n',
      {},
    )
    await BluetoothEscposPrinter.printText(`${printerTranslate('receipt_buyer_label')}\r\n`, {
      codepage: 0,
      widthtimes: 0,
      heigthtimes: 0,
      fonttype: 7,
    })
    await BluetoothEscposPrinter.printText(`${printerTranslate('receipt_nipt_label')}: ${oldInvoice?.NUIS || ' '}\r\n`, {
      codepage: 0,
      widthtimes: 0,
      heigthtimes: 0,
      fonttype: 5,
    })
    await BluetoothEscposPrinter.printText(
      '--------------------------------\r\n',
      {},
    )
    await BluetoothEscposPrinter.printText(`${printerTranslate('SELLER')}\r\n`, {
      codepage: 0,
      widthtimes: 0,
      heigthtimes: 0,
      fonttype: 7,
    })
    await BluetoothEscposPrinter.printText(`${oldInvoice?.TargetTaxpayerName || ' '}\r\n`, {
      codepage: 0,
      widthtimes: 0,
      heigthtimes: 0,
      fonttype: 5,
    })
    await BluetoothEscposPrinter.printText(`${printerTranslate('receipt_nipt_label')}: ${oldInvoice?.TargetTaxpayerNUIS || ' '}\r\n`, {
      codepage: 0,
      widthtimes: 0,
      heigthtimes: 0,
      fonttype: 5,
    })
    await BluetoothEscposPrinter.printText(
      '--------------------------------\r\n',
      {},
    )
    await BluetoothEscposPrinter.printerAlign(
      BluetoothEscposPrinter.ALIGN.CENTER,
    )

    await BluetoothEscposPrinter.setBlob(1)
    await BluetoothEscposPrinter.printText(`${printerTranslate('receipt_generated_by')} Vodafone eBiznes\n\n\n`, {
      codepage: 0,
      widthtimes: 0,
      heigthtimes: 0,
      fonttype: 5,
    })
    await BluetoothEscposPrinter.printText('\n\n\n', {})
    return
  } catch (e) {
    throw e
  }

}

const printReceipt = async (
  transaction,
  cashMoney,
  exchangeRate,
  currency,
  nipt,
  company,
  location,
  customer,
  translate
) => {

  const {
    order: originalOrder,
    createdAt,
    receiptNumber,
    employee,
    qrCode,
    FIC,
    IIC,
    referenceIIC,
    referenceFIC,
    softwareCode,
    operatorCode,
    location: transactionLocation,
    tender,
    employeeDetails,
  } = transaction
  const type = tender?.type || transaction?.transaction?.tender?.type

  const printerTranslate = (val) => {
    return translate(val, true)
  }

  const order = JSON.parse(
    JSON.stringify(originalOrder)
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .normalize('NFC'),
  )

  let price, c
  const sellerDetails = employeeDetails ? employeeDetails?.firstName + ' ' + employeeDetails?.lastName : employee?.split('@')?.[0]

  try {
    await BluetoothEscposPrinter.printerInit()
    await BluetoothEscposPrinter.printerAlign(
      BluetoothEscposPrinter.ALIGN.CENTER,
    )
    await BluetoothEscposPrinter.setBlob(0)
    await BluetoothEscposPrinter.printText(`${company}\n`, {
      fonttype: 1,
      widthtimes: 1,
      heigthtimes: 1,
    })
    await BluetoothEscposPrinter.printerAlign(
      BluetoothEscposPrinter.ALIGN.CENTER,
    )
    await BluetoothEscposPrinter.printText(`${location}\n`, {})
    await BluetoothEscposPrinter.printerAlign(
      BluetoothEscposPrinter.ALIGN.CENTER,
    )
    await BluetoothEscposPrinter.printText(`${printerTranslate('receipt_nipt_label')}: ${nipt}\n\n`, {})
    await BluetoothEscposPrinter.printerAlign(
      BluetoothEscposPrinter.ALIGN.CENTER,
    )
    await BluetoothEscposPrinter.printText(`${printerTranslate('receipt_header')}\n`, {
      fonttype: 2,
      widthtimes: 1,
      heigthtimes: 1,
    })
    await BluetoothEscposPrinter.printText(
      '--------------------------------\r\n',
      {},
    )
    await BluetoothEscposPrinter.printerAlign(
      BluetoothEscposPrinter.ALIGN.LEFT,
    )
    let len = (order?.lineItems || []).length
    for (let i = 0; i < len; i++) {
      const item = order?.lineItems[i]
      price = `${(item.totalMoney?.amount || 0)} ${item.totalMoney?.currency
        }`
      c = price.length + 2
      await BluetoothEscposPrinter.printColumn(
        [32 - c, c],
        [BluetoothEscposPrinter.ALIGN.LEFT, BluetoothEscposPrinter.ALIGN.RIGHT],
        [item.itemName, price],
        {},
      )
      await BluetoothEscposPrinter.printerAlign(
        BluetoothEscposPrinter.ALIGN.LEFT,
      )
      await BluetoothEscposPrinter.printText(
        `${item.quantity || 0} ${item?.measurementUnitId ? printerTranslate(item?.measurementUnitId) : ''} X ${(
          item.basePriceMoney?.amount || 0
        )} ${item.basePriceMoney?.currency}\r\n`,
        {},
      )

      for (let j = 0; j < (item?.modifiers?.length || 0); j++) {
        const modifier = item?.modifiers[j]
        await BluetoothEscposPrinter.printerAlign(
          BluetoothEscposPrinter.ALIGN.LEFT,
        )
        await BluetoothEscposPrinter.printText(
          ` ${modifier.name} (${(
            modifier.appliedMoney?.amount || 0
          )} ${modifier.appliedMoney?.currency})\r\n`,
          {},
        )
      }
      for (let j = 0; j < (item?.appliedDiscounts?.length || 0); j++) {
        const discount = item?.appliedDiscounts[j]
        await BluetoothEscposPrinter.printerAlign(
          BluetoothEscposPrinter.ALIGN.LEFT,
        )
        if (discount.percentage) {
          await BluetoothEscposPrinter.printText(
            ` ${discount.name} (${discount.percentage || 0}%)\r\n`,
            {},
          )
        } else {
          await BluetoothEscposPrinter.printText(
            ` ${discount.name} (${(
              discount.appliedMoney?.amount || 0
            )} ${discount.appliedMoney?.currency})\r\n`,
            {},
          )
        }
      }
    }
    await BluetoothEscposPrinter.printText(
      '--------------------------------\r\n',
      {},
    )
    price = `${(
      order.totalMoney?.amount +
      order.totalDiscountMoney?.amount -
      order?.totalTaxMoney?.amount || 0
    )} ${order.totalMoney?.currency}`
    c = price.length + 2
    await BluetoothEscposPrinter.printColumn(
      [32 - c, c],
      [BluetoothEscposPrinter.ALIGN.LEFT, BluetoothEscposPrinter.ALIGN.RIGHT],
      [`${printerTranslate('receipt_subtotal_label')}`, price],
      {},
    )
    price = `${(order.totalDiscountMoney?.amount || 0)} ${order.totalDiscountMoney?.currency
      }`
    c = price.length + 2
    await BluetoothEscposPrinter.printColumn(
      [32 - c, c],
      [BluetoothEscposPrinter.ALIGN.LEFT, BluetoothEscposPrinter.ALIGN.RIGHT],
      [`${printerTranslate('receipt_total_discount_label')}`, price],
      {},
    )
    len = (order?.orderGroupedTaxes || []).length
    for (let i = 0; i < len; i++) {
      const tax = order?.orderGroupedTaxes?.[i] || {}
      price = `${(tax.VATAmt?.amount || 0)} ${tax.VATAmt?.currency}`
      c = price.length + 2
      await BluetoothEscposPrinter.printColumn(
        [32 - c, c],
        [BluetoothEscposPrinter.ALIGN.LEFT, BluetoothEscposPrinter.ALIGN.RIGHT],
        [`${printerTranslate('receipt_tax')} (${tax.VATRate}%)`, price],
        {},
      )
    }
    price = `${order.totalMoney?.amount} ${order.totalMoney?.currency}`
    c = price.length + 2
    await BluetoothEscposPrinter.printColumn(
      [32 - c, c],
      [BluetoothEscposPrinter.ALIGN.LEFT, BluetoothEscposPrinter.ALIGN.RIGHT],
      [`${printerTranslate('receipt_total_label')}`, price],
      {},
    )
    if (exchangeRate && currency !== order.totalMoney?.currency) {
      price = `${(order.totalMoney?.amount / exchangeRate).toFixed(2)} ${currency}`
      c = price.length + 2
      await BluetoothEscposPrinter.printColumn(
        [32 - c, c],
        [BluetoothEscposPrinter.ALIGN.LEFT, BluetoothEscposPrinter.ALIGN.RIGHT],
        [`${currency} ${printerTranslate('receipt_foreign_total_label')}`, price],
        {},
      )

      await BluetoothEscposPrinter.printColumn(
        [32 - c, c],
        [BluetoothEscposPrinter.ALIGN.LEFT, BluetoothEscposPrinter.ALIGN.RIGHT],
        [`${printerTranslate('receipt_currency_rate_label')}`, exchangeRate],
        {},
      )
    }
    if (cashMoney) {
      price = `${(cashMoney)} ${currency || order.totalMoney?.currency}`
      c = price.length + 2
      await BluetoothEscposPrinter.printColumn(
        [32 - c, c],
        [BluetoothEscposPrinter.ALIGN.LEFT, BluetoothEscposPrinter.ALIGN.RIGHT],
        [`${printerTranslate('receipt_cash_money_label')}`, price],
        {},
      )
    }

    await BluetoothEscposPrinter.printText(
      '--------------------------------\r\n',
      {},
    )
    await BluetoothEscposPrinter.printerAlign(
      BluetoothEscposPrinter.ALIGN.LEFT,
    )
    await BluetoothEscposPrinter.printText(`${printerTranslate('receipt_total_method')}: ${printerTranslate(PAYMENT_METHOD[type])}\r\n`, {
      codepage: 0,
      widthtimes: 0,
      heigthtimes: 0,
      fonttype: 5,
    })
    await BluetoothEscposPrinter.printText(`${printerTranslate('receipt_nsfl_label')}: ${IIC}\r\n`, {
      codepage: 0,
      widthtimes: 0,
      heigthtimes: 0,
      fonttype: 5,
    })
    await BluetoothEscposPrinter.printText(`${printerTranslate('receipt_nivf_label')}: ${FIC || ' '}\r\n`, {
      codepage: 0,
      widthtimes: 0,
      heigthtimes: 0,
      fonttype: 5,
    })
    Boolean(referenceIIC) && await BluetoothEscposPrinter.printText(`${printerTranslate('receipt_nsfl_primary_label')}: ${referenceIIC}\r\n`, {
      codepage: 0,
      widthtimes: 0,
      heigthtimes: 0,
      fonttype: 5,
    })
    Boolean(referenceFIC) && await BluetoothEscposPrinter.printText(`${printerTranslate('receipt_nivf_primary_label')}: ${referenceFIC || ' '}\r\n`, {
      codepage: 0,
      widthtimes: 0,
      heigthtimes: 0,
      fonttype: 5,
    })
    if (transactionLocation?.businUnitCode) {
      await BluetoothEscposPrinter.printText(
        `${printerTranslate('e_invoice_bussines_unit_code_label')}: ${transactionLocation?.businUnitCode}\r\n`,
        {
          codepage: 0,
          widthtimes: 0,
          heigthtimes: 0,
          fonttype: 5,
        },
      )
    } else if (transaction?.transaction?.location?.businUnitCode) {
      await BluetoothEscposPrinter.printText(
        `${printerTranslate('e_invoice_bussines_unit_code_label')}: ${transaction?.transaction?.location?.businUnitCode}\r\n`,
        {
          codepage: 0,
          widthtimes: 0,
          heigthtimes: 0,
          fonttype: 5,
        },
      )
    }
    await BluetoothEscposPrinter.printText(
      `${printerTranslate('receipt_operator_code')}: ${operatorCode}\r\n`,
      {
        codepage: 0,
        widthtimes: 0,
        heigthtimes: 0,
        fonttype: 5,
      },
    )
    await BluetoothEscposPrinter.printText(
      `${printerTranslate('receipt_software_code')}: ${softwareCode}\r\n`,
      {
        codepage: 0,
        widthtimes: 0,
        heigthtimes: 0,
        fonttype: 5,
      },
    )
    await BluetoothEscposPrinter.printText(`${printerTranslate('receipt_seller_label')}: ${sellerDetails}\r\n`, {
      codepage: 0,
      widthtimes: 0,
      heigthtimes: 0,
      fonttype: 6,
    })
    await BluetoothEscposPrinter.printText(`${printerTranslate('receipt_number_label')}: ${receiptNumber}\r\n`, {
      codepage: 0,
      widthtimes: 0,
      heigthtimes: 0,
      fonttype: 7,
    })
    await BluetoothEscposPrinter.printText(
      moment(createdAt).format(`[${printerTranslate('receipt_date_time_label')}: ]DD-MM-YY HH:mm`) + '\n\n',
      {
        codepage: 0,
        widthtimes: 0,
        heigthtimes: 0,
        fonttype: 7,
      },
    )
    Object.keys(customer)?.length > 0 && await BluetoothEscposPrinter.printText(`${printerTranslate('receipt_buyer_label')}: ${customer?.title}\r\n`, {
      codepage: 0,
      widthtimes: 0,
      heigthtimes: 0,
      fonttype: 6,
    })
    Object.keys(customer)?.length > 0 && await BluetoothEscposPrinter.printText(`${printerTranslate('receipt_buyer_address_label')}: ${customer?.address}\r\n`, {
      codepage: 0,
      widthtimes: 0,
      heigthtimes: 0,
      fonttype: 7,
    })
    if (Object.keys(customer)?.length > 0 && Boolean(customer?.nipt)) {
      await BluetoothEscposPrinter.printText(`${printerTranslate('receipt_buyer_nipt_label')}: ${customer?.nipt}\r\n\n`,
        {
          codepage: 0,
          widthtimes: 0,
          heigthtimes: 0,
          fonttype: 7,
        })
    } else if (Object.keys(customer)?.length > 0 && Boolean(customer?.idName)) {
      await BluetoothEscposPrinter.printText(`${printerTranslate(ID_TYPES[customer?.idName])}: ${customer?.idValue}\r\n\n`,
        {
          codepage: 0,
          widthtimes: 0,
          heigthtimes: 0,
          fonttype: 7,
        })
    } else {
      await BluetoothEscposPrinter.printText('\n',
        {
          codepage: 0,
          widthtimes: 0,
          heigthtimes: 0,
          fonttype: 7,
        })
    }
    await BluetoothEscposPrinter.printerAlign(
      BluetoothEscposPrinter.ALIGN.LEFT,
    )
    if (order?.bankAccount) {
      await BluetoothEscposPrinter.printText(`${printerTranslate('receipt_payment_info_label')}\r\n`,
        {
          codepage: 0,
          widthtimes: 0,
          heigthtimes: 0,
          fonttype: 7,
        })
      await BluetoothEscposPrinter.printText(`${printerTranslate('receipt_payment_name_label')}: ${order?.bankAccount?.holderName ? order?.bankAccount?.holderName : ''}\r\n`,
        {
          codepage: 0,
          widthtimes: 0,
          heigthtimes: 0,
          fonttype: 7,
        })
      await BluetoothEscposPrinter.printText(`${printerTranslate('receipt_payment_iban_label')}: ${order?.bankAccount?.iban ? order?.bankAccount?.iban : ''}\r\n`,
        {
          codepage: 0,
          widthtimes: 0,
          heigthtimes: 0,
          fonttype: 7,
        })
      await BluetoothEscposPrinter.printText(`${printerTranslate('receipt_payment_swift_label')}: ${order?.bankAccount?.swiftCode ? order?.bankAccount?.swiftCode : ''}\r\n\n`,
        {
          codepage: 0,
          widthtimes: 0,
          heigthtimes: 0,
          fonttype: 7,
        })
    }
    if (order?.shippingAddress) {
      await BluetoothEscposPrinter.printText(`${printerTranslate('e_invoice_remittances_label')}\r\n`,
        {
          codepage: 0,
          widthtimes: 0,
          heigthtimes: 0,
          fonttype: 7,
        })
      await BluetoothEscposPrinter.printText(`${printerTranslate('payment_delivery_receiver_label')}: ${order?.shippingAddress?.externalId ? order?.shippingAddress?.externalId : ''}\r\n`,
        {
          codepage: 0,
          widthtimes: 0,
          heigthtimes: 0,
          fonttype: 7,
        })
      await BluetoothEscposPrinter.printText(`${printerTranslate('supporting_invoice_address_label')}: ${order?.shippingAddress?.addressLine1 || ''} ${order?.shippingAddress?.locality || ''} ${order?.shippingAddress?.country || ''}\r\n\n`,
        {
          codepage: 0,
          widthtimes: 0,
          heigthtimes: 0,
          fonttype: 7,
        })
    }
    await BluetoothEscposPrinter.printerAlign(
      BluetoothEscposPrinter.ALIGN.CENTER,
    )
    if (Object.keys(customer)?.length > 0) {
      await BluetoothEscposPrinter.printText(`${printerTranslate('e_invoice_buyer_label')}\n ${printerTranslate('e_invoice_signature_field_label')}\n\n`, {
        codepage: 0,
        widthtimes: 0,
        heigthtimes: 0,
        fonttype: 5,
      })
      await BluetoothEscposPrinter.printText(
        '--------------------------------\r\n\n',
        {},
      )
      await BluetoothEscposPrinter.printText(`${printerTranslate('e_invoice_carrier_label')}\n ${printerTranslate('e_invoice_signature_field_label')}\n\n`, {
        codepage: 0,
        widthtimes: 0,
        heigthtimes: 0,
        fonttype: 5,
      })
      await BluetoothEscposPrinter.printText(
        '--------------------------------\r\n\n',
        {},
      )
      await BluetoothEscposPrinter.printText(`${printerTranslate('e_invoice_seller_label')}\n ${printerTranslate('e_invoice_signature_field_label')}\n\n`, {
        codepage: 0,
        widthtimes: 0,
        heigthtimes: 0,
        fonttype: 5,
      })
      await BluetoothEscposPrinter.printText(
        '--------------------------------\r\n\n',
        {},
      )
    }
    await BluetoothEscposPrinter.printQRCode(
      qrCode,
      Platform.OS === 'android' ? 300 : 200,
      BluetoothEscposPrinter.ERROR_CORRECTION.Q,
    )
    await BluetoothEscposPrinter.printerAlign(
      BluetoothEscposPrinter.ALIGN.CENTER,
    )

    await BluetoothEscposPrinter.setBlob(1)
    await BluetoothEscposPrinter.printText(`${printerTranslate('receipt_generated_by')} Vodafone eBiznes\n\n\n`, {
      codepage: 0,
      widthtimes: 0,
      heigthtimes: 0,
      fonttype: 5,
    })
    await BluetoothEscposPrinter.printText('\n\n\n', {})
    return
  } catch (e) {
    throw e
  }
}

export {
  getLineItemIndex,
  clearExtraDetails,
  clearOrderUpdate,
  printReceipt,
  clearRefundExtraDetails,
  OrderCountProvider,
  useOrderCount,
  useCountOrders,
  printOldReceipt,
}
export default calculatePrice
