import React, { useState } from 'react'
import { SafeAreaView, View, ScrollView, TouchableOpacity } from 'react-native'
import PropTypes from 'prop-types'
import { v1 as uuidv1 } from 'uuid'
import { useSelector } from 'react-redux'

import {
  Header,
  InputRow,
  SwipeList,
  Loader,
  Text,
  Button,
  Icon,
  BottomSheetSelector,
  Type,
} from '../../Components'

import { getProductCatalog } from '../ProductCatalog/ProductCatalog.Selectors'


import images, { animations } from '../../Themes/Images'
import colors from '../../Themes/Colors'
import styles from './NewVariation.Styles'

function HiddenItem({
  needsConfirmation,
  onPress,
  onConfirm,
  onCancel,
  loading,
  isFirst,
  isLast,
}) {
  return (
    <View
      style={[
        styles.wrapper,
        styles['firstItem_' + isFirst],
        styles['lastItem_' + isLast],
      ]}>
      {loading ? (
        <View style={styles.loader}>
          <Loader size={32} source={animations.vfLoaderWhite} />
        </View>
      ) : needsConfirmation ? (
        <TouchableOpacity style={styles.option} onPress={onPress}>
          <Icon source={images.delete} color={colors.white} size={24} />
        </TouchableOpacity>
      ) : (
        <View style={styles.optionFull}>
          <TouchableOpacity style={styles.confirm} onPress={onConfirm}>
            <Icon source={images.delete} color={colors.white} size={24} />
            <Text
              i18nKey="opened_orders_delete_confirm"
              color={colors.white}
              style={styles.confirmText}
            />
          </TouchableOpacity>
          <TouchableOpacity style={styles.cancel} onPress={onCancel}>
            <Icon source={images.closeIcon} color={colors.white} size={20} />
          </TouchableOpacity>
        </View>
      )}
    </View>
  )
}
HiddenItem.propTypes = {
  needsConfirmation: PropTypes.bool,
  onPress: PropTypes.func,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  loading: PropTypes.bool,
  isFirst: PropTypes.bool,
  isLast: PropTypes.bool,
}


function NewVariation({
  route: {
    params: {
      index = -1,
      variation: variationObj,
      onSave = () => { },
    } = {},
  } = {},
  navigation: {
    navigate,
    goBack,
  },
}) {

  const [variation, setVariation] = useState({ ...(variationObj || { id: 'new-' + uuidv1(), prices: [] }) })
  const [prices, setPrices] = useState(variation?.prices || [])
  const [needsConfirmation, setNeedForConfirmation] = useState(true)
  const [itemWidth, setItemWidth] = useState(0)
  const { businessName, address } = useSelector(getProductCatalog)


  const [currencies] = useState([
    {
      code: 'Lek (ALL)',
      currencyCode: 'ALL',
    },
    {
      code: 'EUR (€)',
      currencyCode: 'EUR',
    },
    {
      code: 'USD ($)',
      currencyCode: 'USD',
    },
    {
      code: 'GBP (£)',
      currencyCode: 'GBP',
    },
  ])

  const addNewModifier = (newObj) => {
    setPrices((prev) =>
      [
        {
          id: 'new-' + uuidv1(),
          amount: '',
          currency: newObj.currencyCode,
        },
      ].concat(prev),
    )
  }

  const [currSearchValue, setCurrSearchValue] = useState('')
  const [currencyModal, setCurrencyModal] = useState({
    isVisible: false,
    data: [],
    title: '',
    placeholder: '',
    selected: undefined,
    select: () => { },
    Item: Type,
  })

  const closeModal = () => {
    setCurrencyModal(prev => ({ ...prev, isVisible: false }))
    setCurrSearchValue('')
    // setCurrModal(false)
  }

  const openCurrencySelector = () => {
    setCurrencyModal({
      isVisible: true,
      data: currencies,
      title: 'payment_currency_title',
      placeholder: 'payment_currency_placeholder',
      selected: {},
      select: (obj) => {
        addNewModifier(obj)
      },
      searchKey: 'code',
      Item: Type,
    })
  }

  const save = () => {
    onSave({ ...variation, prices }, index)
    goBack()
  }

  const replaceAt = (string, i, replacement) => {
    return string.substr(0, i) + replacement + string.substr(i + replacement.length)
  }

  const changeModifier = (value, i) => {
    let newString = value
    const indexOfComa = value.indexOf(',')
    if (indexOfComa >= 0) {
      newString = replaceAt(value, indexOfComa, '.')
    }
    setPrices((prev) => {
      prev[i].amount = newString
      return [...prev]
    })
  }

  const renderItem = ({ item, index: i }) => {
    return (
      <View
        key={index + '1'}
        style={[
          styles.inputContainer,
          styles['firstItem_' + (i === 0)],
          styles['lastItem_' + (i === prices.length - 1)],
        ]}>
        <InputRow
          label="item_price_label"
          inputType="numeric"
          inputLabel={item.currency}
          placeholder="0.00"
          value={item?.amount + ''}
          onChangeText={(value) => changeModifier(value, i)}
          style={styles.input}
        />
      </View>
    )
  }

  const openRow = (rowMap, id) => {
    setNeedForConfirmation(false)
    rowMap[id].manuallySwipeRow(-itemWidth)
  }

  const closeRow = (rowMap, id) => {
    rowMap[id].closeRow()
    setNeedForConfirmation(true)
  }

  const removeModifier = (i) => {
    setPrices((prev) => prev?.filter((element, ind) => ind !== i))
  }

  const deleteRow = (rowMap, i) => {
    closeRow(rowMap, i)
    removeModifier(i)
  }

  const renderHiddenItem = ({ item, index: i }, rowMap) => (
    <HiddenItem
      needsConfirmation={needsConfirmation}
      onPress={() => openRow(rowMap, i)}
      onConfirm={() => deleteRow(rowMap, i)}
      onCancel={() => closeRow(rowMap, i)}
      isFirst={i === 0}
      isLast={i === prices?.length - 1}
    />
  )

  return (
    <>
      <SafeAreaView style={styles.container}>
        <Header
          title={variationObj ? 'header_edit_variation' : 'header_new_variation'}
          image={images.back}
          onPress={() => goBack()}
          customBusinessName={businessName}
          customAddress={address}
        />
        <ScrollView style={styles.scrollContainer}>
          <View style={styles.itemContainer}>
            <InputRow
              label="item_name_label"
              value={variation.name}
              placeholder="item_name_placeholder"
              onChangeText={(value) => {
                if (/^[\w\s]*$/.test(value)) {
                  setVariation(prev => { return { ...prev, name: value } })
                }
              }
              }
              autoFocus
            />

            {/* <Text weight="light" style={styles.sectionTitle} i18nKey="item_price_inventory_section" />
            <InputRow
              label="item_price_label"
              inputType="numeric"
              inputLabel="Lekë"
              placeholder="0.00"
              value={(variation.priceMoney?.amount || '') + ''}
              style={[styles.inputWithoutSpace, styles.roundedCorners]}
              onChangeText={(value) => { onChange('priceMoney', value) }}
            /> */}
            <View style={styles.sectionTitleContainer}>
              <Text weight="light" i18nKey="item_price_inventory_section" />
              <Button
                icon={images.plusIcon}
                title="modifiers_button_new"
                onPress={openCurrencySelector}
                variant="secondary"
                style={styles.addNew}
              />
            </View>
            <SwipeList
              keyExtractor={(item, ind) => ind}
              data={prices}
              renderItem={renderItem}
              renderHiddenItem={renderHiddenItem}
              disableRightSwipe
              rightOpenValue={-100}
              setItemWidth={setItemWidth}
              onRowClose={() => setNeedForConfirmation(true)}
              contentContainerStyle={styles.listContentStyle}
            />
            <Button
              title={'certificate_button_done'}
              style={styles.primaryButton}
              variant={variation.name && prices?.length > 0 ? 'active' : 'disabled'}
              onPress={() => save()}
            />
          </View>
        </ScrollView>
      </SafeAreaView>
      <BottomSheetSelector
        {...currencyModal}
        closeModal={closeModal}
        searchValue={currSearchValue}
        setSearchValue={setCurrSearchValue}
      />
    </>
  )
}

NewVariation.propTypes = {
  route: PropTypes.object,
  navigation: PropTypes.object,
}
export default NewVariation
