import { styleSheetFactory } from '../../../../Themes/Colors'

const styles = styleSheetFactory(colors => ({
  container: {
    flex: 1,
  },
  emptyComponent: {
    marginTop: 15,
  },
}))

export default styles
