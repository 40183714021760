import React, { useState, useContext } from 'react'
import { Animated, View, FlatList, TouchableOpacity } from 'react-native'
import PropTypes from 'prop-types'
import { useTheme } from 'react-native-themed-styles'

import Icon from '../Icon'
import Text from '../Text'

import Theme from '../../Themes/Theme'
import images from '../../Themes/Images'
import themedStyles from './Options.Styles'

const AnimatedTouchable = Animated.createAnimatedComponent(TouchableOpacity)

function Options({ title, icon, size, items, listHeaderComponent }) {
  const [styles] = useTheme(themedStyles)
  const { colors } = useContext(Theme)

  const height = items.length * 40 + 80
  const [animWidth, setAnimWidth] = useState(75)
  const [animHeight, setAnimHeight] = useState(height / 2)

  const getDimensions = dimensions => {
    setAnimWidth(dimensions.width / 2)
    setAnimHeight(dimensions.height / 2)
  }

  const [open, setOpen] = useState(false)
  const [anim] = useState(new Animated.Value(0))

  const isOpenOptions = {
    scale: {
      true: 0,
      false: 1,
    },
    touchable: {
      true: 'auto',
      false: 'none',
    },
  }

  const animate = () => {
    Animated.timing(anim, {
      toValue: isOpenOptions.scale[open],
      duration: 200,
      useNativeDriver: true,
    }).start()
  }

  const toggleOptions = () => {
    setOpen(!open)
    animate()
  }

  const handleOptionPress = onOptionPress => {
    toggleOptions()
    onOptionPress()
  }

  const keyExtractor = (_, index) => `${index}`
  const renderItem = ({ item }) => (
    <TouchableOpacity
      onPress={() => handleOptionPress(item.onPress)}
      disabled={item.disabled}
      style={[styles.option, styles['optionDisabled_' + item.disabled]]}>
      {item.icon && <Icon source={item.icon} size={24} style={styles.icon} />}
      <Text i18nKey={item.title} size="footnote" />
    </TouchableOpacity>
  )
  const renderItemSeparatorComponent = () => <View style={styles.separator} />
  
  const renderListHeaderComponent = () => {
    if (typeof listHeaderComponent === 'function') {
      return listHeaderComponent(toggleOptions)
    }
    return listHeaderComponent || null
  }

  return (
    <View style={styles.container}>
      {/* <View style={styles.touchableBackdrop} /> */}
      <TouchableOpacity
        onPress={toggleOptions}
        style={[
          styles.optionsToggleContainer,
          styles[`optionsToggleContainer_${open}`],
        ]}
        activeOpacity={0.5}>
        <Icon
          source={icon}
          size={size}
          color={colors.secondaryIcon}
          style={{
            transform: [
              {
                rotate: anim.interpolate({
                  inputRange: [0, 1],
                  outputRange: ['0deg', '90deg'],
                }),
              },
            ],
          }}
          animated
        />
      </TouchableOpacity>
      <View
        style={[
          styles.optionsToggleBackdrop,
          styles[`optionsToggleBackdrop_${open}`],
        ]}
      />
      <Animated.View
        onLayout={event => getDimensions(event.nativeEvent.layout)}
        style={[
          styles.optionsContainer,
          styles[`optionsContainer_${open}`],
          {
            transform: [
              {
                translateX: anim.interpolate({
                  inputRange: [0, 1],
                  outputRange: [animWidth, 0],
                }),
              },
              {
                translateY: anim.interpolate({
                  inputRange: [0, 1],
                  outputRange: [-animHeight, 0],
                }),
              },
              { scale: anim },
            ],
            opacity: anim.interpolate({
              inputRange: [0, 0.5, 0.8, 1],
              outputRange: [0, 0, 1, 1],
            }),
          },
        ]}
        pointerEvents={isOpenOptions.touchable[open]}>
        <TouchableOpacity
          onPress={toggleOptions}
          style={styles.optionsTitle}
          activeOpacity={1}>
          <Text size="h5" i18nKey={title} />
        </TouchableOpacity>
        <FlatList
          data={items}
          contentContainerStyle={styles.options}
          ListHeaderComponent={renderListHeaderComponent()}
          keyExtractor={keyExtractor}
          renderItem={renderItem}
          ItemSeparatorComponent={renderItemSeparatorComponent}
        />
      </Animated.View>
      {open && (
        <AnimatedTouchable
          onPress={toggleOptions}
          style={[
            styles.backdrop,
            {
              opacity: anim.interpolate({
                inputRange: [0, 1],
                outputRange: [0, 1],
              }),
            },
          ]}
          activeOpacity={1}
        />
      )}
    </View>
  )
}

Options.defaultProps = {
  items: [],
  title: '',
  icon: images.dotsMenuV,
  size: 18,
}

Options.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      onPress: PropTypes.func.isRequired,
    }),
  ),
  listHeaderComponent: PropTypes.any,
  icon: PropTypes.any,
  size: PropTypes.number,
  title: PropTypes.string,
}

export default Options
