import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Animated, Easing, LayoutAnimation, Platform, TouchableOpacity, UIManager, View } from 'react-native'


import { useTheme } from 'react-native-themed-styles'

import Text from '../Text'
import Icon from '../Icon'

import themedStyles from './Expandable.Styles'
import { colors, images } from '../../Themes'



const CustomLayoutAnimation = {
    duration: 200,
    create: {
        type: LayoutAnimation.Types.linear,
        property: LayoutAnimation.Properties.opacity,
    },
    update: {
        type: LayoutAnimation.Types.easeInEaseOut,
    },
    delete: {
        duration: 50,
        type: LayoutAnimation.Types.linear,
        property: LayoutAnimation.Properties.opacity,
    },
}

const Item = ({ text, style }) => {
    const [styles] = useTheme(themedStyles)

    return (
        <View>
            <Text i18nKey={text} style={[styles.text, style]} />
        </View>
    )
}


function Expandable({ children, title, titleStyle, containerStyle, scrollRef }) {

    const [styles] = useTheme(themedStyles)


    const animatedIcon = useState(new Animated.Value(0))[0]
    const spin = animatedIcon.interpolate({
        inputRange: [0, 1],
        outputRange: ['0deg', '-180deg'],
    })
    const [isOpened, setisOpened] = useState(false)

    const handleOpenCloseCollapsible = () => {
        LayoutAnimation.configureNext(CustomLayoutAnimation)
        setisOpened(prev => !prev)
    }
    useEffect(() => {
        Animated.timing(animatedIcon, {
            toValue: isOpened ? 1 : 0,
            duration: 200,
            easing: Easing.linear,
            useNativeDriver: false,
        }).start()
        if (isOpened && scrollRef) {
            scrollRef?.current?.scrollToEnd({ animated: true })
        }
    }, [isOpened])

    if (Platform.OS === 'android') {
        if (UIManager.setLayoutAnimationEnabledExperimental) {
            UIManager.setLayoutAnimationEnabledExperimental(true)
        }
    }

    return (<>
        <TouchableOpacity onPress={handleOpenCloseCollapsible}  >
            <View style={[styles.container, containerStyle]}>

                <Item text={title} style={[styles.title, titleStyle]} />
                <Animated.View
                    style={{
                        transform: [{ rotate: spin }, { perspective: 1000 }],
                    }}
                    accessibilityLabel={`${!isNaN(isOpened)}`}
                    testID={'TicketdetailsArrow'}
                >
                    <Icon type="image" size={24} color={colors.secondaryIcon} source={images.arrow_up} style={{
                        transform: [{ rotate: '180deg' }, { perspective: 1000 }],
                    }} />
                </Animated.View>
            </View>
        </TouchableOpacity>
        {isOpened && children}
    </>
    )
}

Expandable.propTypes = {
    title: PropTypes.string,
    titleStyle: PropTypes.object,
    scrollRef: PropTypes.shape({ current: PropTypes.any }),
}

export default Expandable
