import { styleSheetFactory } from '../../Themes/Colors'

const styles = styleSheetFactory(colors => ({
  container: {
    flex: 1,
    backgroundColor: colors.background,
  },
  printButton: {
    flex: 1,
    marginVertical: 15,
  },
  loaderContainer: (isLast = false) => ({
    backgroundColor: colors.white,
    borderBottomColor: colors.separator,
    borderBottomWidth: isLast ? 0 : 1,
    height: 60,
  }),
  loaderWrapper: {
    borderRadius: 7,
    overflow: 'hidden',
  },
  retryButton: {
    margin: 15,
    marginBottom: 25,
  },
  horizontalSelfSpacing: { paddingHorizontal: 15 },
  bottomSpacing: { marginBottom: 30 },
}))

export default styles
