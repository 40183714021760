import { gql } from '@apollo/client'

export const GET_URL = gql`
query getPresignedUrl($fileName:String!,$uploadType:String!,$languageCode:String!,$locationId:String){
    getPresignedUrl(
        fileName:$fileName
        uploadType:$uploadType
        languageCode:$languageCode
        locationId:$locationId
        ){
    metadataKey
    metadataUrl
    uploadKey
    uploadURL
    fileName
    }
}`

