import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useTheme } from 'react-native-themed-styles'
import { useDispatch, useSelector } from 'react-redux'

import { getCurrency } from '../../../../Checkout/Checkout.Selectors'
import images from '../../../../../Themes/Images'
import {
  BottomSheetSelector,
  TouchableInput,
  Type,
} from '../../../../../Components'
import { updatePaymentAttribute } from '../../../Payment.Actions'

import themedStyles from './SecondaryCurrency.Styles'

function SecondaryCurrency({
  currencies,
  currency,
  setCurrency,
}) {
  const [styles] = useTheme(themedStyles)
  const primaryCurrency = useSelector(getCurrency)

  const dispatchAction = useDispatch()

  const setCash = (value) => dispatchAction(updatePaymentAttribute('cash', value))

  const [searchValue, setSearchValue] = useState('')
  const [modal, setModal] = useState({
    isVisible: false,
    data: [],
    title: '',
    placeholder: '',
    selected: undefined,
    select: () => { },
    Item: Type,
  })

  const closeModal = () => {
    setModal(prev => ({ ...prev, isVisible: false }))
    setSearchValue('')
  }

  const openInvoiceTypeSelector = () => {
    setModal({
      isVisible: true,
      data: currencies,
      title: 'payment_currency_title',
      placeholder: 'payment_currency_placeholder',
      selected: currency,
      select: (obj) => {
        setCash('')
        setCurrency(obj)
      },
      searchKey: 'code',
      Item: Type,
    })
  }

  return <>
    <TouchableInput
      onPress={() => openInvoiceTypeSelector()}
      label="payment_currency_title"
      value={currency.code}
      icon={images.filledDownArrow}
      iconSize={16}
      autoCorrect={false}
      autoCapitalize="none"
      editable={false}
      disabled={primaryCurrency.currencyCode !== 'ALL'}
      style={styles.topContent}
    />
    <BottomSheetSelector
      {...modal}
      closeModal={closeModal}
      searchValue={searchValue}
      setSearchValue={setSearchValue}
    />
  </>
}
SecondaryCurrency.defaultProps = {
  currencies: [],
  setCurrency: () => { },
}
SecondaryCurrency.propTypes = {
  currencies: PropTypes.array,
  currency: PropTypes.object,
  setCurrency: PropTypes.func,
}

export default SecondaryCurrency
