/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useCallback, useEffect, useContext, useRef } from 'react'
import { SafeAreaView, View, Text as RNText } from 'react-native'
import { v1 as uuidv1 } from 'uuid'
import moment from 'moment'
import { useFocusEffect, StackActions } from '@react-navigation/native'
import { useLazyQuery, useMutation } from '@apollo/client'
import { useTheme } from 'react-native-themed-styles'
import PropTypes from 'prop-types'

import {
  SimpleList,
  SimpleListElement,
  Loader,
  Button,
  Text,
  InputRow,
  Icon,
  Header,
  ScrollWrapperWeb,
  ErrorModal,
} from '../../Components'
import { useLanguage } from '../../Utils/Language'
import {
  CHANGE_CASH_DRAWER_SHIFT_STATE,
  GET_CASH_DRAWER,
} from '../CashDrawer/CashDrawer.schema'
import { getErrorMessage, INF } from '../../Utils/Helpers'

import { useConfig } from '../../Utils'

import Theme from '../../Themes/Theme'
import { images } from '../../Themes'
import themedStyles from './EndDrawer.Styles'

const LANGUAGES = {
  al: 'sq',
  en: 'en-gb',
}

function EndDrawer({
  route: {
    params: {
      currentDrawerId,
      n = 1,
      cashRegisterId,
    } = {},
  } = {},
  navigation: {
    navigate,
    goBack,
    dispatch,
  },
}) {

  const { switchLocation: { deviceId, locationId } } = useConfig()

  const [styles] = useTheme(themedStyles)
  const { colors } = useContext(Theme)
  const lang = useLanguage()

  const [value, setValue] = useState('')
  const setValueFormat = /^\d*(\.\d{0,2})?$/
  const submitValueFormat = /^\d+(\.\d{0,2})?$/

  const FCDCidFormat = useRef(/^[0-9A-Za-z-]{36}$/)

  const [confirm, setConfirm] = useState(false)
  const [disabled, setDisabled] = useState(true)
  const [description, setDescription] = useState('')

  const [expectedInDrawer, setExpectedInDrawer] = useState(0)
  const [loading, setLoading] = useState(false)
  const [drawerData, setDrawerData] = useState([])

  const [modal, setModal] = useState({
    isVisible: false,
    icon: images.warningIcon,
    title: '',
    desc: '',
    descPlaceholders: [],
    primaryText: '',
    primaryAction: null,
    tertiaryText: '',
    tertiaryAction: null,
  })
  const openModal = (
    title = 'opened_orders_error',
    desc = '',
    descPlaceholders = [],
    primaryText = '',
    primaryAction = null,
    tertiaryText = '',
    tertiaryAction = null,
    icon = images.warningIcon,
  ) => {
    setModal({
      isVisible: true,
      icon,
      title,
      desc,
      descPlaceholders,
      primaryText,
      primaryAction,
      tertiaryText,
      tertiaryAction,
    })
  }
  const closeModal = () => {
    setModal({
      isVisible: false,
      icon: images.delete,
      title: '',
      desc: '',
      primaryText: '',
      primaryAction: null,
      tertiaryText: '',
      tertiaryAction: null,
    })
  }

  const buttonProps = {
    disabled_true: {
      backgroundColor: colors.disabled,
      foregroundColor: colors.buttonSecondaryAccent,
    },
    backgroundColor: {
      true: colors.accent,
      false: colors.buttonSecondaryAccent,
    },
    foregroundColor: {
      true: colors.buttonSecondaryAccent,
      false: colors.accent,
    },
    text: {
      true: 'drawer_confirm_end',
      false: 'drawer_end_button',
    },
  }

  const [getCashDrawer, { loading: drawerInfoLoading, data }] = useLazyQuery(GET_CASH_DRAWER, {
    fetchPolicy: 'network-only',
  })

  useFocusEffect(
    useCallback(() => {
      getCashDrawer({
        variables: {
          locationId: 'test',
          shiftId: currentDrawerId,
          cashRegisterId: cashRegisterId,
        },
      })
      return
    }, []),
  )

  const [
    changeShiftState,
    { loading: endShiftLoading },
  ] = useMutation(CHANGE_CASH_DRAWER_SHIFT_STATE)

  const replaceAt = (string, index, replacement) => {
    return string.substr(0, index) + replacement + string.substr(index + replacement.length)
  }

  const changeValue = (text) => {
    let newString = text
    const indexOfComa = text.indexOf(',')
    if (indexOfComa >= 0) {
      newString = replaceAt(text, indexOfComa, '.')
    }
    if (setValueFormat.test(newString)) {
      setValue(newString)
      if (text === '') {
        setDisabled(true)
      } else {
        setDisabled(false)
      }
    }
  }

  const endDrawer = () => {
    if (submitValueFormat.test(value)) {
      if (confirm) {
        setLoading(true)
        changeShiftState({
          variables: {
            locId: locationId,
            cashRegisterId: deviceId,
            shiftId: currentDrawerId,
            newState: 'END',
            amount: value,
            currency: 'Lekë',
            description: description,
          },
        }).then(r => {
          if (r) {
            dispatch(StackActions.pop(n))
            // displayToast('drawer_end_success')
          }
        })
          .catch(e => {
            setLoading(false)
            openModal(
              'certificate_error_label',
              getErrorMessage(e),
              [],
              'einvoice_cancel_modal_button',
              () => {
                closeModal()
              },
              '',
              undefined
            )
          })
      } else {
        setConfirm(true)
      }
    }
  }

  const renderRightComponent = (rightLabel) => {
    return (
      <View style={styles.rightComponentStyle}>
        <Text size="footnote" style={styles.itemRightLabel} i18nKey={rightLabel} align="center" />
        <Icon source={images.chevronRight} size={20} color={colors.primaryIcon} />
      </View>
    )
  }

  const renderLeftComponent = (leftLabel) => {
    return (
      <View style={styles.leftComponentWrapper}>
        {/* <RNText style={styles.leftText}>{translate(leftLabel)}</RNText> */}
        <Text i18nKey={leftLabel} weight="light" size="footnote" />
        <RNText style={styles.listElementStar}>{'*'}</RNText>
      </View>
    )
  }

  useEffect(() => {
    if (data) {
      const res = data.retrieveCashDrawerShift
      const totalPayIO =
        res?.cashPaidInMoney?.amount - res?.cashPaidOutMoney?.amount
      let idRow
      if (!res?.FCDC) {
        idRow = {
          id: uuidv1(),
          leftText: 'drawer_id_label',
          rightComponent: renderIdStatus(
            images.clock,
            'drawer_processing_label',
            colors.secondaryAccent,
          ),
        }
      } else if (FCDCidFormat.current.test(res?.FCDC)) {
        idRow = {
          id: uuidv1(),
          leftText: 'drawer_id_label',
          rightText: res?.FCDC,
          ratio: '1:3',
        }
      } else {
        idRow = {
          id: uuidv1(),
          leftText: 'drawer_id_label',
          rightComponent: renderIdStatus(
            images.warning,
            'drawer_error_label',
            colors.error,
            [res?.FCDC]
          ),
        }
      }
      const result = [
        idRow,
        {
          id: uuidv1(),
          leftText: 'drawer_starting_date_label',
          rightText: moment(res?.openedAt).locale(LANGUAGES[lang]).format('DD/MM/YYYY hh:mm A'),
          ratio: '1:2',
        },
        {
          id: uuidv1(),
          leftText: 'drawer_started_by_label',
          rightText: res?.openingEmployee?.email,
          ratio: '1:2',
        },
        {
          id: uuidv1(),
          leftText: 'drawer_starting_cash_label',
          rightText: INF.format(res?.openedCashMoney?.amount) + ' Lekë',
        },
        {
          id: uuidv1(),
          leftText: 'drawer_paid_inout_label',
          rightComponent: renderRightComponent(INF.format(Number(totalPayIO.toFixed(2))) + ' Lekë'),
          onItemPress: () => navigate('PayInOut', { cashRegisterId: cashRegisterId, shiftId: currentDrawerId }),
          touchableItems: true,
        },
        {
          id: uuidv1(),
          leftText: 'drawer_cash_sales_label',
          rightComponent: renderRightComponent(INF.format(res?.cashPaymentMoney?.amount || 0) + ' Lekë'),
          onItemPress: () => navigate('TodayDrawer', {
            shiftId: currentDrawerId,
            cashRegisterId: cashRegisterId,
            title: 'drawer_cash_sales_label',
            eventType: 'CASH_TENDER_PAYMENT',
            navigateItem: 'Receipt',
            isRefund: false,
            listIsBlank: 'cash_sales_no_data',
          }),
          touchableItems: true,
        },
        {
          id: uuidv1(),
          leftText: 'drawer_cash_refunds_label',
          rightComponent: renderRightComponent(INF.format(res?.cashRefundsMoney?.amount || 0) + ' Lekë'),
          onItemPress: () => navigate('TodayDrawer', {
            shiftId: currentDrawerId,
            cashRegisterId: cashRegisterId,
            title: 'drawer_cash_refunds_label',
            eventType: 'CASH_TENDER_REFUND',
            navigateItem: 'Refund',
            isRefund: true,
            listIsBlank: 'cash_refunds_no_data',
          }),
          touchableItems: true,
        },
        {
          id: uuidv1(),
          leftComponent: renderLeftComponent('drawer_card_sales'),
          rightComponent: renderRightComponent(INF.format(res?.cardPaymentsMoney?.amount || 0) + ' Lekë'),
          onItemPress: () => navigate('TodayDrawer', {
            title: 'drawer_card_sales',
            shiftId: drawerData.id || currentDrawerId,
            eventType: 'CARD_TENDER_PAYMENT',
            navigateItem: 'Receipt',
            isRefund: false,
            listIsBlank: 'cart_sales_no_data',
            cashRegisterId: cashRegisterId || drawerData.cashRegisterId,
          }),
          touchableItems: true,
        },
        {
          id: uuidv1(),
          leftComponent: renderLeftComponent('drawer_card_refunds'),
          rightComponent: renderRightComponent(INF.format(res?.cardRefundsMoney?.amount || 0) + ' Lekë'),
          onItemPress: () => navigate('TodayDrawer', {
            title: 'drawer_card_refunds',
            shiftId: drawerData.id || currentDrawerId,
            eventType: 'CARD_TENDER_REFUND',
            navigateItem: 'Refund',
            isRefund: true,
            listIsBlank: 'cart_refunds_no_data',
            cashRegisterId: cashRegisterId || drawerData.cashRegisterId,
          }),
          touchableItems: true,
        },
        {
          id: uuidv1(),
          leftComponent: renderLeftComponent('drawer_card_payment'),
          rightComponent: renderRightComponent(INF.format(res?.cardPaidInMoney?.amount || 0) + ' Lekë'),
          onItemPress: () => {
            navigate('TodayDrawer', {
              title: 'drawer_card_payment',
              eventType: 'CARD_PAID_IN',
              listIsBlank: 'cart_sales_no_data',
              navigateItem: 'PayInOutDetails',
              shiftId: drawerData.id || currentDrawerId,
              cashRegisterId: cashRegisterId || drawerData.cashRegisterId,
            })

          },
          touchableItems: true,
        },
        {
          id: uuidv1(),
          leftText: 'drawer_expected_label',
          rightText: INF.format(res?.expectedCashMoney?.amount) + ' Lekë',
        },
      ]
      setExpectedInDrawer(res?.expectedCashMoney?.amount)
      setDrawerData(result)
    }
  }, [data])

  const renderIdStatus = (imgSource, text, color, placeholders = []) => {
    return (
      <View style={styles.idStatus}>
        <Icon source={imgSource} size={20} color={color} style={styles.icon} />
        <Text color={color} i18nKey={text} placeHolders={placeholders} />
      </View>
    )
  }

  const renderListHeaderComponent = () => {
    return (
      <>
        <SimpleListElement
          leftText="drawer_expected_label"
          rightText={INF.format(expectedInDrawer) + ' Lekë'}
          style={styles.description}
        />
        <InputRow
          label="drawer_actual_label"
          inputType="numeric"
          inputLabel=" Lekë"
          value={value}
          placeholder="0.00"
          onChangeText={changeValue}
          style={styles.inputRow}
          returnKeyType="next"
        />
        <InputRow
          label="drawer_description_label"
          inputType="default"
          value={description}
          placeholder="drawer_description_label"
          onChangeText={setDescription}
          // multiline
          style={styles.inputRow}
          returnKeyType="done"
        />
        <Button
          title={buttonProps.text[confirm]}
          titleStyle={styles.buttonTitle}
          style={styles.button}
          backgroundColor={
            buttonProps[`disabled_${disabled}`]?.backgroundColor ||
            buttonProps.backgroundColor[confirm]
          }
          borderWidth={0}
          borderColor={
            buttonProps[`disabled_${disabled}`]?.backgroundColor ||
            buttonProps.backgroundColor[confirm]
          }
          color={
            buttonProps[`disabled_${disabled}`]?.foregroundColor ||
            buttonProps.foregroundColor[confirm]
          }
          onPress={endDrawer}
          isActive
          disabled={disabled}
        />
        <Text size="footnote" style={styles.sectionText} i18nKey="drawer_current_details" />
      </>
    )
  }

  if (endShiftLoading || loading) {
    return (
      <View style={styles.container}>
        <Loader color={colors.primaryIcon} view="full" size={124} />
      </View>
    )
  }

  return (
    <SafeAreaView style={styles.container}>
      <Header
        title="header_end_drawer"
        image={images.closeIcon}
        onPress={() => goBack()}
      />
      <ScrollWrapperWeb>
        <SimpleList
          DATA={drawerData}
          listHeaderComponent={renderListHeaderComponent()}
          containerStyle={styles.list}
          refreshing={drawerInfoLoading}
          onRefresh={() => getCashDrawer({
            variables: {
              locationId: 'test',
              shiftId: currentDrawerId,
              cashRegisterId: cashRegisterId,
            },
          })}
        />
      </ScrollWrapperWeb>
      <ErrorModal
        isVisible={modal?.isVisible}
        title={modal?.title}
        description={modal?.desc}
        placeHolders={modal.descPlaceholders}
        primaryText={modal?.primaryText}
        tertiaryText={modal?.tertiaryText}
        primaryAction={modal?.primaryAction}
        tertiaryAction={modal?.tertiaryAction}
        onBackdropPress={() => closeModal()}
        onBackButtonPress={() => closeModal()}
        onClosePress={() => closeModal()}
      />
    </SafeAreaView>
  )
}

EndDrawer.propTypes = {
  route: PropTypes.object,
  navigation: PropTypes.object,
}

export default EndDrawer
