import { styleSheetFactory } from '../../Themes/Colors'

const styles = styleSheetFactory(colors => ({
  container: {
    flex: 1,
    backgroundColor: colors.background,
    justifyContent: 'center',
    alignItems: 'center',
  },
  contentContainer: {
    backgroundColor: colors.background,
  },
  icon: {

  },
  title: {
    marginTop: 30,
    marginBottom: 15,
    color: colors.accent,
  },
  desc: {

  },
}))

export default styles
