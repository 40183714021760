import React, { useState, useEffect } from 'react'
import { SafeAreaView, View, Linking, Text as RnText, Dimensions, ScrollView } from 'react-native'
import { useTheme } from 'react-native-themed-styles'
import PropTypes from 'prop-types'
import { useMutation, useQuery } from '@apollo/client'
import ContentLoader, { Rect } from 'react-content-loader/native'

import {
  InputWithTags,
  Header,
  Text,
  Button,
  ErrorModal,
  Section,
  TouchableInput,
} from '../../Components'
import { useUserDetails, getErrorMessage } from '../../Utils'
import { UPDATE_EMPLOYEE, GET_EMPLOYEE } from '../Users/Users.schema'

import BottomSheetCodeInput from '../../Components/BottomSheetCodeInput/BottomSheetCodeInput'

import { OPERATORS_LIST } from '../CashRegister/CashRegister.Schema'

import { colors } from '../../Themes'

import images from '../../Themes/Images'
import themedStyles from './OnBoardingFifthStep.Styles'

const { width } = Dimensions.get('screen')

function LoaderContent({ isFirst, isLast, style }) {
  const [styles] = useTheme(themedStyles)
  const loaderStyle = {}
  if (isFirst) {
    loaderStyle.borderTopLeftRadius = 7
    loaderStyle.borderTopRightRadius = 7
  }
  if (isLast) {
    loaderStyle.borderBottomLeftRadius = 7
    loaderStyle.borderBottomRightRadius = 7
    loaderStyle.borderBottomWidth = 0
  }
  return (
    <ContentLoader
      speed={0.7}
      height={70}
      style={Object.assign({}, styles.loaderContainer, loaderStyle, style)}
      backgroundColor="#e2e2e2"
      foregroundColor="#ecebeb">
      <Rect x="20" y="20" rx="3" ry="3" width="90" height="20" />
      <Rect x={`${width - 150}`} y="20" rx="3" ry="3" width="100" height="20" />
    </ContentLoader>
  )
}
LoaderContent.propTypes = {
  isFirst: PropTypes.bool,
  isLast: PropTypes.bool,
  style: PropTypes.object,
}

function SingleLoaderContent({ isFirst, isLast, style }) {
  const loaderStyle = {}
  if (isFirst) {
    loaderStyle.borderTopLeftRadius = 7
    loaderStyle.borderTopRightRadius = 7
  }
  if (isLast) {
    loaderStyle.borderBottomLeftRadius = 7
    loaderStyle.borderBottomRightRadius = 7
  }
  return (
    <ContentLoader
      speed={0.7}
      height={40}
      width={200}
      style={Object.assign({}, loaderStyle, style)}
      backgroundColor="#e2e2e2"
      foregroundColor="#ecebeb">
      <Rect x="0" y="10" rx="3" ry="3" width="120" height="20" />
    </ContentLoader>
  )
}
SingleLoaderContent.propTypes = {
  isFirst: PropTypes.bool,
  isLast: PropTypes.bool,
  style: PropTypes.object,
}

function OnBoardingFifthStep({ next, selectedPage, logout }) {
  const [styles] = useTheme(themedStyles)

  const { id, email } = useUserDetails()
  const [userData, setUserData] = useState([])
  const [opCode, setOpCode] = useState('')
  const [operatorCodeModal, setOperatorCodeModal] = useState(false)
  const [operatorInputValue, setOperatorInputValue] = useState(opCode)
  const [operatorsData, setOperatorsData] = useState([])
  const [selectedOperatorCode, setselectedOperatorCode] = useState({ code: opCode })
  const isAnItemSelected = operatorsData.filter((item) => item.code === opCode).length > 0


  useEffect(() => {
    if (isAnItemSelected) {
      setselectedOperatorCode({ code: opCode })
    }
  }, [operatorCodeModal])

  useEffect(() => {
    if (!isAnItemSelected) {
      setOperatorInputValue(opCode)
    }
  }, [operatorCodeModal])


  const handleTextChange = (text) => {
    setOperatorInputValue(text)
    setselectedOperatorCode('')
  }


  const handleCodeInputModalClose = () => {
    setOperatorCodeModal(false)
  }
  const mapOperators = (operatorsList) => {
    const unmappedOperators = operatorsList?.getOperators?.operators
    const mappedOperators = []

    if (unmappedOperators) {
      unmappedOperators.forEach((item) => {
        mappedOperators.push({
          id: item?.attributes.OprID,
          code: item?.attributes.OprCode,
          description: `${item?.attributes.OprFirstName} ${item?.attributes.OprLastName ? item?.attributes.OprLastName : ' '}`,
        })
      })
    }
    return mappedOperators
  }


  const [modal, setModal] = useState({
    isVisible: false,
    icon: images.warningIcon,
    title: '',
    desc: '',
    descPlaceholders: [],
    primaryText: '',
    primaryAction: null,
    tertiaryText: '',
    tertiaryAction: null,
  })
  const openModal = (
    title = 'opened_orders_error',
    desc = '',
    descPlaceholders = [],
    primaryText = '',
    primaryAction = null,
    tertiaryText = '',
    tertiaryAction = null,
    icon = images.warningIcon,
  ) => {
    setModal({
      isVisible: true,
      icon,
      title,
      desc,
      descPlaceholders,
      primaryText,
      primaryAction,
      tertiaryText,
      tertiaryAction,
    })
  }
  const closeModal = () => {
    setModal({
      isVisible: false,
      icon: images.delete,
      title: '',
      desc: '',
      primaryText: '',
      primaryAction: null,
      tertiaryText: '',
      tertiaryAction: null,
    })
  }

  const { loading, data } = useQuery(GET_EMPLOYEE, {
    fetchPolicy: 'network-only',
    variables: {
      id: id,
    },
    onCompleted: (newData) => {
      const retrievedEmployee = newData?.RetrieveEmployee
      setUserData([{
        label: 'users_first_name_label',
        value: retrievedEmployee?.firstName,
      },
      {
        label: 'users_last_name_label',
        value: retrievedEmployee?.lastName,
      },
      {
        label: 'users_email_label',
        value: retrievedEmployee?.email,
      },
      {
        label: 'users_phone_label',
        value: retrievedEmployee?.phoneNumber,
      }])
    },
    skip: !id || selectedPage !== 5,
  })

  const [
    updateUser,
    { loading: updateLoading },
  ] = useMutation(UPDATE_EMPLOYEE)

  const onFinish = () => {
    updateUser({
      variables: {
        id: id,
        email: email.trim(),
        operatorCode: opCode,
        firstName: data?.RetrieveEmployee?.firstName,
      },
    })
      .then(() => next())
      .catch((er) => {
        openModal(
          'certificate_error_label',
          getErrorMessage(er),
          [],
          'certificate_button_try_again',
          () => {
            closeModal()
          },
          '',
          undefined
        )
      })
  }

  const regex = /^[a-z]{2}\d{3}[a-z]{2}\d{3}$/

  return (
    <>
      <SafeAreaView style={styles.container}>
        <Header
          title={'receipt_operator_code_label'}
          image={images.close}
          onPress={() => {
            openModal(
              'logout_key',
              'onboarding_logout_message_description',
              [],
              'logout_key',
              () => {
                logout()
              },
              'logout_button_cancel',
              () => {
                closeModal()
              },
            )
          }}
        />
        <ScrollView style={styles.scrollView}>
          <View style={styles.contentWrapper}>
            {loading ?
              <View>
                <SingleLoaderContent />
                <LoaderContent isFirst />
                <LoaderContent />
                <LoaderContent />
                <LoaderContent isLast />
              </View> :
              <>
                <Text i18nKey="onboarding_user_detail_label" />
                <Section data={userData} />
              </>}
            <Text i18nKey="onboarding_operator_code_label" />
            <TouchableInput
              onPress={() => setOperatorCodeModal(true)}
              label="receipt_operator_code_label"
              value={opCode}
              autoCapitalize="none"
              onChangeText={setOpCode}
              icon={images.filledDownArrow}
              iconSize={16}
              autoCorrect={false}
              editable={false}
            />
            {/* <InputWithTags
              label="receipt_operator_code_label"
              value={opCode}
              autoCapitalize="none"
              onChangeText={setOpCode}
              autoCorrect={false}
              labelOffset={{
                x0: 0,
                y0: -7,
                x1: 0,
                y1: 2,
              }}
            /> */}
            <RnText
              onPress={() => Linking.openURL('https://efiskalizimi-app.tatime.gov.al/self-care')}
              style={styles.operatorCodeHelper}
              allowFontScaling={false}
            >
              <Text i18nKey="cash_register_business_code1" />
              <Text style={{ color: colors.secondaryAccent }} i18nKey="cash_register_business_code2" />
              <Text i18nKey="cash_register_business_code3" />
              <Text style={styles.operatorCodeHelperItalic} i18nKey="cash_register_business_code4" />
              <Text i18nKey="cash_register_business_code5" />
            </RnText>
          </View>
        </ScrollView>

        <View style={styles.buttonContainer}>
          <Button
            loader={updateLoading}
            title="orders_refund_button_next"
            variant={updateLoading || !regex.test(opCode) ? 'disabled' : 'active'}
            iconColor={colors.accent}
            onPress={() => onFinish()}
          />
        </View>
      </SafeAreaView>
      <BottomSheetCodeInput
        inputValue={operatorInputValue}
        isVisible={operatorCodeModal}
        data={operatorsData}
        title={'receipt_operator_code_label'}
        inputPrompt={'OperatorCodeEnterPrompt'}
        inputLabel={'receipt_operator_code_label'}
        buttonLabel={'OperatorCodeSet'}
        onSave={setOpCode}
        setData={setOperatorsData}
        query={OPERATORS_LIST}
        mapItems={mapOperators}
        note
        onClose={handleCodeInputModalClose}
        onChangeText={handleTextChange}
        selected={selectedOperatorCode}
        select={(obj) => {
          setselectedOperatorCode(obj)
        }}
        editable={true}
      />
      <ErrorModal
        isVisible={modal?.isVisible}
        title={modal?.title}
        description={modal?.desc}
        placeHolders={modal.descPlaceholders}
        primaryText={modal?.primaryText}
        tertiaryText={modal?.tertiaryText}
        primaryAction={modal?.primaryAction}
        tertiaryAction={modal?.tertiaryAction}
        onBackdropPress={() => closeModal()}
        onBackButtonPress={() => closeModal()}
        onClosePress={() => closeModal()}
      />
    </>
    // getErrorMessage(isErrorActive.message
  )
}

OnBoardingFifthStep.propTypes = {
  next: PropTypes.func,
  selectedPage: PropTypes.number,
  logout: PropTypes.func,
}

export default OnBoardingFifthStep
