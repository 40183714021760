import React, { useContext } from 'react'
import { View } from 'react-native'
import { useTheme } from 'react-native-themed-styles'
import PropTypes from 'prop-types'

import Icon from '../Icon'
import Text from '../Text'

import Theme from '../../Themes/Theme'
import themedStyles from './DiscountLabel.Styles'
import images from '../../Themes/Images'

const discountTypes = {
  FIXED_PERCENTAGE: 'FIXED_PERCENTAGE',
  FIXED_AMOUNT: 'FIXED_AMOUNT',
  VARIABLE_PERCENTAGE: 'VARIABLE_PERCENTAGE',
  VARIABLE_AMOUNT: 'VARIABLE_AMOUNT',
}

function DiscountLabel(props) {
  const {
    discountsCount,
    discount,
  } = props
  const [styles] = useTheme(themedStyles)
  const { colors } = useContext(Theme)

  if (!discountsCount || discountsCount === 0){
    return null
  }

  const discountCount = () => (
    <View style={styles.container}>
      <Icon source={images.discounts} size={15} style={styles.icon} />
      <Text
        i18nKey="checkout_discount_number"
        placeHolders={[discountsCount]}
        size="footnote"
        color={colors.white}
      />
    </View>
  )
  if (discountsCount > 1) {
    return discountCount()
  }

  const {
    percentage,
    discountType,
    prices,
  } = discount || {}

  if (discountType?.match(/VARIABLE/)){
    return discountCount()
  }
  if (discountType === discountTypes.FIXED_PERCENTAGE){
    return <View style={styles.container}>
    <Icon source={images.discounts} size={15} style={styles.icon} />
    <Text i18nKey={percentage + '%'} size="footnote" color={colors.white} numberOfLines={1} />
  </View>

  }

  const getItemPrice = (discPrices) => {
    let result
    if (discPrices?.length === 1) {
      result = `${discPrices?.[0]?.amount} ${discPrices?.[0]?.currency}`
    } else {
      result = 'discount_multiple_prices_label'
    }
    return result
  }

  return (<View style={styles.container}>
    <Icon source={images.discounts} size={15} style={styles.icon} />
    <Text
      i18nKey={getItemPrice(prices)}
      size="footnote"
      color={colors.white}
      numberOfLines={1}
      translateOption={{count: prices?.length || 0}}
    />
  </View>
  )
}

DiscountLabel.propTypes = {
  discountsCount: PropTypes.number,
  discount: PropTypes.object,
}

export default DiscountLabel
