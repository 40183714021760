import { styleSheetFactory } from '../../Themes/Colors'

const styles = styleSheetFactory(colors => ({
  container: {
    flex: 1,
  },
  listInfoContainer: {
    flexGrow: 1,
    padding: 15,
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
    height: 70,
  },
  listFirst: {
    borderTopLeftRadius: 7,
    borderTopRightRadius: 7,
  },
  listLast: {
    borderBottomLeftRadius: 7,
    borderBottomRightRadius: 7,
  },
  listStyle: {
    marginTop: 10,
    flexShrink: 1,
    paddingHorizontal: 15,
  },
  listDescContainer: {
    flexGrow: 1,
    flexShrink: 1,
    paddingRight: 5,
  },
  radioContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    flexShrink: 0,
  },
  separator: {
    height: 1,
    backgroundColor: colors.separator,
  },
}))

export default styles
