/* eslint-disable react-native/no-inline-styles */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
  memo,
} from 'react'
import {
  View,
  SafeAreaView,
  TouchableOpacity,
  Animated,
  useWindowDimensions,
} from 'react-native'
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs'
import { useFocusEffect } from '@react-navigation/native'
import { useLazyQuery } from '@apollo/client'
import moment from 'moment'
import PropTypes from 'prop-types'
import { useTheme } from 'react-native-themed-styles'

import ContentManager from '../../Utils/ContentManager'
import { Calendar, Loader, Icon, Text, Header, Tabs, ItemStatus } from '../../Components'
import { LIST_CASH_DRAWER_SHIFTS } from '../CashDrawer/CashDrawer.schema'
import { useLanguage } from '../../Utils/Language'
import { usePermissions } from '../../Utils/Permissions'
import { useUserDetails } from '../../Utils/AuthDetails'

import { useConfig } from '../../Utils'

import images from '../../Themes/Images'
import themedStyles, {
  itemStyles,
  itemSeparatorStyles,
  listEmptyStyles,
  listStyles,
} from './CashDrawerHistory.Styles'
import { colors } from '../../Themes'

const Tab = createMaterialTopTabNavigator()
const LANGUAGES = {
  al: 'sq',
  en: 'en-gb',
}
const POINTER_EVENTS = {
  0: 'none',
  1: 'auto',
}
const LOCATION_ID = 'J11111111A'
const SHIFT_VALUES = {
  OPEN: {
    state: 'OPEN',
    time: 'openedAt',
    employee: 'openingEmployee',
  },
  END: {
    state: 'END',
    time: 'endedAt',
    employee: 'endingEmployee',
  },
  CLOSE: {
    state: 'CLOSE',
    time: 'closedAt',
    employee: 'closingEmployee',
  },
}

const renderItemStatus = (status, itemStatus, code) => {

  const ItemStatuses = {
    OPEN: {
      ACCEPTED: <ItemStatus
        statusStyle={{ marginLeft: 8 }}
        color={colors.green}
        title="invoice_status_success"
      />,
      REJECTED: <ItemStatus
        statusStyle={{ marginLeft: 8 }}
        color={colors.accent}
        title="invoice_status_failed"
        code={code} />,
      IN_PROGRESS: null,
      null: null,
      undefined: null,
    },
    END: {},
    CLOSE: {},
  }
  return ItemStatuses?.[status]?.[itemStatus] || null
}

const Item = memo(({ id, cashRegisterId, time, email, expectedCash, screen, navigate, isFirst, isLast, itemStatus, FCDC, status }) => {
  const [styles] = useTheme(itemStyles)
  return (
    <View
      style={[
        styles.container,
        styles['isFirst_' + isFirst],
        styles['isLast_' + isLast],
      ]}>
      <TouchableOpacity
        onPress={() => navigate(screen, { id, cashRegisterId })}
        style={styles.touchable}
        activeOpacity={0.7}
      >
        <View style={styles.leftData}>
          <View style={{ flexDirection: 'row', alignItems: 'center' }}>
            <Text i18nKey={time} />
            {renderItemStatus(status, itemStatus, FCDC)}
          </View>
          <Text i18nKey={email} size="footnote" weight="light" numberOfLines={1} />
        </View>
        <Text i18nKey={expectedCash} align="right" />
        <Icon source={images.arrow_right} size={24} style={styles.icon} />
      </TouchableOpacity>
    </View>
  )
})
Item.propTypes = {
  id: PropTypes.string,
  cashRegisterId: PropTypes.string,
  time: PropTypes.string,
  email: PropTypes.string,
  expectedCash: PropTypes.string,
  screen: PropTypes.string,
  navigate: PropTypes.func,
  isFirst: PropTypes.bool,
  isLast: PropTypes.bool,
  itemStatus: PropTypes.any,
  FCDC: PropTypes.string,
  status: PropTypes.string,

}
Item.defaultProps = {
  itemStatus: 'REJECTED',
  FCDC: '12',
}

function ItemSeparator() {
  const [styles] = useTheme(itemSeparatorStyles)
  return <View style={styles.container} />
}

function ListEmpty() {
  const [styles] = useTheme(listEmptyStyles)
  return (
    <View style={styles.container}>
      <Text i18nKey="drawer_not_found" />
    </View>
  )
}
function Route({
  status,
  screen,
  day,
  lang,
  refresh,
  navigation: { navigate, goBack },
}) {
  const { switchLocation: { deviceId, locationId } } = useConfig()

  const [styles] = useTheme(listStyles)
  const { width, height } = useWindowDimensions()
  const listRef = useRef(null)
  const [pointerEvents, setPointerEvents] = useState('auto')
  const fabSize = useRef(new Animated.Value(0)).current
  const animate = (value) => {
    setPointerEvents(POINTER_EVENTS[value])
    Animated.spring(fabSize, {
      toValue: value,
      useNativeDriver: true,
    }).start()
  }
  const scrollToTop = () => {
    animate(0)
    listRef.current.scrollToLocation({
      animated: true,
      itemIndex: 0,
      sectionIndex: 0,
    })
  }

  const cursor = useRef(null)
  const sectionDate = useRef('-')
  const [loading, setLoading] = useState(true)
  const [items, setItems] = useState([])

  const [listShifts, { error, data: shifts, fetchMore }] = useLazyQuery(
    LIST_CASH_DRAWER_SHIFTS,
    {
      fetchPolicy: 'network-only',
    }
  )
  const list = () => {
    let beginTime = null,
      endTime = null
    if (day) {
      beginTime = day
      endTime = moment(day).add(1, 'day').format('YYYY-MM-DD')
    }
    setLoading(true)
    listShifts({
      variables: {
        locationId: LOCATION_ID,
        locId: locationId,
        cashRegisterId: deviceId,
        beginTime: beginTime,
        endTime: endTime,
        state: status,
        limit: 30,
      },
    })
  }

  const mapResponse = (data) => {
    const res = items
    data.map((item, index) => {
      const date = moment(item[SHIFT_VALUES[status]?.time])
        .locale(LANGUAGES[lang])
        .format('MMM DD, YYYY')
      const time = moment(item[SHIFT_VALUES[status]?.time])
        .locale(LANGUAGES[lang])
        .format('hh:mm A')
      if (date !== sectionDate.current) {
        sectionDate.current = date
        const section = {
          key: res.length,
          id: date,
          sectionTitle: date,
          data: [],
        }
        res.push(section)
      }
      const expectedMoney = item?.expectedCashMoney

      res[res.length - 1]?.data.push({
        id: item.id,
        time,
        cashRegisterId: item?.cashRegisterId,
        email: item[SHIFT_VALUES[status]?.employee]?.email,
        expectedCash: `${expectedMoney?.amount} ${expectedMoney?.currency}`,
        itemStatus: item?.status,
        FCDC: item?.FCDC,
      })
    })
    return res
  }

  const onEndReached = () => {
    if (cursor.current) {
      let beginTime = null,
        endTime = null
      if (day) {
        beginTime = day
        endTime = moment(day).add(1, 'day').format('YYYY-MM-DD')
      }
      setLoading(true)
      fetchMore({
        variables: {
          locationId: LOCATION_ID,
          locId: locationId,
          cashRegisterId: deviceId,
          beginTime: beginTime,
          endTime: endTime,
          state: status,
          cursor: cursor.current,
          limit: 30,
        },
      })
    }
  }

  const refetch = () => {
    setLoading(true)
    animate(0)
    sectionDate.current = '-'
    cursor.current = null
    setItems([])
    list()
  }

  useEffect(() => {
    refetch()
  }, [day, refresh, width, height])

  useEffect(() => {
    if (shifts?.listCashDrawerShifts) {
      const {
        listCashDrawerShifts: { items: drawers = [], cursor: nextToken },
      } = shifts
      setItems(mapResponse(drawers))
      cursor.current = nextToken
      setLoading(false)
    }
  }, [shifts])

  useEffect(() => {
    if (error) {
      setLoading(false)
    }
  }, [error])

  const onMomentumScrollEnd = (event) => {
    if (event.nativeEvent.contentOffset.y > 800) {
      animate(1)
    } else {
      animate(0)
    }
  }
  const keyExtractor = (_, index) => 'item-' + index
  const renderItem = ({ item, index, section }) => (
    <Item
      key={'item-' + index}
      {...item}
      screen={screen}
      navigate={navigate}
      goBack={goBack}
      isFirst={index === 0}
      isLast={index === section.data.length - 1}
      status={status}
    />
  )
  const renderSectionHeader = ({ section: { sectionTitle } }) => {
    if (sectionTitle) {
      return (
        <Text
          size="footnote"
          style={styles.sectionHeader}
          i18nKey={sectionTitle}
        />
      )
    }
    return null
  }

  return (
    <View style={styles.listContainer}>
      <Animated.SectionList
        ref={(ref) => (listRef.current = ref)}
        onMomentumScrollEnd={onMomentumScrollEnd}
        showsVerticalScrollIndicator={false}
        stickySectionHeadersEnabled={false}
        contentContainerStyle={[styles.contentContainer]}
        ItemSeparatorComponent={ItemSeparator}
        ListEmptyComponent={!loading && ListEmpty}
        renderSectionHeader={renderSectionHeader}
        keyExtractor={keyExtractor}
        sections={items}
        renderItem={renderItem}
        refreshing={false}
        onRefresh={() => refetch()}
        onEndReached={onEndReached}
        onEndReachedThreshold={1}
        ListFooterComponent={
          <View style={styles.footer}>
            {loading ? <Loader size={32} /> : null}
          </View>
        }
      />
      <Animated.View
        pointerEvents={pointerEvents}
        style={[
          styles.fab,
          {
            opacity: fabSize,
            transform: [{ scale: fabSize }],
          },
        ]}>
        <TouchableOpacity
          onPress={scrollToTop}
          activeOpacity={0.5}
          style={styles.fabTouchable}>
          <Icon source={images.arrow_up} size={20} style={styles.fabIcon} />
        </TouchableOpacity>
      </Animated.View>
    </View>
  )
}
Route.propTypes = {
  status: PropTypes.oneOf(Object.keys(SHIFT_VALUES)),
  screen: PropTypes.string,
  day: PropTypes.string,
  lang: PropTypes.oneOf(Object.keys(LANGUAGES)),
  refresh: PropTypes.bool,
  navigation: PropTypes.object,
}
function CashDrawerHistory({ navigation: { goBack } }) {
  const [styles] = useTheme(themedStyles)
  const { width, height } = useWindowDimensions()
  const { role } = useUserDetails()
  const { cashDrawer = {} } = usePermissions()
  const lang = useLanguage()

  const [selectedDay, setSelectedDay] = useState()
  const [refresh, toggleRefresh] = useState(false)

  const translate = (key) => {
    return ContentManager.translate(key)
  }

  useFocusEffect(
    useCallback(() => {
      toggleRefresh((prev) => !prev)
      return
    }, []),
  )

  const renderTabs = useMemo(() => {
    return (
      <Tabs>
        <Tab.Screen
          key={'drawer_opened_route'}
          name={translate('drawer_opened_route')}>
          {(props) => (
            <Route
              {...props}
              status={SHIFT_VALUES.OPEN.state}
              screen={'CurrentDrawer'}
              day={selectedDay}
              lang={lang}
              refresh={refresh}
            />
          )}
        </Tab.Screen>
        <Tab.Screen
          key={'drawer_ended_route'}
          name={translate('drawer_ended_route')}>
          {(props) => (
            <Route
              {...props}
              status={SHIFT_VALUES.END.state}
              screen={'CashDrawerReport'}
              day={selectedDay}
              lang={lang}
              refresh={refresh}
            />
          )}
        </Tab.Screen>
        {(cashDrawer.closeDrawerView || []).includes(role) && <Tab.Screen
          key={'drawer_closed_route'}
          name={translate('drawer_closed_route')}>
          {(props) => (
            <Route
              {...props}
              status={SHIFT_VALUES.CLOSE.state}
              screen={'CashDrawerReport'}
              day={selectedDay}
              lang={lang}
              refresh={refresh}
            />
          )}
        </Tab.Screen>}
      </Tabs>
    )
  }, [selectedDay, lang, width, height, refresh])

  return (
    <SafeAreaView style={styles.container}>
      <Header
        title="header_cash_drawer_history"
        image={images.closeIcon}
        onPress={goBack}
      />
      {renderTabs}
      <View style={styles.searchContainer}>
        <Calendar
          selectedDay={selectedDay}
          placeholder="drawer_calendar_placeholder"
          onDayPress={(day) => setSelectedDay(day)}
        />
      </View>
    </SafeAreaView>
  )
}

CashDrawerHistory.propTypes = {
  navigation: PropTypes.object,
}

export default CashDrawerHistory
