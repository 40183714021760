import React, { useContext, useState } from 'react'
import { SafeAreaView, View, ScrollView } from 'react-native'
import PropTypes from 'prop-types'
import ShadowView from 'react-native-androw'
import { useTheme } from 'react-native-themed-styles'

import { Text, Header, Icon, Button, ErrorModal } from '../../Components'

import Theme from '../../Themes/Theme'

import images from '../../Themes/Images'
import themedStyles from './OnBoardingFirstStep.Styles'

function OnBoardingFirstStep({
    next,
    logout,
}) {
    const [styles] = useTheme(themedStyles)
    const { colors } = useContext(Theme)
    const [modal, setModal] = useState({
        isVisible: false,
        icon: images.warningIcon,
        title: '',
        desc: '',
        descPlaceholders: [],
        primaryText: '',
        primaryAction: null,
        tertiaryText: '',
        tertiaryAction: null,
    })
    const openModal = (
        title = 'opened_orders_error',
        desc = '',
        descPlaceholders = [],
        primaryText = '',
        primaryAction = null,
        tertiaryText = '',
        tertiaryAction = null,
        icon = images.warningIcon,
    ) => {
        setModal({
            isVisible: true,
            icon,
            title,
            desc,
            descPlaceholders,
            primaryText,
            primaryAction,
            tertiaryText,
            tertiaryAction,
        })
    }
    const closeModal = () => {
        setModal({
            isVisible: false,
            icon: images.delete,
            title: '',
            desc: '',
            primaryText: '',
            primaryAction: null,
            tertiaryText: '',
            tertiaryAction: null,
        })
    }

    return (
        <SafeAreaView style={styles.container}>
            <Header
                title={'demo_get_started_button'}
                image={images.close}
                onPress={() => {
                    openModal(
                        'logout_key',
                        'onboarding_logout_message_description',
                        [],
                        'logout_key',
                        () => {
                            logout()
                        },
                        'logout_button_cancel',
                        () => {
                            closeModal()
                        },
                    )
                }}
            />
            <ScrollView style={styles.scrollView}>
                <ShadowView style={styles.shadow}>
                    <View style={styles.imageContainer}>
                        <Icon source={images.personLaying} size={250} resizeMode="contain" />
                        <Text i18nKey="demo_welcome_label" size="h3" weight="bold" align="center" />
                    </View>
                </ShadowView>
                <View style={styles.contentContainer}>
                    <Text i18nKey={'onboarding_setup_title'} weight="bold" size="h4" align="center" style={styles.headerTitle} />
                    <View style={styles.contentItem}>
                        <Icon source={images.doc} size={24} style={styles.rightSpacing} />
                        <Text i18nKey={'onboarding_certificate_title'} align="center" style={styles.stepText} />
                    </View>
                    <View style={styles.contentItem}>
                        <Icon source={images.electronicCashDevice} size={24} style={styles.rightSpacing} />
                        <Text i18nKey={'onboarding_device_title'} align="center" style={styles.stepText} />
                    </View>
                    <View style={styles.contentItem}>
                        <Icon source={images.operatorCode} size={24} style={styles.rightSpacing} />
                        <Text i18nKey={'onboarding_operator_code_title'} align="center" style={styles.stepText} />
                    </View>
                </View>
            </ScrollView>
            <View style={styles.buttonContainer}>
                <Button
                    title="onboarding_setup_button"
                    variant={'active'}
                    iconColor={colors.accent}
                    style={styles.rightButton}
                    onPress={next}
                />
            </View>
            <ErrorModal
                isVisible={modal?.isVisible}
                title={modal?.title}
                description={modal?.desc}
                placeHolders={modal.descPlaceholders}
                primaryText={modal?.primaryText}
                tertiaryText={modal?.tertiaryText}
                primaryAction={modal?.primaryAction}
                tertiaryAction={modal?.tertiaryAction}
                onBackdropPress={() => closeModal()}
                onBackButtonPress={() => closeModal()}
                onClosePress={() => closeModal()}
            />
        </SafeAreaView>
    )
}

OnBoardingFirstStep.propTypes = {
    next: PropTypes.func,
    logout: PropTypes.func,
}

export default OnBoardingFirstStep
