import { styleSheetFactory } from '../../Themes/Colors'

const styles = styleSheetFactory(colors => ({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: colors.inactiveSwitch,
    height: 20,
    width: 34,
    borderRadius: 10,
  },
  containerEnabled_true: {
    backgroundColor: colors.activeSwitch,
  },
  touchable: {
    //flex: 1,
  },
  thumb: {
    height: 16,
    width: 16,
    backgroundColor: 'white',
    borderRadius: 8,
  },
}))

export default styles
