import React, { useState, useContext } from 'react'
import { View, TouchableOpacity, Dimensions } from 'react-native'
import ContentLoader, { Rect } from 'react-content-loader/native'
import PropTypes from 'prop-types'
import { useTheme } from 'react-native-themed-styles'

import {
  Icon,
  SwipeList,
  Text,
  Loader,
} from '../../../../Components'

import Theme from '../../../../Themes/Theme'
import images, { animations } from '../../../../Themes/Images'

import themedStyles, { modifierStyles, hiddenItemStyles, separatorStyles } from './Modifiers.Styles'

const { width } = Dimensions.get('screen')

function Modifier({ name, count, onPress, isFirst, isLast }) {
  const [styles] = useTheme(modifierStyles)

  return <View style={[styles.container, styles['firstItem_' + isFirst], styles['lastItem_' + isLast]]}>
    <TouchableOpacity onPress={onPress} style={styles.touchable}>
      <View style={styles.textContainer}>
        <Text i18nKey={name} numberOfLines={2} />
        {/* <Text i18nKey="modifiers_count" placeHolders={[count + '']} size="footnote" weight="light" numberOfLines={1} /> */}
      </View>
      <Icon
        source={images.chevronRight}
        style={styles.rightIcon}
      />
    </TouchableOpacity>
  </View>
}

Modifier.propTypes = {
  name: PropTypes.string,
  count: PropTypes.number,
  onPress: PropTypes.func,
  isFirst: PropTypes.bool,
  isLast: PropTypes.bool,
}

function ModifierPlaceholder({ isFirst, isLast }) {
  const [styles] = useTheme(themedStyles)

  return (
    <TouchableOpacity style={[styles.itemContainer, styles['fistItem_' + isFirst], styles['lastItem_' + isLast]]} disabled>
      <ContentLoader
        speed={2}
        width={width - 30}
        height={60}
        backgroundColor="#f3f3f3"
        foregroundColor="#fff"
      >
        <Rect x="15" y="21" rx="6" ry="6" width={`${(width - 30) * (3 / 5)}`} height="18" />
        <Rect x={`${width - 128}`} y="21" rx="6" ry="6" width="50" height="18" />
        <Rect x={`${width - 63}`} y="21" rx="3" ry="3" width="18" height="18" />
      </ContentLoader>
    </TouchableOpacity>
  )
}
ModifierPlaceholder.propTypes = {
  isFirst: PropTypes.bool,
  isLast: PropTypes.bool,
}

function HiddenItem({ needsConfirmation, onPress, onConfirm, onCancel, loading, isFirst, isLast }) {
  const [styles] = useTheme(hiddenItemStyles)
  const { colors } = useContext(Theme)

  return (
    <View style={[styles.wrapper, styles['firstItem_' + isFirst], styles['lastItem_' + isLast]]}>
      {loading
        ? <View style={styles.loader}>
          <Loader size={32} source={animations.vfLoaderWhite} />
        </View>
        : needsConfirmation
          ? <TouchableOpacity
            style={styles.option}
            onPress={onPress}
          >
            <Icon source={images.delete} color={colors.white} size={24} />
          </TouchableOpacity>
          : <View style={styles.optionFull}>
            <TouchableOpacity
              style={styles.confirm}
              onPress={onConfirm}
            >
              <Icon source={images.delete} color={colors.white} size={24} />
              <Text i18nKey="opened_orders_delete_confirm" color={colors.white} style={styles.confirmText} />
            </TouchableOpacity>
            <TouchableOpacity
              style={styles.cancel}
              onPress={onCancel}
            >
              <Icon source={images.closeIcon} color={colors.white} size={20} />
            </TouchableOpacity>
          </View>
      }
    </View>
  )
}
HiddenItem.propTypes = {
  needsConfirmation: PropTypes.bool,
  onPress: PropTypes.func,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  loading: PropTypes.bool,
  isFirst: PropTypes.bool,
  isLast: PropTypes.bool,
}

function Separator() {
  const [styles] = useTheme(separatorStyles)
  return <View style={styles.container} />
}

function Modifiers({
  selectedModifiers,
  updateSelection,
  navigate,
}) {
  const [styles] = useTheme(themedStyles)

  const [needsConfirmation, setNeedForConfirmation] = useState(true)
  const [modifiers, setModifiers] = useState([...selectedModifiers])
  const [itemWidth, setItemWidth] = useState(0)
  const [searchText, setSearchText] = useState('')


  const onSearch = (text) => {
    setSearchText(text)
    setModifiers(selectedModifiers?.filter(element => element.name?.toLowerCase().match(text.toLowerCase())))
  }

  const updateModifiers = updated => {
    updateSelection(updated)
    setModifiers(updated?.filter(element => element.name?.toLowerCase().match(searchText.toLowerCase())))
  }

  const addModifiers = () => {
    navigate('Modifiers', { selectable: true, preselectedModifiers: selectedModifiers, updateSelection: updateModifiers })
  }

  const changeModifier = modifier => {
    const i = selectedModifiers.findIndex(element => element.id === modifier.id)
    if (i !== -1) {
      const updated = selectedModifiers
      updated[i] = modifier
      updateModifiers([...updated])
    }
  }

  const editModifier = item => {
    if (item) {
      navigate('NewModifier', { modifier: item, onSave: changeModifier })
    }
  }

  const renderItem = ({ item, index }) => (
    <Modifier
      {...item}
      onPress={() => editModifier(item)}
      isFirst={index === 0}
      isLast={index === modifiers.length - 1}
    />
  )

  const openRow = (rowMap, id) => {
    setNeedForConfirmation(false)
    rowMap[id].manuallySwipeRow(-itemWidth)
  }

  const closeRow = (rowMap, id) => {
    rowMap[id].closeRow()
    setNeedForConfirmation(true)
  }

  const deleteRow = (rowMap, id) => {
    updateModifiers(modifiers?.filter(element => element.id !== id))
    closeRow(rowMap, id)
  }

  const renderHiddenItem = ({ item, index }, rowMap) => (
    <HiddenItem
      needsConfirmation={needsConfirmation}
      onPress={() => openRow(rowMap, item.id)}
      onConfirm={() => deleteRow(rowMap, item.id)}
      onCancel={() => closeRow(rowMap, item.id)}
      isFirst={index === 0}
      isLast={index === modifiers.length - 1}
    />
  )

  return (
    <SwipeList
      searchValue={searchText}
      searchPlaceholder="midifiers_search_placeholder"
      onSearch={onSearch}
      data={modifiers}
      renderItem={renderItem}
      renderHiddenItem={renderHiddenItem}
      disableRightSwipe
      rightOpenValue={-100}
      onRowClose={() => setNeedForConfirmation(true)}
      setItemWidth={setItemWidth}
      createNewLabel="modifier_button_add"
      onCreatePress={addModifiers}
      buttonVariant={'secondary'}
      ItemSeparatorComponent={Separator}
      ListEmptyComponent={<Text i18nKey="modifier_no_selected_message" align="center" />}
      containerStyle={styles.container}
    />
  )
}

Modifiers.defaultProps = {
  selectedModifiers: [],
  updateSelection: () => { },
}

Modifiers.propTypes = {
  selectedModifiers: PropTypes.arrayOf(PropTypes.object),
  updateSelection: PropTypes.func,
  navigate: PropTypes.func,
}

export default Modifiers
