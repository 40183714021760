import React, { memo, useState } from 'react'
import {
    View,
    FlatList,
    SafeAreaView,
    TouchableOpacity,
} from 'react-native'
import { useTheme } from 'react-native-themed-styles'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import {
    Button,
    Header,
    Icon,
    RadioInput,
    Text,
} from '../../Components'

import { getProductCatalog } from '../ProductCatalog/ProductCatalog.Selectors'

import themedStyles, { itemStyles, separatorStyles } from './UploadType.Styles'
import { colors, images } from '../../Themes'


const UPLOAD_TYPES = [
    {
        id: 'UPLOAD',
        title: 'upload_type_config_upload',
    },
    // {
    //     id: 'IMPORT',
    //     title: 'upload_type_config_import',
    // },
]

const Separator = memo(function Separator() {
    const [styles] = useTheme(separatorStyles)

    return (
        <View style={styles.container} />
    )
})

const Item = memo(({
    title,
    onPress,
    id,
    isFirst,
    isLast,
    selected,
}) => {
    const [styles] = useTheme(itemStyles)

    return (
        <View style={[styles.container, styles['firstItem_' + isFirst], styles['lastItem_' + isLast]]}>
            <TouchableOpacity
                style={styles.touchable}
                onPress={onPress}
                activeOpacity={0.5}
            >
                <View style={styles.detailsContainer}>
                    <Text i18nKey={title} numberOfLines={1} />
                </View>
                <RadioInput
                    selected={selected}
                    index={id}
                    onPress={onPress}
                    style={styles.selector}
                    color={colors.secondaryAccent}
                />
            </TouchableOpacity>
        </View>
    )
})
Item.propTypes = {
    title: PropTypes.string,
    id: PropTypes.string,
    selected: PropTypes.string,
    onPress: PropTypes.func,
    isFirst: PropTypes.bool,
    isLast: PropTypes.bool,
}


function UploadType(
    {
        navigation: {
            navigate,
            goBack,
        } }) {

    const [styles] = useTheme(themedStyles)
    const [selected, setSelected] = useState('')

    const { businessName, address } = useSelector(getProductCatalog)


    const keyExtractor = item => item.id
    const getItemLayout = (_, index) => ({
        length: 70,
        offset: 71 * index,
        index,
    })
    const renderItem = ({ item, index }) => (
        <Item
            key={item.id}
            {...item}
            onPress={() => setSelected(item?.id)}
            isFirst={index === 0}
            isLast={index === UPLOAD_TYPES.length - 1}
            selected={selected}
        />
    )

    const onNextPress = () => {
        if (selected === 'UPLOAD') {
            navigate('UploadItems', { selectedType: 'items', catalogUpload: true })
        } else {
            navigate('UploadItems', { selectedType: 'items', catalogUpload: false })
        }
    }

    return (


        <SafeAreaView style={styles.container}>
            <Header
                title="upload_items_empty_header"
                image={images.chevronLeft}
                onPress={() => goBack()}
                customBusinessName={businessName}
                customAddress={address}
            />
            <View style={styles.noteWrapper}>
                <View style={styles.infoWrapper}>
                    <Icon size={20} source={images.info} style={styles.icon} />
                    <Text weight="bold" i18nKey="cash_register_note" />
                </View>
                <Text size="footnote" weight="light" i18nKey="upload_type_note_description" />
            </View>
            <FlatList
                showsVerticalScrollIndicator={false}
                keyExtractor={keyExtractor}
                getItemLayout={getItemLayout}
                data={UPLOAD_TYPES}
                style={styles.listStyle}
                contentContainerStyle={styles.listContainerStyle}
                renderItem={renderItem}
                ItemSeparatorComponent={Separator}
                ListHeaderComponent={
                    <>
                        <Text weight="bold" i18nKey="upload_type_config_label" style={styles.sectionTitle} />
                    </>
                }
            />
            <Button title="certificate_button_next" variant={selected ? 'active' : 'disabled'} onPress={onNextPress} style={styles.button} />
        </SafeAreaView>


    )
}

UploadType.propTypes = {
    navigation: PropTypes.object,
    route: PropTypes.object,
}


export default UploadType
