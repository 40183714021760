import React, { useState } from 'react'
import {
  SafeAreaView,
  View,
  ScrollView,
  TouchableOpacity,
} from 'react-native'
import { useMutation, useQuery } from '@apollo/client'
import PropTypes from 'prop-types'

import { useSelector } from 'react-redux'

import {
  InputRow,
  Text,
  Loader,
  Button,
  Header,
  Icon,
  Modal,
  ModalContent,
  BottomSheetSelector,
  Type,
  SwipeList,
} from '../../Components'

import { getErrorMessage, useConfig } from '../../Utils'

import { getProductCatalog } from '../ProductCatalog/ProductCatalog.Selectors'

import styles, { hiddenItemStyles } from './NewDiscount.Styles'
import colors from '../../Themes/Colors'

import images, { animations } from '../../Themes/Images'

import { CREATE_DISCOUNT, EDIT_DISCOUNT, GET_DISCOUNT } from './NewDiscount.schema'

const discountTypes = {
  FIXED_AMOUNT: 'FIXED_AMOUNT',
  VARIABLE_AMOUNT: 'VARIABLE_AMOUNT',
  FIXED_PERCENTAGE: 'FIXED_PERCENTAGE',
  VARIABLE_PERCENTAGE: 'VARIABLE_PERCENTAGE',
  PERCENTAGE: 'PERCENTAGE',
  AMOUNT: 'AMOUNT',
}

function Separator() {
  return <View style={styles.separator} />
}

function HiddenItem({ needsConfirmation, onPress, onConfirm, onCancel, loading, isFirst, isLast }) {

  return (
    <View style={[hiddenItemStyles.wrapper, hiddenItemStyles['firstItem_' + isFirst], hiddenItemStyles['lastItem_' + isLast]]}>
      {loading
        ? <View style={hiddenItemStyles.loader}>
          <Loader size={32} source={animations.vfLoaderWhite} />
        </View>
        : needsConfirmation
          ? <TouchableOpacity
            style={hiddenItemStyles.option}
            onPress={onPress}
          >
            <Icon source={images.delete} color={colors.white} size={24} />
          </TouchableOpacity>
          : <View style={hiddenItemStyles.optionFull}>
            <TouchableOpacity
              style={hiddenItemStyles.confirm}
              onPress={onConfirm}
            >
              <Icon source={images.delete} color={colors.white} size={24} />
              <Text i18nKey="opened_orders_delete_confirm" color={colors.white} style={hiddenItemStyles.confirmText} />
            </TouchableOpacity>
            <TouchableOpacity
              style={hiddenItemStyles.cancel}
              onPress={onCancel}
            >
              <Icon source={images.closeIcon} color={colors.white} size={20} />
            </TouchableOpacity>
          </View>
      }
    </View>
  )
}
HiddenItem.propTypes = {
  needsConfirmation: PropTypes.bool,
  onPress: PropTypes.func,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  loading: PropTypes.bool,
  isFirst: PropTypes.bool,
  isLast: PropTypes.bool,
}

function NewDiscount({
  route: { params: { discountItem, onSave = () => { } } = {} } = {},
  navigation: { setOptions, goBack },
}) {

  const [currencies] = useState([
    {
      code: 'Lek (ALL)',
      currencyCode: 'ALL',
    },
    {
      code: 'EUR (€)',
      currencyCode: 'EUR',
    },
    {
      code: 'USD ($)',
      currencyCode: 'USD',
    },
    {
      code: 'GBP (£)',
      currencyCode: 'GBP',
    },
  ])

  const { switchLocation: { deviceId, locationId:locId } } = useConfig()


  const { locationId, businessName, address } = useSelector(getProductCatalog)


  const [modal, setmodal] = useState({
    title: '',
    isVisible: false,
  })
  const openModal = (message) => {
    setmodal({
      title: message,
      isVisible: true,
    })
  }
  const closeModal = () => {
    setmodal({
      title: '',
      isVisible: false,
    })
  }

  const [currSearchValue, setCurrSearchValue] = useState('')
  const [currencyModal, setCurrencyModal] = useState({
    isVisible: false,
    data: [],
    title: '',
    placeholder: '',
    selected: undefined,
    select: () => { },
    Item: Type,
  })

  const closeCurrencyModal = () => {
    setCurrencyModal(prev => ({ ...prev, isVisible: false }))
    setCurrSearchValue('')
  }

  const openCurrencySelector = () => {
    setCurrencyModal({
      isVisible: true,
      data: currencies,
      title: 'payment_currency_title',
      placeholder: 'payment_currency_placeholder',
      selected: {},
      select: (obj) => {
        setPrices((prev) => {
          const indexOfCurrency = prev.findIndex(it => it.currency === obj.currencyCode)
          if (indexOfCurrency < 0) {
            prev = prev.concat([{
              amount: '',
              currency: obj.currencyCode,
            }])
          }
          return [...prev]
        })
      },
      searchKey: 'code',
      Item: Type,
    })
  }

  const [discount, setDiscount] = useState(
    { ...discountItem || { discountType: discountTypes.PERCENTAGE } } ,
  )
  const [prices, setPrices] = useState(discount?.prices || [])
  const [discountType, setDiscountType] = useState(
    discount.discountType?.match(/PERCENTAGE/)
      ? discountTypes.PERCENTAGE
      : discountTypes.AMOUNT,
  )
  const [discountValue, setDiscountValue] = useState(discount.discountType?.match(/PERCENTAGE/) ? (discount.percentage || '') + '' : '')
  const [addDiscount, { loading }] = useMutation(CREATE_DISCOUNT)
  const [updateDiscount, { loading: loadingUpdate }] = useMutation(EDIT_DISCOUNT)
  const [needsConfirmation, setNeedForConfirmation] = useState(true)
  const [itemWidth, setItemWidth] = useState(0)
  useQuery(GET_DISCOUNT, {
    variables: {
      id: discount.id,
      locationId: locationId ? locationId : null,
      locId: locId,
      cashRegisterId: deviceId,
    },
    onCompleted: (newData) => {
      const discountObj = newData.getDiscount || {}
      setDiscount({ ...discountObj })
    },
    skip: !discount.id,
  })

  const changeDiscount = (key, value) => {
    setDiscount({ ...discount, [key]: value })
  }

  const replaceAt = (string, i, replacement) => {
    return string.substr(0, i) + replacement + string.substr(i + replacement.length)
  }

  const changeModifier = (key, value, index) => {
    let newString = value
    const indexOfComa = value.indexOf(',')
    if (indexOfComa >= 0) {
      newString = replaceAt(value, indexOfComa, '.')
    }
    setPrices((prev) => {
      prev[index].amount = newString
      return [...prev]
    })
    setDiscount((prev) => {
      if (key === 'prices') {
        prev.prices = prices
      } else {
        prev[index][key] = newString
      }
      return { ...prev }
    })
  }

  const renderItem = ({ item, index }) => {
    return (
      <View
        style={[
          styles.priceItem,
          styles['firstItem_' + (index === 0)],
          styles['lastItem_' + (index === prices.length - 1)],
        ]}>
        <InputRow
          label="item_price_label"
          inputType="numeric"
          inputLabel={item.currency}
          placeholder="0.00"
          value={item?.amount + ''}
          onChangeText={(value) => changeModifier('prices', value, index)}
          style={styles.input}
        />
      </View>
    )
  }

  const changeDiscountAmount = (val) => {
    if ((/^\d{0,3}(\.\d{0,2})?$/.test(val))) {
      if (Number(val) > 100) {
        setDiscountValue('100')
      }
      else if (Number(val) < 0) {
        setDiscountValue('0')
      }
      else {
        setDiscountValue(val)
      }
    }
  }
  const openRow = (rowMap, id) => {
    setNeedForConfirmation(false)
    rowMap[id].manuallySwipeRow(-itemWidth)
  }

  const closeRow = (rowMap, id) => {
    rowMap[id].closeRow()
    setNeedForConfirmation(true)
  }

  const removePrice = (currency) => {
    setPrices((prev) => prev?.filter((element) => element.currency !== currency))
  }

  const deleteRow = (rowMap, item) => {
    closeRow(rowMap, item.currency)
    removePrice(item.currency)

  }

  const renderHiddenItem = ({ item, index }, rowMap) => (
    <HiddenItem
      needsConfirmation={needsConfirmation}
      onPress={() => openRow(rowMap, item.currency)}
      onConfirm={() => deleteRow(rowMap, item)}
      onCancel={() => closeRow(rowMap, item.currency)}
      isFirst={index === 0}
      isLast={index === prices.length - 1}
    />
  )

  const checkAmounts = (discountPrices) => {
    return discountPrices?.every((item) => item?.amount !== '')
  }

  const save = () => {
    let discountData = discount
    if (!discount.name) {
      // displayToast('discount_attention_name_required', images.attention)
      return
    }

    const discountValueNr = parseFloat(discountValue)
    if (discountType === discountTypes.AMOUNT) {
      // if (!isNaN(discountValueNr)) {
      discountData = {
        ...discount,
        prices,
        discountType: discountTypes.FIXED_AMOUNT,
        percentage: null,
      }
      // } else {
      //   discountData = {
      //     ...discount,
      //     discountType: discountTypes.VARIABLE_AMOUNT,
      //     percentage: null,
      //   }
      // }
    } else {
      if (!isNaN(discountValueNr)) {
        discountData = {
          ...discount,
          discountType: discountTypes.FIXED_PERCENTAGE,
          percentage: discountValueNr,
          prices: [],
        }
      } else {
        discountData = {
          ...discount,
          discountType: discountTypes.VARIABLE_PERCENTAGE,
          percentage: null,
          prices: [],
        }
      }
    }

    if (!discountItem?.name) {
      addDiscount({
        variables: {
          ...discountData,
          locationId: locationId ? locationId : null,
          locId: locId,
          cashRegisterId: deviceId,
        },
      })
        .then((data) => {
          // const { updateDiscount: updatedDiscount } = data.data
          onSave({ ...discountData })
          goBack()
        })
        .catch((e) => {
          openModal(getErrorMessage(e))
        })
    } else {
      updateDiscount({
        variables: {
          ...discountData,
          locationId: locationId ? locationId : null,
          locId: locId,
          cashRegisterId: deviceId,
        },
      })
        .then((data) => {
          // const { updateDiscount: updatedDiscount } = data.data
          onSave({ ...discountData })
          goBack()
        })
        .catch((e) => {
          openModal(getErrorMessage(e))
        })
    }
  }

  return (
    <>
      <SafeAreaView style={styles.container}>
        <Header
          title={
            discountItem?.name ? 'header_edit_discount' : 'header_new_discount'
          }
          image={images.closeIcon}
          onPress={goBack}
          customBusinessName={businessName}
          customAddress={address}
        />
        <ScrollView style={styles.scrollContainer}>
          <InputRow
            label="categories_name_label"
            placeholder="discount_name_placeholder"
            defaultValue={discountItem?.name}
            value={discount.name}
            onChangeText={(value) => changeDiscount('name', value)}
          />
          <View style={styles.discountValueContainer}>
            <TouchableOpacity
              onPress={() => setDiscountType(discountTypes.PERCENTAGE)}
              style={[
                styles.discountTypeContainer,
                styles.rightSpacing,
                styles[
                'discountTypeContainerEnabled_' +
                (discountType === discountTypes.PERCENTAGE)
                ],
              ]}>
              <Text
                i18nKey={'%'}
                size={'h4'}
                style={[
                  styles.discountType,
                  styles[
                  'discountTypeEnabled_' +
                  (discountType === discountTypes.PERCENTAGE)
                  ],
                ]}
              />
            </TouchableOpacity>
            <TouchableOpacity
              onPress={() => {
                setDiscountType(discountTypes.AMOUNT)
                setDiscount(prev => ({
                  ...prev,
                  discountType: discountTypes.AMOUNT,
                }))
              }}
              style={[
                styles.discountTypeContainer,
                styles[
                'discountTypeContainerEnabled_' +
                (discountType === discountTypes.AMOUNT)
                ],
              ]}>
              <Text
                i18nKey={'item_price_inventory_section'}
                size={'h4'}
                style={[
                  styles.discountType,
                  styles[
                  'discountTypeEnabled_' +
                  (discountType === discountTypes.AMOUNT)
                  ],
                ]}
              />
            </TouchableOpacity>
          </View>
          {discountType?.match(/PERCENTAGE/) ? <InputRow
            style={styles.input}
            label="discount_value_label"
            placeholder="0.0"
            // inputLabel={
            //   discountType === discountTypes.AMOUNT ? 'Lekë' : undefined
            // }
            inputType={'numeric'}
            value={discountValue}
            defaultValue={discountValue}
            onChangeText={(val) => {
              changeDiscountAmount(val)
            }}
          /> :
            <View>
              <View style={styles.sectionTitleContainer}>
                <Text weight="light" i18nKey="item_price_inventory_section" />
                <Button
                  icon={images.plusIcon}
                  title="modifiers_button_new"
                  onPress={openCurrencySelector}
                  variant="secondary"
                  style={styles.addNew}
                />
              </View>
              <SwipeList
                keyExtractor={item => item?.currency}
                data={prices}
                renderItem={renderItem}
                renderHiddenItem={renderHiddenItem}
                disableRightSwipe
                rightOpenValue={-100}
                onRowClose={() => setNeedForConfirmation(true)}
                setItemWidth={setItemWidth}
                ItemSeparatorComponent={Separator}
                contentContainerStyle={styles.contentContainer}
              />
            </View>
          }
          <Button
            title={'discount_button_save'}
            style={styles.primaryButton}
            variant={
              discount.name && !loading && !loadingUpdate && (discountType?.match(/PERCENTAGE/) ? (discountValue && !(Number(discountValue) <= 0)) : (checkAmounts(prices) && prices?.length > 0))
                ? 'active'
                : 'disabled'
            }
            onPress={save}
            loader={loading || loadingUpdate}
            loaderComponent={
              <Loader size={32} source={animations.vfLoaderThinDark} />
            }
          />
        </ScrollView>
        <Modal
          isVisible={modal.isVisible}
          onBackButtonPress={() => closeModal()}
          onBackdropPress={() => closeModal()}
          animationIn="fadeIn"
          animationOut="fadeOut">
          <ModalContent onClose={() => closeModal()}>
            <Icon source={images.warningIcon} style={styles.modalIcon} size={40} />
            <Text
              i18nKey="orders_error_label"
              color={colors.primaryText}
              size="h5"
              align="center"
              weight="bold"
              style={styles.modalTitle}
            />
            <Text i18nKey={modal.title} align="center" />
          </ModalContent>
        </Modal>
      </SafeAreaView>
      <BottomSheetSelector
        {...currencyModal}
        closeModal={closeCurrencyModal}
        searchValue={currSearchValue}
        setSearchValue={setCurrSearchValue}
      />
    </>
  )
}

NewDiscount.defaultProps = {
  route: {},
}
NewDiscount.propTypes = {
  route: PropTypes.object,
  navigation: PropTypes.object,
}
export default NewDiscount
