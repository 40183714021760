import React, { createContext, useState, useContext, useRef } from 'react'
import { Platform } from 'react-native'
import PropTypes from 'prop-types'

import en from '../I18n/languages/enTemplate'
import al from '../I18n/languages/alTemplate'
import { BottomSheetSelector, SimpleType, Type } from '../Components'

const TemplatesLanguage = createContext()

const Templates_translation_keys = {
  al: al,
  en: en,
}

const language_label = {
  al: 'settings_albanian_label',
  en: 'settings_english_label',
}

function TemplateLangProvider({ children }) {
  const language = useRef('al')
  const receiptFormat = useRef(Platform.OS === 'web' ? 'A4' : '58mm')
  const [receiptLanguage, setReceiptLanguage] = useState({
    id: language.current,
    label: language_label[language.current],
    disabled: false,
  })

  const setLanguage = (value) => {
    language.current = value
  }

  const setReceiptFormat = (value) => {
    receiptFormat.current = value
  }

  const [modal, setModal] = useState({
    isVisible: false,
    isConfiguration: false,
    data: [],
    title: '',
    placeholder: '',
    selected: undefined,
    select: () => { },
    selectedFormat: undefined,
    selectFormat: () => { },
    selectedLang: undefined,
    selectLang: () => { },
    Item: Type,
    onSavePress: () => { },
    setLanguage,
    onModalHide: () => { },
    isSharing: false,
  })

  const closeModal = () => {
    setModal(prev => ({ ...prev, isVisible: false, onModalHide: () => { } }))
  }

  const closeModalWithHide = () => {
    setModal(prev => ({ ...prev, isVisible: false }))
  }

  const openConfigurationSelector = (isSharing = false, onSaveCallBack) => {
    if (isSharing === true) {
      setReceiptFormat('A4')
    } else {
      setReceiptFormat(Platform.OS === 'web' ? 'A4' : '58mm')
    }
    setModal({
      isVisible: true,
      isConfiguration: true,
      title: 'payment_receipt_configuration_title',
      selectedLang: receiptLanguage,
      selectLang: (val) => setReceiptLanguage(val),
      selectFormat: (val) => setReceiptFormat(val),
      selectedFormat: receiptFormat.current,
      Item: SimpleType,
      setLanguage,
      onModalHide: onSaveCallBack,
      isSharing,
    })
  }

  const translate = (val, is58mm) => {
    let value = Templates_translation_keys[language.current][val] || ''
    if (is58mm) {
      var chars = { 'ë': 'e', 'ç': 'c', 'Ç': 'C', 'Ë': 'E' }
      value = value.replace(/[ëçÇË]/g, m => chars[m])
      return value
    }
    return value
  }

  return (
    <TemplatesLanguage.Provider value={{
      language: language.current,
      setLanguage, translate,
      openConfigurationSelector,
      receiptFormat: receiptFormat.current,
      receiptLanguage,
    }}>
      {children}
      <BottomSheetSelector
        {...modal}
        closeModal={closeModal}
        closeModalWithHide={closeModalWithHide}
      />
    </TemplatesLanguage.Provider>
  )
}
TemplateLangProvider.propTypes = {
  children: PropTypes.any,
}

function useTemplateLanguage() {
  const context = useContext(TemplatesLanguage)
  if (context === undefined) {
    throw new Error('useTemplateLanguage must be used within a TemplateLangProvider')
  }
  return context
}

export { useTemplateLanguage }
export default TemplateLangProvider
