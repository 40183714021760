import { styleSheetFactory } from '../../../../../Themes/Colors'

const styles = styleSheetFactory(colors => ({
  indicatorContainer: {
    width: '100%',
    height: 10,
    marginBottom: 10,
    alignContent: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    flexShrink: 0,
  },
  indicator_false: {
    width: 10,
    height: 10,
    backgroundColor: colors.disabled,
    borderRadius: 10,
    marginHorizontal: 7.5,
  },
  indicator_true: {
    width: 18,
    height: 10,
    backgroundColor: colors.accent,
    borderRadius: 10,
    marginHorizontal: 7.5,
  },
}))

export default styles
