import { styleSheetFactory } from '../../Themes/Colors'

const styles = styleSheetFactory(colors => ({
  container: {
    flex: 1,
    position: 'relative',
    backgroundColor: colors.background,
  },
  header: {
    height: 56,
    alignItems: 'center',
    flexDirection: 'row',
    backgroundColor: colors.background,
  },
  headerIcon: {
    marginHorizontal: 20,
  },
  chargeButton: {
    height: 50,
    margin: 15,
    marginBottom: 30,
  },
  sectionHeader: {
    marginHorizontal: 15,
  },
  loadingTitle: {
    margin: 30,
  },
  listStyle: {
    flex: 1,
    marginHorizontal: 15,
    marginTop: 10,
  },
  listContainerStyle: {
    flexGrow: 1,
    paddingBottom: 0,
  },
  separator: {
    height: 1,
    backgroundColor: colors.separator,
  },
  modalContent: {
    marginHorizontal: 30,
  },
  loaderContainer: {
    marginBottom: 32,
  },
  modalTitle: {
    marginVertical: 10,
  },
  modalPrimaryButton: {
    width: '100%',
    marginTop: 30,
    marginVertical: 15,
  },
  modalSecondaryButton: {
    width: '100%',
  },

  // Scroll to top fab
  fab: {
    position: 'absolute',
    bottom: 105,
    right: 15,
    height: 50,
    width: 50,
    borderRadius: 25,
    backgroundColor: colors.background,
    shadowColor: colors.black,
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.22,
    shadowRadius: 2.22,
    elevation: 3,
  },
  fabTouchable: {
    height: 50,
    width: 50,
    borderRadius: 25,
    backgroundColor: colors.tertiaryAccent,
    justifyContent: 'center',
    alignItems: 'center',
  },
  fabIcon: {
    tintColor: colors.white,
  },
}))

const itemStyles = styleSheetFactory(colors => ({
  wrapper: {
    backgroundColor: colors.tertiaryBackground,
    overflow: 'hidden',
  },
  container: {
    flex: 1,
    backgroundColor: colors.tertiaryBackground,
    flexDirection: 'row',
    alignItems: 'center',
    flexGrow: 1,
    height: 70,
  },
  firstItem_true: {
    borderTopLeftRadius: 7,
    borderTopRightRadius: 7,
  },
  lastItem_true: {
    borderBottomLeftRadius: 7,
    borderBottomRightRadius: 7,
  },
  infoContainer: {
    flexGrow: 1,
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
    paddingHorizontal: 15,
    minHeight: 70,
  },
  leftContainer: {
    flexGrow: 1,
    flexShrink: 1,
  },
  rightContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    flexShrink: 0,
  },
  priceContainer: {
    flexDirection: 'column',
    alignItems: 'flex-end',
    marginRight: 15,
  },
}))

export { itemStyles }
export default styles
