import { styleSheetFactory } from '../../Themes/Colors'

const styles = styleSheetFactory(colors => ({
  container:{
    paddingBottom:20,
  },
  header: {
    margin: 15,
    marginTop: 30,
    flexDirection: 'row',
    justifyContent: 'space-between',

  },
  searchContainer: {
    flexDirection: 'row',
    marginHorizontal: 15,
    marginBottom: 15,
    backgroundColor: colors.white,
    borderRadius: 10,
    justifyContent: 'space-between',
    alignItems: 'center',
    flexGrow: 1,
  },
  searchIcon: {
    marginHorizontal: 15,
    marginVertical: 10,
  },
  searchInput: {
    flexGrow: 1,
    borderRadius: 10,
    paddingVertical: 10,
    marginRight: 15,
    color: colors.primaryText,
  },
  clearSearchContainer: {
    borderRadius: 18,
    padding: 5,
    marginRight: 15,
    backgroundColor: colors.background,
  },
  listStyle: {
    height: 220,
    marginBottom: 24,
  },
  listContainerStyle: {
    flexGrow: 1,
    paddingBottom: 20,
  },
  noteContainer: {
    flexDirection: 'column',
    marginHorizontal: 15,
    marginBottom: 15,
    backgroundColor: colors.white,
    borderRadius: 10,
    justifyContent: 'center',
    flexGrow: 1,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: colors.disabled,
  },
  noteInnerContainer: {
    flexDirection: 'row',
    paddingHorizontal: 10,
    paddingTop:10,
    paddingBottom:8,
    alignItems: 'center',
  },
  noteLabel: {
    marginLeft: 8,
  },
  noteText: {
    marginRight: 20,
    marginBottom: 10,
    marginLeft: 10,
  },
  button: {
    marginHorizontal: 16,
    marginTop: 4,
  },
  list: {
    height:'35%',
  },

}))

export default styles
