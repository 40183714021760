import React, { useContext } from 'react'
import { TouchableOpacity } from 'react-native'
import PropTypes from 'prop-types'

import { useTheme } from 'react-native-themed-styles'

import Icon from '../Icon'

import themedStyles from './Scan.Styles'
import Theme from '../../Themes/Theme'


function Scan(props) {

    const {
        image,
        onPress,
    } = props

    const [styles] = useTheme(themedStyles)
    const { colors } = useContext(Theme)

    return (
        <TouchableOpacity
            style={styles.container}
            onPress={onPress}
        >
            <Icon source={image} size={24} color={colors.secondaryIcon} />
        </TouchableOpacity >
    )
}
Scan.propTypes = {
    image: PropTypes.any,
    onPress: PropTypes.func,
}

export default Scan
