import React from 'react'
import { ScrollView } from 'react-native'
import { useTheme } from 'react-native-themed-styles'
import { useSelector } from 'react-redux'
import moment from 'moment'

import { Section } from '../../../../../../Components'

import { getSupportTransport } from '../../../../Payment.Selectors'

import themedStyles from './TransportInfo.Styles'

const dateFormat = 'DD/MM/YYYY'

function TransportInfo() {
    const [styles] = useTheme(themedStyles)
    const {
        vehOwnership: { code: vehOwnership } = {},
        vehPlates,
        startAddr,
        startDateTime,
        startCity,
        startPoint: { code: startPoint } = {},
        destinAddr,
        destinCity,
        destinDateTime,
        destinPoint: { code: destinPoint } = {},
        isEscortRequired,
        isGoodsFlammable,
    } = useSelector(getSupportTransport)

    const transportSection = [
        {
            title: 'supporting_invoice_transport_button',
        },
        {
            label: 'supporting_invoice_transport_ownership',
            value: vehOwnership,
        },
        {
            label: 'supporting_invoice_transport_plate',
            value: vehPlates,
        },
        {
            title: 'supporting_invoice_transport_starting_point',
        },
        {
            label: 'supporting_invoice_transport_starting_address',
            value: startAddr,
        },
        {
            label: 'demo_city_label',
            value: startCity,
        },
        {
            label: 'supporting_invoice_transport_starting_point',
            value: startPoint,
        },
        {
            label: 'certificate_start_date_label',
            value: moment(startDateTime).format(dateFormat),

        },
        {
            title: 'supporting_invoice_transport_destination_point',
        },
        {
            label: 'supporting_invoice_transport_destination_address',
            value: destinAddr,
        },
        {
            label: 'demo_city_label',
            value: destinCity,
        },
        {
            label: 'supporting_invoice_transport_destination_point',
            value: destinPoint,
        },
        {
            label: 'supporting_invoice_transport_destination_date',
            value: moment(destinDateTime).format(dateFormat),
        },
        {
            title: 'menu_items_label',
        },
        {
            label: 'supporting_invoice_preview_dangerous',
            value: isGoodsFlammable ? 'cash_register_yes_button' : 'supporting_invoice_transport_button_question',
        },
        {
            label: 'supporting_invoice_preview_accompaniment',
            value: isEscortRequired ? 'cash_register_yes_button' : 'supporting_invoice_transport_button_question',
        },
    ]

    return (
        <ScrollView
            style={styles.container}
            contentContainerStyle={styles.content}
            showsVerticalScrollIndicator={false}
        >
            <Section data={transportSection} />
        </ScrollView>
    )
}

export default TransportInfo
