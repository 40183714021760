/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useContext, useEffect, useRef } from 'react'
import {
    SafeAreaView,
    View,
    TouchableOpacity,
    Dimensions,
    Animated,
    Easing,
} from 'react-native'
import PropTypes from 'prop-types'
import { useTheme } from 'react-native-themed-styles'
import ContentLoader, { Rect, Circle } from 'react-content-loader/native'

import { Header, Icon, Text, RadioInput } from '../../Components'

import Theme from '../../Themes/Theme'
import themedStyles from './SelectCustomerAddress.Styles'
import images from '../../Themes/Images'

const { width } = Dimensions.get('screen')

const AnimatedTouchable = Animated.createAnimatedComponent(TouchableOpacity)

function LoaderContent({ isFirst, isLast }) {
    const { colors } = useContext(Theme)

    const loaderStyle = {}
    if (isFirst) {
        loaderStyle.borderTopLeftRadius = 7
        loaderStyle.borderTopRightRadius = 7
    }
    if (isLast) {
        loaderStyle.borderBottomLeftRadius = 7
        loaderStyle.borderBottomRightRadius = 7
    }
    return (
        <View
            style={[
                {
                    backgroundColor: colors.tertiaryBackground,
                },
                loaderStyle,
            ]}>
            <ContentLoader
                speed={0.7}
                height={70}
                backgroundColor="#f3f3f3"
                foregroundColor="#fff">
                <Rect x="10" y="10" rx="3" ry="3" width="145" height="16" />
                <Rect x="10" y="35" rx="3" ry="3" width="130" height="14" />
                <Circle cx={`${width - 55}`} cy="30" r="10" />
            </ContentLoader>
        </View>
    )
}
LoaderContent.propTypes = {
    isFirst: PropTypes.bool,
    isLast: PropTypes.bool,
}

function Item({ item, index, selected, onPress, style }) {
    const [styles] = useTheme(themedStyles)
    const { colors } = useContext(Theme)

    return (
        <View style={[{ backgroundColor: colors.tertiaryBackground }, style]}>
            <TouchableOpacity style={style} onPress={onPress}>
                <View style={styles.listInfoContainer}>
                    <View style={styles.listDescContainer}>
                        <Text i18nKey={item?.businessName} numberOfLines={1} />
                        {item?.address?.addressLine1 && (
                            <Text
                                i18nKey={item?.address?.addressLine1}
                                weight="light"
                                color={colors.secondaryText}
                                size="footnote"
                            />
                        )}
                    </View>
                    <View style={styles.listPriceContainer}>
                        <View pointerEvents="none">
                            <RadioInput
                                index={index}
                                selected={selected}
                                onPress={onPress}
                                style={styles.selector}
                                color={colors.secondaryAccent}
                            />
                        </View>
                    </View>
                </View>
            </TouchableOpacity>
        </View>
    )
}
Item.propTypes = {
    item: PropTypes.object,
    index: PropTypes.number,
    selected: PropTypes.number,
    onPress: PropTypes.func,
    style: PropTypes.object,
}

function SelectCustomerAddress({ route: { params: { addresses, onSave, nipt, businessName } }, navigation: { goBack } }) {
    const [styles] = useTheme(themedStyles)
    const { colors } = useContext(Theme)

    const [selected, setSelected] = useState(-1)
    const listRef = useRef(null)

    const fabSize = useRef(new Animated.Value(0)).current

    useEffect(() => {
        const index = addresses.findIndex((x) => x?.issuerNUIS === nipt || x?.businessName === businessName)
        setSelected(index)
    }, [addresses])

    const animate = (val) => {
        Animated.timing(fabSize, {
            toValue: val,
            duration: 370,
            useNativeDriver: true,
            easing: Easing.inOut(Easing.cubic),
        }).start()
    }

    const scrollToTop = () => {
        animate(0)
        listRef.current.scrollToOffset({
            offset: 0,
        })
    }

    return (
        <SafeAreaView style={styles.container}>
            <Header
                title="customer_select_customer_address"
                image={images.back}
                onPress={() => goBack()}
            />
            <Animated.FlatList
                showsVerticalScrollIndicator={false}
                keyExtractor={(item, index) => 'row-' + index}
                getItemLayout={(dataa, index) => ({
                    length: 60,
                    offset: 61 * index,
                    index,
                })}
                ref={(ref) => (listRef.current = ref)}
                onMomentumScrollEnd={(event) => {
                    if (event.nativeEvent.contentOffset.y > 800) {
                        animate(1)
                    } else {
                        animate(0)
                    }
                }}
                data={addresses}
                style={styles.listStyle}
                contentContainerStyle={styles.listContainerStyle}
                renderItem={({ item, index }) => {
                    const style = {}
                    if (index === 0) {
                        style.borderTopLeftRadius = 7
                        style.borderTopRightRadius = 7
                    }
                    if (index === addresses.length - 1) {
                        style.borderBottomLeftRadius = 7
                        style.borderBottomRightRadius = 7
                    }
                    return (
                        <Item
                            key={'row-' + index}
                            index={index}
                            item={item}
                            selected={selected}
                            style={style}
                            onPress={() => {
                                setSelected(index)
                                onSave(item?.businessName, item?.address)
                                goBack()
                            }}
                        />
                    )
                }}
                ItemSeparatorComponent={() => <View style={styles.separator} />}
                refreshing={false}
            />
            <AnimatedTouchable
                onPress={scrollToTop}
                activeOpacity={0.5}
                style={[
                    styles.fab,
                    {
                        opacity: fabSize,
                        transform: [{ scale: fabSize }],
                    },
                ]}>
                <Icon
                    source={images.arrow_right}
                    size={20}
                    color={colors.tertiaryIcon}
                    style={styles.fabIcon}
                />
            </AnimatedTouchable>
        </SafeAreaView>
    )
}
SelectCustomerAddress.propTypes = {
    route: PropTypes.object,
    navigation: PropTypes.object,
}

export default SelectCustomerAddress
