import Color from 'color'

import { styleSheetFactory } from '../../Themes/Colors'

const styles = styleSheetFactory(colors => ({
  container: {
    flexGrow: 1,
    flex: 1,
  },
  inputContainer: {
    minHeight: 60,
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  itemSpacing: {
    marginVertical: 5,
  },
  touchableContainer: {
    backgroundColor: colors.tertiaryBackground,
    overflow: 'hidden',
    borderRadius: 7,
    marginVertical: 5,
    minHeight: 60,
    justifyContent: 'center',
  },
  rightContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  subContainer: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    padding: 15,
  },
  textContainer: {
    flexGrow: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    marginRight: 15,
  },
  saveButton: {
    height: 50,
    marginTop: 10,
    marginBottom: 30,
    marginHorizontal: 15,
  },
  dateButton: {
    height: 50,
    width: '100%',
    marginHorizontal: 15,
    marginTop: 15,
  },
  addButton: {
    marginTop: 5,
    marginBottom: 10,
  },
  selectableAddress: {
    paddingTop: 15,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  topSpacing: { marginTop: 15 },
  checkItemWrapper: {
    marginLeft: 10,
    marginVertical: 5,
  },
  separator: {
    height: 1,
    backgroundColor: colors.separator,
  },
  birthdayContainer: {
    minHeight: 60,
    justifyContent: 'center',
  },
  vehiclePlateHelper: {
    marginBottom: 20,
    marginTop: 5,
    textAlign: 'left',
    fontFamily: 'Vodafone Rg',
    fontSize: 14,
    color: colors.secondaryText,
  },
  vehiclePlateHelperItalic: {
    fontStyle: 'italic',
  },
  clearAddress: {
    borderRadius: 17,
    padding: 6,
    paddingHorizontal: 12,
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: colors.separatorBody,
  },
  addressLabelWrapper: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  rightSelfSpacing: { paddingRight: 10 },
  leftSpacing: { marginLeft: 5 },
  lineHeight: { lineHeight: 16 },
  leftSmallSpacing: { marginLeft: 3 },
  stateSelector: { marginBottom: 0, marginVertical: 5 },
  mediumTopSpacing: {
    marginTop: 10,
  },
  typeContainer: { marginHorizontal: 15, marginTop: 15 },
  typesGroup: { marginBottom: 15, marginTop: 10 },
}))

const itemStyles = styleSheetFactory(colors => ({
  container: {
    height: 70,
    backgroundColor: colors.tertiaryBackground,
    justifyContent: 'center',
    overflow: 'hidden',
  },
  firstItem_true: {
    borderTopLeftRadius: 7,
    borderTopRightRadius: 7,
  },
  lastItem_true: {
    borderBottomLeftRadius: 7,
    borderBottomRightRadius: 7,
  },
  touchable: {
    flexGrow: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    position: 'relative',
  },
  imageContainer: labelColor => {
    return {
      width: 70,
      height: '100%',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: Color(labelColor).fade(0.65).toString(),
    }
  },
  infoContainer: {
    flexGrow: 1,
    flexShrink: 1,
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
    padding: 15,
    paddingLeft: 5,
  },
  descContainer: {
    flexGrow: 1,
    flexShrink: 1,
  },
  priceContainer: {
    flexDirection: 'column',
    alignItems: 'baseline',
    flexShrink: 0,
    marginLeft: 10,
  },
}))


const hiddenItemStyles = styleSheetFactory(colors => ({
  wrapper: {
    backgroundColor: colors.accent,
    height: 70,
    justifyContent: 'center',
    alignItems: 'flex-end',
  },
  firstItem_true: {
    borderTopLeftRadius: 7.5,
    borderTopRightRadius: 7.5,
  },
  lastItem_true: {
    borderBottomLeftRadius: 7.5,
    borderBottomRightRadius: 7.5,
  },
  option: {
    width: 100,
    height: 70,
    justifyContent: 'center',
    alignItems: 'center',
  },
  optionFull: {
    flexDirection: 'row',
  },
  confirm: {
    flexGrow: 1,
    height: 70,
    flexDirection: 'row',
    paddingLeft: 15,
    alignItems: 'center',
  },
  confirmText: {
    marginLeft: 15,
  },
  cancel: {
    width: 40,
    height: 40,
    flexShrink: 0,
    marginVertical: 15,
    marginHorizontal: 15,
    borderRadius: 20,
    backgroundColor: colors.whiteRGBA(0.55),
    justifyContent: 'center',
    alignItems: 'center',
  },
  loader: {
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
}))

const stateitemStyles = styleSheetFactory(colors => ({
  container: {
    paddingVertical: 7.5,
  },
  itemSelected_false: {
    flexDirection: 'row',
    marginHorizontal: 15,
    borderRadius: 10,
    paddingVertical: 10,
    paddingHorizontal: 15,
    backgroundColor: colors.white,
    alignItems: 'center',
    height: 60,
  },
  itemSelected_true: {
    flexDirection: 'row',
    marginHorizontal: 15,
    borderRadius: 10,
    paddingVertical: 10,
    paddingHorizontal: 15,
    backgroundColor: colors.white,
    alignItems: 'center',
    height: 60,
    borderWidth: 1,
    borderColor: colors.secondaryAccent,
  },
  itemTextSelected_false: {
    flexGrow: 1,
    flexShrink: 1,
  },
  itemTextSelected_true: {
    flexGrow: 1,
    flexShrink: 1,
  },
  separator: {
    width: 1,
    backgroundColor: colors.disabled,
    marginHorizontal: 15,
    height: 40,
  },
  isDisabled_true: {
    backgroundColor: colors.secondaryBackround,
  },
  itemCode: {
    minWidth: 30,
    maxWidth: '40%',
  },


}))


export { itemStyles, hiddenItemStyles, stateitemStyles }

export default styles
