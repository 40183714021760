import { StyleSheet, Dimensions } from 'react-native'

import colors from '../../Themes/Colors'

const { width } = Dimensions.get('screen')

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.background,
  },
  scrollContainer: {
    flex: 1,
    paddingHorizontal: 15,
  },
  sectionTitle: {
    marginHorizontal: 15,
    marginTop: 30,
  },
  imagesColorContainer: {
    alignSelf: 'center',
    marginTop: 25,
    backgroundColor: colors.midGrey,
    borderRadius: 7,
    width: 132,
    height: 93,
    padding: 7.5,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  image: {
    width: 58.5,
    height: 78,
    justifyContent: 'center',
    alignItems: 'center',
  },
  galleryIcon: {
    width: 35,
    height: 35,
    resizeMode: 'contain',
    tintColor: colors.white,
  },
  seperator: {
    height: 78,
    width: 1.5,
    backgroundColor: colors.white,
  },
  color: {
    width: 58.5,
    height: 39,
    justifyContent: 'center',
    alignItems: 'center',
  },
  colorIcon: {
    width: 35,
    height: 35,
    resizeMode: 'contain',
  },
  nameContainer: {
    height: 60,
    backgroundColor: colors.white,
    marginHorizontal: 15,
    marginTop: 10,
    borderRadius: 7,
    paddingHorizontal: 17,
    paddingVertical: 12,
  },
  nameInput: {
    fontSize: 16,
    fontFamily: 'OpenSans-Regular',
    padding: 0,
    paddingBottom: 3.5,
    flexGrow: 1,
  },

  categoryContainer: {
    height: 70,
    backgroundColor: colors.white,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginHorizontal: 15,
    marginTop: 10,
    borderRadius: 7,
    paddingHorizontal: 17,
    paddingVertical: 12,
  },
  category: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },

  codeContainer: {
    height: 70,
    backgroundColor: colors.white,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginHorizontal: 15,
    marginTop: 10,
    marginBottom: 15,
    borderRadius: 7,
    paddingHorizontal: 17,
    paddingVertical: 12,
  },
  scanIcon: {
    width: 18,
    height: 18,
    resizeMode: 'contain',
  },

  descInput: {
    fontSize: 14,
    fontFamily: 'OpenSans-Light',
    backgroundColor: colors.white,
    padding: 12,
    margin: 15,
    marginTop: 0,
    borderRadius: 7,
  },
  buttonTitle: {
    textAlign: 'center',
    fontSize: 22,
    paddingVertical: 10,
    flex: 1,
  },
  primaryButton: {
    marginTop: 20,
  },
  plusIcon: {
    tintColor: colors.turquoise,
    backgroundColor: 'blue',
    fontSize: 15,
  },
  discountTypeContainer: {
    height: 60,
    // width: 60,
    flex: 1,
    marginVertical: 5,
    borderRadius: 7,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: colors.tertiaryBackground,
  },
  discountTypeContainerEnabled_true: {
    height: 60,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: colors.secondaryAccent,
  },
  discountType: {
    color: colors.primaryText,
  },
  discountTypeEnabled_true: {
    color: colors.white,
  },
  input: {
    flex: 5,
    flexGrow: 5,
    maxHeight:60,
  },
  discountValueContainer: {
    flex: 1,
    flexDirection: 'row',
    marginTop: 10,
    maxHeight:90,
  },
  percentageColors_type_PERCENTAGE: {
    backgroundColor: colors.secondaryAccent,
    borderColor: colors.transparent,
    color: colors.white,
    borderWidth: 0,
  },
  percentageColors_type_AMOUNT: {
    backgroundColor: colors.white,
    borderColor: colors.transparent,
    color: colors.secondaryAccent,
    borderWidth: 0,
  },
  amountColors_type_PERCENTAGE: {
    backgroundColor: colors.white,
    borderColor: colors.transparent,
    color: colors.secondaryAccent,
    borderWidth: 0,
  },
  amountColors_type_AMOUNT: {
    backgroundColor: colors.secondaryAccent,
    borderColor: colors.transparent,
    color: colors.white,
    borderWidth: 0,
  },

  modalContainer: {
    backgroundColor: colors.white,
    alignSelf: 'center',
    paddingBottom: 30,
    borderRadius: 6,
    minWidth: width - 80,
    maxWidth: 400,
  },
  closeContainer: {
    backgroundColor: colors.disabled,
    position: 'absolute',
    top: 0,
    right: 0,
    borderTopRightRadius: 7,
    borderBottomLeftRadius: 7,
    width: 75,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 10,
  },
  modalContent: {
    paddingHorizontal: 40,
    marginTop: 45,
  },
  modalIcon: {
    alignSelf: 'center',
    marginBottom: 5,
  },
  modalTitle: {
    marginVertical: 8,
  },
  modalButton: {
    marginTop: 15,
    marginHorizontal: 15,
  },

  sectionTitleContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 20,
    marginBottom: 10,
  },
  addNew: {
    height: 36,
    paddingHorizontal: 16,
  },

  separator: {
    backgroundColor: colors.separator,
    height: 1,
  },
  priceItem: {
    height: 60,
    backgroundColor: colors.tertiaryBackground,
    justifyContent: 'center',
    overflow: 'hidden',
  },
  firstItem_true: {
    borderTopLeftRadius: 7,
    borderTopRightRadius: 7,
  },
  lastItem_true: {
    borderBottomLeftRadius: 7,
    borderBottomRightRadius: 7,
  },
  rightSpacing: { marginRight: 10 },
  contentContainer: { paddingBottom: 10, paddingHorizontal: 0 },
})

const hiddenItemStyles = StyleSheet.create({
  wrapper: {
    backgroundColor: colors.accent,
    flex: 1,
    height: 60,
    justifyContent: 'center',
    alignItems: 'flex-end',
  },
  firstItem_true: {
    borderTopLeftRadius: 7.5,
    borderTopRightRadius: 7.5,
  },
  lastItem_true: {
    borderBottomLeftRadius: 7.5,
    borderBottomRightRadius: 7.5,
  },
  option: {
    width: 100,
    height: 60,
    justifyContent: 'center',
    alignItems: 'center',
  },
  optionFull: {
    flexDirection: 'row',
  },
  confirm: {
    flexGrow: 1,
    height: 60,
    flexDirection: 'row',
    paddingLeft: 15,
    alignItems: 'center',
  },
  confirmText: {
    marginLeft: 15,
  },
  cancel: {
    width: 40,
    height: 40,
    flexShrink: 0,
    marginVertical: 10,
    marginHorizontal: 15,
    borderRadius: 20,
    backgroundColor: colors.whiteRGBA(0.55),
    justifyContent: 'center',
    alignItems: 'center',
  },
  loader: {
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
})

export default styles
export { hiddenItemStyles }
