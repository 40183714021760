/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import {
  View,
  Dimensions,
  LayoutAnimation,
} from 'react-native'
import ContentLoader, { Rect } from 'react-content-loader/native'
import moment from 'moment'
import { useQuery } from '@apollo/client'
import { useTheme } from 'react-native-themed-styles'
import PropTypes from 'prop-types'

import {
  Text,
  SimpleList,
  Icon,
} from '../../../../Components'
import { INF } from '../../../../Utils/Helpers'
import { useLanguage } from '../../../../Utils/Language'

import { GET_INVOICE } from '../../EInvoiceDetails.Schema'
import themedStyles from '../../EInvoiceDetails.Styles'
import images from '../../../../Themes/Images'
import { colors } from '../../../../Themes'

import { layoutAnimConfig } from '../../../../Themes/Theme'

const { width } = Dimensions.get('screen')

const LANGUAGES = {
  al: 'sq',
  en: 'en-gb',
}

const statusIcons = {
  REFUSED: {
    image: images.refused,
    color: colors.accent,
  },
  DELIVERED: {
    image: images.waiting,
    color: colors.secondaryAccent,
  },
  PARTIALLY_PAID: {
    image: images.waiting,
    color: colors.secondaryAccent,
  },
  PAID: {
    image: images.waiting,
    color: colors.secondaryAccent,
  },
  ACCEPTED: {
    image: images.accepted,
    color: colors.green,
  },
}

function LoaderContent({ isFirst, isLast, style }) {
  const [styles] = useTheme(themedStyles)
  const loaderStyle = {}
  if (isFirst) {
    loaderStyle.borderTopLeftRadius = 7
    loaderStyle.borderTopRightRadius = 7
  }
  if (isLast) {
    loaderStyle.borderBottomLeftRadius = 7
    loaderStyle.borderBottomRightRadius = 7
    loaderStyle.borderBottomWidth = 0
  }
  return (
    <ContentLoader
      speed={0.7}
      height={70}
      style={Object.assign({}, styles.loaderContainer, loaderStyle, style)}
      backgroundColor="#e2e2e2"
      foregroundColor="#ecebeb">
      <Rect x="20" y="20" rx="3" ry="3" width="90" height="20" />
      <Rect x={`${width - 150}`} y="18" rx="3" ry="3" width="100" height="24" />
    </ContentLoader>
  )
}
LoaderContent.propTypes = {
  isFirst: PropTypes.bool,
  isLast: PropTypes.bool,
  style: PropTypes.object,
}

function ItemLoader() {
  return (
    <View>
      <LoaderContent isFirst />
      <LoaderContent />
      <LoaderContent />
      <LoaderContent />
      <LoaderContent />
      <LoaderContent />
      <LoaderContent />
      <LoaderContent />
      <LoaderContent isLast />
    </View>
  )
}

function BasicInfo({ id, setLoading, refetchOnFinish }) {
  const [styles] = useTheme(themedStyles)
  const lang = useLanguage()

  const [items, setItems] = useState([])

  const { data, refetch, loading } = useQuery(GET_INVOICE, { fetchPolicy: 'network-only', variables: { eic: id } })

  const renderRightComponent = (invoiceStatus) => {
    return (
      <View
        style={styles.listRightComponent}>
        <Icon
          source={statusIcons?.[invoiceStatus]?.image}
          color={statusIcons?.[invoiceStatus]?.color}
          size={24}
          style={styles.statusIcon}
        />
        <Text
          size="footnote"
          style={styles.itemRightLabel}
          i18nKey={invoiceStatus}
        />
      </View>
    )
  }

  const mapData = (unmappedData) => {
    const response = []
    response.push(
      {
        id: 1,
        leftText: 'einvoice_party_type_label',
        rightText: unmappedData?.PartyType,
      },
      {
        id: 2,
        leftText: 'einvoice_date_label',
        rightText: moment(unmappedData?.RecDateTime).locale(LANGUAGES[lang]).format('DD/MM/YYYY hh:mm A'),
      },
      {
        id: 10,
        leftText: 'einvoice_amount_label',
        rightText: `${INF.format(unmappedData?.Amount)}`,
        ratio: '1:2',
        rightTextProps: {
          numberOfLines: 1,
        },
      },
      {
        id: 3,
        leftText: 'einvoice_receipt_label',
        rightText: unmappedData?.DocNumber,
      },
      {
        id: 4,
        leftText: 'einvoice_deadline_label',
        rightText: moment(unmappedData?.DueDateTime).format('DD/MM/YYYY'),
      },
      {
        id: 5,
        leftText: 'einvoice_status_label',
        rightComponent: renderRightComponent(unmappedData?.Status),
      },
      {
        id: 6,
        leftText: 'einvoice_buyertin_label',
        rightText: unmappedData?.BuyerTin,
      },
      {
        id: 7,
        leftText: 'einvoice_sellertin_label',
        rightText: unmappedData?.SellerTin,
      },
      {
        id: 8,
        leftText: 'einvoice_EIC_label',
        rightText: unmappedData?.EIC,
      },
    )
    return response
  }

  useEffect(() => {
    const invoiceData = data?.getEinvoices?.eInvoices?.[0]?.attributes
    if (invoiceData) {
      LayoutAnimation.configureNext(layoutAnimConfig)
      setItems(mapData(invoiceData))
      setLoading(false)
    }
  }, [data])

  useEffect(() => {
    if (refetchOnFinish) {
      refetch()
    }
  }, [refetchOnFinish])

  return (
    <SimpleList
      DATA={items}
      containerStyle={styles.list}
      separatorStyle={styles.separatorStyle}
      initialNumToRender={20}
      refreshing={false}
      onRefresh={refetch}
      listFooterComponent={
        loading ? <ItemLoader /> : null
      }
    />
  )
}
BasicInfo.propTypes = {
  id: PropTypes.string,
  setLoading: PropTypes.func,
  refetchOnFinish: PropTypes.bool,
}

export default BasicInfo
