import React from 'react'
import { View } from 'react-native'
import PropTypes from 'prop-types'
import { useTheme } from 'react-native-themed-styles'
import Color from 'color'

import Text from '../Text'

import ContentManager from '../../Utils/ContentManager'

import style from './ItemStatus.styles'

function ItemStatus({ color, title, code, statusStyle }) {
    const [styles] = useTheme(style)
    const { translate } = ContentManager

    return (
        <View style={[styles.container(Color(color).fade(0.86).toString()), statusStyle]}>
            <Text weight="light" size="footnote" color={color} i18nKey={code ? `${translate(title)} (${code})` : `${translate(title)}`} />
        </View>
    )
}

ItemStatus.propTypes = {
    color: PropTypes.string,
    title: PropTypes.string,
    code: PropTypes.string,
    statusStyle: PropTypes.object,
}

export default ItemStatus

