import React from 'react'
import { View, TouchableOpacity } from 'react-native'
import PropTypes from 'prop-types'
import { useTheme } from 'react-native-themed-styles'

import Icon from '../Icon'

import themedStyles from './OptionsSwitch.Styles'

function OptionsSwitch({
  items,
  selected,
  select,
  onOptionPress,
}) {
  const [styles] = useTheme(themedStyles)

  const handleOptionPress = (item) => {
    select(item)
    onOptionPress()
  }

  return (
    <View style={styles.container}>
      {items.map((item, index) => (
        <TouchableOpacity
          key={index}
          onPress={() => handleOptionPress(item.title)}
          style={[styles.item, styles['itemSelected_' + (selected === item.title)]]}
        >
          <Icon source={item.icon} size={28} style={styles['iconSelected_' + (selected === item.title)]} />
        </TouchableOpacity>
      ))}
    </View>
  )
}

OptionsSwitch.defaultProps = {
  items: [],
  onOptionPress: () => { },
}

OptionsSwitch.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
    }),
  ),
  selected: PropTypes.string,
  select: PropTypes.func,
  onOptionPress: PropTypes.func,
}

export default OptionsSwitch
