import { gql } from '@apollo/client'

export const LIST_INVOICES = gql`
  query getEinvoices(
    $partyType: PartyType, 
    $fromDateTime: String, 
    $toDateTime: String, 
    $limit: Int,
    $nextToken: String
    ) {
    getEinvoices(getEinvoicesInput: {
      partyType: $partyType,
      fromDateTime: $fromDateTime,
      toDateTime: $toDateTime,
      limit: $limit,
      nextToken: $nextToken
    }) {
      nextToken
      eInvoices {
        attributes {
          EIC
          DocNumber
          Amount
          Status
          PartyType
          RecDateTime
          SellerTin
          BuyerTin
        },
      },
    },
  }
`

export const LIST_MY_INVOICES = gql`
  query listMyEinvoices(
    $filter: Filter, 
    $nextToken: String,
    $beginTime: String, 
    $endTime: String,
    $limit: Int,
    $locId: String,
    $cashRegisterId: String
    ) {
      listMyEinvoices(listEinvoicesInput: {
      filter: $filter,
      nextToken: $nextToken,
      beginTime: $beginTime,
      endTime: $endTime,
      limit: $limit
    }
    requestedLocationAndCashRegister: {
      locationId: $locId,
      cashRegisterId: $cashRegisterId
    }
    ) {
      nextToken
      myEinvoices {
        ublInvoice {
          id
          issueDate
          status
          invoiceDocumentCode
          note
          employeeEmail
          legalMonetaryTotal {
            payable {
              amount
              currency
            }
            lineExtension {
              amount
            }
          }
          status
        },
      },
    },
  }
`

export const DELETE_INVOICE = gql`
  mutation deleteEinvoice(
       $id: String!,
       $locId: String,
       $cashRegisterId: String
  ){
    deleteEinvoice(
      id: $id
      requestedLocationAndCashRegister: {
        locationId: $locId,
        cashRegisterId: $cashRegisterId
      }
      ){
      id
    }
  }
`

export const CHANGE_STATUS = gql`
  mutation changeEinvoiceStatus(
      $eics: [String!]!,
      $status: EinvoiceStatus
  ){
    changeEinvoiceStatus(changeEinvoiceStatusInput:{
      eics: $eics,
      status: $status
    }){
      statusCode
    }
  }
`

export const CONVERT_UBL_TO_ORDER = gql`
query ublToOrder($ubl: UblInvoiceInput!,  $locId: String, $cashRegisterId: String) {
  ublToOrder(
    ubl: $ubl
    requestedLocationAndCashRegister: {
      locationId: $locId,
      cashRegisterId: $cashRegisterId
    }
    ){
    totalMoney{
      amount
      currency
    }
    totalDiscountMoney{
      amount
      currency
    }
    totalTaxMoney{
      amount
      currency
    }
    appliedDiscounts{
      id
      name
      discountType
      percentage
      appliedMoney{
        amount
        currency
      }
    }
    orderGroupedTaxes {
      VATRate
      NumOfItems
      PriceBefVat {
        amount
        currency
      }
      VATAmt {
        amount
        currency
      }
    }
    lineItems{
      itemName
      quantity
      modifiers{
        name
        appliedMoney{
          amount
          currency
        }
      }
      appliedDiscounts{
        name
        appliedMoney{
          amount
          currency
        }
        percentage
      }
      totalMoney{
        amount
        currency
      }
      basePriceMoney{
        amount
        currency
      }
    }
  }
}
`

export const GET_MY_INVOICE = gql`
  query getMyEinvoice($id: String!, $locId: String, $cashRegisterId: String){
    getMyEinvoice(
      id: $id
      requestedLocationAndCashRegister: {
        locationId: $locId,
        cashRegisterId: $cashRegisterId
      }
      ){
      myEinvoice {
        ublInvoice {
          dueDate
          invoiceDocumentCode
          invoiceProcessCode
          buyerReference
          orderReference {
            id
            salesOrderId
          }
          contractDocumentReference {
            id
          }
          additionalDocumentReferences {
            id
            description
            uri
            binObj {
              filename
              contents
              mime
            }
          }
          projectReference {
            id
          }
          delivery {
            actualDeliveryDate
            deliveryLocation {
              address {
                streetName
                cityName
                postalCode
                countryCode
              }
            }
            deliveryPartyName {
              name
            }
          }
          docCurrencyCode
          taxCurrencyCode
          invoicePeriod {
            startDate
            endDate
            descriptionCode
          }
          supplierParty {
            issuerNUIS
            name
            address {
              streetName
              cityCode
              countryCode
            }
            taxSchema
            registrationName
            contact
          }
          customerParty {
            issuerNUIS
            name
            address {
              streetName
              cityCode
              countryCode
            }
            taxSchema
            registrationName
            contact
          }
          paymentMeans {
            code
            instructionNote
            card {
              primaryAccountId
              networkId
            }
            payeeFinantialAccount
          }
          taxTotal {
            taxAmount {
              amount
              currency
            }
            taxSubtotal {
              taxableAmount {
                amount
                currency
              }
              taxAmount {
                amount
                currency
              }
              taxCategory {
                id
                percent
                taxSchema
              }
            }
          }
          legalMonetaryTotal {
            lineExtension {
              amount
              currency
            }
            taxExclusive {
              amount
              currency
            }
            taxInclusive {
              amount
              currency
            }
            payable {
              amount
              currency
            }
            charge {
              amount
              currency
            }
            allowance {
              amount
              currency
            }
          }
          allowances {
            chargeIndicator
            chargeReason
            amount {
              amount
              currency
            }
            taxCategory {
              id
              percent
              taxSchema
            }
            baseAmount {
              amount
              currency
            }
            percent
          }
          invoiceLine {
            id
            quantity
            lineExtension {
              amount
              currency
            }
            allowances {
              chargeIndicator
              chargeReason
              amount {
                amount
                currency
              }
              taxCategory {
                id
                percent
                taxSchema
              }
              baseAmount {
                amount
                currency
              }
              percent
            }
            item {
              name
              sellerIdentification
              description
              price {
                amount
                currency
              }
              taxCategory {
                id
                percent
                taxSchema
              }
              baseQuantity {
                quantity
                unitCode
              }
            }
          }
        }
      }
    }
  }
`
