/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState, useEffect } from 'react'
import { SafeAreaView, View, FlatList, TouchableOpacity } from 'react-native'
import PropTypes from 'prop-types'
import { v1 as uuidv1 } from 'uuid'
import { useTheme } from 'react-native-themed-styles'

import { Text, RadioInput, Header } from '../../Components'

import { useLanguage, useChangeLanguage } from '../../Utils/Language'

import Theme from '../../Themes/Theme'

import themedStyles from './Language.Styles'

import images from '../../Themes/Images'

function Item({
  item,
  index,
  selected,
  onPress,
}) {
  const [styles] = useTheme(themedStyles)
  const { colors } = useContext(Theme)

  let propStyle = {}
  if (item.isFirst) {
    propStyle = styles.listFirst
  }
  if (item.isLast) {
    propStyle = styles.listLast
  }
  const style = Object.assign({}, styles.default, styles.list, propStyle)

  return (
    <TouchableOpacity style={[{ backgroundColor: colors.tertiaryBackground }, style]} onPress={onPress}>
      <View style={styles.listInfoContainer}>
        <View style={styles.listDescContainer}>
          <Text i18nKey={item.title} numberOfLines={1} size="footnote" />
        </View>
        <View style={styles.radioContainer}>
          <View pointerEvents="none">
            <RadioInput
              index={index}
              selected={selected}
              onPress={onPress}
              style={styles.selector}
              color={colors.secondaryAccent}
            />
          </View>
        </View>
      </View>
    </TouchableOpacity>
  )
}
Item.propTypes = {
  item: PropTypes.object,
  index: PropTypes.number,
  selected: PropTypes.number,
  onPress: PropTypes.func,
}

function Language({ navigation }) {
  const [styles] = useTheme(themedStyles)
  const lang = useLanguage()
  const setLanguage = useChangeLanguage()

  const [selected, setSelected] = useState(0)

  useEffect(() => {
    const index = items.findIndex(el => el.type === lang)
    setSelected(index)
  }, [lang])

  const items = [
    {
      id: uuidv1(),
      title: 'settings_albanian_label',
      type: 'al',
      isFirst: true,
    },
    {
      id: uuidv1(),
      title: 'settings_english_label',
      type: 'en',
      isLast: true,
    },
  ]

  return (
    <SafeAreaView style={styles.container}>
      <Header title="settings_language_label" image={images.back} onPress={() => navigation.goBack()} />
      <FlatList
        showsVerticalScrollIndicator={false}
        keyExtractor={(item, index) => 'row-' + index}
        getItemLayout={(data, index) => ({
          length: 60,
          offset: 61 * index,
          index,
        })}
        data={items}
        style={styles.listStyle}
        contentContainerStyle={styles.listContainerStyle}
        renderItem={({ item, index }) => {
          return <Item
            key={'row-' + index}
            index={index}
            item={item}
            selected={selected}
            onPress={() => {
              setSelected(index)
              setLanguage(`${items[index].type}`)
              navigation.goBack()
            }}
          />
        }}
        ItemSeparatorComponent={() => <View style={styles.separator} />}
      />
    </SafeAreaView >
  )
}
Language.propTypes = {
  navigation: PropTypes.object,
}

export default Language
