import { Dimensions } from 'react-native'

import { styleSheetFactory } from '../../Themes/Colors'

const { width } = Dimensions.get('screen')

const styles = styleSheetFactory(colors => ({
    container: {
        flex: 1,
    },
    header: {
        flexGrow: 1,
        paddingHorizontal: 15,
        justifyContent: 'center',
        alignItems: 'center',
    },
    footer: {
        paddingHorizontal: 15,
        paddingBottom: 40,
        marginTop: 30,
    },
    orContainer: {
        paddingHorizontal: 10,
        flexDirection: 'row',
        justifyContent: 'center',
        marginVertical: 20,
    },
    separator: {
        backgroundColor: colors.separatorBody,
        height: 1,
        alignSelf: 'center',
        position: 'absolute',
        width: width - 30,
    },
    orText: {
        alignSelf: 'center',
        paddingHorizontal: 15,
        backgroundColor: colors.background,
    },
    logo: {
        marginBottom: 20,
    },
    button: {
        height: 50,
    },
    demoButton: {
        height: 50,
        marginTop: 20,
    },
    splashImage: {
        width: 200,
        height: 200,
        resizeMode: 'contain',
    },
    bottomSelfSpacing: { paddingBottom: 15 },
    horizontalSelfSpacing: { paddingHorizontal: 30 },
}))

export default styles
