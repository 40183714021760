import React, { useState, useContext } from 'react'
import {
  SafeAreaView,
} from 'react-native'
import PropTypes from 'prop-types'
import { useTheme } from 'react-native-themed-styles'

import { Text, Button, InputRow, Header } from '../../Components'

import Theme from '../../Themes/Theme'
import images from '../../Themes/Images'
import themedStyles from './SetDiscountValue.Styles'

const discountTypes = {
  VARIABLE_PERCENTAGE: 'VARIABLE_PERCENTAGE',
  VARIABLE_AMOUNT: 'VARIABLE_AMOUNT',
}
const unitTypes = {
  VARIABLE_PERCENTAGE: '%',
  VARIABLE_AMOUNT: 'Lekë',
}

function SetDiscountValue({
  navigation: { goBack },
  route: { params: { onSave = () => { }, name, type } },
}) {
  const [styles] = useTheme(themedStyles)
  const { colors } = useContext(Theme)

  const [value, setValue] = useState('')
  const [hasError, setError] = useState(false)

  const changeValue = text => {
    if (/^\d*(.\d*)?$/.test(text)) {
      if (type === discountTypes.VARIABLE_PERCENTAGE) {
        const numericValue = parseFloat(text || 0)
        if (numericValue >= 0 && numericValue <= 100) {
          setValue(text)
          if (hasError) {
            setError(false)
          }
        }
      } else {
        setValue(text)
        if (hasError) {
          setError(false)
        }
      }
    }
  }

  const formatResponse = numericValue => {
    if (type === discountTypes.VARIABLE_AMOUNT) {
      return {
        amount: numericValue,
        currency: unitTypes.VARIABLE_AMOUNT,
      }
    }
    return numericValue + ''
  }

  const onDone = () => {
    const numericValue = parseFloat(value)
    if (!isNaN(numericValue) && numericValue > 0) {
      onSave(formatResponse(numericValue))
    } else {
      setError(true)
    }
  }


  return (
    <SafeAreaView style={styles.container}>
      <Header
        title={name}
        image={images.back}
        onPress={goBack}
      />
      <InputRow
        label="discount_value_label"
        placeholder="0"
        value={value}
        onChangeText={changeValue}
        inputLabel={unitTypes[type]}
        style={styles['inputError_' + hasError]}
        autoFocus
        inputType="decimal-pad"
        onSubmitEditing={onDone}
      />
      {hasError && <Text i18nKey="discount_error_message" align="right" color={colors.error} style={styles.errorText} />}
      <Button
        backgroundColor={colors.accent}
        title="discount_button_apply"
        color={colors.white}
        borderColor={colors.transparent}
        style={styles.chargeButton}
        disabled={!value}
        onPress={onDone}
      />
    </SafeAreaView>
  )
}

SetDiscountValue.propTypes = {
  navigation: PropTypes.object,
  route: PropTypes.object,
}

export default SetDiscountValue
