/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useContext, useEffect, useRef, useCallback } from 'react'
import {
  View,
  SafeAreaView,
  FlatList,
  TouchableOpacity,
  LayoutAnimation,
  ImageBackground,
  TextInput,
} from 'react-native'
import PropTypes from 'prop-types'
import Color from 'color'
import { useQuery, useMutation } from '@apollo/client'
import { useTheme } from 'react-native-themed-styles'

import moment from 'moment'

import { isEmpty } from 'lodash'

import {
  Button,
  Text,
  CheckBox,
  Icon,
  Header,
  ListLoader,
  ErrorModal,
  TouchableInput,
  DatePickerModal,
  Switch,
  BottomSheetModal,
} from '../../Components'
import { getErrorMessage, useConfig } from '../../Utils'
import ContentManager from '../../Utils/ContentManager'

import { GET_MY_INVOICE, REGISTER_EINVOICE, CALCULATE_UBL } from './RefundEinvoice.Schema'

import Theme, { layoutAnimConfig } from '../../Themes/Theme'
import themedStyles, { selectStyles } from './RefundEinvoice.Styles'
import images from '../../Themes/Images'

const getAbreviation = (name = '') => name?.match(/\b(\w{1})/g)?.slice(0, 2)?.join('')?.toUpperCase() || ''
const getFadedColor = (color = 'transparent', fadeValue = 0.65) => Color(color || 'transparent').fade(fadeValue).toString()


const dateCodes = [
  {
    code: '3',
    description: 'date_codes_3_description',
    type: '3',
  },
  {
    code: '35',
    description: 'date_codes_35_description',
    type: '35',
  },
  {
    code: '432',
    description: 'date_codes_432_description',
    type: '432',
  },
]

function ImageOrAbreviation({
  name,
  imageUrl,
  labelColor,
}) {
  const [styles] = useTheme(themedStyles)

  if (imageUrl) {
    return (
      <ImageBackground
        source={{ uri: imageUrl }}
        style={styles.listImageContainer}
      />
    )
  }

  return (
    <View
      style={[
        styles.listImageContainer,
        { backgroundColor: getFadedColor(labelColor) },
      ]}>
      <Text
        size="h5"
        color={labelColor}
        i18nKey={getAbreviation(name)}
      />
    </View>
  )
}
ImageOrAbreviation.propTypes = {
  name: PropTypes.string,
  imageUrl: PropTypes.string,
  labelColor: PropTypes.string,
}

function SelectType({
  data = [],
  closeModal,
  selected,
  select,
  title,
  searchValue,
  setSearchValue,
  placeholder,
}) {
  const [styles] = useTheme(selectStyles)
  const { colors } = useContext(Theme)
  const { translate } = ContentManager

  return (
    <>
      <View style={styles.header}>
        <Text
          i18nKey={title}
          weight="bold"
          size="h4"
          color={colors.accent}
        />
        <TouchableOpacity onPress={closeModal}>
          <Icon source={images.close} color={colors.secondaryIcon} size={24} />
        </TouchableOpacity>
      </View>
      <View style={styles.searchContainer}>
        <Icon
          source={images.search}
          size={20}
          style={styles.searchIcon}
        />
        <TextInput
          placeholder={translate(placeholder)}
          value={searchValue}
          onChangeText={setSearchValue}
          placeholderTextColor={colors.disabled}
          spellCheck={false}
          textContentType="none"
          underlineColorAndroid="transparent"
          style={styles.searchInput}
        />
        {Boolean(searchValue) && (
          <TouchableOpacity
            onPress={() => setSearchValue('')}
            style={styles.clearSearchContainer}
          >
            <Icon source={images.close} color={colors.secondaryIcon} size={15} />
          </TouchableOpacity>
        )}
      </View>
      <FlatList
        data={data?.filter((item) =>
          item?.code?.toLowerCase().includes(searchValue.toLowerCase())
        )}
        onStartShouldSetResponder={() => true}
        decelerationRate={0.8}
        keyExtractor={(item) => item.code}
        renderItem={({ item }) => (
          <View style={styles.verticalSelfSpacing}>
            <TouchableOpacity
              onPress={() => {
                select(item)
                closeModal()
              }}
              style={[
                styles['itemSelected_' + (selected?.code === item?.code)],
                styles['isDisabled_' + item?.disabled],
              ]}
              disabled={item?.disabled}
              activeOpacity={0.6}
            >
              <Text
                i18nKey={item.code}
                align="center"
                style={styles.itemCode}
              />
              <View style={styles.separator} />
              <Text
                i18nKey={item.description}
                size="footnote"
                style={styles['itemTextSelected_' + (selected?.code === item?.code)]}
              />
            </TouchableOpacity>
          </View>
        )}
        style={styles.listStyle}
        contentContainerStyle={styles.listContainerStyle}
      />
    </>
  )
}
SelectType.propTypes = {
  data: PropTypes.array,
  select: PropTypes.func,
  selected: PropTypes.object,
  closeModal: PropTypes.func,
  title: PropTypes.string,
  searchValue: PropTypes.func,
  setSearchValue: PropTypes.func,
  placeholder: PropTypes.func,
  icon: PropTypes.any,
}

function Item({
  item: {
    item: {
      name,
      price,
      taxCategory,
    } = {},
    quantity,
    refundableQuantity,
    lineExtension,
    totalQuantity,
    allowances,
  } = {},
  checked,
  onPress,
  increase,
  decrease,
  changeQuantity,
  style,
}) {
  const [styles] = useTheme(themedStyles)
  const { colors } = useContext(Theme)

  let description = ''
  allowances.map((el, ind) => {
    const amount = el?.amount
    if (ind === 0) {
      description = `${el.chargeReason} (${amount?.amount} ${amount?.currency})`
    } else {
      description = `${description}\n${el.chargeReason} (${amount?.amount} ${amount?.currency})`
    }
  })

  return (
    <View style={[styles.listInfoContainer, style]}>
      <View style={styles.listDescContainer}>
        <Text
          i18nKey={name}
          numberOfLines={1}
        />
        <Text
          i18nKey={`${refundableQuantity} X ${(lineExtension?.amount / totalQuantity).toFixed(2) +
            ' ' + price?.currency}`}
        />
        <Text
          size="footnote"
          weight="light"
          align="right"
          i18nKey={`${taxCategory?.id}-${taxCategory?.taxSchema} ${taxCategory?.percent} %`}
        />
        <Text
          i18nKey={description}
          weight="light"
          color={colors.secondaryText}
          size="footnote"
        />
      </View>
      <View style={styles.listPriceContainer}>
        {checked && <View style={styles.quantityContainer}>
          <TouchableOpacity
            style={styles.quantityButton}
            onPress={decrease}
            disabled={parseFloat(quantity) === 0 || quantity === ''}
          >
            <Icon source={images.minusIcon} size={20} color={(parseFloat(quantity) === 0 || quantity === '') ? colors.disabled : colors.secondaryIcon} />
          </TouchableOpacity>
          <TextInput
            placeholder={refundableQuantity.toString()}
            placeholderTextColor={colors.disabled}
            value={quantity.toString()}
            onChangeText={changeQuantity}
            textAlign="center"
            style={styles.quantityInput}
            keyboardType="numeric"
            autoCapitalize="none"
          />
          <TouchableOpacity
            style={styles.quantityButton}
            onPress={increase}
            disabled={(parseFloat(quantity) === parseFloat(refundableQuantity)) || quantity === ''}
          >
            <Icon source={images.plusIcon} size={20} color={((parseFloat(quantity) === parseFloat(refundableQuantity)) || quantity === '') ? colors.disabled : colors.secondaryIcon} />
          </TouchableOpacity>
        </View>}
      </View>
      <CheckBox
        checked={checked}
        tintColors={{
          true: colors.secondaryAccent,
          false: colors.placeholder,
        }}
        onValueChange={onPress}
      />
    </View>
  )
}
Item.propTypes = {
  item: PropTypes.object,
  checked: PropTypes.bool,
  onPress: PropTypes.func,
  style: PropTypes.object,
  increase: PropTypes.func,
  decrease: PropTypes.func,
  changeQuantity: PropTypes.func,
}

function List({ items, checkboxStates, toggleState, decrease, increase, changeQuantity, loading }) {
  const [styles] = useTheme(themedStyles)

  return (
    <FlatList
      showsVerticalScrollIndicator={false}
      keyExtractor={(item, index) => 'row-' + item.id}
      getItemLayout={(dataa, index) => ({
        length: 60,
        offset: 61 * index,
        index,
      })}
      data={items}
      style={styles.listStyle}
      contentContainerStyle={styles.listContainerStyle}
      extraData={[checkboxStates]}
      renderItem={({ item, index }) => {
        const style = {}
        if (index === 0) {
          style.borderTopLeftRadius = 7
          style.borderTopRightRadius = 7
        }
        if (index === items.length - 1) {
          style.borderBottomLeftRadius = 7
          style.borderBottomRightRadius = 7
        }
        return (
          <Item
            key={'row-' + index}
            index={index}
            item={item}
            checked={Boolean(checkboxStates?.[item.id])}
            decrease={() => decrease(item)}
            increase={() => increase(item)}
            changeQuantity={(text) => changeQuantity(item, text)}
            onPress={() => toggleState(item)}
            style={style}
          />
        )
      }}
      ListEmptyComponent={
        loading ? <ListLoader loading={loading} /> : <Text
          i18nKey="receipt_no_refund_items"
          align="center"
          weight="bold"
          style={styles.emptyComponent}
        />
      }
      ItemSeparatorComponent={() => (
        <View style={styles.separatorContainer}>
          <View style={styles.separator} />
        </View>
      )}
      refreshing={false}
    />
  )
}
List.propTypes = {
  items: PropTypes.array,
  checkboxStates: PropTypes.array,
  toggleState: PropTypes.func,
  loading: PropTypes.bool,
  increase: PropTypes.func,
  decrease: PropTypes.func,
  changeQuantity: PropTypes.func,
}

function RefundEinvoice({
  route: {
    params: {
      id,
      primaryEinvoiceId,
    },
  },
  navigation: {
    navigate,
    goBack,
  },
}) {
  const { switchLocation: { deviceId, locationId } } = useConfig()

  const [styles] = useTheme(themedStyles)
  const { colors } = useContext(Theme)
  const { translate } = ContentManager

  const [isCheckAll, setCheckAll] = useState(false)
  const [checkboxStates, setCheckboxStates] = useState({})

  const [primaryData, setPrimaryData] = useState({})
  const [lineItems, setLineItems] = useState([])
  const [itemsToRefund, setItemsToRefund] = useState([])
  const [loading, setLoading] = useState(true)
  const [confirmed, setConfirmed] = useState(false)

  const [buttonLoading, setButtonLoading] = useState(true)
  const data1 = useRef([])

  const [eInvoiceError, setEInvoiceError] = useState({
    isVisible: false,
    errorMessage: '',
  })
  const [shouldHaveBillingPeriod, setShouldHaveBillingPeriod] = useState(false)
  const [dates, setDates] = useState({
    dueDate: '',
    startDate: '',
    endDate: '',
    shown: false,
    willUpdate: '',
    tempDate: '',
  })
  const [effectiveVatModal, setEffectiveModal] = useState(false)
  const [searchValue, setSearchValue] = useState('')
  const [effectiveVat, setEffectiveVat] = useState({})

  const { data } = useQuery(GET_MY_INVOICE, { fetchPolicy: 'network-only', variables: { id: primaryEinvoiceId, locId: locationId, cashRegisterId: deviceId } })
  const [registerInvoice] = useMutation(REGISTER_EINVOICE)
  const [calculateUbl] = useMutation(CALCULATE_UBL)

  useEffect(() => {
    const einvoiceData = data?.getMyEinvoice?.myEinvoice?.ublInvoice
    if (einvoiceData) {
      setPrimaryData(einvoiceData)
      const res = mapData(einvoiceData?.invoiceLine)
      LayoutAnimation.configureNext(layoutAnimConfig)
      setLoading(false)
      setButtonLoading(false)
      setLineItems(res)
    }
  }, [data])


  const showDatesModal = (willUpdate) => {
    setDates(prev => ({
      ...prev,
      shown: true,
      willUpdate,
      tempDate: prev[willUpdate],
    }))
  }

  const mapNote = (prevNote) => {
    let note = {}
    prevNote?.map((element) => {
      const itemValue = element.substring(element.indexOf('=') + 1)
      const itemKey = element.substring(0, element.indexOf('='))
      const noteitem = { [itemKey]: itemValue }
      note = { ...note, ...noteitem }
    })
    return note
  }

  const toggleAllStates = () => {
    const val = isCheckAll
    setCheckAll(!val)
    if (val) {
      setCheckboxStates((prev) => {
        lineItems.map((item) => {
          prev[item.id] = false
        })
        return { ...prev }
      })
      data1.current = []
      setItemsToRefund(data1.current)
    } else {
      data1.current = []
      setItemsToRefund(data1.current)
      setCheckboxStates((prev) => {
        lineItems.map((item) => {
          if (!prev[item.id]) {
            prev[item.id] = item
          }
        })
        return { ...prev }
      })
      primaryData?.invoiceLine?.filter((obj) => {
        const note = mapNote(obj?.note)
        const refundableQuantity = note?.remainingQuantity || 0
        if (refundableQuantity !== 0) {
          return obj
        }
      })
        .map((item, i) => {
          const initNote = []
          item?.note?.map((el) => initNote.push(el))
          initNote.push(`refId=${item?.id}`)
          const note = mapNote(item?.note)
          const refundableQuantity = note?.remainingQuantity || 0
          data1.current.push({
            ...item,
            totalQuantity: item.quantity,
            quantity: refundableQuantity,
            refundableQuantity,
            note: initNote,
          })
        })
      setItemsToRefund(data1.current)
      setLineItems(prev => {
        const newLineItems = prev.map(item => ({
          ...item,
          quantity: item.refundableQuantity,
        }))
        return newLineItems
      })
    }
  }

  const checkAll = () => {
    if (Object.values(checkboxStates).every((value) => value !== false)) {
      setCheckAll(true)
    } else {
      setCheckAll(false)
    }
  }

  const mapData = (unmappedData) => {
    const res = []
    const checkBoxes = {}
    unmappedData?.map((item, index) => {
      const initNote = []
      item?.note?.map((el) => initNote.push(el))
      initNote.push(`refId=${item?.id}`)
      const note = mapNote(item?.note)
      const refundableQuantity = note?.remainingQuantity || 0
      if (refundableQuantity > 0) {
        checkBoxes[`${item?.id}`] = false
        res.push({
          ...item,
          id: `${item?.id}`,
          totalQuantity: item.quantity,
          quantity: refundableQuantity,
          refundableQuantity,
          note: initNote,
        })
      }
    })
    setCheckboxStates(checkBoxes)
    return res
  }

  const toggleState = (item) => {
    setCheckboxStates((prev) => {
      if (prev[item.id]) {
        const index = data1.current.findIndex((x) => x.id === item.id)
        prev[item.id] = false
        data1.current.splice(index, 1)
      } else {
        data1.current.push({
          ...item,
          id: item.id,
        })
        prev[item.id] = item
      }
      return { ...prev }
    })
    setItemsToRefund(data1.current)
    checkAll()
  }

  const refundInvoice = () => {
    if (confirmed) {
      setButtonLoading(true)
      itemsToRefund.map((item, index) => {
        const lineItem = { ...item }
        const keysToRemove = ['totalQuantity', 'refundableQuantity']
        keysToRemove.forEach((element) => {
          lineItem.quantity = lineItem.refundableQuantity
          delete lineItem[element]
        })
        itemsToRefund[index] = lineItem
      })
      const note = mapNote(primaryData?.note)
      const notes = primaryData?.note?.filter(item => item.includes('note='))

      const ubl = {
        ...primaryData,
        invoiceLine: itemsToRefund,
        billingReference: {
          invoiceDocumentReference: {
            id: id,
            issued: note?.IssueDateTime.substring(0, note?.IssueDateTime.indexOf('T')),
          },
        },
        note: [
          `IICRef=${note?.IIC}`,
          `IssueDateRef=${note?.IssueDateTime}`,
          `refId=${primaryEinvoiceId}`,
          `CurrencyExchangeRate=${note?.CurrencyExchangeRate}`,
          ...notes,
        ],
        invoiceDocumentCode: 'CORRECTEDINVOICE',
        invoiceProcessCode: 'P10_CORRECTIVE_INVOICE',
      }

      calculateUbl({
        variables: {
          ublInput: {
            ublInvoice: {
              ...ubl,
            },
          },
        },
      })
        .then((res) => {
          registerInvoice({
            variables: {
              locId: locationId,
              cashRegisterId: deviceId,
              eInvoiceInput: {
                ublInvoice: {
                  ...res.data.calculateUbl.ublInvoice,
                  ...shouldHaveBillingPeriod && {
                    invoicePeriod: {
                      startDate: moment(dates.startDate).format('YYYY-MM-DD'),
                      endDate: moment(dates.endDate).format('YYYY-MM-DD'),
                      descriptionCode: effectiveVat?.code,
                    },
                  },
                },

              },
            },
          })
            .then((result) => {
              setConfirmed(false)
              setButtonLoading(false)
              const itemDetails = result?.data?.registerEinvoice?.ublInvoice
              const mappedNote = mapNote(itemDetails?.note)
              const invoiceId = mappedNote?.id

              navigate('RefundDetails', {
                id: invoiceId,
                title: `${itemDetails?.id} - ${translate('refund_label')}`,
              })
            })
            .catch((er) => {
              setConfirmed(false)
              setButtonLoading(false)
              const errorMessage = getErrorMessage(er)
              if (errorMessage === 'TaxpayerDoesNotExist') {
                setEInvoiceError({
                  isVisible: true,
                  errorMessage: 'einvoice_opcode_error_description',
                })
              } else if (errorMessage === 'GovError') {
                setEInvoiceError({
                  isVisible: true,
                  errorMessage: er?.message,
                })
              } else {
                setEInvoiceError({
                  isVisible: true,
                  errorMessage: errorMessage,
                })
              }
            })
        })
        .catch((er) => {
          setConfirmed(false)
          setButtonLoading(false)
          setEInvoiceError({
            isVisible: true,
            errorMessage: getErrorMessage(er),
          })
        })
    } else {
      setConfirmed(true)
    }
  }

  const buttonProps = {
    buttonTitle: {
      true: 'einvoice_confirm_button',
      false: 'orders_refund_reason_button',
    },
  }

  const increaseQuantity = (item) => {
    if (data1.current.find((obj) => obj.id === item.id)) {
      const index = data1.current.findIndex((obj) => obj.id === item.id)
      data1.current[index].refundableQuantity = parseFloat(data1.current[index].refundableQuantity) + 1
    }
    setItemsToRefund(data1.current)
    setLineItems(prev => {
      if (prev.find((obj) => obj.id === item.id)) {
        const index = prev.findIndex((obj) => obj.id === item.id)
        prev[index].quantity = parseFloat(prev[index].quantity) + 1
      }
      return [...prev]
    })
  }

  const decreaseQuantity = (item) => {
    if (data1.current.find((obj) => obj.id === item.id)) {
      const index = data1.current.findIndex((obj) => obj.id === item.id)
      data1.current[index].refundableQuantity = parseFloat(data1.current[index].refundableQuantity) - 1
    }
    setItemsToRefund(data1.current)
    setLineItems(prev => {
      if (prev.find((obj) => obj.id === item.id)) {
        const index = prev.findIndex((obj) => obj.id === item.id)
        prev[index].quantity = parseFloat(prev[index].quantity) - 1
      }
      return [...prev]
    })
  }

  const replaceAt = (string, index, replacement) => {
    return string.substr(0, index) + replacement + string.substr(index + replacement.length)
  }

  const modifyQuantity = (item, val) => {
    let newString = val
    const indexOfComa = val.indexOf(',')
    if (indexOfComa >= 0) {
      newString = replaceAt(val, indexOfComa, '.')
    }
    if (/^\d{0,12}(\.\d{0,2})?$/.test(newString)) {
      if (data1.current.find((obj) => obj.id === item.id)) {
        const index = data1.current.findIndex((obj) => obj.id === item.id)
        data1.current[index].refundableQuantity = newString
      }
      setItemsToRefund(data1.current)

      setLineItems(prev => {
        if (prev.find((obj) => obj.id === item.id)) {
          const index = prev.findIndex((obj) => obj.id === item.id)
          prev[index].quantity = newString
        }
        return [...prev]
      })
    }
  }

  const getIsDisableDeliveryPeriod = useCallback(
    () => {
      if (shouldHaveBillingPeriod) {
        if (dates.startDate === '' || dates.endDate === '' || isEmpty(effectiveVat)) {
          return true
        } else {
          return false
        }
      }
      return false
    },
    [shouldHaveBillingPeriod, dates, effectiveVat],
  )


  const renderDeliveryDate = () => {
    return (
      <>
        <View style={[styles.AccessItem]}>
          <TouchableOpacity
            onPress={() => {
              LayoutAnimation.configureNext(layoutAnimConfig)
              setShouldHaveBillingPeriod(!shouldHaveBillingPeriod)
              setDates({
                startDate: '',
                endDate: '',
                shown: false,
                willUpdate: '',
                tempDate: '',
              })
              setEffectiveVat({})
            }}
            style={styles.touchable} activeOpacity={0.5}>
            <View style={styles.accessTextContainer}>
              <Text
                i18nKey="einvoice_section_delivery_info"
                weight="bold"
                numberOfLines={2}
                style={styles.name}
              />
            </View>
            <View pointerEvents="none" style={styles.radioInput}>
              <Switch
                value={shouldHaveBillingPeriod}
                color={colors.secondaryAccent}
              />
            </View>
          </TouchableOpacity>
        </View>
        {shouldHaveBillingPeriod && <View style={styles.deliviryWrapper}>
          <View style={styles.buttonWrapper}>
            <TouchableInput
              onPress={() => showDatesModal('startDate')}
              value={dates?.startDate ? moment(dates?.startDate).format('DD/MM/YYYY') : ''}
              label="einvoice_start_date_label"
              icon={images.calendarBlack}
              style={styles.startDateInput}
              autoCorrect={false}
              autoCapitalize="none"
              editable={false}
            />
            <TouchableInput
              onPress={() => showDatesModal('endDate')}
              value={dates?.endDate ? moment(dates?.endDate).format('DD/MM/YYYY') : ''}
              disabled={!dates.startDate}
              label="einvoice_end_date_label"
              icon={images.calendarBlack}
              autoCorrect={false}
              autoCapitalize="none"
              editable={false}
              style={styles.endDateInput}
            />
          </View>
          <Text
            i18nKey="einvoice_date_note"
            weight="bold"
            size="footnote"
            style={styles.sectionNote}
            align="left"
            color={colors.secondaryAccent}
          />
          <TouchableInput
            onPress={() => setEffectiveModal(true)}
            label="einvoice_effective_vat_label"
            value={translate(effectiveVat.description || '')}
            icon={images.filledDownArrow}
            iconSize={16}
            autoCorrect={false}
            autoCapitalize="none"
            editable={false}
          />
        </View>}
      </>
    )
  }

  return (
    <SafeAreaView style={styles.container}>
      <Header
        title="header_issue_refund"
        image={images.back}
        onPress={() => goBack()}
      />
      {renderDeliveryDate()}
      <Text i18nKey="receipt_section_items_service" weight="bold" style={styles.itemsSectionTitle} />

      {lineItems?.[0]?.refundableQuantity > 0 ? (
        <View
          style={styles.checkAllSection}>
          <Text i18nKey="orders_refund_items_title" />
          <CheckBox
            checked={isCheckAll}
            onValueChange={() => toggleAllStates()}
            tintColors={{
              true: colors.secondaryAccent,
              false: colors.placeholder,
            }}
            style={styles.checkbox}
          />
        </View>
      ) : null}
      <List
        items={lineItems?.filter(item => parseFloat(item?.refundableQuantity) > 0)}
        checkboxStates={checkboxStates}
        toggleState={toggleState}
        decrease={decreaseQuantity}
        increase={increaseQuantity}
        changeQuantity={modifyQuantity}
        loading={loading}
      />
      <View style={styles.footerComponent}>
        <Text
          style={styles.itemsInfo}
          i18nKey="orders_refund_items_description"
        />
        <Button
          title={buttonProps.buttonTitle[confirmed]}
          variant={
            buttonLoading
              || !Object.values(checkboxStates).some((value) => value !== false)
              || (lineItems?.filter(item => (item.quantity === ''
                || parseFloat(item.quantity) <= 0
                || parseFloat(item.quantity) > parseFloat(item.refundableQuantity)) && checkboxStates[item.id]).length > 0) || getIsDisableDeliveryPeriod()
              ? 'disabled'
              : confirmed
                ? 'active'
                : 'preActive'
          }
          loader={buttonLoading}
          style={styles.fullIssueButton}
          onPress={refundInvoice}
        />
      </View>
      <ErrorModal
        isVisible={eInvoiceError.isVisible}
        onClosePress={() => setEInvoiceError(prev => ({
          ...prev,
          isVisible: false,
        }))}
        title="orders_error_label"
        icon={images.warningIcon}
        description={eInvoiceError.errorMessage}
        primaryText="einvoice_cancel_modal_button"
        primaryAction={() => setEInvoiceError(prev => ({
          ...prev,
          isVisible: false,
        }))}
      />
      <BottomSheetModal
        isVisible={effectiveVatModal}
        onClose={() => setEffectiveModal(false)}
      >
        <SelectType
          data={dateCodes}
          title="einvoice_effective_vat_modal_header"
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          placeholder="einvoice_effective_vat_search_placeholder"
          selected={effectiveVat.effectiveVat}
          select={obj => setEffectiveVat(obj)}
          closeModal={() => setEffectiveModal(false)}
        />
      </BottomSheetModal>
      <DatePickerModal
        preselectedDate={dates?.tempDate === '' ? moment().format('YYYY-MM-DD') : moment(dates?.tempDate).format('YYYY-MM-DD')}
        onClose={() => setDates(prev => ({
          ...prev,
          shown: false,
        }))}
        isVisible={dates.shown}
        onDateChange={(date) => {
          setDates(prev => {
            if (
              prev.willUpdate === 'endDate'
              && (!moment(dates.startDate).isSame(moment(date), 'month')
                || moment(date).isBefore(moment(dates.startDate)))
            ) {
              return ({
                ...prev,
              })
            }
            return ({
              ...prev,
              tempDate: date,
            })
          })
        }}
        onButtonPress={() => {
          setDates(prev => {
            if (prev.willUpdate === 'startDate') {
              return ({
                ...prev,
                startDate: prev.tempDate,
                endDate: '',
                shown: false,
              })
            } else if (
              prev.willUpdate === 'endDate'
              && (!moment(dates.startDate).isSame(moment(prev.tempDate), 'month')
                || moment(prev.tempDate).isBefore(moment(dates.startDate)))
            ) {
              return ({
                ...prev,
                shown: false,
              })
            }
            return ({
              ...prev,
              [prev.willUpdate]: prev.tempDate,
              shown: false,
            })
          })
        }}
      />
    </SafeAreaView>
  )
}
RefundEinvoice.propTypes = {
  route: PropTypes.object,
  navigation: PropTypes.object,
}

export default RefundEinvoice
