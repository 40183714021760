import { gql } from '@apollo/client'


export const OPERATORS_LIST = gql`
query getOperators {
  getOperators {
    operators {
      attributes {
        OprCode
        OprFirstName
        OprID
        OprValidFrom
        OprValidTo
        OprLastName
      }
    }
  }
}
`


export const LIST_CASH_REGISTERS = gql`
  query listCashRegisters(
    $nextToken: String
    $status: CashRegisterStatus
    $filter: Filter
    $limit: Int
    $locId: String, 
    $cashRegisterId: String
  ) {
    listCashRegisters(
      nextToken: $nextToken, 
      status: $status, 
      filter: $filter, 
      limit: $limit
      requestedLocationAndCashRegister: {
        locationId: $locId,
        cashRegisterId: $cashRegisterId
      }
      ) {
      cashRegisters {
        id
        name
        deviceId
        status
        createdAt
        locationId
        requestUserEmail
        deviceName
        TCRCode
        enableWebAccess
        enableApiAccess
      }
      nextToken
    }
  }
`

export const DELETE_CASH_REGISTER = gql`
  mutation deleteDevice($cashRegisterId: String!) {
    deleteCashRegister(cashRegisterId: $cashRegisterId) {
      id
    }
  }
`

export const GENERATE_TCR_CODE = gql`
  mutation registerTCR($cashRegisterId: String!, $businUnitCode: String) {
    registerTCR(cashRegisterId: $cashRegisterId, businUnitCode: $businUnitCode) {
      TCRCode,
      businUnitCode
    }
  }
`

export const UPDATE_CASH_REGISTER = gql`
  mutation updateCashRegister(
    $id: String!
    $name: String
    $locationId: String
    $status: CashRegisterUpdateStatus
    $employeeToEnable: [String]
    $employeeToDelete: [String]
    $enableWebAccess: Boolean
    $enableApiAccess: Boolean
    $validTo: String
  ) {
    updateCashRegister(
      cashRegisterUpdateInput: {
        id: $id
        name: $name
        locationId: $locationId
        status: $status
        employeeToEnable: $employeeToEnable
        employeeToDelete: $employeeToDelete
        enableApiAccess: $enableApiAccess
        enableWebAccess:  $enableWebAccess
        validTo: $validTo

      }
    ) {
      id
      name
    }
  }
`

export const DELETE_TCR_ID = gql`
  mutation deleteTCR(
    $cashRegisterId: String!
    $TCRCode: String!
    $locId: String
    $cashRegisterIdd: String
  ) {
    deleteTCR( 
    cashRegisterId: $cashRegisterId
    TCRCode: $TCRCode
    requestedLocationAndCashRegister: {
      locationId: $locId,
      cashRegisterId: $cashRegisterIdd
    }
    ) {
      TCRCode
    }
  }
`

export const GET_CASH_REGISTER = gql`
  query getCashRegister($searchBy: SearchBy!) {
    getCashRegister(searchBy: $searchBy) {
      id
      name
      deviceName
      deviceId
      os
      model
      TCRCode
      requestUserEmail
      locationId
      isTablet
      status
      businUnitCode
      enableWebAccess
      enableApiAccess
      totalCashInvoices
      totalNoncashInvoices
      employees {
        id
        firstName
        lastName
        role
        color
        email
        userStatus
      }
    }
  }
`
