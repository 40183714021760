import { styleSheetFactory } from '../../Themes/Colors'

const styles = styleSheetFactory(colors => ({
  container: {
    flexGrow: 1,
    flex: 1,
    backgroundColor: colors.background,
  },
  list: {
    flex: 1,
    marginTop: 15,
  },
  inlineButton1: {
    flex: 1,
    height: 60,
    backgroundColor: colors.white,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 7,
    flexDirection: 'column',
  },
  inlineButton2: {
    flex: 1,
    height: 60,
    marginLeft: 7.5,
    backgroundColor: colors.white,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 7,
    flexDirection: 'column',
  },
  buttonIcon: {
    marginRight: 10,
  },
  inlineButtons: {
    flexDirection: 'row',
    marginVertical: 10,
    alignItems: 'center',
    paddingHorizontal: 15,
  },
  inlineButtonsFooter: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 15,
    marginVertical: 15,
  },
  loaderContainer: {
    backgroundColor: colors.white,
    borderBottomColor: colors.separator,
    borderBottomWidth: 1,
    height: 60,
  },
  listRightComponent: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  statusIcon: {
    marginRight: 5,
  },
  separatorStyle: {
    height: 1,
    flex: 1,
    backgroundColor: colors.lightGrey,
  },

  tabView: {
    backgroundColor: colors.background,
    marginTop: 15,
    paddingTop: 0,
  },
  refundButton: {
    height: 50,
    marginHorizontal: 15,
    marginVertical: 10,
  },
  listContainerStyle: {
    flex: 1,
    marginTop: 30,
  },
  content: {
    position: 'relative',
    flexGrow: 1,
    paddingHorizontal: 15,
    paddingVertical: 20,
  },
  separatorContainer: {
    height: 1,
    backgroundColor: colors.tertiaryBackground,
    paddingLeft: 70,
  },
  separator: {
    height: 1,
    backgroundColor: colors.separator,
  },
  fab: {
    position: 'absolute',
    bottom: 225,
    right: 15,
    height: 50,
    width: 50,
    borderRadius: 25,
    backgroundColor: colors.tertiaryAccent,
    justifyContent: 'center',
    alignItems: 'center',
    shadowColor: colors.black,
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.22,
    shadowRadius: 2.22,
    elevation: 3,
  },
  fabIcon: {
    transform: [{ rotate: '-90deg' }],
  },
  primaryInvoiceSection: {
    paddingHorizontal: 15,
  },
  primaryInvoice: {
    marginBottom: 5,
  },
  invValContainer: {
    marginVertical: 20,
    paddingVertical: 10,
    borderTopColor: colors.separator,
    borderTopWidth: 1,
    justifyContent: 'flex-start',
  },
  qrCode: {
    alignSelf: 'center',
    marginVertical: 25,
    padding: 15,
    backgroundColor: colors.background,
  },
  fullButton: {
    height: 50,
    justifyContent: 'flex-start',
    paddingHorizontal: 0,
    marginBottom: 30,
  },
  invoiceButton: {
    marginHorizontal: 15,
  },
  sectionStyle: {
    marginTop: 20,
  },
  printButton: {
    flex: 1,
    height: 60,
    marginRight: 7.5,
    flexDirection: 'column',
  },
  webStyles: { overflow: 'hidden', height: 0 },
  noteSectionContainer: {
    backgroundColor: colors.tertiaryBackground,
    marginVertical: 8,
    borderRadius: 7,
  },
  expandableContainer: {
    paddingVertical: 20,
    paddingHorizontal: 15,
  },
  noteContainer: {
    paddingVertical: 20,
    paddingHorizontal: 15,
    marginVertical: 0,
  },
}))

const itemStyles = styleSheetFactory(colors => ({
  itemContainer: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    backgroundColor: colors.tertiaryBackground,
    minHeight: 70,
    overflow: 'hidden',
  },
  listImageContainer: {
    width: 70,
    height: 70,
    flexShrink: 0,
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'flex-start',
  },
  listInfoContainer: {
    flexGrow: 1,
    flexShrink: 1,
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    flexDirection: 'row',
    paddingRight: 15,
  },
  listPriceContainer: {
    flexShrink: 0,
    marginTop: 5,
  },
  listFirst: {
    borderTopLeftRadius: 7,
    borderTopRightRadius: 7,
  },
  listLast: {
    borderBottomLeftRadius: 7,
    borderBottomRightRadius: 7,
  },
  listDescContainer: {
    flexGrow: 1,
    flexShrink: 1,
    paddingLeft: 10,
    marginVertical: 5,
  },
}))

const primaryStyles = styleSheetFactory(colors => ({
  itemContainer: {
    backgroundColor: colors.tertiaryBackground,
    borderRadius: 7,
    paddingVertical: 5,
  },
  listDescContainer1: {
    flexGrow: 1,
    flexShrink: 1,
    marginHorizontal: 15,
    flexWrap: 'wrap',
  },
  itemSubcontainer: {
    flexDirection: 'row',
    alignItems: 'center',
    height: 60,
  },
  iconStyles: {
    marginLeft: 15,
  },
  statusIcon: {
    marginBottom: 3,
  },
  description: {
    fontSize: 12,
    lineHeight: 15,
  },
  subdescription: {
    fontSize: 12,
    lineHeight: 13,
  },
  amountContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    padding: 15,
    flexDirection: 'row',
  },
  amount: {
    marginRight: 5,
  },
  fiscItemContainer: {
    backgroundColor: colors.tertiaryBackground,
    borderTopColor: colors.separator,
    borderTopWidth: 1,
  },
  itemStyle: {
    flexDirection: 'row',
    alignItems: 'center',
    height: 60,
  },
}))

export { itemStyles, primaryStyles }

export default styles
