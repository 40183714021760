import {Dimensions} from 'react-native'

import {styleSheetFactory} from '../../Themes/Colors'

const {width: SCREEN_WIDTH, height: SCREEN_HEIGHT} = Dimensions.get('screen')

const styles = styleSheetFactory(colors => ({
  container: {
    position: 'relative',
    height: 50,
    width: 46,
    overflow: 'visible',
    marginLeft: 15,
  },
  touchableBackdrop: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    borderRadius: 7,
    backgroundColor: colors.tertiaryBackground,
  },
  optionsToggleContainer: {
    position: 'absolute',
    height: 48,
    width: 44,
    top: 1,
    right: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: colors.tertiaryBackground,
    borderRadius: 7,
    zIndex: 10,
  },
  optionsToggleContainer_true: {
    zIndex: 20,
  },
  optionsToggleBackdrop: {
    position: 'absolute',
    height: 50,
    width: 46,
    backgroundColor: colors.tertiaryBackground,
    borderRadius: 7,
    zIndex: 8,
  },
  optionsToggleBackdrop_true: {
    zIndex: 18,
  },
  optionsContainer: {
    position: 'absolute',
    right: 0,
    top: 0,
    zIndex: 9,
    flex: 1,
    minWidth: 220,
    maxHeight: (SCREEN_HEIGHT * 2) / 3,
    borderRadius: 7,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: colors.separator,
    backgroundColor: colors.tertiaryBackground,
  },
  optionsContainer_true: {
    zIndex: 19,
  },
  optionsTitle: {
    height: 50,
    justifyContent: 'center',
    paddingRight: 55,
    paddingLeft: 15,
  },
  options: {
    // paddingVertical: 15,
    // borderTopWidth: 1,
    // borderStyle: 'solid',
    // borderColor: colors.separator,
  },
  option: {
    flexDirection: 'row',
    height: 40,
    paddingHorizontal: 15,
    alignItems: 'center',
    flexGrow: 1,
  },
  optionDisabled_true: {
    opacity: 0.3,
  },
  icon: {
    marginRight: 15,
    tintColor: colors.secondaryIcon,
  },
  backdrop: {
    position: 'absolute',
    top: -SCREEN_WIDTH - SCREEN_HEIGHT,
    right: -SCREEN_WIDTH - SCREEN_HEIGHT,
    width: (SCREEN_WIDTH + SCREEN_HEIGHT) * 3,
    height: (SCREEN_WIDTH + SCREEN_HEIGHT) * 3,
    backgroundColor: colors.backgroundRGBA(0.6),
    zIndex: 11,
  },
  separator: {
    height: 1,
    flex: 1,
    backgroundColor: colors.separator,
  },
}))

export default styles
