import React from 'react'
import { View } from 'react-native'
import { useTheme } from 'react-native-themed-styles'
import PropTypes from 'prop-types'

import Text from '../Text'
import { INF } from '../../Utils/Helpers'

import themedStyles from './ExchangeRate.Styles'

function ExchangeRate(props) {
  const [styles] = useTheme(themedStyles)

  const {
    fromValue,
    fromCurrency,
    exchangeRate,
    toCurrency,
    style,
  } = props

  if (exchangeRate) {
    const toValue = fromValue * exchangeRate

    return (
      <View style={[styles.container, style]}>
        <View style={styles.fromContainer}>
          {/* <View style={styles.flagContainer}>
            <Icon source={fromFlag} style={styles.flag} />
          </View> */}
          <Text
            size="footnote"
            i18nKey="payment_price_currency"
            placeHolders={[INF.format(fromValue), fromCurrency]}
          />
        </View>
        <View style={styles.separator} />
        <View style={styles.toContainer}>
          {/* <View style={styles.flagContainer}>
            <Icon source={toFlag} style={styles.flag} />
          </View> */}
          <Text
            size="footnote"
            i18nKey="payment_price_currency"
            placeHolders={[INF.format(toValue), toCurrency]}
          />
        </View>
      </View>
    )
  }
  return null
}

ExchangeRate.propTypes = {
  fromValue: 0,
  fromCurrency: '',
  exchangeRate: 1,
  toCurrency: '',
}

ExchangeRate.propTypes = {
  fromFlag: PropTypes.any,
  fromValue: PropTypes.number,
  fromCurrency: PropTypes.string,
  exchangeRate: PropTypes.number,
  toFlag: PropTypes.any,
  toCurrency: PropTypes.string,
  style: PropTypes.object,
}

export default ExchangeRate
