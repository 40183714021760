/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react'
import { View, ScrollView, Platform } from 'react-native'
import PropTypes from 'prop-types'
import { useTheme } from 'react-native-themed-styles'
import LottieView from 'lottie-react-native'
import { useLazyQuery } from '@apollo/client'
import { useSelector, useDispatch } from 'react-redux'

import { getTotal } from '../../../Checkout/Checkout.Selectors'
import { Button, Text, Header } from '../../../../Components'
import { printReceipt } from '../../../../Utils/Order'
import { useLocationDetails } from '../../../../Utils/AuthDetails'
import { useConfig, useTemplateLanguage } from '../../../../Utils'
import { images, animations } from '../../../../Themes'

import { getPaymentCashMoney, getPaymentChangeMoney, getPaymentCustomer, getPaymentId } from '../../Payment.Selectors'
import { dismissPaymentError, displayPaymentError, updatePaymentAttribute } from '../../Payment.Actions'

import { GET_TRANSACTION } from '../../Payment.Schema'
import { PAYMENT_STEPS } from '../../Payment.Config'

import TotalAndChange from '../Components/TotalAndChange'

import themedStyles from './Print.Styles'

const PRINTER_ERROR_TITLE = {
  COMMAND_NOT_SEND: 'printers_error_description',
  undefined: 'checkount_error_message',
}
const PRINTER_ERROR_DESC = {
  COMMAND_NOT_SEND: 'printers_receipt_error_description',
  undefined: 'printers_check_warning_message',
}
function Print({
  goBackCustom,
  navigate,
  otherCurrency,
  invoicingMethod,
}) {

  const { switchLocation: { deviceId, locationId } } = useConfig()

  const [styles] = useTheme(themedStyles)
  const { euRate, NIPT, name, address } = useLocationDetails()
  const { translate } = useTemplateLanguage()

  const customer = useSelector(getPaymentCustomer)
  const cash = useSelector(getPaymentCashMoney)
  const paymentId = useSelector(getPaymentId)
  const total = useSelector(getTotal)
  const change = useSelector(getPaymentChangeMoney)

  const dispatchAction = useDispatch()

  const dismissError = () => dispatchAction(dismissPaymentError())

  const displayError = (
    title = '',
    desc = '',
    primaryText = '',
    primaryAction = null,
    secondaryText = '',
    secondaryAction = null,
    isDismissable = true,
  ) => {
    dispatchAction(displayPaymentError({
      isVisible: true,
      title: title,
      desc: desc,
      primaryText: primaryText,
      primaryAction: primaryAction,
      secondaryText: secondaryText,
      secondaryAction: secondaryAction,
      isDismissable: isDismissable,
    }))
  }

  const setPaymentStatus = (value) => dispatchAction(updatePaymentAttribute('paymentStatus', value))
  const setChange = (value) => dispatchAction(updatePaymentAttribute('change', value))
  const setLastTransaction = (value) => dispatchAction(updatePaymentAttribute('lastTransaction', value))

  const anim = useRef()
  const [retryPrint, setRetryPrint] = useState({
    active: false,
    transaction: {},
  })

  const [getTransactionDetails, { error, data }] = useLazyQuery(GET_TRANSACTION, {
    fetchPolicy: 'network-only',
  })

  useEffect(() => {
    if (paymentId) {
      setTimeout(() => {
        getTransactionDetails({
          variables: {
            locationId: 'J11111111A',
            locId: locationId,
            cashRegisterId: deviceId,
            transactionId: paymentId,
          },
        })
      }, 3000)
    }
  }, [paymentId])

  useEffect(() => {
    const errorMessage = error?.message
    if (errorMessage) {
      displayError(
        'checkount_error_message',
        errorMessage,
        'drawer_retry_button',
        () => {
          dismissError()
          anim.current?.play()
          getTransactionDetails({
            variables: {
              locationId: 'J11111111A',
              locId: locationId,
              cashRegisterId: deviceId,
              transactionId: paymentId,
            },
          })
        },
        'orders_button_go_back',
        () => {
          dismissError()
          goBackCustom()
        },
        false,
      )
    }
  }, [error])

  const mapCustomer = (customerData) => {
    let customerDataFormatted = {}
    if (customerData?.id) {
      const {
        id: customerId,
        issuerNUIS,
        emailAddress,
        businessName,
        givenName,
        familyName,
        reference,
        address: {
          addressLine1,
          country,
          administrativeDistrictLevel1,
        },
        type,
      } = customerData
      customerDataFormatted = {
        customerId,
        emailAddress,
        address: `${addressLine1}, ${administrativeDistrictLevel1}, ${country}`,
      }
      if (type === 'INDIVIDUAL') {
        const { name: idName, value: idValue } = reference || {}

        customerDataFormatted = { ...customerDataFormatted, title: givenName + ' ' + familyName, idName, idValue }
      } else {
        customerDataFormatted = { ...customerDataFormatted, title: businessName, nipt: issuerNUIS }
      }
    }
    return customerDataFormatted
  }

  const print = (transaction, cashMoney, exchangeRate) => {
    const customerFormatted = mapCustomer(customer)
    anim.current?.play()
    printReceipt(
      transaction,
      cashMoney,
      otherCurrency?.rate,
      otherCurrency?.currencyCode,
      NIPT,
      name,
      address,
      customerFormatted,
      translate
    )
      .then(() => setPaymentStatus(PAYMENT_STEPS.success))
      .catch((e) => {
        displayError(
          PRINTER_ERROR_TITLE[e.message] || PRINTER_ERROR_TITLE.undefined,
          PRINTER_ERROR_DESC[e.message] || e.message,
          'checkout_button_connect',
          () => {
            dismissError()
            navigate('PrinterSetUp')
            setRetryPrint({
              active: true,
              transaction: transaction,
            })
          },
          'checkout_button_skip',
          () => {
            dismissError()
            setPaymentStatus(PAYMENT_STEPS.success)
          },
          false,
        )
      })
  }

  useEffect(() => {
    const transaction = data?.getTransaction
    if (transaction?.id) {
      const { amount } = transaction.tender?.cashDetails?.changeBackMoney || {}
      const { exRate } = transaction.tender?.cashDetails?.currency || {}
      setChange(amount * exRate)
      setLastTransaction(transaction)
      if (Platform.OS === 'web') {
        setPaymentStatus(PAYMENT_STEPS.success)
      } else {
        print(transaction, cash, euRate)
      }
    }
  }, [data])

  return (
    <>
      <Header
        title="checkout_payment_label"
        image={images.closeIcon}
        onPress={goBackCustom}
      />
      <ScrollView contentContainerStyle={styles.fillRemaining}>
        <View style={styles.detailsContainer}>
          <View style={styles.animationContainer}>
            <LottieView
              ref={(ref) => {
                anim.current = ref
              }}
              source={animations.print}
              autoPlay
              loop={false}
              resizeMode="cover"
              style={styles.printAnimation}
            />
          </View>
          <View style={styles.details}>
            {change ? (
              <TotalAndChange
                total={parseFloat(total || 0)}
                cash={parseFloat(cash || 0)}
                secondaryCurrency={otherCurrency?.type}
                rate={otherCurrency?.rate}
              />
            ) : (
              <Text
                i18nKey={invoicingMethod === 'CASH' ? 'payment_calculating_change' : 'drawer_processing_label'}
                size="h1"
                align="center"
              />
            )}
          </View>
        </View>
        <View style={styles.buttonContainer}>
          {retryPrint.active ? (
            <Button
              title="checkout_print_title"
              variant="active"
              style={styles.printButton}
              onPress={() => {
                print(retryPrint.transaction, cash, euRate)
                setRetryPrint({
                  active: false,
                  transaction: {},
                })
              }}
            />
          ) : (
            <Text
              size="h4"
              i18nKey={Platform.OS === 'web' ? 'payment_fiscalizing_description' : 'checkout_printing_description'}
              align="center"
            />
          )}
        </View>
      </ScrollView>
    </>
  )
}
Print.propTypes = {
  goBackCustom: PropTypes.func,
  navigate: PropTypes.func,
  otherCurrency: PropTypes.object,
  invoicingMethod: PropTypes.string,
}

export default Print
