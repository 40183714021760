/* eslint-disable react-native/no-inline-styles */
import React from 'react'
import { SafeAreaView, View } from 'react-native'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import ListContainer from '../../Components/ListContainer'

import {
  Icon,
  Header,
  Text,
} from '../../Components'

import { getProductCatalog } from '../ProductCatalog/ProductCatalog.Selectors'

import styles from './Items.Styles'
import images from '../../Themes/Images'


function Items({ route: {
  params: {
    isGeneral,
  } = {},
}, navigation: {
  goBack,
  navigate,
} }, props) {

  const { businessName, address } = useSelector(getProductCatalog)

  const listItems = [
    {
      id: '9c4195a8-7861-11ea-bc55-0242ac130003',
      title: 'item_label_all',
      rightComponent: <Icon source={images.arrow_right} style={styles.arrow} size={20} />,
      image: images.list_item,
      onPress: () => navigate('AllItems'),
      style: styles.itemList,
    },
    {
      id: '9c419698-7861-11ea-bc55-0242ac130004',
      title: 'item_label_categories',
      rightComponent: <Icon source={images.arrow_right} style={styles.arrow} size={20} />,
      image: images.categories,
      onPress: () => navigate('Categories'),
      style: styles.itemList,
    },
    {
      id: '9c419698-7861-11ea-bc55-0242ac130005',
      title: 'item_label_modifiers',
      rightComponent: <Icon source={images.arrow_right} style={styles.arrow} size={20} />,
      image: images.modifiers,
      onPress: () => navigate('Modifiers'),
      style: styles.itemList,
    },
    {
      id: '9c419698-7861-11ea-bc55-0242ac130006',
      title: 'item_label_discounts',
      rightComponent: <Icon source={images.arrow_right} style={styles.arrow} size={20} />,
      image: images.discounts,
      onPress: () => navigate('Discounts'),
      style: styles.itemList,
    },
    {
      id: '9c419698-7861-11ea-bc55-0242ac130007',
      title: 'item_label_units',
      rightComponent: <Icon source={images.arrow_right} style={styles.arrow} size={20} />,
      image: images.units,
      onPress: () => navigate('Units'),
      style: styles.itemList,
    },
  ]
  return (
    <>
      <SafeAreaView style={styles.container}>
        <Header
          title="header_items"
          image={images.back}
          onPress={() => goBack()}
          customBusinessName={businessName}
          customAddress={address}

        />
        <View style={styles.noteWrapper}>
          <View style={styles.infoWrapper}>
            <Icon size={20} source={images.info} style={styles.infoIcon} />
            <Text weight="bold" i18nKey="cash_register_note" />
          </View>
          <Text size="footnote" weight="light" i18nKey={isGeneral ? 'product_catalog_general_note' : 'product_catalog_location_note'} />
        </View>
        <ListContainer
          menu={true}
          items={listItems}
          showCreateButton={false}
          showSearch={false}
          swipeLeft={false}
          swipeRight={false}
          useHidden={false}
          useDivider={false}
          listItemStyle={{
            leftImageStyle: {
              width: 25,
            },
            itemContainer: {
              marginVertical: 8,
              padding: 5,
              borderRadius: 7,
              overflow: 'hidden',
            },
          }}
          listContainerStyle={{
            marginTop: 0,
          }}
        />
      </SafeAreaView>
    </>
  )
}

Items.propTypes = {
  route: PropTypes.object,
  navigation: PropTypes.object,
}

export default Items

