import React, { useContext, useState, useRef, useEffect } from 'react'
import { TouchableOpacity, FlatList, Platform } from 'react-native'
import PropTypes from 'prop-types'
import { useTheme } from 'react-native-themed-styles'

import { FilledTextField } from '../react-native-material-textfield'
import ContentManager from '../../Utils/ContentManager'
import Text from '../Text'
import { INF } from '../../Utils/Helpers'

import themedStyles, { suggestionStyles } from './InputWithTags.Styles'
import Theme from '../../Themes/Theme'

function Suggestion({
  index,
  title,
  select,
  suggestionType,
  isSelected,
}) {
  const [styles] = useTheme(suggestionStyles)
  let name
  if (suggestionType === 'number') {
    name = INF.format(title)
  } else {
    name = title
  }

  return (
    <TouchableOpacity onPress={() => select(title, index)} style={styles['containerSelected_' + isSelected]}>
      <Text i18nKey={name} style={styles['textSelected_' + isSelected]} />
    </TouchableOpacity>
  )
}
Suggestion.propTypes = {
  index: PropTypes.number,
  title: PropTypes.string,
  select: PropTypes.func,
  suggestionType: PropTypes.string,
  isSelected: PropTypes.bool,
}

function InputWithTags(props) {
  const [styles] = useTheme(themedStyles)
  const { colors } = useContext(Theme)

  const {
    label,
    value,
    prefix,
    suffix,
    placeHolders,
    onChangeText,
    suggestionType,
    suggestions,
    keyboardType,
    onFocus,
    onBlur,
    inputWrapperStyle,
    inputContainerStyle,
    tagsContentContainerStyle,
    labelOffset,
    error,
    ...rest
  } = props

  const inputRef = useRef()
  const [isFocused, setFocused] = useState(false)
  const [selected, setSelected] = useState(-1)
  const placeholderKey = label + ''



  const changeText = (text, idx = -1) => {
    onChangeText(text)
    setSelected(idx)
  }

  useEffect(() => {
    inputRef?.current?.setValue(value)
  }, [value])

  const focused = () => {
    setFocused(true)
    onFocus()
  }

  const blured = () => {
    setFocused(false)
    onBlur()
  }

  const keyExtractor = (_, idx) => 'suggestion_' + idx
  const renderSuggestion = ({ item, index }) => (
    <Suggestion
      index={index}
      title={item}
      select={changeText}
      accent={colors.secondaryAccent}
      suggestionType={suggestionType}
      isSelected={selected === index}
    />
  )

  return (
    <>
      <FlatList
        horizontal
        inverted
        data={suggestions}
        keyExtractor={keyExtractor}
        renderItem={renderSuggestion}
        showsHorizontalScrollIndicator={false}
        contentContainerStyle={tagsContentContainerStyle}
      />
      <FilledTextField
        {...rest}
        ref={inputRef}
        label={ContentManager.translate(placeholderKey, placeHolders)}
        keyboardType={keyboardType}
        tintColor={colors.primaryText}
        baseColor={colors.primaryText}
        // value={value}
        allowFontScaling={false}
        error={error}
        style={styles.inputStyle}
        lineWidth={0}
        activeLineWidth={0}
        disabledLineWidth={0}
        prefix={prefix}
        suffix={suffix}
        onChangeText={text => changeText(text)}
        onFocus={focused}
        onBlur={blured}
        labelOffset={labelOffset}
        labelFontSize={14}
        containerStyle={[styles.input, inputWrapperStyle]}
        inputContainerStyle={[styles.inputContainer(isFocused, error), inputContainerStyle]}
        labelTextStyle={styles.label}
        titleTextStyle={styles.title}
        affixTextStyle={[styles.affix, Boolean(prefix) && Platform.OS === 'ios' ? styles.prefixIos : styles.prefix]}
      />
    </>
  )
}

InputWithTags.defaultProps = {
  labelOffset: {
    x0: 0,
    y0: -9,
    x1: 0,
    y1: -1,
  },
  suggestions: [],
  keyboardType: 'default',
  onFocus: () => { },
  onBlur: () => { },
  error: '',
}

InputWithTags.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  prefix: PropTypes.string,
  suffix: PropTypes.string,
  labelOffset: PropTypes.shape({
    x0: PropTypes.number,
    y0: PropTypes.number,
    x1: PropTypes.number,
    y1: PropTypes.number,
  }),
  placeHolders: PropTypes.arrayOf(PropTypes.string),
  onChangeText: PropTypes.func,
  suggestionType: PropTypes.string,
  suggestions: PropTypes.arrayOf(PropTypes.string),
  keyboardType: PropTypes.string,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  inputWrapperStyle: PropTypes.object,
  inputContainerStyle: PropTypes.object,
  tagsContentContainerStyle: PropTypes.object,
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
}

export default InputWithTags
