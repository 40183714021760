import React, { memo, useState, useEffect, useRef } from 'react'
import {
    View,
    TouchableOpacity,
    Animated,
    Easing,
    useWindowDimensions,

} from 'react-native'
import { useTheme } from 'react-native-themed-styles'
import { useLazyQuery } from '@apollo/client'
import PropTypes from 'prop-types'

import {
    Icon,
    Text,
} from '../../Components'

import { useConfig } from '../../Utils'

import { LIST_CASH_REGISTERS } from './Location.Schema'

import themedStyles, { itemStyles, separatorStyles } from './Location.Styles'
import images from '../../Themes/Images'
import { colors } from '../../Themes'


const POINTER_EVENTS = {
    0: 'none',
    1: 'auto',
}

const Item = memo(({
    name,
    deviceName,
    onPress,
    isFirst,
    isLast,
}) => {
    const [styles] = useTheme(itemStyles)

    return (
        <View style={[styles.container, styles['firstItem_' + isFirst], styles['lastItem_' + isLast]]}>
            <TouchableOpacity
                style={styles.touchable}
                onPress={onPress}
                activeOpacity={0.5}
            >
                <View style={styles.detailsContainer}>
                    <Text i18nKey={name} numberOfLines={1} />
                    <Text
                        i18nKey={deviceName}
                        weight="light"
                        color={colors.secondaryText}
                        size="footnote"
                    />
                </View>
                <Icon source={images.arrow_right} style={styles.rightElement} />
            </TouchableOpacity>
        </View>
    )
})
Item.propTypes = {
    name: PropTypes.string,
    deviceName: PropTypes.string,
    onPress: PropTypes.func,
    isFirst: PropTypes.bool,
    isLast: PropTypes.bool,
}

const Separator = memo(function Separator() {
    const [styles] = useTheme(separatorStyles)

    return (
        <View style={styles.container} />
    )
})
function Devices({ id, navigate }) {

  const { switchLocation: { deviceId, locationId } } = useConfig()


    const [items, setItems] = useState([])
    const [loading, setLoading] = useState(true)
    const [styles] = useTheme(themedStyles)

    const listRef = useRef()
    const [pointerEvents, setPointerEvents] = useState('auto')
    const fabSize = useRef(new Animated.Value(0)).current
    const { width, height } = useWindowDimensions()

    const cursor = useRef()
    const [listCashRegisters, { data }] = useLazyQuery(
        LIST_CASH_REGISTERS,
        { fetchPolicy: 'network-only' }
    )



    const refetch = () => {
        setLoading(true)
        setItems([])
        cursor.current = null
        listCashRegisters({
            variables: {
                locId: locationId,
                cashRegisterId: deviceId,
                filter: {
                    name: 'location',
                    value: id,
                },
            },
        })
    }

    useEffect(() => {
        refetch()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [width, height])

    useEffect(() => {
        const registers = data?.listCashRegisters
        if (registers) {
            setItems((prev) => prev.concat((registers?.cashRegisters || [])?.filter((item) => item.status !== 'UNKNOWN')))
            cursor.current = registers?.nextToken
            setLoading(false)
        }
    }, [data])

    const onEndReached = () => {
        if (cursor.current) {
            setLoading(true)
            listCashRegisters({
                variables: {
                    locId: locationId,
                    cashRegisterId: deviceId,
                    nextToken: cursor.current,
                    filter: {
                        name: 'location',
                        value: id,
                    },
                },
            })
        }
    }

    const animate = val => {
        setPointerEvents(POINTER_EVENTS[val])
        Animated.spring(fabSize, {
            toValue: val,
            useNativeDriver: true,
            easing: Easing.inOut(Easing.cubic),
        }).start()
    }

    const scrollToTop = () => {
        animate(0)
        listRef.current?.scrollToOffset({
            offset: 0,
        })
    }

    const keyExtractor = item => item.id
    const getItemLayout = (_, index) => ({
        length: 70,
        offset: 71 * index,
        index,
    })
    const renderItem = ({ item, index }) => (
        <Item
            key={item.id}
            {...item}
            onPress={() => navigate('RegisterDetails', { status: item.status, cashId: item.id, name: item.name, refetch })}
            isFirst={index === 0}
            isLast={index === items.length - 1}
        />
    )

    return (
        <>
            <Animated.FlatList
                showsVerticalScrollIndicator={false}
                ref={(ref) => (listRef.current = ref)}
                onMomentumScrollEnd={(event) => {
                    if (event.nativeEvent.contentOffset.y > 800) {
                        animate(1)
                    } else {
                        animate(0)
                    }
                }}
                keyExtractor={keyExtractor}
                getItemLayout={getItemLayout}
                data={items}
                style={styles.listStyle}
                contentContainerStyle={styles.listContainerStyle}
                renderItem={renderItem}
                ItemSeparatorComponent={Separator}
                ListHeaderComponent={<Text i18nKey="location_section_cash_register" style={styles.sectionTitle} />}
                ListEmptyComponent={!loading && <Text i18nKey="location_no_cash_register" />}
                onEndReached={onEndReached}
                onRefresh={refetch}
                refreshing={false}
            />
            <Animated.View
                pointerEvents={pointerEvents}
                style={[
                    styles.fab,
                    {
                        opacity: fabSize,
                        transform: [{ scale: fabSize }],
                    },
                ]}>
                <TouchableOpacity
                    onPress={scrollToTop}
                    activeOpacity={0.5}
                    style={styles.fabTouchable}>
                    <Icon source={images.arrow_up} size={20} style={styles.fabIcon} />
                </TouchableOpacity>
            </Animated.View>
        </>
    )
}

Devices.propTypes = {
    id: PropTypes.string,
    navigate: PropTypes.func,
}

export default Devices
