import { styleSheetFactory } from '../../Themes/Colors'

const styles = styleSheetFactory(colors => ({
  modalContent: {
    backgroundColor: colors.white,
    paddingBottom: 30,
    borderRadius: 6,
    paddingTop: 50,
    alignItems: 'center',
    justifyContent: 'center',
  },
  selectedImage: {
    borderWidth: 2,
    borderColor: colors.secondaryAccent,
  },
  selectedColor: {
    borderWidth: 2,
    borderColor: colors.pending,
  },
  colorContainer: {
    width: 40,
    height: 40,
    borderRadius: 6,
    justifyContent: 'center',
    alignItems: 'center',
  },
  avatar: {
    borderRadius: 6,
    width: 120,
    height: 120,
    overflow: 'hidden',
    borderWidth: 2,
    borderColor: colors.white,
  },
  avatarContainer: {
    flexDirection: 'row',
    paddingHorizontal: 20,
    marginTop: 20,
    marginBottom: 10,
    justifyContent: 'center',
  },
  closeContainer: {
    backgroundColor: colors.disabled,
    position: 'absolute',
    top: 0,
    right: 0,
    borderTopRightRadius: 6,
    borderBottomLeftRadius: 6,
    width: 75,
    height: 30,
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 20,
  },
  modal: { justifyContent: 'center', alignItems: 'center' },
}))

export default styles
