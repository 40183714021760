import React, { useState, useContext, useRef } from 'react'
import { View, SafeAreaView, TouchableOpacity, ScrollView } from 'react-native'
import PropTypes from 'prop-types'
import { useTheme } from 'react-native-themed-styles'
import { useSelector, useDispatch } from 'react-redux'

import { useMutation } from '@apollo/client'

import {
  Button,
  Text,
  Icon,
  InputRow,
  Header,
  Note,
  TouchableInput,
  Switch,
  ErrorModal,
} from '../../Components'

import useTaxDetails from '../../Utils/Tax'
import { getCurrency } from '../Checkout/Checkout.Selectors'
import { addItemToOrder } from '../Checkout/Checkout.Actions'

import { CREATE_ITEM } from '../NewItem/NewItem.schema'

import { getErrorMessage, useConfig } from '../../Utils'

import Theme from '../../Themes/Theme'
import images from '../../Themes/Images'
import themedStyles from './VariableItem.Styles'

function Tax({ name, taxInclusionType, onPress }) {
  const [styles] = useTheme(themedStyles)
  const { colors } = useContext(Theme)

  return (
    <TouchableOpacity style={styles.taxContainer} onPress={onPress} disabled={!onPress}>
      <Text i18nKey="checkout_title_applied_tax" numberOfLines={2} weight="light" style={styles.taxNoShrink} />
      <View style={styles.taxDetailsGrow}>
        <View style={styles.taxGrow}>
          <Text i18nKey={name || '%'} numberOfLines={2} />
          <Text i18nKey={taxInclusionType} color={colors.secondaryText} numberOfLines={2} />
        </View>
        {Boolean(onPress) && <Icon source={images.arrow_right} color={colors.accent} style={styles.taxNoShrink} />}
      </View>
    </TouchableOpacity>
  )
}
Tax.propTypes = {
  name: PropTypes.string,
  taxInclusionType: PropTypes.string,
  onPress: PropTypes.func,
}

function VariableItem({
  navigation: { goBack, navigate },
  route: { params: {
    onSave = () => { },
    params = {},
    refetchItems = () => { },
    addToCart = true,
  } = {} },
}) {

  const { switchLocation: { deviceId, locationId } } = useConfig()

  const [styles] = useTheme(themedStyles)
  const currency = useSelector(getCurrency)
  const { taxDetails } = useTaxDetails()
  const [createItem, { loading }] = useMutation(CREATE_ITEM)
  const [itemName, setItemName] = useState('')
  const [itemPrice, setItemPrice] = useState('')
  const [measurementUnit, setMeasurementUnit] = useState({})
  const [shouldSaveItem, setShouldSaveItem] = useState(false)
  const [errorModal, setErroModal] = useState({
    isVisible: false,
    title: '',
    desc: '',
  })

  const { id, name, taxExemptionType, measurementUnitId, measurementUnitName, variationId, variationName } = params

  const dispatchAction = useDispatch()

  const addToOrder = (item) => {
    dispatchAction(addItemToOrder(item))
  }

  const priceRef = useRef(null)

  const replaceAt = (string, index, replacement) => {
    return string.substr(0, index) + replacement + string.substr(index + replacement.length)
  }

  const changePrice = (text) => {
    let newString = text
    const indexOfComa = text.indexOf(',')
    if (indexOfComa >= 0) {
      newString = replaceAt(text, indexOfComa, '.')
    }
    if (/^\d{0,12}(\.\d{0,2})?$/.test(newString)) {
      setItemPrice(newString)
    }
  }

  const openErrorModal = (
    desc = '',
    title = 'unit_general_error',
  ) => {
    setErroModal({
      isVisible: true,
      title: title,
      desc: desc,
    })
  }

  const closeErrorModal = () => {
    setErroModal({
      isVisible: false,
      title: '',
      desc: '',
    })
  }

  const onDone = async () => {
    if (addToCart) {
      if ((id && itemPrice) || (itemName && itemPrice)) {
        let variableItemId = null
        let itemVarName = null, itemVarId = null
        if (shouldSaveItem) {
          try {
            const { data: { createItem: itemData } } = await createItem({
              variables: {
                locId: locationId,
                cashRegisterId: deviceId,
                name: itemName,
                taxInclusionType: taxDetails?.taxInclusionType,
                taxId: taxDetails?.taxId,
                measurementUnitId: id ? measurementUnitId : measurementUnit?.id,
                measurementUnitName: id ? measurementUnitName : measurementUnit?.name,
                itemVariations: [{ name: 'Regular', prices: [{ amount: itemPrice, currency: currency.currencyCode }] }],
                taxExemptionType: taxExemptionType,
              },
            })
            variableItemId = itemData.id
            const itemVarations = itemData?.itemVariations?.[0]
            itemVarId = itemVarations?.id
            itemVarName = itemVarations?.name
            refetchItems()
          } catch (error) {
            return openErrorModal(getErrorMessage(error), 'item_dublicated_non_inventory_label')
          }
        }

        const item = {
          itemId: id || variableItemId,
          itemName: id ? name : itemName,
          basePriceMoney: {
            amount: parseFloat(itemPrice),
            currency: currency.currencyCode,
          },
          measurementUnitId: id ? measurementUnitId : measurementUnit?.id,
          measurementUnitName: id ? measurementUnitName : measurementUnit?.name,
          variationId: id ? variationId : itemVarId,
          variationName: id ? variationName : itemVarName,
          quantity: 1,
          appliedTax: id ? params?.appliedTax : taxDetails?.tax,
          taxInclusionType: id ? params?.taxInclusionType : taxDetails?.taxInclusionType,
          modifiers: [],
          appliedDiscounts: [],
          taxExemptionType: taxExemptionType,
        }
        goBack()
        addToOrder(item)
        onSave()
      }
    } else {
      onSave({ price: parseFloat(itemPrice) })
    }
  }

  const taxMissing = !id && !taxDetails.tax

  return (
    <SafeAreaView style={styles.container}>
      <Header
        title={id ? 'checkout_button_add_price' : 'checkout_noninventory_label'}
        image={images.closeIcon}
        onPress={goBack}
      />
      <ScrollView style={styles.subcontainer}>
        {taxMissing && <Note desc="variable_item_tax_missing" />}
        {id
          ? <Text style={styles.sectionText} i18nKey={name} />
          : <InputRow
            label="item_name_label"
            value={itemName}
            placeholder="item_name_placeholder"
            onChangeText={setItemName}
            style={styles.inputRow}
            autoFocus
            onSubmitEditing={() => priceRef.current?.focus()}
            textContentType="none"
            editable={!taxMissing}
            maxLength={50}
          />
        }
        <InputRow
          inputRef={ref => { priceRef.current = ref }}
          label="item_price_label"
          inputType="numeric"
          inputLabel={currency.type}
          value={itemPrice}
          placeholder="0.0"
          onChangeText={(val) => {
            changePrice(val)
          }}
          style={styles.inputRow}
          autoFocus={id ? true : false}
          onSubmitEditing={onDone}
          editable={!taxMissing}
        />
        {!id && <TouchableInput
          onPress={() => navigate('Units', {
            selectable: true,
            preselectedUnit: measurementUnit,
            updateSelection: (value) => setMeasurementUnit(value),
          })}
          label="item_choose_unit_label"
          value={measurementUnit.name ? `${measurementUnit.name} (${measurementUnit.abbreviation})` : ''}
          icon={images.chevronRight}
          iconSize={18}
          autoCorrect={false}
          autoCapitalize="none"
          editable={false}
          style={styles.unitComponentStyle}
        />}
        {!id && <Tax
          name={taxDetails.tax?.name}
          taxInclusionType={taxDetails.taxInclusionType}
        />}
        {!id && <View style={styles.saveItemContainer}>
          <Text i18nKey={'save_non_invetory_item'} weight="bold" />
          <Switch
            value={shouldSaveItem}
            onPress={() => {
              setShouldSaveItem(prev => !prev)
            }}
          />
        </View>}
        <Button
          variant={((id && !itemPrice) || (!id && (!taxDetails.tax || !itemName || !itemPrice || !measurementUnit?.name || loading))) ? 'disabled' : 'active'}
          icon={addToCart ? images.checkout : undefined}
          title={addToCart ? 'checkout_button_add_to_basket' : 'checkout_button_add_price'}
          style={styles.actionButton}
          loader={loading}
          onPress={onDone}
        />
      </ScrollView>
      <ErrorModal
        isVisible={errorModal.isVisible}
        icon={images.warningIcon}
        title={errorModal.title}
        description={errorModal.desc}
        onBackdropPress={closeErrorModal}
        onClosePress={closeErrorModal}
      />
    </SafeAreaView>
  )
}

VariableItem.propTypes = {
  navigation: PropTypes.object,
  route: PropTypes.shape({
    params: PropTypes.object,
  }),
}

export default VariableItem
