import React from 'react'
import PropTypes from 'prop-types'
import { ScrollView } from 'react-native'

function PagerView({ children, initialPage }) {

    const filteredChildren = children?.filter(element => {
        if (!React.isValidElement(element)) {
            return
        }
        if (initialPage === parseInt(element?.key, 10)) {
            return element
        }
    })
    // eslint-disable-next-line react-native/no-inline-styles
    return <ScrollView showsVerticalScrollIndicator={false} style={{ flex: 1 }}>
        {filteredChildren}
    </ScrollView>
}
PagerView.propTypes = {
    children: PropTypes.any,
    initialPage: PropTypes.number,
}

export default PagerView
