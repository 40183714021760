import React, { useContext } from 'react'
import {
    View,
    TouchableOpacity,
} from 'react-native'
import PropTypes from 'prop-types'
import { useTheme } from 'react-native-themed-styles'

import Icon from '../Icon'
import Text from '../Text'

import Theme from '../../Themes/Theme'
import { images } from '../../Themes'
import themedStyles from './Option.Styles'

function Option(props) {
    const [styles] = useTheme(themedStyles)
    const { colors } = useContext(Theme)

    const {
        icon,
        iconColor = colors.secondaryIcon,
        count,
        onPress,
        disabled,
        style,
    } = props

    return (
        <TouchableOpacity
            onPress={onPress}
            style={[styles.container, style]}
            disabled={disabled}>
            <Icon source={icon} size={18} color={iconColor} />
            {count ? (
                <View style={styles.statusContainer}>
                    <Text i18nKey={count} color={colors.white} style={styles.status} />
                </View>
            ) : null}
        </TouchableOpacity>
    )
}
Option.defaultProps = {
    onPress: () => { },
    icon: images.defaultIcon,
    disabled: false,
}
Option.propTypes = {
    onPress: PropTypes.func,
    icon: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    iconColor: PropTypes.string,
    style: PropTypes.object,
    count: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    disabled: PropTypes.bool,
}

export default Option
