/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from 'react'
import { View, TouchableWithoutFeedback, ScrollView, TouchableOpacity } from 'react-native'
import { useTheme } from 'react-native-themed-styles'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import { Text, Icon, BottomSheetModal, BottomSheetSelector, TouchableInput, Type } from '../../../../../Components'
import images from '../../../../../Themes/Images'
import ContentManager from '../../../../../Utils/ContentManager'

import { getSupportInitData } from '../../../Payment.Selectors'
import { supportInvoiceUpdateStepKey } from '../../../Payment.Actions'
import {
  INVOICE_TYPES,
  TRANSACTION_TYPES,
} from '../../../SupportiveInvoiceSteps/WTNData'

import Theme from '../../../../../Themes/Theme'

import themedStyles from './SupportiveInvoicePayment.Styles'

const { translate } = ContentManager

function SupportiveInvoicePayment({ setDisabled }) {
  const [styles] = useTheme(themedStyles)
  const dispatchAction = useDispatch()
  const { type, transaction } = useSelector(getSupportInitData)
  const { colors } = useContext(Theme)

  const updateInitialData = (key, value) => dispatchAction(supportInvoiceUpdateStepKey('initial', key, value))

  const [searchValue, setSearchValue] = useState('')
  const [infoVisible, setInfoVisible] = useState(false)

  const [modal, setModal] = useState({
    isVisible: false,
    data: [],
    title: '',
    placeholder: '',
    selected: undefined,
    select: () => { },
    Item: Type,
  })

  const closeModal = () => {
    setModal(prev => ({ ...prev, isVisible: false }))
    setSearchValue('')
  }

  const closeInfoModal = () => {
    setInfoVisible(false)
  }

  const selectInvoiceType = item => {
    updateInitialData('type', item)
  }
  const openInvoiceTypeSelector = () => {
    setModal({
      isVisible: true,
      data: INVOICE_TYPES,
      title: 'supporting_invoice_invoice_type_search',
      placeholder: 'einvoice_process_type_search_placeholder',
      selected: type,
      select: selectInvoiceType,
      searchKey: 'code',
      Item: Type,
    })
  }

  const selectTransactionType = item => {
    updateInitialData('transaction', item)
  }
  const openTransactionTypeSelector = () => {
    setModal({
      isVisible: true,
      data: TRANSACTION_TYPES,
      title: 'supporting_invoice_transaction_type_search',
      placeholder: 'einvoice_process_type_search_placeholder',
      selected: transaction,
      select: selectTransactionType,
      searchKey: 'code',
      Item: Type,
    })
  }

  useEffect(() => {
    setDisabled(!type.description || !transaction.description)
  }, [type.description, transaction.description])

  return (
    <>
      <View style={styles.topContent}>
        <View style={styles.infoWrapper}>
          <Text i18nKey="payment_supporting_invoice_title" weight="bold" style={styles.sectionTitle} />
          <TouchableOpacity onPress={() => setInfoVisible(true)}>
            <Icon source={images.info} size={24} />
          </TouchableOpacity>
        </View>
        <TouchableInput
          onPress={() => openInvoiceTypeSelector()}
          label="supporting_invoice_invoice_type_label"
          value={translate(type.description || '')}
          icon={images.filledDownArrow}
          iconSize={16}
          autoCorrect={false}
          autoCapitalize="none"
          editable={false}
        />
        <TouchableInput
          onPress={() => openTransactionTypeSelector()}
          label="supporting_invoice_transaction_type_label"
          value={translate(transaction.description || '')}
          icon={images.filledDownArrow}
          iconSize={16}
          autoCorrect={false}
          autoCapitalize="none"
          editable={false}
        />
        <BottomSheetSelector
          {...modal}
          closeModal={closeModal}
          searchValue={searchValue}
          setSearchValue={setSearchValue}
        />
        <BottomSheetModal
          isVisible={infoVisible}
          onClose={closeInfoModal}
        >
          <View style={styles.infoContainer}>
            <View style={styles.infoWrapper}>
              <Text i18nKey="payment_supporting_invoice_title" weight="bold" align="left" style={styles.InfoLabel} size="h4" />
              <TouchableOpacity onPress={() => { closeInfoModal() }}>
                <Icon source={images.close} color={colors.secondaryIcon} size={24} />
              </TouchableOpacity>
            </View>
            <ScrollView
              showsVerticalScrollIndicator={false}
            >
              <TouchableWithoutFeedback>
                <View>
                  <Text i18nKey="supportive_invoice_information_intro" style={styles.infoIntro} />
                  <Text i18nKey="SALE/Sales" weight="bold" align="left" size="h5" />
                  <Text i18nKey="supportive_invoice_information_sales" style={styles.infoItem} />
                  <Text i18nKey="WTN/Examination" weight="bold" align="left" size="h5" />
                  <Text i18nKey="supportive_invoice_information_examination" style={styles.infoItem} />
                  <Text i18nKey="WTN/Transfer" weight="bold" align="left" size="h5" />
                  <Text i18nKey="supportive_invoice_information_transfer" style={styles.infoItem} />
                  <Text i18nKey="WTN/Door" weight="bold" align="left" size="h5" />
                  <Text i18nKey="supportive_invoice_information_door" style={styles.infoItem} />
                </View>
              </TouchableWithoutFeedback>
            </ScrollView>
          </View>
        </BottomSheetModal>
      </View>
    </>
  )
}

SupportiveInvoicePayment.propTypes = {
  setDisabled: PropTypes.func,
}

export default SupportiveInvoicePayment
