/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState, useEffect } from 'react'
import {
    LayoutAnimation,
    ScrollView,
    View,
    Text as RNText,
} from 'react-native'
import moment from 'moment-timezone'
import { useTheme } from 'react-native-themed-styles'
import { useMutation } from '@apollo/client'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'

import {
    Text,
    Button,
    Icon,
    InputWithTags,
    DatePickerModal,
    ButtonGroup,
    BottomSheetSelector,
    TouchableInput,
    Type,
} from '../../../../Components'
import Theme, { opacityConfig } from '../../../../Themes/Theme'
import images from '../../../../Themes/Images'
import ContentManager from '../../../../Utils/ContentManager'

import { getWTNSaveInput } from '../../Helpers'
import { supportInvoiceUpdateStepKey, updatePaymentAttribute } from '../../Payment.Actions'
import { getSupportTransport, getSupportInvoice } from '../../Payment.Selectors'
import { SAVE_SUPPORTING_INVOICE } from '../../Payment.Schema'

import { City } from '../Components'
import Step from '../Step'
import {
    CITIES,
    TRANSPORT_OWNERSHIP_TYPES,
    START_POINT,
    DESTINATION_POINT,
    SELECT_OPTIONS,
} from '../WTNData'

import { useConfig } from '../../../../Utils'

import themedStyles from './SecondStep.Styles'

const { translate } = ContentManager

function SecondStep({
    goBack,
    stepperLength,
    index,
    addListener,
    dispatch,
}) {

  const { switchLocation: { deviceId, locationId } } = useConfig()

    const [styles] = useTheme(themedStyles)
    const { colors } = useContext(Theme)
    const dispatchAction = useDispatch()
    const {
        vehOwnership,
        vehPlates,
        startAddr,
        startDateTime: startDT,
        startCity,
        startPoint,
        destinAddr,
        destinCity,
        destinDateTime: destinDT,
        destinPoint,
        isEscortRequired,
        isGoodsFlammable,
    } = useSelector(getSupportTransport)

    const updateTransportData = (key, value) => {
        dispatchAction(supportInvoiceUpdateStepKey('transportInfo', key, value))
    }

    const setPaymentStatus = (value) => dispatchAction(updatePaymentAttribute('paymentStatus', value))

    const [disabled, setDisabled] = useState(true)
    const [searchValue, setSearchValue] = useState('')

    const [saveSupportInv, { loading }] = useMutation(SAVE_SUPPORTING_INVOICE)

    const allInvoice = useSelector(getSupportInvoice)

    const [modal, setModal] = useState({
        isVisible: false,
        data: [],
        title: '',
        placeholder: '',
        selected: undefined,
        select: () => { },
        Item: Type,
    })

    const closeModal = () => {
        setModal(prev => ({ ...prev, isVisible: false }))
        setSearchValue('')
    }

    const selectStartCity = item => {
        updateTransportData('startCity', item)
    }
    const openStartCitySelector = () => {
        setModal({
            isVisible: true,
            data: CITIES,
            title: 'einvoice_delivery_city',
            placeholder: 'supporting_invoice_city_search',
            selected: startCity,
            select: selectStartCity,
            searchKey: 'self',
            Item: City,
        })
    }

    const selectVehOwnership = item => {
        updateTransportData('vehOwnership', item)
    }
    const openVehOwnershipSelector = () => {
        setModal({
            isVisible: true,
            data: TRANSPORT_OWNERSHIP_TYPES,
            title: 'supporting_invoice_transport_ownership',
            placeholder: 'supporting_invoice_transport_ownership_search',
            selected: vehOwnership,
            select: selectVehOwnership,
            searchKey: 'code',
            Item: Type,
        })
    }

    const selectStartPoint = item => {
        updateTransportData('startPoint', item)
    }
    const openStartPointSelector = () => {
        setModal({
            isVisible: true,
            data: START_POINT,
            title: 'supporting_invoice_transport_starting_point',
            placeholder: 'supporting_invoice_transport_starting_search',
            selected: startPoint,
            select: selectStartPoint,
            searchKey: 'code',
            Item: Type,
        })
    }

    const selectDestinCity = item => {
        updateTransportData('destinCity', item)
    }
    const openDestinCitySelector = () => {
        setModal({
            isVisible: true,
            data: CITIES,
            title: 'einvoice_delivery_city',
            placeholder: 'supporting_invoice_city_search',
            selected: destinCity,
            select: selectDestinCity,
            searchKey: 'self',
            Item: City,
        })
    }

    const selectDestinPoint = item => {
        updateTransportData('destinPoint', item)
    }
    const openDestinPointSelector = () => {
        setModal({
            isVisible: true,
            data: DESTINATION_POINT,
            title: 'supporting_invoice_transport_destination_point',
            placeholder: 'supporting_invoice_transport_destination_search',
            selected: destinPoint,
            select: selectDestinPoint,
            searchKey: 'code',
            Item: Type,
        })
    }

    const [dates, setDates] = useState({
        startDateTime: startDT,
        destinDateTime: destinDT,
        shown: false,
        willUpdate: '',
        tempDate: '',
    })

    const showDatesModal = (willUpdate) => {
        setDates(prev => ({
            ...prev,
            shown: true,
            willUpdate,
            tempDate: prev[willUpdate],
        }))
    }

    const onPrevPress = (step) => {
        LayoutAnimation.configureNext(opacityConfig)
        setPaymentStatus(step)
    }

    const changePlates = (text) => updateTransportData('vehPlates', text.trim())

    const onNextPress = () => {
        LayoutAnimation.configureNext(opacityConfig)
        saveSupportInv({
            variables: {
                locId: locationId,
                cashRegisterId: deviceId,
                warehouseNoteInput: getWTNSaveInput(allInvoice),
            },
        })
            .then(() => {
                setPaymentStatus('issuerCarrier')

            })
            .catch(e => {
            })
    }

    const regex = /^(\d|\w)+$/
    useEffect(() => {
        setDisabled(
            !vehOwnership ||
            !regex.test(vehPlates) ||
            !startAddr ||
            !startDT ||
            !startCity ||
            !startPoint ||
            !destinAddr ||
            !destinCity ||
            !destinDT ||
            !destinPoint ||
            isEscortRequired === undefined ||
            isGoodsFlammable === undefined
        )
    }, [
        vehOwnership,
        vehPlates,
        startAddr,
        startDT,
        startCity,
        startPoint,
        destinAddr,
        destinCity,
        destinDT,
        destinPoint,
        isEscortRequired,
        isGoodsFlammable,
    ])

    return (
        <>
            <Step
                title="header_transport_supportive_invoice"
                goBack={goBack}
                stepperLength={stepperLength}
                index={index}
                addListener={addListener}
                dispatch={dispatch}
            >
                <ScrollView
                    contentContainerStyle={styles.fillRemaining}
                    showsVerticalScrollIndicator={false}
                >
                    <View style={styles.topContent}>
                        <TouchableInput
                            onPress={() => openVehOwnershipSelector()}
                            label="supporting_invoice_transport_ownership"
                            value={translate(vehOwnership.description || '')}
                            icon={images.filledDownArrow}
                            iconSize={16}
                            autoCorrect={false}
                            autoCapitalize="none"
                            editable={false}
                        />
                        <InputWithTags
                            label="supporting_invoice_transport_plate"
                            value={vehPlates}
                            error={(!regex.test(vehPlates) && Boolean(vehPlates))}
                            onChangeText={text => changePlates(text)}
                            autoCapitalize="characters"
                            autoCompleteType="off"
                        />
                        <RNText
                            style={styles.vehiclePlateHelper}
                            allowFontScaling={false}
                        >
                            <RNText>{translate('supporting_invoice_vehicle_plate_helper_1')}</RNText>
                            <RNText style={styles.vehiclePlateHelperItalic} >{translate('supporting_invoice_vehicle_plate_helper_2')}</RNText>
                        </RNText>
                    </View>
                    <View style={styles.topContent}>
                        <Text i18nKey="supporting_invoice_transport_starting_point" weight="bold" style={styles.sectionTitle} />
                        <InputWithTags
                            label="supporting_invoice_transport_starting_address"
                            value={startAddr}
                            onChangeText={text => updateTransportData('startAddr', text)}
                            autoCompleteType="off"
                            inputWrapperStyle={styles.currency}
                        />
                        <TouchableInput
                            onPress={() => openStartCitySelector()}
                            label="einvoice_details_city_label"
                            value={startCity}
                            icon={images.filledDownArrow}
                            iconSize={16}
                            autoCorrect={false}
                            autoCapitalize="none"
                            editable={false}
                        />
                        <TouchableInput
                            label="supporting_invoice_transport_starting_point"
                            onPress={() => openStartPointSelector()}
                            value={translate(startPoint.description || '')}
                            icon={images.filledDownArrow}
                            iconSize={16}
                            autoCorrect={false}
                            autoCapitalize="none"
                            editable={false}
                        />
                        <TouchableInput
                            onPress={() => showDatesModal('startDateTime')}
                            value={dates?.startDateTime ? moment(dates?.startDateTime).format('DD/MM/YYYY') : ''}
                            label="einvoice_start_date_label"
                            icon={images.calendarBlack}
                            autoCorrect={false}
                            autoCapitalize="none"
                            editable={false}
                        />
                        <Text i18nKey="supporting_invoice_transport_destination_point" weight="bold" style={styles.sectionTitle} />
                        <InputWithTags
                            label="supporting_invoice_transport_destination_address"
                            value={destinAddr}
                            onChangeText={text => updateTransportData('destinAddr', text)}
                            autoCompleteType="off"
                            inputWrapperStyle={styles.currency}
                        />
                        <TouchableInput
                            onPress={() => openDestinCitySelector()}
                            label="einvoice_details_city_label"
                            value={destinCity}
                            icon={images.filledDownArrow}
                            iconSize={16}
                            autoCorrect={false}
                            autoCapitalize="none"
                            editable={false}
                        />
                        <TouchableInput
                            onPress={() => openDestinPointSelector()}
                            label="supporting_invoice_transport_destination_point"
                            value={translate(destinPoint.description || '')}
                            icon={images.filledDownArrow}
                            iconSize={16}
                            autoCorrect={false}
                            autoCapitalize="none"
                            editable={false}
                        />
                        <TouchableInput
                            onPress={() => showDatesModal('destinDateTime')}
                            value={dates?.destinDateTime ? moment(dates?.destinDateTime).format('DD/MM/YYYY') : ''}
                            label="supporting_invoice_transport_destination_date"
                            icon={images.calendarBlack}
                            autoCorrect={false}
                            autoCapitalize="none"
                            editable={false}
                        />
                        <Text i18nKey="supporting_invoice_flammable_item_question" weight="regular" style={styles.sectionTitle} />
                        <View style={styles.questionButtonsContainer}>
                            <ButtonGroup
                                selected={isGoodsFlammable}
                                onPress={type => updateTransportData('isGoodsFlammable', type)}
                                options={SELECT_OPTIONS}
                                buttonStyle={(active) => ({
                                    borderRadius: 6,
                                    height: 50,
                                    borderColor: colors.disabled,
                                    borderWidth: active ? 0 : 1,
                                })}
                            />
                        </View>
                        <Text i18nKey="supporting_invoice_accompany_goods_questin" weight="regular" style={styles.sectionTitle} />
                        <View style={styles.questionButtonsContainer}>
                            <ButtonGroup
                                selected={isEscortRequired}
                                onPress={type => updateTransportData('isEscortRequired', type)}
                                options={SELECT_OPTIONS}
                                buttonStyle={(active) => ({
                                    borderRadius: 6,
                                    height: 50,
                                    borderColor: colors.disabled,
                                    borderWidth: active ? 0 : 1,
                                })}
                            />
                        </View>
                    </View>
                </ScrollView>
                <View style={styles.buttons}>
                    <Button
                        title="einvoice_payment_button"
                        variant={loading ? 'disabled' : 'preActive'}
                        icon={images.arrowLeft}
                        iconColor={loading ? colors.white : colors.accent}
                        style={styles.rightButton}
                        onPress={() => onPrevPress('init')}
                    />
                    <Button
                        title="supporting_invoice_issuerCarrier_title"
                        variant={(disabled || loading) ? 'disabled' : 'active'}
                        titleStyle={styles.buttonTitle}
                        titleProps={{ numberOfLines: 1 }}
                        rightComponent={!loading && <Icon
                            size={20}
                            source={images.arrowRight}
                            color={colors.white}
                            style={styles.rightButtonIcon}
                        />}
                        loader={loading}
                        onPress={() => onNextPress()}
                        style={styles.leftButton}
                    />
                </View>
                <BottomSheetSelector
                    {...modal}
                    closeModal={closeModal}
                    searchValue={searchValue}
                    setSearchValue={setSearchValue}
                />
                <DatePickerModal
                    onClose={() => setDates(prev => ({
                        ...prev,
                        shown: false,
                    }))}
                    isVisible={dates.shown}
                    onDateChange={(date) => {
                        setDates(prev => ({
                            ...prev,
                            tempDate: date,
                        }))
                    }}
                    preselectedDate={dates?.tempDate === '' ? moment().format('YYYY-MM-DD') : moment(dates?.tempDate).format('YYYY-MM-DD')}
                    onButtonPress={() => {
                        setDates(prev => ({
                            ...prev,
                            [prev.willUpdate]: prev.tempDate,
                            shown: false,
                        }))
                        updateTransportData(dates.willUpdate, dates.tempDate)
                    }}
                />
            </Step>
        </>
    )
}

SecondStep.propTypes = {
    goBack: PropTypes.func,
    stepperLength: PropTypes.number,
    index: PropTypes.number,
    addListener: PropTypes.func,
    dispatch: PropTypes.func,
}

export default SecondStep
