import { Dimensions } from 'react-native'

import { styleSheetFactory } from '../../Themes/Colors'

const { width: SCREEN_WIDTH, height: SCREEN_HEIGHT } = Dimensions.get('screen')

const styles = styleSheetFactory(colors => ({
  container: {
    flexGrow: 1,
  },
  touchableBackdrop: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    borderRadius: 7,
    backgroundColor: colors.tertiaryBackground,
  },
  touchable: {
    position: 'absolute',
    zIndex: 10,
    margin: 1,
    flexDirection: 'row',
    backgroundColor: colors.tertiaryBackground,
    flexGrow: 1,
    maxHeight: 48,
    borderRadius: 7,
    width:'100%',
  },
  touchable_true: {
    zIndex: 20,
  },
  calendarIconContainer: {
    padding: 10,
    justifyContent: 'center',
    alignItems: 'center',
    width: 50,
    height: 50,
    top: -1,
    left: -1,
    borderTopLeftRadius: 7,
    borderBottomLeftRadius: 7,
  },
  calendarIconContainer_true: {
    backgroundColor: colors.transparent,
  },
  searchResultContainer: {
    flexGrow: 1,
    justifyContent: 'center',
    // paddingLeft: 15,
  },
  removeSelected: {
    margin: 6.5,
    justifyContent: 'center',
    alignItems: 'center',
    width: 35,
    height: 35,
    borderRadius: 20,
    backgroundColor: colors.background,
    position: 'absolute',
    right: 0,
  },
  calendarContainer: {
    position: 'absolute',
    width: '100%',
    backgroundColor: colors.tertiaryBackground,
    zIndex: 9,
    borderRadius: 7,
    borderWidth: 1,
    paddingTop: 50,
    borderColor: colors.separator,
  },
  calendarContainer_true: {
    zIndex: 19,
  },
  backdrop: {
    position: 'absolute',
    top: -15,
    left: -15,
    width: SCREEN_WIDTH + SCREEN_HEIGHT,
    height: SCREEN_WIDTH + SCREEN_HEIGHT,
    backgroundColor: colors.backgroundRGBA(0.6),
    zIndex: 11,
  },
  input: {
    flexShrink: 1,
    height: 40,
    includeFontPadding: false,
    textAlignVertical: 'center',
    color: colors.primaryText,
    fontSize: 16,
    paddingRight: 2,
    fontFamily: 'Vodafone Rg',
  },
}))

export default styles
