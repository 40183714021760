/* eslint-disable react-hooks/exhaustive-deps */
import React, { memo, useState, useEffect, useRef } from 'react'
import {
    SafeAreaView,
    View,
    Animated,
    useWindowDimensions,
    FlatList,
    TouchableOpacity,
    ScrollView,
    Keyboard,
} from 'react-native'
import MapView, { AnimatedRegion, Marker, PROVIDER_GOOGLE } from 'react-native-maps'
import {
    openSettings,
} from 'react-native-permissions'
import PropTypes from 'prop-types'
import { useTheme } from 'react-native-themed-styles'
import { useMutation, useQuery } from '@apollo/client'

import {
    Button,
    ErrorModal,
    Icon,
    InputWithTags,
    Loader,
    Modal,
    RadioInput,
    Text,
    InputPhone,
} from '../../Components'

import { getErrorMessage, useConfig } from '../../Utils'

import themedStyles, { locationStyles, itemStyles, separatorStyles } from './Location.Styles'

import images from '../../Themes/Images'
import { colors } from '../../Themes'
import { UPDATE_LOCATION, GET_LOCATION } from './Location.Schema'


const INITIAL_REGION = {
    latitude: 41.3275,
    longitude: 19.8187,
    latitudeDelta: 0.015,
    longitudeDelta: 0.0121,
}

const CATALOG_CONFIG = [
    {
        id: 'GLOBAL',
        title: 'location_info_catalog_config_global',
    },
    {
        id: 'LOCAL',
        title: 'location_info_catalog_config_local',
    },
]

const Item = memo(({
    title,
    onPress,
    id,
    isFirst,
    isLast,
    selected,
}) => {
    const [styles] = useTheme(itemStyles)
    return (
        <View style={[styles.container, styles['firstItem_' + isFirst], styles['lastItem_' + isLast]]}>
            <TouchableOpacity
                style={styles.touchable}
                onPress={onPress}
                activeOpacity={0.5}
            >
                <View style={styles.detailsContainer}>
                    <Text i18nKey={title} numberOfLines={1} />
                </View>
                <RadioInput
                    selected={selected}
                    index={id}
                    onPress={onPress}
                    style={styles.selector}
                    color={colors.secondaryAccent}
                />
            </TouchableOpacity>
        </View>
    )
})
Item.propTypes = {
    title: PropTypes.string,
    id: PropTypes.string,
    selected: PropTypes.string,
    onPress: PropTypes.func,
    isFirst: PropTypes.bool,
    isLast: PropTypes.bool,
}

const Separator = memo(function Separator() {
    const [styles] = useTheme(separatorStyles)

    return (
        <View style={styles.container} />
    )
})

const LocationSelector = memo(({ coordinates, updateCoordinates }) => {
    const [styles] = useTheme(locationStyles)
    // const { colors } = useContext(Theme)
    const { width } = useWindowDimensions()

    const [isMnualPermission, setMnualPermission] = useState(false)
    const region = useRef(INITIAL_REGION)
    const location = useRef(new AnimatedRegion(INITIAL_REGION)).current
    const opacity = useRef(new Animated.Value(0)).current

    const marker = useRef()
    const map = useRef()

    const animate = (nextProps) => {
        Animated.spring(opacity, { toValue: 1 }).start()
        location.timing({ ...nextProps, duration: 300 }).start()
        map.current?.animateToRegion({ ...nextProps })
    }

    useEffect(() => {
        if (coordinates) {
            animate({ ...region.current, ...coordinates })
        }
    }, [coordinates])

    return <>
        <View style={styles.mylocationContainer}>
            <Text i18nKey="location_label_location" style={styles.label} />
            <View style={styles.mapContainer(width >= 800 ? width - 280 : width)} onStartShouldSetResponder={() => true}>
                <MapView
                    ref={ref => { map.current = ref }}
                    provider={PROVIDER_GOOGLE}
                    style={styles.map}
                    initialRegion={INITIAL_REGION}
                    mapPadding={{}}
                >
                    <Marker.Animated
                        ref={ref => { marker.current = ref }}
                        coordinate={location}
                        anchor={{ x: 0.5, y: 0.925 }}
                        opacity={opacity}
                    >
                        <Icon source={images.locationMarker} size={70} />
                    </Marker.Animated>
                </MapView>
            </View>

        </View>
        <Modal
            backdropOpacity={0.5}
            onSwipeComplete={() => setMnualPermission(false)}
            onBackdropPress={() => setMnualPermission(false)}
            swipeDirection={null}
            hideModalContentWhileAnimating
            hardwareAccelerated
            useNativeDriver={true}
            animationIn="fadeIn"
            animationOut="fadeOut"
            coverScreen
            style={styles.modal}
            isVisible={isMnualPermission}
        >
            <View style={styles.permissionContainer}>
                <Text size="h4" i18nKey="location_permission_request_message" />
                <Button
                    type="tertiary"
                    title="location_permission_open_settings"
                    onPress={() => {
                        setMnualPermission(false)
                        openSettings()
                    }}
                    disabled={false}
                    style={styles.permissionButton}
                />
            </View>
        </Modal>
    </>
})
LocationSelector.defaultProps = {
    updateCoordinates: () => { },
}
LocationSelector.propTypes = {
    coordinates: PropTypes.shape({
        latitude: PropTypes.number,
        longitude: PropTypes.number,
    }),
    updateCoordinates: PropTypes.func,
}

const keyExtractor = item => item.id
const getItemLayout = (_, index) => ({
    length: 70,
    offset: 71 * index,
    index,
})

function Info({
    location,
    setLocation,
    goBack,
    isVirtual,
    refetch,
}) {
  const { switchLocation: { deviceId, locationId } } = useConfig()

    const [styles] = useTheme(themedStyles)
    const [selected, setSelected] = useState('')
    const [changed, setChanged] = useState(false)
    const [locationInfo, setLocationInfo] = useState({
        businessEmail: '',
        businessName: '',
        phoneNumber: '',
    })
    const copyOfLocation = useRef(null)

    const [modal, setModal] = useState({
        isVisible: false,
        icon: images.warningIcon,
        title: '',
        desc: '',
        descPlaceholders: [],
        primaryText: '',
        primaryAction: null,
        tertiaryText: '',
        tertiaryAction: null,
    })
    const openModal = (
        title = 'opened_orders_error',
        desc = '',
        descPlaceholders = [],
        primaryText = '',
        primaryAction = null,
        tertiaryText = '',
        tertiaryAction = null,
        icon = images.warningIcon,
    ) => {
        setModal({
            isVisible: true,
            icon,
            title,
            desc,
            descPlaceholders,
            primaryText,
            primaryAction,
            tertiaryText,
            tertiaryAction,
        })
    }
    const closeModal = () => {
        setModal({
            isVisible: false,
            icon: images.delete,
            title: '',
            desc: '',
            primaryText: '',
            primaryAction: null,
            tertiaryText: '',
            tertiaryAction: null,
        })
    }

    const [updateLocation, { loading }] = useMutation(UPDATE_LOCATION)
    const { data: locationData, loading: locationLoading, error: fetchError } = useQuery(GET_LOCATION, { variables: { id: location?.id } })

    useEffect(() => {
        if (location?.catalogType) {
            setSelected(location.catalogType)
        }
    }, [location?.catalogType])

    useEffect(() => {
        if (locationData) {
            setLocationInfo({ ...locationData?.getLocation })
            copyOfLocation.current = { ...locationData?.getLocation }
        }
    }, [locationData])

    useEffect(() => {
        if (copyOfLocation?.current?.businessEmail !== locationInfo?.businessEmail ||
            copyOfLocation?.current?.businessName !== locationInfo?.businessName ||
            copyOfLocation?.current?.phoneNumber !== locationInfo?.phoneNumber
        ) {
            setChanged(true)
        } else {
            setChanged(false)
        }
    }, [locationInfo?.businessEmail, locationInfo?.businessName, locationInfo?.phoneNumber])

    // Check if store update has a problem while updating
    useEffect(() => {
        if (fetchError) {
            openModal(
                'certificate_error_label',
                getErrorMessage(fetchError?.message),
                '',
                'cash_register_okay_button',
                () => goBack(),
                'logout_button_cancel',
                undefined,
                images.error,
            )
        }
    }, [fetchError])

    const renderItem = ({ item, index }) => (
        <Item
            key={item.id}
            {...item}
            onPress={() => setSelected(item?.id)}
            isFirst={index === 0}
            isLast={index === CATALOG_CONFIG.length - 1}
            selected={selected}
        />
    )

    const onSavePress = () => {
        closeModal()
        updateLocation({
            variables: {
                locId: locationId,
                cashRegisterId: deviceId,
                id: location?.id,
                catalogType: selected,
            },
        }).then(() => {
            goBack()
        }).catch((err) => {
            openModal(
                'certificate_error_label',
                getErrorMessage(err),
                '',
                'cash_register_okay_button',
                () => closeModal(),
                'logout_button_cancel',
                undefined,
                images.error,
            )
        })
    }

    const openConfirmModal = () => {
        openModal(
            'location_confrim_modal_title',
            'location_confrim_modal_desc',
            '',
            'receipt_button_confirm',
            onSavePress,
            'logout_button_cancel',
            closeModal,
            null,
        )
    }

    const handleUpdate = async () => {
        Keyboard.dismiss()
        try {
            await updateLocation({
                variables: {
                    locId: locationId,
                    cashRegisterId: deviceId,
                    id: locationInfo?.id,
                    businessName: locationInfo?.businessName.trim(),
                    businessEmail: locationInfo?.businessEmail.trim(),
                    phoneNumber: locationInfo?.phoneNumber,
                },
            })
            goBack()
            refetch()
        } catch {
            (error) => {
                openModal(
                    'certificate_error_label',
                    getErrorMessage(error?.message),
                    '',
                    'cash_register_okay_button',
                    () => closeModal(),
                    'logout_button_cancel',
                    undefined,
                    images.error,
                )
            }
        }
    }

    if (isVirtual) {
        if (locationLoading) {
            return <Loader view="full" size={128} />
        }
        return (
            <>
                <SafeAreaView style={styles.detailsContainer}>
                    <ScrollView>
                        <>
                            <InputWithTags
                                label="location_label_business_name"
                                value={locationInfo?.businessName}
                                onChangeText={text => {
                                    setLocationInfo(prev => { return { ...prev, businessName: text } })
                                }}
                                inputContainerStyle={styles.inputContainer}
                                maxLength={50}
                                editable={true}
                                inputWrapperStyle={styles.inputWrapper}
                            />
                            <InputWithTags
                                label="location_label_email"
                                value={locationInfo?.businessEmail}
                                onChangeText={text => {
                                    setLocationInfo(prev => { return { ...prev, businessEmail: text } })
                                }}
                                inputContainerStyle={styles.inputContainer}
                                maxLength={50}
                                editable={true}
                                inputWrapperStyle={styles.inputWrapper}
                            />
                            <InputPhone
                                label="users_phone_label"
                                value={locationInfo?.phoneNumber}
                                inputWrapperStyle={styles.inputWrapper}
                                onChangeText={text => {
                                    setLocationInfo(prev => { return { ...prev, phoneNumber: text } })
                                }}
                                autoCorrect={false}
                                prefix="+355"
                                editable={true}
                                inputContainerStyle={styles.inputContainer}
                                labelOffset={{
                                    x0: 0,
                                    y0: -7,
                                    x1: 0,
                                    y1: 2,
                                }}
                            />
                        </>
                    </ScrollView>
                    <View style={styles.buttonWrap}>
                        <Button
                            style={styles.virtualButton}
                            variant={changed ? 'active' : 'disabled'}
                            title="save_items_save_existing"
                            onPress={handleUpdate}
                            disabled={!changed}
                            loader={loading}
                        />
                    </View>
                </SafeAreaView>
                <ErrorModal
                    isVisible={modal?.isVisible}
                    title={modal?.title}
                    icon={modal?.icon}
                    description={modal?.desc}
                    placeHolders={modal.descPlaceholders}
                    primaryText={modal?.primaryText}
                    tertiaryText={modal?.tertiaryText}
                    primaryAction={modal?.primaryAction}
                    tertiaryAction={modal?.tertiaryAction}
                    onBackdropPress={closeModal}
                    onBackButtonPress={closeModal}
                    onClosePress={closeModal}
                />
            </>
        )
    }

    return (
        <>
            <SafeAreaView style={{ flex: 1 }}>
                <ScrollView style={{ flexGrow: 1 }}>
                    <FlatList
                        showsVerticalScrollIndicator={false}
                        keyExtractor={keyExtractor}
                        getItemLayout={getItemLayout}
                        data={CATALOG_CONFIG}
                        style={styles.listStyle}
                        contentContainerStyle={styles.listContainerStyle}
                        renderItem={renderItem}
                        ItemSeparatorComponent={Separator}
                        ListHeaderComponent={<>
                            <Text weight="bold" size="h5" i18nKey="location_info_catalog_title" style={styles.sectionTitle} />
                            <Text i18nKey="location_info_catalog_description" style={styles.sectionDescription} />
                            <View style={styles.detailsContainer}>
                                <InputWithTags
                                    label="location_label_business_name"
                                    value={location.businessName}
                                    onChangeText={text => setLocation(prev => { return { ...prev, businessName: text } })}
                                    inputContainerStyle={styles.inputContainer}
                                    maxLength={50}
                                    editable={false}
                                    inputWrapperStyle={styles.inputWrapper}
                                />

                                <InputWithTags
                                    label="location_label_vat_number"
                                    value={location.NIPT}
                                    onChangeText={text => setLocation(prev => { return { ...prev, NIPT: text } })}
                                    inputContainerStyle={styles.inputContainer}
                                    maxLength={50}
                                    editable={false}
                                    inputWrapperStyle={styles.inputWrapper}
                                />
                                <InputWithTags
                                    label="location_label_email"
                                    value={location.businessEmail}
                                    onChangeText={text => setLocation(prev => { return { ...prev, businessEmail: text } })}
                                    inputContainerStyle={styles.inputContainer}
                                    maxLength={50}
                                    editable={false}
                                    inputWrapperStyle={styles.inputWrapper}
                                />
                                <InputWithTags
                                    label="location_label_phone"
                                    value={location.phoneNumber}
                                    onChangeText={text => setLocation(prev => { return { ...prev, phoneNumber: text } })}
                                    inputContainerStyle={[styles.inputContainer]}
                                    maxLength={50}
                                    editable={false}
                                    // eslint-disable-next-line react-native/no-inline-styles
                                    inputWrapperStyle={[styles.inputWrapper, { marginBottom: 15 }]}
                                />
                            </View>
                        </>}
                    />

                </ScrollView>
                <Button
                    variant={selected && selected !== location?.catalogType ? 'active' : 'disabled'}
                    title="taxes_save_button"
                    style={styles.button}
                    onPress={openConfirmModal}
                    disabled={selected === location?.catalogType}
                    loader={loading}
                />
            </SafeAreaView>
            <ErrorModal
                isVisible={modal?.isVisible}
                title={modal?.title}
                icon={modal?.icon}
                description={modal?.desc}
                placeHolders={modal.descPlaceholders}
                primaryText={modal?.primaryText}
                tertiaryText={modal?.tertiaryText}
                primaryAction={modal?.primaryAction}
                tertiaryAction={modal?.tertiaryAction}
                onBackdropPress={closeModal}
                onBackButtonPress={closeModal}
                onClosePress={closeModal}
            />
        </>
    )
}
Info.propTypes = {
    location: PropTypes.object,
    setLocation: PropTypes.func,
    goBack: PropTypes.func,
    isVirtual: PropTypes.bool,
    refetch: PropTypes.func,
}

export default Info
