const CURRENCY = 'CURRENCY'
const ORDER = 'ORDER'
const RESET_ORDER = 'RESET_ORDER'
const ADD_ITEM = 'ADD_ITEM'
const REMOVE_ITEM = 'REMOVE_ITEM'
const UPDATE_ITEM = 'UPDATE_ITEM'

const changeCurrency = (currency) => ({
  type: CURRENCY,
  value: currency,
})
const updateOrder = (order) => ({
  type: ORDER,
  value: order,
})
const addItemToOrder = (item) => ({
  type: ADD_ITEM,
  value: item,
})
const updateItemInOrder = (item) => ({
  type: UPDATE_ITEM,
  value: item,
})
const removeItemFromOrder = (item) => ({
  type: REMOVE_ITEM,
  value: item,
})
const resetOrder = () => ({
  type: RESET_ORDER,
})

export {
  CURRENCY,
  ORDER,
  RESET_ORDER,
  ADD_ITEM,
  REMOVE_ITEM,
  UPDATE_ITEM,
  changeCurrency,
  updateOrder,
  resetOrder,
  addItemToOrder,
  removeItemFromOrder,
  updateItemInOrder,
}
