import React, { useContext, useState } from 'react'
import { View, ScrollView, TouchableOpacity, Platform } from 'react-native'
import PropTypes from 'prop-types'
import { useTheme } from 'react-native-themed-styles'

import {
  Icon,
  Text,
  InputWithTags,
  InputPhone,
  CertificateInputWeb,
  InputWithIcon,
} from '../../Components'

import Theme from '../../Themes/Theme'

import themedStyles from './RegisterCertificate.Styles'

import images from '../../Themes/Images'

function FileItem({ onPress, attached, fileName, cleanFile }) {
  const [styles] = useTheme(themedStyles)
  const { colors } = useContext(Theme)

  return (
    <TouchableOpacity
      style={styles.fileItemWrapper}
      onPress={onPress}
      disabled={attached}>
      {!attached ? (
        <View style={[styles.subFileContainer, styles.verticalSelfSpacing]}>
          <Text
            i18nKey="certificate_choose_label"
            color={colors.secondaryAccent}
            size="h4"
          />
          <Text
            i18nKey="certificate_file_format_info"
            weight="light"
            size="footnote"
          />
          <View
            style={styles.iconContainer}>
            <Icon
              source={images.attachFile}
              size={35}
              color={colors.secondaryAccent}
              style={styles.spacing}
            />
          </View>
        </View>
      ) : (
        <View style={styles.attachedItemContainer}>
          <View
            style={styles.leftContainer}>
            <Icon
              source={images.uploadedSuccess}
              size={35}
              style={styles.rightSpacing}
            />
            <Text i18nKey={fileName} numberOfLines={1} />
          </View>
          <TouchableOpacity onPress={cleanFile} style={styles.clearContainer}>
            <Icon
              source={images.close}
              color={colors.secondaryIcon}
              size={12}
            />
          </TouchableOpacity>
        </View>
      )}
    </TouchableOpacity>
  )
}
FileItem.propTypes = {
  onPress: PropTypes.func,
  attached: PropTypes.bool,
  fileName: PropTypes.string,
  cleanFile: PropTypes.func,
}

function Information({
  userPhone,
  setPhone,
  nipt,
  setNipt,
  userEmail,
  setEmail,
  attachfile,
  attached,
  fileName,
  setAttached,
  setFileName,
  pass,
  setPass,
  niptError,
  attachFileWeb,
}) {
  const [styles] = useTheme(themedStyles)

  const [secureEntry, setSecureEntry] = useState(true)

  const changePasswordState = () => setSecureEntry(prev => !prev)

  return (
    <>
      <ScrollView
        keyboardShouldPersistTaps="always"
        showsVerticalScrollIndicator={false}
        keyboardDismissMode="on-drag"
        style={styles.scrollView}>

        <View style={styles.bottomSpacing}>
          <Text
            i18nKey="certificate_company_information_section"
            align="left"
            size="h5"
          />
          <Text i18nKey="certificate_enter_information_label" align="left" />
          <InputWithTags
            label="certificate_nipt_label"
            value={nipt}
            onChangeText={setNipt}
            autoCorrect={false}
            autoCapitalize="characters"
            error={niptError}
            editable={false}
            inputContainerStyle={styles.inputContainer}
            inputWrapperStyle={styles.itemSpacing}
            labelOffset={{
              x0: 0,
              y0: -7,
              x1: 0,
              y1: 2,
            }}
          />
          <InputWithTags
            label="certificate_email_label"
            value={userEmail}
            onChangeText={setEmail}
            autoCorrect={false}
            editable={false}
            inputContainerStyle={styles.inputContainer}
            inputWrapperStyle={styles.itemSpacing}
            labelOffset={{
              x0: 0,
              y0: -7,
              x1: 0,
              y1: 2,
            }}
          />
          <InputPhone
            label="certificate_phone_label"
            value={userPhone}
            prefix="+355"
            onChangeText={setPhone}
            autoCorrect={false}
            inputContainerStyle={styles.inputContainer}
            inputWrapperStyle={styles.itemSpacing}
            keyboardType="numeric"
            labelOffset={{
              x0: 0,
              y0: -7,
              x1: 0,
              y1: 2,
            }}
          />
        </View>
        <View style={styles.mediumBottomSpacing}>
          <Text i18nKey="certificate_upload_label" align="left" />
          {Platform.OS === 'web' ?
            <CertificateInputWeb
              onDrop={attachFileWeb}
              accept="application/x-pkcs12"
              attached={attached}
              fileName={fileName}
              cleanFile={() => {
                setAttached(false)
                setFileName('')
              }}

            />
            :
            <FileItem
              onPress={attachfile}
              attached={attached}
              fileName={fileName}
              cleanFile={() => {
                setAttached(false)
                setFileName('')
              }}
            />
          }
          <InputWithIcon
            label="certificate_password_label"
            value={pass}
            onChangeText={setPass}
            autoCorrect={false}
            secureTextEntry={secureEntry}
            inputContainerStyle={styles.inputContainer}
            inputWrapperStyle={styles.itemSpacing}
            labelOffset={{
              x0: 0,
              y0: -7,
              x1: 0,
              y1: 2,
            }}
            icon={secureEntry ? images.hiddenPass : images.pass}
            onIconPress={changePasswordState}
          />
          <Text i18nKey="certificate_password_helper" weight="light" />
        </View>
      </ScrollView>
    </>
  )
}
Information.propTypes = {
  data: PropTypes.bool,
  userPhone: PropTypes.string,
  setPhone: PropTypes.func,
  nipt: PropTypes.string,
  setNipt: PropTypes.func,
  userEmail: PropTypes.string,
  setEmail: PropTypes.func,
  attachfile: PropTypes.func,
  attached: PropTypes.bool,
  fileName: PropTypes.string,
  setAttached: PropTypes.func,
  setFileName: PropTypes.func,
  pass: PropTypes.string,
  setPass: PropTypes.func,
  disabled: PropTypes.bool,
  onBackPress: PropTypes.func,
  onNextPress: PropTypes.func,
  niptError: PropTypes.bool,
  attachFileWeb: PropTypes.func,

}

export default Information

