
export const BluetoothManager = {}
export const BluetoothEscposPrinter = {}
export const PERMISSIONS = {}
export const RESULTS = {}
export const check = () => { }
export const request = () => { }
export const openSettings = () => { }
export const PROVIDER_GOOGLE = {}
export const Marker = {}
export const AnimatedRegion = {}
export const launchCamera = {}
export const launchImageLibrary = {}
export const selectContactPhone = () => { }

// eslint-disable-next-line import/no-anonymous-default-export
export default { types: {} }
