import React, { useMemo } from 'react'
import { View } from 'react-native'
import PropTypes from 'prop-types'
import LottieView from 'lottie-react-native'

import styles from './Loader.Styles'
import { animations } from '../../Themes'

function Loader({ view, size, source, style, autoPlay, loop }) {

  const loaderComponent = useMemo(function loader() {
    const propStyle = {
      width: size,
      height: size,
    }
    const container = {}

    if (view === 'full') {
      container.flex = 1
    }
    return <View style={[styles.container, container]}>
      <LottieView
        source={source}
        autoPlay={autoPlay}
        loop={loop}
        resizeMode="cover"
        style={[styles.container, propStyle, style]}
      />
    </View>
  }, [autoPlay, loop, size, source, style, view])

  return loaderComponent
}

Loader.defaultProps = {
  source: animations.vfLoaderRed,
  size: 32,
  view: 'normal',
  autoPlay: true,
  loop: true,
}

Loader.propTypes = {
  view: PropTypes.oneOf(['normal', 'full']),
  color: PropTypes.string,
  size: PropTypes.number,
  style: PropTypes.object,
  source: PropTypes.any,
  autoPlay: PropTypes.bool,
  loop: PropTypes.bool,
}

export default Loader
