import React, { useContext } from 'react'
import { View, TouchableOpacity } from 'react-native'

import PropTypes from 'prop-types'

import { useTheme } from 'react-native-themed-styles'

import Text from '../Text'

import Modal from '../Modal'

import Icon from '../Icon'

import Theme from '../../Themes/Theme'

import themedstyles from './ColorPicker.Styles'
import images from '../../Themes/Images'

function ColorPicker(props) {

  const [styles] = useTheme(themedstyles)
  const { colors } = useContext(Theme)
  const { isVisible, onSelect, onClosePress } = props

  return (
    <Modal
      isVisible={isVisible}
      animationIn="fadeIn"
      animationOut="fadeOut"
      useNativeDriver={true}
    >
      <View style={styles.modalContent}>
        <TouchableOpacity style={styles.closeContainer}
          onPress={onClosePress}
        >
          <Icon size={15} source={images.close} color={colors.white} />
        </TouchableOpacity>
        <Text i18nKey="choose_color_label" align="center" weight="bold" size="h4" />
        <View style={styles.colorsContainer}>
          <View style={styles.colorsSubContainer}>
            <TouchableOpacity
              onPress={() => onSelect(styles.color1.backgroundColor)}
              style={[styles.rightSpacing, styles.colorContainer, styles.color1]}
            />
            <TouchableOpacity
              onPress={() => onSelect(styles.color2.backgroundColor)}
              style={[styles.leftSpacing, styles.rightSpacing, styles.colorContainer, styles.color2]}
            />
            <TouchableOpacity
              onPress={() => onSelect(styles.color3.backgroundColor)}
              style={[styles.leftSpacing, styles.colorContainer, styles.color3]}
            />
          </View>
          <View style={styles.colorsSubContainer}>
            <TouchableOpacity
              onPress={() => onSelect(styles.color4.backgroundColor)}
              style={[styles.rightSpacing, styles.colorContainer, styles.color4]}
            />
            <TouchableOpacity
              onPress={() => onSelect(styles.color5.backgroundColor)}
              style={[styles.leftSpacing, styles.rightSpacing, styles.colorContainer, styles.color5]}
            />
            <TouchableOpacity
              onPress={() => onSelect(styles.color6.backgroundColor)}
              style={[styles.leftSpacing, styles.colorContainer, styles.color6]}
            />
          </View>
          <View style={styles.rowView}>
            <TouchableOpacity
              onPress={() => onSelect(styles.color7.backgroundColor)}
              style={[styles.rightSpacing, styles.colorContainer, styles.color7]}
            />
            <TouchableOpacity
              onPress={() => onSelect(styles.color8.backgroundColor)}
              style={[styles.leftSpacing, styles.rightSpacing, styles.colorContainer, styles.color8]}
            />
            <TouchableOpacity
              onPress={() => onSelect(styles.color9.backgroundColor)}
              style={[styles.leftSpacing, styles.colorContainer, styles.color9]}
            />
          </View>
        </View>
      </View>
    </Modal>
  )
}

ColorPicker.defaultProps = {
  isVisible: true,
}

ColorPicker.propTypes = {
  isVisible: PropTypes.bool,
  onSelect: PropTypes.func,
  onClosePress: PropTypes.func,
}

export default ColorPicker

