import { styleSheetFactory } from '../../../../Themes/Colors'

const styles = styleSheetFactory(colors => ({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  lottieView: {
    width: 300,
    height: 300,
  },
  buttonContainer: {
    alignSelf: 'stretch',
    height: 50,
    margin: 15,
    marginBottom: 30,
  },
}))

export default styles
