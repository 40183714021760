const en = {

  //Receipt app
  invoice_header: 'INVOICE',

  //Recipt
  receipt_tax: 'VAT',
  BUYER: 'Buyer',
  SELLER: 'Seller',
  einvoice_buyertin_label: 'Buyer NUIS',
  receipt_foreign_currency_label: 'Total in ',
  receipt_nipt_label: 'NUIS',
  receipt_preview_items: 'Items',
  receipt_item_price_label: 'Price',
  receipt_total: 'Total to be paid',
  receipt_subtotal: 'Subtotal',
  receipt_discount_line: 'Discount',
  receipt_change: 'Change',
  receipt_total_method: 'Payment method',
  receipt_invoice_number: 'Invoice Number',
  receipt_created_at: 'Date & Time',
  payment_supplying_period_label: 'Supplying period',
  receipt_fic_label: 'FIC',
  receipt_iic_label: 'IIC',
  receipt_operator_code_label: 'Operator code',
  receipt_software_code_label: 'Software code',
  receipt_generated_by: 'Generated by',
  receipt_payment_type_cash: 'Cash',
  receipt_payment_type_non_cash: 'Non-Cash',
  receipt_label_type_reverse_charge: 'Auto invoice',
  receipt_label_type_noncash: 'Bank Invoice \n(Non-cash)',
  receipt_label_type_export: 'Export Invoice',
  receipt_type: 'Tax invoice',
  receipt_refund_reason: 'Reason',
  receipt_section_primary_invoice: 'Primary invoice',
  receipt_section_primary_iic_label: 'Primary IIC',
  receipt_section_primary_fic_label: 'Primary FIC',
  receipt_payment_info_label: 'Payment Information',
  receipt_payment_name_label: 'Account name',
  receipt_payment_iban_label: 'IBAN',
  receipt_payment_swift_label: 'SWIFT',
  receipt_payment_description_label: 'Payment description',
  payment_delivery_receiver_label: 'Delivery receiver',
  receipt_currency_rate_label: 'Exchange rate',
  receipt_invoice_type: 'Invoice type',
  //supporting invoices
  supporting_invoice_name_label: 'Name',
  supporting_invoice_address_label: 'Address',
  supporting_invoice_list_items_label: 'List of Invoice Items',
  supporting_invoice_amount_label: 'Amount',
  supporting_invoice_receipt_info: 'Invoice information',
  supporting_invoice_issuer_label: 'Issuer',
  supporting_invoice_carrier_label: 'Carrier',
  supporting_invoice_label_type: 'Support Invoice',
  supporting_invoice_fwtnic: 'FWTNIC',
  supporting_invoice_seller: 'Seller',
  supporting_invoice_type: 'Invoice type',
  supporting_invoice_number: 'Invoice number',
  supporting_invoice_transaction_type_label: 'Transaction type',
  supporting_invoice_transport_info_label: 'Transport Info',
  supporting_invoice_transport_owner_label: 'Ownership of the means of transport',

  supporting_invoice_transport_plate: 'Plate',
  supporting_invoice_preview_dangerous: 'Dangerous',
  supporting_invoice_preview_accompaniment: 'Accompaniment',
  supporting_invoice_no_question: 'No',
  supporting_invoice_yes_question: 'Yes',
  supporting_invoice_transport_starting_point: 'Starting Point',
  supporting_invoice_transport_destination_point: 'Destination Point',
  supporting_invoice_transport_starting_address: 'Starting Address',
  supporting_invoice_transport_destination_date: 'Destination Date',
  supporting_invoice_city: 'City',
  supporting_invoice_starting_date: 'Starting Date',
  supporting_invoice_transport_destination_address: 'Destination Address',

  customer_vat_number_label: 'VAT number',
  customer_tax_number_label: 'Tax number',
  customer_soc_number_label: 'Social Security Number (SSN)',
  customer_passport_number_label: 'Passport number',

  //E-invoice
  e_invoice_number: 'Invoice Number',
  e_invoice_date: 'Date',
  e_invoice_seller_label: 'Seller',
  e_invoice_address_label: 'Address',
  e_invoice_tel_label: 'Tel',
  e_invoice_buyer_label: 'Buyer',
  e_invoice_item_label: 'Item',
  e_invoice_barcode_label: 'Barcode',
  e_invoice_unit_label: 'Unit',
  e_invoice_quantity_label: 'Quantity',
  e_invoice_price_label: 'Unit price without VAT',
  e_invoice_price_without_vat_label: 'Value without VAT',
  e_invoice_price_vat_label: 'Price per unit with VAT',
  e_invoice_no_tax_label: 'No-VAT',
  e_invoice_tax_label: 'Value of VAT',
  e_invoice_total_label: 'Value with VAT',
  e_invoice_total_discount_label: 'Total invoice discounts',
  e_invoice_total_amount_no_tax: 'Total amount without VAT',
  e_invoice_total_tax_amount: 'Vat amount',
  e_invoice_total_amount_with_tax: 'Total amount with VAT',
  e_invoice_bussines_unit_code_label: 'Bussines unit code',
  e_invoice_bussines_soft_code_label: 'Software code',
  e_invoice_bussines_operator_code_label: 'Operator code',
  e_invoice_eic_label: 'EIC',
  e_invoice_info_label: 'Invoice information',
  e_invoice_payment_type_label: 'Payment method',
  e_invoice_payment_type_value: 'Non-cash',

  e_invoice_payment_bic_label: 'Code',
  e_invoice_proccess_type: 'Type of process',
  e_invoice_bill_type: 'Invoice type',
  e_invoice_signature_field_label: '( name, surname, signature )',
  e_invoice_carrier_label: 'Carrier',
  e_invoice_remittances_label: 'Delivery',
  e_invoice_recipient_name_label: 'Recipient',
  e_invoice_date_of_shipment_label: 'Date of shipment',
  e_invoice_country_label: 'Country',
  e_invoice_title_label: 'E-Invoice',
  e_invoice_title_label_1: 'E-Invoice',
  e_invoice_title_label_2: '',
  e_invoice_notes_label: 'Notes',
  e_invoice_scan_qr: 'Scan QR code for more details',


  document_type_384_description: 'Corrected invoice',
  document_type_388_description: 'Tax invoice',
  document_type_82_description: 'Metered services invoice',
  document_type_325_description: 'Proforma invoice',
  document_type_326_description: 'Partial invoice',
  document_type_380_description: 'Commercial invoice',
  document_type_381_description: 'Credit note',
  document_type_383_description: 'Debit note',
  document_type_386_description: 'Prepayment invoice',
  document_type_394_description: 'Lease invoice',

  process_type_p2_description: 'Invoicing deliveries of goods and services based on a contract',
  process_type_p10_description: 'Corrective invoicing',
  process_type_p1_description: 'Invoicing of deliveries of goods and services against purchase orders, based on a contract',
  process_type_p3_description: 'Invoicing the delivery of an incidental purchase order',
  process_type_p4_description: 'Pre-payment',
  process_type_p5_description: 'Spot payment',
  process_type_p6_description: 'Payment in advance of delivery',
  process_type_p7_description: 'Invoices with references to a despatch advice',
  process_type_p8_description: 'Invoices with references to a despatch advice and a receiving advice',
  process_type_p9_description: 'Credit notes or invoices with negative amounts, issued for a variety of reasons including the return of empty packaging',
  process_type_p11_description: 'Partial and final invoicing',
  process_type_p12_description: 'Self billing',

  payment_account_description: 'Account transaction',
  payment_factoring_description: 'Factoring',
  payment_compensation_description: 'Compensation',
  payment_waiver_description: 'Debt write-off',
  payment_transfer_description: 'Transfer of rights or debts',
  payment_kind_description: 'In kind payment',
  payment_other_description: 'Other noncash payments',
  payment_tax_free_description: 'Export of the services',
  payment_exportof_goods_description: 'Export of the products',
  payment_banknote_description: 'Banknotes and coins',
  payment_card_description: 'Debit and credit card',
  payment_check_description: 'Bank check',
  payment_voucher_description: 'Voucher',
  payment_company_description: 'Company cards',
  payment_order_description: 'The bill is still unpaid. Will be paid with summary bill.',

  //Transaction print from app

  receipt_header: 'Receipt',
  receipt_subtotal_label: 'Subtotal',
  receipt_total_discount_label: 'Total discount',
  receipt_total_label: 'TOTAL',
  receipt_foreign_total_label: 'Total',
  receipt_cash_money_label: 'Cash amount',
  receipt_nsfl_label: 'IIC',
  receipt_nivf_label: 'FIC',
  receipt_nsfl_primary_label: 'IIC Prim',
  receipt_nivf_primary_label: 'FIC Prim',
  receipt_operator_code: 'Operator code',
  receipt_software_code: 'Software code',
  receipt_seller_label: 'Seller',
  receipt_buyer_label: 'Buyer',
  receipt_number_label: 'Receipt nr.',
  receipt_date_time_label: 'Date & Time',
  receipt_buyer_address_label: 'Buyer address',
  receipt_buyer_nipt_label: 'Buyer NUIS',
  receipt_thank_you_label: 'Thank you',
  wtn_receipt_info: 'Receipt\n Information',
  wtn_fwtnic_label: 'FWTNIC',
  wtn_invoice_type: 'Invoice type',
  wtn_transaction_type: 'Transaction type',
  wtn_information_label_1: 'Transport',
  wtn_information_label_2: 'information',
  wtn_owner_veh: 'Transport ownership',
  wtn_plate_label: 'License Plate',

  //UNITS
  KGM: 'Kilogram',
  GRM: 'Gram',
  CMT: 'Centimeter',
  MTR: 'Meter',
  LTR: 'Liter',
  XPP: 'Piece',
  HUR: 'Hours',
  E49: 'Day',
  MTK: 'Square meter',
  A19: 'Cubic meter',
  M4: 'Monetary value',
  KMT: 'Kilometer',
  WM: 'Month of work',
  MWH: 'MWh',

  //pay in out
  pay_in_out_title: 'PAYMENT RECEIPT',
  pay_in_out_description: 'Description',
  pay_in_out_sum: 'Sum',
  pay_in_out_id: 'ID',
  pay_in_out_buscode_label: 'Bussines unit code',
  pay_in_out_number: 'Payment receipt number',
}

export default en



