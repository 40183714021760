import React from 'react'
import { View } from 'react-native'
import PropTypes from 'prop-types'
import { useTheme } from 'react-native-themed-styles'

import { stepsArrayIndex } from '../../../Payment.Config'

import themedStyles from './DotSteps.Styles'

function DotSteps({ index, currentPaymentMethod }) {
  const [styles] = useTheme(themedStyles)

  return (
    <View style={styles.indicatorContainer}>
      {stepsArrayIndex(currentPaymentMethod).map((item, i) => (
        <View
          key={item}
          style={styles['indicator_' + (index === i + 1)]}
        />
      ))}
    </View>
  )
}
DotSteps.propTypes = {
  index: PropTypes.number,
  currentPaymentMethod: PropTypes.string,
}

export default DotSteps
