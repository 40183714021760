import { Dimensions } from 'react-native'

import { styleSheetFactory } from '../../Themes/Colors'

const { width } = Dimensions.get('screen')

const styles = styleSheetFactory(colors => ({
  container: {
    paddingHorizontal: 60,
  },
  image: {
    width: 50,
    height: 50,
    alignSelf: 'center',
  },
  text: {
    color: colors.white,
    marginBottom: 15,
  },
  colorsContainer: {
    paddingHorizontal: 20,
    marginTop: 20,
    justifyContent: 'center',
    alignItems: 'center',
  },
  colorsSubContainer: {
    flexDirection: 'row',
    marginBottom: 10,
  },
  rightSpacing: {
    marginRight: 5,
  },
  leftSpacing: {
    marginLeft: 5,
  },
  rowView: {
    flexDirection: 'row',
  },
  colorBox: {
    width: 64,
    height: 64,
    margin: 10,
    backgroundColor: 'red',
    borderRadius: 5,
  },
  color1: {
    backgroundColor: '#9F52FF',
  },
  color2: {
    backgroundColor: '#FCD716',
  },
  color3: {
    backgroundColor: '#FFA726',
  },
  color4: {
    backgroundColor: '#6CCDFE',
  },
  color5: {
    backgroundColor: '#EF5350',
  },
  color6: {
    backgroundColor: '#66BB6A',
  },
  color7: {
    backgroundColor: '#424242',
  },
  color8: {
    backgroundColor: '#F15C9A',
  },
  color9: {
    backgroundColor: '#FFFFFF',
  },

  modalContent: {
    backgroundColor: colors.background,
    alignSelf: 'center',
    paddingBottom: 30,
    borderRadius: 6,
    minWidth: width - 60,
    maxWidth: 400,
  },
  closeContainer: {
    backgroundColor: colors.disabled,
    alignSelf: 'flex-end',
    borderTopRightRadius: 7,
    borderBottomLeftRadius: 7,
    width: 80,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 10,
    marginBottom: 15,
  },
  colorContainer: {
    width: width / 6,
    height: width / 6,
    borderRadius: 6,
    justifyContent: 'center',
    alignItems: 'center',
  },
}))

export default styles

