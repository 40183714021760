import { styleSheetFactory } from '../../Themes/Colors'

const styles = styleSheetFactory((colors) => ({
  container: {
    flexGrow: 1,
    flex:1,
    backgroundColor: colors.background,
  },
  noteView: {
    backgroundColor: colors.white,
    marginHorizontal: 15,
    marginVertical: 10,
    padding: 15,
    borderRadius: 7,
  },
  noteHeader: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  noteTitle: {
    marginLeft: 5,
  },
  buttonGroup: {
    flexDirection: 'row',
    // marginTop: 15,
    marginBottom: 10,
    minHeight: 50,
    justifyContent: 'center',
    alignItems: 'center',
    // marginHorizontal: 15,
  },
  listStyle: {
    flex: 1,
    marginHorizontal: 15,
  },
  listContainerStyle: {
    borderRadius: 7,
    overflow: 'hidden',
  },
  separator: {
    height: 1,
    backgroundColor: colors.separator,
  },
  hiddenText:  { height: 0, overflow: 'hidden' },
}))

const itemStyles = styleSheetFactory((colors) => ({
  listItem: {
    backgroundColor: colors.white,
    paddingHorizontal: 15,
    paddingVertical: 5,
    justifyContent: 'space-between',
    flexDirection: 'row',
    alignItems: 'center',
    height: 70,
  },
  smallText: {
    lineHeight: 16,
  },
  itemFirstRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    // backgroundColor: 'yellow',
  },
  firstItem_true: {
    borderTopLeftRadius: 7,
    borderTopRightRadius: 7,
  },
  lastItem_true: {
    borderBottomLeftRadius: 7,
    borderBottomRightRadius: 7,
  },
  touchableItem: {
    flexGrow: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    // backgroundColor: 'pink',
  },
  growView: { flexGrow: 1 },
  subContainer: { flexDirection: 'row', alignItems: 'center' },
}))

const hiddenItemStyles = styleSheetFactory((colors) => ({
  wrapper: {
    backgroundColor: colors.accent,
    flex: 1,
    justifyContent: 'center',
    alignItems: 'flex-end',
  },
  firstItem_true: {
    borderTopLeftRadius: 7.5,
    borderTopRightRadius: 7.5,
  },
  lastItem_true: {
    borderBottomLeftRadius: 7.5,
    borderBottomRightRadius: 7.5,
  },
  option: {
    width: 100,
    height: 60,
    justifyContent: 'center',
    alignItems: 'center',
  },
  optionFull: {
    flexDirection: 'row',
  },
  confirm: {
    flexGrow: 1,
    height: 70,
    flexDirection: 'row',
    paddingLeft: 15,
    alignItems: 'center',
  },
  confirmText: {
    marginLeft: 10,
  },
  cancel: {
    width: 40,
    height: 40,
    flexShrink: 0,
    marginVertical: 15,
    marginHorizontal: 15,
    borderRadius: 20,
    backgroundColor: colors.whiteRGBA(0.55),
    justifyContent: 'center',
    alignItems: 'center',
  },
  loader: {
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  confirmIcon: {
    width: 30,
    height: 30,
    borderRadius: 20,
    backgroundColor: colors.white,
    justifyContent: 'center',
    alignItems: 'center',
  },
}))

export { itemStyles, hiddenItemStyles }

export default styles
