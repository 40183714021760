import { Dimensions } from 'react-native'
import Color from 'color'

import { styleSheetFactory } from '../../Themes/Colors'

const { width } = Dimensions.get('screen')
const styles = styleSheetFactory(colors => ({
  modalContainer: {
    backgroundColor: colors.white,
    alignSelf: 'center',
    paddingBottom: 30,
    borderRadius: 6,
    minWidth: width - 80,
    maxWidth: 400,
  },
  closeContainer: {
    backgroundColor: colors.disabled,
    position: 'absolute',
    top: 0,
    right: 0,
    borderTopRightRadius: 7,
    borderBottomLeftRadius: 7,
    width: 75,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 10,
  },
  modalContent: {
    paddingHorizontal: 40,
    marginTop: 45,
  },
  errorIcon: {
    alignSelf: 'center',
    marginBottom: 5,
  },
  errorTitle: {
    marginVertical: 8,
  },
}))

const itemStyles = styleSheetFactory(colors => ({
  container: {
    height: 60,
    borderRadius: 7,
    marginVertical: 3,
    backgroundColor: colors.tertiaryBackground,
    justifyContent: 'center',
    overflow: 'hidden',
  },
  firstItem_true: {
    borderTopLeftRadius: 7,
    borderTopRightRadius: 7,
  },
  lastItem_true: {
    borderBottomLeftRadius: 7,
    borderBottomRightRadius: 7,
  },
  touchable: {
    flexGrow: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  abreviation: labelColor => {
    return {
      width: 60,
      height: 60,
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: Color(labelColor).fade(0.65).toString(),
    }
  },
  textContainer: {
    marginRight: 15,
    flexGrow: 1,
    flexShrink: 1,
    flexDirection: 'row',
    alignItems: 'center',
  },
  name: {
    marginLeft: 15,
  },
  rightIcon: {
    width: 24,
    height: 24,
    marginRight: 15,
    tintColor: colors.accent,
    flexShrink: 0,
  },
  radioInput: {
    flexShrink: 0,
    marginRight: 16,
  },
}))

const hiddenItemStyles = styleSheetFactory(colors => ({
  wrapper: {
    backgroundColor: colors.accent,
    height: 60,
    borderRadius: 7.5,
    marginVertical: 3,
    justifyContent: 'center',
    alignItems: 'flex-end',
  },
  option: {
    width: 100,
    height: 60,
    justifyContent: 'center',
    alignItems: 'center',
  },
  optionFull: {
    flexDirection: 'row',
  },
  confirm: {
    flexGrow: 1,
    height: 60,
    flexDirection: 'row',
    paddingLeft: 15,
    alignItems: 'center',
  },
  confirmText: {
    marginLeft: 15,
  },
  cancel: {
    width: 40,
    height: 40,
    flexShrink: 0,
    marginVertical: 10,
    marginHorizontal: 15,
    borderRadius: 20,
    backgroundColor: colors.whiteRGBA(0.55),
    justifyContent: 'center',
    alignItems: 'center',
  },
  loader: {
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
}))


const separatorStyles = styleSheetFactory(colors => ({
  container: {
    backgroundColor: colors.tertiaryBackground,
    height: 1,
  },
  separator: {
    backgroundColor: colors.separator,
    height: 1,
    marginLeft: 60,
  },
}))

export {
  itemStyles,
  hiddenItemStyles,
  separatorStyles,
}

export default styles


