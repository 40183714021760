import { gql } from '@apollo/client'

export const GET_QR = gql`
query generateBase64Qr($text:String!){
    generateBase64Qr(
        text: $text
    ){
        base64
    }
}
`
