import { styleSheetFactory } from '../../../../Themes/Colors'

const styles = styleSheetFactory(colors => ({
  container: {
    flex: 1,
    backgroundColor: colors.background,
  },
  fillRemaining: {
    flexGrow: 1,
    paddingHorizontal: 15,
    paddingBottom: 20,
  },
  buttons: {
    flexDirection: 'row',
    marginBottom: 15,
    marginTop: 10,
    marginHorizontal: 15,
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  leftButton: {
    flex: 1,
    height: 50,
  },
  rightButton: {
    flex: 1,
    height: 50,
    marginRight: 15,
  },
  input: {
    marginBottom: 16,
  },
  sectionText: {
    marginBottom: 5,
  },
  deliveryButton: {
    marginLeft: 10,
  },
}))

const inputStyles = styleSheetFactory(colors => ({
  touchableInput: {
    marginBottom: 16,
  },
}))

const fileItemStyles = styleSheetFactory(colors => ({
  fileContainer: {
    backgroundColor: colors.tertiaryBackground,
    overflow: 'hidden',
    borderRadius: 7,
    marginTop: 10,
    borderColor: colors.separator,
    borderWidth: 1,
  },
  clearContainer: {
    margin: 6.5,
    justifyContent: 'center',
    alignItems: 'center',
    width: 30,
    height: 30,
    borderRadius: 20,
    backgroundColor: colors.background,
    flexShrink: 0,
  },
  subFileContainer: {
    flex: 1,
    alignItems: 'center',
    padding: 15,
    paddingVertical: 10,
  },
  importIcon: {
    backgroundColor: colors.background,
    margin: 5,
    borderRadius: 7,
  },
  attachedFileContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: 15,
    alignItems: 'center',
  },
  attachedFileSubcontainer: {
    flexGrow: 1,
    flexShrink: 1,
    flexDirection: 'row',
    alignItems: 'center',
    marginRight: 60,
  },
  uploadedSuccess: {
    marginRight: 10,
  },
  attachFile: {
    margin: 20,
  },
}))

export { inputStyles, fileItemStyles }

export default styles
