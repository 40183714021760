import React, { useContext, useState } from 'react'
import {
  SafeAreaView,
  View,
  FlatList,
  TouchableOpacity,
} from 'react-native'
import PropTypes from 'prop-types'
import { useTheme } from 'react-native-themed-styles'
import { useMutation } from '@apollo/client'
import { useSelector } from 'react-redux'

import {
  Button,
  Text,
  RadioInput,
  InputRow,
  Header,
  Modal,
  Icon,
  Loader,
  ModalContent,
} from '../../Components'

import { getProductCatalog } from '../ProductCatalog/ProductCatalog.Selectors'

import { getErrorMessage } from '../../Utils'

import Theme from '../../Themes/Theme'
import { animations, images } from '../../Themes'
import themedStyles, { itemStyles, separatorStyles } from './NewUnit.Styles'

import { CREATE_MEASUREMENT_UNIT, EDIT_MEASUREMENT_UNIT } from './NewUnit.schema'

const prcissions = [0, 1, 2, 3, 4, 5]
const precissionDisplay = {
  0: '1',
  1: '.0',
  2: '.00',
  3: '.000',
  4: '.0000',
  5: '.00000',
}

function Precision({ value, selected, onPress, isFirst, isLast }) {
  const [styles] = useTheme(itemStyles)
  const { colors } = useContext(Theme)
  return (
    <View
      style={[
        styles.container,
        styles['firstItem_' + isFirst],
        styles['lastItem_' + isLast],
      ]}>
      <TouchableOpacity onPress={onPress} style={styles.touchable}>
        <View style={styles.textContainer}>
          <Text i18nKey={precissionDisplay[value]} numberOfLines={2} />
        </View>
        <View pointerEvents="none" style={styles.radioInput}>
          <RadioInput
            index={value}
            selected={selected}
            onPress={onPress}
            color={colors.secondaryAccent}
          />
        </View>
      </TouchableOpacity>
    </View>
  )
}

Precision.propTypes = {
  value: PropTypes.number,
  onPress: PropTypes.func,
  selected: PropTypes.number,
  isFirst: PropTypes.bool,
  isLast: PropTypes.bool,
}

function Separator() {
  const [styles] = useTheme(separatorStyles)
  return <View style={styles.container} />
}

function NewUnit({
  route: { params: { unit: unitObj = {}, onSave = () => { } } = {} } = {},
  navigation: { goBack },
}) {
  const [styles] = useTheme(themedStyles)
  const { colors } = useContext(Theme)

  const { locationId, businessName, address } = useSelector(getProductCatalog)

  const [unit, setUnit] = useState({ ...unitObj })
  const [createUnit, { loading: creating }] = useMutation(
    CREATE_MEASUREMENT_UNIT,
  )
  const [updateUnit, { loading: updating }] = useMutation(EDIT_MEASUREMENT_UNIT)

  const [modal, setModal] = useState({
    isVisible: false,
    icon: images.warningIcon,
    title: '',
    desc: '',
  })
  const openModal = (
    desc = '',
    title = 'unit_general_error',
    icon = images.warningIcon,
  ) => {
    setModal({
      isVisible: true,
      icon: icon,
      title: title,
      desc: desc,
    })
  }
  const closeModal = () => {
    setModal({
      isVisible: false,
      icon: images.delete,
      title: '',
      desc: '',
    })
  }

  const save = () => {
    if (!unit.name) {
      return
    }
    if (!unit.abbreviation) {
      return
    }
    if (!unitObj?.id) {
      createUnit({
        variables: {
          ...unit,
          locationId: locationId ? locationId : null,
        },
      })
        .then((data) => {
          // displayToast('unit_add_success')
          const { createMeasurementUnit: createdMeasurementUnit } = data.data
          onSave({ ...createdMeasurementUnit })
          goBack()
        })
        .catch((e) => {
          openModal(getErrorMessage(e))
          return
        })
    } else {
      updateUnit({
        variables: {
          id: unit?.id,
          precision: unit?.precision,
          locationId: locationId ? locationId : null,
        },
      })
        .then((data) => {
          const { updateMeasurementUnit: updatedMeasurementUnit } = data.data
          onSave({ ...updatedMeasurementUnit })
          goBack()
        })
        .catch((e) => {
          openModal(getErrorMessage(e))
          return
        })
    }
  }

  const select = (value) => {
    setUnit({
      ...unit,
      precision: value,
    })
  }
  const keyExtractor = (_, index) => 'precission-' + index
  const renderItem = ({ item, index }) => (
    <Precision
      value={item}
      selected={unit.precision}
      onPress={() => select(item)}
      isFirst={index === 0}
      isLast={index === prcissions.length - 1}
    />
  )

  return (
    <>
      <SafeAreaView style={styles.container}>
        <Header
          title={unit?.name ? 'header_update_unit' : 'header_new_unit'}
          image={images.closeIcon}
          onPress={goBack}
          customBusinessName={businessName}
          customAddress={address}
        />
        <View style={styles.nameContainer}>
          <InputRow
            label="item_choose_unit_label"
            placeholder="item_choose_unit_label"
            value={unit.name}
            maxLength={15}
            onChangeText={(text) => setUnit({ ...unit, name: text })}
            editable={false}
          />
          <InputRow
            label="unit_abbreviation_label"
            placeholder="unit_abbreviation_label"
            value={unit.abbreviation}
            maxLength={4}
            onChangeText={(text) => setUnit({ ...unit, abbreviation: text })}
            editable={false}
          />
        </View>

        <Text style={styles.sectionTitle} i18nKey={'unit_precision_section'} />
        <FlatList
          data={prcissions}
          keyExtractor={keyExtractor}
          renderItem={renderItem}
          ItemSeparatorComponent={Separator}
          contentContainerStyle={styles.list}
        />
        <Button
          title={'unit_button_save'}
          style={styles.button}
          variant={
            !unit.name || !unit.abbreviation || creating || updating
              ? 'disabled'
              : 'active'
          }
          onPress={save}
          loader={creating || updating}
          loaderComponent={
            <Loader size={32} source={animations.vfLoaderThinDark} />
          }
        />
        <Modal
          isVisible={modal.isVisible}
          onBackButtonPress={() => closeModal()}
          onBackdropPress={() => closeModal()}
          animationIn="fadeIn"
          animationOut="fadeOut">
          <ModalContent onClose={() => closeModal()}>
            <Icon source={modal.icon} style={styles.modalIcon} size={40} />
            <Text
              i18nKey={modal.title}
              color={colors.primaryText}
              size="h5"
              align="center"
              weight="bold"
              style={styles.modalTitle}
            />
            <Text i18nKey={modal.desc} align="center" />
          </ModalContent>
        </Modal>
      </SafeAreaView>
    </>
  )
}

NewUnit.propTypes = {
  route: PropTypes.object,
  navigation: PropTypes.object,
}

export default NewUnit
