import React, { useContext, useEffect, useRef, useState } from 'react'
import { SafeAreaView, ScrollView, View, Dimensions, RefreshControl, Platform } from 'react-native'
import moment from 'moment'
import PropTypes from 'prop-types'
import { useTheme } from 'react-native-themed-styles'
import { useReactToPrint } from 'react-to-print'
import { useMutation, useQuery } from '@apollo/client'
import ContentLoader, { Rect } from 'react-content-loader/native'

import {
  Button,
  Header,
  Section,
  ErrorModal,
  RetryBanner,
  SimpleType,
  BottomSheetModal,
  SimpleSelectType,
} from '../../Components'
import { ConvertPayinPayout } from '../../Utils/PdfTemplatesWeb.web'
import { useTemplateLanguage, printPayInOut, useConfig } from '../../Utils'
import { INF } from '../../Utils/Helpers'
import Merchant from '../../Utils/Merchant'

import { GET_CASH_DRAWER_SHIFT_EVENT, SYNC_CASH_DRAWER_SHIFT_EVENTS } from './PayInOutDetails.Schema'

import Theme from '../../Themes/Theme'
import images from '../../Themes/Images'
import themedStyles from './PayInOutDetails.Styles'

const { width } = Dimensions.get('screen')

const eventTypeDesc = {
  title: {
    PAID_IN: 'drawer_pay_in_label',
    PAID_OUT: 'drawer_pay_out_label',
    CARD_PAID_IN: 'drawer_card_pay_in_label',
  },
  amountSign: {
    PAID_IN: '',
    PAID_OUT: '-',
    CARD_PAID_IN: '',

  },
}

const FAIL_REASONS = [
  {
    label: 'receipt_retry_no_internet',
    id: '1',
    value: 'NOINTERNET',
  },
  {
    label: 'receipt_retry_technical_problem',
    id: '2',
    value: 'BOUNDBOOK',

  },
  {
    label: 'receipt_retry_general_problem',
    id: '3',
    value: 'SERVICE',

  },
  {
    label: 'receipt_retry_boundbook_problem',
    id: '4',
    value: 'TECHNICALERROR',

  },
]

function LoaderContent({ style, isLast }) {
  const [styles] = useTheme(themedStyles)
  return (
    <ContentLoader
      speed={0.7}
      height={70}
      style={Object.assign({}, styles.loaderContainer(isLast), style)}
      backgroundColor="#e2e2e2"
      foregroundColor="#ecebeb">
      <Rect x="20" y="20" rx="3" ry="3" width="90" height="20" />
      <Rect x={`${width - 150}`} y="18" rx="3" ry="3" width="100" height="24" />
    </ContentLoader>
  )
}
LoaderContent.propTypes = {
  isFirst: PropTypes.bool,
  isLast: PropTypes.bool,
  style: PropTypes.object,
}

function ItemLoader() {
  const [styles] = useTheme(themedStyles)

  return (
    <View style={styles.loaderWrapper}>
      <LoaderContent />
      <LoaderContent />
      <LoaderContent />
      <LoaderContent />
      <LoaderContent />
      <LoaderContent />
      <LoaderContent />
      <LoaderContent />
      <LoaderContent />
      <LoaderContent />
      <LoaderContent isLast />
    </View>
  )
}

function PayInOutDetails({
  route: {
    params: { type, cashRegisterId, eventId },
  },
  navigation: {
    navigate,
    goBack,
  },
}) {

  const { switchLocation: { deviceId, locationId } } = useConfig()

  const [styles] = useTheme(themedStyles)
  const { colors } = useContext(Theme)
  const { nipt, company, location } = useContext(Merchant)
  const { openConfigurationSelector, translate: configTranslate } = useTemplateLanguage()
  const convertWeb = useRef()
  const retryBannerRef = useRef()

  const [event, setEventData] = useState({})
  const [status, setStatus] = useState('')

  const [selectedReason, setSelectedReason] = useState({})
  const [loading, setLoading] = useState(true)
  const { data, refetch } = useQuery(
    GET_CASH_DRAWER_SHIFT_EVENT,
    {
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
      variables: {
        cashRegisterId,
        eventId,
      },
      onCompleted: () => {
        setEventData(data?.retrieveCashDrawerShiftEvent)
        setStatus(data?.retrieveCashDrawerShiftEvent?.status)
        setLoading(false)
      },
    },
  )

  const [
    syncCashDrawerShiftEvent,
    { loading: syncLoading },
  ] = useMutation(SYNC_CASH_DRAWER_SHIFT_EVENTS)

  const [confirm, setConfirm] = useState(false)
  const [printing, setPrinting] = useState(false)
  const [loadingPrinWeb, setLoadingPrintWeb] = useState(false)

  const [errorModal, setErrorModal] = useState({
    isVisible: false,
    icon: images.error,
    title: '',
    desc: '',
    primaryText: '',
    primaryAction: null,
    secondaryText: '',
    secondaryAction: null,
  })

  const [syncModal, setSyncModal] = useState({
    isVisible: false,
    Item: SimpleType,
  })

  const openErrorModal = (
    title = 'receipt_printer_error',
    desc = '',
    primaryText = '',
    primaryAction = null,
    secondaryText = '',
    secondaryAction = null,
    icon = images.warningIcon,
  ) => {
    setErrorModal({
      isVisible: true,
      icon,
      title,
      desc,
      primaryText,
      primaryAction,
      secondaryText,
      secondaryAction,
    })
  }

  const closeErrorModal = () => {
    setErrorModal({
      isVisible: false,
      icon: images.delete,
      title: '',
      desc: '',
      primaryText: '',
      primaryAction: null,
      secondaryText: '',
      secondaryAction: null,
    })
  }

  const buttonProps = {
    backgroundColor: {
      true: colors.secondaryAccent,
      false: colors.buttonSecondaryAccent,
    },
    foregroundColor: {
      true: colors.invertedText,
      false: colors.primaryText,
    },
    text: {
      true: 'receipt_button_confirm',
      false: 'receipt_button_print_receipt',
    },
    iconColor: {
      true: colors.tertiaryIcon,
      false: colors.secondaryIcon,
    },
  }

  const eventData = [
    {
      label: 'drawer_pay_inout_number',
      value: event?.statementNumber,
    },
    {
      label: 'payment_self_charge_type_label',
      value: eventTypeDesc.title[event?.eventType],
    },
    {
      label: 'drawer_date_time_label',
      value: event.createdAt ? moment(event.createdAt)?.format('DD/MM/YYYY hh:mm A') : '',
    },
    {
      label: 'drawer_label_amount',
      value: `${eventTypeDesc.amountSign[event?.eventType]}${INF.format(event?.eventMoney?.amount)} ${event?.eventMoney?.currency}`,
    },
    {
      label: 'receipt_user',
      value: event?.employee?.email,
    },
    {
      label: 'payment_self_charge_reason_placeholder',
      value: event.reason,
    },
    {
      label: 'drawer_description_label',
      value: event.description,
    },
    {
      label: 'drawer_id_label',
      value: status === 'IN_PROGRESS' ? 'drawer_processing_label' : event.FCDC,
    },
    {
      label: 'receipt_operator_code_label',
      value: event?.employee?.operatorCode,
    },
    {
      label: 'receipt_software_code_label',
      value: event.softCode,
    },
    {
      label: 'receipt_business_code_label',
      value: event.businUnitCode,
    },
  ]

  const activateConfirm = () => {
    if (confirm) {
      setPrinting(true)
      printPayInOut({
        eventData: event,
        nipt,
        company,
        location,
        translate: configTranslate,
      })
        .then(() => setPrinting(false))
        .catch((e) => {
          openErrorModal(
            'receipt_printer_error',
            'receipt_printer_error_label',
            'receipt_open_printers_button',
            () => {
              closeErrorModal()
              navigate('PrinterSetUp')
            },
            'checkout_button_skip',
            () => {
              closeErrorModal()
            },
            images.error
          )
          setPrinting(false)
        })
      setConfirm(false)
    } else {
      openConfigurationSelector(false, () => setConfirm(true))
    }
  }

  const handlePrintWeb = useReactToPrint({
    content: () => convertWeb.current,
    onBeforePrint: () => setLoadingPrintWeb(true),
    onAfterPrint: () => setLoadingPrintWeb(false),
  })

  const onHandlePrintWeb = () => {
    openConfigurationSelector(false, handlePrintWeb)
  }

  const onSyncCashDrawerShiftEvent = () => {
    setSyncModal(prev => ({ ...prev, isVisible: false }))
    const syncObject = {
      locId: locationId,
      cashRegisterId: deviceId,
      eventSyncObjects: [
        {
          id: eventId,
          reason: selectedReason?.value,
        },
      ],
    }

    syncCashDrawerShiftEvent({
      variables: syncObject,
    }).then(res => {
      retryBannerRef.current.closeBanner()
      refetch().then(() =>
        retryBannerRef.current.changeStatus()
      ).catch()
    }).catch(e => { })
  }

  useEffect(() => {
    refetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status])


  return (
    <>
      <SafeAreaView style={styles.container}>
        <Header
          title={eventTypeDesc.title?.[type]}
          image={images.closeIcon}
          onPress={() => goBack()}
        />
        <ScrollView
          showsVerticalScrollIndicator={false}
          style={styles.horizontalSelfSpacing}
          refreshControl={
            <RefreshControl
              refreshing={false}
              onRefresh={refetch}
            />
          }
        >
          <Button
            title={buttonProps.text[confirm]}
            variant={printing || loadingPrinWeb ? 'disabled' : 'preActive'}
            style={styles.printButton}
            onPress={Platform.OS === 'web' ? () => onHandlePrintWeb() : () => activateConfirm()}
            icon={images.printerActive}
            iconColor={printing || loadingPrinWeb ? colors.white : colors.secondaryIcon}
            loader={printing || loadingPrinWeb}
            iconStyle={confirm ? { tintColor: buttonProps.iconColor[confirm] } : null}
            backgroundColor={confirm ? buttonProps.backgroundColor[confirm] : null}
            color={printing || loadingPrinWeb ? colors.white : confirm ? buttonProps.foregroundColor[confirm] : colors.primaryText}
            disabled={loading}
          />

          {loading ? <ItemLoader /> :
            <>
              <RetryBanner
                ref={retryBannerRef}
                onRetry={() => setSyncModal(prev => ({ ...prev, isVisible: true }))}
                loading={syncLoading}
                status={status}
                //if status rejected fcdc is the gov code error
                code={event.FCDC}
                message={event?.failMessage}
              />
              <Section data={eventData} style={styles.bottomSpacing} />
            </>

          }
        </ScrollView>
      </SafeAreaView>
      <ErrorModal
        isVisible={errorModal.isVisible}
        icon={errorModal.icon}
        title={errorModal.title}
        description={errorModal.desc}
        primaryText={errorModal.primaryText}
        primaryAction={errorModal.primaryAction}
        secondaryText={errorModal.secondaryText}
        secondaryAction={errorModal.secondaryAction}
        onBackdropPress={() => closeErrorModal()}
        onBackButtonPress={() => closeErrorModal()}
        onClosePress={() => closeErrorModal()}
      />
      {Platform.OS === 'web' &&
        <div style={{ overflow: 'hidden', height: 0 }}>
          <ConvertPayinPayout
            eventData={event}
            nipt={nipt}
            company={company}
            location={location}
            ref={convertWeb}
          />
        </div>
      }
      <BottomSheetModal
        isVisible={syncModal.isVisible}
        onClose={() => setSyncModal(prev => ({ ...prev, isVisible: false }))}
      >
        <SimpleSelectType
          subtitle="receipt_retry_subtitle"
          data={FAIL_REASONS}
          Item={syncModal.Item}
          title="receipt_retry_title"
          placeholder="einvoice_doc_type_search_placeholder"
          selected={selectedReason?.id}
          select={obj => setSelectedReason(obj)}
          closeModal={() => setSyncModal(prev => ({ ...prev, isVisible: false }))}
        />
        <Button
          title="receipt_retry_synchronize_button"
          style={styles.retryButton}
          variant={Object.keys(selectedReason).length > 0 ? 'active' : 'disabled'}
          onPress={onSyncCashDrawerShiftEvent}
        />
      </BottomSheetModal>
    </>
  )
}

PayInOutDetails.propTypes = {
  drawerId: PropTypes.string,
  route: PropTypes.object,
  navigation: PropTypes.object,
}

export default PayInOutDetails
