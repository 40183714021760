import React, { useRef, useState, useContext, useMemo } from 'react'
import { View, Animated, TouchableOpacity, RefreshControl, Easing } from 'react-native'
import PropTypes from 'prop-types'

import { useTheme } from 'react-native-themed-styles'

import SimpleListElement from '../SimpleListElement'
import Text from '../Text'
import Icon from '../Icon'
import ListElement from '../ListElement'

import Theme from '../../Themes/Theme'

import themedStyles from './SimpleList.Styles'
import { images } from '../../Themes'

const AnimatedTouchable = Animated.createAnimatedComponent(TouchableOpacity)

function SimpleList(props) {
  const [styles] = useTheme(themedStyles)
  const { colors } = useContext(Theme)

  const {
    DATA,
    listHeaderComponent,
    listFooterComponent,
    listEmptyComponent,
    sectionSeparatorComponent,
    style,
    sectionList,
    touchableItems,
    onItemPress,
    onEndReached,
    onRefresh,
    refreshing,
    withIcon,
    numColumns,
    containerStyle,
    separatorStyle,
    separatorContainerStyle,
    sectionHeaderStyle,
    isGrid,
    renderCustomHeader,
  } = props

  const listRef = useRef(null)
  const fabSize = useRef(new Animated.Value(0)).current
  const animate = value => {
    Animated.timing(fabSize, {
      toValue: value,
      duration: 370,
      useNativeDriver: true,
      easing: Easing.inOut(Easing.cubic),
    }).start()
  }

  const [scrollToTopSpacing, setSpacing] = useState({ paddingBottom: 30 })

  const measureSize = event => {
    const { height } = event.nativeEvent.layout
    if (height > 800) {
      setSpacing({ paddingBottom: 105 })
    }
  }

  const scrollToTop = () => {
    animate(0)
    if (sectionList) {
      listRef.current.scrollToLocation({
        animated: true,
        itemIndex: 0,
        sectionIndex: 0,
      })
    } else {
      listRef.current.scrollToOffset({
        offset: 0,
      })
    }
  }

  const renderSeparator = leadingItem => {
    if (numColumns < 2) {
      if (leadingItem?.separator) {
        return <View style={[styles.separatorContainer, separatorContainerStyle]}>
          <View style={[styles.separator, separatorStyle, styles.separatorSpacing]} />
        </View>
      }
      return <View style={[styles.separatorContainer, separatorContainerStyle]}>
        <View style={[styles.separator, separatorStyle]} />
      </View>
    }
    return null
  }

  const sectionHeaderStyles = useMemo(() => {
    const mergedStyle = Object.assign({}, { marginBottom: 10, marginTop: 20 }, sectionHeaderStyle)
    return mergedStyle
  }, [sectionHeaderStyle])

  const renderSectionHeader = sectionTitle => {
    return (
      <>
        {sectionTitle ?
          <Text size="footnote" style={sectionHeaderStyles} i18nKey={sectionTitle} />
          : renderCustomHeader
        }
      </>
    )
  }

  const renderItem = (item, index, listLength, itemWithIcon) => {
    const otherStyle = {}
    let isFirst = false, isLast = false
    if (index === 0) {
      otherStyle.borderTopLeftRadius = 7
      otherStyle.borderTopRightRadius = 7
      isFirst = true
    }
    if (index === listLength - 1) {
      otherStyle.borderBottomLeftRadius = 7
      otherStyle.borderBottomRightRadius = 7
      isLast = true
    }
    if (item.renderItem) {
      return item.renderItem(item, index, otherStyle, isFirst, isLast, isGrid)
    }

    if (itemWithIcon || item.withIcon) {
      return (
        <View
          key={`${item.id}-${index}`}
        >
          <ListElement
            disabled={!item.touchableItems}
            onPress={() => {
              if (item.onItemPress) {
                item.onItemPress()
              } else {
                onItemPress(item)
              }
            }}
            image={item.image}
            imageStyle={item.imageStyle}
            title={item.title}
            description={item.description}
            color={item.color}
            rightLabel={item.rightLabel}
            leftComponent={item.leftComponent}
            rightComponent={item.rightComponent}
            rightOrderComponent={item.rightOrderComponent}
            rightTextStyle={item.rightTextStyle}
            subDescText={item.subDescText}
            subDescriptionStyle={item.subDescriptionStyle}
            descriptionStyle={item.descriptionStyle}
            titleStyle={item.titleStyle}
            titleContainerStyle={item.titleContainerStyle}
            imageContainerStyle={item.imageContainerStyle}
            style={[item.style, otherStyle]}
            numOfLines={item.numOfLines}
          />
        </View>
      )
    }

    return (
      <TouchableOpacity
        key={`${item.id}-${index}`}
        onPress={() => {
          if (item.onItemPress) {
            item.onItemPress()
          } else {
            onItemPress(item)
          }
        }}
        disabled={item.touchableItems ? !item.touchableItems : !touchableItems}
      >
        <SimpleListElement
          leftText={item.leftText}
          leftTextProps={item.leftTextProps}
          leftTextStyle={item.leftTextStyle}
          leftDesc={item.leftDesc}
          leftDescStyle={item.leftDescStyle}
          leftExtraInfo={item.leftExtraInfo}
          leftComponent={item.leftComponent}
          rightText={item.rightText}
          rightTextProps={item.rightTextProps}
          rightTextStyle={item.rightTextStyle}
          rightDesc={item.rightDesc}
          rightDescStyle={item.rightDescStyle}
          rightExtraInfo={item.rightExtraInfo}
          rightComponent={item.rightComponent}
          subtitles={item.subtitles}
          style={[item.style, style]}
          ratio={item.ratio}
        />
      </TouchableOpacity>
    )
  }

  if (sectionList) {
    return (
      <View style={styles.listContainer}>
        <Animated.SectionList
          ref={listRef}
          onLayout={event => measureSize(event)}
          onMomentumScrollEnd={(event) => {
            if (event.nativeEvent.contentOffset.y > 800) {
              animate(1)
            } else {
              animate(0)
            }
          }}
          stickySectionHeadersEnabled={false}
          showsVerticalScrollIndicator={false}
          contentContainerStyle={[styles.contentContainer, style, scrollToTopSpacing]}
          ItemSeparatorComponent={({ leadingItem }) => renderSeparator(leadingItem)}
          keyExtractor={(item, index) => `${item.id}-${index}`}
          sections={DATA}
          numColumns={numColumns}
          style={containerStyle}
          ListHeaderComponent={listHeaderComponent}
          ListFooterComponent={listFooterComponent}
          ListEmptyComponent={listEmptyComponent}
          SectionSeparatorComponent={sectionSeparatorComponent}
          renderItem={({ item, index, section: { data: { length } = [] } = {} }) =>
            renderItem(item, index, length, withIcon)
          }
          renderSectionHeader={({ section: { sectionTitle } }) =>
            renderSectionHeader(sectionTitle)
          }
          onEndReached={() => onEndReached()}
          refreshControl={
            <RefreshControl
              refreshing={refreshing}
              onRefresh={onRefresh}
              // title="Pull to refresh"
              colors={[colors.secondaryAccent, colors.accent]}
              progressBackgroundColor={colors.background}
              tintColor={colors.secondaryAccent}
              titleColor={colors.primaryText}

            />
          }
        />
        <AnimatedTouchable
          onPress={scrollToTop}
          activeOpacity={0.5}
          style={[
            styles.fab,
            {
              opacity: fabSize,
              transform: [{ scale: fabSize }],
            },
          ]}>
          <Icon
            source={images.arrow_right}
            size={20}
            color={colors.tertiaryIcon}
            style={styles.fabIcon}
          />
        </AnimatedTouchable>
      </View>
    )
  }

  return (
    <View style={styles.listContainer}>
      <Animated.FlatList
        ref={listRef}
        onLayout={event => measureSize(event)}
        onMomentumScrollEnd={(event) => {
          if (event.nativeEvent.contentOffset.y > 800) {
            animate(1)
          } else {
            animate(0)
          }
        }}
        showsVerticalScrollIndicator={false}
        contentContainerStyle={[styles.contentContainer, style, scrollToTopSpacing]}
        ItemSeparatorComponent={() => renderSeparator(separatorStyle)}
        keyExtractor={(item, index) => `${item.id}-${index}`}
        data={DATA}
        numColumns={numColumns}
        style={containerStyle}
        ListHeaderComponent={listHeaderComponent}
        ListFooterComponent={listFooterComponent}
        ListEmptyComponent={listEmptyComponent}
        renderItem={({ item, index }) => renderItem(item, index, DATA.length, withIcon)}
        onEndReached={onEndReached}
        refreshControl={
          <RefreshControl
            refreshing={false}
            onRefresh={onRefresh}
            // title="Pull to refresh"
            colors={[colors.secondaryAccent, colors.accent]}
            progressBackgroundColor={colors.background}
            tintColor={colors.secondaryAccent}
            titleColor={colors.primaryText}

          />
        }
      />
      <AnimatedTouchable
        onPress={scrollToTop}
        activeOpacity={0.5}
        style={[
          styles.fab,
          {
            opacity: fabSize,
            transform: [{ scale: fabSize }],
          },
        ]}>
        <Icon
          source={images.arrow_right}
          size={20}
          color={colors.tertiaryIcon}
          style={styles.fabIcon}
        />
      </AnimatedTouchable>
    </View>
  )
}

SimpleList.defaultProps = {
  DATA: [],
  sectionList: false,
  touchableItems: false,
  onItemPress: () => { },
  onEndReached: () => { },
  numColumns: 1,
}

SimpleList.propTypes = {
  DATA: PropTypes.array,
  children: PropTypes.any,
  sectionList: PropTypes.bool,
  touchableItems: PropTypes.bool,
  onItemPress: PropTypes.func,
  withSectionHeader: PropTypes.bool,
  listHeaderComponent: PropTypes.node,
  listFooterComponent: PropTypes.node,
  listEmptyComponent: PropTypes.node,
  sectionSeparatorComponent: PropTypes.node,
  style: PropTypes.object,
  onEndReached: PropTypes.func,
  onRefresh: PropTypes.func,
  refreshing: PropTypes.bool,
  withIcon: PropTypes.bool,
  numColumns: PropTypes.number,
  containerStyle: PropTypes.object,
  separatorStyle: PropTypes.object,
  separatorContainerStyle: PropTypes.object,
  sectionHeaderStyle: PropTypes.object,
  isGrid: PropTypes.bool,
  renderCustomHeader: PropTypes.func,
}

export default SimpleList
