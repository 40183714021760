import { gql } from '@apollo/client'

export const LIST_GROUPS = gql`
  query listCustomerGroups($limit: Int, $nextToken: String, $filter: Filter, $type: CustomerType) {
    listCustomerGroups(listCustomerGroupsInput: {
      limit: $limit,
      nextToken: $nextToken,
      filter: $filter,
      type: $type
    }) {
        customerGroups {
        id
        name
      },
      nextToken
    },
  }
`

export const LIST_SUPPLIERS_GROUPS = gql`
  query listSupplierGroups($limit: Int, $nextToken: String, $filter: Filter, $type: SupplierType) {
    listSupplierGroups(listSupplierGroupsInput: {
      limit: $limit,
      nextToken: $nextToken,
      filter: $filter,
      type: $type
    }) {
        supplierGroups {
        id
        name
      },
      nextToken
    },
  }
`

export const LIST_CUSTOMERS = gql`
query listCustomers($limit: Int, $nextToken: String, $filter: Filter, $type: CustomerType!) {
  listCustomers(listCustomersInput: {
    limit: $limit,
    nextToken: $nextToken,
    filter: $filter,
    type: $type
  }) {
    customers {
      id
      issuerNUIS
      phoneNumber
      emailAddress
      businessName
      givenName
      familyName
      address {
        addressLine1
        country
        administrativeDistrictLevel1
        postalCode
      }
      type
      reference {
        name 
        value
      }
    },
    nextToken
  },
}
`

export const LIST_SUPPLIERS = gql`
query listSuppliers($limit: Int, $nextToken: String, $filter: Filter, $type: SupplierType!) {
  listSuppliers(listSuppliersInput: {
    limit: $limit,
    nextToken: $nextToken,
    filter: $filter,
    type: $type
  }) {
    suppliers {
      id
      issuerNUIS
      phoneNumber
      emailAddress
      businessName
      givenName
      familyName
      address {
        addressLine1
        country
        administrativeDistrictLevel1
        postalCode
      }
      type
      reference {
        name 
        value
      }
    },
    nextToken
  },
}
`

export const DELETE_CUSTOMER = gql`
  mutation deleteCustomer($id: String!) {
    deleteCustomer(id: $id){
      id
    }
  }
`

export const DELETE_SUPPLIER = gql`
  mutation deleteSupplier($id: String!) {
    deleteSupplier(id: $id){
      id
    }
  }
`
