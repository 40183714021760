/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useContext,
  useRef,
  useState,
  useEffect,
  useLayoutEffect,
} from 'react'
import {
  LayoutAnimation,
  View,
  Dimensions,
  ScrollView,
  Text as RNText,
} from 'react-native'
import { useTheme } from 'react-native-themed-styles'
import PropTypes from 'prop-types'
import ContentLoader, { Rect } from 'react-content-loader/native'
import { useMutation, useQuery } from '@apollo/client'
import { useDispatch, useSelector } from 'react-redux'

import { supportInvoiceUpdateStep, supportInvoiceUpdateStepKey, updatePaymentAttribute } from '../../Payment.Actions'
import { GET_BUSINESS_DETAILS, SAVE_SUPPORTING_INVOICE } from '../../Payment.Schema'
import {
  getSupportIssuer,
  getSupportCarrier,
  getSupportInvoice,
} from '../../Payment.Selectors'
import Step from '../Step'

import {
  Text,
  Button,
  Icon,
  InputWithTags,
  Section,
  BottomSheetSelector,
  TouchableInput,
  Type,
} from '../../../../Components'
import {
  useConfig,
  useLocationDetails,
} from '../../../../Utils'
import Theme, { opacityConfig } from '../../../../Themes/Theme'
import ContentManager from '../../../../Utils/ContentManager'
import images from '../../../../Themes/Images'

import { getWTNSaveInput } from '../../Helpers'

import { City } from '../Components'
import { CITIES, ID_TYPES } from '../WTNData'

import themedStyles from './ThirdStep.Styles'

const { width } = Dimensions.get('screen')
const { translate } = ContentManager

function SingleLoaderContent({ isFirst, isLast, style }) {
  const [styles] = useTheme(themedStyles)
  const loaderStyle = {}
  if (isFirst) {
    loaderStyle.borderTopLeftRadius = 7
    loaderStyle.borderTopRightRadius = 7
  }
  if (isLast) {
    loaderStyle.borderBottomLeftRadius = 7
    loaderStyle.borderBottomRightRadius = 7
  }
  return (
    <ContentLoader
      speed={0.7}
      height={70}
      style={Object.assign({}, styles.singleLoader, loaderStyle, style)}
      backgroundColor="#e2e2e2"
      foregroundColor="#ecebeb">
      <Rect x="20" y="20" rx="3" ry="3" width="90" height="20" />
    </ContentLoader>
  )
}
SingleLoaderContent.propTypes = {
  isFirst: PropTypes.bool,
  isLast: PropTypes.bool,
  style: PropTypes.object,
}

function LoaderContent({ style, isLast }) {
  const [styles] = useTheme(themedStyles)
  return (
    <ContentLoader
      speed={0.7}
      height={70}
      style={Object.assign({}, styles.loaderContainer(isLast), style)}
      backgroundColor="#e2e2e2"
      foregroundColor="#ecebeb">
      <Rect x="20" y="20" rx="3" ry="3" width="90" height="20" />
      <Rect x={`${width - 150}`} y="18" rx="3" ry="3" width="100" height="24" />
    </ContentLoader>
  )
}
LoaderContent.propTypes = {
  isFirst: PropTypes.bool,
  isLast: PropTypes.bool,
  style: PropTypes.object,
}

function ItemLoader() {
  const [styles] = useTheme(themedStyles)

  return (
    <View style={styles.loaderWrapper}>
      <SingleLoaderContent />
      <LoaderContent />
      <LoaderContent />
      <LoaderContent />
      <LoaderContent isLast />
    </View>
  )
}

function ThirdStep({
  goBack,
  stepperLength,
  index,
  addListener,
  dispatch,
}) {

  const { switchLocation: { deviceId, locationId } } = useConfig()

  const [styles] = useTheme(themedStyles)
  const { colors } = useContext(Theme)
  const { NIPT } = useLocationDetails()
  const dispatchAction = useDispatch()
  const {
    idNum,
    idType: carrierIdType,
    name: carrierName,
    address: carrierAddress,
    town: carrierTown,
  } = useSelector(getSupportCarrier)
  const {
    nuis,
    name: IssuerName,
    address: IssuerAddress,
    town: IssuerTown,
  } = useSelector(getSupportIssuer)

  const selected = useRef({})

  const { idType = {} } = selected.current

  const [items, setItems] = useState([])

  const updateCarrierData = (key, value) => {
    dispatchAction(supportInvoiceUpdateStepKey('carrier', key, value))
  }

  const setPaymentStatus = (value) => dispatchAction(updatePaymentAttribute('paymentStatus', value))

  const [disabled, setDisabled] = useState(true)
  const [searchValue, setSearchValue] = useState('')
  const [saveSupportInv, { loading }] = useMutation(SAVE_SUPPORTING_INVOICE)

  const [modal, setModal] = useState({
    isVisible: false,
    data: [],
    title: '',
    placeholder: '',
    selected: undefined,
    select: () => { },
    Item: Type,
  })

  const closeModal = () => {
    setModal(prev => ({ ...prev, isVisible: false }))
    setSearchValue('')
  }

  const selectStartCity = item => {
    updateCarrierData('town', item)
  }
  const openStartCitySelector = () => {
    setModal({
      isVisible: true,
      data: CITIES,
      title: 'einvoice_details_city_label',
      placeholder: 'supporting_invoice_city_search',
      selected: carrierTown,
      select: selectStartCity,
      searchKey: 'self',
      Item: City,
    })
  }

  const selectIdType = item => {
    selected.current.idType = item
    updateCarrierData('idType', item.code)
  }
  const openIdTypeSelector = () => {
    setModal({
      isVisible: true,
      data: ID_TYPES,
      title: 'supporting_invoice_issuerCarrier_id_type_seach',
      placeholder: 'supporting_invoice_issuerCarrier_id_type_seach_label',
      selected: idType,
      select: selectIdType,
      searchKey: 'code',
      Item: Type,
    })
  }

  const {
    loading: sellerLoading,
    error: govBusinessError,
    refetch,
  } = useQuery(GET_BUSINESS_DETAILS, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    variables: {
      name: 'businessTin',
      value: NIPT,
    },
    skip: Boolean(nuis),
    onCompleted: (res) => {
      const govBusiness = res?.getBusinessGov?.customers
      if (Array.isArray(govBusiness)) {
        govBusiness?.filter(item => item?.issuerNUIS === NIPT)
        mapSeller(govBusiness?.[0] || {})
      }
    },
  })

  const mapSeller = ({
    issuerNUIS,
    businessName,
    address,
  }) => {
    dispatchAction(supportInvoiceUpdateStep('issuer', {
      nuis: issuerNUIS,
      name: businessName,
      address: address?.addressLine1,
      town: address?.administrativeDistrictLevel1,
    }))
  }
  const mapSellerItems = () => ([
    {
      title: 'certificate_issuer_label',
    },
    {
      label: 'einvoice_details_nipt_label',
      value: nuis,
    },
    {
      label: 'einvoice_details_name_label',
      value: IssuerName,
    },
    {
      label: 'einvoice_details_address_label',
      value: IssuerAddress,
    },
    {
      label: 'einvoice_delivery_city',
      value: IssuerTown,
    },
  ])

  useLayoutEffect(() => {
    if (nuis) {
      LayoutAnimation.configureNext(opacityConfig)
      setItems(prev => prev.concat(mapSellerItems()))
    }
  }, [nuis])

  useEffect(() => {
    setDisabled(
      !idNum ||
      !carrierIdType ||
      !carrierName ||
      !carrierAddress ||
      !carrierTown ||
      items.length < 1
    )
  }, [
    idNum,
    carrierIdType,
    carrierName,
    carrierAddress,
    carrierTown,
    items,
  ])

  const allInvoice = useSelector(getSupportInvoice)

  const onPrevPress = (step) => {
    LayoutAnimation.configureNext(opacityConfig)
    setPaymentStatus(step)
  }

  const onNextPress = () => {
    LayoutAnimation.configureNext(opacityConfig)
    saveSupportInv({
      variables: {
        locId: locationId,
        cashRegisterId: deviceId,
        warehouseNoteInput: getWTNSaveInput(allInvoice),
      },
    })
      .then(() => {
        setPaymentStatus('preview')
      })
      .catch(e => {
      })
  }

  return (
    <>
      <Step
        title="header_issuerCarrier_supportive_invoice"
        goBack={goBack}
        stepperLength={stepperLength}
        index={index}
        addListener={addListener}
        dispatch={dispatch}
      >
        <ScrollView
          contentContainerStyle={styles.fillRemaining}
          showsVerticalScrollIndicator={false}
        >
          {govBusinessError ? sellerLoading ? <ItemLoader /> : <View style={styles.errorContainer}>
            <Icon style={styles.icon} size={35} source={images.attention} />
            <Text align={'center'} i18nKey={'supporting_invoice_issuer_error_message'} />
            <Text align={'center'} i18nKey={'orders_try_again'} weight={'bold'} style={styles.errorRetry} onPress={() => refetch()} />
          </View> : null}
          {sellerLoading ? <ItemLoader /> : <Section style={styles.issuerSection} data={items} />}
          <View style={styles.topContent}>
            <Text i18nKey="supporting_invoice_issuerCarrier_header" weight="bold" style={styles.sectionTitle} />
            <TouchableInput
              onPress={() => openIdTypeSelector()}
              label="supporting_invoice_issuerCarrier_id_type"
              value={translate(idType.description || '')}
              icon={images.filledDownArrow}
              iconSize={16}
              autoCorrect={false}
              autoCapitalize="none"
              editable={false}
            />
            <InputWithTags
              label={idType.code === 'NUIS' ? 'einvoice_details_nipt_label' : 'supporting_invoice_issuerCarrier_id_personal'}
              value={idNum}
              onChangeText={text => updateCarrierData('idNum', text)}
              autoCompleteType="off"
              disabled={!idType.description}
              autoCapitalize="characters"
            />
            <RNText
              style={styles.personalIdHelper}
              allowFontScaling={false}
            >
              <RNText>{translate(idType.code === 'NUIS' ? 'supporting_invoice_nipt_id_helper_1' : 'supporting_invoice_personal_id_helper_1')}</RNText>
              <RNText style={styles.personalIdHelperItalic} >{translate(idType.code === 'NUIS' ? 'supporting_invoice_nipt_id_helper_2' : 'supporting_invoice_personal_id_helper_2')}</RNText>
            </RNText>
            <InputWithTags
              label="einvoice_details_name_label"
              value={carrierName}
              onChangeText={text => updateCarrierData('name', text)}
              autoCompleteType="off"
              inputWrapperStyle={styles.currency}
            />
            <InputWithTags
              label="einvoice_details_address_label"
              value={carrierAddress}
              onChangeText={text => updateCarrierData('address', text)}
              autoCompleteType="off"
              inputWrapperStyle={styles.currency}
            />
            <TouchableInput
              onPress={() => openStartCitySelector()}
              label="einvoice_delivery_city"
              value={carrierTown}
              icon={images.filledDownArrow}
              iconSize={16}
              autoCorrect={false}
              autoCapitalize="none"
              editable={false}
            />
          </View>
        </ScrollView>
        <BottomSheetSelector
          {...modal}
          closeModal={closeModal}
          searchValue={searchValue}
          setSearchValue={setSearchValue}
        />
        <View style={styles.buttons}>
          <Button
            title="supporting_invoice_transport_button"
            variant={loading ? 'disabled' : 'preActive'}
            titleStyle={styles.buttonTitle}
            titleProps={{ numberOfLines: 1 }}
            icon={images.arrowLeft}
            iconColor={loading ? colors.white : colors.accent}
            style={styles.rightButton}
            onPress={() => onPrevPress('transportInfo')}

          />
          <Button
            title="einvoice_delivery_preview_button"
            variant={(disabled || loading) ? 'disabled' : 'active'}
            rightComponent={!loading && <Icon
              size={20}
              source={images.arrowRight}
              color={colors.white}
              style={styles.rightButtonIcon}
            />}
            loader={loading}
            onPress={() => onNextPress()}
            style={styles.leftButton}
          />
        </View>
      </Step>
    </>
  )
}

ThirdStep.propTypes = {
  setPaymentStatus: PropTypes.func,
  goBack: PropTypes.func,
  stepperLength: PropTypes.number,
  index: PropTypes.number,
  navigate: PropTypes.func,
  addListener: PropTypes.func,
  dispatch: PropTypes.func,
  clearOrder: PropTypes.func,
}

export default ThirdStep

