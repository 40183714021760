import React, { createContext, useState, useContext } from 'react'
import PropTypes from 'prop-types'

const Permissions = createContext()

const DEFAULT_PERMISSIONS = {
  menu: {
    Checkout: ['Admin', 'Seller', 'App', 'Web'],
    Items: ['Admin', 'App', 'Web'],
    Customers: ['Admin', 'Seller', 'App', 'Web'],
    Invoices: ['Admin', 'Seller', 'App', 'Web'],
    GOVEvents: [],
    CashDrawer: ['Admin', 'Seller', 'App', 'Web'],
    Users: ['Admin', 'App', 'Web'],
    Analytics: [],
    Settings: ['Admin', 'Seller', 'App', 'Web'],
    Reports: ['Admin', 'Seller', 'App', 'Web'],
    ProductCatalog: ['Admin', 'App', 'Web'],
    OnlineOrders: ['Admin', 'Seller', 'App', 'Web'],
  },
  settings: {
    taxes: ['Admin', 'App', 'Web'],
    printers: ['Admin', 'Seller', 'App'],
    languages: ['Admin', 'Seller', 'App', 'Web'],
    'sound&vibration': ['Admin', 'Seller', 'App'],
    password: ['Admin', 'Seller', 'App', 'Web'],
    locations: ['Admin', 'App', 'Web'],
    onlineStore: ['Admin', 'App', 'Web'],
    cashRegisters: ['Admin', 'App', 'Web'],
    'terms&conditions': ['Admin', 'Seller', 'App', 'Web'],
    certificate: ['Admin', 'App', 'Web'],
    bankAccounts: ['Admin', 'Seller', 'App', 'Web'],
    uploadItems: [],
    privacyPolicy: ['Admin', 'Seller', 'App', 'Web'],
    deleteAccount: ['Admin', 'Seller', 'App', 'Web'],
    businCode: ['Admin', 'App', 'Web'],
  },
  users: {
    editable: ['Admin', 'App', 'Web'],
  },
  checkout: {
    toogleItemDiscounts: ['Admin', 'Seller', 'App', 'Web'],
    toogleCartDiscounts: ['Admin', 'Seller', 'App', 'Web'],
    createNewDiscount: ['Admin'],
  },
  cashDrawer: {
    closeDrawerView: ['Admin', 'App', 'Web'],
    closeDrawerEdit: ['Admin', 'App', 'Web'],
  },

}

function PermissionsProvider({ children }) {

  const [permissions] = useState(DEFAULT_PERMISSIONS)

  return (
    <Permissions.Provider value={permissions}>
      {children}
    </Permissions.Provider>
  )
}
PermissionsProvider.propTypes = {
  children: PropTypes.any,
}

function usePermissions() {
  const context = useContext(Permissions)
  if (context === undefined) {
    throw new Error('usePermissions must be used within a PermissionsProvider')
  }
  return context
}

export { usePermissions }
export default PermissionsProvider
