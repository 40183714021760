import Colors, { styleSheetFactory } from '../../../../Themes/Colors'

const styles = styleSheetFactory(colors => ({
    container: {
        flex: 1,
        backgroundColor: colors.background,
    },
    addNote: {
        marginTop: 12,
        marginBottom: 15,
    },
    fillRemaining: {
        flexGrow: 1,
        paddingHorizontal: 15,
        paddingBottom: 20,
    },
    buttons: {
        flexDirection: 'row',
        marginBottom: 15,
        marginTop: 10,
        marginHorizontal: 15,
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    leftButton: {
        flex: 1,
        height: 50,
    },
    rightButton: {
        flex: 1,
        height: 50,
        marginRight: 15,
    },
    input: {
        marginBottom: 16,
    },
    sectionText: {
        marginBottom: 5,
    },
    leftSpacing: { marginLeft: 10 },
    noteTitle: {
        fontWeight: 'normal',
        color: Colors.secondaryText,
    },
    noteNumberContainer: {
        marginTop: 14,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',

    },
    noteContainer: {
        marginBottom: 0,
    },
    noteInput: {
        padding: 0,
        color: 'black',
    },
    singleNoteContainer: {
        marginVertical: 5,
    },
}))

const inputStyles = styleSheetFactory(colors => ({
    touchableInput: {
        marginBottom: 16,
    },
}))

export { inputStyles }

export default styles
