import { styleSheetFactory } from '../../Themes/Colors'

const styles = styleSheetFactory((colors) => ({
  container: {
    flexGrow: 1,
    flex: 1,
    backgroundColor: colors.background,
  },
  contentContainer: {
    padding: 15,
    paddingBottom: 64,
  },
  touchableItem: {
    backgroundColor: colors.tertiaryBackground,
    overflow: 'hidden',
    borderRadius: 7,
    marginTop: 10,
    marginBottom: 15,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    minHeight: 60,
    paddingHorizontal: 10,
  },
  inputContainer: {
    minHeight: 60,
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  itemStyle: {
    height: 70,
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 15,
  },
  listDescContainer: {
    flexGrow: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  noteView: {
    backgroundColor: colors.white,
    marginVertical: 15,
    padding: 15,
    borderRadius: 7,
  },
  noteHeader: {
    flexDirection: 'row',
  },
  noteTitle: {
    marginLeft: 5,
  },
  separator: {
    height: 1,
    backgroundColor: colors.separator,
  },
  aboutItem: {
    height: 34,
  },
  itemSpacing: { marginTop: 10, marginBottom: 15 },
  employeesSection: {
    marginTop: 10,
    marginHorizontal: 15,
  },
  firstAccessItem: {
    height: 60,
    backgroundColor: colors.tertiaryBackground,
    justifyContent: 'center',
    overflow: 'hidden',
    marginVertical: 1,
    borderTopLeftRadius: 7,
    borderTopRightRadius: 7,
  },
  secondAccessItem: {
    height: 60,
    backgroundColor: colors.tertiaryBackground,
    justifyContent: 'center',
    overflow: 'hidden',
    marginVertical: 1,
    borderBottomLeftRadius: 7,
    borderBottomRightRadius: 7,
  },
  touchable: {
    flexGrow: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  name: {
    marginLeft: 15,
  },
  radioInput: {
    flexShrink: 0,
    marginRight: 16,
  },
  textContainer: {
    marginRight: 15,
    flexGrow: 1,
    flexShrink: 1,
    flexDirection: 'row',
  },
  accessWrapper: {
    marginBottom: 15,
  },
  accessLabel: {
    marginBottom: 10,
  },
  rightSpacing: {
    marginRight: 10,
  },
  itemText: { flexShrink: 1 },
  topSpacing: { marginTop: 10 },
  bottomSpacing: { marginBottom:24},
  bottomSelfSpacing: { paddingBottom: 30 },
  textsWrapper: {
    marginTop: 5,
    marginBottom: 10,
    textAlign: 'left',
    fontFamily: 'Vodafone Rg',
    fontSize: 14,
    color: colors.primaryText,
  },
  italicText: { fontStyle: 'italic' },
  verticalSpacing: { marginVertical: 10 },
  spacing: {
    margin: 10,
  },
}))

const photoItemStyles = styleSheetFactory((colors) => ({
  avatarContainer: { position: 'relative' },
  status: {
    width: 15,
    height: 15,
    position: 'absolute',
    borderRadius: 7.5,
    bottom: 8,
    right: 5,
    borderWidth: 1,
    borderColor: colors.white,
  },
  colorContainer: {
    height: 70,
    width: 70,
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
  },
}))

const itemStyles = styleSheetFactory((colors) => ({
  default: {
    flex: 1,
    backgroundColor: colors.tertiaryBackground,
    overflow: 'hidden',
  },
  list: {
    flexDirection: 'row',
    alignItems: 'center',
    flexGrow: 1,
    height: 70,
  },
  listFirst: {
    borderTopLeftRadius: 7,
    borderTopRightRadius: 7,
  },
  listLast: {
    borderBottomLeftRadius: 7,
    borderBottomRightRadius: 7,
  },
  subContainer: { flexDirection: 'row', alignItems: 'center' },
  textContainer: {
    flexGrow: 1,
    flexShrink: 1,
    marginLeft: 15,
  },
  firstRow: { flexDirection: 'row' },
}))

const hiddenStyles = styleSheetFactory((colors) => ({
  // default: {
  //   flex: 1,
  //   backgroundColor: colors.tertiaryBackground,
  //   overflow: 'hidden',
  // },
  list: {
    // flexDirection: 'row',
    // alignItems: 'center',
    flexGrow: 1,
    height: 70,
  },
  hiddenContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'flex-end',
    overflow: 'hidden',
    backgroundColor: colors.accent,
  },
  listFirst: {
    borderTopLeftRadius: 7,
    borderTopRightRadius: 7,
  },
  listLast: {
    borderBottomLeftRadius: 7,
    borderBottomRightRadius: 7,
  },
  hiddenItem: {
    width: 100,
    height: 70,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: colors.accent,
  },
  confirmContainer: {
    flexDirection: 'row',
    backgroundColor: colors.transparent,
  },
  confirmStyles: {
    flexGrow: 1,
    height: 70,
    flexDirection: 'row',
    paddingLeft: 15,
    alignItems: 'center',
  },
  cancelContainer: {
    width: 40,
    height: 40,
    flexShrink: 0,
    marginVertical: 15,
    marginHorizontal: 15,
    borderRadius: 20,
    backgroundColor: colors.whiteRGBA(0.55),
    justifyContent: 'center',
    alignItems: 'center',
  },
  leftContainer: { flexDirection: 'row' },
  confirmText: { marginLeft: 15 },
  loader: {
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
}))

export { photoItemStyles, itemStyles, hiddenStyles }

export default styles
