import { styleSheetFactory } from '../../Themes/Colors'

const styles = styleSheetFactory(colors => ({
  container: {
    flex: 1,
    position: 'relative',
    backgroundColor: colors.background,
  },
  searchContainer: {
    flexDirection: 'row',
    position: 'absolute',
    top: 45,
    left: 0,
    right: 0,
    margin: 15,
    marginBottom: 0,
  },
}))


const itemStyles = styleSheetFactory(colors => ({
  container: {
    flex: 1,
    height: 70,
    backgroundColor: colors.tertiaryBackground,
    // borderRadius: 7,
    // marginVertical: 3,
  },
  isFirst_true: {
    borderTopLeftRadius: 7,
    borderTopRightRadius: 7,
  },
  isLast_true: {
    borderBottomLeftRadius: 7,
    borderBottomRightRadius: 7,
  },
  touchable: {
    flex: 1,
    paddingHorizontal: 15,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    minHeight: 70,
    // flexShrink:0,
    // backgroundColor:'red',
  },
  leftData: {
    flexGrow: 1,
    flexShrink: 1,
    justifyContent: 'center',
    marginRight: 15,
  },
  icon: {
    tintColor: colors.primaryIcon,
  },

}))

const itemSeparatorStyles = styleSheetFactory(colors => ({
  container: {
    // flex: 1,
    height: 1,
    backgroundColor: colors.separator,
  },
}))

const listEmptyStyles = styleSheetFactory(colors => ({
  container: {
    flexGrow: 1,
    alignItems: 'center',
    padding: 15,
    paddingTop: 20,
  },
}))

const listStyles = styleSheetFactory(colors => ({
  listContainer: {
    position: 'relative',
    flexGrow: 1,
    flex: 1,
  },
  contentContainer: {
    borderRadius: 7,
    overflow: 'hidden',
    paddingBottom: 80,
    marginBottom: 15,
    paddingHorizontal: 12,
  },
  sectionHeader: {
    paddingTop: 20,
    paddingBottom: 10,
  },
  fab: {
    position: 'absolute',
    bottom: 30,
    right: 15,
    height: 50,
    width: 50,
    borderRadius: 25,
    backgroundColor: colors.background,
    shadowColor: colors.black,
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.22,
    shadowRadius: 2.22,
    elevation: 3,
  },
  fabTouchable: {
    height: 50,
    width: 50,
    borderRadius: 25,
    backgroundColor: colors.tertiaryAccent,
    justifyContent: 'center',
    alignItems: 'center',
  },
  fabIcon: {
    tintColor: colors.white,
  },
  footer: {
    height: 48,
    justifyContent: 'center',
    alignItems: 'center',
  },
}))


export { itemStyles, itemSeparatorStyles, listEmptyStyles, listStyles }
export default styles
