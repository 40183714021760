import React from 'react'
import { Animated, View } from 'react-native'
import PropTypes from 'prop-types'

import { useTheme } from 'react-native-themed-styles'

import Icon from '../Icon'

import themedStyles from './IconWithStatus.Styles'

function IconWithStatus(props) {
    const [styles] = useTheme(themedStyles)

    const {
        style,
        iconStyle,
        statusIconStyle,
        accessible,
        accessibilityLabel,
        blurRadius,
        capInsets,
        defaultSource,
        fadeDuration,
        loadingIndicatorSource,
        onError,
        onLayout,
        onLoad,
        onLoadEnd,
        onLoadStart,
        onPartialLoad,
        onProgress,
        progressiveRenderingEnabled,
        resizeMethod,
        resizeMode,
        iconSource,
        statusIconSource,
        testID,
        size,
        iconSize,
        statusSize,
        color,
        backgroundColor,
        animated,
        positionStatus,
    } = props

    const propStyleStatus = {
        width: size,
        height: size,
        tintColor: color ? color : undefined,
        backgroundColor: backgroundColor,
        overlayColor: backgroundColor,
        ...style,
    }

    const propStyleIcon = {
        width: size,
        height: size,
        tintColor: color ? color : undefined,
        backgroundColor: backgroundColor,
        overlayColor: backgroundColor,
        ...style,
    }

    const statusProps = {
        source: statusIconSource,
        accessible: accessible,
        accessibilityLabel: accessibilityLabel,
        blurRadius: blurRadius,
        capInsets: capInsets,
        defaultSource: defaultSource,
        fadeDuration: fadeDuration,
        loadingIndicatorSource: loadingIndicatorSource,
        onError: onError,
        onLayout: onLayout,
        onLoad: onLoad,
        onLoadEnd: onLoadEnd,
        onLoadStart: onLoadStart,
        onPartialLoad: onPartialLoad,
        onProgress: onProgress,
        progressiveRenderingEnabled: progressiveRenderingEnabled,
        resizeMode: resizeMode,
        resizeMethod: resizeMethod,
        size: statusSize,
        style: propStyleStatus,
        testID: testID,
    }

    const iconProps = {
        source: iconSource,
        accessible: accessible,
        accessibilityLabel: accessibilityLabel,
        blurRadius: blurRadius,
        capInsets: capInsets,
        defaultSource: defaultSource,
        fadeDuration: fadeDuration,
        loadingIndicatorSource: loadingIndicatorSource,
        onError: onError,
        onLayout: onLayout,
        onLoad: onLoad,
        onLoadEnd: onLoadEnd,
        onLoadStart: onLoadStart,
        onPartialLoad: onPartialLoad,
        onProgress: onProgress,
        progressiveRenderingEnabled: progressiveRenderingEnabled,
        resizeMode: resizeMode,
        resizeMethod: resizeMethod,
        size: iconSize,
        style: propStyleIcon,
        testID: testID,
    }

    return animated ? (
        <Animated.View style={{ ...styles.container, ...style }}>
            <Icon {...iconProps} style={styles.mainIcon} />
            {statusIconSource && (
                <Icon
                    {...statusProps}
                    style={{ ...styles.statusIcon, ...statusIconStyle, ...styles[positionStatus] }} />
            )}
        </Animated.View>
    ) : (
            <View style={{ ...styles.container, ...style }}>
                <Icon {...iconProps} style={{ ...styles.mainIcon, ...iconStyle }} />
                {statusIconSource && (
                    <Icon
                        {...statusProps}
                        style={{ ...styles.statusIcon, ...statusIconStyle, ...styles[positionStatus] }} />
                )}
            </View>
        )
}

IconWithStatus.defaultProps = {
    fadeDuration: 200,
    resizeMode: 'contain',
    size: 30,
    animated: false,
    positionStatus: 'bottomRight',
}

IconWithStatus.propTypes = {
    positionStatus: PropTypes.oneOf(['topRight', 'topLeft', 'bottomLeft', 'bottomRight']),
    style: PropTypes.object,
    iconStyle: PropTypes.object,
    statusIconStyle: PropTypes.object,
    accessible: PropTypes.bool,
    accessibilityLabel: PropTypes.string,
    blurRadius: PropTypes.number,
    capInsets: PropTypes.array,
    defaultSource: PropTypes.string,
    fadeDuration: PropTypes.number,
    loadingIndicatorSource: PropTypes.string,
    onError: PropTypes.func,
    onLayout: PropTypes.func,
    onLoad: PropTypes.func,
    onLoadEnd: PropTypes.func,
    onLoadStart: PropTypes.func,
    onPartialLoad: PropTypes.func,
    onProgress: PropTypes.func,
    progressiveRenderingEnabled: PropTypes.bool,
    resizeMethod: PropTypes.string,
    resizeMode: PropTypes.string,
    iconSource: PropTypes.object,
    statusIconSource: PropTypes.object,
    testID: PropTypes.string,
    size: PropTypes.number,
    iconSize: PropTypes.number,
    statusSize: PropTypes.number,
    color: PropTypes.string,
    backgroundColor: PropTypes.string,
    animated: PropTypes.bool,
}



export default IconWithStatus
