/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { SafeAreaView, KeyboardAvoidingView, Platform } from 'react-native'
import PropTypes from 'prop-types'
import { useTheme } from 'react-native-themed-styles'
import { useSelector, useDispatch } from 'react-redux'

import { Button, Text, Icon, Modal, ModalContent } from '../../Components'

import { getCurrency, getTotal } from '../Checkout/Checkout.Selectors'

import { getPaymentError, getPaymentStatus } from './Payment.Selectors'
import { dismissPaymentError, generateNewKey, resetState } from './Payment.Actions'

import { SecondStep, ThirdStep, FourthStep, FifthStep, SixthStep, NoteStep } from './EInvoiceSteps'
import {
  SupportiveSecondStep,
  SupportiveThirdStep,
  SupportiveFourthStep,
  SupportiveFifthStep,
  SupportiveSixththStep,
} from './SupportiveInvoiceSteps'
import { Init, Pay, Print, Success, DotSteps } from './PaymentSteps'
import {
  INVOICING_METHODS,
  stepsIndex,
  stepsArrayIndex,
  PAYMENT_METHODS,
} from './Payment.Config'

import { images } from '../../Themes'
import themedStyles from './Payment.Styles'

const PaymentSteps = {
  init: Init,
  paying: Pay,
  printing: Print,
  success: Success,
}

const EinvoicePaymentSteps = {
  init: Init,
  process: SecondStep,
  buyerSeller: ThirdStep,
  references: FourthStep,
  delivery: FifthStep,
  notes: NoteStep,
  preview: SixthStep,
}

const SupportInvoiceSteps = {
  init: Init,
  transportInfo: SupportiveSecondStep,
  issuerCarrier: SupportiveThirdStep,
  preview: SupportiveFourthStep,
  print: SupportiveFifthStep,
  success: SupportiveSixththStep,
}

const FlowSteps = {
  'CASH': PaymentSteps,
  'NONCASH': PaymentSteps,
  'EXPORT_OF_GOODS': PaymentSteps,
  'SELF_INVOICE': PaymentSteps,
  'REVERSE_CHARGE': PaymentSteps,
  'EINVOICE': EinvoicePaymentSteps,
  'WAREHOUSE_NOTE': SupportInvoiceSteps,
  'OTHER': PaymentSteps,
}

function Payment({
  navigation: { navigate, goBack, dispatch, addListener, removeListener },
}) {
  const [styles] = useTheme(themedStyles)
  const dispatchAction = useDispatch()

  const total = useSelector(getTotal)
  const currency = useSelector(getCurrency)
  const paymentStatus = useSelector(getPaymentStatus)
  const error = useSelector(getPaymentError)
  const dismissError = () => dispatchAction(dismissPaymentError())
  const clearPayment = () => dispatchAction(resetState())
  const newEmpotencyKey = () => dispatchAction(generateNewKey())

  const [invoicingMethods] = useState(() => {
    const amount = parseFloat(total)
    return INVOICING_METHODS.map(element => {
      if (amount < element.min || amount > element.max) {
        element.isDisabled = true
      } else {
        element.isDisabled = false
      }

      return element
    })
  })
  const [invoicingMethod, setInvoicingMethod] = useState(() => {
    const amount = parseFloat(total)
    return INVOICING_METHODS.find(element => element.min <= amount && element.max >= amount)?.method
  })
  const [paymentMethods, setPaymentMethods] = useState(PAYMENT_METHODS[invoicingMethod])

  const PaymentStep = FlowSteps[invoicingMethod][paymentStatus]
  const stepperLength = stepsArrayIndex(invoicingMethod).length

  const [otherCurrency, setOtherCurrency] = useState(currency)
  const step = stepsIndex[invoicingMethod][paymentStatus] || {}

  const goBackCustom = () => {
    goBack()
    clearPayment()
    newEmpotencyKey()
  }

  useEffect(() => {
    newEmpotencyKey()
  }, [])

  return (
    <>
      <KeyboardAvoidingView
        style={styles.container}
        behavior={Platform.select({
          ios: 'padding',
          android: undefined,
        })}>
        <SafeAreaView style={styles.container}>
          <PaymentStep
            invoicingMethods={invoicingMethods}
            stepperLength={stepperLength}
            index={step.index}
            invoicingMethod={invoicingMethod}
            setInvoicingMethod={setInvoicingMethod}
            otherCurrency={otherCurrency}
            setOtherCurrency={setOtherCurrency}
            paymentMethods={paymentMethods}
            setPaymentMethods={setPaymentMethods}
            goBack={goBack}
            goBackCustom={goBackCustom}
            navigate={navigate}
            dispatch={dispatch}
            addListener={addListener}
            removeListener={removeListener}
          />
          {step.showStepper && <DotSteps index={step.index} currentPaymentMethod={invoicingMethod} />}
        </SafeAreaView>
      </KeyboardAvoidingView>
      <Modal
        isVisible={error.isVisible}
        onBackButtonPress={
          error.isDismissable ? () => dismissError() : undefined
        }
        onBackdropPress={
          error.isDismissable ? () => dismissError() : undefined
        }
        animationIn="fadeIn"
        animationOut="fadeOut"
        useNativeDriver>
        <ModalContent
          onClose={error.isDismissable ? () => dismissError() : undefined}
          contentContainerStyle={styles.modalContent}>
          <Icon source={images.warningIcon} size={40} />
          <Text
            i18nKey={error.title}
            align="center"
            size="h4"
            weight="bold"
            style={styles.modalTitle}
          />
          <Text
            i18nKey={error.desc}
            align="center"
            style={styles.modalDesc}
          />
          <Button
            title={error.primaryText}
            variant="active"
            style={styles.modalPrimaryButton}
            onPress={() => error.primaryAction?.()}
          />
          {error.secondaryAction && (
            <Button
              title={error.secondaryText}
              variant="text"
              style={styles.modalSecondaryButton}
              onPress={() => error.secondaryAction?.()}
            />
          )}
        </ModalContent>
      </Modal>
    </>
  )
}

Payment.propTypes = {
  navigation: PropTypes.object,
  route: PropTypes.shape({
    params: PropTypes.object,
  }),
}

export default Payment
