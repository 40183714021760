import { Dimensions, Platform } from 'react-native'

import { styleSheetFactory } from '../../Themes/Colors'

const { width } = Dimensions.get('screen')
const styles = styleSheetFactory(colors => ({
    container: {
        flex: 1,
        backgroundColor: colors.background,
    },
    contentContainer: {
        flex: 1,
    },
    listRightComponent: {
        alignSelf: 'center',
    },
    itemRightLabel: {
    },
    inlineButton1: {
        flex: 1,
        minHeight: 50,
        flexDirection: 'row',
        marginBottom: Platform.OS === 'web' ? 0 : 30,
    },
    rightConfigInput: {
        marginBottom: 2,
        justifyContent: 'center',
    },
    credentialButton: {
        height: 50,
        marginTop: 10,
        marginHorizontal: 15,
        marginBottom: 20,
    },
    inlineButton2: {
        flex: 1,
        height: 50,
        // marginLeft: 7.5,
        flexDirection: 'row',
        width: '100%',
    },
    inlineButtons: {
        flexDirection: 'row',
        marginVertical: 10,
        alignItems: 'center',
        justifyContent: 'center',
        paddingHorizontal: 15,
    },
    fullButton: {
        height: 50,
        justifyContent: 'flex-start',
        paddingHorizontal: 0,
        marginBottom: 30,
    },
    fullIssueButton: {
        flex: 1,
        height: 60,
        marginRight: 7.5,
        flexDirection: 'column',
    },
    invValContainer: {
        marginVertical: 20,
        paddingVertical: 10,
        borderTopColor: colors.separator,
        borderTopWidth: 1,
        justifyContent: 'flex-start',
    },
    generalInfo: {
        marginTop: 20,
        borderRadius: 7,
        backgroundColor: colors.tertiaryBackground,
    },
    invoiceButton: {
        marginHorizontal: 15,
    },
    serviceItemsContainer: {
        marginTop: 20,
    },
    totalItems: {
        marginTop: 20,
    },
    marginBottom: {
        marginBottom: 10,
    },
    listTitles: {
        marginVertical: 10,
    },
    serviceItems: {
        borderRadius: 7,
        backgroundColor: colors.tertiaryBackground,
        padding: 5,
    },
    listItems: {
        backgroundColor: colors.tertiaryBackground,
        borderRadius: 7,
    },
    titleStyle: {
        color: colors.secondaryAccent,
        fontSize: 16,
    },
    rightLabelStyle: {
        alignSelf: 'flex-start',
        marginRight: 10,
        paddingVertical: 5,
    },
    listLeftComponent: {
        flex: 1,
        flexDirection: 'row',
    },
    listRefundRightComponent: {
        alignItems: 'center',
        marginRight: 10,
    },
    listRightComponentText: {
        alignItems: 'flex-end',
        marginRight: 10,
    },
    header: {
        height: 56,
        alignItems: 'center',
        flexDirection: 'row',
        backgroundColor: colors.background,
    },
    headerIcon: {
        marginHorizontal: 20,
    },

    modalButton: {
        height: 45,
        //width: 230,
        //alignSelf: 'center',
        marginTop: 15,
    },
    separatorStyle: {
        height: 1,
        flex: 1,
        backgroundColor: colors.lightGrey,
    },
    modalContent: {
        backgroundColor: colors.white,
        alignSelf: 'center',
        paddingBottom: 30,
        borderRadius: 6,
        minWidth: width - 80,
        maxWidth: 400,
    },
    closeContainer: {
        backgroundColor: colors.disabled,
        position: 'absolute',
        top: 0,
        right: 0,
        borderTopRightRadius: 7,
        borderBottomLeftRadius: 7,
        width: 75,
        justifyContent: 'center',
        alignItems: 'center',
        padding: 10,
    },
    itemStyle: {
        flexDirection: 'row',
        alignItems: 'center',
        height: 60,
    },
    listDescContainer: {
        flexGrow: 1,
        flexShrink: 1,
        marginLeft: 15,
        flexDirection: 'row',
    },
    retryButton: {
        margin: 15,
        marginBottom: 25,
    },
    itemWrapper: {
        flex: 1,
        flexGrow: 1,
        height: 50,
        backgroundColor: colors.white,
        paddingHorizontal: 15,
        alignItems: 'center',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    isFirst_true: {
        borderTopRightRadius: 7,
        borderTopLeftRadius: 7,
    },
    isLast_true: {
        borderBottomLeftRadius: 7,
        borderBottomRightRadius: 7,
    },
    tabView: {
        paddingTop: 0,
        flex: 1,
        flexGrow: 1,
    },
    lastUpdate: {
        margin: 15,
    },
    scene: {
        flex: 1,
    },
    horizontalPadding: {
        paddingHorizontal: 15,
    },
    statusWrapper: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        backgroundColor: colors.white,
        height: 50,
        alignItems: 'center',
        paddingHorizontal: 15,
        borderRadius: 7,
    },
    touchableStyles: {
        minHeight: 60,
        borderWidth: 0,
    },
    timelineIconContainer: {
        marginBottom: 4,
    },
    timelineItemTitle: {
        padding: 0,
        fontSize: 16,
    },
    timelineActiveItemTitle: {
        fontWeight: 'bold',
    },
    separator: {
        marginVertical: 1,
    },
    tabMarginTop: {
        flex: 1,
        marginTop: 20,
    },
    tabFlexEnd: {
        justifyContent: 'flex-end',
    },
    footerWrapper: {
        marginTop: 15,
    },
    qrWrapper: {
        alignItems: 'center',
    },
    qrCode: {
        justifyContent: 'center',
        flexDirection: 'row',
    },
    linkWrapper: {
        backgroundColor: colors.white,
        flexDirection: 'row',
        alignItems: 'center',
        minHeight: 45,
        borderRadius: 7,
        paddingHorizontal: 15,
        marginVertical: 15,
    },
    itemComponent: {
        backgroundColor: colors.white,
        flexDirection: 'row',
        alignItems: 'center',
        minHeight: 60,
        paddingHorizontal: 15,
        marginTop: 1,
    },
    modalContentSecond: {
        marginHorizontal: 30,
        marginBottom: 15,
    },
    loaderContainer: {
        marginBottom: 32,
    },
    modalTitle: {
        marginVertical: 10,
    },
    modalPrimaryButton: {
        width: '100%',
        marginTop: 30,
        marginVertical: 15,
    },
    modalSecondaryButton: {
        width: '100%',
        marginBottom: 15,
    },
    modalTertiaryButton: {
        width: '100%',
    },
    paddingHorziontal: {
        paddingHorizontal: 15,
    },
    buttonTitle: {
        fontSize: 18,
    },
    rowFlexDate: {
        flexDirection: 'row',
        alignItems: 'center',
        alignSelf: 'center',
        marginVertical: 15,
    },
    firstLoader: {
        marginBottom: 20,
    },
}))

export const routeStyles = styleSheetFactory(colors => ({
    itemContainer: {
        backgroundColor: colors.tertiaryBackground,
        borderRadius: 7,
        paddingVertical: 5,
    },
    itemSubcontainer: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    iconStyles: {
        marginLeft: 15,
    },
    statusIcon: {
        marginBottom: 3,
    },
    listDescContainer: {
        flexGrow: 1,
        flexShrink: 1,
        marginHorizontal: 15,
        flexWrap: 'wrap',
    },
    description: {
        fontSize: 12,
        lineHeight: 15,
    },
    subdescription: {
        fontSize: 12,
        lineHeight: 13,
    },
    amountContainer: {
        alignItems: 'center',
        justifyContent: 'center',
        padding: 15,
        flexDirection: 'row',
    },
    amount: {
        marginRight: 5,
    },
    listStyle: {
        marginTop: 15,
        flex: 1,
        paddingHorizontal: 15,
    },
    separator: {
        height: 1,
        backgroundColor: colors.separator,
    },
    listContainerStyle: {
        paddingBottom: 30,
    },
    fab: {
        position: 'absolute',
        bottom: 30,
        right: 15,
        height: 50,
        width: 50,
        borderRadius: 25,
        backgroundColor: colors.accent,
        justifyContent: 'center',
        alignItems: 'center',
        shadowColor: colors.black,
        shadowOffset: {
            width: 0,
            height: 1,
        },
        shadowOpacity: 0.22,
        shadowRadius: 2.22,
        elevation: 3,
    },
    fabIcon: {
        transform: [{ rotate: '-90deg' }],
    },
    transactionDetail: {
        flexDirection: 'row',
        alignItems: 'center',
    },
}))

export default styles
