import React from 'react'
import { AppState, Platform, View } from 'react-native'
import { useTheme } from 'react-native-themed-styles'
import PropTypes from 'prop-types'

import themedStyles from './SecurityScreen.Styles'

const POINTER_EVENTS = {
  true: 'box-only',
  false: 'none',
}

const IS_IOS = Platform.OS === 'ios'

function SecurityScreenComponent({ isVisible }) {
  const [styles] = useTheme(themedStyles)
  if (IS_IOS) {
    return <View style={[styles.container, styles['visible_' + isVisible]]} pointerEvents={POINTER_EVENTS[isVisible]} />
  }

  return null
}
SecurityScreenComponent.propTypes = {
  isVisible: PropTypes.bool,
}

const showSecurityScreenFromAppState = appState =>
  ['background', 'inactive'].includes(appState)

class SecurityScreen extends React.Component {
  state = {
    showSecurityScreen: showSecurityScreenFromAppState(AppState.currentState),
    listener: '',
  }

  componentDidMount() {
    const listener = AppState.addEventListener('change', this.onChangeAppState)
    this.setState(prev => ({
      ...prev,
      listener,
    }))
  }

  shouldComponentUpdate() {
    return IS_IOS
  }

  componentWillUnmount() {
    this.state.listener.remove()
  }

  onChangeAppState = nextAppState => {
    const showSecurityScreen = showSecurityScreenFromAppState(nextAppState)

    this.setState({ showSecurityScreen })
  }

  render() {
    return (
      <SecurityScreenComponent isVisible={this.state.showSecurityScreen} />
    )
  }
}

export default SecurityScreen
