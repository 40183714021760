import { styleSheetFactory } from '../../../../../Themes/Colors'

const styles = styleSheetFactory(colors => ({
  topContent: {
    padding: 15,
    paddingTop: 0,
  },
  sectionTitle: {
    marginBottom: 10,
  },
  infoWrapper: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  infoContainer: {
    height: 650,
    paddingHorizontal: 15,
    paddingVertical: 20,
  },
  InfoLabel: {
    color: colors.accent,
    paddingBottom: 15,
  },
  infoItem: {
    paddingTop: 5,
    paddingBottom: 15,
  },
  infoIntro: {
    paddingTop: 10,
    paddingBottom: 15,
  },
}))

const inputStyles = styleSheetFactory(colors => ({
  touchableInput: {
    marginBottom: 16,
  },
}))

const selectStyles = styleSheetFactory(colors => ({
  itemSelected_false: {
    flexDirection: 'row',
    marginHorizontal: 15,
    borderRadius: 10,
    paddingVertical: 10,
    paddingHorizontal: 15,
    backgroundColor: colors.white,
    alignItems: 'center',
    height: 60,
  },
  itemSelected_true: {
    flexDirection: 'row',
    marginHorizontal: 15,
    borderRadius: 10,
    paddingVertical: 10,
    paddingHorizontal: 15,
    backgroundColor: colors.white,
    alignItems: 'center',
    height: 60,
    borderWidth: 1,
    borderColor: colors.secondaryAccent,
  },
  itemTextSelected_false: {
    flexGrow: 1,
    flexShrink: 1,
  },
  itemTextSelected_true: {
    flexGrow: 1,
    flexShrink: 1,
  },
  separator: {
    width: 1,
    backgroundColor: colors.disabled,
    marginHorizontal: 15,
    height: 40,
  },
  isDisabled_true: {
    backgroundColor: colors.secondaryBackround,
  },
  itemCode: {
  },
  header: {
    margin: 15,
    marginTop: 30,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  searchContainer: {
    flexDirection: 'row',
    marginHorizontal: 15,
    marginBottom: 15,
    backgroundColor: colors.white,
    borderRadius: 10,
    justifyContent: 'space-between',
    alignItems: 'center',
    flexGrow: 1,
  },
  searchIcon: {
    marginHorizontal: 15,
    marginVertical: 10,
  },
  searchInput: {
    flexGrow: 1,
    borderRadius: 10,
    paddingVertical: 10,
    marginRight: 15,
  },
  clearSearchContainer: {
    borderRadius: 18,
    padding: 5,
    marginRight: 15,
    backgroundColor: colors.background,
  },
  listStyle: {
    height: 300,
  },
  listContainerStyle: {
    flexGrow: 1,
    paddingBottom: 20,
  },
}))

export { inputStyles, selectStyles }

export default styles
