import { styleSheetFactory } from '../../Themes/Colors'

const styles = styleSheetFactory(colors => ({
  container: {
    flex: 1,
    backgroundColor: colors.background,
  },
  sectionText: {
    marginBottom: 15,
  },
  inputRow: {
    marginBottom: 15,
  },
  description: {
    marginBottom: 15,
  },
  button: {
    marginTop: 15,
    marginBottom: 30,
    marginHorizontal: 0,
    height: 50,
  },
  buttonTitle: {
    fontSize: 18,
  },
  idStatus: {
    flex: 1,
    marginHorizontal: 15,
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  icon: {
    marginRight: 15,
  },
  listElementStar: {
    color: 'red',
    marginLeft: 5,
  },
  rightComponentStyle: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  itemRightLabel: {
    marginRight: 5,
  },
  leftComponentWrapper: {
    flexGrow: 1,
    flexShrink: 0,
    flexDirection: 'row',
  },
  list: { flex: 1 },
}))

export default styles
