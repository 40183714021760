import { gql } from '@apollo/client'

export const LIST_ITEMS = gql`
  query ($filter: Filter, $limit: Int, $nextToken: String,$locationId:String,  $locId: String, $cashRegisterId: String){
    listItems (listItemsInput: {
      filter: $filter,
      limit: $limit,
      nextToken: $nextToken
    }
    locationId:$locationId
    requestedLocationAndCashRegister: {
      locationId: $locId,
      cashRegisterId: $cashRegisterId
    }
    ){
      items{
        id
        barcode
        name
        description
        labelColor
        imageUrl
        category{
          id
          name
          labelColor
        }
        tax{
          id
          name
          percentage
        }
        taxExemptionType
        taxInclusionType
        itemVariationsCount
        itemVariation{
          id
          name
          priceMoney{
            amount
            currency
          }
          prices{
            amount
            currency
          }
        }
        discountsCount
        discount{
          id
          name
          discountType
          percentage
          amountMoney{
            amount
            currency
          }
          prices {
            amount
            currency
          }
        }
      },
      nextToken
    }
  }
`

export const DELETE_ITEM = gql`
  mutation deleteItem ($id: String!,$locationId:String , $locId: String, $cashRegisterId: String ) {
    deleteItem(
      id: $id
      locationId:$locationId
      requestedLocationAndCashRegister: {
        locationId: $locId,
        cashRegisterId: $cashRegisterId
      }
      ){
      id
    }
  }
`

export const GET_ITEM = gql`
  query getItem($searchField: String!, $searchValue: String!, $nextVariations: String, $locationId:String, $locId: String, $cashRegisterId: String ) {
    getItem(getItemInput: {
      searchBy: {
        name: $searchField
        value: $searchValue
      }
      listItemVariationsInput: {
        nextToken: $nextVariations
      }
    }
    locationId:$locationId
    requestedLocationAndCashRegister: {
      locationId: $locId,
      cashRegisterId: $cashRegisterId
    }
    ){
      item{
        id
        barcode
        name
        description
        labelColor
        tax{
          id
          name
          percentage
        }
        taxInclusionType
        itemVariationsCount
        modifierListsCount
        imageUrl
      }
      listItemVariations{
        itemVariations{
          itemId
          id
          name
          priceMoney{
            amount
            currency
          }
          prices {
            amount
            currency
          }
        }
        nextToken
      }
    }
  }
`
