import React from 'react'
import PropTypes from 'prop-types'
import { Text as RNText, View, Platform } from 'react-native'
import { useTheme } from 'react-native-themed-styles'
import ContentLoader, { Rect } from 'react-content-loader/native'

import ContentManager from '../../Utils/ContentManager'

import themedStyles from './Text.Styles'

function Text(props) {
  const [styles] = useTheme(themedStyles)
  // font options are: regular, bold , extraBold , light
  const {
    size,
    color,
    weight,
    align,
    style,
    i18nKey,
    localize,
    placeHolders,
    translateOption,
    textAccessibilityLabel,
    isLoading,
    loadingBackgroundColor,
    loadingForegroundColor,
    ...rest
  } = props

  const propsStyle = {
    color: color || styles.default.color,
    textAlign: align,
  }

  const key = i18nKey ? i18nKey + '' : ''
  const platform = Platform.OS === 'web' ? 'desktop' : 'mobile'
  if (isLoading) {
    const loaderAlign = {
      left: 'flex-start',
      right: 'flex-end',
      center: 'center',
      justify: 'flex-start',
    }
    const width = '100%'
    const height = styles[size].mobile.lineHeight // + 0.7 IOS
    const contentHeight = styles[size].mobile.fontSize
    const y = (styles[size].mobile.lineHeight - styles[size].mobile.fontSize) / 2
    return (
      <View style={[
        {
          height: height,
          justifyContent: loaderAlign[align] || 'flex-start',
        },
        styles.loader,
        style,
      ]}>
        <ContentLoader
          speed={2}
          width={width}
          height={height}
          backgroundColor={loadingBackgroundColor}
          foregroundColor={loadingForegroundColor}
        >
          <Rect x="0" y={y} rx="6" ry="6" width={width} height={contentHeight} />
        </ContentLoader>
      </View>
    )
  }

  return (
    <RNText
      {...rest}
      style={[
        styles.default,
        styles[weight],
        styles[size][platform],
        propsStyle,
        style,
      ]}
      allowFontScaling={false}
      accessibilityLabel={textAccessibilityLabel}
    >
      {localize ? ContentManager.translate(key, placeHolders, translateOption) : i18nKey}
    </RNText>
  )
}


Text.defaultProps = {
  size: 'body',
  weight: 'regular',
  localize: true,
  isLoading: false,
  loadingBackgroundColor: '#e4e4e4',
  loadingForegroundColor: '#d4d4d4',
}

Text.propTypes = {
  i18nKey: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  placeHolders: PropTypes.array,
  translateOption: PropTypes.object,
  textAccessibilityLabel: PropTypes.string,
  localize: PropTypes.bool,
  size: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'body', 'footnote']),
  color: PropTypes.string,
  weight: PropTypes.oneOf(['light', 'regular', 'bold', 'extraBold']),
  align: PropTypes.oneOf(['left', 'right', 'center', 'justify']),
  style: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  isLoading: PropTypes.bool,
  loadingBackgroundColor: PropTypes.string,
  loadingForegroundColor: PropTypes.string,
}

export default Text
