import { styleSheetFactory } from '../../Themes/Colors'

const styles = styleSheetFactory((colors) => ({
  container: {
    flex: 1,
    position: 'relative',
    backgroundColor: colors.background,
  },
  header: {
    height: 56,
    alignItems: 'center',
    flexDirection: 'row',
    backgroundColor: colors.background,
  },
  headerIcon: {
    marginHorizontal: 20,
  },
  chargeButton: {
    height: 50,
    margin: 15,
  },
  listStyle: {
    marginHorizontal: 15,
    flexShrink: 1,
  },
  listContainerStyle: {
    borderRadius: 7,
  },
  separator: {
    height: 1,
    backgroundColor: colors.separator,
  },
  countWrapper: {
    alignItems: 'center',
    marginVertical: 15,
  },
  rightSpacing: { margin: 15 },
}))

const photoItemStyles = styleSheetFactory((colors) => ({
  avatarContainer: { position: 'relative' },
  status: {
    width: 15,
    height: 15,
    position: 'absolute',
    borderRadius: 7.5,
    bottom: 8,
    right: 5,
    borderWidth: 1,
    borderColor: colors.white,
  },
  colorContainer: {
    height: 70,
    width: 70,
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
  },
}))

const itemStyles = styleSheetFactory((colors) => ({
  itemContainer: {
    backgroundColor: colors.tertiaryBackground,
    flexDirection: 'row',
    alignItems: 'center',
    overflow: 'hidden',
    height: 70,
    flex: 1,
  },
  fistItem_true: {
    borderTopLeftRadius: 7,
    borderTopRightRadius: 7,
  },
  lastItem_true: {
    borderBottomLeftRadius: 7,
    borderBottomRightRadius: 7,
  },
  itemTitle: {
    flexGrow: 1,
    flexShrink: 1,
    margin: 15,
  },
  checkBoxTintColors: {
    true: colors.secondaryAccent,
    false: colors.placeholder,
  },
  checkBox: {
    flexShrink: 0,
    marginLeft: 10,
  },
  subContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    flexShrink: 1,
    flexGrow: 1,
  },
  textContainer: {
    flexGrow: 1,
    flexShrink: 1,
    marginLeft: 15,
  },
  firstRow: { flexDirection: 'row' },
  rightSpacing: { margin: 15 },
}))

export { itemStyles, photoItemStyles }
export default styles
