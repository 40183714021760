import { styleSheetFactory } from '../../../../Themes/Colors'

const styles = styleSheetFactory(colors => ({
  container: {
    flex: 1,
  },
  contentContainerStyle: {
    paddingHorizontal: 15,
    paddingBottom: 30,
  },
  separator: {
    height: 1,
    backgroundColor: colors.separator,
  },
  listStyle: {
    flexGrow: 1,
  },
  topSpacing: {
    marginTop: 15,
  },
  header: {
    marginBottom: 5,
    marginTop: 15,
  },
}))

const itemStyles = styleSheetFactory(colors => ({
  listItem: {
    backgroundColor: colors.white,
    paddingHorizontal: 15,
    paddingVertical: 5,
    justifyContent: 'center',
    height: 70,
  },
  smallText: {
    lineHeight: 16,
  },
  itemFirstRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  firstItem_true: {
    borderTopLeftRadius: 7,
    borderTopRightRadius: 7,
  },
  lastItem_true: {
    borderBottomLeftRadius: 7,
    borderBottomRightRadius: 7,
  },
  itemContainer: {
    backgroundColor: colors.tertiaryBackground,
    flexDirection: 'row',
    alignItems: 'center',
    overflow: 'hidden',
    height: 70,
    flex: 1,
  },
}))


export { itemStyles }

export default styles
