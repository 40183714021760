import { styleSheetFactory } from '../../../../Themes/Colors'

const styles = styleSheetFactory(colors => ({
  container: {
    flex: 1,
    backgroundColor: colors.background,
  },
  sectionText: {
    marginBottom: 15,
  },
  inputRow: {
    marginBottom: 15,
  },
  inlineButtons: {
    flexDirection: 'row',
  },
  inlineButton: {
    flex: 1,
    marginHorizontal: 0,
  },
  inlineButtonRight: {
    marginLeft: 15,
  },
  button: {
    margin: 15,
    marginBottom: 30,
    height: 50,
  },
  buttonTitle: {
    fontSize: 18,
  },
  tabView: {
    backgroundColor: colors.background,
  },
  scene: {
    flex: 1,
    backgroundColor: colors.background,
  },
  tabContainerStyle: {
    backgroundColor: colors.tertiaryBackground,
    elevation: 0,
    shadowRadius: 0,
    shadowOpacity: 0,
    marginBottom: 0,
  },
  indicatorStyle: {
    backgroundColor: colors.tabAccent,
  },
  label: {
    fontFamily: 'Vodafone Rg',
    fontSize: 14,
    textTransform: 'none',
  },
  labelStyle: {
    color: colors.inActiveText,
    fontSize: 14,
  },
  itemStyle: {
    backgroundColor: colors.tertiaryBackground,
    minHeight: 60,
    justifyContent: 'center',
    paddingVertical: 5,
    borderTopWidth: 1,
    borderTopColor: colors.separator,
  },
  listDescContainer: {
    flexGrow: 1,
    flexShrink: 1,
    marginLeft: 10,
    paddingRight: 15,
    justifyContent: 'center',
    marginRight: 15,
  },
  itemContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    minHeight: 60,
  },
  priceContainer: {
    marginRight: 15,
    alignItems: 'flex-end',
    flexShrink: 0,
    justifyContent: 'center',
  },
  listStyle: {
    flex: 1,
    paddingHorizontal: 15,
  },
  listContainerStyle: {
    paddingBottom: 30,
  },
  fab: {
    position: 'absolute',
    bottom: 30,
    right: 15,
    height: 50,
    width: 50,
    borderRadius: 25,
    backgroundColor: colors.accent,
    justifyContent: 'center',
    alignItems: 'center',
    shadowColor: colors.black,
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.22,
    shadowRadius: 2.22,
    elevation: 3,
  },
  fabIcon: {
    transform: [{ rotate: '-90deg' }],
  },
  firstItem_true: {
    borderTopLeftRadius: 7,
    borderTopRightRadius: 7,
    borderTopWidth: 0,
  },
  lastItem_true: {
    borderBottomLeftRadius: 7,
    borderBottomRightRadius: 7,
  },
  sectionTitle: {
    marginBottom: 10,
    marginTop: 15,
  },
  emptyComponent: {
    marginTop: 10,
  },

}))

export default styles
