/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import { View } from 'react-native'
import PropTypes from 'prop-types'
import { useTheme } from 'react-native-themed-styles'
import { useMutation } from '@apollo/client'
import { useDispatch, useSelector } from 'react-redux'

import { Loader } from '../../../../Components'
import { clearExtraDetails } from '../../../../Utils/Order'
import { getErrorMessage, useConfig } from '../../../../Utils'

import { CREATE_ORDER, PAY_ORDER } from '../../Payment.Schema'
import { PAYMENT_STEPS } from '../../Payment.Config'
import { filterOutCart } from '../../Helpers'
import { getOrder } from '../../../Checkout/Checkout.Selectors'
import { dismissPaymentError, displayPaymentError, generateNewKey, updatePaymentAttribute } from '../../Payment.Actions'

import {
  getIsReverseCharge,
  getPaymentBankAccount,
  getPaymentCashMoney,
  getPaymentCustomer,
  getPaymentDates,
  getPaymentDelivery,
  getPaymentEmpotencyKey,
  getPaymentMethod,
  getPaymentOrderSubtype,
  getPaymentOrderType,
  getShouldHaveBillingPeriod,
} from '../../Payment.Selectors'

import themedStyles from './Pay.Styles'

function Pay({
  goBackCustom,
  navigate,
  dispatch,
  invoicingMethod,
  otherCurrency,
}) {

  const { switchLocation: { deviceId, locationId } } = useConfig()

  const [styles] = useTheme(themedStyles)

  const { order } = useSelector(getOrder)
  const idempotencyKey = useSelector(getPaymentEmpotencyKey)
  const delivery = useSelector(getPaymentDelivery)
  const orderType = useSelector(getPaymentOrderType)
  const orderSubType = useSelector(getPaymentOrderSubtype)
  const bankAccount = useSelector(getPaymentBankAccount)
  const shouldHaveBillingPeriod = useSelector(getShouldHaveBillingPeriod)
  const dates = useSelector(getPaymentDates)
  const paymentMethod = useSelector(getPaymentMethod)
  const customer = useSelector(getPaymentCustomer)
  const cash = useSelector(getPaymentCashMoney)
  const isReverseCharge = useSelector(getIsReverseCharge)

  const dispatchAction = useDispatch()

  const dismissError = () => dispatchAction(dismissPaymentError())
  const displayError = (
    title = '',
    desc = '',
    primaryText = '',
    primaryAction = null,
    secondaryText = '',
    secondaryAction = null,
    isDismissable = true,
  ) => {
    dispatchAction(displayPaymentError({
      isVisible: true,
      title: title,
      desc: desc,
      primaryText: primaryText,
      primaryAction: primaryAction,
      secondaryText: secondaryText,
      secondaryAction: secondaryAction,
      isDismissable: isDismissable,
    }))
  }

  const setPaymentStatus = (value) => dispatchAction(updatePaymentAttribute('paymentStatus', value))
  const setPaymentId = (value) => dispatchAction(updatePaymentAttribute('paymentId', value))

  const newEmpotencyKey = () => dispatchAction(generateNewKey())

  const [createOrder, { }] = useMutation(CREATE_ORDER)
  const [payOrder, { }] = useMutation(PAY_ORDER)

  const deliveryHasData = Object.keys(delivery).length > 0

  useEffect(() => {
    const orderObj = { ...order }
    createOrder({
      variables: {
        locationId: locationId,
        cashRegisterId: deviceId,
        orderInput: {
          locationId: 'J11111111A',
          ...clearExtraDetails(orderObj),
          orderType: orderType.code,
          orderSubType: orderSubType?.type,
          bankAccount: bankAccount?.id ? { id: bankAccount?.id } : null,
          shippingAddress: deliveryHasData ? {
            externalId: delivery?.receiver,
            addressLine1: delivery?.street,
            locality: delivery?.city,
            country: delivery?.country?.code,
          } : null,
          supplyDateOrPeriod: (shouldHaveBillingPeriod && dates.startDate && dates.endDate) ? {
            startDate: dates.startDate,
            endDate: dates.endDate,
          } : null,
        },
      },
    })
      .then(({ data: orderRes }) => {
        const orderId = orderRes?.createOrder?.id
        if (orderId) {
          payOrder({
            variables: {
              locationId: locationId,
              cashRegisterId: deviceId,
              idempotencyKey,
              orderId: orderId,
              amountMoney: {
                amount: invoicingMethod === 'CASH' ? cash : null,
                currency: otherCurrency?.currencyCode,
              },
              type: paymentMethod?.type,
              customerId: customer.id ? customer.id : null,
              customerEmail: customer?.emailAddress ? customer.emailAddress : null,
              isReverseCharge: isReverseCharge?.type ? isReverseCharge?.type : false,
              currency: {
                exRate: otherCurrency?.rate,
                currencyCode: otherCurrency?.currencyCode,
                isBuyer: otherCurrency?.isBuyer,
              },
            },
          })
            .then(({ data }) => {
              const paymentId = data?.payOrder?.id
              if (paymentId) {
                setPaymentId(paymentId)
                setPaymentStatus(PAYMENT_STEPS.printing)
                dispatch(filterOutCart)
                newEmpotencyKey()
              }
            })
            .catch((error) => {
              if (error) {
                const errorMessage = getErrorMessage(error)
                if (errorMessage === 'OrderNoOpenDrawer') {
                  displayError(
                    'receipt_drawer_warning_title',
                    'checkout_drawer_error_message',
                    'receipt_button_open_drawer',
                    () => {
                      navigate('CurrentDrawer', { nextAction: 'drawer_continue_to_payment' })
                      dismissError()
                      setPaymentStatus(PAYMENT_STEPS.init)
                    },
                    'orders_button_go_back',
                    () => {
                      setPaymentStatus(PAYMENT_STEPS.init)
                      dismissError()
                      newEmpotencyKey()
                    },
                    false,
                  )
                } else {
                  displayError(
                    'checkount_error_message',
                    errorMessage,
                    'orders_button_go_back',
                    () => {
                      setPaymentStatus(PAYMENT_STEPS.init)
                      dismissError()
                      goBackCustom()
                    },
                    '',
                    undefined,
                    false,
                  )
                }
              }
            })
        }
      })
      .catch((orderFail) => {
        const errorMessage = getErrorMessage(orderFail)
        if (errorMessage === 'OrderNoOpenDrawer') {
          displayError(
            'receipt_drawer_warning_title',
            'checkout_drawer_error_message',
            'receipt_button_open_drawer',
            () => {
              navigate('CurrentDrawer', { nextAction: 'drawer_continue_to_payment' })
              dismissError()
              setPaymentStatus(PAYMENT_STEPS.init)
            },
            'orders_button_go_back',
            () => {
              setPaymentStatus(PAYMENT_STEPS.init)
              dismissError()
            },
            false,
          )
        } else {
          displayError(
            'checkount_error_message',
            errorMessage,
            'drawer_retry_button',
            () => {
              dismissError()
              setPaymentStatus(PAYMENT_STEPS.init)
            },
            'orders_button_go_back',
            () => {
              dismissError()
              goBackCustom()
            },
            false,
          )
        }
      })
  }, [])

  return (
    <View style={styles.container}>
      <Loader size={124} />
    </View>
  )
}
Pay.propTypes = {
  goBackCustom: PropTypes.func,
  navigate: PropTypes.func,
  dispatch: PropTypes.func,
  invoicingMethod: PropTypes.string,
  otherCurrency: PropTypes.object,
}

export default Pay
