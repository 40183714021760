import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment-timezone'

import {
    DatePickerModal,
} from '../../../../../Components'
import { getPaymentDates } from '../../../Payment.Selectors'
import { updatePaymentAttribute } from '../../../Payment.Actions'


function DatesModalWithLimit({ }) {
    const dispatchAction = useDispatch()

    const dates = useSelector(getPaymentDates)
    const setDates = (value) => dispatchAction(updatePaymentAttribute('dates', value))

    return (
        <DatePickerModal
            preselectedDate={dates?.tempDate === '' ? moment().format('YYYY-MM-DD') : moment(dates?.tempDate).format('YYYY-MM-DD')}
            onClose={() => {
                const newDates = {
                    ...dates,
                    shown: false,
                }
                setDates(newDates)
            }}
            isVisible={dates.shown}
            onDateChange={(date) => {
                const datesObj = dates
                if (datesObj.willUpdate === 'endDate'
                    && (!moment(datesObj.startDate).isSame(moment(date), 'month')
                        || moment(date).isBefore(moment(datesObj.startDate)))) {

                    setDates({ ...datesObj })
                    return
                }

                setDates({
                    ...datesObj,
                    tempDate: date,
                })
            }}
            onButtonPress={() => {
                const datesObj = dates

                if (datesObj.willUpdate === 'startDate') {
                    setDates({
                        ...datesObj,
                        startDate: datesObj.tempDate,
                        endDate: '',
                        shown: false,
                    })
                    return
                } else if (
                    datesObj.willUpdate === 'endDate'
                    && (!moment(datesObj.startDate).isSame(moment(datesObj.tempDate), 'month')
                        || moment(datesObj.tempDate).isBefore(moment(datesObj.startDate)))
                ) {
                    setDates({
                        ...datesObj,
                        shown: false,
                    })
                    return
                }
                setDates({
                    ...datesObj,
                    [datesObj.willUpdate]: datesObj.tempDate,
                    shown: false,
                })

            }}
        />
    )
}

export default DatesModalWithLimit
