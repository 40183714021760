/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useContext, useCallback, useEffect, useRef } from 'react'
import {
  View,
  SafeAreaView,
  Linking,
  Dimensions,
  LayoutAnimation,
  Platform,
} from 'react-native'
import QRCode from 'react-native-qrcode-svg'
import ContentLoader, { Rect } from 'react-content-loader/native'
import moment from 'moment'
import { useLazyQuery, useApolloClient, useMutation } from '@apollo/client'
import Big from 'big.js'
import Share from 'react-native-share'
import { useFocusEffect } from '@react-navigation/native'
import { useTheme } from 'react-native-themed-styles'
import PropTypes from 'prop-types'
import RNHTMLtoPDF from 'react-native-html-to-pdf'
import { useReactToPrint } from 'react-to-print'

import { Margin, usePDF } from 'react-to-pdf'

import {
  Text,
  Button,
  DoubleIcon,
  SimpleList,
  Icon,
  Header,
  ErrorModal,
  Loader,
  ScrollWrapperWeb,
  RetryBanner,
  SimpleType,
  SimpleSelectType,
  BottomSheetModal,
} from '../../Components'
import { useLanguage } from '../../Utils/Language'
import Merchant from '../../Utils/Merchant'
import { GET_TRANSACTION, SYNC_TRANSACTION } from '../Orders/Orders.schema'
import { printReceipt } from '../../Utils/Order'
import { convertReceipt } from '../../Utils/PdfTemplates'
// eslint-disable-next-line import/no-unresolved
import { ConvertRecipt, ReactToPDF } from '../../Utils/PdfTemplatesWeb'

import { INF, useConfig, useTemplateLanguage } from '../../Utils'
import ContentManager from '../../Utils/ContentManager'

import { useLocationDetails } from '../../Utils/AuthDetails'

import { GET_QR } from '../../Utils/Utils.Schema'

import Theme, { layoutAnimConfig } from '../../Themes/Theme'
import themedStyles from './Receipt.Styles'
import images, { animations } from '../../Themes/Images'

const { width } = Dimensions.get('screen')

const LANGUAGES = {
  al: 'sq',
  en: 'en-gb',
}

function LoaderContent({ isFirst, isLast, style }) {
  const loaderStyle = {}
  if (isFirst) {
    loaderStyle.borderTopLeftRadius = 7
    loaderStyle.borderTopRightRadius = 7
  }
  if (isLast) {
    loaderStyle.borderBottomLeftRadius = 7
    loaderStyle.borderBottomRightRadius = 7
  }
  return (
    <ContentLoader
      speed={0.7}
      height={70}
      style={Object.assign({}, { backgroundColor: 'white' }, loaderStyle, style)}
      backgroundColor="#e2e2e2"
      foregroundColor="#ecebeb">
      <Rect x="20" y="22.5" rx="3" ry="3" width="30" height="25" />
      <Rect x="80" y="10" rx="3" ry="3" width="145" height="12" />
      <Rect x="80" y="32" rx="3" ry="3" width="70" height="8" />
      <Rect x="80" y="50" rx="3" ry="3" width="130" height="8" />
      <Rect x={`${width - 110}`} y="27" rx="3" ry="3" width="30" height="16" />
      <Rect x={`${width - 63}`} y="22.5" rx="3" ry="3" width="20" height="25" />
    </ContentLoader>
  )
}
LoaderContent.propTypes = {
  isFirst: PropTypes.bool,
  isLast: PropTypes.bool,
  style: PropTypes.object,
}

function ItemLoader() {
  const [styles] = useTheme(themedStyles)

  return (
    <View>
      <LoaderContent isFirst isLast style={styles.bottomSpacing} />
      <LoaderContent isFirst />
      <LoaderContent />
      <LoaderContent />
      <LoaderContent isLast />
      <ContentLoader
        speed={0.7}
        height={50}
        style={Object.assign({}, { borderRadius: 7 })}
        backgroundColor="#e2e2e2"
        foregroundColor="#ecebeb">
        <Rect x="0" y="30" rx="3" ry="3" width="110" height="12" />
      </ContentLoader>
      <LoaderContent isFirst />
      <LoaderContent />
      <LoaderContent isLast />
    </View>
  )
}

function SingleLoaderContent({ isFirst, isLast, style }) {
  const loaderStyle = {}
  if (isFirst) {
    loaderStyle.borderTopLeftRadius = 7
    loaderStyle.borderTopRightRadius = 7
  }
  if (isLast) {
    loaderStyle.borderBottomLeftRadius = 7
    loaderStyle.borderBottomRightRadius = 7
  }
  return (
    <ContentLoader
      speed={0.7}
      height={40}
      width={200}
      style={Object.assign({}, loaderStyle, style)}
      backgroundColor="#e2e2e2"
      foregroundColor="#ecebeb">
      <Rect x="0" y="10" rx="3" ry="3" width="200" height="20" />
    </ContentLoader>
  )
}
SingleLoaderContent.propTypes = {
  isFirst: PropTypes.bool,
  isLast: PropTypes.bool,
  style: PropTypes.object,
}

function QRLoaderContent({ isFirst, isLast, style }) {
  const loaderStyle = {}
  if (isFirst) {
    loaderStyle.borderTopLeftRadius = 7
    loaderStyle.borderTopRightRadius = 7
  }
  if (isLast) {
    loaderStyle.borderBottomLeftRadius = 7
    loaderStyle.borderBottomRightRadius = 7
  }
  return (
    <ContentLoader
      speed={0.7}
      height={80}
      width={80}
      style={Object.assign({}, loaderStyle, style)}
      backgroundColor="#e2e2e2"
      foregroundColor="#ecebeb">
      <Rect x="0" y="0" rx="3" ry="3" width="80" height="80" />
    </ContentLoader>
  )
}
QRLoaderContent.propTypes = {
  isFirst: PropTypes.bool,
  isLast: PropTypes.bool,
  style: PropTypes.object,
}

function Item({ title, value, isFirst, isLast }) {
  const [styles] = useTheme(themedStyles)

  let style = {}
  if (isFirst) {
    style = Object.assign({
      borderTopLeftRadius: 7,
      borderTopRightRadius: 7,
      borderTopWidth: 0,
    })
  }
  if (isLast) {
    style = Object.assign({
      borderBottomLeftRadius: 7,
      borderBottomRightRadius: 7,
    })
  }

  return (
    <View
      style={[
        styles.itemStyles,
        style,
      ]}>
      <View style={styles.itemStyle}>
        <View style={styles.listDescContainer}>
          <Text i18nKey={title} />
          <Text i18nKey={`: ${value}`} />
        </View>
      </View>
    </View>
  )
}
Item.propTypes = {
  title: PropTypes.string,
  value: PropTypes.string,
  isFirst: PropTypes.bool,
  isLast: PropTypes.bool,
}

const INVOICE_TYPE = {
  CASH: 'receipt_cash_payment',
  NONCASH: 'receipt_noncash_payment',
}

const FAIL_REASONS = [
  {
    label: 'receipt_retry_no_internet',
    id: '1',
    value: 'NOINTERNET',
  },
  {
    label: 'receipt_retry_technical_problem',
    id: '2',
    value: 'BOUNDBOOK',

  },
  {
    label: 'receipt_retry_general_problem',
    id: '3',
    value: 'SERVICE',

  },
  {
    label: 'receipt_retry_boundbook_problem',
    id: '4',
    value: 'TECHNICALERROR',

  },
]

const PAYMENT_METHOD = {
  BANKNOTE: 'payment_banknote_description',
  CARD: 'payment_card_description',
  CHECK: 'payment_check_description',
  SVOUCHER: 'payment_voucher_description',
  COMPANY: 'payment_company_description',
  ORDER: 'payment_order_description',
  ACCOUNT: 'payment_account_description',
  FACTORING: 'payment_factoring_description',
  COMPENSATION: 'payment_compensation_description',
  TRANSFER: 'payment_transfer_description',
  WAIVER: 'payment_waiver_description',
  KIND: 'payment_kind_description',
  OTHER: 'payment_other_description',

}

function Receipt({ route, navigation: { navigate, goBack } }) {

  const { switchLocation: { deviceId, locationId: locId } } = useConfig()

  const [styles] = useTheme(themedStyles)
  const { colors } = useContext(Theme)
  const context = useContext(Merchant)
  const { openConfigurationSelector, translate } = useTemplateLanguage()
  const { euRate, usdRate, poundRate } = useLocationDetails()
  const lang = useLanguage()
  const { id, receiptNumber, locationId } = route.params
  // const convertReceiptWebRef = useRef()
  const retryBannerRef = useRef()

  const OTHER_CURRENCY_DETAILS = {
    EUR: {
      currency: 'EUR',
      rate: euRate,
    },
    USD: {
      currency: 'USD',
      rate: usdRate,
    },
    GBP: {
      currency: 'GBP',
      rate: poundRate,
    },
    ALL: {
    },
    Lekë: {},
  }

  const [confirm, setConfirm] = useState(false)
  const [items, setItems] = useState([])
  const [shareLoading, setShareLoading] = useState(false)
  const [transactionId, setTransactionId] = useState('')
  const [loading1, setLoading] = useState(true)
  const [loadingDownloadWeb, setLoadingDownloadWeb] = useState(false)
  const [loadingPrintWeb, setLoadingPrintWeb] = useState(false)

  const [qrCode, setQrCode] = useState('')
  const [lineItems, setLineItems] = useState([])
  const [transaction1, setTrancation] = useState({})
  const [cashBuyer, setCashBuyer] = useState('')
  const [payType, setPayType] = useState()
  const [change, setChange] = useState('')

  const [customer, setCustomer] = useState({})
  const [bankAccountData, setBankAccount] = useState({})
  const [deliveryData, setDelivery] = useState({})
  const [otherCurrency, setOtherCurrency] = useState({})

  const [selectedReason, setSelectedReason] = useState({})

  const client = useApolloClient()

  const [
    getTransaction,
    { loading: loading, data: transactionData, refetch, error },
  ] = useLazyQuery(GET_TRANSACTION, {
    fetchPolicy: 'network-only',
  })

  const [syncTransaction, { loading: syncLoading }] = useMutation(SYNC_TRANSACTION)

  const [modal, setModal] = useState({
    isVisible: false,
    data: [],
    title: '',
    placeholder: '',
    selected: undefined,
    select: () => { },
    Item: SimpleType,
  })

  const [errorModal, setErrorModal] = useState({
    isVisible: false,
    icon: images.error,
    title: '',
    desc: '',
    primaryText: '',
    primaryAction: null,
    secondaryText: '',
    secondaryAction: null,
  })

  const openErrorModal = (
    title = 'receipt_printer_error',
    desc = '',
    primaryText = '',
    primaryAction = null,
    secondaryText = '',
    secondaryAction = null,
    icon = images.warningIcon,
  ) => {
    setErrorModal({
      isVisible: true,
      icon,
      title,
      desc,
      primaryText,
      primaryAction,
      secondaryText,
      secondaryAction,
    })
  }

  const closeErrorModal = () => {
    setErrorModal({
      isVisible: false,
      icon: images.delete,
      title: '',
      desc: '',
      primaryText: '',
      primaryAction: null,
      secondaryText: '',
      secondaryAction: null,
    })
  }

  useFocusEffect(
    useCallback(() => {
      closeErrorModal()
      getTransaction({
        variables: {
          locationId: locationId ? locationId : 'K11715005L',
          locId: locId,
          cashRegisterId: deviceId,
          transactionId: id,
        },
      })
    }, []),
  )

  const { translate: conTranslate } = ContentManager

  const mapResponse = useCallback((data) => {
    const response = []
    let section = null

    const transaction = data?.getTransaction
    if (transaction) {
      const refunds = data?.getTransaction?.refunds
      setTrancation(transaction)
      setCashBuyer(`${transaction?.tender?.cashDetails?.buyerTenderedMoney?.amount}`)
      setChange(`${transaction?.tender?.cashDetails?.changeBackMoney?.amount}`)
      setPayType(transaction?.tender?.type)
      setQrCode(transaction?.qrCode)
      const invoiceType = transaction?.invoiceType
      setTransactionId(transaction?.id)
      const order = transaction?.order
      const orderLineItems = order?.lineItems
      const taxes = order?.orderGroupedTaxes
      setLineItems(orderLineItems?.filter(item => parseFloat(item?.refundableQuantity) > 0))
      const customer1 = transaction?.customer

      const shipping = order?.shippingAddress || {}
      const bankAccount = order?.bankAccount || {}
      setBankAccount(bankAccount)
      setDelivery(shipping)
      let customerData = {}
      if (customer1) {
        const {
          id: customerId,
          issuerNUIS,
          businessName,
          givenName,
          familyName,
          reference,
          address: {
            addressLine1,
            country,
            administrativeDistrictLevel1,
          },
          type,
        } = customer1
        customerData = {
          customerId,
          address: `${addressLine1}, ${administrativeDistrictLevel1}, ${country}`,

        }
        if (type === 'INDIVIDUAL') {
          const { name: idName, value: idValue } = reference || {}

          customerData = { ...customerData, title: givenName + ' ' + familyName, idName, idValue }
        } else {
          customerData = { ...customerData, title: businessName, nipt: issuerNUIS }
        }
      }
      setCustomer(customerData)
      refunds?.map((item, index) => {
        section = {
          id: 1,
          data: [],
        }
        response.push(section)
        response[response.length - 1]?.data.push({
          id: item?.id,
          withIcon: true,
          touchableItems: true,
          leftComponent: renderRefundLeftComponent(images.cash, images.refund),
          title: item.receiptNumber,
          description: moment(item.createdAt)
            .locale(LANGUAGES[lang])
            .format('DD/MM/YYYY hh:mm A'),
          rightOrderComponent: renderRefundRightComponent(
            INF.format(item.amountMoney?.amount) + ' ' + item.amountMoney.currency,
          ),
          style: {
            marginBottom: 20,
          },
          imageContainerStyle: {
            minWidth: 50,
          },
          titleContainerStyle: {
            paddingLeft: 10,
          },
          onItemPress: () =>
            navigate('Refund', {
              id: id,
              refundId: item?.id,
              receiptNum: item.receiptNumber,
              type: 'goBack',
            }),
        })
      })

      section = {
        id: 2,
        data: [],
      }
      response.push(section)
      const amountMoney = transaction?.tender?.amountMoney
      const otherCurrencyAmount = transaction?.tender?.additionalAmountMoney || amountMoney
      const currency = transaction?.tender?.cashDetails?.currency || {}
      setOtherCurrency(otherCurrencyAmount)
      response[response.length - 1]?.data.push(
        {
          id: 1,
          leftText: INVOICE_TYPE?.[invoiceType],
          rightText: moment(transaction?.createdAt)
            .locale(LANGUAGES[lang])
            .format('DD/MM/YYYY hh:mm A'),
        },
        {
          id: 2,
          leftText: 'receipt_total_method',
          rightText: conTranslate(PAYMENT_METHOD[transaction?.tender?.type]),
        },
        {
          id: 5,
          leftText: 'receipt_user',
          rightText: transaction?.employee,
          ratio: '1:2',
          rightTextProps: {
            numberOfLines: 1,
          },
        },
        {
          id: 3,
          leftText: 'receipt_invoice_number',
          rightText: transaction?.receiptNumber,
        },
        {
          id: 4,
          leftText: 'receipt_invoice_ordinal_number',
          rightText: transaction?.invoiceNumber,
        },
      )

      section = {
        id: 3,
        sectionTitle: 'receipt_section_items_service',
        data: [],
      }
      response.push(section)
      orderLineItems?.map((item, index) => {
        let subdescription = '',
          modifierLine = '',
          discountLine = ''
        const quantity = item.quantity || 0
        const baseAmount = INF.format(item.basePriceMoney?.amount) || 0
        const itemCurrency = item.basePriceMoney?.currency || 'Lekë'
        const variation = item?.variationName
        const modifier = item?.modifiers
        const discounts = item?.appliedDiscounts

        discounts?.map((el, ind) => {
          if (
            el.discountType === 'FIXED_PERCENTAGE' ||
            el.discountType === 'VARIABLE_PERCENTAGE'
          ) {
            if (ind === 0) {
              discountLine = `${el.name} (${el.percentage}%)`
            } else {
              discountLine = `${discountLine}\n${el.name} (${el.percentage}%)`
            }
          } else {
            if (ind === 0) {
              discountLine = `${el?.appliedMoney?.amount} ${el?.appliedMoney?.currency
                } ${conTranslate('receipt_discount_line')}`
            } else {
              discountLine = `${discountLine}\n${el?.appliedMoney?.amount} ${el?.appliedMoney?.currency
                } ${conTranslate('receipt_discount_line')}`
            }
          }
        })
        modifier?.map((element, i) => {
          if (i === 0) {
            modifierLine = `${element.name} (${element.appliedMoney?.amount + ' ' + element.appliedMoney.currency
              })`
          } else {
            modifierLine = `${modifierLine}\n${element.name} (${element.appliedMoney?.amount + ' ' + element.appliedMoney.currency
              })`
          }
        })
        if (variation !== null && modifier?.[0] && discounts?.[0]) {
          subdescription = `${variation}\n${modifierLine}\n${discountLine}`
        } else if (modifier?.[0] && variation !== null) {
          subdescription = `${variation}\n${modifierLine}`
        } else if (modifier?.[0] && discounts?.[0]) {
          subdescription = `${modifierLine}\n${discountLine}`
        } else if (discounts?.[0] && variation !== null) {
          subdescription = `${variation}\n${discountLine}`
        } else if (variation !== null) {
          subdescription = variation
        } else if (modifier?.[0]) {
          subdescription = modifierLine
        } else if (discounts?.[0]) {
          subdescription = discountLine
        } else {
          subdescription = ''
        }

        response[response.length - 1]?.data.push({
          id: item?.id,
          rightOrderComponent: renderItemRightComponent(
            INF.format(item.totalMoney.amount) + ' ' + item.totalMoney.currency,
          ),
          leftComponent: renderIconLeftComponent(
            item.imageUrl
              ? { uri: item.imageUrl }
              : item.labelColor || colors.purple,
            item.itemName,
          ),
          subDescText: subdescription,
          subDescriptionStyle: {
            fontSize: 12,
            lineHeight: 14,
          },
          withIcon: true,
          title: item.itemName,
          description: quantity + ' x ' + baseAmount + ' ' + itemCurrency,
          separator: true,
          titleContainerStyle: { paddingVertical: 5, width: 100 },
        })
      })

      section = {
        id: 4,
        sectionTitle: 'receipt_section_total',
        data: [],
      }
      response.push(section)
      const total = INF.format(order?.totalMoney?.amount) + ' ' + order?.totalMoney?.currency
      const discount = order?.totalDiscountMoney?.amount || 0
      const taxAmount = order?.totalTaxMoney?.amount || 0
      const subtotal = new Big(order?.totalMoney?.amount)
      const taxesss = [
        {
          id: 2,
          leftComponent: renderLeftComponent('receipt_subtotal'),
          rightComponent: renderRightComponent(
            INF.format(subtotal.add(discount).minus(taxAmount)) +
            ' ' +
            order?.totalMoney?.currency,
          ),
        },
        {
          id: 1,
          leftComponent: renderLeftComponent('receipt_total'),
          rightComponent: renderRightComponent(total),
        },
      ]
      if (otherCurrencyAmount && (otherCurrencyAmount?.currency !== 'ALL' && otherCurrencyAmount?.currency !== 'Lekë')) {
        if (Object.keys(otherCurrencyAmount)?.length > 0) {
          taxesss.push({
            id: otherCurrencyAmount?.amount,
            leftComponent: renderLeftComponent('receipt_foreign_currency_label', OTHER_CURRENCY_DETAILS?.[otherCurrencyAmount?.currency]?.currency),
            rightComponent: renderRightComponent(
              INF.format(otherCurrencyAmount?.amount || 0) + ' ' + OTHER_CURRENCY_DETAILS?.[otherCurrencyAmount?.currency]?.currency,
            ),
          },
            {
              id: otherCurrencyAmount?.amount + '1',
              leftComponent: renderLeftComponent('receipt_currency_rate_label'),
              // rightComponent: renderRightComponent(
              // OTHER_CURRENCY_DETAILS?.[otherCurrencyAmount?.currency]?.rate,
              // ),
              rightComponent: renderRightComponent(
                currency?.exRate
              ),
            }
          )
        }
      }
      taxes?.map((item, index) => {
        taxesss.push({
          id: item.VATRate + '' + index,
          leftComponent: renderLeftComponent(
            'receipt_tax',
            (item?.VATRate || 0) + '',
          ),
          rightComponent: renderRightComponent(
            INF.format(item?.VATAmt?.amount || 0) + ' ' + item?.VATAmt?.currency,
          ),
        })
      })
      taxesss.push(
        {
          id: 4,
          leftComponent: renderLeftComponent('receipt_total_discount'),
          rightComponent: renderRightComponent(
            INF.format(discount) + ' ' + order?.totalDiscountMoney?.currency,
          ),
        },
        {
          id: 4,
          leftComponent: renderLeftComponent('receipt_total_without_tax'),
          rightComponent: renderRightComponent(
            INF.format(subtotal.minus(taxAmount)) +
            ' ' +
            order?.totalMoney?.currency,
          ),
        },
      )
      taxesss?.map((item, index) => {
        response[response.length - 1]?.data.push(item)
      })
      if (Object.keys(customerData).length > 0) {
        section = {
          id: 5,
          sectionTitle: 'customer_checkout_label',
          data: [],
        }
        response.push(section)
        response[response.length - 1]?.data.push(
          {
            id: 1,
            leftText: 'categories_name_label',
            rightText: customerData.title,
          },
          {
            id: 5,
            leftText: 'demo_address_label',
            rightText: customerData.address,
            ratio: '1:2',
            rightTextProps: {
              numberOfLines: 1,
            },
          },
        )
        if (customerData.nipt) {
          response[response.length - 1]?.data.push(
            {
              id: 12,
              leftText: 'certificate_nipt_label',
              rightText: customerData.nipt,
            },
          )
        }
      }
      if (Object?.keys(shipping)?.length > 0) {
        section = {
          id: 6,
          sectionTitle: 'einvoice_details_delivery_tab',
          data: [],
        }
        response.push(section)
        if (shipping?.externalId) {
          response[response.length - 1]?.data.push(
            {
              id: 12,
              leftText: 'payment_delivery_receiver_label',
              rightText: shipping?.externalId,
            },
          )
        }
        response[response.length - 1]?.data.push(
          {
            id: 17,
            leftText: 'einvoice_delivery_address_header',
            rightText: `${shipping?.addressLine1}, ${shipping?.locality}, ${shipping?.country}`,
          },
        )
      }
      if (Object?.keys(bankAccount)?.length > 0) {
        section = {
          id: 7,
          sectionTitle: 'einvoice_payment_bank_account_label',
          data: [],
        }
        response.push(section)
        response[response.length - 1]?.data.push(
          {
            id: 11,
            leftText: 'payment_account_number_label',
            rightText: bankAccount?.holderName,
          },
          {
            id: 12,
            leftText: 'payment_iban_label',
            rightText: bankAccount?.iban,
          },
          {
            id: 13,
            leftText: 'bank_accounts_bank_name',
            rightText: bankAccount?.bankName,
          },
          {
            id: 14,
            leftText: 'bank_accounts_swift_code',
            rightText: bankAccount?.swiftCode,
          },
        )
      }
    }
    return response
  })

  useEffect(() => {
    if (transactionData) {
      const resp = mapResponse(transactionData)
      LayoutAnimation.configureNext(layoutAnimConfig)
      setItems(resp)
      setLoading(false)
    }
  }, [transactionData])

  const renderIconLeftComponent = (icon, name) => {
    if (icon.uri) {
      return <Icon size={70} source={icon} resizeMode="cover" />
    } else {
      return (
        <View
          style={styles.leftComponentStyles(icon)}>
          <Text
            size="h5"
            color={icon}
            align="center"
            i18nKey={name?.slice(0, 2)}
          />
        </View>
      )
    }
  }

  const renderLeftComponent = (title, value) => {
    return (
      <View style={styles.listLeftComponent}>
        <Text i18nKey={title} placeHolders={[value]} />
      </View>
    )
  }

  const renderRightComponent = (rightLabel, style) => {
    return (
      <View style={[styles.listRightComponent, style]}>
        <Text
          size="footnote"
          style={styles.itemRightLabel}
          i18nKey={rightLabel}
        />
      </View>
    )
  }

  const renderItemRightComponent = (rightLabel) => {
    return (
      <View style={styles.rightLabelStyle}>
        <Text style={styles.itemRightLabel} i18nKey={rightLabel} />
      </View>
    )
  }

  const renderRefundRightComponent = (rightLabel) => {
    return (
      <View
        style={styles.refundComponent}>
        <View style={styles.listRightComponent}>
          <Text
            size="footnote"
            style={styles.itemRightLabel}
            i18nKey={`-${rightLabel}`}
          />
        </View>
        <Icon
          source={images.chevronRight}
          size={24}
          color={colors.primaryIcon}
        />
      </View>
    )
  }

  const renderRefundLeftComponent = (icon, statusIcon) => {
    return (
      <DoubleIcon
        statusIconSource={statusIcon}
        iconSource={icon}
        statusSize={10}
        iconSize={30}
        style={styles.leftSpacing}
        statusIconStyle={styles.bottomSmallSpacing}
        iconStyle={{ tintColor: colors.secondaryIcon }}
        positionStatus="bottomRight"
      />
    )
  }

  const { toPDF, targetRef } = usePDF({
    method: 'save',
    filename: `${receiptNumber}.pdf`,
    page: { margin: Margin.NONE },
  })

  const handlePrintWeb = useReactToPrint({
    content: () => targetRef.current,
    onBeforePrint: () => setLoadingPrintWeb(true),
    onAfterPrint: () => setLoadingPrintWeb(false),
  })

  const onHandlePrintWeb = () => {
    openConfigurationSelector(false, handlePrintWeb)
  }


  const handleDownload = async () => {
    setLoadingDownloadWeb(true)
    await toPDF()
    setLoadingDownloadWeb(false)
  }

  const activateConfirm = () => {
    if (confirm) {
      printReceipt(
        transaction1,
        cashBuyer,
        OTHER_CURRENCY_DETAILS?.[otherCurrency?.currency]?.rate,
        OTHER_CURRENCY_DETAILS?.[otherCurrency?.currency]?.currency,
        context?.nipt,
        context?.company,
        context?.location,
        customer,
        translate
      ).catch(() => {
        openErrorModal(
          'receipt_printer_error',
          'receipt_printer_error_label',
          'receipt_open_printers_button',
          () => {
            closeErrorModal()
            navigate('PrinterSetUp')
          },
          'checkout_button_skip',
          () => {
            closeErrorModal()
          },
          images.error
        )
        setConfirm(false)
      })
    } else {
      openConfigurationSelector(false, () => setConfirm(true))
    }
  }

  const buttonProps = {
    backgroundColor: {
      true: colors.secondaryAccent,
      false: colors.buttonSecondaryAccent,
    },
    foregroundColor: {
      true: colors.invertedText,
      false: colors.buttonTertiaryAccent,
    },
    text: {
      true: 'receipt_button_confirm',
      false: 'receipt_button_print_receipt',
    },
    iconColor: {
      true: colors.tertiaryIcon,
      false: colors.secondaryIcon,
    },
  }

  const shareReceipt = async () => {
    setShareLoading(true)
    const { data: qr } = await client.query({
      query: GET_QR,
      variables: {
        text: transaction1.qrCode,
      },
    })

    openConfigurationSelector(true, () => {
      RNHTMLtoPDF.convert({
        html: convertReceipt(
          transaction1,
          cashBuyer,
          change,
          context?.nipt,
          context?.company,
          context?.location,
          customer,
          translate,
          qr
        ),
        fileName: 'fature',
      })
        .then((res) => {
          Share.open({
            url: `file://${res.filePath}`,
            subject: 'Fatura ' + receiptNumber,
            title: 'Fatura',
          })
            .catch(() => { })
          setShareLoading(false)
        })
        .catch(() => {
          setShareLoading(false)
        })
    })
    setShareLoading(false)
  }

  const onSyncTransaction = () => {
    setModal(prev => ({ ...prev, isVisible: false }))
    syncTransaction({
      variables: {
        locId: locId,
        cashRegisterId: deviceId,
        id: id,
        reason: selectedReason?.value,
      },
    }).then(res => {
      retryBannerRef.current.closeBanner()
      refetch().then(() => retryBannerRef.current.changeStatus()).catch()
    }).catch(e => {
      openErrorModal(
        'certificate_error_label',
        'demo_error_description',
        'printers_button_close',
        () => {
          closeErrorModal()
        },
        '',
        null,
        images.error
      )
    })
  }

  return (
    <>
      <SafeAreaView style={styles.container}>
        <Header
          image={images.closeIcon}
          title={receiptNumber}
          loading={loading1}
          onPress={() => goBack()}
        />
        <ScrollWrapperWeb>
          <SimpleList
            DATA={items}
            containerStyle={styles.listStyle}
            withSectionHeader
            sectionList
            separatorStyle={styles.separatorStyle}
            initialNumToRender={20}
            refreshing={false}
            onRefresh={() => refetch()}
            listHeaderComponent={
              <>
                <View style={styles.inlineButtons}>
                  <Button
                    title="receipt_button_issue_refund"
                    titleStyle={styles.buttonTitle}
                    backgroundColor={colors.buttonSecondaryAccent}
                    color={colors.buttonTertiaryAccent}
                    borderColor={colors.transparent}
                    icon={images.refundedInvoice}
                    iconColor={colors.secondaryIcon}
                    iconStyle={{ transform: [{ rotate: '90deg' }] }}
                    disabled={loading || error}
                    style={styles.fullIssueButton}
                    onPress={() =>
                      navigate('IssueRefundDetails', {
                        lineItems: lineItems,
                        transactionId: transactionId,
                        type: payType,
                        customerId: customer?.customerId,
                        bankId: bankAccountData?.id,
                        shippingAddress: deliveryData,
                      })
                    }
                  />
                  <Button
                    title={Platform.OS === 'web' ? 'receipt_button_print_receipt' : buttonProps.text[confirm]}
                    icon={loadingPrintWeb ? null : images.printerActive}
                    iconStyle={{
                      tintColor: buttonProps.iconColor[confirm],
                    }}
                    style={styles.inlineButton1}
                    disabled={loading || loadingPrintWeb || error}
                    backgroundColor={buttonProps.backgroundColor[confirm]}
                    borderWidth={0}
                    borderColor={buttonProps.backgroundColor[confirm]}
                    color={buttonProps.foregroundColor[confirm]}
                    loader={loadingPrintWeb}
                    loaderComponent={<Loader source={animations.vfLoaderThinDark} size={24} />}
                    onPress={Platform.OS === 'web' ? () => onHandlePrintWeb() : () => activateConfirm()}
                  />
                  {Platform.OS === 'web' ?
                    <Button
                      title="receipt_download_as_pdf"
                      icon={loadingDownloadWeb ? null : images.download}
                      iconColor={colors.secondaryIcon}
                      style={styles.inlineButton1}
                      disabled={loadingDownloadWeb}
                      backgroundColor={buttonProps.backgroundColor[confirm]}
                      borderWidth={0}
                      borderColor={buttonProps.backgroundColor[confirm]}
                      titleStyle={{ color: colors.primaryText }}
                      loader={loadingDownloadWeb}
                      loaderComponent={<Loader source={animations.vfLoaderThinDark} size={24} />}
                      onPress={() => {
                        openConfigurationSelector(true, handleDownload)
                      }}
                    />
                    :
                    <Button
                      icon={shareLoading ? null : images.share}
                      iconColor={colors.secondaryIcon}
                      color={colors.primaryText}
                      title="orders_button_share_receipt"
                      backgroundColor={colors.white}
                      style={styles.inlineButton2}
                      onPress={() => shareReceipt().catch(e => { })}
                      loader={shareLoading}
                      loaderComponent={<Loader source={animations.vfLoaderThinDark} size={24} />}
                      variant={loading || shareLoading || error ? 'disabled' : 'active'}
                    />
                  }

                </View>
                {loading1 ? null : <RetryBanner
                  ref={retryBannerRef}
                  onRetry={() => setModal(prev => ({ ...prev, isVisible: true }))}
                  status={transaction1?.status}
                  code={transaction1?.code}
                  message={transaction1?.message}
                  loading={syncLoading}
                />
                }

              </>
            }
            listFooterComponent={
              <>
                {loading1 ? (
                  <>
                    <ItemLoader />
                  </>
                ) : null}
                {Boolean(qrCode) && (
                  <View style={styles.invValContainer}>
                    {loading1 ? (
                      <>
                        <SingleLoaderContent />
                      </>
                    ) : (
                      <Text
                        size="footnote"
                        style={styles.qrTitle}
                        i18nKey="receipt_section_invoice_validation"
                      />
                    )}
                    <View style={styles.qrCode}>
                      {loading1 ? (
                        <QRLoaderContent />
                      ) : (
                        <QRCode
                          value={qrCode}
                          size={120}
                          backgroundColor={colors.background}
                          color={colors.secondaryIcon}
                        />
                      )}
                    </View>
                    <Button
                      icon={images.link}
                      iconStyle={styles.invoiceButton}
                      iconColor={colors.quartenaryIcon}
                      titleStyle={{ color: colors.primaryText }}
                      title="receipt_button_invoice_validation"
                      backgroundColor={colors.tertiaryBackground}
                      style={styles.fullButton}
                      borderColor={colors.transparent}
                      onPress={() => Linking.openURL(qrCode)}
                    />
                    <View>
                      <Item
                        title="receipt_iic_label"
                        value={transaction1?.IIC}
                        isFirst={true}
                      />
                      <Item title="receipt_fic_label" value={transaction1?.FIC} />
                      {transaction1?.location?.businUnitCode ? <Item
                        title="cash_register_business_code"
                        value={transaction1?.location?.businUnitCode}
                      /> : null}
                      <Item
                        title="receipt_operator_code_label"
                        value={transaction1?.operatorCode}
                      />
                      <Item
                        title="receipt_software_code_label"
                        value={transaction1?.softwareCode}
                        isLast={true}
                      />
                    </View>
                  </View>
                )}
              </>
            }
          />
        </ScrollWrapperWeb>

        {Platform.OS === 'web' && !loading1 &&
          <div style={styles.webStyles}>
            <ConvertRecipt
              transaction={transaction1}
              cashBuyer={cashBuyer}
              change={change}
              nipt={context?.nipt}
              company={context?.company}
              location={context?.location}
              customer={customer}
              ref={targetRef}
            />
          </div>
        }
      </SafeAreaView>
      <ErrorModal
        isVisible={errorModal.isVisible}
        icon={errorModal.icon}
        title={errorModal.title}
        description={errorModal.desc}
        primaryText={errorModal.primaryText}
        primaryAction={errorModal.primaryAction}
        secondaryText={errorModal.secondaryText}
        secondaryAction={errorModal.secondaryAction}
        onBackdropPress={() => closeErrorModal()}
        onBackButtonPress={() => closeErrorModal()}
        onClosePress={() => closeErrorModal()}
      />
      <BottomSheetModal
        isVisible={modal.isVisible}
        onClose={() => setModal(prev => ({ ...prev, isVisible: false }))}
      >
        <SimpleSelectType
          subtitle="receipt_retry_subtitle"
          data={FAIL_REASONS}
          Item={modal.Item}
          title="receipt_retry_title"
          placeholder="einvoice_doc_type_search_placeholder"
          selected={selectedReason?.id}
          select={obj => setSelectedReason(obj)}
          closeModal={() => setModal(prev => ({ ...prev, isVisible: false }))}
        />
        <Button
          title="receipt_retry_synchronize_button"
          style={styles.retryButton}
          loader={shareLoading}
          loaderComponent={<Loader source={animations.vfLoaderThinDark} size={24} />}
          variant={Object.keys(selectedReason).length > 0 ? 'active' : 'disabled'}
          onPress={onSyncTransaction}
        />
      </BottomSheetModal>
    </>
  )
}
Receipt.propTypes = {
  route: PropTypes.object,
  navigation: PropTypes.object,
}

export default Receipt
