/* eslint-disable react-native/no-inline-styles */
import React, { useContext } from 'react'
import { View, TouchableOpacity, ImageBackground, Dimensions } from 'react-native'
import DeviceInfo from 'react-native-device-info'

import PropTypes from 'prop-types'

import { useTheme } from 'react-native-themed-styles'

import Text from '../Text'

import Modal from '../Modal'

import Icon from '../Icon'

import Theme from '../../Themes/Theme'

import themedstyles from './ProfilePicPicker.Styles'
import images from '../../Themes/Images'

const { width } = Dimensions.get('screen')

function ProfilePicPicker(props) {

  const [styles] = useTheme(themedstyles)
  const { colors } = useContext(Theme)
  const {
    isVisible,
    setSelected,
    onClosePress,
    selected,
  } = props


  return (
    <Modal
      isVisible={isVisible}
      animationIn="fadeIn"
      animationOut="fadeOut"
      useNativeDriver={true}
      onBackdropPress={onClosePress}
      style={styles.modal}
    >
      <View style={[styles.modalContent, DeviceInfo.isTablet() ? { width: 400 } : { width: width - 30 }]}>
        <TouchableOpacity style={styles.closeContainer}
          onPress={onClosePress}
        >
          <Icon size={18} source={images.closeIcon} color={colors.white} />
        </TouchableOpacity>
        <Text i18nKey="users_choose_avatar_title" align="center" weight="bold" size="h4" />
        <View style={styles.avatarContainer}>
          <TouchableOpacity onPress={() => setSelected('maleAvatar')} style={{ marginRight: 25 }}>
            <ImageBackground
              source={images.maleAvatar}
              style={[styles.avatar, selected === 'maleAvatar' ? styles.selectedImage : {}]}
              resizeMode="cover"
            />
          </TouchableOpacity>
          <TouchableOpacity onPress={() => setSelected('femaleAvatar')} style={{ marginLeft: 25 }}>
            <ImageBackground
              source={images.femaleAvatar}
              style={[styles.avatar, selected === 'femaleAvatar' ? styles.selectedImage : {}]}
              resizeMode="cover"
            />
          </TouchableOpacity>
        </View>
        <Text i18nKey="users_choose_color_title" align="center" />
        <View style={{ flexDirection: 'row', paddingHorizontal: 20, marginTop: 20, justifyContent: 'center' }}>
          <TouchableOpacity
            onPress={() => setSelected('gray')}
            style={[{
              backgroundColor: colors.pending,
              marginRight: 5,
            }, styles.colorContainer, selected === 'gray' ? styles.selectedColor : {}]}
          >
            {selected === 'gray' && <Icon source={images.tick} size={20} color={colors.white} />}
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => setSelected('red')}
            style={[{
              backgroundColor: colors.red,
              marginLeft: 5,
              marginRight: 5,
            }, styles.colorContainer, selected === 'red' ? styles.selectedColor : {}]}
          >
            {selected === 'red' && <Icon source={images.tick} size={20} color={colors.white} />}
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => setSelected('purple')}
            style={[{
              backgroundColor: colors.purple,
              marginLeft: 5,
              marginRight: 5,
            }, styles.colorContainer, selected === 'purple' ? styles.selectedColor : {}]}
          >
            {selected === 'purple' && <Icon source={images.tick} size={20} color={colors.white} />}
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => setSelected('blue')}
            style={[{
              backgroundColor: colors.blue,
              marginLeft: 5,
              marginRight: 5,
            }, styles.colorContainer, selected === 'blue' ? styles.selectedColor : {}]}
          >
            {selected === 'blue' && <Icon source={images.tick} size={20} color={colors.white} />}
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => setSelected('green')}
            style={[{
              backgroundColor: colors.green,
              marginLeft: 5,
              marginRight: 5,
            }, styles.colorContainer, selected === 'green' ? styles.selectedColor : {}]}
          >
            {selected === 'green' && <Icon source={images.tick} size={20} color={colors.white} />}
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => setSelected('yellow')}
            style={[{
              backgroundColor: colors.yellow,
              marginLeft: 5,
            }, styles.colorContainer, selected === 'yellow' ? styles.selectedColor : {}]}
          >
            {selected === 'yellow' && <Icon source={images.tick} size={20} color={colors.white} />}
          </TouchableOpacity>
        </View>
      </View>
    </Modal>
  )
}

ProfilePicPicker.defaultProps = {
  isVisible: true,
}

ProfilePicPicker.propTypes = {
  isVisible: PropTypes.bool,
  setSelected: PropTypes.func,
  selected: PropTypes.string,
  onClosePress: PropTypes.func,
}

export default ProfilePicPicker

