import { styleSheetFactory } from '../../Themes/Colors'

const styles = styleSheetFactory(colors => ({
  container: {
    flexGrow: 1,
    flex:1,
    backgroundColor: colors.background,
  },
  contentContainer: {
    flexGrow: 1,
  },
  rowContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: colors.tertiaryBackground,
    marginVertical: 30,
    marginHorizontal: 15,
    padding: 15,
    borderRadius: 7,
  },
  separator: {
    height: 1,
    backgroundColor: colors.separator,
    marginLeft: 70,
  },
  separatorContainer: {
    backgroundColor: colors.tertiaryBackground,
    height: 1,
  },
  listStyle: {
    flex: 1,
    marginHorizontal: 15,
  },
  listContainerStyle: {
    paddingBottom: 30,
  },
  listInfoContainer: {
    marginTop: 15,
    backgroundColor: colors.tertiaryBackground,
    padding: 15,
    borderRadius: 7,
    flexDirection: 'row',
    alignItems: 'center',
  },
  rightSpacing: { marginRight: 20 },
  leftComponent: { flexGrow: 1 },
}))

export default styles
