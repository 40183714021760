/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { SafeAreaView, View, TouchableOpacity, FlatList, ScrollView, Platform, Dimensions } from 'react-native'
import moment from 'moment-timezone'
import { useTheme } from 'react-native-themed-styles'
import PropTypes from 'prop-types'
import ShadowView from 'react-native-androw'
import { useLazyQuery } from '@apollo/client'
import ContentLoader, { Rect } from 'react-content-loader/native'
import RNFS from 'react-native-fs'
import RNFetchBlob from 'rn-fetch-blob'
import Share from 'react-native-share'
import _ from 'lodash'
import axios from 'axios'

import {
    Header,
    Text,
    Button,
    DatePickerModal,
    TouchableInput,
    Icon,
    ErrorModal,
    Loader,
} from '../../Components'

import { getErrorMessage } from '../../Utils'

import { useUserDetails, useLocationDetails } from '../../Utils/AuthDetails'

import { GET_REPORTS } from './Reports.Schema'
import images from '../../Themes/Images'
import { colors, animations } from '../../Themes'

import themedStyles from './Report.Styles'

const { width } = Dimensions.get('screen')

const reportItems = [
    {
        title: 'reports_article_summary_title',
        type: 'ArticleSummary',
    },
    {
        title: 'reports_business_turnover_title',
        type: 'BusinessTurnover',
    },
    {
        title: 'reports_analytical_sales_title',
        type: 'AnalyticalSales',
    },
]

const dateItems = [
    {
        title: 'reports_custom_date_title',
        type: 'custom',
    },
    {
        title: 'reports_month_date_title',
        type: 'months',
    },
    {
        title: 'reports_week_date_title',
        type: 'weeks',
    },
    {
        title: 'reports_today_date_title',
        type: 'days',
    },
]

function ReportItem({ title, isSelected, select, isSuggestion, disabled }) {
    const [styles] = useTheme(themedStyles)

    return (
        <View style={[isSuggestion ? styles.suggestionContainer : styles.itemContainer, styles['itemContainerSelected_' + isSelected]]}>
            <TouchableOpacity disabled={disabled} onPress={select} style={isSuggestion ? styles.suggestionTouchable : [styles.touchable, Platform.OS === 'web' && { width: '100%' }]}>
                <Text i18nKey={title} size={'body'} align="center" style={styles['titleSelected_' + isSelected]} />
            </TouchableOpacity>
        </View>
    )
}
ReportItem.propTypes = {
    title: PropTypes.string,
    isSelected: PropTypes.bool,
    select: PropTypes.func,
    isSuggestion: PropTypes.bool,
    disabled: PropTypes.bool,
}

function ListComponent({
    data,
    isSuggestion,
    setReportsList,
    reports,
    selectedType,
    setSelectedType,
    contentContainerStyle,
    openModal,
    closeModal,
    loading,
    ...rest
}) {
    const [styles] = useTheme(themedStyles)


    // eslint-disable-next-line no-shadow
    const keyExtractor = (_, index) => 'item_' + index

    const renderItem = ({ item, index }) => {
        return (
            <ReportItem
                {...item}
                isSelected={item?.type === selectedType}
                select={() => {
                    reports?.length > 0 ? openModal(
                        'report_clear_filter_warning_title',
                        'report_clear_filter_warning_description',
                        [],
                        'report_clear_filter_warning_yes',
                        () => {
                            setSelectedType(item?.type)
                            setReportsList([])
                            closeModal()
                        },
                        'logout_button_cancel',
                        () => closeModal()
                    ) : setSelectedType(item?.type)
                }}
                isSuggestion={isSuggestion}
                disabled={loading}
            />
        )
    }

    return (
        <FlatList
            data={data}
            keyExtractor={keyExtractor}
            renderItem={renderItem}
            horizontal
            style={styles.listStyle}
            contentContainerStyle={[styles.listContainerStyle, contentContainerStyle]}
            showsHorizontalScrollIndicator={false}
            inverted={isSuggestion}
            CellRendererComponent={isSuggestion ? undefined : ({ children }) => <View style={styles.callRenderer}>{children}</View>}
            {...rest}
        />

    )
}
ListComponent.propTypes = {
    data: PropTypes.array,
    isSuggestion: PropTypes.bool,
    setReportsList: PropTypes.func,
    reports: PropTypes.array,
    selectedType: PropTypes.object,
    setSelectedType: PropTypes.func,
    contentContainerStyle: PropTypes.object,
    openModal: PropTypes.func,
    closeModal: PropTypes.func,
    loading: PropTypes.bool,
}
function Reports({ navigation }) {
    const [styles] = useTheme(themedStyles)
    const { navigate } = navigation
    const [selectedType, setSelectedType] = useState('ArticleSummary')
    const [selectedInterval, setSelectedInterval] = useState('')
    const [reportsList, setReportsList] = useState([])
    const [disabled, setDisabled] = useState(true)
    const { role, id } = useUserDetails()
    const { id: locationId } = useLocationDetails()
    const [isAdmin] = useState(Boolean(role === 'Admin'))
    const [hasFilters, setHasFilters] = useState(false)
    const [loaders, setLoaders] = useState({
        pdfDownloadLoader: false,
        pdfShareLoader: false,
        xlsDownloadLoader: false,
        xlsShareLoader: false,
    })

    const downloadFile = async (item, index) => {
        if (index === 0) {
            setLoaders(prev => ({
                ...prev,
                xlsDownloadLoader: true,
            }))
        } else {
            setLoaders(prev => ({
                ...prev,
                pdfDownloadLoader: true,
            }))
        }

        const fileTyte = item?.ContentType === 'application/pdf' ? '.pdf' : '.xlsx'
        const mimeType = item?.ContentType === 'application/pdf' ? 'application/pdf' : 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        if (Platform.OS === 'web') {
            return (
                axios({
                    method: 'GET',
                    url: item?.SourceUrl,
                    responseType: 'blob',
                }).then((res) => {
                    // eslint-disable-next-line no-undef
                    const url = window.URL.createObjectURL(new Blob([res.data]))
                    const link = document.createElement('a')
                    link.href = url
                    link.setAttribute('download', `${item?.DisplayName}${fileTyte}`)
                    document.body.appendChild(link)
                    link.click()
                    if (index === 0) {
                        setLoaders(prev => ({
                            ...prev,
                            xlsDownloadLoader: false,
                        }))
                    } else {
                        setLoaders(prev => ({
                            ...prev,
                            pdfDownloadLoader: false,
                        }))
                    }
                }).catch(e => {
                    if (index === 0) {
                        setLoaders(prev => ({
                            ...prev,
                            xlsDownloadLoader: false,
                        }))
                    } else {
                        setLoaders(prev => ({
                            ...prev,
                            pdfDownloadLoader: false,
                        }))
                    }
                })
            )
        } else {
            const { dirs } = RNFetchBlob.fs
            const options = Platform.OS === 'ios' ?
                {
                    fileCache: true,
                    path: dirs.DocumentDir + `/${item?.DisplayName}${fileTyte}`,
                    mime: mimeType,
                    // appendExt: 'xlsx',
                    //path: filePath,
                    //appendExt: fileExt,
                    notification: true,
                }
                : {
                    addAndroidDownloads: {
                        useDownloadManager: true,
                        path: dirs.DownloadDir + `/${item?.DisplayName}${fileTyte}`,
                        notification: true,
                        mime: mimeType,
                        title: `${item?.DisplayName}${fileTyte}`,
                    },
                    fileCache: true,

                }
            RNFetchBlob.config(options).fetch('GET', item?.SourceUrl)
                .then((res) => {
                    return res.readFile('base64')
                }).then(async base64 => {
                    Platform.OS === 'ios' && previewFile(base64, item?.DisplayName, fileTyte, mimeType)
                    if (index === 0) {
                        setLoaders(prev => ({
                            ...prev,
                            xlsDownloadLoader: false,
                        }))
                    } else {
                        setLoaders(prev => ({
                            ...prev,
                            pdfDownloadLoader: false,
                        }))
                    }
                })
                .catch(e => {
                    if (index === 0) {
                        setLoaders(prev => ({
                            ...prev,
                            xlsDownloadLoader: false,
                        }))
                    } else {
                        setLoaders(prev => ({
                            ...prev,
                            pdfDownloadLoader: false,
                        }))
                    }
                })
        }
    }

    const previewFile = (base64, title, fileTyte, mimeType) => {
        const { dirs } = RNFetchBlob.fs
        const dirToSave = Platform.OS === 'ios' ? dirs.DocumentDir : dirs.DownloadDir
        const configfb = {
            fileCache: true,
            useDownloadManager: true,
            notification: true,
            mediaScannable: true,
            title: `${title.replace(/[/]/g, '_')}${fileTyte}`,
            path: `${dirToSave}/${title.replace(/[/]/g, '_')}${fileTyte}`,
        }
        const configOptions = Platform.select({
            ios: {
                fileCache: configfb.fileCache,
                title: configfb.title,
                path: configfb.path,
                appendExt: 'pdf',
            },
            android: configfb,
        })

        RNFetchBlob.config(configOptions)
        RNFetchBlob.fs.writeFile(configfb.path, base64, 'base64')

        if (Platform.OS === 'ios') {
            RNFetchBlob.ios.openDocument(configfb.path)
        } else {
            RNFetchBlob.android.actionViewIntent(configfb.path, mimeType)
            RNFetchBlob.android.addCompleteDownload({
                title: `${title.replace(/[/]/g, '_')}${fileTyte}`,
                description: 'Download complete',
                mime: mimeType,
                path: configfb.path,
                showNotification: true,
            })
        }
    }

    const shareFile = async (item, index) => {
        if (index === 0) {
            setLoaders(prev => ({
                ...prev,
                xlsShareLoader: true,
            }))
        } else {
            setLoaders(prev => ({
                ...prev,
                pdfShareLoader: true,
            }))
        }
        const dirs = RNFetchBlob.fs.dirs
        const fileTyte = item?.ContentType === 'application/pdf' ? '.pdf' : '.xlsx'
        let filePath = null
        const mimeType = item?.ContentType === 'application/pdf' ? 'application/pdf' : 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'

        const options = Platform.OS === 'ios' ?
            {
                fileCache: true,
                path: dirs.DocumentDir + `/${item?.DisplayName}${fileTyte}`,
                mime: mimeType,
                appendExt: fileTyte.substring(1),
            }
            : {
                addAndroidDownloads: {
                    useDownloadManager: true,
                    path: dirs.DownloadDir + `/${item?.DisplayName}${fileTyte}`,
                    mime: mimeType,
                },
                fileCache: true,

            }
        RNFetchBlob.config(options).fetch('GET', item?.SourceUrl,)
            .then(async resp => {
                filePath = resp.path()
                if (Platform.OS === 'android') {
                    let base64Data = ''
                    resp.readFile('base64')
                        .then((async res => {
                            base64Data = `data:${mimeType};base64,` + res
                            Share.open({ url: base64Data, filename: `${item?.DisplayName}` })
                                .catch(() => { })
                        }))
                } else {
                    await Share.open({ url: filePath, type: `${item?.ContentType}` })
                        .catch(e => {
                            if (index === 0) {
                                setLoaders(prev => ({
                                    ...prev,
                                    xlsShareLoader: false,
                                }))
                            } else {
                                setLoaders(prev => ({
                                    ...prev,
                                    pdfShareLoader: false,
                                }))
                            }
                        })
                }
                if (index === 0) {
                    setLoaders(prev => ({
                        ...prev,
                        xlsShareLoader: false,
                    }))
                } else {
                    setLoaders(prev => ({
                        ...prev,
                        pdfShareLoader: false,
                    }))
                }
                await RNFS.unlink(filePath)
            })
    }

    const [dates, setDates] = useState({
        startDate: '',
        endDate: '',
        shown: false,
        willUpdate: '',
        tempDate: '',
    })

    const selectDate = (type) => {
        type === 'custom' ?
            setDates({
                ...dates,
                startDate: '',
                endDate: '',
            })
            : setDates({
                ...dates,
                startDate: moment().startOf(`${type}`).format('YYYY-MM-DD'),
                endDate: moment().format('YYYY-MM-DD'),
            })
        setSelectedInterval(type)
    }

    const [selectedEmployees, setSelectedEmployees] = useState({})
    const [selectedLocations, setSelectedLocations] = useState({})

    const [modal, setModal] = useState({
        isVisible: false,
        icon: images.warningIcon,
        title: '',
        desc: '',
        descPlaceholders: [],
        primaryText: '',
        primaryAction: null,
        tertiaryText: '',
        tertiaryAction: null,
    })
    const openModal = (
        title = 'opened_orders_error',
        desc = '',
        descPlaceholders = [],
        primaryText = '',
        primaryAction = null,
        tertiaryText = '',
        tertiaryAction = null,
        icon = images.warningIcon,
    ) => {
        setModal({
            isVisible: true,
            icon,
            title,
            desc,
            descPlaceholders,
            primaryText,
            primaryAction,
            tertiaryText,
            tertiaryAction,
        })
    }
    const closeModal = () => {
        setModal({
            isVisible: false,
            icon: images.delete,
            title: '',
            desc: '',
            primaryText: '',
            primaryAction: null,
            tertiaryText: '',
            tertiaryAction: null,
        })
    }

    const [getReports, { data, error, loading }] = useLazyQuery(
        GET_REPORTS,
        {
            fetchPolicy: 'network-only',
        }
    )

    const showDatesModal = (willUpdate) => {
        setDates(prev => ({
            ...prev,
            shown: true,
            willUpdate,
            tempDate: prev[willUpdate],
        }))
    }

    const onSave = (locations) => {
        setSelectedLocations(locations)
    }

    const onEmployeeSave = (employees) => {
        setSelectedEmployees(employees)
    }

    const retrieveReports = () => {
        setReportsList([])

        if (isAdmin) {
            getReports({
                variables: {
                    type: selectedType,
                    filter: {
                        employeeIds: Object.keys(selectedEmployees),
                        locationIds: Object.keys(selectedLocations),
                    },
                    beginDate: dates.startDate,
                    endDate: dates.endDate,
                },
            })
        } else {
            getReports({
                variables: {
                    type: selectedType,
                    filter: {
                        employeeIds: [id],
                        locationIds: [locationId],
                    },
                    beginDate: dates.startDate,
                    endDate: dates.endDate,
                },
            })
        }
    }

    useEffect(() => {
        if (error) {
            openModal(
                'report_clear_filter_warning_title',
                getErrorMessage(error),
                [],
                'orders_try_again',
                () => {
                    getReports({
                        variables: {
                            type: selectedType,
                            filter: {
                                employeeIds: Object.keys(selectedEmployees),
                                locationIds: Object.keys(selectedLocations),
                            },
                            beginDate: dates.startDate,
                            endDate: dates.endDate,
                        },
                    })
                    closeModal()
                },
                'logout_button_cancel',
                () => { closeModal() }
            )
        }
    }, [error])

    const clearFilters = () => {
        if (reportsList?.length > 0) {
            openModal(
                'report_clear_filter_warning_title',
                'report_clear_filter_warning_description',
                [],
                'report_clear_filter_warning_yes',
                () => {
                    setSelectedEmployees({})
                    setSelectedLocations({})
                    setSelectedType('')
                    setSelectedInterval('')
                    setDates({
                        ...dates,
                        startDate: '',
                        endDate: '',
                    })
                    setReportsList([])
                    closeModal()
                },
                'logout_button_cancel',
                () => { closeModal() }
            )
        }
        else {
            setSelectedEmployees({})
            setSelectedLocations({})
            setSelectedType('')
            setSelectedInterval('')
            setDates({
                ...dates,
                startDate: '',
                endDate: '',
            })
            setReportsList([])
        }
    }

    useEffect(() => {
        if (data) {
            setReportsList(data?.getReport?.reports)
        }
    }, [data])

    useEffect(() => {
        if (
            dates.startDate === '' ||
            dates.endDate === '' ||
            selectedType === '' ||
            (_.isEmpty(selectedLocations) && isAdmin) ||
            (_.isEmpty(selectedEmployees) && isAdmin)
        ) {
            setDisabled(true)
        } else {
            setDisabled(false)
        }
    }, [dates, selectedLocations, selectedEmployees, selectedType, isAdmin])

    const selectTypeChange = (type) => {
        if (type === selectedType) {
            return
        } else {
            setSelectedInterval('')
            setDates({
                ...dates,
                startDate: '',
                endDate: '',
            })
            setSelectedType(type)
        }
    }

    useEffect(() => {
        if (
            dates.startDate ||
            dates.endDate ||
            selectedType ||
            Object.keys(selectedLocations)?.length > 0 ||
            Object.keys(selectedEmployees)?.length > 0
        ) {
            setHasFilters(true)
        } else {
            setHasFilters(false)
        }
    }, [dates, selectedLocations, selectedEmployees, selectedType, isAdmin])

    return (
        <>
            <SafeAreaView style={styles.wrapper}>
                <Header
                    title="menu_reports_label"
                    image={images.menu}
                    onPress={() => navigation.openDrawer()}
                />
                <ScrollView style={styles.container} showsVerticalScrollIndicator={false} >
                    <Text weight="bold" i18nKey="reports_report_type_title" style={styles.listTitle} />
                    <ListComponent
                        loading={loading}
                        data={reportItems}
                        isSuggestion={false}
                        selectedType={selectedType}
                        setSelectedType={(type) => {
                            selectTypeChange(type)
                        }}
                        openModal={openModal}
                        closeModal={closeModal}
                        reports={reportsList}
                        setReportsList={setReportsList}
                    />
                    <View style={styles.textBlock}>
                        <Text weight="bold" i18nKey="reports_locations_button_title" />
                        {hasFilters ? <TouchableOpacity style={styles.resetFilter} onPress={() => { if (!loading) { clearFilters() } }} >
                            <Text i18nKey="reports_filer_clear" style={styles.textLineHeight} color={colors.primaryText} />
                            <Icon source={images.closeS} size={14} color="gray" style={styles.leftSpacing} />
                        </TouchableOpacity> : <View >
                            <Text i18nKey="reports_filer_clear" color={colors.placeholder} onPress={() => { }} />
                        </View>}
                    </View>
                    <Button
                        color={!isAdmin || loading ? colors.white : colors.secondaryAccent}
                        title="reports_business_location_title"
                        titleProps={{ translateOption: { count: Object.keys(selectedLocations)?.length } }}
                        variant={!isAdmin || loading ? 'disabled' : 'preActive'}
                        style={styles.button}
                        onPress={() => {
                            reportsList?.length > 0 ? openModal(
                                'report_clear_filter_warning_title',
                                'report_clear_filter_warning_description',
                                [],
                                'report_clear_filter_warning_yes',
                                () => {
                                    closeModal()
                                    setReportsList([])
                                    navigate('Locations', { multipleSelection: true, selectable: true, onSave, preselectedLocations: selectedLocations })
                                },
                                'logout_button_cancel',
                                () => closeModal()

                            ) : navigate('Locations', { multipleSelection: true, selectable: true, onSave, preselectedLocations: selectedLocations })
                        }}
                    />
                    <Text weight="bold" i18nKey="reports_date_title" style={styles.sectionTitle} />
                    <ListComponent
                        data={dateItems}
                        isSuggestion={true}
                        selectedType={selectedInterval}
                        setSelectedType={selectDate}
                        openModal={openModal}
                        closeModal={closeModal}
                        reports={reportsList}
                        setReportsList={setReportsList}
                    />
                    {selectedInterval === 'custom' ?
                        <View>
                            <View style={styles.buttonWrapper}>
                                <TouchableInput
                                    disabled={loading}
                                    onPress={() => {
                                        reportsList?.length > 0 ? openModal(
                                            'report_clear_filter_warning_title',
                                            'report_clear_filter_warning_description',
                                            [],
                                            'report_clear_filter_warning_yes',
                                            () => {
                                                showDatesModal('startDate')
                                                setReportsList([])

                                                closeModal()
                                            },
                                            'logout_button_cancel',
                                            () => { closeModal() }

                                        )
                                            : showDatesModal('startDate')

                                    }}
                                    value={dates?.startDate ? moment(dates?.startDate).format('DD/MM/YYYY') : ''}
                                    label="einvoice_start_date_label"
                                    icon={images.calendarBlack}
                                    autoCorrect={false}
                                    autoCapitalize="none"
                                    editable={false}
                                    style={styles.leftButton}
                                />
                                <TouchableInput
                                    onPress={() =>
                                        reportsList?.length > 0 ? openModal(
                                            'report_clear_filter_warning_title',
                                            'report_clear_filter_warning_description',
                                            [],
                                            'report_clear_filter_warning_yes',
                                            () => {
                                                showDatesModal('endDate')
                                                setReportsList([])

                                                closeModal()
                                            },
                                            'logout_button_cancel',
                                            () => { closeModal() }

                                        )
                                            : showDatesModal('endDate')
                                    }
                                    value={dates?.endDate ? moment(dates?.endDate).format('DD/MM/YYYY') : ''}
                                    disabled={!dates.startDate || loading}
                                    label="einvoice_end_date_label"
                                    icon={images.calendarBlack}
                                    autoCorrect={false}
                                    autoCapitalize="none"
                                    editable={false}
                                    style={styles.rightButton}

                                />
                            </View>
                            {selectedType === 'AnalyticalSales' ? <Text i18nKey="reports_date_note" size="footnote" weight="light" style={styles.dateNote} /> : null}

                        </View>
                        : null}
                    {((Object.keys(selectedLocations)?.length > 0) || !isAdmin) ?
                        <View>
                            <Text weight="bold" i18nKey="reports_employees_filter_title" style={styles.sectionTitle} />
                            <Button
                                color={!isAdmin || loading ? colors.white : colors.secondaryAccent}
                                title={'reports_employees_select_title'}
                                titleProps={{ translateOption: { count: Object.keys(selectedEmployees)?.length } }}
                                variant={!isAdmin || loading ? 'disabled' : 'preActive'}
                                style={styles.button}
                                onPress={() => {
                                    reportsList?.length > 0 ? openModal(
                                        'report_clear_filter_warning_title',
                                        'report_clear_filter_warning_description',
                                        [],
                                        'report_clear_filter_warning_yes',
                                        () => {
                                            //setDataBosh
                                            closeModal()
                                            navigate('SelectEmployee', { isReport: true, onSave: onEmployeeSave, preSelectedEmployees: selectedEmployees })
                                            setReportsList([])

                                        },
                                        'logout_button_cancel',
                                        () => { closeModal() }
                                    ) : navigate('SelectEmployee', { isReport: true, onSave: onEmployeeSave, preSelectedEmployees: selectedEmployees })
                                }}
                            />
                        </View> : null}
                    <DatePickerModal
                        preselectedDate={dates?.tempDate === '' ? moment().format('YYYY-MM-DD') : moment(dates?.tempDate).format('YYYY-MM-DD')}
                        onClose={() => setDates(prev => ({
                            ...prev,
                            shown: false,
                        }))}
                        isVisible={dates.shown}
                        onDateChange={(date) => {
                            setDates(prev => {
                                if (
                                    prev.willUpdate === 'endDate'
                                    && ((!moment(dates.startDate).isSame(moment(date), 'month') && selectedType === 'AnalyticalSales')
                                        || moment(date).isBefore(moment(dates.startDate)))
                                ) {
                                    return ({
                                        ...prev,
                                    })
                                }
                                return ({
                                    ...prev,
                                    tempDate: date,
                                })
                            })
                        }}
                        // date={dates?.tempDate ? dates?.tempDate : new Date()}
                        onButtonPress={() => {
                            setDates(prev => {
                                if (prev.willUpdate === 'startDate') {
                                    return ({
                                        ...prev,
                                        startDate: prev.tempDate,
                                        endDate: '',
                                        shown: false,
                                    })
                                } else if (
                                    prev.willUpdate === 'endDate'
                                    && ((!moment(dates.startDate).isSame(moment(prev.tempDate), 'month') && selectedType === 'AnalyticalSales')
                                        || moment(prev.tempDate).isBefore(moment(dates.startDate)))
                                ) {
                                    return ({
                                        ...prev,
                                        shown: false,
                                    })
                                }
                                return ({
                                    ...prev,
                                    [prev.willUpdate]: prev.tempDate,
                                    shown: false,
                                })
                            })
                        }}
                    />
                    <View style={styles.reportsWrapper}>
                        {reportsList?.length > 0 ?
                            <View style={styles.textBlock}>
                                <Text weight="bold" i18nKey="reports_generated_section" />
                                <TouchableOpacity style={styles.removeBuyer} onPress={() => {
                                    setReportsList([])
                                }}>
                                    <Icon source={images.closeIcon} color={colors.secondaryIcon} size={18} />
                                </TouchableOpacity>
                            </View> : null}

                        {loading ? <View style={styles.horizontalSpacing}>
                            <ContentLoader
                                speed={0.7}
                                height={120}
                                width={Platform.OS === 'web' ? '100%' : (width - 30)}
                                style={styles.loaderContainer}
                                backgroundColor="#e2e2e2"
                                foregroundColor="#ecebeb">
                                {/* <Rect x="0" y="0" rx="3" ry="3" width="390" height="125" /> */}
                                <Rect x="15" y="15" rx="3" ry="3" width="40" height="40" />
                                <Rect x="65" y="15" rx="3" ry="3" width="200" height="15" />
                                <Rect x="65" y="35" rx="3" ry="3" width="85" height="10" />
                                {Platform.OS === 'web' ?
                                    <Rect x="65" y="75" rx="3" ry="3" width={width - 500} height="35" />
                                    :
                                    <>
                                        <Rect x="60" y="75" rx="3" ry="3" width="90" height="35" />
                                        <Rect x="230" y="75" rx="3" ry="3" width="90" height="35" />
                                    </>
                                }
                            </ContentLoader>
                            <ContentLoader
                                speed={0.7}
                                height={120}
                                width={Platform.OS === 'web' ? '100%' : (width - 30)}
                                style={styles.secondLoader}
                                backgroundColor="#e2e2e2"
                                foregroundColor="#ecebeb">
                                {/* <Rect x="0" y="0" rx="3" ry="3" width="390" height="125" /> */}
                                <Rect x="15" y="15" rx="3" ry="3" width="40" height="40" />
                                <Rect x="65" y="15" rx="3" ry="3" width="200" height="15" />
                                <Rect x="65" y="35" rx="3" ry="3" width="85" height="10" />
                                {Platform.OS === 'web' ?
                                    <Rect x="65" y="75" rx="3" ry="3" width={width - 500} height="35" />
                                    : <>
                                        <Rect x="60" y="75" rx="3" ry="3" width="90" height="35" />
                                        <Rect x="230" y="75" rx="3" ry="3" width="90" height="35" />
                                    </>
                                }
                            </ContentLoader>
                        </View>
                            :
                            <>
                                {reportsList?.length > 0 ? reportsList.map((item, index) => (
                                    <ShadowView style={styles.shadow}>
                                        <View style={styles.reportWrapper}>
                                            <View style={styles.cardHeader}>
                                                <Icon source={images.reports} style={styles.rightMediumSpacing} />
                                                <View>
                                                    <Text size="h4" i18nKey={`${item?.DisplayName}.${item?.ContentType === 'application/pdf' ? 'pdf' : 'xlsx'}`} />
                                                    <Text weight="footnote" i18nKey={`${(moment(item?.LastModified).format('DD/MM/YYYY'))}`} color={colors.secondaryText} />
                                                </View>
                                            </View>
                                            <View style={styles.reportButtonWrapper}>
                                                {Platform.OS !== 'web' && <TouchableOpacity style={styles.reportOptions} activeOpacity={0.5} onPress={() => shareFile(item, index)} >
                                                    {index === 0 ? loaders.xlsShareLoader ? <Loader source={animations.vfLoaderThinDark} size={24} /> : <>
                                                        <Icon source={images.share} size={24} style={styles.rightSpacing} />
                                                        <Text i18nKey="orders_button_share_receipt" />
                                                    </> : loaders.pdfShareLoader ? <Loader source={animations.vfLoaderThinDark} size={24} /> : <>
                                                        <Icon source={images.share} size={24} style={styles.rightSpacing} />
                                                        <Text i18nKey="orders_button_share_receipt" />
                                                    </>}
                                                </TouchableOpacity>}
                                                {Platform.OS !== 'web' && <View style={styles.separator} />}
                                                <TouchableOpacity style={styles.reportOptions} activeOpacity={0.5} onPress={() => downloadFile(item, index)}>
                                                    {Platform.OS === 'ios' ?
                                                        index === 0 ? loaders.xlsDownloadLoader ? <Loader source={animations.vfLoaderThinDark} size={24} /> :

                                                            <>
                                                                <Icon source={images.previewDoc} size={24} style={styles.rightSpacing} />
                                                                <Text i18nKey="einvoice_delivery_preview_button" />
                                                            </> : loaders.pdfDownloadLoader ? <Loader source={animations.vfLoaderThinDark} size={24} /> :
                                                            <>
                                                                <Icon source={images.previewDoc} size={24} style={styles.rightSpacing} />
                                                                <Text i18nKey="einvoice_delivery_preview_button" />
                                                            </> : index === 0 ? loaders.xlsDownloadLoader ? <Loader source={animations.vfLoaderThinDark} size={24} /> :

                                                                <>
                                                                    <>
                                                                        <Icon source={images.download} size={24} style={styles.rightSpacing} />
                                                                        <Text i18nKey="reports_download_button" />
                                                                    </>
                                                                </> : loaders.pdfDownloadLoader ? <Loader source={animations.vfLoaderThinDark} size={24} /> :
                                                            <>
                                                                <>
                                                                    <Icon source={images.download} size={24} style={styles.rightSpacing} />
                                                                    <Text i18nKey="reports_download_button" />
                                                                </>
                                                            </>
                                                    }
                                                </TouchableOpacity>
                                            </View>
                                        </View>
                                    </ShadowView>
                                )) : null}
                            </>
                        }

                    </View>
                </ScrollView>
                <Button
                    title="reports_generate_button"
                    variant={disabled || loading ? 'disabled' : 'active'}
                    style={styles.chargeButton}
                    onPress={() => retrieveReports()}
                />
            </SafeAreaView>
            <ErrorModal
                isVisible={modal?.isVisible}
                title={modal?.title}
                description={modal?.desc}
                placeHolders={modal.descPlaceholders}
                primaryText={modal?.primaryText}
                tertiaryText={modal?.tertiaryText}
                primaryAction={modal?.primaryAction}
                tertiaryAction={modal?.tertiaryAction}
                onBackdropPress={() => closeModal()}
                onBackButtonPress={() => closeModal()}
                onClosePress={() => closeModal()}
            />
        </>
    )
}

Reports.propTypes = {
    navigation: PropTypes.object,
}

export default Reports
