/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, memo } from 'react'
import {
  SafeAreaView,
  View,
  TouchableOpacity,
  Dimensions,
  LayoutAnimation,
  Text as RNText,
  Linking,
  ScrollView,
  KeyboardAvoidingView,
  Platform,
  Keyboard,
} from 'react-native'
import { useTheme } from 'react-native-themed-styles'
import PropTypes from 'prop-types'
import ContentLoader, { Rect } from 'react-content-loader/native'
import { SwipeRow } from 'react-native-swipe-list-view'
import Color from 'color'
import { useLazyQuery, useMutation } from '@apollo/client'
import moment from 'moment'

import ContentManager from '../../Utils/ContentManager'

import {
  Header,
  InputWithTags,
  Text,
  Button,
  Icon,
  ErrorModal,
  Loader,
  Switch,
  Modal,
  ModalContent,
} from '../../Components'

import SwipeList from '../Users/SwipeList'
import {
  GET_CASH_REGISTER,
  DELETE_CASH_REGISTER,
  UPDATE_CASH_REGISTER,
  DELETE_TCR_ID,
} from '../CashRegister/CashRegister.Schema'

import { GET_LOCATION } from '../Location/Location.Schema'
import { getErrorMessage, useConfig } from '../../Utils'


import { layoutAnimConfig } from '../../Themes/Theme'
import themedStyles, {
  photoItemStyles,
  itemStyles,
  hiddenStyles,
} from './RegisterDetails.Styles'


import images from '../../Themes/Images'
import { colors, animations } from '../../Themes'


const deviceType = {
  '': {
    primaryButtonTitle: '',
    secondaryButtonTitle: '',
    titleColor: colors.pending,
    primaryButtonColor: colors.accent,
  },
  ACTIVE: {
    primaryButtonTitle: 'taxes_save_button',
    secondaryButtonTitle: 'cash_register_deactivate_button',
    titleColor: colors.pending,
    primaryButtonColor: colors.accent,
  },
  INACTIVE: {
    primaryButtonTitle: 'cash_register_activate_button',
    secondaryButtonTitle: 'cash_register_delete_button',
    titleColor: colors.accent,
    primaryButtonColor: colors.secondaryAccent,
  },
}
const employeeStatus = {
  Pending: 'gray',
  Active: 'green',
  Inactive: 'red',
}
const avatars = ['maleAvatar', 'femaleAvatar']
const { width } = Dimensions.get('screen')

function ItemPhoto(props) {
  const [styles] = useTheme(photoItemStyles)

  const { color, title, status } = props

  const pic = avatars.find((el) => el === color)

  return (
    <View>
      {pic ? (
        <View style={styles.avatarContainer}>
          <Icon source={images[pic]} size={70} resizeMode="cover" />
          <View
            style={[
              styles.status,
              {
                backgroundColor: colors[status],
              },
            ]}
          />
        </View>
      ) : (
        <View
          style={[
            {
              backgroundColor: Color(colors[color] || colors.transparent)
                .fade(0.7)
                .toString(),
            },
            styles.colorContainer,
          ]}>
          <Text
            size="h5"
            color={colors[color]}
            align="center"
            i18nKey={title
              ?.match(/\b(\w{1})/g)
              ?.slice(0, 2)
              ?.join('')
              ?.toUpperCase()}
          />
          <View
            style={[
              styles.status,
              {
                backgroundColor: colors[status],
              },
            ]}
          />
        </View>
      )}
    </View>
  )
}
ItemPhoto.propTypes = {
  color: PropTypes.string,
  role: PropTypes.string,
  title: PropTypes.string,
  status: PropTypes.string,
}

function Employee(props) {
  const [styles] = useTheme(itemStyles)
  const { item, isFirst, isLast, stylee } = props

  let propStyle = {},
    propStylee = {}
  if (isFirst) {
    propStyle = styles.listFirst
  }
  if (isLast) {
    propStylee = styles.listLast
  }
  const style = Object.assign(
    {},
    styles.default,
    styles.list,
    propStyle,
    propStylee,
  )

  return (
    <View style={[{ backgroundColor: colors.tertiaryBackground }, stylee, style]}>
      <View style={styles.subContainer}>
        <ItemPhoto
          color={item?.color}
          role={item?.role}
          title={`${item?.firstName} ${item?.lastName}`}
          status={employeeStatus[item?.userStatus]}
        />
        <View style={styles.textContainer}>
          <View style={styles.firstRow}>
            <Text
              i18nKey={`${item?.firstName} ${item?.lastName}`}
              numberOfLines={1}
            />
            <Text i18nKey={` (${item?.role})`} numberOfLines={1} />
          </View>
          <Text
            i18nKey={item?.email}
            weight="light"
            color={colors.secondaryText}
            size="footnote"
            numberOfLines={1}
          />
        </View>
      </View>
    </View>
  )
}
Employee.propTypes = {
  item: PropTypes.object,
  isFirst: PropTypes.bool,
  isLast: PropTypes.bool,
  stylee: PropTypes.object,
}

const HiddenItem = memo((props) => {
  const [styles] = useTheme(hiddenStyles)
  const {
    needsConfirmation,
    isFirst,
    isLast,
    onPress,
    onConfirm,
    onCancel,
    style,
    loading,
  } = props

  let propStyle = {},
    propStylee = {}
  if (isFirst) {
    propStyle = styles.listFirst
  }
  if (isLast) {
    propStylee = styles.listLast
  }
  const stylee = Object.assign(
    {},
    styles.default,
    styles.list,
    propStyle,
    propStylee,
  )

  return (
    <View style={[styles.hiddenContainer, style, stylee]}>
      {loading
        ? <View style={styles.loader}>
          <Loader size={32} color={colors.white} source={animations.vfLoaderWhite} />
        </View>
        : needsConfirmation ? (
          <View style={styles.leftContainer}>
            <TouchableOpacity style={styles.hiddenItem} onPress={onPress}>
              <Text i18nKey="cash_register_remove_button" color="white" />
            </TouchableOpacity>
          </View>
        ) : (
          <View style={styles.confirmContainer}>
            <TouchableOpacity style={styles.confirmStyles} onPress={onConfirm}>
              <Icon source={images.bin_light} color={colors.white} size={24} />
              <Text
                i18nKey="receipt_button_confirm"
                color={colors.white}
                style={styles.confirmText}
              />
            </TouchableOpacity>
            <TouchableOpacity style={styles.cancelContainer} onPress={onCancel}>
              <Icon source={images.closeIcon} color={colors.white} size={20} />
            </TouchableOpacity>
          </View>
        )}
    </View>
  )
})
HiddenItem.propTypes = {
  needsConfirmation: PropTypes.bool,
  isFirst: PropTypes.bool,
  isLast: PropTypes.bool,
  onPress: PropTypes.func,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  style: PropTypes.object,
  loading: PropTypes.bool,
}

function Item(props) {
  const [styles] = useTheme(themedStyles)
  const {
    title,
    value,
    isFirst,
    isLast,
    itemStyle,
    containerStyle,
    loading,
  } = props

  const style = {}
  if (isFirst) {
    style.borderTopLeftRadius = 7
    style.borderTopRightRadius = 7
  }
  if (isLast) {
    style.borderBottomLeftRadius = 7
    style.borderBottomRightRadius = 7
  }

  return (
    <View
      style={[
        {
          backgroundColor: colors.tertiaryBackground,
        },
        style,
        containerStyle,
      ]}>
      <View style={[styles.itemStyle, itemStyle]}>
        <View style={styles.listDescContainer}>
          <Text i18nKey={title} weight="light" style={styles.rightSpacing} />
          {loading ? (
            <ContentLoader
              speed={0.7}
              height={25}
              width={100}
              style={Object.assign({ borderRadius: 7 })}
              backgroundColor="#e2e2e2"
              foregroundColor="#ecebeb">
              <Rect x="0" y="5" rx="3" ry="3" width={100} height="15" />
            </ContentLoader>
          ) : (
            <Text i18nKey={value} numberOfLines={1} style={styles.itemDescription} />
          )}
        </View>
      </View>
    </View>
  )
}
Item.propTypes = {
  title: PropTypes.string,
  value: PropTypes.string,
  isFirst: PropTypes.bool,
  isLast: PropTypes.bool,
  itemStyle: PropTypes.object,
  containerStyle: PropTypes.object,
  loading: PropTypes.bool,
}

function RegisterDetails({
  navigation: { goBack, navigate },
  route: {
    params: { cashId, refetch = () => { } },
  },
}) {

  const { switchLocation: { deviceId, locationId } } = useConfig()

  const [styles] = useTheme(themedStyles)

  const [value, setValue] = useState('')
  const [status, setStatus] = useState('')
  const [location, setLocation] = useState('')
  const [webAccess, setWebAccess] = useState(false)
  const [apiAccess, setApiAccess] = useState(false)
  const [tcrModalVisible, setTcrModalVisible] = useState(false)
  const [isTcrError, setIsTcrError] = useState({
    display: false,
    message: '',
  })

  const [userTcrInput, setUserTcrInput] = useState('')

  const [businessCode, setBusinessCode] = useState('')
  const [modal, setModal] = useState({
    isVisible: false,
    icon: images.warningIcon,
    title: '',
    desc: '',
    descPlaceholders: [],
    primaryText: '',
    primaryAction: null,
    secondaryText: '',
    secondaryAction: null,
  })
  const openModal = (
    title = 'opened_orders_error',
    desc = '',
    descPlaceholders = [],
    primaryText = '',
    primaryAction = null,
    secondaryText = '',
    secondaryAction = null,
    icon = images.warningIcon,
  ) => {
    setModal({
      isVisible: true,
      icon,
      title,
      desc,
      descPlaceholders,
      primaryText,
      primaryAction,
      secondaryText,
      secondaryAction,
    })
  }
  const closeModal = () => {
    setModal({
      isVisible: false,
      icon: images.delete,
      title: '',
      desc: '',
      primaryText: '',
      primaryAction: null,
      secondaryText: '',
      secondaryAction: null,
    })
  }
  const [disabled, setDisabled] = useState(true)
  const [needsConfirmation, setNeedForConfirmation] = useState(true)
  const [rightOpenValueFull, setRightOpenValueFull] = useState(-width + 30)
  const [expDate] = useState('')
  const [deviceData, setDeviceData] = useState({
    TCRCode: '',
    deviceId: '',
    deviceName: '',
    id: '',
    locationId: '',
    model: '',
    name: '',
    os: '',
    requestUserEmail: '',
    totalCashInvoices: '',
    totalNoncashInvoices: '',
    isTablet: false,
  })

  const [employees, setEmployees] = useState([])

  const [isKeyboardVisible, setKeyboardVisible] = useState(false)

  useEffect(() => {
    const keyboardDidShowListener = Keyboard.addListener(
      'keyboardDidShow',
      () => {

        setKeyboardVisible(true)
        Platform.OS === 'android' && LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut)
      }
    )
    const keyboardDidHideListener = Keyboard.addListener(
      'keyboardDidHide',
      () => {
        setKeyboardVisible(false)
        Platform.OS === 'android' && LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut)
      }
    )

    return () => {
      keyboardDidHideListener.remove()
      keyboardDidShowListener.remove()
    }
  }, [])

  const measureView = (event) => {
    setRightOpenValueFull(-event.nativeEvent.layout.width)
  }

  const openRow = (rowMap, id) => {
    setNeedForConfirmation(false)
    rowMap[id].manuallySwipeRow(rightOpenValueFull)
  }

  const closeRow = (rowMap, id) => {
    rowMap[id].closeRow()
    setNeedForConfirmation(true)
  }

  const resetNeedForConfirmation = () => {
    setNeedForConfirmation(true)
  }

  const expRegex = /^([0-2][0-9]|(3)[0-1])(\/)(((0)[0-9])|((1)[0-2]))(\/)\d{4}$/i

  const [
    deleteDevice,
    { loading: deleting },
  ] = useMutation(DELETE_CASH_REGISTER)

  const [
    updateDevice,
    { loading: updating },
  ] = useMutation(UPDATE_CASH_REGISTER)

  const [deleteTcr, { loading: TcrLoading }] = useMutation(DELETE_TCR_ID)








  const [
    getDevice,
    { loading: devLoading, data: devData },
  ] = useLazyQuery(GET_CASH_REGISTER, {
    fetchPolicy: 'network-only',
    variables: {
      searchBy: {
        name: 'id',
        value: cashId,
      },
    },
  })

  const [getLocation, {
    data: details,
    loading: locationLoading,
  }] = useLazyQuery(GET_LOCATION)

  useEffect(() => {
    getDevice()
  }, [])

  useEffect(() => {
    if (devData?.getCashRegister) {
      LayoutAnimation.configureNext(layoutAnimConfig)
      setEmployees(devData?.getCashRegister?.employees)
      setValue(devData?.getCashRegister?.name)
      setStatus(devData?.getCashRegister?.status)
      getLocation({ variables: { id: devData?.getCashRegister?.locationId } })
      setDeviceData(devData?.getCashRegister)
      setBusinessCode(devData?.getCashRegister?.businUnitCode)
      setWebAccess(devData?.getCashRegister?.enableWebAccess)
      setApiAccess(devData?.getCashRegister?.enableApiAccess)
    }
  }, [devData])

  const onButtonPress = () => {
    if (status === 'ACTIVE') {
      updateDevice({
        variables: {
          id: cashId,
          name: value,
          enableWebAccess: webAccess,
          enableApiAccess: apiAccess,
          validTo: expDate ? moment(expDate, 'DD/MM/YYYY').format('YYYY-MM-DD') : null,
        },
      })
        .then(() => goBack())
        .catch((err) => {
          openModal(
            'certificate_error_label',
            getErrorMessage(err),
            [],
            'cash_register_yes_button',
            undefined,
            'printers_button_cancel',
            () => closeModal(),
          )
        })

    } else {
      openModal(
        'cash_register_activate_button',
        'cash_register_activate_message',
        [],
        'cash_register_yes_button',
        () => {
          updateDevice({
            variables: {
              id: cashId,
              status: 'ACTIVE',
              enableWebAccess: webAccess,
              enableApiAccess: apiAccess,
              validTo: expDate ? moment(expDate, 'DD/MM/YYYY').format('YYYY-MM-DD') : null,
            },
          })
            .then(() => {
              closeModal()
              goBack()
            })
            .catch((err) => {
              closeModal()
              openModal(
                'certificate_error_label',
                getErrorMessage(err),
                [],
                '',
                undefined,
                '',
                undefined,
              )
            })
        },
        'printers_button_cancel',
        () => closeModal(),
      )
    }
  }

  const onSecondButtonPress = () => {
    if (status === 'INACTIVE') {
      openModal(
        'cash_register_deleting_title',
        'cash_register_modal_description',
        [],
        'cash_register_yes_button',
        () => {
          deleteDevice({
            variables: {
              cashRegisterId: cashId,
            },
          })
            .then(() => {
              closeModal()
              goBack()
            })
            .catch(() => {
              // displayToast('cash_register_delete_error', images.warning)
            })
        },
        'printers_button_cancel',
        () => closeModal(),
      )
    } else {
      openModal(
        'cash_register_deactivate_button',
        'cash_register_deactivate_message',
        [],
        'cash_register_yes_button',
        () => {
          updateDevice({
            variables: {
              id: cashId,
              status: 'INACTIVE',
            },
          })
            .then(() => {
              closeModal()
              goBack()
            })
            .catch((err) => {
              closeModal()
              openModal(
                'certificate_error_label',
                getErrorMessage(err),
                [],
                '',
                undefined,
                '',
                undefined,
              )
            })
        },
        'printers_button_cancel',
        () => closeModal(),
      )
    }
  }

  useEffect(() => {
    const locationData = details?.getLocation
    if (locationData) {
      setLocation(locationData?.businessName)
    }
  }, [details])

  useEffect(() => {
    const regex = /^[a-z]{2}\d{3}[a-z]{2}\d{3}$/
    if ((value === devData?.getCashRegister?.name && devData?.getCashRegister?.enableApiAccess === apiAccess && devData?.getCashRegister?.enableWebAccess === webAccess && (!expRegex.test(expDate) || !expDate)) || value === '' || !regex.test(businessCode) || businessCode === '') {
      setDisabled(true)
    } else {
      setDisabled(false)
    }
  }, [value, businessCode, apiAccess, webAccess, expDate])

  const addEmployees = (items) => {
    const preEmployees = {}
    employees.map((item) => {
      preEmployees[item.id] = true
    })
    const employeesToAdd = items
      ?.filter((employee) => !preEmployees[employee.id])
      .map((em) => em.id)
    updateDevice({
      variables: {
        id: cashId,
        employeeToEnable: employeesToAdd,
      },
    })
      .then(() => {
        setEmployees(items)
        setDisabled(false)
      })
      .catch((err) => {
        if (getErrorMessage(err) === 'CashRegisterLocationMismatchError') {

          openModal(
            'certificate_error_label',
            getErrorMessage(err),
            [],
            '',
            undefined,
            'cash_register_okay_button',
            () => closeModal(),
          )

        }
        else {
          openModal(
            'certificate_error_label',
            employeesToAdd?.length === 1
              ? 'cash_register_add_error_1'
              : 'cash_register_add_error_0',
            [`${employeesToAdd?.length}`],
            '',
            undefined,
            'cash_register_okay_button',
            () => closeModal(),
          )
        }
      })
  }

  const { translate } = ContentManager

  const closeTcrModal = () => {
    setTcrModalVisible(prev => !prev)
    setIsTcrError({ display: false, message: '' })
    setUserTcrInput('')
  }

  return (
    <SafeAreaView style={styles.container}>
      <Header title={value} loading={devLoading} image={images.back} onPress={() => goBack()} />
      <SwipeList
        onLayout={(event) => measureView(event)}
        ItemSeparatorComponent={() => <View style={styles.separator} />}
        style={styles.topSpacing}
        contentContainerStyle={styles.contentContainerStyle}
        data={employees?.filter(item => item)}
        onRowClose={resetNeedForConfirmation}
        ListHeaderComponent={
          <>
            <InputWithTags
              label="cash_register_name_label"
              value={value}
              onChangeText={setValue}
              editable={status === 'ACTIVE'}
              autoCorrect={false}
              inputContainerStyle={styles.inputContainer}
              labelOffset={{
                x0: 0,
                y0: -7,
                x1: 0,
                y1: 2,
              }}
              maxLength={20}
            />
            <Item
              title="users_location_label"
              value={location}
              loading={locationLoading || devLoading}
              isFirst
              isLast
              containerStyle={styles.itemSpacing}
            />

            <InputWithTags
              label="cash_register_business_code"
              value={businessCode}
              onChangeText={setBusinessCode}
              autoCorrect={false}
              autoCapitalize="none"
              disabled
              inputContainerStyle={styles.inputContainer}
              labelOffset={{
                x0: 0,
                y0: -7,
                x1: 0,
                y1: 2,
              }}
              maxLength={20}
            />
            <RNText
              onPress={() => Linking.openURL('https://efiskalizimi-app.tatime.gov.al/self-care')}
              style={styles.multiText}
              allowFontScaling={false}
            >
              <RNText>{translate('cash_register_business_code1')}</RNText>
              <RNText style={{ color: colors.secondaryAccent }}>{translate('cash_register_business_code2')}</RNText>
              <RNText>{translate('cash_register_business_code3')}</RNText>
              <RNText style={styles.italicText}>{translate('cash_register_business_code4_1')}</RNText>
              <RNText>{translate('cash_register_business_code5')}</RNText>
            </RNText>

            <Text i18nKey="cash_register_about_label" align="left" />
            <View>
              <Item
                title="cash_register_device_name"
                value={deviceData?.deviceName}
                loading={devLoading}
                isFirst={true}
                itemStyle={styles.aboutItem}
              />
              <Item
                title="cash_register_device_type"
                value={
                  deviceData?.isTablet
                    ? 'cash_register_tablet_label'
                    : 'cash_register_mobile_label'
                }
                loading={devLoading}
                itemStyle={styles.aboutItem}
              />
              <Item
                title="cash_register_device_model"
                value={deviceData?.model}
                loading={devLoading}
                itemStyle={styles.aboutItem}
              />
              <Item
                title="cash_register_operating_system"
                value={deviceData?.os}
                loading={devLoading}
                itemStyle={styles.aboutItem}
              />
              <Item
                title="cash_register_device_id"
                value={deviceData?.deviceId}
                loading={devLoading}
                isLast={true}
                itemStyle={styles.aboutItem}
              />
            </View>
            <View style={styles.invoiceNrWrapper}>
              <Item
                title="cash_register_cash_invoice_nr"
                value={deviceData?.totalCashInvoices || deviceData?.totalCashInvoices === 0 ? deviceData?.totalCashInvoices + '' : ''}
                loading={devLoading}
                isFirst={true}
                itemStyle={styles.aboutItem}
              />
              <Item
                title="cash_register_noncash_invoice_nr"
                value={deviceData?.totalNoncashInvoices || deviceData?.totalNoncashInvoices === 0 ? deviceData?.totalNoncashInvoices + '' : ''}
                isLast={true}
                loading={devLoading}
                itemStyle={styles.aboutItem}
              />
            </View>

            <Item
              title="cash_register_tcr_label"
              value={deviceData?.TCRCode}
              loading={devLoading}
              isFirst
              isLast
              containerStyle={styles.itemSpacing}
            />
            {deviceData?.TCRCode ? <Button
              title={'cash_register_remove_tcr_button'}
              variant={devLoading ? 'disabled' : 'secondary'}
              disabled={devLoading}
              style={styles.removeTcrButton}
              onPress={() => setTcrModalVisible(true)}
            /> : null}
            <View style={styles.accessWrapper}>
              <Text i18nKey="cash_register_access_label" style={styles.accessLabel} />
              <View style={styles.firstAccessItem}>
                <TouchableOpacity onPress={() => setWebAccess(!webAccess)} style={styles.touchable} activeOpacity={0.5}>
                  <View style={styles.textContainer}>
                    <Text i18nKey="cash_register_web_access_label" numberOfLines={2} style={styles.name} />
                  </View>
                  <View pointerEvents="none" style={styles.radioInput}>
                    {devLoading ? (
                      <ContentLoader
                        speed={0.7}
                        height={25}
                        width={100}
                        style={Object.assign({ borderRadius: 15 })}
                        backgroundColor="#e2e2e2"
                        foregroundColor="#ecebeb">
                        <Rect x="65" y="1" rx="10" ry="10" width={35} height="20" />
                      </ContentLoader>
                    ) : (
                      <Switch
                        value={webAccess}
                        onPress={() => setWebAccess(!webAccess)}
                        color={colors.secondaryAccent}
                      />
                    )}

                  </View>
                </TouchableOpacity>
              </View>
              <View style={styles.secondAccessItem}>
                <TouchableOpacity onPress={() => setApiAccess(!apiAccess)} style={styles.touchable} activeOpacity={0.5}>
                  <View style={styles.textContainer}>
                    <Text i18nKey="cash_register_api_access_label" numberOfLines={2} style={styles.name} />
                  </View>
                  <View pointerEvents="none" style={styles.radioInput}>
                    {devLoading ? (
                      <ContentLoader
                        speed={0.7}
                        height={25}
                        width={100}
                        style={Object.assign({ borderRadius: 15 })}
                        backgroundColor="#e2e2e2"
                        foregroundColor="#ecebeb">
                        <Rect x="65" y="1" rx="10" ry="10" width={35} height="20" />
                      </ContentLoader>
                    ) : (
                      <Switch
                        value={apiAccess}
                        onPress={() => setApiAccess(!apiAccess)}
                        color={colors.secondaryAccent}
                      />
                    )}
                  </View>
                </TouchableOpacity>
              </View>
            </View>
            <Button
              title="cash_register_add_employee"
              icon={images.plus}
              variant={status === 'INACTIVE' ? 'disabled' : 'secondary'}
              onPress={() =>
                navigate('SelectEmployee', {
                  onSave: addEmployees,
                  preSelectedEmployees: employees,
                })
              }
            />
            <Text
              weight="light"
              size="footnote"
              align="center"
              i18nKey="cash_register_add_employee_message"
              style={styles.spacing}
            />
          </>
        }
        ListFooterComponent={
          <>
            <Button
              title={deviceType?.[status]?.primaryButtonTitle}
              disabled={disabled && status === 'ACTIVE'}
              variant={
                status === 'INACTIVE'
                  ? 'secondary'
                  : disabled
                    ? 'disabled'
                    : 'active'
              }
              onPress={onButtonPress}
              loader={updating || devLoading}
              loaderComponent={
                <Loader size={32} source={animations.vfLoaderWhite} />
              }
              style={styles.verticalMediumSpacing}
            />
            <Button
              title={deviceType?.[status]?.secondaryButtonTitle}
              backgroundColor={colors.white}
              titleStyle={{ color: deviceType?.[status]?.titleColor }}
              onPress={onSecondButtonPress}
              loader={deleting || devLoading}
              loaderComponent={
                <Loader size={32} source={animations.vfLoaderRed} />
              }
            />
          </>
        }
        renderItem={(rowData, rowMap) => {
          const { item, index } = rowData
          let isFirst = false,
            isLast = false
          if (index === 0) {
            isFirst = true
          }
          if (index === employees?.length - 1) {
            isLast = true
          }
          return (
            <SwipeRow
              key={`item-${index}`}
              disableRightSwipe
              rightOpenValue={-100}
              closeOnRowPress
              restSpeedThreshold={0.004}
              recalculateHiddenLayout
              disableLeftSwipe={status === 'INACTIVE'}
              useNativeDriver={true}>
              <HiddenItem
                key={item?.id}
                needsConfirmation={needsConfirmation}
                isFirst={isFirst}
                isLast={isLast}
                onPress={() => openRow(rowMap, 'row-' + index)}
                loading={updating}
                onConfirm={() => {
                  const newEmployees = employees?.filter(
                    (employee) => employee.id !== item?.id,
                  )
                  updateDevice({
                    variables: {
                      id: cashId,
                      employeeToDelete: [item?.id],
                    },
                  })
                    .then(() => {
                      closeRow(rowMap, 'row-' + index)
                      setEmployees(newEmployees)
                      setDisabled(false)
                    })
                    .catch(() => {
                      closeRow(rowMap, 'row-' + index)
                      openModal(
                        'certificate_error_label',
                        'cash_register_delete_error',
                        [],
                        '',
                        undefined,
                        'cash_register_okay_button',
                        () => closeModal(),
                      )
                    })
                }}
                onCancel={() => closeRow(rowMap, 'row-' + index)}
              />
              <Employee
                key={item?.id}
                item={item}
                isFirst={isFirst}
                isLast={isLast}
              />
            </SwipeRow>
          )
        }}
        refreshing={false}
      />
      <ErrorModal
        isVisible={modal?.isVisible}
        title={modal?.title}
        description={modal?.desc}
        placeHolders={modal.descPlaceholders}
        primaryText={modal?.primaryText}
        secondaryText={modal?.secondaryText}
        primaryAction={modal?.primaryAction}
        secondaryAction={modal?.secondaryAction}
        onBackdropPress={() => closeModal()}
        onBackButtonPress={() => closeModal()}
        onClosePress={() => closeModal()}
      />
      <Modal
        isVisible={tcrModalVisible}
        onBackdropPress={() => closeTcrModal()}
      >
        <ModalContent onClose={() => closeTcrModal()} contentContainerStyle={{ marginHorizontal: 0 }} >
          <ScrollView showsVerticalScrollIndicator={false} style={(isKeyboardVisible && Platform.OS === 'android') && { height: '97%' }} contentContainerStyle={[styles.scrollContainer]} >
            <KeyboardAvoidingView behavior={Platform.select({
              ios: 'padding',
              android: 'height',
            })} >
              <Text i18nKey={'cash_register_remove_tcr_header'} weight="bold" color={colors.red} size="h5" align="center" />
              <Text i18nKey={'cash_register_remove_tcr_descr'} weight="bold" color={colors.primaryText} />
              <Text i18nKey={'cash_register_remove_tcr_actual'} style={styles.subHeader} />
              <View style={styles.deviceInfoContainer}>
                <View style={styles.deviceInfoRow}>
                  <Text i18nKey={'cash_register_tcr_label'} color={colors.midGrey} />
                  <Text i18nKey={deviceData?.TCRCode} />
                </View>
                <View style={styles.separator} />
                <View style={styles.deviceInfoRow}>
                  <Text i18nKey={'cash_register_remove_tcr_users'} color={colors.midGrey} />
                  <Text i18nKey={devData?.getCashRegister?.employees.length} />
                </View>
                <View style={styles.separator} />
                <View style={styles.deviceInfoRow}>
                  <Text i18nKey={'cash_register_name_label'} color={colors.midGrey} />
                  <Text i18nKey={deviceData?.deviceName} />
                </View>
              </View>
              <Text i18nKey={'cash_register_remove_confirm_subheader'} style={styles.subHeader} />
              <InputWithTags
                label="cash_register_tcr_label"
                value={userTcrInput}
                onChangeText={setUserTcrInput}
                autoCapitalize="none"
                error={Boolean(isTcrError?.display)}
                autoCorrect={false}
                inputContainerStyle={styles.inputContainer}
                labelOffset={{
                  x0: 0,
                  y0: -7,
                  x1: 0,
                  y1: 2,
                }}
                maxLength={20}
              />
              {isTcrError?.display && <Text i18nKey={`${isTcrError?.message}`} size="footnote" style={{ color: colors.accent, marginTop: 5 }} />}
              <Text i18nKey={'cash_register_remove_confirm_delete'} style={styles.questionHeader} />

              <Button
                title={'cash_register_yes_button'}
                variant={userTcrInput ? 'active' : 'disabled'}
                style={{ marginVertical: 15 }}
                loader={TcrLoading}
                onPress={() => {
                  deleteTcr({
                    variables: {
                      locId: locationId,
                      cashRegisterIdd: deviceId,
                      cashRegisterId: deviceData?.id,
                      TCRCode: userTcrInput,
                    },
                  }).then(() => {
                    setTcrModalVisible(false)
                    goBack()
                    refetch()
                  }).catch((e) => {
                    setIsTcrError({ display: true, message: getErrorMessage(e) })
                  })
                }}
              />
              <Button
                title={'einvoice_cancel_modal_button'}
                variant="text"
                onPress={closeTcrModal}
              />
            </KeyboardAvoidingView>
          </ScrollView>
        </ModalContent>

      </Modal>
    </SafeAreaView >
  )
}
RegisterDetails.propTypes = {
  navigation: PropTypes.object,
  route: PropTypes.object,
}

export default RegisterDetails
