import React, { useState, useContext, useEffect, useRef } from 'react'
import { findNodeHandle, KeyboardAvoidingView, LayoutAnimation, Platform, TouchableOpacity, UIManager, View } from 'react-native'
import { useTheme } from 'react-native-themed-styles'
import PropTypes from 'prop-types'
import { useMutation } from '@apollo/client'
import { v1 as uuidv1 } from 'uuid'
import { useSelector } from 'react-redux'
import { SwipeListView } from 'react-native-swipe-list-view'

import {
  InputRow,
  Button,
  Text,
  QRScannerOrder,
  Loader,
  Icon,
  InputWithTags,
  BottomSheetSelector,
  Type,
} from '../../../../Components'

import Theme, { layoutAnimConfig } from '../../../../Themes/Theme'
import images, { animations } from '../../../../Themes/Images'
import { DETELE_VARIATION } from '../../NewItem.schema'
import { getProductCatalog } from '../../../ProductCatalog/ProductCatalog.Selectors'
import { useConfig } from '../../../../Utils'


import ContentManager from '../../../../Utils/ContentManager'


import themedStyles, {
  variationStyles,
  hiddenItemStyles,
  separatorStyles,
} from './ItemInfo.Styles'

const INCLUSION_TYPES = {
  inclusive: 'INCLUSIVE',
  additive: 'ADDITIVE',
}

const LABELS = {
  'TYPE_1': 'item_tax_type1_title',
  'TYPE_2': 'item_tax_type2_title',
  'TAX_FREE': 'item_tax_type3_title',
  'MARGIN_SCHEME': 'item_tax_type4_title',
}

const multilineInput = Platform.OS === 'android'

function Variation({ name, prices, onPress, isFirst, isLast }) {
  const [styles] = useTheme(variationStyles)

  return <View style={[styles.container, styles['firstItem_' + isFirst], styles['lastItem_' + isLast]]}>
    <TouchableOpacity onPress={onPress} style={styles.touchable}>
      <Text i18nKey={name} numberOfLines={2} style={styles.title} />
      {prices?.length === 1
        ? <Text i18nKey="discount_amount" placeHolders={[prices?.[0]?.amount, prices?.[0]?.currency]} style={styles.desc} />
        : <Text i18nKey="item_prices_label" translateOption={{ count: prices?.length || 0 }} style={styles.desc} />
      }
    </TouchableOpacity>
  </View>
}

Variation.propTypes = {
  name: PropTypes.string,
  prices: PropTypes.array,
  onPress: PropTypes.func,
  isFirst: PropTypes.bool,
  isLast: PropTypes.bool,
}

function HiddenItem({ needsConfirmation, onPress, onConfirm, onCancel, loading, isFirst, isLast }) {
  const [styles] = useTheme(hiddenItemStyles)
  const { colors } = useContext(Theme)

  return (
    <View style={[styles.wrapper, styles['firstItem_' + isFirst], styles['lastItem_' + isLast]]}>
      {loading
        ? <View style={styles.loader}>
          <Loader size={32} source={animations.vfLoaderWhite} />
        </View>
        : needsConfirmation
          ? <TouchableOpacity
            style={styles.option}
            onPress={onPress}
          >
            <Icon source={images.delete} color={colors.white} size={24} />
          </TouchableOpacity>
          : <View style={styles.optionFull}>
            <TouchableOpacity
              style={styles.confirm}
              onPress={onConfirm}
            >
              <Icon source={images.delete} color={colors.white} size={24} />
              <Text i18nKey="opened_orders_delete_confirm" color={colors.white} style={styles.confirmText} />
            </TouchableOpacity>
            <TouchableOpacity
              style={styles.cancel}
              onPress={onCancel}
            >
              <Icon source={images.closeIcon} color={colors.white} size={20} />
            </TouchableOpacity>
          </View>
      }
    </View>
  )
}
HiddenItem.propTypes = {
  needsConfirmation: PropTypes.bool,
  onPress: PropTypes.func,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  loading: PropTypes.bool,
  isFirst: PropTypes.bool,
  isLast: PropTypes.bool,
}

function Separator() {
  const [styles] = useTheme(separatorStyles)
  return <View style={styles.container} />
}

const qrImage = {
  true: images.closeIcon,
  false: images.scan,
}

function ItemInfo({
  item: itemInfo,
  setItem,
  selectedCategory,
  setSelectedCategory,
  selectedMeasurement,
  setSelectedMeasurement,
  selectedTax,
  selectedInclusionType,
  setSelectedTax,
  variations,
  setVariations,
  navigate,
  taxExemptionType,
}) {

  const { switchLocation: { deviceId, locationId:locId } } = useConfig()


  const [styles] = useTheme(themedStyles)

  const [prices, setPrices] = useState([])

  const { locationId } = useSelector(getProductCatalog)

  const [offset, setOffset] = useState(300)
  const keyboardRef = useRef()


  const [currencies] = useState([
    {
      code: 'Lek (ALL)',
      currencyCode: 'ALL',
    },
    {
      code: 'EUR (€)',
      currencyCode: 'EUR',
    },
    {
      code: 'USD ($)',
      currencyCode: 'USD',
    },
    {
      code: 'GBP (£)',
      currencyCode: 'GBP',
    },
  ])
  const [deleteVariation, { loading: deleting }] = useMutation(DETELE_VARIATION)
  const [needsConfirmation, setNeedForConfirmation] = useState(true)
  const [itemWidth, setItemWidth] = useState(0)

  const [currSearchValue, setCurrSearchValue] = useState('')
  const [currencyModal, setCurrencyModal] = useState({
    isVisible: false,
    data: [],
    title: '',
    placeholder: '',
    selected: undefined,
    select: () => { },
    Item: Type,
  })

  const closeModal = () => {
    setCurrencyModal(prev => ({ ...prev, isVisible: false }))
    setCurrSearchValue('')
    // setCurrModal(false)
  }

  const openCurrencySelector = () => {
    setCurrencyModal({
      isVisible: true,
      data: currencies,
      title: 'payment_currency_title',
      placeholder: 'payment_currency_placeholder',
      selected: {},
      select: (obj) => {
        setPrices((prev) => {
          const indexOfCurrency = prev?.findIndex(it => it.currency === obj.currencyCode)
          if (indexOfCurrency < 0) {
            prev = prev.concat([{
              id: 'new-' + uuidv1(),
              amount: '',
              currency: obj.currencyCode,
            }])
          }
          return [...prev]
        })
      },
      searchKey: 'code',
      Item: Type,
    })
  }

  useEffect(() => {
    if (variations?.length && !itemInfo?.id) {
      setPrices(variations?.[0]?.prices || [])
    } else if (variations?.length && Boolean(itemInfo?.id)) {
      const vars = variations?.[0]?.prices?.map((el, index) => {
        return {
          ...el,
          id: 'new-' + uuidv1(),
        }
      })
      setPrices(vars || [])
    }
  }, [variations, itemInfo])

  const [isScannerVisible, setScannerVisibility] = useState(false)
  const toggleScanner = () => {
    LayoutAnimation.configureNext(layoutAnimConfig)
    setScannerVisibility(prev => !prev)
  }

  const onBarcodeRead = event => {
    onValueChange('barcode', event.data)
    toggleScanner()
  }

  const onValueChange = (key, value) => {
    if (/(?=^[\w\s.]*$)(?=^[^\r\n|\r|\n]*$)/.test(value)) {
      setItem({ ...itemInfo, [key]: value })
    }
  }

  const replaceAt = (string, i, replacement) => {
    return string.substr(0, i) + replacement + string.substr(i + replacement.length)
  }

  const updateVariation = (i, value, key, item) => {
    let newString = value
    const indexOfComa = value.indexOf(',')
    if (indexOfComa >= 0) {
      newString = replaceAt(value, indexOfComa, '.')
    }
    setPrices((prev) => {
      prev[i].amount = newString || ''
      return [...prev]
    })
  }

  const onSave = (element, index) => {
    setVariations(prev => {
      if (index >= 0) {
        prev.splice(index, 1, element)
      } else {
        prev.push(element)
      }
      return [...prev]
    })
  }

  const editVariation = (element, index) => {
    if (element && index >= 0) {
      navigate('NewVariation', {
        index,
        variation: element,
        onSave,
      })
    } else {
      navigate('NewVariation', {
        onSave,
      })
    }
  }

  const openRow = (rowMap, id) => {
    deleteRow(rowMap, id)
    deletePriceRow(rowMap, id)
    setNeedForConfirmation(false)
    rowMap[id].manuallySwipeRow(-itemWidth)
  }

  const closeRow = (rowMap, id) => {
    rowMap[id].closeRow()
    setNeedForConfirmation(true)
  }

  const deleteRow = (rowMap, id) => {
    if (id?.startsWith('new-')) {
      setPrices(prev => [...prev?.filter(element => element.id !== id)])
      setVariations(prev => [...prev?.filter(element => element.id !== id)])
      closeRow(rowMap, id)
    } else {
      deleteVariation({
        variables: {
          locId: locId,
          cashRegisterId: deviceId,
          id: id,
          itemId: itemInfo?.id,
          locationId: locationId ? locationId : null,
        },
      }).then(() => {
        setVariations(prev => prev?.filter(element => element.id !== id))
      }).catch((e) => {
      }).then(() => {
        closeRow(rowMap, id)
      })
    }
  }

  const deletePriceRow = (rowMap, id) => {
    if (id?.startsWith('new-') && !itemInfo?.id) {
      setPrices(prev => [...prev?.filter(element => element.id !== id)])
      setVariations(prev => {
        const variation = { ...prev[0] }
        variation.prices = prices?.filter(element => element.id !== id)
        prev.splice(0, 1, variation)
        return [...prev]
      })
      closeRow(rowMap, id)
    } else if (id?.startsWith('new-') && Boolean(itemInfo?.id)) {
      setPrices(prev => prev?.filter(element => element.id !== id))
      setVariations(prev => {
        const variation = { ...prev[0] }
        variation.prices = prices?.filter(element => element.id !== id)
        prev.splice(0, 1, variation)
        return [...prev]
      })
      closeRow(rowMap, id)
    }
  }

  const renderItem = ({ item: element, index }) => (
    <Variation
      {...element}
      onPress={() => editVariation(element, index)}
      isFirst={index === 0}
      isLast={index === variations?.length - 1}
    />
  )

  const renderHiddenItem = ({ item: { id }, index }, rowMap) => (
    <HiddenItem
      needsConfirmation={needsConfirmation}
      onPress={() => openRow(rowMap, id)}
      onConfirm={() => deleteRow(rowMap, id)}
      onCancel={() => closeRow(rowMap, id)}
      loading={deleting}
      isFirst={index === 0}
      isLast={index === variations?.length - 1}
    />
  )

  const renderPriceItem = ({ item, index }) => {
    return (
      <View
        key={index + '1'}
        style={[styles.priceItem, styles['firstItem_' + (index === 0)], styles['lastItem_' + (index === prices?.length - 1)]]}>
        <InputRow
          label="item_price_label"
          inputType="numeric"
          inputLabel={item.currency}
          placeholder="0.00"
          value={item?.amount + ''}
          onChangeText={(value) => updateVariation(index, value, 'prices', item)}
          onBlur={() => {
            setVariations(prev => {
              const variation = { name: 'Regular', ...prev[0] }
              variation.prices = prices
              prev.splice(0, 1, variation)
              return [...prev]
            })
          }}
          style={styles.input}
        />
      </View>
    )
  }

  const renderPriceHiddenItem = ({ item, index }, rowMap) => (
    <HiddenItem
      needsConfirmation={needsConfirmation}
      onPress={() => openRow(rowMap, item.id)}
      onConfirm={() => deletePriceRow(rowMap, item.id)}
      onCancel={() => closeRow(rowMap, item.id)}
      isFirst={index === 0}
      isLast={index === prices?.length - 1}
    />
  )

  const measureView = (x, y, width, height, pageX, pageY) => setOffset(pageY)

  useEffect(() => {
    if (keyboardRef.current) {
      UIManager.measure(findNodeHandle(keyboardRef.current), measureView)
    }
  }, [keyboardRef])

  const keyExtractor = item => item?.id

  return (
    <>
      <KeyboardAvoidingView
        ref={(ref) => { keyboardRef.current = ref }}
        style={styles.scrollContainer}
        behavior={Platform.select({
          ios: 'padding',
          android: undefined,
        })}
        keyboardVerticalOffset={Platform.OS === 'ios' ? offset : undefined}
      >
        <SwipeListView
          keyExtractor={keyExtractor}
          data={variations?.length < 2 ? prices : variations}
          renderItem={variations?.length < 2 ? renderPriceItem : renderItem}
          renderHiddenItem={variations?.length < 2 ? renderPriceHiddenItem : renderHiddenItem}
          disableRightSwipe
          rightOpenValue={-100}
          onRowClose={() => setNeedForConfirmation(true)}
          setItemWidth={setItemWidth}
          ItemSeparatorComponent={Separator}
          contentContainerStyle={styles.container}
          showsVerticalScrollIndicator={false}
          ListHeaderComponent={<View style={styles.header}>
            <InputRow
              label="item_name_label"
              placeholder="item_name_placeholder"
              value={itemInfo?.name}
              multiline={multilineInput}
              maxLength={50}
              onChangeText={(value) => onValueChange('name', value)}
            />
            <InputRow
              label="item_category_label"
              linking={true}
              linkingLabel="item_choose_category_label"
              linkingValue={selectedCategory?.name}
              rightIcon={images.arrow_right}
              multiline={multilineInput}
              editable={false}
              onLinkPress={() => navigate('Categories', {
                selectable: true,
                preselectedCategory: selectedCategory,
                updateSelection: setSelectedCategory,
              })}
            />
            <InputRow
              label="item_barcode_label"
              linking={true}
              placeholder={'12345678'}
              value={itemInfo?.barcode}
              rightIcon={Platform.OS !== 'web' && qrImage[`${isScannerVisible}`]}
              rightIconStyle={styles.scanIcon}
              onLinkPress={() => toggleScanner()}
              onChangeText={value => onValueChange('barcode', value)}
            />
            <InputRow
              label="item_unit_type_label"
              linking={true}
              linkingLabel="item_choose_unit_label"
              linkingValue={selectedMeasurement?.name}
              editable={false}
              rightIcon={images.arrow_right}
              onLinkPress={() => navigate('Units', {
                selectable: true,
                preselectedUnit: itemInfo?.measurementUnit,
                updateSelection: setSelectedMeasurement,
              })}
              style={styles.inputWithoutSpace}
            // multiline={multilineInput}
            />
            <QRScannerOrder
              barcode
              onBarcodeRead={onBarcodeRead}
              visible={isScannerVisible}
              containerStyle={styles.scanner}
            />
            {variations?.length < 2
              ?
              <View style={styles.sectionTitleContainer}>
                <Text weight="light" i18nKey="item_price_inventory_section" />
                <Button
                  icon={images.plusIcon}
                  title="modifiers_button_new"
                  onPress={() => openCurrencySelector()}
                  variant="secondary"
                  style={styles.addNew}
                />
              </View> : <Text weight="light" style={styles.sectionTitle} i18nKey="item_variations_section" />}
          </View>}
          ListFooterComponent={<View>
            <Button
              icon={images.plusIcon}
              variant="secondary"
              onPress={() => navigate('NewVariation', { onSave })}
              style={styles.verticalSpacing}
              title="item_button_add_variation"
            />
            <InputRow
              label="item_vat_label"
              linking={true}
              linkingLabel="item_choose_tax_label"
              editable={false}
              linkingValue={selectedTax?.name ? selectedTax?.name + '\n' + (ContentManager.translate(LABELS[taxExemptionType] || selectedInclusionType)) : undefined}
              rightIcon={images.arrow_right}
              onLinkPress={() => navigate('SelectTax', {
                preselectedTax: selectedTax,
                preselectedInclusionType: selectedInclusionType,
                updateSelection: setSelectedTax,
                preselectedExemptionType: taxExemptionType,
              })}
              multiline={multilineInput}
            />
            <InputWithTags
              label="drawer_default_description"
              value={itemInfo?.description}
              onChangeText={(value) => onValueChange('description', value)}
              inputWrapperStyle={styles.smallVerticalSpacing}
              inputContainerStyle={styles.descriptionInput}
              labelOffset={{
                x0: 0,
                y0: -7,
                x1: 0,
                y1: 2,
              }}
              maxLength={150}
            />
          </View>}
        />
      </KeyboardAvoidingView>
      <BottomSheetSelector
        {...currencyModal}
        closeModal={closeModal}
        searchValue={currSearchValue}
        setSearchValue={setCurrSearchValue}
      />
    </>
  )
}

ItemInfo.defaultProps = {
  item: {},
  setItem: () => { },
  navigate: () => { },
}

ItemInfo.propTypes = {
  item: PropTypes.object,
  setItem: PropTypes.func,
  selectedCategory: PropTypes.object,
  setSelectedCategory: PropTypes.func,
  selectedMeasurement: PropTypes.object,
  setSelectedMeasurement: PropTypes.func,
  selectedTax: PropTypes.object,
  selectedInclusionType: PropTypes.oneOf(Object.values(INCLUSION_TYPES)),
  setSelectedTax: PropTypes.func,
  variations: PropTypes.array,
  setVariations: PropTypes.func,
  navigate: PropTypes.func,
  taxExemptionType: PropTypes.string,
}
export default ItemInfo
