import { Platform } from 'react-native'

import { styleSheetFactory } from '../../Themes/Colors'

const styles = styleSheetFactory(colors => ({
  container: {
    flex: 1,
    position: 'relative',
    backgroundColor: colors.background,
  },
  header: {
    height: 56,
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
    backgroundColor: colors.background,
  },
  headerLeftContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  headerRightContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    // marginRight: 10,
  },
  headerIcon: {
    marginHorizontal: 20,
  },
  headerStatusIcon: {
    marginRight: 15,
  },
  topMenuContainer: {
    flexDirection: 'row',
    position: 'absolute',
    top: 56,
    left: 0,
    right: 0,
    margin: 15,
    marginBottom: 0,
  },
  qrContainer: {
    marginTop: 80,
  },
  scene: {
    flex: 1,
    backgroundColor: colors.background,
  },
  tabContainerStyle: {
    backgroundColor: colors.tertiaryBackground,
    // elevation: 0,
    // shadowRadius: 0,
    // shadowOpacity: 0,
    // marginBottom: 0,
  },
  noTabContainerStyle: {
    backgroundColor: colors.tertiaryBackground,
    elevation: 0,
    shadowRadius: 0,
    shadowOpacity: 0,
    marginBottom: 0,
    height: 0,
    overflow: 'hidden',
  },
  indicatorStyle: {
    backgroundColor: colors.tabAccent,
  },
  label: {
    fontFamily: 'Vodafone Rg',
    textTransform: 'none',
    fontSize: Platform.OS === 'web' ? 15 : 13,
  },
  tabElements_scroll_true: {
    width: 'auto',
    minWidth: 100,
  },
  tabView: {
    backgroundColor: colors.background,
    paddingTop: 80,
    // width: width,
  },
  tabViewScan_true: {
    paddingTop: 0,
  },
  tabViewSearch_true: {
    paddingTop: 65,
  },
  searchWrapper: {
    flexGrow: 1,
    flexDirection: 'row',
    borderRadius: 7,
    overflow: 'hidden',
    backgroundColor: colors.tertiaryBackground,
  },
  searchContainer: {
    flexGrow: 1,
    flexDirection: 'row',
    backgroundColor: colors.tertiaryBackground,
    borderRadius: 7,
    overflow: 'hidden',
    alignItems: 'center',
  },
  searchIcon: {
    marginHorizontal: 10,
  },
  inputContainer: {
    flexGrow: 1,
  },
  searchInput: {
    flexGrow: 1,
    paddingLeft: 0,
    fontSize: 16,
    fontFamily: 'Vodafone Rg',
    color: colors.primaryText,
  },
  clearSearchContainer: {
    height: 50,
    aspectRatio: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  clearSearchTouchable: {
    backgroundColor: colors.background,
    height: 30,
    aspectRatio: 1,
    borderRadius: 15,
    justifyContent: 'center',
    alignItems: 'center',
  },
  scan: {
    height: 50,
    width: 50,
    backgroundColor: 'white',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: 15,
    borderRadius: 7,
  },
  bottomMenuContainer: {
    height: 80,
    paddingVertical: 15,
    paddingRight: 15,
    backgroundColor: colors.background,
    flexDirection: 'row',
    borderTopLeftRadius: 7,
    borderTopRightRadius: 7,
    alignItems: 'center',
  },
  chargeButton: {
    flexGrow: 1,
    margin: 0,
    marginLeft: 15,
  },

  // Cart option styles
  cartContainer: {
    height: 50,
    width: 50,
    backgroundColor: colors.tertiaryBackground,
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: 15,
    borderRadius: 7,
    position: 'relative',
  },
  cartStatusContainer: {
    minWidth: 16,
    height: 16,
    padding: 2,
    backgroundColor: colors.accent,
    position: 'absolute',
    top: 8,
    right: 8,
    borderRadius: 7.5,
    justifyContent: 'center',
    alignItems: 'center',
  },
  cartStatus: {
    fontSize: 12,
    lineHeight: 12,
    marginTop: 1,
  },
  shadow: {
    shadowColor: colors.black,
    shadowOffset: {
      width: 0,
      height: -1,
    },
    shadowOpacity: 0.16,
    shadowRadius: 6,
  },

  // Modal
  modalContent: {
    marginTop: 30,
  },
  modalCloseContainer: {
    position: 'absolute',
    top: 0,
    right: 0,
    height: 30,
    width: 75,
    borderTopRightRadius: 7,
    borderBottomLeftRadius: 7,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: colors.separator,
  },
  modalTitle: {
    marginVertical: 10,
  },
  modalDesc: {
    marginBottom: 15,
  },
  modalPrimaryButton: {
    width: '100%',
    marginVertical: 15,
  },
  modalSecondaryButton: {
    width: '100%',
  },
  keyboardWrapper: { flex: 1 },
  leftSpacing: { marginLeft: 15 },
}))

const gridItemStyles = styleSheetFactory(colors => ({
  container: (numCols = 1) => {
    return {
      flex: 1 / numCols,
      backgroundColor: colors.tertiaryBackground,
      borderRadius: 7,
      margin: 3,
      overflow: 'hidden',
      position: 'relative',
    }
  },
  touchable: {
    flexGrow: 1,
    flexDirection: 'column',
  },
  imageContainer: {
    height: 135,
    borderTopLeftRadius: 7,
    borderTopRightRadius: 7,
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
    flexShrink: 0,
  },
  image: {
    height: '70%',
    aspectRatio: 1,
    width: 'auto',
  },
  infoContainer: {
    flexGrow: 1,
    padding: 15,
    justifyContent: 'space-between',
  },
  priceContainer: {
    flexDirection: 'row',
    alignItems: 'baseline',
  },
}))

const cardItemStyles = styleSheetFactory(colors => ({
  container: (numCols = 1) => {
    return {
      flex: 1 / numCols,
      backgroundColor: colors.tertiaryBackground,
      borderRadius: 7,
      margin: 3,
      overflow: 'hidden',
      position: 'relative',
    }
  },
  touchable: {
    flexGrow: 1,
    flexDirection: 'column',
  },
  imageContainer: {
    height: 170,
    borderTopLeftRadius: 7,
    borderTopRightRadius: 7,
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
    flexShrink: 0,
  },
  image: {
    height: '70%',
    aspectRatio: 1,
    width: 'auto',
  },
  infoContainer: {
    flexGrow: 1,
    padding: 15,
    justifyContent: 'space-between',
  },
  priceContainer: {
    flexDirection: 'row',
    alignItems: 'baseline',
  },
}))

const listItemStyles = styleSheetFactory(colors => ({
  container: (numCols = 1) => {
    return {
      flex: 1 / numCols,
      height: 70,
      backgroundColor: colors.tertiaryBackground,
      borderRadius: 7,
      margin: 3,
      overflow: 'hidden',
      position: 'relative',
    }
  },
  touchable: {
    flexGrow: 1,
    flexDirection: 'row',
  },
  imageContainer: {
    width: 70,
    height: 70,
    justifyContent: 'center',
    alignItems: 'center',
  },
  image: {
    height: 55,
    aspectRatio: 1,
  },
  infoContainer: {
    flexGrow: 1,
    flexShrink: 1,
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
    padding: 15,
    paddingLeft: 5,
  },
  priceContainer: {
    flexDirection: 'column',
    alignItems: 'baseline',
    flexShrink: 0,
    marginLeft: 10,
  },
  descContainer: {
    flexGrow: 1,
    flexShrink: 1,
  },
}))


const optionStyles = styleSheetFactory(colors => ({
  container: {
    height: 50,
    width: 50,
    backgroundColor: colors.tertiaryBackground,
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: 15,
    borderRadius: 7,
    position: 'relative',
  },
  statusContainer: {
    minWidth: 16,
    height: 16,
    padding: 2,
    backgroundColor: colors.accent,
    position: 'absolute',
    top: 8,
    right: 8,
    borderRadius: 7.5,
    justifyContent: 'center',
    alignItems: 'center',
  },
  status: {
    marginTop: Platform.OS === 'android' ? 2 : 1,
    fontSize: 12,
    lineHeight: 12,
  },
}))

const headerStatusIconStyles = styleSheetFactory(colors => ({
  container: {
    marginLeft: 15,
    position: 'relative',
  },
  statusContainer: {
    minWidth: 13,
    height: 13,
    padding: 2,
    backgroundColor: colors.accent,
    position: 'absolute',
    top: 2,
    right: 0,
    borderRadius: 6.5,
    justifyContent: 'center',
    alignItems: 'center',
  },
  status: {
    fontSize: 10,
    lineHeight: 12,
  },
}))

const separatorStyles = styleSheetFactory(colors => ({
  separatorContainer: {
    height: 1,
    backgroundColor: colors.tertiaryBackground,
  },
  separator: {
    height: 1,
    flex: 1,
    backgroundColor: colors.separator,
    marginLeft: 70,
  },
}))

const listEmptyStyles = styleSheetFactory(colors => ({
  container: {
    backgroundColor: colors.tertiaryBackground,
    padding: 15,
    minHeight: 50,
    borderRadius: 7,
    alignItems: 'center',
  },
  button: {
    flexGrow: 1,
    margin: 0,
    marginLeft: 15,
  },
}))

const listStyles = styleSheetFactory(colors => ({
  listContainer: {
    position: 'relative',
    flexGrow: 1,
    flex: 1,
  },
  contentContainer: {
    borderRadius: 7,
    overflow: 'hidden',
    paddingBottom: 80,
    marginVertical: 15,
    paddingHorizontal: 12,
  },
  fab: {
    position: 'absolute',
    bottom: 30,
    right: 15,
    height: 50,
    width: 50,
    borderRadius: 25,
    backgroundColor: colors.tertiaryAccent,
    justifyContent: 'center',
    alignItems: 'center',
    shadowColor: colors.black,
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.22,
    shadowRadius: 2.22,
    elevation: 3,
  },
  fabIcon: {
    transform: [{ rotate: '-90deg' }],
  },
  footer: {
    height: 48,
    justifyContent: 'center',
    alignItems: 'center',
  },
}))

const tabBarStyles = styleSheetFactory(colors => ({
  tabbar: {
    backgroundColor: colors.white,
    elevation: 0,
    shadowColor: 'transparent',
    shadowOpacity: 0,
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowRadius: 0,
    marginBottom: 20,
    marginTop: 16,
  },
  labelContainer: {
    minWidth: 100,
    paddingVertical: 6,
    paddingHorizontal: 16,
    marginHorizontal: 4,
    borderRadius: 25,
    justifyContent: 'center',
    alignItems: 'center',
    borderWidth: 2,
  },
  tabStyle: {
    width: 'auto',
    backgroundColor: colors.white,
    padding: 0,
    overflow: 'hidden',
  },
}))


const searchlistStyles = styleSheetFactory(colors => ({
  container: {
    marginTop: 80,
  },
  content: {
    marginTop: 0,
  },
}))


export {
  cardItemStyles,
  gridItemStyles,
  listItemStyles,
  optionStyles,
  headerStatusIconStyles,
  separatorStyles,
  listEmptyStyles,
  listStyles,
  tabBarStyles,
  searchlistStyles,
}
export default styles
