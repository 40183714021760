import React from 'react'

import {View} from 'react-native'

import ContentLoader, { Rect } from 'react-content-loader/native'

import Styles from './ItemListLoader.Styles'

export default function ItemsListLoader(props) {

  const Loader = () => (
    <ContentLoader
      speed={0.7}
      height={60}
      style={Styles.item}
      backgroundColor="#e2e2e2"
      foregroundColor="#ecebeb">
      <Rect x="80" y="10" rx="3" ry="3" width="250" height="12" />
      <Rect x="80" y="32" rx="3" ry="3" width="150" height="8" />
      <Rect x="-7" y="-2" rx="0" ry="0" width="69" height="60" />
    </ContentLoader>
  )

  return (
    <View style={Styles.container}>
      <Loader />
      <Loader />
      <Loader />
      <Loader />
      <Loader />
      <Loader />
      <Loader />
    </View>
  )
}
