import React, { useState, useEffect } from 'react'
import {
  SafeAreaView,
} from 'react-native'
import { useTheme } from 'react-native-themed-styles'

import { useQuery } from '@apollo/client'
import PropTypes from 'prop-types'
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs'

import {
  Header, Tabs,
} from '../../Components'

import ContentManager from '../../Utils/ContentManager'

import Info from './Info'
import Devices from './Devices'
import BusinessUnitInfo from './BusinessUnitInfo'

import { GET_LOCATION } from './Location.Schema'
import themedStyles from './Location.Styles'
import images from '../../Themes/Images'

const Tab = createMaterialTopTabNavigator()
function Locations({
  route: {
    params: {
      id,
      businessName,
      address,
      logoUrl,
      isVirtual,
      refetch,
      isBusinessUnitList = {},
    } = {},
  } = {},
  navigation: { goBack, navigate },
}) {
  const { translate } = ContentManager
  const [styles] = useTheme(themedStyles)
  const [location, setLocation] = useState({ id, businessName, address, logoUrl })
  const { data: details, loading } = useQuery(GET_LOCATION, { variables: { id } })

  useEffect(() => {
    const locationData = details?.getLocation
    if (locationData) {
      const locationCoordinates = locationData?.coordinates
      setLocation((prev) => {
        return {
          ...prev,
          ...locationData,
          coordinates: {
            latitude: locationCoordinates?.latitude || 0,
            longitude: locationCoordinates?.longitude || 0,
          },
        }
      })
    }
  }, [details])

  const renderTab = () => {
    if (isVirtual) {
      return <Info isVirtual={isVirtual} location={location} setLocation={setLocation} goBack={goBack} refetch={refetch} />
    }

    else {
      return (
        <Tabs style={styles.tabView} initialRouteName={translate('einvoice_my_invoices_tab')}>
          <Tab.Screen key={'tab-info'} name={translate('location_info_tab_label')}>
            {(props) => (isBusinessUnitList ? <BusinessUnitInfo location={location} setLocation={setLocation} goBack={goBack} navigate={navigate} loading={loading} /> : <Info location={location} setLocation={setLocation} goBack={goBack} isVirtual={isVirtual} />)}
          </Tab.Screen>
          <Tab.Screen key={'tab-devices'} name={translate('cash_register_user_tab')}>
            {(props) => (<Devices id={id} navigate={navigate} />)}
          </Tab.Screen>
        </Tabs>
      )
    }
  }


  return (
    <SafeAreaView style={styles.container}>
      <Header
        title={location.businessName || 'header_locations'}
        image={images.back}
        onPress={() => goBack()}
      />
      {renderTab()}
    </SafeAreaView>
  )
}

Locations.propTypes = {
  route: PropTypes.object,
  navigation: PropTypes.object,
}


export default Locations
