import React from 'react'
import { ScrollView } from 'react-native'
import { useTheme } from 'react-native-themed-styles'
import { useSelector } from 'react-redux'

import { getSupportInitData } from '../../../../Payment.Selectors'

import { Section } from '../../../../../../Components'

import themedStyles from './BasicInfo.Styles'


function BasicInfo() {
    const [styles] = useTheme(themedStyles)
    const data = useSelector(getSupportInitData)

    const basicSection = [
        {
            title: 'payment_supporting_invoice_title',
        },
        {
            label: 'supporting_invoice_invoice_type_label',
            value: data?.type?.description,
        },
        {
            label: 'supporting_invoice_transaction_type_label',
            value: data?.transaction?.code,
        },
    ]

    return (
        <ScrollView
            style={styles.container}
            contentContainerStyle={styles.content}
            showsVerticalScrollIndicator={false}
        >
            <Section data={basicSection} />
        </ScrollView>
    )
}

export default BasicInfo
