import { styleSheetFactory } from '../../../../../Themes/Colors'

const styles = styleSheetFactory(colors => ({
  sectionTitle: {
    marginBottom: 5,
  },
  topContent: {
    flex: 1,
    marginRight: 7,
  },
}))

export default styles
