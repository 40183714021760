import moment from 'moment-timezone'

const getFormatedTransportInfo = ({
  vehOwnership: { code: vehOwnership } = {},
  startPoint: { code: startPoint } = {},
  destinPoint: { code: destinPoint } = {},
  ...rest
} = {}) => ({
  vehOwnership,
  startPoint,
  destinPoint,
  ...rest,
})

const getWTNSaveInput = ({
  invoiceId,
  initial: { type: invoice, transaction } = {},
  transportInfo,
  total: valueOfGoods,
  ...allInvoice
} = {}) => {

  const note = invoiceId ? [`id=${invoiceId}`] : null
  const {
      startDateTime,
      destinDateTime,
      ...rest
  } = getFormatedTransportInfo(transportInfo)
  return {
    note,
    ...allInvoice,
    ...rest,
    startDateTime: moment(startDateTime).tz('Europe/Tirane').format(),
    destinDateTime: moment(destinDateTime).tz('Europe/Tirane').format(),
    type: invoice.type,
    transaction: transaction.type,
    valueOfGoods,
  }
}

export { getFormatedTransportInfo, getWTNSaveInput }
