/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import {
  SafeAreaView,
  Keyboard,
  Platform,
  LayoutAnimation,
} from 'react-native'
import { useMutation, useLazyQuery } from '@apollo/client'
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import {
  Button,
  Header,
  Modal,
  ImageColorPicker,
  Loader,
  Icon,
  Text,
  ModalContent,
  Tabs,
} from '../../Components'

import ContentManager from '../../Utils/ContentManager'
import { getErrorMessage, useConfig } from '../../Utils'
import useTaxDetails from '../../Utils/Tax'
import { getProductCatalog } from '../ProductCatalog/ProductCatalog.Selectors'

import { ItemInfo, Modifiers, Discounts } from './Tabs'

import { EDIT_ITEM, GET_ITEM, CREATE_ITEM } from './NewItem.schema'

import { colors, animations } from '../../Themes'
import styles from './NewItem.Styles'

import images from '../../Themes/Images'

const Tab = createMaterialTopTabNavigator()

const keyBoardEvents = {
  ios: {
    show: 'keyboardWillShow',
    hide: 'keyboardWillHide',
  },
  android: {
    show: 'keyboardDidShow',
    hide: 'keyboardDidHide',
  },
  web: {},
}

function NewItem({
  route: { params: { item: selectedItem, onSave = () => { } } = {} } = {},
  navigation: { navigate, goBack, getState },
}) {

  const { switchLocation: { deviceId, locationId: locId } } = useConfig()

  const { taxDetails } = useTaxDetails()
  const stateOfStack = getState(state => state)

  const [image, setImage] = useState({ uri: selectedItem?.imageUrl })
  const [labelColor, setLabelColor] = useState(selectedItem?.labelColor)
  const [item, setItem] = useState({ ...(selectedItem || { ...taxDetails }) })
  const [variations, setVariations] = useState([])
  const [prevDiscounts, setPrevDiscounsts] = useState([])
  const [discounts, setDiscounts] = useState([])
  const [prevModifiers, setPrevModifiers] = useState([])
  const [modifiers, setModifiers] = useState([])

  const { locationId, businessName, address } = useSelector(getProductCatalog)

  const [saving, setSaving] = useState(false)

  const [editItem, { }] = useMutation(EDIT_ITEM)
  const [createItem, { }] = useMutation(CREATE_ITEM)
  const [getItem, { error, data }] = useLazyQuery(GET_ITEM)

  const [modal, setModal] = useState({
    isVisible: false,
    icon: images.warningIcon,
    title: '',
    desc: '',
  })
  const openModal = (
    desc = '',
    title = 'unit_general_error',
    icon = images.warningIcon,
  ) => {
    setModal({
      isVisible: true,
      icon: icon,
      title: title,
      desc: desc,
    })
  }
  const closeModal = () => {
    setModal({
      isVisible: false,
      icon: images.delete,
      title: '',
      desc: '',
    })
  }

  useEffect(() => {
    const id = selectedItem?.id
    if (id) {
      getItem({
        variables: {
          locId: locId,
          cashRegisterId: deviceId,
          id,
          locationId: locationId ? locationId : null,
        },
      })
    }
  }, [])

  const updateCategory = (category) => {
    if (category?.id) {
      setItem((prev) => {
        return {
          ...prev,
          categoryId: category?.id,
          category,
        }
      })
    }
  }

  const updateMeasurement = (measurementUnit) => {
    if (measurementUnit?.id) {
      setItem((prev) => {
        return {
          ...prev,
          measurementUnitId: measurementUnit?.id,
          measurementUnit,
        }
      })
    }
  }

  const updateTax = (taxDetail) => {
    const { tax, taxInclusionType, taxExemptionType } = taxDetail || {}
    if (tax?.id) {
      setItem((prev) => {
        return {
          ...prev,
          taxId: tax?.id,
          tax,
          taxInclusionType,
          taxExemptionType,
        }
      })
    }
  }

  const updateDiscounts = (newDiscounts) => {
    setDiscounts([...newDiscounts])
  }
  const updateModifiers = (newModifiers) => {
    setModifiers([...newModifiers])
  }


  useEffect(() => {
    if (data) {
      const {
        item: updatedItem,
        listItemVariations,
        listDiscounts,
        listModifierLists,
      } = data.getItem || {}

      if (updatedItem?.id) {
        setLabelColor(item?.labelColor)
        setImage({ uri: updatedItem?.imageUrl })
        setItem(updatedItem)
        if (listItemVariations?.itemVariations) {
          setVariations([...listItemVariations.itemVariations])
        }
        if (listDiscounts?.discounts) {
          setPrevDiscounsts([...listDiscounts.discounts])
          updateDiscounts(listDiscounts.discounts)
        }
        if (listModifierLists?.modifierLists) {
          setPrevModifiers([...listModifierLists.modifierLists])
          updateModifiers(listModifierLists.modifierLists)
        }
      }
    }
  }, [data])

  useEffect(() => {
    if (error) {
      // set
    }
  }, [error])

  const saveItem = () => {
    setSaving(true)

    const newItem = { ...item, measurementUnitId: item?.measurementUnit?.id }

    const modifierIds = modifiers.map((element) => element.id)
    const discountIds = discounts.map((element) => element.id)

    const itemVariations = variations?.map((element) => {
      const prices = element?.prices?.filter(el => el?.amount !== '')?.map((price) => {
        if (price?.id?.startsWith('new-')) {
          delete price.id
        }
        return price
      })
      if (element?.id?.startsWith('new-')) {
        delete element.id
      }
      return { ...element, prices }
    })

    const keysToDelete = [
      'category',
      'tax',
      'labelColor',
      'imageUrl',
    ]
    keysToDelete.forEach((key) => {
      delete newItem[key]
    })

    const imageOrColor = {}
    if (image?.base64) {
      imageOrColor.base64Image = image.base64
      imageOrColor.labelColor = null
    } else if (labelColor) {
      imageOrColor.labelColor = labelColor
      imageOrColor.base64Image = null
    }

    if (selectedItem) {
      const prevModifierIds = prevModifiers.map((element) => element.id)
      const prevDiscountIds = prevDiscounts.map((element) => element.id)

      const modifierListsToEnable = modifierIds?.filter(
        (element) => !prevModifierIds.includes(element),
      )
      const modifierListsToDelete = prevModifierIds?.filter(
        (element) => !modifierIds.includes(element),
      )
      const discountsToEnable = discountIds?.filter(
        (element) => !prevDiscountIds.includes(element),
      )
      const discountsToDelete = prevDiscountIds?.filter(
        (element) => !discountIds.includes(element),
      )

      delete newItem.barcode

      editItem({
        variables: {
          ...newItem,
          modifierListsToEnable,
          modifierListsToDelete,
          discountsToEnable,
          locationId: locationId ? locationId : null,
          locId: locId,
          cashRegisterId: deviceId,
          discountsToDelete,
          itemVariations,
          ...imageOrColor,
        },
      })
        .then(() => {
          // displayToast('item_update_success')
          setSaving(false)
          onSave()
          goBack()
        })
        .catch((e) => {
          openModal(getErrorMessage(e))
          setSaving(false)
        })
    } else {
      createItem({
        variables: {
          ...newItem,
          modifierListsToEnable: modifierIds,
          discountsToEnable: discountIds,
          locationId: locationId ? locationId : null,
          locId: locId,
          cashRegisterId: deviceId,
          itemVariations,
          ...imageOrColor,
        },
      })
        .then(() => {
          // displayToast('item_add_success')
          setSaving(false)
          onSave()
          goBack()
        })
        .catch((e) => {
          openModal(getErrorMessage(e))
          setSaving(false)
        })
    }
  }

  const translate = (key) => {
    return ContentManager.translate(key)
  }

  const [keboarvVisibleProps, setKeboarvVisibleProps] = useState({
    imageColorHeight: 150,
    saveButtonStyle: styles.button,
  })

  useEffect(() => {
    if (Platform.OS === 'android') {
      const showKeyboard = Keyboard.addListener(keyBoardEvents[Platform.OS].show, keyboardDidShow)
      const hideKeyboard = Keyboard.addListener(keyBoardEvents[Platform.OS].hide, keyboardDidHide)
      return () => {
        showKeyboard.remove()
        hideKeyboard.remove()
      }
    }
  }, [])

  const keyboardDidShow = () => {
    // If the focused screen isn't NewItem , don't run the Animation so the state is changed properly with the render
    if (stateOfStack.routes[stateOfStack.index].name === 'NewItem') {
      LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut)
    }
    setKeboarvVisibleProps({
      imageColorHeight: 0,
      saveButtonStyle: { height: 0, paddingTop: 0, paddingBottom: 0, overflow: 'hidden' },
    })
  }
  const keyboardDidHide = () => {
    // If the focused screen isn't NewItem , don't run the Animation so the state is changed properly with the render
    if (stateOfStack.routes[stateOfStack.index].name === 'NewItem') {
      LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut)
    }
    setKeboarvVisibleProps({
      imageColorHeight: 150,
      saveButtonStyle: styles.button,
    }
    )
  }

  const renderTabs = () => (
    <Tabs
      style={styles.tabs}
      sceneContainerStyle={styles.sceneContainer}
      tabContainerStyle={styles.tabContainerStyle}
      screenOptions={{
        swipeEnabled: false,
      }}
    >
      <Tab.Screen key={'item-info'} name={translate('item_info_tab')}>
        {() => (
          <ItemInfo
            item={item}
            setItem={setItem}
            selectedCategory={item.category}
            setSelectedCategory={updateCategory}
            selectedMeasurement={item.measurementUnit}
            setSelectedMeasurement={updateMeasurement}
            selectedTax={item.tax}
            selectedInclusionType={item.taxInclusionType}
            setSelectedTax={updateTax}
            variations={variations}
            setVariations={setVariations}
            navigate={navigate}
            taxExemptionType={item?.taxExemptionType}
          />
        )}
      </Tab.Screen>
      <Tab.Screen
        key={'modifiers'}
        name={translate('item_label_modifiers')}>
        {() => (
          <Modifiers
            selectedModifiers={modifiers}
            updateSelection={updateModifiers}
            navigate={navigate}
          />
        )}
      </Tab.Screen>
      <Tab.Screen
        key={'discounts'}
        name={translate('item_label_discounts')}>
        {() => (
          <Discounts
            selectedDiscounts={discounts}
            updateSelection={updateDiscounts}
            navigate={navigate}
          />
        )}
      </Tab.Screen>
    </Tabs>
  )

  return (
    <>
      <SafeAreaView style={styles.container}>
        <Header
          title={selectedItem ? 'header_edit_item' : 'header_new_item'}
          customBusinessName={businessName}
          customAddress={address}
          image={images.closeIcon}
          onPress={goBack}
        />
        <ImageColorPicker
          selectedColor={labelColor}
          setSelectedColor={setLabelColor}
          selectedImage={image}
          setSelectedImage={setImage}
          style={{ height: keboarvVisibleProps.imageColorHeight }}
        />
        {renderTabs()}
        <Button
          title={'item_button_save'}
          style={keboarvVisibleProps.saveButtonStyle}
          variant={!item?.name || !item?.measurementUnit?.id || saving ? 'disabled' : 'active'}
          onPress={saveItem}
          loader={saving}
          loaderComponent={
            <Loader size={32} source={animations.vfLoaderThinDark} />
          }
        />
      </SafeAreaView>
      <Modal
        isVisible={modal.isVisible}
        onBackButtonPress={() => closeModal()}
        onBackdropPress={() => closeModal()}
        animationIn="fadeIn"
        animationOut="fadeOut">
        <ModalContent onClose={() => closeModal()}>
          <Icon source={modal.icon} style={styles.modalIcon} size={40} />
          <Text
            i18nKey={modal.title}
            color={colors.primaryText}
            size="h5"
            align="center"
            weight="bold"
            style={styles.modalTitle}
          />
          <Text i18nKey={modal.desc} align="center" />
        </ModalContent>
      </Modal>
    </>
  )
}

NewItem.propTypes = {
  route: PropTypes.object,
  navigation: PropTypes.object,
}

export default NewItem
