import moment from 'moment'

import { INF } from './Helpers'

const PAYMENT_METHOD = {
    BANKNOTE: 'payment_banknote_description',
    CARD: 'payment_card_description',
    CHECK: 'payment_check_description',
    SVOUCHER: 'payment_voucher_description',
    COMPANY: 'payment_company_description',
    ORDER: 'payment_order_description',
    ACCOUNT: 'payment_account_description',
    FACTORING: 'payment_factoring_description',
    COMPENSATION: 'payment_compensation_description',
    TRANSFER: 'payment_transfer_description',
    WAIVER: 'payment_waiver_description',
    KIND: 'payment_kind_description',
    OTHER: 'payment_other_description',

}

const ID_TYPES = {
    NUIS: 'receipt_nipt_label',
    ID: 'pay_in_out_id',
    PASS: 'customer_passport_number_label',
    VAT: 'customer_vat_number_label',
    TAX: 'customer_tax_number_label',
    SOC: 'customer_soc_number_label',
}

const convertReceipt = (
    transaction,
    cashBuyer,
    change,
    nipt,
    company,
    location,
    customer,
    translate,
    qr
) => {
    const {
        order,
        createdAt,
        receiptNumber,
        employee,
        invoiceType,
        FIC,
        IIC,
        invoiceNumber,
        softwareCode,
        operatorCode,
        tender,
        employeeDetails,
    } = transaction


    const totalMoney = tender?.additionalAmountMoney || tender?.amountMoney
    const businUnitCode = transaction?.location?.businUnitCode
    const orderSubType = order?.orderSubType?.split('_')?.[0] || null
    const currency = tender?.cashDetails?.currency || {}
    const supplyDateOrPeriod = order?.supplyDateOrPeriod || {}

    const printItems = () => {
        const l = (order?.lineItems || []).length
        let r = ''
        for (let i = 0; i < l; i++) {
            const item = order?.lineItems[i]
            const price = `${INF.format(item.totalMoney?.amount || 0)} ${item.totalMoney?.currency}`
            r += `
            <table style="table-layout:fixed; width:100%; border-bottom: 1px solid #ccc; padding:7px 10px; border-collapse:separate;">
      <tr>
          <td style="text-align:left; padding:0px 0px;font-size: 16px;">
          ${item.itemName}
          </td>
          <td  style="text-align:right; padding:0px 0px; font-size: 16px;">
          ${price}
          </td>
      </tr>
      <tr>
          <td  style="text-align:left; padding:0px 0px;" colspan="2">
              <div style="font-size: 12px; padding:1px 0;">${item.quantity || 0} ${item?.measurementUnitId ? translate(item?.measurementUnitId) : ''} X ${INF.format(item.basePriceMoney?.amount || 0,)} ${item.basePriceMoney?.currency}</div>`
            for (let j = 0; j < (item?.modifiers?.length || 0); j++) {
                const modifier = item?.modifiers[j]
                r += `<div  style="font-size: 12px;padding:1px 0;" >${modifier.name} (${INF.format(modifier.appliedMoney?.amount || 0)} ${modifier.appliedMoney?.currency})</div>`
            }

            for (let j = 0; j < (item?.appliedDiscounts?.length || 0); j++) {
                const discount = item?.appliedDiscounts[j]
                if (discount.percentage) {
                    r += ` <div   style="font-size: 12px;padding:1px 0;" >${discount.name} (${discount.percentage || 0}%)</div>`
                } else {
                    r += ` <div  style="font-size: 12px;padding:1px 0;" >${discount.name} (${INF.format(discount.appliedMoney?.amount || 0)} ${discount.appliedMoney?.currency})</div>`
                }
            }
            r += `</td>  
            </tr> 
            </table>
            `
        }
        return r
    }

    const printTaxes = () => {
        const l = (order?.orderGroupedTaxes || []).length
        let r = ''
        for (let i = 0; i < l; i++) {
            const t = order?.orderGroupedTaxes?.[i] || {}
            const p = `${INF.format(t.VATAmt?.amount || 0)} ${t.VATAmt?.currency}`
            r += ` <tr><td style="font-size: 13px; padding:3px 0">
                <b>${translate('receipt_tax')} (${t.VATRate}%) : </b> ${p}
            </td> </tr>`
        }
        return r
    }

    const printCustomer = () => {
        let r = ''
        if (Object.keys(customer).length > 0) {
            r += `<b style="font-size: 13px" >${orderSubType === 'SELF' ? translate('SELLER') : translate('BUYER')}</b>
                <div style = "font-size: 13px" > ${customer?.title}</div>
                    <div style="font-size: 13px">${customer?.address}</div>`
            if (customer?.nipt) {
                r += `<div style = "font-size: 13px" > ${translate('einvoice_buyertin_label')}: ${customer?.nipt}</div> `
            } else
                if (customer?.idName) {
                    r += `<div style = "font-size: 13px" > ${translate(ID_TYPES[customer?.idName])}: ${customer?.idValue}</div> `
                }
        }
        return r
    }

    const printFooter = () => {
        let r = ''
        if (Object.keys(customer).length > 0) {
            r += `<table style = "width:100%; padding:10px 10px;border-top:1px dashed black;" >
                <tr>
                    <td>
                        <div style="text-align:center;font-size: 14px; padding:0px">
                            ${translate('e_invoice_buyer_label')}
                        </div>
                        <div style="text-align:center;font-size: 12px; padding:0px">
                            ${translate('e_invoice_signature_field_label')}
                        </div>
                        <div style="text-align:center;padding:10px 0; border-bottom: 1px solid black;">
                        </div>
                    </td>
                    <td>
                        <div style="text-align:center;font-size: 14px; padding:0px">
                            ${translate('e_invoice_carrier_label')}
                        </div>
                        <div style="text-align:center;font-size: 12px; padding:0px">
                            ${translate('e_invoice_signature_field_label')}
                        </div>
                        <div style="text-align:center;padding:10px 0; border-bottom: 1px solid black;">
                        </div>
                    </td>
                    <td>
                        <div style="text-align:center;font-size: 14px; padding:0px">
                            ${translate('e_invoice_seller_label')}
                        </div>
                        <div style="text-align:center;font-size: 12px; padding:0px">
                            ${translate('e_invoice_signature_field_label')}
                        </div>
                        <div style="text-align:center;padding:10px 0; border-bottom: 1px solid black;">
                        </div>
                    </td>
                </tr>
        </table> `
        }
        return r
    }

    const printOtherCurrency = () => {
        const addAmount = tender?.additionalAmountMoney || tender?.amountMoney
        const totAmount = tender?.amountMoney
        let r = ''

        if (addAmount?.currency !== totAmount?.currency) {
            r += `<tr>
            <td style="text-align:left;font-size: 14px; padding:3px 0">
            ${translate('receipt_currency_rate_label')}:
            </td>
            <td style="text-align:right;font-size: 14px; padding:3px 0">
             ${currency?.exRate}
            </td>
        </tr>
    <tr>
            <td style="text-align:left;font-size: 14px; padding:3px 0">
            ${translate('receipt_foreign_currency_label')}  ${totAmount?.currency}:
            </td>
            <td style="text-align:right;font-size: 14px; padding:3px 0">
            ${INF.format(totAmount?.amount)} ${totAmount?.currency}
            </td>
        </tr>`
        }
        return r
    }
    const printDelivery = () => {
        let r = ''
        if (order?.shippingAddress) {
            r += `<table style = "table-layout:fixed; width:100%; padding:7px 10px;border-top: 1px dashed #ccc;" >
    <tr>
        <td "textAlign: 'left'; padding: '3px 0'; fontSize: '20px';">
        ${translate('e_invoice_remittances_label')}:
    </td>
                </tr >
                <tr>
                    <td style="text-align:left;font-size: 12px; padding:0px 0">
                    <b> ${translate('payment_delivery_receiver_label')}:</b> ${order?.shippingAddress?.externalId ? order?.shippingAddress?.externalId : ''}
                    </td>
                </tr>
                <tr>
                <td style="text-align:left;font-size: 12px; padding:0px 0">
                <b> ${translate('supporting_invoice_address_label')}:</b> 
                ${order?.shippingAddress?.addressLine1 || ''} ${order?.shippingAddress?.locality || ''} ${order?.shippingAddress?.country || ''}
                </td>
            </tr>
            </table> `
        }
        return r
    }

    const printBankAccount = () => {
        let r = ''
        if (order?.bankAccount) {
            r += `            <table style = "table-layout:fixed; width:100%; padding:7px 10px;border-top: 1px dashed #ccc;" >
    <tr>
        <td "textAlign: 'left'; padding: '3px 0'; fontSize: '20px';">
        ${translate('receipt_payment_info_label')}:
    </td>
                </tr >
                <tr>
                    <td style="text-align:left;font-size: 12px; padding:0px 0">
                    <b> ${translate('receipt_payment_name_label')}:</b> ${order?.bankAccount?.holderName ? order?.bankAccount?.holderName : ''}
                    </td>
                </tr>
                <tr>
                <td style="text-align:left;font-size: 12px; padding:0px 0">
                <b> ${translate('receipt_payment_iban_label')}:</b> ${order?.bankAccount?.iban ? order?.bankAccount?.iban : ''}
                </td>
            </tr>
            <tr>
            <td style="text-align:left;font-size: 12px; padding:0px 0">
            <b> ${translate('receipt_payment_swift_label')}:</b> ${order?.bankAccount?.swiftCode ? order?.bankAccount?.swiftCode : ''}
            </td>
        </tr>
            </table> `
        }
        return r
    }

    const PAYMENT_TYPE = {
        NONCASH: {
            null: {
                title: 'receipt_invoice_type',
                description: translate('receipt_label_type_noncash'),
            },
            EXPORT: {
                title: 'receipt_invoice_type',
                description: translate('receipt_label_type_export'),
            },
            SELF: {
                title: 'receipt_invoice_type',
                description: translate('receipt_label_type_reverse_charge'),
            },
        },
        CASH: {
            null: {
                title: 'receipt_payment_type_cash',
                description: `${INF.format(cashBuyer)} ${totalMoney?.currency}`,
            },
            SELF: {
                title: 'receipt_invoice_type',
                description: translate('receipt_label_type_reverse_charge'),
            },
        },
    }

    return `<html >

    <head>
        <title>Invoice</title>
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
            <link rel="preconnect" href="https://fonts.googleapis.com">
                <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
                    <link href="https://fonts.googleapis.com/css2?family=Roboto:wght@100;400;500;700&display=swap" rel="stylesheet">
                        <style>
                            body {
                                font - family: 'Roboto', sans-serif;
            }
                        </style>
                    </head>

                    <body style="margin: 0 !important;padding: 0 !important;background-color: #fff !important;">
                        <div style="margin:20px 40px;border:1px solid #ccc;border-radius: 8px;">
                            <table style="table-layout:fixed;width:100%;padding:16px 10px">
                                <tr>
                                    <td>
                                        <div style=" font-size: 16px; font-weight: 700;">
                                            ${company}
                                        </div>
                                        <div style="font-size: 13px">
                                            ${location}
                                        </div>
                                        <div style="font-size: 13px">
                                            ${translate('receipt_nipt_label')}: ${nipt}
                                        </div>
                                        <div style="font-size: 13px">
                                            ${businUnitCode ? translate('e_invoice_bussines_unit_code_label') + ':' : ''} ${businUnitCode ? businUnitCode : ''}
                                        </div>
                                        <div style="font-size: 13px">
                                            ${receiptNumber}
                                        </div>
                                    </td>
                                    <td>
                                        ${printCustomer()}
                                    </td>
                                </tr>
                            </table>
                            <table style="table-layout:fixed; width:100%; border-top: 1px dashed #ccc;">
                                <tr>
                                    <td style="text-align:center; padding:16px 10px;font-size: 18px;font-weight: 700;">
                                        ${translate('invoice_header')}
                                    </td>
                                </tr>
                            </table>
                            <table style="table-layout:fixed; width:100%; background-color: #eee;">
                                <tr>
                                    <td style="text-align:left; padding:5px 10px;font-size: 16px;">
                                        ${translate('receipt_preview_items')}
                                    </td>
                                    <td style="text-align:right; padding:5px 10px; font-size: 16px;">
                                        ${translate('receipt_item_price_label')}
                                    </td>
                                </tr>
                            </table>

                            <!-- item -->
                            ${printItems()}
                            <!-- Totals -->

                            <table style="table-layout:fixed; width:100%; padding:7px 10px;">
                                <tr>
                                    <td style="text-align:left;font-size: 14px; padding:0px 0">
                                        ${translate('receipt_subtotal')}:
                                    </td>
                                    <td style="text-align:right;font-size: 14px; padding:0px 0">
                                        ${INF.format(
        order.totalMoney?.amount +
        order.totalDiscountMoney?.amount -
        order?.totalTaxMoney?.amount || 0,
    )} ${order.totalMoney?.currency}
                                    </td>
                                </tr>
                                <tr>
                                    <td style="text-align:left;font-size: 14px; padding:0px 0; color: #e60000;">
                                        ${translate('receipt_discount_line')}:
                                    </td>
                                    <td style="text-align:right;font-size: 14px; padding:0px 0; color: #e60000;">
                                        ${INF.format(order.totalDiscountMoney?.amount || 0)}
                                        ${order.totalDiscountMoney?.currency}
                                    </td>
                                </tr>
                                <tr>
                                    <td style="text-align:left;font-size: 18px;font-weight: bold;padding:3px 0">
                                        ${translate('receipt_total')}:
                                    </td>
                                    <td style="text-align:right;font-size: 18px;font-weight: bold; padding:3px 0">
                                        ${INF.format(totalMoney?.amount)} ${totalMoney?.currency}
                                    </td>
                                </tr>
                                ${printOtherCurrency()}
                            </table>
                            <table style="width:100%; padding:7px 10px;border-top: 1px dashed #ccc;">
                                ${printTaxes()}
                            </table>

                            <!-- footer -->


                            <table style="table-layout:fixed; width:100%; padding:7px 10px;border-top: 1px dashed #ccc;">
                                <tr>
                                    <tr>
                                        <td style="text-align:left;font-size: 12px; padding:0px 0">
                                        <b> ${translate(PAYMENT_TYPE?.[invoiceType]?.[orderSubType]?.title)}: </b> ${PAYMENT_TYPE?.[invoiceType]?.[orderSubType]?.description}

                                        </td>
                                        <td style="text-align:left;font-size: 12px; padding:0px 0">
                                            <b> ${translate('receipt_change')}:</b> ${INF.format(change)} ${totalMoney?.currency}
                                        </td>
                                    </tr>
                                    <tr>
                                    <td style="text-align:left;font-size: 12px; padding:0px 0">
                                    <b> ${translate('receipt_total_method')} :</b> ${translate(PAYMENT_METHOD[tender?.type])}
                                </td>
                                        <td style="text-align:left;font-size: 12px; padding:0px 0">
                                            <b> ${translate('SELLER')}:</b> ${employeeDetails ? employeeDetails?.firstName + ' ' + employeeDetails?.lastName : employee?.split('@')?.[0]}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style="text-align:left;font-size: 12px; padding:0px 0">
                                            <b> ${translate('receipt_invoice_number')}:</b> ${invoiceNumber}
                                        </td>
                                        <td style="text-align:left;font-size: 12px; padding:0px 0">
                                            <b> ${translate('receipt_created_at')}:</b> ${moment(createdAt).format('DD-MM-YY HH:mm')}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style="text-align:left;font-size: 12px; padding:0px 0">
                                            <b> ${translate('receipt_fic_label')}:</b> ${IIC}
                                        </td>
                                        <td style="text-align:left;font-size: 12px; padding:0px 0">
                                            <b> ${translate('receipt_operator_code_label')}:</b> ${operatorCode}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style="text-align:left;font-size: 12px; padding:0px 0">
                                            <b> ${translate('receipt_iic_label')}:</b> ${FIC}
                                        </td>
                                        <td style="text-align:left;font-size: 12px; padding:0px 0">
                                            <b> ${translate('receipt_software_code_label')}: </b>${softwareCode}
                                        </td>
                                    </tr>
                                    ${Object.keys(supplyDateOrPeriod).length > 0 ?
            `<tr>
                           <td style="text-align:left;font-size: 12px; padding:0px 0">
                        <b> ${translate('payment_supplying_period_label')}:</b> ${moment(supplyDateOrPeriod?.startDate).format('DD/MM/YYYY')} - ${moment(supplyDateOrPeriod?.endDate).format('DD/MM/YYYY')}
                         </td>
                      </tr>` : ''}
                                </tr >
                            </table >
                            ${printDelivery()}
                            ${printBankAccount()}

                            ${printFooter()}
                            <!--qr -->

                            <table style="table-layout:fixed; width:100%;padding:7px 10px;">
                                <tr>
                                    <td style="text-align:center;">
                                        <img src=${qr?.generateBase64Qr?.base64} />
                                    </td>
                                </tr>
                            </table>
                            <table style="table-layout:fixed; width:100%;padding:7px 10px;">
                                <tr>
                                    <td style="text-align:center;font-size: 13px;font-weight: 700;">
                                        ${translate('receipt_generated_by')} Vodafone eBiznes
                                    </td>
                                </tr>
                            </table>

                        </div >
                    </body >

                </>
    `
}

const convertRefund = (
    refund,
    cashMoney,
    nipt,
    company,
    location,
    customer,
    translate,
    qr,
) => {
    const {
        order,
        createdAt,
        reason,
        receiptNumber,
        employee,
        FIC,
        IIC,
        invoiceNumber,
        referenceIIC,
        referenceFIC,
        softwareCode,
        operatorCode,
        transaction,
        employeeDetails,
    } = refund
    const businUnitCode = transaction?.location?.businUnitCode || ''


    const currency = transaction?.tender?.cashDetails?.currency || {}
    const supplyDateOrPeriod = transaction?.order?.supplyDateOrPeriod || {}
    const orderSubType = transaction?.order?.orderSubType?.split('_')?.[0] || null
    const PAYMENT_TYPE = {
        NONCASH: {
            null: {
                title: 'receipt_invoice_type',
                description: translate('receipt_label_type_noncash'),
            },
            EXPORT: {
                title: 'receipt_invoice_type',
                description: translate('receipt_label_type_export'),
            },
            SELF: {
                title: 'receipt_invoice_type',
                description: translate('receipt_label_type_reverse_charge'),
            },
        },
        CASH: {
            null: {
                title: 'receipt_payment_type_cash',
                description: `${INF.format(cashMoney)} ${order?.totalMoney?.currency}`,
            },
            SELF: {
                title: 'receipt_invoice_type',
                description: translate('receipt_label_type_reverse_charge'),
            },
        },
    }



    const printItems = () => {
        const l = (order?.lineItems || []).length
        let r = ''
        for (let i = 0; i < l; i++) {
            const item = order?.lineItems[i]
            const price = `${INF.format(item.totalMoney?.amount || 0)} ${item.totalMoney?.currency} `
            r += `
    <table style = "table-layout:fixed; width:100%; border-bottom: 1px solid #ccc; padding:7px 10px; border-collapse:separate;" >
                    <tr>
                        <td style="text-align:left; padding:0px 0px;font-size: 16px;">
                            ${item.itemName}
                        </td>
                        <td style="text-align:right; padding:0px 0px; font-size: 16px;">
                            ${price}
                        </td>
                    </tr>
                    <tr>
                        <td style="text-align:left; padding:0px 0px;" colspan="2">
                            <div style="font-size: 12px; padding:1px 0;">${item.quantity || 0} ${item?.measurementUnitId ? translate(item?.measurementUnitId) : ''} X ${INF.format(item.basePriceMoney?.amount || 0,)} ${item.basePriceMoney?.currency}</div>`
            for (let j = 0; j < (item?.modifiers?.length || 0); j++) {
                const modifier = item?.modifiers[j]
                r += `<div style="font-size: 12px;padding:1px 0;" >${modifier.name} (${INF.format(modifier.appliedMoney?.amount || 0)} ${modifier.appliedMoney?.currency})</div>`
            }

            for (let j = 0; j < (item?.appliedDiscounts?.length || 0); j++) {
                const discount = item?.appliedDiscounts[j]
                if (discount.percentage) {
                    r += ` <div   style="font-size: 12px;padding:1px 0;" >${discount.name} (${discount.percentage || 0}%)</div>`
                } else {
                    r += ` <div  style="font-size: 12px;padding:1px 0;" >${discount.name} (${INF.format(discount.appliedMoney?.amount || 0)} ${discount.appliedMoney?.currency})</div>`
                }
            }
            r += `</td>
                    </tr>
                </table >
    `
        }
        return r
    }

    const printTaxes = () => {
        const l = (order?.orderGroupedTaxes || []).length
        let r = ''
        for (let i = 0; i < l; i++) {
            const t = order?.orderGroupedTaxes?.[i] || {}
            const p = `${INF.format(t.VATAmt?.amount || 0)} ${t.VATAmt?.currency} `
            r += ` <tr> <td style="font-size: 13px; padding:3px 0">
        <b>${translate('receipt_tax')} (${t.VATRate}%) : </b> ${p}
    </td> </tr> `
        }
        return r
    }

    const printCustomer = () => {
        let r = ''
        if (Object.keys(customer).length > 0) {
            r += `<b style = "font-size: 13px" > ${translate('BUYER')}</b>
                <div style="font-size: 13px" >${customer?.title}</div>
                <div style="font-size: 13px">${customer?.address}</div>`
            if (customer?.nipt) {
                r += `<div style = "font-size: 13px" > ${translate('einvoice_buyertin_label')}: ${customer?.nipt}</div> `
            } else
                if (customer?.idName) {
                    r += `<div style = "font-size: 13px" > ${translate(ID_TYPES[customer?.idName])}: ${customer?.idValue}</div> `
                }
        }
        return r
    }


    const printOtherCurrency = () => {
        const addAmount = transaction?.tender?.additionalAmountMoney || transaction?.tender?.amountMoney
        const totAmount = transaction?.tender?.amountMoney
        let r = ''

        if (addAmount?.currency !== totAmount?.currency) {
            r += `
            <tr>
                    <td style="text-align:left;font-size: 14px; padding:3px 0">
                    ${translate('receipt_currency_rate_label')}:
                    </td>
                    <td style="text-align:right;font-size: 14px; padding:3px 0">
                     ${currency?.exRate}
                    </td>
                </tr>
            <tr>
            <tr>
                    <td style="text-align:left;font-size: 14px; padding:3px 0">
                    ${translate('receipt_foreign_currency_label')}  ${totAmount?.currency} :
                    </td>
                    <td style="text-align:right;font-size: 14px; padding:3px 0">
                    ${INF.format(totAmount?.amount)} ${totAmount?.currency}
                    </td>
            </tr>`
        }
        return r
    }

    const printDelivery = () => {
        let r = ''
        if (order?.shippingAddress) {
            r += `            <table style = "table-layout:fixed; width:100%; padding:7px 10px;border-top: 1px dashed #ccc;" >
    <tr>
        <td "textAlign: 'left'; padding: '3px 0'; fontSize: '20px';">
        ${translate('e_invoice_remittances_label')}:
    </td>
                </tr >
                <tr>
                    <td style="text-align:left;font-size: 12px; padding:0px 0">
                    <b> ${translate('payment_delivery_receiver_label')}:</b> ${order?.shippingAddress?.externalId ? order?.shippingAddress?.externalId : ''}
                    </td>
                </tr>
                <tr>
                <td style="text-align:left;font-size: 12px; padding:0px 0">
                <b> ${translate('supporting_invoice_address_label')}:</b> 
                ${order?.shippingAddress?.addressLine1 || ''} ${order?.shippingAddress?.locality || ''} ${order?.shippingAddress?.country || ''}
                </td>
            </tr>
            </table> `
        }
        return r
    }

    const printBankAccount = () => {
        let r = ''
        if (order?.bankAccount) {
            r += `            <table style = "table-layout:fixed; width:100%; padding:7px 10px;border-top: 1px dashed #ccc;" >
    <tr>
        <td "textAlign: 'left'; padding: '3px 0'; fontSize: '20px';">
        ${translate('receipt_payment_info_label')}:
    </td>
                </tr >
                <tr>
                    <td style="text-align:left;font-size: 12px; padding:0px 0">
                    <b> ${translate('receipt_payment_name_label')}:</b> ${order?.bankAccount?.holderName ? order?.bankAccount?.holderName : ''}
                    </td>
                </tr>
                <tr>
                <td style="text-align:left;font-size: 12px; padding:0px 0">
                <b> ${translate('receipt_payment_iban_label')}:</b> ${order?.bankAccount?.iban ? order?.bankAccount?.iban : ''}
                </td>
            </tr>
            <tr>
            <td style="text-align:left;font-size: 12px; padding:0px 0">
            <b> ${translate('receipt_payment_swift_label')}:</b> ${order?.bankAccount?.swiftCode ? order?.bankAccount?.swiftCode : ''}
            </td>
        </tr>
            </table> `
        }
        return r
    }

    const printFooter = () => {
        let r = ''
        if (Object.keys(customer).length > 0) {
            r += `<table style = "width:100%; padding:10px 10px;border-top:1px dashed black;" >
    <tr>
        <td>
            <div style="text-align:center;font-size: 14px; padding:0px">
                ${translate('e_invoice_buyer_label')}
            </div>
            <div style="text-align:center;font-size: 12px; padding:0px">
                ${translate('e_invoice_signature_field_label')}
            </div>
            <div style="text-align:center;padding:10px 0; border-bottom: 1px solid black;">
            </div>
        </td>
        <td>
            <div style="text-align:center;font-size: 14px; padding:0px">
                ${translate('e_invoice_carrier_label')}
            </div>
            <div style="text-align:center;font-size: 12px; padding:0px">
                ${translate('e_invoice_signature_field_label')}
            </div>
            <div style="text-align:center;padding:10px 0; border-bottom: 1px solid black;">
            </div>
        </td>
        <td>
            <div style="text-align:center;font-size: 14px; padding:0px">
                ${translate('e_invoice_seller_label')}
            </div>
            <div style="text-align:center;font-size: 12px; padding:0px">
                ${translate('e_invoice_signature_field_label')}
            </div>
            <div style="text-align:center;padding:10px 0; border-bottom: 1px solid black;">
            </div>
        </td>
    </tr>
        </table> `
        }
        return r
    }

    return `
    <html >
    <head>
        <title>Invoice</title>
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
            <link rel="preconnect" href="https://fonts.googleapis.com">
                <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
                    <link href="https://fonts.googleapis.com/css2?family=Roboto:wght@100;400;500;700&display=swap" rel="stylesheet">
                        <style>
                            body {
                                font - family: 'Roboto', sans-serif;
        }
                        </style>
                    </head>
                    <body style="margin: 0 !important;padding: 0 !important;background-color: #fff !important;">
                        <div style="margin:20px 40px;border:1px solid #ccc;border-radius: 8px;">
                            <table style="table-layout:fixed;width:100%;padding:16px 10px">
                                <tr>
                                    <td>
                                        <div style=" font-size: 16px; font-weight: 700;">
                                            ${company}
                                        </div>
                                        <div style="font-size: 13px">
                                            ${location}
                                        </div>
                                        <div style="font-size: 13px">
                                            ${translate('receipt_nipt_label')}: ${nipt}
                                        </div>
                                        <div style="font-size: 13px">
                                            ${businUnitCode ? translate('e_invoice_bussines_unit_code_label') + ':' : ''} ${businUnitCode ? businUnitCode : ''}
                                        </div>
                                        <div style="font-size: 13px">
                                            ${receiptNumber}
                                        </div>
                                    </td>
                                    <td>
                                        ${printCustomer()}
                                    </td>
                                </tr>
                            </table>
                            <table style="table-layout:fixed; width:100%; border-top: 1px dashed #ccc;">
                                <tr>
                                    <td style="text-align:center; padding:16px 10px;font-size: 18px;font-weight: 700;">
                                        ${translate('invoice_header')}
                                    </td>
                                </tr>
                            </table>
                            <table style="table-layout:fixed; width:100%; background-color: #eee;">
                                <tr>
                                    <td style="text-align:left; padding:5px 10px;font-size: 16px;">
                                        ${translate('receipt_preview_items')}
                                    </td>
                                    <td style="text-align:right; padding:5px 10px; font-size: 16px;">
                                        ${translate('receipt_item_price_label')}
                                    </td>
                                </tr>
                            </table>
                            ${printItems()}
                            <table style="table-layout:fixed; width:100%; padding:7px 10px;">
                                <tr>
                                    <td style="text-align:left;font-size: 14px; padding:0px 0">
                                        ${translate('receipt_subtotal')}:
                                    </td>
                                    <td style="text-align:right;font-size: 14px; padding:0px 0">
                                        ${INF.format(
        order.totalMoney?.amount +
        order.totalDiscountMoney?.amount -
        order?.totalTaxMoney?.amount || 0,
    )} ${order.totalMoney?.currency}
                                    </td>
                                </tr>
                                <tr>
                                    <td style="text-align:left;font-size: 14px; padding:0px 0; color: #e60000;">
                                        ${translate('receipt_discount_line')}:
                                    </td>
                                    <td style="text-align:right;font-size: 14px; padding:0px 0; color: #e60000;">
                                        ${INF.format(order.totalDiscountMoney?.amount || 0)}
                                        ${order.totalDiscountMoney?.currency}
                                    </td>
                                </tr>
                                <tr>
                                    <td style="text-align:left;font-size: 18px;font-weight: bold;padding:3px 0">
                                        ${translate('receipt_total')}:
                                    </td>
                                    <td style="text-align:right;font-size: 18px;font-weight: bold; padding:3px 0">
                                        ${INF.format(order.totalMoney?.amount)} ${order.totalMoney?.currency}
                                    </td>
                                </tr>
                                ${printOtherCurrency()}
                            </table>
                            <table style="width:100%; padding:7px 10px;border-top: 1px dashed #ccc;">
                                ${printTaxes()}

                            </table>
                            <table style="width:100%; padding:7px 10px;border-top: 1px dashed #ccc;">
                                <tr>
                                    <td style="text-align:left;font-size: 12px; padding:0px 0" >
                                    <b>${translate(PAYMENT_TYPE?.[transaction?.invoiceType]?.[orderSubType]?.title)}:</b> ${PAYMENT_TYPE?.[transaction?.invoiceType]?.[orderSubType]?.description}

                                    </td>
                                    <td style="text-align:left;font-size: 12px; padding:0px 0"}>
                                    <b> ${translate('receipt_refund_reason')}:</b> ${reason}
                                </td>
                            </tr>


                            <tr>
                                <td style="text-align:left;font-size: 12px; padding:0px 0">
                                    <b> ${translate('receipt_invoice_number')}:</b> ${invoiceNumber}
                                </td>
                                <td style="text-align:left;font-size: 12px; padding:0px 0">
                                    <b> ${translate('receipt_created_at')}:</b> ${moment(createdAt).format('DD-MM-YY HH:mm')}
                                </td>
                            </tr>
                            <tr>
                                <td style="text-align:left;font-size: 12px; padding:0px 0">
                                    <b> ${translate('receipt_iic_label')}:</b> ${IIC}
                                </td>
                                <td style="text-align:left;font-size: 12px; padding:0px 0">
                                    <b> ${translate('receipt_operator_code_label')}:</b> ${operatorCode}
                                </td>
                            </tr>
                            <tr>
                                <td style="text-align:left;font-size: 12px; padding:0px 0" >
                                    <b> ${translate('receipt_fic_label')}:</b> ${FIC}
                                </td>
                                <td style="text-align:left;font-size: 12px; padding:0px 0">
                                    <b> ${translate('receipt_software_code_label')}: </b> ${softwareCode}
                                </td>
                            </tr>
                            <tr>
                                <td style="text-align:left;font-size: 12px; padding:0px 0">
                                    <b> ${translate('SELLER')}:</b> ${employeeDetails ? employeeDetails?.firstName + ' ' + employeeDetails?.lastName : employee?.split('@')?.[0]}
                                </td>
                                <td style="text-align:left;font-size: 12px; padding:0px 0">
                                <b> ${translate('receipt_total_method')} :</b> ${translate(PAYMENT_METHOD[transaction?.tender?.type])}
                            </td>
                            </tr>
                        </table>
                        <table style=" tableLayout: 'fixed'; width:100%; padding:7px 10px; border-top: 1px dashed #ccc; borderCollapse: 'separate';">
                            <tr>
                                <td "textAlign: 'left'; padding: '3px 0'; fontSize: '20px'; fontWeight: 'bold'">
                                ${translate('receipt_section_primary_invoice')}:
                            </td>
                        </tr>
                        <tr>
                            <td style="text-align:left;font-size: 12px; padding:0px 0">
                                <b> ${translate('receipt_section_primary_iic_label')}:</b> ${referenceIIC}
                            </td>
                        </tr>
                        <tr>
                            <td style="text-align:left;font-size: 12px; padding:0px 0">
                                <b> ${translate('receipt_section_primary_fic_label')}: </b> ${referenceFIC}
                            </td>
                        </tr>
                        <tr>
                            <td style="text-align:left;font-size: 12px; padding:0px 0">
                                <b> ${translate('receipt_created_at')}:</b> ${moment(transaction?.createdAt).format('DD-MM-YY HH:mm')}
                            </td>
                        </tr>
                        ${Object.keys(supplyDateOrPeriod).length > 0 ?
            `<tr>
                                               <td style="text-align:left;font-size: 12px; padding:0px 0">
                                            <b> ${translate('payment_supplying_period_label')}:</b> ${moment(supplyDateOrPeriod?.startDate).format('DD/MM/YYYY')} - ${moment(supplyDateOrPeriod?.endDate).format('DD/MM/YYYY')}
                                             </td>
                                          </tr>` : ''}

                    </table>
                    ${printDelivery()}
                    ${printBankAccount()}
                    ${printFooter()}
                    <table style="table-layout:fixed; width:100%;padding:7px 10px;">
                        <tr>
                            <td style="text-align:center;">
                                <img src=${qr?.generateBase64Qr?.base64} />
                            </td>
                        </tr>
                    </table>
                    <table style="table-layout:fixed; width:100%;padding:7px 10px;">
                        <tr>
                            <td style="text-align:center;font-size: 13px;font-weight: 700;">
                                ${translate('receipt_generated_by')} Vodafone eBiznes
                            </td>
                        </tr>
                    </table>
                </div>
            </body>
        </html>
        `
}

// const convertOldInvoice = (
//     invoice,
//     nipt,
//     company,
//     location,
//     translate,
//     qr,
// ) => {
//     const {
//         basicInfo,
//         transportInfo,
//         issuer,
//         carrier,
//         items,
//     } = invoice

//     const printItems = () => {
//         const l = (items || []).length
//         let r = ''
//         for (let i = 0; i < l; i++) {
//             const item = items[i]
//             const price = `${item.quantity || 0} ${item.unit}`
//             r += `<tr>
//             <td style="text-align:left; padding:0px 0px;font-size: 16px;">
//                 ${item.name}
//             </td>
//             <td style="text-align:right; padding:0px 0px; font-size: 16px;">
//                 ${price}
//             </td>
//         </tr>`
//         }
//         return r
//     }

//     return `<html>

//             <head>
//                 <title>Invoice</title>
//                 <meta name="viewport" content="width=device-width, initial-scale=1.0">
//                     <link rel="preconnect" href="https://fonts.googleapis.com">
//                         <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
//                             <link href="https://fonts.googleapis.com/css2?family=Roboto:wght@100;400;500;700&display=swap" rel="stylesheet">
//                                 <style>
//                                     body {
//                                         font - family: 'Roboto', sans-serif;
//             }
//                                 </style>
//                             </head>

//                             <body style="margin: 0 !important;padding: 0 !important;background-color: #fff !important;">
//                                 <div style="margin:20px auto;width:210mm;">
//                                     <table style="table-layout:fixed;width:100%;padding:10px 10px">
//                                         <tr>
//                                             <td>
//                                                 <div style=" font-size: 16px; font-weight: 700;">
//                                                     ${company}
//                                                 </div>
//                                                 <div style="font-size: 13px">
//                                                     ${location}
//                                                 </div>
//                                                 <div style="font-size: 13px">
//                                                     ${translate('receipt_nipt_label')}: ${nipt}
//                                                 </div>

//                                                 <div style="font-size: 13px">
//                                                     ${basicInfo.businUnitCode ? translate('e_invoice_bussines_unit_code_label') + ':' : ''} ${basicInfo.businUnitCode ? basicInfo.businUnitCode : ''}
//                                                 </div>
//                                             </td>
//                                         </tr>
//                                     </table>
//                                     <table style="width:100%; border-top: 1px dashed #ccc;padding:10px 10px;">
//                                         <tr>
//                                             <td style="text-align:center; font-size: 18px;font-weight: bold;">
//                                                 ${translate('supporting_invoice_label_type')}
//                                             </td>
//                                         </tr>
//                                     </table>
//                                     <table style="width:100%; background-color: #eee;padding:0px 10px;">
//                                         <tr>
//                                             <td style="text-align:left; padding:5px 0px;font-size: 16px;">
//                                                 ${translate('receipt_preview_items')}
//                                             </td>
//                                             <td style="text-align:right; padding:5px 0px; font-size: 16px;">
//                                                 ${translate('supporting_invoice_amount_label')}
//                                             </td>
//                                         </tr>
//                                     </table>

//                                     <!-- item -->

//                                     <table style="width:100%; border-bottom: 1px solid #ccc; padding:5px 10px;">
//                                         ${printItems()}
//                                     </table>

//                                     <table style="table-layout:fixed; width:100%; padding:7px 10px;">
//                                         <tr>
//                                             <td style="font-size: 16px;font-weight: bold;padding-bottom:5px;">${translate('supporting_invoice_receipt_info')}</td>
//                                         </tr>
//                                         <tr>
//                                             <td style="text-align:left;font-size: 14px; padding:0px 0">
//                                                 <b>${translate('supporting_invoice_fwtnic')}</b>: ${basicInfo.fwtnic}
//                                             </td>
//                                             <td style="font-size: 14px; padding:0px 0">

//                                                 <b>${translate('supporting_invoice_seller')}</b>: ${basicInfo?.employeeDetails ? basicInfo?.employeeDetails?.firstName + ' ' + basicInfo?.employeeDetails?.lastName : basicInfo?.employeeEmail?.split('@')?.[0]}
//                                             </td>
//                                         </tr>
//                                         <tr>
//                                             <td style="text-align:left;font-size: 14px; padding:0px 0">
//                                                 <b>${translate('receipt_operator_code_label')}</b>: ${basicInfo.operatorCode}
//                                             </td>
//                                             <td style="font-size: 14px; padding:0px 0">
//                                                 <b>${translate('receipt_created_at')}</b>: ${basicInfo.date}
//                                             </td>
//                                         </tr>
//                                         <tr>
//                                             <td style="text-align:left;font-size: 14px; padding:0px 0">
//                                                 <b>${translate('receipt_software_code_label')}</b>: ${basicInfo.softCode}
//                                             </td>
//                                             <td style="font-size: 14px; padding:0px 0">
//                                                 <b>${translate('supporting_invoice_type')}</b>: ${basicInfo.invoiceType}
//                                             </td>
//                                         </tr>
//                                         <tr>
//                                             <td style="text-align:left;font-size: 14px; padding:0px 0">
//                                                 <b>${translate('supporting_invoice_number')}</b>: ${basicInfo.receiptNr}
//                                             </td>
//                                             <td style="font-size: 14px; padding:0px 0">
//                                                 <b>${translate('supporting_invoice_transaction_type_label')}</b>: ${basicInfo.transactionType}
//                                             </td>
//                                         </tr>
//                                     </table>
//                                     <table style="table-layout:fixed;width:100%; padding:7px 10px;border-top: 1px dashed #ccc;">
//                                         <tr>
//                                             <td style="font-size: 16px;font-weight: bold;padding-bottom:5px;">${translate('supporting_invoice_transport_info_label')}</td>
//                                         </tr>
//                                         <tr>
//                                             <td style="text-align:left;font-size: 14px; padding:0px 0">
//                                                 <b> ${translate('supporting_invoice_transport_owner_label')}</b>: ${transportInfo.transportOwnership}
//                                             </td>
//                                         </tr>
//                                         <tr>
//                                             <td style="font-size: 14px; padding:0px 0">
//                                                 <b>${translate('supporting_invoice_transport_plate')}</b>: ${transportInfo.plates}
//                                             </td>
//                                         </tr>
//                                     </table>
//                                     <table style="table-layout:fixed;width:100%; padding:7px 10px;">
//                                         <tr>
//                                             <td>
//                                                 <div style="font-size: 16px;font-weight: bold;padding-bottom:5px;">${translate('supporting_invoice_transport_starting_point')}</div>
//                                                 <div style="font-size: 14px; padding:0px 0"><b>${translate('supporting_invoice_transport_starting_address')}</b>: ${transportInfo.startingAddress}
//                                                 </div>
//                                                 <div style="font-size: 14px; padding:0px 0"><b>${translate('supporting_invoice_city')}</b>: ${transportInfo.startingCity}</div>
//                                                 <div style="font-size: 14px; padding:0px 0"><b>${translate('supporting_invoice_transport_starting_point')}</b>: ${transportInfo.startingPoint}
//                                                 </div>
//                                                 <div style="font-size: 14px; padding:0px 0"><b>${translate('supporting_invoice_starting_date')}</b>: ${transportInfo.startingDate}
//                                                 </div>
//                                             </td>
//                                             <td>
//                                                 <div style="font-size: 16px;font-weight: bold; padding-bottom:5px;">${translate('supporting_invoice_transport_destination_point')}</div>
//                                                 <div style="font-size: 14px; padding:0px 0"><b>${translate('supporting_invoice_transport_destination_address')}</b>:
//                                                     ${transportInfo.destinationAddress}</div>
//                                                 <div style="font-size: 14px; padding:0px 0"><b>${translate('supporting_invoice_city')}</b>: ${transportInfo.destinationCity}</div>
//                                                 <div style="font-size: 14px; padding:0px 0"><b>${translate('supporting_invoice_transport_destination_point')}</b>:
//                                                     ${transportInfo.destinationPoint}</div>
//                                                 <div style="font-size: 14px; padding:0px 0"><b>${translate('supporting_invoice_transport_destination_date')}</b>:
//                                                     ${transportInfo.destinationDate} </div>
//                                             </td>
//                                         </tr>
//                                     </table>
//                                     <table style="table-layout:fixed;width:100%; padding:7px 10px;">
//                                         <tr>
//                                             <td style="font-size: 16px;font-weight: bold;padding-bottom:5px;">${translate('receipt_preview_items')}</td>
//                                         </tr>
//                                         <tr>
//                                             <td style="text-align:left;font-size: 14px; padding:0px 0">
//                                                 ${translate('supporting_invoice_preview_dangerous')}: ${transportInfo.isItemDangerous ? `${translate('supporting_invoice_yes_question')}` : `${translate('supporting_invoice_no_question')}`}
//                                             </td>
//                                             <td style="font-size: 14px; padding:0px 0">
//                                                 ${translate('supporting_invoice_preview_accompaniment')}: ${transportInfo.isEscort ? `${translate('supporting_invoice_yes_question')}` : `${translate('supporting_invoice_no_question')}`}
//                                             </td>
//                                         </tr>
//                                     </table>
//                                     <table style="table-layout:fixed;width:100%; padding:7px 10px;border-top: 1px dashed #ccc;">
//                                         <tr>
//                                             <td>
//                                                 <div style="font-size: 16px;font-weight: bold;padding-bottom:5px;">${translate('supporting_invoice_issuer_label')}</div>
//                                                 <div style="font-size: 14px; padding:0px 0"><b>${translate('receipt_nipt_label')}</b>: ${issuer.nuis}</div>
//                                                 <div style="font-size: 14px; padding:0px 0"><b>${translate('supporting_invoice_name_label')}</b>: ${issuer.name}</div>
//                                                 <div style="font-size: 14px; padding:0px 0"><b>${translate('supporting_invoice_address_label')}</b>: ${issuer.address} </div>
//                                                 <div style="font-size: 14px; padding:0px 0"><b>${translate('supporting_invoice_city')}</b>: ${issuer.city} </div>
//                                             </td>
//                                             <td>
//                                                 <div style="font-size: 16px;font-weight: bold;padding-bottom:5px;">${translate('supporting_invoice_carrier_label')}</div>
//                                                 <div style="font-size: 14px; padding:0px 0"><b>${translate('receipt_nipt_label')}</b>: ${carrier.nuis}</div>
//                                                 <div style="font-size: 14px; padding:0px 0"><b>${translate('supporting_invoice_name_label')}</b>: ${carrier.name}</div>
//                                                 <div style="font-size: 14px; padding:0px 0"><b>${translate('supporting_invoice_address_label')}</b>: ${carrier.address}</div>
//                                                 <div style="font-size: 14px; padding:0px 0"><b>${translate('supporting_invoice_city')}</b>: ${carrier.city} </div>
//                                             </td>
//                                         </tr>
//                                     </table>
//                                     <!-- qr -->

//                                     <table style="table-layout:fixed; width:100%;padding:5px 5px;">
//                                         <tr>
//                                             <td style="text-align:center;">
//                                                 <img src=${qr?.generateBase64Qr?.base64} />
//                                             </td>
//                                         </tr>
//                                     </table>
//                                     <table style="table-layout:fixed; width:100%;padding:5px 5px;">
//                                         <tr>
//                                             <td style="text-align:center;font-size: 13px;font-weight: 700;">
//                                                 ${translate('receipt_generated_by')} Vodafone eBiznes
//                                             </td>
//                                         </tr>
//                                     </table>

//                                 </div>
//                             </body>

//                         </html>
//                         `


// }

const convertSupportingInvoice = (
    invoice,
    nipt,
    company,
    location,
    translate,
    qr,
) => {
    const {
        basicInfo,
        transportInfo,
        issuer,
        carrier,
        items,
    } = invoice

    const printItems = () => {
        const l = (items || []).length
        let r = ''
        for (let i = 0; i < l; i++) {
            const item = items[i]
            const price = `${item.quantity || 0} ${item.unit}`
            r += `<tr>
            <td style="text-align:left; padding:0px 0px;font-size: 16px;">
                ${item.name}
            </td>
            <td style="text-align:right; padding:0px 0px; font-size: 16px;">
                ${price}
            </td>
        </tr>`
        }
        return r
    }

    return `<html>

            <head>
                <title>Invoice</title>
                <meta name="viewport" content="width=device-width, initial-scale=1.0">
                    <link rel="preconnect" href="https://fonts.googleapis.com">
                        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
                            <link href="https://fonts.googleapis.com/css2?family=Roboto:wght@100;400;500;700&display=swap" rel="stylesheet">
                                <style>
                                    body {
                                        font - family: 'Roboto', sans-serif;
            }
                                </style>
                            </head>

                            <body style="margin: 0 !important;padding: 0 !important;background-color: #fff !important;">
                                <div style="margin:20px auto;width:210mm;">
                                    <table style="table-layout:fixed;width:100%;padding:10px 10px">
                                        <tr>
                                            <td>
                                                <div style=" font-size: 16px; font-weight: 700;">
                                                    ${company}
                                                </div>
                                                <div style="font-size: 13px">
                                                    ${location}
                                                </div>
                                                <div style="font-size: 13px">
                                                    ${translate('receipt_nipt_label')}: ${nipt}
                                                </div>

                                                <div style="font-size: 13px">
                                                    ${basicInfo.businUnitCode ? translate('e_invoice_bussines_unit_code_label') + ':' : ''} ${basicInfo.businUnitCode ? basicInfo.businUnitCode : ''}
                                                </div>
                                            </td>
                                        </tr>
                                    </table>
                                    <table style="width:100%; border-top: 1px dashed #ccc;padding:10px 10px;">
                                        <tr>
                                            <td style="text-align:center; font-size: 18px;font-weight: bold;">
                                                ${translate('supporting_invoice_label_type')}
                                            </td>
                                        </tr>
                                    </table>
                                    <table style="width:100%; background-color: #eee;padding:0px 10px;">
                                        <tr>
                                            <td style="text-align:left; padding:5px 0px;font-size: 16px;">
                                                ${translate('receipt_preview_items')}
                                            </td>
                                            <td style="text-align:right; padding:5px 0px; font-size: 16px;">
                                                ${translate('supporting_invoice_amount_label')}
                                            </td>
                                        </tr>
                                    </table>

                                    <!-- item -->

                                    <table style="width:100%; border-bottom: 1px solid #ccc; padding:5px 10px;">
                                        ${printItems()}
                                    </table>

                                    <table style="table-layout:fixed; width:100%; padding:7px 10px;">
                                        <tr>
                                            <td style="font-size: 16px;font-weight: bold;padding-bottom:5px;">${translate('supporting_invoice_receipt_info')}</td>
                                        </tr>
                                        <tr>
                                            <td style="text-align:left;font-size: 14px; padding:0px 0">
                                                <b>${translate('supporting_invoice_fwtnic')}</b>: ${basicInfo.fwtnic}
                                            </td>
                                            <td style="font-size: 14px; padding:0px 0">
                                            
                                                <b>${translate('supporting_invoice_seller')}</b>: ${basicInfo?.employeeDetails ? basicInfo?.employeeDetails?.firstName + ' ' + basicInfo?.employeeDetails?.lastName : basicInfo?.employeeEmail?.split('@')?.[0]}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style="text-align:left;font-size: 14px; padding:0px 0">
                                                <b>${translate('receipt_operator_code_label')}</b>: ${basicInfo.operatorCode}
                                            </td>
                                            <td style="font-size: 14px; padding:0px 0">
                                                <b>${translate('receipt_created_at')}</b>: ${basicInfo.date}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style="text-align:left;font-size: 14px; padding:0px 0">
                                                <b>${translate('receipt_software_code_label')}</b>: ${basicInfo.softCode}
                                            </td>
                                            <td style="font-size: 14px; padding:0px 0">
                                                <b>${translate('supporting_invoice_type')}</b>: ${basicInfo.invoiceType}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style="text-align:left;font-size: 14px; padding:0px 0">
                                                <b>${translate('supporting_invoice_number')}</b>: ${basicInfo.receiptNr}
                                            </td>
                                            <td style="font-size: 14px; padding:0px 0">
                                                <b>${translate('supporting_invoice_transaction_type_label')}</b>: ${basicInfo.transactionType}
                                            </td>
                                        </tr>
                                    </table>
                                    <table style="table-layout:fixed;width:100%; padding:7px 10px;border-top: 1px dashed #ccc;">
                                        <tr>
                                            <td style="font-size: 16px;font-weight: bold;padding-bottom:5px;">${translate('supporting_invoice_transport_info_label')}</td>
                                        </tr>
                                        <tr>
                                            <td style="text-align:left;font-size: 14px; padding:0px 0">
                                                <b> ${translate('supporting_invoice_transport_owner_label')}</b>: ${transportInfo.transportOwnership}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style="font-size: 14px; padding:0px 0">
                                                <b>${translate('supporting_invoice_transport_plate')}</b>: ${transportInfo.plates}
                                            </td>
                                        </tr>
                                    </table>
                                    <table style="table-layout:fixed;width:100%; padding:7px 10px;">
                                        <tr>
                                            <td>
                                                <div style="font-size: 16px;font-weight: bold;padding-bottom:5px;">${translate('supporting_invoice_transport_starting_point')}</div>
                                                <div style="font-size: 14px; padding:0px 0"><b>${translate('supporting_invoice_transport_starting_address')}</b>: ${transportInfo.startingAddress}
                                                </div>
                                                <div style="font-size: 14px; padding:0px 0"><b>${translate('supporting_invoice_city')}</b>: ${transportInfo.startingCity}</div>
                                                <div style="font-size: 14px; padding:0px 0"><b>${translate('supporting_invoice_transport_starting_point')}</b>: ${transportInfo.startingPoint}
                                                </div>
                                                <div style="font-size: 14px; padding:0px 0"><b>${translate('supporting_invoice_starting_date')}</b>: ${transportInfo.startingDate}
                                                </div>
                                            </td>
                                            <td>
                                                <div style="font-size: 16px;font-weight: bold; padding-bottom:5px;">${translate('supporting_invoice_transport_destination_point')}</div>
                                                <div style="font-size: 14px; padding:0px 0"><b>${translate('supporting_invoice_transport_destination_address')}</b>:
                                                    ${transportInfo.destinationAddress}</div>
                                                <div style="font-size: 14px; padding:0px 0"><b>${translate('supporting_invoice_city')}</b>: ${transportInfo.destinationCity}</div>
                                                <div style="font-size: 14px; padding:0px 0"><b>${translate('supporting_invoice_transport_destination_point')}</b>:
                                                    ${transportInfo.destinationPoint}</div>
                                                <div style="font-size: 14px; padding:0px 0"><b>${translate('supporting_invoice_transport_destination_date')}</b>:
                                                    ${transportInfo.destinationDate} </div>
                                            </td>
                                        </tr>
                                    </table>
                                    <table style="table-layout:fixed;width:100%; padding:7px 10px;">
                                        <tr>
                                            <td style="font-size: 16px;font-weight: bold;padding-bottom:5px;">${translate('receipt_preview_items')}</td>
                                        </tr>
                                        <tr>
                                            <td style="text-align:left;font-size: 14px; padding:0px 0">
                                                ${translate('supporting_invoice_preview_dangerous')}: ${transportInfo.isItemDangerous ? `${translate('supporting_invoice_yes_question')}` : `${translate('supporting_invoice_no_question')}`}
                                            </td>
                                            <td style="font-size: 14px; padding:0px 0">
                                                ${translate('supporting_invoice_preview_accompaniment')}: ${transportInfo.isEscort ? `${translate('supporting_invoice_yes_question')}` : `${translate('supporting_invoice_no_question')}`}
                                            </td>
                                        </tr>
                                    </table>
                                    <table style="table-layout:fixed;width:100%; padding:7px 10px;border-top: 1px dashed #ccc;">
                                        <tr>
                                            <td>
                                                <div style="font-size: 16px;font-weight: bold;padding-bottom:5px;">${translate('supporting_invoice_issuer_label')}</div>
                                                <div style="font-size: 14px; padding:0px 0"><b>${translate('receipt_nipt_label')}</b>: ${issuer.nuis}</div>
                                                <div style="font-size: 14px; padding:0px 0"><b>${translate('supporting_invoice_name_label')}</b>: ${issuer.name}</div>
                                                <div style="font-size: 14px; padding:0px 0"><b>${translate('supporting_invoice_address_label')}</b>: ${issuer.address} </div>
                                                <div style="font-size: 14px; padding:0px 0"><b>${translate('supporting_invoice_city')}</b>: ${issuer.city} </div>
                                            </td>
                                            <td>
                                                <div style="font-size: 16px;font-weight: bold;padding-bottom:5px;">${translate('supporting_invoice_carrier_label')}</div>
                                                <div style="font-size: 14px; padding:0px 0"><b>${translate('receipt_nipt_label')}</b>: ${carrier.nuis}</div>
                                                <div style="font-size: 14px; padding:0px 0"><b>${translate('supporting_invoice_name_label')}</b>: ${carrier.name}</div>
                                                <div style="font-size: 14px; padding:0px 0"><b>${translate('supporting_invoice_address_label')}</b>: ${carrier.address}</div>
                                                <div style="font-size: 14px; padding:0px 0"><b>${translate('supporting_invoice_city')}</b>: ${carrier.city} </div>
                                            </td>
                                        </tr>
                                    </table>
                                    <!-- qr -->

                                    <table style="table-layout:fixed; width:100%;padding:5px 5px;">
                                        <tr>
                                            <td style="text-align:center;">
                                                <img src=${qr?.generateBase64Qr?.base64} />
                                            </td>
                                        </tr>
                                    </table>
                                    <table style="table-layout:fixed; width:100%;padding:5px 5px;">
                                        <tr>
                                            <td style="text-align:center;font-size: 13px;font-weight: 700;">
                                                ${translate('receipt_generated_by')} Vodafone eBiznes
                                            </td>
                                        </tr>
                                    </table>

                                </div>
                            </body>

                        </html>
                        `


}
const convertOldInvoice = (
    oldInvoice,
    translate,
) => {

    return `<html>

            <head>
                <title>Invoice</title>
                <meta name="viewport" content="width=device-width, initial-scale=1.0">
                    <link rel="preconnect" href="https://fonts.googleapis.com">
                        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
                            <link href="https://fonts.googleapis.com/css2?family=Roboto:wght@100;400;500;700&display=swap" rel="stylesheet">
                                <style>
                                    body {
                                        font - family: 'Roboto', sans-serif;
            }
                                </style>
                            </head>

                            <body style="margin: 0 !important;padding: 0 !important;background-color: #fff !important;">
                                <div style="margin:20px auto;width:210mm;">
                                    <table style="width:100%; border-top: 1px dashed #ccc;padding:10px 10px;">
                                        <tr>
                                            <td style="text-align:center; font-size: 18px;font-weight: bold;">
                                                ${translate('invoice_header')}
                                            </td>
                                        </tr>
                                    </table>

                                    <table style="table-layout:fixed; width:100%; padding:7px 10px;">
                                        <tr>
                                            <td style="font-size: 16px;font-weight: bold;padding-bottom:5px;">${translate('supporting_invoice_receipt_info')}</td>
                                        </tr>
                                        <tr>
                                            <td style="text-align:left;font-size: 14px; padding:0px 0">
                                                <b>${translate('receipt_nsfl_label')}</b>: ${oldInvoice?.IICRef}
                                            </td>
                                            <td style="font-size: 14px; padding:0px 0">
                                            
                                                <b>${translate('receipt_invoice_number')}</b>: ${oldInvoice?.InvoiceNumber}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style="text-align:left;font-size: 14px; padding:0px 0">
                                                <b>${translate('receipt_created_at')}</b>: ${oldInvoice.InvoiceDate}
                                            </td>
                                        </tr>
                                    </table>
                                    <table style="width:100%; border-top: 1px dashed #ccc;padding:10px 10px;">
                                    <tr>
                                        <td style="text-align:left; font-size: 18px;font-weight: bold;">
                                            ${translate('receipt_buyer_label')}
                                        </td>
                                    </tr>
                                    
                                </table>
                                <table style="table-layout:fixed; width:100%; padding:7px 10px;">
                                <tr>
                                    <td style="text-align:left;font-size: 14px; padding:0px 0">
                                        <b>${translate('receipt_nipt_label')}</b>: ${oldInvoice?.NUIS}
                                    </td>
                                </tr>
                            </table>
                            <table style="width:100%; border-top: 1px dashed #ccc;padding:10px 10px;">
                                    <tr>
                                        <td style="text-align:left; font-size: 18px;font-weight: bold;">
                                            ${translate('SELLER')}
                                        </td>
                                    </tr>
                                    
                                </table>
                                <table style="table-layout:fixed; width:100%; padding:7px 10px;">
                                <tr>
                                    <td style="text-align:left;font-size: 14px; padding:0px 0">
                                        <b>${translate('receipt_nipt_label')}</b>: ${oldInvoice?.TargetTaxpayerNUIS}
                                    </td>
                                </tr>
                            </table>
                                </div>
                            </body>
                        </html>
                        `

}

export { convertReceipt, convertRefund, convertSupportingInvoice, convertOldInvoice }
