/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useMemo, useCallback, useRef } from 'react'
import { SafeAreaView, View, Dimensions, LayoutAnimation, TouchableOpacity, Linking } from 'react-native'
import PropTypes from 'prop-types'
import { useTheme } from 'react-native-themed-styles'
import { useMutation, useLazyQuery } from '@apollo/client'
import moment from 'moment'
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs'
import Big from 'big.js'
import ContentLoader, { Rect } from 'react-content-loader/native'
import { useFocusEffect } from '@react-navigation/native'
import { v4 as uuidv4 } from 'uuid'
import QRCode from 'react-native-qrcode-svg'
import Color from 'color'

import {
    Header,
    Button,
    Loader,
    Text,
    Tabs,
    SimpleList,
    ItemStatus,
    Timeline,
    ErrorModal,
    Icon,
    ModalContent,
    Modal,
    ScrollWrapperWeb,
    DoubleIcon,
} from '../../Components'
import { useLanguage } from '../../Utils/Language'
import ContentManager from '../../Utils/ContentManager'

import { getErrorMessage, INF, useConfig } from '../../Utils'

import { GET_ORDER, UPDATE_ORDER, PAY_ORDER } from './OrderDetails.Schema'
import themedStyles, { routeStyles } from './OrderDetails.Styles'
import { layoutAnimConfig } from '../../Themes/Theme'
import images, { animations } from '../../Themes/Images'
import { colors } from '../../Themes'

const { width } = Dimensions.get('screen')

const LANGUAGES = {
    al: 'sq',
    en: 'en-gb',
}
const Tab = createMaterialTopTabNavigator()
function Item({
    receiptNumber,
    createdAt,
    activities,
    totalMoney,
    onItemPress,
}) {
    const [styles] = useTheme(routeStyles)
    const lang = useLanguage()
    return (
        <TouchableOpacity
            onPress={onItemPress}
            style={styles.itemContainer}
            disabled={!onItemPress}>
            <View style={styles.itemSubcontainer}>
                <DoubleIcon
                    iconSource={images.cash}
                    statusIconSource={images.transaction}
                    iconSize={30}
                    statusSize={10}
                    style={styles.iconStyles}
                    statusIconStyle={styles.statusIcon}
                />
                <View style={styles.listDescContainer}>
                    <Text i18nKey={receiptNumber} numberOfLines={1} size="footnote" />
                    <Text
                        i18nKey={`${moment(createdAt).locale(LANGUAGES[lang]).format('DD/MM/YYYY HH:mm A')}`}
                        weight="light"
                        color={colors.secondaryText}
                        numberOfLines={1}
                        style={styles.description}
                    />
                    <Text
                        i18nKey={activities[0]?.email}
                        numberOfLines={1}
                        weight="light"
                        color={colors.secondaryText}
                        style={styles.subdescription}
                    />
                </View>
                <View
                    style={styles.amountContainer}>
                    <Text i18nKey={`${INF.format(totalMoney?.amount)} ${totalMoney?.currency || 'Lekë'}`} style={styles.amount} />
                    <Icon source={images.arrow_right} size={24} />
                </View>
            </View>
        </TouchableOpacity>
    )
}
Item.propTypes = {
    receiptNumber: PropTypes.string,
    createdAt: PropTypes.string,
    activities: PropTypes.array,
    totalMoney: PropTypes.object,
    onItemPress: PropTypes.func,
}

function LoaderContent({ isFirst, isLast, style }) {
    const loaderStyle = {}
    if (isFirst) {
        loaderStyle.borderTopLeftRadius = 7
        loaderStyle.borderTopRightRadius = 7
    }
    if (isLast) {
        loaderStyle.borderBottomLeftRadius = 7
        loaderStyle.borderBottomRightRadius = 7
    }
    return (
        <ContentLoader
            speed={0.7}
            height={70}
            style={Object.assign({}, { backgroundColor: 'white', overflow: 'hidden' }, loaderStyle, style)}
            backgroundColor="#e2e2e2"
            foregroundColor="#ecebeb">
            <Rect x="20" y="22.5" rx="3" ry="3" width="30" height="25" />
            <Rect x="80" y="10" rx="3" ry="3" width="145" height="12" />
            <Rect x="80" y="32" rx="3" ry="3" width="70" height="8" />
            <Rect x="80" y="50" rx="3" ry="3" width="130" height="8" />
            <Rect x={`${width - 110}`} y="27" rx="3" ry="3" width="30" height="16" />
            <Rect x={`${width - 63}`} y="22.5" rx="3" ry="3" width="20" height="25" />
        </ContentLoader>
    )
}
LoaderContent.propTypes = {
    isFirst: PropTypes.bool,
    isLast: PropTypes.bool,
    style: PropTypes.object,
}

function ItemLoader() {
    const [styles] = useTheme(themedStyles)
    return (
        <View style={styles.paddingHorziontal}>
            <LoaderContent isFirst isLast style={styles.firstLoader} />
            <LoaderContent isFirst />
            <LoaderContent />
            <LoaderContent />
            <LoaderContent isLast />
            <ContentLoader
                speed={0.7}
                height={50}
                style={Object.assign({}, { borderRadius: 7 })}
                backgroundColor="#e2e2e2"
                foregroundColor="#ecebeb">
                <Rect x="0" y="30" rx="3" ry="3" width="110" height="12" />
            </ContentLoader>
            <LoaderContent isFirst />
            <LoaderContent />
            <LoaderContent isLast />
        </View>
    )
}

const RenderStatus = ({ status }) => {
    const [styles] = useTheme(themedStyles)

    const { translate } = ContentManager
    const reasonLabel = translate('cancel_order_reason_label') + ': ' + translate(status?.desc || ' ')
    return (
        < View style={styles.statusWrapper}>
            <View>
                <Text i18nKey="users_status_label" />
                {status?.desc && <Text i18nKey={reasonLabel} size="footnote" weight="light" />}
            </View>
            <ItemStatus color={status?.color} title={status?.title} />
        </View >
    )
}

RenderStatus.propTypes = {
    status: PropTypes.object,
}

const OrderDetails = ({
    route: {
        params: {
            id,
        },
    },
    navigation: {
        navigate,
        goBack,
    },
}) => {
    const { switchLocation: { deviceId, locationId } } = useConfig()

    const lang = useLanguage()
    const { translate } = ContentManager
    const [styles] = useTheme(themedStyles)

    const [order, setOrder] = useState([])
    const copyOfOrder = useRef(null)

    const [loading, setLoading] = useState(true)
    const [shareLoading] = useState(false)
    const [isStatusLoading, setIsStatusLoading] = useState(true)
    const [isOrderProccesing, setIsOrderProccesing] = useState(false)
    const [status, setStatus] = useState('')
    const [isorderFinished, setIsOrderFinished] = useState(false)
    const [isOrderActive, setIsOrderActive] = useState(false)
    const [isError, setIsError] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [activity, setActivity] = useState([])
    const [modal, setModal] = useState({
        isVisible: false,
        icon: images.warningIcon,
        title: '',
        desc: '',
        descPlaceholders: [],
        primaryText: '',
        primaryAction: null,
        secondaryText: '',
        secondaryAction: null,
    })

    const [fetchOrder, { data, refetch, error: fetchError }] = useLazyQuery(GET_ORDER, {
        variables: {
            id: id,
            locationId: locationId,
            cashRegisterId: deviceId,
        },
    })
    const [updateOrder, { data: updatedData }] = useMutation(UPDATE_ORDER, {
        variables: {
            orderUpdateInput: 1, locationId: locationId,
            cashRegisterId: deviceId
        }
    })

    const [payOrder, { }] = useMutation(PAY_ORDER)

    useEffect(() => {
        if (data) {
            LayoutAnimation.configureNext(layoutAnimConfig)
            const temp = { ...data?.getOrder }
            setOrder(mapResponse(temp))
            handleStatus(temp?.status)
            removeUnnecessaryFields(temp)
            setIsStatusLoading(false)
            setLoading(false)
        }
    }, [data])

    useEffect(() => {
        if (updatedData) {
            if (updatedData?.updateOrder?.id === data?.getOrder?.id) {
                refetch()
                setLoading(false)
            }
        }
    }, [updatedData])

    useFocusEffect(
        useCallback(
            () => {
                setLoading(true)
                setIsOrderActive(false)
                fetchOrder()
            }, [])
    )

    useEffect(() => {
        if (fetchError) {
            handleError(fetchError.message)
        }
    }, [fetchError])

    const handleStatus = (statusOfOrder) => {
        if (statusOfOrder === 'NEW') {
            setStatus({
                label: 'order_accept_label',
                nextStatus: 'ACTIVE',
                status: 'NEW',
            })
            setIsOrderProccesing(false)
            setIsOrderActive(true)
        }
        if (statusOfOrder === 'ACTIVE') {
            setStatus({
                label: 'order_in_progress_label',
                nextStatus: 'IN_PROGRESS',
                status: 'ACTIVE',
            })
            setIsOrderProccesing(false)
            setIsOrderActive(true)
        }
        if (statusOfOrder === 'IN_PROGRESS') {
            setStatus({
                label: 'order_complete_label',
                nextStatus: 'COMPLETED',
                status: 'IN_PROGRESS',
            })
            setIsOrderProccesing(false)
            setIsOrderActive(true)
        }
        if (statusOfOrder === 'COMPLETED') {
            setIsOrderActive(false)
            setStatus({
                nextStatus: null,
                color: colors.green,
                title: 'online_orders_completed_item',
                transaction: true,
            })
            setIsOrderProccesing(false)
        }
        if (statusOfOrder === 'CANCELLED') {
            setStatus({
                nextStatus: null,
                color: colors.accent,
                title: 'online_orders_cancelled_item',
                desc: translate(data?.getOrder?.reason),
            })
            setIsOrderActive(false)
        }
    }

    const mapResponse = (dataOfOrder) => {
        const activitiesIndex = {
            'NEW': {
                index: 0,
                title: 'order_activity_new',
            },
            'ACTIVE': {
                index: 1,
                title: 'order_activity_confirmed',


            },
            'IN_PROGRESS': {
                index: 2,
                title: 'order_activity_in_progress',

            },
            'IN PROGRESS': {
                index: 2,
                title: 'order_activity_in_progress',
            },
            'COMPLETED': {
                index: 3,
                title: 'order_activity_completed',
            },
            'CANCELLED': {
                index: 4,
                title: 'order_activity_cancelled',
            },
        }
        const indexOfCurrent = activitiesIndex[dataOfOrder?.status || 'New'].index || 0
        const activities = []

        dataOfOrder.activities.map((activitySingle, index) => {
            const activiti = {}
            let type
            if (indexOfCurrent === index) {
                if (dataOfOrder?.status === 'COMPLETED' || dataOfOrder?.status === 'CANCELLED') {
                    type = 'active'
                }
                else {
                    type = 'current'
                }
            }
            if (indexOfCurrent > index) {
                type = 'active'
            }
            if (indexOfCurrent < index) {
                type = 'inactive'
            }
            if (activitySingle.action === 'Cancelled') {
                activiti.reason = translate(dataOfOrder?.reason)
                activiti.type = type
                activiti.desc = activitySingle?.email
                activiti.time = !(activitySingle.createdAt) ? null : moment(activitySingle.createdAt).locale(LANGUAGES[lang]).format('MMM DD, YYYY HH:mm a')
                activiti.title = translate(activitiesIndex[activitySingle.action.toUpperCase()].title)
                activities.push(activiti)
                setIsOrderFinished({
                    color: colors.accent,
                    title: 'online_orders_cancelled_item',
                    cancelled: true,
                })
                return
            }

            activiti.type = type
            activiti.time = !(activitySingle.createdAt) ? null : moment(activitySingle.createdAt).locale(LANGUAGES[lang]).format('MMM DD, YYYY HH:mm ')
            activiti.desc = activitySingle?.email
            activiti.title = translate(activitiesIndex[activitySingle.action.toUpperCase()].title)
            activities.push(activiti)
        })
        setActivity([...activities])
        const response = []
        let section = {}
        section = {
            sectionTitle: 'order_details_label',
            data: [
                {
                    leftText: 'order_details_date_placed',
                    rightText: moment(dataOfOrder?.createdAt)
                        .locale(LANGUAGES[lang])
                        .format('ddd DD,YYYY HH:mm'),
                },
                {
                    leftText: 'order_details_type',
                    rightText: dataOfOrder?.fulfillmentType || '-',
                },
                {
                    leftText: 'order_details_price',
                    rightText: INF.format(dataOfOrder?.totalMoney?.amount) + ' ' + dataOfOrder?.totalMoney?.currency,
                },
                {
                    leftText: 'order_details_delivery_time',
                    rightText: moment(dataOfOrder?.supplyDateOrPeriod?.endDate)
                        .locale(LANGUAGES[lang])
                        .format('ddd DD, YYYY HH:mm'),
                },
            ],
        }
        response.push(section)
        const itemData = []
        dataOfOrder?.lineItems.map(item => {
            const desc = item?.quantity + ' x ' + item?.basePriceMoney?.amount + ' ' + item?.basePriceMoney?.currency
            const renderRightComponent = (basePriceMoney, quantity) => (
                <View>
                    <Text i18nKey={basePriceMoney?.amount * quantity + ' ' + basePriceMoney.currency || 'ALL'} />
                </View>
            )

            itemData.push({
                withIcon: true,
                leftComponent: renderIconLeftComponent(item.imageUrl
                    ? { uri: item.imageUrl }
                    : item.labelColor || colors.purple,
                    item.itemName),
                rightComponent: renderRightComponent(item?.basePriceMoney, item?.quantity),
                title: item?.itemName,
                subDescText: desc,

            })
        })

        section = {
            sectionTitle: 'order_details_lineItems',
            data: itemData,
        }
        response.push(section)
        const total = INF.format(dataOfOrder?.totalMoney?.amount) + ' ' + dataOfOrder?.totalMoney?.currency
        const totalDiscount = INF.format(dataOfOrder?.totalDiscountMoney?.amount || 0) + ' ' + dataOfOrder?.totalDiscountMoney?.currency
        const taxAmount = INF.format(dataOfOrder?.totalTaxMoney?.amount || 0 || 0) + ' ' + dataOfOrder?.totalTaxMoney?.currency
        let taxPercentage = 0
        dataOfOrder?.orderGroupedTaxes.map((item) => {
            taxPercentage = taxPercentage + (item?.VATRate || 0)
        })
        const taxLabel = translate('item_vat_label') + ' (' + taxPercentage + '%)'
        const subtotal = INF.format(new Big(dataOfOrder?.totalMoney?.amount || 0)) + ' ' + dataOfOrder?.totalMoney?.currency
        const totalWithoutTax = INF.format(new Big(dataOfOrder?.totalMoney?.amount || 0) - dataOfOrder?.totalTaxMoney?.amount || 0) + ' ' + dataOfOrder?.totalMoney?.currency
        section = {
            sectionTitle: 'order_details_lineItems',
            data: dataOfOrder?.lineItems,
        }

        section = {
            sectionTitle: 'order_details_total_details_label',
            data: [
                {
                    leftText: 'order_details_total_details_subtotal',
                    rightText: subtotal,
                },
                {
                    leftText: 'order_details_total_details_total',
                    rightText: total,
                },
                {
                    leftText: taxLabel,
                    rightText: taxAmount,
                },
                {
                    leftText: 'order_details_total_details_total_discount',
                    rightText: totalDiscount,
                },
                {
                    leftText: 'order_details_total_details_total_without_tax',
                    rightText: totalWithoutTax,
                },
            ],
        }
        response.push(section)
        if (dataOfOrder?.transaction) {
            if (!dataOfOrder?.transaction?.qrCode) {
                refetch()
            }
            setIsOrderFinished({
                color: colors.green,
                title: 'online_orders_completed_item',
                transaction: true,
            })
            setIsStatusLoading(false)
        }
        return response
    }

    //Remove null values
    function clean(obj) {
        for (var propName in obj) {
            if (obj[propName] === null || obj[propName] === undefined) {
                delete obj[propName]
            }
        }
        return obj
    }

    const removeUnnecessaryFields = (orderToBeModified) => {
        const copied = orderToBeModified
        delete copied.activities
        delete copied.transaction
        delete copied.createdAt
        delete copied.refundableQuantity
        copied.lineItems.map((item, index) => {
            delete copied.lineItems[index].refundableQuantity
        })
        delete copied.updatedAt
        copyOfOrder.current = clean(copied)
    }

    // Render functions
    const renderTabs = useMemo(() => {
        return (
            <Tabs
                initialRouteName={'Order'}
                routeContainerStyle={styles.scene}
                style={styles.tabView}
                tabContainerStyle={styles.tabFlexEnd}
                screenOptions={{
                    swipeEnabled: true,
                }}
            >
                <Tab.Screen key="1" name={translate('order_info_label')} value>
                    {(props) => (<ScrollWrapperWeb><SimpleList
                        sectionList
                        withSectionHeader
                        DATA={order}
                        refreshing={false}
                        renderItem={({ item, index }) => renderItem(item, index)}
                        SectionSeparatorComponent={renderSeparator}
                        renderSectionHeader={({ title }) => renderTitle(title)}
                        listFooterComponent={(renderTransactionFooter)}
                    /></ScrollWrapperWeb>)}
                </Tab.Screen>
                <Tab.Screen key="2" name={translate('order_activity_label')} >
                    {(props) => (<ScrollWrapperWeb contentContainerStyle={{ flex: 1 }} >
                        <View style={styles.tabMarginTop}>
                            <Timeline
                                items={activity}
                                titleStyle={styles.timelineItemTitle}
                                titleProps={{
                                    numberOfLines: 1,
                                    ellipsizeMode: 'clip',
                                }}
                                activeTitleStyle={styles.timelineActiveItemTitle}
                                iconContainerStyle={styles.timelineIconContainer}
                            />
                        </View>
                    </ScrollWrapperWeb>)}
                </Tab.Screen>
            </Tabs>
        )
    }, [order])

    const renderTransactionFooter = () => {
        if (isorderFinished?.transaction) {
            const { IIC, FIC, operatorCode, softwareCode, qrCode } = {} = data.getOrder?.transaction
            return (
                <View style={styles.footerWrapper}>
                    {qrCode &&
                        <View>
                            <Text i18nKey="order_invoice_label" />
                            <View>
                                <View styles={styles.qrWrapper}>
                                    <View style={styles.qrCode}>
                                        <QRCode
                                            RCode
                                            value={qrCode || 'https://efiskalizimi-app-test.tatime.gov.al/invoice-check/#/noData'}
                                            size={120}
                                            backgroundColor={colors.background}
                                            color={colors.secondaryIcon}
                                            styl
                                        />
                                    </View>
                                    <TouchableOpacity onPress={() => { Linking.openURL(qrCode || 'https://efiskalizimi-app-test.tatime.gov.al/invoice-check/#/noData') }} style={styles.linkWrapper}>
                                        <Icon source={images.link} />
                                        <Text i18nKey="order_invoice_link" size="body" style={styles.invoiceButton} />
                                    </TouchableOpacity>
                                </View>
                            </View>
                        </View>
                    }
                    {IIC && <View style={[styles.itemComponent, styles.isFirst_true]}>
                        <Text i18nKey="order_invoice_iix" />
                        <Text i18nKey={': '} />
                        <Text i18nKey={IIC} />
                    </View>}
                    {FIC && <View style={[styles.itemComponent]}>
                        <Text i18nKey="order_invoice_fix" />
                        <Text i18nKey={': '} />
                        <Text i18nKey={FIC} />
                    </View>}
                    {operatorCode && <View style={[styles.itemComponent]}>
                        <Text i18nKey="order_invoice_operator_code" />
                        <Text i18nKey={operatorCode} />
                    </View>}
                    {softwareCode && <View style={[styles.itemComponent, styles.isLast_true]}>
                        <Text i18nKey="order_invoice_software" />
                        <Text i18nKey={softwareCode} />
                    </View>}
                </View>
            )
        }
        return (
            <></>
        )
    }

    const renderTitle = (title) => {
        return (
            <View style={styles.listTitles}>
                <Text i18nKey={title} size="body" weight="regular" />
            </View>
        )
    }

    const renderIsTransaction = () => {
        if (isorderFinished?.transaction) {
            return <View style={styles.lastUpdate}>
                <Text i18nKey="order_transaction_label" size="body" weight="regular" />
                <Item {...data?.getOrder} onItemPress={() => navigate('Receipt', {
                    id: data?.getOrder?.transaction?.id,
                    receiptNumber: data?.getOrder?.transaction?.receiptNumber,
                    locationId: '26stsmctZOEc2VEqCQICZMz8J2Z',
                })}
                />
            </View>
        }
        return <View style={styles.rowFlexDate}>
            <Text i18nKey="order_details_last_update" weight="light" />
            <Text i18nKey={moment(data?.getOrder?.updatedAt).locale(LANGUAGES[lang]).format('ddd DD, YYYY') || '-'} weight="light" />
        </ View>
    }

    const renderItem = (item) => {
        return (
            <View style={[styles.itemWrapper, styles['isFirst_' + item?.isFirst], styles['isLast_' + item?.isLast]]}>
                <Text i18nKey={item?.leftText} weight="light" size="footnote" />
                <Text i18nKey={item?.rightText} size="body" />
            </View>
        )
    }

    const renderSeparator = () => <View style={styles.separator} />

    const renderIconLeftComponent = (icon, name) => {
        if (icon.uri) {
            return <Icon size={70} source={icon} resizeMode="cover" />
        } else {
            return (
                <View
                    // eslint-disable-next-line react-native/no-inline-styles
                    style={{
                        backgroundColor: Color(icon).fade(0.7).toString(),
                        height: '100%',
                        width: 70,
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}>
                    <Text
                        size="h5"
                        color={icon}
                        align="center"
                        i18nKey={name?.slice(0, 2)}
                    />
                </View>
            )
        }
    }

    const renderStatus = () => {
        if (isStatusLoading) {
            return <Loader size={36} source={animations.vfLoaderRed} />

        }
        if (status?.nextStatus === null) {
            return <RenderStatus status={status} />
        }
        return (
            <Button
                title={status?.label || ''}
                style={styles.inlineButton1}
                backgroundColor={colors.blue}
                borderWidth={0}
                color={colors.white}
                loader={isOrderProccesing}
                loaderComponent={<Loader source={animations.vfLoaderWhite} size={28} />}
                onPress={handleUpdateOrder}
            />
        )
    }

    const handleUpdateOrder = async () => {
        if (copyOfOrder.current.status === 'IN_PROGRESS') {
            setIsStatusLoading(true)
            handlePay()
        }
        else {
            setIsOrderProccesing(true)
            try {
                await updateOrder({
                    variables: {
                        orderUpdateInput: { ...copyOfOrder.current, status: status.nextStatus }, locationId: locationId,
                        cashRegisterId: deviceId
                    }
                }).then(() => { }).catch(
                    (error) => {
                        const reasonError = getErrorMessage(error)
                        setIsStatusLoading(false)
                        setErrorMessage(reasonError)
                        setIsError(true)
                    }
                )
            } catch (error) {
                setIsOrderProccesing(false)
                handleError(error)
            }
        }
    }

    const openModal = (
        title = 'opened_orders_error',
        desc = '',
        descPlaceholders = [],
        primaryText = '',
        primaryAction = null,
        secondaryText = '',
        secondaryAction = null,
        icon = images.warningIcon,
    ) => {
        setModal({
            isVisible: true,
            icon,
            title,
            desc,
            descPlaceholders,
            primaryText,
            primaryAction,
            secondaryText,
            secondaryAction,
        })
    }

    const closeBigModal = () => {
        setModal({
            isVisible: false,
            icon: images.delete,
            title: '',
            desc: '',
            primaryText: '',
            primaryAction: null,
            secondaryText: '',
            secondaryAction: null,
        })
    }

    const handlePay = async () => {
        const { getOrder: { id: idForPayOrder, totalMoney } } = data
        try {
            await payOrder({
                variables: {
                    locationId: locationId,
                    cashRegisterId: deviceId,
                    payOrderRequest: {
                        idempotencyKey: uuidv4(),
                        orderId: idForPayOrder,
                        amountMoney: totalMoney,
                        type: 'ORDER',
                    },
                },
            })
            refetch()
            setIsOrderFinished({
                color: colors.green,
                title: 'online_orders_completed_item',
            })
        } catch (error) {
            const errorMessageCatched = getErrorMessage(error)
            if (errorMessageCatched === 'OrderNoOpenDrawer') {
                openModal(
                    'receipt_drawer_warning_title',
                    'checkout_drawer_error_message',
                    [],
                    'receipt_button_open_drawer',
                    () => {
                        navigate('CurrentDrawer', { nextAction: 'order_continue_to_order' })
                        closeBigModal()
                    },
                    'orders_button_go_back',
                    () => closeBigModal(),
                )
            }
            setIsStatusLoading(false)
        }
    }

    const closeModal = () => {
        setModal(prev => ({ ...prev, isVisible: false }))
    }

    const handleError = (unFilteredMessage) => {
        const message = getErrorMessage(unFilteredMessage)
        openModal(
            'checkount_error_message',
            message,
            [],
            'orders_button_go_back',
            () => {
                closeBigModal()
                goBack()
            },
        )

    }

    return (
        <>
            <SafeAreaView style={styles.container}>
                <Header
                    image={images.closeIcon}
                    loading={loading}
                    title={copyOfOrder?.current?.receiptNumber || translate('order_details_label')}
                    onPress={() => goBack()}
                />
                <View style={styles.inlineButtons}>
                    {isOrderActive && <Button
                        icon={images.refused}
                        color={colors.black}
                        title="cancel_order_title"
                        backgroundColor={colors.white}
                        style={styles.inlineButton2}
                        onPress={() => navigate('CancelOrder', { id: id })}
                        titleStyle={styles.buttonTitle}
                        iconColor={colors.primaryText}
                        loader={shareLoading}
                        loaderComponent={<Loader source={animations.vfLoaderThinDark} size={24} />}
                    />}
                </View>
                {!loading ?
                    <View style={styles.contentContainer}>
                        <View style={styles.horizontalPadding}>
                            {renderStatus()}
                        </View>
                        {renderIsTransaction()}
                        {renderTabs}
                    </View> : <ItemLoader />}
            </SafeAreaView>
            <ErrorModal
                isVisible={isError}
                icon={images.error}
                title="checkount_error_message"
                description={errorMessage}
                onClosePress={() => goBack()}
            />
            <Modal
                isVisible={modal.isVisible}
                onBackButtonPress={() => closeModal()}
                onBackdropPress={() => closeModal()}
                animationIn="fadeIn"
                animationOut="fadeOut">
                <ModalContent
                    onClose={() => closeModal()}
                    contentContainerStyle={styles.modalContentSecond}>
                    <Icon source={modal.icon} size={40} />
                    <Text
                        i18nKey={modal.title}
                        align="center"
                        size="h4"
                        weight="bold"
                        style={styles.modalTitle}
                    />
                    <Text
                        i18nKey={modal.desc}
                        numberOfLines={10}
                        placeHolders={modal.descPlaceholders}
                        align="center"
                    />
                    {modal.primaryAction && (
                        <Button
                            title={modal.primaryText}
                            variant="active"
                            style={styles.modalPrimaryButton}
                            onPress={() => modal.primaryAction?.()}
                        />
                    )}
                    {modal.secondaryAction && (
                        <Button
                            title={modal.secondaryText}
                            variant="outline"
                            style={styles.modalSecondaryButton}
                            onPress={() => modal.secondaryAction?.()}
                        />
                    )}
                </ModalContent>
            </Modal>
        </>
    )
}

OrderDetails.propTypes = {
    navigation: PropTypes.object,
    route: PropTypes.object,
}

export default OrderDetails
