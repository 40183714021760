/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useRef, useState } from 'react'
import {
  View,
  TouchableOpacity,
  FlatList,
  TouchableWithoutFeedback,
} from 'react-native'
import debounce from 'lodash.debounce'
import { useTheme } from 'react-native-themed-styles'
import PropTypes from 'prop-types'

import Text from '../Text'
import Icon from '../Icon'
import TextInput from '../TextInput'
import Type from '../Type'

import ContentManager from '../../Utils/ContentManager'

import Theme from '../../Themes/Theme'

import images from '../../Themes/Images'

import themedStyles from './SelectType.Styles'

const { translate } = ContentManager

function SelectType({
  data = [],
  closeModal,
  selected,
  select,
  title,
  searchValue,
  setSearchValue,
  placeholder,
  searchKey,
  Item,
  containerStyle,
  withSearch,
}) {
  const [styles] = useTheme(themedStyles)
  const { colors } = useContext(Theme)
  const inputRef = useRef()
  const [items, setItems] = useState(data)
  useEffect(() => {
    let newItems = []

    if (Array.isArray(data)) {
      const filterSelf = (item) => item?.toLowerCase().includes(searchValue.toLowerCase())
      const filterKey = ({ [searchKey]: key } = {}) => key?.toLowerCase().includes(searchValue.toLowerCase())
      const filter = searchKey === 'self' ? filterSelf : filterKey
      newItems = data?.filter(filter)
    }
    setItems(newItems)
  }, [data, searchValue])


  const keyExtractor = (_, index) => 'item_' + index
  const renderItem = ({ item }) => (
    <Item
      item={item}
      selected={selected}
      onPress={() => {
        select(item)
        closeModal()
      }}
    />
  )

  const updateSearchValue = debounce(text => setSearchValue(text), 300)
  const resetSearchValue = () => {
    inputRef.current?.clear()
    updateSearchValue.cancel()
    setSearchValue('')
  }

  return (
    <>
      <View style={styles.header}>
        <Text
          i18nKey={title}
          weight="bold"
          size="h4"
          color={colors.accent}
        />
        <TouchableOpacity onPress={closeModal}>
          <Icon source={images.close} color={colors.secondaryIcon} size={24} />
        </TouchableOpacity>
      </View>
      {withSearch && <View style={styles.searchContainer}>
        <Icon
          source={images.search}
          size={20}
          style={styles.searchIcon}
        />
        <TextInput
          ref={ref => { inputRef.current = ref }}
          placeholder={translate(placeholder)}
          onChangeText={updateSearchValue}
          placeholderTextColor={colors.disabled}
          spellCheck={false}
          textContentType="none"
          underlineColorAndroid="transparent"
          style={styles.searchInput}
        />
        {Boolean(searchValue) && (
          <TouchableOpacity
            onPress={resetSearchValue}
            style={styles.clearSearchContainer}
          >
            <Icon source={images.close} color={colors.secondaryIcon} size={15} />
          </TouchableOpacity>
        )}
      </View>}
      <TouchableWithoutFeedback>
        <FlatList
          data={items}
          onStartShouldSetResponder={() => true}
          decelerationRate={0.8}
          keyExtractor={keyExtractor}
          renderItem={renderItem}
          style={styles.listStyle}
          contentContainerStyle={[styles.listContainerStyle, containerStyle]}
        />
      </TouchableWithoutFeedback>
    </>
  )
}
SelectType.defaultProps = {
  Item: Type,
  withSearch: true,
}
SelectType.propTypes = {
  data: PropTypes.array,
  select: PropTypes.func,
  selected: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  closeModal: PropTypes.func,
  title: PropTypes.string,
  searchValue: PropTypes.string,
  setSearchValue: PropTypes.func,
  placeholder: PropTypes.string,
  searchKey: PropTypes.string,
  Item: PropTypes.any,
  containerStyle: PropTypes.object,
  withSearch: PropTypes.bool,
}

export default SelectType
