import calculatePrice, { getLineItemIndex } from '../../Utils/Order'

import {
  CURRENCY,
  RESET_ORDER,
  ORDER,
  ADD_ITEM,
  REMOVE_ITEM,
  UPDATE_ITEM,
} from './Checkout.Actions'

const initOrder = {
  currency: {
    code: 'Lek (ALL)',
    type: 'Lek',
    currencyCode: 'ALL',
    description: '',
    descPlaceHolders: [],
    rate: 1,
    isBuyer: false,
  },
  order: {
    lineItems: [],
    appliedDiscounts: [],
    totalMoney: {
      amount: 0,
      currency: 'ALL',
    },
    totalTaxMoney: {
      amount: 0,
      currency: 'ALL',
    },
    totalDiscountMoney: {
      amount: 0,
      currency: 'ALL',
    },
  },
}

function checkoutData(
  state = initOrder,
  action,
) {
  const { type, value } = action || {}
  switch (type) {
    case CURRENCY:
      return {
        ...state,
        currency: value,
      }

    case RESET_ORDER:
      return {
        currency: state.currency,
        order: {
          lineItems: [],
          appliedDiscounts: [],
          totalMoney: {
            amount: 0,
            currency: 'ALL',
          },
          totalTaxMoney: {
            amount: 0,
            currency: 'ALL',
          },
          totalDiscountMoney: {
            amount: 0,
            currency: 'ALL',
          },
        },
      }

    case ORDER:
      return {
        ...state,
        order: { ...value },
      }

    case UPDATE_ITEM:
      const { item, index } = value
      const orderWithUpdatedItem = state.order
      orderWithUpdatedItem.lineItems.splice(index, 1, item)
      const updatedOrder = calculatePrice(orderWithUpdatedItem)

      return {
        ...state,
        order: { ...updatedOrder },
      }

    case ADD_ITEM:
      const newOrder = state.order
      const exIndex = getLineItemIndex(newOrder, value)
      if (exIndex === -1) {
        newOrder.lineItems.unshift(value)
      } else {
        newOrder.lineItems[exIndex].quantity += value.quantity
      }
      const orderWithPrice = calculatePrice(newOrder)
      return {
        ...state,
        order: { ...orderWithPrice },
      }

    case REMOVE_ITEM:
      const orderLineItems = state.order
      const itemIndex = getLineItemIndex(orderLineItems, value)
      if (itemIndex >= 0) {
        orderLineItems.lineItems.splice(itemIndex, 1)
      }
      const calculatedOrder = calculatePrice(orderLineItems)
      return {
        ...state,
        order: { ...calculatedOrder },
      }

    default:
      return state
  }
}

export {
  checkoutData,
}
