/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react'
import {
  SafeAreaView,
  TouchableOpacity,
  View,
  useWindowDimensions,
} from 'react-native'
import { SwipeRow } from 'react-native-swipe-list-view'
import PropTypes from 'prop-types'
import { useTheme } from 'react-native-themed-styles'
import { useLazyQuery, useMutation } from '@apollo/client'

import moment from 'moment'

import {
  SwipeList,
  ButtonGroup,
  ModalContent,
  Modal,
  Icon,
  Text,
  Loader,
  ListLoader,
} from '../../../Components'

import { getErrorMessage, INF } from '../../../Utils'
import { useLanguage } from '../../../Utils/Language'

import { LIST_INVOICES, CHANGE_STATUS } from '../EInvoice.Schema'

import { images, animations, colors } from '../../../Themes'
import themedStyles, { itemStyles, hiddenItemStyles } from '../EInvoice.Styles'

const statusIcons = {
  REFUSED: {
    image: images.refused,
    color: colors.accent,
  },
  DELIVERED: {
    image: images.waiting,
    color: colors.secondaryAccent,
  },
  PARTIALLY_PAID: {
    image: images.waiting,
    color: colors.secondaryAccent,
  },
  PAID: {
    image: images.waiting,
    color: colors.secondaryAccent,
  },
  ACCEPTED: {
    image: images.accepted,
    color: colors.green,
  },
}

const LANGUAGES = {
  al: 'sq',
  en: 'en-gb',
}

function EmptyItem() {
  return null
}

function SectionItem({ sectionTitle }) {
  const [styles] = useTheme(themedStyles)

  return (
    <Text
      i18nKey={sectionTitle}
      size="footnote"
      color={colors.secondaryText}
      style={styles.sectionTitle}
    />
  )
}
SectionItem.propTypes = {
  sectionTitle: PropTypes.string,
}

function Invoice({
  type,
  nipt,
  partyType,
  createdAt,
  title,
  amount,
  onPress,
  isFirst,
  isLast,
}) {
  const [styles] = useTheme(itemStyles)

  return <View style={[
    styles.myContainer,
    styles['myFirstItem_' + isFirst],
    styles['lastItem_' + isLast],
  ]}>
    <TouchableOpacity
      onPress={onPress}
      style={styles.touchable}
    >
      <View style={styles.textContainer}>
        <Icon source={statusIcons?.[type]?.image} color={statusIcons?.[type]?.color} />
        <View style={styles.leftData}>
          <Text i18nKey={title} />
          {/* <Text i18nKey={type} weight="light" size="footnote" numberOfLines={1} />
          <Text i18nKey={createdAt} weight="light" size="footnote" numberOfLines={1} /> */}
          <Text i18nKey={createdAt} weight="light" size="footnote" numberOfLines={1} />
          <Text i18nKey={nipt} weight="light" size="footnote" numberOfLines={1} />
        </View>
      </View>
      <View style={styles.rightComponent}>
        <View style={styles.rightData}>
          <Text i18nKey={amount} />
          <Text i18nKey={partyType} size="footnote" align="right" />
        </View>
        <Icon
          source={images.chevronRight}
          size={26}
          color={colors.accent}
          style={styles.rightIcon}
        />
      </View>

    </TouchableOpacity>
  </View>
}
Invoice.propTypes = {
  type: PropTypes.string,
  nipt: PropTypes.string,
  partyType: PropTypes.string,
  createdAt: PropTypes.string,
  title: PropTypes.string,
  amount: PropTypes.string,
  onPress: PropTypes.func,
  isFirst: PropTypes.bool,
  isLast: PropTypes.bool,
}

function HiddenItem({
  needsConfirmation,
  onAccept,
  onRefuse,
  onAcceptConfirm,
  onRefuseConfirm,
  onCancel,
  shouldAccept,
  loading,
  isFirst,
  isLast,
}) {
  const [styles] = useTheme(hiddenItemStyles)
  const onConfirm = shouldAccept ? onAcceptConfirm : onRefuseConfirm

  return (
    <View style={[styles.wrapper, styles.optionFulll(shouldAccept), styles['firstItem_' + isFirst], styles['lastItem_' + isLast]]}>
      {loading
        ? <View style={styles.loader}>
          <Loader size={32} color={colors.white} source={animations.vfLoaderWhite} />
        </View>
        : needsConfirmation
          ? <View style={styles.options}>
            <TouchableOpacity
              activeOpacity={0.7}
              style={styles.hiddenItem}
              onPress={onAccept}
            >
              <Icon source={images.accepted} color={colors.white} size={24} />
              <Text i18nKey="einvoice_accept_button" color="white" />
            </TouchableOpacity>
            <TouchableOpacity
              activeOpacity={0.7}
              style={styles.option}
              onPress={onRefuse}
            >
              <Icon source={images.refused} color={colors.white} size={24} />
              <Text i18nKey="einvoice_refuse_button" color="white" />
            </TouchableOpacity>
          </View>

          : <View style={styles.optionFull(shouldAccept)}>
            <TouchableOpacity
              style={styles.confirm}
              onPress={onConfirm}
            >
              <Icon source={shouldAccept ? images.accepted : images.refused} color={colors.white} size={24} />
              <Text i18nKey={shouldAccept ? 'einvoice_accept_button' : 'einvoice_refuse_button'} color={colors.white} style={styles.confirmText} />
            </TouchableOpacity>
            <TouchableOpacity
              style={styles.cancel}
              onPress={onCancel}
            >
              <Icon source={images.closeIcon} color={colors.white} size={20} />
            </TouchableOpacity>
          </View>
      }
    </View>
  )
}
HiddenItem.propTypes = {
  needsConfirmation: PropTypes.bool,
  onAccept: PropTypes.func,
  onRefuse: PropTypes.func,
  onAcceptConfirm: PropTypes.func,
  onRefuseConfirm: PropTypes.func,
  shouldAccept: PropTypes.bool,
  onCancel: PropTypes.func,
  loading: PropTypes.bool,
  isFirst: PropTypes.bool,
  isLast: PropTypes.bool,
}

const listItems = {
  section: SectionItem,
  DELIVERED: Invoice,
  REFUSED: Invoice,
  ACCEPTED: Invoice,
  PARTIALLY_PAID: Invoice,
  PAID: Invoice,
}

const hiddenItems = {
  section: EmptyItem,
  DELIVERED: HiddenItem,
  REFUSED: EmptyItem,
  ACCEPTED: EmptyItem,
  PARTIALLY_PAID: EmptyItem,
  PAID: EmptyItem,
}

function EInvoice({ navigation: { navigate }, selectedDates }) {
  const [styles] = useTheme(themedStyles)
  const { width, height } = useWindowDimensions()
  const lang = useLanguage()

  const [needsConfirmation, setNeedForConfirmation] = useState(true)
  const [eInvoices, setEinvoices] = useState({
    invoices: [],
    loading: true,
  })
  const [shouldAccept, setShouldAccept] = useState(true)
  const [itemWidth, setItemWidth] = useState(0)
  const [refetching, setRefetching] = useState(false)

  const [selected, setSelected] = useState('all')
  const sectionDate = useRef('-')

  const types = [
    {
      title: 'users_all_filter',
      type: 'all',
      list: () => list(),
    },
    {
      title: 'einvoice_buyer_filter',
      type: 'BUYER',
      list: () => filteredList('BUYER'),
    },
    {
      title: 'einvoice_seller_filter',
      type: 'SELLER',
      list: () => filteredList('SELLER'),
    },
  ]

  const [listInvoices, { data, refetch, called, networkStatus }] = useLazyQuery(LIST_INVOICES, { fetchPolicy: 'network-only', notifyOnNetworkStatusChange: true })

  const list = () => {
    listInvoices({
      fetchPolicy: 'network-only',
      variables: {
        limit: 20,
      },
    })
  }

  const filteredList = (val) => {
    setSelected(val)
    listInvoices({
      fetchPolicy: 'network-only',
      variables: {
        limit: 20,
        partyType: val,
      },
    })
  }

  const [isErrorActive, setErrorActive] = useState({
    isVisible: false,
    message: '',
  })

  const [changeStatus, {
    loading: statusLoading,
  }] = useMutation(CHANGE_STATUS)

  useEffect(() => {
    const invoices = data?.getEinvoices?.eInvoices
    if (Array.isArray(invoices)) {
      const newArray = mapData(invoices)
      if (refetching) {
        setEinvoices({
          invoices: [],
          loading: true,
        })
      }
      setEinvoices(({ invoices: prevInvoices }) => ({
        invoices: prevInvoices.concat(newArray),
        loading: false,
      }))
    }
  }, [data])

  useEffect(() => {
    const index = types.findIndex((el) => el.type === selected)
    setRefetching(false)
    setEinvoices({
      invoices: [],
      loading: true,
    })
    types[index].list()
  }, [selected])

  useEffect(() => {
    setEinvoices({
      invoices: [],
      loading: true,
    })
  }, [width, height])

  const mapData = (unmappedData) => {
    const response = []
    let isFirst = false, isLast = false
    sectionDate.current = null
    unmappedData?.map((item, index) => {
      const itemDetails = item?.attributes
      const status = itemDetails?.Status
      isFirst = false
      isLast = false
      const nipt = itemDetails?.BuyerTin || itemDetails?.SellerTin || ''
      const itemTime = itemDetails?.RecDateTime
      const date = moment(itemTime).locale(LANGUAGES[lang]).format('MMM DD, YYYY')
      if (date !== sectionDate.current) {
        if (response?.[response.length - 1]) {
          response[response.length - 1].isLast = true
        }
        sectionDate.current = date
        response.push({
          id: date,
          sectionTitle: date,
          type: 'section',
        })
      }

      if (response?.[response.length - 1]?.type === 'section') {
        isFirst = true
        isLast = false
      }
      response.push({
        id: itemDetails?.EIC,
        type: status,
        nipt: nipt,
        createdAt: moment(itemDetails?.RecDateTime).format('hh:mm A'),
        title: itemDetails?.DocNumber,
        amount: INF.format(itemDetails?.Amount),
        partyType: itemDetails?.PartyType,
        isFirst: isFirst,
        isLast: isLast,
      })
    })
    if (response?.[response.length - 1]) {
      response[response.length - 1].isLast = true
    }
    return response
  }

  const openRow = (rowMap, id) => {
    setShouldAccept(true)
    setNeedForConfirmation(false)
    rowMap[id].manuallySwipeRow(-itemWidth)
  }

  const openRefuseRow = (rowMap, id) => {
    setShouldAccept(false)
    setNeedForConfirmation(false)
    rowMap[id].manuallySwipeRow(-itemWidth)
  }

  const closeRow = (rowMap, id) => {
    setShouldAccept(true)
    rowMap[id].closeRow()
    setNeedForConfirmation(true)
  }

  const changeEinvoiceStatus = (rowMap, id, status) => {
    changeStatus({
      variables: {
        eics: [id],
        status,
      },
    }).then(() => {
      closeRow(rowMap, id)
      refetch()
    }).catch((er) => {
      setErrorActive({
        isVisible: true,
        message: er,
      })
      closeRow(rowMap, id)
    })
  }

  useEffect(() => {
    setEinvoices((prev) => ({
      ...prev,
      loading: true,
      invoices: [],
    }))
    if (selectedDates) {
      if (Object.keys(selectedDates).length > 0) {
        let vars = {}
        const partyTypes = ['BUYER', 'SELLER']
        if (partyTypes.includes(selected)) {
          vars = {
            limit: 20,
            partyType: selected,
            fromDateTime: selectedDates?.startingDay,
            toDateTime: selectedDates?.endingDay,
          }
        } else {
          vars = {
            limit: 20,
            fromDateTime: selectedDates?.startingDay,
            toDateTime: selectedDates?.endingDay,
          }
        }
        listInvoices({
          variables: vars,
        })
      } else {
        list()
      }
    }
  }, [selectedDates])

  const renderItem = (rowData, rowMap) => {
    const { item } = rowData
    const ListItem = listItems[item?.type]
    const ListHiddenItem = hiddenItems[item?.type]
    return (
      <SwipeRow
        key={'row-' + item?.id}
        disableRightSwipe
        disableLeftSwipe={item?.type === 'DELIVERED' ? (item?.partyType === 'BUYER' ? false : true) : true}
        rightOpenValue={-200}
        closeOnRowPress
        recalculateHiddenLayout={false}
        useNativeDriver={true}
      >
        <ListHiddenItem
          key={'row-' + item?.id}
          needsConfirmation={needsConfirmation}
          shouldAccept={shouldAccept}
          onAccept={() => openRow(rowMap, item.id)}
          onRefuse={() => openRefuseRow(rowMap, item.id)}
          onAcceptConfirm={() => changeEinvoiceStatus(rowMap, item.id, 'ACCEPTED')}
          onRefuseConfirm={() => changeEinvoiceStatus(rowMap, item.id, 'REFUSED')}
          onCancel={() => closeRow(rowMap, item.id)}
          isFirst={item.isFirst}
          isLast={item.isLast}
          loading={statusLoading}
        />
        <ListItem
          {...item}
          sectionTitle={item?.sectionTitle}
          key={'row-' + item?.id}
          onPress={() => navigate('EInvoiceDetails', { id: item?.id, title: item?.title, status: item?.type, isMyInvoice: false, partyType: item?.partyType })}
        />
      </SwipeRow>
    )
  }

  const onEndReached = () => {
    if (!data?.getEinvoices?.nextToken) {
      return
    }
    if (networkStatus === 3) {
      return
    }
    setRefetching(false)
    setEinvoices((prev) => ({
      ...prev,
      loading: true,
    }))
    listInvoices({
      fetchPolicy: 'network-only',
      variables: {
        limit: 20,
        nextToken: data.getEinvoices.nextToken,
        partyType: selected === 'all' ? null : selected,
      },
    })
  }

  const onRefresh = () => {
    setRefetching(true)
    if (called) {
      refetch()
    }
  }

  return (
    <SafeAreaView style={styles.container}>
      <SwipeList
        data={eInvoices?.invoices}
        renderItem={renderItem}
        disableRightSwipe
        onRowClose={() => setNeedForConfirmation(true)}
        setItemWidth={setItemWidth}
        onEndReached={onEndReached}
        buttonVariant="active"
        ListHeaderComponent={<ButtonGroup
          selected={selected}
          onPress={setSelected}
          options={types}
          style={styles.headerStyles}
        />}
        style={styles.allInvoiceList}
        onEndReachedThreshold={0.2}
        ListFooterComponent={eInvoices.loading && <ListLoader loading={eInvoices.loading} />}
        ListEmptyComponent={eInvoices.loading ? null : <Text weight="light" i18nKey="einvoice_list_empty_message" style={styles.emptyComponent} />}
        refreshing={false}
        onRefresh={onRefresh}
      />
      <Modal
        isVisible={isErrorActive.isVisible}
        onBackButtonPress={() => setErrorActive({
          isVisible: false,
          message: '',
        })}
        onBackdropPress={() => setErrorActive({
          isVisible: false,
          message: '',
        })}
        animationIn="fadeIn"
        animationOut="fadeOut"
      >
        <ModalContent onClose={() => setErrorActive({
          isVisible: false,
          message: '',
        })}>
          <Icon source={images.delete} style={styles.errorIcon} />
          <Text i18nKey="orders_error_label" color={colors.accent} size="h5" align="center" weight="bold" style={styles.errorTitle} />
          <Text i18nKey={getErrorMessage(isErrorActive.message)} align="center" />
        </ModalContent>
      </Modal>
    </SafeAreaView>
  )
}
EInvoice.propTypes = {
  navigation: PropTypes.object,
  selectedDates: PropTypes.object,
}

export default EInvoice
