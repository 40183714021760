import React, { useState, useContext } from 'react'
import PropTypes from 'prop-types'
import { useTheme } from 'react-native-themed-styles'
import { SafeAreaView, View, FlatList, TouchableOpacity } from 'react-native'

import { Icon, Text, Header, Button } from '../../Components'

import themedStyles from './Invoices.Styles'
import images from '../../Themes/Images'
import Theme from '../../Themes/Theme'

function Item({
    onPress,
    image,
    title,
    icon,
    style,
}) {
    const [styles] = useTheme(themedStyles)
    const { colors } = useContext(Theme)
    return (
        <TouchableOpacity
            onPress={onPress}
            style={[styles.listInfoContainer, style]}>
            <Icon source={image} size={28} style={styles.rightSpacing} />
            <View style={styles.titleContainer}>
                <Text i18nKey={title} />
            </View>
            <Icon source={icon} size={24} color={colors.accent} />
        </TouchableOpacity>
    )
}
Item.propTypes = {
    onPress: PropTypes.func,
    image: PropTypes.any,
    title: PropTypes.string,
    icon: PropTypes.any,
    style: PropTypes.object,
}

function Invoices({ navigation: { navigate, openDrawer, goBack } }) {

    const [styles] = useTheme(themedStyles)

    const onSearchPress = () => {
        navigate('SearchGovInvoices')
    }

    const [data] = useState(() => [
        {
            id: 'transactions',
            image: images.cashInvoice,
            title: 'menu_transactions_label',
            icon: images.chevronRight,
            onPress: () => navigate('Transactions'),
        },
        {
            id: 'einvoice',
            image: images.nonCashInvoice,
            title: 'menu_einvoice_label',
            icon: images.chevronRight,
            onPress: () => navigate('EInvoice'),
        },
        {
            id: 'supportinginvoice',
            image: images.wtnInvoice,
            title: 'menu_supporting_invoice_label',
            icon: images.chevronRight,
            onPress: () => navigate('SupportingInvoice'),
        },
    ])

    const keyExtractor = item => 'row-' + item.id
    const getItemLayout = (_, index) => ({
        length: 60,
        offset: 61 * index,
        index,
    })

    const renderItem = ({ item }) => <Item {...item} />

    return (
        <SafeAreaView style={styles.container}>
            <Header title="menu_invoices_label" image={images.menu} onPress={() => openDrawer()} />
            <FlatList
                showsVerticalScrollIndicator={false}
                keyExtractor={keyExtractor}
                getItemLayout={getItemLayout}
                data={data}
                style={styles.listStyle}
                contentContainerStyle={styles.listContainerStyle}
                ListFooterComponent={
                    <Button
                        variant='secondary'
                        title='old_invoices_button'
                        style={styles.button}
                        icon={images.search}
                        onPress={onSearchPress}
                    />}
                renderItem={renderItem}
                refreshing={false}
            />


        </SafeAreaView>
    )
}

Invoices.propTypes = {
    navigation: PropTypes.object,
}

export default Invoices
