import { Platform } from 'react-native'
import Color from 'color'

import { styleSheetFactory } from '../../Themes/Colors'

const styles = styleSheetFactory(colors => ({
  container: {
    flex: 1,
  },
  iconHeader: {
    alignItems: 'center',
  },
  loaderContainer: {
    backgroundColor: colors.white,
    width: 110,
    height: 110,
    overflow: 'hidden',
    borderRadius: 7,
    justifyContent: 'center',
    alignItems: 'center',
  },

  //Tabs
  tabView: {
    backgroundColor: colors.background,
    paddingTop: 0,
  },
  scene: {
    flex: 1,
    backgroundColor: colors.background,
  },
  label: {
    fontFamily: 'Vodafone Rg',
    textTransform: 'none',
    fontSize: Platform.OS === 'web' ? 15 : 13,
  },
  indicatorStyle: {
    backgroundColor: colors.tabAccent,
  },
  tabContainerStyle: {
    backgroundColor: colors.tertiaryBackground,
    elevation: 0,
    shadowRadius: 0,
    shadowOpacity: 0,
    marginBottom: 0,
  },

  searchContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: colors.tertiaryBackground,
    height: 50,
    borderRadius: 7,
    margin: 15,
    marginTop: 5,
  },
  iconContainer: {
    padding: 10,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: colors.backgroundRGBA(0.5),
    borderRadius: 20,
    marginRight: 10,
    width: 30,
    height: 30,
  },
  icon: {
    width: 15,
    height: 15,
    margin: 15,
    resizeMode: 'contain',
    tintColor: colors.secondaryIcon,
  },
  input: {
    flexGrow: 1,
    fontSize: 16,
    fontFamily: 'Vodafone Lt',
    color: colors.primaryText,
  },
  button: {
    marginTop: 5,
    marginBottom: 15,
    marginHorizontal: 15,
    height: 50,
  },
  buttonTitle: {
    fontSize: 18,
  },
  plusIcon: {
    tintColor: colors.white,
    height: 22,
    width: 22,
  },
  horizontalSpacing: { marginHorizontal: 4 },
  rowView: { flexDirection: 'row' },
}))

const routeStyles = styleSheetFactory(colors => ({
  emptyComponent: {
    alignSelf: 'center',
    flexGrow: 1,
    alignItems: 'center',
    marginTop: 30,
  },
  verticalSpacing: { marginVertical: 15 },
}))

const itemStyles = styleSheetFactory(colors => ({
  container: {
    height: 70,
    backgroundColor: colors.tertiaryBackground,
    justifyContent: 'center',
    overflow: 'hidden',
    marginVertical: 3,
    borderRadius: 7,
  },
  touchable: {
    flexGrow: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    position: 'relative',
  },
  imageContainer: labelColor => {
    return {
      width: 70,
      height: '100%',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: Color(labelColor).fade(0.65).toString(),
    }
  },
  infoContainer: {
    flexGrow: 1,
    flexShrink: 1,
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
    padding: 15,
    paddingLeft: 5,
  },
  descContainer: {
    flexGrow: 1,
    flexShrink: 1,
  },
  priceContainer: {
    flexDirection: 'column',
    alignItems: 'baseline',
    flexShrink: 0,
    marginLeft: 10,
  },
}))


const hiddenItemStyles = styleSheetFactory(colors => ({
  wrapper: {
    backgroundColor: colors.accent,
    height: 70,
    justifyContent: 'center',
    alignItems: 'flex-end',
    marginVertical: 3,
    borderRadius: 7.5,
  },
  option: {
    width: 100,
    height: 70,
    justifyContent: 'center',
    alignItems: 'center',
  },
  optionFull: {
    flexDirection: 'row',
  },
  confirm: {
    flexGrow: 1,
    height: 70,
    flexDirection: 'row',
    paddingLeft: 15,
    alignItems: 'center',
  },
  confirmText: {
    marginLeft: 15,
  },
  cancel: {
    width: 40,
    height: 40,
    flexShrink: 0,
    marginVertical: 15,
    marginHorizontal: 15,
    borderRadius: 20,
    backgroundColor: colors.whiteRGBA(0.55),
    justifyContent: 'center',
    alignItems: 'center',
  },
  loader: {
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
}))


export { routeStyles, itemStyles, hiddenItemStyles }

export default styles
