import React, { useContext } from 'react'
import { SafeAreaView } from 'react-native'
import { useTheme } from 'react-native-themed-styles'
import PropTypes from 'prop-types'

import { Icon, Header } from '../../Components'
import ListContainer from '../../Components/ListContainer'

import themedStyles from './CashDrawer.Styles'
import images from '../../Themes/Images'
import Theme from '../../Themes/Theme'

function CashDrawer({ navigation: { navigate, openDrawer } }) {
  const [styles] = useTheme(themedStyles)
  const { colors } = useContext(Theme)

  function getIcon(icon, style = {}, size = 16, color = colors.primaryIcon) {
    return (
      <Icon
        source={icon}
        size={size}
        color={color}
        style={{ ...styles.primaryIcon, ...style }}
      />
    )
  }
  const listItems = [
    {
      id: '9c4195a8-7861-11ea-bc55-0242ac130003',
      title: 'drawer_current',
      rightComponent: getIcon(images.arrow_right),
      image: images['cash-drawer'],
      imageStyle: styles.listIcon,
      onPress: () => navigate('CurrentDrawer'),
    },
    {
      id: '9c419698-7861-11ea-bc55-0242ac130003',
      title: 'drawer_history',
      rightComponent: getIcon(images.arrow_right),
      image: images.cashDrawer,
      imageStyle: styles.listIcon,
      onPress: () => navigate('CashDrawerHistory'),
    },
  ]

  return (
    <>
      <SafeAreaView style={styles.container}>
        <Header
          title="header_cash_drawer"
          image={images.menu}
          onPress={() => openDrawer()}
        />
        <ListContainer
          items={listItems}
          showCreateButton={false}
          showSearch={false}
          swipeLeft={false}
          swipeRight={false}
          useHidden={false}
          useDivider={false}
          listItemStyle={{
            leftImageStyle: {
              width: 25,
              tintColor: colors.secondaryIcon,
            },
            itemContainer: {
              ...styles.listItem,
            },
          }}
        />
      </SafeAreaView>
    </>
  )
}

CashDrawer.propTypes = {
  navigation: PropTypes.object,
}

export default CashDrawer
