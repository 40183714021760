import { styleSheetFactory } from '../../Themes/Colors'

const styles = styleSheetFactory(colors => ({
    container: {
        flex: 1,
        backgroundColor: colors.background,
    },
    contentContainer: {
        marginTop: 30,
    },
    syncButton: {
        height: 50,
        marginBottom: 20,
        marginTop: 15,
    },
    listStyle: {
        marginHorizontal: 15,
        // marginTop: 20,
    },
    default: {
        flex: 1,
        backgroundColor: colors.tertiaryBackground,
        overflow: 'hidden',
    },
    list: {
        flexDirection: 'row',
        alignItems: 'center',
        flexGrow: 1,
        height: 60,
    },
    listDescContainer: {
        flexGrow: 1,
        flexShrink: 1,
        marginLeft: 15,
        flexDirection: 'row',
    },
    separatorStyle: {
        height: 1,
        flex: 1,
        backgroundColor: colors.lightGrey,
    },
    inputContainer: {
        flexDirection: 'row',
        flexGrow: 1,
        backgroundColor: colors.white,
        borderRadius: 7,
        marginVertical: 15,
    },
    searchIconContainer: {
        padding: 10,
        justifyContent: 'center',
        alignItems: 'center',
        width: 50,
        height: 50,
    },
    clearContainer: {
        margin: 6.5,
        justifyContent: 'center',
        alignItems: 'center',
        width: 35,
        height: 35,
        borderRadius: 20,
        backgroundColor: colors.background,
    },
    searchInput: {
        flexGrow: 1,
        fontSize: 16,
        fontFamily: 'Vodafone Lt',
    },
    itemStyle: {
        flexDirection: 'row',
        alignItems: 'center',
        height: 60,
    },
    bottomSpacing: { marginBottom: 15 },
}))

export default styles
