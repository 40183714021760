import { styleSheetFactory } from '../../../../../Themes/Colors'

const styles = styleSheetFactory(colors => ({
  wrapper: {
    borderRadius: 7,
    backgroundColor: colors.tertiaryBackground,
  },
  container: {
    padding: 20,
    flexDirection: 'row',
    alignItems: 'center',
  },
  label: {
    width: 50,
  },
  separator: {
    width: 1,
    height: '100%',
    marginHorizontal: 15,
    backgroundColor: colors.separator,
  },
  amount: {
    justifyContent: 'center',
    alignItems: 'flex-start',
    flexGrow: 1,
    flexShrink: 1,
  },
  primartCurrency: {
    flexGrow: 1,
    flexShrink: 1,
  },
  secondaryCurrency: {
    flexGrow: 1,
    flexShrink: 1,
  },
}))

export default styles
