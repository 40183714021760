/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useContext, useEffect } from 'react'
import { View, SafeAreaView, Dimensions, TouchableOpacity, ScrollView, ImageBackground, Image } from 'react-native'
import PropTypes from 'prop-types'
import { useTheme } from 'react-native-themed-styles'
import { useQuery } from '@apollo/client'
import ContentLoader, { Rect, Circle } from 'react-content-loader/native'
import Color from 'color'
import { useSelector, useDispatch } from 'react-redux'

import { Icon, Text, Button, RadioInput, CheckBox, TextInput, InputRow, Header } from '../../Components'
import { useUserDetails } from '../../Utils/AuthDetails'
import { usePermissions } from '../../Utils/Permissions'
import { getCurrency } from '../Checkout/Checkout.Selectors'
import { removeItemFromOrder, addItemToOrder, updateItemInOrder } from '../Checkout/Checkout.Actions'

import { useConfig } from '../../Utils'

import {
  GET_ITEM,
  LIST_ALL_TAXES,
  LIST_MODIFIERS,
} from './AddToBasket.Schema'

import Theme from '../../Themes/Theme'
import images from '../../Themes/Images'
import themedStyles from './AddToBasket.Styles'

const { width } = Dimensions.get('screen')

function Variation({ id, name, priceMoney, selected, onPress, isFirst, isLast }) {
  const [styles] = useTheme(themedStyles)
  const { colors } = useContext(Theme)

  return (
    <TouchableOpacity style={[styles.itemContainer, styles['fistItem_' + isFirst], styles['lastItem_' + isLast]]} onPress={onPress} disabled={!onPress}>
      <Text i18nKey={name} style={styles.itemTitle} numberOfLines={2} />
      <View style={styles.itemPriceContainer}>
        <View style={styles.priceCurrencyContainer}>
          <Text i18nKey="checkout_price_currency_value" placeHolders={[priceMoney?.amount, priceMoney?.currency]} />
        </View>
        <View pointerEvents="none">
          <RadioInput
            index={id}
            selected={selected.id}
            onPress={onPress}
            style={styles.selector}
            color={colors.secondaryAccent}
          />
        </View>
      </View>
    </TouchableOpacity>
  )
}
Variation.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  priceMoney: PropTypes.shape({
    amount: PropTypes.number,
    currency: PropTypes.string,
  }),
  selected: PropTypes.object,
  onPress: PropTypes.func,
  isFirst: PropTypes.bool,
  isLast: PropTypes.bool,
}

function VariationPlaceholder({ isFirst, isLast }) {
  const [styles] = useTheme(themedStyles)

  return (
    <TouchableOpacity style={[styles.itemContainer, styles['fistItem_' + isFirst], styles['lastItem_' + isLast]]} disabled>
      <ContentLoader
        speed={2}
        width={width - 30}
        height={60}
        backgroundColor="#f3f3f3"
        foregroundColor="#fff"
      >
        <Rect x="15" y="21" rx="6" ry="6" width={`${(width - 30) * (3 / 5)}`} height="18" />
        <Rect x={`${width - 128}`} y="21" rx="6" ry="6" width="50" height="18" />
        <Circle cx={`${width - 54}`} cy="30" r="9" />
      </ContentLoader>
    </TouchableOpacity>
  )
}
VariationPlaceholder.propTypes = {
  isFirst: PropTypes.bool,
  isLast: PropTypes.bool,
}

function Variations({ loading, variations, selectedVariation, setSelectedVariation }) {
  const [styles] = useTheme(themedStyles)
  const currency = useSelector(getCurrency)

  if (loading) {
    return <>
      <Text
        i18nKey="checkout_title_choose_variation"
        style={styles.sectionSeparator}
      />
      <VariationPlaceholder isFirst />
      <VariationPlaceholder />
      <VariationPlaceholder isLast />
    </>
  }

  function filterPrice(prices) {
    return prices?.find(pr => pr.currency === currency.currencyCode)
  }

  if (variations.length > 0) {
    return <>
      <Text
        i18nKey="checkout_title_choose_variation"
        style={styles.sectionSeparator}
      />
      {
        variations?.map((item, index) => {
          let isFirst = false, isLast = false
          if (index === 0) {
            isFirst = true
          }
          if (index === variations.length - 1) {
            isLast = true
          }

          return <Variation
            key={item.id}
            id={item.id}
            name={item.name}
            priceMoney={filterPrice(item.prices)}
            selected={selectedVariation}
            onPress={() => setSelectedVariation({
              ...item,
              priceMoney: filterPrice(item.prices),
            })}
            isFirst={isFirst}
            isLast={isLast}
          />
        })
      }
    </>
  }
  return null
}
Variations.defaultProps = {
  variations: [],
}
Variations.propTypes = {
  loading: PropTypes.bool,
  variations: PropTypes.arrayOf(
    PropTypes.object,
  ),
  selectedVariation: PropTypes.object,
  setSelectedVariation: PropTypes.func,
}

function Modifier({ name, priceMoney, checked, onPress, isFirst, isLast }) {
  const [styles] = useTheme(themedStyles)
  const { colors } = useContext(Theme)

  return (
    <TouchableOpacity style={[styles.itemContainer, styles['fistItem_' + isFirst], styles['lastItem_' + isLast]]} onPress={onPress} disabled={!onPress}>
      <Text i18nKey={name} style={styles.itemTitle} numberOfLines={2} />
      <View style={styles.itemPriceContainer}>
        <View style={styles.priceCurrencyContainer}>
          <Text i18nKey={`${priceMoney?.amount} ${priceMoney?.currency}`} />
        </View>
        <View pointerEvents="none">
          <CheckBox
            tintColors={styles.checkBoxTintColors}
            checked={checked}
            onValueChange={onPress}
            style={styles.checkBox}
            color={colors.secondaryAccent}
          />
        </View>
      </View>
    </TouchableOpacity>
  )
}
Modifier.propTypes = {
  name: PropTypes.string,
  priceMoney: PropTypes.shape({
    amount: PropTypes.number,
    currency: PropTypes.string,
  }),
  checked: PropTypes.bool,
  onPress: PropTypes.func,
  isFirst: PropTypes.bool,
  isLast: PropTypes.bool,
}
function ModifierPlaceholder({ isFirst, isLast }) {
  const [styles] = useTheme(themedStyles)

  return (
    <TouchableOpacity style={[styles.itemContainer, styles['fistItem_' + isFirst], styles['lastItem_' + isLast]]} disabled>
      <ContentLoader
        speed={2}
        width={width - 30}
        height={60}
        backgroundColor="#f3f3f3"
        foregroundColor="#fff"
      >
        <Rect x="15" y="21" rx="6" ry="6" width={`${(width - 30) * (3 / 5)}`} height="18" />
        <Rect x={`${width - 128}`} y="21" rx="6" ry="6" width="50" height="18" />
        <Rect x={`${width - 63}`} y="21" rx="3" ry="3" width="18" height="18" />
      </ContentLoader>
    </TouchableOpacity>
  )
}
ModifierPlaceholder.propTypes = {
  isFirst: PropTypes.bool,
  isLast: PropTypes.bool,
}
function Modifiers({ setId, setName, modifierStates, changeState }) {
  const { switchLocation: { deviceId, locationId } } = useConfig()

  const [styles] = useTheme(themedStyles)

  const { loading, data } = useQuery(LIST_MODIFIERS, {
    fetchPolicy: 'network-only',
    variables: {
      locId: locationId,
      cashRegisterId: deviceId,
      id: setId,
    },
  })

  if (loading) {
    return <>
      <Text
        i18nKey={setName}
        style={styles.sectionSeparator}
      />
      <ModifierPlaceholder isFirst />
      <ModifierPlaceholder isLast />
    </>
  }

  const modifiers = data?.getModifierList?.listModifiers?.modifiers || []

  return (
    <>
      <Text
        i18nKey="checkout_title_add_modifiers"
        placeHolders={[setName, modifiers.length]}
        style={styles.sectionSeparator}
      />
      {
        modifiers?.map((item, index) => {
          let isFirst = false, isLast = false
          if (index === 0) {
            isFirst = true
          }
          if (index === modifiers.length - 1) {
            isLast = true
          }

          const mofifier = {
            id: item.id,
            name: item.name,
            modifierListId: setId,
            modifierListName: setName,
            appliedMoney: item.priceMoney,
          }

          return <Modifier
            key={item.id}
            id={item.id}
            name={item.name}
            priceMoney={item.priceMoney}
            isFirst={isFirst}
            isLast={isLast}
            checked={!!modifierStates[item.id]}
            onPress={() => changeState(item.id, mofifier)}
          />
        })
      }
    </>
  )
}
Modifiers.propTypes = {
  setId: PropTypes.string,
  setName: PropTypes.string,
  modifierStates: PropTypes.objectOf(
    PropTypes.objectOf(
      PropTypes.bool,
    )
  ),
  changeState: PropTypes.func,
}

const discountTypes = {
  FIXED_PERCENTAGE: 'FIXED_PERCENTAGE',
  FIXED_AMOUNT: 'FIXED_AMOUNT',
  VARIABLE_PERCENTAGE: 'VARIABLE_PERCENTAGE',
  VARIABLE_AMOUNT: 'VARIABLE_AMOUNT',
}

function Discount({ name, percentage, discountType, amountMoney = {}, checked, disabled, onPress, isFirst, isLast }) {
  const [styles] = useTheme(themedStyles)
  const { colors } = useContext(Theme)
  const isDisabled = disabled || !onPress

  return (
    <TouchableOpacity style={[styles.itemContainer, styles['fistItem_' + isFirst], styles['lastItem_' + isLast]]} onPress={onPress} disabled={isDisabled}>
      <View style={styles.itemTitle}>
        <Text i18nKey={name} numberOfLines={1} />
        {discountType.match(/PERCENTAGE/)
          ? percentage && <Text i18nKey={percentage + '%'} color={colors.secondaryText} numberOfLines={1} />
          : amountMoney?.amount
            ? <Text i18nKey="checkout_price_currency_value" placeHolders={[amountMoney?.amount, amountMoney?.currency]} color={colors.secondaryText} numberOfLines={1} />
            : null
        }
      </View>
      <View pointerEvents="none">
        <CheckBox
          checked={checked}
          onValueChange={onPress}
          isDisabled={isDisabled}
          style={styles.checkBox}
          color={colors.secondaryAccent}
        />
      </View>
    </TouchableOpacity>
  )
}
Discount.propTypes = {
  name: PropTypes.string,
  percentage: PropTypes.string,
  discountType: PropTypes.oneOf(Object.values(discountTypes)),
  amountMoney: PropTypes.shape({
    amount: PropTypes.number,
    currency: PropTypes.string,
  }),
  checked: PropTypes.bool,
  onPress: PropTypes.func,
  disabled: PropTypes.bool,
  isFirst: PropTypes.bool,
  isLast: PropTypes.bool,
}
function DiscountPlaceholder({ isFirst, isLast }) {
  const [styles] = useTheme(themedStyles)

  return (
    <TouchableOpacity style={[styles.itemContainer, styles['fistItem_' + isFirst], styles['lastItem_' + isLast]]} disabled>
      <ContentLoader
        speed={2}
        width={width - 30}
        height={60}
        backgroundColor="#f3f3f3"
        foregroundColor="#fff"
      >
        <Rect x="15" y="10" rx="6" ry="6" width={`${(width - 30) * (3 / 5)}`} height="18" />
        <Rect x="15" y="32" rx="6" ry="6" width="60" height="18" />
        <Rect x={`${width - 63}`} y="21" rx="3" ry="3" width="18" height="18" />
      </ContentLoader>
    </TouchableOpacity>
  )
}
DiscountPlaceholder.propTypes = {
  isFirst: PropTypes.bool,
  isLast: PropTypes.bool,
}

function Discounts({ loading, discounts, selectedDiscounts, preappliedDiscounts, changeState, editable, navigate, goBack }) {
  const [styles] = useTheme(themedStyles)
  const { colors } = useContext(Theme)

  const currency = useSelector(getCurrency)

  const [appliedDiscounts, setAppliedDiscounst] = useState(preappliedDiscounts || [])

  const applyDiscounts = (items = []) => {
    goBack()
    const added = items?.filter(newDiscount => appliedDiscounts?.findIndex(oldDiscount => newDiscount.id === oldDiscount.id) === -1)
    const removed = appliedDiscounts?.filter(oldDiscount => items?.findIndex(newDiscount => oldDiscount.id === newDiscount.id) === -1)
    setAppliedDiscounst(items)
    removed?.map(item => changeState(item.id, item))
    added?.map(item => changeState(item.id, item))
  }

  const toggleDiscount = (id, discount) => {
    const type = discount?.discountType || ''
    if (type.match(/VARIABLE/) && !(discount?.appliedMoney?.amount || discount?.percentage) && !selectedDiscounts[id]) {
      navigate('SetDiscountValue', {
        onSave: res => {
          goBack()
          if (type === discountTypes.VARIABLE_AMOUNT) {
            discount.appliedMoney = res
          } else {
            discount.percentage = res
          }
          changeState(id, discount)
        },
        name: discount.name,
        type: type,
      })
    } else {
      changeState(id, discount)
    }
  }

  if (loading) {
    return <>
      <Text
        i18nKey="checkout_title_add_discount"
        style={styles.sectionSeparator}
      />
      <DiscountPlaceholder isFirst />
      <DiscountPlaceholder />
      <DiscountPlaceholder isLast />
    </>
  }

  function filterPrice(prices) {
    return prices?.find(pr => pr.currency === currency.currencyCode)
  }

  const mapData = (dataa) => {
    const response = []
    dataa?.map((item, index) => {
      let amount = {}
      if (item?.amountMoney) {
        amount = item.amountMoney
      } else if (item?.prices?.length) {
        amount = filterPrice(item.prices)
      }
      if (amount) {
        response.push({
          ...item,
          amountMoney: amount,
        })
      }
    })
    return response
  }

  if (discounts.length > 0) {
    const newDiscounts = mapData(discounts)
    return <>
      <Text
        i18nKey="checkout_title_add_discount"
        style={styles.sectionSeparator}
      />
      {
        newDiscounts?.map((item, index) => {
          let isFirst = false, isLast = false
          if (index === 0) {
            isFirst = true
          }
          if (index === discounts.length - 1) {
            isLast = true
          }

          const discount = {
            id: item.id,
            name: item.name,
            discountType: item.discountType,
            percentage: item.percentage,
            appliedMoney: item.amountMoney,
          }

          return <Discount
            key={item.id}
            id={item.id}
            name={item.name}
            percentage={item.percentage || selectedDiscounts[item.id]?.percentage}
            discountType={item.discountType}
            amountMoney={item.amountMoney || selectedDiscounts[item.id]?.appliedMoney || filterPrice(item?.prices, currency.currencyCode)}
            checked={Boolean(selectedDiscounts?.[item.id])}
            onPress={() => toggleDiscount(item.id, discount)}
            disabled={!editable}
            isFirst={isFirst}
            isLast={isLast}
          />
        })
      }
    </>
  }
  if (editable) {
    if (appliedDiscounts.length > 0) {
      return <Button
        backgroundColor={colors.secondaryAccent}
        title="checkout_title_select_discount"
        placeHolders={[appliedDiscounts.length]}
        borderColor={colors.transparent}
        color={colors.white}
        iconColor={colors.secondaryAccent}
        style={styles.addDiscountButton}
        onPress={() => { navigate('SelectDiscount', { onSave: applyDiscounts, preSelectedDiscounts: appliedDiscounts }) }}
      />
    }
    return <Button
      backgroundColor={colors.tertiaryBackground}
      icon={images.plusIcon}
      title="checkout_title_add_discount"
      borderColor={colors.transparent}
      color={colors.secondaryAccent}
      iconColor={colors.secondaryAccent}
      style={styles.addDiscountButton}
      onPress={() => { navigate('SelectDiscount', { onSave: applyDiscounts, preSelectedDiscounts: appliedDiscounts }) }}
    />
  }
  return null
}
Discounts.defaultProps = {
  discounts: [],
}
Discounts.propTypes = {
  loading: PropTypes.bool,
  discounts: PropTypes.arrayOf(
    PropTypes.object,
  ),
  selectedDiscounts: PropTypes.object,
  preappliedDiscounts: PropTypes.array,
  changeState: PropTypes.func,
  editable: PropTypes.bool,
  navigate: PropTypes.func,
  goBack: PropTypes.func,
}

function Tax({ name, taxInclusionType, onPress, isFirst, isLast }) {
  const [styles] = useTheme(themedStyles)
  const { colors } = useContext(Theme)

  return (
    <TouchableOpacity style={[
      styles.taxContainer,
      styles[`isFirst_${isFirst}`],
      styles[`isLast_${isLast}`],
    ]} onPress={onPress} disabled={!onPress}>
      <Text i18nKey={name} numberOfLines={2} style={styles.taxName} />
      <Text i18nKey={taxInclusionType} color={colors.secondaryText} numberOfLines={2} style={styles.taxInclusionType} />
    </TouchableOpacity>
  )
}
Tax.propTypes = {
  name: PropTypes.string,
  taxInclusionType: PropTypes.string,
  onPress: PropTypes.func,
  isFirst: PropTypes.bool,
  isLast: PropTypes.bool,
}

function TaxPlaceholder({ isFirst, isLast }) {
  const [styles] = useTheme(themedStyles)

  return (
    <TouchableOpacity style={[styles.itemContainer, styles['fistItem_' + isFirst], styles['lastItem_' + isLast]]} disabled>
      <ContentLoader
        speed={2}
        width={width - 30}
        height={60}
        backgroundColor="#f3f3f3"
        foregroundColor="#fff"
      >
        <Rect x="15" y="10" rx="6" ry="6" width={`${(width - 30) * (3 / 5)}`} height="18" />
        <Rect x="15" y="32" rx="6" ry="6" width="60" height="18" />
        <Circle cx={`${width - 54}`} cy="30" r="9" />
      </ContentLoader>
    </TouchableOpacity>
  )
}
TaxPlaceholder.propTypes = {
  isFirst: PropTypes.bool,
  isLast: PropTypes.bool,
}

function Taxes({ selectedTax, setSelectedTax }) {
  const [styles] = useTheme(themedStyles)

  const { loading, data } = useQuery(LIST_ALL_TAXES, {
    fetchPolicy: 'network-only',
  })

  if (loading) {
    return <>
      <Text
        i18nKey="checkout_button_add_tax"
        style={styles.sectionSeparator}
      />
      <TaxPlaceholder isFirst />
      <TaxPlaceholder />
      <TaxPlaceholder isLast />
    </>
  }

  const taxes = data?.listTaxes?.taxes || []

  return (
    <>
      <Text
        i18nKey="checkout_button_add_tax"
        style={styles.sectionSeparator}
      />
      {
        taxes?.map((item, index) => {
          let isFirst = false, isLast = false
          if (index === 0) {
            isFirst = true
          }
          if (index === taxes.length - 1) {
            isLast = true
          }

          return <Tax
            key={item.id}
            id={item.id}
            name={item.name}
            percentage={item.percentage}
            selected={selectedTax}
            onPress={() => setSelectedTax(item)}
            isFirst={isFirst}
            isLast={isLast}
          />
        })
      }
    </>
  )
}
Taxes.propTypes = {
  selectedTax: PropTypes.object,
  setSelectedTax: PropTypes.func,
}

function AddToBasket({
  route: {
    params: {
      itemId,
      itemName,
      imageUrl,
      labelColor,
      index,
      onSave = () => { },
      shouldUpdateItem,
      ...rest
    } = {},
  },
  navigation: {
    navigate,
    goBack,
  },
}) {
  const { switchLocation: { deviceId, locationId } } = useConfig()

  const [styles] = useTheme(themedStyles)
  const { colors } = useContext(Theme)
  const { role } = useUserDetails()
  const { checkout = {} } = usePermissions()
  const currency = useSelector(getCurrency)
  const dispatchAction = useDispatch()

  const removeFromBasket = (obj) => dispatchAction(removeItemFromOrder(obj))

  const addToOrder = (item) => dispatchAction(addItemToOrder(item))

  const updateOrderItem = (item) => dispatchAction(updateItemInOrder(item))

  const { loading, data } = useQuery(GET_ITEM, {
    fetchPolicy: 'network-only',
    variables: {
      locId: locationId,
      cashRegisterId: deviceId,
      searchField: 'id',
      searchValue: itemId,
    },
  })

  const {
    preselectedVariation,
    preselectedModifiers,
    preSelectedDiscounts,
    preselectedTax,
    presetQuantity,
    taxInclusionType,
  } = rest

  const [countable] = useState(true)
  const [quantity, setQuantity] = useState(presetQuantity ? presetQuantity + '' : '1.00')
  const [weight, setWeight] = useState('0.00')

  const arrayToObject = (acc, curr) => {
    acc[curr.id] = curr
    return acc
  }

  function filterPrice(prices) {
    return prices?.find(pr => pr.currency === currency.currencyCode)
  }

  const [selectedVariation, setSelectedVariation] = useState(preselectedVariation || {})
  const [selectedModifiers, setSelectedModifiers] = useState((preselectedModifiers || []).reduce(arrayToObject, {}))
  const [selectedDiscounts, setSelectedDiscounts] = useState((preSelectedDiscounts || []).reduce(arrayToObject, {}))
  const [selectedTax, setSelectedTax] = useState(preselectedTax || {})

  const item = data?.getItem?.item || { imageUrl, labelColor, taxInclusionType }
  const name = item.name || itemName || ''
  const measurementUnit = item?.measurementUnit?.name
  const measurementUnitId = item?.measurementUnitId
  const variations = data?.getItem?.listItemVariations?.itemVariations || []
  const modifiers = data?.getItem?.listModifierLists?.modifierLists || []
  const [discounts, setDiscounts] = useState([])

  const discountArrayToObject = (acc, curr) => {
    acc[curr.id] = {
      id: curr.id,
      name: curr.name,
      discountType: curr.discountType,
      percentage: curr.percentage,
      appliedMoney: curr.amountMoney,
    }
    return acc
  }

  const mapData = (dataa) => {
    const response = []
    dataa?.map((element) => {
      let amount = null
      if (element?.prices?.length) {
        amount = filterPrice(element.prices)
      }
      if (!element?.discountType.match(/AMOUNT/) || amount) {
        response.push({
          ...element,
          amountMoney: amount,
        })
      }
    })

    return response
  }

  useEffect(() => {
    if (data) {
      const discs = data?.getItem?.listDiscounts?.discounts || []
      setDiscounts(mapData(discs))
      if (!preSelectedDiscounts) {
        setSelectedDiscounts(mapData(discs).reduce(discountArrayToObject, {}))
      }
    }
  }, [data])

  useEffect(() => {
    if (variations.length === 1 && selectedVariation?.id !== variations[0]?.id) {
      setSelectedVariation({
        ...item,
        priceMoney: filterPrice(item.prices),
      })
    }
  }, [])

  const toggleModifier = (id, modifier) => {
    setSelectedModifiers(prev => {
      if (prev[id]) {
        delete prev[id]
      } else {
        prev[id] = modifier
      }
      return { ...prev }
    })
  }

  const toggleDiscount = (id, discount) => {
    setSelectedDiscounts(prev => {
      if (prev[id]) {
        delete prev[id]
      } else {
        prev[id] = discount
      }
      return { ...prev }
    })
  }

  useEffect(() => {
    if (data && !preselectedTax) {
      setSelectedTax(data.getItem?.item?.tax || {})
    }
  }, [data])

  const replaceAt = (string, i, replacement) => string.substr(0, i) + replacement + string.substr(i + replacement.length)

  const changeQuantity = (text) => {
    let newString = text
    const indexOfComa = text.indexOf(',')
    if (indexOfComa >= 0) {
      newString = replaceAt(text, indexOfComa, '.')
    }
    if (/^\d{0,12}(\.\d{0,2})?$/.test(newString)) {
      setQuantity(newString)
    }
  }

  const decrement = () => {
    let val = parseFloat(quantity || 0)
    val--
    if (val <= 0) {
      val = 0
    }
    setQuantity(parseFloat(val.toFixed(3)) + '')
  }
  const increment = () => {
    let val = parseFloat(quantity || 0)
    val++
    setQuantity(parseFloat(val.toFixed(3)) + '')
  }

  let button = {
    variant: 'disabled',
    iconColor: colors.primaryText,
    icon: images.checkout,
    title: 'checkout_button_add_to_basket',
    onPress: () => {
      goBack()
      const itemDetails = {
        itemName: name,
        quantity: parseFloat(parseFloat(quantity || 0).toFixed(3)),
        itemId: itemId,
        variationName: selectedVariation?.name,
        variationId: selectedVariation?.id,
        taxInclusionType: item?.taxInclusionType,
        imageUrl: item?.imageUrl,
        labelColor: item?.labelColor,
        basePriceMoney: {
          amount: selectedVariation?.priceMoney?.amount,
          currency: selectedVariation?.priceMoney?.currency,
        },
        modifiers: Object.values(selectedModifiers),
        appliedTax: selectedTax,
        appliedDiscounts: Object.values(selectedDiscounts),
        barcode: item?.barcode,
        measurementUnitId,
        measurementUnitName: measurementUnit,
        taxExemptionType: item?.taxExemptionType,
      }
      if (shouldUpdateItem) {
        updateOrderItem({ item: itemDetails, index })
      } else {
        addToOrder(itemDetails)
      }
      onSave()
    },
  }
  if (!loading) {
    if (variations.length > 0 && !selectedVariation?.priceMoney && !selectedVariation?.id) {
      button = { ...button, icon: undefined, title: 'checkout_button_select_variation' }
    } else if (!selectedVariation?.priceMoney) {
      button = {
        ...button,
        variant: 'active',
        iconColor: colors.white,
        icon: undefined,
        title: 'checkout_button_add_price',
        onPress: () => navigate('VariableItem', {
          onSave: details => {
            goBack()
            setSelectedVariation({
              ...selectedVariation,
              priceMoney: {
                amount: details?.price,
                currency: currency.currencyCode,
              },
            })
          },
          e: null,
          params: { id: itemId, name: item.name },
          addToCart: false,
        }),
      }
    } else if (!selectedTax?.id) {
      button = { ...button, icon: undefined, title: 'checkout_button_add_tax' }
    } else if (parseFloat(quantity || 0) <= 0) {
      button = {
        ...button,
        variant: 'active',
        icon: undefined,
        title: 'checkout_button_remove_item',
        onPress: () => {
          removeFromBasket({
            itemName: name,
            quantity: parseFloat(parseFloat(quantity || 0).toFixed(3)),
            itemId: itemId,
            variationName: selectedVariation?.name,
            variationId: selectedVariation?.id,
            taxInclusionType: item.taxInclusionType,
            imageUrl: item.imageUrl,
            labelColor: item.labelColor,
            basePriceMoney: {
              amount: selectedVariation?.priceMoney?.amount,
              currency: selectedVariation?.priceMoney?.currency,
            },
            modifiers: Object.values(selectedModifiers),
            appliedTax: selectedTax,
            appliedDiscounts: Object.values(selectedDiscounts),
            barcode: item.barcode,
          })
          goBack()
        },
      }
    } else {
      button = {
        ...button,
        variant: 'active',
        iconColor: colors.white,
        title: 'checkout_button_add_to_basket',
      }
    }
  }

  return (
    <SafeAreaView style={styles.container}>
      <Header
        title={name}
        image={images.arrow_left}
        onPress={goBack}
      />
      <ScrollView>
        <View style={styles.productInfoContainer} >
          <ImageBackground source={item.imageUrl ? { uri: item.imageUrl } : undefined} style={styles.productImage} resizeMode="cover" blurRadius={6}>
            {item.imageUrl
              ? <Image source={{ uri: item.imageUrl }} style={styles.fill} resizeMode="contain" />
              : <View style={[styles.abreviationContainer, { backgroundColor: Color(item.labelColor || colors.transparent).fade(0.65).toString() }]}>
                <Text i18nKey={name?.match(/\b(\w{1})/g)?.slice(0, 2)?.join('')?.toUpperCase()} size="h1" color={item.labelColor} style={styles.abreviation} />
              </View>
            }
          </ImageBackground>
          <View style={styles.quantityPriceContainer}>
            {countable
              ? <View style={styles.quantityContainer}>
                <TouchableOpacity
                  style={styles.quantityButton}
                  onPress={decrement}
                >
                  <Icon source={images.minusIcon} size={30} color={colors.secondaryIcon} />
                </TouchableOpacity>
                <TextInput
                  placeholder="0"
                  placeholderTextColor={colors.disabled}
                  value={quantity}
                  onChangeText={changeQuantity}
                  textAlign="center"
                  style={styles.quantityInput}
                  keyboardType="numeric"
                  autoCapitalize="none"
                />
                <TouchableOpacity
                  style={styles.quantityButton}
                  onPress={increment}
                >
                  <Icon source={images.plusIcon} size={30} color={colors.secondaryIcon} />
                </TouchableOpacity>
                <Text i18nKey={measurementUnit} size="h5" style={styles.leftSpacing} />
              </View>
              : <InputRow
                label="weight"
                value={weight}
                placeholder="0.00"
                onChangeText={setWeight}
                inputLabel="gr"
                style={styles.uncauntable}
                keyboardType="numeric"
              />
            }
            <View style={styles.pricesContainer}>
              <View style={styles.priceContainer}>
                <Text
                  i18nKey="checkout_price_currency_value"
                  placeHolders={[selectedVariation?.priceMoney?.amount, selectedVariation?.priceMoney?.currency]}
                  size="h4"
                />
              </View>
            </View>
          </View>
        </View>
        {item?.description
          ?
          <><Text i18nKey="checkout_title_about_item" color={colors.primaryText} style={styles.productTitle} />
            <Text
              i18nKey={item?.description}
              color={colors.primaryText}
              style={styles.productDesc}
              isLoading={loading}
            /></> : null
        }
        <Variations
          loading={loading}
          variations={variations}
          selectedVariation={selectedVariation}
          setSelectedVariation={setSelectedVariation}
        />
        {currency.currencyCode === 'ALL' && modifiers?.map(modifierSet => <Modifiers
          setId={modifierSet.id}
          setName={modifierSet.name}
          modifierStates={selectedModifiers || {}}
          changeState={toggleModifier}
        />)}
        <Discounts
          loading={loading}
          discounts={discounts}
          selectedDiscounts={selectedDiscounts}
          preappliedDiscounts={preSelectedDiscounts}
          changeState={toggleDiscount}
          editable={(checkout.toogleItemDiscounts || []).includes(role)}
          navigate={navigate}
          goBack={goBack}
        />
        {/* <Taxes selectedTax={selectedTax} setSelectedTax={setSelectedTax} /> */}
        <Text
          i18nKey="checkout_title_applied_tax"
          style={styles.sectionSeparator}
        />
        <Tax
          {...selectedTax}
          key={selectedTax.id}
          taxInclusionType={item?.taxInclusionType}
          isFirst
          isLast
        />
      </ScrollView>
      <View style={styles.actionButtonContainer}>
        <Button
          {...button}
          style={styles.actionButton}
        />
      </View>
    </SafeAreaView>
  )
}

AddToBasket.propTypes = {
  navigation: PropTypes.object,
  route: PropTypes.object,
}

export default AddToBasket
