/* eslint-disable react-hooks/exhaustive-deps */
import React, { memo, useState, useContext, useEffect, useRef, useCallback } from 'react'
import { SafeAreaView, View, TouchableOpacity, Animated, Easing, ImageBackground } from 'react-native'
import { useTheme } from 'react-native-themed-styles'
import { useLazyQuery } from '@apollo/client'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'

import { useFocusEffect } from '@react-navigation/native'

import {
    Header,
    Icon,
    Text,
    ListLoader,
    AnimatedTouchable,
} from '../../Components'

import ContentManager from '../../Utils/ContentManager'


import { useLocationDetails } from '../../Utils'
import { useLanguage } from '../../Utils/Language'

import LIST_LOCATIONS from '../Locations/Locations.Schema'

import { updateLocation, resetLocation } from './ProductCatalog.Actions'


import Theme from '../../Themes/Theme'
import themedStyles, { itemStyles } from './ProductCatalog.Styles'
import images from '../../Themes/Images'

const { translate } = ContentManager


const Item = memo(function Item({
    businessName,
    address: {
        addressLine1,
    },
    logoUrl,
    onPress,
}) {
    const [styles] = useTheme(itemStyles)
    const { colors } = useContext(Theme)
    const scale = useRef(new Animated.Value(1)).current
    const animate = value => {
        Animated.spring(scale, {
            toValue: value,
            useNativeDriver: true,
        }).start()
    }

    const language = useLanguage()


    return (
        <Animated.View style={[styles.container, { transform: [{ scale }] }]}>
            <TouchableOpacity
                style={styles.touchable}
                onPress={onPress}
                onPressIn={() => animate(1.05)}
                onPressOut={() => animate(1)}
                activeOpacity={0.7}
            >
                <ImageBackground source={logoUrl ? { url: logoUrl } : undefined} style={styles.logo}>
                    {/* {!logoUrl && ( */}
                    <Text i18nKey={businessName?.match(/\b(\w{1})/g)?.slice(0, 2)?.join('')?.toUpperCase()} size="h5" />
                    {/* )} */}
                </ImageBackground>
                <View style={styles.detailsContainer}>
                    <Text i18nKey={language === 'en' ? `${businessName} ${translate('product_catalog_catalog')}` : `${translate('product_catalog_catalog')} ${businessName}`} numberOfLines={1} />
                    {Boolean(addressLine1) && <Text
                        i18nKey={addressLine1}
                        weight="light"
                        color={colors.secondaryText}
                        size="footnote"
                    />}
                </View>
                <Icon source={images.arrow_right} style={styles.rightElement} />
            </TouchableOpacity>
        </Animated.View>
    )
})
Item.propTypes = {
    businessName: PropTypes.string,
    address: PropTypes.object,
    logoUrl: PropTypes.string,
    onPress: PropTypes.func,
}

function ProductCatalog({
    navigation: { goBack, navigate, openDrawer },
}) {

    const [styles] = useTheme(themedStyles)
    const { colors } = useContext(Theme)
    const [loading, setLoading] = useState(true)
    const { merchantId } = useLocationDetails()
    const GENERAL_CATALOG = {
        id: merchantId,
        businessName: translate('product_catalog_general_business'),
        address: {
            addressLine1: null,
        },
        logoUrl: null,
        isGeneral: true,
    }
    const [items, setItems] = useState([GENERAL_CATALOG])

    const dispatchAction = useDispatch()

    const listRef = useRef(null)
    const fabSize = useRef(new Animated.Value(0)).current

    const cursor = useRef()
    const [listLocations, { data }] = useLazyQuery(
        LIST_LOCATIONS,
        { fetchPolicy: 'network-only' }
    )

    const list = () => {
        listLocations({
            variables: {},
        })
    }

    useEffect(() => {
        list()
        // dispatchAction(resetLocation())
    }, [])

    useFocusEffect(
        useCallback(() => {
            dispatchAction(resetLocation())
        }, [])
    )



    const refetch = () => {
        setLoading(true)
        setItems([GENERAL_CATALOG])
        cursor.current = null
        list()
    }

    const animate = val => {
        Animated.spring(fabSize, {
            toValue: val,
            useNativeDriver: true,
            easing: Easing.inOut(Easing.cubic),
        }).start()
    }

    const scrollToTop = () => {
        animate(0)
        listRef.current.scrollToOffset({
            offset: 0,
        })
    }

    const keyExtractor = item => item.id

    const getItemLayout = (_, index) => ({
        length: 76,
        offset: 76 * index,
        index,
    })

    const renderItem = ({ item }) => (
        <Item
            key={item.id}
            {...item}
            onPress={() => {
                dispatchAction(updateLocation(
                    {
                        locationId: item.id,
                        businessName: item.businessName,
                        address: item.address.addressLine1,
                    }))
                navigate('Items', { isGeneral: item?.isGeneral || false })
            }
            }
        />
    )

    const onEndReached = () => {
        if (data?.listLocations?.nextToken == null) {
            return
        }

        if (cursor.current) {
            setLoading(true)
            listLocations({
                variables: {
                    nextToken: cursor.current,
                },
            })
        }
    }



    useEffect(() => {
        const locations = data?.listLocations
        if (locations) {
            setItems((prev) => prev.concat(locations?.locations || []))
            cursor.current = locations?.nextToken
            setLoading(false)
        }
    }, [data])

    return (
        <SafeAreaView style={styles.listStyle}>
            <Header
                title="product_catalog_title"
                image={images.menu}
                onPress={() => openDrawer()}
            />
            <Animated.FlatList
                showsVerticalScrollIndicator={false}
                keyExtractor={keyExtractor}
                getItemLayout={getItemLayout}
                ref={(ref) => (listRef.current = ref)}
                onMomentumScrollEnd={(event) => {
                    if (event.nativeEvent.contentOffset.y > 800) {
                        animate(1)
                    } else {
                        animate(0)
                    }
                }}
                data={items}
                style={styles.listStyle}
                contentContainerStyle={styles.listContainerStyle}
                renderItem={renderItem}
                ListFooterComponent={<ListLoader loading={loading} isInitial={items.length === 0} />}
                ListEmptyComponent={!loading && <Text i18nKey="location_no_locations" />}
                onEndReached={onEndReached}
                onRefresh={refetch}
                refreshing={false}
            />
            <AnimatedTouchable
                onPress={scrollToTop}
                activeOpacity={0.5}
                style={[
                    styles.fab,
                    {
                        opacity: fabSize,
                        transform: [{ scale: fabSize }],
                    },
                ]}>
                <Icon
                    source={images.arrow_right}
                    size={20}
                    color={colors.tertiaryIcon}
                    style={styles.fabIcon}
                />
            </AnimatedTouchable>
        </SafeAreaView>
    )
}

ProductCatalog.propTypes = {
    route: PropTypes.object,
    navigation: PropTypes.object,
}

export default ProductCatalog
