import { Dimensions } from 'react-native'

import { styleSheetFactory } from '../../Themes/Colors'

const { width } = Dimensions.get('screen')

const styles = styleSheetFactory(colors => ({
  header: {
    height: 56,
    alignItems: 'center',
    flexDirection: 'row',
  },
  headerIcon: {
    marginLeft: 25,
    marginRight: 15,
  },

}))

const itemStyles = styleSheetFactory(colors => ({
  container: {
    backgroundColor: colors.tertiaryBackground,
    borderRadius: 7,
    marginHorizontal: 15,
    marginVertical: 5,
  },
  touchableContainer: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
  },
  iconContainer: {
    flexShrink: 0,
    margin: 10,
    justifyContent: 'center',
    alignItems: 'center',
  },
  detailsContainer: {
    flexGrow: 1,
    flexShrink: 1,
    marginVertical: 10,
    marginRight: 10,
  },
}))

const separatorStyle = styleSheetFactory(colors => ({
  container: {
    position: 'relative',
    justifyContent: 'center',
    alignItems: 'center',
    height: 22,
    paddingHorizontal: 15,
    marginTop: 15,
    marginBottom: 5,
  },
  separatorLine: {
    width: width - 30,
    height: 1,
    backgroundColor: colors.separatorBody,
  },
  label: {
    position: 'absolute',
    top: 0,
    left: 33,
    paddingHorizontal: 10,
    backgroundColor: colors.background,
  },
}))

export { itemStyles, separatorStyle }
export default styles
