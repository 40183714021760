import { gql } from '@apollo/client'

export const LIST_UNITS = gql`
  query listMeasurementUnits ($filter: Filter, $limit: Int, $nextToken: String,$locationId:String) {
    listMeasurementUnits(
      listMeasurementUnitsInput:{
        filter: $filter,
        limit: $limit,
        nextToken: $nextToken
      }
      locationId:$locationId
    ){
      measurementUnits{
        id
        precision
        abbreviation
        name
      },
      nextToken
    }
  }
`

export const DELETE_UNIT = gql`
  mutation deleteMeasurementUnit ($id: String!,$locationId:String) {
    deleteMeasurementUnit(
      id: $id
      locationId:$locationId
      ){
      id
      precision
      name
    }
  }
`
