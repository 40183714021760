import { gql } from '@apollo/client'

export const CREATE_ORDER = gql`
  mutation createOrder($orderInput: OrderInput! , $locationId: String , $cashRegisterId: String) {
    createOrder(
      orderInput: $orderInput
      requestedLocationAndCashRegister: {
        locationId: $locationId,
        cashRegisterId: $cashRegisterId
      }
      ){
      id
    }
  }
`

export const PAY_ORDER = gql`
  mutation payOrder(
    $idempotencyKey: String!
    $orderId: String!
    $amountMoney: MoneyInput!
    $type: PayOrderRequestType!
    $customerEmail: String
    $customerId: String
    $currency: CurrencyInput
    $locationId: String
    $cashRegisterId: String
    $isReverseCharge: Boolean,
  ){
    payOrder(
      payOrderRequest:{
      idempotencyKey: $idempotencyKey
      orderId: $orderId
      amountMoney: $amountMoney
      type: $type
      customerEmail: $customerEmail
      customerId: $customerId
      currency: $currency
      isReverseCharge: $isReverseCharge
    }
    requestedLocationAndCashRegister: {
      locationId: $locationId,
      cashRegisterId: $cashRegisterId
    }
    ){
      id
      employeeDetails {
      email
      firstName
      id
      lastName
    }
      tender{
        cashDetails{
          changeBackMoney{
            amount
            currency
          }
          buyerTenderedMoney{
            amount
            currency
          }
        }
      }
    }
  }
`

export const GET_TRANSACTION = gql`
query getTransaction($locationId: String!, $transactionId: String!, $locId: String, $cashRegisterId: String){
    getTransaction(
       locationId: $locationId,
       transactionId: $transactionId
       requestedLocationAndCashRegister: {
        locationId: $locId,
        cashRegisterId: $cashRegisterId
      }
       ){
      id
      qrCode
      invoiceType
      FIC
      IIC
      softwareCode
      operatorCode
      employeeDetails {
      firstName
      email
      id
      lastName
    }
      location {
        businUnitCode
      }
      order{
        orderSubType
        bankAccount {
          id
          swiftCode
          bankName
          iban
          holderName
        }
        shippingAddress {
          externalId
          addressLine1
          locality
          country
        }
        totalMoney{
          amount
          currency
        }
        totalDiscountMoney{
          amount
          currency
        }
        totalTaxMoney{
          amount
          currency
        }
        orderGroupedTaxes{
          VATRate,
          NumOfItems
          PriceBefVat{
            currency
            amount
          }
          VATAmt{
            currency
            amount
          }
        }
        lineItems{
          itemName
          quantity
          modifiers{
            name
            appliedMoney{
              amount
              currency
            }
          }
          appliedDiscounts{
            name
            appliedMoney{
              amount
              currency
            }
            percentage
          }
          totalMoney{
            amount
            currency
          }
          basePriceMoney{
            amount
            currency
          }
          measurementUnitName
          measurementUnitId
          taxExemptionType
        }
        supplyDateOrPeriod {
          endDate
          startDate
       }
      }
      tender{
        amountMoney{
          amount
          currency
        }
        additionalAmountMoney {
          amount
          currency
        }
        cashDetails{
          changeBackMoney{
            amount
          }
          buyerTenderedMoney{
            amount
          }
          currency {
            currencyCode
            exRate
          }
        }
        type
      }
      employee
      employeeDetails{
        id
        email
        firstName
        lastName
      }
      createdAt
      receiptNumber
      invoiceNumber
      expectedMoney{
        amount
        currency
      }
    }
  }
`

export const SAVE_ORDER = gql`
  mutation createOrder($orderInput: OrderInput! , $locationId: String , $cashRegisterId: String) {
    createOrder(
      orderInput: $orderInput
      requestedLocationAndCashRegister: {
        locationId: $locationId,
        cashRegisterId: $cashRegisterId
      }
      ){
      id
    }
  }
`

export const SAVE_EINVOICE = gql`
  mutation saveEinvoice($eInvoiceInput: EinvoiceInput!, $locId: String, $cashRegisterId: String) {
    saveEinvoice(
      eInvoiceInput: $eInvoiceInput
      requestedLocationAndCashRegister: {
        locationId: $locId,
        cashRegisterId: $cashRegisterId
      }
      ){
      ublInvoice {
        note
      }
    }
  }
`

export const GET_BUSINESS_DETAILS = gql`
  query getBusinessGov($name: String!, $value: String!) {
    getBusinessGov(searchBy: {
      name: $name,
      value: $value
    }) {
      customers {
        id
        issuerNUIS
        businessName
        address {
          addressLine1
          country
          administrativeDistrictLevel1
          postalCode
        }
      },
    },
  }
`

export const CONVERT_ORDER_TO_UBL = gql`
  query orderToUbl($order: OrderInput!,  $locId: String, $cashRegisterId: String){
      orderToUbl(
        order: $order
        requestedLocationAndCashRegister: {
          locationId: $locId,
          cashRegisterId: $cashRegisterId
        }
        ){
        docCurrencyCode
        allowances {
          chargeIndicator
          chargeReason
          amount {
            amount
            currency
          }
          taxCategory {
            id
            percent
            taxSchema
          }
          baseAmount {
            amount
            currency
          }
          percent
        }
        invoiceLine {
          id
          quantity
          lineExtension {
            amount
            currency
          }
          item {
            name
            sellerIdentification
            description
            price {
              amount
              currency
            }
            taxCategory {
              id
              percent
              taxSchema
            }
            baseQuantity {
              quantity
              unitCode
            }
          }
          totalMoney {
            amount
            currency
          }
          totalTaxMoney {
            amount
            currency
          }
          allowances {
            chargeIndicator
            chargeReason
            amount {
              amount
              currency
            }
            taxCategory {
              id
              percent
              taxSchema
            }
            baseAmount {
              amount
              currency
            }
            percent
          }
        }
      }
  }
`

export const REGISTER_EINVOICE = gql`
  mutation registerEinvoice($eInvoiceInput: EinvoiceInput!, $locId: String, $cashRegisterId: String) {
    registerEinvoice(
      eInvoiceInput: $eInvoiceInput
      requestedLocationAndCashRegister: {
        locationId: $locId,
        cashRegisterId: $cashRegisterId
      }
      ){
      ublInvoice {
        id
        note
      }
    }
  }
`

export const REGISTER_SUPPORTING_INVOICE = gql`
  mutation registerWarehouseNote($warehouseNoteInput: WarehouseNoteInput!, $locId: String, $cashRegisterId: String) {
    registerWarehouseNote(
      warehouseNoteInput: $warehouseNoteInput
      requestedLocationAndCashRegister: {
        locationId: $locId,
        cashRegisterId: $cashRegisterId
      }
      ){
      wtnic
      fwtnic
      qrCode
      note
    }
  }
`

export const SAVE_SUPPORTING_INVOICE = gql`
  mutation saveWarehouseNote($warehouseNoteInput: SaveWarehouseNoteInput!, $locId: String, $cashRegisterId: String ) {
    saveWarehouseNote(
      warehouseNoteInput: $warehouseNoteInput,
       requestedLocationAndCashRegister: {
        locationId: $locId,
        cashRegisterId: $cashRegisterId
      }
      ){
      note
    }
  }
`

export const CALCULATE_UBL = gql`
  mutation calculateUbl($ublInput: EinvoiceInput!) {
    calculateUbl(ublInput: $ublInput){
      ublInvoice {
        dueDate
        invoiceDocumentCode
        invoiceProcessCode
        buyerReference
        orderReference {
          id
          salesOrderId
        }
        contractDocumentReference {
          id
        }
        additionalDocumentReferences {
          id
          description
          uri
          binObj {
            filename
            contents
            mime
          }
        }
        projectReference {
          id
        }
        delivery {
          actualDeliveryDate
          deliveryLocation {
            address {
              streetName
              cityName
              postalCode
              countryCode
            }
          }
          deliveryPartyName {
            name
          }
        }
        docCurrencyCode
        taxCurrencyCode
        invoicePeriod {
          startDate
          endDate
          descriptionCode
        }
        supplierParty {
          issuerNUIS
          name
          address {
            streetName
            cityCode
            countryCode
            postalCode
          }
          taxSchema
          registrationName
          contact {
            name
            telephone
            electronicMail
          }
        }
        customerParty {
          issuerNUIS
          name
          address {
            streetName
            cityCode
            countryCode
            postalCode
          }
          taxSchema
          registrationName
          contact {
            name
            telephone
            electronicMail
          }
        }
        paymentMeans {
          code
          instructionNote
          card {
            primaryAccountId
            networkId
          }
          payeeFinantialAccount
        }
        taxTotal {
          taxAmount {
            amount
            currency
          }
          taxSubtotal {
            taxableAmount {
              amount
              currency
            }
            taxAmount {
              amount
              currency
            }
            taxCategory {
              id
              percent
              taxSchema
            }
          }
        }
        legalMonetaryTotal {
          lineExtension {
            amount
            currency
          }
          taxExclusive {
            amount
            currency
          }
          taxInclusive {
            amount
            currency
          }
          payable {
            amount
            currency
          }
          charge {
            amount
            currency
          }
          allowance {
            amount
            currency
          }
        }
        allowances {
          chargeIndicator
          chargeReason
          amount {
            amount
            currency
          }
          taxCategory {
            id
            percent
            taxSchema
          }
          baseAmount {
            amount
            currency
          }
          percent
        }
        invoiceLine {
          id
          quantity
          lineExtension {
            amount
            currency
          }
          allowances {
            chargeIndicator
            chargeReason
            amount {
              amount
              currency
            }
            taxCategory {
              id
              percent
              taxSchema
            }
            baseAmount {
              amount
              currency
            }
            percent
          }
          item {
            name
            sellerIdentification
            description
            price {
              amount
              currency
            }
            taxCategory {
              id
              percent
              taxSchema
            }
            baseQuantity {
              quantity
              unitCode
            }
          }
          totalMoney {
            amount
            currency
          }
          totalTaxMoney {
            amount
            currency
          }
        }
      }
    }
  }
`

export const CONVERT_ORDER_TO_WTN = gql`
  query orderToWarehouseTransferNote($order: OrderInput){
    orderToWarehouseTransferNote(order: $order){
        carrier {
          address
          idNum
          idType
          name
          town
        }
        destinAddr
        destinCity
        destinDateTime
        destinPoint
        isEscortRequired
        isGoodsFlammable
        issuer {
          address
          name
          nuis
          town
        }
        items {
          code
          name
          quantity
          unit
        }
        itemsNum
        startAddr
        startDateTime
        startCity
        startPoint
        transaction
        type
        valueOfGoods
        vehOwnership
        vehPlates
        issueDateTime
        packType
        packNum
      }
  }
`

export const GET_WAREHOUSE_NOTE = gql`
  query getWarehouseNote($id: String!,  $locId: String, $cashRegisterId: String){
    getWarehouseNote(
      id: $id
      requestedLocationAndCashRegister: {
        locationId: $locId,
        cashRegisterId: $cashRegisterId
      }
      ){
      wtnic
      fwtnic
      qrCode
      type
      transaction
      issueDateTime
      operatorCode
      businUnitCode
      softCode
      wtnOrdNum
      wtnNum
      valueOfGoods
      vehOwnership
      vehPlates
      startAddr
      startCity
      startDateTime
      startPoint
      destinAddr
      destinCity
      destinDateTime
      destinPoint
      isGoodsFlammable
      isEscortRequired
      items {
        name
        code
        unit
        quantity
      }
      issuer {
        nuis
        name
        address
        town
      }
      carrier {
        idType
        idNum
        name
        address
        town
      }
    }
  }
`
