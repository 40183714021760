import React, { useContext } from 'react'
import { TouchableOpacity, Linking } from 'react-native'
import { useTheme } from 'react-native-themed-styles'
import PropTypes from 'prop-types'

import Text from '../Text'
import Icon from '../Icon'

import Theme from '../../Themes/Theme'
import themedStyles from './TutorialNote.Styles'
import { images } from '../../Themes'

function Note({
  url,
  style,
}) {
  const [styles] = useTheme(themedStyles)
  const { colors } = useContext(Theme)

  return (
    <TouchableOpacity
      activeOpacity={0.4}
      onPress={() => Linking.openURL(url)}
      style={[styles.touchableTextNote, style]}
    >
      <Icon source={images.info} size={20} color={colors.accent} style={styles.noteIcon} />
      <Text i18nKey="certificate_note_description" style={styles.description} />
    </TouchableOpacity>
  )
}

Note.propTypes = {
  url: PropTypes.string,
  style: PropTypes.object,
}

export default Note
