/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { SafeAreaView, LayoutAnimation } from 'react-native'
import { useMutation, useLazyQuery } from '@apollo/client'
import { useTheme } from 'react-native-themed-styles'
import PropTypes from 'prop-types'
import _ from 'lodash'

import { GET_EMPLOYEE, RESEND_CREDENTIALS, UPDATE_EMPLOYEE } from '../../../Users/Users.schema'
import UserForm from '../../../NewUser/UserForm'
import { ProfilePicPicker, Text, ErrorModal } from '../../../../Components'
import { layoutAnimConfig } from '../../../../Themes/Theme'
import { getErrorMessage, useUserDetails } from '../../../../Utils'
import { usePermissions } from '../../../../Utils/Permissions'
import { colors } from '../../../../Themes'

import ContentManager from '../../../../Utils/ContentManager'

import themedStyles from './Information.Styles'

function NewUser({
  id,
  firstName,
  setFirstName,
  lastName,
  setLastName,
  selected,
  setSelected,
  navigation: { navigate },
  goBack,
}) {

  const [styles] = useTheme(themedStyles)
  const { role, refreshToken } = useUserDetails()
  const { users = {} } = usePermissions()
  const editable = users.editable.includes(role)
  const [status, setStatus] = useState('')
  const [role1, setRole] = useState('')
  const [userEmail, setEmail] = useState('')
  const [userPhone, setPhone] = useState('')
  const [operatorCode, setOperatorCode] = useState('')
  const [callingCode] = useState('355')
  const [opened, setOpened] = useState(false)
  const [location1, setLocation] = useState('')
  const [locationId, setLocationId] = useState('')
  const [loading1, setLoading] = useState(true)
  const [disabled, setDisabled] = useState(true)
  const [parent, setParent] = useState({})

  const [webAccess, setWebAccess] = useState(false)
  const [apiAccess, setApiAccess] = useState(false)
  const [webAccessRegister, setWebAccessRegister] = useState(null)
  const [apiAccessRegister, setApiAccessRegister] = useState(null)

  const [confirm, setConfirm] = useState(false)

  const [errorModal, setErrorModal] = useState({
    isVisible: false,
    errorMessage: '',
    title: '',
    desc: '',
  })
  const { translate } = ContentManager

  const regex = /^[a-z]{2}\d{3}[a-z]{2}\d{3}$/

  const [
    updateUser,
    { loading: updateLoading },
  ] = useMutation(UPDATE_EMPLOYEE)

  const updateEmployee = () => {
    updateUser({
      variables: {
        id: id,
        firstName: firstName,
        lastName: lastName,
        email: userEmail.trim(),
        operatorCode: operatorCode,
        defaultLocation: locationId,
        defaultLocationName: location1,
        phoneNumber: `+${callingCode + userPhone}`,
        color: selected || colors.gray,
        webAccessCashRegisterId: webAccessRegister?.id ? webAccessRegister?.id : null,
        apiAccessCashRegisterId: apiAccessRegister?.id ? apiAccessRegister?.id : null,
      },
    })
      .then((res) => {
        setDisabled(true)
        refreshToken()
        goBack()
      })
      .catch((er) => {
        setErrorModal({
          isVisible: true,
          title: 'certificate_error_label',
          desc: getErrorMessage(er),
        })
      })
  }

  const [
    getEmployee,
    { data: empData },
  ] = useLazyQuery(GET_EMPLOYEE, {
    fetchPolicy: 'network-only',
  })


  const [resendCredentials, { loading: credentialsLoading }] = useMutation(RESEND_CREDENTIALS, {
    variables: {
      id,
    },
  })

  const retrieveEmployee = () => {
    getEmployee({
      variables: {
        id: id,
      },
    })
  }

  const credentialsOnPress = () => {
    if (confirm) {
      resendCredentials().then((data) => {
        setErrorModal({
          isVisible: true,
          title: 'users_resend_credentials_modal_title',
          desc: 'users_resend_credentials_modal_description',
        })
      })
        .catch((error) => {
          setErrorModal({
            isVisible: true,
            title: 'certificate_error_label',
            desc: 'demo_error_description',
          })
        })
      setConfirm(false)
    } else {
      setConfirm(true)
    }
  }

  useEffect(() => {
    if (id) {
      retrieveEmployee()
    } else {
      setLoading(false)
    }
  }, [id])

  useEffect(() => {
    if (empData) {
      setParent(empData?.RetrieveEmployee?.parent)
      setLocationId(empData?.RetrieveEmployee?.defaultLocation)
      setLocation(empData?.RetrieveEmployee?.defaultLocationName)
      setFirstName(empData?.RetrieveEmployee?.firstName)
      setLastName(empData?.RetrieveEmployee?.lastName)
      setOperatorCode(empData?.RetrieveEmployee?.operatorCode)
      setRole(empData?.RetrieveEmployee?.role)
      setStatus(empData?.RetrieveEmployee?.userStatus)
      setSelected(empData?.RetrieveEmployee?.color)
      setEmail(empData?.RetrieveEmployee?.email)
      setPhone(empData?.RetrieveEmployee?.phoneNumber?.slice(4))
      LayoutAnimation.configureNext(layoutAnimConfig)
      setApiAccess(Boolean(empData?.RetrieveEmployee?.apiAccessCashRegister))
      setApiAccessRegister(empData?.RetrieveEmployee?.apiAccessCashRegister?.id ? {
        id: empData?.RetrieveEmployee?.apiAccessCashRegister?.id,
        code: empData?.RetrieveEmployee?.apiAccessCashRegister?.name,
        description: empData?.RetrieveEmployee?.apiAccessCashRegister?.TCRCode,
      } : null)
      setWebAccess(Boolean(empData?.RetrieveEmployee?.webAccessCashRegister))
      setWebAccessRegister(empData?.RetrieveEmployee?.webAccessCashRegister?.id ? {
        id: empData?.RetrieveEmployee?.webAccessCashRegister?.id,
        code: empData?.RetrieveEmployee?.webAccessCashRegister?.name,
        description: empData?.RetrieveEmployee?.webAccessCashRegister?.TCRCode,
      } : null)
      setLoading(false)
    }
  }, [empData])

  const checkInputs = () => {
    if ((
      firstName === '' ||
      lastName === '' ||
      userEmail === '' ||
      userPhone === '' ||
      operatorCode === '' ||
      location1 === '' ||
      !regex.test(operatorCode)
    ) || ((_.isEqual(empData?.RetrieveEmployee,
      {
        email: userEmail,
        firstName: firstName,
        lastName: lastName,
        phoneNumber: `+${callingCode + userPhone}`,
        operatorCode: operatorCode,
        color: selected,
        role: role1,
        userStatus: status,
        defaultLocation: locationId,
        defaultLocationName: location1,
        webAccessCashRegister: webAccessRegister?.id ? {
          id: webAccessRegister?.id,
          name: webAccessRegister?.code,
          TCRCode: webAccessRegister?.description,
        } : null,
        apiAccessCashRegister: apiAccessRegister?.id ? {
          id: apiAccessRegister?.id,
          name: apiAccessRegister?.code,
          TCRCode: apiAccessRegister?.description,
        } : null,
        parent: parent,
      })
    ) && (Boolean(webAccessRegister?.id) === webAccess)
      && (Boolean(apiAccessRegister?.id) === apiAccess))) {
      setDisabled(true)
    } else {
      setDisabled(false)
    }
  }

  useEffect(() => {
    checkInputs()
  }, [
    firstName,
    lastName,
    userEmail,
    userPhone,
    operatorCode,
    locationId,
    selected,
    parent,
    apiAccessRegister,
    webAccessRegister,
    apiAccess,
    webAccess,
  ])

  const changeLocation = (userLocation) => {
    setLocation(userLocation?.businessName)
    setLocationId(userLocation?.id)
  }

  return (
    <SafeAreaView style={styles.container}>
      <UserForm
        id={id}
        firstName={firstName}
        setFirstName={setFirstName}
        lastName={lastName}
        setLastName={setLastName}
        userEmail={userEmail}
        setEmail={setEmail}
        userPhone={userPhone}
        setPhone={setPhone}
        operatorCode={operatorCode}
        setOperatorCode={setOperatorCode}
        loading1={loading1}
        locationId={locationId}
        changeLocation={changeLocation}
        location1={location1}
        disabled={disabled}
        setDisabled={setDisabled}
        status={status}
        role={role1}
        setStatus={setStatus}
        error1={false}
        loading={updateLoading}
        editable={editable}
        ListEmptyComponent={
          loading1 ? null : <Text
            i18nKey="users_no_cash_register"
            style={styles.emptyComponent}
            align="center"
          />
        }
        goBack={goBack}
        navigate={navigate}
        user={parent}
        onSave={() => updateEmployee()}
        webAccessRegister={webAccessRegister}
        apiAccessRegister={apiAccessRegister}
        setWebAccessRegister={setWebAccessRegister}
        setApiAccessRegister={setApiAccessRegister}
        webAccess={webAccess}
        setWebAccess={setWebAccess}
        apiAccess={apiAccess}
        setApiAccess={setApiAccess}
        credentialsOnPress={credentialsOnPress}
        confirm={confirm}
        credentialsLoading={credentialsLoading}
      />

      <ProfilePicPicker
        isVisible={opened}
        onClosePress={() => setOpened(false)}
        selected={selected}
        disabled={!editable}
        setSelected={setSelected}
      />
      <ErrorModal
        isVisible={errorModal.isVisible}
        onClosePress={() => setErrorModal({
          isVisible: false,
        })}
        title={errorModal.title}
        description={errorModal.desc}
        primaryText="cash_register_okay_button"
        primaryAction={() => setErrorModal({
          isVisible: false,
        })}
      />
    </SafeAreaView>
  )
}
NewUser.propTypes = {
  id: PropTypes.string,
  firstName: PropTypes.string,
  setFirstName: PropTypes.func,
  lastName: PropTypes.string,
  setLastName: PropTypes.func,
  selected: PropTypes.string,
  setSelected: PropTypes.func,
  navigation: PropTypes.object,
  goBack: PropTypes.func,
}

export default NewUser
