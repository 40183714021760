import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import {
    getIsReverseCharge,
    getPaymentCustomer,
    getPaymentDates,
    getPaymentMethod,
    getPaymentOrderSubtype,
    getPaymentOrderType,
    getShouldHaveBillingPeriod,
} from '../../../Payment.Selectors'

import Footer from '../Footer'

function CashPaymentFooter({
    onCashPay,
    onCashSavePress,
    disabled,
    loading,
}) {

    return (
        <Footer
            onOptionPress={onCashSavePress}
            onButtonPress={onCashPay}
            title="checkout_button_pay"
            variant={loading || disabled ? 'disabled' : 'active'}
            loading={loading}
        />
    )
}
CashPaymentFooter.propTypes = {
    onCashPay: PropTypes.func,
    onCashSavePress: PropTypes.func,
    loading: PropTypes.bool,
    navigate: PropTypes.func,
    disabled: PropTypes.bool,
}

function EInvoiceFooter({ onNonCashProceed, saveLoading, ublLoading }) {

    return (
        <Footer
            onButtonPress={onNonCashProceed}
            variant={(ublLoading || saveLoading) ? 'disabled' : 'active'}
            loading={ublLoading || saveLoading}
        />
    )
}
EInvoiceFooter.propTypes = {
    onNonCashProceed: PropTypes.func,
    saveLoading: PropTypes.bool,
    ublLoading: PropTypes.bool,
}

function NonCashPaymentFooter({
    onCashPay,
    loading,
}) {

    const paymentMethod = useSelector(getPaymentMethod)
    const customer = useSelector(getPaymentCustomer)

    return (
        <Footer
            onButtonPress={onCashPay}
            variant={(paymentMethod?.code && !loading && customer?.id) ? 'active' : 'disabled'}
            loader={loading}
        />
    )
}
NonCashPaymentFooter.propTypes = {
    onCashPay: PropTypes.func,
    loading: PropTypes.bool,
}

function ExportInvoiceFooter({ onCashPay, loading }) {
    const shouldHaveBillingPeriod = useSelector(getShouldHaveBillingPeriod)
    const dates = useSelector(getPaymentDates)
    const paymentMethod = useSelector(getPaymentMethod)
    const orderSubType = useSelector(getPaymentOrderSubtype)
    const customer = useSelector(getPaymentCustomer)


    const billingPeriodChecker = () => {
        if (shouldHaveBillingPeriod) {
            if (dates.startDate && dates.endDate) {
                return true
            }
            else {
                return false
            }
        }
        return true
    }

    return (
        <Footer
            onButtonPress={onCashPay}
            variant={(paymentMethod?.code && orderSubType?.code && customer?.id && !loading && billingPeriodChecker()) ? 'active' : 'disabled'}
            loader={loading}
        />
    )
}
ExportInvoiceFooter.propTypes = {
    onCashPay: PropTypes.func,
    loading: PropTypes.bool,
}

function ReverseChargeFooter({
    onCashPay,
    onCashSavePress,
    loading,
}) {
    const paymentMethod = useSelector(getPaymentMethod)
    const customer = useSelector(getPaymentCustomer)
    const orderSubType = useSelector(getPaymentOrderSubtype)
    const orderType = useSelector(getPaymentOrderType)
    const isReverseCharge = useSelector(getIsReverseCharge)

    return (
        <Footer
            onOptionPress={onCashSavePress}
            onButtonPress={onCashPay}
            variant={(paymentMethod?.code && customer?.id && orderSubType?.code && orderType?.code && isReverseCharge?.code && !loading) ? 'active' : 'disabled'}
            loader={loading}
        />
    )
}
ReverseChargeFooter.propTypes = {
    onCashPay: PropTypes.func,
    onCashSavePress: PropTypes.func,
    loading: PropTypes.bool,
}

function SupportiveInvoiceFooter({ onSupportiveProceed, supportiveDisabled, supportInvLoading }) {

    return (
        <Footer
            onButtonPress={onSupportiveProceed}
            variant={supportiveDisabled || supportInvLoading ? 'disabled' : 'active'}
            loader={supportInvLoading}
        />
    )
}
SupportiveInvoiceFooter.propTypes = {
    supportiveDisabled: PropTypes.bool,
    supportInvLoading: PropTypes.bool,
    onSupportiveProceed: PropTypes.func,
}

export {
    CashPaymentFooter,
    EInvoiceFooter,
    NonCashPaymentFooter,
    ExportInvoiceFooter,
    ReverseChargeFooter,
    SupportiveInvoiceFooter,
}
