import { styleSheetFactory } from '../../../Themes/Colors'

const styles = styleSheetFactory(colors => ({
  container: {
    borderRadius: 7,
    overflow: 'hidden',
    flex: 1,
  },
  contentContainer: {
    backgroundColor: colors.background,
    paddingBottom: 80,
    paddingHorizontal: 15,
    flexGrow: 1,
  },
  fab: {
    position: 'absolute',
    bottom: 15,
    right: 15,
    height: 50,
    width: 50,
    borderRadius: 25,
    backgroundColor: colors.accent,
    justifyContent: 'center',
    alignItems: 'center',
    shadowColor: colors.black,
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.22,
    shadowRadius: 2.22,
    elevation: 3,
  },
  fabIcon: {
    transform: [{ rotate: '-90deg' }],
  },
  listStyle: {
    marginTop: 70,
  },
}))

export default styles
