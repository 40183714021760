import React, { useContext, useRef, useState } from 'react'
import { SafeAreaView, View, ScrollView } from 'react-native'
import PropTypes from 'prop-types'
import ShadowView from 'react-native-androw'
import LottieView from 'lottie-react-native'
import { useDispatch } from 'react-redux'
import { useTheme } from 'react-native-themed-styles'

import {
    Text,
    Header,
    Icon,
    Button,
    ErrorModal,
} from '../../Components'

import { onBoardingResetState } from '../OnBoarding/OnBoarding.Actions'

import Theme from '../../Themes/Theme'

import { images, animations } from '../../Themes'
import themedStyles from './OnBoardingSixthStep.Styles'

function OnBoardingSixthStep({ logout, refreshToken, next }) {
    const dispatchAction = useDispatch()
    const [styles] = useTheme(themedStyles)
    const { colors } = useContext(Theme)

    const [loading, setLoading] = useState(false)
    const [modal, setModal] = useState({
        isVisible: false,
        icon: images.warningIcon,
        title: '',
        desc: '',
        descPlaceholders: [],
        primaryText: '',
        primaryAction: null,
        tertiaryText: '',
        tertiaryAction: null,
    })
    const openModal = (
        title = 'opened_orders_error',
        desc = '',
        descPlaceholders = [],
        primaryText = '',
        primaryAction = null,
        tertiaryText = '',
        tertiaryAction = null,
        icon = images.warningIcon,
    ) => {
        setModal({
            isVisible: true,
            icon,
            title,
            desc,
            descPlaceholders,
            primaryText,
            primaryAction,
            tertiaryText,
            tertiaryAction,
        })
    }
    const closeModal = () => {
        setModal({
            isVisible: false,
            icon: images.delete,
            title: '',
            desc: '',
            primaryText: '',
            primaryAction: null,
            tertiaryText: '',
            tertiaryAction: null,
        })
    }

    const anim = useRef()

    return (
        <SafeAreaView style={styles.container}>
            <Header
                title={'demo_welcome_label'}
                image={images.close}
                onPress={() => {
                    openModal(
                        'logout_key',
                        'onboarding_logout_message_description',
                        [],
                        'logout_key',
                        () => {
                            logout()
                        },
                        'logout_button_cancel',
                        () => {
                            closeModal()
                        },
                    )
                }}
            />
            <ScrollView style={styles.scrollView}>
                <ShadowView style={styles.shadow}>
                    <View style={styles.imageContainer}>
                        <LottieView
                            ref={(ref) => {
                                anim.current = ref
                            }}
                            source={animations.celebrate}
                            autoPlay
                            loop={true}
                            resizeMode="contain"
                            style={styles.printAnimation}
                        />
                        <Text i18nKey={'onboarding_congatulations_title'} style={styles.lottieText} size="h3" weight="bold" align="center" />
                        <Text i18nKey={'onboarding_account_ready'} style={styles.lottieText} size="h4" weight="light" align="center" />
                    </View>
                </ShadowView>

                <View style={styles.contentContainer}>
                    <View style={styles.contentItem}>
                        <Icon source={images.salesDeclaration} size={24} style={styles.descIcon} />
                        <Text i18nKey={'onboarding_sales_declaration_title'} style={styles.stepText} />
                    </View>
                    <View style={styles.contentItem}>
                        <Icon source={images.fourG} size={24} style={styles.descIcon} />
                        <Text i18nKey={'onboarding_internet_title'} style={styles.stepText} />
                    </View>
                    <View style={styles.contentItem}>
                        <Icon source={images.reports} size={24} style={styles.descIcon} />
                        <Text i18nKey={'onboarding_reports_title'} style={styles.stepText} />
                    </View>
                </View>
            </ScrollView>
            <View style={styles.buttonContainer}>
                <Button
                    title="demo_get_started_button"
                    variant={loading ? 'disabled' : 'active'}
                    loader={loading}
                    iconColor={colors.accent}
                    style={styles.rightButton}
                    onPress={() => {
                        setLoading(true)
                        dispatchAction(onBoardingResetState())
                        refreshToken()
                    }}
                />
            </View>
            <ErrorModal
                isVisible={modal?.isVisible}
                title={modal?.title}
                description={modal?.desc}
                placeHolders={modal.descPlaceholders}
                primaryText={modal?.primaryText}
                tertiaryText={modal?.tertiaryText}
                primaryAction={modal?.primaryAction}
                tertiaryAction={modal?.tertiaryAction}
                onBackdropPress={() => closeModal()}
                onBackButtonPress={() => closeModal()}
                onClosePress={() => closeModal()}
            />
        </SafeAreaView>
    )
}

OnBoardingSixthStep.propTypes = {
    navigation: PropTypes.object,
    route: PropTypes.object,
    logout: PropTypes.func,
    refreshToken: PropTypes.func,
    next: PropTypes.func,
}

export default OnBoardingSixthStep
