import { styleSheetFactory } from '../../Themes/Colors'

const styles = styleSheetFactory(colors => ({
    container: {
        marginHorizontal: 15,

    },
    header: {
        marginTop: 30,
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    subtitle: {
        marginTop: 15,
        marginBottom: 10,
    },
    listStyle: {
        height: 300,
    },
    listContainerStyle: {
        paddingBottom: 30,
        // flexGrow: 1,
    },
}))

export default styles
