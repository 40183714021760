import Color from 'color'

import { styleSheetFactory } from '../../Themes/Colors'

const styles = styleSheetFactory(colors => ({
  container: {
    flex: 1,
    backgroundColor: colors.background,
  },
  imagesColorContainer: {
    alignSelf: 'center',
    marginTop: 25,
    backgroundColor: colors.white,
    borderRadius: 7,
    width: 132,
    height: 93,
    padding: 7.5,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  color: {
    width: 58.5,
    height: 39,
    justifyContent: 'center',
    alignItems: 'center',
  },
  colorIcon: {
    width: 35,
    height: 35,
    resizeMode: 'contain',
  },
  inputContainer: {
    marginTop: 10,
  },
  rightContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },

  image: {
    width: 58.5,
    height: 78,
    justifyContent: 'center',
    alignItems: 'center',
  },

  galleryIcon: {
    width: 35,
    height: 35,
    resizeMode: 'contain',
    tintColor: colors.white,
  },
  seperator: {
    height: 78,
    width: 1.5,
    backgroundColor: colors.white,
  },


  nameInput: {
    fontSize: 16,
    fontFamily: 'Vodafone Rg',
    padding: 0,
    paddingBottom: 3.5,
    flexGrow: 1,
  },

  categoryContainer: {
    height: 70,
    backgroundColor: colors.white,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginHorizontal: 15,
    marginTop: 10,
    borderRadius: 7,
    paddingHorizontal: 17,
    paddingVertical: 12,
  },
  category: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  primaryButton: {
    margin: 15,
  },
  modalContainer: {
    backgroundColor: colors.white,
    alignSelf: 'center',
    paddingBottom: 30,
    borderRadius: 6,
  },
  modalContent: {
    paddingHorizontal: 40,
    marginTop: 5,
    marginBottom: 30,
  },
  modalIcon: {
    alignSelf: 'center',
    marginBottom: 5,
  },
  modalTitle: {
    marginVertical: 8,
  },
  modalButton: {
    marginTop: 15,
    marginHorizontal: 15,
  },
  inputContainer1: {
    minHeight: 60,
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  itemSpacing: {
    marginVertical: 5,
  },
  separator: {
    height: 1,
    backgroundColor: colors.separator,
  },
  leftSpacing: { marginLeft: 5 },
  footer: { marginTop: 20 },
  typesGroup: { marginBottom: 15, marginTop: 5 },
}))

const itemStyles = styleSheetFactory(colors => ({
  container: {
    height: 70,
    backgroundColor: colors.tertiaryBackground,
    justifyContent: 'center',
    overflow: 'hidden',
  },
  firstItem_true: {
    borderTopLeftRadius: 7,
    borderTopRightRadius: 7,
  },
  lastItem_true: {
    borderBottomLeftRadius: 7,
    borderBottomRightRadius: 7,
  },
  touchable: {
    flexGrow: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    position: 'relative',
  },
  imageContainer: labelColor => {
    return {
      width: 70,
      height: '100%',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: Color(labelColor).fade(0.65).toString(),
    }
  },
  infoContainer: {
    flexGrow: 1,
    flexShrink: 1,
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
    padding: 15,
    paddingLeft: 5,
  },
  descContainer: {
    flexGrow: 1,
    flexShrink: 1,
  },
  priceContainer: {
    flexDirection: 'column',
    alignItems: 'baseline',
    flexShrink: 0,
    marginLeft: 10,
  },
}))

const hiddenItemStyles = styleSheetFactory(colors => ({
  wrapper: {
    backgroundColor: colors.accent,
    height: 70,
    justifyContent: 'center',
    alignItems: 'flex-end',
  },
  firstItem_true: {
    borderTopLeftRadius: 7.5,
    borderTopRightRadius: 7.5,
  },
  lastItem_true: {
    borderBottomLeftRadius: 7.5,
    borderBottomRightRadius: 7.5,
  },
  option: {
    width: 100,
    height: 70,
    justifyContent: 'center',
    alignItems: 'center',
  },
  optionFull: {
    flexDirection: 'row',
  },
  confirm: {
    flexGrow: 1,
    height: 70,
    flexDirection: 'row',
    paddingLeft: 15,
    alignItems: 'center',
  },
  confirmText: {
    marginLeft: 15,
  },
  cancel: {
    width: 40,
    height: 40,
    flexShrink: 0,
    marginVertical: 15,
    marginHorizontal: 15,
    borderRadius: 20,
    backgroundColor: colors.whiteRGBA(0.55),
    justifyContent: 'center',
    alignItems: 'center',
  },
  loader: {
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
}))


export { itemStyles, hiddenItemStyles }

export default styles
