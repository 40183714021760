import React, { useState } from 'react'
import {
    View,
    Dimensions,
    Linking,
} from 'react-native'
import ContentLoader, { Rect } from 'react-content-loader/native'
import { useTheme } from 'react-native-themed-styles'
import PropTypes from 'prop-types'
import QRCode from 'react-native-qrcode-svg'

import {
    Text,
    SimpleList,
    Button,
    Section,
    ScrollWrapperWeb,
} from '../../../Components'

import themedStyles from '../SupportiveInvoiceDetails.Styles'
import images from '../../../Themes/Images'
import { colors } from '../../../Themes'

const { width } = Dimensions.get('screen')

function LoaderContent({ isFirst, isLast, style }) {
    const [styles] = useTheme(themedStyles)
    const loaderStyle = {}
    if (isFirst) {
        loaderStyle.borderTopLeftRadius = 7
        loaderStyle.borderTopRightRadius = 7
    }
    if (isLast) {
        loaderStyle.borderBottomLeftRadius = 7
        loaderStyle.borderBottomRightRadius = 7
        loaderStyle.borderBottomWidth = 0
    }
    return (
        <ContentLoader
            speed={0.7}
            height={70}
            style={Object.assign({}, styles.loaderContainer, loaderStyle, style)}
            backgroundColor="#e2e2e2"
            foregroundColor="#ecebeb">
            <Rect x="20" y="20" rx="3" ry="3" width="90" height="20" />
            <Rect x={`${width - 150}`} y="18" rx="3" ry="3" width="100" height="24" />
        </ContentLoader>
    )
}
LoaderContent.propTypes = {
    isFirst: PropTypes.bool,
    isLast: PropTypes.bool,
    style: PropTypes.object,
}

function ItemLoader() {
    return (
        <View>
            <LoaderContent isFirst />
            <LoaderContent />
            <LoaderContent />
            <LoaderContent />
            <LoaderContent />
            <LoaderContent />
            <LoaderContent />
            <LoaderContent />
            <LoaderContent isLast />
        </View>
    )
}

function BasicInfo({ supportiveInvoiceData, loading }) {
    const [styles] = useTheme(themedStyles)

    const [items] = useState([])

    const sectionData = [
        {
            label: 'receipt_fic_label',
            value: supportiveInvoiceData?.NIVF,
        },
        {
            label: 'receipt_iic_label',
            value: supportiveInvoiceData?.NSLF,
        },
        {
            label: 'cash_register_business_code',
            value: supportiveInvoiceData?.businUnitCode,
        },
        {
            label: 'receipt_software_code_label',
            value: supportiveInvoiceData?.softCode,
        },
        {
            label: 'receipt_operator_code_label',
            value: supportiveInvoiceData?.operatorCode,
        },
    ]

    const basicInfoSection = [
        {
            label: 'einvoice_date_label',
            value: supportiveInvoiceData?.date,
        },
        {
            label: 'receipt_label_number',
            value: supportiveInvoiceData?.receiptNr,
        },
        {
            label: 'receipt_label',
            value: supportiveInvoiceData?.receipt,
        },
        {
            label: 'receipt_user',
            value: supportiveInvoiceData?.employeeEmail,
        },
        {
            label: 'einvoice_sellertin_label',
            value: supportiveInvoiceData?.issuerNIPT,
        },
    ]

    const invoiceDetailsSection = [
        {
            title: 'payment_supporting_invoice_title',
        },
        {
            label: 'supporting_invoice_invoice_type_label',
            value: supportiveInvoiceData?.invoiceType,
        },
        {
            label: 'supporting_invoice_transaction_type_label',
            value: supportiveInvoiceData?.transactionType,
        },
        {
            label: 'einvoice_start_date_label',
            value: supportiveInvoiceData?.startDate,
        },
    ]

    return (
        <ScrollWrapperWeb>
            <SimpleList
                DATA={items}
                containerStyle={styles.list}
                separatorStyle={styles.separatorStyle}
                initialNumToRender={20}
                refreshing={false}
                listFooterComponent={
                    loading ? <ItemLoader /> :
                        <>
                            <Section data={basicInfoSection} style={styles.sectionStyle} />
                            <Section data={invoiceDetailsSection} />
                            <View style={styles.invValContainer}>
                                <Text
                                    size="footnote"
                                    i18nKey="receipt_section_invoice_validation"
                                />

                                <View style={styles.qrCode}>
                                    {supportiveInvoiceData?.qrCode && <QRCode
                                        value={supportiveInvoiceData?.qrCode}
                                        size={90}
                                        backgroundColor={colors.background}
                                        color={colors.secondaryIcon}
                                    />}
                                </View>
                                <Button
                                    icon={images.link}
                                    iconStyle={styles.invoiceButton}
                                    iconColor={colors.quartenaryIcon}
                                    titleStyle={{ color: colors.primaryText }}
                                    title="receipt_button_invoice_validation"
                                    backgroundColor={colors.tertiaryBackground}
                                    style={styles.fullButton}
                                    borderColor={colors.transparent}
                                    onPress={() => Linking.openURL(supportiveInvoiceData?.qrCode)}
                                />
                                <View>
                                    <Section data={sectionData} />
                                </View>
                            </View>
                        </>
                }
            />
        </ScrollWrapperWeb>
    )
}
BasicInfo.propTypes = {
    supportiveInvoiceData: PropTypes.object,
    loading: PropTypes.bool,
}

export default BasicInfo
