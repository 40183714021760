import React, { useContext, useEffect, useState } from 'react'
import {
    View,
    TouchableOpacity,
    FlatList,
    TouchableWithoutFeedback,
} from 'react-native'
import { useTheme } from 'react-native-themed-styles'
import PropTypes from 'prop-types'

import Text from '../Text'
import Icon from '../Icon'


import Theme from '../../Themes/Theme'

import images from '../../Themes/Images'

import themedStyles from './SimpleSelectType.Styles'

function SimpleSelectType({
    data = [],
    closeModal,
    selected,
    select,
    title,
    Item,
    containerStyle,
    subtitle,
    textAlign,
    textSize,
}) {
    const [styles] = useTheme(themedStyles)
    const { colors } = useContext(Theme)
    const [items, setItems] = useState(data)
    useEffect(() => {
        if (data) {
            setItems(data)
        }

    }, [data])


    const keyExtractor = (_, index) => 'item_' + index
    const renderItem = ({ item }) => (
        <Item
            item={item}
            selected={selected}
            textAlign={textAlign}
            textSize={textSize}
            onPress={() => {
                select(item)
            }}
        />
    )

    return (
        <View style={styles.container}>
            <View style={styles.header}>
                <Text
                    i18nKey={title}
                    weight="bold"
                    size="h4"
                    color={colors.accent}
                />

                <TouchableOpacity onPress={closeModal}>
                    <Icon source={images.close} color={colors.secondaryIcon} size={24} />
                </TouchableOpacity>
            </View>
            {subtitle ? <Text
                style={styles.subtitle}
                i18nKey={subtitle}
                weight="light" /> : null}
            <TouchableWithoutFeedback>
                <FlatList
                    data={items}
                    onStartShouldSetResponder={() => true}
                    decelerationRate={0.8}
                    keyExtractor={keyExtractor}
                    showsVerticalScrollIndicator={false}
                    renderItem={renderItem}
                    style={styles.listStyle}
                    contentContainerStyle={[styles.listContainerStyle, containerStyle]}
                />
            </TouchableWithoutFeedback>
        </View>
    )
}

SimpleSelectType.defaultProps = {
    textAlign: 'center',
    textSize: 'footnote',
}

SimpleSelectType.propTypes = {
    data: PropTypes.array,
    select: PropTypes.func,
    selected: PropTypes.object,
    closeModal: PropTypes.func,
    title: PropTypes.string,
    searchValue: PropTypes.string,
    setSearchValue: PropTypes.func,
    placeholder: PropTypes.string,
    searchKey: PropTypes.string,
    Item: PropTypes.node,
    containerStyle: PropTypes.object,
    subtitle: PropTypes.string,
    textAlign: PropTypes.string,
    textSize: PropTypes.string,
}

export default SimpleSelectType
