import React from 'react'
import PropTypes from 'prop-types'
import { ScrollView } from 'react-native'

function KeyboardAwareScrollView({ children }) {

    return <ScrollView showsVerticalScrollIndicator={false} style={{ flex: 1 }} contentContainerStyle={{ flexGrow: 1 }}>
        {children}
    </ScrollView>

}
KeyboardAwareScrollView.propTypes = {
    children: PropTypes.any,
}

export default KeyboardAwareScrollView