import React from 'react'
import { SafeAreaView, View  } from 'react-native'
import { useTheme } from 'react-native-themed-styles'
import { WebView } from 'react-native-webview'
import LottieView from 'lottie-react-native'
import PropTypes from 'prop-types'

import { Header } from '../../Components'


import themedStyles from './TermsAndConditions.Styles'
import images, { animations } from '../../Themes/Images'

function TermsAndConditions({
  navigation: {
    goBack,
  },
}) {
  const [styles] = useTheme(themedStyles)

  return (
    <SafeAreaView style={styles.container}>
      <Header
        title="header_terms_conditions"
        image={images.back}
        onPress={() => goBack()}
      />
      <WebView
          source={{ uri: 'https://api-web-vfal.vodafone.com/file/eBiznes/Licence_Software_Agreement.html' }}
          style={styles.webView}
          androidHardwareAccelerationDisabled={true}
          startInLoadingState={true}
          renderLoading={() => (
            <View style={styles.lottieWrapper}>
              <LottieView
                style={[styles.lottie]}
                autoPlay
                loop={true}
                source={animations.vfLoaderRed}
              />
            </View>
          )}
        />
    </SafeAreaView >
  )
}
TermsAndConditions.propTypes = {
  navigation: PropTypes.object,
}

export default TermsAndConditions
