import { gql } from '@apollo/client'

export const GET_MY_INVOICE = gql`
  query getMyEinvoice($id: String!, $locId: String, $cashRegisterId: String){
    getMyEinvoice(
      id: $id
      requestedLocationAndCashRegister: {
        locationId: $locId,
        cashRegisterId: $cashRegisterId
      }
      ){
      myEinvoice {
        ublInvoice {
          note
          dueDate
          invoiceDocumentCode
          invoiceProcessCode
          buyerReference
          orderReference {
            id
            salesOrderId
          }
          contractDocumentReference {
            id
          }
          additionalDocumentReferences {
            id
            description
            uri
            binObj {
              filename
              contents
              mime
            }
          }
          projectReference {
            id
          }
          delivery {
            deliveryPartyName {
              name
            }
            actualDeliveryDate
            deliveryLocation {
              address {
                streetName
                cityName
                postalCode
                countryCode
              }
            }
            deliveryPartyName {
              name
            }
          }
          docCurrencyCode
          taxCurrencyCode
          invoicePeriod {
            startDate
            endDate
            descriptionCode
          }
          supplierParty {
            issuerNUIS
            name
            address {
              streetName
              cityCode
              countryCode
            }
            taxSchema
            registrationName
            contact {
              name
              telephone
              electronicMail
            }
          }
          customerParty {
            issuerNUIS
            name
            address {
              streetName
              cityCode
              countryCode
            }
            taxSchema
            registrationName
            contact {
              name
              telephone
              electronicMail
            }
          }
          paymentMeans {
            card {
              primaryAccountId
            }
            payeeFinantialAccount
            instructionNote
            code
          }
          taxTotal {
            taxAmount {
              amount
              currency
            }
            taxSubtotal {
              taxableAmount {
                amount
                currency
              }
              taxAmount {
                amount
                currency
              }
              taxCategory {
                id
                percent
                taxSchema
              }
            }
          }
          legalMonetaryTotal {
            lineExtension {
              amount
              currency
            }
            taxExclusive {
              amount
              currency
            }
            taxInclusive {
              amount
              currency
            }
            payable {
              amount
              currency
            }
            charge {
              amount
              currency
            }
            allowance {
              amount
              currency
            }
          }
          allowances {
            chargeIndicator
            chargeReason
            amount {
              amount
              currency
            }
            taxCategory {
              id
              percent
              taxSchema
            }
            baseAmount {
              amount
              currency
            }
            percent
          }
          invoiceLine {
            id
            quantity
            note
            lineExtension {
              amount
              currency
            }
            allowances {
              chargeIndicator
              chargeReason
              amount {
                amount
                currency
              }
              taxCategory {
                id
                percent
                taxSchema
              }
              baseAmount {
                amount
                currency
              }
              percent
            }
            item {
              name
              sellerIdentification
              description
              price {
                amount
                currency
              }
              taxCategory {
                id
                percent
                taxSchema
              }
              baseQuantity {
                quantity
                unitCode
              }
            }
          }
        }
      }
    }
  }
`

export const REGISTER_EINVOICE = gql`
  mutation registerEinvoice($eInvoiceInput: EinvoiceInput!, $locId: String, $cashRegisterId: String) {
    registerEinvoice(
      eInvoiceInput: $eInvoiceInput
      requestedLocationAndCashRegister: {
        locationId: $locId,
        cashRegisterId: $cashRegisterId
      }
      ){
      ublInvoice {
        id
        note
      }
    }
  }
`

export const CALCULATE_UBL = gql`
  mutation calculateUbl($ublInput: EinvoiceInput!) {
    calculateUbl(ublInput: $ublInput){
      ublInvoice {
        note
        dueDate
        invoiceDocumentCode
        invoiceProcessCode
        buyerReference
        orderReference {
          id
          salesOrderId
        }
        contractDocumentReference {
          id
        }
        additionalDocumentReferences {
          id
          description
          uri
          binObj {
            filename
            contents
            mime
          }
        }
        projectReference {
          id
        }
        delivery {
          actualDeliveryDate
          deliveryLocation {
            address {
              streetName
              cityName
              postalCode
              countryCode
            }
          }
          deliveryPartyName {
            name
          }
        }
        docCurrencyCode
        taxCurrencyCode
        invoicePeriod {
          startDate
          endDate
          descriptionCode
        }
        billingReference {
          invoiceDocumentReference {
            id
            issued
          }
        }
        supplierParty {
          issuerNUIS
          name
          address {
            streetName
            cityCode
            countryCode
            postalCode
          }
          taxSchema
          registrationName
          contact {
            name
            telephone
            electronicMail
          }
        }
        customerParty {
          issuerNUIS
          name
          address {
            streetName
            cityCode
            countryCode
            postalCode
          }
          taxSchema
          registrationName
          contact {
            name
            telephone
            electronicMail
          }
        }
        paymentMeans {
          code
          instructionNote
          card {
            primaryAccountId
            networkId
          }
          payeeFinantialAccount
        }
        taxTotal {
          taxAmount {
            amount
            currency
          }
          taxSubtotal {
            taxableAmount {
              amount
              currency
            }
            taxAmount {
              amount
              currency
            }
            taxCategory {
              id
              percent
              taxSchema
            }
          }
        }
        legalMonetaryTotal {
          lineExtension {
            amount
            currency
          }
          taxExclusive {
            amount
            currency
          }
          taxInclusive {
            amount
            currency
          }
          payable {
            amount
            currency
          }
          charge {
            amount
            currency
          }
          allowance {
            amount
            currency
          }
        }
        allowances {
          chargeIndicator
          chargeReason
          amount {
            amount
            currency
          }
          taxCategory {
            id
            percent
            taxSchema
          }
          baseAmount {
            amount
            currency
          }
          percent
        }
        invoiceLine {
          id
          quantity
          note
          lineExtension {
            amount
            currency
          }
          allowances {
            chargeIndicator
            chargeReason
            amount {
              amount
              currency
            }
            taxCategory {
              id
              percent
              taxSchema
            }
            baseAmount {
              amount
              currency
            }
            percent
          }
          item {
            name
            sellerIdentification
            description
            price {
              amount
              currency
            }
            taxCategory {
              id
              percent
              taxSchema
            }
            baseQuantity {
              quantity
              unitCode
            }
          }
        }
      }
    }
  }
`

