import { styleSheetFactory } from '../Themes/Colors'

const styles = styleSheetFactory(colors => ({
  container: {
    flex: 1,
    backgroundColor: colors.background,
  },
  stack: {
    flex: 1,
  },
}))

export default styles
