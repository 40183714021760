import { styleSheetFactory } from '../../Themes/Colors'

const styles = styleSheetFactory(colors => ({
  container: {
    flex: 1,
    backgroundColor: colors.background,
  },
  viewPager: {
    flexGrow: 1,
    flexShrink: 1,
  },
  indicatorContainer: {
    width: '100%',
    height: 20,
    marginVertical: 10,
    alignContent: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    flexShrink: 0,
  },
  leftButton: {
    flex: 1,
    height: 50,
  },
  rightButton: {
    flex: 1,
    height: 50,
    marginRight: 15,
  },
  rightButtonIcon: {
    marginLeft: 10,
  },
  leftButtonIcon: {
    marginRight: 15,
  },
  indicator_false: {
    width: 10,
    height: 10,
    backgroundColor: colors.disabled,
    borderRadius: 10,
    marginHorizontal: 7.5,
  },
  indicator_true: {
    width: 10,
    height: 10,
    backgroundColor: colors.accent,
    borderRadius: 10,
    marginHorizontal: 7.5,
  },
  buttonContainer: {
    flexDirection: 'row',
    marginTop: 15,
    marginHorizontal: 15,
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  // Modal
  modalContent: {
    margin: 15,
    padding: 30,
    borderRadius: 7,
    backgroundColor: colors.tertiaryBackground,
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    paddingTop: 45,
    overflow: 'hidden',
  },
  modalCloseContainer: {
    position: 'absolute',
    top: 0,
    right: 0,
    height: 30,
    width: 75,
    borderTopRightRadius: 7,
    borderBottomLeftRadius: 7,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: colors.separator,
  },
  modalTitle: {
    marginVertical: 10,
  },
  modalDesc: {
    marginBottom: 15,
  },
  modalPrimaryButton: {
    width: '100%',
    marginVertical: 15,
  },
  modalSecondaryButton: {
    width: '100%',
  },

}))

export default styles
