import React, { useContext, useEffect, useState } from 'react'
import { View, TouchableOpacity, SafeAreaView, FlatList } from 'react-native'
import { useTheme } from 'react-native-themed-styles'
import PropTypes from 'prop-types'
import { useMutation } from '@apollo/client'

import {
    Text,
    Header,
    RadioInput,
    Button,
    Icon,
    ErrorModal,
} from '../../Components'
import Confirmation from '../RegisterCertificate/Confirmation'

import { TOGGLE_TAX } from '../Taxes/Taxes.schema'
import { SEND_PIN, GENERATE_AND_SEND_PIN } from '../RegisterCertificate/RegisterCertificate.schema'

import { getErrorMessage, useUserDetails } from '../../Utils'
import useTaxDetails from '../../Utils/Tax'

import Theme from '../../Themes/Theme'
import themedStyles, { itemStyles, separatorStyles } from './SelectDefaultTax.Styles'
import images from '../../Themes/Images'

function Tax({ name, id, isDefault, onPress, isFirst, isLast, selected }) {
    const [styles] = useTheme(itemStyles)
    const { colors } = useContext(Theme)

    return <View style={[styles.container, styles['firstItem_' + isFirst], styles['lastItem_' + isLast]]}>
        <TouchableOpacity onPress={onPress} style={styles.touchable} activeOpacity={0.5}>
            <View style={styles.textContainer}>
                <Text i18nKey={'taxes_name_default_' + isDefault} placeHolders={[name]} numberOfLines={2} style={styles.name} />
            </View>
            <View pointerEvents="none" style={styles.radioInput}>
                <RadioInput
                    index={id}
                    selected={selected}
                    onPress={onPress}
                    style={styles.selector}
                    color={colors.secondaryAccent}
                />
            </View>
        </TouchableOpacity>
    </View>
}
Tax.propTypes = {
    name: PropTypes.string,
    selected: PropTypes.string,
    id: PropTypes.any,
    isDefault: PropTypes.bool,
    isLoading: PropTypes.bool,
    onPress: PropTypes.func,
    isFirst: PropTypes.bool,
    isLast: PropTypes.bool,
}

function Separator() {
    const [styles] = useTheme(separatorStyles)
    return <View style={styles.container} />
}

const keyExtractor = item => item.id
function SelectDefaultTax({ navigation:
    {
        goBack,
    },
    route: {
        params: {
            items,
            listTaxes,
        },
    } }) {

    const [styles] = useTheme(themedStyles)
    const { colors } = useContext(Theme)
    const { phoneNumber } = useUserDetails()
    const { setReload } = useTaxDetails()
    const [selected, setSelected] = useState('')
    const [taxes, setTaxes] = useState([])
    const [pin, setPin] = useState('')
    const [isConfirm, setIsConfirm] = useState(false)
    const [disabled, setDisabled] = useState(true)

    const [modal, setModal] = useState({
        isVisible: false,
        icon: images.warningIcon,
        title: '',
        desc: '',
        descPlaceholders: [],
        primaryText: '',
        primaryAction: null,
        tertiaryText: '',
        tertiaryAction: null,
    })
    const openModal = (
        title = 'opened_orders_error',
        desc = '',
        descPlaceholders = [],
        primaryText = '',
        primaryAction = null,
        tertiaryText = '',
        tertiaryAction = null,
        icon = images.warningIcon,
    ) => {
        setModal({
            isVisible: true,
            icon,
            title,
            desc,
            descPlaceholders,
            primaryText,
            primaryAction,
            tertiaryText,
            tertiaryAction,
        })
    }
    const closeModal = () => {
        setModal({
            isVisible: false,
            icon: images.delete,
            title: '',
            desc: '',
            primaryText: '',
            primaryAction: null,
            tertiaryText: '',
            tertiaryAction: null,
        })
    }

    const [changeTax, { loading }] = useMutation(TOGGLE_TAX)
    const [sendPin, { }] = useMutation(GENERATE_AND_SEND_PIN, { variables: { id: 'static' } })

    useEffect(() => {
        if (items) {
            setTaxes(items.filter(e => e.status === 'ACTIVE'))
            setSelected(items.find(e => e.isDefault))
        }
    }, [items])

    useEffect(() => {
        if (!isConfirm) {
            selected?.isDefault ? setDisabled(true) : setDisabled(false)
        } else {
            pin?.length < 5 ? setDisabled(true) : setDisabled(false)
        }
    }, [pin, selected, isConfirm])

    const onSavePress = () => {
        if (!isConfirm) {
            sendPin().then(() => {
                setIsConfirm(true)
            }).catch((err) => {
                openModal(
                    'certificate_error_label',
                    getErrorMessage(err),
                    '',
                    'cash_register_okay_button',
                    () => closeModal(),
                    'logout_button_cancel',
                    undefined,
                    images.error,
                )
            })
        } else {
            changeTax({
                variables: {
                    id: selected?.id,
                    isDefault: true,
                    verificationToken: pin,
                },
            }).then(() => {
                setReload(prev => !prev)
                goBack()
                listTaxes()
            }).catch((err) => {
                openModal(
                    'certificate_error_label',
                    getErrorMessage(err),
                    '',
                    'cash_register_okay_button',
                    () => closeModal(),
                    'logout_button_cancel',
                    undefined,
                    images.error,
                )
            })
        }
    }

    const renderItem = ({ item, index }) => {
        return <Tax
            {...item}
            onPress={() => setSelected(item)}
            selected={selected?.id}
            isFirst={index === 0}
            isLast={index === items.length - 1}
        />
    }

    const resendPin = () => {
        sendPin().catch((err) => {
            openModal(
                'certificate_error_label',
                getErrorMessage(err),
                '',
                'cash_register_okay_button',
                () => closeModal(),
                'logout_button_cancel',
                undefined,
                images.error,
            )
        })
    }

    return (
        <>
            <SafeAreaView style={styles.container}>
                <Header
                    title="header_taxes"
                    image={images.back}
                    onPress={() => goBack()}
                />
                {!isConfirm ?
                    <FlatList
                        data={taxes}
                        keyExtractor={keyExtractor}
                        renderItem={renderItem}
                        ItemSeparatorComponent={Separator}
                        // refreshing={loading}
                        // onRefresh={() => listTaxes()}
                        ListHeaderComponent={(
                            <>
                                <View style={styles.footer}>
                                    <View style={styles.note}>
                                        <Icon source={images.info} size={20} style={styles.icon} />
                                        <Text i18nKey="taxes_label_note" weight="bold" />
                                    </View>
                                    <Text i18nKey="taxes_change_default_note" size="footnote" weight="light" color={colors.secondaryText} />
                                </View>
                                <Text i18nKey="taxes_change_default_info" align="center" color={colors.secondaryText} />
                            </>
                        )}

                        ListHeaderComponentStyle={styles.header}
                        // ListFooterComponentStyle={styles.footer}
                        contentContainerStyle={styles.contentContainer}
                    /> : <Confirmation resend={() => { resendPin() }} value={pin} setValue={(val) => { setPin(val) }} />
                }
                <Button loader={loading} variant={disabled || loading ? 'disabled' : 'active'} style={styles.button} title="taxes_save_button" onPress={() => onSavePress()} />
            </SafeAreaView>
            <ErrorModal
                isVisible={modal?.isVisible}
                title={modal?.title}
                icon={modal?.icon}
                description={modal?.desc}
                placeHolders={modal.descPlaceholders}
                primaryText={modal?.primaryText}
                tertiaryText={modal?.tertiaryText}
                primaryAction={modal?.primaryAction}
                tertiaryAction={modal?.tertiaryAction}
                onBackdropPress={() => closeModal()}
                onBackButtonPress={() => closeModal()}
                onClosePress={() => closeModal()}
            />
        </>
    )

}

SelectDefaultTax.propTypes = {
    navigation: PropTypes.object,
    route: PropTypes.object,
}

export default SelectDefaultTax


