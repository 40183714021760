/* eslint-disable react-native/no-inline-styles */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react'
import { SafeAreaView, View, FlatList, Animated, Easing, TouchableOpacity, Keyboard } from 'react-native'
import moment from 'moment'
import PropTypes from 'prop-types'
import { useLazyQuery, useQuery, useMutation } from '@apollo/client'
import { useTheme } from 'react-native-themed-styles'
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs'
import { useFocusEffect } from '@react-navigation/native'

import {
  Text,
  Loader,
  Button,
  Icon,
  InputRow,
  Header,
  SimpleType,
  BottomSheetModal,
  SimpleSelectType,
  TouchableInput,
  LoaderModal,
  ItemStatus,
  Tabs,
  ErrorModal,
} from '../../Components'
import ContentManager from '../../Utils/ContentManager'
import { useLanguage } from '../../Utils/Language'
import { getErrorMessage, INF } from '../../Utils/Helpers'

import {
  CREATE_CASH_DRAWER_SHIFT_EVENT,
  GET_CURRENT_DRAWER,
} from '../CashDrawer/CashDrawer.schema'
import { LIST_CASH_DRAWER_SHIFTS_EVENTS } from '../TodaySales/TodaySales.schema'

import { useConfig } from '../../Utils'

import images from '../../Themes/Images'
import themedStyles, { routeStyles } from './PayInOut.Styles'
import { colors } from '../../Themes'

const Tab = createMaterialTopTabNavigator()

const AnimatedTouchable = Animated.createAnimatedComponent(TouchableOpacity)

const eventTypeDesc = {
  title: {
    PAID_IN: 'drawer_paid_in_label',
    PAID_OUT: 'drawer_paid_out_label',
    TOTAL: 'drawer_total_label',
  },
  amountSign: {
    PAID_IN: '',
    PAID_OUT: '-',
    TOTAL: '',
    CARD_PAID_IN: '',
  },
}

const LANGUAGES = {
  al: 'sq',
  en: 'en-gb',
}

// @TODO fix
const renderItemStatus = (itemStatus, code) => {
  const ItemStatuses = {
    ACCEPTED: <ItemStatus
      statusStyle={{ marginLeft: 8 }}
      color={colors.green}
      title="invoice_status_success"
    />,
    REJECTED: <ItemStatus
      statusStyle={{ marginLeft: 8 }}
      color={colors.accent}
      title="invoice_status_failed"
      code={code} />,
    IN_PROGRESS: <ItemStatus
      statusStyle={{ marginLeft: 8 }}
      color={colors.secondaryAccent}
      title="IN_PROGRESS"
    />,
    null: null,
    undefined: null,
  }
  return ItemStatuses?.[itemStatus] || null
}
function Item({
  item,
  style,
  onItemPress,
}) {
  const [styles] = useTheme(routeStyles)
  const lang = useLanguage()

  const { translate } = ContentManager

  if (item.eventType === 'TOTAL') {
    return (
      <View style={[styles.itemStyle, style]}>
        <View style={styles.itemContainer}>
          <View style={styles.listDescContainer}>
            <Text
              i18nKey={`${translate(eventTypeDesc.title[item.eventType])} ${translate(eventTypeDesc.title[item.type])}`}
              numberOfLines={1} size="footnote"
            />
          </View>
          <View style={styles.priceContainer}>
            <Text i18nKey={`${translate(eventTypeDesc.amountSign[item.eventType])}${INF.format(item?.eventMoney?.amount)} ${item?.eventMoney?.currency}`} />
          </View>
        </View>
      </View >
    )
  }


  return (
    <View style={[styles.itemStyle, style]}>
      <TouchableOpacity style={styles.itemContainer} onPress={onItemPress} >
        <View style={styles.listDescContainer}>
          <Text
            i18nKey={item?.statementNumber || '...'}
            numberOfLines={1}
          />
          <View style={styles.rowView}>
            <Text
              i18nKey={`${moment(item?.createdAt).locale(LANGUAGES[lang]).format('hh:mm A')}`}
              numberOfLines={1}
              weight="light"
              color={colors.secondaryText}
            />
            {renderItemStatus(item?.status, item?.FCDC)}
          </View>
          <Text
            i18nKey={item?.employee?.email}
            weight="light"
            color={colors.secondaryText}
            numberOfLines={1}
            size="footnote"
          />
        </View>
        <View style={styles.priceContainer}>
          <View style={styles.rightTexts}>
            <Text i18nKey={`${translate(eventTypeDesc.amountSign[item.eventType])}${INF.format(item?.eventMoney?.amount)} ${item?.eventMoney?.currency || 'Lekë'}`} />
          </View>
          <Icon source={images.arrow_right} size={20} />
        </View>
      </TouchableOpacity>
    </View >
  )
}
Item.propTypes = {
  item: PropTypes.object,
  style: PropTypes.object,
  onItemPress: PropTypes.func,
}
function Route({
  eventType,
  shiftId,
  data,
  loading,
  cashRegisterId,
  navigate,
}) {
  const [styles] = useTheme(routeStyles)
  const lang = useLanguage()

  const [items, setItems] = useState([])
  const [endLoading, setEndLoading] = useState(false)
  const [loading1, setLoading] = useState(true)
  const listRef = useRef(null)

  const [list, { loading: listLoading, data: listData, refetch, fetchMore }] = useLazyQuery(
    LIST_CASH_DRAWER_SHIFTS_EVENTS,
    { fetchPolicy: 'network-only' }
  )

  const listShifts = () => {
    list({
      variables: {
        locationId: '123123',
        shiftId: shiftId,
        limit: 20,
        eventType: eventType,
        cashRegisterId: cashRegisterId,
        status: ['REJECTED', 'IN_PROGRESS'],
      },
    })
  }

  useEffect(() => {
    listShifts()
  }, [])

  useEffect(() => {
    if (listData) {

      const events = listData?.listCashDrawerShiftEvents?.events
      let sum = 0
      let amount = 0
      let currency = 'Lekë'
      let type
      const result = []
      events.map(event => {
        amount = event.eventMoney?.amount || 0.0
        currency = event.eventMoney?.currency || 'Lekë'
        result.push(event)

        if (event.eventType === 'PAID_IN' || event.eventType === 'CARD_PAID_IN') {
          sum += amount
          type = 'PAID_IN'
        } else {
          sum -= amount
          type = 'PAID_OUT'
        }
      })

      if (events.length > 0) {
        result.push({
          id: 'total-id',
          eventType: 'TOTAL',
          eventMoney: {
            amount: Number(sum.toFixed(2)),
            currency: currency,
          },
          type: type,
        })
      }
      setItems(result)
      setLoading(false)
      setEndLoading(false)
    }
  }, [listData])

  useEffect(() => {
    if (data) {
      if (items.length < 1) {
        listShifts()
      } else {
        refetch()
      }
    }
  }, [data, loading])

  const onEndReached = () => {
    if (listData?.listCashDrawerShiftEvents?.cursor === null) {
      return
    }
    setEndLoading(true)
    fetchMore({
      query: LIST_CASH_DRAWER_SHIFTS_EVENTS,
      variables: {
        locationId: '123123',
        cursor: listData?.listCashDrawerShiftEvents?.cursor,
        limit: 20,
        shiftId: shiftId,
        eventType: eventType,
      },
    })
  }

  const fabSize = useRef(new Animated.Value(0)).current
  const animate = val => {
    Animated.timing(fabSize, {
      toValue: val,
      duration: 370,
      useNativeDriver: true,
      easing: Easing.inOut(Easing.cubic),
    }).start()
  }

  const scrollToTop = () => {
    animate(0)
    listRef.current.scrollToOffset({
      offset: 0,
    })
  }

  return (
    <>
      <FlatList
        onStartShouldSetResponder={() => true}
        showsVerticalScrollIndicator={false}
        keyExtractor={(item, index) => 'row-' + index}
        ref={(ref) => (listRef.current = ref)}
        onMomentumScrollEnd={({ nativeEvent }) => {
          if (nativeEvent.contentOffset.y > 800) {
            animate(1)
          } else {
            animate(0)
          }
        }}
        data={items}
        style={styles.listStyle}
        refreshing={false}
        onRefresh={() => refetch()}
        contentContainerStyle={styles.listContainerStyle}
        renderItem={({ item, index }) => {
          const style = {}
          if (index === 0) {
            style.borderTopLeftRadius = 7
            style.borderTopRightRadius = 7
          }
          if (index === items.length - 1) {
            style.borderBottomLeftRadius = 7
            style.borderBottomRightRadius = 7
          }
          return <Item
            key={'row-' + index}
            item={item}
            style={style}
            onItemPress={() => navigate('PayInOutDetails', { type: item?.eventType, cashRegisterId, eventId: item?.id })}
          />
        }}
        ListFooterComponent={listLoading || endLoading ? <Loader size={25} /> : null}
        ListEmptyComponent={listLoading || loading1 ? null : <Text i18nKey="drawer_no_events_label" style={styles.topSpacing} />}
        ListHeaderComponent={Boolean(items.length) && <Text
          i18nKey={moment(items?.[0]?.createdAt).locale(LANGUAGES[lang]).format('MMM DD, YYYY')}
          size="footnote"
          style={styles.bottomSpacing}
        />}
        ItemSeparatorComponent={() => <View style={styles.separator} />}
        onEndReached={onEndReached}
      />
      <AnimatedTouchable
        onPress={scrollToTop}
        activeOpacity={0.5}
        style={[
          styles.fab,
          {
            opacity: fabSize,
            transform: [{ scale: fabSize }],
          },
        ]}>
        <Icon
          source={images.arrow_right}
          size={20}
          color={colors.tertiaryIcon}
          style={styles.fabIcon}
        />
      </AnimatedTouchable>
    </>
  )
}
Route.propTypes = {
  eventType: PropTypes.string,
  shiftId: PropTypes.string,
  data: PropTypes.array,
  loading: PropTypes.bool,
  cashRegisterId: PropTypes.string,
  navigate: PropTypes.func,
}

const REASONS = [
  {
    id: '1',
    label: 'drawer_pay_in_reason',
    value: 'drawer_pay_in_reason',
    type: 'payin',
  },
  {
    id: '2',
    label: 'drawer_pay_out_reason',
    value: 'drawer_pay_out_reason',

    type: 'payout',
  },
  {
    id: '3',
    label: 'drawer_pay_inout_payment_reason',
    value: 'drawer_pay_inout_payment_reason',
    type: 'payment',
  },
  {
    id: '4',
    label: 'drawer_pay_inout_credit_card_reason',
    value: 'drawer_pay_inout_credit_card_reason',
    eventType: 'CARD_PAID_IN',
    type: 'payin',
    key: 'CARD_PAID_IN',
  },
]
function PayInOut({
  route: {
    params: { shiftId, isCurrent, cashRegisterId, isPayment = false, eventType },
  },
  navigation: {
    navigate,
    goBack,
  },
}) {

  const { switchLocation: { deviceId, locationId } } = useConfig()

  const [styles] = useTheme(themedStyles)
  // Current drawer
  const [currentDrawerId, setCurrentDrawerId] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const { translate } = ContentManager

  const { data: curentDrawerData } = useQuery(
    GET_CURRENT_DRAWER,
    {
      fetchPolicy: 'network-only',
      variables: {
        locationId: locationId,
        cashRegisterId: deviceId,
      },
    },
  )

  useEffect(() => {
    if (curentDrawerData) {
      const id = curentDrawerData.currentCashDrawerShift?.id
      if (id) {
        setCurrentDrawerId(id)
      }
    }
  }, [curentDrawerData])

  // Pay In/Out
  const [value, setValue] = useState('')
  const submitValueFormat = /^\d{0,12}(\.\d{0,2})?$/
  const [description, setDescription] = useState('')
  const [reason, setReason] = useState(isPayment ? {
    id: '3',
    label: 'drawer_pay_inout_payment_reason',
    value: 'drawer_pay_inout_payment_reason',
    type: 'payment',
  } : {
    id: '1',
    label: 'drawer_pay_in_reason',
    value: 'drawer_pay_in_reason',
    type: 'payin',
  })


  //Error Modal
  const [errorModal, setErrorModal] = useState({
    isVisible: false,
    icon: images.warningIcon,
    title: '',
    desc: '',
    descPlaceholders: [],
    primaryText: '',
    primaryAction: null,
    secondaryText: '',
    secondaryAction: null,
  })
  const openModal = (
    title = 'opened_orders_error',
    desc = '',
    descPlaceholders = [],
    primaryText = '',
    primaryAction = null,
    secondaryText = '',
    secondaryAction = null,
    icon = images.warningIcon,
  ) => {
    setErrorModal({
      isVisible: true,
      icon,
      title,
      desc,
      descPlaceholders,
      primaryText,
      primaryAction,
      secondaryText,
      secondaryAction,
    })
  }
  const closeErrorModal = () => {
    setErrorModal({
      isVisible: false,
      icon: images.delete,
      title: '',
      desc: '',
      primaryText: '',
      primaryAction: null,
      secondaryText: '',
      secondaryAction: null,
    })
  }

  const [reasonModal, setReasonModal] = useState({
    isVisible: false,
    data: REASONS,
    title: '',
    subtitle: '',
    selected: undefined,
    select: () => { },
    Item: SimpleType,
    onModalHide: () => { },
  })

  const openReasonSelector = () => {
    setReasonModal({
      isVisible: true,
      data: REASONS,
      title: 'payment_self_charge_reason_placeholder',
      subtitle: 'gov_select_reason',
      selected: reason?.id,
      select: (obj) => {
        setReason(obj)
        closeModal()
      },
      Item: SimpleType,
      onModalHide: () => closeModal(),
    })
    Keyboard.dismiss()
  }
  const closeModal = () => {
    setReasonModal(prev => ({ ...prev, isVisible: false }))
  }

  const buttonProps = {
    disabled_true: {
      backgroundColor: colors.disabled,
      foregroundColor: colors.buttonSecondaryAccent,
    },
    backgroundColor: {
      true: colors.accent,
      false: colors.buttonSecondaryAccent,
    },
    foregroundColor: {
      true: colors.buttonSecondaryAccent,
      false: colors.accent,
    },
    textPayIn: {
      true: 'drawer_button_confirm_payin',
      false: 'drawer_button_payin',
    },
    textPayOut: {
      true: 'drawer_button_confirm_payout',
      false: 'drawer_button_payout',
    },
  }

  useFocusEffect(
    useCallback(() => {
      setIsLoading(prev => !prev)
    }, [])
  )

  // Confirm Pay In / Pay Out
  const [confirmPayIn, setConfirmPayIn] = useState(false)
  const [confirmPayOut, setConfirmPayOut] = useState(false)
  const [disabledPayIn, setDisabledPayIn] = useState(true)
  const [disabledPayOut, setDisabledPayOut] = useState(true)

  const [createShiftEvent, { loading, data }] = useMutation(
    CREATE_CASH_DRAWER_SHIFT_EVENT,
  )

  const confirmPayInOut = (payIn, payOut) => {
    setConfirmPayIn(payIn)
    setConfirmPayOut(payOut)
  }

  const replaceAt = (string, index, replacement) => {
    return string.substr(0, index) + replacement + string.substr(index + replacement.length)
  }

  const changeValue = (text) => {
    let newString = text
    const indexOfComa = text.indexOf(',')
    if (indexOfComa >= 0) {
      newString = replaceAt(text, indexOfComa, '.')
    }
    if (/^\d{0,12}(\.\d{0,2})?$/.test(newString)) {
      setValue(newString)
      if (text === '' || parseFloat(text) <= 0 || description === '') {
        setDisabledPayIn(true)
        setDisabledPayOut(true)
      } else {
        setDisabledPayIn(false)
        setDisabledPayOut(false)
      }
    }
  }

  const changeDescription = text => {
    setDescription(text)
    confirmPayInOut(false, false)
    if (value === '' || text === '' || parseFloat(value) <= 0) {
      setDisabledPayIn(true)
      setDisabledPayOut(true)
    } else {
      setDisabledPayIn(false)
      setDisabledPayOut(false)
    }
  }

  const payIn = () => {
    if (submitValueFormat.test(value) && description !== '') {
      if (confirmPayIn) {
        Keyboard.dismiss()
        setDisabledPayIn(true)
        setDisabledPayOut(true)
        createShiftEvent({
          variables: {
            locationId: 'test',
            locId: locationId,
            cashRegisterId: deviceId,
            shiftId: currentDrawerId,
            description: description,
            amount: value,
            currency: 'Lek',
            //@tofix
            eventType: reason?.key || 'PAID_IN',
            reason: translate(reason?.value),

          },
        })
          .then((res) => {
            const payInOutObj = res?.data?.createCashDrawerShiftEvent
            navigate('PayInOutDetails', { type: payInOutObj?.eventType, cashRegisterId, eventId: payInOutObj?.id })
            setValue('')
            setDescription('')
            confirmPayInOut(false, false)
          })
          .catch((err) => {
            openModal(
              'certificate_error_label',
              getErrorMessage(err),
              [],
              '',
              undefined,
              '',
              undefined,
            )
          })
      } else {
        confirmPayInOut(true, false)
      }
    }
  }

  const payOut = () => {
    if (submitValueFormat.test(value) && description !== '') {
      if (confirmPayOut) {
        Keyboard.dismiss()
        setDisabledPayIn(true)
        setDisabledPayOut(true)
        createShiftEvent({
          variables: {
            locationId: 'test',
            locId: locationId,
            cashRegisterId: deviceId,
            shiftId: currentDrawerId,
            description: description,
            amount: value,
            currency: 'Lek',
            eventType: 'PAID_OUT',
            reason: translate(reason?.value),
          },
        })
          .then((res) => {
            const payInOutObj = res?.data?.createCashDrawerShiftEvent
            navigate('PayInOutDetails', { type: payInOutObj?.eventType, cashRegisterId, eventId: payInOutObj?.id })
            setValue('')
            setDescription('')
            confirmPayInOut(false, false)

          })
          .catch((err) => {
            openModal(
              'certificate_error_label',
              getErrorMessage(err),
              [],
              '',
              undefined,
              '',
              undefined,
            )
          })
      } else {
        confirmPayInOut(false, true)
      }
    }
  }

  const renderTabs = useMemo(() => (
    <Tabs
      style={styles.tabView}
      initialRouteName="Drawer"
    >
      <Tab.Screen key="Paid In" name={translate('drawer_paid_in_label')}>
        {props => (
          <Route
            {...props}
            screen="paidIn"
            eventType="PAID_IN"
            shiftId={shiftId}
            data={data}
            loading={isLoading}
            cashRegisterId={cashRegisterId}
            navigate={navigate}
          />
        )}
      </Tab.Screen>
      <Tab.Screen key="Paid Out" name={translate('drawer_paid_out_label')}>
        {props => (
          <Route
            {...props}
            screen="paidOut"
            eventType="PAID_OUT"
            shiftId={shiftId}
            data={data}
            loading={isLoading}
            cashRegisterId={cashRegisterId}
            navigate={navigate}
          />
        )}
      </Tab.Screen>
      <Tab.Screen key="Card Paid In" name={translate('drawer_card_pay_in_label')}>
        {props => (
          <Route
            {...props}
            screen="cardPaidIn"
            eventType="CARD_PAID_IN"
            shiftId={shiftId}
            data={data}
            loading={isLoading}
            cashRegisterId={cashRegisterId}
            navigate={navigate}
          />
        )}
      </Tab.Screen>
    </Tabs>
  ), [data, loading, isLoading])


  return (
    <>
      <SafeAreaView style={styles.container}>
        <Header
          title="header_pay_in_out"
          image={images.closeIcon}
          onPress={() => goBack()}
        />
        {isCurrent && <View style={styles.horizontalSelfSpacing}>
          <View style={styles.rowView}>
            <InputRow
              label="drawer_label_amount"
              inputType="numeric"
              inputLabel="drawer_currency_all"
              value={value}
              placeholder="drawer_default_amount"
              onChangeText={changeValue}
              style={styles.inputRow}
              returnKeyType="next"
              disabled={loading}
            />
            <TouchableInput
              style={styles.rightConfigInput}
              onPress={() => openReasonSelector()}
              label={'payment_self_charge_reason_placeholder'}
              value={translate(reason?.label)}
              icon={images.filledDownArrow}
              iconSize={16}
              autoCorrect={false}
              autoCapitalize="none"
              editable={false}
              disabled={loading || isPayment}
              inputContainerStyle={styles.reasonSelector}
            />
          </View>
          <InputRow
            label="drawer_description_label"
            inputType="default"
            value={description}
            placeholder="drawer_default_description"
            onChangeText={changeDescription}
            returnKeyType="done"
            maxLength={50}
            disabled={loading}
          />
          <View style={styles.inlineButtons}>
            <Button
              title={buttonProps.textPayIn[confirmPayIn]}
              style={[styles.button, styles.inlineButton]}
              variant={disabledPayIn || reason.type === 'payout' ? 'disabled' : confirmPayIn ? 'active' : 'preActive'}
              onPress={payIn}
              disabled={disabledPayIn}
            />
            <Button
              title={buttonProps.textPayOut[confirmPayOut]}
              style={[
                styles.button,
                styles.inlineButton,
                styles.inlineButtonRight,
              ]}
              variant={disabledPayOut || reason.type === 'payin' ? 'disabled' : confirmPayOut ? 'active' : 'preActive'}
              onPress={payOut}
              disabled={disabledPayOut}
            />
          </View>
        </View>}
        {renderTabs}
      </SafeAreaView>
      <BottomSheetModal
        isVisible={reasonModal.isVisible}
        onClose={() => setReasonModal(prev => ({ ...prev, isVisible: false }))}
      >
        <SimpleSelectType
          subtitle={reasonModal.subtitle}
          data={reasonModal.data}
          Item={reasonModal.Item}
          title={reasonModal.title}
          selected={reasonModal.selected}
          select={reasonModal.select}
          closeModal={reasonModal.onModalHide}
          textAlign="left"
          textSize="body"
        />
      </BottomSheetModal>
      <ErrorModal
        isVisible={errorModal?.isVisible}
        title={errorModal?.title}
        description={errorModal?.desc}
        placeHolders={errorModal.descPlaceholders}
        primaryText={errorModal?.primaryText}
        secondaryText={errorModal?.secondaryText}
        primaryAction={errorModal?.primaryAction}
        secondaryAction={errorModal?.secondaryAction}
        onBackdropPress={() => closeErrorModal()}
        onBackButtonPress={() => closeErrorModal()}
        onClosePress={() => closeErrorModal()}
      />
      <LoaderModal loading={loading} message="gov_processing_description" />
    </>
  )
}

PayInOut.propTypes = {
  drawerId: PropTypes.string,
  route: PropTypes.objectOf({
    params: PropTypes.objectOf({
      description: PropTypes.string,
    }),
  }),
  navigation: PropTypes.object,
}

export default PayInOut
