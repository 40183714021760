import { gql } from '@apollo/client'

export const LIST_TAXES = gql`
  query listTaxes($limit: Int $nextToken: String, $filter: Filter){
    listTaxes(listTaxesInput:{
      limit: $limit
      nextToken: $nextToken
      filter: $filter
    }){
      taxes{
        id
        name
        percentage
        isDefault
        status
      }
      nextToken
    }
  }
`

export const TOGGLE_TAX = gql`
mutation updateTax( $id: String!, $status: TaxStatus, $isDefault:Boolean,$verificationToken:String ) {
    updateTax(taxUpdateInput:{
      id: $id
      status: $status
      isDefault: $isDefault
      verificationToken:$verificationToken,
    }){
      id
      name
      percentage
      isDefault
      status
    }
  }
`
