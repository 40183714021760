import { gql } from '@apollo/client'

export const CREATE_ITEM = gql`
  mutation createItem(
      $barcode: String
      $abbreviation: String
      $categoryId: String
      $description: String
      $name: String!
      $productType: String
      $taxId: String!
      $taxInclusionType: TaxInclusionType!
      $measurementUnitId: String
      $sku: String
      $labelColor: String
      $itemVariations: [ItemVariationInput]
      $modifierListsToEnable: [String]
      $discountsToEnable: [String]
      $base64Image: String
      $locationId:String
      $taxExemptionType: TaxExemptionType
      $locId: String 
      $cashRegisterId: String
    ) {
      createItem(ItemInput:{
        barcode: $barcode
        abbreviation: $abbreviation
        categoryId: $categoryId
        description: $description
        name: $name
        productType: $productType
        taxId: $taxId
        taxInclusionType: $taxInclusionType
        measurementUnitId: $measurementUnitId
        sku: $sku
        labelColor: $labelColor
        itemVariations: $itemVariations
        modifierListsToEnable: $modifierListsToEnable
        discountsToEnable: $discountsToEnable
        base64Image: $base64Image
        taxExemptionType: $taxExemptionType
    }
    locationId:$locationId
    requestedLocationAndCashRegister: {
      locationId: $locId,
      cashRegisterId: $cashRegisterId
    }
    ){
      id
      name
      itemVariations {
        id
        name
      }
    }
  }
`


export const EDIT_ITEM = gql`
mutation updateItem(
    $id: String!
    $barcode: String
    $abbreviation: String
    $categoryId: String
    $description: String
    $name: String
    $productType: String
    $taxId: String
    $taxInclusionType: TaxInclusionType!
    $measurementUnitId: String
    $labelColor: String
    $sku: String
    $itemVariations: [ItemVariationUpdateInput]
    $modifierListsToEnable: [String]
    $modifierListsToDelete: [String]
    $discountsToEnable: [String]
    $discountsToDelete: [String]
    $base64Image: String
    $locationId:String
    $taxExemptionType: TaxExemptionType
    $locId: String, 
    $cashRegisterId: String
  ) {
    updateItem(itemUpdateInput:{
      id: $id
      barcode: $barcode
      abbreviation: $abbreviation
      categoryId: $categoryId
      description: $description
      name: $name
      productType: $productType
      taxId: $taxId
      taxInclusionType: $taxInclusionType
      measurementUnitId: $measurementUnitId
      sku: $sku
      labelColor: $labelColor
      itemVariations: $itemVariations
      modifierListsToEnable: $modifierListsToEnable
      modifierListsToDelete: $modifierListsToDelete
      discountsToEnable: $discountsToEnable
      discountsToDelete: $discountsToDelete
      base64Image: $base64Image
      taxExemptionType: $taxExemptionType
  }
  locationId:$locationId
  requestedLocationAndCashRegister: {
    locationId: $locId,
    cashRegisterId: $cashRegisterId
  }
  ){
    id
    name
  }
}
`

export const GET_ITEM = gql`
query getItem($id: String!,$locationId:String, $locId: String, $cashRegisterId: String) {
  getItem(getItemInput: {
    searchBy: {
      name: "id"
      value: $id
    },
    listItemVariationsInput: {
      limit: 10
    }
  }
  locationId:$locationId
  requestedLocationAndCashRegister: {
    locationId: $locId,
    cashRegisterId: $cashRegisterId
  }
  ){
    item {
      id
      barcode
      name
      description
      categoryId
      category {
        id
        name
        labelColor
      }
      measurementUnit {
        id
        precision
        abbreviation
        name
      }
      taxId
      tax{
        id
        name
        percentage
      }
      taxInclusionType
      measurementUnitId
      sku
      labelColor
      imageUrl
      taxExemptionType
    },
    listItemVariations{
      itemVariations{
        name
        id
        prices {
          amount
          currency
        }
      }
    }
    listModifierLists{
      modifierLists{
        id
        name
        modifiers{
          id,
          name,
          priceMoney{
            amount
            currency
          }
        }
      }
    }
    listDiscounts{
      discounts{
        id
        prices {
          amount
          currency
        }
        discountType
        name
        percentage
        discountType
      }
    }
  }
}
`


export const DETELE_VARIATION = gql`
  mutation deleteItemVariation($id: String!, $itemId: String!,$locationId:String, $locId: String, $cashRegisterId: String) {
    deleteItemVariation(
      id: $id, 
      itemId: $itemId,
      locationId:$locationId
      requestedLocationAndCashRegister: {
        locationId: $locId,
        cashRegisterId: $cashRegisterId
      }
      ){
      id
    }
  }
`
