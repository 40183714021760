import React, { useState, useEffect, useRef, memo, useCallback } from 'react'
import {
    SafeAreaView,
    View,
    TouchableOpacity,
    Animated,
    LayoutAnimation,
} from 'react-native'
import { useFocusEffect } from '@react-navigation/native'
import { useTheme } from 'react-native-themed-styles'
import PropTypes from 'prop-types'
import { useMutation, useQuery } from '@apollo/client'

import {
    Button,
    Icon,
    SwipeList,
    Header,
    Text,
    ListLoader,
    Loader,
    ModalContent,
    Modal,
    RadioInput,
} from '../../Components'

import images from '../../Themes/Images'
import { opacityConfig } from '../../Themes/Theme'
import { colors, animations } from '../../Themes'

import { LIST_BANK_ACCOUNTS, DELETE_BANK_ACCOUNT } from './BankAccounts.Schema'
import themedStyles, { itemStyles, hiddenItemStyles } from './BankAccounts.Styles'

const Item = memo(({
    id,
    bankName,
    iban,
    currency,
    onPress,
}) => {
    const [styles] = useTheme(itemStyles)
    const anim = useRef(new Animated.Value(1)).current
    const animate = value => {
        Animated.spring(anim, {
            toValue: value,
            useNativeDriver: true,
        }).start()
    }

    return (
        <Animated.View
            style={[
                styles.container,
                { transform: [{ scale: anim }] },
            ]}>
            <TouchableOpacity
                style={styles.touchable}
                onPress={onPress}
                onPressIn={() => animate(1.05)}
                onPressOut={() => animate(1)}
                activeOpacity={0.7}
                disabled={!onPress}
            >
                <View style={styles.infoContainer}>
                    <View style={styles.descContainer}>
                        <Text i18nKey={`${iban} (${currency})`} numberOfLines={1} />
                        <Text
                            i18nKey={bankName}
                            weight="light"
                            color={colors.secondaryText}
                            size="footnote"
                            numberOfLines={1}
                        />
                    </View>
                    <View style={styles.priceContainer} >
                        <Icon source={images.chevronRight} size={24} color={colors.accent} />
                    </View>
                </View>

            </TouchableOpacity>
        </Animated.View>
    )
})
Item.defaultProps = {
    labelColor: 'white',
}
Item.propTypes = {
    id: PropTypes.string,
    bankName: PropTypes.string,
    iban: PropTypes.string,
    currency: PropTypes.string,
    onPress: PropTypes.func,
}

function SelectableBankAccount({
    id,
    bankName,
    iban,
    currency,
    selected,
    onPress,
}) {
    const [styles] = useTheme(itemStyles)
    const anim = useRef(new Animated.Value(1)).current
    const animate = value => {
        Animated.spring(anim, {
            toValue: value,
            useNativeDriver: true,
        }).start()
    }

    return <Animated.View style={[
        styles.container,
        { transform: [{ scale: anim }] },
    ]}>
        <TouchableOpacity
            onPress={onPress}
            onPressIn={() => animate(1.05)}
            onPressOut={() => animate(1)}
            style={styles.touchable}
        >
            <View style={styles.infoContainer}>
                <View style={styles.descContainer}>
                    <Text i18nKey={`${iban} (${currency})`} numberOfLines={1} />
                    <Text
                        i18nKey={bankName}
                        weight="light"
                        color={colors.secondaryText}
                        size="footnote"
                        numberOfLines={1}
                    />
                </View>
                <View style={styles.priceContainer} >
                    <View pointerEvents="none" style={styles.radioInput}>
                        <RadioInput
                            index={id}
                            selected={selected}
                            onPress={onPress}
                            style={styles.selector}
                            color={colors.secondaryAccent}
                        />
                    </View>
                </View>
            </View>

        </TouchableOpacity>
    </Animated.View>
}
SelectableBankAccount.propTypes = {
    id: PropTypes.string,
    bankName: PropTypes.string,
    iban: PropTypes.string,
    selected: PropTypes.string,
    onPress: PropTypes.func,
    currency: PropTypes.string,
}

function HiddenItem({
    needsConfirmation,
    onPress,
    onConfirm,
    onCancel,
    loading,
}) {
    const [styles] = useTheme(hiddenItemStyles)

    return (
        <View
            style={styles.wrapper}>
            {loading ? (
                <View style={styles.loader}>
                    <Loader
                        size={32}
                        color={colors.white}
                        source={animations.vfLoaderWhite}
                    />
                </View>
            ) : needsConfirmation ? (
                <TouchableOpacity style={styles.option} onPress={onPress}>
                    <Icon source={images.delete} color={colors.white} size={24} />
                </TouchableOpacity>
            ) : (
                <View style={styles.optionFull}>
                    <TouchableOpacity style={styles.confirm} onPress={onConfirm}>
                        <Icon source={images.delete} color={colors.white} size={24} />
                        <Text
                            i18nKey="opened_orders_delete_confirm"
                            color={colors.white}
                            style={styles.confirmText}
                        />
                    </TouchableOpacity>
                    <TouchableOpacity style={styles.cancel} onPress={onCancel}>
                        <Icon source={images.closeIcon} color={colors.white} size={20} />
                    </TouchableOpacity>
                </View>
            )}
        </View>
    )
}
HiddenItem.propTypes = {
    needsConfirmation: PropTypes.bool,
    onPress: PropTypes.func,
    onConfirm: PropTypes.func,
    onCancel: PropTypes.func,
    loading: PropTypes.bool,
}

function BankAccounts({
    route: {
        params: {
            selectable,
            preselectedCustomer,
            updateSelection,
        },
    },
    navigation: { goBack, navigate } }) {
    const [styles] = useTheme(themedStyles)
    const [selectedBankAccount, setSelectedBankAccount] = useState({ ...preselectedCustomer })
    const [itemWidth, setItemWidth] = useState(0)
    const [needsConfirmation, setNeedForConfirmation] = useState(true)
    const [bankAccounts, setBankAccounts] = useState({
        bankAccounts: [],
        loading: true,
    })
    const [isErrorActive, setErrorActive] = useState(false)
    const [collapsed, setCollapsed] = useState(false)

    const [deleteBankAccount, { loading: deleting }] = useMutation(DELETE_BANK_ACCOUNT)

    const { data: listData, refetch, fetchMore } = useQuery(
        LIST_BANK_ACCOUNTS,
        {
            fetchPolicy: 'network-only',
            variables: {
                limit: 20,
            },
        }
    )

    useEffect(() => {
        setBankAccounts((prev) => ({
            ...prev,
            loading: true,
        }))
        const accountList = listData?.listBankAccounts?.bankAccounts
        if (accountList) {
            setBankAccounts({
                bankAccounts: [...accountList],
                loading: false,
            })
        }
    }, [listData])

    const onEndListReached = () => {
        if (listData?.listBankAccounts?.nextToken === null) {
            return
        }

        fetchMore({
            variables: {
                limit: 20,
                nextToken: listData?.listBankAccounts?.nextToken,
            },
        })
    }

    const openRow = (rowMap, id) => {
        setNeedForConfirmation(false)
        rowMap[id].manuallySwipeRow(-itemWidth)
    }

    const closeRow = (rowMap, id) => {
        rowMap[id].closeRow()
        setNeedForConfirmation(true)
    }

    const deleteRow = (rowMap, id) => {
        deleteBankAccount({
            variables: {
                id: id,
            },
        })
            .then(() => {
                // displayToast('item_delete_success')
                closeRow(rowMap, id)
                refetch()
            })
            .catch(() => {
                setErrorActive(true)
                closeRow(rowMap, id)
            })
    }

    const renderNonSelectableItem = ({ item, index }) => (
        <Item
            {...item}
            onPress={() => navigate('NewBankAccount', { title: item.iban, id: item.id, onSave: () => { } })}
        />
    )

    const selectAccount = (item) => {
        setSelectedBankAccount(item)
        updateSelection(item)
        goBack()
    }

    const renderSelectableItem = ({ item, index }) => {
        return (<SelectableBankAccount
            {...item}
            selected={selectedBankAccount?.id}
            onPress={() => selectAccount(item)}
        />)
    }

    const renderItem = selectable ? renderSelectableItem : renderNonSelectableItem

    const renderHiddenItem = ({ item, index }, rowMap) => (
        <HiddenItem
            needsConfirmation={needsConfirmation}
            onPress={() => openRow(rowMap, item.id)}
            onConfirm={() => deleteRow(rowMap, item.id)}
            onCancel={() => closeRow(rowMap, item.id)}
            loading={deleting}
        />
    )

    const refetchData = async () => {
        if (refetch) {
            await refetch()
        }
    }

    useFocusEffect(
        useCallback(() => {
            refetchData()
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [])
    )

    return (
        <>
            <SafeAreaView style={styles.container}>
                <Header
                    title="settings_bank_accounts_label"
                    image={images.chevronLeft}
                    onPress={() => goBack()}
                />
                <TouchableOpacity
                    onPress={() => {
                        LayoutAnimation.configureNext(opacityConfig)
                        setCollapsed(prev => !prev)
                    }}
                    style={styles.noteView}
                >
                    <View style={styles.noteHeader}>
                        <Icon source={images.info} size={20} />
                        <Text
                            i18nKey="cash_register_note"
                            weight="bold"
                            style={styles.noteTitle}
                        />
                    </View>
                    <Text i18nKey="bank_accounts_note_description" size="footnote" style={collapsed ? styles.hiddenText : styles.topSpacing} />
                </TouchableOpacity>
                <Button
                    icon={images.plus}
                    iconStyle={styles.plusIcon}
                    title="bank_accounts_add_button_label"
                    titleStyle={styles.buttonTitle}
                    style={styles.button}
                    onPress={() => navigate('NewBankAccount', {})}
                    variant={'active'}
                />
                <SwipeList
                    data={bankAccounts.bankAccounts}
                    renderItem={renderItem}
                    renderHiddenItem={renderHiddenItem}
                    rightOpenValue={-100}
                    onRowClose={() => setNeedForConfirmation(true)}
                    disableRightSwipe
                    setItemWidth={setItemWidth}
                    refreshing={false}
                    onRefresh={refetch}
                    onEndReached={onEndListReached}
                    ListFooterComponent={bankAccounts.loading && <ListLoader
                        loading={bankAccounts.loading}
                        isInitial={bankAccounts.bankAccounts.length === 0}
                    />}
                    ListEmptyComponent={bankAccounts.loading ? null : <View style={styles.emptyComponent}>
                        <Text i18nKey="bank_accounts_empty_list_title" />
                        <Text weight="light" i18nKey="bank_accounts_empty_list_subtitle" />
                    </View>}
                />
            </SafeAreaView>
            <Modal
                isVisible={isErrorActive}
                onBackButtonPress={() => setErrorActive(false)}
                onBackdropPress={() => setErrorActive(false)}
                animationIn="fadeIn"
                animationOut="fadeOut"
            >
                <ModalContent onClose={() => setErrorActive(false)}>
                    <Icon source={images.delete} style={styles.errorIcon} />
                    <Text i18nKey="orders_error_label" color={colors.accent} size="h5" align="center" weight="bold" style={styles.errorTitle} />
                    {/* <Text i18nKey={getErrorMessage(deleteError)} align="center" /> */}
                </ModalContent>
            </Modal>
        </>
    )
}

BankAccounts.propTypes = {
    navigation: PropTypes.object,
    route: PropTypes.object,
    selectable: PropTypes.bool,
    preselectedCustomer: PropTypes.object,
    updateSelection: PropTypes.func,
}

export default BankAccounts
