import { gql } from '@apollo/client'

export const GET_MODIFIERS = gql`
  query listModifierLists($limit: Int, $nextToken: String, $filter: Filter,$locationId:String, $locId: String, $cashRegisterId: String) {
    listModifierLists(listModifierListsInput: {
      limit: $limit,
      nextToken: $nextToken,
      filter: $filter,
    }
    locationId:$locationId
    requestedLocationAndCashRegister: {
      locationId: $locId,
      cashRegisterId: $cashRegisterId
    }
    ) {
      modifierLists {
        id
        name
      },
      nextToken
    },
  }
`


export const DELETE_MODIFIER = gql`
  mutation deleteModifierList ($id: String!,$locationId:String, $locId: String, $cashRegisterId: String) {
    deleteModifierList(
      id: $id
      locationId:$locationId
      requestedLocationAndCashRegister: {
        locationId: $locId,
        cashRegisterId: $cashRegisterId
      }
      ){
      id
      name
    }
  }
`

export const DELETE_MODIFIER_ITEM = gql`
  mutation deleteModifier($id: String!, $modifierListId: String!,$locationId:String, $locId: String, $cashRegisterId: String){
    deleteModifier(
      id: $id
      modifierListId: $modifierListId
      locationId:$locationId
      requestedLocationAndCashRegister: {
        locationId: $locId,
        cashRegisterId: $cashRegisterId
      }
    ){
      id
    }
  }
`
