/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useContext, useCallback, useEffect } from 'react'
import {
  View,
  SafeAreaView,
  TouchableOpacity,
  FlatList,
  RefreshControl,
  Dimensions,
} from 'react-native'
import PropTypes from 'prop-types'
import { useFocusEffect } from '@react-navigation/native'
import { useTheme } from 'react-native-themed-styles'
import { useLazyQuery } from '@apollo/client'
import ContentLoader, { Rect } from 'react-content-loader/native'
import moment from 'moment'
import 'moment/locale/sq'
import 'moment/locale/en-gb'
import { useSelector } from 'react-redux'

import { Text, Button, CheckBox, Header } from '../../Components'
import { usePermissions } from '../../Utils/Permissions'
import { useUserDetails } from '../../Utils/AuthDetails'
import { getCurrency } from '../Checkout/Checkout.Selectors'

import { useConfig } from '../../Utils'

import LIST_ALL_DISCOUNTS from './SelectDiscount.Schema'

import Theme from '../../Themes/Theme'
import images from '../../Themes/Images'
import themedStyles, { itemStyles } from './SelectDiscount.Styles'

const { width } = Dimensions.get('screen')

const LANGUAGES = {
  al: 'sq',
  en: 'en-gb',
}
const discountTypes = {
  FIXED_PERCENTAGE: 'FIXED_PERCENTAGE',
  FIXED_AMOUNT: 'FIXED_AMOUNT',
  VARIABLE_PERCENTAGE: 'VARIABLE_PERCENTAGE',
  VARIABLE_AMOUNT: 'VARIABLE_AMOUNT',
}

function Discount({ name, percentage, discountType, appliedMoney = {}, checked, onPress, isFirst, isLast }) {
  const [styles] = useTheme(itemStyles)
  const { colors } = useContext(Theme)

  return (
    <TouchableOpacity style={[styles.itemContainer, styles['fistItem_' + isFirst], styles['lastItem_' + isLast]]} onPress={onPress} disabled={!onPress}>
      <View style={styles.itemTitle}>
        <Text i18nKey={name} numberOfLines={1} />
        {discountType?.match(/PERCENTAGE/)
          ? percentage && <Text i18nKey={percentage + '%'} color={colors.secondaryText} numberOfLines={1} />
          : appliedMoney?.amount
            ? <Text i18nKey="checkout_price_currency_value" placeHolders={[appliedMoney?.amount, appliedMoney?.currency]} color={colors.secondaryText} numberOfLines={1} />
            : null
        }
      </View>
      <View pointerEvents="none">
        <CheckBox
          tintColors={styles.checkBoxTintColors}
          checked={checked}
          onValueChange={onPress}
          style={styles.checkBox}
          color={colors.secondaryAccent}
        />
      </View>
    </TouchableOpacity>
  )
}
Discount.propTypes = {
  name: PropTypes.string,
  percentage: PropTypes.string,
  discountType: PropTypes.oneOf(Object.values(discountTypes)),
  appliedMoney: PropTypes.shape({
    amount: PropTypes.number,
    currency: PropTypes.string,
  }),
  checked: PropTypes.bool,
  onPress: PropTypes.func,
  isFirst: PropTypes.bool,
  isLast: PropTypes.bool,
}

function DiscountPlaceholder({ isFirst, isLast }) {
  const [styles] = useTheme(itemStyles)

  return (
    <TouchableOpacity style={[styles.itemContainer, styles['fistItem_' + isFirst], styles['lastItem_' + isLast]]} disabled>
      <ContentLoader
        speed={2}
        width={width - 30}
        height={60}
        backgroundColor="#f3f3f3"
        foregroundColor="#fff"
      >
        <Rect x="15" y="10" rx="6" ry="6" width={`${(width - 30) * (3 / 5)}`} height="18" />
        <Rect x="15" y="32" rx="6" ry="6" width="60" height="18" />
        <Rect x={`${width - 63}`} y="21" rx="3" ry="3" width="18" height="18" />
      </ContentLoader>
    </TouchableOpacity>
  )
}
DiscountPlaceholder.propTypes = {
  isFirst: PropTypes.bool,
  isLast: PropTypes.bool,
}

function SelectDiscount({
  navigation: { navigate, goBack },
  route: { params: { onSave = () => { }, preSelectedDiscounts = [] } = {} },
}) {

  const { switchLocation: { deviceId, locationId } } = useConfig()

  const [styles] = useTheme(themedStyles)
  const { colors } = useContext(Theme)
  const { role } = useUserDetails()
  const { checkout = {} } = usePermissions()
  const currency = useSelector(getCurrency)

  moment.locale(LANGUAGES.al)

  const [listDiscounts, { loading, data, refetch, fetchMore }] = useLazyQuery(LIST_ALL_DISCOUNTS, {
    fetchPolicy: 'network-only',
    variables: {
      locId: locationId,
      cashRegisterId: deviceId,
      limit: 30,
    },
  })

  useEffect(() => {
    preSelectedDiscounts.map(item => changeState(item.id, item))
  }, [])

  useFocusEffect(useCallback(() => {
    listDiscounts()
    return
  }, []))

  const [selectedDiscounts, setSelectedDiscounts] = useState({})

  const changeState = (id, discount) => {
    const type = discount?.discountType || ''
    if (type.startsWith('VARIABLE') && !(discount?.appliedMoney?.amount || discount?.percentage)) {
      if (!selectedDiscounts[id]) {
        navigate('SetDiscountValue', {
          onSave: res => {
            goBack()
            if (type === discountTypes.VARIABLE_AMOUNT) {
              discount.appliedMoney = res
            } else {
              discount.percentage = res
            }
            setSelectedDiscounts(prev => {
              prev[id] = discount
              return { ...prev }
            })
          }, name: discount.name, type,
        })
      } else {
        setSelectedDiscounts(prev => {
          delete prev[id]
          return { ...prev }
        })
      }
    } else {
      setSelectedDiscounts(prev => {
        if (prev[id]) {
          delete prev[id]
        } else {
          prev[id] = discount
        }
        return { ...prev }
      })
    }
  }

  function filterPrice(prices) {
    return prices?.find(pr => pr.currency === currency.currencyCode)
  }

  const mapData = (dataa) => {
    const response = []
    dataa?.map((item, index) => {
      let amount = null
      if (item?.prices?.length) {
        amount = filterPrice(item.prices)
      }
      if (!item?.discountType?.match(/AMOUNT/) || amount) {
        response.push({
          ...item,
          appliedMoney: amount,
        })
      }
    })
    return response
  }

  const discounts = mapData(data?.listDiscounts?.discounts) || []

  const onEndReached = () => {
    if (!data?.listDiscounts?.nextToken) {
      return
    }
    fetchMore({
      variables: {
        locId: locationId,
        cashRegisterId: deviceId,
        limit: 30,
        nextToken: data.listDiscounts.nextToken,
      },
    })
  }

  return (
    <SafeAreaView style={styles.container}>
      <Header
        title="discount_select_label"
        image={images.back}
        onPress={goBack}
      />

      {(checkout.createNewDiscount || []).includes(role) && <Button
        backgroundColor={colors.tertiaryBackground}
        title="discount_button_create_new"
        color={colors.secondaryAccent}
        borderColor={colors.transparent}
        style={styles.chargeButton}
        onPress={() => navigate('NewDiscount', {})}
      />}

      <FlatList
        showsVerticalScrollIndicator={false}
        keyExtractor={(item) => item.id}
        getItemLayout={(_, index) => ({
          length: 60,
          offset: 60 * index,
          index,
        })}
        data={discounts}
        style={styles.listStyle}
        contentContainerStyle={styles.listContainerStyle}
        renderItem={({ item, index }) => {
          let isFirst = false, isLast = false
          if (index === 0) {
            isFirst = true
          }
          if (index === discounts.length - 1) {
            isLast = true
          }

          const discount = {
            id: item.id,
            name: item.name,
            discountType: item.discountType,
            percentage: item.percentage,
            appliedMoney: item.appliedMoney,
          }

          return <Discount
            key={item.id}
            id={item.id}
            name={item.name}
            percentage={item.percentage || selectedDiscounts[item.id]?.percentage}
            discountType={item.discountType}
            appliedMoney={item.appliedMoney || selectedDiscounts[item.id]?.appliedMoney}
            checked={Boolean(selectedDiscounts[item.id])}
            onPress={() => changeState(item.id, discount)}
            isFirst={isFirst}
            isLast={isLast}
          />
        }}
        ItemSeparatorComponent={() => <View style={styles.separator} />}
        refreshControl={
          <RefreshControl
            refreshing={false}
            onRefresh={refetch}
          />
        }
        onEndReached={onEndReached}
        ListFooterComponent={loading && <><DiscountPlaceholder isFirst /><DiscountPlaceholder isLast /></>}
      />

      <Button
        backgroundColor={colors.accent}
        title="discount_button_apply"
        color={colors.white}
        borderColor={colors.transparent}
        style={styles.chargeButton1}
        onPress={() => onSave(Object.values(selectedDiscounts))}
      />
    </SafeAreaView>
  )
}

SelectDiscount.propTypes = {
  navigation: PropTypes.object,
  route: PropTypes.object,
}

export default SelectDiscount
