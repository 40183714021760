import React, { useState } from 'react'
import { LayoutAnimation, View, TouchableOpacity } from 'react-native'
import PropTypes from 'prop-types'
import { useTheme } from 'react-native-themed-styles'
import { useDispatch, useSelector } from 'react-redux'

import { Text, Switch, InputWithTags, Type, TouchableInput, BottomSheetSelector } from '../../../../../Components'
import { STATE_LIST, CITIES } from '../../../../NewCustomer'
import images from '../../../../../Themes/Images'

import { layoutAnimConfig } from '../../../../../Themes/Theme'

import { getPaymentCustomer, getPaymentDelivery, getShouldHaveDelivery } from '../../../Payment.Selectors'
import { updatePaymentAttribute } from '../../../Payment.Actions'

import themedStyles, { itemStyles } from './Delivery.Styles'

function CityType({ item, selected, onPress }) {

    const [itemStyle] = useTheme(itemStyles)
    const isSelected = selected === item

    return (
        <View style={itemStyle.container}>
            <TouchableOpacity
                onPress={onPress}
                style={itemStyle['itemSelected_' + isSelected]}
                activeOpacity={0.6}
            >
                <Text
                    i18nKey={item}
                    size="footnote"
                    style={itemStyle['itemTextSelected_' + isSelected]}
                />
            </TouchableOpacity>
        </View>
    )
}
CityType.propTypes = {
    item: PropTypes.string,
    selected: PropTypes.object,
    onPress: PropTypes.func,
}

function Delivery({ }) {
    const [styles] = useTheme(themedStyles)
    const [searchValue, setSearchValue] = useState('')

    const dispatchAction = useDispatch()

    const delivery = useSelector(getPaymentDelivery)
    const customer = useSelector(getPaymentCustomer)
    const shouldHaveDelivery = useSelector(getShouldHaveDelivery)

    const setDelivery = (value) => dispatchAction(updatePaymentAttribute('delivery', value))
    const setShouldHaveDelivery = (value) => dispatchAction(updatePaymentAttribute('shouldHaveDelivery', value))

    const updateDelivery = (key, value) => {
        const newDelivery = delivery
        newDelivery[key] = value
        setDelivery(newDelivery)
    }

    const [modal, setModal] = useState({
        isVisible: false,
        data: [],
        title: '',
        placeholder: '',
        selected: undefined,
        select: () => { },
        Item: Type,
    })

    const closeModal = () => {
        setModal(prev => ({ ...prev, isVisible: false }))
        setSearchValue('')
    }

    const setReceiver = (receiver) => updateDelivery('receiver', receiver)

    const setStreet = (street) => updateDelivery('street', street)

    const setCity = (city) => updateDelivery('city', city)

    const setCountry = (country) => {
        updateDelivery('country', country)
        setCity('')
    }

    const openStateSelector = () => {
        setModal({
            isVisible: true,
            data: STATE_LIST,
            title: 'customers_state_label',
            placeholder: 'customers_state_placeholder',
            selected: delivery.country,
            select: setCountry,
            searchKey: 'description',
            Item: Type,
        })
    }

    const openCitySelector = () => {
        setModal({
            isVisible: true,
            data: CITIES,
            title: 'einvoice_details_city_label',
            placeholder: 'supporting_invoice_city_search',
            selected: delivery.city,
            select: setCity,
            searchKey: 'self',
            Item: CityType,
        })
    }

    return <>
        <View style={styles.containerStyle}>
            {customer?.id && <View style={styles.header}>
                <Text i18nKey="payment_delivery_information_section" weight="bold" />
                <Switch
                    value={shouldHaveDelivery}
                    onPress={() => {
                        LayoutAnimation.configureNext(layoutAnimConfig)
                        setShouldHaveDelivery(!shouldHaveDelivery)
                        setDelivery({})
                    }}
                />
            </View>}
            {(customer?.id && shouldHaveDelivery) && <View>
                <InputWithTags
                    label="payment_delivery_receiver_label"
                    value={delivery?.receiver}
                    onChangeText={setReceiver}
                    autoCorrect={false}
                    inputContainerStyle={styles.inputContainer}
                    inputWrapperStyle={styles.itemSpacing}
                    labelOffset={{
                        x0: 0,
                        y0: -7,
                        x1: 0,
                        y1: 2,
                    }}
                />
                <InputWithTags
                    label="einvoice_delivery_street"
                    value={delivery?.street}
                    onChangeText={setStreet}
                    autoCorrect={false}
                    inputContainerStyle={styles.inputContainer}
                    inputWrapperStyle={styles.itemSpacing}
                    labelOffset={{
                        x0: 0,
                        y0: -7,
                        x1: 0,
                        y1: 2,
                    }}
                />
                <TouchableInput
                    onPress={() => openStateSelector()}
                    label="customers_state_label"
                    value={delivery?.country?.description || ''}
                    icon={images.filledDownArrow}
                    iconSize={16}
                    autoCorrect={false}
                    autoCapitalize="none"
                    editable={false}
                    style={styles.stateSelector}
                />
                {delivery?.country?.code === 'ALB' ? <TouchableInput
                    onPress={() => openCitySelector()}
                    label="einvoice_details_city_label"
                    value={delivery?.city || ''}
                    icon={images.filledDownArrow}
                    iconSize={16}
                    autoCorrect={false}
                    autoCapitalize="none"
                    editable={false}
                    style={styles.citySelector}
                /> : <InputWithTags
                    label="einvoice_details_city_label"
                    value={delivery?.city?.description}
                    onChangeText={setCity}
                    disabled={!delivery?.country?.code}
                    autoCorrect={false}
                    inputContainerStyle={styles.inputContainer}
                    inputWrapperStyle={styles.itemSpacing}
                    labelOffset={{
                        x0: 0,
                        y0: -7,
                        x1: 0,
                        y1: 2,
                    }}
                />}
            </View>}
        </View>
        <BottomSheetSelector
            {...modal}
            closeModal={closeModal}
            searchValue={searchValue}
            setSearchValue={setSearchValue}
        />
    </>
}
Delivery.defaultProps = {
    currencies: [],
    setCurrency: () => { },
}
Delivery.propTypes = {
    delivery: PropTypes.object,
    setDelivery: PropTypes.func,
    customer: PropTypes.object,
    shouldHaveDelivery: PropTypes.bool,
    setShouldHaveDelivery: PropTypes.func,
}

export default Delivery
