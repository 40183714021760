import React from 'react'
import { View } from 'react-native'
import { useTheme } from 'react-native-themed-styles'
import PropTypes from 'prop-types'

import Loader from '../Loader'

import themedStyles from './ListLoader.Styles'

const SIZES = {
  true: {
    view: 'full',
    size: 124,
  },
  false: {
    view: undefined,
    size: 32,
  },
}

function ListLoader({ loading, isInitial, style, initialStyle, size }) {
  const [styles] = useTheme(themedStyles)

  const props = SIZES[isInitial]

  return (
    <View style={[styles['containerFull_' + isInitial], isInitial ? initialStyle : style]} >
      {loading && <Loader size={size} {...props} />}
    </View>
  )
}
ListLoader.defaultProps = {
  loading: false,
  isInitial: false,
}
ListLoader.propTypes = {
  loading: PropTypes.bool,
  isInitial: PropTypes.bool,
  style: PropTypes.object,
  initialStyle: PropTypes.object,
  size: PropTypes.number,
}

export default ListLoader
