import React, { useEffect, useState } from 'react'
import { View } from 'react-native'
import { useTheme } from 'react-native-themed-styles'
import PropTypes from 'prop-types'

import Loader from '../Loader'
import Text from '../Text'

import themedStyles from './LoaderModal.Styles'
import { animations } from '../../Themes'

function LoaderModal({ loading, message, containerStyle, ...rest }) {
  const [styles] = useTheme(themedStyles)

  const [animating, setAnimating] = useState(false)


  useEffect(() => {
    if (loading) {
      setAnimating(true)
    } else {
      setAnimating(false)
    }
  }, [loading])

  if (animating) {
    return (
      <View
        style={[styles.loadingContainer, containerStyle]}>
        <Loader source={animations.vfLoaderWhite} size={124} {...rest} />
        {Boolean(message) && <Text i18nKey={message} style={styles.message} />}
      </View>
    )
  }
  return null
}

LoaderModal.propTypes = {
  loading: PropTypes.bool,
  message: PropTypes.string,
  containerStyle: PropTypes.object,
}

export default LoaderModal
