import { styleSheetFactory } from '../../Themes/Colors'

const styles = styleSheetFactory(colors => ({
    container: {
        flex: 1,
        position: 'relative',
    },
    listInfoContainer: {
        flexGrow: 1,
        padding: 15,
        justifyContent: 'space-between',
        alignItems: 'center',
        flexDirection: 'row',
    },
    listFirst: {
        borderTopLeftRadius: 7,
        borderTopRightRadius: 7,
    },
    listLast: {
        borderBottomLeftRadius: 7,
        borderBottomRightRadius: 7,
    },
    listStyle: {
        flexShrink: 1,
        paddingHorizontal: 15,
    },
    listDescContainer: {
        flexGrow: 1,
        flexShrink: 1,
        paddingRight: 5,
    },
    listPriceContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        flexShrink: 0,
    },
    separator: {
        height: 1,
        backgroundColor: colors.separator,
    },
    header: {
        height: 56,
        alignItems: 'center',
        flexDirection: 'row',
        backgroundColor: colors.background,
    },
    headerIcon: {
        marginHorizontal: 20,
    },
    listContainerStyle: {
        borderRadius: 10,
        paddingBottom: 30,
    },
    fab: {
        position: 'absolute',
        bottom: 30,
        right: 15,
        height: 50,
        width: 50,
        borderRadius: 25,
        backgroundColor: colors.accent,
        justifyContent: 'center',
        alignItems: 'center',
        shadowColor: colors.black,
        shadowOffset: {
            width: 0,
            height: 1,
        },
        shadowOpacity: 0.22,
        shadowRadius: 2.22,
        elevation: 3,
    },
    fabIcon: {
        transform: [{ rotate: '-90deg' }],
    },
}))

export default styles
