import { styleSheetFactory } from '../../Themes/Colors'


const styles = styleSheetFactory(colors => ({
  container: {
    flex: 1,
  },
  registerButton: {
    marginHorizontal: 15,
    marginVertical: 20,
  },
  listStyles: {
    flex: 1,
    marginTop: 20,
    paddingBottom: 30,
  },
  emptyComponent: {
    marginBottom: 10,
  },
  headerComponent: {
    marginBottom: 10,
  },
  horizontalSpacing: {
    marginHorizontal: 15,
  },
}))

export default styles
