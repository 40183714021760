import { Dimensions, Platform } from 'react-native'

import { styleSheetFactory } from '../../Themes/Colors'

const { width } = Dimensions.get('screen')

const styles = styleSheetFactory((colors) => ({
  container: {
    flex: 1,
    position: 'relative',
    backgroundColor: colors.background,
  },
  contentContainerStyle: {
    paddingBottom: 30,
    backgroundColor: colors.background,
  },
  searchContainer: {
    flexDirection: 'row',
    position: 'absolute',
    top: 50,
    left: 0,
    right: 0,
    margin: 15,
    marginBottom: 0,
  },
  cameraContainer: {
    marginHorizontal: 15,
  },
  listContainer: {
    marginTop: 0,
  },
  cameraStyle: {
    width: width - 30,
    height: 150,
    overflow: 'hidden',
  },
  listRightComponent: {
    flexDirection: 'row',
    alignItems: 'center',
    marginRight: 10,
  },
  listRightComponentText: {
    alignItems: 'flex-end',
    marginRight: 10,
  },
  itemRightLabel: {},
  retryView: {
    flex: 1,
    opacity: 0.7,
    backgroundColor: colors.qrMask,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
  },
  retryText: {
    color: colors.invertedText,
    marginLeft: 5,
  },
  retryIcon: {
    marginRight: 5,
    tintColor: colors.invertedText,
  },
  itemNotFound: {
    backgroundColor: 'white',
    alignItems: 'center',
    justifyContent: 'center',
    paddingVertical: 10,
    borderRadius: 7,
    marginTop: 5,
  },
  default: {
    flex: 1,
    backgroundColor: colors.tertiaryBackground,
    overflow: 'hidden',
  },
  list: {
    flexDirection: 'row',
    alignItems: 'center',
    flexGrow: 1,
  },
  listStyle: {
    flex: 1,
    marginHorizontal: 15,
  },
  rightComponent: {
    flexDirection: 'row',
    marginRight: 15,
  },
  separatorStyle: {
    height: 1,
    flex: 1,
    backgroundColor: colors.lightGrey,
  },
  fab: {
    position: 'absolute',
    bottom: 30,
    right: 15,
    height: 50,
    width: 50,
    borderRadius: 25,
    backgroundColor: colors.tertiaryAccent,
    justifyContent: 'center',
    alignItems: 'center',
    shadowColor: colors.black,
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.22,
    shadowRadius: 2.22,
    elevation: 3,
  },
  fabIcon: {
    transform: [{ rotate: '-90deg' }],
  },
  modalContent: {
    height: '100%',
    backgroundColor: colors.background,
    justifyContent: 'center',
  },
  lottieView: {
    width: 300,
    height: 300,
  },
  footer: {
    height: 48,
    justifyContent: 'center',
    alignItems: 'center',
  },
  qrCameraContainer: (isVisible = true) => {
    return {
      marginTop: 20,
      paddingTop: 50,
      marginBottom: isVisible ? 10 : 0,
    }
  },
  sectionTitle: { marginBottom: 10, marginTop: 15 },
  leftSpacing: { marginLeft: 10 },
  mediumLeftSpacing: { marginLeft: 12 },
  bottomSpacing: { marginBottom: 3 },
  lottieWrapper: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  hidden: {
    overflow: 'hidden',
    height: 0,
  },
  successWrapper: {
    height: '100%',
  },
  lottie: {
    width: 150,
  },
  backButton: {
    height: 50,
    marginBottom: 30,
    marginHorizontal: 20,
  },
  modal: { margin: 0 },
}))

const itemStyles = styleSheetFactory((colors) => ({
  itemStyle: {
    backgroundColor: colors.tertiaryBackground,
    height: 70,
    justifyContent: 'center',
    borderTopWidth: 1,
    borderTopColor: colors.separator,
  },
  firstItem_true: {
    borderTopLeftRadius: 7,
    borderTopRightRadius: 7,
    borderTopWidth: 0,
  },
  lastItem_true: {
    borderBottomLeftRadius: 7,
    borderBottomRightRadius: 7,
  },
  listDescContainer: {
    flexGrow: 1,
    flexShrink: 1,
    marginLeft: 10,
    justifyContent: 'center',
  },
  amountContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    padding: 15,
    flexDirection: 'row',
  },
  container: { flexDirection: 'row', alignItems: 'center' },
  subDescription: { fontSize: Platform.OS === 'web' ? 14 : 12, lineHeight: Platform.OS === 'web' ? 16 : 14 },
  description: (type) => {
    return {
      fontSize: type ? 12 : 14,
      lineHeight: type ? 14 : 16,
      marginRight: 4,
    }
  },
  errorContainer: (search = true) => {
    return {
      backgroundColor: search ? colors.tertiaryBackground : colors.background,
      marginTop: 15,
      borderRadius: 7,
    }
  },
  errorSubcontainer: {
    padding: 15,
    justifyContent: 'center',
    alignItems: 'center',
  },
  rowView: { flexDirection: 'row', alignItems: 'center' },
  rightSpacing: { marginRight: 5 },
  horizontalSpacing: { marginHorizontal: 30 },
}))

const hiddenItemStyles = styleSheetFactory((colors) => ({
  firstHiddenItem_true: {
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    borderTopWidth: 0,
  },
  lastHiddenItem_true: {
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
  },
  hiddenItemStyle: {
    height: 70,
    backgroundColor: colors.secondaryAccent,
    flex: 1,
    justifyContent: 'flex-end',
    // alignItems: 'flex-end',
    flexDirection: 'row',
  },
  hiddenSubcontainer: {
    width: 80,
    height: 70,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    // backgroundColor: 'pink'
  },
  divider: {
    height: 50,
    alignSelf: 'center',
    width: 1,
    backgroundColor: 'rgba(255, 255, 255, 0.4)',
    // position: 'absolute',
  },
}))

export { itemStyles, hiddenItemStyles }

export default styles
