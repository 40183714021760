import { styleSheetFactory } from '../../Themes/Colors'

const styles = styleSheetFactory(colors => ({
  container: {
    //flex: 1,
    backgroundColor: colors.tertiaryBackground,
    overflow: 'hidden',
  },
  subContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    minHeight: 60,
    padding: 15,
  },
  textContainer: {
    flexGrow: 1,
    flexShrink: 0,
    flexDirection: 'column',
    justifyContent: 'center',
  },
  rightTextContainer: {
    flexDirection: 'column',
    alignItems: 'flex-end',
    marginLeft: 7.5,
    flexShrink: 1,
  },
  rightText: {
    textAlign: 'right',
  },
  desc: {
    color: colors.placeholder,
  },
}))

export default styles
