import React from 'react'
import { ScrollView } from 'react-native'
import { useTheme } from 'react-native-themed-styles'
import { useSelector } from 'react-redux'
import moment from 'moment'

import { getProcessData, getEinvoiceCalculated } from '../../../../Payment.Selectors'

import { INF } from '../../../../../../Utils'

import { Section, Text } from '../../../../../../Components'

import themedStyles from './ProcessDoc.Styles'

const dateFormat = 'DD/MM/YYYY'

function ProcessDoc() {
  const [styles] = useTheme(themedStyles)
  const data = useSelector(getProcessData)
  const {
    taxTotal,
    legalMonetaryTotal,
    allowances,
  } = useSelector(getEinvoiceCalculated)

  const mapAllowances = (unmappedData) => {
    const mappedData = []
    unmappedData?.map((item) => {
      const itemAmount = item?.amount
      const taxCategory = item?.taxCategory
      mappedData.push({
        title: item?.chargeReason,
      }, {
        label: `${taxCategory?.id}-${taxCategory?.taxSchema}`,
        value: `${taxCategory?.percent} %`,
      }, {
        label: 'einvoice_discount_amount_receipt_label',
        value: `${INF.format(itemAmount?.amount)} ${itemAmount?.currency}`,
      }, {
        label: 'einvoice_discount_base_receipt_label',
        value: item?.baseAmount,
      }, {
        label: 'einvoice_discount_percentage_receipt_label',
        value: item?.percent ? `${item?.percent} %` : '',
      })
    })
    return mappedData
  }

  const allowanceItems = mapAllowances(allowances)

  const mapTaxes = (unmappedData) => {
    const mappedData = []
    unmappedData?.taxSubtotal.map((item) => {
      const taxCategory = item?.taxCategory
      const taxableAmount = item?.taxableAmount
      const taxAmount = item?.taxAmount
      mappedData.push({
        title: `${taxCategory?.id}-${taxCategory?.taxSchema} ${taxCategory?.percent}%`,
      }, {
        label: 'taxable_amount_label',
        value: `${INF.format(taxableAmount?.amount)} ${taxableAmount?.currency}`,
      }, {
        label: 'tax_sum_label',
        value: `${INF.format(taxAmount?.amount)} ${taxAmount?.currency}`,
      })
    })
    return mappedData
  }


  const sectionData = [
    {
      title: 'einvoice_process_preview_section',
    },
    {
      label: 'einvoice_section_process',
      value: data.invoiceProcess?.description,
    },
    {
      label: 'einvoice_doc_type_preview',
      value: data.invoiceDocument?.description,
    },
    {
      label: 'einvoice_currency_label',
      value: data.currency,
    },
    {
      label: 'einvoice_payment_deadline_label',
      value: moment(data.dueDate).format(dateFormat),
    },
    {
      label: 'einvoice_start_date_label',
      value: data.startDate ? moment(data.startDate).format(dateFormat) : '-',
    },
    {
      label: 'einvoice_end_date_label',
      value: data.endDate ? moment(data.endDate).format(dateFormat) : '-',
    },
    {
      label: 'einvoice_effective_vat_label',
      value: data.effectiveVat?.code || '-',
    },
  ]

  const totalData = [
    {
      title: 'receipt_total',
    },
    {
      label: 'einvoice_preview_total_amount',
      value: `${INF.format(legalMonetaryTotal?.lineExtension?.amount)} ${legalMonetaryTotal?.lineExtension?.currency}`,
    },
    {
      label: 'receipt_total_discount',
      value: `${INF.format(legalMonetaryTotal?.allowance?.amount)} ${legalMonetaryTotal?.allowance?.currency}`,
    },
    {
      label: 'einvoice_preview_total_withoutvat',
      value: `${INF.format(legalMonetaryTotal?.taxExclusive?.amount)} ${legalMonetaryTotal?.taxExclusive?.currency}`,
    },
    {
      label: 'einvoice_preview_total_vat',
      value: `${INF.format(taxTotal?.taxAmount?.amount)} ${taxTotal?.taxAmount?.currency}`,
    },
    {
      label: 'einvoice_preview_total_withvat',
      value: `${INF.format(legalMonetaryTotal?.taxInclusive?.amount)} ${legalMonetaryTotal?.taxInclusive?.currency}`,
    },
    {
      label: 'einvoice_preview_total_paid',
      value: `${INF.format(legalMonetaryTotal?.payable?.amount)} ${legalMonetaryTotal?.payable?.currency}`,
    },
  ]

  return (
    <ScrollView
      style={styles.container}
      contentContainerStyle={styles.content}
      showsVerticalScrollIndicator={false}
    >
      <Section data={sectionData} />
      {allowanceItems.length !== 0 ? <Text i18nKey="einvoice_discount_receipt_header" weight="bold" style={styles.sectionStyle} /> : null}
      <Section data={allowanceItems} />
      <Text i18nKey="einvoice_addition_receipt_header" weight="bold" style={styles.sectionStyle} />
      <Section data={mapTaxes(taxTotal)} />
      <Section data={totalData} />
    </ScrollView>
  )
}

export default ProcessDoc
