import React, { useRef } from 'react'
import { TouchableOpacity, Animated } from 'react-native'
import PropTypes from 'prop-types'
import { useTheme } from 'react-native-themed-styles'

import Icon from '../Icon'
import Text from '../Text'
import Loader from '../Loader'

import themedStyles from './button.styles'
import colors from '../../Themes/Colors'
import { animations } from '../../Themes/Images'

const AnimatedTouchable = Animated.createAnimatedComponent(TouchableOpacity)

function Button(props) {
  const [styles] = useTheme(themedStyles)
  const {
    icon,
    iconStyle,
    title,
    titleStyle,
    titleProps,
    borderWidth,
    borderColor,
    backgroundColor,
    color,
    disabled,
    variant,
    isActive,
    iconColor,
    loader,
    loaderComponent,
    placeHolders,
    rightComponent,
  } = props
  const anim = useRef(new Animated.Value(1)).current
  const animate = value => {
    Animated.spring(anim, {
      toValue: value,
      useNativeDriver: true,
    }).start()
  }

  const propsStyle = {
    borderColor: borderColor || styles[variant].borderColor,
    borderWidth: borderWidth || styles[variant].borderWidth,
    backgroundColor: backgroundColor || styles[variant].backgroundColor,
  }

  const textStyle = {
    color: color || styles[variant].color,
  }

  let isDisabled = disabled
  if (variant === 'disabled') {
    isDisabled = true
  }

  return (
    <AnimatedTouchable
      onPress={props.onPress}
      disabled={isDisabled}
      onPressIn={() => animate(1.05)}
      onPressOut={() => animate(1)}
      activeOpacity={0.7}
      style={[
        styles.ButtonRoot,
        props.style,
        styles[variant],
        isActive && styles.active,
        propsStyle,
        {
          transform: [{ scale: anim }],
        },
      ]}>
      {loader ? null : icon && <Icon source={icon} size={20} style={{ ...styles.icon, ...iconStyle }} color={iconColor} />}
      {loader ? loaderComponent : <Text style={[textStyle, titleStyle]} i18nKey={title} placeHolders={placeHolders} {...titleProps} />}
      {rightComponent}
    </AnimatedTouchable>
  )
}

Button.defaultProps = {
  iconColor: colors.tertiaryIcon,
  titleProps: {},
  disabled: false,
  variant: 'default',
  loaderComponent: <Loader size={32} color={colors.white} source={animations.vfLoaderWhite} />,
}

Button.propTypes = {
  icon: PropTypes.any,
  iconStyle: PropTypes.object,
  title: PropTypes.string,
  titleStyle: PropTypes.object,
  titleProps: PropTypes.object,
  color: PropTypes.string,
  backgroundColor: PropTypes.string,
  disabled: PropTypes.bool,
  style: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  borderWidth: PropTypes.number,
  onPress: PropTypes.func,
  variant: PropTypes.string,
  borderColor: PropTypes.string,
  isActive: PropTypes.bool,
  loader: PropTypes.bool,
  iconColor: PropTypes.string,
  loaderComponent: PropTypes.node,
  placeHolders: PropTypes.array,
  rightComponent: PropTypes.node,
}

export default Button

