import { styleSheetFactory } from '../Themes/Colors'

const styles = styleSheetFactory(colors => ({
  full: {
    flex: 1,
  },
  container: {
    flex: 1,
    backgroundColor: colors.background,
  },
  shadow: {
    flex: 1,
    shadowColor: colors.black,
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 1,
    shadowRadius: 12,
  },
  drawerContainer: {
    flex: 1,
    backgroundColor: colors.drawerBackground,
  },
  drawerNavigatorContent: {
    flex: 1,
  },
  drawerNavigatorSceene: {
    backgroundColor: colors.drawerBackground,
  },
  fillRemaining: {
    flexGrow: 1,
  },
  drawerItemContainer: {
    backgroundColor: 'transparent',
    borderRadius: 7,
    marginHorizontal: 15,
    marginVertical: 5,
    overflow: 'hidden',
  },
  topContainer: {
    backgroundColor: 'transparent',
    borderRadius: 7,
    marginHorizontal: 15,
    marginVertical: 30,
    overflow: 'hidden',
  },
  drawerItem: {
    flexDirection: 'row',
    alignItems: 'center',
    minHeight: 50,
    paddingHorizontal: 15,
    paddingVertical: 10,
  },
  drawerItemFocused_true: {
    backgroundColor: colors.drawerActiveBackgroundRGBA(0.1),
  },
  drawerItemLabel: {
    marginHorizontal: 15,
  },
  stack: {
    flex: 1,
    overflow: 'hidden',
  },
  headerStyle: {
    backgroundColor: colors.background,
    shadowColor: colors.background,
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0,
    shadowRadius: 0,
    elevation: 0,
  },
  drawerStyles: {
    width: 280,
    backgroundColor: colors.drawerBackground,
  },
  topContainerActive_true: {
    backgroundColor: colors.drawerActiveBackgroundRGBA(0.1),
  },
  topInfo: {
    marginLeft: 15,
    flexGrow: 1,
    flexShrink: 1,
  },
  companyInfoContainer: {
    flexGrow: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  companyInfo: {
    fontSize: 18,
    color: colors.drawerText,
  },
  userInfo: {
    fontSize: 14,
    color: colors.drawerText,
  },
  login: {
    marginBottom: 25,
  },
  rightIcon: {
    marginLeft: 10,
    flexShrink: 0,
  },
  largeScreenContainer: {
    flexDirection: 'row',
    flex: 1,
  },
  separator: {
    height: 1,
    flexGrow: 1,
    backgroundColor: colors.whiteRGBA(0.2),
  },
  switchLocationButton: {
    height: 40,
    marginTop: 16,
  },
}))

export default styles
