/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import {
    View,
    TouchableOpacity,
    KeyboardAvoidingView,
    Text as RNText,
    ScrollView,
    Platform,
} from 'react-native'
import PropTypes from 'prop-types'
import { useTheme } from 'react-native-themed-styles'
import { useLazyQuery, useMutation } from '@apollo/client'
import _ from 'lodash'

import {
    TouchableInput,
    BottomSheetSelector,
    InputWithTags,
    Header,
    Text,
    Button,
    ErrorModal,
} from '../../Components'
import { CITIES } from '../NewCustomer'

import ContentManager from '../../Utils/ContentManager'
import { getErrorMessage } from '../../Utils'

import images from '../../Themes/Images'
import themedStyles, { itemStyles } from './NewBankAcount.Styles'
import { CREATE_BANK_ACCOUNT, GET_BANK_ACCOUNT, UPDATE_BANK_ACCOUNT } from './NewBankAccount.Schema'

const CURRENCIES = [
    'ALL',
    'EUR',
    'USD',
    'GBP',
]

// const SWIFT_CODE = [
//     'RSTAALTT',
//     'CRBAALTRXXX',
//     'EMPOALTRXXX',
//     'NCBAALTXXXX',
//     'PUPPALTRXXX',
//     'CDISALTRXXX',
//     'FINVALTRXXX',
//     'USALALTRXXX',
//     'FEFAALTRXXX',
//     'SGSBALTXXXX',
//     'TIRBALTRXXX',
//     'UNALALTRXXX',
//     'AAISALTRXXX',
//     'CRBAALTR',
//     'EMPOALTR',
//     'STANALTR',
//     'STANALTS',
//     'NCBAALTX',
//     'PUPPALTR',
//     'CDISALTR',
//     'FINVALTR',
//     'USALALTRDUR',
//     'USALALTRDR2',
//     'USALALTRELB',
//     'USALALTRFIE',
//     'USALALTRGJR',
//     'USALALTRKJ1',
//     'USALALTRKOR',
//     'USALALTRLZH',
//     'USALALTRLUS',
//     'USALALTRSH1',
//     'USALALTR020',
//     'USALALTR010',
//     'USALALTR',
//     'USALALTRVLR',
//     'USALALTRVL2',
//     'MIFBALTR',
//     'FEFAALTRDUR',
//     'FEFAALTRFIE',
//     'FEFAALTR',
//     'FEFAALTRTIR',
//     'SGSBALTX',
//     'TIRBALTR',
//     'UNALALTR',
//     'AAISALTR',
// ]

const BANK_NAMES = [
    'ALPHA BANK ALBANIA',
    'AMERICAN BANK OF INVESTMENTS S.A.',
    'BANKA KOMBETARE TREGTARE SH.A.',
    'BANKA OTP ALBANIA SH.A.',
    'CREDINS BANK S.A.',
    'FIRST INVESTMENT BANK-ALBANIA SH.A',
    'INTESA SANPAOLO BANK ALBANIA SH.A.',
    'PROCREDIT BANK SH. A. ALBANIA (FORMERLY FEFAD BANK)',
    'RAIFFEISEN BANK SH.A.',
    'TIRANA BANK',
    'UNION BANK SH.A.',
    'UNITED BANK OF ALBANIA SH.A',
]

// const SwiftMatrix = {
//     'ALPHA BANK ALBANIA': {
//         'Tiranë': ['CRBAALTR', 'CRBAALTRXXX'],
//     },
//     'AMERICAN BANK OF INVESTMENTS S.A.': {
//         'Tiranë': ['EMPOALTR', 'EMPOALTRXXX'],
//     },
//     'BANKA KOMBETARE TREGTARE SH.A.': {
//         'Tiranë': ['NCBAALTX', 'NCBAALTXXXX'],
//     },
//     'BANKA OTP ALBANIA SH.A.': {
//         'Tiranë': ['PUPPALTR', 'PUPPALTRXXX'],
//     },
//     'CREDINS BANK S.A.': {
//         'Tiranë': ['CDISALTR', 'CDISALTRXXX'],
//     },
//     'FIRST INVESTMENT BANK-ALBANIA SH.A': {
//         'Tiranë': ['FINVALTR', 'FINVALTRXXX'],
//     },
//     'INTESA SANPAOLO BANK ALBANIA SH.A.': {
//         'Tiranë': ['USALALTR', 'USALALTRXXX', 'USALALTR010', 'USALALTR020'],
//         'Durrës': ['USALALTRDUR', 'USALALTRDR2'],
//         'Elbasan': ['USALALTRELB'],
//         'Fier': ['USALALTRFIE'],
//         'Gjirokastra': ['USALALTRGJR'],
//         'Kavajë': ['USALALTRKJ1'],
//         'Korçë': ['USALALTRKOR'],
//         'Lezhë': ['USALALTRLZH'],
//         'Lushnjë': ['USALALTRLUS'],
//         'Shkodër': ['USALALTRSH1'],
//         'Vlorë': ['USALALTRVLR', 'USALALTRVL2'],
//     },
//     'PROCREDIT BANK SH. A. ALBANIA (FORMERLY FEFAD BANK)': {
//         'Tiranë': ['FEFAALTR', 'FEFAALTRTIR', 'FEFAALTRXXX'],
//         'Durrës': ['FEFAALTRDUR'],
//         'Fier': ['FEFAALTRFIE'],
//     },
//     'RAIFFEISEN BANK SH.A.': {
//         'Tiranë': ['SGSBALTX', 'SGSBALTXXXX'],
//     },
//     'TIRANA BANK': {
//         'Tiranë': ['TILBALTR', 'TIRBALTRXXX'],
//     },
//     'UNION BANK SH.A.': {
//         'Tiranë': ['UNALALTR', 'UNALALTRXXX'],
//     },
//     'UNITED BANK OF ALBANIA SH.A': {
//         'Tiranë': ['AAISALTR', 'AAISALTRXXX'],
//     },
// }

function Type({ item, selected, onPress }) {

    const [itemStyle] = useTheme(itemStyles)
    const isSelected = selected === item

    return (
        <View style={itemStyle.container}>
            <TouchableOpacity
                onPress={onPress}
                style={itemStyle['itemSelected_' + isSelected]}
                activeOpacity={0.6}
            >
                <Text
                    i18nKey={item}
                    size="footnote"
                    style={itemStyle['itemTextSelected_' + isSelected]}
                />
            </TouchableOpacity>
        </View>
    )
}
Type.propTypes = {
    item: PropTypes.string,
    selected: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    onPress: PropTypes.func,
}
function NewBankAccount({
    route: {
        params: {
            id,
            title,
        },
    },
    navigation: { goBack, addListener, navigate },
}) {
    const { translate } = ContentManager

    const [disabled, setDisabled] = useState(true)
    const [isError, setError] = useState({
        isVisible: false,
        errorMessage: '',
    })

    const [city, setCity] = useState('')
    const [iban, setIban] = useState()
    const [cardOwner, setCardOwner] = useState()
    const [country, setCountry] = useState('Albania')
    const [swiftCode, setSwiftCode] = useState('')
    const [bankName, setBankName] = useState('')
    const [searchValue, setSearchValue] = useState('')
    const [currency, setCurrency] = useState('')
    const [styles] = useTheme(themedStyles)
    const [updateBankAccount, { loading: updateLoading }] = useMutation(UPDATE_BANK_ACCOUNT)
    const [createBankAccount, { loading: createLoading }] = useMutation(CREATE_BANK_ACCOUNT)
    const [getBankAccount, { data: bankAccountData }] = useLazyQuery(GET_BANK_ACCOUNT, {
        fetchPolicy: 'network-only',
    })
    const regex = /^[A-Za-z0-9]{28,}$/

    const [modal, setModal] = useState({
        isVisible: false,
        data: [],
        title: '',
        placeholder: '',
        selected: undefined,
        select: () => { },
        Item: Type,
    })

    const closeModal = () => {
        setModal(prev => ({ ...prev, isVisible: false }))
        setSearchValue('')
    }

    useEffect(() => {
        if (id) {
            getBankAccount({
                variables: {
                    id,
                },
            })
        }
    }, [id])

    useEffect(() => {
        const bankData = bankAccountData?.getBankAccount
        if (bankData) {
            setCardOwner(bankData?.holderName)
            setBankName(bankData?.bankName)
            setCity(bankData?.city)
            setCountry(bankData?.country)
            setSwiftCode(bankData?.swiftCode)
            setIban(bankData?.iban)
            setCurrency(bankData?.currency)
        }
    }, [bankAccountData])


    const openCitySelector = () => {
        setModal({
            isVisible: true,
            data: CITIES,
            title: 'einvoice_delivery_city',
            placeholder: 'supporting_invoice_city_search',
            selected: city,
            select: (txt) => setCity(txt),
            searchKey: 'self',
            Item: Type,
        })
    }

    const openCurrencySelector = () => {
        setModal({
            isVisible: true,
            data: CURRENCIES,
            title: 'payment_currency_title',
            placeholder: 'payment_currency_placeholder',
            selected: currency,
            select: setCurrency,
            searchKey: 'self',
            Item: Type,
        })
    }

    const openBankSelector = () => {
        setModal({
            isVisible: true,
            data: BANK_NAMES,
            title: 'bank_accounts_bank_name',
            placeholder: 'bank_accounts_bank_search',
            selected: bankName,
            select: (txt) => setBankName(txt),
            searchKey: 'self',
            Item: Type,
        })
    }


    useEffect(() => {
        if (bankAccountData) {
            if (
                _.isEqual(bankAccountData.getBankAccount, {
                    city: city,
                    country: country,
                    iban: iban,
                    swiftCode: swiftCode,
                    bankName: bankName,
                    holderName: cardOwner,
                    currency: currency,
                })
                ||
                (!cardOwner ||
                    !regex.test(iban) ||
                    !country ||
                    !bankName ||
                    !city ||
                    !swiftCode ||
                    !currency)
            ) {
                setDisabled(true)
            }
            else {
                setDisabled(false)
            }
        }
        else {
            setDisabled(
                !cardOwner ||
                !regex.test(iban) ||
                !country ||
                !bankName ||
                !city ||
                !swiftCode ||
                !currency
            )
        }
    }, [
        cardOwner,
        iban,
        country,
        bankName,
        city,
        swiftCode,
        currency,
    ])

    const registerBankAccount = () => {
        if (id) {
            updateBankAccount({
                variables: {
                    id,
                    iban: iban,
                    city: city,
                    country: country,
                    bankName: bankName,
                    swiftCode: swiftCode.toUpperCase(),
                    holderName: cardOwner,
                    currency: currency,
                },
            }).then(() => {
                goBack()
            }).catch((e) => {
                setError({
                    isVisible: true,
                    errorMessage: getErrorMessage(e),
                })
            })
        } else {
            createBankAccount({
                variables: {
                    iban: iban,
                    city: city,
                    country: country,
                    bankName: bankName,
                    swiftCode: swiftCode.toUpperCase(),
                    holderName: cardOwner,
                    currency: currency,
                },
            }).then(() => {
                goBack()
            }).catch((e) => {
                setError({
                    isVisible: true,
                    errorMessage: getErrorMessage(e),
                })
            })
        }
    }

    return (
        <KeyboardAvoidingView
            behavior={Platform.select({
                ios: 'padding',
                android: undefined,
            })}
            style={styles.container}
        >
            <Header
                title={title ? title : 'bank_accounts_create_page_label'}
                image={images.close}
                onPress={() => goBack()}
            />
            <ScrollView
                contentContainerStyle={styles.fillRemaining}
                showsVerticalScrollIndicator={false}
            >
                <Text i18nKey="bank_accounts_card_info_label" weight="bold" style={styles.sectionTitle} />
                <InputWithTags
                    label="bank_accounts_account_holder_name"
                    value={cardOwner}
                    onChangeText={text => setCardOwner(text)}
                    autoCompleteType="off"
                    inputWrapperStyle={styles.currency}

                />
                <InputWithTags
                    label="payment_iban_label"
                    value={iban}
                    onChangeText={text => setIban(text)}
                    autoCapitalize="characters"
                    autoCompleteType="off"
                />
                <RNText
                    style={styles.vehiclePlateHelper}
                    allowFontScaling={false}
                >
                    <RNText>{translate('bank_accounts_card_iban_helper1')}</RNText>
                    <RNText style={styles.vehiclePlateHelperItalic} >{translate('bank_accounts_card_iban_helper2')}</RNText>
                </RNText>
                <TouchableInput
                    onPress={() => openCurrencySelector()}
                    label="payment_currency_title"
                    value={currency}
                    icon={images.filledDownArrow}
                    iconSize={16}
                    autoCorrect={false}
                    autoCapitalize="none"
                    editable={false}
                />
                <InputWithTags
                    label="customers_state_label"
                    value={country}
                    onChangeText={text => setCountry(text)}
                    autoCompleteType="off"
                    inputWrapperStyle={styles.currency}
                    disabled
                />
                <TouchableInput
                    onPress={() => openBankSelector()}
                    label="bank_accounts_bank_name"
                    value={bankName}
                    icon={images.filledDownArrow}
                    iconSize={16}
                    autoCorrect={false}
                    autoCapitalize="none"
                    editable={false}
                />
                <TouchableInput
                    onPress={() => openCitySelector()}
                    label="einvoice_details_city_label"
                    value={city}
                    icon={images.filledDownArrow}
                    iconSize={16}
                    autoCorrect={false}
                    autoCapitalize="none"
                    editable={false}
                    disabled={bankName ? false : true}
                />
                <InputWithTags
                    autoCapitalize="characters"
                    label="bank_accounts_swift_code"
                    value={swiftCode}
                    onChangeText={text => setSwiftCode(text)}
                    autoCompleteType="off"
                // inputWrapperStyle={styles.currency}
                />
                <Text i18nKey="bank_accounts_card_swift_helper1" style={styles.vehiclePlateHelper} />
            </ScrollView>
            <View style={styles.buttonContainer}>
                <Text i18nKey="bank_accounts_card_credit_helper1" align="center" size="footnote" weight="light" style={styles.creditScoreHelper} />
                <Button
                    title="users_save_button"
                    titleStyle={styles.buttonTitle}
                    onPress={() => registerBankAccount()}
                    variant={disabled || updateLoading || createLoading ? 'disabled' : 'active'}
                    loader={updateLoading || createLoading}
                />
            </View>
            <BottomSheetSelector
                {...modal}
                closeModal={closeModal}
                searchValue={searchValue}
                setSearchValue={setSearchValue}
            />
            <ErrorModal
                isVisible={isError.isVisible}
                onClosePress={() => setError({
                    isVisible: false,
                })}
                title="orders_error_label"
                description={isError.errorMessage}
                primaryText="einvoice_cancel_modal_button"
                primaryAction={() => setError({
                    isVisible: false,
                })}
            />
        </KeyboardAvoidingView>
    )
}

NewBankAccount.propTypes = {
    navigation: PropTypes.object,
    route: PropTypes.object,
}

export default NewBankAccount
