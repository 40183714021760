import { gql } from '@apollo/client'

export const GET_ITEM = gql`
  query getItem($searchField: String!, $searchValue: String!, $nextVariations: String, $nextModifierList: String, $nextDiscount: String, $locId: String, $cashRegisterId: String ) {
    getItem(getItemInput: {
      searchBy: {
        name: $searchField
        value: $searchValue
      }
        listItemVariationsInput: {
        nextToken: $nextVariations
      }
        listModifierListsInput: {
        nextToken: $nextModifierList
      }
        listDiscountsInput: {
        nextToken: $nextDiscount
      }
    }
    requestedLocationAndCashRegister: {
      locationId: $locId,
      cashRegisterId: $cashRegisterId
    }
    ){
      item{
        id
        barcode
        name
        description
        labelColor
        taxId
        tax{
          id
          name
          percentage
        }
        taxExemptionType
        taxInclusionType
        itemVariationsCount
        modifierListsCount
        imageUrl
        measurementUnitId
        measurementUnit {
          name
          precision
        }
      }
      listItemVariations{
        itemVariations{
          itemId
          id
          name
          priceMoney{
            amount
            currency
          }
          prices {
            amount
            currency
          }
        }
        nextToken
      }
      listModifierLists{
        modifierLists{
          id
          name
          modifiers{
            name
            modifierListId
            id
            priceMoney{
              amount
              currency
            }
          }
        }
        nextToken
      }
      listDiscounts{
        discounts{
          id
          name
          discountType
          percentage
          amountMoney{
            amount
            currency
          }
          prices {
            amount
            currency
          }
        }
        nextToken
      }
    }
  }
`

export const LIST_ALL_TAXES = gql`
  query listTaxes($nextToken: String) {
    listTaxes(listTaxesInput: {
      limit: 20
      nextToken: $nextToken
    }){
      taxes{
        id
        name
        percentage
      }
      nextToken
    }
  }
`


export const LIST_MODIFIERS = gql`
query getModifierList($id: String!, $nextToken: String, $locId: String, $cashRegisterId: String){
  getModifierList(getModifierListInput:{
    id: $id
    listModifiersInput:{
      nextToken: $nextToken
    }
  }
  requestedLocationAndCashRegister: {
    locationId: $locId,
    cashRegisterId: $cashRegisterId
  }
  ){
    modifierList{
      id
      name
    }
    listModifiers{
      modifiers{
        id
        name
        priceMoney {
          amount
          currency
        }
      }
      nextToken
    }
  }
}
`
