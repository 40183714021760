import { Dimensions, StyleSheet } from 'react-native'

import { styleSheetFactory } from '../../Themes/Colors'

const { width } = Dimensions.get('screen')

const styles = styleSheetFactory(colors => ({
  container: {
    flex: 1,
    position: 'relative',
    backgroundColor: colors.background,
  },
  header: {
    height: 56,
    alignItems: 'center',
    flexDirection: 'row',
    backgroundColor: colors.background,
  },
  headerIcon: {
    marginHorizontal: 20,
  },
  topMenuContainer: {
    flexDirection: 'row',
    position: 'absolute',
    top: 56,
    left: 0,
    right: 0,
    margin: 15,
    marginBottom: 0,
  },
  scene: {
    flex: 1,
    backgroundColor: colors.background,
  },
  tabContainerStyle: {
    backgroundColor: colors.tertiaryBackground,
    elevation: 0,
    shadowRadius: 0,
    shadowOpacity: 0,
    marginBottom: 0,
  },
  indicatorStyle: {
    backgroundColor: colors.tabAccent,
  },
  label: {
    fontFamily: 'Vodafone Rg',
    textTransform: 'none',
  },
  tabView: {
    backgroundColor: colors.background,
    paddingTop: 80,
    width: width,
  },
  listContainerStyle: {
    overflow: 'hidden',
    borderRadius: 7,
    paddingHorizontal: 15,
    paddingBottom: 80,
  },
  listSeparatorStyle: {
    height: 1,
    backgroundColor: colors.separator,
    borderLeftWidth: 70,
    borderLeftColor: colors.tertiaryBackground,
  },
  searchWrapper: {
    flexGrow: 1,
    flexDirection: 'row',
    borderRadius: 7,
    overflow: 'hidden',
    backgroundColor: colors.tertiaryBackground,
  },
  searchContainer: {
    flexGrow: 1,
    flexDirection: 'row',
    backgroundColor: colors.tertiaryBackground,
    alignItems: 'center',
  },
  searchIcon: {
    marginHorizontal: 10,
  },
  inputContainer: {
    flexGrow: 1,
  },
  searchInput: {
    flexGrow: 1,
    paddingLeft: 0,
    fontSize: 12,
    lineHeight: 14,
    color: colors.primaryText,
  },
  clearSearchContainer: {
    height: 50,
    aspectRatio: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  clearSearchTouchable: {
    backgroundColor: colors.background,
    height: 30,
    aspectRatio: 1,
    borderRadius: 15,
    justifyContent: 'center',
    alignItems: 'center',
  },
  scan: {
    height: 50,
    width: 50,
    backgroundColor: 'white',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: 15,
    borderRadius: 7,
  },
  bottomMenuContainer: {
    backgroundColor: colors.background,
    borderTopLeftRadius: 7,
    borderTopRightRadius: 7,
  },
  cardInfoWrapper: (isKeyBoardVisible = false) => {
    return {
      overflow: 'hidden',
      maxHeight: isKeyBoardVisible ? 0 : null,
    }
  },
  cardInfoContainer: {
    marginHorizontal: 15,
    marginTop: 15,
  },
  shadow: {
    shadowColor: colors.black,
    shadowOffset: {
      width: 0,
      height: -1,
    },
    shadowOpacity: 0.16,
    shadowRadius: 6,
  },
  bottomMenu: {
    flexDirection: 'row',
    paddingVertical: 15,
    paddingRight: 15,
  },
  separator: {
    height: 1,
    backgroundColor: colors.separator,
    marginTop: 10,
  },
  chargeButton: {
    flexGrow: 1,
    margin: 0,
    marginLeft: 15,
  },

  qrVisible: {
    marginTop: 80,
  },
  qrInvisible: {
    marginTop: 15,
  },

  // Modal
  modalContent: {
    margin: 15,
    padding: 30,
    borderRadius: 7,
    backgroundColor: colors.tertiaryBackground,
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    overflow: 'hidden',
  },
  modalCloseContainer: {
    position: 'absolute',
    top: 0,
    right: 0,
    height: 30,
    width: 75,
    borderTopRightRadius: 7,
    borderBottomLeftRadius: 7,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: colors.separator,
  },
  modalTitle: {
    marginVertical: 10,
  },
  modalDesc: {
    marginBottom: 15,
  },
  modalPrimaryButton: {
    width: '100%',
    marginVertical: 15,
  },
  modalSecondaryButton: {
    width: '100%',
  },

  //Animated List
  listContainer: {
    position: 'relative',
    flexGrow: 1,
  },
  // contentContainer: {
  //   borderRadius: 7,
  //   overflow: 'hidden',
  //   paddingBottom: 105,
  //   margin: 15,
  //   marginHorizontal: 12,
  // },
  fab: {
    position: 'absolute',
    bottom: 225,
    right: 15,
    height: 50,
    width: 50,
    borderRadius: 25,
    backgroundColor: colors.tertiaryAccent,
    justifyContent: 'center',
    alignItems: 'center',
    shadowColor: colors.black,
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.22,
    shadowRadius: 2.22,
    elevation: 3,
  },
  fabKeyboard: {
    bottom: 95,
  },
  fabNoKeyboard: {
    bottom: 225,
  },
  fabIcon: {
    transform: [{ rotate: '-90deg' }],
  },
  leftSpacing: { marginLeft: 15 },
}))

const itemStyles = styleSheetFactory(colors => ({
  itemContainer: {
    backgroundColor: colors.tertiaryBackground,
    height: 70,
    borderRadius: 7,
    marginVertical: 3,
    overflow: 'hidden',
  },
  touchable: {
    // flexGrow: 1,
    height: 70,
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: colors.tertiaryBackground,
    overflow: 'hidden',
  },
  listImageContainer: {
    width: 70,
    height: 70,
    flexShrink: 0,
    justifyContent: 'center',
    alignItems: 'center',
  },
  listImage: {
    height: 55,
    aspectRatio: 1,
  },
  listInfoContainer: {
    flexGrow: 1,
    flexShrink: 1,
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
    padding: 15,
    paddingLeft: 5,
  },
  priceDetails: {
    flexDirection: 'row',
  },
  listPriceContainer: {
    flexDirection: 'column',
    alignItems: 'baseline',
    flexShrink: 0,
  },
  listFirst: {
    borderTopLeftRadius: 7,
    borderTopRightRadius: 7,
  },
  listLast: {
    borderBottomLeftRadius: 7,
    borderBottomRightRadius: 7,
  },
  listDescContainer: {
    flexGrow: 1,
    flexShrink: 1,
  },
  quantityPriceContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: width - 32,
    marginHorizontal: 16,
  },
  quantityContainer: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  quantityButton: {
    width: 28,
    height: 28,
    borderRadius: 4,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: colors.primaryText,
    justifyContent: 'center',
    alignItems: 'center',
  },
  quantityInput: {
    width: 70,
    height: 28,
    paddingVertical: 2,
    color: colors.primaryText,
  },
  rowView: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  rightSpacing: { marginRight: 5 },
}))

const hiddenItemStyles = styleSheetFactory(colors => ({
  itemContainer: {
    backgroundColor: colors.tertiaryBackground,
    height: 70,
    borderRadius: 7.5,
    marginVertical: 3,
    overflow: 'hidden',
    justifyContent: 'center',
    alignItems: 'flex-end',
  },
  touchableContent: {
    width: 100,
    height: 70,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: colors.accent,
  },
}))

const cardInfoStyles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
})

export { itemStyles, hiddenItemStyles, cardInfoStyles }
export default styles
